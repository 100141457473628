<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.accounting.posting-type.posting-type', 2)" :to="{ name: 'accounting-posting-type-list' }" />
            </template>

            <h1>{{ $tc('common.accounting.posting-type.posting-type', 2) }}</h1>
        </page-header>

        <base-table
            ref="postingTypeListTable"
            :columns="columns"
            :fetch-objects-fn="PostingType.service.all"
            user-settings-base-path="PostingTypeList"
            pagination-sort-by-default-key="name"
            data-test="table:posting-type-list"
            @row-dblclick="onDblClick"
        >
            <template v-slot:body-cell-name="slotProps">
                <q-td :props="slotProps">
                    {{ slotProps.row.name }}
                    <info-icon
                        v-if="slotProps.row.isSystemPostingType"
                        data-test="btn:tooltip-details"
                        name="mib-lock-1"
                        :title="$tc('views.accounting.posting-type.system-posting-type', 1)"
                        :text="$t('views.accounting.posting-type.system-posting-type--description')"
                    />
                </q-td>
            </template>

            <template v-slot:body-cell-actions="slotProps">
                <q-td :props="slotProps" auto-width>
                    <q-btn
                        color="primary"
                        size="sm"
                        flat
                        round
                        icon="mib-pencil"
                        :to="{ name: 'accounting-posting-type-update', params: { id: slotProps.row.id } }"
                        data-test="link:tooltip-actions"
                    >
                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.accounting.posting-type.show-posting-type', 1) }}</q-tooltip>
                    </q-btn>
                </q-td>
            </template>
        </base-table>

        <in-page-footer>
            <base-button
                :label="$tc('views.accounting.posting-type.add-posting-type', 1)"
                primary-button
                :to="{ name: 'accounting-posting-type-create' }"
                data-test="link:add-posting-type"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { PostingType } from '@/models/postingType'
import BaseTable from '@/components/BaseTable'
import { EventBus } from '@/event-bus'

export default {
    name: 'PostingTypeList',
    meta () {
        return {
            title: this.$tc('common.accounting.posting-type.posting-type', 2),
        }
    },
    components: {
        BaseTable,
    },
    data () {
        return {
            PostingType,
            columns: [
                {
                    name: 'name',
                    label: this.$tc('common.term.name', 1),
                    align: 'left',
                    sortable: true,
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left' },
            ],
        }
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        onNewEntity () {
            this.$router.push({ name: 'accounting-posting-type-create' })
        },
        onDblClick (evt, row) {
            this.$router.push({ name: 'accounting-posting-type-update', params: { id: row.id } })
        },
    },
}
</script>
