import gql from 'graphql-tag'

export const EMAIL_ADDRESS_LABELS_QUERY = gql`
    query emailAddressLabels(
        $filterCategoryIds: [ID!]
    ) {
        emailAddressLabels(
            search: {
                category_ids: $filterCategoryIds
            }
        ) {
            id
            key
            label
        }
    }
`
