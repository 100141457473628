<template>
    <!-- TODO improvement: Add layout and navigation (categories) to Content Store and open the corresponding item when dialog is opened. (So that a user can browse through the Content Store.) -->
    <q-dialog
        ref="contentStoreDialog"
        :persistent="persistent"
        :full-width="$q.screen.lt.lg"
        :full-height="$q.screen.height < 800"
    >
        <q-layout view="Lhh lpR fff" container class="content-store-wrapper">
            <q-header>
                <q-toolbar class="bg-primary-dark text-text-inverted">
                    <inline-svg :src="require('@/assets/img/max-logo-white.svg')" style="height: 2rem;" />
                    <q-toolbar-title>{{ $t('common.content-store.title') }}</q-toolbar-title>
                    <q-btn
                        v-close-popup
                        icon="mib-close"
                        size="xs"
                        class="q-pa-xs"
                        flat
                        round
                        dense
                    />
                </q-toolbar>
            </q-header>
            <q-page-container>
                <q-page padding>
                    <component
                        :is="`content-store-${toKebabCase(contentType)}`"
                        :content-type="contentType"
                        @import-finished="$emit('import-finished')"
                        @hide="hide"
                        @mode-changed="modeChanged"
                    />
                </q-page>
            </q-page-container>
        </q-layout>
    </q-dialog>
</template>

<script>
import { ContentStoreViewMode } from '@/enums'
import kebabCase from 'lodash.kebabcase'
import ContentStoreProductProvider from '@/components/content-store/ContentStoreProductProvider'
import ContentStoreProductTemplate from '@/components/content-store/ContentStoreProductTemplate'

export default {
    name: 'ContentStoreDialog',
    components: {
        ContentStoreProductProvider,
        ContentStoreProductTemplate,
    },
    props: {
        contentType: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            persistent: false,
        }
    },
    methods: {
        toKebabCase: function (text) {
            return kebabCase(text)
        },
        show () {
            this.$refs.contentStoreDialog.show()
        },
        hide () {
            this.$refs.contentStoreDialog.hide()
        },
        modeChanged (mode) {
            this.persistent = (mode === ContentStoreViewMode.IMPORT)
        },
    },
}
</script>

<style lang="scss" scoped>
.content-store-wrapper {
    min-width: 60vw;
    min-height: 75vh;
}
</style>
