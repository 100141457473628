<template>
    <div class="entity-number-input" :data-test="$attrs['data-test']">
        <!-- TODO refactoring @TFU: This currently only supports string and counter segments. -->
        <!-- TODO improvement @TFU: Add error handling. -->
        <!-- TODO improvement @MTR: Find solution for label integration. -->
        <template v-for="(segment, index) in value">
            <base-input
                :key="`segment-${index}`"
                :value="displayValues[index]"
                :disable="!segment.mutable"
                :type="segment.type === 'counter' ? 'number' : 'text'"
                :maxlength="segment.type === 'string' ? segment.maxLength : undefined"
                :min="segment.type === 'counter' ? 1 : undefined"
                :max="segment.type === 'counter' ? parseInt('9'.repeat(segment.maxLength)) : undefined"
                :style="`flex-basis: ${segment.maxLength}0%;`"
                @input="inputValue => setValue(index, inputValue)"
            >
                <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
            </base-input>
        </template>
    </div>
</template>

<script>
import BaseInput from '@/components/form/BaseInput.vue'
import Vue from 'vue'
import { computed, ref, watch } from 'vue'

export default {
    name: 'EntityNumberInput',
    components: {
        BaseInput,
    },
    inheritAttrs: false,
    props: {
        value: {
            type: Array,
            required: true,
        },
    },
    setup (props, context) {
        const values = ref(props.value.map(segment => segment.value))
        const displayValues = computed(() => {
            return props.value.map(segment => {
                if (segment.type === 'counter') {
                    return segment.value.padStart(segment.paddingLeft, '0')
                } else {
                    return segment.value
                }
            })
        })

        function setValue (index, value) {
            Vue.set(values.value, index, value)
        }

        watch(values.value, () => {
            emitValue()
        })

        function emitValue () {
            const valueStructure = JSON.parse(JSON.stringify(props.value))
            valueStructure.forEach((segment, index) => {
                segment.value = values.value.at(index)
            })
            context.emit('input', valueStructure)
        }

        return {
            values,
            displayValues,
            setValue,
        }
    },
}
</script>

<style type="text/css">
.entity-number-input {
    display: flex;

    & > .q-input {
        flex: 1 1 auto;

        &:not(:last-child) {
            margin-right: 0.5em;
        }
    }
}
</style>
