import gql from 'graphql-tag'

export const PRODUCTS_QUERY = gql`
    query products(
        $page: Int = 1
        $count: Int = 1000
        $orderField: ProductOrderField = PRODUCT_NAME
        $orderDirection: OrderDirection = ASC
        $filterQuery: String
        $productStatus: [ProductStatus!] = [ACTIVE]
    ) {
        products(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                product_search: $filterQuery
                status: $productStatus
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                name
                status
                template {
                    id
                    name
                    status
                    category {
                        name
                    }
                    subCategory {
                        name
                    }
                    version
                }
                basicProvider {
                    id
                    name
                    display_name
                }
            }
        }
    }
`
