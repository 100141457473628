<template>
    <page-wrapper>
        <q-breadcrumbs class="q-mt-none">
            <q-breadcrumbs-el icon="mib-app-window-error-404" :label="$tc('common.term.error', 1)" />
            <q-breadcrumbs-el :label="$t('views.error.not-found.title')" />
        </q-breadcrumbs>

        <div class="text-center">
            <illustration-heading :title="$t('views.error.not-found.title')" :subtitle="`${this.$tc('common.term.error')} 404`" img="code-magnifier" />

            <i18n path="views.error.not-found.text" tag="p">
                <template v-slot:url>
                    <code v-if="originalUrl">{{ originalUrl }}</code>
                </template>
            </i18n>

            <i18n path="views.error.general.text-instructions" tag="p">
                <template v-slot:backlink>
                    <a @click="$router.go(-1)">{{ $t('views.error.general.text-instructions-back-link') }}</a>
                </template>
            </i18n>
        </div>
    </page-wrapper>
</template>

<script>
export default {
    name: 'ErrorNotFound',
    meta () {
        return {
            title: this.$t('views.error.not-found.title'),
        }
    },
    computed: {
        originalUrl () {
            return this.$route.params.pathMatch
        },
    },
}
</script>
