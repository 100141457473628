import { PostingAccountSide } from '@/enums'
import { AccountType } from '@/enums/graphql'

/**
 * Calculate the account balance change.
 * @export
 * @param {Object} arguments
 * @param {string} arguments.accountType
 * @param {string} arguments.postingAccountSide
 * @param {number} arguments.postingAmount
 * @return {number}
 */
export function getAccountBalanceChange({ accountType, postingAccountSide, postingAmount }) {
    let isDecreasing = false

    if (([AccountType.LIABILITIES, AccountType.EQUITY, AccountType.REVENUE].includes(accountType) && postingAccountSide === PostingAccountSide.DEBIT) || ([AccountType.ASSETS, AccountType.EXPENSES].includes(accountType) && postingAccountSide === PostingAccountSide.CREDIT)) {
        isDecreasing = true
    }

    return isDecreasing ? -postingAmount : postingAmount
}
