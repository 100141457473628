<template>
    <form
        data-test="form:commission-split"
        @submit.prevent="onFormSubmit"
    >
        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="formErrorMessage"
            type="negative"
            no-margin
            data-test="error:global-message"
            v-html="formErrorMessage"
        />
        <!-- eslint-enable -->

        <markup-table-cell :column="{ name: 'additional_recipient' }" :class="{ 'has-error': formErrorMessage }">
            <form-builder item-key="additionalRecipientId" />
        </markup-table-cell>

        <markup-table-cell :column="{ name: 'rate' }" :class="{ 'has-error': formErrorMessage }">
            <form-builder item-key="splitRate" />
        </markup-table-cell>

        <markup-table-cell :column="{ name: 'active_from' }" :class="{ 'has-error': formErrorMessage }">
            <form-builder item-key="activeFrom" />
        </markup-table-cell>

        <markup-table-cell :column="{ name: 'active_until' }" :class="{ 'has-error': formErrorMessage }">
            <form-builder item-key="activeUntil" />
        </markup-table-cell>

        <markup-table-cell
            :column="{ name: 'actions' }"
            :class="['', { 'has-error': formErrorMessage }]"
            :grid-column-start="-3"
            :col-span="2"
        >
            <base-button
                primary-button
                data-test="btn:save"
                type="submit"
                :label="$t('common.term.save')"
                class="q-mr-sm"
                :disable="formSubmitStatus"
                :loading="formSubmitStatus"
            />
            <base-button
                primary-button
                flat
                data-test="btn:cancel"
                :label="$t('common.term.cancel')"
                :disable="formSubmitStatus"
                @click="$emit('cancel')"
            />
        </markup-table-cell>
    </form>
</template>

<script>
import { ViewMode } from '@/enums'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import COMMISSION_SPLIT_FORM from '@/forms/commissionSplit.form'
import MarkupTableCell from '@/components/MarkupTableCell.vue'
import { lightFormat } from 'date-fns'

export default {
    name: 'CommissionSplitForm',
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: COMMISSION_SPLIT_FORM,
    },
    components: {
        MarkupTableCell,
    },
    props: {
        commissionSplit: {
            type: Object,
            required: false,
            default () {
                return undefined
            },
        },
    },
    data () {
        const data = {
            ViewMode,
            currentMode: (this.commissionSplit) ? ViewMode.UPDATE : ViewMode.CREATE,
            formData: {},
        }

        if (data.currentMode === ViewMode.UPDATE) this.updateFormData(data.formData)

        return data
    },
    methods: {
        resetInitialFormData () {
            this.updateFormData(this.formData)
        },
        updateFormData (targetObject) {
            const data = {
                additionalRecipientId: this.commissionSplit.additionalRecipient.id,
                splitRate: this.commissionSplit.split_rate,
                activeFrom: '',
                activeUntil: '',
            }
            if (this.commissionSplit.active_from) data.activeFrom = lightFormat(new Date(this.commissionSplit.active_from), 'yyyy-MM-dd')
            if (this.commissionSplit.active_until) data.activeUntil = lightFormat(new Date(this.commissionSplit.active_until), 'yyyy-MM-dd')
            Object.assign(targetObject, data)
        },
    },
}
</script>
