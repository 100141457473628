import gql from 'graphql-tag'

export const CONTACT_ADDRESS_DELETE_MUTATION = gql`
    mutation contactAddressDelete(
        $id: ID!
    ) {
        contactAddressDelete(
            id: $id
        ) {
            status
        }
    }
`
