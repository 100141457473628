import gql from 'graphql-tag'

export const CORRESPONDENCE_SALUTATION_CREATE_MUTATION = gql`
    mutation correspondenceSalutationCreate(
        $contactType: ContactType!
        $formality: DocumentContentFormality!
        $gender: GenderChoice
        $languageId: ID!
        $salutation: EditorContentInput!
    ) {
        correspondenceSalutationCreate(
            contact_type: $contactType
            formality: $formality
            gender: $gender
            language_id: $languageId
            salutation: $salutation
        ) {
            id
            contact_type
            formality
            gender
            language {
                id
                name
            }
            is_default
            salutation {
                schemaType
                schemaVersion
                contentJSON
                contentHTML
                attrs
            }
            created_at
            updated_at
        }
    }
`
