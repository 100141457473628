import { apolloClient } from '@/apollo/client'

import { PRODUCT_TEMPLATE_QUERY } from '@/graphql/product/productTemplate'
import { PRODUCT_TEMPLATES_QUERY } from '@/graphql/product/productTemplates'
import { PRODUCT_TEMPLATE_CREATE_MUTATION } from '@/graphql/product/productTemplateCreate'
import { PRODUCT_TEMPLATE_UPDATE_MUTATION } from '@/graphql/product/productTemplateUpdate'
import { PRODUCT_TEMPLATE_CHANGE_STATUS_MUTATION } from '@/graphql/product/productTemplateChangeStatus'
import { PRODUCT_TEMPLATE_DELETE_MUTATION } from '@/graphql/product/productTemplateDelete'

export const ProductTemplateService = {
    get (id, version) {
        return apolloClient.query({
            query: PRODUCT_TEMPLATE_QUERY,
            variables: {
                id: id,
                version: version,
            },
        }).then(response => response.data.productTemplate)
    },
    all (variables) {
        return apolloClient.query({
            query: PRODUCT_TEMPLATES_QUERY,
            variables,
        }).then(response => response.data.productTemplates)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: PRODUCT_TEMPLATE_CREATE_MUTATION,
            variables,
        }).then(response => response.data.productTemplateCreate)
    },
    update (id, productTemplateVariables) {
        return apolloClient.mutate({
            mutation: PRODUCT_TEMPLATE_UPDATE_MUTATION,
            variables: {
                id: id,
                productTemplate: productTemplateVariables,
            },
        }).then(response => response.data.productTemplateUpdate)
    },
    changeStatus (id, status) {
        return apolloClient.mutate({
            mutation: PRODUCT_TEMPLATE_CHANGE_STATUS_MUTATION,
            variables: {
                id: id,
                status: status,
            },
        }).then(response => response.data.productTemplateChangeStatus)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: PRODUCT_TEMPLATE_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.productTemplateDelete)
    },
}
