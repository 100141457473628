<template>
    <div class="file-browser-wrapper">
        <div class="row">
            <div class="col-xs-12 col-md-3 col-lg-2 file-type-filter">
                <q-tabs
                    v-if="fileTypes.length"
                    v-model="currentFileTypeId"
                    no-caps
                    dense
                    :vertical="$q.screen.gt.sm"
                    indicator-color="accent"
                    active-bg-color="white"
                >
                    <consulting-file-upload-dropzone
                        :target-object-type="targetObjectType"
                        :target-object-id="targetObjectId"
                        :target-object-consultants="targetObjectConsultants"
                        :meta="meta"
                    >
                        <q-tab
                            key="all"
                            :name="null"
                            :label="$tc('common.consulting-file.type.all', 1)"
                        />
                    </consulting-file-upload-dropzone>

                    <consulting-file-upload-dropzone
                        v-for="fileType in fileTypes"
                        :key="fileType.id"
                        :target-object-type="targetObjectType"
                        :target-object-id="targetObjectId"
                        :target-object-consultants="targetObjectConsultants"
                        :file-type-id="fileType.id"
                        :meta="meta"
                    >
                        <q-tab
                            :data-test="`tab:${fileType.key}`"
                            :name="fileType.id"
                            :label="$tc(`common.consulting-file.type.${toKebabCase(fileType.key)}`, 2)"
                        />
                    </consulting-file-upload-dropzone>
                </q-tabs>
            </div>
            <div class="col-xs-12 col-md-9 col-lg-10 file-list">
                <consulting-file-upload-dropzone
                    :target-object-type="targetObjectType"
                    :target-object-id="targetObjectId"
                    :target-object-consultants="targetObjectConsultants"
                    :file-type-id="currentFileTypeId"
                    :meta="meta"
                    zone-type="browser"
                >
                    <q-table
                        :data="consultingFiles"
                        :columns="columns"
                        row-key="id"
                        :pagination.sync="pagination"
                        :loading="loading"
                        :rows-per-page-options="[10, 20, 50, 100]"
                        binary-state-sort
                        flat
                        square
                        dense
                        @request="onRequest"
                        @row-dblclick="(evt, row) => row.download()"
                    >
                        <template v-slot:top>
                            <q-space />
                            <filter-input v-model="filter" @clear-filter="clearFilter" />
                        </template>

                        <template v-slot:body-cell-file_name="props">
                            <q-td :props="props">
                                <b>{{ props.row.file.name }}</b>
                            </q-td>
                        </template>

                        <template v-slot:body-cell-actions="props">
                            <q-td :props="props" auto-width>
                                <q-btn
                                    color="primary"
                                    size="sm"
                                    flat
                                    round
                                    icon="mib-cloud-download"
                                    @click="props.row.file.download()"
                                >
                                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.term.download-item', 1, { item: props.row.file.name }) }}</q-tooltip>
                                </q-btn>
                                <q-btn
                                    color="primary"
                                    size="sm"
                                    flat
                                    round
                                    icon="mib-pencil"
                                    @click="triggerConsultingFileUpdate(props.row)"
                                >
                                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.term.edit-item', 1, { item: props.row.file.name }) }}</q-tooltip>
                                </q-btn>
                                <q-btn
                                    color="primary"
                                    size="sm"
                                    flat
                                    round
                                    icon="mib-bin"
                                    @click="triggerConsultingFileDelete(props.row)"
                                >
                                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.term.delete-item', 1, { item: props.row.file.name }) }}</q-tooltip>
                                </q-btn>
                            </q-td>
                        </template>
                    </q-table>
                </consulting-file-upload-dropzone>

                <form-dialog
                    v-if="consultingFileToUpdate"
                    ref="consultingFileUpdateDialog"
                    :item="consultingFileToUpdate"
                    :form-builder-settings="consultingFileUpdateformBuilderSettings"
                    :initial-form-data="{ type: consultingFileToUpdate.type.id, consultant: consultingFileToUpdate.consultant.id, fileName: consultingFileToUpdate.file.name }"
                    dont-close-on-confirm
                    @confirm="handleConsultingFileUpdate"
                    @hide="consultingFileToUpdate = null"
                >
                    <template v-slot:title>{{ $tc('common.document.update-document', 1) }}</template>
                    <template v-slot:default="slotProps">
                        <div v-if="slotProps.item"><h2>{{ slotProps.item.file.name }}</h2></div>
                    </template>
                </form-dialog>
                <form-dialog
                    ref="consultingFileDeleteDialog"
                    :item="consultingFileToDelete"
                    @confirm="handleConsultingFileDelete"
                    @hide="consultingFileToDelete = null"
                >
                    <template v-slot:title>{{ $tc('common.document.delete-document', 1) }}</template>
                    <template v-slot:default="slotProps">
                        <i18n v-if="slotProps.item" path="common.document.delete-document--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:documentName><b>{{ slotProps.item.file.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </div>
        </div>
        <div v-if="$slots.default" class="row">
            <div class="col-xs-12 col-md-9 offset-md-3 col-lg-10 offset-lg-2 q-pt-md file-list-actions">
                <slot name="default" />
            </div>
        </div>
    </div>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { EventBus } from '@/event-bus'
import { OrderDirection, ConsultingFileTargetObjectType } from '@/enums/graphql'
import { ConsultingFileService } from '@/services'
import { getFormItemByKey } from '@/helpers/form'
import ConsultingFileUploadDropzone from '@/components/consultingFile/ConsultingFileUploadDropzone'
import FormDialog from '@/components/form/FormDialog'
import FilterInput from '@/components/FilterInput'

import CONSULTING_FILE_FIELDS from '@/forms/consulting-file.fields.json'

export default {
    name: 'ConsultingFileBrowser',
    components: {
        ConsultingFileUploadDropzone,
        FormDialog,
        FilterInput,
    },
    props: {
        targetObjectType: {
            type: String,
            required: true,
        },
        targetObjectId: {
            type: String,
            required: true,
        },
        targetObjectConsultants: {
            type: Array,
        },
        meta: {
            type: Object,
        },
    },
    data () {
        return {
            currentFileTypeId: null,
            filter: '',
            loading: false,
            pagination: {
                sortBy: 'created_at',
                descending: true,
                page: 1,
                rowsPerPage: 50,
                rowsNumber: 0,
            },
            columns: [
                {
                    name: 'file_name',
                    label: this.$tc('common.term.name', 1),
                    field: row => row.file.name,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'type_id',
                    label: this.$tc('common.term.type', 1),
                    field: row => row.type.name,
                    sortable: true,
                    align: 'left',
                },
                // TODO: Check if it would make sense to have a column showing the file type (PDF, Excel, …)
                /*
                                {
                                    name: 'file_size',
                                    label: this.$tc('common.file.file-type', 1),
                                    // TODO: Add file type
                                    // field: row => row.file.type,
                                    sortable: true,
                                    align: 'left'
                                },
                */
                {
                    name: 'file_size',
                    label: this.$tc('common.file.file-size', 1),
                    field: row => row.file.formattedFileSize,
                    // sortable: true, // TODO: so far not sortable...
                },
                {
                    name: 'created_at',
                    label: this.$t('common.term.added-on'),
                    field: row => row.file.formattedCreatedAt,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'consultant_name',
                    label: this.$tc('common.contact.consultant', 1),
                    field: row => row.consultant.getContactName({ consultingCompanyName: !row.consultant.worksForUserConsultingCompany }),
                    sortable: true,
                    align: 'left',
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left' },
            ],
            fileTypes: [],
            consultingFiles: [],
            consultingFileToUpdate: null,
            consultingFileToDelete: null,
        }
    },
    computed: {
        consultingFileUpdateformBuilderSettings () {
            if (this.consultingFileToUpdate) {
                const formSchema = JSON.parse(JSON.stringify(CONSULTING_FILE_FIELDS))

                // Inject consultants
                const injectConsultants = [this.consultingFileToUpdate.consultant]
                if (this.targetObjectConsultants.length) injectConsultants.push(...this.targetObjectConsultants)
                const consultantField = getFormItemByKey(formSchema, 'consultant')
                consultantField.extra_attributes = {
                    injectConsultants,
                }

                switch (this.consultingFileToUpdate.__typename) {
                    case 'ContactFile':
                        formSchema.children.push({
                            type: 'field',
                            key: 'type',
                            label: 'common.document.document-type',
                            inputType: 'contactFileType',
                            validators: [
                                { 'type': 'required' },
                            ],
                        })
                        break
                    case 'ApplicationFile':
                        formSchema.children.push({
                            type: 'field',
                            key: 'type',
                            label: 'common.document.document-type',
                            inputType: 'applicationFileType',
                            validators: [
                                { 'type': 'required' },
                            ],
                        })
                        break
                    case 'ContractFile':
                        formSchema.children.push({
                            type: 'field',
                            key: 'type',
                            label: 'common.document.document-type',
                            inputType: 'contractFileType',
                            validators: [
                                { 'type': 'required' },
                            ],
                        })
                        break
                }
                return { schema: formSchema }
            }
            return {}
        },
    },
    watch: {
        'targetObjectId' () {
            this.fetchObjects()
        },
        'currentFileTypeId' () {
            this.$emit('currentFileTypeIdChange', this.currentFileTypeId)
            this.fetchObjects()
        },
        'filter' () {
            this.fetchObjects()
        },
    },
    created () {
        EventBus.$on('fileUploadManager:allUploadsDone', this.checkReload)

        switch (this.targetObjectType) {
            case ConsultingFileTargetObjectType.CONTACT:
                ConsultingFileService.getContactFileTypes().then(contactFileTypes => {
                    this.fileTypes = contactFileTypes
                })
                break
            case ConsultingFileTargetObjectType.APPLICATION:
                ConsultingFileService.getApplicationFileTypes().then(applicationFileTypes => {
                    this.fileTypes = applicationFileTypes
                })
                break
            case ConsultingFileTargetObjectType.CONTRACT:
                ConsultingFileService.getContractFileTypes().then(contractFileTypes => {
                    this.fileTypes = contractFileTypes
                })
                break
        }
        this.fetchObjects()
    },
    beforeDestroy () {
        EventBus.$off('fileUploadManager:allUploadsDone', this.checkReload)
    },
    methods: {
        clearFilter () {
            this.filter = ''
        },
        onRequest (props) {
            let { page, rowsPerPage, sortBy, descending } = props.pagination

            this.pagination.page = page
            this.pagination.rowsPerPage = rowsPerPage
            this.pagination.sortBy = sortBy
            this.pagination.descending = descending

            this.fetchObjects()
        },
        fetchObjects () {
            this.loading = true
            const variables = {
                page: this.pagination.page,
                count: this.pagination.rowsPerPage,
                orderField: this.pagination.sortBy.toUpperCase(),
                orderDirection: (this.pagination.descending) ? OrderDirection.DESC : OrderDirection.ASC,

                consultingFileTargetObject: {
                    object_type: this.targetObjectType,
                    object_id: this.targetObjectId,
                },

                searchTerm: this.filter,
            }

            if (this.currentFileTypeId !== null) variables.typeId = this.currentFileTypeId

            ConsultingFileService.all(variables).then(response => {
                this.pagination.rowsNumber = response.paginatorInfo.total
                this.loading = false
                this.consultingFiles = response.data
                this.$emit('numberOfDocuments', response.paginatorInfo.total)
            })
        },
        checkReload (targetObjects) {
            if (targetObjects.some(targetObject => targetObject.type === this.targetObjectType && targetObject.id === this.targetObjectId)) {
                this.fetchObjects()
            }
        },
        async triggerConsultingFileUpdate (consultingFile) {
            this.consultingFileToUpdate = consultingFile
            await this.$nextTick()
            this.$refs.consultingFileUpdateDialog.open()
        },
        handleConsultingFileUpdate (formData, consultingFile) {
            consultingFile.update({ typeId: formData.type, consultantId: formData.consultant, fileName: formData.fileName }).then(() => {
                this.fetchObjects() // TODO: replace with method that updates the file just in the frontend?
                // TODO: show notification?
                this.$refs.consultingFileUpdateDialog.close()
            })
        },
        triggerConsultingFileDelete (consultingFile) {
            this.consultingFileToDelete = consultingFile
            this.$refs.consultingFileDeleteDialog.open()
        },
        handleConsultingFileDelete (formData, consultingFile) {
            consultingFile.delete().then(() => {
                this.fetchObjects() // TODO: replace with method that removes the file just in the frontend?
                // TODO: show notification?
            })
        },
        toKebabCase: function (text) {
            return kebabCase(text)
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .file-type-filter {
    .q-tab {
        background-color: var(--color-background-faded);

        // Left-align labels
        justify-content: flex-start;

        // Styling of active filter
        &.q-tab--active {

            .q-tab__label {
                font-weight: bold;
                /*color: var(--q-color-primary-darker, currentColor);*/
            }
        }
    }

    .q-tab__label {
        // Add ellipsis if text is too long
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

::v-deep .file-list {

    .dropzone {
        height: 100%;
        min-height: 280px;
    }
}

@include mq($from: $sizeBreakpointMd) {
    .file-browser-wrapper {
        // Minimize the gap between heading and table
        margin-top: (-($sizeSpacingXl));
    }

    .file-type-filter {
        // TODO: Check if there’s a good way to get the correct/real-time padding-top (= height of the table header)
        padding: 86px $sizeSpacingMd 0 0;

        .dropzone {
            border: 1px solid var(--color-border-primary);
            border-bottom: none;

            &:last-child {
                border-bottom: 1px solid var(--color-border-primary);
            }
        }
    }
}

@media print {
    ::v-deep .file-type-filter {

        .dropzone {
            display: inline-block;
        }

        .q-tab {

            &.q-tab--active {
                border-bottom: 2px solid var(--q-color-accent);

                .q-tab__indicator {
                    display: none;
                }
            }
        }
    }
}
</style>
