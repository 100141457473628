import gql from 'graphql-tag'

const paginatorFields = `
    count
    firstItem
    lastItem
    total
    currentPage
    lastPage
    perPage
    hasMorePages
`

const taskFields = `
    id
    status
    creator {
        id
        first_name
        last_name
        gender
        consultingCompany {
            id
        }
        consulting_company_name
    }
    assignee {
        id
        first_name
        last_name
        gender
        consultingCompany {
            id
        }
        consulting_company_name
    }
    visibility
    subject
    description {
        schemaType
        schemaVersion
        contentJSON
        contentHTML
        attrs
    }
    attachments {
        id
    }
    target_object_type
    target_object_id
    targetObject {
        ... on Person {
            first_name
            last_name
        }
        ... on Company {
            company_name
        }
        ... on Application {
            number {
                number
            }
            customer {
                ... on Person {
                    first_name
                    last_name
                }
                ... on Company {
                    company_name
                }
            }
        }
        ... on Contract {
            contractNumbers {
                number
            }
            customer {
                ... on Person {
                    first_name
                    last_name
                }
                ... on Company {
                    company_name
                }
            }
        }
    }
    due_date
    created_at
    updated_at
    done_at
`

export const TASK_PANEL_QUERY = gql`
    query taskPanel(
        $page: Int = 1
        $count: Int = 1000
        $orderField: TaskOrderField = DUE_DATE
        $orderDirection: OrderDirection = ASC
        $filterStatus: [TaskStatus!]
        $filterAssigneeId: ID
        $filterCreatorId: ID
        $filterDueDateUntil: DateTimeTz
    ) {
        assignedTasks: tasks(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                status: $filterStatus
                assignee_id: $filterAssigneeId
                due_date_until: $filterDueDateUntil
            }
        ) {
            paginatorInfo {
                ${paginatorFields}
            }
            data {
                ${taskFields}
            }
        }

        createdTasks: tasks(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                status: $filterStatus
                creator_id: $filterCreatorId
                due_date_until: $filterDueDateUntil
            }
        ) {
            paginatorInfo {
                ${paginatorFields}
            }
            data {
                ${taskFields}
            }
        }
    }
`
