<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('views.contact.create.title')" />
            </template>

            <h1 v-if="type === 'person'">{{ $t('views.contact.create.add-person') }}</h1>
            <h1 v-else>{{ $t('views.contact.create.add-company') }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <contact-person-create-form v-if="type === ContactType.PERSON.toLowerCase()" ref="contactPersonCreateForm" @submit="onContactPersonCreateFormSubmit" />
                <contact-company-create-form v-else ref="contactCompanyCreateForm" @submit="onContactCompanyCreateFormSubmit" />
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { ContactType } from '@/enums/graphql'

import ContactPersonCreateForm from '@/components/contact/ContactPersonCreateForm'
import ContactCompanyCreateForm from '@/components/contact/ContactCompanyCreateForm'

import { Person, Company } from '@/models/contact'

export default {
    name: 'ContactCreate',
    meta () {
        return {
            title: (this.type === 'person') ? this.$t('views.contact.create.add-person') : this.$t('views.contact.create.add-company'),
        }
    },
    components: {
        ContactPersonCreateForm,
        ContactCompanyCreateForm,
    },
    props: {
        type: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            ContactType,
        }
    },
    methods: {
        onContactPersonCreateFormSubmit (formData) {
            Person.create(formData)
                .then(person => {
                    this.$router.replace({ name: 'contact-detail', params: { id: person.id } })
                })
                .catch(error => {
                    this.$refs.contactPersonCreateForm.showFormErrorMessage(error)
                    this.$refs.contactPersonCreateForm.resetFormSubmitStatus()
                })
        },
        onContactCompanyCreateFormSubmit (formData) {
            Company.create(formData)
                .then(company => {
                    this.$router.replace({ name: 'contact-detail', params: { id: company.id } })
                })
                .catch(error => {
                    this.$refs.contactCompanyCreateForm.showFormErrorMessage(error)
                    this.$refs.contactCompanyCreateForm.resetFormSubmitStatus()
                })
        },
    },
}
</script>
