import gql from 'graphql-tag'

export const POSTING_TYPE_QUERY = gql`
    query postingType(
        $id: ID!
    ) {
        postingType(
            id: $id
        ) {
            id
            key
            name
            name_language_versions
            created_at
            updated_at
        }
    }
`
