import gql from 'graphql-tag'

export const PRODUCT_UPDATE_MUTATION = gql`
    mutation productUpdate(
        $id: ID!
        $product: ProductInputUpdate!
    ) {
        productUpdate(
            id: $id
            product: $product
        ) {
            id
            name
            status
            template {
                id
                name
                status
                category {
                    name
                }
                subCategory {
                    name
                }
                version
            }
            provider {
                id
                contactNumber {
                    id
                }
                ... on Person {
                    first_name
                    last_name
                }
                ... on Company {
                    company_name
                }
            }
        }
    }
`
