<template>
    <form data-test="form:forgot-password-username" @submit.prevent="onFormSubmit">
        <p>{{ $t('views.auth.forgot-password.text') }}</p>

        <form-builder item-key="username" autofocus />

        <form-navigation>
            <template v-slot:col2>
                <base-button
                    data-test="btn:back"
                    outline
                    :label="$t('common.term.back')"
                    :to="loginURL"
                    class="q-mr-sm"
                />
                <base-button
                    data-test="btn:submit"
                    type="submit"
                    :label="$t('common.term.continue')"
                    :disable="formSubmitStatus"
                    :loading="formSubmitStatus"
                />
            </template>
        </form-navigation>
    </form>
</template>

<script>
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import FormNavigation from '@/components/form/FormNavigation'

import AUTH_CREDENTIALS_FIELDS from '@/forms/auth.fields.json'

export default {
    name: 'AuthForgotPasswordUsername',
    components: {
        FormNavigation,
    },
    mixins: [formBuilderMixin],
    props: {
        prefillUsername: {
            type: String,
        },
    },
    formBuilderSettings: {
        schema: AUTH_CREDENTIALS_FIELDS,
        fields: ['username'],
    },
    data () {
        return {
            formData: {
                username: (this.prefillUsername) ? this.prefillUsername : '',
            },
        }
    },
    computed: {
        loginURL () {
            const link = { name: 'auth-login' }
            if (this.formData.username) {
                link.query = { username: this.formData.username }
            }
            return link
        },
    },
}
</script>
