import { i18n } from '@/i18n'
import { ActivityLogParser } from '@/parsers/activityLog'

export class CommissionDistributionRuleSetActivityLogParser extends ActivityLogParser {
    static getLogDescriptionCreated (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `<b>${i18n.tc('common.change-log.subject.this.commission-distribution-rule-set', 1)}</b>`,
        }
        return i18n.t('common.change-log.someone-created-something', interpolationParams)
    }

    static getLogDescriptionUpdated (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `${i18n.tc('common.change-log.someone-updated-something--field', activityLog.attributeKeys.length)} <b>${activityLog.attributeLabels}</b>`,
        }
        return i18n.t('common.change-log.someone-updated-something', interpolationParams)
    }

    static getLogDescriptionDeleted (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `<b>${i18n.tc('common.change-log.subject.this.commission-distribution-rule-set', 1)}</b>`,
        }
        return i18n.t('common.change-log.someone-deleted-something', interpolationParams)
    }
}
