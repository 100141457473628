import { Form, FormField, FormRepeaterItem } from '@/libs/form'
import { isAfterOrEqualToDate, isBeforeOrEqualToDate } from '@/libs/form/validators'
import { DxpBillingPremiumKind, DxpBillingPremiumType } from '@/enums/graphql'
import { kebabCase } from 'lodash'

export class PremiumInvoicePositionForm extends Form {
    compose () {
        // Will not be rendered, but needed for updating the positions instead of deleting and creating new ones.
        this.addField(new FormField('id', {
            type: 'text',
        }))

        // Premium from
        this.addField(new FormField('premiumFrom', {
            label: 'common.premium-invoice.premium-from',
            inputType: 'calendar',
            validators: [
                { type: 'required' },
                { type: 'date' },
                {
                    type: 'isBeforeOrEqualToDate',
                    validator: () => {
                        return isBeforeOrEqualToDate(this.getItem('premiumFrom').value, this.getItem('premiumTo').value)
                    },
                    params: {
                        targetLabel: 'common.premium-invoice.premium-to',
                    },
                },
            ],
        }))

        // Premium to
        this.addField(new FormField('premiumTo', {
            label: 'common.premium-invoice.premium-to',
            inputType: 'calendar',
            validators: [
                { type: 'required' },
                { type: 'date' },
                {
                    type: 'isAfterOrEqualToDate',
                    validator: () => {
                        return isAfterOrEqualToDate(this.getItem('premiumTo').value, this.getItem('premiumFrom').value)
                    },
                    params: {
                        targetLabel: 'common.premium-invoice.premium-from',
                    },
                },
            ],
        }))

        // Premium kind
        const premiumKindOptions = [
            { label: 'common.term.please-choose', value: null, disable: true },
            ...Object.values(DxpBillingPremiumKind).map(value => ({ label: `common.premium-invoice.premium-kind.${kebabCase(value.toLowerCase())}`, value: value })),
        ]
        this.addField(new FormField('premiumKind', {
            label: 'common.premium-invoice.premium-kind.premium-kind',
            inputType: 'select',
            validators: [
                { type: 'required' },
            ],
            attributes: {
                options: premiumKindOptions,
            },
        }))

        // Premium type
        const premiumTypeOptions = [
            { label: 'common.term.please-choose', value: null, disable: true },
            ...Object.values(DxpBillingPremiumType).map(value => ({ label: `common.premium-invoice.premium-type.${kebabCase(value.toLowerCase())}`, value: value })),
        ]
        this.addField(new FormField('premiumType', {
            label: 'common.premium-invoice.premium-type.premium-type',
            inputType: 'select',
            validators: [
                { type: 'required' },
            ],
            attributes: {
                options: premiumTypeOptions,
            },
        }))

        // Sector code
        this.addField(new FormField('sectorCodeId', {
            label: 'common.premium-invoice.sector-code',
            inputType: 'igb2bSectorCode',
            validators: [
                { type: 'required' },
            ],
        }))

        // Annual premium amount
        this.addField(new FormField('annualPremiumAmount', {
            label: 'common.premium-invoice.annual-premium-amount',
            inputType: 'text', // TODO @TFU: Make 'currency' so that the field is correctly formatted (e.g. 12'345.00)
            validators: [
                { type: 'decimal' },
                {
                    type: 'minValue',
                    params: {
                        min: 0,
                    },
                },
            ],
            attributes: {
                prefix: 'CHF',
            },
        }))

        // Instalment surcharge
        this.addField(new FormField('instalmentSurcharge', {
            label: 'common.premium-invoice.instalment-surcharge',
            inputType: 'text', // TODO @TFU: Make 'currency' so that the field is correctly formatted (e.g. 12'345.00)
            validators: [
                { type: 'decimal' },
                {
                    type: 'minValue',
                    params: {
                        min: 0,
                    },
                },
            ],
            attributes: {
                infoText: 'common.premium-invoice.instalment-surcharge--info',
                prefix: 'CHF',
            },
        }))

        // Taxes statutory charges
        this.addField(new FormField('taxesStatutoryCharges', {
            label: 'common.premium-invoice.taxes-statutory-charges',
            inputType: 'text', // TODO @TFU: Make 'currency' so that the field is correctly formatted (e.g. 12'345.00)
            validators: [
                { type: 'decimal' },
                {
                    type: 'minValue',
                    params: {
                        min: 0,
                    },
                },
            ],
            attributes: {
                infoText: 'common.premium-invoice.taxes-statutory-charges--info',
                prefix: 'CHF',
            },
        }))

        // Invoiced amount
        this.addField(new FormField('invoicedAmount', {
            label: 'common.premium-invoice.invoiced-amount',
            inputType: 'text', // TODO @TFU: Make 'currency' so that the field is correctly formatted (e.g. 12'345.00)
            validators: [
                { type: 'required' },
                { type: 'decimal' },
            ],
            attributes: {
                prefix: 'CHF',
            },
        }))
    }
}

export class PremiumInvoicePositionRepeaterItem extends FormRepeaterItem {
    compose () {
        const form = new PremiumInvoicePositionForm()
        this.addItems(form.items)
    }
}
