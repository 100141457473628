<template>
    <form
        class="row q-col-gutter-md"
        data-test="form:accountBalanceReport"
        @submit.prevent="onFormSubmit"
    >
        <div v-if="formErrorMessage" class="col-xs-12">
            <!-- eslint-disable vue/no-v-html -->
            <info-box
                type="negative"
                no-margin
                data-test="error:global-message"
                v-html="formErrorMessage"
            />
            <!-- eslint-enable -->
        </div>

        <grid-card
            :columns="{ xs: 12, md: 6 }"
            :heading="$tc('common.term.configuration')"
        >
            <div class="row q-col-gutter-md">
                <div class="col-xs-6 col-sm-4 col-md-6 col-lg-4">
                    <form-builder item-key="dateFrom" />
                </div>

                <div class="col-xs-6 col-sm-4 col-md-6 col-lg-4">
                    <form-builder item-key="dateUntil" />
                </div>

                <div class="col-xs-12 col-sm-4 col-md-12 col-lg-4">
                    <form-builder item-key="fileTypes" readonly />
                </div>
            </div>
        </grid-card>

        <grid-card
            :columns="{ xs: 12, md: 6 }"
            :heading="$tc('common.accounting.account.account', 2)"
        >
            <div class="row q-col-gutter-x-md q-col-gutter-y-sm">
                <div class="col-xs-5 col-sm-4 col-md-12 col-lg-4">
                    <q-radio
                        v-model="accountSelectionType"
                        :label="$tc('views.accounting.account-balance-report.account-selection--range', 1)"
                        :val="AccountBalanceReportAccountSelectionType.RANGE"
                    />
                </div>
                <div class="col-xs-7 col-sm-8 col-md-12 col-lg-8">
                    <div class="row q-col-gutter-md">
                        <div class="col-xs-12 col-sm-6">
                            <form-builder item-key="accountNumberFrom" :disable="formData.selectSpecificAccounts" />
                        </div>
                        <div class="col-xs-12 col-sm-6">
                            <form-builder item-key="accountNumberTo" :disable="formData.selectSpecificAccounts" />
                        </div>
                    </div>
                </div>
                <div class="col-xs-12">
                    <q-radio
                        v-model="accountSelectionType"
                        :label="$tc('views.accounting.account-balance-report.account-selection--individual', 1)"
                        :val="AccountBalanceReportAccountSelectionType.INDIVIDUAL"
                    />
                </div>
            </div>
        </grid-card>

        <div v-if="formData.selectSpecificAccounts" class="col-xs-12">
            <base-table
                ref="table"
                :columns="columns"
                :fetch-objects-fn="Account.service.all"
                :additional-filters="additionalFilters"
                user-settings-base-path="AccountBalanceReportSpecificAccountList"
                pagination-sort-by-default-key="number"
                selection="multiple"
                pagination-initial-order-direction-ascending
                :selected.sync="selectedItems"
                :selection-row-condition="selectionRowCondition"
                enable-visible-columns
                @update:selected="setSelectedItems"
            >
                <template v-slot:tabs>
                    <q-tabs
                        v-model="filters.filterStatus"
                        dense
                        no-caps
                        inline-label
                        align="left"
                        class="q-mr-sm bg-background-secondary"
                        indicator-color="accent"
                    >
                        <q-tab
                            :name="AccountStatus.ACTIVE"
                            :label="$t('common.status.active')"
                        />
                        <q-tab
                            :name="AccountStatus.INACTIVE"
                            :label="$t('common.status.inactive')"
                        />
                    </q-tabs>
                </template>

                <template v-slot:selectionRowConditionInfo>
                    <q-checkbox
                        :value="false"
                        class="not-selectable"
                        color="secondary-light"
                        keep-color
                        dense
                    />
                </template>

                <template v-slot:body-cell-status="slotProps">
                    <q-td :props="slotProps" auto-width>
                        <status-badge :status="slotProps.row.status" />
                    </q-td>
                </template>
            </base-table>
        </div>

        <in-page-footer>
            <base-button
                primary-button
                flat
                data-test="btn:cancel"
                :label="$t('common.term.cancel')"
                @click="handleCancel"
            />

            <base-button
                primary-button
                type="submit"
                :label="$t('common.term.create')"
                :disable="formSubmitStatus || (formData.selectSpecificAccounts && !selectedItems.length)"
                :loading="formSubmitStatus"
                data-test="btn:submit"
            />
        </in-page-footer>
    </form>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { lightFormat, startOfMonth, endOfMonth } from 'date-fns'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import ACCOUNT_BALANCE_REPORT_FORM from '@/forms/accountBalanceReport.form'
import { Account } from '@/models/account'
import { AccountBalanceReportAccountSelectionType } from '@/enums'
import { AccountStatus, FileType } from '@/enums/graphql'

import BaseTable from '@/components/BaseTable.vue'
import StatusBadge from '@/components/StatusBadge'

export default {
    name: 'AccountBalanceReportForm',
    components: {
        BaseTable,
        StatusBadge,
    },
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: ACCOUNT_BALANCE_REPORT_FORM,
    },
    data () {
        return {
            AccountStatus,
            Account,
            AccountBalanceReportAccountSelectionType,
            accountSelectionType: AccountBalanceReportAccountSelectionType.RANGE,
            loading: false,
            maxSelectedItemsAmount: 300,
            selectedItems: [],
            columns: [
                {
                    name: 'number',
                    label: this.$tc('common.accounting.account.account-number'),
                    align: 'left',
                    sortable: true,
                    field: row => row.number,
                    required: true,
                },
                {
                    name: 'name',
                    label: this.$tc('common.accounting.account.name'),
                    align: 'left',
                    sortable: true,
                    field: row => row.name,
                    required: true,
                },
                {
                    name: 'type',
                    label: this.$tc('common.accounting.account.type.type'),
                    align: 'left',
                    sortable: true,
                    field: row => this.$t(`common.accounting.account.type.${kebabCase(row.type)}`),
                },
                {
                    name: 'assigned_to_name',
                    label: this.$tc('common.accounting.account.assigned-to', 1),
                    align: 'left',
                    sortable: true,
                    field: row => row.formattedAssignedTo,
                    classes: row => row.assignedTo && row.assignedTo.type ? null : 'additional-info',
                },
                {
                    name: 'assigned_as',
                    label: this.$tc('common.accounting.account.assigned-as', 1),
                    align: 'left',
                    field: row => row.formattedAssignedAs,
                    classes: row => row.assigned_as_type ? null : 'additional-info',
                },
                {
                    name: 'balance',
                    label: this.$tc('common.accounting.account.balance', 1),
                    align: 'right',
                    sortable: true,
                    field: row => row.formattedBalance,
                    classes: row => `text-bold monospace ${row.balance < 0 ? 'text-negative-darker' : null}`,
                    required: true,
                },
                {
                    name: 'status',
                    label: this.$tc('common.term.status', 1),
                    align: 'left',
                    sortable: true,
                    field: row => row.status,
                    required: true,
                },
            ],
            formData: {
                fileTypes: FileType.EXCEL,
                dateFrom: lightFormat(startOfMonth(new Date()), 'yyyy-MM-dd'),
                dateUntil: lightFormat(endOfMonth(new Date()), 'yyyy-MM-dd'),
            },
            filters: {
                filterStatus: AccountStatus.ACTIVE,
            },
        }
    },
    computed: {
        additionalFilters () {
            const filters = {
                filterStatus: this.filters.filterStatus,
            }

            return filters
        },
        selectedItemsLength () {
            return this.selectedItems.length
        },
    },
    watch: {
        'formData.selectSpecificAccounts' (newVal) {
            this.resetAccountNumberFrom()
            this.resetAccountNumberTo()
            this.resetFilterStatus()

            if (!newVal) {
                this.resetSelectedItems()
            }
        },
        accountSelectionType (newVal) {
            switch (newVal) {
                case AccountBalanceReportAccountSelectionType.RANGE:
                    this.formData.selectSpecificAccounts = false
                    break
                case AccountBalanceReportAccountSelectionType.INDIVIDUAL:
                    this.formData.selectSpecificAccounts = true
                    break
            }
        },
    },
    methods: {
        handleFormData (formData) {
            const accountIds = this.selectedItems.map((account) => account.id)
            this.$emit('post-handle-form-data', { ...formData, accountIds: accountIds })
        },
        handleCancel () {
            this.$router.push({ name: 'accounting-account-balance-reports' })
        },
        selectionRowCondition () {
            return this.formData.selectSpecificAccounts
        },
        resetSelectedItems () {
            this.selectedItems.length = 0
            if (this.$refs.table) this.$refs.table.$refs.qTable.clearSelection()
        },
        setSelectedItems () {
            if (this.selectedItemsLength > this.maxSelectedItemsAmount) {
                this.selectedItems.length = this.maxSelectedItemsAmount
                return
            }
            const validSelectedItems = this.selectedItems.filter(this.selectionRowCondition)
            this.selectedItems.length = 0
            this.selectedItems.push(...validSelectedItems)
        },
        resetAccountNumberFrom () {
            this.formData.accountNumberFrom = null
        },
        resetAccountNumberTo () {
            this.formData.accountNumberTo = null
        },
        resetFilterStatus () {
            this.filters.filterStatus = AccountStatus.ACTIVE
        },
    },
}
</script>
