<template>
    <base-select-filter
        v-if="options.length"

        :value="value"
        v-bind="$attrs"
        :options="options"

        option-value="id"
        option-label="name"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { PostingTypeService } from '@/services'

export default {
    name: 'PostingTypeSelect',
    components: {
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        filter: {
            type: Object,
        },
        filterFn: {
            type: Function,
            default: null,
        },
    },
    data () {
        return {
            options: [],
        }
    },
    created () {
        const variables = {}

        if (this.filter) Object.assign(variables, this.filter)

        PostingTypeService.all(variables).then(response => {
            this.options = this.filterFn ? this.filterFn(response.data) : response.data
        })
    },
}
</script>
