<template>
    <div class="row">
        <div class="col-xs-12 col-sm-10 col-md-10 col-lg-6 col-xl-5">
            <p>{{ $t('common.auth.password-requirements') }}</p>

            <form data-test="form:user-change-password" class="q-gutter-y-sm" @submit.prevent="onFormSubmit">
                <!-- eslint-disable vue/no-v-html -->
                <info-box
                    v-if="formErrorMessage"
                    type="negative"
                    data-test="error:global-message"
                    v-html="formErrorMessage"
                />
                <!-- eslint-enable -->

                <form-builder
                    class="q-mt-lg"
                    item-key="password"
                    :label="$t('views.account.change-password.current-password')"
                    :hint="$t('views.account.change-password.current-password-hint')"
                />
                <form-builder
                    class="q-mt-md"
                    item-key="setPassword"
                    :label="$t('views.account.change-password.new-password')"
                />
                <form-builder
                    item-key="confirmPassword"
                    :label="$t('views.account.change-password.confirm-new-password')"
                />

                <base-button
                    data-test="btn:submit"
                    class="q-mt-md"
                    type="submit"
                    :label="$t('common.term.save')"
                    :disable="formSubmitStatus"
                    :loading="formSubmitStatus"
                />
            </form>
        </div>
    </div>
</template>

<script>
import { formBuilderMixin } from '@/mixins/formBuilderMixin'

import AUTH_CREDENTIALS_FIELDS from '@/forms/auth.fields.json'

export default {
    name: 'UserChangePasswordForm',
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: AUTH_CREDENTIALS_FIELDS,
        fields: ['password', 'setPassword', 'confirmPassword'],
    },
}
</script>
