import { Model } from '@/models'

import { ConsultingFileService } from '@/services'

export class ConsultingFile extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'file',
            'type',
            'consultant',

            // Meta
            '__typename',
        ], data)
    }

    static get service () { return ConsultingFileService }

    get formattedFileSize () {
        console.warn('ConsultingFile.formattedFileSize is deprecated, use ConsultingFile.file.formattedFileSize instead.')
        return this.file.formattedFileSize
    }

    get formattedCreatedAt () {
        console.warn('ConsultingFile.formattedCreatedAt is deprecated, use ConsultingFile.file.formattedCreatedAt instead.')
        return this.file.formattedCreatedAt
    }

    download () {
        console.warn('ConsultingFile.download() is deprecated, use ConsultingFile.file.download() instead.')
        this.file.download()
    }
}

export class ContactFile extends ConsultingFile {
    constructor (data) {
        super(data)
        Model.initializeFields(this, [
            'contact',
        ], data)
    }

    static create (fileUploadWrapper) {
        return ConsultingFileService.createContactFile(fileUploadWrapper.targetObjectId, fileUploadWrapper.fileTypeId, fileUploadWrapper.file, fileUploadWrapper.abortController)
    }

    update ({ typeId, consultantId, fileName }) {
        const variables = {
            id: this.id,
        }

        if (typeId) variables.typeId = typeId
        if (consultantId) variables.consultantId = consultantId
        if (fileName) variables.fileName = fileName

        return ConsultingFileService.updateContactFile(variables)
    }

    delete () {
        return ConsultingFileService.deleteContactFile(this.id)
    }
}

export class ContactFileType extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            ['status', 'contactFileTypeStatus'], // Gets returned as contactFileTypeStatus on the ConsultingFile union
            'key',
            'name',
        ], data)
    }
}

export class ApplicationFile extends ConsultingFile {
    constructor (data) {
        super(data)
        Model.initializeFields(this, [
            'application',
        ], data)
    }

    static create (fileUploadWrapper) {
        return ConsultingFileService.createApplicationFile(fileUploadWrapper.targetObjectId, fileUploadWrapper.fileTypeId, fileUploadWrapper.file, fileUploadWrapper.abortController)
    }

    update ({ typeId, consultantId, fileName }) {
        const variables = {
            id: this.id,
        }

        if (typeId) variables.typeId = typeId
        if (consultantId) variables.consultantId = consultantId
        if (fileName) variables.fileName = fileName

        return ConsultingFileService.updateApplicationFile(variables)
    }

    delete () {
        return ConsultingFileService.deleteApplicationFile(this.id)
    }
}

export class ApplicationFileType extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            ['status', 'applicationFileTypeStatus'], // Gets returned as contractFileTypeStatus on the ConsultingFile union
            'key',
            'name',
        ], data)
    }
}


export class ContractFile extends ConsultingFile {
    constructor (data) {
        super(data)
        Model.initializeFields(this, [
            'contract',
        ], data)
    }

    static create (fileUploadWrapper) {
        return ConsultingFileService.createContractFile(fileUploadWrapper.targetObjectId, fileUploadWrapper.fileTypeId, fileUploadWrapper.file, fileUploadWrapper.abortController)
    }

    update ({ typeId, consultantId, fileName }) {
        const variables = {
            id: this.id,
        }

        if (typeId) variables.typeId = typeId
        if (consultantId) variables.consultantId = consultantId
        if (fileName) variables.fileName = fileName

        return ConsultingFileService.updateContractFile(variables)
    }

    delete () {
        return ConsultingFileService.deleteContractFile(this.id)
    }
}

export class ContractFileType extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            ['status', 'contractFileTypeStatus'], // Gets returned as contractFileTypeStatus on the ConsultingFile union
            'key',
            'name',
        ], data)
    }
}
