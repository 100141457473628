<template>
    <form
        data-test="form:correspondence-item"
        class="row q-col-gutter-sm"
        @submit.prevent="onFormSubmit"
        @keyup.esc="cancel"
    >
        <!-- eslint-disable vue/no-v-html -->
        <div v-if="formErrorMessage" class="col-xs-12">
            <info-box
                type="negative"
                data-test="error:global-message"
                v-html="formErrorMessage"
            />
        </div>
        <!-- eslint-enable -->

        <div class="col-xs-12 col-md-8">
            <form-builder item-key="name" />
        </div>
        <div class="col-xs-12 col-md-4">
            <form-builder item-key="languageId" />
        </div>
        <div v-if="correspondenceItem?.type === DocumentType.LETTER" class="col-xs-12 col-md-4">
            <form-builder item-key="confidentialityNotice" />
        </div>
    </form>
</template>

<script>
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import CORRESPONDENCE_ITEM_FIELDS from '@/forms/correspondence-item.fields'
import { DocumentType } from '@/enums/graphql'

export default {
    name: 'CorrespondenceItemForm',
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: CORRESPONDENCE_ITEM_FIELDS,
        fields () {
            const fields = [
                'name',
                'languageId',
            ]
            if (this.correspondenceItem?.type === DocumentType.LETTER) fields.push('confidentialityNotice')

            return fields
        },
    },
    props: {
        correspondenceItem: {
            type: Object,
        },
    },
    data () {
        const data = {
            DocumentType,
            formData: {},
        }

        // Prefill with the first defined correspondence language in the tenantSettings.
        if (this.$store.state.user.tenantSettings.correspondenceLanguages.length) {
            data.formData.languageId = this.$store.state.user.tenantSettings.correspondenceLanguages[0].id
        }

        if (this.correspondenceItem) {
            if (this.correspondenceItem.name) data.formData.name = this.correspondenceItem.name
            if (this.correspondenceItem.language) data.formData.languageId = this.correspondenceItem.language.id
            if (this.correspondenceItem.confidentiality_notice) data.formData.confidentialityNotice = this.correspondenceItem.confidentiality_notice
        }

        return data
    },
}
</script>
