import gql from 'graphql-tag'

export const REGISTRATION_VALIDATE_2FA_BACKUP_CODE_MUTATION = gql`
    mutation registrationValidate2FABackupCode($token: String!, $two_fa_backup_code: String!) {
        registrationValidate2FABackupCode(
            token: $token,
            two_fa_backup_code: $two_fa_backup_code
        ) {
            user {
                id,
                email,
                status
            },
            jwtToken {
                token,
                type,
                expires_in
            }
        }
    }
`
