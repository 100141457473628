import DefaultLayout from '@/layouts/Default.vue'
import AccountDashboard from '@/views/account/AccountDashboard'
import AccountSecurityPassword from '@/views/account/AccountSecurityPassword'
import AccountSecurityReset2fa from '@/views/account/AccountSecurityReset2fa'
import ReleaseNotes from '@/views/account/ReleaseNotes'

export default [
    {
        path: '/account',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'account-dashboard',
            component: AccountDashboard,
            meta: {
                appSubSection: 'account',
            },
        }],
    },
    {
        path: '/account/security/password',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'account-security-password',
            component: AccountSecurityPassword,
            meta: {
                appSubSection: 'account',
                navGroup: 'security',
            },
        }],
    },
    {
        path: '/account/security/reset-2fa',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'account-security-reset-2fa',
            component: AccountSecurityReset2fa,
            meta: {
                appSubSection: 'account',
                navGroup: 'security',
            },
        }],
    },
    {
        path: '/account/release-notes',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'release-notes',
            component: ReleaseNotes,
            meta: {
                appSubSection: 'account',
            },
        }],
    },
]
