<template>
    <div>
        <q-dialog ref="removeItemDialog" @show="markItem" @hide="unMarkItem">
            <q-card>
                <q-card-section class="row items-center">
                    <i18n :path="(itemLabel) ? `forms.delete-${item.type}--confirm-with-name--temp-component-interpolation-count` : `forms.delete-${item.type}--confirm--temp-component-interpolation-count`" tag="p">
                        <template v-slot:name><b>{{ itemLabel }}</b></template>
                    </i18n>
                </q-card-section>

                <q-card-actions align="right">
                    <base-button
                        v-close-popup
                        :label="$t('common.term.cancel')"
                        flat
                        primary-button
                    />
                    <base-button
                        :label="$t('common.term.confirm')"
                        primary-button
                        @click="onClick"
                    />
                </q-card-actions>
            </q-card>
        </q-dialog>
        <base-button
            data-test="btn:product-template-builder-item-remove"
            icon="mib-bin"
            size="xs"
            color="secondary"
            round
            flat
            @click="$refs.removeItemDialog.show()"
        />
    </div>
</template>

<script>
export default {
    name: 'ProductTemplateBuilderItemRemove',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        itemLabel () {
            let label = ''
            switch (this.item.type) {
                case 'section':
                    label = this.item.label[localStorage.getItem('locale')]
                    break
                case 'field':
                    label = this.item.label
                    break
            }
            return label
        },
    },
    methods: {
        onClick () {
            this.$emit('removeItem', this.item)
        },
        markItem () {
            this.item.removeFlag = true
        },
        unMarkItem () {
            this.item.removeFlag = false
        },
    },
}
</script>
