<template>
    <page-loading-indicator v-if="!correspondenceSalutation" :number-of-breadcrumb-elements="3" actions />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.term.correspondence', 1)" :to="{ name: 'admin-correspondence' }" />
                <q-breadcrumbs-el :label="$tc('common.correspondence.salutation.salutation', 2)" :to="{ name: 'admin-correspondence-salutation-list' }" />
                <q-breadcrumbs-el :label="$tc('views.admin.correspondence.salutation.edit-salutation', 1)" :to="{ name: 'admin-correspondence-salutation-update', params: { id: correspondenceSalutation.id } }" />
            </template>

            <h1 v-html="stringWithPlaceholderTags(correspondenceSalutation.salutation.contentHTML)">{{ $tc('views.admin.correspondence.salutation.edit-salutation', 1) }}</h1>

            <template v-slot:actions>
                <actions
                    v-if="correspondenceSalutation.actions"
                    :actions="correspondenceSalutation.actions"
                    @click="handleActions"
                />

                <!-- Set Default CorrespondenceSalutation -->
                <form-dialog
                    ref="setDefaultDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleSetDefault"
                >
                    <template v-slot:title>{{ $tc('views.admin.correspondence.salutation.set-as-default-salutation', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.correspondence.salutation.set-as-default-salutation--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:salutation><div class="preview-item-wrapper" v-html="stringWithPlaceholderTags(correspondenceSalutation.salutation.contentHTML)">{{ correspondenceSalutation.salutation }}</div></template>
                        </i18n>
                        <ul>
                            <li>{{ $tc('common.contact.contact-type.contact-type', 1) }}: <b>{{ $tc(`common.contact.contact-type.contact-type--${correspondenceSalutation.contact_type.toLowerCase()}`, 1) }}</b><b v-if="correspondenceSalutation.gender"> ({{ $tc(`common.contact.gender-${correspondenceSalutation.gender.toLowerCase()}`, 1) }})</b></li>
                            <li>{{ $t('common.contact.formality.formality') }}: <b>{{ $tc(`common.contact.formality.formality--${correspondenceSalutation.formality.toLowerCase()}`, 1) }}</b></li>
                            <li>{{ $tc('common.contact.correspondence-language', 1) }}: <b>{{ correspondenceSalutation.language.name }}</b></li>
                        </ul>
                    </template>
                </form-dialog>

                <!-- Delete CorrespondenceSalutation -->
                <form-dialog
                    ref="deleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleDelete"
                >
                    <template v-slot:title>{{ $tc('views.admin.correspondence.salutation.delete-salutation', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.correspondence.salutation.delete-salutation--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:salutation><div class="preview-item-wrapper" v-html="stringWithPlaceholderTags(correspondenceSalutation.salutation.contentHTML)">{{ correspondenceSalutation.salutation }}</div></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <div class="row q-col-gutter-y-md">
            <div class="col-xs-12">
                <base-badge v-if="correspondenceSalutation.is_default" :label="$tc('views.admin.correspondence.salutation.default-salutation', 1)" />
                <ul>
                    <li>{{ $tc('common.contact.contact-type.contact-type', 1) }}: <b>{{ $tc(`common.contact.contact-type.contact-type--${correspondenceSalutation.contact_type.toLowerCase()}`, 1) }}</b><b v-if="correspondenceSalutation.gender"> ({{ $tc(`common.contact.gender-${correspondenceSalutation.gender.toLowerCase()}`, 1) }})</b></li>
                    <li>{{ $t('common.contact.formality.formality') }}: <b>{{ $tc(`common.contact.formality.formality--${correspondenceSalutation.formality.toLowerCase()}`, 1) }}</b></li>
                    <li>{{ $tc('common.contact.correspondence-language', 1) }}: <b>{{ correspondenceSalutation.language.name }}</b></li>
                </ul>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-6 q-gutter-y-sm">
                <correspondence-salutation-update-form ref="correspondenceSalutationUpdateForm" :correspondence-salutation="correspondenceSalutation" @submit="onSubmit" />
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'
import { stringWithPlaceholderTags } from '@/helpers'
import CorrespondenceSalutationUpdateForm from '@/components/correspondence/CorrespondenceSalutationUpdateForm'
import { CorrespondenceSalutation } from '@/models/correspondenceSalutation'
import BaseBadge from '@/components/BaseBadge'

export default {
    name: 'CorrespondenceSalutationUpdate',
    meta () {
        return {
            title: this.$tc('views.admin.correspondence.salutation.edit-salutation', 1),
        }
    },
    components: {
        Actions,
        FormDialog,
        CorrespondenceSalutationUpdateForm,
        BaseBadge,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            correspondenceSalutation: null,
        }
    },
    created () {
        CorrespondenceSalutation.objects.get(this.id).then(correspondenceSalutation => {
            this.correspondenceSalutation = correspondenceSalutation
        })
    },
    methods: {
        onSubmit ({ salutation }) {
            this.correspondenceSalutation.update({ salutation })
                .then(() => {
                    this.$router.replace({ name: 'admin-correspondence-salutation-list' }) // TODO: redirect to list or stay on update?
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.correspondence.correspondence-salutation-saved-success', 1),
                    })
                })
                .catch((error) => {
                    this.$refs.correspondenceSalutationUpdateForm.showFormErrorMessage(error)
                    this.$refs.correspondenceSalutationUpdateForm.resetFormSubmitStatus()
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$tc('common.notifications.correspondence.correspondence-salutation-saved-error', 1),
                    })
                })
        },
        handleActions (action) {
            switch (action.key) {
                case CorrespondenceSalutation.action.SET_DEFAULT:
                    this.$refs.setDefaultDialog.open()
                    break
                case CorrespondenceSalutation.action.DELETE:
                    this.$refs.deleteDialog.open()
                    break
            }
        },
        handleSetDefault () {
            this.correspondenceSalutation.setDefault()
                .then(() => {
                    this.$refs.setDefaultDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.correspondence.correspondence-salutation-set-default-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.setDefaultDialog.showFormErrorMessage(error)
                    this.$refs.setDefaultDialog.resetFormSubmitStatus()
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$tc('common.notifications.correspondence.correspondence-salutation-set-default-error', 1),
                    })
                })
        },
        handleDelete () {
            this.correspondenceSalutation.delete()
                .then(() => {
                    this.$refs.deleteDialog.close()
                    this.$router.replace({ name: 'admin-correspondence-salutation-list' })
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.correspondence.correspondence-salutation-deleted-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.deleteDialog.showFormErrorMessage(error)
                    this.$refs.deleteDialog.resetFormSubmitStatus()
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$tc('common.notifications.correspondence.correspondence-salutation-deleted-error', 1),
                    })
                })
        },
        stringWithPlaceholderTags (...args) {
            return stringWithPlaceholderTags(...args)
        },
    },
}
</script>
