import gql from 'graphql-tag'

export const COMMISSION_LIST_QUERY = gql`
    query commissionList(
        $id: ID!
    ) {
        commissionList(
            id: $id
        ) {
            id
            productProvider {
                id
                name
                display_name
            }
            source
            type
            status
            file {
                id
                name
                size
                mime_type
                created_at
                updated_at
            }
            filename
            excelMapping {
                id
            }
            file_import_identification_number
            exchange_date_from
            exchange_date_to
            commission_total
            total_amount_of_imported_commission
            total_amount_of_imported_commission_grouped_by_commission_type
            total_number_of_commission_entries
            total_number_of_imported_commission_entries
            total_number_of_commission_list_import_errors
            created_at
            updated_at
            posted_at
        }
    }
`
