<template>
    <div class="markup-table-tr" role="row">
        <slot name="default">
            <markup-table-cell
                v-for="(column, index) in columns"
                :key="`data-column-${column.name}-${index}`"
                :column="column"
                :row="row"
            >
                <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotProps">
                    <slot :name="name" v-bind="slotProps" />
                </template>
            </markup-table-cell>
        </slot>
    </div>
</template>

<script>
import MarkupTableCell from '@/components/MarkupTableCell.vue'

export default {
    name: 'MarkupTableRow',
    components: {
        MarkupTableCell,
    },
    props: {
        columns: {
            type: Array,
            default: () => {
                return []
            },
        },
        row: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
}
</script>
