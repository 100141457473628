import DefaultLayout from '@/layouts/Default'
import ApplicationCreate from '@/views/application/ApplicationCreate'
import ApplicationDetail from '@/views/application/ApplicationDetail'
import ApplicationUpdate from '@/views/application/ApplicationUpdate'
import ApplicationApprove from '@/views/application/ApplicationApprove'

export default [
    {
        path: '/contacts/:contactId(\\d+)/applications/create/:sourceContractId(\\d+)?',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'application-create',
            component: ApplicationCreate,
            props: true,
            meta: {
                navGroup: 'contacts',
            },
        }],
    },
    {
        path: '/contacts/:contactId(\\d+)/applications/:id(\\d+)/:tab(documents|comments)?',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'application-detail',
            component: ApplicationDetail,
            props: true,
            meta: { // TODO improvement @TFU: Allow/disallow access to this route based on tab-param values.
                navGroup: 'contacts',
            },
        }],
    },
    {
        path: '/contacts/:contactId(\\d+)/applications/:id(\\d+)/update',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'application-update',
            component: ApplicationUpdate,
            props: true,
            meta: {
                navGroup: 'contacts',
            },
        }],
    },
    {
        path: '/contacts/:contactId(\\d+)/applications/:id(\\d+)/approve',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'application-approve',
            component: ApplicationApprove,
            props: true,
            meta: {
                navGroup: 'contacts',
            },
        }],
    },
]
