import { mapSubject } from '@/helpers/acl'

export class DocumentTemplatePolicy {
    beforeAll (verb, user, subject) {
        if (typeof this[verb] === 'undefined') {
            return user && user.aclPermissions.includes(`${mapSubject(subject)}:${verb}`)
        }
    }

    update (user, documentTemplate) {
        return [user.consultant.id, user.consultant.consultingCompany.id].includes(documentTemplate.owner.id)
    }
}
