import { Model } from '@/models'
import { FileType } from '@/enums/graphql'
import { formatDate } from '@/helpers/date'
import { getFileDownloadURL, humanStorageSize } from '@/helpers/file'

export class File extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'name',
            'size',
            'mime_type',
            'created_at',
            'updated_at',
        ], data)
    }

    get formattedFileSize () {
        return humanStorageSize(this.size)
    }

    get formattedFileMimeType () {
        switch(this.mime_type) {
            case 'application/pdf':
                return FileType.PDF
            case 'application/vnd.ms-excel':
                return FileType.EXCEL
            default:
                return ''
        }
    }

    get formattedCreatedAt () {
        return formatDate(this.created_at)
    }

    get fileDownloadUrl() {
        return getFileDownloadURL(this.id)
    }

    download () {
        window.location.assign(this.fileDownloadUrl)
    }

    async downloadAwait () {
        await window.location.assign(this.fileDownloadUrl)
    }
}
