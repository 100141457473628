<template>
    <node-view-wrapper as="span" data-drag-handle>
        <span
            class="smart-object"
            :class="{ selected: selected }"
            data-smart-object="date"
            :data-id="node.attrs.id"
        >
            <q-icon name="mib-calendar" />
            {{ $tc('common.correspondence.smart-object.' + normalizeTranslationId(node.attrs.id), 1) }}
            <span v-if="formattedDateString">: {{ formattedDateString }}</span>
            <span v-else>({{ node.attrs.format }})</span>
            <q-btn
                ref="configButton"
                color="primary"
                size="sm"
                flat
                round
                icon="mib-cog-double-3"
            >
                <q-tooltip
                    :delay="2000"
                    anchor="center right"
                    self="center left"
                    :offset="[0, 20]"
                >{{ $t('common.term.configure') }}</q-tooltip>
            </q-btn>
        </span>
        <q-popup-proxy
            ref="configProxy"
            :target="$refs.configButton"
            anchor="center right"
            self="center left"
            @before-show="onBeforeConfigShow"
        >
            <q-card>
                <q-card-section class="q-pb-none">
                    <calendar-input
                        v-if="node.attrs.id === 'general.date'"
                        v-model="date"
                        :label="$tc('common.term.date', 1)"
                        class="q-mb-sm"
                    />
                    <base-select-filter v-model="format" :label="$tc('common.term.format', 1)" :options="formatOptions" />
                </q-card-section>
                <q-card-actions align="right" class="q-pt-sm q-pr-md q-pb-md q-pl-md">
                    <base-button
                        :label="$t('common.term.cancel')"
                        primary-button
                        flat
                        @click="closeConfig"
                    />
                    <base-button
                        :label="$t('common.term.save-and-close')"
                        primary-button
                        @click="saveAndCloseConfig"
                    />
                </q-card-actions>
            </q-card>
        </q-popup-proxy>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import { normalizeTranslationId } from '@/helpers'
import CalendarInput from '@/components/form/CalendarInput'
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { getDateFnsLocale } from '@/helpers/date'
import { format, parseISO } from 'date-fns'

export default {
    name: 'SmartObjectDateNodeView',
    components: {
        NodeViewWrapper,
        CalendarInput,
        BaseSelectFilter,
    },
    props: nodeViewProps,
    data () {
        const formatOptionStrings = ['d. MMMM yyyy', 'dd.MM.yyyy']
        const formatOptions = []
        formatOptionStrings.forEach(formatString => {
            const dateString = format(Date.now(), formatString, { locale: getDateFnsLocale(this.$i18n.locale) })
            formatOptions.push({
                label: `${formatString} (${dateString})`,
                value: formatString,
            })
        })

        return {
            date: '',
            format: null,
            formatOptions,
        }
    },
    computed: {
        formattedDateString () {
            if (this.node.attrs.id === 'general.date' && this.node.attrs.date) {
                return format(parseISO(this.node.attrs.date), this.node.attrs.format, { locale: getDateFnsLocale(this.$i18n.locale) })
            } else {
                return ''
            }
        },
    },
    methods: {
        onBeforeConfigShow () {
            this.date = this.node.attrs.date ?? ''
            this.format = this.node.attrs.format || null
        },
        saveAndCloseConfig () {
            this.updateAttributes({
                date: this.date,
                format: this.format,
            })
            this.closeConfig()
        },
        closeConfig () {
            this.$refs.configProxy.hide()
        },
        normalizeTranslationId (...args) {
            return normalizeTranslationId(...args)
        },
    },
}
</script>
