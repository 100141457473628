<template>
    <q-btn
        round
        unelevated
        size="1rem"
        data-test="btn:user-menu"
        :aria-label="$t('common.a11y.toggle-user-menu')"
    >
        <q-avatar
            :icon="($store.state.user && $store.state.user.consultant) ? `mib-single-${getContactGender($store.state.user.consultant, { useAlternativeWording: true })}` : 'mib-single-neutral'"
            size="2rem"
        />

        <q-menu max-width="30ch" transition-show="jump-down" transition-hide="jump-up">
            <q-list role="navigation">
                <q-item
                    v-ripple
                    clickable
                    :to="{ name: 'account-dashboard' }"
                    exact
                >
                    <q-item-section avatar>
                        <q-icon name="mi-settings-user" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>{{ $tc('common.term.user-account--my-user-account', 1) }}</q-item-label>
                        <q-item-label caption class="user-name ellipsis">{{ getUserName($store.state.user) }}</q-item-label>
                    </q-item-section>
                </q-item>

                <q-separator v-if="$can('hasSystemSettingsAccess')" />

                <q-item
                    v-if="$can('hasSystemSettingsAccess')"
                    v-ripple
                    clickable
                    :to="{ name: 'admin-dashboard' }"
                    exact
                >
                    <q-item-section avatar>
                        <q-icon name="mi-desktop-monitor-settings" />
                    </q-item-section>
                    <q-item-section class="text-no-wrap">{{ $tc('common.term.system-setting', 2) }}</q-item-section>
                </q-item>

                <q-separator />

                <q-item
                    v-ripple
                    clickable
                    :to="{ name: 'user-manual' }"
                    exact
                >
                    <q-item-section avatar>
                        <q-icon name="mi-book-flip-page" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>{{ $tc('common.user-manual.user-manual', 1) }}</q-item-label>
                    </q-item-section>
                </q-item>

                <template v-if="$store.state.user.tenantSettings.systemLanguages.length > 1">
                    <q-separator />

                    <q-item clickable>
                        <q-item-section avatar>
                            <q-icon name="mi-translate" />
                        </q-item-section>
                        <q-item-section>{{ $t('common.term.language--change-language') }}</q-item-section>

                        <q-menu anchor="top right" self="top left" auto-close>
                            <q-list>
                                <q-item
                                    v-for="language in $store.state.user.tenantSettings.systemLanguages"
                                    :key="language.id"
                                    clickable
                                    dense
                                    :class="{ 'active': (language.id === $i18n.locale) }"
                                    @click="loadLocale(language.id)"
                                >
                                    <q-item-section>{{ language.name }}</q-item-section><!-- TODO @TFU: As the name gets loaded with the tenantSettings, it will not switch to the newly selected language. A solution would require to re-fetch the tenantSettings. -->
                                </q-item>
                            </q-list>
                        </q-menu>
                    </q-item>
                </template>

                <q-separator />

                <q-item
                    ref="logout"
                    clickable
                    exact
                    data-test="link:user-menu-logout"
                    @click="triggerLogout"
                >
                    <q-item-section avatar>
                        <q-icon name="mi-logout-1" />
                    </q-item-section>
                    <q-item-section>
                        {{ $t('common.auth.logout-action') }}
                    </q-item-section>
                </q-item>
            </q-list>
        </q-menu>
    </q-btn>
</template>

<script>
import { loadLocaleAsync } from '@/i18n'
import { contactMixin } from '@/mixins/contactMixin'
import { getContactGender } from '@/helpers/contact'
import { getUserName } from '@/helpers/user'
import { apolloClient } from '@/apollo/client'
import { onLogout } from '@/apollo'

import { AuthService } from '@/services'

export default {
    name: 'UserMenu',
    mixins: [contactMixin],
    methods: {
        async loadLocale (locale) {
            await loadLocaleAsync(locale)
        },
        getUserName (...args) {
            return getUserName(...args)
        },
        getContactGender (...args) {
            return getContactGender(...args)
        },
        triggerLogout () {
            AuthService.logout()
                .finally(async () => {
                    await onLogout(apolloClient)
                    window.location.assign(this.$router.resolve({ name: 'auth-logout', params: { reason: 'logged-out' } }).href)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .active {
    color: var(--q-color-primary);
}
</style>
