<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <template v-if="currentPage">
                    <q-breadcrumbs-el :label="currentChapter.label[$i18n.locale]" :to="{ name: 'user-manual', params: { chapter: currentChapter.key }}" />
                    <q-breadcrumbs-el v-if="currentPage.key !== 'index'" :label="currentPage[$i18n.locale].attributes.label" :to="{ name: 'user-manual', params: { chapter: currentChapter.key, page: currentPage.key }}" />
                </template>
            </template>

            <h1>
                {{ (currentPage) ? currentPage[$i18n.locale].attributes.label : $tc('common.user-manual.user-manual', 1) }}
                <template v-if="currentPage && currentPage[$i18n.locale].attributes.tags">
                    <base-badge
                        v-for="tag in currentPage[$i18n.locale].attributes.tags.split(' ')"
                        :key="tag"
                        :class="`tag-${tag}`"
                        align="middle"
                    >
                        {{ $t(`common.user-manual.tags.${tag}`) }}
                    </base-badge>
                </template>
            </h1>
        </page-header>

        <!-- Content from markdown files -->
        <div v-if="currentPage" class="row q-col-gutter-md md-content-wrapper" @click="handleAnchorNavigation">
            <div class="col-xs-12 md-content-inner-wrapper" v-html="currentPage[$i18n.locale].html" />
        </div>

        <!-- User manual "index" page -->
        <!-- TODO: Add check for glossary URL -->
        <div v-else class="row q-col-gutter-md md-content-wrapper">
            <div class="col-xs-12 md-content-inner-wrapper">
                <div class="warning pre-wrap">{{ $tc('common.user-manual.intro', 1) }}</div>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import userManual from '#/user-manual'
import { markdownContentMixin } from '@/mixins/markdownContentMixin'
import BaseBadge from '@/components/BaseBadge'

export default {
    name: 'UserManual',
    components: { BaseBadge },
    mixins: [markdownContentMixin],
    meta () {
        return {
            title: this.getTitle(),
        }
    },
    props: {
        chapter: {
            type: String,
        },
        page: {
            type: String,
            default: 'index',
        },
    },
    data () {
        return {
            userManual,
        }
    },
    computed: {
        currentChapter () {
            return this.userManual.chapters.find(chapter => chapter.key === this.chapter)
        },
        currentPage () {
            return (this.currentChapter) ? this.currentChapter.pages.find(page => page.key === this.page) : null
        },
    },
    watch: {
        '$route' () {
            this.checkRedirectToFirstChildPage()
        },
    },
    created () {
        this.checkRedirectToFirstChildPage()
    },
    methods: {
        getTitle () {
            if (this.currentPage) {
                const title = []
                title.push(this.currentChapter.label[this.$i18n.locale])
                if (this.currentPage.key !== 'index') {
                    title.push(this.currentPage[this.$i18n.locale].attributes.label)
                }
                return `${this.$tc('common.user-manual.user-manual', 1)}: ${title.join(' / ')}`
            } else {
                return this.$tc('common.user-manual.user-manual', 1)
            }
        },
        checkRedirectToFirstChildPage () {
            if (this.currentChapter && !this.currentPage) {
                const nextPage = this.currentChapter.pages[0]
                if (nextPage) {
                    this.$router.replace({ name: 'user-manual', params: { chapter: this.currentChapter.key, page: nextPage.key } })
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped="">
.tag {
    &-administrator {
        background-color: var(--q-color-blue-grey-4);
        color: var(--color-text-secondary);
    }
}

// Tags: ("name", background-color, [text-color])
$tags: (
        // Roles
        ("administrator", var(--q-color-secondary), var(--color-text-inverted)),
        ("consultingCompanyAdministrator", var(--q-color-secondary), var(--color-text-inverted)),
);

@each $tag, $backgroundColor, $color in $tags {
    .tag-#{$tag} {
        background-color: $backgroundColor;

        @if ($color) {
            color: $color;
        }
    }
}
</style>
