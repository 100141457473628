<template>
    <page-loading-indicator
        v-if="!state.account"
        :number-of-breadcrumb-elements="2"
        actions
        :number-of-next-actions="1"
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.accounting.account.account', 2)" :to="{ name: 'accounting-account-list' }" />
                <q-breadcrumbs-el :label="state.account.name" :to="{ name: 'accounting-account-update', params: { id: id } }" data-test="breadcrumb:account-detail">
                    <status-badge :status="state.account.status" />
                </q-breadcrumbs-el>
            </template>

            <h1>{{ state.account.number }} – {{ state.account.name }} <span class="subtitle">({{ state.account.formattedBalance }})</span></h1>
            <template v-slot:actions>
                <!-- TODO @TFU: Disable 'delete' option if `account.has_postings` and/or `account.is_assigned` (and show according tooltip). [Enhance `actions` component to provide this possibility.] -->
                <actions
                    :actions="state.account.actions"
                    translation-base-key="common.actions.accounting"
                    @click="handleActions"
                />

                <!-- Activate Account -->
                <form-dialog
                    ref="activateDialog"
                    @submit="handleActivate"
                >
                    <template v-slot:title>{{ $tc('common.actions.accounting.account.activate', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.accounting.account.activate-account--confirm--temp-component-interpolation-count" tag="p">
                            <template v-if="state.account" v-slot:name><b>{{ state.account.number }} – {{ state.account.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Deactivate Account -->
                <form-dialog
                    ref="deactivateDialog"
                    @submit="handleDeactivate"
                >
                    <template v-slot:title>{{ $tc('common.actions.accounting.account.deactivate', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.accounting.account.deactivate-account--confirm--temp-component-interpolation-count" tag="p">
                            <template v-if="state.account" v-slot:name><b>{{ state.account.number }} – {{ state.account.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Delete Account -->
                <form-dialog
                    ref="deleteDialog"
                    double-confirm
                    @submit="handleDelete"
                >
                    <template v-slot:title>{{ $tc('common.actions.accounting.account.delete', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.accounting.account.delete-account--confirm--temp-component-interpolation-count" tag="p">
                            <template v-if="state.account" v-slot:name><b>{{ state.account.number }} – {{ state.account.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <div class="row q-col-gutter-md">
            <div class="col-xs-12 col-lg-10 col-xl-8">
                <info-box v-if="state.account.has_postings" :inline-actions="$q.screen.gt.sm" data-test="info:related-postings">
                    <p>{{ $tc('views.accounting.account.postings-related-to-account', 1) }}</p>

                    <template v-slot:action>
                        <!-- TODO: Link to filtered posting list view. -->
                        <base-button
                            :label="$tc('common.accounting.posting.show-posting', 2)"
                            :to="{ name: 'accounting-posting-list' }"
                            primary-button
                            flat
                            data-test="link:show-postings"
                        />
                    </template>
                </info-box>

                <!-- TODO: Add proper v-if condition -->
                <!-- TODO: Add correct translation ids -->
                <!-- TODO: Check if different entities need different handling/translations -->
                <!-- TODO: How to handle this info? Should it be linked? (What about access permissions for the "targetObject"?) -->
                <!--
                <info-box>
                    <i18n path="views.accounting.account.account-assigned-as-to">
                        <template v-slot:assignedTo><b>TODO: Add correct assignedTo</b></template>
                        <template v-slot:assignedAs>{{ $t(`common.accounting.account.type.${toKebabCase('TODO ADD CORRECT ASSIGNED_AS'.toLowerCase())}`) }}</template>
                    </i18n>
                </info-box>
                -->

                <account-form
                    ref="accountForm"
                    :account="state.account"
                    @cancel="onCancel"
                    @submit="onSubmit"
                />
            </div>

            <div class="col-xs-12">
                <p>
                    <small>
                        <base-button
                            show-as-link
                            @click="$refs.activityLogOverlay.open({ filterSubjects: [{ id: id, type: ActivityLogSubjectType.ACCOUNT }] })"
                        >{{ $tc('common.term.created-on--date-time', 1, { date: state.account.formattedCreatedAtDate, time: state.account.formattedCreatedAtTime }) }}</base-button>
                    </small>
                </p>
            </div>
        </div>

        <!-- Activity log -->
        <activity-log-overlay ref="activityLogOverlay" />
    </page-wrapper>
</template>

<script>
import { kebabCase } from 'lodash'
import { Account } from '@/models/account'
import { ActivityLogSubjectType } from '@/enums/graphql'
import AccountForm from '@/components/accounting/AccountForm.vue'
import Actions from '@/components/Actions'
import FormDialog from '@/libs/form/components/FormDialog'
import StatusBadge from '@/components/StatusBadge.vue'
import ActivityLogOverlay from '@/components/ActivityLogOverlay'
import { useQuasar } from '@/composables/quasar'
import { onBeforeMount, reactive, ref } from 'vue'
import { useRouter } from '@/composables/router'
import { useI18n } from '@/composables/i18n'

export default {
    name: 'AccountUpdate',
    meta () {
        return {
            title: this.$tc('views.accounting.account.update-account', 1),
        }
    },
    components: {
        AccountForm,
        Actions,
        FormDialog,
        StatusBadge,
        ActivityLogOverlay,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup (props) {
        // Composables
        const $q = useQuasar()
        const router = useRouter()
        const { tc } = useI18n()

        // Data
        const state = reactive({
            account: null,
        })

        // Template refs
        const accountForm = ref(null)
        const activateDialog = ref(null)
        const deactivateDialog = ref(null)
        const deleteDialog = ref(null)

        function fetchObject () {
            Account.service.get(props.id).then(account => {
                state.account = account
            }).catch(() => {
                // Forward to `forbidden` error view.
                router.replace({ name: '403' }) // TODO @TFU: Differentiate between 403 and 404.
            })
        }
        function onCancel () {
            router.push({ name: 'accounting-account-list' })
        }
        function onSubmit (formData) {
            const account = { ...formData, number: parseInt(formData.number) }
            state.account.update(account)
                .then(() => {
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.accounting.account-updated-success', 1),
                    })
                    router.replace({ name: 'accounting-account-list' })
                })
                .catch(error => {
                    accountForm.value.formComponent.formBuilder.showFormErrorMessage(error)
                    accountForm.value.formComponent.formBuilder.resetFormSubmitStatus()
                })
        }
        function handleActions (action) {
            switch (action.key) {
                case Account.action.ACTIVATE:
                    activateDialog.value.open()
                    break
                case Account.action.DEACTIVATE:
                    deactivateDialog.value.open()
                    break
                case Account.action.DELETE:
                    deleteDialog.value.open()
                    break
            }
        }
        function handleActivate () {
            state.account.activate()
                .then(() => {
                    activateDialog.value.close()
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.accounting.account-activated-success', 1),
                    })
                })
                .catch(error => {
                    activateDialog.value.showFormErrorMessage(error)
                    activateDialog.value.resetFormSubmitStatus()
                })
        }
        function handleDeactivate () {
            state.account.deactivate()
                .then(() => {
                    deactivateDialog.value.close()
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.accounting.account-deactivated-success', 1),
                    })
                })
                .catch(error => {
                    deactivateDialog.value.showFormErrorMessage(error)
                    deactivateDialog.value.resetFormSubmitStatus()
                })
        }
        function handleDelete () {
            state.account.delete()
                .then(() => {
                    deleteDialog.value.close()
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.accounting.account-deleted-success', 1),
                    })
                    router.replace({ name: 'accounting-account-list' })
                })
                .catch(error => {
                    deleteDialog.value.showFormErrorMessage(error)
                    deleteDialog.value.resetFormSubmitStatus()
                })
        }

        // Lifecycle hooks
        onBeforeMount(() => {
            fetchObject()
        })

        return {
            // Static
            ActivityLogSubjectType,

            // Data
            state,

            // Template refs
            accountForm,
            activateDialog,
            deactivateDialog,
            deleteDialog,

            // Methods
            onCancel,
            onSubmit,
            handleActions,
            handleActivate,
            handleDeactivate,
            handleDelete,
            kebabCase,
        }
    },
}
</script>
