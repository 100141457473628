<template>
    <page-wrapper>
        <page-header :progress-bar="isQueuedOrProcessing" progress-bar-indeterminate>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.accounting.account-statement.account-statement', 2)" :to="{ name: 'accounting-account-statements' }" />
            </template>

            <h1>{{ $tc('views.accounting.account-statement.account-statement', 2) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-12">
                <base-table
                    ref="table"
                    :columns="columns"
                    :fetch-objects-fn="AccountStatementReport.objects.all"
                    user-settings-base-path="AccountStatementReportList"
                    pagination-sort-by-default-key="created_at"
                    pagination-initial-order-direction-descending
                    selection="multiple"
                    :selected.sync="selectedItems"
                    :selection-row-condition="selectionRowCondition"
                    :show-filter="false"
                    enable-visible-columns
                    @row-dblclick="(evt, row) => $router.push({ name: 'accounting-account-statement-detail', params: { id: row.id } })"
                >
                    <template v-slot:selectionRowConditionInfo>
                        <q-checkbox
                            :value="false"
                            class="not-selectable"
                            color="secondary-light"
                            keep-color
                            dense
                            disable
                        />
                    </template>

                    <template v-slot:header-cell-owner="slotProps">
                        <q-th :props="slotProps">
                            {{ $tc('views.accounting.account-statement.column-title--created-by', 1) }}
                            <info-icon :text="$t('views.accounting.account-statement.created-by--info-text')" />
                        </q-th>
                    </template>

                    <template v-slot:body-cell-accounts="slotProps">
                        <q-td :props="slotProps">
                            <template v-if="slotProps.row.formattedAccountList.teaser">
                                {{ slotProps.row.formattedAccountList.teaser.full }}
                                <info-icon :text="slotProps.row.formattedAccountList.accounts" />
                            </template>
                            <template v-else>{{ slotProps.row.formattedAccountList.accounts }}</template>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-status="slotProps">
                        <q-td :props="slotProps" auto-width>
                            <!-- TODO @TFU: Provide list with all `currentQueueItem.status_message.code` and their meaning. -->
                            <!-- TODO @MTR: Translations for error messages. -->
                            <status-badge
                                :status="slotProps.row.currentQueueItem.status"
                                default-translation-base-path="common.status.queue-item-status"
                                :loading="[QueueItemStatus.QUEUED, QueueItemStatus.PROCESSING].includes(slotProps.row.currentQueueItem.status)"
                                :info-text="slotProps.row.currentQueueItem.status === QueueItemStatus.FAILED ? slotProps.row.currentQueueItem.status_message.message : null"
                            />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-actions="slotProps">
                        <q-td :props="slotProps" auto-width>
                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-view-1"
                                :to="{ name: 'accounting-account-statement-detail', params: { id: slotProps.row.id } }"
                            >
                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.term.show-details', 1) }}</q-tooltip>
                            </q-btn>
                        </q-td>
                    </template>
                </base-table>

                <!-- Cancel Creation Account Statement Report -->
                <form-dialog
                    ref="cancelCreationDialog"
                    dont-close-on-confirm
                    @confirm="handleCancelCreation"
                >
                    <template v-slot:title>{{ $tc('common.actions.accounting.account-statement-report.cancel-creation', eligibleItemsForCancelCreation.length) }}</template>
                    <template v-slot:default>
                        <info-box v-if="eligibleItemsForCancelCreation.length !== selectedItems.length">{{ $tc('views.accounting.account-statement.cancel-creation--confirm--n-of-x--info', eligibleItemsForCancelCreation.length, { x: selectedItems.length }) }}</info-box>
                        <p>{{ $tc(`views.accounting.account-statement.cancel-creation--confirm${eligibleItemsForCancelCreation.length !== selectedItems.length ? '--n-of-x' : '' }`, eligibleItemsForCancelCreation.length) }}</p>
                    </template>
                </form-dialog>

                <!-- Delete Account Statement Report -->
                <form-dialog
                    ref="deleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleDelete"
                >
                    <template v-slot:title>{{ $tc('common.accounting.account-statement.delete-statement', eligibleItemsForDelete.length) }}</template>
                    <template v-slot:default>
                        <info-box v-if="eligibleItemsForDelete.length !== selectedItems.length">{{ $tc('views.accounting.account-statement.delete-statement--confirm--n-of-x--info', eligibleItemsForDelete.length, { x: selectedItems.length }) }}</info-box>
                        <p>{{ $tc(`views.accounting.account-statement.delete-statement--confirm${eligibleItemsForDelete.length !== selectedItems.length ? '--n-of-x' : '' }`, eligibleItemsForDelete.length) }}</p>
                    </template>
                </form-dialog>
            </div>
        </div>

        <polling
            ref="polling"
            :poll-fn="AccountStatementReport.service.all"
            :stop-condition-fn="stopPollingConditionFn"
            :poll-filters="pollFilters"
            :timeout-tm="3000"
            auto-start
            @condition-check="handlePollingConditionCheck"
        />

        <in-page-footer>
            <base-button
                :to="{ name: 'accounting-account-statement-create' }"
                :label="$tc('common.accounting.account-statement.create-statement', 1)"
                primary-button
            />

            <base-button
                v-if="eligibleItemsForCancelCreation.length"
                :label="`${$tc('common.accounting.account-statement.cancel-creation', eligibleItemsForCancelCreation.length)} (${eligibleItemsForCancelCreation.length})`"
                outline
                @click="handleActions({ key: AccountStatementReport.action.CANCEL_CREATION })"
            />

            <base-button
                v-if="eligibleItemsForDelete.length"
                :label="`${$tc('common.accounting.account-statement.delete-statement', eligibleItemsForDelete.length)} (${eligibleItemsForDelete.length})`"
                outline
                color="negative"
                @click="handleActions({ key: AccountStatementReport.action.DELETE })"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import { AccountStatementReportActions } from '@/enums'
import { QueueItemStatus } from '@/enums/graphql'
import { AccountStatementReport } from '@/models/models'
import { cancelCreationCondition, deleteCondition } from '@/helpers/accountStatementReport'

import BaseTable from '@/components/BaseTable'
import StatusBadge from '@/components/StatusBadge'
import FormDialog from '@/components/form/FormDialog'
import Polling from '@/components/Polling.vue'
import { kebabCase } from 'lodash'

export default {
    name: 'AccountStatementReportList',
    meta () {
        return {
            title: this.$tc('common.accounting.account-statement.account-statement', 2),
        }
    },
    components: {
        BaseTable,
        StatusBadge,
        FormDialog,
        Polling,
    },
    data () {
        return {
            QueueItemStatus,
            AccountStatementReport,
            AccountStatementReportActions,
            selectedItems: [],
            columns: [
                {
                    name: 'date_from',
                    label: this.$tc('views.accounting.account-statement.column-title--period-from', 1),
                    align: 'left',
                    sortable: true,
                    field: row => row.formattedDateFrom,
                    required: true,
                },
                {
                    name: 'date_until',
                    label: this.$tc('views.accounting.account-statement.column-title--period-to', 1),
                    align: 'left',
                    sortable: true,
                    field: row => row.formattedDateUntil,
                    required: true,
                },
                {
                    name: 'accounts',
                    label: this.$tc('views.accounting.account-statement.column-title--accounts', 2),
                    align: 'left',
                    field: row => row.accounts.length ? row.accounts.length : ' - ',
                    required: true,
                },
                {
                    name: 'created_at',
                    label: this.$tc('common.term.created-on', 1),
                    align: 'left',
                    sortable: true,
                    field: row => row.formattedCreatedAt,
                },
                {
                    name: 'owner',
                    label: this.$tc('views.accounting.account-statement.column-title--created-by', 1),
                    align: 'left',
                    sortable: true,
                    field: row => row.owner.getContactName(),
                },
                {
                    name: 'file_types',
                    label: this.$tc('common.file.file-type', 1),
                    align: 'left',
                    field: row => row.file_types.map(file_type => this.$tc(`common.file.type.${kebabCase(file_type)}`, 1)),
                },
                {
                    name: 'status',
                    label: this.$tc('common.term.status'),
                    align: 'left',
                    required: true,
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left', required: true },
            ],
            isQueuedOrProcessing: false,
            pollFilters: {
                count: 1,
                filterCurrentQueueItemStatus: [QueueItemStatus.QUEUED, QueueItemStatus.PROCESSING],
            },
        }
    },
    computed: {
        eligibleItemsForCancelCreation () {
            return this.getEligibleItemsForCancelCreation()
        },
        eligibleItemsForDelete () {
            return this.getEligibleItemsForDelete()
        },
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        handleActions (action) {
            switch (action.key) {
                case AccountStatementReport.action.CANCEL_CREATION:
                    this.$refs.cancelCreationDialog.open()
                    break

                case AccountStatementReport.action.DELETE:
                    this.$refs.deleteDialog.open()
                    break
            }
        },
        handleCancelCreation () {
            const accountStatementReportIds = this.eligibleItemsForCancelCreation.map(accountStatementReport => accountStatementReport.id)

            AccountStatementReport.cancelCreation(accountStatementReportIds)
                .then(accountStatementReports => {
                    this.$refs.cancelCreationDialog.close()
                    this.resetSelectedItems()
                    this.$refs.table.fetchObjects()

                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.accounting.account-statement-creation-cancelled-success', accountStatementReportIds.length),
                    })
                }).catch(error => {
                    this.$refs.cancelCreationDialog.showFormErrorMessage(error)
                    this.$refs.cancelCreationDialog.resetFormSubmitStatus()
                })
        },
        handleDelete () {
            const accountStatementReportIds = this.eligibleItemsForDelete.map(accountStatementReport => accountStatementReport.id)

            AccountStatementReport.delete(accountStatementReportIds)
                .then(() => {
                    this.$refs.deleteDialog.close()
                    this.resetSelectedItems()
                    this.$refs.table.fetchObjects()

                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.accounting.account-statement-deleted-success', accountStatementReportIds.length),
                    })
                }).catch(error => {
                    this.$refs.deleteDialog.showFormErrorMessage(error)
                    this.$refs.deleteDialog.resetFormSubmitStatus()
                })
        },
        selectionRowCondition () {
            return true
        },
        resetSelectedItems () {
            this.selectedItems.length = 0
            if (this.$refs.table) this.$refs.table.$refs.qTable.clearSelection()
        },
        getEligibleItemsForCancelCreation () {
            return cancelCreationCondition(this.selectedItems)
        },
        getEligibleItemsForDelete () {
            return deleteCondition(this.selectedItems)
        },
        stopPollingConditionFn (response) {
            return !response.data.length
        },
        handlePollingConditionCheck (stopCondition) {
            if (this.isQueuedOrProcessing && stopCondition) this.$refs.table.fetchObjects()
            this.isQueuedOrProcessing = !stopCondition
        },
        onNewEntity () {
            this.$router.push({ name: 'accounting-account-statement-create' })
        },
    },
}
</script>
