<template>
    <form
        data-test="form:posting"
        class="row q-col-gutter-sm q-mb-md"
        @submit.prevent="onFormSubmit"
    >
        <div class="col-xs-12 col-lg-10 col-xl-8">
            <!-- eslint-disable vue/no-v-html -->
            <info-box
                v-if="formErrorMessage"
                type="negative"
                data-test="error:global-message"
                class="q-mb-xs"
                no-margin
                v-html="formErrorMessage"
            />
            <!-- eslint-enable -->

            <div class="row q-col-gutter-md">
                <div class="col-xs-12 col-sm-4">
                    <form-builder item-key="date" autofocus />
                </div>
                <div class="col-xs-12 col-sm-8">
                    <form-builder item-key="typeId" />
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4">
                    <form-builder item-key="debitAccountId" />
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4">
                    <form-builder item-key="creditAccountId" />
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4">
                    <form-builder item-key="amount" />
                </div>
                <div class="col-xs-12 col-sm-8 col-md-12">
                    <form-builder item-key="text" />
                </div>

                <div class="col-xs-12">
                    <h2>{{ $tc('common.term.attachment', 2) }}</h2>
                    <p v-if="posting" :class="{ 'additional-info': !posting.attachments.length }">{{ $tc('common.attachment.number-of-attachments', posting.attachments.length) }} <template v-if="posting.attachments.length"><span class="additional-info">({{ humanStorageSize(attachmentsTotalSize) }})</span>:</template></p>

                    <div class="row q-col-gutter-md">
                        <div class="col-xs-12 col-lg-6">
                            <file-upload
                                v-model="attachments"
                                multiple
                                class="do-not-print"
                            />
                        </div>
                        <div v-if="posting && posting.attachments.length" class="col-xs-12 col-md-8">
                            <!-- TODO improvement @MTR: Create file library component. -->
                            <div class="file-library-wrapper">
                                <q-card
                                    v-for="attachment in posting.attachments"
                                    :key="attachment.id"
                                    :class="['file-library-item', { 'delete': attachmentToDelete && attachmentToDelete.id === attachment.id }]"
                                    square
                                    flat
                                    bordered
                                >
                                    <q-card-section horizontal>
                                        <q-item class="items-start">
                                            <q-item-section side>
                                                <q-avatar square>
                                                    <q-icon
                                                        v-if="attachment.mime_type"
                                                        :name="getFileTypeIcon(attachment.mime_type)"
                                                        size="sm"
                                                        color="secondary"
                                                    />
                                                </q-avatar>
                                            </q-item-section>

                                            <q-item-section>
                                                <q-item-label>{{ attachment.name }}</q-item-label>
                                                <q-item-label v-if="attachment.size" caption>{{ humanStorageSize(attachment.size) }}</q-item-label>
                                            </q-item-section>
                                        </q-item>

                                        <q-card-actions vertical>
                                            <q-btn
                                                icon="mib-bin"
                                                flat
                                                round
                                                color="negative"
                                                size="sm"
                                                @click="triggerPostingDeleteAttachment(attachment)"
                                            >
                                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.attachment.delete-attachment', 1) }}</q-tooltip>
                                            </q-btn>

                                            <q-btn
                                                color="primary"
                                                size="sm"
                                                flat
                                                round
                                                icon="mib-cloud-download"
                                                @click="attachment.download()"
                                            >
                                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.term.download-item', 1, { item: attachment.name }) }}</q-tooltip>
                                            </q-btn>
                                        </q-card-actions>
                                    </q-card-section>
                                </q-card>
                            </div>
                            <!-- Delete Attachment -->
                            <form-dialog
                                ref="postingDeleteAttachmentDialog"
                                :item="attachmentToDelete"
                                dont-close-on-confirm
                                @confirm="handlePostingDeleteAttachment"
                                @hide="attachmentToDelete = null"
                            >
                                <template v-slot:title>{{ $tc('common.attachment.delete-attachment', 1) }}</template>
                                <template v-slot:default="slotProps">
                                    <i18n v-if="slotProps.item" path="common.attachment.delete-attachment--confirm--temp-component-interpolation-count" tag="p">
                                        <template v-slot:name><b>{{ slotProps.item.name }}</b></template>
                                    </i18n>
                                </template>
                            </form-dialog>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <in-page-footer>
            <!-- TODO: Create BaseButtonCancel component -->
            <base-button
                :label="$t('common.term.cancel')"
                primary-button
                flat
                @click="cancel"
            />
            <!-- TODO: Create BaseButtonSubmit component -->
            <base-button
                type="submit"
                data-test="btn:submit"
                :label="currentMode === ViewMode.CREATE ? $tc('views.accounting.posting.add-posting', 1) : $t('common.term.save')"
                :disable="formSubmitStatus"
                :loading="formSubmitStatus"
                primary-button
            />
        </in-page-footer>
    </form>
</template>

<script>
import { lightFormat } from 'date-fns'
import { ViewMode } from '@/enums'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import POSTING_FORM from '@/forms/posting.form'
import { EventBus } from '@/event-bus'
import { Posting } from '@/models/posting'
import { getFileTypeIcon, humanStorageSize } from '@/helpers/file'
import FileUpload from '@/components/form/FileUpload'
import FormDialog from '@/components/form/FormDialog'

export default {
    name: 'PostingForm',
    components: {
        FileUpload,
        FormDialog,
    },
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: POSTING_FORM,
    },
    props: {
        posting: {
            type: Object,
            required: false,
            default () {
                return undefined
            },
        },
    },
    data () {
        const data = {
            ViewMode,
            currentMode: this.posting ? ViewMode.UPDATE : ViewMode.CREATE,
            formData: {
                date: lightFormat(new Date(), 'yyyy-MM-dd'),
            },
            attachments: [],
            attachmentToDelete: null,
        }

        if (data.currentMode === ViewMode.UPDATE) {
            Object.assign(data.formData, {
                typeId: this.posting.type.id,
                date: this.posting.date,
                text: this.posting.text,
                debitAccountId: this.posting.debitAccount.id,
                creditAccountId: this.posting.creditAccount.id,
                amount: this.posting.amount,
            })
        }

        return data
    },
    computed: {
        attachmentsTotalSize () {
            return (this.posting) ? this.posting.attachments.reduce((total, file) => (total += file.size), 0) : 0
        },
    },
    methods: {
        async triggerPostingDeleteAttachment (attachment) {
            this.attachmentToDelete = attachment
            await this.$nextTick()
            this.$refs.postingDeleteAttachmentDialog.open()
        },
        handlePostingDeleteAttachment () {
            Posting.service.deleteAttachment(this.posting.id, this.attachmentToDelete.id)
                .then(() => {
                    this.$refs.postingDeleteAttachmentDialog.close()
                    const attachmentIndex = this.posting.attachments.findIndex(attachment => attachment.id === this.attachmentToDelete.id)
                    if (attachmentIndex > -1) {
                        this.posting.attachments.splice(attachmentIndex, 1)
                    }
                    EventBus.$emit(`posting:delete-attachment`, this.posting)
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.attachment.attachment-deleted-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.postingDeleteAttachmentDialog.showFormErrorMessage(error)
                    this.$refs.postingDeleteAttachmentDialog.resetFormSubmitStatus()
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$tc('common.notifications.attachment.attachment-deleted-error', 1),
                    })
                })
        },
        getFileTypeIcon (...args) {
            return getFileTypeIcon(...args)
        },
        humanStorageSize (...args) {
            return humanStorageSize(...args)
        },
        handleFormData (data) {
            const formData = data
            if (this.attachments.length) {
                formData.attachments = this.attachments // Grab upload directly from formData, as the one processed by formBuilderMixin loses it's binary data. See TODO in formBuilderMixin.
            } else {
                delete formData.attachments
            }
            this.$emit('post-handle-form-data', formData)
        },
        cancel () {
            this.$router.push({ name: 'accounting-posting-list' })
        },
    },
}
</script>
