import gql from 'graphql-tag'

export const APPLICATION_FILE_CREATE_MUTATION = gql`
    mutation applicationFileCreate(
        $applicationId: ID!
        $typeId: ID!
        $file: Upload!
    ) {
        applicationFileCreate(
            application_id: $applicationId
            type_id: $typeId
            file: $file
        ) {
            id
            file {
                id
                name
            }
            type {
                id
                status
                key
                name
            }
            consultant {
                id
                contact_number
                first_name
                last_name
                gender
                title
                consulting_company_name
                consulting_settings_status
            }
        }
    }
`
