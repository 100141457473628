<template>
    <!--
        TODO:
        [ ] How to handle old/EOL applications? (Those will pile up over time and make the list _huge_.)
            Idea: Do _not_ show them at first but add additional filter to "Include EOL applications"
    -->
    <div>
        <template v-if="value.length">
            <q-table
                :data="value"
                :columns="columns"
                row-key="id"
                :rows-per-page-options="[]"
                binary-state-sort
                flat
                square
                dense
                class="target-object-table"
            >
                <template
                    v-if="correspondenceItem.type === CorrespondenceItemType.LETTER"
                    v-slot:body-cell-attention_of="slotProps"
                >
                    <q-td :props="slotProps">
                        <template v-if="slotProps.row.recipient.isCompany">
                            <base-input
                                v-model="slotProps.row.attentionOf"
                                :label="$tc('common.correspondence.attention-of.attention-of', 1)"
                                clearable
                            >
                                <template v-slot:append>
                                    <!-- TODO refactoring: Once bulk is implemented: Make sure to lazy load the relationships on user action. -->
                                    <contact-relationship-menu
                                        :node-id="slotProps.row.recipient.id"
                                        :filter="{ definition: { types: [ContactRelationshipType.EMPLOYER__EMPLOYEE] } }"
                                        :format-relationship="relationship => relationship.node2.getContactName()"
                                        @select="relationship => slotProps.row.attentionOf = relationship.node2.getContactName()"
                                        hide-if-empty
                                    />
                                </template>
                            </base-input>
                        </template>
                    </q-td>
                </template>

                <template
                    v-if="correspondenceItem.type === CorrespondenceItemType.LETTER"
                    v-slot:body-cell-recipient_address="slotProps"
                >
                    <q-td :props="slotProps">
                        <address class="recipient-address">
                            {{ slotProps.row.recipient.getContactAddress(slotProps.row.recipient_address, { multiline: !$q.screen.gt.sm }) }}
                            <base-badge v-if="!slotProps.row.isDefaultAddress" margin-left="none">{{ $t('common.term.edited') }}</base-badge>
                        </address>
                        <q-btn
                            color="primary"
                            size="sm"
                            flat
                            round
                            icon="mib-itx-pin-action-edit"
                        >
                            <q-menu>
                                <q-list separator>
                                    <q-item
                                        v-for="address in slotProps.row.recipient.addresses"
                                        :key="address.id"
                                        v-close-popup
                                        clickable
                                        @click="slotProps.row.setAddressOverride(address)"
                                    >
                                        <q-item-section side class="currently-selected-wrapper">
                                            <q-icon v-if="address.id === slotProps.row.recipient_address.id" name="mib-check" size="1em" />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label caption class="caption-label">
                                                <b>{{ address.category.name }}<span v-if="address.label"> / {{ address.label.label }}</span></b>
                                                <template v-if="address.is_main_correspondence_address"> ({{ $tc('common.contact.main-correspondence-address', 1) }})</template>
                                            </q-item-label>
                                            <q-item-label>
                                                <address>{{ slotProps.row.recipient.getContactAddress(address, { multiline: true }) }}</address>
                                            </q-item-label>
                                        </q-item-section>
                                    </q-item>
                                </q-list>
                            </q-menu>
                        </q-btn>
                        <!-- TODO improvement: Add "Reset to default" button (when value has been edited) -->
                    </q-td>
                </template>

                <template
                    v-if="correspondenceItem.type === CorrespondenceItemType.EMAIL"
                    v-slot:body-cell-recipient_email_address="slotProps"
                >
                    <q-td :props="slotProps">
                        <address>
                            {{ slotProps.row.recipient_email_address.email }}
                            <base-badge v-if="!slotProps.row.isDefaultEmailAddress" margin-left="none">{{ $t('common.term.edited') }}</base-badge>
                        </address>
                        <q-btn
                            color="primary"
                            size="sm"
                            flat
                            round
                            icon="mib-email-action-edit"
                        >
                            <q-menu>
                                <q-list separator>
                                    <q-item
                                        v-for="emailAddress in slotProps.row.recipient.emailAddresses"
                                        :key="emailAddress.id"
                                        v-close-popup
                                        clickable
                                        @click="slotProps.row.setEmailAddressOverride(emailAddress)"
                                    >
                                        <q-item-section side class="currently-selected-wrapper">
                                            <q-icon v-if="emailAddress.id === slotProps.row.recipient_email_address.id" name="mib-check" size="1em" />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label caption class="caption-label">
                                                <b>{{ emailAddress.category.name }}<span v-if="emailAddress.label"> / {{ emailAddress.label.label }}</span></b>
                                                <template v-if="emailAddress.is_main_email_address"> ({{ $tc('common.contact.main-email-address', 1) }})</template>
                                            </q-item-label>
                                            <q-item-label>{{ emailAddress.email }}</q-item-label>
                                        </q-item-section>
                                    </q-item>
                                </q-list>
                            </q-menu>
                        </q-btn>
                        <!-- TODO improvement: Add "Reset to default" button (when value has been edited) -->
                    </q-td>
                </template>

                <template v-slot:body-cell-sender="slotProps">
                    <q-td :props="slotProps">
                        <span>{{ slotProps.row.sender.getContactName({ consultingCompanyName: !slotProps.row.sender.worksForUserConsultingCompany }) }} <base-badge v-if="!slotProps.row.isDefaultSender" margin-left="none">{{ $t('common.term.edited') }}</base-badge></span>
                        <q-btn
                            color="primary"
                            size="sm"
                            flat
                            round
                            icon="mib-single-neutral-actions-edit"
                        >
                            <q-menu>
                                <q-list separator>
                                    <q-item
                                        v-for="consultant in slotProps.row.allConsultants"
                                        :key="consultant.id"
                                        v-close-popup
                                        clickable
                                        @click="slotProps.row.setSenderOverride(consultant)"
                                    >
                                        <q-item-section side class="currently-selected-wrapper">
                                            <q-icon v-if="consultant.id === slotProps.row.sender.id" name="mib-check" size="1em" />
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label v-if="consultant.id === slotProps.row.getSender({ ignoreOverride: true }).id" caption class="caption-label">
                                                {{ $tc('common.correspondence.sender.default-sender', 1) }}
                                            </q-item-label>
                                            <q-item-label>{{ consultant.getContactName() }} <span class="additional-info" v-if="!consultant.worksForUserConsultingCompany">{{ consultant.getContactName({ noFirstName: true, noLastName: true, consultingCompanyName: !consultant.worksForUserConsultingCompany }) }}</span></q-item-label>
                                        </q-item-section>
                                    </q-item>
                                </q-list>
                            </q-menu>
                        </q-btn>
                        <!-- TODO improvement: Add "Reset to default" button (when value has been edited) -->
                    </q-td>
                </template>

                <template v-slot:body-cell-actions="slotProps">
                    <q-td :props="slotProps" auto-width>
                        <q-btn
                            color="primary"
                            size="sm"
                            flat
                            round
                            icon="mib-pencil"
                            @click="showSelectTargetObjectDialog"
                        >
                            <q-tooltip>{{ $tc('common.correspondence.target-object.select-target-object--edit--choose-another', 1) }}</q-tooltip>
                        </q-btn>
                        <!-- TODO improvement: Add "Remove target object" button -->
                    </q-td>
                </template>

                <template v-slot:bottom>
                    <base-button
                        :label="$tc('common.correspondence.target-object.select-target-object--edit--choose-another', 1)"
                        flat
                        @click="showSelectTargetObjectDialog"
                    />
                </template>
            </q-table>
        </template>
        <base-button
            v-else
            :label="(correspondenceItem && correspondenceItem.target_object_type) ? $tc(`common.correspondence.target-object.select-target-object--${correspondenceItem.target_object_type.toLowerCase()}`, 1) : $tc(`common.correspondence.target-object.select-target-object`, 1)"
            @click="showSelectTargetObjectDialog"
        />

        <q-dialog
            ref="selectTargetObjectDialog"
            full-width
            full-height
        >
            <q-card class="q-pt-sm q-pr-md q-pb-xs q-pl-md">
                <!-- Contacts -->
                <base-table
                    v-if="correspondenceItem.target_object_type === CorrespondenceItemTargetObjectType.CONTACT"
                    ref="targetContactsTable"
                    :columns="targetContactsColumns"
                    :fetch-objects-fn="fetchTargetContacts"
                    pagination-sort-by-default-key="contact_name"
                    selection="single"
                    :selected.sync="selected"
                    :selection-row-condition="targetContactsSelectionRowCondition"
                    :filter-autofocus="true"
                    sticky
                    class="q-mb-xl"
                >
                    <template v-slot:tabs>
                        <h3>{{ $tc('common.correspondence.target-object.select-target-object', 1) }}</h3>
                    </template>

                    <template v-slot:selectionRowConditionInfo="slotProps">
                        <q-checkbox
                            :value="false"
                            class="no-email-address"
                            color="secondary-light"
                            keep-color
                            dense
                            disable
                        />
                        <!-- TODO improvement @MTR: Create custom tooltip component (<div> only needed here to be able to apply max-width) -->
                        <q-tooltip
                            :delay="1000"
                            anchor="center right"
                            self="center left"
                            :offset="[0, 10]"
                        >
                            <div style="max-width: 80ch;">
                                <p v-if="correspondenceItem.type === CorrespondenceItemType.EMAIL && !slotProps.row.mainEmailAddress">{{ $tc('common.correspondence.target-object.select-target-object--not-selectable--contact--no-email-address', 1) }}</p>
                            </div>
                        </q-tooltip>
                    </template>

                    <template v-slot:body-cell-contact_number="slotProps">
                        <q-td :props="slotProps" data-test="td:contact-number">{{ slotProps.row.contactNumber.number }}</q-td>
                    </template>

                    <template v-slot:body-cell-contact_name="slotProps">
                        <q-td :props="slotProps" class="contact-name-td" data-test="td:contact-name">
                            <contact-name
                                :contact="slotProps.row"
                                type-icon
                                highlighted
                                comma-separated
                            />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-main_email_address="slotProps">
                        <q-td :props="slotProps" :class="['email-address-td', { 'no-email-address': !slotProps.row.mainEmailAddress } ]" data-test="td:email-address">
                            <template v-if="slotProps.row.mainEmailAddress">{{ slotProps.row.mainEmailAddress.email }}</template>
                            <template v-else>{{ $tc('common.email-address.no-email-address-on-record', 1) }}</template>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-status="slotProps">
                        <q-td :props="slotProps" auto-width>
                            <status-badge :status="slotProps.row.status" />
                            <contact-type-badges :contact="slotProps.row" />
                        </q-td>
                    </template>
                </base-table>

                <!-- Applications -->
                <base-table
                    v-if="correspondenceItem.target_object_type === CorrespondenceItemTargetObjectType.APPLICATION"
                    ref="targetApplicationsTable"
                    :columns="targetApplicationsColumns"
                    :fetch-objects-fn="fetchTargetApplications"
                    :additional-filters="{ filterEntityTypes: ProductDerivedEntityType.APPLICATION }"
                    pagination-sort-by-default-key="number"
                    selection="single"
                    :selected.sync="selected"
                    :selection-row-condition="targetApplicationsSelectionRowCondition"
                    :filter-autofocus="true"
                    sticky
                    class="q-mb-xl"
                >
                    <template v-slot:selectionRowConditionInfo="slotProps">
                        <q-checkbox
                            :value="false"
                            class="not-selectable"
                            color="secondary-light"
                            keep-color
                            dense
                            disable
                        />
                        <!-- TODO improvement @MTR: Create custom tooltip component (<div> only needed here to be able to apply max-width) -->
                        <q-tooltip
                            :delay="1000"
                            anchor="center right"
                            self="center left"
                            :offset="[0, 10]"
                        >
                            <div style="max-width: 80ch;">
                                <p v-if="!slotProps.row.consultantAlsoConsultsCustomer && correspondenceItem.type === CorrespondenceItemType.EMAIL && !slotProps.row.customer.mainEmailAddress">{{ $tc('common.correspondence.target-object.select-target-object--not-selectable--application--consultant-not-on-contact-and-no-email-address', 1, { consultantName: slotProps.row.consultant.getContactName({ salutation: true, consultingCompanyName: !slotProps.row.consultant.worksForUserConsultingCompany, nestedParentheses: true }) }) }}</p>
                                <template v-else>
                                    <p v-if="!slotProps.row.consultantAlsoConsultsCustomer">{{ $tc('common.correspondence.target-object.select-target-object--not-selectable--application--consultant-not-on-contact', 1, { consultantName: slotProps.row.consultant.getContactName({ salutation: true, consultingCompanyName: !slotProps.row.consultant.worksForUserConsultingCompany, nestedParentheses: true }) }) }}</p>
                                    <p v-if="correspondenceItem.type === CorrespondenceItemType.EMAIL && !slotProps.row.customer.mainEmailAddress">{{ $tc('common.correspondence.target-object.select-target-object--not-selectable--application--no-email-address', 1) }}</p>
                                </template>
                            </div>
                        </q-tooltip>
                    </template>

                    <template v-slot:body-cell-number="slotProps">
                        <q-td
                            :props="slotProps"
                            :class="['application-number-td']"
                            data-test="td:application-number"
                            auto-width
                        >
                            {{ slotProps.row.formattedNumber }}
                            <info-icon
                                :title="slotProps.row.formattedNumber"
                                :text="applicationDetailInfo(slotProps.row)"
                            />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-main_email_address="slotProps">
                        <q-td :props="slotProps" :class="['email-address-td', { 'no-email-address': !slotProps.row.customer.mainEmailAddress } ]" data-test="td:email-address">
                            <template v-if="slotProps.row.customer.mainEmailAddress">{{ slotProps.row.customer.mainEmailAddress.email }}</template>
                            <template v-else>{{ $tc('common.email-address.no-email-address-on-record', 1) }}</template>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-status="slotProps">
                        <q-td :props="slotProps" auto-width>
                            <contract-status-badge :contract="slotProps.row" default-translation-base-path="common.status.application-status" />
                        </q-td>
                    </template>
                </base-table>

                <!-- Contracts -->
                <base-table
                    v-if="correspondenceItem.target_object_type === CorrespondenceItemTargetObjectType.CONTRACT"
                    ref="targetContractsTable"
                    :columns="targetContractsColumns"
                    :fetch-objects-fn="fetchTargetContracts"
                    :additional-filters="{ filterStatus: [ContractStatus.ACTIVE, ContractStatus.TERMINATED] }"
                    pagination-sort-by-default-key="customer_name"
                    selection="single"
                    :selected.sync="selected"
                    :selection-row-condition="targetContractsSelectionRowCondition"
                    :filter-autofocus="true"
                    sticky
                    class="q-mb-xl"
                >
                    <template v-slot:selectionRowConditionInfo="slotProps">
                        <q-checkbox
                            :value="false"
                            class="not-selectable"
                            color="secondary-light"
                            keep-color
                            dense
                            disable
                        />
                        <!-- TODO improvement @MTR: Create custom tooltip component (<div> only needed here to be able to apply max-width) -->
                        <q-tooltip
                            :delay="1000"
                            anchor="center right"
                            self="center left"
                            :offset="[0, 10]"
                        >
                            <div style="max-width: 80ch;">
                                <p v-if="!slotProps.row.consultantAlsoConsultsCustomer && correspondenceItem.type === CorrespondenceItemType.EMAIL && !slotProps.row.customer.mainEmailAddress">{{ $tc('common.correspondence.target-object.select-target-object--not-selectable--contract--consultant-not-on-contact-and-no-email-address', 1, { consultantName: slotProps.row.consultant.getContactName({ salutation: true, consultingCompanyName: !slotProps.row.consultant.worksForUserConsultingCompany, nestedParentheses: true }) }) }}</p>
                                <template v-else>
                                    <p v-if="!slotProps.row.consultantAlsoConsultsCustomer">{{ $tc('common.correspondence.target-object.select-target-object--not-selectable--contract--consultant-not-on-contact', 1, { consultantName: slotProps.row.consultant.getContactName({ salutation: true, consultingCompanyName: !slotProps.row.consultant.worksForUserConsultingCompany, nestedParentheses: true }) }) }}</p>
                                    <p v-if="correspondenceItem.type === CorrespondenceItemType.EMAIL && !slotProps.row.customer.mainEmailAddress">{{ $tc('common.correspondence.target-object.select-target-object--not-selectable--contract--no-email-address', 1) }}</p>
                                </template>
                            </div>
                        </q-tooltip>
                    </template>

                    <template v-slot:body-cell-contract_number="slotProps">
                        <q-td
                            :props="slotProps"
                            :class="['contract-number-td']"
                            data-test="td:contract-number"
                            auto-width
                        >
                            {{ slotProps.row.currentContractNumber }}
                            <info-icon
                                :title="slotProps.row.currentContractNumber"
                                :text="contractDetailInfo(slotProps.row)"
                            />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-main_email_address="slotProps">
                        <q-td :props="slotProps" :class="['email-address-td', { 'no-email-address': !slotProps.row.customer.mainEmailAddress } ]" data-test="td:email-address">
                            <template v-if="slotProps.row.customer.mainEmailAddress">{{ slotProps.row.customer.mainEmailAddress.email }}</template>
                            <template v-else>{{ $tc('common.email-address.no-email-address-on-record', 1) }}</template>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-status="slotProps">
                        <q-td :props="slotProps" auto-width>
                            <contract-status-badge :contract="slotProps.row" />
                        </q-td>
                    </template>
                </base-table>

                <base-button
                    v-close-popup
                    :label="$t('common.term.cancel')"
                    primary-button
                    flat
                    class="q-mr-sm"
                />
                <span class="button-tooltip-wrapper">
                    <base-button
                        :label="(correspondenceItem && correspondenceItem.target_object_type) ? $tc(`common.correspondence.target-object.select-target-object--${correspondenceItem.target_object_type.toLowerCase()}`, (selected.length > 1 ? selected.length : 1)) : $tc(`common.correspondence.target-object.select-target-object`, (selected.length > 1 ? selected.length : 1))"
                        :disable="selected.length < 1"
                        primary-button
                        @click="setTargetObject"
                    />
                    <q-tooltip
                        v-if="selected.length < 1"
                        anchor="center right"
                        self="center left"
                        :offset="[5, 0]"
                    >{{ (correspondenceItem && correspondenceItem.target_object_type) ? $tc(`common.correspondence.target-object.select-target-object--disabled-action--${correspondenceItem.target_object_type.toLowerCase()}`, 1) : $tc(`common.correspondence.target-object.select-target-object--disabled-action`, 1) }}</q-tooltip>
                </span>
            </q-card>
        </q-dialog>
    </div>
</template>

<script>
import {
    CorrespondenceItemType,
    CorrespondenceItemTargetObjectType,
    ContractStatus,
    ProductDerivedEntityType, ContactRelationshipType
} from '@/enums/graphql'
import { Contact, Contract } from '@/models/models'
import { wrapCorrespondenceItemTargetObject } from '@/helpers/correspondence'
import BaseTable from '@/components/BaseTable'
import ContactName from '@/components/contact/ContactName'
import StatusBadge from '@/components/StatusBadge'
import ContactTypeBadges from '@/components/contact/ContactTypeBadges'
import BaseBadge from '@/components/BaseBadge'
import ContractStatusBadge from '@/components/contract/ContractStatusBadge'
import { ProductDerivedEntityService } from '@/services'
import { unref } from 'vue'
import BaseInput from '@/components/form/BaseInput'
import ContactRelationshipMenu from '@/components/contactRelationship/ContactRelationshipMenu'

export default {
    name: 'CorrespondenceItemTargetObjectConfigurator',
    components: {
        BaseInput,
        BaseBadge,
        BaseTable,
        ContactName,
        ContactTypeBadges,
        StatusBadge,
        ContractStatusBadge,
        ContactRelationshipMenu,
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
        correspondenceItem: {
            type: Object,
            required: true,
        },
    },
    data () {
        const data = {
            ContactRelationshipType,
            CorrespondenceItemType,
            CorrespondenceItemTargetObjectType,
            ContractStatus,
            ProductDerivedEntityType,
            selected: [],
            columns: [
                {
                    name: 'recipient',
                    label: this.$tc('common.term.recipient', 1),
                    field: row => row.recipient.getContactName({ salutation: true, title: true }),
                    align: 'left',
                },
                {
                    name: 'sender',
                    label: this.$tc('common.term.sender', 1),
                    align: 'left',
                },
                {
                    name: 'actions',
                    label: this.$tc('common.term.action', 2),
                    align: 'left',
                },
            ],
            targetContactsColumns: [
                {
                    name: 'contact_number',
                    label: this.$tc('common.contact.contact-number', 1),
                    field: row => row.contactNumber.number,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'contact_name',
                    label: this.$tc('common.term.name'),
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'main_email_address',
                    label: this.$tc('common.contact.main-email-address'),
                    field: row => row.mainEmailAddress?.email,
                    align: 'left',
                },
                {
                    name: 'main_address_address_1',
                    label: this.$tc('common.term.address'),
                    field: row => row.mainAddress.address1,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'main_address_address_zip',
                    label: this.$tc('common.address.zip'),
                    field: row => row.mainAddress.zip,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'main_address_address_city',
                    label: this.$tc('common.address.city'),
                    field: row => row.mainAddress.city,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'status',
                    label: this.$tc('common.term.status'),
                    align: 'left',
                },
            ],
            targetApplicationsColumns: [
                {
                    name: 'number',
                    label: this.$tc('common.application.application-number', 1),
                    field: row => row.formattedNumber,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'product_provider_name',
                    label: this.$tc('common.product.product-provider', 1),
                    field: row => row.currentContractInformation.product.providerName,
                    align: 'left',
                },
                {
                    name: 'customer_name',
                    label: this.$tc('common.term.customer', 1),
                    field: row => row.customer.getContactName({ salutation: true, title: true }),
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'main_email_address',
                    label: this.$tc('common.contact.main-email-address'),
                    field: row => row.customer.mainEmailAddress?.email,
                    align: 'left',
                },
                {
                    name: 'main_address_address_1',
                    label: this.$tc('common.term.address'),
                    field: row => row.customer.mainAddress?.address1,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'main_address_address_zip',
                    label: this.$tc('common.address.zip'),
                    field: row => row.customer.mainAddress?.zip,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'main_address_address_city',
                    label: this.$tc('common.address.city'),
                    field: row => row.customer.mainAddress?.city,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'status',
                    label: this.$tc('common.term.status'),
                    align: 'left',
                },
            ],
            targetContractsColumns: [
                {
                    name: 'contract_number',
                    label: this.$tc('common.contract.contract-number', 1),
                    field: row => row.currentContractNumber,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'product_provider_name',
                    label: this.$tc('common.product.product-provider', 1),
                    field: row => row.currentContractInformation.product.providerName,
                    align: 'left',
                },
                {
                    name: 'customer_name',
                    label: this.$tc('common.term.customer', 1),
                    field: row => row.customer.getContactName({ salutation: true, title: true }),
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'main_email_address',
                    label: this.$tc('common.contact.main-email-address'),
                    field: row => row.customer.mainEmailAddress?.email,
                    align: 'left',
                },
                {
                    name: 'main_address_address_1',
                    label: this.$tc('common.term.address'),
                    field: row => row.customer.mainAddress?.address1,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'main_address_address_zip',
                    label: this.$tc('common.address.zip'),
                    field: row => row.customer.mainAddress?.zip,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'main_address_address_city',
                    label: this.$tc('common.address.city'),
                    field: row => row.customer.mainAddress?.city,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'status',
                    label: this.$tc('common.term.status'),
                    align: 'left',
                },
            ],
        }

        switch (this.correspondenceItem.type) {
            case CorrespondenceItemType.LETTER:
                // AttentionOf and Recipient list
                data.columns.splice(1, 0, {
                    name: 'attention_of',
                    label: this.$tc('common.correspondence.attention-of.attention-of', 1),
                    align: 'left',
                }, {
                    name: 'recipient_address',
                    label: this.$tc('common.term.address', 1),
                    align: 'left',
                })

                // TargetObject selection list
                // Remove email address column from table
                data.targetContactsColumns.splice(data.targetContactsColumns.findIndex(column => column.name === 'main_email_address'), 1)
                data.targetApplicationsColumns.splice(data.targetApplicationsColumns.findIndex(column => column.name === 'main_email_address'), 1)
                data.targetContractsColumns.splice(data.targetContractsColumns.findIndex(column => column.name === 'main_email_address'), 1)
                break
            case CorrespondenceItemType.EMAIL:
                // Recipient list
                data.columns.splice(1, 0, {
                    name: 'recipient_email_address',
                    label: this.$tc('common.email-address.email-address', 1),
                    align: 'left',
                })
                break
        }

        if (this.correspondenceItem.target_object_type === CorrespondenceItemTargetObjectType.APPLICATION) {
            data.columns.unshift({
                name: 'application',
                label: this.$tc('common.term.application', 1),
                field: row => unref(row.formattedNumber),
                align: 'left',
            })
        }

        if (this.correspondenceItem.target_object_type === CorrespondenceItemTargetObjectType.CONTRACT) {
            data.columns.unshift({
                name: 'contract',
                label: this.$tc('common.term.contract', 1),
                field: row => row.currentContractNumber,
                align: 'left',
            })
        }

        return data
    },
    methods: {
        targetContactsSelectionRowCondition (row) {
            return (this.correspondenceItem.type === CorrespondenceItemType.EMAIL) ? (row.mainEmailAddress) : true
        },
        targetApplicationsSelectionRowCondition (row) {
            // The consultant on the application also needs to be in the customer's consultants list.
            if (!row.consultantAlsoConsultsCustomer) return false

            // If the correspondence target_object_type is email, the customer also needs to have an email address.
            return (this.correspondenceItem.type === CorrespondenceItemType.EMAIL) ? (row.customer.mainEmailAddress) : true
        },
        targetContractsSelectionRowCondition (row) {
            // The consultant on the contract also needs to be in the customer's consultants list.
            if (!row.consultantAlsoConsultsCustomer) return false

            // If the correspondence target_object_type is email, the customer also needs to have an email address.
            return (this.correspondenceItem.type === CorrespondenceItemType.EMAIL) ? (row.customer.mainEmailAddress) : true
        },
        applicationDetailInfo (application) { // TODO
            const contractDetailInfo = [`${application.currentContractInformation.product.name} – ${application.currentContractInformation.product.providerName}`]
            if (application.currentContractInformation.formattedStartDate) contractDetailInfo.push(`${this.$tc('common.contract.contract-start', 1)}: ${application.currentContractInformation.formattedStartDate}`)
            if (application.currentContractInformation.formattedEndDate) contractDetailInfo.push(`${this.$tc('common.contract.contract-end', 1)}: ${application.currentContractInformation.formattedEndDate}`)
            if (application.consultant) contractDetailInfo.push(`${this.$tc('common.contact.consultant--' + application.consultant.gender.toLowerCase(), 1)}: ${application.consultant.getContactName({ salutation: true, consultingCompanyName: !application.consultant.worksForUserConsultingCompany })}`)
            return contractDetailInfo.join('\n')
        },
        contractDetailInfo (contract) {
            const contractDetailInfo = [`${contract.currentContractInformation.product.name} – ${contract.currentContractInformation.product.providerName}`]
            if (contract.currentContractInformation.formattedStartDate) contractDetailInfo.push(`${this.$tc('common.contract.contract-start', 1)}: ${contract.currentContractInformation.formattedStartDate}`)
            if (contract.currentContractInformation.formattedEndDate) contractDetailInfo.push(`${this.$tc('common.contract.contract-end', 1)}: ${contract.currentContractInformation.formattedEndDate}`)
            if (contract.consultant) contractDetailInfo.push(`${this.$tc('common.contact.consultant--' + contract.consultant.gender.toLowerCase(), 1)}: ${contract.consultant.getContactName({ salutation: true, consultingCompanyName: !contract.consultant.worksForUserConsultingCompany })}`)
            return contractDetailInfo.join('\n')
        },
        fetchTargetContacts (variables) {
            return Contact.objects.all(variables, `
                id
                status
                consultingSettings {
                    status
                    company {
                        id
                        company_name
                    }
                }
                productProviderSettings {
                    status
                    display_name
                }
                contactNumber {
                    id
                    number
                }
                addresses {
                    id
                    category {
                        id
                        key
                        name
                    }
                    label {
                        id
                        key
                        label
                    }
                    po_box
                    address1
                    address2
                    address3
                    zip
                    city
                    country {
                        id
                        name
                    }
                    valid_from
                    valid_until
                    is_main_correspondence_address
                    status
                }
                mainAddress {
                    id
                    category {
                        id
                        key
                        name
                    }
                    label {
                        id
                        key
                        label
                    }
                    po_box
                    address1
                    address2
                    address3
                    zip
                    city
                    country {
                        id
                        name
                    }
                    valid_from
                    valid_until
                    is_main_correspondence_address
                    status
                }
                emailAddresses {
                    id
                    category {
                        id
                        key
                        name
                    }
                    label {
                        id
                        key
                        label
                    }
                    email
                    is_main_email_address
                }
                mainEmailAddress {
                    id
                    category {
                        id
                        key
                        name
                    }
                    label {
                        id
                        key
                        label
                    }
                    email
                    is_main_email_address
                }
                consultants {
                    id
                    contact_number
                    first_name
                    last_name
                    gender
                    title
                    consulting_company_name
                    consulting_settings_status
                }
                ... on Person {
                    first_name
                    last_name
                    gender
                    personal_pronoun
                }
                ... on Company {
                    company_name
                    is_tenant_company
                }
            `)
        },
        fetchTargetApplications (variables) {
            return ProductDerivedEntityService.all(variables, `
                ... on Application {
                    id
                    number {
                        number
                    }
                    external_reference_number
                    status
                    customer {
                        id
                        status
                        consultingSettings {
                            status
                            company {
                                id
                                company_name
                            }
                        }
                        productProviderSettings {
                            status
                            display_name
                        }
                        contactNumber {
                            id
                            number
                        }
                        addresses {
                            id
                            category {
                                id
                                key
                                name
                            }
                            label {
                                id
                                key
                                label
                            }
                            po_box
                            address1
                            address2
                            address3
                            zip
                            city
                            country {
                                id
                                name
                            }
                            valid_from
                            valid_until
                            is_main_correspondence_address
                            status
                        }
                        mainAddress {
                            id
                            category {
                                id
                                key
                                name
                            }
                            label {
                                id
                                key
                                label
                            }
                            po_box
                            address1
                            address2
                            address3
                            zip
                            city
                            country {
                                id
                                name
                            }
                            valid_from
                            valid_until
                            is_main_correspondence_address
                            status
                        }
                        emailAddresses {
                            id
                            category {
                                id
                                key
                                name
                            }
                            label {
                                id
                                key
                                label
                            }
                            email
                            is_main_email_address
                        }
                        mainEmailAddress {
                            id
                            category {
                                id
                                key
                                name
                            }
                            label {
                                id
                                key
                                label
                            }
                            email
                            is_main_email_address
                        }
                        consultants {
                            id
                            contact_number
                            first_name
                            last_name
                            gender
                            title
                            consulting_company_name
                            consulting_settings_status
                        }
                        ... on Person {
                            first_name
                            last_name
                            gender
                            personal_pronoun
                        }
                        ... on Company {
                            company_name
                            is_tenant_company
                        }
                    }
                    consultant {
                        id
                        contact_number
                        first_name
                        last_name
                        gender
                        title
                        consulting_company_name
                        consulting_settings_status
                    }
                    currentContractInformation {
                        product {
                            name
                            basicProvider {
                                name
                                display_name
                            }
                        }
                        productTemplate {
                            name
                            category {
                                name
                            }
                            subCategory {
                                name
                            }
                            version
                        }
                        start_date
                        end_date
                        premium
                    }
                }
            `)
        },
        fetchTargetContracts (variables) {
            return Contract.objects.all(variables, `
                id
                contractNumbers {
                    id
                    number
                }
                status
                customer {
                    id
                    status
                    consultingSettings {
                        status
                        company {
                            id
                            company_name
                        }
                    }
                    productProviderSettings {
                        status
                        display_name
                    }
                    contactNumber {
                        id
                        number
                    }
                    addresses {
                        id
                        category {
                            id
                            key
                            name
                        }
                        label {
                            id
                            key
                            label
                        }
                        po_box
                        address1
                        address2
                        address3
                        zip
                        city
                        country {
                            id
                            name
                        }
                        valid_from
                        valid_until
                        is_main_correspondence_address
                        status
                    }
                    mainAddress {
                        id
                        category {
                            id
                            key
                            name
                        }
                        label {
                            id
                            key
                            label
                        }
                        po_box
                        address1
                        address2
                        address3
                        zip
                        city
                        country {
                            id
                            name
                        }
                        valid_from
                        valid_until
                        is_main_correspondence_address
                        status
                    }
                    emailAddresses {
                        id
                        category {
                            id
                            key
                            name
                        }
                        label {
                            id
                            key
                            label
                        }
                        email
                        is_main_email_address
                    }
                    mainEmailAddress {
                        id
                        category {
                            id
                            key
                            name
                        }
                        label {
                            id
                            key
                            label
                        }
                        email
                        is_main_email_address
                    }
                    consultants {
                        id
                        contact_number
                        first_name
                        last_name
                        gender
                        title
                        consulting_company_name
                        consulting_settings_status
                    }
                    ... on Person {
                        first_name
                        last_name
                        gender
                        personal_pronoun
                    }
                    ... on Company {
                        company_name
                        is_tenant_company
                    }
                }
                consultant {
                    id
                    contact_number
                    first_name
                    last_name
                    gender
                    title
                    consulting_company_name
                    consulting_settings_status
                }
                currentContractInformation {
                    product {
                        name
                        basicProvider {
                            name
                            display_name
                        }
                    }
                    productTemplate {
                        name
                        category {
                            name
                        }
                        subCategory {
                            name
                        }
                        version
                    }
                    start_date
                    end_date
                    premium
                }
                cancellations {
                    id
                    status
                    cancellation_date
                }
                termination_date
                replacedBy {
                    id
                }
            `)
        },
        showSelectTargetObjectDialog () {
            this.$refs.selectTargetObjectDialog.show()
        },
        setTargetObject () {
            const targetObjectTables = {}
            targetObjectTables[CorrespondenceItemTargetObjectType.CONTACT] = this.$refs.targetContactsTable
            targetObjectTables[CorrespondenceItemTargetObjectType.APPLICATION] = this.$refs.targetApplicationsTable
            targetObjectTables[CorrespondenceItemTargetObjectType.CONTRACT] = this.$refs.targetContractsTable

            const targetObjectTable = targetObjectTables[this.correspondenceItem.target_object_type]
            const wrappedTargetObject = wrapCorrespondenceItemTargetObject(targetObjectTable.selected[0], this.correspondenceItem.target_object_type, { creatorId: this.correspondenceItem.creator.id })
            this.$emit('input', [wrappedTargetObject])
            this.$refs.selectTargetObjectDialog.hide()
        },
        // Only implement once bulk gets implemented.
        // removeTargetObject (id) {
        //     const targetObjectIndex = this.value.findIndex(targetObject => targetObject.id === id)
        //     if (targetObjectIndex !== -1) {
        //         const values = Array.from(this.value)
        //         values.splice(targetObjectIndex, 1)
        //         this.$emit('input', values)
        //     }
        // },
    },
}
</script>

<style lang="scss" scoped>
.target-object-table {

    ::v-deep .q-table__bottom {
        padding-top: $sizeSpacingSm;
        padding-left: 0;
    }
}

.recipient-address {
    display: initial;
}

.currently-selected-wrapper {
    width: 2em;
}

.caption-label {
    margin-bottom: 0;

    font-size: 12px;
}

.no-email-address {
    color: var(--q-color-secondary-light);
}

tr {
    &:hover,
    &:focus {
        .not-selectable,
        .no-email-address {
            color: var(--q-color-negative) !important;

            ::v-deep .q-checkbox__inner {
                color: var(--q-color-negative-light) !important;
            }
        }
    }
}
</style>
