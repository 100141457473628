import { Model } from '@/models'
import { CorrespondenceLetterService } from '@/services'

export class ProcessedCorrespondenceLetter extends Model {
    /**
     * Create a ProcessedCorrespondenceLetter model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'correspondenceItem',
            'targetObject',
            'status',
            'status_message',
            'creator',
            'sender',
            'recipient_address',
            'subject',
            'language',
            'file_id',
        ], data)
    }

    static get service () { return CorrespondenceLetterService }

    /**
     * Delete this processedCorrespondenceLetter.
     * @returns {Promise<*>}
     */
    delete (deleteConsultingFile = false) {
        return CorrespondenceLetterService.processedCorrespondenceLetterDelete(this.id, deleteConsultingFile)
    }
}
