import gql from 'graphql-tag'

export const CONTACT_PRODUCT_PROVIDER_SETTINGS_UPDATE_MUTATION = gql`
    mutation contactProductProviderSettingsUpdate(
        $contact_id: ID!
        $productProviderSettings: ProductProviderSettingsInput!
    ) {
        contactProductProviderSettingsUpdate(
            contact_id: $contact_id
            productProviderSettings: $productProviderSettings
        ) {
            contact_id
            status
            display_name
            igb2bInsurerCode {
                id
                name
            }
            dxpServiceProviderSettings {
                enabledProcesses {
                    id
                }
                enabledForAutomaticProcessing {
                    id
                }
            }
        }
    }
`
