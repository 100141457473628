import gql from 'graphql-tag'

export const COMMISSION_LIST_ENTRIES_QUERY = gql`
    query commissionListEntries(
        $page: Int = 1
        $count: Int = 1000
        $orderField: CommissionListEntryOrderField = STATUS
        $orderDirection: OrderDirection = DESC
        $filterQuery: String
        $commissionListIds: [ID!]
        $createdManually: Boolean
        $filterStatus: [CommissionListEntryStatus!]
    ) {
        commissionListEntries(
            page: $page
            count: $count
            order: [{
                field: $orderField,
                direction: $orderDirection
            }]
            search: {
                query: $filterQuery
                commission_list_ids: $commissionListIds
                created_manually: $createdManually
                status: $filterStatus
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                status
                ready_to_post
                commissionList {
                    id
                }
                contractMatchShortlist {
                    id
                    customer_name_match
                    contract {
                        id
                        contractNumbers {
                            id
                            number
                        }
                        currentContractInformation {
                            product {
                                name
                                basicProvider {
                                    id
                                    name
                                    display_name
                                }
                            }
                        }
                        customer {
                            id
                            ... on Person {
                                first_name
                                last_name
                                gender
                                personal_pronoun
                                title
                            }
                            ... on Company {
                                company_name
                            }
                            contactNumber {
                                number
                            }
                        }
                    }
                }
                matchedContract {
                    id
                    contractNumbers {
                        id
                        number
                    }
                    currentContractInformation {
                        product {
                            basicProvider {
                                id
                                name
                                display_name
                            }
                        }
                    }
                    customer {
                        id
                        ... on Person {
                            first_name
                            last_name
                            gender
                            personal_pronoun
                            title
                        }
                        ... on Company {
                            company_name
                        }
                        contactNumber {
                            number
                        }
                    }
                }
                contract_number
                framework_agreement_number
                positions {
                    id
                    type
                    premium_from
                    premium_to
                    commission_type
                    commission_amount
                }
                customer_first_name
                customer_last_name
                customer_company_name
                customer_full_name
                created_manually
                status_message
                created_at
                updated_at
            }
        }
    }
`
