export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'productProviderId',
            label: 'forms.field.label.commission-rate-adjustment-rule-set.product-provider',
            inputType: 'productProvider',
            validators: [
                { 'type': 'required' },
            ],
        },
    ],
}
