<template>
    <base-input
        ref="filterInput"
        :value="value"
        v-bind="$attrs"
        :label="label || $tc('common.term.filter', 1)"
        debounce="300"
        :class="{ 'do-not-print': value === '' }"
        data-test="input:filter"
        v-on="$listeners"
        @keyup.esc="$emit('clear-filter')"
    >
        <template v-slot:append>
            <q-icon v-if="value === ''" name="mib-search-alternate" size="1rem" />
            <q-icon
                v-else
                name="mib-search-remove"
                size="1rem"
                class="cursor-pointer"
                data-test="icon:clear-filter"
                @click="$emit('clear-filter')"
            />
        </template>
    </base-input>
</template>

<script>
import BaseInput from '@/components/form/BaseInput'

export default {
    name: 'FilterInput',
    components: {
        BaseInput,
    },
    inheritAttrs: false,
    props: {
        value: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
    },
}
</script>
