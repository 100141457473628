import gql from 'graphql-tag'

export const PRODUCT_TEMPLATE_UPDATE_MUTATION = gql`
    mutation productTemplateUpdate(
        $id: ID!
        $productTemplate: ProductTemplateInputUpdate!
    ) {
        productTemplateUpdate(
            id: $id
            productTemplate: $productTemplate
        ) {
            id
            name
            status
            category {
                id
                name
            }
            subCategory {
                id
                name
            }
            version
        }
    }
`
