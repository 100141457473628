import gql from 'graphql-tag'

export const ECO_HUB_TECH_USER_UPDATE_MUTATION = gql`
    mutation ecoHubTechUserUpdate(
        $techUser: EcoHubTechUserInput!
    ) {
        ecoHubTechUserUpdate(
            techUser: $techUser
        ) {
            idp_user_id
            created_at
            updated_at
        }
    }
`
