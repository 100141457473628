<template>
    <form
        data-test="form:consulting-settings"
        @submit.prevent="onFormSubmit"
        @keyup.esc="cancel"
    >
        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="formErrorMessage"
            type="negative"
            data-test="error:global-message"
            v-html="formErrorMessage"
        />
        <!-- eslint-enable -->

        <div class="row q-col-gutter-md">
            <grid-card
                :columns="{ xs: 12, md: 6, xl: 4 }"
                :heading="$t('views.contact.settings.consulting-settings.general')"
            >
                <info-box v-if="contact.is_tenant_company">{{ $tc('views.contact.settings.consulting-settings.contact-is-system-owner', 1, { name: contact.getContactName() }) }}</info-box>
                <!-- TODO: replace with component that is capable of filtering server-side. This one only displays the first 1000 companies -->
                <form-builder
                    v-if="displayFormField('company')"
                    item-key="company"
                    :label="companyFieldLabel"
                    :filter="{
                        hasConsultingSettings: true
                    }"
                />
            </grid-card>

            <grid-card
                v-if="hasAccountingPermissions"
                :columns="{ xs: 12, md: 6, xl: 4 }"
                :heading="$t(hasCommissionsPermissions ? 'views.contact.settings.consulting-settings.accounting-and-commissions' : 'views.contact.settings.consulting-settings.accounting')"
                content-class="q-gutter-y-sm"
            >
                <form-builder
                    v-if="displayFormField('currentAccount')"
                    item-key="currentAccount"
                    :filter="{
                        filterIsAssigned: false,
                        filterType: contact.is_tenant_company ? [AccountType.ASSETS] : [AccountType.LIABILITIES]
                    }"
                    :inject-options="injectOptions('currentAccount')"
                />

                <form-builder
                    v-if="displayFormField('cancellationReserveAccount')"
                    item-key="cancellationReserveAccount"
                    :filter="{
                        filterIsAssigned: false,
                        filterType: [AccountType.LIABILITIES]
                    }"
                    :inject-options="injectOptions('cancellationReserveAccount')"
                />

                <form-builder
                    v-if="displayFormField('otherIncomeAccount')"
                    item-key="otherIncomeAccount"
                    :filter="{
                        filterIsAssigned: false,
                        filterType: [AccountType.REVENUE]
                    }"
                    :inject-options="injectOptions('otherIncomeAccount')"
                />

                <form-builder
                    v-if="displayFormField('commissionIncomeAccount')"
                    item-key="commissionIncomeAccount"
                    :filter="{
                        filterIsAssigned: false,
                        filterType: [AccountType.REVENUE]
                    }"
                    :inject-options="injectOptions('commissionIncomeAccount')"
                />

                <form-builder
                    v-if="displayFormField('mailProcessingRevenueAccount')"
                    item-key="mailProcessingRevenueAccount"
                    :filter="{
                        filterIsAssigned: false,
                        filterType: [AccountType.REVENUE]
                    }"
                    :inject-options="injectOptions('mailProcessingRevenueAccount')"
                />

                <template v-if="displayFormField('commissionDistributionRuleSet')">
                    <q-separator />
                    <form-builder item-key="commissionDistributionRuleSet" />
                </template>
            </grid-card>

            <grid-card
                v-if="displayFormField('statementRecipientIds')"
                :columns="{ xs: 12, md: 6, xl: 4 }"
                :heading="$tc('common.accounting.statement.statement-recipient', 2)"
                :heading-info="$t('views.contact.settings.consulting-settings.statement-recipient.statement-recipient--info')"
                content-class="q-gutter-y-sm"
            >
                <q-list dense>
                    <q-item tag="label">
                        <q-item-section avatar>
                            <q-radio v-model="statementRecipientSelection" val="none" />
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{ $tc('views.contact.settings.consulting-settings.statement-recipient.statement-recipient--none', 1) }}
                                <info-icon :text="$tc('views.contact.settings.consulting-settings.statement-recipient.statement-recipient--none--info', 1)" />
                            </q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item v-if="contact.type === ContactType.PERSON" tag="label">
                        <q-item-section avatar>
                            <q-radio v-model="statementRecipientSelection" val="self" />
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{ $tc(`views.contact.settings.consulting-settings.statement-recipient.statement-recipient--self--${contact.getGender()}`, 1) }}
                                <info-icon :text="$tc(`views.contact.settings.consulting-settings.statement-recipient.statement-recipient--self--info--${contact.getGender()}`, 1, { name: contact.getContactName() })" />
                            </q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item tag="label">
                        <q-item-section avatar top>
                            <q-radio v-model="statementRecipientSelection" val="specific" />
                        </q-item-section>
                        <q-item-section>
                            <!-- TODO improvement: Show warning/hint if one or more of the selected recipients work for another consultingCompany than the consultant whose settings are being edited. -->
                            <q-item-label>{{ $tc('views.contact.settings.consulting-settings.statement-recipient.statement-recipient--specific', formData.statementRecipientIds ? formData.statementRecipientIds.length : 1) }}
                                <info-icon :text="$tc('views.contact.settings.consulting-settings.statement-recipient.statement-recipient--specific--info', 1, { name: contact.getContactName() })" />
                            </q-item-label>

                            <div class="q-mt-sm statement-recipients--specific">
                                <form-builder
                                    item-key="statementRecipientIds"
                                    include-inactive
                                    :inject-consultants="[{ id: contact.id, gender: contact.gender, first_name: contact.first_name, last_name: contact.last_name }]"
                                    :disable="statementRecipientSelection !== 'specific'"
                                >
                                    <template v-slot:option="slotProps">
                                        <q-item
                                            v-bind="slotProps.itemProps"
                                            v-on="slotProps.itemEvents"
                                            :class="kebabCase(slotProps.opt.consulting_settings_status)"
                                        >
                                            <q-item-section>
                                                <q-item-label>{{ slotProps.opt.getContactName() }} <span class="additional-info">{{ slotProps.opt.getContactName({ noFirstName: true, noLastName: true, contactNumber: true, consultingCompanyName: !slotProps.opt.worksForUserConsultingCompany }) }}</span>
                                                    <status-badge
                                                        v-if="slotProps.opt.consulting_settings_status && slotProps.opt.consulting_settings_status !== ConsultingSettingsStatus.ACTIVE"
                                                        :status="slotProps.opt.consulting_settings_status"
                                                    />
                                                </q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </template>
                                </form-builder>
                            </div>
                        </q-item-section>
                    </q-item>
                </q-list>
            </grid-card>

            <grid-card
                v-if="contact.type === ContactType.PERSON"
                :columns="{ xs: 12, md: 6, xl: 4 }"
                :heading="$tc('views.contact.settings.consulting-settings.signature.signature', 1)"
                :heading-info="$t('views.contact.settings.consulting-settings.signature.signature--info')"
            >
                <div v-if="currentMode === ViewMode.UPDATE && consultingSettings?.signature && !removeCurrentSignature">
                    <div class="signature-wrapper">
                        <img :src="consultingSettings.signature.fileDownloadUrl" :alt="$t('views.contact.settings.consulting-settings.signature.signature-of--name', { name: contact.getContactName() })" />
                    </div>

                    <p>{{ consultingSettings.signature.name }} ({{ consultingSettings.signature.formattedFileSize }})
                        <q-btn
                            color="primary"
                            size="sm"
                            flat
                            round
                            icon="mib-cloud-download"
                            @click="consultingSettings.signature.download()"
                        >
                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.term.download', 1) }}</q-tooltip>
                        </q-btn>

                        <q-btn
                            color="negative"
                            size="xs"
                            flat
                            round
                            icon="mib-bin"
                            class="do-not-print"
                            @click="setRemoveCurrentSignature"
                        >
                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.contact.settings.consulting-settings.signature.delete-signature', 1) }}</q-tooltip>
                        </q-btn>
                    </p>
                </div>

                <div v-else>
                    <file-upload v-model="$v.formData.signature.$model" drop-zone-small />

                    <info-box no-margin class="q-mt-md">
                        <p>{{ $t('views.contact.settings.consulting-settings.signature.signature--requirements') }}</p>
                    </info-box>
                </div>

                <info-box v-if="$v.formData && $v.formData.signature && $v.formData.signature.$error" type="negative">
                    <ul>
                        <li v-if="!$v.formData.signature.maxLength">{{ $tc('common.error-message.max-n-files-allowed', $v.formData.signature.$params.maxLength.max) }}</li>
                        <li v-if="$v.formData.signature.$each.$error">{{ $tc('common.error-message.only-specific-file-types-allowed', 1, { type: allowedFileTypes }) }}</li>
                    </ul>
                </info-box>
            </grid-card>

            <!-- MailProcessingSettings -->
            <grid-card
                v-if="$can('Feature:mailProcessing:core') && $can('MailProcessingSettings:manage')"
                :columns="{ xs: 12, md: 6, xl: 4 }"
                :heading="$tc('forms.field.label.mail-processing.mail-processing', 1)"
            >
                <div class="row q-col-gutter-sm">
                    <div v-if="displayFormField('activeFrom')" class="col-xs-12 col-sm-6">
                        <form-builder item-key="activeFrom" />
                    </div>
                    <div v-if="displayFormField('activeUntil')" class="col-xs-12 col-sm-6">
                        <form-builder item-key="activeUntil" />
                    </div>
                    <div v-if="contact.type === ContactType.PERSON && mailProcessingSettingsStatus !== MailProcessingSettingsStatus.INACTIVE" class="col-xs-12">
                        <info-box v-if="mailProcessingSettingsStatus === MailProcessingSettingsStatus.FUTURE" type="info">{{ $tc('views.contact.settings.consulting-settings.mail-processing-settings.status.future', 1, { name: contact.getContactName(), activeFrom: mailProcessingSettingsFormattedActiveFrom }) }}</info-box>
                        <info-box v-if="mailProcessingSettingsStatus === MailProcessingSettingsStatus.ACTIVE" type="positive">{{ $tc('views.contact.settings.consulting-settings.mail-processing-settings.status.active', 1, { name: contact.getContactName() }) }}</info-box>
                        <info-box v-if="mailProcessingSettingsStatus === MailProcessingSettingsStatus.EXPIRED" type="warning">{{ $tc('views.contact.settings.consulting-settings.mail-processing-settings.status.expired', 1, { name: contact.getContactName(), activeUntil: mailProcessingSettingsFormattedActiveUntil }) }}</info-box>
                    </div>

                    <div v-if="displayFormField('pricePerProcessedMailItem')" class="col-xs-12">
                        <form-builder item-key="pricePerProcessedMailItem" />
                    </div>

                    <div v-if="displayFormField('notificationEmailOverride')" class="col-xs-12">
                        <form-builder item-key="notificationEmailOverride" />
                    </div>

                    <div v-if="displayFormField('errorNotificationEmail')" class="col-xs-12">
                        <form-builder item-key="errorNotificationEmail" />
                    </div>
                </div>
            </grid-card>
        </div>

        <in-page-footer>
            <base-button
                v-if="currentMode === ViewMode.CREATE"
                :label="$t('common.term.cancel')"
                primary-button
                outline
                @click="cancel"
            />
            <base-button
                data-test="btn:submit"
                type="submit"
                :label="$t('common.term.save')"
                primary-button
            />
        </in-page-footer>
    </form>
</template>

<script>
import { formatDate } from '@/helpers/date'
import { kebabCase } from 'lodash'
import { i18n } from '@/i18n'
import { ViewMode } from '@/enums'
import { ContactType, AccountType, AccountStatus, ConsultingSettingsStatus } from '@/enums/graphql'
import { MailProcessingSettingsStatus } from '@/enums'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import CONSULTING_SETTINGS_FIELDS from '@/forms/consultingSettings.form'
import StatusBadge from '@/components/StatusBadge'
import FileUpload from '@/components/form/FileUpload'
import { maxLength } from 'vuelidate/lib/validators'
import { isMediaType } from '@/helpers/form'
import { MailProcessingSettings } from '@/models/mailProcessingSettings'

export default {
    name: 'ConsultingSettingsForm',
    components: {
        StatusBadge,
        FileUpload,
    },
    mixins: [formBuilderMixin],
    props: {
        contact: {
            type: Object,
            required: true,
        },
        consultingSettings: {
            type: Object,
            default () {
                return undefined
            },
        },
    },
    data () {
        const data = {
            ViewMode,
            currentMode: this.consultingSettings ? ViewMode.UPDATE : ViewMode.CREATE,
            formData: {
                signature: [],
            },
            allowedFileTypes: ['image/jpeg', 'image/jpeg', 'image/png'],
            removeCurrentSignature: false,
            statementRecipientSelection: 'none',
            accountFieldsData: [],
            ContactType,
            AccountType,
            AccountStatus,
            ConsultingSettingsStatus,
            MailProcessingSettingsStatus,
            hasAccountingPermissions: ['Feature:accounting:core', 'Accounting:manage'].every(permission => this.$can(permission)),
            hasCommissionsPermissions: ['Feature:commissions:core', 'Commissions:manage'].every(permission => this.$can(permission)),
        }

        if (data.currentMode === ViewMode.UPDATE) {
            this.updateFormData(data.formData)
            if (data.formData.statementRecipientIds?.length > 0) {
                if (data.formData.statementRecipientIds.length === 1 && data.formData.statementRecipientIds[0] === this.contact.id) {
                    data.statementRecipientSelection = 'self'
                } else {
                    data.statementRecipientSelection = 'specific'
                }
            }
        }

        return data
    },
    validations () {
        const validations = {}
        validations.formData = {
            signature: {
                maxLength: maxLength(1),
                $each: {
                    type: isMediaType(this.allowedFileTypes),
                },
            },
        }
        return validations
    },
    computed: {
        companyFieldLabel () {
            return this.contact.type === ContactType.PERSON
                ? `${i18n.tc('common.term.employer', 1)} (${i18n.t('views.admin.consulting-settings.superordinate-consulting-company')})`
                : i18n.t('views.admin.consulting-settings.superordinate-consulting-company')
        },
        mailProcessingSettingsStatus () {
            return MailProcessingSettings.getStatus(this.formData.activeFrom, this.formData.activeUntil)
        },
        mailProcessingSettingsFormattedActiveFrom () {
            return formatDate(this.formData.activeFrom)
        },
        mailProcessingSettingsFormattedActiveUntil () {
            return formatDate(this.formData.activeUntil)
        },
    },
    formBuilderSettings: {
        schema: CONSULTING_SETTINGS_FIELDS,
        fields () {
            const fields = []
            const hasAccountingPermissions = ['Feature:accounting:core', 'Accounting:manage'].every(permission => this.$can(permission))
            const hasCommissionsPermissions = ['Feature:commissions:core', 'Commissions:manage'].every(permission => this.$can(permission))
            const hasMailProcessingFeaturePermissions = this.$can('Feature:mailProcessing:core')
            const hasMailProcessingManagePermissions = this.$can('MailProcessingSettings:manage')

            if (!this.contact.is_tenant_company) {
                fields.push('company')
            }

            if (hasAccountingPermissions) {
                fields.push('currentAccount', 'cancellationReserveAccount', 'statementRecipientIds')
            }

            if (hasAccountingPermissions && this.contact.is_tenant_company) {
                fields.push('otherIncomeAccount', 'commissionIncomeAccount')
            }

            if (hasAccountingPermissions && hasMailProcessingFeaturePermissions && this.contact.is_tenant_company) {
                fields.push('mailProcessingRevenueAccount')
            }

            if (hasCommissionsPermissions && !this.contact.is_tenant_company) {
                fields.push('commissionDistributionRuleSet')
            }

            // MailProcessingSettings
            if (hasMailProcessingFeaturePermissions && hasMailProcessingManagePermissions) {
                if (this.contact.isPerson) {
                    fields.push('activeFrom')
                    fields.push('activeUntil')
                    fields.push('notificationEmailOverride')
                }
                if (this.contact.isCompany) fields.push('pricePerProcessedMailItem')
                if (this.contact.is_tenant_company) fields.push('errorNotificationEmail')
            }

            return fields
        },
    },
    watch: {
        statementRecipientSelection (newValue) {
            if (newValue === 'self') {
                this.$v.formData.statementRecipientIds.$model = [`${this.contact.id}`]
            }
            if (newValue === 'none') {
                this.$v.formData.statementRecipientIds.$model = []
            }
        },
    },
    methods: {
        setRemoveCurrentSignature () {
            this.removeCurrentSignature = true
            this.$v.$touch()
        },
        handleFormData (formData) {
            // TODO @TFU: This might only be necessary until we can use formbuilder-next…
            // Only emit form fields that are being displayed.
            const cleanedFormData = {}
            this.formFields.forEach(key => {
                cleanedFormData[key] = formData[key]
            })

            if (this.formData.signature.length) {
                cleanedFormData.signature = this.formData.signature[0] // Grab upload directly from formData, as the one processed by formBuilderMixin loses it's binary data. See TODO in formBuilderMixin.
            } else if (this.removeCurrentSignature) {
                cleanedFormData.signature = null // Sending 'null' to the API deletes the current signature.
            }

            // Reset
            this.removeCurrentSignature = false
            this.formData.signature = []

            // Emit
            this.$emit('post-handle-form-data', cleanedFormData)
        },
        updateFormData (targetObject) {
            Object.assign(targetObject, {
                company: this.consultingSettings.company ? this.consultingSettings.company.id : null,
                currentAccount: this.consultingSettings.currentAccount ? this.consultingSettings.currentAccount.id : null,
                cancellationReserveAccount: this.consultingSettings.cancellationReserveAccount ? this.consultingSettings.cancellationReserveAccount.id : null,
                otherIncomeAccount: this.consultingSettings.otherIncomeAccount ? this.consultingSettings.otherIncomeAccount.id : null,
                commissionIncomeAccount: this.consultingSettings.commissionIncomeAccount ? this.consultingSettings.commissionIncomeAccount.id : null,
                mailProcessingRevenueAccount: this.consultingSettings.mailProcessingRevenueAccount ? this.consultingSettings.mailProcessingRevenueAccount.id : null,
                commissionDistributionRuleSet: this.consultingSettings.commissionDistributionRuleSet ? this.consultingSettings.commissionDistributionRuleSet.id : null,
                statementRecipientIds: this.consultingSettings.statementRecipients
                    ? this.consultingSettings.statementRecipients.consultants.map(consultant => consultant.id)
                    : [],
                activeFrom: this.consultingSettings.mailProcessingSettings?.active_from || '',
                activeUntil: this.consultingSettings.mailProcessingSettings?.active_until || '',
                notificationEmailOverride: this.consultingSettings.mailProcessingSettings?.notification_email_override || '',
                pricePerProcessedMailItem: this.consultingSettings.mailProcessingSettings?.price_per_processed_mail_item ?? '',
                errorNotificationEmail: this.consultingSettings.mailProcessingSettings?.error_notification_email || '',
            })
        },
        cancel () {
            this.$emit('cancel')
        },
        displayFormField (key) {
            return this.formFields?.includes(key)
        },
        injectOptions (key) {
            return this.contact.consultingSettings && this.contact.consultingSettings[key]
                ? [this.contact.consultingSettings[key]]
                : []
        },
        kebabCase (...args) {
            return kebabCase(...args)
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep {
    .statement-recipients--specific {
        max-width: 100%;
    }

    .signature-wrapper {
        margin-bottom: $sizeSpacingSm;
        border: 2px dashed var(--color-border-primary);

        img {
            vertical-align: bottom;
        }
    }
}
</style>
