<template>
    <page-loading-indicator
        v-if="!commissionList"
        :number-of-breadcrumb-elements="2"
        actions
        no-more-actions-button
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.commission.commission-list.commission-list', 2)" :to="{ name: 'commission-lists' }" />
                <q-breadcrumbs-el :label="pageTitle" :to="{ name: 'commission-list-detail', params: { id: commissionList.id } }">
                    <status-badge :status="commissionList.status" default-translation-base-path="common.status.commission-list-status" />
                </q-breadcrumbs-el>
            </template>

            <h1>{{ pageTitle }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <commission-list-xml-form
                    v-if="commissionList.type === CommissionListType.XML"
                    :ref="CommissionListType.XML"
                    :commission-list="commissionList"
                    @post-handle-form-data="onXmlFormSubmit"
                />
                <commission-list-excel-form
                    v-else
                    :ref="CommissionListType.EXCEL"
                    :commission-list="commissionList"
                    @post-handle-form-data="onExcelFormSubmit"
                />
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { CommissionList } from '@/models/models'
import { CommissionListType, CommissionListStatus } from '@/enums/graphql'

import StatusBadge from '@/components/StatusBadge'
import { formatDate } from '@/helpers/date'

import CommissionListXmlForm from '@/components/commission/CommissionListXmlForm.vue'
import CommissionListExcelForm from '@/components/commission/CommissionListExcelForm.vue'

export default {
    name: 'CommissionListUpdate',
    meta () {
        return {
            title: !this.commissionList ? `${this.$t('common.term.loading')} …` : `${this.$tc('views.commission.commission-list.commission-list', 1)}: ${this.pageTitle}`,
        }
    },
    components: {
        StatusBadge,
        CommissionListXmlForm,
        CommissionListExcelForm,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            CommissionListType,
            CommissionListStatus,
            commissionList: null,
        }
    },
    computed: {
        accountingPeriod () {
            const fromDate = formatDate(this.commissionList.exchange_date_from, { noLeadingZero: true })
            const toDate = formatDate(this.commissionList.exchange_date_to, { noLeadingZero: true })

            if (fromDate || toDate) {
                return `${fromDate || '?'} ${this.$t('views.commission.commission-list.date-range--to')} ${toDate || '?'}`
            }

            return this.$tc('common.commission.accounting-period', 0)
        },
        pageTitle () {
            const providerName = this.commissionList.productProvider.display_name || this.commissionList.productProvider.name
            const fileName = this.commissionList.filename

            if (this.commissionList.exchange_date_from || this.commissionList.exchange_date_to) {
                return `${providerName}, ${this.accountingPeriod}`
            }

            return `${providerName}, ${fileName}`
        },
    },
    created () {
        CommissionList.service.get(this.id).then(commissionList => {
            this.commissionList = commissionList

            // Forward to detail view if CommissionList cannot be updated.
            if (commissionList.status !== CommissionListStatus.PENDING) {
                this.$router.replace({ name: 'commission-list-detail', params: { id: commissionList.id } })
            }
        }).catch(error => {
            if (error.graphQLErrors[0].message === 'Commission List doesn’t exist.') {
                // Forward to `not found` error view.
                this.$router.replace({ name: '404' })
            } else {
                // Forward to `forbidden` error view.
                this.$router.replace({ name: '403' })
            }
        })
    },
    methods: {
        onXmlFormSubmit (formData) {
            const submitData = {
                type: formData.type,
                commissionList: {
                    product_provider_id: formData.productProviderId,
                },
            }
            this.handleSubmit(submitData)
        },
        onExcelFormSubmit (formData) {
            const submitData = {
                type: formData.type,
                commissionList: {
                    product_provider_id: formData.productProviderId,
                    excel_mapping_id: formData.excelMappingId,
                    exchange_date_from: formData.exchangeDateFrom,
                    exchange_date_to: formData.exchangeDateTo,
                    commission_total: parseFloat(formData.commissionTotal),
                },
            }
            this.handleSubmit(submitData)
        },
        handleSubmit ({ type, commissionList }) {
            this.commissionList.update(commissionList)
                .then(() => {
                    this.$router.replace({ name: 'commission-list-detail', params: { id: this.id, status: 'pending' } })
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.commission.commission-list-updated-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs[type].showFormErrorMessage(error)
                    this.$refs[type].resetFormSubmitStatus()
                })
        },
    },
}
</script>
