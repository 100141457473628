<template>
    <page-loading-indicator
        v-if="!statement"
        :number-of-breadcrumb-elements="2"
        subtitle
        actions
        :number-of-next-actions="0"
    />
    <page-wrapper v-else>
        <page-header :progress-bar="statement.currentQueueItem.isQueuedOrProcessing" progress-bar-indeterminate>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.accounting.statement.statement', 2)" :to="{ name: 'accounting-statements' }" />
                <q-breadcrumbs-el :label="`${statement.consultingEntity.getContactName()} (${statement.formattedPeriod})`" :to="{ name: 'accounting-statement-detail', params: { id: statement.id } }">
                    <status-badge
                        :status="statement.currentQueueItem.status"
                        default-translation-base-path="common.status.queue-item-status"
                        :loading="statement.currentQueueItem.isQueuedOrProcessing"
                    />
                </q-breadcrumbs-el>
            </template>

            <h1>{{ $tc('views.accounting.statement.statement-for', 1) }} {{ statement.consultingEntity.getContactName() }} <span class="subtitle">(<span data-test="text:date-period">{{ statement.formattedPeriod }}</span>)</span></h1>

            <template v-slot:actions>
                <actions
                    v-if="statement.actions"
                    :actions="statement.actions"
                    :number-of-next-best-actions="0"
                    translation-base-key="common.actions.accounting"
                    @click="handleActions"
                />

                <!-- Cancel Creation Statement -->
                <form-dialog
                    ref="cancelCreationDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleCancelCreation"
                >
                    <template v-slot:title>{{ $tc('common.actions.accounting.statement.cancel-creation', 1) }}</template>
                    <template v-slot:default>
                        <p>{{ $tc('views.accounting.statement.cancel-statement-creation--confirm--n-of-x', 1) }}</p>
                    </template>
                </form-dialog>

                <!-- Share Statement -->
                <form-dialog
                    ref="shareDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleShare"
                >
                    <template v-slot:title>{{ $tc('common.actions.accounting.statement.share', 2) }}</template>
                    <template v-slot:default>
                        <p v-if="statement.hasRecipients">{{ $tc('views.accounting.statement.share-statement--confirm--recipients-info', statement.recipients.consultants.length, { recipients: statement.formattedRecipients(true) }) }}</p>
                        <p>{{ $tc('views.accounting.statement.share-statement--confirm--n-of-x', 1) }}</p>
                    </template>
                </form-dialog>

                <!-- Delete Statement -->
                <form-dialog
                    ref="deleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleDelete"
                >
                    <template v-slot:title>{{ $tc('common.actions.accounting.statement.delete', 1) }}</template>
                    <template v-slot:default>
                        <p>{{ $tc('views.accounting.statement.delete-statement--confirm--n-of-x', 1) }}</p>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <div class="row q-col-gutter-md">
            <div v-if="hasStatusMessage(statement)" class="col-xs-12">
                <info-box :type="statement.currentQueueItem.status === QueueItemStatus.FAILED ? 'negative' : 'info'" no-margin>
                    <p><b>{{ $tc(`common.status.${kebabCase(statement.currentQueueItem.status)}`, 1) }}</b>: {{ statement.currentQueueItem.status_message.message }}</p>
                </info-box>
            </div>

            <grid-card
                :columns="{ xs: 12, md: 6 }"
                :heading="$tc('common.term.configuration', 1)"
                content-class="q-pb-sm"
            >
                <dl>
                    <div>
                        <dt>{{ $tc(`common.contact.consulting-${kebabCase(statement.consultingEntity.type)}`, 1) }}: </dt>
                        <dd>
                            <router-link :to="{ name: 'contact-detail', params: { id: statement.consultingEntity.id } }">{{ statement.consultingEntity.getContactName() }} <span v-if="statement.consultingEntity.consulting_company_name" class="font-weight-normal">({{ statement.consultingEntity.consulting_company_name }})</span></router-link>
                        </dd>
                    </div>

                    <div>
                        <dt>{{ $tc('common.commission.accounting-period', 1) }}: </dt>
                        <dd>{{ statement.formattedPeriod }}</dd>
                    </div>

                    <div>
                        <dt>{{ $tc('common.attachment.attachment', 2) }}: </dt>
                        <dd :class="{ 'additional-info': !statement.include_attachments }">{{ $tc('views.accounting.statement.attachments-included', statement.include_attachments ? 2 : 0) }}</dd>
                    </div>

                    <div>
                        <dt>{{ $tc('common.file.file-type', 1) }}: </dt>
                        <dd><template v-for="(name, index) in statement.file_types">
                            <span :key="index">{{ $tc(`common.file.type.${kebabCase(name)}`, 1) }}<template v-if="index + 1 < statement.file_types.length">, </template></span>
                        </template></dd>
                    </div>

                    <hr class="q-mt-xs q-mb-sm">

                    <div v-if="statement.file_storage_item_shared_at">
                        <dt>{{ $t('views.accounting.statement.shared-on') }}: </dt>
                        <dd>{{ statement.formattedFileStorageItemSharedAt }}</dd>
                    </div>
                    <div v-else>
                        <dt class="visually-hidden">{{ $tc('views.accounting.statement.sharing', 1) }}: </dt>
                        <dd class="additional-info">{{ $t('views.accounting.statement.shared--not-shared') }}</dd>
                    </div>

                    <div v-if="statement.file_storage_item_shared_at && statement.hasRecipients">
                        <dt>{{ $tc('common.accounting.statement.statement-recipient', statement.recipients.consultants.length) }}: </dt>
                        <dd>
                            <span v-for="(recipient, index) in statement.recipients.consultants" :key="index">
                                <router-link :to="{ name: 'contact-detail', params: { id: recipient.id } }">{{ recipient.getContactName() }} <span v-if="statement.consultingEntity.consulting_company_name" class="font-weight-normal">({{ statement.consultingEntity.consulting_company_name }})</span></router-link>
                                <br v-if="index + 1 < statement.recipients.consultants.length">
                            </span>
                        </dd>
                    </div>
                </dl>
            </grid-card>

            <!-- TODO improvement @MTR: Refine when Drive has been done -->
            <grid-card
                v-if="statement.fileStorageItem"
                :columns="{ xs: 12, md: 6 }"
                :heading="$tc('common.file.file', 2)"
            >
                <template v-for="(entry, index) in statement.fileStorageItem.files">
                    <div :key="index">
                        {{ entry.name }} / {{ entry.formattedFileSize }}
                        <q-btn
                            color="primary"
                            size="sm"
                            flat
                            round
                            icon="mib-cloud-download"
                            @click="entry.download()"
                        />
                    </div>
                </template>
            </grid-card>

            <div class="col-xs-12">
                <p>
                    <small>
                        <base-button
                            show-as-link
                            @click="$refs.activityLogOverlay.open({ filterSubjects: [{ id: id, type: ActivityLogSubjectType.STATEMENT }] })"
                        >{{ $tc('common.term.created-on--date-time', 1, { date: statement.formattedCreatedAtDate, time: statement.formattedCreatedAtTime }) }}</base-button>
                    </small>
                </p>
            </div>
        </div>

        <!-- Polling -->
        <polling
            v-if="statement.currentQueueItem.isQueuedOrProcessing"
            ref="polling"
            :poll-fn="Statement.objects.get"
            :poll-filters="id"
            :stop-condition-fn="stopPollingConditionFn"
            :timeout-tm="3000"
            auto-start
        />

        <!-- Activity log -->
        <activity-log-overlay ref="activityLogOverlay" />

        <in-page-footer>
            <base-button
                :label="$t('common.term.back')"
                primary-button
                flat
                @click="$router.go(-1)"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { kebabCase } from 'lodash'
import { Statement } from '@/models/models'
import { ContactType, QueueItemStatus, ActivityLogSubjectType } from '@/enums/graphql'

import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'
import StatusBadge from '@/components/StatusBadge'
import Polling from '@/components/Polling.vue'
import ActivityLogOverlay from '@/components/ActivityLogOverlay'

export default {
    name: 'StatementDetail',
    components: {
        Actions,
        FormDialog,
        StatusBadge,
        Polling,
        ActivityLogOverlay,
    },
    meta () {
        return {
            title: !this.statement ? `${this.$t('common.term.loading')} …` : `${this.$tc('common.accounting.statement.statement', 1)}: ${this.statement.consultingEntity.getContactName()} (${this.statement.formattedPeriod})`,
        }
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            ActivityLogSubjectType,
            ContactType,
            QueueItemStatus,
            Statement,
            statement: null,
        }
    },
    created () {
        this.fetchObject()
    },
    methods: {
        fetchObject() {
            Statement.objects.get(this.id).then(statement => {
                this.statement = statement
            })
        },
        handleActions (action) {
            switch (action.key) {
                case Statement.action.CANCEL_CREATION:
                    this.$refs.cancelCreationDialog.open()
                    break
                case Statement.action.SHARE:
                    this.$refs.shareDialog.open()
                    break
                case Statement.action.DELETE:
                    this.$refs.deleteDialog.open()
                    break
            }
        },
        handleCancelCreation () {
            Statement.cancelCreation([this.id])
                .then(() => {
                    this.$refs.cancelCreationDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.accounting.statement-creation-cancelled-success', 1),
                    })
                    this.$router.replace({ name: 'accounting-statement' })
                }).catch(error => {
                    this.$refs.cancelCreationDialog.showFormErrorMessage(error)
                    this.$refs.cancelCreationDialog.resetFormSubmitStatus()
                })
        },
        handleShare () {
            Statement.share([this.id])
                .then(response => {
                    this.$refs.shareDialog.close()
                    this.statement = response[0]
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.accounting.statement-shared-success', 1),
                    })
                }).catch(error => {
                    this.$refs.shareDialog.showFormErrorMessage(error)
                    this.$refs.shareDialog.resetFormSubmitStatus()
                })
        },
        handleDelete () {
            Statement.delete([this.id])
                .then(() => {
                    this.$refs.deleteDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.accounting.statement-deleted-success', 1),
                    })
                    this.$router.replace({ name: 'accounting-statements' })
                })
                .catch(error => {
                    this.$refs.deleteDialog.showFormErrorMessage(error)
                    this.$refs.deleteDialog.resetFormSubmitStatus()
                })
        },
        stopPollingConditionFn (statement) {
            Object.assign(this.statement, statement)
            return !this.statement.currentQueueItem.isQueuedOrProcessing
        },
        hasStatusMessage (statement) {
            return !!statement.currentQueueItem?.status_message
        },
        kebabCase(...args) {
            return kebabCase(...args)
        },
    },
}
</script>
