import { render, staticRenderFns } from "./GlobalSearchResultCompany.vue?vue&type=template&id=6e36d0de&"
import script from "./GlobalSearchResultCompany.vue?vue&type=script&lang=js&"
export * from "./GlobalSearchResultCompany.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports