import gql from 'graphql-tag'

export const CONTACT_CHANGE_STATUS_MUTATION = gql`
    mutation contactChangeStatus(
        $id: ID!
        $status: ContactStatus!
        $consultantIds: [ID!]
    ) {
        contactChangeStatus(
            id: $id
            status: $status
            consultant_ids: $consultantIds
        ) {
            status
            consultants {
                id
                contact_number
                first_name
                last_name
                gender
                title
                consulting_company_name
                consulting_settings_status
                created_at
                updated_at
            }
        }
    }
`
