import { Model } from '@/models'
import { FileStorageItemService } from '@/services'
import { formatDate, formatDateTime } from '@/helpers/date'
import { TemporaryDownloadFile } from '@/models/temporaryDownloadFile'

export class FileStorageItem extends Model {
    /**
     * Create a FileStorageItem model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'type',
            'files',
            'targetObject',
            'accessControl',
            'acknowledged_at',
        ], data)
    }

    static get service () { return FileStorageItemService }

    static setAcknowledgeAt (ids) {
        return FileStorageItemService.setAcknowledgeAt(ids)
    }

    get formattedCreatedAt () {
        return formatDate(this.created_at)
    }

    get formattedCreatedAtFull () {
        return formatDateTime(this.created_at)
    }

    get formattedAcknowledgedAt () {
        return formatDate(this.acknowledged_at)
    }

    downloadFilesPackage () {
        const fileIds = this.files.map(file => file.id)

        return TemporaryDownloadFile.filesPackage({ fileIds: fileIds }).then(response => {
            response.file.download()
        })
    }

    setAcknowledgeAt () {
        return FileStorageItemService.setAcknowledgeAt([this.id])
    }
}
