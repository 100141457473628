import gql from 'graphql-tag'

export function buildAccountsQuery (fields, additionalParams = '') {
    return gql`
        query accounts(
            $page: Int = 1
            $count: Int = 1000
            $orderField: AccountOrderField = NUMBER
            $orderDirection: OrderDirection = ASC
            $filterQuery: String
            $filterType: [AccountType!]
            $filterStatus: [AccountStatus!]
            $filterIsAssigned: Boolean
            $filterAssignedToType: [AccountAssignedToType!]
            $filterAssignedAsType: [AccountAssignedAsType!]
            $filterAssignedToIds: [ID!]
            $filterAssignedToConsultingSettingsStatus: [ConsultingSettingsStatus!]
            $filterBalanceDate: Date
            $filterBalanceFrom: Float
            $filterBalanceTo: Float
            ${additionalParams}
        ) {
            accounts(
                page: $page
                count: $count
                order: [{ field: $orderField, direction: $orderDirection }]
                search: {
                    query: $filterQuery
                    type: $filterType
                    status: $filterStatus
                    is_assigned: $filterIsAssigned
                    assigned_to_type: $filterAssignedToType
                    assigned_as_type: $filterAssignedAsType
                    assigned_to_ids: $filterAssignedToIds
                    assigned_to_consulting_settings_status: $filterAssignedToConsultingSettingsStatus
                    balance_date: $filterBalanceDate
                    balance_from: $filterBalanceFrom
                    balance_to: $filterBalanceTo
                }
            ) {
                paginatorInfo {
                    count
                    firstItem
                    lastItem
                    total
                    currentPage
                    lastPage
                    perPage
                    hasMorePages
                }
                data {
                    ${fields}
                }
            }
        }
    `
}

const defaultQueryFields = `
    id
    type
    status
    number
    name
    balance(posting_date_from: $balancePostingDateFrom, posting_date_until: $balancePostingDateUntil)
    is_assigned
    assignedTo {
        ... on Person {
            first_name
            last_name
            consultingSettings {
                company {
                    company_name
                }
            }
        }
        ... on Company {
            company_name
            is_tenant_company
        }
        ... on CommissionTypeAccountingConfiguration {
            id
        }
    }
    assigned_as_type
    created_at
    updated_at
`

const defaultAdditionalParams = `
    $balancePostingDateFrom: Date
    $balancePostingDateUntil: Date
`

export const ACCOUNTS_QUERY = buildAccountsQuery(defaultQueryFields, defaultAdditionalParams)
