<template>
    <fake-select
        v-if="state.initialFetch"
        v-bind="$attrs"
    />
    <base-select-filter-next
        v-else
        v-bind="$attrs"

        :value="value"
        :loading="state.isLoading"
        :options="state.options"
        option-value="id"
        option-label="name"

        v-on="$listeners"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" /><!-- Named slots -->
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template><!-- Scoped slots -->
    </base-select-filter-next>
</template>

<script>
import BaseSelectFilterNext from '@/components/form/BaseSelectFilterNext'
import { onBeforeMount, reactive } from 'vue'
import { DocumentTemplate } from '@/models/documentTemplate'
import FakeSelect from '@/components/FakeSelect.vue'

export default {
    name: 'DocumentTemplateSelect',
    components: {
        FakeSelect,
        BaseSelectFilterNext,
    },
    props: {
        value: {
            required: true,
        },
        filters: {
            type: Object,
            default () {
                return {}
            },
        },
    },
    setup (props) {
        // Data
        const state = reactive({
            initialFetch: true,
            isLoading: false,
            options: [],
        })

        // Functions
        async function fetchOptions () {
            state.isLoading = true
            const response = await DocumentTemplate.objects.all(props.filters)

            state.initialFetch = false
            state.isLoading = false
            return response.data
        }

        // Lifecycle hooks
        onBeforeMount(async () => {
            const options = await fetchOptions()
            state.options = options
        })

        return {
            // Data
            state,
        }
    },
}
</script>

<style scoped>

</style>
