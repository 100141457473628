<template>
    <form
        data-test="form:commission-list-manual-entry"
        @submit.prevent="onFormSubmit"
    >
        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="formErrorMessage"
            type="negative"
            data-test="error:global-message"
            v-html="formErrorMessage"
        />
        <!-- eslint-enable -->

        <div class="row q-col-gutter-md">
            <div class="col-xs-12 col-lg-6">
                <div v-if="selectedContract" class="selected-contract-wrapper">
                    <base-input
                        :label="$tc('common.term.contract', 1)"
                        :value="`${selectedContract.currentContractNumber} (${selectedContract.currentContractInformation.product.basicProvider.display_name || selectedContract.currentContractInformation.product.basicProvider.name}) – ${selectedContract.customer.getContactName()}`"
                        readonly
                    >
                        <template v-slot:append>
                            <q-btn
                                icon="mib-remove-circle"
                                flat
                                round
                                size="0.65rem"
                                @click="clearContractId"
                            >
                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.commission.manual-entry.remove-contract', 1) }}</q-tooltip>
                            </q-btn>
                        </template>
                    </base-input>
                </div>
                <template v-else>
                    <fetch-contracts-search
                        :enabled-result-types="['Contract']"
                        :placeholder="$tc('common.term.contract', 1)"
                        :error="$v.formData.contractId.$error"
                        autofocus
                        @open-search-result="setContractId"
                    >
                        <template v-if="$v.formData.contractId.$error" v-slot:error>
                            <div v-if="!$v.formData.contractId.required" class="error-message">{{ $t('common.error-message.required', { field: $tc('common.term.contract', 1) }) }}</div>
                        </template>
                    </fetch-contracts-search>
                </template>
            </div>

            <div class="col-xs-12 col-sm-6 col-lg-3">
                <form-builder item-key="premiumFrom" />
            </div>

            <div class="col-xs-12 col-sm-6 col-lg-3">
                <form-builder item-key="premiumTo" />
            </div>

            <div class="col-xs-12 col-sm-6 col-lg-3">
                <form-builder item-key="commissionType" />
            </div>

            <div class="col-xs-12 col-sm-6 col-lg-3">
                <form-builder item-key="commissionAmount" prefix="CHF" />
            </div>
        </div>

        <in-page-footer>
            <base-button
                primary-button
                flat
                data-test="btn:cancel"
                :label="$t('common.term.cancel')"
                @click="handleCancel"
            />
            <base-button
                primary-button
                type="submit"
                data-test="btn:save"
                :label="currentMode === ViewMode.CREATE ? $t('common.term.create') : $t('common.term.save')"
            />
        </in-page-footer>
    </form>
</template>

<script>
import { ViewMode } from '@/enums'

import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import COMMISSION_LIST_ENTRY_FORM from '@/forms/commissionListEntry.form'
import { required } from 'vuelidate/lib/validators'
import FetchContractsSearch from '@/components/search/FetchContractsSearch'
import BaseInput from '@/components/form/BaseInput'

export default {
    name: 'CommissionListManualEntryForm',
    components: {
        FetchContractsSearch,
        BaseInput,
    },
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: COMMISSION_LIST_ENTRY_FORM,
    },
    props: {
        commissionListEntry: {
            type: Object,
            required: false,
            default () {
                return undefined
            },
        },
    },
    data () {
        const data = {
            ViewMode,
            currentMode: this.commissionListEntry ? ViewMode.UPDATE : ViewMode.CREATE,
            formData: {
                contractId: null,
            },
            selectedContract: null,
        }

        if (data.currentMode === ViewMode.UPDATE) {
            Object.assign(data.formData, {
                contractId: this.commissionListEntry.matchedContract.id,
                premiumFrom: this.commissionListEntry.totalPosition.premium_from || '',
                premiumTo: this.commissionListEntry.totalPosition.premium_to || '',
                commissionType: this.commissionListEntry.totalPosition.commission_type,
                commissionAmount: this.commissionListEntry.totalPosition.commission_amount,
            })
            data.selectedContract = this.commissionListEntry.matchedContract
        }

        return data
    },
    validations () {
        const validations = {
            formData: {
                contractId: {
                    required,
                },
            },
        }
        return validations
    },
    methods: {
        setContractId (searchResult) {
            this.selectedContract = searchResult
            this.formData.contractId = searchResult.id
        },
        clearContractId () {
            this.selectedContract = null
            this.formData.contractId = null
        },
        handleCancel () {
            this.$router.push({ name: 'commission-list-manual-entries' })
        },
    },
}
</script>

<style lang="scss" scoped>
.selected-contract-wrapper {
    ::v-deep .q-field__control {
        padding-right: 6px;
    }
}
</style>
