<template>
    <base-select-filter
        v-if="options.length"

        :value="value"
        v-bind="$attrs"
        :options="options"

        option-value="id"
        :option-label="getOptionLabel"
        @input="inputValue => $emit('input', options.find(option => option.id === inputValue))"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import { CommissionListImportExcelMappingOrderField, OrderDirection } from '@/enums/graphql'
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { CommissionListImportExcelMappingService } from '@/services'

export default {
    name: 'CommissionListImportExcelMappingSelect',
    components: {
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        filter: {
            type: Object,
        },
    },
    data () {
        return {
            options: [],
        }
    },
    created () {
        const variables = {
            orderField: CommissionListImportExcelMappingOrderField.PRODUCT_PROVIDER_NAME,
            orderDirection: OrderDirection.ASC,
        }
        if (this.filter) Object.assign(variables, this.filter)

        CommissionListImportExcelMappingService.all(variables).then(response => {
            this.options = response.data
        })
    },
    methods: {
        getOptionLabel (item) {
            return `${item.productProvider.display_name || item.productProvider.name}: ${item.name}`
        },
    },
}
</script>
