<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.admin.users.add-user', 1)" :to="{ name: 'admin-user-create' }" />
            </template>

            <h1>{{ $tc('views.admin.users.add-user', 1) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12 col-md-7 col-lg-6 col-xl-5">
                <form
                    data-test="form:user-create"
                    class="row q-col-gutter-y-sm"
                    @submit.prevent="onFormSubmit"
                    @keyup.esc="cancel"
                >
                    <!-- eslint-disable vue/no-v-html -->
                    <info-box
                        v-if="formErrorMessage"
                        type="negative"
                        data-test="error:global-message"
                        v-html="formErrorMessage"
                    />
                    <!-- eslint-enable -->

                    <div class="col-xs-12">
                        <form-builder
                            item-key="username"
                            :label="$tc('views.admin.users.create-user.username-field-label', 1)"
                            :autofocus="(!formData.username)"
                            :hint="$t('views.admin.users.create-user.username-hint')"
                        />
                    </div>
                    <div class="col-xs-12">
                        <form-builder item-key="roleIds" />
                    </div>

                    <div class="col-xs-12 q-mt-sm q-gutter-x-sm">
                        <base-button
                            :label="$t('common.term.cancel')"
                            flat
                            primary-button
                            @click="cancel"
                        />
                        <base-button
                            data-test="btn:submit"
                            type="submit"
                            :label="$tc('views.admin.users.add-user', 1)"
                            :disable="formSubmitStatus"
                            :loading="formSubmitStatus"
                            primary-button
                        />
                    </div>
                </form>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { formBuilderMixin } from '@/mixins/formBuilderMixin'

import AUTH_FIELDS from '@/forms/auth.fields.json'
import USER_FIELDS from '@/forms/user.fields.json'

import { User } from '@/models/user'

export default {
    name: 'AdminUserCreate',
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: {
            'type': 'root',
            'children': [
                ...AUTH_FIELDS.children,
                ...USER_FIELDS.children,
            ],
        },
        fields: ['username', 'roleIds'],
    },
    meta () {
        return {
            title: this.$tc('views.admin.users.add-user', 1),
        }
    },
    methods: {
        handleFormData ({ username, roleIds }) {
            User.create(username, roleIds)
                .then(() => {
                    this.$router.replace({ name: 'admin-user-list', params: { tab: 'invited' } })
                    // TODO: Check if success will only show a notification or if the user is shown something else (e.g. the next step in a process, …)
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.user.user-added-success', 1),
                        caption: this.$tc('common.notifications.user.user-added-success-caption', 1, { userEmail: username }),
                        timeout: 10000,
                    })
                })
                .catch(error => {
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$t('common.notifications.something-could-not-be-created', { something: this.$tc('common.term.user', 1) }),
                    })
                    this.showFormErrorMessage(error)
                    this.resetFormSubmitStatus()
                })
        },
        cancel () {
            this.$router.go(-1)
        },
    },
}
</script>
