import { Model } from '@/models'
import { CommissionListImportErrorService } from '@/services'

export class CommissionListImportError extends Model {
    /**
     * Create a CommissionListImportError model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'commissionList',
            'scope',
            'messages',
            'source_entry_number',
            'source_data',
            'parsed_data',
        ], data)
    }

    static get service () { return CommissionListImportErrorService }
}
