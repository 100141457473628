<template>
    <node-view-wrapper :class="['structure-block', { 'selected': selected }]" data-structure-block="subject">
        <span :class="['fake-field-label', 'required', 'q-mb-xs']" contenteditable="false">{{ $tc('common.term.subject', 1) }}</span>

        <!-- TODO @TFU: Add `.has-focus` class to node-view-content element when it is active (document.activeElement)  -->
        <node-view-content
            :class="['structure-block-content', { 'is-empty': !hasContent, 'has-error': (validation.$error && !validation.required) }]"
            :data-placeholder="schemaType ? $t(`common.editor.structure-block.subject.placeholder--${schemaType}`) : $t('common.editor.structure-block.subject.placeholder')"
        />

        <p v-if="validation.$error && !validation.required" class="error-message" contenteditable="false">{{ $tc('common.error-message.required', 1, { field: $tc('common.term.subject', 1) }) }}</p>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-2'

export default {
    name: 'StructureBlockSubjectNodeView',
    components: {
        NodeViewWrapper,
        NodeViewContent,
    },
    props: nodeViewProps,
    computed: {
        validation () {
            return (this.editor.$v) ? this.editor.$v[this.editor.schema.nodes.structureBlock_subject.name] ?? {} : {}
        },
        hasContent () {
            return this.node.content.size > 0
        },
        schemaType () {
            return this.editor.schema.spec.topNode
        },
    },
}
</script>
