<template>
    <!-- TODO @MTR: Fix layout (deafult-/auto-/manual-layout) -->
    <form-builder-next
        :form="AreaOfResponsibilityForm"
        :initial-data="initialData"
        manual-layout
        @submit="formData => $emit('submit', formData)"
        @cancel="$emit('cancel')"
    >
        <template v-slot:default="{ formBuilder }">
            <div class="row q-col-gutter-md">
                <div v-if="infoBoxHandling.showInfoBox" class="col-xs-12">
                    <info-box :type="infoBoxHandling.infoBoxType" no-margin>
                        <p>{{ infoBoxHandling.text }}</p>
                    </info-box>
                </div>

                <div class="col-xs-12 col-md-6">
                    <div class="row q-col-gutter-md">
                        <div class="col-xs-12">
                            <form-builder-next :item="formBuilder.form.getItem('name')" />
                        </div>
                        <div class="col-xs-12">
                            <form-builder-next :item="formBuilder.form.getItem('description')" />
                        </div>
                    </div>
                </div>

                <div class="col-xs-12 col-md-6">
                    <div class="row q-col-gutter-md">
                        <!-- TODO improvement: If at any point the number of relationships should be available earlier (f.e. on the `contact`), the condition below should be changed back to `v-if` (or maybe even `v-if="currentMode === ViewMode.CREATE"`) so that the skeleton is shown again while loading the options. -->
                        <template v-if="state.currentMode === ViewMode.CREATE">
                            <div v-show="contactHasRelationships" class="col-xs-12">
                                <div class="visually-hidden">
                                    <form-builder-next :item="formBuilder.form.getItem('isAnonymous')" disable />
                                </div>
                                <div class="row q-col-gutter-md">
                                    <div class="col-xs-1">
                                        <q-checkbox v-model="state.assignSpecificContact" />
                                    </div>
                                    <div class="col-xs-11">
                                        <form-builder-next
                                            :item="formBuilder.form.getItem('contactRelationshipId')"
                                            :filter="contactRelationshipFilters"
                                            :contact-id="contact.id"
                                            :class="{ 'required': state.assignSpecificContact }"
                                            :disable="!state.assignSpecificContact"
                                            @fetchedObjects="setContactRelationshipOptions"
                                        />
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div v-if="state.currentMode === ViewMode.UPDATE" class="col-xs-12">
                            <div class="row q-col-gutter-md" disabled>
                                <div class="col-xs-1">
                                    <q-checkbox :value="state.assignSpecificContact" disable />
                                </div>
                                <div class="col-xs-11">
                                    <base-input
                                        v-if="selectedContactRelationship || !state.assignSpecificContact"
                                        :label="$tc('forms.field.label.area-of-responsibility.contact-id', 1)"
                                        :class="{ 'required': state.assignSpecificContact }"
                                        :value="formBuilder.form.getItem('contactRelationshipId').value ? `${selectedContactRelationship.getTargetNode(contact.id).getContactName()} – ${selectedContactRelationship.getTargetNodeTypeText(contact.id, { withPreposition: true })} ${selectedContactRelationship.getSourceNode(contact.id).getContactName()}` : $tc('common.contact.relationship.area-of-responsibility.is-anonymous', 1)"
                                        disable
                                    />
                                    <skeleton v-else width="100%" height="40px" />
                                </div>
                            </div>
                            <q-tooltip
                                :delay="1000"
                                anchor="top middle"
                                self="bottom middle"
                                :offset="[0, 0]"
                            >
                                {{ $t('views.area-of-responsibility.list.update-area-of-responsibility--contact-not-editable--info-text') }}
                            </q-tooltip>
                        </div>

                        <div class="col-xs-12">
                            <form-builder-next
                                v-if="state.emailAddressOptionsLoaded"
                                :item="formBuilder.form.getItem('emailAddressId')"
                                :options="emailAddressOptions"
                                :class="{ 'required': !formBuilder.form.getItem('phoneNumberId').value && (!state.assignSpecificContact || state.currentMode === ViewMode.CREATE && !contactHasRelationships)}"
                            />
                            <skeleton v-else width="100%" height="40px" />
                        </div>

                        <div class="col-xs-12">
                            <form-builder-next
                                v-if="state.phoneNumberOptionsLoaded"
                                :item="formBuilder.form.getItem('phoneNumberId')"
                                :options="phoneNumberOptions"
                                :class="{ 'required': !formBuilder.form.getItem('emailAddressId').value && (!state.assignSpecificContact || state.currentMode === ViewMode.CREATE && !contactHasRelationships)}"
                            />
                            <skeleton v-else width="100%" height="40px" />
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:buttons="{ formBuilder }">
            <div class="row q-col-gutter-md q-mt-xs">
                <div class="col-xs-12 text-right">
                    <base-button
                        primary-button
                        flat
                        data-test="btn:cancel"
                        :label="$t('common.term.cancel')"
                        :disable="formBuilder.formSubmitStatus"
                        @click="$emit('cancel')"
                    />

                    <base-button
                        primary-button
                        class="q-ml-sm"
                        data-test="btn:save"
                        type="submit"
                        :label="state.currentMode === ViewMode.CREATE ? (!state.assignSpecificContact || !contactHasRelationships) ? $tc('views.area-of-responsibility.list.create-without-specific-contact', 1) : $t('common.term.create') : $t('common.term.save')"
                        :disable="formBuilder.formSubmitStatus || (!state.assignSpecificContact || !contactHasRelationships) && !contactInfoAvailable"
                        :loading="formBuilder.formSubmitStatus"
                    />
                </div>
            </div>
        </template>
    </form-builder-next>
</template>

<script>
import { computed, onBeforeMount, reactive, ref, watch } from 'vue'
import { ContactRelationshipType } from '@/enums/graphql'
import { ViewMode } from '@/enums'
import { AreaOfResponsibilityForm } from '@/forms/areaOfResponsibility'
import { ContactRelationshipService } from '@/services'
import BaseInput from '@/components/form/BaseInput'
import { useI18n } from '@/composables/i18n'

export default {
    name: 'AreaOfResponsibilityForm',
    components: {
        BaseInput,
    },
    props: {
        contact: {
            type: Object,
            required: true,
        },
        areaOfResponsibility: {
            type: Object,
            default: null,
        },
    },
    setup (props, context) {
        // Composables
        const { t } = useI18n()

        // State
        const state = reactive({
            currentMode: props.areaOfResponsibility ? ViewMode.UPDATE : ViewMode.CREATE,
            contactRelationshipOptions: [],
            contactRelationshipOptionsLoaded: false,
            emailAddressOptionsLoaded: true,
            phoneNumberOptionsLoaded: true,
            assignSpecificContact: true,
        })

        // Template refs
        const formComponent = ref(null)

        // Computed
        const contactRelationshipFilters = computed(() => {
            return {
                search: {
                    node_id: props.contact.id,
                    definition: {
                        types: [ContactRelationshipType.EMPLOYER__EMPLOYEE, ContactRelationshipType.ASSOCIATION__MEMBER],
                    },
                },
            }
        })
        const selectedContactRelationship = computed(() => {
            return state.contactRelationshipOptions.filter(contactRelationship => contactRelationship.id === formComponent.value.formBuilder.form.getItem('contactRelationshipId').value)[0]
        })
        const emailAddressOptions = computed(() => {
            const emailAddresses = props.contact.emailAddresses.map(emailAddress => ({
                emailAddress,
                contact: props.contact.getContactName(),
            }))

            if (selectedContactRelationship.value && selectedContactRelationship.value.getTargetNode(props.contact.id)?.emailAddresses.length) {
                emailAddresses.push(...selectedContactRelationship.value.getTargetNode(props.contact.id).emailAddresses.map(emailAddress => ({
                    emailAddress,
                    contact: selectedContactRelationship.value.getTargetNode(props.contact.id).getContactName(),
                })))
            }

            return emailAddresses.map(entry => ({
                value: entry.emailAddress.id,
                label: `${entry.emailAddress.email} <span class="additional-info">(${entry.contact} – ${entry.emailAddress.category.name}${entry.emailAddress.label ? ' / ' + entry.emailAddress.label.label : ''})</span>`,
            }))
        })
        const phoneNumberOptions = computed(() => {
            const phoneNumbers = props.contact.phoneNumbers.map(phoneNumber => ({
                phoneNumber,
                contact: props.contact.getContactName(),
            }))

            if (selectedContactRelationship.value && selectedContactRelationship.value.getTargetNode(props.contact.id)?.phoneNumbers.length) {
                phoneNumbers.push(...selectedContactRelationship.value.getTargetNode(props.contact.id).phoneNumbers.map(phoneNumber => ({
                    phoneNumber,
                    contact: selectedContactRelationship.value.getTargetNode(props.contact.id).getContactName(),
                })))
            }

            return phoneNumbers.map(entry => ({
                value: entry.phoneNumber.id,
                label: `${entry.phoneNumber.phone_number} <span class="additional-info">(${entry.contact} – ${entry.phoneNumber.category.name}${entry.phoneNumber.label ? ' / ' + entry.phoneNumber.label.label : ''})</span>`,
            }))
        })
        const contactHasRelationships = computed(() => {
            return (state.contactRelationshipOptionsLoaded) ? !!state.contactRelationshipOptions.length : false
        })
        const hasEmailAddressOptions = computed(() => {
            return (state.emailAddressOptionsLoaded) ? !!emailAddressOptions.value.length : false
        })
        const hasPhoneNumberOptions = computed(() => {
            return (state.phoneNumberOptionsLoaded) ? !!phoneNumberOptions.value.length : false
        })
        const allDependenciesLoaded = computed(() => {
            return state.contactRelationshipOptionsLoaded && state.emailAddressOptionsLoaded && state.phoneNumberOptionsLoaded
        })
        const contactInfoAvailable = computed(() => {
            return hasEmailAddressOptions.value || hasPhoneNumberOptions.value
        })
        const infoBoxHandling = computed(() => {
            return {
                showInfoBox: state.currentMode === ViewMode.CREATE && allDependenciesLoaded.value && (!contactHasRelationships.value || (formComponent.value.formBuilder.form.getItem('isAnonymous').value && !contactInfoAvailable.value)),
                infoBoxType: !contactInfoAvailable.value ? !contactHasRelationships.value ? 'negative' : 'warning' : 'info',
                text: t(`views.area-of-responsibility.list.info-text--${!contactHasRelationships.value ? 'no-' : ''}relationships--${!contactInfoAvailable.value ? 'no-' : ''}contact-info`, { contactName: props.contact.getContactName() }),
            }
        })

        // Initial data
        const initialData = {}
        if (state.currentMode === ViewMode.UPDATE) {
            if (props.areaOfResponsibility.contactRelationship) {
                state.emailAddressOptionsLoaded = false
                state.phoneNumberOptionsLoaded = false
            }

            Object.assign(initialData, {
                isAnonymous: props.areaOfResponsibility.is_anonymous,
                contactRelationshipId: props.areaOfResponsibility.contactRelationship?.id || null,
                name: props.areaOfResponsibility.name,
                description: props.areaOfResponsibility.description,
            })

            if (props.areaOfResponsibility.emailAddress) initialData.emailAddressId = props.areaOfResponsibility.emailAddress.id
            if (props.areaOfResponsibility.phoneNumber) initialData.phoneNumberId = props.areaOfResponsibility.phoneNumber.id
        }

        // Watchers
        watch(() => state.contactRelationshipOptionsLoaded, () => {
            if (state.contactRelationshipOptions.length === 0) state.assignSpecificContact = false
        })
        watch(() => state.assignSpecificContact, () => {
            formComponent.value.formBuilder.form.getItem('isAnonymous').value = !state.assignSpecificContact
        })
        watch(() => selectedContactRelationship.value, () => {
            state.emailAddressOptionsLoaded = true
            state.phoneNumberOptionsLoaded = true
        })

        // Functions
        function setContactRelationshipOptions (options) {
            state.contactRelationshipOptions = options
            state.contactRelationshipOptionsLoaded = true
        }

        // Hooks
        onBeforeMount(() => {
            if (state.currentMode === ViewMode.UPDATE && props.areaOfResponsibility.contactRelationship) {
                ContactRelationshipService.get(props.areaOfResponsibility.contactRelationship.id).then(response => setContactRelationshipOptions([response]))
            }
        })

        return {
            // Static
            ViewMode,
            AreaOfResponsibilityForm,

            // State
            state,
            initialData,

            // Template refs
            formComponent,

            // Computed
            contactRelationshipFilters,
            selectedContactRelationship,
            emailAddressOptions,
            phoneNumberOptions,
            contactHasRelationships,
            hasEmailAddressOptions,
            hasPhoneNumberOptions,
            allDependenciesLoaded,
            contactInfoAvailable,
            infoBoxHandling,

            // Functions
            setContactRelationshipOptions,
        }
    },
}
</script>
