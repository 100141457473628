<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.correspondence.letter.letter', 2)" :to="{ name: 'correspondence-letters' }" />
                <q-breadcrumbs-el :label="$tc('common.correspondence.letter.created-letter', 2)" :to="{ name: 'processed-correspondence-letter-list' }" />
            </template>

            <h1 class="q-mb-none">{{ $tc('common.correspondence.letter.created-letter', 2) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-12">
                <base-table
                    :columns="columns"
                    :fetch-objects-fn="CorrespondenceService.items.processed"
                    :additional-filters="additionalFilters"
                    enable-visible-columns
                    user-settings-base-path="ProcessedCorrespondenceLetterList"
                    pagination-sort-by-default-key="updated_at"
                    :pagination-initial-order-direction-descending="true"
                >
                    <template v-slot:body-cell-subject="props">
                        <q-td
                            :props="props"
                            class="subject-td"
                            data-test="td:subject"
                            auto-width
                        >
                            {{ props.row.subject }}
                            <q-tooltip
                                v-if="props.row.subject"
                                anchor="center middle"
                                self="center start"
                                :delay="2000"
                                :offset="[0, 10]"
                            >
                                {{ props.row.subject }}
                            </q-tooltip>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-recipient_address="props">
                        <q-td :props="props" class="recipient-address-td" data-test="td:recipient-address">
                            {{ props.row.recipient_address }}
                            <q-tooltip
                                v-if="props.row.recipient_address"
                                anchor="center middle"
                                self="center start"
                                :delay="2000"
                                :offset="[0, 10]"
                            >
                                {{ props.row.recipient_address }}
                            </q-tooltip>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-status="slotProps">
                        <q-td :props="slotProps">
                            <status-badge
                                v-if="slotProps.row.status_message"
                                :status="slotProps.row.status"
                                default-translation-base-path="common.status.correspondence-item-status"
                                :info-text="$t(`common.status.code.${slotProps.row.status_message.type}.${slotProps.row.status_message.code.replace('_', '.')}`)"
                            />
                            <status-badge
                                v-else
                                :status="slotProps.row.status"
                                default-translation-base-path="common.status.correspondence-item-status"
                            />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-actions="slotProps">
                        <q-td :props="slotProps" auto-width>
                            <!-- TODO @TFU: Implement delete functionality -->
                            <!--<q-btn-->
                            <!--    color="primary"-->
                            <!--    size="sm"-->
                            <!--    flat-->
                            <!--    round-->
                            <!--    icon="mib-bin"-->
                            <!--&gt;-->
                            <!--    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.correspondence.letter.delete-letter', 1) }}</q-tooltip>-->
                            <!--</q-btn>-->

                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-navigation-next-alternate"
                                :to="slotProps.row.targetObject ? { name: `${getTargetObjectType(slotProps.row.targetObject).toLowerCase()}-detail`, params: { id: slotProps.row.targetObject.id, contactId: slotProps.row.targetObject.customer?.id, tab: 'documents' } } : null"
                                :disable="!slotProps.row.targetObject"
                            >
                                <q-tooltip v-if="slotProps.row.targetObject" :delay="1000" :offset="[0, 10]">{{ $tc(`common.${getTargetObjectType(slotProps.row.targetObject).toLowerCase()}.go-to-${getTargetObjectType(slotProps.row.targetObject).toLowerCase()}`, 1) }}</q-tooltip>
                                <q-tooltip v-else :delay="1000" :offset="[0, 10]">{{ $t('common.target-object.target-object--no-target-object-available') }}</q-tooltip>
                            </q-btn>
                        </q-td>
                    </template>
                </base-table>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { CorrespondenceService } from '@/services/correspondence'
import { ProcessedCorrespondenceItemType, CorrespondenceItemStatus } from '@/enums/graphql'
import BaseTable from '@/components/BaseTable'
import StatusBadge from '@/components/StatusBadge.vue'

export default {
    name: 'ProcessedCorrespondenceLetterList',
    components: {
        BaseTable,
        StatusBadge,
    },
    meta () {
        return {
            title: this.$tc('common.correspondence.letter.created-letter', 2),
        }
    },
    props: {
        statusGroup: {
            type: String,
            default: 'pending',
        },
    },
    data () {
        return {
            activeStatusGroup: this.statusGroup,
            CorrespondenceService,
            CorrespondenceItemStatus,
            columns: [
                {
                    name: 'subject',
                    label: this.$tc('common.correspondence.letter.subject', 1),
                    field: row => row.subject,
                    sortable: true,
                    align: 'left',
                    required: true,
                },
                {
                    name: 'recipient_address',
                    label: this.$tc('common.term.recipient', 1),
                    field: row => row.recipient_address,
                    classes: 'truncate',
                    align: 'left',
                    required: true,
                },
                {
                    name: 'target_object_type',
                    label: this.$tc('common.correspondence.target-object.target-object-type', 1),
                    field: row => (row.targetObject) ? this.$tc(`common.term.${this.getTargetObjectType(row.targetObject).toLowerCase()}`, 1) : '—',
                    align: 'left',
                    hideInitially: true,
                },
                {
                    name: 'language_name',
                    label: this.$tc('common.term.language'),
                    field: row => row.language.name,
                    align: 'left',
                    hideInitially: true,
                },
                {
                    name: 'creator_name',
                    label: this.$t('common.term.created-by'),
                    field: row => (row.creator) ? row.creator.getContactName({ consultingCompanyName: !row.creator.worksForUserConsultingCompany }) : '—', // TODO improvement @MTR: Maybe display a tooltip with possible reasons why?
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'sender_name',
                    label: this.$tc('common.term.sender', 1),
                    field: row => (row.sender) ? row.sender.getContactName({ consultingCompanyName: !row.sender.worksForUserConsultingCompany }) : '—', // TODO improvement @MTR: Maybe display a tooltip with possible reasons why?
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'updated_at',
                    label: this.$tc('common.term.updated--last-updated', 1),
                    field: row => row.formattedUpdatedAt,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'status',
                    label: this.$tc('common.term.status'),
                    field: row => row.status,
                    align: 'left',
                    required: true,
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left', required: true },
            ],
        }
    },
    computed: {
        additionalFilters () {
            const filters = {
                filterProcessedCorrespondenceItemType: ProcessedCorrespondenceItemType.LETTER,
            }

            // switch (this.activeStatusGroup) {
            //     case 'pending':
            //         filters.filterStatus = [
            //             CorrespondenceItemStatus.DRAFT,
            //             CorrespondenceItemStatus.PROCESSING,
            //             CorrespondenceItemStatus.PROCESSED,
            //             CorrespondenceItemStatus.FAILED,
            //         ]
            //         break
            //     case 'completed':
            //         filters.filterStatus = [
            //             CorrespondenceItemStatus.DONE,
            //             CorrespondenceItemStatus.CANCELLED,
            //         ]
            //         break
            // }

            return filters
        },
    },
    watch: {
        '$route' (to) {
            this.activeStatusGroup = to.params.statusGroup || this.statusGroup
        },
    },
    methods: {
        getTargetObjectType (targetObject) {
            return ['Person', 'Company'].includes(targetObject.__typename) ? 'Contact' : targetObject.__typename
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep {
    @include mq($from: $sizeBreakpointMd) {
        .subject-td,
        .recipient-address-td {
            max-width: 20vw;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    @include mq($from: $sizeBreakpointXl) {
        .subject-td,
        .recipient-address-td {
            max-width: 15vw;
        }
    }
}
</style>
