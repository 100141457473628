import gql from 'graphql-tag'

export const CONTRACT_CREATE_MUTATION = gql`
    mutation contractCreate(
        $contract: ContractInputCreate!
    ) {
        contractCreate(
            contract: $contract
        ) {
            id
            contractNumbers {
                number
            }
            consultant {
                id
                contact_number
                first_name
                last_name
                gender
                title
                consultingCompany {
                    id
                }
                consulting_company_name
                consulting_settings_status
            }
        }
    }
`
