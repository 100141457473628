import { Model } from '@/models'
import { CorrespondenceLetterService } from '@/services'
import { CorrespondenceLetterActions } from '@/enums'
import { CorrespondenceItemType, CorrespondenceItemTargetObjectType, CorrespondenceItemStatus } from '@/enums/graphql'
import { wrapCorrespondenceItemTargetObject } from '@/helpers/correspondence'
import { saveAs } from 'file-saver'
import { decode } from 'base64-arraybuffer'

export class CorrespondenceLetter extends Model {
    /**
     * Create a CorrespondenceLetter model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'creator',
            'status',
            'name',
            'target_object_type',
            'target_object_ids',
            'targetContacts',
            'targetApplications',
            'targetContracts',
            'language',
            'confidentiality_notice',
            'attention_ofs',
            'address_overrides',
            'sender_overrides',
            'differentiate_formality',
            'contents',
            'printPDF',
        ], data)
        this.type = CorrespondenceItemType.LETTER
    }

    static get service () { return CorrespondenceLetterService }
    static action = CorrespondenceLetterActions

    get actions () {
        const actions = super.actions

        if (![CorrespondenceItemStatus.PROCESSING, CorrespondenceItemStatus.PROCESSED].includes(this.status)) {
            actions.push({
                key: CorrespondenceLetterActions.DELETE,
                item: this,
            })
        }

        return actions
    }

    /**
     * Create a new correspondenceLetter.
     * @param {Object} variables - The values used to create the correspondenceLetter.
     * @param {string} variables.targetObjectType
     * @param {string} variables.name
     * @param {string} variables.targetObjectIds
     * @param {string} variables.languageId
     * @param {string} variables.confidentialityNotice
     * @param {string} variables.attentionOfs
     * @param {string} variables.addressOverrides
     * @param {string} variables.senderOverrides
     * @param {string} variables.differentiateFormality
     * @param {string} variables.contents
     * @returns {Promise<*>}
     */
    static create ({ targetObjectType, name, targetObjectIds, languageId, confidentialityNotice, attentionOfs, addressOverrides, senderOverrides, differentiateFormality, contents }) {
        const variables = {
            targetObjectType,
            correspondenceLetter: {
                name,
                target_object_ids: targetObjectIds,
                language_id: languageId,
                confidentiality_notice: confidentialityNotice ?? null,
                differentiate_formality: differentiateFormality,
                contents,
            },
        }

        if (typeof attentionOfs !== 'undefined' && attentionOfs !== null) variables.correspondenceLetter.attention_ofs = attentionOfs
        if (typeof addressOverrides !== 'undefined' && addressOverrides !== null) variables.correspondenceLetter.address_overrides = addressOverrides
        if (typeof senderOverrides !== 'undefined' && senderOverrides !== null) variables.correspondenceLetter.sender_overrides = senderOverrides

        return CorrespondenceLetterService.create(variables)
    }

    /**
     * Update this correspondenceLetter.
     * @param {Object} variables - The values used to update the correspondenceLetter.
     * @param {string} variables.name
     * @param {string} variables.targetObjectIds
     * @param {string} variables.languageId
     * @param {string} variables.confidentialityNotice
     * @param {string} variables.attentionOfs
     * @param {string} variables.addressOverrides
     * @param {string} variables.senderOverrides
     * @param {string} variables.differentiateFormality
     * @param {string} variables.contents
     * @returns {Promise<*>}
     */
    update ({ name, targetObjectIds, languageId, confidentialityNotice, attentionOfs, addressOverrides, senderOverrides, differentiateFormality, contents }) {
        const correspondenceLetterVariables = {
            name,
            target_object_ids: targetObjectIds,
            language_id: languageId,
            confidentiality_notice: confidentialityNotice ?? null,
            differentiate_formality: differentiateFormality,
            contents,
        }

        if (typeof attentionOfs !== 'undefined' && attentionOfs !== null) correspondenceLetterVariables.attention_ofs = attentionOfs
        if (typeof addressOverrides !== 'undefined' && addressOverrides !== null) correspondenceLetterVariables.address_overrides = addressOverrides
        if (typeof senderOverrides !== 'undefined' && senderOverrides !== null) correspondenceLetterVariables.sender_overrides = senderOverrides

        return CorrespondenceLetterService.update(this.id, correspondenceLetterVariables).then(correspondenceLetter => {
            Object.assign(this, correspondenceLetter)
            return correspondenceLetter
        })
    }

    /**
     * Delete this correspondenceLetter.
     * @returns {Promise<*>}
     */
    delete () {
        return CorrespondenceLetterService.delete(this.id)
    }

    /**
     * Generates a preview PDF of this letter's content by calling a microservice via the API.
     * @returns {Promise<*>}
     */
    preview (targetObjectId) {
        return CorrespondenceLetterService.createPreview(this.id, targetObjectId).then(response => {
            saveAs(new Blob([decode(response.content)], { type: 'application/pdf' }), `${response.subject} – Preview.pdf`)
        })
    }

    /**
     * Processes the content and creates the letter.
     * @returns {Promise<*>}
     */
    startProcessing () {
        return CorrespondenceLetterService.startProcessing(this.id)
    }

    /**
     * Get the appropriate targetObjects based on the `target_object_type`.
     * Returns a wrapper object that has its prototype set to the corresponding targetObject.
     * Mixes in some helper properties/functions and a reference to this `CorrespondenceItem`.
     */
    get targetObjects () {
        let targetObjects

        switch (this.target_object_type) {
            case CorrespondenceItemTargetObjectType.CONTACT:
                targetObjects = this.targetContacts
                break
            case CorrespondenceItemTargetObjectType.APPLICATION:
                targetObjects = this.targetApplications
                break
            case CorrespondenceItemTargetObjectType.CONTRACT:
                targetObjects = this.targetContracts
                break
        }

        if (typeof targetObjects !== 'undefined') {
            const additionalProps = { creatorId: this.creator.id }
            return targetObjects.map(targetObject => {
                const wrappedTargetObject = wrapCorrespondenceItemTargetObject(targetObject, this.target_object_type, additionalProps)
                if (this.attention_ofs !== null && typeof this.attention_ofs[wrappedTargetObject.recipient.id] !== 'undefined') wrappedTargetObject.attentionOf = this.attention_ofs[wrappedTargetObject.recipient.id]
                if (this.address_overrides !== null && typeof this.address_overrides[wrappedTargetObject.recipient.id] !== 'undefined') wrappedTargetObject.addressOverrideId = this.address_overrides[wrappedTargetObject.recipient.id]
                if (this.sender_overrides !== null && typeof this.sender_overrides[wrappedTargetObject.recipient.id] !== 'undefined') wrappedTargetObject.senderOverrideId = this.sender_overrides[wrappedTargetObject.recipient.id]
                return wrappedTargetObject
            })
        } else {
            return null
        }
    }
}
