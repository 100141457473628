<template>
    <page-wrapper>
        <page-header>
            <h1>Wizard</h1>
        </page-header>

        <h2>Step: {{ wizard.currentStepIndex }}, {{ wizard.currentStep }}</h2>
        <wizard-component :wizard="wizard">
            <template v-slot:step-first>
                <h3>Hi from Step 1</h3>
                <!-- TODO: Manually hook up form submit and advance step. -->
                <!--<form-component @submit="formData => wizard.setFormData(1, formData)" />-->
                <contract-select-product-form @submit="getProduct" />
                <p><base-button label="setData" @click="wizard.handleFormSubmit({ ding: 'dong' })" /></p>
            </template>
            <template v-slot:step-second>
                <h3>Hi from Step 2</h3>
                <p><base-button label="setData" @click="wizard.handleFormSubmit({ giga: 'gaga' })" /></p>
            </template>
            <template v-slot:step-third><h3>Ringelingdong!</h3></template>
        </wizard-component>

        <in-page-footer>
            <base-button
                label="Previous step"
                @click="wizard.previousStep()"
            />
            <base-button
                label="Next step"
                @click="wizard.nextStep()"
            />
            <base-button
                label="Reset"
                @click="wizard.reset()"
            />
            <base-button
                label="Log formData"
                @click="logFormData()"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import ContractSelectProductForm from '@/components/contract/ContractSelectProductForm'
import WizardComponent from '@/libs/wizard/components/WizardComponent'
import { FormWizard, FormWizardStep } from '@/libs/form/wizard'
import { Product } from '@/models/product'

export default {
    name: 'DevLibsWizard',
    components: {
        WizardComponent,
        ContractSelectProductForm,
    },
    setup () {
        const wizard = new FormWizard([
            new FormWizardStep('first',{ title: 'Step 1' }),
            new FormWizardStep('second', { title: 'Step 2' }),
            new FormWizardStep('third', { title: 'Step 3' }),
        ])

        function logFormData () {
            console.log(wizard.formData)
        }

        let myProduct = null
        function getProduct (formData) {
            wizard.getCurrentStep().setData(formData)

            Product.objects.get(formData.productId).then(product => {
                myProduct = product
                wizard.nextStep()
            })
        }

        return {
            wizard,
            logFormData,
            getProduct,
        }
    },
}
</script>
