import gql from 'graphql-tag'

export const CONTRACT_CHANGE_STATUS_MUTATION = gql`
    mutation contractChangeStatus(
        $id: ID!
        $status: ContractStatus!
        $terminationDate: Date
        $consultantId: ID
    ) {
        contractChangeStatus(
            id: $id
            status: $status
            termination_date: $terminationDate
            consultant_id: $consultantId
        ) {
            id
            status
            termination_date
            consultant {
                id
                contact_number
                first_name
                last_name
                gender
                title
                consultingCompany {
                    id
                }
                consulting_company_name
                consulting_settings_status
            }
        }
    }
`
