import { PostingTypeRounding } from '@/enums'

const postingTypeRoundingOptions = Object.values(PostingTypeRounding).map(value => ({
    value: value,
    label: value,
}))

export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'postingDate',
            label: 'common.accounting.posting.date',
            inputType: 'calendar',
            validators: [
                {
                    type: 'date',
                },
                {
                    type: 'required',
                },
            ],
        },
        {
            type: 'field',
            key: 'postingType',
            label: 'common.accounting.posting.type',
            inputType: 'postingType',
            validators: [
                {
                    type: 'required',
                },
            ],
        },
        {
            type: 'field',
            key: 'postingTypeRounding',
            label: 'common.accounting.posting.rounding',
            prefix: 'CHF',
            inputType: 'select',
            extra_attributes: {
                list: {
                    options: postingTypeRoundingOptions,
                },
            },
        },
    ],
}
