<template>
    <div
        v-if="editor"
        class="editor-wrapper"
        :data-display-mode="toKebabCase(displayMode)"
        :data-schema-type="toKebabCase(value.schemaType)"
    >
        <q-resize-observer debounce="100" @resize="onResize" />
        <div class="row q-col-gutter-md">
            <div v-if="showFullEditor && !readonly" :class="['editor-menu-bar-wrapper col-xs-12 do-not-print', { 'sticky': stickyMenuBar }]">
                <menu-bar
                    :editor="editor"
                    :schema-type="value.schemaType"
                    :display-mode="displayMode"
                    :readonly="readonly"
                >
                    <template v-slot:menuBarMoreMenu>
                        <slot name="menuBarMoreMenu" />
                    </template>
                </menu-bar>
            </div>
            <div :class="['editor-content-wrapper col-xs-12', { 'col-md-8 col-lg-9': (showFullEditor && (smartObjectTreeDisplayMode === SmartObjectTreeDisplayMode.IN_PAGE && !readonly)), 'has-error': $attrs.error }]">
                <!-- TODO improvement @MTR: Finalize label styling -->
                <span v-if="displayMode === EditorDisplayMode.MINIMAL" class="fake-field-label">{{ $attrs.label }}</span><!-- TODO @TFU/@MTR: Refactor this. It could also be that we render the editor in EditorDisplayMode.FULL mode with FormBuilder without StructureBlocks (e.g. Tasks, Comments). -->
                <editor-content :editor="editor" />
                <slot v-if="$attrs.error" name="error" />
            </div>
            <div
                v-if="smartObjectTreeDisplayMode === SmartObjectTreeDisplayMode.IN_PAGE && !readonly"
                :class="['smart-object-tree-wrapper col-xs-12 col-md-4 col-lg-3 do-not-print', { 'sticky': stickyMenuBar }]"
            >
                <smart-object-tree :smart-object-valid-target-object-types="smartObjectValidTargetObjectTypes" :valid-schema-type="value.schemaType" />
            </div>
        </div>
    </div>
</template>

<script>
import { EditorContentSchemaType, generateText } from '@max/tiptap-extensions'
import { EditorDisplayMode, SmartObjectTreeDisplayMode } from '@/enums'
import { walkJSON } from '@/helpers'
import kebabCase from 'lodash.kebabcase'
import { Editor, EditorContent } from '@tiptap/vue-2'

import MenuBar from '@/components/editor/MenuBar'
import SmartObjectTree from '@/components/editor/SmartObjectTree'

import { Letter } from '@/editor/schemaTypes/Letter'
import { Email } from '@/editor/schemaTypes/Email'
import { Salutation } from '@/editor/schemaTypes/Salutation'
import { SimpleRichText } from '@/editor/schemaTypes/SimpleRichText'
import { StructureBlockSelectAll } from '@/editor/extensions/StructureBlockSelectAll'
import { StructureBlockTabbing } from '@/editor/extensions/StructureBlockTabbing'

import { EditorContent as EditorContentModel } from '@/models/models'

import {
    generalSmartObjectItems,
    recipientPersonOnlySmartObjectItems,
    recipientCompanyOnlySmartObjectItems,
    recipientMixedSmartObjectItems,
    senderSmartObjectItems,
    contractSmartObjectItems,
    applicationSmartObjectItems
} from '@/editor/smartObjects/smartObject.items'

export default {
    name: 'Editor',
    components: {
        EditorContent,
        MenuBar,
        SmartObjectTree,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        validatorConfiguration: {
            type: Object,
            default: () => {
                return {}
            },
        },
        validationDebounceTimeout: {
            type: Number,
            default: 500,
        },
        smartObjectValidTargetObjectTypes: {
            type: Array,
            default: () => {
                return []
            },
        },
        displayMode: {
            type: String,
            default: EditorDisplayMode.FULL,
        },
        stickyMenuBar: {
            type: Boolean,
            default: false,
        },
        smartObjectTreeDisplayMode: {
            type: String,
            default: SmartObjectTreeDisplayMode.IN_PAGE,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        const extensions = []

        switch (this.value.schemaType) {
            case EditorContentSchemaType.LETTER:
                extensions.push(Letter.configure({ blockEditorComponentInstance: this }), StructureBlockSelectAll, StructureBlockTabbing)
                break
            case EditorContentSchemaType.EMAIL:
                extensions.push(Email.configure({ blockEditorComponentInstance: this }), StructureBlockSelectAll, StructureBlockTabbing)
                break
            case EditorContentSchemaType.SALUTATION:
                extensions.push(Salutation.configure({ blockEditorComponentInstance: this }))
                break
            case EditorContentSchemaType.SIMPLE_RICH_TEXT:
                extensions.push(SimpleRichText)
                break
        }

        const editor = new Editor({
            extensions,
            content: this.value.contentJSON,
            onUpdate: this.onUpdate,
            editable: !this.readonly,
        })

        const data = {
            EditorDisplayMode,
            SmartObjectTreeDisplayMode,
            editor,
            validationNodeNames: Object.keys(this.validatorConfiguration),
            validationTimeout: null,
        }
        data.validationNodeNames.forEach(validationNodeName => (data[validationNodeName] = ''))

        return data
    },
    validations () {
        const validations = {}
        this.validationNodeNames.forEach(validationNodeName => (validations[validationNodeName] = this.validatorConfiguration[validationNodeName]))
        return validations
    },
    computed: {
        filteredSmartObjectItems () {
            const smartObjectItems = [
                ...generalSmartObjectItems,
                ...recipientPersonOnlySmartObjectItems,
                ...recipientCompanyOnlySmartObjectItems,
                ...recipientMixedSmartObjectItems,
                ...senderSmartObjectItems,
                ...applicationSmartObjectItems,
                ...contractSmartObjectItems,
            ]

            let filteredSmartObjectItems = smartObjectItems.filter(smartObjectItem => {
                return smartObjectItem.validSchemaTypes.includes(this.value.schemaType)
            })

            if (this.smartObjectValidTargetObjectTypes.length) {
                return filteredSmartObjectItems.filter(smartObjectItem => {
                    return smartObjectItem.validTargetObjectTypes.some(validTargetObjectType => {
                        return this.smartObjectValidTargetObjectTypes.includes(validTargetObjectType)
                    })
                })
            } else {
                return filteredSmartObjectItems
            }
        },
        showFullEditor () {
            return (this.displayMode === EditorDisplayMode.FULL || this.displayMode === EditorDisplayMode.COMPACT)
        },
    },
    mounted () {
        if (this.validationNodeNames.length) {
            const editorContent = this.getEditorContent(this.editor)

            Object.assign(this, this.extractValidationNodeValues(this.editor.schema, editorContent.contentJSON))
            this.editor.$v = this.$v

            editorContent.$v = this.$v
            this.$emit('input', editorContent)
        }
    },
    beforeDestroy () {
        this.editor.destroy()
    },
    methods: {
        getEditorContent (editor) {
            return EditorContentModel.create({
                schemaType: this.value.schemaType,
                contentJSON: editor.getJSON(),
                contentHTML: editor.getHTML(),
                attrs: this.value.attrs,
            })
        },
        onUpdate ({ editor }) {
            // Validation
            if (this.validationNodeNames.length) {
                if (this.validationTimeout) this.validationTimeout = clearTimeout(this.validationTimeout)
                this.validationTimeout = setTimeout(this.validate, this.validationDebounceTimeout)
            } else {
                this.$emit('input', this.getEditorContent(editor))
            }
        },
        extractValidationNodeValues (schema, contentJSON) {
            const extractedValues = {}
            const extractedNodes = []

            if (this.validationNodeNames.length) {
                walkJSON(contentJSON, (value, key, node) => {
                    if (key === 'type' && this.validationNodeNames.includes(value)) {
                        extractedValues[value] = generateText(schema, node)
                        extractedNodes.push(value)
                        if (extractedNodes.length === this.validationNodeNames.length) return false // Abort once content of all nodes is extracted.
                    }
                })
            }

            return extractedValues
        },
        validate () {
            const editorContent = this.getEditorContent(this.editor)

            const extractedValues = this.extractValidationNodeValues(this.editor.schema, editorContent.contentJSON)
            Object.keys(extractedValues).forEach(nodeKey => {
                if (extractedValues[nodeKey] !== this[nodeKey]) {
                    this[nodeKey] = extractedValues[nodeKey]
                    this.$v[nodeKey].$touch()
                }
            })

            editorContent.$v = this.$v
            this.$emit('input', editorContent)
        },
        toKebabCase: function (text) {
            return kebabCase(text)
        },
        onResize (size) {
            // Correct values for existing elements will be set initially and onResize (<q-resize-observer>)
            let editorMenuBarHeight = '0px'
            let editorContentWrapperHeight = '310px'
            let editorContentHeight = '310px'
            let smartObjectTreeHeaderHeight = '0px'

            const editorMenuBar = this.$el.querySelector('.editor-menu-bar-wrapper')
            const editorContentWrapper = this.$el.querySelector('.editor-content-wrapper')
            const editorContent = this.$el.querySelector('.editor-content-wrapper > div')
            const smartObjectTreeHeader = this.$el.querySelector('.smart-object-tree-header')

            if (editorMenuBar) editorMenuBarHeight = getComputedStyle(editorMenuBar).height
            if (editorContentWrapper) editorContentWrapperHeight = getComputedStyle(editorContentWrapper).height
            if (editorContent) editorContentHeight = getComputedStyle(editorContent).height
            if (smartObjectTreeHeader) smartObjectTreeHeaderHeight = getComputedStyle(smartObjectTreeHeader).height

            this.$el.style.setProperty('--size-editor-menu-bar-height', editorMenuBarHeight)
            this.$el.style.setProperty('--size-editor-content-wrapper-height', editorContentWrapperHeight)
            this.$el.style.setProperty('--size-editor-content-height', editorContentHeight)
            this.$el.style.setProperty('--size-smart-object-tree-header-height', smartObjectTreeHeaderHeight)
        },
    },
}
</script>

<style lang="scss" scoped>
.editor-wrapper {
    // Adjust spacing for "simple" editors
    &[data-schema-type="simple-rich-text"] {
        .editor-content-wrapper > div {
            padding: 0 $sizeSpacingSm $sizeSpacingXs;
        }
    }
}

</style>

