<template>
    <component :is="component" class="form-item-repeater">
        <slot :item="item"></slot>
        <slot name="buttons" :item="item"><base-button label="Add" @click="item.addItem()" /></slot>
    </component>
</template>

<script>
import { FormRepeater } from '@/libs/form'

export default {
    name: 'FormRepeaterComponent',
    props: {
        item: {
            type: FormRepeater,
            required: true,
        },
        component: {
            type: String,
            default: 'div',
        },
    },
}
</script>
