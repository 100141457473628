<template>
    <page-wrapper>
        <page-header>
            <!-- TODO: Add breadcrumbs -->
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.product.product-template', 2)" :to="{ name: 'admin-product-template-list' }" />
                <q-breadcrumbs-el :label="$t('views.admin.products.product-templates.add-product-template')" :to="{ name: 'admin-product-create' }" />
            </template>

            <h1>{{ $t('views.admin.products.product-templates.add-product-template') }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12 col-md-7 col-lg-6 col-xl-5 q-gutter-y-sm">
                <product-template-form
                    v-if="prefillCheckDone"
                    ref="productTemplateForm"
                    :product-template="productTemplate"
                    @submit="onSubmit"
                />
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import ProductTemplateForm from '@/components/product/ProductTemplateForm.vue'
import { ProductTemplate } from '@/models/productTemplate'

export default {
    name: 'ProductTemplateCreate',
    meta () {
        return {
            title: this.$t('views.admin.products.product-templates.add-product-template'),
        }
    },
    components: {
        ProductTemplateForm,
    },
    data () {
        return {
            productTemplate: null,
            prefillCheckDone: false,
        }
    },
    created () {
        if (this.$route.query?.prefill) {
            ProductTemplate.objects.get(this.$route.query.prefill)
                .then(productTemplate => {
                    this.productTemplate = productTemplate
                })
                .catch(() => {
                    this.prefillCheckDone = true
                })
                .finally(() => {
                    this.prefillCheckDone = true
                })
        } else {
            this.prefillCheckDone = true
        }
    },
    methods: {
        onSubmit ({ name, productCategory, templateConfiguration }) {
            ProductTemplate.create(name, productCategory.category, productCategory.subCategory, templateConfiguration)
                .then(() => {
                    this.$router.replace({ name: 'admin-product-template-list' })
                })
                .catch(error => {
                    this.$refs.productTemplateForm.showFormErrorMessage(error)
                    this.$refs.productTemplateForm.resetFormSubmitStatus()
                })
        },
    },
}
</script>
