import { Model } from '@/models'

import { ConsultingSettingsStatus, ContactType } from '@/enums/graphql'
import { getContactName } from '@/helpers/contact'

export class ConsultingCompany extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'contact_number',
            'company_name',
            // Timestamps
            'created_at',
            'updated_at',
        ], data)
        this.type = ContactType.COMPANY
    }

    get isActive () {
        return this.consulting_settings_status === ConsultingSettingsStatus.ACTIVE
    }

    getContactName (args) {
        return getContactName(this, args)
    }
}
