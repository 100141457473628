<template>
    <!--
        TODO @TFU:
        [x] "Confirm contract" throws an error ("TypeError: Cannot read properties of null (reading 'id')")
        [x] Polling when posting has been started (update queued/posted, …)
        [x] Handling of selection depending on status (f.e. not selectable if status is QUEUED_FOR_POSTING or POSTED)
        [x] Refactor markup of `CommissionListEntryPostingPreviewOverlay` to not use `form-dialog` component
        [ ] Check if amounts in `total_amount_of_imported_commission_grouped_by_commission_type` can also be rounded (same as `total_amount_of_imported_commission`)
        [ ] Handling of entries that have an amount of `0` – those can never be posted but still get `ready_to_post`. a) Handling of status. / b) How should we treat this in the UI?
        [ ] Show actual postings for POSTED entries instead of preview.
    -->
    <page-loading-indicator
        v-if="!commissionList"
        :number-of-breadcrumb-elements="2"
        actions
        no-more-actions-button
    />
    <page-wrapper v-else>
        <page-header
            :progress-bar="isImportingOrProcessing || isPosting"
            progress-bar-indeterminate
        >
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.commission.commission-list.commission-list', 2)" :to="{ name: 'commission-lists' }" />
                <q-breadcrumbs-el :label="pageTitle" :to="{ name: 'commission-list-detail', params: { id: commissionList.id } }">
                    <status-badge :status="commissionList.status" default-translation-base-path="common.status.commission-list-status" />
                </q-breadcrumbs-el>
            </template>

            <h1>{{ pageTitle }}</h1>

            <template v-slot:actions>
                <actions
                    :actions="commissionList.actions"
                    @click="handleActions"
                />

                <!-- Start processing CommissionList -->
                <form-dialog
                    ref="startProcessingDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleStartProcessing"
                >
                    <template v-slot:title>{{ $tc('views.commission.commission-list.start-processing', 1) }}</template>
                    <template v-slot:default>
                        <p>{{ $tc('views.commission.commission-list.start-processing--confirm', 1) }}</p>
                    </template>
                </form-dialog>

                <!-- Cancel processing CommissionList -->
                <form-dialog
                    ref="cancelProcessingDialog"
                    dont-close-on-confirm
                    @confirm="handleCancelProcessing"
                >
                    <template v-slot:title>{{ $tc('views.commission.commission-list.cancel-processing', 1) }}</template>
                    <template v-slot:default>
                        <p>{{ $tc('views.commission.commission-list.cancel-processing--confirm', 1) }}</p>
                    </template>
                </form-dialog>

                <!-- Delete CommissionList -->
                <form-dialog
                    ref="deleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleDelete"
                >
                    <template v-slot:title>{{ $tc('views.commission.commission-list.delete-list', 1) }}</template>
                    <template v-slot:default>
                        <p>{{ $tc('views.commission.commission-list.delete-list--confirm', 1) }}</p>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <info-box :type="statusBoxType" max-height="25em">
                    <p>
                        <b>{{ $tc(`common.status.commission-list-status.${kebabCase(commissionList.status)}`, 1) }}: </b>
                        {{ $tc(`views.commission.commission-list.status-message.${kebabCase(commissionList.status)}`, ([CommissionListStatus.PROCESSING, CommissionListStatus.PROCESSED, CommissionListStatus.POSTED].includes(commissionList.status) ? commissionList.total_number_of_imported_commission_entries : 1)) }}
                    </p>
                </info-box>
            </div>
        </div>

        <div class="row q-col-gutter-md">
            <grid-card :columns="hasCommissionInfo ? { xs: 12, sm: 6, lg: 6 } : { xs: 12 }">
                <dl class="q-mb-none">
                    <dt>{{ $tc('common.product-provider.product-provider', 1) }}: </dt>
                    <dd>{{ commissionList.productProvider.display_name || commissionList.productProvider.name }}</dd>
                    <br>
                    <dt>{{ $tc('common.file.file-name', 1) }}: </dt>
                    <dd>{{ commissionList.filename }} <info-icon :text="fileInfo" tooltip-max-width="52ch" /></dd>

                    <template v-if="commissionList.exchange_date_from || commissionList.exchange_date_to">
                        <br>
                        <dt>{{ $tc('common.commission.accounting-period', 1) }}: </dt>
                        <dd :class="[{ 'additional-info': !commissionList.exchange_date_from && !commissionList.exchange_date_to }]">{{ accountingPeriod }}</dd>
                    </template>
                </dl>
            </grid-card>

            <grid-card
                v-if="hasCommissionInfo"
                :columns="{ xs: 12, sm: 6, lg: 6 }"
                content-class="q-pt-xs q-pr-none q-pb-sm q-pl-none"
            >
                <q-list dense>
                    <q-item>
                        <q-item-section>
                            <q-item-label>
                                <div class="row">
                                    <div class="col-xs-8">{{ $t('views.commission.commission-list.commission-total--target') }}
                                        <info-icon :text="$t(`views.commission.commission-list.commission-total--target--text--${kebabCase(commissionList.type)}`)" />
                                    </div>
                                    <div class="col-xs-4 text-right">{{ formatCurrency(commissionList.commission_total) }}</div>
                                </div>
                            </q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-separator />

                    <q-item>
                        <q-item-section>
                            <q-item-label :lines="1">
                                <div class="row">
                                    <div class="col-xs-8">
                                        {{ $t('views.commission.commission-list.commission-total--imported') }}
                                        <info-icon :text="$t('views.commission.commission-list.commission-total--imported--text')" />
                                    </div>
                                    <div class="col-xs-4 text-right" :class="importedTotalValidation !== null ? `imported-total-validation--${importedTotalValidation ? 'success' : 'error'}` : null">
                                        <info-icon
                                            v-if="importedTotalValidation !== null"
                                            class="q-mr-xs"
                                            :name="importedTotalValidation ? 'mib-check-1' : 'mib-alert-triangle'"
                                            :color="importedTotalValidation ? 'positive' : 'negative-dark'"
                                            :text="$t(`views.commission.commission-list.imported-total-validation--text--${importedTotalValidation ? 'success' : 'error'}`, { amount: formatCurrency(commissionList.total_amount_of_imported_commission - commissionList.commission_total) })"
                                        />
                                        {{ formatCurrency(commissionList.total_amount_of_imported_commission) }}
                                    </div>
                                </div>
                            </q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-expansion-item
                        v-model="showImportedCommissionDetails"
                        :caption="$t('views.commission.commission-list.commission-total--per-commission-type')"
                        expand-icon="mib-arrow-left-1"
                        expanded-icon="mib-arrow-down-1"
                        dense
                        dense-toggle
                    >
                        <q-markup-table
                            flat
                            square
                            dense
                            class="q-mb-sm"
                        >
                            <tbody>
                                <tr v-for="(value, key) in commissionList.total_amount_of_imported_commission_grouped_by_commission_type" :key="key">
                                    <td><span class="monospace">{{ key }}</span> – {{ $t(`common.commission.commission-type.type-${key}`) }}</td>
                                    <!-- TODO @TFU: formatCurrency already rounds the value. Check if this is sufficient for this case. (The amount should be the same as the total nevertheless.) -->
                                    <td class="text-right">{{ formatCurrency(value) }}</td>
                                </tr>
                            </tbody>
                        </q-markup-table>
                    </q-expansion-item>
                </q-list>
            </grid-card>

            <div>
                <p>
                    <small>
                        <base-button
                            show-as-link
                            @click="$refs.activityLogOverlay.open({ filterSubjects: [{ id: id, type: ActivityLogSubjectType.COMMISSION_LIST }] })"
                        >{{ $tc('common.term.created-on--date-time', 1, { date: commissionList.formattedCreatedAtDate, time: commissionList.formattedCreatedAtTime }) }}</base-button>
                    </small>
                </p>
            </div>
        </div>

        <div v-if="commissionListImportErrors" class="row">
            <div class="col-xs-12">
                <info-box type="secondary" class="import-error-wrapper">
                    <!-- File error (occurs if the file cannot be processed at all) -->
                    <div v-if="commissionListImportFileErrors.length" class="error-message-wrapper" data-test="error:file-error-message">
                        <p class="text-bold">{{ $tc('views.commission.commission-list.import-error.file-error', 1) }}:</p>
                        <ul>
                            <li>
                                <p><span class="text-negative-dark">{{ $tc('views.commission.commission-list.import-error.type.unusable-file', 1) }}:</span> {{ $t('views.commission.commission-list.import-error.file-error--description') }}</p>
                            </li>
                        </ul>
                    </div>

                    <!-- List errors -->
                    <div v-if="commissionListImportListErrors.length" class="error-message-wrapper" data-test="error:list-error-message">
                        <p class="text-bold">{{ $tc('views.commission.commission-list.import-error.list-error', commissionListImportListErrors.length) }}:</p>
                        <ul v-for="commissionListImportError in commissionListImportListErrors" :key="commissionListImportError.id">
                            <li v-for="(message, index) in commissionListImportError.messages" :key="`${commissionListImportError.id}-message-${index}`">
                                <span class="text-negative-dark">{{ $te(`views.commission.commission-list.import-error.type.${kebabCase(message.type)}`) ? $tc(`views.commission.commission-list.import-error.type.${kebabCase(message.type)}`, 1) : message.type }}:</span> {{ $te(`views.commission.commission-list.import-error.subject.${kebabCase(message.subject)}`) ? $tc(`views.commission.commission-list.import-error.subject.${kebabCase(message.subject)}`, 1) : message.subject }}

                                <template v-if="message.code">
                                    <span v-if="message.subject" class="additional-info">→ {{ $te(`views.commission.commission-list.import-error.code.${message.code}`) ? $tc(`views.commission.commission-list.import-error.code.${message.code}`, 1, { field: $te(`views.commission.commission-list.import-error.subject.${kebabCase(message.subject)}`) ? $tc(`views.commission.commission-list.import-error.subject.${kebabCase(message.subject)}`, 1) : message.subject }) : message.subject }}</span>
                                    <span v-else class="additional-info">→ {{ $tc('common.status.code.error.error-code', 1) }}: <span class="monospace text-bold">{{ message.code }}</span>)</span>
                                </template>
                                <span v-else class="additional-info">(<span class="monospace">{{ message.subject }}</span>)</span>
                            </li>
                        </ul>
                    </div>

                    <!-- Entry errors -->
                    <div v-if="commissionListImportEntryErrors.length" class="error-message-wrapper" data-test="error:entry-error-message">
                        <p class="text-bold">{{ $tc('views.commission.commission-list.import-error.entry-error', commissionListImportEntryErrors.length) }}:</p>
                        <ul>
                            <li v-for="commissionListImportError in commissionListImportEntryErrors" :key="commissionListImportError.id">
                                <p class="text-bold"><base-badge color="background-primary" class="contract-number-badge">{{ commissionListImportError.parsed_data['App\\Models\\Commissions\\CommissionListEntry']?.contract_number || `${$tc('views.commission.commission-list-entry.commission-list-entry', 1)} ${commissionListImportError.source_entry_number}` }} <info-icon v-if="commissionList.type === CommissionListType.EXCEL && !commissionListImportError.parsed_data['App\\Models\\Commissions\\CommissionListEntry']?.contract_number" class="q-ml-xs" :text="$tc('views.commission.commission-list-entry.commission-list-entry--info-row-number', 1, { number: commissionListImportError.source_entry_number, higherNumber: commissionListImportError.source_entry_number + 1 })" /></base-badge><template v-if="commissionListImportError.parsed_data['App\\Models\\Commissions\\CommissionListEntry']?.customer_company_name || commissionListImportError.parsed_data['App\\Models\\Commissions\\CommissionListEntry']?.customer_first_name || commissionListImportError.parsed_data['App\\Models\\Commissions\\CommissionListEntry']?.customer_last_name"> – {{ commissionListImportError.parsed_data['App\\Models\\Commissions\\CommissionListEntry']?.customer_company_name || `${commissionListImportError.parsed_data['App\\Models\\Commissions\\CommissionListEntry']?.customer_first_name} ${commissionListImportError.parsed_data['App\\Models\\Commissions\\CommissionListEntry']?.customer_last_name}` }}</template>:</p>
                                <ul>
                                    <li v-for="(message, index) in commissionListImportError.messages" :key="`${commissionListImportError.id}-message-${index}`">
                                        <span class="text-negative-dark">{{ $te(`views.commission.commission-list.import-error.type.${kebabCase(message.type)}`) ? $tc(`views.commission.commission-list.import-error.type.${kebabCase(message.type)}`, 1) : message.type }}:</span> {{ $te(`views.commission.commission-list.import-error.subject.${kebabCase(message.subject)}`) ? $tc(`views.commission.commission-list.import-error.subject.${kebabCase(message.subject)}`, 1) : message.subject }}

                                        <span v-if="message.code" class="additional-info">
                                            <template v-if="message.subject">
                                                <template v-if="message.params?.relatedSubject">→ {{ $te(`views.commission.commission-list.import-error.code.${message.code}`) ? $tc(`views.commission.commission-list.import-error.code.${message.code}`, 1, { field: $te(`views.commission.commission-list.import-error.subject.${kebabCase(message.subject)}`) ? $tc(`views.commission.commission-list.import-error.subject.${kebabCase(message.subject)}`, 1) : message.subject, target: $te(`views.commission.commission-list.import-error.subject.${kebabCase(message.params?.relatedSubject)}`) ? $tc(`views.commission.commission-list.import-error.subject.${kebabCase(message.params?.relatedSubject)}`, 1) : message.params?.relatedSubject }) : message.code }}</template>
                                                <template v-else>→ {{ $te(`views.commission.commission-list.import-error.code.${message.code}--no-related-subject`) ? $tc(`views.commission.commission-list.import-error.code.${message.code}--no-related-subject`, 1, { field: $te(`views.commission.commission-list.import-error.subject.${kebabCase(message.subject)}`) ? $tc(`views.commission.commission-list.import-error.subject.${kebabCase(message.subject)}`, 1) : message.subject }) : $te(`views.commission.commission-list.import-error.code.${message.code}`) ? $tc(`views.commission.commission-list.import-error.code.${message.code}`, 1, { field: $te(`views.commission.commission-list.import-error.subject.${kebabCase(message.subject)}`) ? $tc(`views.commission.commission-list.import-error.subject.${kebabCase(message.subject)}`, 1) : message.subject }) : message.code }}</template>
                                            </template>
                                            <template v-else>→ {{ $tc('common.status.code.error.error-code', 1) }}: <span class="monospace text-bold">{{ message.code }}</span>)</template>
                                        </span>
                                        <template v-else>
                                            <span v-if="message.params?.relatedSubject" class="additional-info">→ {{ $tc('views.commission.commission-list.import-error.subject.related-subject--no-error-code', 1, { relatedSubject: $te(`views.commission.commission-list.import-error.subject.${kebabCase(message.params?.relatedSubject)}`) ? $tc(`views.commission.commission-list.import-error.subject.${kebabCase(message.params?.relatedSubject)}`, 1) : message.params?.relatedSubject }) }}</span>
                                        </template>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </info-box>
            </div>
        </div>

        <div v-if="commissionList.status === CommissionList.status.PROCESSED" class="row">
            <div class="col-xs-12">
                <!-- TODO @TFU: add dblClick behaviour; open confirm or edit; -->
                <!-- TODO improvement @TFU: Remove selection functionality when on tab 'posted'. -->
                <base-table
                    ref="table"
                    :columns="columns"
                    :fetch-objects-fn="CommissionListEntry.service.all"
                    :additional-filters="additionalFilters"
                    user-settings-base-path="CommissionListEntry"
                    pagination-sort-by-default-key="contract_number"
                    selection="multiple"
                    :selected.sync="selectedCommissionListEntries"
                    :selection-row-condition="selectionRowCondition"
                    @update:selected="onUpdateSelected"
                >
                    <template v-slot:tabs>
                        <q-tabs
                            dense
                            no-caps
                            inline-label
                            align="left"
                            class="q-mr-sm bg-background-secondary"
                            indicator-color="accent"
                        >
                            <q-route-tab
                                :to="{ name: 'commission-list-detail', params: { id: id, status: 'pending' } }"
                                :label="$t('common.status.commission-list-status.pending')"
                            />
                            <q-route-tab
                                :to="{ name: 'commission-list-detail', params: { id: id, status: 'posted' } }"
                                :label="$t('common.status.commission-list-status.posted')"
                            />
                        </q-tabs>
                    </template>

                    <!-- Header cells -->
                    <template v-slot:header-cell-contract_number="props">
                        <q-th :props="props">
                            {{ props.col.label }}
                            <info-icon :text="$t('views.commission.commission-list.column-title--contract-number--info-text')" />
                        </q-th>
                    </template>

                    <template v-slot:header-cell-customer_name="props">
                        <q-th :props="props">
                            {{ props.col.label }}
                            <info-icon :text="$t('views.commission.commission-list.column-title--customer-name--info-text')" />
                        </q-th>
                    </template>

                    <template v-slot:header-cell-matched_contract="props">
                        <q-th :props="props">
                            {{ props.col.label }}
                            <info-icon :text="$t('views.commission.commission-list.column-title--matched-contract--info-text')" />
                        </q-th>
                    </template>

                    <template v-slot:header-cell-matched_contract_customer_name="props">
                        <q-th :props="props">
                            {{ props.col.label }}
                            <info-icon :text="$t('views.commission.commission-list.column-title--matched-contract-customer-name--info-text')" />
                        </q-th>
                    </template>

                    <!-- Selection -->
                    <template v-slot:selectionRowConditionInfo="slotProps">
                        <q-spinner v-if="slotProps.row.status === CommissionListEntryStatus.QUEUED_FOR_POSTING" />
                        <q-checkbox
                            v-else
                            :value="false"
                            class="not-selectable"
                            color="secondary-light"
                            keep-color
                            dense
                            disable
                        />
                    </template>

                    <!-- Body cells -->
                    <template v-slot:body-cell-amount="slotProps">
                        <q-td :props="slotProps" :class="['text-bold monospace', `entry-status-${combinedStatus(slotProps.row.status, slotProps.row.matchPostStatus, slotProps.row.nextStep).indicatorStatus}`, { 'text-negative-darker': slotProps.row.totalPosition.commission_amount < 0 }]">
                            {{ slotProps.row.totalPosition.formattedCommissionAmount }}
                        </q-td>
                    </template>

                    <template v-slot:body-cell-matched_contract="slotProps">
                        <q-td :props="slotProps" :class="['matched-contract', slotProps.row.matchPostStatus.toLowerCase(), { 'additional-info': !slotProps.row.matchedContract }]">
                            <span class="content">
                                <template v-if="slotProps.row.matchedContract">{{ slotProps.row.matchedContract.currentContractNumber }}</template>
                                <!-- TODO improvement: Should click trigger the same action as the nextStep button? -->
                                <template v-else>{{ $t(`views.commission.commission-list.entry.matched-contract-info.${slotProps.row.matchPostStatus.toLowerCase()}`) }}</template>
                            </span>

                            <span
                                v-if="slotProps.row.matchedContract && slotProps.row.matchedContract.currentContractInformation.product.basicProvider.id !== commissionList.productProvider.id"
                                class="text-negative text-bold"
                            >
                                – {{ slotProps.row.matchedContract.currentContractInformation.product.basicProvider.display_name || slotProps.row.matchedContract.currentContractInformation.product.basicProvider.name }}
                                <info-icon
                                    name="mib-alert-triangle"
                                    :title="`${$t('common.term.attention')}!`"
                                    :text="$tc('views.commission.commission-list.matched-contract.other-provider', 1, { listProvider: commissionList.productProvider.display_name || commissionList.productProvider.name, otherProvider: slotProps.row.matchedContract.currentContractInformation.product.basicProvider.display_name || slotProps.row.matchedContract.currentContractInformation.product.basicProvider.name })"
                                    color="negative-light"
                                /></span>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-matched_contract_customer_name="slotProps">
                        <q-td :props="slotProps" :class="['matched-contract-customer-name', slotProps.row.matchPostStatus.toLowerCase(), { 'additional-info': !slotProps.row.matchedContract }]">
                            <span class="content">
                                <template v-if="slotProps.row.matchedContract">{{ slotProps.row.matchedContract.customer.getContactName({ commaSeparated: true }) }} <span class="additional-info">({{ slotProps.row.matchedContract.customer.contactNumber.number }})</span></template>
                                <!-- TODO improvement: Should click trigger the same action as the nextStep button? -->
                                <template v-else>–</template>
                            </span>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-status="slotProps">
                        <q-td auto-width class="border-left">
                            <status-badge
                                v-if="slotProps.row.status === CommissionListEntryStatus.PROCESSED"
                                :status="combinedStatus(slotProps.row.status, slotProps.row.matchPostStatus, slotProps.row.nextStep).badgeStatus"
                                default-translation-base-path="common.status.commission-list-entry-status"
                                :label="$t(`views.commission.commission-list.entry.next-step--${kebabCase(slotProps.row.nextStep)}${$te(`views.commission.commission-list.entry.next-step--${kebabCase(slotProps.row.nextStep)}--label-text`) ? '--label-text' : ''}`)"
                            />

                            <status-badge
                                v-else
                                :status="slotProps.row.status"
                                default-translation-base-path="common.status.commission-list-entry-status"
                                :info-text="entryStatusInfoText(slotProps.row.status, slotProps.row.status_message)"
                                :loading="slotProps.row.status === CommissionListEntryStatus.QUEUED_FOR_POSTING"
                            />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-actions="slotProps">
                        <q-td :props="slotProps" auto-width>
                            <template v-if="![CommissionListEntryStatus.QUEUED_FOR_POSTING, CommissionListEntryStatus.POSTED].includes(slotProps.row.status)">
                                <q-btn
                                    v-if="slotProps.row.nextStep === CommissionListEntryNextStep.CONFIRM_SELECTED_CONTRACT"
                                    color="primary"
                                    size="sm"
                                    flat
                                    round
                                    icon="mib-check"
                                    @click="triggerUpdateMatchedContract(slotProps.row, 'confirm')"
                                >
                                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $t(`views.commission.commission-list.entry.next-step--${kebabCase(slotProps.row.nextStep)}`) }}</q-tooltip>
                                </q-btn>

                                <q-btn
                                    color="primary"
                                    size="sm"
                                    flat
                                    round
                                    :icon="nextStepUpdateMatchedContractIcon(slotProps.row.nextStep, slotProps.row.matchPostStatus)"
                                    @click="triggerUpdateMatchedContract(slotProps.row, 'update', $t(`views.commission.commission-list.entry.next-step--${slotProps.row.nextStep === CommissionListEntryNextStep.CONFIRM_SELECTED_CONTRACT ? 'post' : kebabCase(slotProps.row.nextStep)}`))"
                                >
                                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $t(`views.commission.commission-list.entry.next-step--${slotProps.row.nextStep === CommissionListEntryNextStep.CONFIRM_SELECTED_CONTRACT ? 'post' : kebabCase(slotProps.row.nextStep)}`) }}</q-tooltip>
                                </q-btn>

                                <q-btn
                                    size="sm"
                                    flat
                                    round
                                    :icon="!slotProps.row.ready_to_post || slotProps.row.totalPosition.commission_amount === 0 ? 'mib-view-off' : 'mib-view-1'"
                                    :color="!slotProps.row.ready_to_post || slotProps.row.totalPosition.commission_amount === 0 ? 'secondary' : 'primary'"
                                    :disable="!slotProps.row.ready_to_post || slotProps.row.totalPosition.commission_amount === 0"
                                    @click="handlePreview(slotProps.row)"
                                >
                                    <!-- TODO: (When taking care of "show postings / posting preview" task) Remove line below and un-comment the code below -->
                                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.accounting.posting.show-posting-preview', 1) }}</q-tooltip>
                                    <!--
                                    <q-tooltip v-if="slotProps.row.status === CommissionListEntryStatus.POSTED" :delay="1000" :offset="[0, 10]">{{ $tc('common.accounting.posting.show-posting', 3) }}</q-tooltip>
                                    <q-tooltip v-else :delay="1000" :offset="[0, 10]">{{ $tc('common.accounting.posting.show-posting-preview', 1) }}</q-tooltip>
                                    -->
                                </q-btn>
                            </template>

                            <q-btn
                                class="overflow-hidden"
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-navigation-menu-horizontal"
                                data-test="btn:tooltip-actions"
                            >
                                <q-tooltip
                                    :delay="1000"
                                    :offset="[0, 10]"
                                >
                                    {{ $t('common.term.more') }}
                                </q-tooltip>

                                <q-menu anchor="top left" self="top end">
                                    <div class="q-pl-xs q-pr-xs">
                                        <q-btn
                                            size="sm"
                                            flat
                                            round
                                            icon="mib-synchronize-arrow-clock"
                                            color="primary"
                                            @click="$refs.activityLogOverlay.open({ filterSubjects: [{ id: slotProps.row.id, type: ActivityLogSubjectType.COMMISSION_LIST_ENTRY }], filterIncludeSubjectRelations: [ActivityLogSubjectRelation.COMMISSION_LIST_ENTRY_POSITIONS] })"
                                        >
                                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.change-log.show-change-log', 1) }}</q-tooltip>
                                        </q-btn>
                                    </div>
                                </q-menu>
                            </q-btn>
                        </q-td>
                    </template>
                </base-table>
            </div>
        </div>

        <div v-if="commissionList.status === CommissionList.status.POSTED">
            <!-- TODO @TFU: Re-add filter when migration data has been fixed -->
            <base-table
                :columns="postedEntriesColumns"
                :fetch-objects-fn="CommissionListEntry.service.all"
                :show-filter="false"
                :additional-filters="{ commissionListIds: [commissionList.id], filterStatus: [CommissionListEntryStatus.POSTED] }"
                user-settings-base-path="CommissionListEntryPosted"
                pagination-sort-by-default-key="contract_number"
                @row-dblclick="(evt, row) => { $router.push({ name: 'contract-detail', params: { contactId: row.matchedContract.customer.id, id: row.matchedContract.id, tab: 'commission-postings' } }) }"
            >
                <!-- Header cells -->
                <template v-slot:header-cell-matched_contract="props">
                    <q-th :props="props">
                        {{ props.col.label }}
                        <info-icon :text="$t('views.commission.commission-list.column-title--matched-contract--posted--info-text')" />
                    </q-th>
                </template>

                <template v-slot:header-cell-matched_contract_customer_name="props">
                    <q-th :props="props">
                        {{ props.col.label }}
                        <info-icon :text="$t('views.commission.commission-list.column-title--matched-contract-customer-name--posted--info-text')" />
                    </q-th>
                </template>

                <!-- Body cells -->
                <template v-slot:body-cell-amount="slotProps">
                    <q-td :props="slotProps" :class="['text-bold monospace', { 'text-negative-darker': slotProps.row.totalPosition.commission_amount < 0 }]" auto-width>
                        {{ slotProps.row.totalPosition.formattedCommissionAmount }}
                    </q-td>
                </template>

                <template v-slot:body-cell-matched_contract="slotProps">
                    <q-td :props="slotProps" class="matched-contract">{{ slotProps.row.matchedContract.currentContractNumber }}</q-td>
                </template>

                <template v-slot:body-cell-matched_contract_customer_name="slotProps">
                    <q-td :props="slotProps" :class="['matched-contract-customer-name', slotProps.row.matchPostStatus.toLowerCase()]">{{ slotProps.row.matchedContract.customer.getContactName({ commaSeparated: true }) }} <span class="additional-info">({{ slotProps.row.matchedContract.customer.contactNumber.number }})</span></q-td>
                </template>

                <template v-slot:body-cell-status="slotProps">
                    <q-td auto-width class="border-left">
                        <status-badge
                            :status="slotProps.row.status"
                            default-translation-base-path="common.status.commission-list-entry-status"
                            :info-text="entryStatusInfoText(slotProps.row.status, slotProps.row.status_message)"
                            :loading="slotProps.row.status === CommissionListEntryStatus.QUEUED_FOR_POSTING"
                        />
                    </q-td>
                </template>

                <!-- TODO improvement: Add action to show actual postings of specific entry in dialog (same as posting preview). -->
                <template v-slot:body-cell-actions="slotProps">
                    <q-td :props="slotProps" auto-width>
                        <q-btn
                            size="sm"
                            flat
                            round
                            icon="mib-view-1"
                            color="primary"
                            :to="{ name: 'contract-detail', params: { contactId: slotProps.row.matchedContract.customer.id, id: slotProps.row.matchedContract.id, tab: 'commission-postings' } }"
                            class="do-not-print"
                        >
                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.commission.commission-list.show-commission-postings', 1, { contractNumber: slotProps.row.matchedContract.currentContractNumber }) }}</q-tooltip>
                        </q-btn>
                    </q-td>
                </template>
            </base-table>
        </div>

        <!-- Update matched contract dialog -->
        <commission-list-entry-matched-contract-update-overlay
            ref="updateMatchedContractOverlay"
            :commission-list-provider="commissionListProvider"
            :commission-list-entry="currentCommissionListEntryToUpdate"
            :mode="commissionListEntryMatchedContractUpdateOverlayMode"
            @hide="currentCommissionListEntryToUpdate = null"
        />

        <!-- List entry posting preview -->
        <commission-list-entry-posting-preview-overlay ref="postingPreviewOverlay" />

        <!-- Start posting CommissionList -->
        <form-dialog
            ref="startPostingDialog"
            double-confirm
            dont-close-on-confirm
            @confirm="handleStartPosting"
        >
            <template v-slot:title>{{ $tc('views.commission.commission-list.post-commission-entry', 2) }}</template>
            <template v-slot:default>
                <p>{{ $tc('views.commission.commission-list.post-commission-entry--confirm', selectedCommissionListEntries.length || 1) }}</p>
            </template>
        </form-dialog>

        <!-- Cancel posting CommissionList -->
        <form-dialog
            ref="cancelPostingDialog"
            double-confirm
            dont-close-on-confirm
            @confirm="handleCancelPosting"
        >
            <template v-slot:title>{{ $tc('views.commission.commission-list.cancel-posting', 1) }}</template>
            <template v-slot:default>
                <p>{{ $t('views.commission.commission-list.cancel-posting--confirm') }}</p>
            </template>
        </form-dialog>

        <!-- Polling -->
        <polling
            v-if="isImportingOrProcessing"
            ref="pollingImportingOrProcessing"
            :poll-fn="CommissionList.service.get"
            :stop-condition-fn="stopImportingOrProcessingPollingConditionFn"
            :poll-filters="id"
            :timeout-tm="10000"
            auto-start
        />
        <polling
            ref="polling"
            :poll-fn="CommissionListEntry.service.all"
            :stop-condition-fn="stopPollingConditionFn"
            :poll-filters="pollFilters"
            :timeout-tm="3000"
            auto-start
            @condition-check="handlePollingConditionCheck"
        />

        <!-- Activity log -->
        <activity-log-overlay ref="activityLogOverlay" />

        <in-page-footer>
            <base-button
                v-if="commissionList.status === CommissionList.status.PENDING"
                :label="$tc('views.commission.commission-list.edit-list', 1)"
                primary-button
                outline
                :to="{ name: 'commission-list-update', params: { id: commissionList.id } }"
            />

            <base-button
                v-if="commissionList.status === CommissionList.status.PROCESSED"
                :label="$tc('views.commission.commission-list.post-commission-entry--n-entries', selectedCommissionListEntries.length || 1)"
                primary-button
                :disable="!selectedCommissionListEntries.length || status === 'posted'"
                @click="handleActions({ key: CommissionListActions.START_POSTING })"
            />
            <base-button
                v-if="isPosting"
                :label="$tc('views.commission.commission-list.cancel-posting', selectedCommissionListEntries.length)"
                outline
                color="negative"
                @click="handleActions({ key: CommissionListActions.CANCEL_POSTING })"
            />

            <base-button
                v-if="![CommissionListStatus.PENDING, CommissionListStatus.PROCESSED].includes(commissionList.status)"
                :label="$tc('views.commission.commission-list.back-to-commission-list', 2)"
                primary-button
                flat
                :to="{ name: 'commission-lists' }"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { CommissionList, CommissionListEntry, CommissionListImportError } from '@/models/models'
import { CommissionListActions, CommissionListEntryMatchPostStatus, CommissionListEntryNextStep } from '@/enums'
import {
    CommissionListStatus,
    CommissionListEntryStatus,
    ActivityLogSubjectType,
    ActivityLogSubjectRelation,
    CommissionListErrorScope, CommissionListType
} from '@/enums/graphql'
import { getContactName } from '@/helpers/contact'
import { formatDate } from '@/helpers/date'
import { formatCurrency } from '@/helpers/number'
import { kebabCase } from 'lodash'

import BaseTable from '@/components/BaseTable'
import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'
import Polling from '@/components/Polling.vue'
import StatusBadge from '@/components/StatusBadge'
import CommissionListEntryMatchedContractUpdateOverlay from '@/components/commission/CommissionListEntryMatchedContractUpdateOverlay'
import CommissionListEntryPostingPreviewOverlay from '@/components/commission/CommissionListEntryPostingPreviewOverlay'
import ActivityLogOverlay from '@/components/ActivityLogOverlay'
import BaseBadge from '@/components/BaseBadge.vue'

export default {
    name: 'CommissionListDetail',
    meta () {
        return {
            title: !this.commissionList ? `${this.$t('common.term.loading')} …` : `${this.$tc('views.commission.commission-list.commission-list', 1)}: ${this.pageTitle}`,
        }
    },
    components: {
        BaseBadge,
        BaseTable,
        Actions,
        FormDialog,
        Polling,
        StatusBadge,
        CommissionListEntryMatchedContractUpdateOverlay,
        CommissionListEntryPostingPreviewOverlay,
        ActivityLogOverlay,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        status: {
            type: String,
            default: 'pending',
        },
    },
    data () {
        const pending = Object.values(CommissionListEntryStatus).filter(status => status !== CommissionListEntryStatus.POSTED)

        return {
            ActivityLogSubjectType,
            ActivityLogSubjectRelation,
            CommissionListActions,
            CommissionList,
            CommissionListStatus,
            CommissionListEntry,
            CommissionListEntryStatus,
            CommissionListEntryNextStep,
            commissionList: null,
            commissionListEntries: null,
            commissionListImportErrors: null,
            showImportedCommissionDetails: false,
            selectedCommissionListEntries: [],
            currentCommissionListEntryToUpdate: null,
            commissionListEntryMatchedContractUpdateOverlayMode: 'update',
            filterStatus: {
                'pending': [...pending],
                'posted': [CommissionListEntryStatus.POSTED],
            },
            columns: [
                {
                    name: 'amount',
                    label: this.$tc('common.accounting.posting.amount', 1),
                    align: 'right',
                },
                {
                    name: 'contract_number',
                    label: this.$tc('views.commission.commission-list.column-title--contract-number', 1),
                    field: row => row.contract_number,
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'customer_name',
                    label: this.$tc('views.commission.commission-list.column-title--customer-name', 1),
                    field: row => row.customerName,
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'matched_contract',
                    label: this.$tc('views.commission.commission-list.column-title--matched-contract', 1),
                    align: 'left',
                    classes: 'border-left',
                    headerClasses: 'border-left',
                },
                {
                    name: 'matched_contract_customer_name',
                    label: this.$tc('views.commission.commission-list.column-title--matched-contract-customer-name', 1),
                    align: 'left',
                },
                {
                    name: 'status',
                    label: this.$tc('common.term.status', 1),
                    align: 'left',
                    classes: 'border-left',
                    headerClasses: 'border-left',
                },
                {
                    name: 'actions',
                    label: this.$tc('common.term.action', 2),
                    align: 'right',
                    classes: 'border-left',
                    headerClasses: 'border-left',
                },
            ],
            pollFilters: {
                count: 1,
                commissionListIds: [this.id],
                filterStatus: [CommissionListEntryStatus.QUEUED_FOR_POSTING],
            },
            isPosting: false,
            postedEntriesColumns: [
                {
                    name: 'amount',
                    label: this.$tc('common.accounting.posting.amount', 1),
                    align: 'right',
                },
                // TODO @TFU: Un-comment when migration data has been fixed
                // {
                //     name: 'contract_number',
                //     label: this.$tc('views.commission.commission-list.column-title--contract-number', 1),
                //     field: row => row.contract_number,
                //     align: 'left',
                //     sortable: true,
                // },
                // {
                //     name: 'customer_name',
                //     label: this.$tc('views.commission.commission-list.column-title--customer-name', 1),
                //     field: row => row.customerName,
                //     align: 'left',
                //     sortable: true,
                // },
                {
                    name: 'matched_contract',
                    label: this.$tc('views.commission.commission-list.column-title--matched-contract--posted', 1),
                    align: 'left',
                    classes: 'border-left',
                    headerClasses: 'border-left',
                },
                {
                    name: 'matched_contract_customer_name',
                    label: this.$tc('views.commission.commission-list.column-title--matched-contract-customer-name--posted', 1),
                    align: 'left',
                },
                {
                    name: 'status',
                    label: this.$tc('common.term.status', 1),
                    align: 'left',
                    classes: 'border-left',
                    headerClasses: 'border-left',
                },
                {
                    name: 'actions',
                    label: this.$tc('common.term.action', 2),
                    align: 'center',
                    classes: 'border-left',
                    headerClasses: 'border-left',
                },
            ],
        }
    },
    computed: {
        CommissionListType () {
            return CommissionListType
        },
        additionalFilters () {
            return {
                commissionListIds: [this.commissionList.id],
                filterStatus: this.filterStatus[this.status],
            }
        },
        accountingPeriod () {
            const fromDate = formatDate(this.commissionList.exchange_date_from, { noLeadingZero: true })
            const toDate = formatDate(this.commissionList.exchange_date_to, { noLeadingZero: true })

            if (fromDate || toDate) {
                return `${fromDate || '?'} ${this.$t('views.commission.commission-list.date-range--to')} ${toDate || '?'}`
            }

            return this.$tc('common.commission.accounting-period', 0)
        },
        pageTitle () {
            const providerName = this.commissionList.productProvider.display_name || this.commissionList.productProvider.name
            const fileName = this.commissionList.filename

            if (this.commissionList.exchange_date_from || this.commissionList.exchange_date_to) {
                return `${providerName}, ${this.accountingPeriod}`
            }

            return `${providerName}, ${fileName}`
        },
        fileInfo () {
            const fileInfo = []

            fileInfo.push(this.$tc(`common.file.type.${kebabCase(this.commissionList.type)}--file`, 1))
            fileInfo.push(this.$tc(`views.commission.commission-list.source--${kebabCase(this.commissionList.source)}--text`, 1))

            if (this.commissionList.file_import_identification_number) {
                fileInfo.push(`${this.$tc('views.commission.commission-list.file-id', 1)}: ${this.commissionList.file_import_identification_number}`)
            }

            return fileInfo.join('\n')
        },
        statusBoxType () {
            switch (this.commissionList.status) {
                case CommissionListStatus.PENDING:
                case CommissionListStatus.CANCELED:
                    return 'warning'

                case CommissionListStatus.INCOMPLETELY_PROCESSED:
                case CommissionListStatus.FAILED:
                    return 'negative'

                case CommissionListStatus.POSTED:
                    return 'positive'

                case CommissionListStatus.IMPORTING:
                case CommissionListStatus.PROCESSING:
                case CommissionListStatus.PROCESSED:
                default:
                    return 'info'
            }
        },
        hasCommissionInfo () {
            return [
                CommissionListStatus.PROCESSING,
                CommissionListStatus.INCOMPLETELY_PROCESSED,
                CommissionListStatus.PROCESSED,
                CommissionListStatus.POSTED,
            ].includes(this.commissionList.status)
        },
        importedTotalValidation () {
            if ([
                CommissionListStatus.INCOMPLETELY_PROCESSED,
                CommissionListStatus.PROCESSED,
                CommissionListStatus.POSTED,
            ].includes(this.commissionList.status)) {
                return (this.commissionList.total_amount_of_imported_commission === this.commissionList.commission_total)
            }
            return null
        },
        commissionListProvider () {
            return this.commissionList?.productProvider
        },
        isImportingOrProcessing () {
            return [CommissionListStatus.IMPORTING, CommissionListStatus.PROCESSING].includes(this.commissionList.status)
        },
        commissionListImportFileErrors () {
            return this.commissionListImportErrors?.filter(error => error.scope === CommissionListErrorScope.FILE) ?? []
        },
        commissionListImportListErrors () {
            return this.commissionListImportErrors?.filter(error => error.scope === CommissionListErrorScope.LIST) ?? []
        },
        commissionListImportEntryErrors () {
            return this.commissionListImportErrors?.filter(error => error.scope === CommissionListErrorScope.ENTRY) ?? []
        },
    },
    created () {
        CommissionList.service.get(this.id).then(commissionList => {
            this.commissionList = commissionList
            if (this.commissionList.total_number_of_commission_list_import_errors) {
                CommissionListImportError.service.all({ filterCommissionListIds: [this.commissionList.id] }).then(response => {
                    this.commissionListImportErrors = response.data
                })
            }
        }).catch(error => {
            if (error.graphQLErrors[0].message === 'Commission List doesn’t exist.') {
                // Forward to `not found` error view.
                this.$router.replace({ name: '404' })
            } else {
                // Forward to `forbidden` error view.
                this.$router.replace({ name: '403' })
            }
        })
    },
    methods: {
        selectionRowCondition (row) {
            return row.ready_to_post && row.totalPosition.commission_amount !== 0 && row.status !== CommissionListEntryStatus.QUEUED_FOR_POSTING
        },
        onUpdateSelected () {
            const validSelectedItems = this.selectedCommissionListEntries.filter(this.selectionRowCondition)
            this.selectedCommissionListEntries.length = 0
            this.selectedCommissionListEntries.push(...validSelectedItems)
        },
        resetSelectedItems () {
            this.selectedCommissionListEntries.length = 0
            if (this.$refs.table) this.$refs.table.$refs.qTable.clearSelection()
        },
        handleActions (action) {
            switch (action.key) {
                case CommissionList.action.START_PROCESSING:
                    this.$refs.startProcessingDialog.open()
                    break
                case CommissionList.action.CANCEL_PROCESSING:
                    this.$refs.cancelProcessingDialog.open()
                    break
                case CommissionList.action.START_POSTING:
                    this.$refs.startPostingDialog.open()
                    break
                case CommissionList.action.CANCEL_POSTING:
                    this.$refs.cancelPostingDialog.open()
                    break
                case CommissionList.action.DELETE:
                    this.$refs.deleteDialog.open()
                    break
            }
        },
        handleStartProcessing () {
            this.commissionList.startProcessing()
                .then(() => {
                    this.$refs.startProcessingDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.commission.commission-list-started-processing-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.startProcessingDialog.showFormErrorMessage(error)
                    this.$refs.startProcessingDialog.resetFormSubmitStatus()
                })
        },
        handleCancelProcessing () {
            this.commissionList.cancelProcessing()
                .then(() => {
                    this.$refs.cancelProcessingDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$t('common.notifications.commission.commission-list-cancel-processing-success'),
                    })
                })
                .catch(error => {
                    this.$refs.cancelProcessingDialog.showFormErrorMessage(error)
                    this.$refs.cancelProcessingDialog.resetFormSubmitStatus()
                })
        },
        handleStartPosting () {
            const ids = this.selectedCommissionListEntries.map(entry => entry.id)

            this.commissionList.startPosting(ids)
                .then(response => {
                    this.isPosting = true
                    this.$refs.startPostingDialog.close()

                    this.selectedCommissionListEntries.forEach(entry => {
                        entry.status = CommissionListEntryStatus.QUEUED_FOR_POSTING
                    })
                    this.resetSelectedItems()

                    this.startPolling()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.commission.commission-list-entry-started-posting-success', 2),
                    })
                })
                .catch(error => {
                    this.$refs.startPostingDialog.showFormErrorMessage(error)
                    this.$refs.startPostingDialog.resetFormSubmitStatus()
                })
        },
        handleCancelPosting () {
            this.commissionList.cancelPosting()
                .then(response => {
                    this.isPosting = false
                    this.$refs.cancelPostingDialog.close()
                    this.resetSelectedItems()
                    this.stopPolling()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.commission.commission-list-entry-cancel-posting-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.cancelPostingDialog.showFormErrorMessage(error)
                    this.$refs.cancelPostingDialog.resetFormSubmitStatus()
                })
        },
        handleDelete () {
            this.commissionList.delete()
                .then(() => {
                    this.$refs.deleteDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.commission.commission-list-deleted-success', 1),
                    })
                    this.$router.replace({ name: 'commission-list-list' })
                })
                .catch(error => {
                    this.$refs.deleteDialog.showFormErrorMessage(error)
                    this.$refs.deleteDialog.resetFormSubmitStatus()
                })
        },
        handlePreview (commissionListEntry) {
            this.$refs.postingPreviewOverlay.open(commissionListEntry)
        },
        triggerUpdateMatchedContract (commissionListEntry, mode, title) {
            this.currentCommissionListEntryToUpdate = commissionListEntry
            this.commissionListEntryMatchedContractUpdateOverlayMode = mode
            this.$refs.updateMatchedContractOverlay.open()
        },
        stopImportingOrProcessingPollingConditionFn (commissionList) {
            Object.assign(this.commissionList, commissionList)
            return !this.isImportingOrProcessing
        },
        startPolling () {
            this.$refs.polling.start()
        },
        stopPolling () {
            this.$refs.polling.stop()
        },
        stopPollingConditionFn (response) {
            return !response.data.length
        },
        handlePollingConditionCheck (stopCondition) {
            if (this.isPosting && stopCondition) {
                this.$refs.table.fetchObjects()
                // TODO @MTR: Add notification? --> Posting was in progress and has now finished. Currently, no entries are being posted === empty queue.
            }
            this.isPosting = !stopCondition
        },
        entryStatusInfoText (status, statusMessage) {
            const infoText = []

            // Add custom status message (= explanation of the current status) if there is any (`$te` checks if a translation exists)
            if (this.$te(`views.commission.commission-list.entry.status-message.${kebabCase(status)}`)) {
                infoText.push(this.$tc(`views.commission.commission-list.entry.status-message.${kebabCase(status)}`, 1))
            }

            // Add system status message (= error message or similar feedback)
            if (statusMessage?.message) {
                // TODO @TFU: (1) Please provide a list with all possible `code`s with some more info.
                // TODO @MTR: (2) Translations for different statuses
                infoText.push(statusMessage?.message)
            }

            return infoText.join('\n\n')
        },
        combinedStatus (status, matchPostStatus, nextStep) {
            let combinedStatus = {
                badgeStatus: 'none',
                indicatorStatus: 'failed',
            }

            if ([
                CommissionListEntryStatus.PROCESSED,
                CommissionListEntryStatus.QUEUED_FOR_POSTING,
                CommissionListEntryStatus.POSTING_CANCELLED,
                CommissionListEntryStatus.POSTING_FAILED,
            ].includes(status)) {
                switch (matchPostStatus) {
                    case CommissionListEntryMatchPostStatus.READY:
                        combinedStatus.badgeStatus = 'ready'
                        combinedStatus.indicatorStatus = 'ready'
                        break

                    case CommissionListEntryMatchPostStatus.CONTRACT_MATCH__NAME_MATCH_PARTIAL:
                        // combinedStatus.badgeStatus = 'none'
                        combinedStatus.indicatorStatus = 'warning'
                        break

                    case CommissionListEntryMatchPostStatus.CONTRACT_MATCH__NAME_MATCH_NONE:
                        // combinedStatus.badgeStatus = 'none'
                        combinedStatus.indicatorStatus = 'failed'

                        break

                    case CommissionListEntryMatchPostStatus.CONTRACT_SHORTLIST__NAME_MATCH_FULL:
                        // combinedStatus.badgeStatus = 'none'
                        combinedStatus.indicatorStatus = 'warning'
                        break

                    case CommissionListEntryMatchPostStatus.CONTRACT_SHORTLIST__NAME_MATCH_PARTIAL:
                        // combinedStatus.badgeStatus = 'none'
                        combinedStatus.indicatorStatus = 'partially-failed'
                        break

                    case CommissionListEntryMatchPostStatus.NO_MATCH:
                        switch (nextStep) {
                            case CommissionListEntryNextStep.SELECT_CONTRACT_FROM_SHORTLIST:
                                // combinedStatus.badgeStatus = 'none'
                                combinedStatus.indicatorStatus = 'partially-failed'
                                break

                            case CommissionListEntryNextStep.SELECT_CONTRACT:
                            default:
                                // combinedStatus.badgeStatus = 'none'
                                combinedStatus.indicatorStatus = 'failed'
                        }
                        break

                    default:
                        // combinedStatus.badgeStatus = 'none'
                        combinedStatus.indicatorStatus = 'failed'
                }

                return combinedStatus
            } else {
                // Non `PROCESSED` cases
                combinedStatus.badgeStatus = kebabCase(status)
                combinedStatus.indicatorStatus = kebabCase(status)
            }

            return combinedStatus
        },
        nextStepUpdateMatchedContractIcon (nextStep) {
            let action = 'edit'

            switch (nextStep) {
                // CommissionListEntryNextStep.CONFIRM_SELECTED_CONTRACT handled directly in the template (additional button)

                case CommissionListEntryNextStep.SELECT_CONTRACT:
                    action = 'search'
                    break

                case CommissionListEntryNextStep.SELECT_CONTRACT_FROM_SHORTLIST:
                    action = 'question'
                    break

                case CommissionListEntryNextStep.POST:
                default:
                    action = 'edit'
                    break
            }

            return `mib-common-file-text-${action}`
        },
        getContactName (...args) {
            return getContactName(...args)
        },
        formatDate (...args) {
            return formatDate(...args)
        },
        formatCurrency (...args) {
            return formatCurrency(...args)
        },
        kebabCase (...args) {
            return kebabCase(...args)
        },
    },
}
</script>

<style lang="scss" scoped>
.imported-total-validation {
    &--success {
        color: var(--q-color-positive-dark);
    }

    &--error {
        color: var(--q-color-negative-dark);
    }
}

.import-error-wrapper {
	.contract-number-badge {
		position: relative;
        font-size: 1rem;
        font-weight: bold;
        padding: 0.5em 0.5em 0.4em;
    }

    .error-message-wrapper + .error-message-wrapper {
        margin-top: $sizeSpacingLg;
    }
}

// Additional padding needed if entry-status is placed directly after the selection cell. (Visually equal spacing on both sides of the checkbox.)
::v-deep .q-table--col-auto-width:first-child > .q-checkbox {
    margin-right: $sizeSpacingSm;
}

// Add transparent border as fallback so that cells without special `.entry-status` are properly aligned, too.
::v-deep .q-td[class*="entry-status"] {
    border-left: $sizeSpacingXs solid transparent;
}

// Entry status indicator
::v-deep .q-table .q-td.entry-status {
    &-ready {
        border-left-color: var(--q-color-positive);
    }

    &-warning {
        border-left-color: var(--q-color-warning);
    }

    &-partially-failed {
        border-left-color: var(--q-color-orange); // TODO improvement @MTR: Define 'partially failed' color as (global?) custom property
    }

    &-failed {
        border-left-color: var(--q-color-negative);
    }
}

// Matched contract / customer cell highlighting
::v-deep .q-table .q-td {
    // Matched contract
    &.matched-contract {
        // Ready
        &.ready {
            .content {
                border-bottom: 2px solid var(--q-color-positive);
            }
        }

        // Match
        &[class*="contract-match--"] {
            .content {
                border-bottom: 2px solid var(--q-color-positive);
            }
        }

        // No match
        &[class*="no-match--select-contract"] {
            .content {
                border-bottom: 2px solid var(--q-color-negative);
            }
        }

        // No match – multiple contracts found
        &[class*="no-match--select-contract-from-shortlist"] {
            .content {
                border-bottom: 2px solid var(--q-color-orange); // TODO improvement @MTR: Define 'partially failed' color as (global?) custom property
            }
        }
    }

    // Matched contract’s customer name
    &.matched-contract-customer-name {
        // Ready
        &.ready {
            .content {
                border-bottom: 2px solid var(--q-color-positive);
            }
        }

        // Warning
        &[class*="--name-match-partial"] {
            .content {
                border-bottom: 2px solid var(--q-color-warning);
            }
        }

        // Failed
        &[class*="--name-match-none"] {
            .content {
                border-bottom: 2px solid var(--q-color-negative);
            }
        }
    }
}
</style>
