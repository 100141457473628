import { apolloClient } from '@/apollo/client'

import { CANCELLATION_CREATE_MUTATION } from '@/graphql/contract/cancellationCreate'
import { CANCELLATION_UPDATE_MUTATION } from '@/graphql/contract/cancellationUpdate'
import { CANCELLATION_CHANGE_STATUS_MUTATION } from '@/graphql/contract/cancellationChangeStatus'

export const CancellationService = {
    create (contractId, cancellationDate) {
        return apolloClient.mutate({
            mutation: CANCELLATION_CREATE_MUTATION,
            variables: {
                contract_id: contractId,
                cancellation_date: cancellationDate,
            },
        }).then(response => response.data.cancellationCreate)
    },
    update (id, cancellationDate) {
        return apolloClient.mutate({
            mutation: CANCELLATION_UPDATE_MUTATION,
            variables: {
                id: id,
                cancellation_date: cancellationDate,
            },
        }).then(response => response.data.cancellationUpdate)
    },
    changeStatus (id, status, cancellationDate) {
        return apolloClient.mutate({
            mutation: CANCELLATION_CHANGE_STATUS_MUTATION,
            variables: {
                id: id,
                status: status,
                cancellation_date: cancellationDate,
            },
        }).then(response => response.data.cancellationChangeStatus)
    },
}
