<template>
    <div>
        <div class="col-12">
            <!-- TODO improvement: Move h2 to v-slot:top in q-table when temporary/dismissable info-boxes have been introduced AND the info-box below has been dismissed. -->
            <h2>{{ $tc('common.document-layout.document-layout', 2) }}</h2>
            <info-box>{{ $tc('views.contact.settings.document-layout.document-layout--description', 1) }}</info-box>

            <q-table
                :data="documentLayouts"
                :columns="columns"
                row-key="id"
                :pagination.sync="pagination"
                :loading="loading"
                :rows-per-page-options="[10, 20, 50, 100]"
                binary-state-sort
                flat
                square
                dense
                class="table-top-padding-none"
                @request="onRequest"
                @row-dblclick="(evt, row) => { $router.push({ name: 'contact-settings-document-layout-update', params: { id: ownerId, documentLayoutId: row.id } }) }"
            >
                <template v-slot:top>
                    <q-space />
                    <filter-input v-model="filter" @clear-filter="clearFilter" />
                </template>

                <template v-slot:body-cell-name="props">
                    <q-td :props="props">{{ props.row.name }} <base-badge v-if="props.row.is_default" :label="$tc('common.document-layout.default-layout', 1)" /></q-td>
                </template>

                <template v-slot:body-cell-actions="props">
                    <q-td :props="props" auto-width>
                        <q-btn
                            color="primary"
                            size="sm"
                            flat
                            round
                            icon="mib-pencil"
                            :to="{ name: 'contact-settings-document-layout-update', params: { id: ownerId, documentLayoutId: props.row.id } }"
                        >
                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.contact.settings.document-layout.edit-document-layout', 1) }}</q-tooltip>
                        </q-btn>
                    </q-td>
                </template>
            </q-table>
        </div>

        <in-page-footer>
            <base-button
                :to="{ name: 'contact-settings-document-layout-create', params: { id: ownerId } }"
                :label="$tc('views.contact.settings.document-layout.add-document-layout', 1)"
                icon="mib-common-file-add"
                primary-button
                data-test="btn:create-document-layout"
            />
        </in-page-footer>
    </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import { UserSettings } from '@/helpers/user'
import { OrderDirection } from '@/enums/graphql'
import FilterInput from '@/components/FilterInput'
import BaseBadge from '@/components/BaseBadge'

import { DocumentLayout } from '@/models/documentLayout'

export default {
    name: 'DocumentLayoutList',
    components: {
        FilterInput,
        BaseBadge,
    },
    props: {
        ownerId: {
            type: String,
            required: true,
        },
    },
    data () {
        const userSettings = new UserSettings(this.$store.state.user)
        return {
            userSettings,
            filter: '',
            loading: false,
            pagination: {
                sortBy: 'name',
                page: 1,
                rowsPerPage: parseInt(userSettings.getItem('DocumentLayoutList.pagination.rowsPerPage')) || 50,
                rowsNumber: 0,
            },
            columns: [
                {
                    name: 'name',
                    label: this.$tc('common.document-layout.document-layout', 1),
                    field: row => row.name,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'actions',
                    label: this.$tc('common.term.action', 2),
                    align: 'left',
                },
            ],
            documentLayouts: [],
        }
    },
    watch: {
        'filter' () {
            this.fetchObjects()
        },
        'pagination.rowsPerPage' (rowsPerPage) {
            this.userSettings.setItem('DocumentLayoutList.pagination.rowsPerPage', rowsPerPage)
        },
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
        this.fetchObjects()
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        clearFilter () {
            this.filter = ''
        },
        onRequest (props) {
            let { page, rowsPerPage, sortBy, descending } = props.pagination

            this.pagination.page = page
            this.pagination.rowsPerPage = rowsPerPage
            this.pagination.sortBy = sortBy
            this.pagination.descending = descending

            this.fetchObjects()
        },
        fetchObjects () {
            this.loading = true
            DocumentLayout.objects.all({
                ownerId: this.ownerId,
                page: this.pagination.page,
                count: this.pagination.rowsPerPage,
                orderField: this.pagination.sortBy.toUpperCase(),
                orderDirection: (this.pagination.descending) ? OrderDirection.DESC : OrderDirection.ASC,
                filterQuery: this.filter,
            }).then(response => {
                this.pagination.rowsNumber = response.paginatorInfo.total
                this.loading = false
                this.documentLayouts = response.data
            })
        },
        onNewEntity () {
            this.$router.push({ name: 'contact-settings-document-layout-create', params: { id: this.ownerId } })
        },
    },
}
</script>
