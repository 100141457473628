export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'postingTextPart',
            label: 'forms.field.label.commission-type-accounting-configuration.posting-text-part',
            inputType: 'text',
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'commissionExpenseAccountId',
            label: 'forms.field.label.commission-type-accounting-configuration.commission-expense-account-id',
            inputType: 'account',
            validators: [
                { type: 'required' },
                {
                    type: 'notSameAs',
                    params: {
                        target: 'commissionIncomeAccountId',
                        targetLabel: 'forms.field.label.commission-type-accounting-configuration.commission-income-account-id',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'commissionIncomeAccountId',
            label: 'forms.field.label.commission-type-accounting-configuration.commission-income-account-id',
            inputType: 'account',
            validators: [
                { type: 'required' },
                {
                    type: 'notSameAs',
                    params: {
                        target: 'commissionExpenseAccountId',
                        targetLabel: 'forms.field.label.commission-type-accounting-configuration.commission-expense-account-id',
                    },
                },
            ],
        },
    ],
}
