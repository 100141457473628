import gql from 'graphql-tag'

export const CONTACT_ADDRESS_UPDATE_MUTATION = gql`
    mutation contactAddressUpdate(
        $id: ID!
        $address: AddressInput!
    ) {
        contactAddressUpdate(
            id: $id
            address: $address
        ) {
            id
            category {
                id
                name
            }
            label {
                id
                label
            }
            po_box
            address1
            address2
            address3
            zip
            city
            country {
                id
                name
            }
            valid_from
            valid_until
            is_main_correspondence_address
            status
        }
    }
`
