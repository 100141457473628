<template>
    <form
        data-test="form:contact-consultant-relation-settings-update"
        @submit.prevent="onFormSubmit"
        @keyup.esc="cancel"
    >
        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="formErrorMessage"
            type="negative"
            data-test="error:global-message"
            v-html="formErrorMessage"
        />
        <!-- eslint-enable -->

        <div class="row q-col-gutter-md">
            <grid-card
                :columns="{ xs: 12, lg: 4 }"
                :heading="$tc('common.contact.formality.formality', 1)"
            >
                <form-builder item-key="formality" />
            </grid-card>

            <grid-card
                v-can="'Feature:correspondence:core'"
                :columns="{ xs: 12, lg: 8 }"
                class="q-pt-lg-none"
                :heading="$tc('common.contact.salutation', 1)"
                :loading="!correspondenceSalutations"
            >
                <template v-if="correspondenceSalutations">
                    <p>{{ $tc('views.contact.settings.customer-relation.current-salutation', 1) }}:
                        <span v-html="stringWithPlaceholderTags(currentSalutation.contentHTML)" />
                    </p>
                    <p>
                        <base-checkbox v-model="defineCustomSalutation" :label="$tc('views.contact.settings.customer-relation.use-custom-salutation', 1)" />
                    </p>
                    <div v-if="defineCustomSalutation" class="row q-col-gutter-md">
                        <div class="col-xs-12">
                            <p>{{ $tc('views.contact.settings.customer-relation.use-custom-salutation--select-from-list', 1) }}</p>
                            <form-builder item-key="correspondenceSalutationId" :filter="{ filterContactType: [contact.type] }" />
                        </div>
                        <div class="col-xs-12">
                            <p>{{ $tc('views.contact.settings.customer-relation.use-custom-salutation--write-custom', 1) }}</p>
                            <form-builder item-key="correspondenceSalutationOverride" clearable />
                        </div>
                    </div>
                </template>
            </grid-card>
        </div>

        <in-page-footer>
            <base-button
                data-test="btn:submit"
                type="submit"
                :label="$t('common.term.save')"
                :disable="formSubmitStatus"
                :loading="formSubmitStatus"
                primary-button
            />
        </in-page-footer>
    </form>
</template>

<script>
import { EditorContentSchemaType } from '@max/tiptap-extensions'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import CONTACT_CONSULTANT_RELATION_SETTINGS_FIELDS from '@/forms/contact.consultant-relation-settings.fields'
import BaseCheckbox from '@/components/form/BaseCheckbox.vue'
import { CorrespondenceSalutationService } from '@/services/correspondenceSalutation'
import { stringWithPlaceholderTags } from '@/helpers'
import { DocumentContentFormality } from '@/enums/graphql'
import { EditorContent } from '@/models/models'

export default {
    name: 'ContactConsultantRelationSettingsUpdateForm',
    components: {
        BaseCheckbox,
    },
    mixins: [formBuilderMixin],
    props: {
        contact: {
            type: Object,
            required: true,
        },
    },
    formBuilderSettings: {
        schema: CONTACT_CONSULTANT_RELATION_SETTINGS_FIELDS,
        fields: ['formality', 'correspondenceSalutationOverride', 'correspondenceSalutationId'],
    },
    data () {
        const data = {
            formData: {},
            defineCustomSalutation: false,
            correspondenceSalutations: null,
        }

        if (this.contact.contactConsultantRelationSettings) {
            if (this.contact.contactConsultantRelationSettings.formality) data.formData.formality = this.contact.contactConsultantRelationSettings.formality
            if (this.contact.contactConsultantRelationSettings.correspondenceSalutation) {
                data.formData.correspondenceSalutationId = this.contact.contactConsultantRelationSettings.correspondenceSalutation.id
                data.defineCustomSalutation = true
            }
            if (this.contact.contactConsultantRelationSettings.correspondence_salutation_override) {
                data.formData.correspondenceSalutationOverride = this.contact.contactConsultantRelationSettings.correspondence_salutation_override
                data.defineCustomSalutation = true
            }
        }

        return data
    },
    computed: {
        currentSalutation () {
            if (this.formData.correspondenceSalutationOverride?.contentHTML?.length) {
                return this.formData.correspondenceSalutationOverride
            } else if (this.formData.correspondenceSalutationId) {
                return this.correspondenceSalutations.find(correspondenceSalutation => correspondenceSalutation.id === this.formData.correspondenceSalutationId).salutation
            } else {
                const formality = this.formData.formality || DocumentContentFormality.FORMAL
                return this.correspondenceSalutations.find(correspondenceSalutation => {
                    if (correspondenceSalutation.is_default !== true) return false
                    if (correspondenceSalutation.contact_type !== this.contact.type) return false
                    if (correspondenceSalutation.formality !== formality) return false
                    if (correspondenceSalutation.gender && correspondenceSalutation.gender !== this.contact.gender) return false
                    if (correspondenceSalutation.language.id !== this.contact.correspondenceLanguage.id) return false
                    return true
                }).salutation
            }
        },
    },
    watch: {
        defineCustomSalutation (newValue) {
            if (newValue === false) {
                this.formData.correspondenceSalutationId = null
                this.formData.correspondenceSalutationOverride = EditorContent.create({ schemaType: EditorContentSchemaType.SALUTATION })
            }
        },
    },
    created () {
        if (this.$can('Feature:correspondence:core')) {
            CorrespondenceSalutationService.all().then(response => {
                this.correspondenceSalutations = response.data
            })
        }
    },
    methods: {
        stringWithPlaceholderTags (...args) {
            return stringWithPlaceholderTags(...args)
        },
    },
}
</script>
