import { mapSubject } from '@/helpers/acl'

export class Policy {
    beforeAll (verb, user, subject) {
        if (!(user && user.aclPermissions.includes(`${mapSubject(subject)}:${verb}`))) return false
    }
}

export class CRUDPolicy extends Policy {
    constructor () {
        super()
        this.create = true
        this.read = true
        this.update = true
        this.delete = true
    }
}
