<template>
    <page-loading-indicator
        v-if="state.isLoading"
        :number-of-breadcrumb-elements="2"
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="`${$tc('views.correspondence.mail-processing.cover-letter', 2)} (${$t('common.mail-processing.mail-processing')})`" :to="{ name: 'mail-processing-template-list' }" data-test="breadcrumb:mail-processing-template-list" />
                <q-breadcrumbs-el :label="state.mailProcessingTemplate.name" :to="{ name: 'mail-processing-template-update', params: { mailProcessingTemplateId: state.mailProcessingTemplate.id } }" data-test="breadcrumb:mail-processing-template-list" />
            </template>

            <h1>{{ state.mailProcessingTemplate.name }}</h1>

            <template v-slot:actions>
                <actions
                    v-if="state.mailProcessingTemplate.actions"
                    :actions="state.mailProcessingTemplate.actions"
                    :number-of-next-best-actions="0"
                    @click="handleActions"
                />

                <!-- Delete mailProcessingTemplate -->
                <form-dialog
                    ref="deleteDialog"
                    double-confirm
                    @submit="handleDelete"
                >
                    <template v-slot:title>{{ $tc('common.mail-processing.delete-mail-processing-template', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.mail-processing.delete-mail-processing-template--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:name><b>{{ state.mailProcessingTemplate.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <div class="row">
            <div class="col-xs-12 col-lg-10 col-xl-8">
                <mail-processing-template-form
                    ref="mailProcessingTemplateForm"
                    :mail-processing-template="state.mailProcessingTemplate"
                    @cancel="onCancel"
                    @submit="onSubmit"
                />
            </div>
        </div>
    </page-wrapper>
</template>

<script>

import Actions from '@/components/Actions'
import FormDialog from '@/libs/form/components/FormDialog'
import { MailProcessingTemplate } from '@/models/mailProcessingTemplate'
import { onBeforeMount, reactive, ref } from 'vue'
import { useRouter } from '@/composables/router'
import { useI18n } from '@/composables/i18n'
import { useQuasar } from '@/composables/quasar'
import MailProcessingTemplateForm from '@/components/correspondence/MailProcessingTemplateForm'

export default {
    name: 'MailProcessingTemplateUpdate',
    meta () {
        return {
            title: this.state.isLoading ? `${this.$t('common.term.loading')} …` : this.state.mailProcessingTemplate.name,
        }
    },
    components: {
        Actions, FormDialog,
        MailProcessingTemplateForm,
    },
    props: {
        mailProcessingTemplateId: {
            type: String,
            required: true,
        },
    },
    setup (props) {
        // Composables
        const router = useRouter()
        const $q = useQuasar()
        const { t, tc } = useI18n()

        // Data
        const state = reactive({
            isLoading: true,
            mailProcessingTemplate: null,
        })

        // Template refs
        const mailProcessingTemplateForm = ref(null)
        const deleteDialog = ref(null)

        // Functions
        async function fetchObjects () {
            state.mailProcessingTemplate = await MailProcessingTemplate.objects.get(props.mailProcessingTemplateId)
            state.isLoading = false
        }
        function onSubmit (formData) {
            state.mailProcessingTemplate.update(formData)
                .then(() => {
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.correspondence.mail-processing-template-updated-success', 1),
                    })
                    router.replace({ name: 'mail-processing-template-list' })
                })
                .catch(error => {
                    $q.notify({
                        type: 'negative',
                        title: t('common.term.error'),
                        message: tc('common.notifications.correspondence.mail-processing-template-updated-error', 1),
                    })
                    mailProcessingTemplateForm.value.formComponent.formBuilder.showFormErrorMessage(error)
                    mailProcessingTemplateForm.value.formComponent.formBuilder.resetFormSubmitStatus()
                })
        }
        function onCancel () {
            router.go(-1)
        }
        function handleActions (action) {
            switch (action.key) {
                case MailProcessingTemplate.action.DELETE:
                    deleteDialog.value.open()
                    break
            }
        }
        function handleDelete () {
            state.mailProcessingTemplate.delete()
                .then(() => {
                    deleteDialog.value.close()
                    router.replace({ name: 'mail-processing-template-list' })
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.correspondence.mail-processing-template-deleted-success', 1),
                    })
                })
                .catch(error => {
                    deleteDialog.value.showFormErrorMessage(error)
                    deleteDialog.value.resetFormSubmitStatus()
                    $q.notify({
                        type: 'negative',
                        message: tc('common.term.error', 1),
                        caption: tc('common.notifications.correspondence.mail-processing-template-deleted-error', 1),
                    })
                })
        }

        // Lifecycle hooks
        onBeforeMount(() => {
            fetchObjects()
        })

        return {
            // Data
            state,

            // Template refs
            mailProcessingTemplateForm,
            deleteDialog,

            // Functions
            onSubmit,
            onCancel,
            handleActions,
            handleDelete,
        }
    },
}
</script>
