import gql from 'graphql-tag'

export const COMMISSION_SPLIT_CREATE_MUTATION = gql`
    mutation commissionSplitCreate(
        $contractId: ID!
        $commissionSplit: CommissionSplitInput!
    ) {
        commissionSplitCreate(
            contract_id: $contractId,
            commissionSplit: $commissionSplit
        ) {
            id,
            additionalRecipient {
                id
                first_name,
                last_name
            }
            split_rate,
            active_from,
            active_until
            is_active
        }
    }
`
