<template>
    <page-wrapper>
        <page-header>
            <h1>{{ $tc('views.admin.settings.title', 2) }} ({{ $tc('common.term.dashboard') }})</h1>
        </page-header>
        <!-- TODO: Add some useful info here (or or check if this view should be temporary removed until there is something useful to add) -->
    </page-wrapper>
</template>

<script>
export default {
    name: 'AdminDashboard',
    meta () {
        return {
            title: this.$tc('views.admin.settings.title', 2),
        }
    },
}
</script>
