<template>
    <form
        data-test="form:commission-list-import-excel-mapping"
        class="row q-col-gutter-sm q-mb-md"
        @submit.prevent="onFormSubmit"
    >
        <div class="col-xs-12 col-lg-10 col-xl-8">
            <!-- eslint-disable vue/no-v-html -->
            <info-box
                v-if="formErrorMessage"
                type="negative"
                data-test="error:global-message"
                class="q-mb-xs"
                no-margin
                v-html="formErrorMessage"
            />
            <!-- eslint-enable -->

            <div class="row q-col-gutter-md">
                <div class="col-xs-12 col-lg-6">
                    <form-builder item-key="name" autofocus />
                </div>
                <div class="col-xs-12 col-lg-6">
                    <form-builder v-if="currentMode === ViewMode.CREATE" item-key="productProvider" />
                    <base-input
                        v-else
                        :label="$tc('common.product.product-provider', 1)"
                        :value="commissionListImportExcelMapping.productProvider.name"
                        dense
                        filled
                        square
                        disable
                        required
                    />
                </div>
                <div class="col-xs-12">
                    <q-separator />
                </div>
                <div class="col-xs-6 col-sm-4 col-lg-3">
                    <form-builder item-key="commissionEntriesStartRow" min="1" />
                </div>
                <div class="col-xs-6 col-sm-4 col-lg-3">
                    <form-builder item-key="contractNumberColumn" mask="XX" />
                </div>
                <div class="col-xs-6 col-sm-4 col-lg-3">
                    <form-builder item-key="commissionTypeColumn" mask="XX" />
                </div>
                <div class="col-xs-6 col-sm-4 col-lg-3">
                    <form-builder item-key="commissionAmountColumn" mask="XX" />
                </div>
                <div class="col-xs-6 col-sm-4 col-lg-3">
                    <form-builder item-key="premiumFromColumn" mask="XX" />
                </div>
                <div class="col-xs-6 col-sm-4 col-lg-3">
                    <form-builder item-key="premiumFromDateFormat" dense />
                </div>
                <div class="col-xs-6 col-sm-4 col-lg-3">
                    <form-builder item-key="premiumToColumn" mask="XX" />
                </div>
                <div class="col-xs-6 col-sm-4 col-lg-3">
                    <form-builder item-key="premiumToDateFormat" dense />
                </div>
                <div class="col-xs-6 col-sm-4 col-lg-3">
                    <form-builder item-key="customerFullNameColumn" mask="XX" />
                </div>
            </div>
        </div>

        <in-page-footer>
            <base-button
                :label="$t('common.term.cancel')"
                primary-button
                flat
                @click="cancel"
            />
            <base-button
                type="submit"
                data-test="btn:submit"
                :label="currentMode === ViewMode.CREATE ? $t('common.term.create') : $t('common.term.save')"
                :disable="formSubmitStatus"
                :loading="formSubmitStatus"
                primary-button
            />
        </in-page-footer>
    </form>
</template>

<script>
import { ViewMode } from '@/enums'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import BaseInput from '@/components/form/BaseInput'
import COMMISSION_LIST_IMPORT_EXCEL_MAPPING_FORM from '@/forms/commissionListImportExcelMapping.form'
// TODO bug @TFU: verify form validators in case they are more than one

export default {
    name: 'CommissionListImportExcelMappingForm',
    components: {
        BaseInput,
    },
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema () {
            const FORM_CONFIGURATION = JSON.parse(JSON.stringify(COMMISSION_LIST_IMPORT_EXCEL_MAPPING_FORM))

            if (this.commissionListImportExcelMapping) {
                const productProviderFieldIndex = FORM_CONFIGURATION.children.findIndex(item => item.key === 'productProvider')
                if (productProviderFieldIndex > -1) FORM_CONFIGURATION.children.splice(productProviderFieldIndex, 1)
            }

            return FORM_CONFIGURATION
        },
    },
    props: {
        commissionListImportExcelMapping: {
            type: Object,
            required: false,
            default () {
                return undefined
            },
        },
    },
    data () {
        const data = {
            ViewMode,
            currentMode: (this.commissionListImportExcelMapping) ? ViewMode.UPDATE : ViewMode.CREATE,
            formData: {},
        }

        if (data.currentMode === ViewMode.UPDATE) {
            Object.assign(data.formData, {
                name: this.commissionListImportExcelMapping.name,
                commissionEntriesStartRow: this.commissionListImportExcelMapping.commission_entries_start_row,
                contractNumberColumn: this.commissionListImportExcelMapping.contract_number_column,
                commissionTypeColumn: this.commissionListImportExcelMapping.commission_type_column,
                commissionAmountColumn: this.commissionListImportExcelMapping.commission_amount_column,
                premiumFromColumn: this.commissionListImportExcelMapping.premium_from_column,
                premiumFromDateFormat: this.commissionListImportExcelMapping.premium_from_date_format,
                premiumToColumn: this.commissionListImportExcelMapping.premium_to_column,
                premiumToDateFormat: this.commissionListImportExcelMapping.premium_to_date_format,
                customerFullNameColumn: this.commissionListImportExcelMapping.customer_full_name_column,
            })
        }
        return data
    },
    methods: {
        cancel () {
            this.$router.push({ name: 'commission-list-import-excel-mapping-list' })
        },
    },
}
</script>
