import gql from 'graphql-tag'

export const CONTACT_FILE_TYPES_QUERY = gql`
    query contactFileTypes(
        $status: ContactFileTypeStatus = ACTIVE
    ) {
        contactFileTypes(
            status: $status
        ) {
            id
            status
            key
            name
        }
    }
`
