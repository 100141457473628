import { SimpleRichText as SimpleRichTextBase } from '@max/tiptap-extensions'

import Gapcursor from '@tiptap/extension-gapcursor'
import Dropcursor from '@tiptap/extension-dropcursor'
import History from '@tiptap/extension-history'

export const SimpleRichText = SimpleRichTextBase.extend({
    addExtensions() {
        const baseExtensions = this.parent?.() || []
        return [
            ...baseExtensions,

            Gapcursor,
            Dropcursor,
            History,
        ]
    },
})
