import gql from 'graphql-tag'

export const COMMISSION_RATE_ADJUSTMENT_RULE_SET_QUERY = gql`
    query commissionRateAdjustmentRuleSet(
        $id: ID!
    ) {
        commissionRateAdjustmentRuleSet(
            id: $id
        ) {
            id
            productProvider {
                id
                contact_number
                name
                display_name
            }
            rules {
                id
                igb2bSectorCode {
                    id
                    code
                    name
                }
                custom_sector_code
                commission_rate
                adjusted_commission_rate
                created_at
                updated_at
            }
            created_at
            updated_at
        }
    }
`
