import gql from 'graphql-tag'

export const DOCUMENT_LAYOUTS_QUERY = gql`
    query documentLayouts(
        $page: Int = 1
        $count: Int = 200
        $ownerId: ID!
        $orderField: DocumentLayoutOrderField = NAME
        $orderDirection: OrderDirection = DESC
        $filterQuery: String
    ) {
        documentLayouts(
            page: $page
            count: $count
            owner_id: $ownerId
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                query: $filterQuery
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                name
                name_language_versions
                owner {
                    id
                    company_name
                }
                pageBackground {
                    name
                }
                page_margins
                is_default
                created_at
                updated_at
            }
        }
    }
`
