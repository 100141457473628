<template>
    <fake-select
        v-if="isFetchingObjects"
        v-bind="$attrs"
    />
    <base-select-filter
        v-else

        :value="value"
        v-bind="$attrs"
        :options="options"

        option-value="key"
        :option-label="getOptionLabel"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import { i18n } from '@/i18n'
import { kebabCase } from 'lodash'
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { ContactRelationshipDefinitionsService } from '@/services'
import { generateContactRelationshipDefinitionsOptions } from '@/helpers/contactRelationshipDefinition'
import { ContactRelationship } from '@/models/contactRelationship'
import FakeSelect from '@/components/FakeSelect.vue'

export default {
    name: 'ContactRelationshipDefinitionSelect',
    components: {
        FakeSelect,
        BaseSelectFilter,
    },
    props: {
        value: {
            type: String,
            default: null,
        },
        sourceNodeContact: {
            type: Object,
            required: true,
        },
        targetNodeContact: {
            type: Object,
            required: false,
        },
        excludeDefinitions: {
            type: Array,
            required: false,
            default () {
                return []
            },
        },
        filterCategories: {
            type: Array,
            default: null,
        },
    },
    data () {
        return {
            ContactRelationship,
            options: [],
            isFetchingObjects: false,
            abortController: null,
        }
    },
    computed: {
        filters () {
            const filters = {
                search: {},
            }

            Object.assign(filters.search, { contact_type: this.targetNodeContact.type })
            if (this.filterCategories) Object.assign(filters.search, { categories: this.filterCategories })

            return filters
        },
    },
    watch: {
        targetNodeContact () {
            if (this.targetNodeContact) {
                this.fetchObjects(this.filters)
            } else {
                this.options = []
                if (this.abortController) this.abortController.abort()
                this.isFetchingObjects = false
                this.$emit('fetchedObjects', this.options)
            }
        },
    },
    methods: {
        fetchObjects (filters) {
            if (this.abortController) this.abortController.abort()
            this.isFetchingObjects = true
            this.abortController = new AbortController()
            ContactRelationshipDefinitionsService.allWithOptions(filters, {
                abortController: this.abortController,
            }).then(response => {
                let options = generateContactRelationshipDefinitionsOptions(response.data, this.sourceNodeContact, this.targetNodeContact)
                if (this.excludeDefinitions.length) options = options.filter(option => !this.excludeDefinitions.includes(option.key))
                this.options = options
                this.abortController = null
                this.isFetchingObjects = false
                this.$emit('fetchedObjects', this.options)
            })
        },
        getOptionLabel (item) {
            if (!item.key) return // Needed because we want to render the disabled select before we actually load and process the options.

            let relationship = this.$tc(`common.contact.relationship.node-type-${this.targetNodeContact?.gender ? this.targetNodeContact.getGender() : 'neutral'}.${kebabCase(item.key.split(':').pop())}`, 1)
            return item.key ? `${relationship} <span class="additional-info">${i18n.t(`common.contact.relationship.node-type-prepositions.${kebabCase(item.key.split(':').pop())}`)} ${this.sourceNodeContact.getContactName()}</span>` : ''
        },
    },
}
</script>
