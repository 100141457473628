<template>
    <div class="form-builder-fields-wrapper q-mt-none">
        <div class="inner-wrapper">
            <div v-for="(systemLanguage, index) in $store.state.user.tenantSettings.systemLanguages" :key="systemLanguage.id">
                <base-input
                    :label="getInputLabel($attrs.label, systemLanguage.name)"
                    :value="value[systemLanguage.id]"
                    :error="validation[systemLanguage.id] ? validation[systemLanguage.id].$error : false"
                    :class="{ required: validation[systemLanguage.id] ? typeof validation[systemLanguage.id].required !== 'undefined' : false }"
                    :autofocus="autofocus && index === 0 ? true : null"
                    :disable="$attrs.disable"
                    :data-test="`input:localized-${systemLanguage.id}`"
                    @input="value => { onInput(systemLanguage.id, value)}"
                >
                    <template v-if="validation[systemLanguage.id]" v-slot:error>
                        <div v-show="!validation[systemLanguage.id].required">{{ $t('common.error-message.required', { field: getInputLabel($attrs.label, systemLanguage.name) }) }}</div>
                    </template>
                </base-input>
            </div>
        </div>
    </div>
</template>

<script>
import BaseInput from '@/components/form/BaseInput.vue'

export default {
    name: 'LocalizedInput',
    components: {
        BaseInput,
    },
    inheritAttrs: false,
    props: {
        value: {
            type: Object,
            required: true,
        },
        validation: {
            type: Object,
            required: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        const data = {
            values: {},
        }
        this.$store.state.user.tenantSettings.systemLanguages.forEach(systemLanguage => {
            data.values[systemLanguage.id] = this.value[systemLanguage.id] || ''
        })
        return data
    },
    methods: {
        getInputLabel (label, systemLanguage) {
            return (this.$store.state.user.tenantSettings.systemLanguages.length > 1) ? `${label} (${systemLanguage})` : label
        },
        onInput (languageId, value) {
            if (this.validation[languageId]) this.validation[languageId].$touch()
            this.values[languageId] = value
            this.$emit('input', this.values)
        },
    },
}
</script>
