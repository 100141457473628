import { createPopper } from '@popperjs/core'
import { Extension } from '@tiptap/core'
import Suggestion from '@tiptap/suggestion'
import { VueRenderer } from '@tiptap/vue-2'
import SmartObjectSuggestionList from '@/components/editor/SmartObjectSuggestionList'
import { normalizeTranslationId } from '@/helpers'

export const SmartObjectSuggestion = Extension.create({
    name: 'smartObjectSuggestion',

    defaultOptions: {
        suggestion: {
            char: '[',
            command: ({ editor, range, props }) => {
                const attributes = Object.assign(props, props.attrs)
                delete attributes.attrs

                editor
                    .chain()
                    .focus()
                    .replaceRange(range, `smartObject_${props.type}`, attributes) // Dynamically insert a specific SmartObject based on the selected items `type` property.
                    .run()
            },
            allow: ({ editor, range }) => {
                return editor.can().replaceRange(range, 'smartObject_simplePlaceholder') // Checking if it's possible to insert the most basic SmartObject (`smartObject_simplePlaceholder`) at this place should be alright, as all types of SmartObjects share the same schema configuration.
            },
        },
    },

    addKeyboardShortcuts() {
        return {
            Backspace: () => this.editor.commands.command(({ tr, state }) => {
                let isSmartObject = false
                const { selection } = state
                const { empty, anchor } = selection

                if (!empty) return false

                state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
                    if (node.type.name.startsWith('smartObject_')) {
                        isSmartObject = true
                        tr.insertText(this.options.suggestion.char || '', pos, pos + node.nodeSize)
                        return false
                    }
                })

                return isSmartObject
            }),
        }
    },

    addProseMirrorPlugins() {
        return [
            Suggestion({
                editor: this.editor,
                items: (filterQuery) => {
                    return this.options.blockEditorComponentInstance.filteredSmartObjectItems
                        .filter((item) => {
                            return this.options.blockEditorComponentInstance.$tc('common.correspondence.smart-object.' + normalizeTranslationId(item.id), 1).toLowerCase().indexOf(filterQuery.toLowerCase()) > -1
                        })
                        .slice(0, 10)
                },
                render: () => {
                    let component
                    let virtualElement
                    let popper

                    return {
                        onStart: (props) => {
                            component = new VueRenderer(
                                SmartObjectSuggestionList,
                                {
                                    parent: this.options.blockEditorComponentInstance,
                                    propsData: props,
                                }
                            )
                            this.options.blockEditorComponentInstance.$el.append(component.element)

                            virtualElement = { getBoundingClientRect: props.clientRect }
                            popper = createPopper(virtualElement, component.element, {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [2, 4],
                                        },
                                    },
                                ],
                                placement: 'bottom-start',
                            })
                        },
                        onUpdate(props) {
                            component.updateProps(props)
                            virtualElement.getBoundingClientRect = props.clientRect
                            popper.update()
                        },
                        onKeyDown(props) {
                            if (props.event.key === 'Escape') {
                                this.onExit()
                                return true
                            }
                            return component.ref?.onKeyDown(props)
                        },
                        onExit() {
                            popper.destroy()
                            component.element.remove()
                            component.destroy()
                        },
                    }
                },
                ...this.options.suggestion,
            }),
        ]
    },
})
