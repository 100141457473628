import gql from 'graphql-tag'

export const USER_CHANGE_EMAIL_MUTATION = gql`
    mutation userChangeEmail($id: ID!, $email: String!) {
        userChangeEmail(
            id: $id,
            email: $email,
        ) {
            new_email
        }
    }
`
