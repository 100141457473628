import { ActivityLogParser } from '@/parsers/activityLog'
import { i18n } from '@/i18n'

export class CommissionListEntryActivityLogParser extends ActivityLogParser {
    static getLogDescriptionCreated (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `<b>${i18n.tc('common.change-log.subject.this.commission-list-entry', 1)}</b>`,
        }
        return i18n.t('common.change-log.someone-created-something', interpolationParams)
    }

    static getLogDescriptionUpdated (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `${i18n.tc('common.change-log.someone-updated-something--field', activityLog.attributeKeys.length)} <b>${activityLog.attributeLabels}</b>`,
        }
        return i18n.t('common.change-log.someone-updated-something', interpolationParams)
    }

    static getLogDescriptionDeleted (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `<b>${i18n.tc('common.change-log.subject.this.commission-list-entry', 1)}</b>`,
        }
        return i18n.t('common.change-log.someone-deleted-something', interpolationParams)
    }

    static getLogDescriptionStartPosting (activityLog, data) {
        return i18n.tc('common.change-log.someone-started-something', 1, { 'someone': data.causer, 'something': `<b>${ i18n.tc('common.change-log.action.commission-list-entry-posting', 1) }</b>` })
    }

    static getLogDescriptionCancelPosting (activityLog, data) {
        return i18n.tc('common.change-log.someone-cancelled-something', 1, { 'someone': data.causer, 'something': `<b>${ i18n.tc('common.change-log.action.commission-list-entry-posting', 1) }</b>` })
    }

    static getLogDetailsCreated (activityLog, data) {
        return [
            {
                key: 'commission_type',
                label: i18n.tc('forms.field.label.commission-list-entry.matched-contract-contract-number-number', 1),
                newValue: activityLog.properties.attributes['matchedContract.contractNumber.number'],
            },
        ]
    }
}
