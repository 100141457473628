import gql from 'graphql-tag'

export const TASK_CHANGE_STATUS_MUTATION = gql`
    mutation taskUpdate(
        $id: ID!
        $status: TaskStatus!
    ) {
        taskChangeStatus(
            id: $id
            status: $status
        ) {
            id
            status
            updated_at
            done_at
        }
    }
`
