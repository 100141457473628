<template>
    <form data-test="form:contact-person-update" @submit.prevent="onFormSubmit" @keyup.esc="cancel">
        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="formErrorMessage"
            type="negative"
            data-test="error:global-message"
            v-html="formErrorMessage"
        />
        <!-- eslint-enable -->

        <div class="row q-col-gutter-md">
            <div class="col-xs-6 col-sm-2 col-md-6 col-lg-2">
                <form-builder item-key="gender" autofocus />
            </div>
            <div class="col-xs-6 col-sm-2 col-md-6 col-lg-2">
                <form-builder item-key="title" />
            </div>
            <div class="col-xs-12 col-sm-4 col-md-12 col-lg-4">
                <form-builder item-key="firstName" />
            </div>
            <div class="col-xs-12 col-sm-4 col-md-12 col-lg-4">
                <form-builder item-key="lastName" />
            </div>
        </div>

        <div class="row q-col-gutter-md q-pt-md">
            <div class="col-xs-12 col-sm-6 col-md-12 col-lg-6 col-xl-6">
                <form-builder item-key="profession" />
            </div>
            <div class="col-xs-12 col-sm-6 col-md-12 col-lg-6 col-xl-6">
                <form-builder item-key="role" />
            </div>
        </div>

        <div class="row q-col-gutter-md q-pt-md">
            <div class="col-xs-6 col-sm-4 col-md-6 col-lg-4 col-xl-3">
                <form-builder item-key="dateOfBirth" />
            </div>
            <div class="col-xs-6 col-sm-8 col-md-6 col-lg-4 col-xl-3">
                <form-builder item-key="nationality" />
            </div>
            <div class="col-xs-12 col-sm-4 col-md-6 col-lg-4 col-xl-3">
                <form-builder item-key="civilStatus" />
            </div>
            <div class="col-xs-12 col-sm-4 col-md-6 col-lg-4 col-xl-3">
                <form-builder item-key="correspondenceLanguageId" />
            </div>
            <div class="col-xs-6 col-sm-4 col-md-6 col-lg-4 col-xl-3">
                <form-builder item-key="drivingLicenceSince" />
            </div>
            <div class="col-xs-12 col-sm-4 col-md-6 col-lg-4 col-xl-3">
                <!-- TODO: Add explanation (preferably in `info` element which might be introduced for <base-input>) -->
                <form-builder item-key="personalPronoun" />
            </div>
            <div class="col-xs-12 col-sm-8 col-md-12 col-lg-8 col-xl-6">
                <form-builder item-key="website" />
            </div>
        </div>

        <div class="row q-col-gutter-md">
            <div class="col-xs-12">
                <div class="row q-col-gutter-md q-pt-md">
                    <div class="col-xs-12 col-sm-4 col-md-12 col-lg-4 col-xl-3">
                        <form-builder item-key="customerStatus" />
                    </div>
                    <div class="col-xs-6 col-sm-4 col-md-6 col-lg-4 col-xl-3">
                        <form-builder item-key="customerSince" />
                    </div>
                    <div class="col-xs-6 col-sm-4 col-md-6 col-lg-4 col-xl-3">
                        <form-builder item-key="customerUntil" :disable="formData.customerStatus !== CustomerStatus.INACTIVE" />
                        <q-tooltip v-if="formData.customerStatus !== CustomerStatus.INACTIVE" :delay="1000" :offset="[0, 10]">{{ $t('common.contact.customer-until--disabled-info') }}</q-tooltip>
                    </div>
                    <div class="col-xs-12 col-lg-6">
                        <form-builder item-key="consultants" :inject-consultants="contact.inactiveConsultants" />
                    </div>
                </div>
            </div>
        </div>

        <form-dialog
            ref="customerUntilDialog"
            @confirm="resetCustomerUntilValue"
            @cancel="restoreCustomerStatusValue"
        >
            <template v-slot:title>{{ $tc('common.contact.customer-status.status-change', 1) }}</template>
            <template v-slot:default>
                <p>{{ $t('common.contact.customer-status.status-change--reset-customer-until-date-info', { date: contact.formattedCustomerUntil }) }}</p>
            </template>
        </form-dialog>

        <div class="row q-col-gutter-md q-pt-md">
            <div class="col-xs-12">
                <base-button
                    data-test="btn:cancel"
                    :label="$t('common.term.cancel')"
                    :disable="formSubmitStatus"
                    class="q-mr-sm"
                    flat
                    primary-button
                    @click="cancel"
                />
                <base-button
                    data-test="btn:submit"
                    type="submit"
                    :label="$t('common.term.save')"
                    :disable="formSubmitStatus"
                    :loading="formSubmitStatus"
                    primary-button
                />
            </div>
        </div>
    </form>
</template>

<script>
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import { CustomerStatus } from '@/enums/graphql'
import { lightFormat } from 'date-fns'

import CONTACT_FIELDS from '@/forms/contact.fields.json'
import CONTACT_PERSON_FIELDS from '@/forms/contact.person.fields.json'

import FormDialog from '@/components/form/FormDialog'

export default {
    name: 'ContactPersonUpdateForm',
    components: {
        FormDialog,
    },
    mixins: [formBuilderMixin],
    props: {
        contact: {
            type: Object,
            required: true,
        },
    },
    formBuilderSettings: {
        schema: {
            type: 'root',
            children: [
                ...CONTACT_FIELDS.children,
                ...CONTACT_PERSON_FIELDS.children,
            ],
        },
    },
    validations () {
        return {
            formData: {
                customerUntil: {
                    required: (value) => {
                        if (this.formData.customerStatus === CustomerStatus.INACTIVE && !value) return false
                        return true
                    },
                },
            },
        }
    },
    data () {
        const customerUntil = !this.contact.customer_until
            ? this.contact.customer_status === CustomerStatus.INACTIVE
                ? lightFormat(new Date(), 'yyyy-MM-dd')
                : ''
            : this.contact.customer_until

        return {
            CustomerStatus,
            customerUntil: customerUntil,
            formData: {
                gender: this.contact.gender,
                personalPronoun: this.contact.personal_pronoun,
                title: this.contact.title,
                profession: this.contact.profession,
                role: this.contact.role,
                firstName: this.contact.first_name,
                lastName: this.contact.last_name,
                nationality: (this.contact.nationality) ? this.contact.nationality.id : null,
                civilStatus: this.contact.civil_status,
                drivingLicenceSince: this.contact.driving_licence_since || '',
                dateOfBirth: this.contact.date_of_birth || '',
                // dateOfDeath: this.contact.date_of_death,
                website: this.contact.website,
                customerStatus: this.contact.customer_status,
                customerSince: this.contact.customer_since || '',
                consultants: this.contact.consultants.map(consultant => consultant.id),
                correspondenceLanguageId: this.contact.correspondenceLanguage.id,
                customerUntil: customerUntil,
            },
        }
    },
    watch: {
        'formData.customerStatus' (newVal, oldVal) {
            if (newVal === CustomerStatus.INACTIVE) this.updateCustomerUntilValue()
            if (oldVal === CustomerStatus.INACTIVE && newVal !== CustomerStatus.INACTIVE) {
                if (this.contact.customer_until) {
                    this.$refs.customerUntilDialog.open()
                } else {
                    this.resetCustomerUntilValue()
                }
            }
        },
        'formData.customerUntil' (newVal) {
            if (!newVal) return
            this.customerUntil = newVal
        },
    },
    methods: {
        handleFormData (formData) {
            this.contact.update(formData)
                .then(person => {
                    this.$emit('update', person)
                    this.$emit('done')
                    this.resetFormSubmitStatus()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contact.contact-saved-success', 1),
                    })
                    this.customerUntil = null
                })
                .catch(error => {
                    this.showFormErrorMessage(error)
                    this.resetFormSubmitStatus()
                })
        },
        cancel () {
            this.$emit('cancel')
        },
        updateCustomerUntilValue () {
            if (!this.customerUntil) this.customerUntil = lightFormat(new Date(), 'yyyy-MM-dd')
            this.formData.customerUntil = this.customerUntil
        },
        resetCustomerUntilValue () {
            this.formData.customerUntil = ''
        },
        restoreCustomerStatusValue () {
            this.formData.customerStatus = CustomerStatus.INACTIVE
        },
    },
}
</script>
