<template>
    <nav class="main-menu-wrapper" role="navigation">
        <component :is="`main-menu-${appSubSection}`" v-if="appSubSection" />
        <template v-else>
            <main-menu-default />
            <q-toggle
                v-model="editMode"
                :label="$t('common.term.edit-mode')"
                color="edit-mode-link"
                checked-icon="mib-typing"
                data-test="toggle:edit-mode"
            />
        </template>
    </nav>
</template>

<script>
import { EventBus } from '@/event-bus'
import MainMenuDefault from './MainMenuDefault'
import MainMenuAccount from './MainMenuAccount'
import MainMenuUserManual from './MainMenuUserManual'
import MainMenuAdmin from './MainMenuAdmin'
import MainMenuDev from './MainMenuDev'

export default {
    name: 'MainMenu',
    components: {
        MainMenuDefault,
        MainMenuAccount,
        MainMenuUserManual,
        MainMenuAdmin,
        MainMenuDev,
    },
    computed: {
        appSubSection () {
            return this.$route.meta.appSubSection
        },
        editMode: {
            get () {
                return this.$store.state.editMode
            },
            set (val) {
                if (val) {
                    this.$store.dispatch('enableEditMode')
                    // TODO: Check why this notification is not shown anymore
                    this.$q.notify({
                        message: this.$t('common.notifications.edit-mode-enabled'),
                        timeout: 1500,
                    })
                } else {
                    this.$store.dispatch('disableEditMode')
                    // TODO: Check why this notification is not shown anymore
                    this.$q.notify({
                        message: this.$t('common.notifications.edit-mode-disabled'),
                        timeout: 1500,
                    })
                }
            },
        },
    },
    created () {
        EventBus.$on('shortcut:editMode', this.toggleEditMode)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:editMode', this.toggleEditMode)
    },
    methods: {
        toggleEditMode () {
            this.$store.dispatch('toggleEditMode')
        },
    },
}
</script>

<style lang="scss">
.main-menu-wrapper {
    // Prevent layout issues caused by overflowing elements
    overflow-x: hidden;

    // Prevent horizontal scrollbars during opening animation
    .q-expansion-item__content {
        overflow-x: hidden;
    }
}

.main-menu {
    // Default menu styling
    font-weight: bold;
    // font-weight: 900;
    color: var(--color-text-primary);

    // Prevent layout issues when link texts are too long
    .q-item__section {
        word-break: break-word;
        hyphens: auto;
    }

    // Current menu item styling
    .q-item.q-router-link--active,
    .q-item--active {
        color: var(--q-color-primary-dark, currentColor);
    }

    // Current menu item indicator bar at the left
    & > .q-router-link,
    & > .q-expansion-item > .q-expansion-item__container > .q-router-link {
        &--active,
        &--exact-active {
            box-shadow: 5px 0 0 inset var(--q-color-accent);

            // Hide active subitem indicator
            &::before {
                display: none;
            }
        }
    }

    // Expansion icon styling when "expand-icon-toggle" is set
    // (Expansion events applied to the expand icon only and not to the whole header.)
    .q-item--clickable.q-link {
        .q-expansion-item__toggle-icon {
            &:hover,
            &:focus,
            &:active {
                color: var(--color-link)
            }
        }
    }

    // Reduce spacing between icons and text (and adjust nested items accordingly)
    .q-item__section--avatar {
        min-width: $sizeSpacingLg * 2;
    }
}

// Expansion menu items styling
// Wrapper around the whole content of an expansion item (including the main link)
.q-expansion-item {

    // Wrapper around the content of an expansion item
    .q-expansion-item__content {
        font-weight: normal;

        // Nav level 3+ wrapper
        .q-expansion-item__content {
            // Indent nested expansion items
            padding-left: $sizeSpacingMd;

            // Add visual hierarchy
            font-weight: normal;

            // Special "tree" styling for expansion items (expanded line so that potential later siblings also are connected)
            &::after {
                content: "";
                position: absolute;
                top: -1.1em;
                left: $sizeSpacingMd;
                height: 100%;
                border-left: 1px solid var(--color-border-secondary);
            }

            // Nav level 3+ items
            .q-item {

                // "Tree" lines
                &::before {
                    content: "";
                    position: absolute;
                    width: $sizeSpacingSm;
                    height: 100%;
                    left: 0;
                    top: -1.1em;

                    border-bottom: 1px solid var(--color-border-secondary);
                    border-left: 1px solid var(--color-border-secondary);
                }

                // Active state
                &.q-router-link--active {

                    &::before {
                        //border-bottom: 2px solid var(--q-color-accent);
                        border-bottom-color: currentColor;
                    }
                }
            }
        }
    }

    // Adjust indentation of level 2 items (level 1 has icons)
    .main-menu > & > .q-expansion-item__container > .q-expansion-item__content {
        padding-left: $sizeSpacingLg * 2 !important; // !important needed since Quasar adds the padding as inline styles to the element
    }
}

// Current menu item indicator triangle at the right
// TODO improvement: Tweak this for mobile screens
@mixin currentNavIndicator {
    position: relative;

    &::after {
        $sizeTriangle: 1.2rem;

        content: "";
        position: absolute;
        right: (-($sizeTriangle / 8));
        top: 45%;
        width: $sizeTriangle;
        height: $sizeTriangle;
        z-index: 100;
        opacity: 1;

        transform: rotate(45deg) translateY(-50%);
        transform-origin: center;
        transition: opacity 0.2s ease-in-out;

        background: var(--color-background-primary);
        border: 1px solid var(--color-border-secondary);

        // Navigation on small screens
        @include mq($to: $sizeBreakpointMd) {
            width: 0.7rem;
            height: 0.7rem;

            border: none;
            background-color: rgba(0, 0, 0, 0.4);

            // The transition is only added to prevent weird effects when the viewport is being resized
            transition: background-color 0s 1s ease-in-out;
        }
    }
}

// Add indicator to active items
// This needs to be very specific as there are lots of cases where the navigation item is not `--exact-active` when f.e. a detail view is used.
.main-menu {
    .q-router-link--exact-active,
    & > .q-item.q-router-link--active,
    & > .q-expansion-item.q-expansion-item--expanded .q-list > .q-item.q-router-link--active {
        @include currentNavIndicator;
    }
}

// Handling of collapsed expansion items when a sub-item is active
.main-menu .q-expansion-item--collapsed > .q-expansion-item__container > .q-router-link--active {
    @include currentNavIndicator;
}

// TODO improvement @MTR: If possible, we should get rid of this and find a proper solution with (nested?) routes.
// Hide indicator from expanded active expansion items when a subitem points to the same route
.main-menu .q-expansion-item--expanded.subitem-points-to-same-route > .q-expansion-item__container > .q-router-link--exact-active {
    &::after {
        opacity: 0;
    }
}
</style>
