import gql from 'graphql-tag'

export const COMMISSION_LIST_START_POSTING_MUTATION = gql`
    mutation commissionListStartPosting(
        $id: ID!,
        $commissionListEntryIds: [ID!]
        $startAll: Boolean!
    ) {
        commissionListStartPosting(
            id: $id
            commission_list_entry_ids: $commissionListEntryIds
            start_all: $startAll
        ) {
            number_of_affected_entries
            status
        }
    }
`
