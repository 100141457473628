<template>
    <base-select-filter
        v-if="productTemplates.length"

        :value="value"
        v-bind="$attrs"
        :options="productTemplates"

        option-label="name"
        option-value="id"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { ProductTemplate } from '@/models/productTemplate'

export default {
    name: 'ProductTemplateSelect',
    components: {
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
    },
    data () {
        return {
            productTemplates: [],
        }
    },
    created () {
        ProductTemplate.objects.all().then(response => {
            this.productTemplates = response.data
        })
    },
}
</script>
