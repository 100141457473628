var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.contract)?_c('page-loading-indicator',{attrs:{"number-of-breadcrumb-elements":5,"subtitle":""}}):_c('page-wrapper',[_c('page-header',{attrs:{"back-link-route":{ name: 'contract-detail', params: { id: _vm.contract.id } },"back-link-label":_vm.$tc('views.contract.settings.back-to-contract', 1)},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_c('q-breadcrumbs-el',{attrs:{"label":_vm.contract.customer.getContactName(),"to":{ name: 'contact-detail', params: { id: _vm.contactId } }}}),_c('q-breadcrumbs-el',{attrs:{"label":_vm.$tc('common.term.contract', 2),"to":{ name: 'contact-detail', params: { id: _vm.contactId } }}}),_c('q-breadcrumbs-el',{attrs:{"label":_vm.contract.currentContractNumber,"to":{ name: 'contract-detail', params: { id: _vm.contract.id } }}}),_c('q-breadcrumbs-el',{attrs:{"label":_vm.$tc('views.contract.settings.settings', 2),"to":{ name: 'contract-settings', params: { id: _vm.id } }}}),(_vm.$route.meta && _vm.$route.meta.title)?_c('q-breadcrumbs-el',{attrs:{"label":_vm.$route.meta.title,"to":{ name: _vm.$route.name }}}):_vm._e()]},proxy:true}])},[_c('h1',[_vm._v(_vm._s(_vm.contract.currentContractNumber)+" "),_c('span',{staticClass:"subtitle"},[_vm._v("("+_vm._s(_vm.contract.currentContractInformation.product.providerName)+")")])])]),_c('div',{staticClass:"row q-mt-md q-col-gutter-x-md"},[_c('div',{staticClass:"col-xs-12 col-md-3 col-lg-2"},[_c('in-page-navigation',[_c('q-route-tab',{attrs:{"to":{
                        name: 'contract-settings-commission-settings',
                        params: {
                            id: _vm.id,
                            contactId: _vm.contactId
                        }
                    },"label":_vm.$tc('views.contract.settings.commission.commission-setting', 2),"name":"contract-settings-commission-settings","ripple":false,"data-test":"tab:contract-settings-commission-settings"}})],1)],1),_c('div',{staticClass:"col-xs-12 col-md-9 col-lg-10"},[_c('router-view',{attrs:{"contract":_vm.contract}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }