import gql from 'graphql-tag'

export const COMMISSION_RATE_ADJUSTMENT_RULE_SET_DELETE_MUTATION = gql`
    mutation commissionRateAdjustmentRuleSetDelete(
        $id: ID!
    ) {
        commissionRateAdjustmentRuleSetDelete(
            id: $id
        ) {
            status
        }
    }
`
