<template>
    <span class="contact-detail-label-wrapper">
        <q-badge
            class="contact-detail-label"
            multi-line
            :class="{ 'main': isMain }"
            data-test="text:contact-label-badge"
        >
            <q-icon
                v-if="isMain"
                name="mib-rating-star"
                class="q-mr-xs"
                data-test="icon:is-main"
            />{{ label }}
        </q-badge>
        <q-tooltip
            v-if="isMain && isMainType"
            anchor="center right"
            self="center left"
            :delay="1000"
            :offset="[10, 10]"
        >
            <template v-if="isMainType">{{ mainType() }}</template>
        </q-tooltip>
    </span>
</template>

<script>
export default {
    name: 'ContactDetailLabelBadge',
    props: {
        label: { type: String, default: '' },
        isMain: { type: Boolean, default: false },
        isMainType: { type: String, default: '' },
    },
    methods: {
        mainType () {
            if (this.isMainType) {
                switch (this.isMainType) {
                    case 'address':
                        return this.$t('common.contact.main-address')
                    case 'correspondenceAddress':
                        return this.$t('common.contact.main-correspondence-address')
                    case 'email':
                        return this.$t('common.contact.main-email-address')
                    case 'phone':
                        return this.$t('common.contact.main-phone-number')
                    default:
                        return this.isMainType
                }
            } else {
                return this.$t('common.term.main-entry')
            }
        },
    },
}
</script>
