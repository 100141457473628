import gql from 'graphql-tag'

export const CORRESPONDENCE_LETTER_QUERY = gql`
    query correspondenceLetter(
        $id: ID!
    ) {
        correspondenceLetter(
            id: $id
        ) {
            id
            creator {
                id
                first_name
                last_name
                consultingCompany {
                    id
                }
            }
            status
            name
            target_object_type
            target_object_ids
            targetContacts {
                id
                contactNumber {
                    id
                }
                ... on Person {
                    gender
                    personal_pronoun
                    title
                    first_name
                    last_name
                }
                ... on Company {
                    company_name
                }
                addresses {
                    id
                    category {
                        id
                        key
                        name
                    }
                    label {
                        id
                        key
                        label
                    }
                    po_box
                    address1
                    address2
                    address3
                    zip
                    city
                    country {
                        id
                        name
                    }
                    valid_from
                    valid_until
                    is_main_correspondence_address
                    status
                }
                mainAddress {
                    id
                    category {
                        id
                        key
                        name
                    }
                    label {
                        id
                        key
                        label
                    }
                    po_box
                    address1
                    address2
                    address3
                    zip
                    city
                    country {
                        id
                        name
                    }
                    valid_from
                    valid_until
                    is_main_correspondence_address
                    status
                }
                consultants {
                    id
                    contact_number
                    first_name
                    last_name
                    gender
                    title
                    consultingCompany {
                        id
                    }
                    consulting_company_name
                    consulting_settings_status
                }
            }
            targetApplications {
                id
                number {
                    number
                }
                status
                customer {
                    id
                    status
                    consultingSettings {
                        status
                        company {
                            id
                            company_name
                        }
                    }
                    productProviderSettings {
                        status
                        display_name
                    }
                    contactNumber {
                        id
                    }
                    addresses {
                        id
                        category {
                            id
                            key
                            name
                        }
                        label {
                            id
                            key
                            label
                        }
                        po_box
                        address1
                        address2
                        address3
                        zip
                        city
                        country {
                            id
                            name
                        }
                        valid_from
                        valid_until
                        is_main_correspondence_address
                        status
                    }
                    mainAddress {
                        id
                        category {
                            id
                            key
                            name
                        }
                        label {
                            id
                            key
                            label
                        }
                        po_box
                        address1
                        address2
                        address3
                        zip
                        city
                        country {
                            id
                            name
                        }
                        valid_from
                        valid_until
                        is_main_correspondence_address
                        status
                    }
                    consultants {
                        id
                        contact_number
                        first_name
                        last_name
                        gender
                        title
                        consultingCompany {
                            id
                        }
                        consulting_company_name
                        consulting_settings_status
                    }
                    ... on Person {
                        first_name
                        last_name
                        gender
                        personal_pronoun
                    }
                    ... on Company {
                        company_name
                        is_tenant_company
                    }
                }
                consultant {
                    id
                    contact_number
                    first_name
                    last_name
                    gender
                    title
                    consultingCompany {
                        id
                    }
                    consulting_company_name
                    consulting_settings_status
                }
                currentContractInformation {
                    product {
                        name
                        basicProvider {
                            name
                            display_name
                        }
                    }
                    productTemplate {
                        name
                        category {
                            name
                        }
                        subCategory {
                            name
                        }
                        version
                    }
                    start_date
                    end_date
                    premium
                }
            }
            targetContracts {
                id
                contractNumbers {
                    id
                    number
                }
                status
                customer {
                    id
                    status
                    consultingSettings {
                        status
                        company {
                            id
                            company_name
                        }
                    }
                    productProviderSettings {
                        status
                        display_name
                    }
                    contactNumber {
                        id
                    }
                    addresses {
                        id
                        category {
                            id
                            key
                            name
                        }
                        label {
                            id
                            key
                            label
                        }
                        po_box
                        address1
                        address2
                        address3
                        zip
                        city
                        country {
                            id
                            name
                        }
                        valid_from
                        valid_until
                        is_main_correspondence_address
                        status
                    }
                    mainAddress {
                        id
                        category {
                            id
                            key
                            name
                        }
                        label {
                            id
                            key
                            label
                        }
                        po_box
                        address1
                        address2
                        address3
                        zip
                        city
                        country {
                            id
                            name
                        }
                        valid_from
                        valid_until
                        is_main_correspondence_address
                        status
                    }
                    consultants {
                        id
                        contact_number
                        first_name
                        last_name
                        gender
                        title
                        consultingCompany {
                            id
                        }
                        consulting_company_name
                        consulting_settings_status
                    }
                    ... on Person {
                        first_name
                        last_name
                        gender
                        personal_pronoun
                    }
                    ... on Company {
                        company_name
                        is_tenant_company
                    }
                }
                consultant {
                    id
                    contact_number
                    first_name
                    last_name
                    gender
                    title
                    consultingCompany {
                        id
                    }
                    consulting_company_name
                    consulting_settings_status
                }
                currentContractInformation {
                    product {
                        name
                        basicProvider {
                            name
                            display_name
                        }
                    }
                    productTemplate {
                        name
                        category {
                            name
                        }
                        subCategory {
                            name
                        }
                        version
                    }
                    start_date
                    end_date
                    premium
                }
                cancellations {
                    id
                    status
                    cancellation_date
                }
                termination_date
                replacedBy {
                    id
                }
            }
            address_overrides
            sender_overrides
            language {
                id
                name
            }
            confidentiality_notice
            attention_ofs
            differentiate_formality
            contents {
                id
                formality
                content {
                    schemaType
                    schemaVersion
                    contentJSON
                    contentHTML
                    attrs
                }
            }
            printPDF {
                id
                name
                size
                mime_type
                created_at
                updated_at
            }
            created_at
            updated_at
        }
    }
`
