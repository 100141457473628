import gql from 'graphql-tag'

export const ACCOUNT_QUERY = gql`
    query account(
        $id: ID!
    ) {
        account(
            id: $id
        ) {
            id
            type
            status
            number
            name
            description
            balance
            has_postings
            is_assigned
            assignedTo {
                ... on Person {
                    id
                }
                ... on Company {
                    id
                }
                ... on CommissionTypeAccountingConfiguration {
                    id
                }
            }
            assigned_as_type
            created_at
            updated_at
        }
    }
`
