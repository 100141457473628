import { apolloClient } from '@/apollo/client'

import { DOCUMENT_TEMPLATE_QUERY } from '@/graphql/documentTemplate/documentTemplate'
import { DOCUMENT_TEMPLATES_QUERY } from '@/graphql/documentTemplate/documentTemplates'
import { DOCUMENT_TEMPLATE_CREATE_MUTATION } from '@/graphql/documentTemplate/documentTemplateCreate'
import { DOCUMENT_TEMPLATE_UPDATE_MUTATION } from '@/graphql/documentTemplate/documentTemplateUpdate'
import { DOCUMENT_TEMPLATE_DELETE_MUTATION } from '@/graphql/documentTemplate/documentTemplateDelete'

export const DocumentTemplateService = {
    get (id) {
        return apolloClient.query({
            query: DOCUMENT_TEMPLATE_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.documentTemplate)
    },
    all (variables) {
        return apolloClient.query({
            query: DOCUMENT_TEMPLATES_QUERY,
            variables,
        }).then(response => response.data.documentTemplates)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: DOCUMENT_TEMPLATE_CREATE_MUTATION,
            variables,
        }).then(response => response.data.documentTemplateCreate)
    },
    update (id, documentTemplateVariables) {
        return apolloClient.mutate({
            mutation: DOCUMENT_TEMPLATE_UPDATE_MUTATION,
            variables: {
                id: id,
                documentTemplate: documentTemplateVariables,
            },
        }).then(response => response.data.documentTemplateUpdate)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: DOCUMENT_TEMPLATE_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.documentTemplateDelete)
    },
}
