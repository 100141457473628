import gql from 'graphql-tag'

export function buildCommissionDistributionRuleSetsQuery (fields) {
    return gql`
        query commissionDistributionRuleSets(
            $page: Int = 1
            $count: Int = 1000
            $orderField: CommissionDistributionRuleSetOrderField = NAME
            $orderDirection: OrderDirection = ASC
            $filterQuery: String
            $filterIsAssigned: Boolean
        ) {
            commissionDistributionRuleSets(
                page: $page
                count: $count
                order: [{ field: $orderField, direction: $orderDirection }]
                search: {
                    query: $filterQuery
                    is_assigned: $filterIsAssigned
                }
            ) {
                paginatorInfo {
                    count
                    firstItem
                    lastItem
                    total
                    currentPage
                    lastPage
                    perPage
                    hasMorePages
                }
                data {
                    ${fields}
                }
            }
        }
    `
}

const defaultQueryFields = `
    id
    name
    description
    rules {
        id
        set {
            id
            name
            description
        }
        commission_type
        ranges {
            id
            rule {
                id
            }
            amount_from
            amount_to
            payout_ratio
            cancellation_reserve
            created_at
            updated_at
        }
        created_at
        updated_at
    }
    is_assigned
    created_at
    updated_at
`

export const COMMISSION_DISTRIBUTION_RULE_SETS_QUERY = buildCommissionDistributionRuleSetsQuery(defaultQueryFields)
