export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'name',
            label: 'common.correspondence.correspondence-item.name',
            infoText: 'common.correspondence.correspondence-item.name--description',
            inputType: 'text',
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'languageId',
            label: 'common.correspondence.correspondence-item.language',
            infoText: 'common.correspondence.correspondence-item.language--description',
            inputType: 'correspondenceLanguage',
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'differentiateFormality',
            label: 'common.correspondence.correspondence-item.differentiate-formality',
            inputType: 'checkbox',
        },
        {
            type: 'field',
            key: 'confidentialityNotice',
            label: 'common.correspondence.confidentiality-notice.confidentiality-notice',
            infoText: 'common.correspondence.confidentiality-notice.confidentiality-notice--info',
            inputType: 'select',
            extra_attributes: {
                list: {
                    options: [
                        { id: null, label: 'common.correspondence.confidentiality-notice.none' },
                        { id: 'PERSONAL', label: 'common.correspondence.confidentiality-notice.personal' },
                        { id: 'CONFIDENTIAL', label: 'common.correspondence.confidentiality-notice.confidential' },
                    ],
                },
                optionValue: 'id',
            },
        },
    ],
}
