import { apolloClient } from '@/apollo/client'

import { buildConsultingFilesQuery, CONSULTING_FILES_QUERY } from '@/graphql/consultingFile/consultingFiles'

import { CONTACT_FILE_TYPES_QUERY } from '@/graphql/consultingFile/contactFileTypes'
import { CONTACT_FILE_CREATE_MUTATION } from '@/graphql/consultingFile/contactFileCreate'
import { CONTACT_FILE_UPDATE_MUTATION } from '@/graphql/consultingFile/contactFileUpdate'
import { CONTACT_FILE_DELETE_MUTATION } from '@/graphql/consultingFile/contactFileDelete'

import { APPLICATION_FILE_TYPES_QUERY } from '@/graphql/consultingFile/applicationFileTypes'
import { APPLICATION_FILE_CREATE_MUTATION } from '@/graphql/consultingFile/applicationFileCreate'
import { APPLICATION_FILE_UPDATE_MUTATION } from '@/graphql/consultingFile/applicationFileUpdate'
import { APPLICATION_FILE_DELETE_MUTATION } from '@/graphql/consultingFile/applicationFileDelete'

import { CONTRACT_FILE_TYPES_QUERY } from '@/graphql/consultingFile/contractFileTypes'
import { CONTRACT_FILE_CREATE_MUTATION } from '@/graphql/consultingFile/contractFileCreate'
import { CONTRACT_FILE_UPDATE_MUTATION } from '@/graphql/consultingFile/contractFileUpdate'
import { CONTRACT_FILE_DELETE_MUTATION } from '@/graphql/consultingFile/contractFileDelete'

export const ConsultingFileService = {
    all (variables, fields) {
        const QUERY = (typeof fields !== 'undefined') ? buildConsultingFilesQuery(fields) : CONSULTING_FILES_QUERY
        return apolloClient.query({
            query: QUERY,
            variables,
        }).then(response => response.data.consultingFiles)
    },

    // ContactFile
    createContactFile (contactId, typeId, consultantId, file, abortController) {
        return apolloClient.mutate({
            mutation: CONTACT_FILE_CREATE_MUTATION,
            variables: {
                contactId: contactId,
                typeId: typeId,
                consultantId: consultantId,
                file: file,
            },
            context: {
                fetchOptions: {
                    signal: abortController.signal,
                },
            },
        }).then(response => response.data.contactFileCreate)
    },
    updateContactFile (variables) {
        return apolloClient.mutate({
            mutation: CONTACT_FILE_UPDATE_MUTATION,
            variables,
        }).then(response => response.data.contactFileUpdate)
    },
    deleteContactFile (id) {
        return apolloClient.mutate({
            mutation: CONTACT_FILE_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.contactFileDelete)
    },
    getContactFileTypes (status) {
        return apolloClient.query({
            query: CONTACT_FILE_TYPES_QUERY,
            variables: {
                status: status,
            },
        }).then(response => response.data.contactFileTypes)
    },

    // ApplicationFile
    createApplicationFile (applicationId, typeId, file, abortController) {
        return apolloClient.mutate({
            mutation: APPLICATION_FILE_CREATE_MUTATION,
            variables: {
                applicationId,
                typeId,
                file,
            },
            context: {
                fetchOptions: {
                    signal: abortController.signal,
                },
            },
        }).then(response => response.data.applicationFileCreate)
    },
    updateApplicationFile (variables) {
        return apolloClient.mutate({
            mutation: APPLICATION_FILE_UPDATE_MUTATION,
            variables,
        }).then(response => response.data.applicationFileUpdate)
    },
    deleteApplicationFile (id) {
        return apolloClient.mutate({
            mutation: APPLICATION_FILE_DELETE_MUTATION,
            variables: {
                id,
            },
        }).then(response => response.data.applicationFileDelete)
    },
    getApplicationFileTypes (status) {
        return apolloClient.query({
            query: APPLICATION_FILE_TYPES_QUERY,
            variables: {
                status,
            },
        }).then(response => response.data.applicationFileTypes)
    },

    // ContractFile
    createContractFile (contractId, typeId, file, abortController) {
        return apolloClient.mutate({
            mutation: CONTRACT_FILE_CREATE_MUTATION,
            variables: {
                contractId: contractId,
                typeId: typeId,
                file: file,
            },
            context: {
                fetchOptions: {
                    signal: abortController.signal,
                },
            },
        }).then(response => response.data.contractFileCreate)
    },
    updateContractFile (variables) {
        return apolloClient.mutate({
            mutation: CONTRACT_FILE_UPDATE_MUTATION,
            variables,
        }).then(response => response.data.contractFileUpdate)
    },
    deleteContractFile (id) {
        return apolloClient.mutate({
            mutation: CONTRACT_FILE_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.contractFileDelete)
    },
    getContractFileTypes (status) {
        return apolloClient.query({
            query: CONTRACT_FILE_TYPES_QUERY,
            variables: {
                status: status,
            },
        }).then(response => response.data.contractFileTypes)
    },
}
