<template>
    <q-item
        :to="linkSearchResultComponents && !disabled ? { name: 'contract-detail', params: { contactId: item.model.customer.id, id: item.model.id } } : null"
        class="search-result-contract"
        :class="{ 'selected': item.selected }"
        dense
        exact
        clickable
        data-test="wrapper:global-search-result-contract"
        @click="!linkSearchResultComponents && !disabled ? $emit('open-search-result', item) : null"
    >
        <q-item-section side top class="contract-icon">
            <q-icon size="1.2em" name="mib-common-file-text" :class="`contract-status-icon-${kebabCase(item.model.status.toLowerCase())}`" />
        </q-item-section>

        <q-item-section top>
            <q-item-label class="contract-info-wrapper">
                <div class="contract-base-info">
                    <b>{{ item.model.currentContractNumber }}</b>,
                    <span class="no-highlighting">
                        {{ item.model.currentContractInformation.product.name }},
                        {{ item.model.currentContractInformation.product.providerName }}
                    </span>
                </div>
                <div class="contract-additional-info">
                    <license-plate
                        v-if="item.model.currentContractInformation.productFields.licencePlate && item.model.currentContractInformation.productFields.licencePlate.value"
                        :license-plate="item.model.currentContractInformation.productFields.licencePlate.value"
                    />

                    <span
                        v-if="(item.model.currentContractInformation.productFields.vehicleMark && item.model.currentContractInformation.productFields.vehicleMark.value) || (item.model.currentContractInformation.productFields.vehicleModel && item.model.currentContractInformation.productFields.vehicleModel.value)"
                        class="car"
                    >
                        <q-icon name="mib-car" size="1em" color="grey" />
                        <span v-if="item.model.currentContractInformation.productFields.vehicleMark && item.model.currentContractInformation.productFields.vehicleMark.value">
                            {{ item.model.currentContractInformation.productFields.vehicleMark.value }}
                        </span>
                        <span v-if="item.model.currentContractInformation.productFields.vehicleModel && item.model.currentContractInformation.productFields.vehicleModel.value">
                            {{ item.model.currentContractInformation.productFields.vehicleModel.value }}
                        </span>
                    </span>
                </div>
            </q-item-label>
        </q-item-section>

        <q-item-section side top class="no-highlighting entry-status-section">
            <contract-status-badge :contract="item.model" />

            <info-icon
                size="1em"
                tooltip-max-width="50ch"
                class="q-ml-xs"
                icon-position-y-offset="-0.08em"
            >
                <p class="q-mb-none text-bold">{{ item.model.currentContractNumber }}</p>

                <q-separator class="q-mt-xs q-mb-sm" color="text-secondary" />

                <dl>
                    <div class="q-mb-sm">
                        <dt>{{ $tc('common.product-provider.product-provider', 1) }}: </dt>
                        <dd>{{ item.model.currentContractInformation.product.providerName }}</dd>

                        <br>
                        <dt>{{ $tc('common.term.status', 1) }}: </dt>
                        <dd>{{ $tc(`common.status.${kebabCase(statusText)}`, 1) }} <template v-if="item.model.currentContractInformation.productFields.suspended && item.model.currentContractInformation.productFields.suspended.value">({{ $tc('common.status.suspended', 1) }})</template></dd>
                    </div>

                    <div v-if="item.model.start_date">
                        <dt>{{ $tc('common.contract.contract-start', 1) }}: </dt>
                        <dd>{{ item.model.currentContractInformation.formattedStartDate }}</dd>
                    </div>

                    <div v-if="item.model.end_date">
                        <dt>{{ $tc('common.contract.contract-end', 1) }}: </dt>
                        <dd>{{ item.model.currentContractInformation.formattedEndDate }}</dd>
                    </div>

                    <div v-if="item.model.termination_date">
                        <dt>{{ $tc('common.contract.termination-date', 1) }}: </dt>
                        <dd>{{ item.model.formattedTerminationDate }}</dd>
                    </div>

                    <div v-if="item.model.premium">
                        <dt>{{ $tc('common.contract.premium', 1) }}: </dt>
                        <dd>{{ item.model.currentContractInformation.formattedPremium }}</dd>
                    </div>

                    <q-separator class="q-mt-xs q-mb-sm" color="text-secondary" />

                    <div class="q-mt-sm">
                        <dt>{{ $tc('common.contact.consultant', 1) }}: </dt>
                        <dd>{{ item.model.consultant.getContactName({ consultingCompanyName: !item.model.consultant.worksForUserConsultingCompany }) }}</dd>
                    </div>
                </dl>
            </info-icon>
        </q-item-section>
    </q-item>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import StatusBadge from '@/components/StatusBadge'
import ContractStatusBadge from '@/components/contract/ContractStatusBadge'

import LicensePlate from '@/components/contract/LicensePlate'
import { ContractStatus } from '@/enums/graphql'

export default {
    name: 'GlobalSearchResultContract',
    components: {
        StatusBadge,
        ContractStatusBadge,
        LicensePlate,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        linkSearchResultComponents: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            ContractStatus,
        }
    },
    computed: {
        statusText () {
            if (this.item.model.status === ContractStatus.TERMINATED) {
                return this.item.model.status
            }
            if (this.item.model.currentCancellation) {
                return `cancellation-${this.item.model.currentCancellation.status}`
            }
            return this.item.model.status
        },
    },
    methods: {
        kebabCase: function (text) {
            return kebabCase(text)
        },
    },
}
</script>

<style lang="scss" scoped>
.car {
    margin-left: $sizeSpacingXs;
}
</style>
