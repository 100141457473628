<template>
    <q-dialog
        ref="dialog"
        v-bind="$attrs"
        @show="onShow"
        @hide="onHide"
    >
        <q-card class="overlay-size-md">
            <q-card-section>
                <slot name="header"><h1>{{ $tc(`views.area-of-responsibility.list.${currentMode.toLowerCase()}-area-of-responsibility`, 1) }} <info-icon :text="$t('views.area-of-responsibility.list.responsible-contact--info-text')" size="xs" /></h1></slot>

                <area-of-responsibility-form
                    ref="areaOfResponsibilityForm"
                    :contact="contact"
                    :area-of-responsibility="areaOfResponsibility"
                    @submit="handleSubmit"
                    @cancel="close"
                />
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script>
import { parseFormDataToInput } from '@/helpers/areaOfResponsibility'
import { AreaOfResponsibility } from '@/models/areaOfResponsibility'
import { ViewMode } from '@/enums'

import AreaOfResponsibilityForm from '@/components/areaOfResponsibility/AreaOfResponsibilityForm.vue'

export default {
    name: 'AreaOfResponsibilityOverlay',
    components: {
        AreaOfResponsibilityForm,
    },
    props: {
        contact: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            ViewMode,
            areaOfResponsibility: null,
        }
    },
    computed: {
        currentMode () {
            return this.areaOfResponsibility ? ViewMode.UPDATE : ViewMode.CREATE
        },
    },
    methods: {
        open ({ areaOfResponsibility }) {
            Object.assign(this, { areaOfResponsibility })
            this.$refs.dialog.show()
        },
        close () {
            this.$refs.dialog.hide()
        },
        onShow () {
            this.$emit('show')
        },
        onHide () {
            this.$emit('hide')
        },
        handleSubmit (formData) {
            const areaOfResponsibilityInput = parseFormDataToInput(formData)

            switch (this.currentMode) {
                case ViewMode.CREATE:
                    this.handleCreate({ formData, areaOfResponsibilityInput})
                    break
                case ViewMode.UPDATE:
                    this.handleUpdate(areaOfResponsibilityInput)
                    break
            }
        },
        handleCreate ({ formData, areaOfResponsibilityInput }) {
            const variables = {
                companyId: this.contact.id,
                isAnonymous: formData.isAnonymous,
                areaOfResponsibilityInput,
            }

            if (!formData.isAnonymous) {
                variables.contactRelationshipId = formData.contactRelationshipId
            }

            AreaOfResponsibility.create(variables).then(() => {
                this.close()

                this.$q.notify({
                    type: 'positive',
                    message: this.$tc('common.notifications.area-of-responsibility.area-of-responsibility-created-success', 1),
                })

                this.$emit('refetch:objects')
            }).catch(error => {
                this.$refs.areaOfResponsibilityForm.formComponent.formBuilder.showFormErrorMessage(error)
                this.$refs.areaOfResponsibilityForm.formComponent.formBuilder.resetFormSubmitStatus()
            })
        },
        handleUpdate (areaOfResponsibilityInput) {
            this.areaOfResponsibility.update(areaOfResponsibilityInput).then(() => {
                this.close()

                this.$q.notify({
                    type: 'positive',
                    message: this.$tc('common.notifications.area-of-responsibility.area-of-responsibility-updated-success', 1),
                })
            }).catch(error => {
                this.$refs.areaOfResponsibilityForm.formComponent.formBuilder.showFormErrorMessage(error)
                this.$refs.areaOfResponsibilityForm.formComponent.formBuilder.resetFormSubmitStatus()
            })
        },
    },
}
</script>
