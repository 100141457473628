<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.product.product-template', 2)" :to="{ name: 'admin-product-template-list' }" />
            </template>

            <h1 class="q-mb-none">{{ $tc('common.product.product-template', 2) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-12">
                <q-table
                    :data="productTemplates"
                    :columns="columns"
                    row-key="id"
                    :pagination.sync="pagination"
                    :loading="loading"
                    :rows-per-page-options="[10, 20, 50, 100]"
                    binary-state-sort
                    flat
                    square
                    dense
                    @request="onRequest"
                    @row-dblclick="(evt, row) => $router.push({ name: 'admin-product-template-update', params: { id: row.id } })"
                >
                    <!-- TODO improvement: Add filter for categories and subcategories -->
                    <template v-slot:top>
                        <q-tabs
                            dense
                            no-caps
                            inline-label
                            align="left"
                            class="q-mr-sm bg-background-secondary"
                            indicator-color="accent"
                        >
                            <q-route-tab :to="{ name: 'admin-product-template-list' }" :label="$t('common.status.active')" />
                            <q-route-tab :to="{ name: 'admin-product-template-list', params: { tab: 'archived' } }" :label="$t('common.status.archived')" />
                        </q-tabs>

                        <q-space />

                        <filter-input v-model="filter" @clear-filter="clearFilter" />
                    </template>

                    <template v-slot:body-cell-product_template_name="props">
                        <q-td :props="props">
                            <b>{{ props.row.name }}</b>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-status="props">
                        <q-td :props="props" auto-width>
                            <status-badge :status="props.row.status" />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-actions="props">
                        <q-td :props="props" auto-width>
                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-pencil"
                                :to="{ name: 'admin-product-template-update', params: { id: props.row.id } }"
                            >
                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.admin.products.product-templates.edit-product-template', 1) }}</q-tooltip>
                            </q-btn>
                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-common-file-double-1"
                                :to="{ name: 'admin-product-template-create', query: { prefill: props.row.id } }"
                            >
                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $t('views.admin.products.product-templates.duplicate-product-template') }}</q-tooltip>
                            </q-btn>
                        </q-td>
                    </template>
                </q-table>
            </div>
        </div>

        <content-store-dialog ref="contentStoreDialog" content-type="productTemplate" @import-finished="fetchObjects" />

        <in-page-footer>
            <base-button
                :to="{ name: 'admin-product-template-create' }"
                :label="$tc('views.admin.products.product-templates.add-product-template', 1)"
                icon="mib-shipment-sync"
                primary-button
            />
            <base-button
                :label="$t('common.content-store.import-from-content-store')"
                icon="mib-folder-file"
                primary-button
                outline
                @click="$refs.contentStoreDialog.show()"
                @keyup.enter="$refs.contentStoreDialog.show()"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import { UserSettings } from '@/helpers/user'
import { OrderDirection } from '@/enums/graphql'
import { ProductTemplate } from '@/models/productTemplate'
import ContentStoreDialog from '@/components/content-store/ContentStoreDialog'

import StatusBadge from '@/components/StatusBadge.vue'
import FilterInput from '@/components/FilterInput'

export default {
    name: 'ProductTemplateList',
    meta () {
        return {
            title: this.$tc('common.product.product-template', 2),
        }
    },
    components: {
        ContentStoreDialog,
        StatusBadge,
        FilterInput,
    },
    data () {
        const userSettings = new UserSettings(this.$store.state.user)
        return {
            userSettings,
            activeTab: this.tab || 'active',
            filter: '',
            loading: false,
            pagination: {
                sortBy: 'product_template_name',
                descending: false,
                page: 1,
                rowsPerPage: parseInt(userSettings.getItem('ProductTemplateList.pagination.rowsPerPage')) || 50,
                rowsNumber: 0,
            },
            columns: [
                { name: 'product_template_name', label: this.$tc('common.term.name'), sortable: true, align: 'left' },
                { name: 'product_template_category_name', label: this.$tc('common.term.category', 1), field: row => row.category.name, sortable: true, align: 'left' },
                { name: 'product_template_sub_category_name', label: this.$tc('common.term.subcategory', 1), field: row => row.subCategory.name, sortable: true, align: 'left' },
                { name: 'product_template_version', label: this.$tc('common.term.version', 1), field: row => row.version, align: 'left' },
                { name: 'status', label: this.$tc('common.term.status'), align: 'left' },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left' },
            ],
            productTemplates: [],
        }
    },
    watch: {
        'filter' () {
            this.fetchObjects()
        },
        '$route' (to) {
            this.activeTab = to.params.tab || 'active'
            this.fetchObjects()
        },
        'pagination.rowsPerPage' (rowsPerPage) {
            this.userSettings.setItem('ProductTemplateList.pagination.rowsPerPage', rowsPerPage)
        },
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
        this.fetchObjects()
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        clearFilter () {
            this.filter = ''
        },
        onRequest (props) {
            let { page, rowsPerPage, sortBy, descending } = props.pagination

            this.pagination.page = page
            this.pagination.rowsPerPage = rowsPerPage
            this.pagination.sortBy = sortBy
            this.pagination.descending = descending

            this.fetchObjects()
        },
        fetchObjects () {
            this.loading = true
            ProductTemplate.objects.all({
                page: this.pagination.page,
                count: this.pagination.rowsPerPage,
                orderField: this.pagination.sortBy.toUpperCase(),
                orderDirection: (this.pagination.descending) ? OrderDirection.DESC : OrderDirection.ASC,
                filterQuery: this.filter,
                filterStatus: [this.activeTab.toUpperCase()],
            }).then(response => {
                this.pagination.rowsNumber = response.paginatorInfo.total
                this.loading = false
                this.productTemplates = response.data
            })
        },
        onNewEntity () {
            this.$router.push({ name: 'admin-product-template-create' })
        },
    },
}
</script>
