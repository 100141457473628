<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('views.dev.components.components')" :to="{ name: 'dev-components' }" />
                <q-breadcrumbs-el :label="$tc('views.dev.components.markup-table.markup-table', 1)" :to="{ name: 'dev-component-markup-table' }" />
            </template>

            <h1>{{ $tc('views.dev.components.markup-table.markup-table', 1) }}</h1>
        </page-header>

        <!-- Workaround / hacky way to be able to easily show the same table in different wrappers/components/context without having to duplicate the table code. -->
        <!-- PLEASE NOTE: This results in duplicated element IDs – which invalidates any a11y ARIA labels/labelledby. -->
        <div class="row q-col-gutter-md">
            <template v-for="i in 2">
                <component
                    :is="(i === 1) ? 'div' : 'grid-card'"
                    :key="i"
                    :class="(i === 1) ? 'col-xs-12' : null"
                    :columns="(i === 2) ? { xs: 12 } : null"
                >
                    <markup-table
                        :columns="columns"
                        :data="data"
                        dense
                    >
                        <template v-slot:body-row="slotProps">
                            <template v-if="slotProps.row.editMode">
                                <markup-table-row>
                                    <markup-table-cell
                                        :col-span="columns.length"
                                        :aria-label="slotProps.row.editMode.label"
                                    >{{ slotProps.row.model.id }}</markup-table-cell>
                                </markup-table-row>
                            </template>
                            <markup-table-row v-else :columns="columns" :row="slotProps.row">
                                <template v-slot:body-cell-actions="slotProps">
                                    <div>Actions for row with ID: {{ slotProps.row.model.id }}</div>
                                </template>
                            </markup-table-row>
                        </template>
                    </markup-table>
                </component>
            </template>
        </div>
    </page-wrapper>
</template>

<script>
import MarkupTable from '@/components/MarkupTable.vue'
import MarkupTableRow from '@/components/MarkupTableRow.vue'
import MarkupTableCell from '@/components/MarkupTableCell.vue'

export default {
    name: 'DevComponentMarkupTables',
    meta () {
        return {
            title: this.$tc('views.dev.components.markup-table.markup-table', 1),
        }
    },
    components: {
        MarkupTable,
        MarkupTableRow,
        MarkupTableCell,
    },
    data () {
        return {
            columns: [
                // {
                //     name: 'editMode',
                //     label: this.$t('common.term.edit-mode'),
                //     align: 'left',
                // },
                {
                    name: 'additional_recipient',
                    label: this.$tc('views.contract.settings.commission.commission-splitting.additional-recipient', 1),
                    align: 'left',
                    field: row => `${row.model.additionalRecipient.first_name} ${row.model.additionalRecipient.last_name}`,
                },
                {
                    name: 'rate',
                    label: this.$tc('views.contract.settings.commission.commission-splitting.split-rate', 1),
                    align: 'left',
                    field: row => row.model.split_rate,
                },
                {
                    name: 'active_from',
                    label: this.$t('views.contract.settings.commission.commission-splitting.active-from'),
                    align: 'left',
                    field: row => row.model.active_from,
                },
                {
                    name: 'active_until',
                    label: this.$t('views.contract.settings.commission.commission-splitting.active-until'),
                    align: 'left',
                    field: row => row.model.active_until,
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left', autoWidth: true },
            ],
            data: [
                {
                    "id": 1,
                    "editMode": false,
                    "model": {
                        "statusTransitions": {},
                        "statusActionMapping": {},
                        "id": "103",
                        "additionalRecipient": {
                            "statusTransitions": {},
                            "statusActionMapping": {},
                            "id": "17",
                            "first_name": "Tobias",
                            "last_name": "Fuhlroth",
                            "__typename": "Consultant",
                        },
                        "split_rate": '80 %',
                        "active_from": null,
                        "active_until": null,
                        "is_active": true,
                        "__typename": "CommissionSplit",
                    },
                },
                {
                    "id": 2,
                    "editMode": false,
                    "model": {
                        "statusTransitions": {},
                        "statusActionMapping": {},
                        "id": "104",
                        "additionalRecipient": {
                            "statusTransitions": {},
                            "statusActionMapping": {},
                            "id": "35",
                            "first_name": "Matthias",
                            "last_name": "Trummer",
                            "__typename": "Consultant",
                        },
                        "split_rate": '20 %',
                        "active_from": null,
                        "active_until": "2022-01-31T22:59:59+00:00",
                        "is_active": false,
                        "__typename": "CommissionSplit",
                    },
                },
                {
                    "id": 3,
                    "editMode": true,
                    "model": {
                        "statusTransitions": {},
                        "statusActionMapping": {},
                        "id": "105",
                        "additionalRecipient": {
                            "statusTransitions": {},
                            "statusActionMapping": {},
                            "id": "313",
                            "first_name": "Anna",
                            "last_name": "Tester",
                            "__typename": "Consultant",
                        },
                        "split_rate": '20 %',
                        "active_from": "2022-02-01T22:59:59+00:00",
                        "active_until": null,
                        "is_active": true,
                        "__typename": "CommissionSplit",
                    },
                },
            ],
        }
    },
    methods: {},
}
</script>
