import gql from 'graphql-tag'

export const MAIL_PROCESSING_TEMPLATES_QUERY = gql`
    query mailProcessingTemplates(
        $page: Int = 1
        $count: Int = 200
        $orderField: MailProcessingTemplateOrderField = NAME
        $orderDirection: OrderDirection = ASC
        $filterQuery: String
        $filterTargetObjectTypes: [ConsultingFileTargetObjectType!]
        $filterBillable: Boolean
    ) {
        mailProcessingTemplates(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                query: $filterQuery
                target_object_types: $filterTargetObjectTypes
                billable: $filterBillable
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                name
                target_object_type
                billable
                created_at
                updated_at
            }
        }
    }
`
