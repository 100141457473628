<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('views.dev.design.design')" :to="{ name: 'dev-design' }" />
                <q-breadcrumbs-el :label="$t('views.dev.design.typography.title')" :to="{ name: 'dev-design-typography' }" />
            </template>

            <h1 class="q-mb-none">
                {{ $t('views.dev.design.typography.title') }}
                <mark class="subtitle">This document is still work in progress.</mark>
            </h1>
        </page-header>

        <section class="row q-mt-md q-col-gutter-x-md">
            <h2>Headings</h2>
            <div class="col-xs-12">
                <h1>Heading 1 with <small>small text</small> and a <a href="#">link</a></h1>
                <h2>Heading 2 with <small>small text</small> and a <a href="#">link</a></h2>
                <h3>Heading 3 with <small>small text</small> and a <a href="#">link</a></h3>
                <h4>Heading 4 with <small>small text</small> and a <a href="#">link</a></h4>
                <h5>Heading 5 with <small>small text</small> and a <a href="#">link</a></h5>
                <h6>Heading 6 with <small>small text</small> and a <a href="#">link</a></h6>
            </div>
        </section>

        <section class="row q-mt-md q-col-gutter-x-md">
            <h2>Paragraph/Text</h2>
            <div class="col-xs-12">
                <p>Lorem ipsum dolor sit amet, <a href="#">consectetur</a> adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </div>
        </section>

        <section class="row q-mt-md q-col-gutter-x-md">
            <h2>Figure</h2>
            <div class="col-xs-12">
                <figure>
                    <img :src="require('@/assets/img/images/placeholder-image-5040-small.jpg')" alt="Image Alt Text">
                    <figcaption>Figcaption content</figcaption>
                </figure>
                <figure>
                    <img :src="require('@/assets/img/images/placeholder-image-5040-small.jpg')" alt="Image Alt Text">
                    <figcaption>
                        <h4>Figure Title</h4>
                        Figcaption content
                    </figcaption>
                </figure>
            </div>
        </section>

        <section class="row q-mt-md q-col-gutter-x-md">
            <h2>Blockquote</h2>
            <div class="col-xs-12">
                <blockquote>
                    <p>Some sort of famous witty quote marked up with a &lt;blockquote> and a child &lt;p> element.</p>
                </blockquote>
                <blockquote>Even better philosophical quote marked up with just a &lt;blockquote> element.</blockquote>
            </div>
        </section>

        <section class="row q-mt-md q-col-gutter-x-md">
            <h2>Address</h2>
            <div class="col-xs-12">
                <address>
                    Company Name, Inc.<br>
                    811 7th Ave, Suite 700<br>
                    Manhattan, NY 10019<br>
                    USA
                </address>
            </div>
        </section>

        <section class="row q-mt-md q-col-gutter-x-md">
            <h2>Lists</h2>
            <div class="col-xs-12">
                <div class="sg_subsection">
                    <h3>Definition List</h3>
                    <dl>
                        <dt>Description name</dt>
                        <dd>Description value</dd>
                        <dt>Description name</dt>
                        <dd>Description value</dd>
                        <dd>Description value</dd>
                        <dt>Description name</dt>
                        <dt>Description name</dt>
                        <dd>Description value</dd>
                    </dl>
                </div>

                <div class="sg_subsection">
                    <h3>Ordered List</h3>
                    <ol>
                        <li>list item 1</li>
                        <li>
                            list item 1
                            <ol>
                                <li>list item 2</li>
                                <li>
                                    list item 2
                                    <ol>
                                        <li>list item 3</li>
                                        <li>list item 3</li>
                                    </ol>
                                </li>
                                <li>list item 2</li>
                                <li>list item 2</li>
                            </ol>
                        </li>
                        <li>list item 1</li>
                        <li>list item 1</li>
                    </ol>
                </div>

                <div class="sg_subsection">
                    <h3>Unordered List</h3>
                    <ul>
                        <li>list item 1</li>
                        <li>
                            list item 1
                            <ul>
                                <li>list item 2</li>
                                <li>
                                    list item 2
                                    <ul>
                                        <li>list item 3</li>
                                        <li>list item 3</li>
                                    </ul>
                                </li>
                                <li>list item 2</li>
                                <li>list item 2</li>
                            </ul>
                        </li>
                        <li>list item 1</li>
                        <li>list item 1</li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="row q-mt-md q-col-gutter-x-md">
            <h2>Preformatted text</h2>
            <div class="col-xs-12">
                <div class="sg_subsection">
                    <h3>Pre</h3>
                    <pre>
P R E F O R M A T T E D T E X T
! " # $ % &amp; ' ( ) * + , - . /
0 1 2 3 4 5 6 7 8 9 : ; &lt; = &gt; ?
@ A B C D E F G H I J K L M N O
P Q R S T U V W X Y Z [ \ ] ^ _
` a b c d e f g h i j k l m n o
p q r s t u v w x y z { | } ~
</pre>
                </div>

                <div class="sg_subsection">
                    <h3>Code</h3>
                    <code>&lt;html>
                        &lt;head>
                        &lt;/head>
                        &lt;body>
                        &lt;div class="main"> &lt;div>
                        &lt;/body>
                        &lt;/html>
                    </code>
                </div>
            </div>
        </section>

        <section class="row q-mt-md q-col-gutter-x-md">
            <h2>Text Elements</h2>
            <div class="col-xs-12">
                <p>There are a number of inline <abbr title="HyperText Markup Language">HTML</abbr> elements you may use anywhere within other elements.</p>

                <div class="sg_subsection">
                    <h3>Links and anchors</h3>
                    <p>The <code>a</code> element is used to hyperlink text, be that to another page, a named fragment on the current page or any other location on the web. Example:</p>
                    <div class="example">
                        <p><a href="../../">Go to the home page</a> or <a href="#banner">return to the top of this page</a>.</p>
                    </div>
                </div>

                <div class="sg_subsection">
                    <h3>Stressed emphasis</h3>
                    <p>The <code>em</code> element is used to denote text with stressed emphasis, i.e., something you&#8217;d pronounce differently. Where italicizing is required for stylistic differentiation, the <code>i</code> element may be preferable. Example:</p>
                    <div class="example">
                        <p>You simply <em>must</em> try the negitoro maki!</p>
                    </div>
                </div>

                <div class="sg_subsection">
                    <h3>Strong importance</h3>
                    <p>The <code>strong</code> element is used to denote text with strong importance. Where bolding is used for stylistic differentiation, the <code>b</code> element may be preferable. Example:</p>
                    <div class="example">
                        <p><strong>Don&#8217;t</strong> stick nails in the electrical outlet.</p>
                    </div>
                </div>

                <div class="sg_subsection">
                    <h3>Small print</h3>
                    <p>The <small>small</small> element is used to represent disclaimers, caveats, legal restrictions, or copyrights (commonly referred to as &#8216;small print&#8217;). It can also be used for attributions or satisfying licensing requirements. Example:</p>
                    <div class="example">
                        <p><small>Copyright &#169; 1922-2011 Acme Corporation. All Rights Reserved.</small></p>
                    </div>
                </div>

                <div class="sg_subsection">
                    <h3>Strikethrough</h3>
                    <p>The <code>s</code> element is used to represent content that is no longer accurate or relevant. When indicating document edits i.e., marking a span of text as having been removed from a document, use the <code>del</code> element instead. Example:</p>
                    <div class="example">
                        <p><s>Recommended retail price: CHF 3.95 per bottle</s><br><strong>Now selling for just CHF 2.95 a bottle!</strong></p>
                    </div>
                </div>

                <div class="sg_subsection">
                    <h3>Citations</h3>
                    <p>The <code>cite</code> element is used to represent the title of a work (e.g. a book, essay, poem, song, film, TV show, sculpture, painting, musical, exhibition, etc). This can be a work that is being quoted or referenced in detail (i.e. a citation), or it can just be a work that is mentioned in passing. Example:</p>
                    <div class="example">
                        <p><cite>Universal Declaration of Human Rights</cite>, United Nations, December 1948. Adopted by General Assembly resolution 217 A (III).</p>
                    </div>
                </div>

                <div class="sg_subsection">
                    <h3>Inline quotes</h3>
                    <p>The <code>q</code> element is used for quoting text inline. Example showing nested quotations:</p>
                    <div class="example">
                        <p>John said, <q>I saw Lucy at lunch, she told me <q>Mary wants you to get some ice cream on your way home</q>. I think I will get some at Ben and Jerry&#8217;s, on Gloucester Road.</q></p>
                    </div>
                </div>

                <div class="sg_subsection">
                    <h3>Definition</h3>
                    <p>The <code>dfn</code> element is used to highlight the first use of a term. The <code>title</code> attribute can be used to describe the term. Example:</p>
                    <div class="example">
                        <p>Bob&#8217;s <dfn title="Dog">canine</dfn> mother and <dfn title="Horse">equine</dfn> father sat him down and carefully explained that he was an <dfn title="A mutation that combines two or more sets of chromosomes from different species">allopolyploid</dfn> organism.</p>
                    </div>
                </div>

                <div class="sg_subsection">
                    <h3>Abbreviation</h3>
                    <p>The <code>abbr</code> element is used for any abbreviated text, whether it be acronym, initialism, or otherwise. Generally, it&#8217;s less work and useful (enough) to mark up only the first occurrence of any particular abbreviation on a page, and ignore the rest. Any text in the <code>title</code> attribute will appear when the user&#8217;s mouse hovers the abbreviation (although notably, this does not work in Internet Explorer for Windows). Example abbreviations:</p>
                    <div class="example">
                        <p><abbr title="British Broadcasting Corportation">BBC</abbr>, <abbr title="HyperText Markup Language">HTML</abbr>, and <abbr title="Staffordshire">Staffs.</abbr></p>
                    </div>
                </div>

                <div class="sg_subsection">
                    <h3>Time</h3>
                    <p>The <code>time</code> element is used to represent either a time on a 24 hour clock, or a precise date in the proleptic Gregorian calendar, optionally with a time and a time-zone offset. Example:</p>
                    <div class="example">
                        <p>Queen Elizabeth II was proclaimed sovereign of each of the Commonwealth realms on <time datetime="1952-02-6">6</time> and <time datetime="1952-02-7">7 February 1952</time>, after the death of her father, King George VI.</p>
                    </div>
                </div>

                <div class="sg_subsection">
                    <h3>Code</h3>
                    <p>The <code>code</code> element is used to represent fragments of computer code. Useful for technology-oriented sites, not so useful otherwise. Example:</p>
                    <div class="example">
                        <p>When you call the <code>activate()</code> method on the <code>robotSnowman</code> object, the eyes glow.</p>
                    </div>
                    <p>Used in conjunction with the <code>pre</code> element:</p>
                    <div class="example">
                        <pre>
<code>function getJelly() {
&#160;&#160;&#160;&#160;echo $aDeliciousSnack;
}</code>
</pre>
                    </div>
                </div>

                <div class="sg_subsection">
                    <h3>Variable</h3>
                    <p>The <code>var</code> element is used to denote a variable in a mathematical expression or programming context, but can also be used to indicate a placeholder where the contents should be replaced with your own value. Example:</p>
                    <div class="example">
                        <p>If there are <var>n</var> pipes leading to the ice cream factory then I expect at <em>least</em> <var>n</var> flavours of ice cream to be available for purchase!</p>
                    </div>
                </div>

                <div class="sg_subsection">
                    <h3>Sample output</h3>
                    <p>The <code>samp</code> element is used to represent (sample) output from a program or computing system. Useful for technology-oriented sites, not so useful otherwise. Example:</p>
                    <div class="example">
                        <p>The computer said <samp>Too much cheese in tray two</samp> but I didn&#8217;t know what that meant.</p>
                    </div>
                </div>

                <div class="sg_subsection">
                    <h3>Keyboard entry</h3>
                    <p>The <code>kbd</code> element is used to denote user input (typically via a keyboard, although it may also be used to represent other input methods, such as voice commands). Example:</p>
                    <div class="example">
                        <p>To take a screenshot on your Mac, press <kbd>&#8984; Cmd</kbd> + <kbd>&#8679; Shift</kbd> + <kbd>3</kbd>.</p>
                    </div>
                </div>

                <div class="sg_subsection">
                    <h3>Superscript and subscript text</h3>
                    <p>The <code>sup</code> element represents a superscript and the sub element represents a <code>sub</code>. These elements must be used only to mark up typographical conventions with specific meanings, not for typographical presentation. As a guide, only use these elements if their absence would change the meaning of the content. Example:</p>
                    <div class="example">
                        <p>The coordinate of the <var>i</var>th point is (<var>x<sub><var>i</var></sub></var>, <var>y<sub><var>i</var></sub></var>). For example, the 10th point has coordinate (<var>x<sub>10</sub></var>, <var>y<sub>10</sub></var>).</p>
                        <p>f(<var>x</var>, <var>n</var>) = log<sub>4</sub><var>x</var><sup><var>n</var></sup></p>
                    </div>
                </div>

                <div class="sg_subsection">
                    <h3>Italicised</h3>
                    <p>The <code>i</code> element is used for text in an alternate voice or mood, or otherwise offset from the normal prose. Examples include taxonomic designations, technical terms, idiomatic phrases from another language, the name of a ship or other spans of text whose typographic presentation is typically italicised. Example:</p>
                    <div class="example">
                        <p>There is a certain <i lang="fr">je ne sais quoi</i> in the air.</p>
                    </div>
                </div>

                <div class="sg_subsection">
                    <h3>Emboldened</h3>
                    <p>The <code>b</code> element is used for text stylistically offset from normal prose without conveying extra importance, such as key words in a document abstract, product names in a review, or other spans of text whose typographic presentation is typically emboldened. Example:</p>
                    <div class="example">
                        <p>You enter a small room. Your <b>sword</b> glows brighter. A <b>rat</b> scurries past the corner wall.</p>
                    </div>
                </div>

                <div class="sg_subsection">
                    <h3>Marked or highlighted text</h3>
                    <p>The <code>mark</code> element is used to represent a run of text marked or highlighted for reference purposes. When used in a quotation it indicates a highlight not originally present but added to bring the reader&#8217;s attention to that part of the text. When used in the main prose of a document, it indicates a part of the document that has been highlighted due to its relevance to the user&#8217;s current activity. Example:</p>
                    <div class="example">
                        <p>I also have some <mark>kitten</mark>s who are visiting me these days. They&#8217;re really cute. I think they like my garden! Maybe I should adopt a <mark>kitten</mark>.</p>
                    </div>
                </div>

                <div class="sg_subsection">
                    <h3 id="edits">Edits</h3>
                    <p>The <code>del</code> element is used to represent deleted or retracted text which still must remain on the page for some reason. Meanwhile its counterpart, the <code>ins</code> element, is used to represent inserted text. Both <code>del</code> and <code>ins</code> have a <code>datetime</code> attribute which allows you to include a timestamp directly in the element. Example inserted text and usage:</p>
                    <div class="example">
                        <p>She bought <del datetime="2005-05-30T13:00:00">two</del> <ins datetime="2005-05-30T13:00:00">five</ins> pairs of shoes.</p>
                    </div>
                </div>
            </div>
        </section>

        <section class="row q-mt-md q-col-gutter-x-md">
            <h2>Tables</h2>
            <div class="col-xs-12">
                <div class="sg_subsection q-mb-md">
                    <h3>Default Table</h3>
                    <table>
                        <caption>Table Caption</caption>
                        <thead>
                            <tr>
                                <th>thead th</th>
                                <th>thead th</th>
                                <th>thead th</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>tbody td</td>
                                <td>tbody td</td>
                                <td>tbody td</td>
                            </tr>
                            <tr>
                                <td>tbody td</td>
                                <td>tbody td</td>
                                <td>tbody td</td>
                            </tr>
                            <tr>
                                <td>tbody td</td>
                                <td>tbody td</td>
                                <td>tbody td</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>tfoot th</th>
                                <th>tfoot th</th>
                                <th>tfoot th</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div class="sg_subsection q-mb-md">
                    <h3>Table with class <code>.q-table</code></h3>
                    <table class="q-table">
                        <caption>Table Caption</caption>
                        <thead>
                            <tr>
                                <th>thead th</th>
                                <th>thead th</th>
                                <th>thead th</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>tbody td</td>
                                <td>tbody td</td>
                                <td>tbody td</td>
                            </tr>
                            <tr>
                                <td>tbody td</td>
                                <td>tbody td</td>
                                <td>tbody td</td>
                            </tr>
                            <tr>
                                <td>tbody td</td>
                                <td>tbody td</td>
                                <td>tbody td</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>tfoot th</th>
                                <th>tfoot th</th>
                                <th>tfoot th</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div class="sg_subsection">
                    <h3>Table with side headings</h3>
                    <table>
                        <caption>Table Caption</caption>
                        <thead>
                            <tr>
                                <th>thead th</th>
                                <th>thead th</th>
                                <th>thead th</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>tbody th</th>
                                <td>tbody td</td>
                                <td>tbody td</td>
                            </tr>
                            <tr>
                                <th>tbody th</th>
                                <td>tbody td</td>
                                <td>tbody td</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>tfoot th</th>
                                <td>tfoot td</td>
                                <td>tfoot td</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </section>

        <section class="row q-mt-md q-col-gutter-x-md">
            <h2>Media</h2>
            <div class="col-xs-12">
                <div class="sg_subsection">
                    <h3>Default Image</h3>
                    <img :src="require('@/assets/img/images/placeholder-image-5040-small.jpg')" alt="Dog">
                </div>

                <div class="sg_subsection">
                    <h3>Linked Image</h3>
                    <a href="#"><img :src="require('@/assets/img/images/placeholder-image-5040-small.jpg')" alt="Dog"></a>
                </div>

                <div class="sg_subsection">
                    <h3>Missing Image</h3>
                    <img alt="This is an example of a missing image">
                </div>

                <div class="sg_subsection">
                    <h3>Svg</h3>
                    <svg width="200px" height="200px">
                        <circle
                            cx="100"
                            cy="100"
                            r="100"
                            fill="#ff0000"
                        />
                    </svg>
                </div>

                <div class="sg_subsection">
                    <h3>Video</h3>
                    <video
                        controls
                        preload="metadata"
                        poster="http://sandbox.thewikies.com/vfe-generator/images/big-buck-bunny_poster.jpg"
                        width="640"
                        height="360"
                        style="max-width: 100%;"
                    >
                        <source id="mp4" src="http://media.w3.org/2010/05/bunny/trailer.mp4" type="video/mp4">
                        <source id="ogv" src="http://media.w3.org/2010/05/bunny/trailer.ogv" type="video/ogg">
                        <p>Your user agent does not support the HTML5 Video element.</p>
                    </video>
                </div>

                <div class="sg_subsection">
                    <h3>Missing Video</h3>
                    <video controls />
                </div>

                <div class="sg_subsection">
                    <h3>Audio</h3>
                    <audio controls preload="metadata">
                        <source src="http://media.w3.org/2010/07/bunny/04-Death_Becomes_Fur.mp4" type="audio/mp4">
                        <source src="http://media.w3.org/2010/07/bunny/04-Death_Becomes_Fur.oga" type="audio/ogg; codecs=vorbis">
                        <p>Your user agent does not support the HTML5 Audio element.</p>
                    </audio>
                </div>

                <div class="sg_subsection">
                    <h3>Missing Audio</h3>
                    <audio controls />
                </div>
            </div>
        </section>
    </page-wrapper>
</template>

<script>
export default {
    name: 'DevDesignTypography',
    meta () {
        return {
            title: 'Typography',
        }
    },
}
</script>
