/**
 * Validate formData range.
 *
 * @param {Object} formData - {id, amountFrom, amountTo, payoutRatio, cancellationReserve}
 * @returns {Boolean}
 */

export function validateFormDataRange(formData) {
    return parseFloat(formData.cancellationReserve) <= parseFloat(formData.payoutRatio)
}

/**
 * Validate if formData amountTo is bigger than given amount to.
 *
 * @param {Object} formData - {id, amountFrom, amountTo, payoutRatio, cancellationReserve}
 * @param {Number} amountFrom
 * @returns {Boolean}
 */
export function validateBiggerFormDataAmountTo(formData, amountFrom) {
    if (!formData.amountTo || amountFrom === null) return true

    return parseFloat(formData.amountTo) > amountFrom
}

/**
 * Validate if formData amountTo is smaller than given amount to.
 *
 * @param {Object} formData - {id, amountFrom, amountTo, payoutRatio, cancellationReserve}
 * @param {Number} amountFrom
 * @returns {Boolean}
 */
export function validateSmallerFormDataAmountTo(formData, nextAmountFrom) {
    return parseFloat(formData.amountTo) < nextAmountFrom
}

/**
 * Parse form data values into rule set input.
 *
 * @param  {string} name
 * @param  {string} description
 * @param  {Array} rules - CommissionDistributionRuleInput[]
 * @return {Object} - CommissionDistributionRuleSetInput
 */
export function parseFormDataToRuleSetInput(name, description, rules) {
    return {
        name: name,
        description: description || null,
        rules: rules.map(rule => ({
            commission_type: rule.commission_type,
            ranges: rule.ranges.map(range => ({
                amount_from: range.amount_from !== '' && range.amount_from !== null ? parseFloat(range.amount_from) : null,
                amount_to: range.amount_to !== '' && range.amount_to !== null ? parseFloat(range.amount_to) : null,
                payout_ratio: parseFloat(range.payout_ratio),
                cancellation_reserve: parseFloat(range.cancellation_reserve),
            })),
        })),
    }
}

/**
 * Parse form data values into range input.
 *
 * @param  {Object} formData - {amountFrom, amountTo, payoutRatio, cancellationReserve}
 * @return {Object} - CommissionDistributionRuleRangeInput
 */
export function parseFormDataToRangeInput(formData) {
    const rangeInput =  {
        amount_from: formData.amountFrom !== '' && formData.amountFrom !== null ? parseFloat(formData.amountFrom) : null,
        amount_to: formData.amountTo !== '' && formData.amountTo !== null ? parseFloat(formData.amountTo) : null,
        payout_ratio: parseFloat(formData.payoutRatio),
        cancellation_reserve: parseFloat(formData.cancellationReserve),
    }
    return rangeInput
}
