<template>
    <form
        data-test="form:commission-recipient-override"
        @submit.prevent="onFormSubmit"
    >
        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="formErrorMessage"
            type="negative"
            data-test="error:global-message"
            v-html="formErrorMessage"
        />
        <!-- eslint-enable -->

        <div class="row q-col-gutter-md">
            <div class="col-xs-12 col-md-6 col-lg-4">
                <form-builder item-key="commissionRecipientOverrideId" />
            </div>

            <div class="col-xs-12 col-md-6 col-lg-8">
                <base-button
                    primary-button
                    data-test="btn:save"
                    type="submit"
                    :label="$t('common.term.save')"
                    class="q-mr-sm"
                    :disable="formSubmitStatus"
                    :loading="formSubmitStatus"
                />

                <base-button
                    primary-button
                    flat
                    data-test="btn:cancel"
                    :label="$t('common.term.cancel')"
                    :disable="formSubmitStatus"
                    @click="$emit('cancel')"
                />
            </div>
        </div>
    </form>
</template>

<script>
import { ViewMode } from '@/enums'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import COMMISSION_RECIPIENT_OVERRIDE_FORM from '@/forms/commissionRecipientOverride.form'

export default {
    name: 'CommissionRecipientOverrideForm',
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: COMMISSION_RECIPIENT_OVERRIDE_FORM,
    },
    props: {
        contract: {
            type: Object,
            required: true,
        },
    },
    data () {
        const data = {
            ViewMode,
            currentMode: this.contract.commissionRecipientOverride ? ViewMode.UPDATE : ViewMode.CREATE,
            formData: {},
        }

        if (data.currentMode === ViewMode.UPDATE) {
            Object.assign(data.formData, {
                commissionRecipientOverrideId: this.contract.commissionRecipientOverride.id,
            })
        }

        return data
    },
}
</script>
