import { i18n } from '@/i18n'
import kebabCase from 'lodash.kebabcase'
import { ActivityLogParser } from '@/parsers/activityLog'

export class CommissionDistributionRuleRangeActivityLogParser extends ActivityLogParser {
    static attributeDataTypes = {
        'amount_from': 'currency',
        'amount_to': 'currency',
        'payout_ratio': 'percentage',
        'cancellation_reserve': 'percentage',
    }

    static getLogDescriptionCreated (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `<b>${data.subject}</b>`,
            toSomething: i18n.tc('common.change-log.subject.to-this.commission-distribution-rule--for-type', 1, { commissionType: `<b>${i18n.t(`common.commission.commission-type.${kebabCase(activityLog.properties.rule_commission_type)}`)}</b>` }),
        }
        return i18n.t(`common.change-log.someone-added-something-to-something`, interpolationParams)
    }

    static getLogDescriptionUpdated (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `<b>${i18n.tc('common.change-log.subject.some.commission-distribution-rule-range', 1)}</b>`,
            ofSomething: i18n.tc('common.change-log.subject.of-this.commission-distribution-rule--for-type', 1, { commissionType: `<b>${i18n.t(`common.commission.commission-type.${kebabCase(activityLog.properties.rule_commission_type)}`)}</b>` }),
        }
        return i18n.t(`common.change-log.someone-updated-something-of-something`, interpolationParams)
    }

    static getLogDescriptionDeleted (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `<b>${i18n.tc('common.change-log.subject.some.commission-distribution-rule-range', 1)}</b>`,
            ofSomething: i18n.tc('common.change-log.subject.of-this.commission-distribution-rule--for-type', 1, { commissionType: `<b>${i18n.t(`common.commission.commission-type.${kebabCase(activityLog.properties.rule_commission_type)}`)}</b>` }),
        }
        return i18n.t(`common.change-log.someone-deleted-something-of-something`, interpolationParams)
    }

    static getLogDetailsCreated (activityLog, data) {
        return [
            {
                key: 'amountFrom',
                label: i18n.tc('forms.field.label.commission-distribution-rule-range.amount-from', 1),
                newValue: this.getFormattedLogDetailValue(activityLog, 'amount_from', this.attributeDataTypes['amount_from'] ?? 'undefined', activityLog.properties.attributes.amount_from) ?? '-∞',
            },
            {
                key: 'amountTo',
                label: i18n.tc('forms.field.label.commission-distribution-rule-range.amount-to', 1),
                newValue: this.getFormattedLogDetailValue(activityLog, 'amount_to', this.attributeDataTypes['amount_to'] ?? 'undefined', activityLog.properties.attributes.amount_to) ?? '-∞',
            },
            {
                key: 'payoutRatio',
                label: i18n.tc('forms.field.label.commission-distribution-rule-range.payout-ratio', 1),
                newValue: this.getFormattedLogDetailValue(activityLog, 'payout_ratio', this.attributeDataTypes['payout_ratio'] ?? 'undefined', activityLog.properties.attributes.payout_ratio),
            },
            {
                key: 'cancellationReserve',
                label: i18n.tc('forms.field.label.commission-distribution-rule-range.cancellation-reserve', 1),
                newValue: this.getFormattedLogDetailValue(activityLog, 'cancellation_reserve', this.attributeDataTypes['cancellation_reserve'] ?? 'undefined', activityLog.properties.attributes.cancellation_reserve),
            },
        ]
    }
}
