<template>
    <form data-test="form:auth-credentials" @submit.prevent="onFormSubmit">
        <div class="col q-gutter-md">
            <p>{{ $t('views.auth.login.text') }}</p>

            <!-- eslint-disable vue/no-v-html -->
            <info-box
                v-if="formErrorMessage"
                type="negative"
                data-test="error:global-message"
                v-html="formErrorMessage"
            />
            <!-- eslint-enable -->

            <form-builder item-key="username" :autofocus="(!formData.username)" />
            <form-builder item-key="password" :autofocus="(!!formData.username)" />
        </div>

        <form-navigation>
            <template v-slot:default>
                <small><router-link :to="forgotPasswordURL" data-test="link:forgot-password">{{ $t('views.auth.login.forgot-password-link') }}</router-link></small>
            </template>
            <template v-slot:col2>
                <base-button
                    data-test="btn:submit"
                    type="submit"
                    :label="$t('common.term.continue')"
                    :disable="formSubmitStatus"
                    :loading="formSubmitStatus"
                />
            </template>
        </form-navigation>
    </form>
</template>

<script>
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import FormNavigation from '@/components/form/FormNavigation'

import AUTH_CREDENTIALS_FIELDS from '@/forms/auth.fields.json'

export default {
    name: 'AuthCredentials',
    components: {
        FormNavigation,
    },
    mixins: [formBuilderMixin],
    props: {
        prefillUsername: {
            type: String,
        },
    },
    formBuilderSettings: {
        schema: AUTH_CREDENTIALS_FIELDS,
        fields: ['username', 'password'],
    },
    data () {
        return {
            formData: {
                username: (this.prefillUsername) ? this.prefillUsername : '',
            },
        }
    },
    computed: {
        forgotPasswordURL () {
            const link = { name: 'auth-forgot-password' }
            if (this.formData.username) {
                link.query = { username: this.formData.username }
            }
            return link
        },
    },
}
</script>
