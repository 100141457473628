import { apolloClient } from '@/apollo/client'

import { FILE_STORAGE_ITEM_SET_ACKNOWLEDGE_AT_MUTATION } from '@/graphql/fileStorageItem/fileStorageItemSetAcknowledgedAt'

export const FileStorageItemService = {
    setAcknowledgeAt (ids) {
        return apolloClient.mutate({
            mutation: FILE_STORAGE_ITEM_SET_ACKNOWLEDGE_AT_MUTATION,
            variables: {
                ids: ids,
            },
        }).then(response => response.data.fileStorageItemSetAcknowledgedAt)
    },
}
