<template>
    <div class="smart-object-tree-inner-wrapper">
        <header class="smart-object-tree-header">
            <h2 v-if="showHeading" class="text-h4">
                {{ $tc('common.correspondence.smart-object.smart-object', 2) }}
                <info-icon :text="$t('common.correspondence.smart-object.smart-object--description')" />
            </h2>
            <base-input
                ref="filter"
                v-model="filterQuery"
                :label="$tc('common.term.filter', 1)"
                class="q-mb-sm"
            >
                <template v-slot:append>
                    <q-icon v-if="filterQuery !== ''" name="mib-close" @click="resetFilter" />
                </template>
            </base-input>
        </header>

        <q-scroll-area
            class="smart-object-tree-content"
            :thumb-style="{ width: '5px', right: '3px', borderRadius: '5px', backgroundColor: 'var(--color-border-tertiary)', opacity: 1 }"
            :bar-style="{ right: '1px', borderLeft: '1px solid var(--color-background-primary)', backgroundColor: 'var(--color-background-secondary)', width: '10px', opacity: 1 }"
            visible
        >
            <q-tree
                ref="smartObjectTree"
                :nodes="smartObjectTreeNodes"
                node-key="id"
                :filter="filterQuery"
                :filter-method="filter"
                :expanded.sync="expandedKeys"
            >
                <template v-slot:default-header="slotProps">
                    <template v-if="slotProps.node.selectable !== false">
                        <span
                            draggable="true"
                            :data-smart-object="slotProps.node.type"
                            :data-id="slotProps.node.id"
                            class="smart-object"
                            v-bind="(slotProps.node.attrs) ? getNodeAttributes(slotProps.node.attrs) : null"
                            @dragstart="onDragStart"
                        >
                            <q-icon v-if="slotProps.node.icon" :name="slotProps.node.icon" />
                            {{ $tc('common.correspondence.smart-object.' + normalizeTranslationId(slotProps.node.id), 1) }}
                        </span>
                    </template>
                    <template v-else>
                        <q-icon
                            v-if="slotProps.node.icon"
                            :name="slotProps.node.icon"
                            size="1em"
                            class="q-ml-xs q-mr-xs"
                        />
                        {{ slotProps.node.label }}
                    </template>
                </template>
            </q-tree>
        </q-scroll-area>
    </div>
</template>

<script>
import { kebabCase } from 'lodash'
import { walkTree, normalizeTranslationId } from '@/helpers'
import { ContactType, CorrespondenceItemTargetObjectType } from '@/enums/graphql'
import BaseInput from '@/components/form/BaseInput'

import {
    generalSmartObjectItems,
    recipientPersonOnlySmartObjectItems,
    recipientCompanyOnlySmartObjectItems,
    recipientMixedSmartObjectItems,
    senderSmartObjectItems,
    contractSmartObjectItems,
    applicationSmartObjectItems
} from '@/editor/smartObjects/smartObject.items'

export default {
    name: 'SmartObjectTree',
    components: {
        BaseInput,
    },
    props: {
        smartObjectValidTargetObjectTypes: {
            type: Array,
            default: () => {
                return []
            },
        },
        validSchemaType: {
            type: String,
            required: true,
        },
        showHeading: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        const data = {
            filterQuery: '',
            expandedKeys: [],
            expandedKeysBackup: [],
        }

        return data
    },
    computed: {
        smartObjectTreeNodes () {
            const generalNode = {
                label: this.$tc('common.correspondence.smart-object.general.general', 1),
                id: 'general',
                icon: 'mib-folder',
                selectable: false,
                children: [
                    ...this.filterByValidSchemaType(generalSmartObjectItems),
                ],
            }

            const recipientNode = {
                label: this.$tc('common.correspondence.smart-object.recipient.recipient', 1),
                id: 'recipient',
                icon: 'mib-single-neutral-actions-mail',
                selectable: false,
                children: [
                    ...this.filterByValidSchemaType(recipientMixedSmartObjectItems),
                ],
            }

            const personNode = {
                label: this.$tc('common.correspondence.smart-object.recipient.person', 1),
                id: 'recipient.person.only',
                icon: 'mib-single-neutral',
                selectable: false,
                children: [
                    ...this.filterByValidSchemaType(recipientPersonOnlySmartObjectItems),
                ],
            }

            const companyNode = {
                label: this.$tc('common.correspondence.smart-object.recipient.company', 1),
                id: 'recipient.company.only',
                icon: 'mib-building-1',
                selectable: false,
                children: [
                    ...this.filterByValidSchemaType(recipientCompanyOnlySmartObjectItems),
                ],
            }

            const senderNode = {
                label: this.$tc('common.correspondence.smart-object.sender.sender', 1),
                id: 'sender',
                icon: 'mib-send-email-envelope',
                selectable: false,
                children: [
                    ...this.filterByValidSchemaType(senderSmartObjectItems),
                ],
            }

            const applicationNode = {
                label: this.$tc('common.correspondence.smart-object.application.application', 1),
                id: 'application',
                icon: 'mib-common-file-text-clock',
                selectable: false,
                children: [
                    ...this.filterByValidSchemaType(applicationSmartObjectItems),
                ],
            }

            const contractNode = {
                label: this.$tc('common.correspondence.smart-object.contract.contract', 1),
                id: 'contract',
                icon: 'mib-common-file-text-alternate',
                selectable: false,
                children: [
                    ...this.filterByValidSchemaType(contractSmartObjectItems),
                ],
            }

            if (this.smartObjectValidTargetObjectTypes.length) {
                const treeNodes = [generalNode]
                if (this.smartObjectValidTargetObjectTypes.includes(ContactType.PERSON)) recipientNode.children.unshift(personNode)
                if (this.smartObjectValidTargetObjectTypes.includes(ContactType.COMPANY)) recipientNode.children.unshift(companyNode)
                treeNodes.push(recipientNode)
                treeNodes.push(senderNode)
                if (this.smartObjectValidTargetObjectTypes.includes(CorrespondenceItemTargetObjectType.APPLICATION)) treeNodes.push(applicationNode)
                if (this.smartObjectValidTargetObjectTypes.includes(CorrespondenceItemTargetObjectType.CONTRACT)) treeNodes.push(contractNode)
                return treeNodes
            } else {
                recipientNode.children.unshift(personNode, companyNode)
                return [
                    generalNode,
                    recipientNode,
                    senderNode,
                    applicationNode,
                    contractNode,
                ]
            }
        },
        expandableKeys () {
            const expandableKeys = []
            walkTree({ children: this.smartObjectTreeNodes }, node => {
                if (node.selectable === false && node.id) expandableKeys.push(node.id)
            })
            return expandableKeys
        },
    },
    watch: {
        'filterQuery' (newFilterQuery, oldFilterQuery) {
            if (newFilterQuery.length && oldFilterQuery === '') {
                this.expandedKeysBackup = Array.from(this.expandedKeys)
                this.expandedKeys = Array.from(this.expandableKeys)
            } else if (newFilterQuery === '') {
                this.expandedKeys = Array.from(this.expandedKeysBackup)
                this.expandedKeysBackup = []
            }
        },
    },
    methods: {
        getNodeAttributes (attrs) {
            const attributes = {}
            Object.keys(attrs).forEach(key => {
                attributes[`data-${kebabCase(key)}`] = attrs[key]
            })
            return attributes
        },
        filterByValidSchemaType (smartObjectItems) {
            return smartObjectItems.filter(smartObjectItem => {
                return smartObjectItem.validSchemaTypes.includes(this.validSchemaType)
            })
        },
        filter (node, filterQuery) {
            if (node.selectable === false) return false
            return this.$tc('common.correspondence.smart-object.' + normalizeTranslationId(node.id), 1).toLowerCase().indexOf(filterQuery.toLowerCase()) > -1
        },
        resetFilter () {
            this.filterQuery = ''
        },
        onDragStart (event) {
            event.dataTransfer.setData('text/html', event.target.outerHTML)
            event.dataTransfer.setData('data-id', event.target.getAttribute('data-id'))
        },
        normalizeTranslationId (...args) {
            return normalizeTranslationId(...args)
        },
    },
}
</script>
