import gql from 'graphql-tag'

export const CANCELLATION_CREATE_MUTATION = gql`
    mutation cancellationCreate(
        $contract_id: ID!
        $cancellation_date: Date!
    ) {
        cancellationCreate(
            contract_id: $contract_id
            cancellation_date: $cancellation_date
        ) {
            id
            status
            cancellation_date
        }
    }
`
