import { apolloClient } from '@/apollo/client'

import { COMMISSION_RATE_ADJUSTMENT_RULE_SET_QUERY } from '@/graphql/commissionRateAdjustmentRuleSet/commissionRateAdjustmentRuleSet'
import { COMMISSION_RATE_ADJUSTMENT_RULE_SETS_QUERY } from '@/graphql/commissionRateAdjustmentRuleSet/commissionRateAdjustmentRuleSets'
import { COMMISSION_RATE_ADJUSTMENT_RULE_SET_CREATE_MUTATION } from '@/graphql/commissionRateAdjustmentRuleSet/commissionRateAdjustmentRuleSetCreate'
import { COMMISSION_RATE_ADJUSTMENT_RULE_SET_UPDATE_MUTATION } from '@/graphql/commissionRateAdjustmentRuleSet/commissionRateAdjustmentRuleSetUpdate'
import { COMMISSION_RATE_ADJUSTMENT_RULE_SET_DELETE_MUTATION } from '@/graphql/commissionRateAdjustmentRuleSet/commissionRateAdjustmentRuleSetDelete'

export const CommissionRateAdjustmentRuleSetService = {
    get (id) {
        return apolloClient.query({
            query: COMMISSION_RATE_ADJUSTMENT_RULE_SET_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.commissionRateAdjustmentRuleSet)
    },
    all (variables) {
        return apolloClient.query({
            query: COMMISSION_RATE_ADJUSTMENT_RULE_SETS_QUERY,
            variables,
        }).then(response => response.data.commissionRateAdjustmentRuleSets)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: COMMISSION_RATE_ADJUSTMENT_RULE_SET_CREATE_MUTATION,
            variables,
        }).then(response => response.data.commissionRateAdjustmentRuleSetCreate)
    },
    update (variables) {
        return apolloClient.mutate({
            mutation: COMMISSION_RATE_ADJUSTMENT_RULE_SET_UPDATE_MUTATION,
            variables: variables,
        }).then(response => response.data.commissionRateAdjustmentRuleSetUpdate)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: COMMISSION_RATE_ADJUSTMENT_RULE_SET_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.commissionRateAdjustmentRuleSetDelete)
    },
}
