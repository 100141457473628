import { Model } from '@/models'

import { ContactService } from '@/services'
import { AddressStatus } from '@/enums/graphql'
import { formatDate } from '@/helpers/date'
import { parseISO, isPast, isFuture } from 'date-fns'
import { computed } from 'vue'

export class Address extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'category',
            'label',
            'po_box',
            'address1',
            'address2',
            'address3',
            'zip',
            'city',
            'country',
            'valid_from',
            'valid_until',
            'is_main_correspondence_address',
            'status',

            // Timestamps
            'created_at',
            'updated_at',
        ], data)

        // Computed
        this.formattedCategoryLabel = computed(() => {
            return this.label ? `${this.category?.name}: ${this.label?.label}` : this.category?.name
        })
    }

    static status = AddressStatus

    get formattedValidFrom () {
        return formatDate(this.valid_from)
    }

    get formattedValidUntil () {
        return formatDate(this.valid_until)
    }

    get isNotValidYet () {
        return isFuture(parseISO(this.valid_from))
    }

    get isNoLongerValid () {
        if (this.valid_until) {
            return isPast(parseISO(this.valid_until))
        } else {
            return false
        }
    }

    static create (contactId, { category, label, poBox, zip, city, validFrom, isMain, country, address1, address2, address3, validUntil }) {
        const variables = {
            contact_id: contactId,
            address: {
                category_id: category, // TODO: refactor to categoryId
                label_id: label, // TODO: refactor to labelId
                po_box: poBox,
                zip: zip,
                city: city,
                valid_from: validFrom,
                is_main_correspondence_address: isMain,
                country_id: country, // TODO: refactor to countryId
            },
        }

        if (address1) variables.address.address1 = address1
        if (address2) variables.address.address2 = address2
        if (address3) variables.address.address3 = address3
        if (validUntil) variables.address.valid_until = validUntil

        return ContactService.createAddress(variables)
    }

    update ({ category, label, poBox, zip, city, validFrom, isMain, country, address1, address2, address3, validUntil }) {
        const addressVariables = {
            category_id: category,
            label_id: label,
            po_box: poBox,
            zip: zip,
            city: city,
            valid_from: validFrom,
            is_main_correspondence_address: isMain,
            country_id: country, // TODO: refactor to countryId
        }

        addressVariables.address1 = address1 || null
        addressVariables.address2 = address2 || null
        addressVariables.address3 = address3 || null
        addressVariables.valid_until = validUntil || null

        // TODO: Directly update address object?
        return ContactService.updateAddress(this.id, addressVariables)
    }

    delete () {
        return ContactService.deleteAddress(this.id)
    }
}
