<template>
    <q-icon
        v-if="text || title || $slots.default"
        :name="name"
        :color="color"
        :size="size"
        :class="['info-icon', { 'do-not-print': doNotPrintIcon }]"
        :style="iconPositionYOffset ? `transform: translateY(${iconPositionYOffset});` : null"
    >
        <!-- TODO improvement: Replace with custom BaseTooltip component (after that component has been created) -->
        <q-tooltip
            :anchor="anchor"
            :self="self"
            :offset="[offsetX, offsetY]"
            :delay="500"
            :hide-delay="100"
            :max-width="tooltipMaxWidth"
        >
            <slot name="default">
                <p v-if="title" class="title">{{ title }}</p>
                <!-- TODO: Replace v-html with custom Vue directive when MAX-937 has been done -->
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p v-if="text" class="text" v-html="infoText" />
                <p v-if="statusCode" class="status-code">{{ $tc('common.status.code.status-code', 1) }}: {{ statusCode }}</p>
            </slot>
        </q-tooltip>
    </q-icon>
</template>

<script>
// TODO @TFU: Make DOMpurify available globally (https://itxpert.atlassian.net/browse/MAX-937)
import DOMPurify from 'dompurify'

export default {
    name: 'InfoIcon',
    props: {
        name: {
            type: String,
            default: 'mib-information-circle',
        },
        title: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        statusCode: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: 'secondary-light',
        },
        size: {
            type: String,
            default: '0.8em',
        },
        iconPositionYOffset: {
            type: String,
            default: '',
        },
        anchor: {
            type: String,
            default: 'center right',
        },
        self: {
            type: String,
            default: 'center left',
        },
        offsetX: {
            type: Number,
            default: 5,
        },
        offsetY: {
            type: Number,
            default: 0,
        },
        tooltipMaxWidth: {
            type: String,
            default: '20rem',
        },
        doNotPrintIcon: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        infoText () {
            // TODO @TFU: Make DOMpurify available globally and create custom Vue directive for this (https://itxpert.atlassian.net/browse/MAX-937)
            // Sanitize text from locale file with DOMPurify
            const content = DOMPurify.sanitize(this.text)
            // Wrap text between `backticks` with <code> element
            const regex =/`(.+?)`/mig
            return content.replace(regex, '<code>$1</code>')
        },
    },
}
</script>

<style lang="scss" scoped>
.info-icon {
    vertical-align: baseline;

    &:hover {
        cursor: help;
    }

    // Adjust styling when info-icon is used …
    // … in regular paragraphs
    p & {
        font-size: 1em !important;
    }

    // … within form fields (BaseInput, BaseSelect, BaseSelectFilter, …)
    .q-field__append & {
        font-size: 0.75em !important;

        &:last-child {
            margin-right: $sizeSpacingXs;
        }
    }
}
</style>
