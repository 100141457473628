import gql from 'graphql-tag'

export const CONTRACT_CONTRACT_NUMBER_DELETE_MUTATION = gql`
    mutation contractContractNumberDelete(
        $id: ID!
    ) {
        contractContractNumberDelete(
            id: $id
        ) {
            status
        }
    }
`
