import gql from 'graphql-tag'

export const CONTACT_RELATIONSHIP_UPDATE_MUTATION = gql`
    mutation contactRelationshipUpdate(
        $id: ID!
        $contactRelationship: ContactRelationshipInput!
        $areasOfResponsibility: [AreaOfResponsibilityInput!]
    ) {
        contactRelationshipUpdate(
            id: $id
            contactRelationship: $contactRelationship
            areasOfResponsibility: $areasOfResponsibility
        ) {
            id
            definition {
                id
                type
                categories
                node1_type
                node1_valid_contact_types
                node2_type
                node2_valid_contact_types
            }
            node1 {
                id
                contactNumber {
                    id
                    number
                }
                ... on Person {
                    gender
                    personal_pronoun
                    title
                    first_name
                    last_name
                }
                ... on Company {
                    company_name
                    is_tenant_company
                }
            }
            node2 {
                id
                contactNumber {
                    id
                    number
                }
                ... on Person {
                    gender
                    personal_pronoun
                    title
                    first_name
                    last_name
                }
                ... on Company {
                    company_name
                    is_tenant_company
                }
            }
            notes
            areasOfResponsibility {
                id
                name
                description
                is_anonymous
                contactRelationshipNode2Contact {
                    ... on BasicPerson {
                        id
                        first_name
                        last_name
                    }
                    ... on BasicCompany {
                        id
                        company_name
                    }
                }
                emailAddress {
                    id
                    category {
                        id
                        key
                        name
                    }
                    label {
                        key
                        label
                    }
                    email
                    is_main_email_address
                }
                phoneNumber {
                    id
                    category {
                        id
                        key
                        name
                    }
                    label {
                        key
                        label
                    }
                    phone_number
                    is_main_phone_number
                }
            }
            created_at
            updated_at
        }
    }
`
