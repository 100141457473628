<template>
    <page-loading-indicator v-if="!product" actions />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="product.name" />
                <q-badge align="middle" class="q-ml-xs" :class="'status-' + product.status.toLowerCase()">{{ $t('common.status.' + product.status.toLowerCase()) }}</q-badge>
            </template>

            <h1>
                {{ product.name }}
                <span v-if="!showProductUpdateForm">
                    <base-button
                        round
                        size="xs"
                        icon="mib-pencil"
                        flat
                        class="do-not-print"
                        @click="showProductUpdateForm = true"
                    />
                </span>
            </h1>

            <template v-slot:actions>
                <actions
                    v-if="product.actions"
                    :actions="product.actions"
                    @click="handleActions"
                />

                <form-dialog
                    ref="productArchiveDialog"
                    @confirm="handleProductArchive"
                >
                    <template v-slot:title>{{ $tc('common.actions.product.archive', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.products.detail.archive--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:productName><b>{{ product.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
                <form-dialog
                    ref="productUnarchiveDialog"
                    @confirm="handleProductUnarchive"
                >
                    <template v-slot:title>{{ $tc('common.actions.product.unarchive', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.products.detail.unarchive--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:productName><b>{{ product.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
                <!-- TODO: enhance v-if with defined conditions which must be met to be able to delete: Only possible if no active or archived `Contracts` exist that have been created based on this `Product` -->
                <form-dialog
                    ref="productDeleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleProductDelete"
                >
                    <template v-slot:title>{{ $tc('common.actions.product.delete', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.products.detail.delete--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:productName><b>{{ product.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <div class="row">
            <div class="col-xs-12 col-sm-10 col-md-7 col-lg-6 col-xl-5 q-gutter-y-sm">
                <div class="col-xs-12">
                    <dl>
                        <dt>{{ $tc('common.product.product-provider', 1) }}: </dt>
                        <!-- TODO: Check if this should be linked to the product provider (only if the user has the according permission) -->
                        <dd>{{ product.providerName }}</dd>
                        <br>

                        <dt>{{ $tc('common.product.product-template', 1) }}: </dt>
                        <!-- TODO: Check if this should be linked to the product template (only if the user has the according permission) -->
                        <dd v-if="product.template">{{ product.template.name }}</dd>
                        <dd v-else class="additional-info">{{ $t('common.term.not-provided--alt') }}</dd>
                    </dl>
                </div>

                <product-update-form
                    v-if="showProductUpdateForm"
                    ref="productUpdateForm"
                    :product="product"
                    @update="showProductUpdateForm = false"
                    @cancel="showProductUpdateForm = false"
                />
            </div>
        </div>

        <in-page-footer>
            <base-button
                :label="$t('common.term.back')"
                flat
                primary-button
                @click="$router.go(-1)"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { ContactType, ProductStatus } from '@/enums/graphql'

import { contactMixin } from '@/mixins/contactMixin'
import ProductUpdateForm from '@/components/product/ProductUpdateForm'
import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'

import { Product } from '@/models/product'

export default {
    name: 'ProductDetail',
    meta () {
        return {
            title: !this.product ? `${this.$t('common.term.loading')} …` : this.product.name,
        }
    },
    components: {
        ProductUpdateForm,
        Actions,
        FormDialog,
    },
    mixins: [contactMixin],
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            ContactType,
            ProductStatus,
            product: null,
            showProductUpdateForm: false,
            confirmDeletion: false,
        }
    },
    created () {
        Product.objects.get(this.id).then(product => {
            this.product = product
        })
    },
    methods: {
        handleActions (action) {
            switch (action.key) {
                case Product.action.ARCHIVE:
                    this.$refs.productArchiveDialog.open()
                    break
                case Product.action.UNARCHIVE:
                    this.$refs.productUnarchiveDialog.open()
                    break
                case Product.action.DELETE:
                    this.$refs.productDeleteDialog.open()
                    break
            }
        },
        handleProductArchive () {
            this.product.archive()
                .then(() => {
                    // TODO: Add notification?
                })
        },
        handleProductUnarchive () {
            this.product.unarchive()
                .then(() => {
                    // TODO: Add notification?
                })
        },
        handleProductDelete () {
            this.product.delete()
                .then(() => {
                    this.$refs.productDeleteDialog.close()
                    this.$router.replace({ name: 'admin-product-list' })
                })
                .catch(error => {
                    this.$refs.productDeleteDialog.showFormErrorMessage(error)
                    this.$refs.productDeleteDialog.resetFormSubmitStatus()
                })
        },
    },
}
</script>
