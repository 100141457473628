import gql from 'graphql-tag'

export const DOCUMENT_LAYOUT_UPDATE_MUTATION = gql`
    mutation documentLayoutUpdate(
        $id: ID!
        $documentLayout: DocumentLayoutInput!
    ) {
        documentLayoutUpdate(
            id: $id
            documentLayout: $documentLayout
        ) {
            id
            name
            name_language_versions
            owner {
                company_name
            }
            pageBackground {
                name
            }
            page_margins
            is_default
            created_at
            updated_at
        }
    }
`
