import kebabCase from 'lodash.kebabcase'
import upperFirst from 'lodash/upperFirst'
import { i18n } from '@/i18n'
import store from '@/store/store'
import { getFormFieldTranslationId, getTrueFalseAsYesNo } from '@/helpers/form'
import { formatDate } from '@/helpers/date'
import { formatCurrency } from '@/helpers/number'

export class ActivityLogParser {
    static attributeDataTypes = {}

    static parse (activityLog) {
        if (this.preprocess) activityLog = this.preprocess(activityLog)

        const data = {
            causer: activityLog.causer?.consultant?.getContactName({ consultingCompanyName: !activityLog.causer.consultant.worksForUserConsultingCompany }) ?? i18n.t('common.term.someone'),
            event: activityLog.description,
            subject: i18n.tc(`common.change-log.subject.${activityLog.isSubjectRelation ? 'some' : 'this'}.${kebabCase(activityLog.log_name)}`, 1),
            logDetails: [],
        }

        // TODO @MTR & @TFU: At some point the causer of the ActivityLog can and will be `null`, e.g. shadow processing of commissions. Handle in the creation of the logDescription.
        // Log description & details
        const getLogDescriptionFunctionName = `getLogDescription${upperFirst(activityLog.description)}`
        const getLogDetailsFunctionName = `getLogDetails${upperFirst(activityLog.description)}`

        if (this[getLogDescriptionFunctionName]) data.logDescription = this[getLogDescriptionFunctionName](activityLog, data)
        if (this[getLogDetailsFunctionName]) data.logDetails = this[getLogDetailsFunctionName](activityLog, data)

        return data
    }

    static getLogDescriptionCreated (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `<b>${data.subject}</b>`,
        }
        return i18n.t(`common.change-log.someone-${activityLog.isSubjectRelation ? 'added' : 'created'}-something`, interpolationParams)
    }

    static getLogDescriptionUpdated (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `${i18n.tc('common.change-log.someone-updated-something--field', activityLog.attributeKeys.length)} <b>${activityLog.attributeLabels}</b>`,
            ofSomething: `${i18n.tc(`common.change-log.subject.of-some.${kebabCase(activityLog.log_name)}`, 1)}`,
        }
        return i18n.t(`common.change-log.someone-updated-something${activityLog.isSubjectRelation ? '-of-something' : ''}`, interpolationParams)
    }

    static getLogDescriptionDeleted (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `<b>${data.subject}</b>`,
        }
        return i18n.t(`common.change-log.someone-deleted-something`, interpolationParams)
    }

    static getLogDetailsUpdated (activityLog, data) {
        const details = []

        Object.keys(activityLog.properties.attributes).forEach(attributeKey => {
            const attributeDataType = this.attributeDataTypes[attributeKey] ?? 'undefined'
            const detail = {
                key: attributeKey,
                label: i18n.tc(getFormFieldTranslationId({ type: 'label', context: kebabCase(activityLog.log_name), key: kebabCase(attributeKey) }), 1),
            }

            const newValue = activityLog.properties.attributes[attributeKey]
            const oldValue = activityLog.properties?.old[attributeKey]

            if (this.checkValue(newValue)) detail.newValue = this.getFormattedLogDetailValue(activityLog, attributeKey, attributeDataType, newValue)
            if (this.checkValue(oldValue)) detail.oldValue = this.getFormattedLogDetailValue(activityLog, attributeKey, attributeDataType, oldValue)

            details.push(detail)
        })

        return details
    }

    static checkValue (value) {
        return (value !== '' && value !== null && value !== undefined)
    }

    static getFormattedLogDetailValue (activityLog, attributeKey, attributeDataType, value) {
        let correspondenceLanguage
        let translationId

        switch (attributeDataType) {
            case 'addressLabel':
                return store.state.addressLabels[value]
            case 'emailAddressLabel':
                return store.state.emailAddressLabels[value]
            case 'phoneNumberLabel':
                return store.state.phoneNumberLabels[value]
            case 'country':
                return store.state.countryLabels[value]
            case 'correspondenceLanguage':
                correspondenceLanguage = store.state.user.tenantSettings.correspondenceLanguages.find(correspondenceLanguage => correspondenceLanguage.id === value)
                return (correspondenceLanguage) ? correspondenceLanguage.name : value
            case 'date':
                return formatDate(value)
            case 'currency':
                return formatCurrency(value)
            case 'boolean':
                return getTrueFalseAsYesNo(value)
            case 'percentage':
                return `${value} %`
            case 'localized':
                return Object.keys(value).map(locale => {
                    const output = [value[locale]]
                    const systemLanguage = store.state.user.tenantSettings.systemLanguages.find(systemLanguage => systemLanguage.id === locale)
                    if (systemLanguage) output.unshift(`${systemLanguage.name}: `)
                    return output.join('')
                }).join(', ')
            case 'localized:userLanguage':
                return value[i18n.locale]
            default:
                translationId = getFormFieldTranslationId({ type: 'value', context: kebabCase(activityLog.log_name), key: kebabCase(attributeKey), value: value })
                if (i18n.te(translationId)) return i18n.tc(translationId, 1)
                return value
        }
    }
}
