<template>
    <page-wrapper>
        <page-header progress-bar :progress-bar-value="queue.length ? 1 - (queue.length / selectedItems.length) : 0">
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.admin.products.create-product.create-product', 2)" :to="{ name: 'admin-product-create' }" />
            </template>

            <h1>{{ $tc('views.admin.products.create-product.create-product', 2) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <q-stepper
                    v-model="currentStep"
                    alternative-labels
                    :vertical="$q.screen.lt.md"
                    flat
                >
                    <q-step
                        name="productProvider"
                        :title="$tc('views.admin.products.create-product.select-product-provider', 1)"
                        :caption="(currentStep !== 'productProvider') ? productProvider.getContactName() : ''"
                        icon="mib-shipment-box"
                        active-icon="mib-shipment-box"
                        done-icon="mib-check"
                        done-color="secondary"
                        :done="currentStep !== 'productProvider'"
                    >
                        <product-select-product-provider-form @submit="fetchProductProvider" />
                    </q-step>

                    <q-step
                        name="form"
                        :title="$tc('views.admin.products.create-product.select-product-templates', 2)"
                        caption=""
                        icon="mib-pencil-write-3-alternate"
                        active-icon="mib-pencil-write-3-alternate"
                        done-icon="mib-check"
                        done-color="secondary"
                        :done="currentStep === 'createProducts'"
                    >
                        <template v-if="productProvider">
                            <q-table
                                :data="productTemplates"
                                :columns="columns"
                                row-key="id"
                                :pagination.sync="pagination"
                                hide-pagination
                                selection="multiple"
                                :selected.sync="selectedItems"
                                :filter="filter"
                                binary-state-sort
                                flat
                                square
                            >
                                <template v-slot:top>
                                    <q-space />
                                    <filter-input v-model="filter" @clear-filter="clearFilter" />
                                </template>

                                <template v-slot:body-cell-product_name="props">
                                    <q-td :props="props" class="product-name">
                                        <template v-if="selectedItem(props.row.id)">
                                            <base-input
                                                v-model="selectedItem(props.row.id).productName"
                                                :error="$v.selectedItems.$each[selectedItemIndex(props.row.id)].$invalid"
                                                :error-message="$tc('common.error-message.required', 1, { field: $tc('common.product.product-name', 1) })"
                                            />
                                        </template>
                                    </q-td>
                                </template>

                                <template v-if="selectedItems.length && $v.selectedItems.$invalid" v-slot:bottom-row>
                                    <q-tr>
                                        <q-td colspan="100%">
                                            <p class="text-negative q-ma-none"><q-icon class="q-mr-sm" name="mib-alert-triangle" color="negative" /> {{ $tc('views.admin.products.create-product.error--table-contains-errors', 2) }}</p>
                                        </q-td>
                                    </q-tr>
                                </template>
                            </q-table>

                            <in-page-footer>
                                <base-button
                                    :label="$t('common.term.cancel')"
                                    primary-button
                                    flat
                                    @click="reset"
                                />
                                <base-button
                                    :label="$tc('views.admin.products.create-product.create-product--create-n-products', selectedItems.length, { count: selectedItems.length })"
                                    :disable="$v.$invalid"
                                    primary-button
                                    icon="mib-check"
                                    @click="submit"
                                />
                            </in-page-footer>
                        </template>
                    </q-step>

                    <q-step
                        name="createProducts"
                        :title="$tc('views.admin.products.create-product.create-product', 2)"
                        icon="mib-shipment-box"
                        active-icon="mib-shipment-box"
                        done-icon="mib-check"
                        done-color="secondary"
                    >
                        <div v-if="queue.length">
                            <h2 class="text-h3">{{ $tc('views.admin.products.create-product.creating-products-for-provider-xy', selectedItems.length, { n: selectedItems.length, productProvider: productProvider.getContactName({ displayName: true }) }) }}</h2>

                            <i18n path="common.term.creating-item--temp-component-interpolation-count" tag="p" >
                                <template v-slot:item>{{ $t('common.term.of--x-of-n', { x: (selectedItems.length - queue.length + 1), n: selectedItems.length }) }}: <b>{{ queue[0].item.productName }}</b></template>
                            </i18n>
                            <base-button @click="abort">{{ $t('common.term.abort') }}</base-button>
                        </div>
                        <div v-else-if="log.length">
                            <template v-for="status in ['DONE', 'ABORTED', 'FAILED']">
                                <div v-if="logItemsByStatus[status].length" :key="`status-${status.toLowerCase()}`" class="q-mb-lg">
                                    <template v-if="status === 'DONE'">
                                        <h2 class="text-h3">{{ $tc('views.admin.products.create-product.created-products-for-provider-xy', logItemsByStatus[status].length, { productProvider: productProvider.getContactName({ displayName: true }) }) }}</h2>
                                    </template>
                                    <template v-else>
                                        <h2 class="text-h3">{{ $t(`views.admin.products.create-product.status.${status.toLowerCase()}`) }}</h2>
                                        <p>{{ $tc(`views.admin.products.create-product.status.${status.toLowerCase()}--description`, logItemsByStatus[status].length) }}</p>
                                    </template>

                                    <q-list class="bulk-item-list" :data-test="`bulk-item-list-${status.toLowerCase()}`">
                                        <q-item
                                            v-for="logItem in logItemsByStatus[status]"
                                            :key="logItem.id"
                                            class="log-item"
                                            data-test="log-item"
                                        >
                                            <q-item-section thumbnail top class="log-item-icon">
                                                <bulk-item-status-indicator :status="BulkItemWrapperStatus[status]" base-icon="shipment" status-translation-base-path="views.admin.products.create-product.status" />
                                            </q-item-section>

                                            <q-item-section class="log-item-info" data-test="log-item-info">
                                                <q-item-label>
                                                    <span class="log-item-name" data-test="log-item-name">{{ logItem.item.productName }}</span>
                                                </q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </q-list>
                                </div>
                            </template>

                            <in-page-footer>
                                <base-button
                                    :label="$t('views.admin.products.create-product.back-to-product-list')"
                                    :to="{ name: 'admin-product-list' }"
                                    primary-button
                                    flat
                                />
                                <base-button
                                    :label="$t('views.admin.products.create-product.create-new-products')"
                                    primary-button
                                    icon="mib-shipment-add"
                                    @click="reset"
                                />
                                <base-button
                                    :label="$tc('views.admin.products.create-product.create-the-same-product-for-another-provider', log.length)"
                                    primary-button
                                    icon="mib-shipment-next"
                                    @click="createWithSameTemplates"
                                />
                            </in-page-footer>
                        </div>
                    </q-step>
                </q-stepper>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import { wrapBulkItems } from '@/helpers'
import { BulkItemWrapperStatus } from '@/enums'
import ProductSelectProductProviderForm from '@/components/product/ProductSelectProductProviderForm'
import BaseInput from '@/components/form/BaseInput'
import BulkItemStatusIndicator from '@/components/BulkItemStatusIndicator'
import FilterInput from '@/components/FilterInput'

import { Contact } from '@/models/contact'
import { ProductTemplate } from '@/models/productTemplate'
import { Product } from '@/models/product'

export default {
    name: 'ProductCreateBulk',
    meta () {
        return {
            title: this.$tc('views.admin.products.create-product.create-product', 2),
        }
    },
    components: {
        ProductSelectProductProviderForm,
        BaseInput,
        BulkItemStatusIndicator,
        FilterInput,
    },
    data () {
        return {
            currentStep: 'productProvider',
            BulkItemWrapperStatus,
            productProvider: null,
            productTemplates: [],
            filter: '',
            pagination: {
                rowsPerPage: 0,
            },
            columns: [
                { name: 'product_template_name', label: this.$tc('common.product.product-template', 1), field: row => row.name, sortable: true, align: 'left' },
                { name: 'product_template_category_name', label: this.$tc('common.term.category', 1), field: row => row.category.name, sortable: true, align: 'left' },
                { name: 'product_template_sub_category_name', label: this.$tc('common.term.subcategory', 1), field: row => row.subCategory.name, sortable: true, align: 'left' },
                { name: 'product_name', label: this.$tc('common.product.product-name', 1), align: 'left' },
            ],
            selectedItems: [],
            queue: [],
            log: [],
        }
    },
    computed: {
        logItemsByStatus () {
            const items = {}
            Object.keys(BulkItemWrapperStatus).forEach(bulkItemWrapperStatus => {
                items[bulkItemWrapperStatus] = this.log.filter(logItem => logItem.status === bulkItemWrapperStatus)
            })
            return items
        },
    },
    validations () {
        return {
            selectedItems: {
                required,
                minLength: minLength(1),
                $each: {
                    productName: {
                        required,
                        minLength: minLength(1),
                    },
                },
            },
        }
    },
    watch: {
        queue () {
            if (this.queue.length) {
                const nextItem = this.queue[0]
                if (nextItem.status === BulkItemWrapperStatus.QUEUED) this.createItem(nextItem)
            }
        },
    },
    created () {
        ProductTemplate.objects.all().then(response => {
            this.productTemplates = response.data.map(productTemplate => {
                productTemplate.productName = productTemplate.name
                return productTemplate
            })
        })
    },
    methods: {
        clearFilter () {
            this.filter = ''
        },
        fetchProductProvider ({ productProviderId }) {
            Contact.objects.get(productProviderId).then(productProvider => {
                this.productProvider = productProvider
                this.currentStep = 'form'
            })
        },
        selectedItem (productTemplateId) {
            return this.selectedItems.find(selectedItem => selectedItem.id === productTemplateId)
        },
        selectedItemIndex (productTemplateId) {
            return this.selectedItems.findIndex(selectedItem => selectedItem.id === productTemplateId)
        },
        createItem (bulkItemWrapper) {
            bulkItemWrapper.status = BulkItemWrapperStatus.PROCESSING
            Product.create({ name: bulkItemWrapper.item.productName, productTemplate: bulkItemWrapper.item.id, productProvider: this.productProvider.id })
                .then(() => {
                    this.handleProcessingSuccess(bulkItemWrapper)
                })
                .catch(() => {
                    this.handleProcessingFailure(bulkItemWrapper)
                })
        },
        addToLog (bulkItemWrapper, status) {
            const bulkItemWrapperIndex = this.queue.findIndex(queueItem => queueItem.id === bulkItemWrapper.id)
            this.queue.splice(bulkItemWrapperIndex, 1)

            bulkItemWrapper.status = status
            this.log.unshift(bulkItemWrapper)
        },
        handleProcessingSuccess (bulkItemWrapper) {
            this.addToLog(bulkItemWrapper, BulkItemWrapperStatus.DONE)
        },
        handleProcessingFailure (bulkItemWrapper) {
            this.addToLog(bulkItemWrapper, BulkItemWrapperStatus.FAILED)
        },
        handleProcessingAbort (bulkItemWrapper) {
            this.addToLog(bulkItemWrapper, BulkItemWrapperStatus.ABORTED)
        },
        async submit () {
            if (this.selectedItems.length) {
                const bulkItemWrappers = await wrapBulkItems(this.selectedItems, { initialStatus: BulkItemWrapperStatus.QUEUED })
                this.currentStep = 'createProducts'
                this.queue.push(...bulkItemWrappers)
            }
        },
        abort () {
            this.queue.filter(importQueueItem => importQueueItem.status === BulkItemWrapperStatus.QUEUED).forEach(importQueueItem => {
                this.handleProcessingAbort(importQueueItem)
            })
        },
        reset () {
            this.currentStep = 'productProvider'
            this.productProvider = null
            this.selectedItems = []
            this.queue = []
            this.log = []
            this.clearFilter()
        },
        createWithSameTemplates () {
            this.currentStep = 'productProvider'
            this.productProvider = null
            this.queue = []
            this.log = []
            this.clearFilter()
        },
    },
}
</script>

<style lang="scss" scoped>
.product-name {
    width: 30vw;
    min-width: 250px;

    @include mq($from: $sizeBreakpointMd) {
        min-width: 300px;
    }

    @include mq($from: $sizeBreakpointLg) {
        width: 20vw;
        min-width: 350px;
    }

    @include mq($from: $sizeBreakpointXl) {
        width: 15vw;
    }
}

::v-deep .q-stepper__step-inner.q-stepper__step-inner {
    @include mq($from: $sizeBreakpointLg) {
        padding-left: 0;
        padding-right: 0;
    }
}

.log-item {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    min-height: auto;

    &-icon {
        padding-right: $sizeSpacingXs;
    }

    &-info {
    }

    &-name {
        word-break: break-word;
    }
}
</style>
