import { render, staticRenderFns } from "./GlobalSearchResultApplication.vue?vue&type=template&id=4880a5a0&scoped=true&"
import script from "./GlobalSearchResultApplication.vue?vue&type=script&lang=js&"
export * from "./GlobalSearchResultApplication.vue?vue&type=script&lang=js&"
import style0 from "./GlobalSearchResultApplication.vue?vue&type=style&index=0&id=4880a5a0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4880a5a0",
  null
  
)

export default component.exports