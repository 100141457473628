import { Model } from '@/models'
import { TemporaryDownloadFileService } from '@/services'

export class TemporaryDownloadFile extends Model {
    /**
     * Create a TemporaryDownloadFile model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'file',
            'user',
            'expires_at',
            'created_at',
            'updated_at',
        ], data)
    }

    static get service () { return TemporaryDownloadFileService }

    static filesPackage ({fileIds, prefix}) {
        return TemporaryDownloadFileService.filesPackage({
            file_ids: fileIds,
            file_name_prefix: prefix,
        })
    }
}
