import gql from 'graphql-tag'

export const CORRESPONDENCE_EMAIL_DELETE_ATTACHMENT_MUTATION = gql`
    mutation correspondenceEmailDeleteAttachment(
        $fileId: ID!
    ) {
        correspondenceEmailDeleteAttachment(
            file_id: $fileId
        ) {
            status
        }
    }
`
