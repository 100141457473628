import Vue from 'vue'

import './styles/quasar.styl'
import langDe from 'quasar/lang/de'

// Use custom icons with QIcon component
import iconSet from '@/assets/fonts/icon-fonts/_icon-component-mapping'

import {
    Quasar,
    LocalStorage,
    Cookies,
    Meta,
    Notify,
    Ripple,
    Screen,
    ClosePopup
    // QLayout,
    // QHeader,
    // QDrawer,
    // QPageContainer,
    // QPage,
    // QSpace,
    // QSpinner,
    // QToolbar,
    // QToolbarTitle,
    // QBtn,
    // QIcon,
    // QTooltip,
    // QList,
    // QItem,
    // QItemSection,
    // QItemLabel
} from 'quasar'
/*
    TODO: Only import components that are used in the app.
    This import statement, that loads all components should be used for prototyping purposes only!
 */
import * as components from 'quasar/src/components.js'

// Quasar config
Vue.use(Quasar, {
    config: {
        loading: {
            /* Loading defaults */
        },
        notify: {
            position: 'bottom-left',
            color: 'background-inverted',
            textColor: 'white',
            progress: true,
            attrs: {
                'data-test':"text:notify",
            },
        },
    },
    components,
    directives: {
        ClosePopup,
        Ripple,
    },
    iconSet: iconSet,
    plugins: {
        LocalStorage,
        Cookies,
        Meta,
        Notify,
    },
    lang: langDe,
})

// Define Quasar CSS breakpoints
// NOTE: Breakpoint sizes must be adjusted in 3 different places:
// - /src/quasar.js → Screen.setSizes({ … }); Breakpoint min-width must be used.
// - /src/styles/quasar.variables.styl → Breakpoint max-width must be used.
// - /src/styles/globals/_variables.scss → Breakpoint min-width must be used.
Screen.setSizes({ sm: 600, md: 1024, lg: 1600, xl: 1920 })
// Define debounce
Screen.setDebounce(100) // Set as a measure to prevent scrolling/overflow issues when resizing the window (MAXQA-9).
