<template>
    <page-wrapper>
        <page-header :progress-bar="isPosting" progress-bar-indeterminate>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.commission.manual-entry.manual-commission-entry', 2)" :to="{ name: 'commission-list-manual-entries' }" />
            </template>

            <h1>{{ $tc('views.commission.manual-entry.manual-commission-entry', 2) }}</h1>
        </page-header>

        <!-- TODO improvement: Enhance with actual numbers/progress -->
        <info-box v-if="isPosting">
            <p>{{ $tc('views.commission.commission-list-entry.entry-is-being-posted', 2) }}</p>
        </info-box>

        <!-- TODO improvement: Currently we are technically not able to filter manually created entries by query. Because of this, we hide the filter input for now. -->
        <base-table
            ref="table"
            :columns="columns"
            :fetch-objects-fn="CommissionListEntry.service.all"
            :additional-filters="additionalFilters"
            user-settings-base-path="CommissionListManualEntryList"
            pagination-sort-by-default-key="updated_at"
            :selection="selection"
            pagination-initial-order-direction-descending
            :selected.sync="selectedCommissionListEntries"
            :selection-row-condition="selectionRowCondition"
            :show-filter="false"
            enable-visible-columns
            @row-dblclick="onDblClick"
        >
            <template v-slot:tabs>
                <q-tabs
                    dense
                    no-caps
                    inline-label
                    align="left"
                    class="q-mr-sm bg-background-secondary"
                    indicator-color="accent"
                >
                    <q-route-tab
                        :to="{ name: 'commission-list-manual-entry-list', params: { status: 'pending' } }"
                        :label="$t('common.status.commission-list-entry-status.pending')"
                    />
                    <q-route-tab
                        :to="{ name: 'commission-list-manual-entry-list', params: { status: 'posted' } }"
                        :label="$t('common.status.commission-list-entry-status.posted')"
                    />
                </q-tabs>
            </template>

            <template v-slot:selectionRowConditionInfo>
                <q-checkbox
                    :value="false"
                    class="not-selectable"
                    color="secondary-light"
                    keep-color
                    dense
                    disable
                />
            </template>

            <template v-slot:body-cell-contract_number="slotProps">
                <q-td :props="slotProps">
                    <template v-if="slotProps.row.matchedContract">{{ slotProps.row.matchedContract.currentContractNumber }}</template>
                    <template v-else>{{ $t('common.term.assigned--not-assigned') }}</template>
                </q-td>
            </template>

            <template v-slot:body-cell-commission_type="slotProps">
                <q-td :props="slotProps">
                    <template v-if="slotProps.row.totalPosition && slotProps.row.totalPosition.commission_type">
                        {{ slotProps.row.totalPosition.commission_type.replace('TYPE_', '') }}
                        <info-icon
                            :title="`${$tc(`common.commission.commission-type.commission-type`, 1)} ${slotProps.row.totalPosition.commission_type.replace('TYPE_', '')}`"
                            :text="$t(`common.commission.commission-type.${toKebabCase(slotProps.row.totalPosition.commission_type)}`)"
                        />
                    </template>
                </q-td>
            </template>

            <template v-slot:body-cell-amount="slotProps">
                <q-td :props="slotProps" :class="['text-bold monospace', { 'text-negative-darker': slotProps.row.totalPosition.commission_amount < 0 }]">
                    {{ slotProps.row.totalPosition.formattedCommissionAmount }}
                </q-td>
            </template>

            <template v-slot:body-cell-status="slotProps">
                <q-td :props="slotProps" auto-width>
                    <status-badge
                        :status="slotProps.row.status"
                        default-translation-base-path="common.status.commission-list-entry-status"
                        :info-text="statusBadgeInfoText(slotProps.row.status_message)"
                    />
                </q-td>
            </template>

            <template v-slot:body-cell-actions="slotProps">
                <q-td :props="slotProps" data-test="td:actions" auto-width>
                    <q-btn
                        v-if="slotProps.row.status !== CommissionListEntryStatus.POSTED"
                        color="primary"
                        size="sm"
                        flat
                        round
                        icon="mib-pencil"
                        :to="{ name: 'commission-list-manual-entry-update', params: { id: slotProps.row.id } }"
                    >
                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.commission.manual-entry.edit-entry', 1) }}</q-tooltip>
                    </q-btn>

                    <!-- TODO @TFU: Show postings for already posted commission entries instead of the preview. -->
                    <q-btn
                        v-if="slotProps.row.status !== CommissionListEntryStatus.POSTED"
                        :color="!slotProps.row.ready_to_post || slotProps.row.totalPosition.commission_amount === 0 ? 'text-secondary' : 'primary'"
                        size="sm"
                        flat
                        round
                        :icon="!slotProps.row.ready_to_post || slotProps.row.totalPosition.commission_amount === 0 ? 'mib-view-off' : 'mib-view-1'"
                        :disabled="!slotProps.row.ready_to_post || slotProps.row.totalPosition.commission_amount === 0"
                        @click="handlePreview(slotProps.row)"
                    >
                        <q-tooltip v-if="slotProps.row.status === CommissionListEntryStatus.POSTED" :delay="1000" :offset="[0, 10]">{{ $tc('common.accounting.posting.show-posting', 2) }}</q-tooltip>
                        <template v-else>
                            <q-tooltip
                                v-if="!slotProps.row.ready_to_post || slotProps.row.totalPosition.commission_amount === 0"
                                :delay="1000"
                                :offset="[0, 10]"
                            >{{ $tc('common.accounting.posting.show-posting-preview', 0) }}</q-tooltip>
                            <q-tooltip v-else :delay="1000" :offset="[0, 10]">{{ $tc('common.accounting.posting.show-posting-preview', 2) }}</q-tooltip>
                        </template>
                    </q-btn>

                    <q-btn
                        color="primary"
                        size="sm"
                        flat
                        round
                        icon="mib-common-file-text-info"
                        :to="{ name: 'contract-detail', params: { contactId: slotProps.row.matchedContract.customer.id, id: slotProps.row.matchedContract.id } }"
                        target="_blank"
                        :disabled="!slotProps.row.matchedContract"
                        data-test="btn:tooltip-details"
                    >
                        <q-tooltip v-if="slotProps.row.matchedContract" :delay="1000" :offset="[0, 10]">{{ $tc('views.contract.list.show-details-of-contract-xy', 1, { contractNumber: slotProps.row.matchedContract.currentContractNumber }) }}</q-tooltip>
                        <q-tooltip v-else :delay="1000" :offset="[0, 10]">{{ $t('common.term.assigned--not-assigned') }}</q-tooltip>
                    </q-btn>

                    <q-btn
                        class="overflow-hidden"
                        color="primary"
                        size="sm"
                        flat
                        round
                        icon="mib-navigation-menu-horizontal"
                        data-test="btn:tooltip-actions"
                    >
                        <q-tooltip
                            :delay="1000"
                            :offset="[0, 10]"
                        >
                            {{ $t('common.term.more') }}
                        </q-tooltip>

                        <q-menu anchor="top left" self="top end">
                            <div class="q-pl-xs q-pr-xs">
                                <q-btn
                                    size="sm"
                                    flat
                                    round
                                    icon="mib-synchronize-arrow-clock"
                                    color="primary"
                                    @click="$refs.activityLogOverlay.open({ filterSubjects: [{ id: slotProps.row.id, type: ActivityLogSubjectType.COMMISSION_LIST_ENTRY }], filterIncludeSubjectRelations: [ActivityLogSubjectRelation.COMMISSION_LIST_ENTRY_POSITIONS] })"
                                >
                                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.change-log.show-change-log', 1) }}</q-tooltip>
                                </q-btn>
                            </div>
                        </q-menu>
                    </q-btn>
                </q-td>
            </template>
        </base-table>

        <!-- Start posting CommissionListEntries -->
        <form-dialog
            ref="startPostingDialog"
            double-confirm
            dont-close-on-confirm
            @confirm="handleStartPosting"
        >
            <template v-slot:title>{{ $tc('views.commission.manual-entry.post-commission-entry', selectedCommissionListEntries.length) }}</template>
            <template v-slot:default>
                <p>{{ $tc('views.commission.manual-entry.post-commission-entry--confirm', selectedCommissionListEntries.length) }}</p>
            </template>
        </form-dialog>

        <!-- Cancel posting CommissionListEntries -->
        <form-dialog
            ref="cancelPostingDialog"
            dont-close-on-confirm
            @confirm="handleCancelPosting"
        >
            <template v-slot:title>{{ $tc('views.commission.manual-entry.cancel-posting', 1) }}</template>
            <template v-slot:default>
                <p>{{ $t('views.commission.manual-entry.cancel-posting--confirm') }}</p>
            </template>
        </form-dialog>

        <!-- List entry posting preview -->
        <commission-list-entry-posting-preview-overlay ref="postingPreviewOverlay" />

        <!-- Polling -->
        <polling
            ref="polling"
            :poll-fn="CommissionListEntry.service.all"
            :stop-condition-fn="stopPollingConditionFn"
            :poll-filters="pollFilters"
            :timeout-tm="3000"
            auto-start
            @condition-check="handlePollingConditionCheck"
        />

        <!-- Activity log -->
        <activity-log-overlay ref="activityLogOverlay" />

        <in-page-footer>
            <base-button
                :label="$tc('views.commission.manual-entry.add-entry', 1)"
                primary-button
                :to="{ name: 'commission-list-manual-entry-create' }"
            />

            <div v-if="status === 'pending'">
                <base-button
                    :label="$tc('views.commission.manual-entry.post-commission-entry--n-entries', selectedCommissionListEntries.length)"
                    primary-button
                    outline
                    :disable="!selectedCommissionListEntries.length"
                    @click="handleActions({ key: CommissionListEntryActions.START_POSTING })"
                />
                <q-tooltip v-if="!selectedCommissionListEntries.length" :delay="1000" :offset="[0, 10]">{{ $tc('views.commission.manual-entry.n-manual-entries-selected', selectedCommissionListEntries.length) }}</q-tooltip>
            </div>

            <base-button
                v-if="isPosting"
                :label="$tc('views.commission.manual-entry.cancel-posting', selectedCommissionListEntries.length)"
                primary-button
                outline
                @click="handleActions({ key: CommissionListEntryActions.CANCEL_POSTING })"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import { kebabCase } from 'lodash'
import { CommissionListEntry } from '@/models/commissionListEntry'
import { CommissionListEntryActions } from '@/enums'
import { CommissionListEntryStatus, ActivityLogSubjectType, ActivityLogSubjectRelation } from '@/enums/graphql'
import CommissionListEntryPostingPreviewOverlay from '@/components/commission/CommissionListEntryPostingPreviewOverlay'

import BaseTable from '@/components/BaseTable'
import StatusBadge from '@/components/StatusBadge.vue'
import FormDialog from '@/components/form/FormDialog'
import Polling from '@/components/Polling.vue'
import ActivityLogOverlay from '@/components/ActivityLogOverlay'

export default {
    name: 'CommissionListManualEntryList',
    meta () {
        return {
            title: this.$tc('views.commission.manual-entry.manual-commission-entry', 2),
        }
    },
    components: {
        BaseTable,
        StatusBadge,
        FormDialog,
        CommissionListEntryPostingPreviewOverlay,
        Polling,
        ActivityLogOverlay,
    },
    props: {
        status: {
            type: String,
            default: 'pending',
        },
    },
    data () {
        const pending = Object.values(CommissionListEntryStatus).filter(status => status !== CommissionListEntryStatus.POSTED)

        const data = {
            filterStatus: {
                'pending': [...pending],
                'posted': [CommissionListEntryStatus.POSTED],
            },
        }

        return {
            ...data,
            ActivityLogSubjectType,
            ActivityLogSubjectRelation,
            CommissionListEntry,
            CommissionListEntryActions,
            CommissionListEntryStatus,
            selection: this.status === 'pending' ? 'multiple' : 'none',
            selectedCommissionListEntries: [],
            additionalFilters: this.status
                ? {
                    filterStatus: data.filterStatus[this.status],
                    createdManually: true,
                }
                : {
                    createdManually: true,
                },
            columns: [
                {
                    name: 'created_at',
                    label: this.$t('common.term.added-on'),
                    field: row => row.formattedCreatedAt,
                    align: 'left',
                    sortable: true,
                    hideInitially: true,
                },
                {
                    name: 'updated_at',
                    label: this.$t('common.term.updated--last-updated'),
                    field: row => row.formattedUpdatedAt,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'contract_number',
                    label: this.$tc('views.commission.manual-entry.column-title--matched-contract', 1),
                    field: row => row.matchedContract?.currentContractNumber || this.$t('common.term.assigned--not-assigned'),
                    align: 'left',
                    classes: row => (row.matchedContract?.currentContractNumber ? null : 'additional-info'),
                    sortable: true,
                    required: true,
                },
                {
                    name: 'product_provider',
                    label: this.$tc('common.product.product-provider', 1),
                    field: row => (row.matchedContract?.currentContractInformation.product.basicProvider.display_name || row.matchedContract?.currentContractInformation.product.basicProvider.name),
                    align: 'left',
                    hideInitially: true,
                },
                {
                    name: 'customer_name',
                    label: this.$tc('views.commission.manual-entry.column-title--customer-name', 1),
                    field: row => row.matchedContract?.customer.getContactName() || '–',
                    align: 'left',
                    sortable: true,
                    required: true,
                },
                {
                    name: 'premium_from',
                    label: this.$tc('views.commission.manual-entry.column-title--premium-from', 1),
                    field: row => row.totalPosition.formattedPremiumFrom,
                    align: 'left',
                    classes: row => (row.totalPosition?.premium_from ? null : 'additional-info'),
                },
                {
                    name: 'premium_to',
                    label: this.$tc('views.commission.manual-entry.column-title--premium-to', 1),
                    field: row => row.totalPosition.formattedPremiumTo,
                    align: 'left',
                    classes: row => (row.totalPosition?.premium_to ? null : 'additional-info'),
                },
                {
                    name: 'commission_type',
                    label: this.$tc('common.commission.commission-type.commission-type', 1),
                    align: 'left',
                    required: true,
                },
                {
                    name: 'amount',
                    label: this.$tc('views.commission.manual-entry.column-title--amount', 1),
                    align: 'right',
                    required: true,
                },
                {
                    name: 'status',
                    label: this.$tc('common.term.status', 1),
                    align: 'left',
                    sortable: true,
                    required: true,
                },
                {
                    name: 'actions',
                    label: this.$tc('common.term.action', 2),
                    align: 'left',
                    required: true,
                },
            ],
            pollFilters: {
                count: 1,
                filterStatus: [CommissionListEntryStatus.QUEUED_FOR_POSTING],
                createdManually: true,
            },
            isPosting: false,
        }
    },
    watch: {
        $route () {
            this.additionalFilters = {
                filterStatus: this.filterStatus[this.status],
                createdManually: true,
            }
            this.selection = this.status === 'pending' ? 'multiple' : 'none'
        },
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        onNewEntity () {
            this.$router.push({
                name: 'commission-list-manual-entry-create',
            })
        },
        onDblClick (evt, row) {
            this.$router.push({
                name: 'commission-list-manual-entry-update',
                params: { id: row.id },
            })
        },
        selectionRowCondition (row) {
            return row.ready_to_post && row.totalPosition.commission_amount !== 0
        },
        resetSelectedItems () {
            this.selectedCommissionListEntries.length = 0
            if (this.$refs.table) this.$refs.table.$refs.qTable.clearSelection()
        },
        statusBadgeInfoText (statusMessage) {
            return statusMessage?.message || null
        },
        handleActions (action) {
            switch (action.key) {
                case CommissionListEntry.action.START_POSTING:
                    this.$refs.startPostingDialog.open()
                    break
                case CommissionListEntry.action.CANCEL_POSTING:
                    this.$refs.cancelPostingDialog.open()
                    break
            }
        },
        handleStartPosting () {
            const ids = this.selectedCommissionListEntries.map(entry => entry.id)

            CommissionListEntry.startPosting(ids)
                .then(response => {
                    this.isPosting = true
                    this.$refs.startPostingDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.commission.manual-commission-entry-started-posting-success', this.selectedCommissionListEntries?.length),
                    })
                    this.resetSelectedItems()
                    this.startPolling()
                    this.$refs.table.fetchObjects()
                })
                .catch(error => {
                    this.$refs.startPostingDialog.showFormErrorMessage(error)
                    this.$refs.startPostingDialog.resetFormSubmitStatus()
                })
        },
        handleCancelPosting () {
            CommissionListEntry.cancelPosting()
                .then(response => {
                    this.isPosting = false
                    this.$refs.cancelPostingDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$t('common.notifications.commission.manual-commission-entry-cancel-posting-success'),
                    })
                    this.resetSelectedItems()
                    this.stopPolling()
                    this.$refs.table.fetchObjects()
                })
                .catch(error => {
                    this.$refs.cancelPostingDialog.showFormErrorMessage(error)
                    this.$refs.cancelPostingDialog.resetFormSubmitStatus()
                })
        },
        handlePreview (commissionListEntry) {
            this.$refs.postingPreviewOverlay.open(commissionListEntry)
        },
        startPolling () {
            this.$refs.polling.start()
        },
        stopPolling () {
            this.$refs.polling.stop()
        },
        stopPollingConditionFn (response) {
            return !response.data.length
        },
        handlePollingConditionCheck (stopCondition) {
            if (this.isPosting && stopCondition) this.$refs.table.fetchObjects()
            this.isPosting = !stopCondition
        },
        toKebabCase: function (text) {
            return kebabCase(text)
        },
    },
}
</script>
