import gql from 'graphql-tag'

export const DOCUMENT_TEMPLATE_DELETE_MUTATION = gql`
    mutation documentTemplateDelete(
        $id: ID!
    ) {
        documentTemplateDelete(
            id: $id
        ) {
            status
        }
    }
`
