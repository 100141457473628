import gql from 'graphql-tag'

export const IGB2B_SECTOR_CODES_QUERY = gql`
    query igb2bSectorCodes(
        $page: Int = 1
        $count: Int = 1000
        $orderField: IGB2BSectorCodeOrderField = CODE
        $orderDirection: OrderDirection = ASC
        $filterQuery: String
        $filterCategoryIds: [ID!]
        $filterCombinedProduct: Boolean
    ) {
        igb2bSectorCodes(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                query: $filterQuery
                category_ids: $filterCategoryIds
                combined_product: $filterCombinedProduct
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                category {
                    id
                    code
                    name
                }
                code
                combined_product
                name
            }
        }
    }
`
