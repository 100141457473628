import store from '@/store/store'

export function getAgreementPermissions (igb2bInsurerCode) {
    if (store.state.insurerList) {
        let permissionScore = 0
        if (store.state.insurerList.download_permissions_granted.includes(igb2bInsurerCode)) permissionScore++
        if (store.state.insurerList.upload_permissions_granted.includes(igb2bInsurerCode)) permissionScore++

        // TODO improvement @TFU: Extract enums and refactor if used elsewhere.
        switch (permissionScore) {
            case 0:
                return 'NO_PERMISSIONS'
            case 1:
                return 'PARTIAL_PERMISSIONS'
            case 2:
                return 'FULL_PERMISSIONS'
        }
    } else {
        return null
    }
}

export function getAvailableDxpProcessVersionMappings (igb2bInsurerCode) {
    if (store.state.igb2bInsurerCodes) {
        const dxpProcessesForSelectedIgb2bInsurerCode = store.state.igb2bInsurerCodes.find(insurerCode => insurerCode.id === igb2bInsurerCode)
        return dxpProcessesForSelectedIgb2bInsurerCode.dxpProcesses
    } else {
        return []
    }
}
