<template>
    <fake-select
        v-if="!options"
        v-bind="$attrs"
    />
    <base-select-filter
        v-else

        :value="value"
        v-bind="$attrs"
        :options="options"

        option-value="id"
        option-label="name"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { CommissionDistributionRuleSetService } from '@/services'
import FakeSelect from '@/components/FakeSelect.vue'

export default {
    name: 'CommissionDistributionRuleSetSelect',
    components: {
        FakeSelect,
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        filter: {
            type: Object,
        },
        injectOptions: {
            type: Array,
            default () {
                return []
            },
        },
    },
    data () {
        return {
            options: null,
        }
    },
    created () {
        const filters = {}

        if (this.filter) Object.assign(filters, this.filter)

        CommissionDistributionRuleSetService.all(filters, `
            id
            name
        `).then(response => {
            let responseOptions = response.data
            let injectedOptions = []

            this.injectOptions.filter(Boolean).forEach(injectOption => {
                const index = responseOptions.findIndex(option => option.id === injectOption.id)
                if (index === -1) injectedOptions.push(injectOption)
            })

            const options = [...responseOptions, ...injectedOptions]

            options.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })

            this.options = options
        })
    },
}
</script>
