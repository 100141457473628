<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.commission.commission-turnover-report.commission-turnover-report', 1)" :to="{ name: 'commission-turnover-report' }" />
            </template>

            <h1>{{ $tc('common.commission.commission-turnover-report.commission-turnover-report', 1) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <commission-turnover-report-form
                    ref="commissionTurnoverReportForm"
                    @submit="handleSubmit"
                />
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { useI18n } from '@/composables/i18n'
import { useQuasar } from '@/composables/quasar'
import { CommissionTurnoverReportService } from '@/services/commissionTurnoverReport'
import { ref } from 'vue'
import CommissionTurnoverReportForm from '@/components/commission/CommissionTurnoverReportForm'

export default {
    name: 'CommissionTurnoverReportCreate',
    components: {
        CommissionTurnoverReportForm,
    },
    setup () {
        // Composables
        const { t, tc } = useI18n()
        const $q = useQuasar()

        // Template refs
        const commissionTurnoverReportForm = ref(null)

        // Functions
        function handleSubmit ({ formData, consultingEntityIds }) {
            CommissionTurnoverReportService.createBulk({
                consultingEntityIds,
                dateFrom: formData.dateFrom,
                dateUntil: formData.dateUntil,
            }).then(() => {
                commissionTurnoverReportForm.value.resetSelectedItems()

                $q.notify({
                    type: 'positive',
                    message: tc('common.notifications.commission.commission-turnover-report-creation-started-success', consultingEntityIds.length || 1),
                    caption: t('common.notifications.commission.commission-turnover-report-email-notification-hint'),
                })
            }).catch(error => {
                commissionTurnoverReportForm.value.formComponent.formBuilder.showFormErrorMessage(error)
                commissionTurnoverReportForm.value.formComponent.formBuilder.resetFormSubmitStatus()
            })
        }

        return {
            // Template refs
            commissionTurnoverReportForm,

            // Functions
            handleSubmit,
        }
    },
}
</script>
