<template>
    <base-select-filter
        v-if="state.options.length"

        :value="value"
        v-bind="$attrs"
        :options="state.options"

        option-value="id"
        option-label="name"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { ContactService } from '@/services'
import { onBeforeMount, reactive, watch } from 'vue'

export default {
    name: 'ContactPhoneNumberCategorySelect',
    components: {
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        preSelectKey: {
            type: String,
        },
    },
    setup (props, context) {
        // Data
        const state = reactive({
            options: [],
        })

        // Methods
        async function fetchOptions () {
            const phoneNumberCategories = await ContactService.getPhoneNumberCategories()
            state.options.length = 0
            state.options.push(...phoneNumberCategories)
        }
        async function fetchInitialOptions () {
            await fetchOptions()

            // Set initial value
            if (props.preSelectKey && props.value === null) {
                const category = state.options.find(option => option.key === props.preSelectKey)
                if (category) context.emit('input', category.id)
            }
        }

        // Lifecycle hooks
        onBeforeMount(() => {
            fetchInitialOptions()
        })

        return {
            state,
        }
    },
}
</script>
