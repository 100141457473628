import gql from 'graphql-tag'

export const POSTING_TYPE_DELETE_MUTATION = gql`
    mutation postingTypeDelete(
        $id: ID!
    ) {
        postingTypeDelete(
            id: $id
        ) {
            status
        }
    }
`
