<template>
    <q-select
        :value="$i18n.locale"
        :options="options"
        emit-value
        map-options
        square
        borderless
        dense
        @input="onInput"
    />
</template>

<script>
import { availableLanguages, loadLocaleAsync } from '@/i18n'

export default {
    name: 'LocaleSwitch',
    data () {
        return {
            options: availableLanguages,
        }
    },
    methods: {
        async onInput (locale) {
            await loadLocaleAsync(locale)
        },
    },
}
</script>

<style lang="scss" scoped>
// Coloring of the options and the dropdown arrow
::v-deep span,
::v-deep i {
    color: var(--color-text-inverted);
}
</style>
