import { CorrespondenceItemTargetObjectType } from '@/enums/graphql'

const correspondenceItemTargetObjectMixin = {
    creatorId: null,
    attentionOf: '',
    addressOverrideId: null,
    emailAddressOverrideId: null,
    senderOverrideId: null,

    setAddressOverride (address) {
        this.addressOverrideId = (address.is_main_correspondence_address) ? null : address.id
    },

    get isDefaultAddress () {
        return (this.addressOverrideId === null)
    },

    get recipient_address () {
        // Check if there is an address override for this recipient.
        if (this.addressOverrideId) return this.recipient.addresses.find(address => address.id === this.addressOverrideId)

        // Else use `recipient.mainAddress`.
        return this.recipient.mainAddress
    },

    setEmailAddressOverride (emailAddress) {
        this.emailAddressOverrideId = (emailAddress.is_main_email_address) ? null : emailAddress.id
    },

    get isDefaultEmailAddress () {
        return (this.emailAddressOverrideId === null)
    },

    get recipient_email_address () {
        // Check if there is an emailAddress override for this recipient.
        if (this.emailAddressOverrideId) return this.recipient.emailAddresses.find(emailAddress => emailAddress.id === this.emailAddressOverrideId)

        // Else use `recipient.mainEmailAddress`.
        return this.recipient.mainEmailAddress
    },

    setSenderOverride (consultant) {
        const defaultSender = this.getSender({ ignoreOverride: true })
        this.senderOverrideId = (defaultSender.id === consultant.id) ? null : consultant.id
    },

    get isDefaultSender () {
        return (this.senderOverrideId === null)
    },

    /**
     * Determines the `Consultant` that acts as sender for this targetObject.
     */
    getSender ({
        ignoreOverride = false,
    } = {
        ignoreOverride: false,
    }) {
        // Check if there is a sender override for this recipient.
        if (!ignoreOverride && this.senderOverrideId) return this.allConsultants.find(consultant => consultant.id === this.senderOverrideId)

        // Else use the `creator` if he is in the consultants list of the target object.
        const creatorConsultant = this.allConsultants.find(consultant => consultant.id === this.creatorId)
        if (creatorConsultant) return creatorConsultant

        // Else use the first consultant out of the consultants list of the target object that the `Creator` has access to.
        return this.allConsultants[0]
    },

    /**
     * Alias for getSender() call with default params.
     */
    get sender () {
        return this.getSender()
    },
}

const correspondenceItemTargetObjectContactMixin = {}
Object.defineProperties(correspondenceItemTargetObjectContactMixin, Object.getOwnPropertyDescriptors(correspondenceItemTargetObjectMixin))
Object.defineProperties(correspondenceItemTargetObjectContactMixin, Object.getOwnPropertyDescriptors({
    _mixinName: 'correspondenceItemTargetObjectContactMixin',

    get recipient () {
        return this
    },

    get allConsultants () {
        return this.recipient.consultants
    },
}))
export { correspondenceItemTargetObjectContactMixin }

const correspondenceItemTargetObjectContractMixin = {}
Object.defineProperties(correspondenceItemTargetObjectContractMixin, Object.getOwnPropertyDescriptors(correspondenceItemTargetObjectMixin))
Object.defineProperties(correspondenceItemTargetObjectContractMixin, Object.getOwnPropertyDescriptors({
    _mixinName: 'correspondenceItemTargetObjectContractMixin',
    type: CorrespondenceItemTargetObjectType.CONTRACT,

    get recipient () {
        return this.customer
    },

    get allConsultants () {
        return [this.consultant]
    },
}))
export { correspondenceItemTargetObjectContractMixin }

const correspondenceItemTargetObjectApplicationMixin = {}
Object.defineProperties(correspondenceItemTargetObjectApplicationMixin, Object.getOwnPropertyDescriptors(correspondenceItemTargetObjectMixin))
Object.defineProperties(correspondenceItemTargetObjectApplicationMixin, Object.getOwnPropertyDescriptors({
    _mixinName: 'correspondenceItemTargetObjectApplicationMixin',
    type: CorrespondenceItemTargetObjectType.APPLICATION,

    get recipient () {
        return this.customer
    },

    get allConsultants () {
        return [this.consultant]
    },
}))
export { correspondenceItemTargetObjectApplicationMixin }
