import gql from 'graphql-tag'

export const PRODUCT_QUERY = gql`
    query product(
        $id: ID!
    ) {
        product(
            id: $id
        ) {
            id
            name
            status
            template {
                id
                name
                status
                category {
                    name
                }
                subCategory {
                    name
                }
                version
                form_configuration
            }
            basicProvider {
                id
                name
                display_name
            }
        }
    }
`
