import { i18n } from '@/i18n'

import DefaultLayout from '@/layouts/Default.vue'
import ContactList from '@/views/contact/ContactList.vue'
import ContactCreate from '@/views/contact/ContactCreate.vue'
import ContactDetail from '@/views/contact/ContactDetail.vue'
import ContactSettings from '@/views/contact/ContactSettings.vue'
import DocumentLayoutList from '@/components/documentLayout/DocumentLayoutList'
import DocumentLayoutCreate from '@/views/documentLayout/DocumentLayoutCreate'
import DocumentLayoutUpdate from '@/views/documentLayout/DocumentLayoutUpdate'
import ContactConsultantRelationSettingsUpdate from '@/views/contact/ContactConsultantRelationSettingsUpdate'
import ContactConsultingSettingsCreate from '@/views/contact/ContactConsultingSettingsCreate'
import ContactConsultingSettingsUpdate from '@/views/contact/ContactConsultingSettingsUpdate'
import ConsultingSettingsListView from '@/views/admin/ConsultingSettingsListView.vue'
import { checkRouteAccessPermissions } from '@/helpers/acl'

export default [
    {
        path: '/contacts/:tab(archived)?',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'contact-list',
            component: ContactList,
            props: true,
            meta: {
                navGroup: 'contacts',
            },
        }],
    },
    {
        path: '/contacts/create/:type(person|company)',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'contact-create',
            component: ContactCreate,
            props: true,
            meta: {
                can: 'create Contact',
                fail: { name: 'contact-list' },
                navGroup: 'contacts',
            },
        }],
    },
    {
        path: '/contacts/:id(\\d+)/:tab(documents|addresses|comments|change-log|relationships|areas-of-responsibility)?',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'contact-detail',
            component: ContactDetail,
            props: true,
            meta: {
                navGroup: 'contacts',
            },
        }],
    },
    {
        path: '/contacts/:id(\\d+)/settings',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'contact-settings',
            component: ContactSettings,
            props: true,
            meta: {
                navGroup: 'contacts',
            },
            redirect: { name: 'contact-settings-contact-customer-relation' },
            children: [
                {
                    path: 'customer-relation',
                    name: 'contact-settings-contact-customer-relation',
                    component: ContactConsultantRelationSettingsUpdate,
                    props: route => { return { contactId: route.params.id } },
                    meta: {
                        navGroup: 'contacts',
                        title: i18n.tc('views.contact.settings.customer-relation.customer-relation--title', 1),
                    },
                },
                {
                    path: 'document-layouts',
                    name: 'contact-settings-document-layout-list',
                    component: DocumentLayoutList,
                    props: route => { return { ownerId: route.params.id } },
                    meta: {
                        can: 'Feature:correspondence:core',
                        fail: function (to, from) {
                            return { name: '403' }
                        },
                        navGroup: 'contacts',
                        title: i18n.tc('common.document-layout.document-layout', 2),
                    },
                },
                {
                    path: 'document-layouts/create',
                    name: 'contact-settings-document-layout-create',
                    component: DocumentLayoutCreate,
                    props: route => { return { ownerId: route.params.id } },
                    meta: {
                        can: 'Feature:correspondence:core',
                        fail: function (to, from) {
                            return { name: '403' }
                        },
                        navGroup: 'contacts',
                        title: i18n.tc('common.document-layout.document-layout', 2),
                    },
                },
                {
                    path: 'document-layouts/:documentLayoutId(\\d+)',
                    name: 'contact-settings-document-layout-update',
                    component: DocumentLayoutUpdate,
                    props: route => { return { ownerId: route.params.id, documentLayoutId: route.params.documentLayoutId } },
                    meta: {
                        can: 'Feature:correspondence:core',
                        fail: function (to, from) {
                            return { name: '403' }
                        },
                        navGroup: 'contacts',
                        title: i18n.tc('common.document-layout.document-layout', 2),
                    },
                },
                {
                    path: 'consulting-settings/create',
                    name: 'contact-settings-consulting-settings-create',
                    component: ContactConsultingSettingsCreate,
                    meta: {
                        accessPermissions: ['ConsultingSettings:create', 'ConsultingSettings:read'],
                        can: checkRouteAccessPermissions,
                        fail: function (to, from) {
                            return { name: '403' }
                        },
                        navGroup: 'contacts',
                        title: i18n.tc('common.contact.consulting-setting', 2),
                    },
                },
                {
                    path: 'consulting-settings/update',
                    name: 'contact-settings-consulting-settings-update',
                    component: ContactConsultingSettingsUpdate,
                    meta: {
                        accessPermissions: ['ConsultingSettings:create', 'ConsultingSettings:read'],
                        can: checkRouteAccessPermissions,
                        fail: function (to, from) {
                            return { name: '403' }
                        },
                        navGroup: 'contacts',
                        title: i18n.tc('common.contact.consulting-setting', 2),
                    },
                },
            ],
        }],
    },

    // System preferences
    {
        path: '/admin/consulting-settings/:viewMode(list|tree)?/:tab(active|inactive)?',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'admin-consulting-settings-list',
            component: ConsultingSettingsListView,
            props: true,
            meta: {
                accessPermissions: ['ConsultingSettings:create', 'ConsultingSettings:read'],
                can: checkRouteAccessPermissions,
                appSubSection: 'admin',
                navGroup: 'consultingSettings',
            },
        }],
    },
]
