<template>
    <page-wrapper>
        <q-breadcrumbs class="q-mt-none">
            <q-breadcrumbs-el icon="mib-shield-warning" :label="$tc('common.term.error', 1)" />
            <q-breadcrumbs-el :label="$t('views.error.forbidden.title')" />
        </q-breadcrumbs>

        <div class="text-center">
            <illustration-heading :title="$t('views.error.forbidden.title')" :subtitle="`${this.$tc('common.term.error')} 403`" img="monitor-access-denied" />

            <p>{{ $t('views.error.forbidden.text') }}</p>

            <i18n path="views.error.general.text-instructions" tag="p">
                <template v-slot:backlink>
                    <a @click="$router.go(-1)">{{ $t('views.error.general.text-instructions-back-link') }}</a>
                </template>
            </i18n>
        </div>
    </page-wrapper>
</template>

<script>
export default {
    name: 'ErrorForbidden',
    meta () {
        return {
            title: this.$t('views.error.forbidden.title'),
        }
    },
}
</script>
