import gql from 'graphql-tag'

export const ACCOUNT_BALANCE_REPORTS_DELETE_MUTATION = gql`
    mutation accountBalanceReportsDelete(
        $account_balance_report_ids: [ID!]!
    ) {
        accountBalanceReportsDelete(
            account_balance_report_ids: $account_balance_report_ids
        ) {
            status
        }
    }
`
