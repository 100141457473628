import { apolloClient } from '@/apollo/client'

import { ECO_HUB_TECH_USER_QUERY } from '@/graphql/ecoHubTechUser/ecoHubTechUser'
import { ECO_HUB_TECH_USER_CREATE_MUTATION } from '@/graphql/ecoHubTechUser/ecoHubTechUserCreate'
import { ECO_HUB_TECH_USER_UPDATE_MUTATION } from '@/graphql/ecoHubTechUser/ecoHubTechUserUpdate'
import { ECO_HUB_TECH_USER_DELETE_MUTATION } from '@/graphql/ecoHubTechUser/ecoHubTechUserDelete'

export const EcoHubTechUserService = {
    get () {
        return apolloClient.query({
            query: ECO_HUB_TECH_USER_QUERY,
        }).then(response => response.data.ecoHubTechUser)
    },
    create (techUserVariables) {
        return apolloClient.mutate({
            mutation: ECO_HUB_TECH_USER_CREATE_MUTATION,
            variables: {
                techUser: techUserVariables,
            },
        }).then(response => response.data.ecoHubTechUserCreate)
    },
    update (techUserVariables) {
        return apolloClient.mutate({
            mutation: ECO_HUB_TECH_USER_UPDATE_MUTATION,
            variables: {
                techUser: techUserVariables,
            },
        }).then(response => response.data.ecoHubTechUserUpdate)
    },
    delete () {
        return apolloClient.mutate({
            mutation: ECO_HUB_TECH_USER_DELETE_MUTATION,
        }).then(response => response.data.ecoHubTechUserDelete)
    },
}
