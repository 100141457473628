<template>
    <q-page>
        <div class="row q-col-gutter-md q-pa-lg justify-center">
            <div class="q-pt-xl col-xs-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 self-center">
                <h1 class="text-center">{{ $t('views.auth.confirm-email.title') }}</h1>

                <div v-show="state.currentMode === 'verification'">
                    <illustration name="max-envelope-configure" />
                    <info-box>
                        <p><q-spinner class="q-mr-sm" color="info" /> {{ $t(`views.auth.confirm-email.verification--info`) }}</p>
                    </info-box>
                </div>

                <div v-show="state.currentMode === 'confirmed'">
                    <illustration name="max-envelope-check" />
                    <info-box type="positive">
                        <p><b>{{ $t(`views.auth.confirm-email.confirmed--summary`) }}</b></p>
                        <p>{{ $t(`views.auth.confirm-email.confirmed--info`) }}</p>

                        <template v-slot:action>
                            <!-- Logged-in -->
                            <base-button
                                v-if="$store.state.user"
                                :label="$t('views.auth.confirm-email.link--dashboard')"
                                :to="{ name: 'dashboard' }"
                            />
                            <!-- Not logged-in-->
                            <base-button
                                v-else
                                :label="$t('views.auth.confirm-email.link--login')"
                                :to="{ name: 'auth-login' }"
                            />
                        </template>
                    </info-box>
                </div>

                <div v-show="state.currentMode === 'error'">
                    <illustration name="max-envelope-deny" error />
                    <info-box type="negative">
                        <p><b>{{ $t(`views.auth.confirm-email.error--summary`) }}</b></p>
                        <p>{{ $t(`views.auth.confirm-email.error--info`) }}</p>
                    </info-box>
                </div>
            </div>
        </div>
    </q-page>
</template>

<script>
import { UserService } from '@/services'
import { onMounted, reactive } from 'vue'

export default {
    name: 'AuthConfirmEmail',
    props: {
        token: {
            type: String,
            required: true,
        },
    },
    meta () {
        return {
            title: this.$t('views.auth.confirm-email.title'),
        }
    },
    setup (props) {
        // State
        const state = reactive({
            currentMode: 'verification',
        })

        // Hooks
        onMounted(() => {
            setTimeout(() => {
                UserService.confirmEmail(props.token)
                    .then(() => {
                        state.currentMode = 'confirmed'
                    })
                    .catch(error => {
                        state.currentMode = 'error'
                    })
            }, 1500)
        })

        return {
            state,
        }
    },
}
</script>
