<template>
    <base-button
        :label="$tc('common.correspondence.compose-correspondence', 1)"
        icon="mib-read-email-letter"
        flat
        data-test="btn:create-correspondence-item"
    >
        <q-menu anchor="top end" self="top start">
            <q-list dense>
                <q-item
                    clickable
                    data-test="btn:create-letter"
                >
                    <q-item-section>
                        <q-item-label>{{ $tc('common.correspondence.letter.write-letter', 1) }}</q-item-label>
                    </q-item-section>
                    <q-menu anchor="top end" self="top start">
                        <q-list dense>
                            <q-item
                                :to="{
                                    name: 'correspondence-letter-create',
                                    params: {
                                        correspondenceItemTargetObjectType: correspondenceItemTargetObjectType,
                                        correspondenceItemTargetObjectId: correspondenceItemTargetObjectId,
                                        correspondenceItemInitialDataSource: CorrespondenceItemInitialDataSource.TEMPLATE
                                    }
                                }"
                                data-test="link:create-template"
                            >
                                <q-item-section>
                                    <q-item-label>{{ $tc('common.correspondence.document-template.choose-template', 1) }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item
                                :to="{
                                    name: 'correspondence-letter-create',
                                    params: {
                                        correspondenceItemTargetObjectType: correspondenceItemTargetObjectType,
                                        correspondenceItemTargetObjectId: correspondenceItemTargetObjectId,
                                        correspondenceItemInitialDataSource: CorrespondenceItemInitialDataSource.BLANK
                                    }
                                }"
                                data-test="link:create-blank"
                            >
                                <q-item-section>
                                    <q-item-label>{{ $tc('common.correspondence.letter.write-letter--blank', 1) }}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </q-menu>
                    <q-item-submenu-indicator />
                </q-item>
                <q-item
                    clickable
                    data-test="btn:create-email"
                    :disable="disableEmail"
                >
                    <q-item-section>
                        <q-item-label>{{ $tc('common.correspondence.email.write-email', 1) }}</q-item-label>
                    </q-item-section>
                    <q-tooltip
                        v-if="disableEmail"
                        :delay="1000"
                        :offset="[0, 10]"
                        anchor="center right"
                        self="center left"
                    >
                        {{ $tc('common.email-address.no-email-address-on-record', 1) }}
                    </q-tooltip>
                    <q-menu
                        v-else
                        anchor="top end"
                        self="top start"
                    >
                        <q-list dense>
                            <q-item
                                :to="{
                                    name: 'correspondence-email-create',
                                    params: {
                                        correspondenceItemTargetObjectType: correspondenceItemTargetObjectType,
                                        correspondenceItemTargetObjectId: correspondenceItemTargetObjectId,
                                        correspondenceItemInitialDataSource: CorrespondenceItemInitialDataSource.TEMPLATE
                                    }
                                }"
                                data-test="link:create-template"
                            >
                                <q-item-section>
                                    <q-item-label>{{ $tc('common.correspondence.document-template.choose-template', 1) }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item
                                :to="{
                                    name: 'correspondence-email-create',
                                    params: {
                                        correspondenceItemTargetObjectType: correspondenceItemTargetObjectType,
                                        correspondenceItemTargetObjectId: correspondenceItemTargetObjectId,
                                        correspondenceItemInitialDataSource: CorrespondenceItemInitialDataSource.BLANK
                                    }
                                }"
                                data-test="link:create-blank"
                            >
                                <q-item-section>
                                    <q-item-label>{{ $tc('common.correspondence.email.write-email--blank', 1) }}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </q-menu>
                    <q-item-submenu-indicator />
                </q-item>
            </q-list>
        </q-menu>
    </base-button>
</template>

<script>
import { CorrespondenceItemInitialDataSource } from '@/enums'
import QItemSubmenuIndicator from '@/components/QItemSubmenuIndicator'

export default {
    name: 'CorrespondenceItemCreateButton',
    components: { QItemSubmenuIndicator },
    props: {
        correspondenceItemTargetObjectType: {
            type: String,
            default: null,
        },
        correspondenceItemTargetObjectId: {
            type: String,
            default: null,
        },
        disableEmail: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            CorrespondenceItemInitialDataSource,
            QItemSubmenuIndicator,
        }
    },
}
</script>
