<template>
    <form
        data-test="form:commission-type-accounting-configuration"
        @submit.prevent="onFormSubmit"
    >
        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="formErrorMessage"
            type="negative"
            data-test="error:global-message"
            v-html="formErrorMessage"
        />
        <!-- eslint-enable -->

        <div class="row q-col-gutter-md">
            <div class="col-xs-12">
                <form-builder item-key="postingTextPart" />
            </div>
            <div class="col-xs-12 col-md-6">
                <form-builder
                    item-key="commissionIncomeAccountId"
                    :filter="{
                        filterIsAssigned: false,
                        filterType: [AccountType.REVENUE]
                    }"
                    :inject-options="injectOptions('commissionIncomeAccount')"
                />
            </div>
            <div class="col-xs-12 col-md-6">
                <form-builder
                    item-key="commissionExpenseAccountId"
                    :filter="{
                        filterIsAssigned: false,
                        filterType: [AccountType.EXPENSES]
                    }"
                    :inject-options="injectOptions('commissionExpenseAccount')"
                />
            </div>
        </div>

        <in-page-footer>
            <!-- TODO: Create BaseButtonCancel component -->
            <base-button
                :label="$t('common.term.cancel')"
                primary-button
                flat
                @click="cancel"
            />
            <!-- TODO: Create BaseButtonSubmit component -->
            <base-button
                type="submit"
                data-test="btn:submit"
                :label="$t('common.term.save')"
                primary-button
            />
        </in-page-footer>
    </form>
</template>

<script>
import { ViewMode } from '@/enums'
import { AccountType } from '@/enums/graphql'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import COMMISSION_TYPE_ACCOUNTING_CONFIGURATION_FORM from '@/forms/commissionTypeAccountingConfiguration.form'

export default {
    name: 'CommissionTypeAccountingConfigurationForm',
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: COMMISSION_TYPE_ACCOUNTING_CONFIGURATION_FORM,
    },
    props: {
        commissionTypeAccountingConfiguration: {
            type: Object,
            required: false,
            default () {
                return undefined
            },
        },
    },
    data () {
        const data = {
            ViewMode,
            currentMode: this.commissionTypeAccountingConfiguration ? ViewMode.UPDATE : ViewMode.CREATE,
            formData: {},
            AccountType,
        }

        if (data.currentMode === ViewMode.UPDATE) {
            Object.assign(data.formData, {
                postingTextPart: this.commissionTypeAccountingConfiguration.posting_text_part,
                commissionExpenseAccountId: this.commissionTypeAccountingConfiguration.commissionExpenseAccount?.id,
                commissionIncomeAccountId: this.commissionTypeAccountingConfiguration.commissionIncomeAccount?.id,
            })
        }

        return data
    },
    methods: {
        injectOptions (key) {
            return this.commissionTypeAccountingConfiguration && this.commissionTypeAccountingConfiguration[key]
                ? [this.commissionTypeAccountingConfiguration[key]]
                : []
        },
        cancel () {
            this.$router.push({ name: 'commission-type-accounting-configuration-list' })
        },
    },
}
</script>
