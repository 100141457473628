import { Model } from '@/models'

import { CancellationService } from '@/services'
import { CancellationStatus } from '@/enums/graphql'
import { CancellationActions } from '@/enums'
import { formatDate } from '@/helpers/date'
import { parseISO, isPast } from 'date-fns'

export class Cancellation extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'status',
            'contract',
            'cancellation_date',

            // Timestamps
            'created_at',
            'updated_at',
        ], data)

        this.statusTransitions[CancellationStatus.PENDING] = [CancellationStatus.APPROVED, CancellationStatus.DECLINED, CancellationStatus.WITHDRAWN]
        this.statusTransitions[CancellationStatus.APPROVED] = [CancellationStatus.WITHDRAWN]

        this.statusActionMapping[CancellationStatus.APPROVED] = CancellationActions.APPROVE
        this.statusActionMapping[CancellationStatus.DECLINED] = CancellationActions.DECLINE
        this.statusActionMapping[CancellationStatus.WITHDRAWN] = CancellationActions.WITHDRAW
    }

    static get service () { return CancellationService }
    static status = CancellationStatus
    static action = CancellationActions

    get formattedCancellationDate () {
        return formatDate(this.cancellation_date)
    }

    get hasPastCancellationDate () {
        return isPast(parseISO(this.cancellation_date))
    }

    static create (contractId, cancellationDate) {
        return CancellationService.create(contractId, cancellationDate)
    }

    update (cancellationDate) {
        return CancellationService.update(this.id, cancellationDate).then(cancellation => {
            Object.assign(this, cancellation)
            return cancellation
        })
    }

    get actions () {
        const actions = super.actions
        if (this.status === CancellationStatus.APPROVED && this.hasPastCancellationDate) {
            const index = actions.findIndex(action => action.key === CancellationActions.WITHDRAW)
            if (index !== -1) actions.splice(index, 1)
        }
        return actions
    }

    approve (cancellationDate) {
        return CancellationService.changeStatus(this.id, CancellationStatus.APPROVED, cancellationDate)
    }

    decline () {
        return CancellationService.changeStatus(this.id, CancellationStatus.DECLINED)
    }

    withdraw () {
        return CancellationService.changeStatus(this.id, CancellationStatus.WITHDRAWN)
    }
}
