import gql from 'graphql-tag'

export const CONTACT_CONSULTING_SETTINGS_UPDATE_MUTATION = gql`
    mutation contactConsultingSettingsUpdate(
        $contact_id: ID!
        $consultingSettings: ConsultingSettingsInput!
    ) {
        contactConsultingSettingsUpdate(
            contact_id: $contact_id
            consultingSettings: $consultingSettings
        ) {
            contact_id
            status
            company {
                id
                company_name
            }
            defaultDocumentLayout {
                id
                is_default
            }
            signature {
                id
                name
                size
                mime_type
                created_at
                updated_at
            }
            currentAccount {
                id
                type
                status
                number
                name
            }
            cancellationReserveAccount {
                id
                type
                status
                number
                name
            },
            otherIncomeAccount {
                id
                type
                status
                number
                name
            },
            commissionIncomeAccount {
                id
                type
                status
                number
                name
            },
            mailProcessingRevenueAccount {
                id
                type
                status
                number
                name
            }
            statementRecipients {
                id
                consultants {
                    id
                }
            }
            commissionDistributionRuleSet {
                id
                name
                description
                is_assigned
            }
            mailProcessingSettings {
                active_from
                active_until
                mail_processing_is_active
                price_per_processed_mail_item
                notification_email_override
                error_notification_email
            }
        }
    }
`
