<template>
    <fake-select
        v-if="!options"
        v-bind="$attrs"
    />
    <base-select-filter
        v-else
        :value="value"
        v-bind="$attrs"
        :options="options"

        option-value="id"
        :option-label="getOptionLabel"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import { AccountStatus } from '@/enums/graphql'
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { AccountService } from '@/services'
import FakeSelect from '@/components/FakeSelect.vue'
import { fetchPaginatedObjects } from '@/helpers'
import { Contact } from '@/models/contact'

export default {
    name: 'AccountSelect',
    components: {
        FakeSelect,
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        filter: {
            type: Object,
        },
        injectOptions: {
            type: Array,
            default () {
                return []
            },
        },
    },
    data () {
        return {
            options: null,
        }
    },
    created () {
        const filters = {}

        if (this.filter) {
            Object.assign(filters, {
                filterStatus: [AccountStatus.ACTIVE],
                ...this.filter,
            })
        }

        fetchPaginatedObjects({ fn: AccountService.all }, filters, `
            id
            number
            name
        `).then(responseOptions => {
            let injectedOptions = []

            this.injectOptions.filter(Boolean).forEach(injectOption => {
                const index = responseOptions.findIndex(option => option.id === injectOption.id)
                if (index === -1) injectedOptions.push(injectOption)
            })

            const options = [...responseOptions, ...injectedOptions]

            options.sort((a, b) => {
                return a.number < b.number
            })

            this.options = options
        })
    },
    methods: {
        getOptionLabel (item) {
            return `${item.number} – ${item.name}`
        },
    },
}
</script>
