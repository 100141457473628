<template>
    <q-card
        class="product-template-builder--section"
        :class="{ remove: item.removeFlag, error: $v.$anyError }"
        square
        flat
    >
        <q-item class="item-header draggable-handle" dense>
            <q-item-section avatar class="drag-icon-wrapper do-not-print">
                <q-icon
                    class="drag-icon"
                    name="mib-cursor-select"
                    size="xs"
                    color="secondary"
                />
            </q-item-section>

            <q-item-section @dblclick="editSectionDetails = !editSectionDetails">
                <q-item-label class="ellipsis" :class="{ 'missing-label': !item.label[currentLocale] }">{{ (item.label[currentLocale]) ? item.label[currentLocale] : $tc('forms.new-section', 1) }}</q-item-label>
                <q-item-label v-if="!item.label[currentLocale]" caption class="missing-label ellipsis">{{ $t('forms.missing-label-text') }}</q-item-label>
            </q-item-section>

            <q-item-section side class="item-header--actions do-not-print">
                <base-button
                    data-test="btn:product-template-builder--section-edit"
                    :icon="(!editSectionDetails) ? 'mib-pencil' : 'mib-check'"
                    size="xs"
                    color="secondary"
                    round
                    flat
                    @click="editSectionDetails = !editSectionDetails"
                />

                <slot name="header" />
            </q-item-section>
        </q-item>

        <q-card-section horizontal>
            <div class="inner-wrapper row q-col-gutter-sm">
                <q-expansion-item
                    ref="sectionDetails"
                    expand-icon-toggle
                    :value="editSectionDetails"
                    class="section-details col-xs-12"
                    header-class="section-details-header"
                >
                    <div class="row q-col-gutter-sm">
                        <div v-for="locale in availableLocales" :key="locale" class="col-xs-12 col-sm-6">
                            <form-builder :item-key="locale" />
                        </div>
                    </div>
                    <div class="col-xs-12">
                        <form-builder item-key="isCollapsible" />
                    </div>
                </q-expansion-item>

                <div class="col-xs-12">
                    <slot />
                </div>
            </div>
        </q-card-section>
    </q-card>
</template>

<script>
import { formBuilderMixin } from '@/mixins/formBuilderMixin'

const schema = {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'isCollapsible',
            label: 'forms.collapsible-section',
            inputType: 'checkbox',
        },
    ],
}

const availableLocales = ['de-CH', 'en'] // TODO: get from i18n.js
availableLocales.forEach(locale => {
    schema.children.push({
        type: 'field',
        key: locale,
        label: {
            key: 'common.term.label-locale',
            values: { locale: locale },
        },
        inputType: 'text',
        validators: [
            { type: 'required' },
        ],
    })
})

export default {
    name: 'ProductTemplateSection',
    mixins: [formBuilderMixin],
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    formBuilderSettings: {
        schema: schema,
    },
    data () {
        const data = {
            currentLocale: localStorage.getItem('locale'),
            availableLocales,
            formData: {},
            editSectionDetails: !!this.item.newlyInserted,
        }
        availableLocales.forEach(locale => {
            if (this.item.label[locale]) data.formData[locale] = this.item.label[locale]
        })
        data.formData.isCollapsible = this.item.is_collapsible
        return data
    },
    watch: {
        'formData.isCollapsible' (isCollapsible) {
            this.item.is_collapsible = isCollapsible
        },
    },
    created () {
        this.availableLocales.forEach(locale => {
            this.$watch(() => this.formData[locale], (label) => {
                this.item.label[locale] = label
            })
        })
    },
    methods: {
        validate () {
            this.$v.$touch()
            const invalid = this.$v.$invalid
            if (invalid) this.editSectionDetails = true
            return !invalid
        },
    },
}
</script>
