export const ViewMode = Object.freeze({
    'LIST': 'LIST',
    'CREATE': 'CREATE',
    'DETAIL': 'DETAIL',
    'UPDATE': 'UPDATE',
    'DELETE': 'DELETE',
})

export const BulkItemWrapperStatus = Object.freeze({
    'QUEUED': 'QUEUED',
    'PROCESSING': 'PROCESSING',
    'DONE': 'DONE',
    'FAILED': 'FAILED',
    'ABORTED': 'ABORTED',
})

// General list of available TargetObjectTypes that is f.e. for the `Something.targetObjectType` getters.
export const TargetObjectType = Object.freeze({
    'CONTACT': 'CONTACT',
    'APPLICATION': 'APPLICATION',
    'CONTRACT': 'CONTRACT',
})

// TODO refactoring @TFU: Check if this can (and should) be replaced with BulkItemWrapperStatus
export const FileUploadWrapperStatus = Object.freeze({
    'QUEUED': 'QUEUED',
    'UPLOADING': 'UPLOADING',
    'DONE': 'DONE',
    'FAILED': 'FAILED',
    'ABORTED': 'ABORTED',
})

export const FileUploadTargetObjectType = Object.freeze({
    'CONTACT': 'CONTACT',
    'CONTRACT': 'CONTRACT',
    'CORRESPONDENCE_EMAIL_ATTACHMENT': 'CORRESPONDENCE-EMAIL-ATTACHMENT',
    'TASK': 'TASK',
    'POSTING': 'POSTING',
})

// TODO refactoring @TFU: Check if this can (and should) be replaced with BulkItemWrapperStatus
export const ContentStoreItemWrapperStatus = Object.freeze({
    'QUEUED': 'QUEUED',
    'IMPORTING': 'IMPORTING',
    'DONE': 'DONE',
    'FAILED': 'FAILED',
    'ABORTED': 'ABORTED',
})

export const ContentStoreViewMode = Object.freeze({
    'LIST': 'LIST',
    'IMPORT': 'IMPORT',
})

export const UserActions = Object.freeze({
    'RESENDINVITE': 'USER.RESEND-INVITE',
    'CHANGE_EMAIL': 'USER.CHANGE-EMAIL',
    'DEACTIVATE': 'USER.DEACTIVATE',
    'INVITE': 'USER.INVITE',
    'DELETE': 'USER.DELETE',
})

export const ConsultingSettingsActions = Object.freeze({
    'ACTIVATE': 'CONSULTING-SETTINGS.ACTIVATE',
    'DEACTIVATE': 'CONSULTING-SETTINGS.DEACTIVATE',
    'DELETE': 'CONSULTING-SETTINGS.DELETE',
})

export const ProductProviderSettingsActions = Object.freeze({
    'ACTIVATE': 'PRODUCT-PROVIDER-SETTINGS.ACTIVATE',
    'DEACTIVATE': 'PRODUCT-PROVIDER-SETTINGS.DEACTIVATE',
    'DELETE': 'PRODUCT-PROVIDER-SETTINGS.DELETE',
})

export const ProductTemplateActions = Object.freeze({
    'ARCHIVE': 'PRODUCT-TEMPLATE.ARCHIVE',
    'UNARCHIVE': 'PRODUCT-TEMPLATE.UNARCHIVE',
    'DELETE': 'PRODUCT-TEMPLATE.DELETE',
    'EXPORT': 'PRODUCT-TEMPLATE.EXPORT',
})

export const ProductActions = Object.freeze({
    'ARCHIVE': 'PRODUCT.ARCHIVE',
    'UNARCHIVE': 'PRODUCT.UNARCHIVE',
    'DELETE': 'PRODUCT.DELETE',
})

export const ContactActions = Object.freeze({
    'ARCHIVE': 'CONTACT.ARCHIVE',
    'UNARCHIVE': 'CONTACT.UNARCHIVE',
    'DELETE': 'CONTACT.DELETE',
    'EXPORT': 'CONTACT.EXPORT',
})

export const ApplicationActions = Object.freeze({
    'SUBMIT_TO_PRODUCT_PROVIDER': 'APPLICATION.SUBMIT-TO-PRODUCT-PROVIDER',
    'SET_APPROVED_BY_PRODUCT_PROVIDER': 'APPLICATION.SET-APPROVED-BY-PRODUCT-PROVIDER',
    'SET_DECLINED_BY_PRODUCT_PROVIDER': 'APPLICATION.SET-DECLINED-BY-PRODUCT-PROVIDER',
    'SUBMIT_COUNTER_PROPOSAL_TO_CUSTOMER': 'APPLICATION.SUBMIT-COUNTER-PROPOSAL-TO-CUSTOMER',
    'SET_COUNTER_PROPOSAL_DECLINED_BY_CUSTOMER': 'APPLICATION.SET-COUNTER-PROPOSAL-DECLINED-BY-CUSTOMER',
    'WITHDRAW': 'APPLICATION.WITHDRAW',
    'DELETE': 'APPLICATION.DELETE',
})

export const ApplicationDueDateStatus = Object.freeze({
    'OVERDUE': 'OVERDUE',
    'DUE_TODAY': 'DUE-TODAY',
    'UPCOMING': 'UPCOMING',
})

export const ContractActions = Object.freeze({
    'ACTIVATE': 'CONTRACT.ACTIVATE',
    'TERMINATE': 'CONTRACT.TERMINATE',
    'ARCHIVE': 'CONTRACT.ARCHIVE',
    'UNARCHIVE': 'CONTRACT.UNARCHIVE',
    'CANCEL': 'CONTRACT.CANCEL',
    'DELETE': 'CONTRACT.DELETE',
    'CREATE_CHANGE_APPLICATION': 'CONTRACT.CREATE-CHANGE-APPLICATION',
})

export const CancellationActions = Object.freeze({
    'APPROVE': 'CANCELLATION.APPROVE',
    'DECLINE': 'CANCELLATION.DECLINE',
    'WITHDRAW': 'CANCELLATION.WITHDRAW',
})

export const DocumentLayoutActions = Object.freeze({
    'SET_DEFAULT': 'DOCUMENT-LAYOUT.SET-DEFAULT',
    'DELETE': 'DOCUMENT-LAYOUT.DELETE',
})

export const DocumentTemplateActions = Object.freeze({
    'DELETE': 'DOCUMENT-TEMPLATE.DELETE',
})

export const DocumentTemplateOwnerType = Object.freeze({
    'CONSULTANT': 'CONSULTANT',
    'CONSULTING_COMPANY': 'CONSULTING-COMPANY',
})

export const CorrespondenceSalutationActions = Object.freeze({
    'SET_DEFAULT': 'CORRESPONDENCE-SALUTATION.SET-DEFAULT',
    'DELETE': 'CORRESPONDENCE-SALUTATION.DELETE',
})

export const CorrespondenceItemInitialDataSource = Object.freeze({
    'BLANK': 'BLANK',
    'TEMPLATE': 'TEMPLATE',
})

export const CorrespondenceLetterActions = Object.freeze({
    'DELETE': 'CORRESPONDENCE-LETTER.DELETE',
})

export const CorrespondenceEmailActions = Object.freeze({
    'DELETE': 'CORRESPONDENCE-EMAIL.DELETE',
})

export const EditorDisplayMode = Object.freeze({
    'FULL': 'FULL',
    'COMPACT': 'COMPACT',
    'MINIMAL': 'MINIMAL',
})

export const SmartObjectTreeDisplayMode = Object.freeze({
    'IN_PAGE': 'IN-PAGE',
    'NONE': 'NONE',
})

export const TaskActions = Object.freeze({
    'START_PROGRESS': 'TASK.START-PROGRESS',
    'FINISH': 'TASK.FINISH',
    'DELETE': 'TASK.DELETE',
})

export const TaskDueDateStatus = Object.freeze({
    'OVERDUE': 'OVERDUE',
    'DUE_TODAY': 'DUE-TODAY',
    'UPCOMING': 'UPCOMING',
    'FUTURE': 'FUTURE',
})

export const TaskDisplayStatus = Object.freeze({
    'DONE_IN_TIME': 'DONE-IN-TIME',
    'DONE_AFTER_DUE_DATE': 'DONE-AFTER-DUE-DATE',
})

export const TaskDueDateRangeFilterType = Object.freeze({
    'DEFAULT': 'DEFAULT',
    'DYNAMIC': 'DYNAMIC',
})

export const TaskDueDateRangeUnitOptions = Object.freeze({
    'DAY': 'DAY',
    'WEEK': 'WEEK',
    'MONTH': 'MONTH',
    'YEAR': 'YEAR',
})

export const TaskStatistics = Object.freeze({
    'STATUS': 'STATUS',
    'DUE_DATE_STATUS': 'DUE-DATE-STATUS',
})

export const TaskFilterMode = Object.freeze({
    'DEFAULT': 'DEFAULT',
    'QUERYSTRING': 'QUERYSTRING',
})

export const AccountActions = Object.freeze({
    'ACTIVATE': 'ACCOUNT.ACTIVATE',
    'DEACTIVATE': 'ACCOUNT.DEACTIVATE',
    'DELETE': 'ACCOUNT.DELETE',
})

export const PostingTypeActions = Object.freeze({
    'DELETE': 'POSTING-TYPE.DELETE',
})

export const PostingActions = Object.freeze({
    'DELETE': 'POSTING.DELETE',
})

export const CommissionListActions = Object.freeze({
    'START_PROCESSING': 'COMMISSION-LIST.START-PROCESSING',
    'CANCEL_PROCESSING': 'COMMISSION-LIST.CANCEL-PROCESSING',
    'DELETE': 'COMMISSION-LIST.DELETE',
    'START_POSTING': 'COMMISSION-LIST.START-POSTING',
    'CANCEL_POSTING': 'COMMISSION-LIST.CANCEL-POSTING',
})

export const CommissionListImportExcelMappingActions = Object.freeze({
    'DELETE': 'EXCEL-MAPPING.DELETE',
})

export const CommissionRateAdjustmentRuleSetActions = Object.freeze({
    'DELETE': 'COMMISSION-RATE-ADJUSTMENT-RULE-SET.DELETE',
})

export const CommissionDistributionRuleSetActions = Object.freeze({
    'DELETE': 'COMMISSION-DISTRIBUTION-RULE-SET.DELETE',
})

export const PostingTypeRounding = Object.freeze({
    '0.01': '0.01',
    '0.05': '0.05',
    '0.10': '0.10',
    '0.50': '0.50',
    '1.00': '1.00',
    '5.00': '5.00',
})

export const PostingDateType = Object.freeze({
    'INDIVIDUAL': 'INDIVIDUAL',
    'UNIVERSAL': 'UNIVERSAL',
})

export const CommissionListEntryNextStep = Object.freeze({
    'POST': 'POST',
    'CONFIRM_SELECTED_CONTRACT': 'CONFIRM-SELECTED-CONTRACT',
    'SELECT_CONTRACT_FROM_SHORTLIST': 'SELECT-CONTRACT-FROM-SHORTLIST',
    'SELECT_CONTRACT': 'SELECT-CONTRACT',
})

export const CommissionListEntryMatchPostStatus = Object.freeze({
    'READY': 'READY',
    'CONTRACT_MATCH__NAME_MATCH_PARTIAL': 'CONTRACT-MATCH--NAME-MATCH-PARTIAL',
    'CONTRACT_MATCH__NAME_MATCH_NONE': 'CONTRACT-MATCH--NAME-MATCH-NONE',
    'CONTRACT_SHORTLIST__NAME_MATCH_FULL': 'CONTRACT-SHORTLIST--NAME-MATCH-FULL',
    'CONTRACT_SHORTLIST__NAME_MATCH_PARTIAL': 'CONTRACT-SHORTLIST--NAME-MATCH-PARTIAL',
    'NO_MATCH': 'NO-MATCH',
})

export const CommissionListEntryActions = Object.freeze({
    'DELETE': 'COMMISSION-LIST-ENTRY.DELETE',
    'START_POSTING': 'COMMISSION-LIST-ENTRY.START-POSTING',
    'CANCEL_POSTING': 'COMMISSION-LIST-ENTRY.CANCEL-POSTING',
})

export const StatementActions = Object.freeze({
    'CANCEL_CREATION': 'STATEMENT.CANCEL-CREATION',
    'SHARE': 'STATEMENT.SHARE',
    'DELETE': 'STATEMENT.DELETE',
})

export const AccountBalanceReportActions = Object.freeze({
    'CANCEL_CREATION': 'ACCOUNT-BALANCE-REPORT.CANCEL-CREATION',
    'DELETE': 'ACCOUNT-BALANCE-REPORT.DELETE',
})

export const AccountBalanceReportAccountSelectionType = Object.freeze({
    'RANGE': 'RANGE',
    'INDIVIDUAL': 'INDIVIDUAL',
})

export const AccountStatementReportActions = Object.freeze({
    'CANCEL_CREATION': 'ACCOUNT-STATEMENT-REPORT.CANCEL-CREATION',
    'DELETE': 'ACCOUNT-STATEMENT-REPORT.DELETE',
})

export const FetchContractsSearchStatus = Object.freeze({
    'NO_CONTRACTS_LOADED': 'NO-CONTRACTS-LOADED',
    'NO_ADDITIONAL_CONTRACTS_LOADED': 'NO-ADDITIONAL-CONTRACTS-LOADED',
    'ADDITIONAL_CONTRACTS_LOADED': 'ADDITIONAL-CONTRACTS-LOADED',
})

export const InvalidContractRelationshipTypeForNotes = Object.freeze({
    'EMPLOYER__EMPLOYEE': 'EMPLOYER__EMPLOYEE',
    'ASSOCIATION__MEMBER': 'ASSOCIATION__MEMBER',
})

export const ValidContractRelationshipTypeForAreasOfResponsibility = Object.freeze({
    'EMPLOYER__EMPLOYEE': 'EMPLOYER__EMPLOYEE',
    'ASSOCIATION__MEMBER': 'ASSOCIATION__MEMBER',
})

export const EcoHubTechUserActions = Object.freeze({
    'RE_ENROLL': 'ECO-HUB-TECH-USER.RE-ENROLL',
    'DELETE': 'ECO-HUB-TECH-USER.DELETE',
})

export const PremiumInvoiceActions = Object.freeze({
    'UPDATE': 'PREMIUM-INVOICE.UPDATE',
    'DELETE': 'PREMIUM-INVOICE.DELETE',
})

export const PrinterStatus = Object.freeze({
    'ONLINE': 'ONLINE',
    'OFFLINE': 'OFFLINE',
})

export const MailProcessingSettingsStatus = Object.freeze({
    'INACTIVE': 'INACTIVE',
    'FUTURE': 'FUTURE',
    'ACTIVE': 'ACTIVE',
    'EXPIRED': 'EXPIRED',
})

export const MailProcessingTemplateActions = Object.freeze({
    'DELETE': 'MAIL-PROCESSING-TEMPLATE.DELETE',
})

export const PostingAccountSide = Object.freeze({
    'DEBIT': 'DEBIT',
    'CREDIT': 'CREDIT',
})
