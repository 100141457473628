import { Model } from '@/models'
import { CommissionListImportExcelMappingService } from '@/services'
import { CommissionListImportExcelMappingActions } from '@/enums'

export class CommissionListImportExcelMapping extends Model {
    /**
     * Create a CommissionListImportExcelMapping model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'productProvider',
            'name',
            'commission_entries_start_row',
            'contract_number_column',
            'commission_type_column',
            'commission_amount_column',
            'premium_from_column',
            'premium_from_date_format',
            'premium_to_column',
            'premium_to_date_format',
            'customer_full_name_column',
            'created_at',
            'updated_at',
        ], data)
    }

    static get service () { return CommissionListImportExcelMappingService }
    static action = CommissionListImportExcelMappingActions

    /**
     * Add custom actions.
     * @returns {Array}
     */
    get actions () {
        const actions = super.actions
        actions.push({
            key: CommissionListImportExcelMappingActions.DELETE,
            item: this,
        })
        return actions
    }

    /**
     * Create a new commissionListImportExcelMapping.
     * @param {ID} productProviderId
     * @param {CommissionListImportExcelMappingInput} commissionListImportExcelMappingInput
     * @returns {Promise<*>}
     */
    static create (productProviderId, commissionListImportExcelMappingInput) {
        return CommissionListImportExcelMappingService.create(productProviderId, commissionListImportExcelMappingInput)
    }

    /**
     * Update this commissionListImportExcelMapping.
     * @param {CommissionListImportExcelMappingInput} commissionListImportExcelMappingInput
     * @returns {Promise<*>}
     */
    update (commissionListImportExcelMappingInput) {
        return CommissionListImportExcelMappingService.update(this.id, commissionListImportExcelMappingInput).then(commissionListImportExcelMapping => {
            Object.assign(this, commissionListImportExcelMapping)
            return commissionListImportExcelMapping
        })
    }

    /**
     * Delete this commissionListImportExcelMapping.
     * @returns {Promise<*>}
     */
    delete () {
        return CommissionListImportExcelMappingService.delete(this.id)
    }
}
