import { apolloClient } from '@/apollo/client'

import { ACCOUNT_BALANCE_REPORT_QUERY } from '@/graphql/accountBalanceReport/accountBalanceReport'
import { ACCOUNT_BALANCE_REPORTS_QUERY } from '@/graphql/accountBalanceReport/accountBalanceReports'
import { ACCOUNT_BALANCE_REPORT_CREATE_MUTATION } from '@/graphql/accountBalanceReport/accountBalanceReportCreate'
import { ACCOUNT_BALANCE_REPORTS_CANCEL_CREATION_MUTATION } from '@/graphql/accountBalanceReport/accountBalanceReportsCancelCreation'
import { ACCOUNT_BALANCE_REPORTS_DELETE_MUTATION } from '@/graphql/accountBalanceReport/accountBalanceReportsDelete'

export const AccountBalanceReportService = {
    get (id) {
        return apolloClient.query({
            query: ACCOUNT_BALANCE_REPORT_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.accountBalanceReport)
    },
    all (variables) {
        return apolloClient.query({
            query: ACCOUNT_BALANCE_REPORTS_QUERY,
            variables,
        }).then(response => response.data.accountBalanceReports)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: ACCOUNT_BALANCE_REPORT_CREATE_MUTATION,
            variables,
        }).then(response => response.data.accountBalanceReportCreate)
    },
    cancelCreation (variables) {
        return apolloClient.mutate({
            mutation: ACCOUNT_BALANCE_REPORTS_CANCEL_CREATION_MUTATION,
            variables,
        }).then(response => response.data.accountBalanceReportsCancelCreation)
    },
    delete (variables) {
        return apolloClient.mutate({
            mutation: ACCOUNT_BALANCE_REPORTS_DELETE_MUTATION,
            variables,
        }).then(response => response.data.accountBalanceReportsDelete)
    },
}
