<template>
    <div
        class="contact-info--phone-numbers q-mt-md"
        data-test="wrapper:contact-phone-numbers"
    >
        <template v-if="items.length">
            <span
                v-for="(item, i) in items"
                :key="`email-${item.id}`"
                data-test="item:phone-number"
            >
                <br v-if="i > 0">
                <span class="q-mr-xs" data-test="text:phone">{{ item.phone_number }}</span>
                <contact-detail-label-badge
                    :label="item.formattedCategoryLabel"
                    :is-main="item.is_main_phone_number"
                    is-main-type="phone"
                />

                <base-button
                    v-if="store.state.editMode"
                    data-test="btn:update-phone-number"
                    unelevated
                    round
                    color="none"
                    text-color="edit-mode-link"
                    size="xs"
                    class="edit-link q-ml-xs"
                    icon="mib-pencil"
                    @click="triggerUpdateItemWrapper(item)"
                >
                    <q-tooltip
                        :delay="1000"
                        anchor="center right"
                        self="center left"
                        :offset="[10, 0]"
                        transition-show="scale"
                        transition-hide="scale"
                    >{{ $tc('common.phone-number.edit-phone-number', 1) }}</q-tooltip>
                </base-button>

                <base-button
                    v-if="store.state.editMode"
                    data-test="btn:delete-phone-number"
                    unelevated
                    round
                    color="none"
                    text-color="edit-mode-link"
                    size="xs"
                    class="edit-link"
                    icon="mib-bin"
                    @click="triggerDeleteItem(item)"
                >
                    <q-tooltip
                        :delay="1000"
                        anchor="center right"
                        self="center left"
                        :offset="[10, 0]"
                        transition-show="scale"
                        transition-hide="scale"
                    >{{ $tc('common.phone-number.delete-phone-number', 1) }}</q-tooltip>
                </base-button>
            </span>
        </template>
        <span v-else class="additional-info">{{ $tc('common.phone-number.no-phone-number-on-record', 1) }}</span>
        <template v-if="store.state.editMode">
            <br>
            <a
                data-test="link:create-phone-number"
                class="edit-link q-link q-mr-sm"
                tabindex="0"
                @click="triggerCreateItemWrapper"
                @keyup.enter="triggerCreateItemWrapper"
            ><q-icon name="mib-add" class="q-mr-xs" />{{ $tc('common.phone-number.add-phone-number', 1) }}</a>
        </template>

        <!-- Create item -->
        <form-dialog
            v-if="state.currentMode === ViewMode.CREATE"
            ref="createItemDialog"
            :form="PhoneNumberForm"
            :initial-data="initialFormData"
            @setup="onCreateFormSetup"
            @submit="handleCreateItem"
            @cancel="cancelAction"
        >
            <template v-slot:title>{{ $tc('common.phone-number.add-phone-number', 1) }}</template>
        </form-dialog>

        <!-- Update item -->
        <form-dialog
            v-if="state.currentMode === ViewMode.UPDATE && state.currentItem"
            ref="updateItemDialog"
            :form="PhoneNumberForm"
            :initial-data="initialFormData"
            @submit="handleUpdateItem"
            @cancel="cancelAction"
        >
            <template v-slot:title>{{ $tc('common.phone-number.edit-phone-number', 1) }}</template>
        </form-dialog>

        <!-- Delete item -->
        <form-dialog
            v-if="state.currentMode === ViewMode.DELETE && state.currentItem"
            ref="deleteItemDialog"
            :submit-button-label="$t('common.term.confirm')"
            @submit="handleDeleteItem"
            @cancel="cancelAction"
        >
            <template v-slot:title>{{ $tc('common.phone-number.delete-phone-number', 1) }}</template>
            <template v-slot:default>
                <i18n v-if="state.currentItem" path="common.term.delete-item--confirm" tag="span">
                    <template v-slot:item><b>{{ state.currentItem.phone_number }}</b> ({{ state.currentItem.formattedCategoryLabel }})</template>
                </i18n>
            </template>
        </form-dialog>
    </div>
</template>

<script>
import FormDialog from '@/libs/form/components/FormDialog'
import ContactDetailLabelBadge from '@/components/contact/ContactDetailLabelBadge.vue'

import { PhoneNumber } from '@/models/phoneNumber'
import { useStore } from '@/composables/store'
import { useViewModeManager } from '@/composables/viewModeManager'
import { computed } from 'vue'
import { getContactAddress } from '@/helpers/contact'
import { PhoneNumberForm } from '@/forms/contact/phoneNumber'
import { ContactType } from '@/enums/graphql'

export default {
    name: 'ContactPhoneNumberList',
    components: {
        FormDialog,
        ContactDetailLabelBadge,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        targetObject: {
            type: Object,
            required: true,
        },
    },
    setup (props, context) {
        const store = useStore()
        const {
            // Static
            ViewMode,

            // Data
            state,

            // Template refs
            createItemDialog,
            updateItemDialog,
            deleteItemDialog,

            // Methods
            triggerCreateItem,
            triggerUpdateItem,
            triggerDeleteItem,
            handleUpdateItem,
            handleDeleteItem,
            cancelAction,
            emitCreate,
        } = useViewModeManager(context)

        // Computed
        const initialFormData = computed(() => {
            if (state.currentItem) {
                return {
                    phoneNumber: state.currentItem.phone_number,
                    category: state.currentItem.category?.id || null,
                    label: state.currentItem.label?.id || null,
                    isMain: state.currentItem.is_main_phone_number,
                }
            }
        })

        // Methods
        async function triggerCreateItemWrapper (item) {
            await triggerCreateItem(item)
            if (!props.items.length) {
                createItemDialog.value.state.formInstance.getItem('isMain').configuration.attributes.disable = true
                createItemDialog.value.state.formInstance.getItem('isMain').value = true
            }
        }
        async function triggerUpdateItemWrapper (item) {
            await triggerUpdateItem(item)
            updateItemDialog.value.state.formInstance.getItem('isMain').configuration.attributes.disable = state.currentItem.is_main_email_address
        }
        function handleCreateItem (formData) {
            PhoneNumber.create(props.targetObject.id, formData)
                .then(phoneNumber => {
                    emitCreate(phoneNumber)
                    createItemDialog.value.close()
                    // TODO: Add notification?
                })
                .catch(error => {
                    createItemDialog.value.showFormErrorMessage(error)
                    createItemDialog.value.resetFormSubmitStatus()
                })
        }
        function onCreateFormSetup (formBuilder) {
            if (!formBuilder.form.getItem('category').configuration.attributes) formBuilder.form.getItem('category').configuration.attributes = {}
            formBuilder.form.getItem('category').configuration.attributes.preSelectKey = props.targetObject.type === ContactType.PERSON ? 'private' : 'business'
        }

        return {
            // Static
            ViewMode,
            PhoneNumberForm,

            // Data
            store,
            state,

            // Computed
            initialFormData,

            // Template refs
            createItemDialog,
            updateItemDialog,
            deleteItemDialog,

            // Methods
            getContactAddress,
            triggerCreateItemWrapper,
            triggerUpdateItemWrapper,
            triggerDeleteItem,
            handleCreateItem,
            handleUpdateItem,
            handleDeleteItem,
            cancelAction,
            emitCreate,
            onCreateFormSetup,
        }
    },
}
</script>
