<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.admin.products.create-product.create-product', 1)" :to="{ name: 'admin-product-create' }" />
            </template>

            <h1>{{ $tc('views.admin.products.create-product.create-product', 1) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12 col-sm-10 col-md-7 col-lg-6 col-xl-5 q-gutter-y-sm">
                <form data-test="form:product-create" @submit.prevent="onFormSubmit" @keyup.esc="cancel">
                    <!-- eslint-disable vue/no-v-html -->
                    <info-box
                        v-if="formErrorMessage"
                        type="negative"
                        data-test="error:global-message"
                        v-html="formErrorMessage"
                    />
                    <!-- eslint-enable -->

                    <div class="row q-col-gutter-sm">
                        <div class="col-xs-12">
                            <form-builder item-key="name" autofocus />
                        </div>
                        <div class="col-xs-12 col-lg-6">
                            <form-builder item-key="productTemplate" />
                        </div>
                        <div class="col-xs-12 col-lg-6">
                            <form-builder item-key="productProvider" />
                        </div>

                        <div class="col-xs-12 q-mt-sm q-gutter-x-sm">
                            <base-button
                                :label="$t('common.term.cancel')"
                                flat
                                primary-button
                                @click="cancel"
                            />
                            <base-button
                                data-test="btn:submit"
                                type="submit"
                                :label="$tc('views.admin.products.create-product.create-product', 1)"
                                :disable="formSubmitStatus"
                                :loading="formSubmitStatus"
                                primary-button
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import { Product } from '@/models/product'

import PRODUCT_FIELDS from '@/forms/product.fields.json'

export default {
    name: 'ProductCreate',
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: PRODUCT_FIELDS,
        fields: ['name', 'productTemplate', 'productProvider'],
    },
    meta () {
        return {
            title: this.$tc('views.admin.products.create-product.create-product', 1),
        }
    },
    methods: {
        handleFormData ({ name, productTemplate, productProvider }) {
            Product.create({ name, productTemplate, productProvider })
                .then(() => {
                    this.$router.replace({ name: 'admin-product-list' })
                })
                .catch(error => {
                    this.showFormErrorMessage(error)
                    this.resetFormSubmitStatus()
                })
        },
        cancel () {
            this.$router.go(-1)
        },
    },
}
</script>
