<template>
    <node-view-wrapper as="span" data-drag-handle>
        <span
            class="smart-object"
            :class="{ selected: selected }"
            data-smart-object="currency"
            :data-id="node.attrs.id"
        >
            <q-icon name="mib-money-wallet-open" />
            {{ $tc('common.correspondence.smart-object.' + normalizeTranslationId(node.attrs.id), 1) }}
        </span>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import { normalizeTranslationId } from '@/helpers'

export default {
    name: 'SmartObjectCurrencyNodeView',
    components: {
        NodeViewWrapper,
    },
    props: nodeViewProps,
    methods: {
        normalizeTranslationId (...args) {
            return normalizeTranslationId(...args)
        },
    },
}
</script>
