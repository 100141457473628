<template>
    <q-table
        :data="items"
        :columns="columns"
        row-key="id"
        :pagination.sync="pagination"
        :rows-per-page-options="[10, 20, 50, 100]"
        selection="multiple"
        :selected.sync="selectedItems"
        :filter="filter"
        binary-state-sort
        flat
        square
        dense
    >
        <template v-slot:top>
            <h2 class="q-ma-none">{{ $tc(`common.content-store.content-type.${toKebabCase(contentType)}`, 2) }}</h2>
            <q-space />
            <q-input
                v-model="filter"
                dense
                filled
                square
                debounce="300"
                :label="$tc('common.term.filter')"
                data-test="input:content-store-list-filter"
            >
                <template v-slot:append>
                    <q-icon v-if="filter === ''" name="mib-search-alternate" size="1rem" />
                    <q-icon
                        v-else
                        name="mib-search-remove"
                        size="1rem"
                        class="cursor-pointer"
                        data-test="icon:clear-content-store-list-filter"
                        @click="clearFilter"
                    />
                </template>
            </q-input>
        </template>

        <template v-slot:body-cell-status="props">
            <q-td :props="props">
                <template v-if="props.row.status !== null">{{ $t(`common.content-store.status.${props.row.status.toLowerCase()}`) }}</template>
            </q-td>
        </template>
    </q-table>
</template>

<script>
import kebabCase from 'lodash.kebabcase'

export default {
    name: 'ContentStoreList',
    props: {
        items: {
            type: Array,
            required: true,
        },
        columns: {
            type: Array,
            required: true,
        },
        contentType: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            filter: '',
            pagination: {
                sortBy: this.columns[0].name,
                descending: false,
                page: 1,
                rowsPerPage: 50,
            },
            selectedItems: [],
        }
    },
    watch: {
        selectedItems () {
            this.$emit('update-selection', this.selectedItems)
        },
    },
    methods: {
        toKebabCase: function (text) {
            return kebabCase(text)
        },
        clearFilter () {
            this.filter = ''
        },
    },
}
</script>
