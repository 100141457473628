<template>
    <page-wrapper>
        <page-header :progress-bar="isQueuedOrProcessing" progress-bar-indeterminate>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.accounting.statement.statement', 2)" :to="{ name: 'accounting-statements' }" />
            </template>
            <h1>{{ $tc('common.accounting.statement.statement', 2) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-12">
                <!-- TODO: Adjust pagination-sort-by-default-key if necessary. -->
                <base-table
                    ref="table"
                    :columns="columns"
                    :fetch-objects-fn="Statement.service.all"
                    :additional-filters="additionalFilters"
                    user-settings-base-path="StatementList"
                    pagination-sort-by-default-key="created_at"
                    pagination-initial-order-direction-descending
                    selection="multiple"
                    :selected.sync="selectedStatements"
                    enable-visible-columns
                    @row-dblclick="(evt, row) => $router.push({ name: 'accounting-statement-detail', params: { id: row.id } })"
                >
                    <!-- TODO @MTR: Talk to TFU about where this slot should be inserted in the BaseTable component. -->
                    <template v-slot:quickfilters>
                        <div class="q-gutter-x-sm">
                            <p class="inline-block q-mb-none">{{ $tc('common.term.quick-filter', 2) }}:</p>
                            <base-button-toggle
                                v-model="filters.is_shared"
                                :options="quickFilters.sharedStatusOptions"
                                clearable
                            />
                        </div>
                    </template>

                    <template v-slot:body-cell-consulting_entity_name="slotProps">
                        <q-td :props="slotProps">
                            <contact-name
                                :contact="slotProps.row.consultingEntity"
                                highlighted
                                comma-separated
                            />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-statement_recipient="slotProps">
                        <q-td
                            :props="slotProps"
                            :class="['truncate-text', { 'additional-info': !slotProps.row.hasRecipients, 'text-negative': !slotProps.row.isShared && !slotProps.row.hasRecipients }]"
                            data-test="td:statement_recipient"
                        >
                            <span v-if="slotProps.row.hasRecipients">
                                <span>{{ slotProps.row.formattedRecipients(true) }}</span>
                                <q-tooltip
                                    :delay="1000"
                                    anchor="center start"
                                    self="center start"
                                    :offset="[0, 0]"
                                    max-width="70ch"
                                >{{ slotProps.row.formattedRecipients(true) }}</q-tooltip>
                            </span>
                            <template v-else>{{ $tc('views.contact.settings.consulting-settings.statement-recipient.no-recipient-defined', 1) }}</template>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-status="slotProps">
                        <q-td :props="slotProps">
                            <status-badge
                                :status="slotProps.row.currentQueueItem.status"
                                default-translation-base-path="common.status.queue-item-status"
                                :loading="[QueueItemStatus.QUEUED, QueueItemStatus.PROCESSING].includes(slotProps.row.currentQueueItem.status)"
                            />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-actions="slotProps">
                        <q-td :props="slotProps" auto-width>
                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-view-1"
                                :to="{ name: 'accounting-statement-detail', params: { id: slotProps.row.id } }"
                            >
                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.term.show-details', 1) }}</q-tooltip>
                            </q-btn>
                        </q-td>
                    </template>
                </base-table>

                <!-- Cancel Creation Statements -->
                <form-dialog
                    ref="cancelCreationDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleCancelCreation"
                >
                    <template v-slot:title>{{ $tc('common.accounting.statement.cancel-creation', eligibleStatementsForCancelCreation.length) }}</template>
                    <template v-slot:default>
                        <info-box v-if="eligibleStatementsForCancelCreation.length !== selectedStatements.length">{{ $tc('views.accounting.statement.cancel-statement-creation--confirm--n-of-x--info', eligibleStatementsForCancelCreation.length, { x: selectedStatements.length }) }}</info-box>
                        <p>{{ $tc(`views.accounting.statement.cancel-statement-creation--confirm${eligibleStatementsForCancelCreation.length !== selectedStatements.length ? '--n-of-x' : '' }`, eligibleStatementsForCancelCreation.length) }}</p>
                    </template>
                </form-dialog>

                <!-- Share Statements -->
                <form-dialog
                    ref="shareDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleShare"
                >
                    <template v-slot:title>{{ $tc('common.accounting.statement.share-statement', eligibleStatementsForShare.length) }}</template>
                    <template v-slot:default>
                        <info-box v-if="eligibleStatementsForShare.length !== selectedStatements.length">{{ $tc('views.accounting.statement.share-statement--confirm--n-of-x--info', eligibleStatementsForShare.length, { x: selectedStatements.length }) }}</info-box>
                        <p>{{ $tc(`views.accounting.statement.share-statement--confirm${eligibleStatementsForShare.length !== selectedStatements.length ? '--n-of-x' : '' }`, eligibleStatementsForShare.length) }}</p>
                    </template>
                </form-dialog>

                <!-- Delete Statements -->
                <form-dialog
                    ref="deleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleDelete"
                >
                    <template v-slot:title>{{ $tc('common.accounting.statement.delete-statement', eligibleStatementsForDelete.length) }}</template>
                    <template v-slot:default>
                        <info-box v-if="eligibleStatementsForDelete.length !== selectedStatements.length">{{ $tc('views.accounting.statement.delete-statement--confirm--n-of-x--info', eligibleStatementsForDelete.length, { x: selectedStatements.length }) }}</info-box>
                        <p>{{ $tc(`views.accounting.statement.delete-statement--confirm${eligibleStatementsForDelete.length !== selectedStatements.length ? '--n-of-x' : '' }`, eligibleStatementsForDelete.length) }}</p>
                    </template>
                </form-dialog>
            </div>
        </div>

        <in-page-footer>
            <base-button
                :to="{ name: 'accounting-statement-create' }"
                :label="$tc('common.accounting.statement.create-statement', 2)"
                primary-button
            />
            <base-button
                v-if="eligibleStatementsForCancelCreation.length"
                :label="`${$tc('common.accounting.statement.cancel-creation', eligibleStatementsForCancelCreation.length)} (${eligibleStatementsForCancelCreation.length})`"
                primary-button
                outline
                @click="handleActions({ key: Statement.action.CANCEL_CREATION })"
            />
            <base-button
                v-if="eligibleStatementsForShare.length"
                :label="`${$tc('common.accounting.statement.share-statement', eligibleStatementsForShare.length)} (${eligibleStatementsForShare.length})`"
                primary-button
                outline
                @click="handleActions({ key: Statement.action.SHARE })"
            />
            <base-button
                v-if="eligibleStatementsForDelete.length"
                :label="`${$tc('common.accounting.statement.delete-statement', eligibleStatementsForDelete.length)} (${eligibleStatementsForDelete.length})`"
                outline
                color="negative"
                @click="handleActions({ key: Statement.action.DELETE })"
            />

            <polling
                ref="polling"
                :poll-fn="Statement.service.all"
                :stop-condition-fn="stopPollingConditionFn"
                :poll-filters="pollFilters"
                :timeout-tm="3000"
                auto-start
                @condition-check="handlePollingConditionCheck"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import { Statement } from '@/models/models'
import { StatementActions } from '@/enums'
import { QueueItemStatus } from '@/enums/graphql'
import { cancelCreationCondition, shareCondition, deleteCondition } from '@/helpers/statement'

import BaseTable from '@/components/BaseTable'
import ContactName from '@/components/contact/ContactName.vue'
import StatusBadge from '@/components/StatusBadge'
import BaseButtonToggle from '@/components/form/BaseButtonToggle.vue'
import FormDialog from '@/components/form/FormDialog'
import Polling from '@/components/Polling.vue'

export default {
    name: 'StatementList',
    meta () {
        return {
            title: this.$tc('common.accounting.statement.statement', 2),
        }
    },
    components: {
        BaseTable,
        ContactName,
        StatusBadge,
        BaseButtonToggle,
        FormDialog,
        Polling,
    },
    data () {
        return {
            Statement,
            StatementsActions: StatementActions,
            QueueItemStatus,
            selectedStatements: [],
            columns: [
                {
                    name: 'consulting_entity_name',
                    label: this.$tc('views.accounting.statement.statement-for', 1),
                    sortable: true,
                    required: true,
                    align: 'left',
                },
                {
                    name: 'consulting_company_name',
                    label: this.$tc('common.term.company', 1),
                    field: row => row.consultingEntity.consulting_company_name || '–',
                    align: 'left',
                    classes: row => !row.consultingEntity.consulting_company_name ? 'additional-info' : null,
                },
                {
                    name: 'date_from',
                    label: this.$tc('common.commission.accounting-period--from--short', 1),
                    field: row => row.formattedDateFrom,
                    sortable: true,
                    required: true,
                    align: 'left',
                },
                {
                    name: 'date_until',
                    label: this.$tc('common.commission.accounting-period--to--short', 1),
                    field: row => row.formattedDateUntil,
                    sortable: true,
                    required: true,
                    align: 'left',
                },
                {
                    name: 'include_attachments',
                    label: this.$tc('views.accounting.statement.include-attachment', 2),
                    field: row => row.include_attachments ? this.$t('common.term.yes') : this.$t('common.term.no'),
                    sortable: true,
                    align: 'left',
                    classes: row => !row.include_attachments ? 'additional-info' : null,
                },
                {
                    name: 'file_storage_item_shared_at',
                    label: this.$t('views.accounting.statement.shared-on'),
                    field: row => row.formattedFileStorageItemSharedAt || this.$t('views.accounting.statement.shared--not-shared'),
                    sortable: true,
                    align: 'left',
                    classes: row => !row.formattedFileStorageItemSharedAt ? 'additional-info' : null,
                },
                {
                    name: 'statement_recipient',
                    label: this.$tc('common.accounting.statement.statement-recipient', 2),
                    required: true,
                    align: 'left',
                },
                {
                    name: 'created_at',
                    label: this.$t('common.term.created-on'),
                    field: row => row.formattedCreatedAt,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'status',
                    label: this.$tc('common.term.status'),
                    align: 'left',
                    sortable: true,
                    required: true,
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left', required: true },
            ],
            isQueuedOrProcessing: false,
            pollFilters: {
                count: 1,
                filterCurrentQueueItemStatus: [QueueItemStatus.QUEUED, QueueItemStatus.PROCESSING],
            },
            filters: {
                is_shared: null,
            },
            quickFilters: {
                sharedStatusOptions: [
                    { label: this.$t('views.accounting.statement.quick-filter--shared'), value: true },
                    { label: this.$t('views.accounting.statement.quick-filter--not-shared'), value: false },
                ],
            },
        }
    },
    computed: {
        additionalFilters () {
            const filterVariables = {
                ...this.filters,
            }
            return filterVariables
        },
        eligibleStatementsForCancelCreation () {
            return this.getEligibleStatementsForCancelCreation()
        },
        eligibleStatementsForShare () {
            return  this.getEligibleStatementsForShare()
        },
        eligibleStatementsForDelete () {
            return this.getEligibleStatementsForDelete()
        },
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        handleActions (action) {
            switch (action.key) {
                case Statement.action.CANCEL_CREATION:
                    this.$refs.cancelCreationDialog.open()
                    break
                case Statement.action.SHARE:
                    this.$refs.shareDialog.open()
                    break
                case Statement.action.DELETE:
                    this.$refs.deleteDialog.open()
                    break
            }
        },
        handleCancelCreation () {
            const statementIds = this.eligibleStatementsForCancelCreation.map(statement => statement.id)
            Statement.cancelCreation(statementIds)
                .then(() => {
                    this.$refs.cancelCreationDialog.close()
                    this.resetSelectedItems()
                    this.$refs.table.fetchObjects()

                    this.$q.notify({
                        type: 'positive',
                        // TODO improvement: Verify if pluralization works correctly.
                        message: this.$tc('common.notifications.accounting.statement-creation-cancelled-success', this.eligibleStatementsForCancelCreation.length),
                    })
                }).catch(error => {
                    this.$refs.cancelCreationDialog.showFormErrorMessage(error)
                    this.$refs.cancelCreationDialog.resetFormSubmitStatus()
                })
        },
        handleShare () {
            const statementIds = this.eligibleStatementsForShare.map(statement => statement.id)
            Statement.share(statementIds)
                .then(() => {
                    this.$refs.shareDialog.close()
                    this.resetSelectedItems()
                    this.$refs.table.fetchObjects()

                    this.$q.notify({
                        type: 'positive',
                        // TODO improvement: Verify if pluralization works correctly.
                        message: this.$tc('common.notifications.accounting.statement-shared-success', this.eligibleStatementsForShare.length),
                    })
                }).catch(error => {
                    this.$refs.shareDialog.showFormErrorMessage(error)
                    this.$refs.shareDialog.resetFormSubmitStatus()
                })
        },
        handleDelete () {
            const statementIds = this.eligibleStatementsForDelete.map(statement => statement.id)
            Statement.delete(statementIds)
                .then(() => {
                    this.$refs.deleteDialog.close()
                    this.resetSelectedItems()
                    this.$refs.table.fetchObjects()

                    this.$q.notify({
                        type: 'positive',
                        // TODO improvement: Verify if pluralization works correctly.
                        message: this.$tc('common.notifications.accounting.statement-deleted-success', this.eligibleStatementsForDelete.length),
                    })
                }).catch(error => {
                    this.$refs.deleteDialog.showFormErrorMessage(error)
                    this.$refs.deleteDialog.resetFormSubmitStatus()
                })
        },
        getEligibleStatementsForCancelCreation () {
            return cancelCreationCondition(this.selectedStatements)
        },
        getEligibleStatementsForShare () {
            return shareCondition(this.selectedStatements)
        },
        getEligibleStatementsForDelete () {
            return deleteCondition(this.selectedStatements)
        },
        resetSelectedItems () {
            this.selectedStatements.length = 0
            if (this.$refs.table) this.$refs.table.$refs.qTable.clearSelection()
        },
        stopPollingConditionFn (response) {
            return !response.data.length
        },
        handlePollingConditionCheck (stopCondition) {
            if (this.isQueuedOrProcessing && stopCondition) this.$refs.table.fetchObjects()
            this.isQueuedOrProcessing = !stopCondition
        },
        onNewEntity () {
            this.$router.push({ name: 'accounting-statement-create' })
        },
    },
}
</script>
