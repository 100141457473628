import gql from 'graphql-tag'

export const CONTACT_ADDRESS_CREATE_MUTATION = gql`
    mutation contactAddressCreate(
        $contact_id: ID!
        $address: AddressInput!
    ) {
        contactAddressCreate(
            contact_id: $contact_id
            address: $address
        ) {
            id
            category {
                id
                name
            }
            label {
                id
                label
            }
            po_box
            address1
            address2
            address3
            zip
            city
            country {
                id
                name
            }
            valid_from
            valid_until
            is_main_correspondence_address
            status
        }
    }
`
