import gql from 'graphql-tag'

export const COMMISSION_LIST_IMPORT_EXCEL_MAPPING_QUERY = gql`
    query commissionListImportExcelMapping(
        $id: ID!
    ) {
        commissionListImportExcelMapping(
            id: $id
        ) {
            id
            productProvider {
                id
                name
                display_name
            }
            name
            commission_entries_start_row
            contract_number_column
            commission_type_column
            commission_amount_column
            premium_from_column
            premium_from_date_format
            premium_to_column
            premium_to_date_format
            customer_full_name_column
            created_at
            updated_at
        }
    }
`
