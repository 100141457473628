<template>
    <q-dialog
        ref="taskFormDialog"
        :content-class="['task-form-wrapper-dialog', { 'no-shadow': currentMode === ViewMode.UPDATE && !task }]"
        no-backdrop-dismiss
        @hide="hide"
    >
        <loading-indicator v-if="currentMode === ViewMode.UPDATE && !task" auto-height color="white" />
        <div v-else class="task-form-wrapper">
            <!-- Needs to have a wrapper element around form. If form tag is first element in q-dialog it gets closed on every click… -->
            <form data-test="form:dialog" class="task-form" @submit.prevent="onFormSubmit">
                <q-card>
                    <q-card-section>
                        <div :class="['row', { 'q-mb-md': $q.screen.xs }]">
                            <div :class="['col-xs-12', { 'col-sm-7 col-md-9': currentMode === ViewMode.UPDATE }]">
                                <h1>{{ (currentMode === ViewMode.UPDATE) ? $tc('common.task.edit-task', 1) : $tc('common.task.add-task', 1) }}</h1>
                                <!-- eslint-disable vue/no-v-html -->
                                <info-box
                                    v-if="formErrorMessage"
                                    type="negative"
                                    data-test="error:global-message"
                                    v-html="formErrorMessage"
                                />
                                <!-- eslint-enable -->
                            </div>
                            <div v-if="currentMode === ViewMode.UPDATE" :class="['col-xs-12 col-sm-5 col-md-3', { 'text-right': $q.screen.gt.xs }]">
                                <span class="additional-info">{{ $tc('common.term.status', 1) }}:</span>
                                <q-btn-dropdown
                                    :label="$tc(`common.status.${toKebabCase(formData.status.toLowerCase())}`, 1)"
                                    :class="`change-status-button status-button ${toKebabCase(formData.status.toLowerCase())} q-ml-sm`"
                                    unelevated
                                    no-caps
                                    :ripple="false"
                                    :text-color="(formData.status === TaskStatus.DONE) ? 'text-inverted' : 'text-secondary-dark'"
                                    menu-anchor="bottom left"
                                    menu-self="top left"
                                    data-test="btn:dropdown-status"
                                >
                                    <q-list>
                                        <q-item
                                            v-if="formData.status !== TaskStatus.TODO"
                                            v-close-popup
                                            clickable
                                            data-test="btn:task-status-todo"
                                            @click="formData.status = TaskStatus.TODO"
                                        >
                                            <q-item-section>
                                                <q-item-label>{{ $tc(`common.status.${toKebabCase(TaskStatus.TODO.toLowerCase())}`, 1) }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                        <q-item
                                            v-if="formData.status !== TaskStatus.IN_PROGRESS"
                                            v-close-popup
                                            clickable
                                            data-test="btn:task-status-in-progress"
                                            @click="formData.status = TaskStatus.IN_PROGRESS"
                                        >
                                            <q-item-section>
                                                <q-item-label>{{ $tc(`common.status.${toKebabCase(TaskStatus.IN_PROGRESS)}`, 1) }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                        <q-item
                                            v-if="formData.status !== TaskStatus.DONE"
                                            v-close-popup
                                            clickable
                                            data-test="btn:task-status-done"
                                            @click="formData.status = TaskStatus.DONE"
                                        >
                                            <q-item-section>
                                                <q-item-label>{{ $tc(`common.status.${toKebabCase(TaskStatus.DONE.toLowerCase())}`, 1) }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </q-list>
                                </q-btn-dropdown>
                            </div>
                        </div>
                        <div class="row q-col-gutter-md">
                            <div class="col-xs-12 col-md-9">
                                <form-builder item-key="subject" autofocus />
                            </div>
                            <div class="col-xs-12 col-sm-4 col-md-3">
                                <form-builder item-key="dueDate" />
                            </div>

                            <div class="col-xs-12 col-sm-8 col-md-6">
                                <!-- TODO improvement: Maybe introduce validation or hint if the assignee removes themselves (and thus would lose access). -->
                                <form-builder item-key="assignee" />
                            </div>

                            <div class="col-xs-12 col-sm-8 col-md-6">
                                <template v-if="showTargetObject">
                                    <p>
                                        <i18n
                                            :path="`common.task.target-object--${toKebabCase(formData.targetObject.targetObjectType)}--link-text`"
                                            tag="span"
                                            class="additional-info"
                                        >
                                            <template v-slot:contactName>
                                                <template v-if="formData.targetObject.targetObjectType === TaskTargetObjectType.CONTACT">
                                                    <router-link :to="{ name: 'contact-detail', params: { id: formData.targetObject.id } }">{{ formData.targetObject.getContactName({ contactNumber: true }) }}</router-link>
                                                </template>
                                                <template v-else>{{ formData.targetObject.customer.getContactName({ contactNumber: true }) }}</template>
                                            </template>
                                            <template v-if="formData.targetObject.targetObjectType === TaskTargetObjectType.APPLICATION" v-slot:referenceNumber>
                                                <router-link :to="{ name: 'application-detail', params: { contactId: formData.targetObject.customer.id, id: formData.targetObject.id } }">{{ formData.targetObject.formattedNumber }}</router-link>
                                            </template>
                                            <template v-if="formData.targetObject.targetObjectType === TaskTargetObjectType.CONTRACT" v-slot:contractNumber>
                                                <router-link :to="{ name: 'contract-detail', params: { contactId: formData.targetObject.customer.id, id: formData.targetObject.id } }">{{ formData.targetObject.currentContractNumber }}</router-link>
                                            </template>
                                        </i18n>
                                        <q-btn
                                            icon="mib-delete-2-alternate"
                                            flat
                                            round
                                            color="negative"
                                            size="sm"
                                            @click="clearTargetObject"
                                        >
                                            <q-tooltip
                                                :delay="1000"
                                                anchor="center end"
                                                self="center start"
                                                :offset="[10, 0]"
                                            >
                                                {{ $tc(`common.target-object.unlink-target-object--${(formData.targetObject.type) ? toKebabCase(TaskTargetObjectType.CONTACT.toLowerCase()) : toKebabCase(TaskTargetObjectType.CONTRACT.toLowerCase())}`, 1) }}
                                            </q-tooltip>
                                        </q-btn>
                                    </p>
                                </template>
                                <p v-else-if="currentMode === ViewMode.UPDATE && task.target_object_type && !task.targetObject" class="additional-info">{{ $tc(`common.task.target-object--${toKebabCase(task.target_object_type.toLowerCase())}--no-access`, 1) }}</p>
                                <target-object-search v-else :placeholder="$tc('forms.field.label.task.link-task-to-target-object', 1)" @open-search-result="setTargetObject" />
                            </div>

                            <div class="col-sm-4">
                                <!-- TODO improvement @MTR: `checked-icon` and `unchecked-icon` -->
                                <form-builder item-key="isPrivate" />
                            </div>
                        </div>

                        <div class="row q-col-gutter-md q-pt-md">
                            <template v-if="!showDescriptionAndAttachment">
                                <div class="col-xs-12">
                                    <base-button flat padding="0" @click="showDescriptionAndAttachment = true">{{ $tc('common.task.add-description-or-attachment', 1) }}</base-button>
                                </div>
                            </template>
                            <template v-else>
                                <div class="task-description col-xs-12">
                                    <!-- TODO improvement @TFU: Make `autofocus` available also for "editor" form builder item. -->
                                    <form-builder item-key="description" />
                                </div>
                                <div class="col-xs-12">
                                    <h2>{{ $tc('common.term.attachment', 2) }}</h2>
                                    <p v-if="task" :class="{ 'additional-info': !task.attachments.length }">{{ $tc('common.attachment.number-of-attachments', task.attachments.length) }} <template v-if="task.attachments.length"><span class="additional-info">({{ humanStorageSize(attachmentsTotalSize) }})</span>:</template></p>

                                    <div class="row q-col-gutter-md">
                                        <div class="col-xs-12 col-md-4">
                                            <file-upload
                                                v-model="attachments"
                                                multiple
                                                drop-zone-small
                                                class="do-not-print"
                                            />
                                        </div>

                                        <div v-if="task" class="col-xs-12 col-md-8">
                                            <!-- TODO improvement @MTR: Create file library component. -->
                                            <div class="file-library-wrapper">
                                                <q-card
                                                    v-for="attachment in task.attachments"
                                                    :key="attachment.id"
                                                    :class="['file-library-item', { 'delete': attachmentToDelete && attachmentToDelete.id === attachment.id }]"
                                                    square
                                                    flat
                                                    bordered
                                                >
                                                    <q-card-section horizontal>
                                                        <q-item class="items-start">
                                                            <q-item-section side>
                                                                <q-avatar square>
                                                                    <q-icon
                                                                        v-if="attachment.mime_type"
                                                                        :name="getFileTypeIcon(attachment.mime_type)"
                                                                        size="sm"
                                                                        color="secondary"
                                                                    />
                                                                </q-avatar>
                                                            </q-item-section>

                                                            <q-item-section>
                                                                <q-item-label>{{ attachment.name }}</q-item-label>
                                                                <q-item-label v-if="attachment.size" caption>{{ humanStorageSize(attachment.size) }}</q-item-label>
                                                            </q-item-section>
                                                        </q-item>

                                                        <q-card-actions vertical>
                                                            <q-btn
                                                                icon="mib-bin"
                                                                flat
                                                                round
                                                                color="negative"
                                                                size="sm"
                                                                @click="triggerTaskDeleteAttachment(attachment)"
                                                            >
                                                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.attachment.delete-attachment', 1) }}</q-tooltip>
                                                            </q-btn>

                                                            <q-btn
                                                                color="primary"
                                                                size="sm"
                                                                flat
                                                                round
                                                                icon="mib-cloud-download"
                                                                @click="attachment.download()"
                                                            >
                                                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.term.download-item', 1, { item: attachment.name }) }}</q-tooltip>
                                                            </q-btn>
                                                        </q-card-actions>
                                                    </q-card-section>
                                                </q-card>
                                            </div>
                                            <!-- Delete Attachment -->
                                            <form-dialog
                                                ref="taskDeleteAttachmentDialog"
                                                :item="attachmentToDelete"
                                                dont-close-on-confirm
                                                @confirm="handleTaskDeleteAttachment"
                                                @hide="attachmentToDelete = null"
                                            >
                                                <template v-slot:title>{{ $tc('common.attachment.delete-attachment', 1) }}</template>
                                                <template v-slot:default="slotProps">
                                                    <i18n v-if="slotProps.item" path="common.attachment.delete-attachment--confirm--temp-component-interpolation-count" tag="p">
                                                        <template v-slot:name><b>{{ slotProps.item.name }}</b></template>
                                                    </i18n>
                                                </template>
                                            </form-dialog>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </q-card-section>
                    <q-card-actions align="right" class="q-gutter-sm">
                        <base-button
                            v-close-popup
                            :label="$t('common.term.cancel')"
                            outline
                            :disable="formSubmitStatus"
                            primary-button
                            flat
                            data-test="btn:cancel"
                        />
                        <base-button
                            type="submit"
                            :label="(currentMode === ViewMode.CREATE) ? $t('common.term.add') : $t('common.term.save')"
                            :disable="formSubmitStatus"
                            :loading="formSubmitStatus"
                            primary-button
                            data-test="btn:submit"
                        />
                    </q-card-actions>
                </q-card>
            </form>
        </div>
    </q-dialog>
</template>

<script>
import { FileUploadTargetObjectType, ViewMode } from '@/enums'
import { TaskStatus, TaskVisibility, ContactType, TaskTargetObjectType } from '@/enums/graphql'
import { prepareFileUploadWrappers, getFileTypeIcon, humanStorageSize } from '@/helpers/file'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import TASK_FORM from '@/forms/task.form'
import { EventBus } from '@/event-bus'
import { formatISO, endOfDay } from 'date-fns'
import kebabCase from 'lodash.kebabcase'
import { EditorContent, Task } from '@/models/models'
import FileUpload from '@/components/form/FileUpload'
import TargetObjectSearch from '@/components/search/TargetObjectSearch'
import FormDialog from '@/components/form/FormDialog'

export default {
    name: 'TaskFormDialog',
    components: {
        FileUpload,
        TargetObjectSearch,
        FormDialog,
    },
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: TASK_FORM,
    },
    data () {
        const data = {
            ContactType,
            ViewMode,
            getFileTypeIcon,
            TaskTargetObjectType,
            TaskStatus,
            formData: {},
        }
        this.setInitialData(data)

        return data
    },
    computed: {
        attachmentsTotalSize () {
            return (this.task) ? this.task.attachments.reduce((total, file) => (total += file.size), 0) : 0
        },
        taskInput () {
            const description = this.formData.description.contentHTML !== '<p></p>' // This is currently the only (read simplest) way to test if there is any meaningful content.
                ? new EditorContent(this.formData.description).getVariablesObject()
                : null

            const taskInput = {
                subject: this.formData.subject,
                due_date: this.formData.dueDate ? formatISO(endOfDay(new Date(this.formData.dueDate))) : null,
                visibility: this.formData.visibility,
                description,
            }

            if (this.currentMode === ViewMode.UPDATE && this.task && this.task.assignee !== null) {
                if (this.task.assignee.id !== this.formData.assignee) taskInput.assignee_id = this.formData.assignee
            } else {
                taskInput.assignee_id = this.formData.assignee
            }

            if (this.formData.targetObject) {
                const target_object_type = {
                    'Person': TaskTargetObjectType.CONTACT,
                    'Company': TaskTargetObjectType.CONTACT,
                    'Application': TaskTargetObjectType.APPLICATION,
                    'Contract': TaskTargetObjectType.CONTRACT,
                }
                taskInput.target_object_type = target_object_type[this.formData.targetObject.__typename]
                taskInput.target_object_id = this.formData.targetObject.id
            } else {
                taskInput.target_object_type = null
                taskInput.target_object_id = null
            }

            return taskInput
        },
    },
    watch: {
        'formData.isPrivate' (isPrivate) {
            this.formData.visibility = isPrivate
                ? TaskVisibility.PRIVATE
                : TaskVisibility.PUBLIC
            if (isPrivate) this.formData.assignee = null
        },
        'formData.assignee' (assignee) {
            // If an assignee gets selected, the task should be made public.
            if (assignee) {
                this.formData.isPrivate = false
                this.formData.visibility = TaskVisibility.PUBLIC
            }
        },
    },
    created () {
        EventBus.$on('taskFormDialog:open', this.show)
    },
    beforeDestroy () {
        EventBus.$off('taskFormDialog:open', this.show)
    },
    methods: {
        setInitialData (targetObject) {
            targetObject.currentMode = ViewMode.CREATE
            targetObject.formData.isPrivate = true
            targetObject.formData.visibility = TaskVisibility.PRIVATE
            targetObject.formData.targetObject = null
            targetObject.formData.status = null
            targetObject.showDescriptionAndAttachment = false
            targetObject.showTargetObject = false
            targetObject.attachmentToDelete = null
            targetObject.attachments = []
            targetObject.task = null
        },
        show ({ targetObject, taskId } = { targetObject: null, taskId: null }) {
            if (targetObject) this.setTargetObject(targetObject)
            if (taskId) {
                this.currentMode = ViewMode.UPDATE
                this.fetchObject(taskId)
            }
            this.$refs.taskFormDialog.show()
        },
        hide () {
            this.reset()
        },
        reset () {
            this.resetFormSubmitStatus()
            this.resetFormData()
            this.setInitialData(this) // This line must come after resetFormData has been called, as it would overwrite the custom initial data.
        },
        setTargetObject (targetObject) {
            this.formData.targetObject = targetObject
            this.showTargetObject = true
        },
        clearTargetObject () {
            this.showTargetObject = false
            this.formData.targetObject = null
        },
        async fetchObject (taskId) {
            const task = await Task.objects.get(taskId)
            if (task) {
                this.showDescriptionAndAttachment = (task.description && task.description.contentHTML !== '<p></p>') || task.attachments.length ? true : false

                Object.assign(this.formData, {
                    status: task.status,
                    subject: task.subject,
                    dueDate: task.due_date || '',
                    assignee: task.assignee && task.assignee.id ? task.assignee.id : null,
                    isPrivate: (task.visibility === TaskVisibility.PRIVATE),
                    visibility: task.visibility,
                    description: task.description && task.description.contentHTML ? task.description : this.formData.description, // TODO @TFU: Verify this.
                    due_date: task.due_date,
                })

                if (task.targetObject) this.setTargetObject(task.targetObject)
                this.task = task
            }
        },
        handleFormData (formData) {
            switch (this.currentMode) {
                case ViewMode.CREATE:
                    this.createTask(formData)
                    break
                case ViewMode.UPDATE:
                    this.updateTask(formData)
                    break
            }
        },
        createTask (formData) {
            Task.create({ taskInput: this.taskInput })
                .then(this.handleTaskSubmitSuccess)
                .catch(this.handleTaskSubmitError)
        },
        updateTask (formData) {
            // TODO improvement @TFU: In the case that an assignee removes themselves from the task and updates it, forward them to the TaskList (instead of the TaskDetail – which shows a 403).
            const updateTask = function () {
                this.task.update({ taskInput: this.taskInput })
                    .then(this.handleTaskSubmitSuccess)
                    .catch(this.handleTaskSubmitError)
            }.bind(this)

            if (formData.status !== this.task.status) {
                this.task.changeStatus(formData.status)
                    .then(() => {
                        updateTask()
                    })
            } else {
                updateTask()
            }
        },
        async handleTaskSubmitSuccess (task) {
            if (this.attachments.length) {
                // Add attachments to file upload manager queue.
                const fileUploadWrappers = await prepareFileUploadWrappers(this.attachments, {
                    targetObjectType: FileUploadTargetObjectType.TASK,
                    targetObjectId: task.id,
                })
                EventBus.$emit('fileUploadManager:addToUploadQueue', fileUploadWrappers)
            }

            EventBus.$emit(`task:${this.currentMode.toLowerCase()}`, task)
            this.$q.notify({
                type: 'positive',
                message: this.$tc('common.notifications.task.task-saved-success', 1),
            })

            this.$refs.taskFormDialog.hide()
            this.reset()
        },
        handleTaskSubmitError (error) {
            this.showFormErrorMessage(error)
            this.resetFormSubmitStatus()
        },
        async triggerTaskDeleteAttachment (attachment) {
            this.attachmentToDelete = attachment
            await this.$nextTick()
            this.$refs.taskDeleteAttachmentDialog.open()
        },
        handleTaskDeleteAttachment () {
            Task.service.deleteAttachment(this.task.id, this.attachmentToDelete.id)
                .then(() => {
                    this.$refs.taskDeleteAttachmentDialog.close()
                    const attachmentIndex = this.task.attachments.findIndex(attachment => attachment.id === this.attachmentToDelete.id)
                    if (attachmentIndex > -1) {
                        this.task.attachments.splice(attachmentIndex, 1)
                    }
                    EventBus.$emit(`task:delete-attachment`, this.task)
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.attachment.attachment-deleted-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.taskDeleteAttachmentDialog.showFormErrorMessage(error)
                    this.$refs.taskDeleteAttachmentDialog.resetFormSubmitStatus()
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$tc('common.notifications.attachment.attachment-deleted-error', 1),
                    })
                })
        },
        humanStorageSize (...args) {
            return humanStorageSize(...args)
        },
        toKebabCase: function (text) {
            return kebabCase(text)
        },
    },
}
</script>

<style lang="scss">
.q-dialog.no-shadow {
    .q-dialog__inner > div {
        box-shadow: none;
    }
}

.task-form-wrapper.task-form-wrapper {
    @include mq($from: $sizeBreakpointSm) {
        max-width: pxToRem(1020);
    }
}

.task-description {
    .editor-wrapper {
        min-height: 160px;
    }
}

.file-library-wrapper {
    --file-library-items-per-line: 1;
    --column-gap: #{$sizeSpacingSm};
    --row-gap: #{$sizeSpacingSm};

    @media screen and (min-width: $sizeBreakpointSm), print {
        --file-library-items-per-line: 2;
    }

    @media screen and (min-width: $sizeBreakpointFormBuilderMd), print {
        --file-library-items-per-line: 3;
    }

    @media screen and (min-width: $sizeBreakpointMd), print {
        --file-library-items-per-line: 2;
        --column-gap: #{$sizeSpacingMd};
        --row-gap: #{$sizeSpacingMd};
    }

    @media screen and (min-width: $sizeBreakpointXl), print and (min-width: 150mm) {
    }

    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(var(--file-library-items-per-line), 1fr);
    column-gap: var(--column-gap);
    row-gap: var(--row-gap);
}

.file-library-item.file-library-item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    transition: all 1s $defaultTransitionTimingFunction 0s;

    &.delete {
        border-style: dashed;
        border-color: var(--q-color-negative-light);
        background-color: var(--q-color-negative-lighter);
    }

    & > .q-card__section {
        width: 100%;
        justify-content: space-between;
    }
}
</style>
