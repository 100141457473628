<template>
    <fake-select
        v-if="!options.length"
        v-bind="$attrs"
    />
    <base-select-filter
        v-else

        :value="value"
        v-bind="$attrs"
        :options="options"

        option-value="id"
        :option-label="getOptionLabel"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import { i18n } from '@/i18n'
import { isEqual, kebabCase } from 'lodash'
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { ContactRelationshipService } from '@/services'
import FakeSelect from '@/components/FakeSelect.vue'

export default {
    name: 'ContactRelationshipSelect',
    components: {
        FakeSelect,
        BaseSelectFilter,
    },
    props: {
        value: {
            required: true,
        },
        filter: {
            type: Object,
            default: () => null,
        },
        contactId: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            options: [],
        }
    },
    watch: {
        filter (newValue, oldValue) {
            if (isEqual(oldValue, newValue)) return

            const filters = {
                ...newValue,
            }

            this.fetchObjects(filters)
        },
    },
    created() {
        const filters = {}
        if (this.filter) Object.assign(filters, this.filter)

        this.fetchObjects(filters)
    },
    methods: {
        fetchObjects(filters) {
            ContactRelationshipService.all(filters).then(response => {
                this.options = response.data
                this.$emit('fetchedObjects', response.data)
            })
        },
        getOptionLabel (item) {
            const sourceNode = item.getSourceNode(this.contactId)
            const targetNode = item.getTargetNode(this.contactId)

            let relationship = this.$tc(`common.contact.relationship.node-type-${targetNode?.gender ? targetNode.getGender() : 'neutral'}.${kebabCase(item.getTargetNodeType(this.contactId))}`, 1)
            return `${targetNode.getContactName()} <span class="additional-info">– ${relationship} ${i18n.t(`common.contact.relationship.node-type-prepositions.${kebabCase(item.getTargetNodeType(this.contactId))}`)} ${sourceNode.getContactName()}</span>`
        },
    },
}
</script>
