<template>
    <form-builder-next
        :form="ContactRelationshipForm"
        :initial-data="initialData"
    >
        <template v-slot:default="{ formBuilder }">
            <div class="row q-col-gutter-md">
                <div v-show="state.currentMode === ViewMode.CREATE" class="col-xs-12 col-md-6">
                    <form-builder-next
                        :item="formBuilder.form.getItem('contactId')"
                        :exclude-ids="[contact.id]"
                        @fetchedObjects="setContactOptions"
                    />
                </div>

                <div v-show="state.currentMode === ViewMode.CREATE" class="col-xs-12 col-md-6">
                    <form-builder-next
                        :item="formBuilder.form.getItem('definition')"
                        :source-node-contact="contact"
                        :target-node-contact="selectedContact"
                        :exclude-definitions="excludeContactRelationshipDefinitions"
                        @fetchedObjects="setContactRelationshipDefinitionsOptions"
                    />
                </div>

                <div v-if="isValidContactRelationshipTypeForNotes" class="col-xs-12">
                    <form-builder-next :item="formBuilder.form.getItem('notes')" />
                </div>
            </div>

            <form-builder-next
                v-if="isValidContactRelationshipTypeForAreasOfResponsibility"
                :item="formBuilder.form.getItem('areasOfResponsibility')"
                class="row q-col-gutter-md q-mt-md"
            >
                <div class="col-xs-12">
                    <h2 class="q-mb-none">{{ $tc('common.contact.relationship.area-of-responsibility.area-of-responsibility', 2) }} <info-icon size="xs" :text="$t('common.contact.relationship.area-of-responsibility.area-of-responsibility--info-text')" /></h2>
                    <p v-if="formBuilder.form.getItem('areasOfResponsibility').length === 0" class="additional-info q-mt-sm q-mb-none">{{ $t('common.contact.relationship.area-of-responsibility.no-area-of-responsibility-defined') }}</p>
                </div>

                <grid-card
                    v-for="repeaterItem in formBuilder.form.getItem('areasOfResponsibility').items"
                    :key="repeaterItem.id"
                    :columns="{ xs: 12, lg: 6 }"
                    align-actions="center"
                >
                    <div class="row q-col-gutter-sm">
                        <div class="col-xs-12">
                            <form-builder-next
                                :item="repeaterItem.getItem('name')"
                                :autofocus="state.currentMode === ViewMode.CREATE || state.currentMode === ViewMode.UPDATE && repeaterItem.index === 0"
                            />
                        </div>

                        <div class="col-xs-12">
                            <form-builder-next :item="repeaterItem.getItem('description')" />
                        </div>

                        <div class="col-xs-12">
                            <form-builder-next
                                :item="repeaterItem.getItem('emailAddressId')"
                                :options="emailAddressOptions"
                            />
                        </div>

                        <div class="col-xs-12">
                            <form-builder-next
                                :item="repeaterItem.getItem('phoneNumberId')"
                                :options="phoneNumberOptions"
                            />
                        </div>
                    </div>

                    <template v-slot:actions>
                        <base-button
                            :label="$tc('common.contact.relationship.area-of-responsibility.remove-area-of-responsibility', 1)"
                            flat
                            color="negative"
                            @click="repeaterItem.remove()"
                        />
                    </template>
                </grid-card>

                <template v-slot:repeater-item-buttons="{ item: repeaterItem }">&nbsp;</template>

                <template v-slot:buttons="{ item }">
                    <div class="col q-mt-md text-center">
                        <base-button
                            :label="$tc('common.contact.relationship.area-of-responsibility.add-area-of-responsibility', 1)"
                            primary-button
                            outline
                            @click="item.addItem()"
                        />
                    </div>
                </template>
            </form-builder-next>
        </template>
        <template v-slot:buttons="{ formBuilder }">
            <div class="row q-col-gutter-md q-mt-xs">
                <div class="col-xs-12 text-right">
                    <base-button
                        class="q-mr-sm"
                        primary-button
                        flat
                        data-test="btn:cancel"
                        :label="$t('common.term.cancel')"
                        :disable="formBuilder.formSubmitStatus"
                        @click="$emit('cancel')"
                    />
                    <base-button
                        primary-button
                        data-test="btn:save"
                        type="submit"
                        :label="state.currentMode === ViewMode.CREATE ? $t('common.term.add') : $t('common.term.save')"
                        :disable="formBuilder.formSubmitStatus"
                        :loading="formBuilder.formSubmitStatus"
                    />
                </div>
            </div>
        </template>
    </form-builder-next>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { kebabCase } from 'lodash'
import { ContactRelationshipForm } from '@/forms/contactRelationship'
import { ViewMode } from '@/enums'
import { InvalidContractRelationshipTypeForNotes, ValidContractRelationshipTypeForAreasOfResponsibility } from '@/enums'

export default {
    name: 'ContactRelationshipForm',
    props: {
        contact: {
            type: Object,
            required: true,
        },
        contactRelationship: {
            type: Object,
            default: () => null,
        },
        contactRelationships: {
            type: Array,
            default: () => [],
        },
    },
    setup (props, context) {
        // State
        const state = reactive({
            currentMode: props.contactRelationship ? ViewMode.UPDATE : ViewMode.CREATE,
            contactOptions: [],
            contactRelationshipDefinitionOptions: [],
        })

        // Template refs
        const formComponent = ref(null)

        // Initial data
        const initialData = {}
        if (state.currentMode === ViewMode.UPDATE) {
            Object.assign(initialData, {
                contactId: props.contactRelationship.getTargetNode(props.contact.id).id,
                definition: props.contactRelationship.getTargetType(props.contact.id),
            })

            if (props.contactRelationship.notes) initialData.notes = props.contactRelationship.notes
            if (props.contactRelationship.areasOfResponsibility && props.contactRelationship.areasOfResponsibility.length) {
                initialData.areasOfResponsibility = props.contactRelationship.areasOfResponsibility.map(area => {
                    const areaData = { name: area.name }
                    if (area.id) areaData.id = area.id
                    if (area.description) areaData.description = area.description
                    if (area.emailAddress) areaData.emailAddressId = area.emailAddress.id
                    if (area.phoneNumber) areaData.phoneNumberId = area.phoneNumber.id
                    return areaData
                })
            }
        }

        // Computed
        const selectedContact = computed(() => {
            return state.contactOptions.filter(contact => contact.id === formComponent.value.formBuilder.form.getItem('contactId').value)[0]
        })
        const excludeContactRelationshipDefinitions = computed(() => {
            if (!props.contactRelationships || !selectedContact.value || state.currentMode === ViewMode.UPDATE) return []
            return props.contactRelationships
                .filter(contactRelationship => ([contactRelationship.node1.id, contactRelationship.node2.id].includes(selectedContact.value.id)))
                .map(contactRelationship => contactRelationship.getTargetType(props.contact.id))
        })
        const selectedContactRelationshipDefinition = computed(() => {
            return state.contactRelationshipDefinitionOptions.filter(option => option.key === formComponent.value.formBuilder.form.getItem('definition').value)[0]
        })
        const selectedContactRelationshipDefinitionKey = computed(() => {
            if (!formComponent.value.formBuilder.form.getItem('definition').value) return null
            return formComponent.value.formBuilder.form.data.definition.split(':')[1] || ''
        })
        const isValidContactRelationshipTypeForNotes = computed(() => {
            if (props.contactRelationship) {
                return Object.keys(InvalidContractRelationshipTypeForNotes).every(invalidType => props.contactRelationship.definition.type !== invalidType)
            } else {
                if (!selectedContactRelationshipDefinition.value) return false
                return Object.keys(InvalidContractRelationshipTypeForNotes).every(invalidType => selectedContactRelationshipDefinition.value.type !== invalidType)
            }
        })
        const isValidContactRelationshipTypeForAreasOfResponsibility = computed(() => {
            if (props.contactRelationship) {
                return Object.keys(ValidContractRelationshipTypeForAreasOfResponsibility).some(validType => props.contactRelationship.definition.type === validType)
            } else {
                if (!selectedContactRelationshipDefinition.value) return false
                return Object.keys(ValidContractRelationshipTypeForAreasOfResponsibility).some(validType => selectedContactRelationshipDefinition.value.type === validType)
            }
        })
        const emailAddressOptions = computed(() => {
            const emailAddresses = props.contact.emailAddresses.map(emailAddress => ({
                emailAddress,
                contact: props.contact.getContactName(),
            }))

            if (selectedContact.value && selectedContact.value?.emailAddresses.length) {
                emailAddresses.push(...selectedContact.value.emailAddresses.map(emailAddress => ({
                    emailAddress,
                    contact: selectedContact.value.getContactName(),
                })))
            }

            return emailAddresses.map(entry => ({
                value: entry.emailAddress.id,
                label: `${entry.contact}: ${entry.emailAddress.email} (${entry.emailAddress.category.name}${entry.emailAddress.label ? ' / ' + entry.emailAddress.label.label : ''})`,
            }))
        })
        const phoneNumberOptions = computed(() => {
            const phoneNumbers = props.contact.phoneNumbers.map(phoneNumber => ({
                phoneNumber,
                contact: props.contact.getContactName(),
            }))

            if (selectedContact.value && selectedContact.value?.phoneNumbers.length) {
                phoneNumbers.push(...selectedContact.value.phoneNumbers.map(phoneNumber => ({
                    phoneNumber,
                    contact: selectedContact.value.getContactName(),
                })))
            }

            return phoneNumbers.map(entry => ({
                value: entry.phoneNumber.id,
                label: `${entry.contact}: ${entry.phoneNumber.phone_number} (${entry.phoneNumber.category.name}${entry.phoneNumber.label ? ' / ' + entry.phoneNumber.label.label : ''})`,
            }))
        })

        // Functions
        function setContactOptions (options) {
            state.contactOptions = options
        }
        function setContactRelationshipDefinitionsOptions (options) {
            state.contactRelationshipDefinitionOptions = options
        }
        function formDataHandler (formData) {
            const formDataClone = Object.assign({}, formData)
            if (!isValidContactRelationshipTypeForAreasOfResponsibility.value) delete formDataClone.areasOfResponsibility

            const data = {
                formData: formDataClone,
                contactRelationshipDefinitionKey: selectedContactRelationshipDefinitionKey.value,
                contactRelationshipDefinition: selectedContactRelationshipDefinition.value,
            }

            context.emit('submit', data)
        }

        // Hooks
        onMounted(() => {
            formComponent.value.formBuilder.setFormDataHandler(formDataHandler)
        })

        return {
            // Static
            ViewMode,
            ContactRelationshipForm,

            // Template refs
            formComponent,

            // State
            state,
            initialData,

            // Computed
            selectedContact,
            excludeContactRelationshipDefinitions,
            isValidContactRelationshipTypeForNotes,
            isValidContactRelationshipTypeForAreasOfResponsibility,
            emailAddressOptions,
            phoneNumberOptions,

            // Functions
            setContactOptions,
            setContactRelationshipDefinitionsOptions,
            kebabCase,
        }
    },
}
</script>
