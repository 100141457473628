import { i18n } from '@/i18n'
import { kebabCase } from 'lodash'
import { Model } from '@/models'
import { ContactRelationshipNodeType, ContactType } from '@/enums/graphql'
import { ContactRelationshipService } from '@/services'

export class ContactRelationship extends Model {
    /**
     * Create a ContactRelationship model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'definition',
            'node1',
            'node2',
            'notes',
            'areasOfResponsibility',

            // Timestamps
            'created_at',
            'updated_at',
        ], data)
    }

    static get service () {
        return ContactRelationshipService
    }

    /**
     * Create a new contactRelationship.
     * @param {string} definitionId - The values used to create the contactRelationship.
     * @param {string} node1Id
     * @param {string} node2Id
     * @param {Object} contactRelationshipInput - typeof ContactRelationshipInput
     * @param {Array} areasOfResponsibility - typeof AreaOfResponsibilityInput
     * @returns {Promise<*>}
     */
    static create ({ definitionId, node1Id, node2Id, contactRelationshipInput, areasOfResponsibility }) {
        const variables = {
            definitionId,
            node1Id,
            node2Id,
            contactRelationship: contactRelationshipInput,
            areasOfResponsibility,
        }
        return ContactRelationshipService.create(variables)
    }

    /**
     * Update this contactRelationship.
     * @param {Object} contactRelationshipInput - typeof ContactRelationshipInput
     * @param {Array} areasOfResponsibility - typeof AreaOfResponsibilityInput
     * @returns {Promise<*>}
     */
    update ({ contactRelationshipInput, areasOfResponsibility }) {
        const variables = {
            contactRelationship: contactRelationshipInput,
            areasOfResponsibility,
        }

        return ContactRelationshipService.update(this.id, variables).then(contactRelationship => {
            Object.assign(this, contactRelationship)
            return contactRelationship
        })
    }

    /**
     * Delete this contactRelationship.
     * @returns {Promise<*>}
     */
    delete () {
        return ContactRelationshipService.delete(this.id)
    }

    /**
     * Get the type of the relationship
     * @returns {string}
     */
    getRelationshipType () {
        return `${this.definition.type}`
    }

    /**
     * Get the type of the relationship in text form (translated)
     * @returns {string}
     */
    getRelationshipTypeText () {
        const node1TranslationPathGenderPart = this.node1.type === ContactType.PERSON ? kebabCase(this.node1.getGender()) : 'neutral'
        const node2TranslationPathGenderPart = this.node2.type === ContactType.PERSON ? kebabCase(this.node2.getGender()) : 'neutral'
        const node1Type = kebabCase(this.definition.node1_type)
        const node2Type = kebabCase(this.definition.node2_type)

        return `${i18n.tc(`common.contact.relationship.node-type-${node1TranslationPathGenderPart}.${node1Type}`, 1)}–${i18n.tc(`common.contact.relationship.node-type-${node2TranslationPathGenderPart}.${node2Type}`, 1)}`
    }

    /**
     * Get the relationship in text form (full sentence, translated)
     * @param {string} - sourceId - id of the source contact
     * @param {Boolean} - inverted - invert the relationship (A is the parent of B // B is the child of A)
     * @returns {string}
     */
    getRelationshipFullText (
        sourceNodeId,
        {
            inverted = false,
        } = {
            inverted: false,
        }
    ) {
        const contact = inverted ? this.getTargetNode(sourceNodeId).getContactName() : this.getSourceNode(sourceNodeId).getContactName()
        const useArticle = ![
            ContactRelationshipNodeType.REPRESENTATIVE,
            ContactRelationshipNodeType.PRINCIPAL,
            ContactRelationshipNodeType.MEMBER,
            ContactRelationshipNodeType.EMPLOYEE,
            ContactRelationshipNodeType.GENERAL_AGENCY,
            ContactRelationshipNodeType.MANAGED,
            ContactRelationshipNodeType.REVISED,
        ].includes(this.getTargetNodeType(sourceNodeId))
        const relation = inverted ? this.getTargetNodeTypeText(sourceNodeId,{ withArticle: useArticle, withPreposition: true }) : this.getSourceNodeTypeText(sourceNodeId,{ withArticle: useArticle, withPreposition: true })
        const someone = inverted ? this.getSourceNode(sourceNodeId).getContactName() : this.getTargetNode(sourceNodeId).getContactName()

        return i18n.t('common.contact.relationship.contact-is-related-to-someone', { contact: contact, relation: relation, someone: someone })
    }

    /**
     * Get the source node
     * @param {string} - sourceId - id of the source contact
     * @returns {*}
     */
    getSourceNode (sourceNodeId) {
        return this.node1.id === sourceNodeId ? this.node1 : this.node2
    }

    /**
     * Get the explicit type of the source node as a combination of the relationship type and the node type, f.e. `ROLE_A__ROLE_B:ROLE_A`.
     * @param {string} - sourceId - id of the source contact
     * @returns {string}
     */
    getSourceType (sourceNodeId) {
        const sourceType = this.node1.id === sourceNodeId ? this.definition.node1_type : this.definition.node2_type
        return `${this.definition.type}:${sourceType}`
    }

    /**
     * Get the type of the source node
     * @param {string} - sourceId - id of the source contact
     * @returns {*}
     */
    getSourceNodeType (sourceNodeId) {
        return this.node1.id === sourceNodeId ? this.definition.node1_type : this.definition.node2_type
    }

    /**
     * Get the type of the source node in text form (translated)
     * @param {string} - sourceId - id of the source contact
     * @param {Boolean} - withArticle - return text with definite article before the relationship type (f.e. 'the child')
     * @param {Boolean} - withPreposition - return text with preposition after the relationship type (f.e. 'child of')
     * @returns {string}
     */
    getSourceNodeTypeText (
        sourceNodeId,
        {
            withArticle = false,
            withPreposition = false,
        } = {
            withArticle: false,
            withPreposition: false,
        }
    ) {
        const sourceNodeTranslationPathGenderPart = this.getSourceNode(sourceNodeId).type === ContactType.PERSON ? kebabCase(this.getSourceNode(sourceNodeId).getGender()) : 'neutral'
        const sourceNodeType = kebabCase(this.getSourceNodeType(sourceNodeId))

        return `${i18n.tc(`common.contact.relationship.node-type-${sourceNodeTranslationPathGenderPart}${withArticle ? '--with-article--definite' : ''}.${sourceNodeType}`, 1)}${withPreposition ? ` ${i18n.t(`common.contact.relationship.node-type-prepositions.${kebabCase(this.getSourceNodeType(sourceNodeId))}`)}` : ''}`
    }

    /**
     * Get the target node
     * @param {string} - sourceId - id of the source contact
     * @returns {*}
     */
    getTargetNode (sourceNodeId) {
        return this.node1.id === sourceNodeId ? this.node2 : this.node1
    }

    /**
     * Get the explicit type of the target node as a combination of the relationship type and the node type, f.e. `ROLE_A__ROLE_B:ROLE_B`.
     * @param {string} - sourceId - id of the source contact
     * @returns {string}
     */
    getTargetType (sourceNodeId) {
        const targetType = this.node1.id === sourceNodeId ? this.definition.node2_type : this.definition.node1_type
        return `${this.definition.type}:${targetType}`
    }

    /**
     * Get the type of the target node
     * @param {string} - sourceId - id of the source contact
     * @returns {*}
     */
    getTargetNodeType (sourceNodeId) {
        return this.node1.id === sourceNodeId ? this.definition.node2_type : this.definition.node1_type
    }

    /**
     * Get the type of the target node in text form (translated)
     * @param {string} - sourceId - id of the source contact
     * @param {Boolean} - withArticle - return text with definite article before the relationship type (f.e. 'the child')
     * @param {Boolean} - withPreposition - return text with preposition after the relationship type (f.e. 'child of')
     * @returns {string}
     */
    getTargetNodeTypeText (
        sourceNodeId,
        {
            withArticle = false,
            withPreposition = false,
        } = {
            withArticle: false,
            withPreposition: false,
        }
    ) {
        const targetNodeTranslationPathGenderPart = this.getTargetNode(sourceNodeId).type === ContactType.PERSON ? kebabCase(this.getTargetNode(sourceNodeId).getGender()) : 'neutral'
        const targetNodeType = kebabCase(this.getTargetNodeType(sourceNodeId))

        return `${i18n.tc(`common.contact.relationship.node-type-${targetNodeTranslationPathGenderPart}${withArticle ? '--with-article--definite' : ''}.${targetNodeType}`, 1)}${withPreposition ? ` ${i18n.t(`common.contact.relationship.node-type-prepositions.${kebabCase(this.getTargetNodeType(sourceNodeId))}`)}` : ''}`
    }
}
