<template>
    <page-loading-indicator v-if="!contact" :number-of-breadcrumb-elements="3" subtitle />
    <page-wrapper v-else>
        <page-header :back-link-route="{ name: 'contact-detail', params: { id: contact.id } }" :back-link-label="$tc('views.contact.settings.back-to-contact', 1)">
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="contact.getContactName()" :to="{ name: 'contact-detail', params: { id: contact.id } }" />
                <q-breadcrumbs-el :label="$tc('views.contact.settings.settings', 2)" :to="{ name: 'contact-settings', params: { id: contact.id } }" />
                <q-breadcrumbs-el v-if="$route.meta && $route.meta.title" :label="$route.meta.title" />
            </template>

            <h1 class="contact-name">
                <contact-name :contact="contact" salutation />
                <small class="subtitle"> (<span data-test="text:contact-number">{{ contact.contactNumber.number }}</span>)</small>
                <small v-if="contact.personal_pronoun" class="subtitle"> ({{ contact.personal_pronoun }})</small>
            </h1>
        </page-header>

        <div class="row q-mt-md q-col-gutter-x-md">
            <div class="col-xs-12 col-md-3 col-lg-2">
                <in-page-navigation v-model="activeTab">
                    <q-route-tab
                        :to="{ name: 'contact-settings-contact-customer-relation', params: { id: id } }"
                        :label="$tc('views.contact.settings.customer-relation.customer-relation--title', 1)"
                        name="contact-consultant-relation-settings"
                        :ripple="false"
                        data-test="tab:contact-consultant-relation-settings"
                    />

                    <q-route-tab
                        v-if="!contact.consultingSettings"
                        v-can="'administrator'"
                        :to="{ name: 'contact-settings-consulting-settings-create', params: { id: id } }"
                        :label="$tc('common.contact.consulting-setting', 2)"
                        name="consulting-settings"
                        :ripple="false"
                        data-test="tab:consulting-settings"
                    />
                    <q-route-tab
                        v-else-if="contact.consultingSettings"
                        v-can="'administrator'"
                        :to="{ name: 'contact-settings-consulting-settings-update', params: { id: id } }"
                        :label="$tc('common.contact.consulting-setting', 2)"
                        name="consulting-settings"
                        :ripple="false"
                        data-test="tab:consulting-settings"
                    />

                    <q-route-tab
                        v-if="(contact.type === Contact.type.COMPANY && contact.consultingSettings !== null) && ($can('Feature:correspondence:core') && ($can('manage:own-company', 'DocumentLayout', contact) || $can('DocumentLayout:manage:subordinate-company')))"
                        :to="{ name: 'contact-settings-document-layout-list', params: { id: id } }"
                        :label="$tc('common.document-layout.document-layout', 2)"
                        name="document-layouts"
                        :ripple="false"
                        data-test="tab:document-layout-settings"
                    />
                </in-page-navigation>
            </div>
            <div class="col-xs-12 col-md-9 col-lg-10">
                <!-- TODO improvement @TFU: Check how to handle access on route level (e.g. documentLayoutCreate should only be accessible if the contact is a consulting company) -->
                <router-view :contact="contact" />
            </div>
        </div>

        <!-- TODO improvement: Re-add this using a `Teleport` component after upgrading to Vue 3 -->
        <!--
        <in-page-footer>
            <base-button
                :to="{ name: 'contact-detail', params: { id: contact.id } }"
                :label="$t('views.contact.settings.back-to-contact')"
                primary-button
                flat
                data-test="btn:back-to-contact"
            />
        </in-page-footer>
        -->
    </page-wrapper>
</template>

<script>
import ContactName from '@/components/contact/ContactName'
import InPageNavigation from '@/components/InPageNavigation'

import { Contact } from '@/models/contact'

export default {
    name: 'ContactSettings',
    meta () {
        return {
            title: !this.contact ? `${this.$t('common.term.loading')} …` : this.pageTitle,
        }
    },
    components: {
        ContactName,
        InPageNavigation,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        tab: {
            type: String,
        },
    },
    data () {
        return {
            Contact,
            contact: null,
            activeTab: this.tab,
        }
    },
    computed: {
        pageTitle () {
            const contactName = this.contact.getContactName({ salutation: true, title: true, contactNumber: true })
            const currentPage = (this.$route.meta && this.$route.meta.title) ? `${this.$tc('common.term.setting', 2)}: ${this.$route.meta.title}` : this.$tc('common.term.setting', 2)

            return `${contactName} – ${currentPage}`
        },
    },
    watch: {
        '$route' (to, from) {
            // Re-fetch if contact changes while view stays the same (e.g. navigate to other contact via global search).
            if (to.params.id !== from.params.id) {
                this.fetchObject()
            }
        },
    },
    created () {
        this.fetchObject()
    },
    methods: {
        fetchObject () {
            Contact.objects.get(this.id).then(contact => {
                this.contact = contact
            })
        },
    },
}
</script>
