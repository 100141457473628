import gql from 'graphql-tag'

export const STATEMENTS_QUERY = gql`
    query statements(
        $page: Int = 1
        $count: Int = 1000
        $orderField: StatementOrderField = CREATED_AT
        $orderDirection: OrderDirection = DESC
        $filterQuery: String
        $include_attachments: Boolean
        $is_shared: Boolean
        $filterCurrentQueueItemStatus: [QueueItemStatus!]
    ) {
        statements(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                query: $filterQuery
                include_attachments: $include_attachments
                is_shared: $is_shared
                current_queue_item_status: $filterCurrentQueueItemStatus
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                consultingEntity {
                    ... on Consultant {
                        id
                        contact_number
                        first_name
                        last_name
                        consulting_company_name
                    }
                    ... on ConsultingCompany {
                        id
                        contact_number
                        company_name
                    }
                }
                recipients {
                    consultants {
                        id
                        contact_number
                        first_name
                        last_name
                        gender
                        consultingCompany {
                            id
                        }
                        consulting_company_name
                    }
                }
                date_from
                date_until
                file_types
                include_attachments
                trigger_file_storage_item_sharing
                file_storage_item_shared_at
                currentQueueItem {
                    id
                    status
                    status_message
                }
                queueItems {
                    id
                    status
                    status_message
                }
                fileStorageItem {
                    id
                    type
                    files {
                        id
                        name
                        size
                        mime_type
                    }
                }
                created_at
                updated_at
            }
        }
    }
`
