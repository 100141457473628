import gql from 'graphql-tag'

export const CONTACT_UPDATE_MUTATION = gql`
    mutation contactUpdate(
        $input: ContactInputUpdate!
    ) {
        contactUpdate(
            input: $input
        ) {
            id
            contactNumber {
                id
                value_structure
                number
            }
            website
            customer_status
            customer_since
            customer_until
            consultants {
                id
                contact_number
                first_name
                last_name
                gender
                title
                consulting_company_name
                consulting_settings_status
                created_at
                updated_at
            }
            correspondenceLanguage {
                id
                name
            }
            ... on Person {
                gender
                personal_pronoun
                title
                profession
                role
                first_name
                last_name
                nationality {
                    id
                    name
                }
                civil_status
                driving_licence_since
                date_of_birth
            }
            ... on Company {
                company_name
                uid
                is_liable_to_vat
            }
        }
    }
`
