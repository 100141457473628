import gql from 'graphql-tag'

export const ADDRESS_LABELS_QUERY = gql`
    query addressLabels(
        $filterCategoryIds: [ID!]
    ) {
        addressLabels(
            search: {
                category_ids: $filterCategoryIds
            }
        ) {
            id
            key
            label
        }
    }
`
