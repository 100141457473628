import gql from 'graphql-tag'

export const STATEMENTS_DELETE_MUTATION = gql`
    mutation statementsDelete(
        $statement_ids: [ID!]!
    ) {
        statementsDelete(
            statement_ids: $statement_ids
        ) {
            status
        }
    }
`
