<template>
    <form
        class="row q-col-gutter-md"
        data-test="form:account-search"
        @submit.prevent="onFormSubmit"
    >
        <div class="col-xs-12">
            <form-builder item-key="balancePerDate" />
        </div>

        <div class="col-xs-12 col-sm-6 col-md-12 col-lg-6">
            <form-builder item-key="accountAssignedAsType" />
        </div>

        <div class="col-xs-12 col-sm-6 col-md-12 col-lg-6">
            <form-builder item-key="accountAssignedToType" />
        </div>

        <div class="col-xs-12 col-lg-8 col-xl-9">
            <form-builder
                item-key="assignedToConsultingSettingsStatus"
                :label="inactiveConsultingEntitiesLabel"
            />
        </div>

        <!-- TODO improvement: Actions should be passed through to the actions slot of the parent grid-card component. -->
        <div :class="['col-xs-12 col-lg-4 col-xl-3', { 'text-right': $q.screen.gt.md }]">
            <base-button
                primary-button
                type="submit"
                data-test="btn:submit"
                :label="$tc('common.accounting.account.show-account', 2)"
            />
        </div>
    </form>
</template>

<script>
import { i18n } from '@/i18n'
import { kebabCase } from 'lodash'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import { AccountAssignedToType } from '@/enums/graphql'
import ACCOUNT_SEARCH_FORM from '@/forms/accountSearch.form'
import { lightFormat } from 'date-fns'

export default {
    name: 'AccountSearchForm',
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: ACCOUNT_SEARCH_FORM,
    },
    data () {
        return {
            AccountAssignedToType,
            formData: {
                balancePerDate: lightFormat(new Date(), 'yyyy-MM-dd'),
            },
        }
    },
    computed: {
        inactiveConsultingEntitiesLabel () {
            if (this.formData.accountAssignedToType?.length === 1) {
                return i18n.t(`views.accounting.transfer-posting.include-inactive-consulting-entities--${kebabCase(this.formData.accountAssignedToType[0])}`)
            }
            return i18n.t('views.accounting.transfer-posting.include-inactive-consulting-entities')
        },
    },
}
</script>
