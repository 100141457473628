export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'excelMapping',
            label: 'forms.field.label.commission-list.excel-mapping-name',
            inputType: 'commissionListImportExcelMapping',
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'exchangeDateFrom',
            label: 'forms.field.label.commission-list.exchange-date-from',
            inputType: 'calendar',
            validators: [
                { type: 'required' },
                { type: 'date' },
                {
                    type: 'isBeforeOrEqualToDate',
                    params: {
                        target: 'exchangeDateTo',
                        targetLabel: 'common.term.exchange-date-to',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'exchangeDateTo',
            label: 'forms.field.label.commission-list.exchange-date-to',
            inputType: 'calendar',
            validators: [
                { type: 'required' },
                { type: 'date' },
                {
                    type: 'isAfterOrEqualToDate',
                    params: {
                        target: 'exchangeDateFrom',
                        targetLabel: 'common.term.exchange-date-from',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'commissionTotal',
            label: 'forms.field.label.commission-list.commission-total',
            inputType: 'text',
            validators: [
                { type: 'required' },
                {
                    type: 'decimal',
                },
                {
                    type: 'minValue',
                    params: {
                        'min': 0,
                    },
                },
            ],
        },
    ],
}






