import { Model } from '@/models'
import { computed } from 'vue'
import { formatCurrency } from '@/helpers/number'
import { formatDate } from '@/helpers/date'

export class PremiumInvoicePosition extends Model {
    /**
     * Create a PremiumInvoicePosition model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'type',
            'premium_from',
            'premium_to',
            'premium_kind',
            'premium_type',
            'sectorCode',
            'product_provider_sector_code',
            'currency',
            'annual_premium_amount',
            'instalment_surcharge',
            'taxes_statutory_charges',
            'invoiced_amount',
            'bonus_system',
            'premium_level',
            'commissionable_premium_amount',
        ], data)

        // Computed
        this.formattedAnnualPremiumAmount = computed( () => {
            return formatCurrency(this.annual_premium_amount)
        })

        this.formattedInstalmentSurcharge = computed( () => {
            return formatCurrency(this.instalment_surcharge)
        })

        this.formattedTaxesStatutoryCharges = computed( () => {
            return formatCurrency(this.taxes_statutory_charges)
        })

        this.formattedInvoicedAmount = computed( () => {
            return formatCurrency(this.invoiced_amount)
        })

        this.formattedBillingPeriodStart = computed( () => {
            return formatDate(this.premium_from)
        })

        this.formattedBillingPeriodEnd = computed( () => {
            return formatDate(this.premium_to)
        })

        this.formattedBillingPeriod = computed( () => {
            return `${this.formattedBillingPeriodStart.value}-${this.formattedBillingPeriodEnd.value}`
        })

        this.formattedIgb2bSectorCode = computed( () => {
            return `${this.sectorCode.code} – ${this.sectorCode.name}`
        })
    }
}
