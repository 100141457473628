<template>
    <page-loading-indicator
        v-if="!prefillCheckDone"
        :number-of-breadcrumb-elements="2"
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.commission.commission-distribution.commission-distribution', 1)" :to="{ name: 'commission-distribution-rule-sets' }" />
                <q-breadcrumbs-el :label="$tc('common.rule.add-rule-set', 1)" :to="{ name: 'commission-distribution-rule-set-create' }" />
            </template>

            <h1>{{ $tc('common.rule.add-rule-set', 1) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <commission-distribution-rule-set-form
                    ref="createForm"
                    :commission-distribution-rule-set="commissionDistributionRuleSet"
                    @submit="handleSubmit"
                />
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import { CommissionDistributionRuleSet } from '@/models/commissionDistributionRuleSet'
import { parseFormDataToRuleSetInput } from '@/helpers/commissionDistributionRuleSet'
import CommissionDistributionRuleSetForm from '@/components/commission/commissionDistributionRuleSet/CommissionDistributionRuleSetForm.vue'

export default {
    name: 'CommissionDistributionRuleSetCreate',
    meta () {
        return {
            title: this.$tc('common.rule.add-rule-set', 1),
        }
    },
    components: {
        CommissionDistributionRuleSetForm,
    },
    data () {
        return {
            name: null,
            description: null,
            commissionDistributionRuleSet: undefined,
            prefillCheckDone: false,
        }
    },
    created () {
        EventBus.$on('commissionDistributionRuleSet:get-rule-set:response', this.handleCreate)

        if (this.$route.query?.prefill) {
            CommissionDistributionRuleSet.objects.get(this.$route.query.prefill)
                .then(commissionDistributionRuleSet => {
                    this.commissionDistributionRuleSet = commissionDistributionRuleSet
                })
                .catch(() => {
                    this.prefillCheckDone = true
                })
                .finally(() => {
                    this.prefillCheckDone = true
                })
        } else {
            this.prefillCheckDone = true
        }
    },
    beforeDestroy () {
        EventBus.$off('commissionDistributionRuleSet:get-rule-set:response', this.handleCreate)
    },
    methods: {
        handleSubmit (formData) {
            this.name = formData.name
            this.description = formData.description

            EventBus.$emit('commissionDistributionRuleSet:get-rule-set')
        },
        handleCreate (rules) {
            const input = parseFormDataToRuleSetInput(this.name, this.description, rules)

            CommissionDistributionRuleSet
                .create(input)
                .then(() => {
                    this.$router.replace({ name: 'commission-distribution-rule-set-list' })
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.commission.commission-distribution-rule-set-created-success', 1),
                    })
                }).catch(error => {
                    this.$refs.createForm.showFormErrorMessage(error)
                    this.$refs.createForm.resetFormSubmitStatus()
                })
        },
    },
}
</script>
