<template>
    <!-- TODO: Fix issue with loading timing where the q-page does not (properly?) get its content: -->
    <!-- TypeError: Cannot read property 'getContactName' of null -->
    <loading-indicator v-if="loading" />
    <!-- TODO: Check if we can remove .q-gutter-y-md without breaking the layout -->
    <q-page v-else class="page-wrapper q-gutter-y-md" data-test="wrapper:main">
        <slot name="default" />
    </q-page>
</template>

<script>
export default {
    name: 'PageWrapper',
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.page-wrapper {
    // TODO: Add/refine styling (including adjustments for responsive behavior)
    padding: $sizeSpacingLg;
}
</style>
