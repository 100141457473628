import gql from 'graphql-tag'

export const CONTACT_CONSULTING_SETTINGS_CHANGE_STATUS_MUTATION = gql`
    mutation contactConsultingSettingsChangeStatus(
        $contact_id: ID!
        $status: ConsultingSettingsStatus!
    ) {
        contactConsultingSettingsChangeStatus(
            contact_id: $contact_id
            status: $status
        ) {
            status
            company {
                id
                company_name
            }
            currentAccount {
                id
                type
                status
                number
                name
            }
            cancellationReserveAccount {
                id
                type
                status
                number
                name
            },
            otherIncomeAccount {
                id
                type
                status
                number
                name
            },
            commissionIncomeAccount {
                id
                type
                status
                number
                name
            },
            mailProcessingRevenueAccount {
                id
                type
                status
                number
                name
            }
            statementRecipients {
                id
                consultants {
                    id
                }
            }
            commissionDistributionRuleSet {
                id
                name
                description
                is_assigned
            }
            mailProcessingSettings {
                active_from
                active_until
                mail_processing_is_active
                price_per_processed_mail_item
                notification_email_override
                error_notification_email
            }
        }
    }
`
