<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.dev.automation.automation', 1)" :to="{ name: 'dev-automation' }" />
                <q-breadcrumbs-el :label="$t('views.dev.automation.premium-invoice-premium-reminder')" :to="{ name: 'dev-automation-premium-invoice-premium-reminder' }" />
            </template>

            <h1 :data-is-ready="(!state.isProcessing).toString()">
                {{ $tc('views.dev.automation.premium-invoice-premium-reminder', 1) }}
                <status-badge
                    v-if="state.isProcessing"
                    status="Processing"
                    :loading="state.isProcessing"
                />
            </h1>
        </page-header>

        <form-builder-next
            :form="form"
            in-page-footer
            :submit-button-label="$tc('common.contract.search-contract', 2)"
            @submit="fetchObjects"
        />
    </page-wrapper>
</template>

<script>
import { ContractStatus } from '@/enums/graphql'
import { ContractService } from '@/services'
import { reactive, ref } from 'vue'
import { Form, FormField } from '@/libs/form'
import { useRouter } from '@/composables/router'
import StatusBadge from '@/components/StatusBadge.vue'

export default {
    name: 'DevAutomationPremiumInvoicePremiumReminder',
    meta () {
        return {
            title: `${this.$tc('views.dev.automation.automation', 1)}: ${this.$t('views.dev.automation.premium-invoice-premium-reminder')}`,
        }
    },
    components: { StatusBadge },
    setup () {
        // Composables
        const router = useRouter()

        // Data
        const state = reactive({
            isProcessing: false,
        })

        // Template refs
        const formComponent = ref(null)

        // Form
        const form = new Form()
        form.addField(new FormField('contractNumber', {
            label: 'common.contract.contract-number',
            type: 'text',
            validators: [
                { type: 'required' },
            ],
        }))
        form.addField(new FormField('jsonOutput', {
            label: 'JSON Output',
            type: 'textarea',
            attributes: {
                readonly: true,
                class: 'monospace',
            },
        }))

        // Functions
        async function fetchObjects () {
            state.isProcessing = true

            const variables = {
                filterStatus: [ContractStatus.ACTIVE, ContractStatus.TERMINATED],
                filterQuery: form.getItem('contractNumber').value,
            }
            const contractsResponse = await ContractService.all(variables, `
                id
                contractNumbers {
                    number
                }
                currentContractInformation {
                    product {
                        name
                        basicProvider {
                            name
                        }
                    }
                    managed_by_status
                }
                customer {
                    id
                    ... on Person {
                        first_name
                        last_name
                    }
                    ... on Company {
                        company_name
                    }
                    mainEmailAddress {
                        email
                    }
                }
                consultant {
                    first_name
                    last_name
                    consultingCompany {
                        company_name
                    }
                }
                created_at
            `)

            form.getItem('jsonOutput').setData(JSON.stringify(contractsResponse.data.map(contract => {
                const output = {
                    id: contract.id,
                    currentContractNumber: contract.currentContractNumber,
                    productProviderName: contract.currentContractInformation.product.basicProvider.name,
                    productName: contract.currentContractInformation.product.name,
                    managedByStatus: contract.currentContractInformation.managed_by_status,
                    consultantName: `${contract.consultant.first_name} ${contract.consultant.last_name}`,
                    consultingCompanyName: contract.consultant.consultingCompany.company_name,
                    customerMainEmailAddress: contract.customer.mainEmailAddress?.email || '',
                    createdAt: contract.created_at,
                    views: {
                        contractDetailDocuments: `${window.location.origin}${router.resolve({ name: 'contract-detail', params: { contactId: contract.customer.id, id: contract.id, tab: 'documents' } }).href}`,
                        contractUpdate: `${window.location.origin}${router.resolve({ name: 'contract-update', params: { contactId: contract.customer.id, id: contract.id } }).href}`,
                        premiumInvoiceCreate: `${window.location.origin}${router.resolve({ name: 'premium-invoice-create', params: { contactId: contract.customer.id, contractId: contract.id } }).href}`,
                    },
                }

                if (contract.customer.company_name) {
                    output.customerCompanyName = contract.customer.company_name
                } else {
                    output.customerFirstName = contract.customer.first_name
                    output.customerLastName = contract.customer.last_name
                }

                return output
            }), null, 4))

            formComponent.value.formBuilder.resetFormSubmitStatus()
            state.isProcessing = false
        }

        return {
            // Data
            state,

            // Template ref
            formComponent,

            // Form
            form,

            // Functions
            fetchObjects,
        }
    },
}
</script>
