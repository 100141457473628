import gql from 'graphql-tag'

export const CANCELLATION_UPDATE_MUTATION = gql`
    mutation cancellationUpdate(
        $id: ID!
        $cancellation_date: Date!
    ) {
        cancellationUpdate(
            id: $id
            cancellation_date: $cancellation_date
        ) {
            id
            status
            cancellation_date
        }
    }
`
