import gql from 'graphql-tag'

export const PHONE_NUMBER_CATEGORIES_QUERY = gql`
    query {
        phoneNumberCategories {
            id
            key
            name
        }
    }
`
