<template>
    <base-select-filter
        v-if="types.length"

        :value="value"
        v-bind="$attrs"
        :options="types"

        option-value="id"
        option-label="name"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { ConsultingFileService } from '@/services/consultingFile'

export default {
    name: 'ContractFileTypeSelect',
    components: {
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
    },
    data () {
        return {
            types: [],
        }
    },
    created () {
        ConsultingFileService.getContractFileTypes().then(contractFileTypes => {
            this.types = contractFileTypes
        })
    },
}
</script>
