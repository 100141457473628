<template functional>
    <div class="row q-mt-md">
        <div v-if="$slots.default" class="col-xs-12" :class="{ 'col-md-6': $slots.col2, 'q-mb-md': parent.$q.screen.lt.md }">
            <slot name="default" />
        </div>
        <div v-if="$slots.col2" class="col-xs-12" :class="{ 'col-md-6': $slots.default, 'text-right': parent.$q.screen.gt.sm }">
            <slot name="col2" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'FormNavigation',
}
</script>
