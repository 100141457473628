<template>
    <div class="row q-col-gutter-md">
        <div class="col-xs-12">
            <markup-table
                v-if="commissionRateAdjustmentRules.length"
                :columns="columns"
                :data="commissionRateAdjustmentRules"
                dense
                data-test="table:commission-rate-adjustment-rule-set"
            >
                <template v-if="commissionRateAdjustmentRules" v-slot:body-row="slotProps">
                    <markup-table-row v-if="!slotProps.row.editMode" :columns="columns" :row="slotProps.row">
                        <template v-slot:body-cell-name>
                            <span v-if="slotProps.row.model.custom_sector_code" class="additional-info">{{ $tc('common.commission.sector-code.custom-sector-code', 1) }}</span>
                        </template>

                        <template v-slot:body-cell-actions class="actions text-right">
                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-pencil"
                                data-test="btn:tooltip-actions-update"
                                @click="toggleUpdateFormVisibility(slotProps.row, slotProps.rowIndex)"
                            >
                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $t('common.term.update-action') }}</q-tooltip>
                            </q-btn>
                            <q-btn
                                color="negative"
                                size="sm"
                                flat
                                round
                                icon="mib-bin"
                                data-test="btn:tooltip-actions-delete"
                                @click="triggerCommissionRateAdjustmentRuleDelete(slotProps.row, $event)"
                            >
                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $t('common.term.delete') }}</q-tooltip>
                            </q-btn>
                        </template>
                    </markup-table-row>

                    <markup-table-row v-show="slotProps.row.editMode">
                        <commission-rate-adjustment-rule-form
                            v-if="slotProps.row.viewMode === ViewMode.UPDATE"
                            :ref="`updateForm-${slotProps.row.id}`"
                            :commission-rate-adjustment-rule="slotProps.row.model"
                            @submit="formData => handleSubmit(slotProps.rowIndex, formData)"
                            @cancel="toggleUpdateFormVisibility(slotProps.row)"
                        />
                        <commission-rate-adjustment-rule-form
                            v-else-if="slotProps.row.viewMode === ViewMode.CREATE"
                            @submit="formData => handleSubmit(slotProps.rowIndex, formData)"
                            @cancel="removeRule(slotProps.row)"
                        />
                    </markup-table-row>
                </template>
            </markup-table>

            <info-box
                v-else
                type="negative"
                no-margin
                data-test="error:global-message"
            >
                <p>{{ $t('common.rule.error--at-least-one-rule') }}</p>
            </info-box>
        </div>

        <div class="col-xs-12">
            <base-button
                primary-button
                outline
                data-test="btn:add"
                :label="$tc('common.rule.add-rule', 1)"
                @click="addRule"
            />
        </div>

        <form-dialog
            ref="commissionRateAdjustmentRuleDeleteDialog"
            :item="commissionRateAdjustmentRuleToDelete"
            dont-close-on-confirm
            @confirm="handleDelete"
            @hide="commissionRateAdjustmentRuleToDelete = null"
        >
            <template v-slot:title>{{ $tc('common.rule.delete-rule', 1) }}</template>
            <template v-slot:default>
                <i18n path="common.rule.delete-rule--for-entity--confirm--temp-component-interpolation-count" tag="p">
                    <template v-if="commissionRateAdjustmentRuleToDelete" v-slot:entity><b>{{ commissionRateAdjustmentRuleToDelete.model.formattedSectorCodeName }}</b> ({{ commissionRateAdjustmentRuleToDelete.model.formattedCommissionRate }} → {{ commissionRateAdjustmentRuleToDelete.model.formattedAdjustedCommissionRate }})</template>
                </i18n>
            </template>
        </form-dialog>
    </div>
</template>

<script>
import { ViewMode } from '@/enums'
import { EventBus } from '@/event-bus'
import FormDialog from '@/components/form/FormDialog'
import MarkupTable from '@/components/MarkupTable.vue'
import MarkupTableRow from '@/components/MarkupTableRow.vue'
import CommissionRateAdjustmentRuleForm from '@/components/commission/commissionRateAdjustmentRule/CommissionRateAdjustmentRuleForm.vue'

export default {
    name: 'CommissionRateAdjustmentRuleManage',
    components: {
        CommissionRateAdjustmentRuleForm,
        FormDialog,
        MarkupTable,
        MarkupTableRow,
    },
    props: {
        commissionRateAdjustmentRuleSetRules: {
            type: Array,
            required: false,
            default () {
                return []
            },
        },
    },
    data () {
        return {
            ViewMode,
            commissionRateAdjustmentRules: this.commissionRateAdjustmentRuleSetRules.length
                ? this.commissionRateAdjustmentRuleSetRules.map(entry => ({ id: entry.id, viewMode: ViewMode.UPDATE, editMode: false, model: entry }))
                : [],
            formData: [],
            tmpRuleIdCounter: 0,
            tmpRuleIdPrefix: 'tmp',
            commissionRateAdjustmentRuleToDelete: null,
            columns: [
                {
                    name: 'sector_code',
                    label: this.$tc('views.commission.rate-adjustment.column-title--sector-code', 1),
                    align: 'left',
                    field: row => row.model.igb2bSectorCode?.code || row.model.custom_sector_code,
                    required: true,
                    autoWidth: true,
                },
                {
                    name: 'name',
                    label: this.$tc('views.commission.rate-adjustment.column-title--name', 1),
                    align: 'left',
                    field: row => row.model.igb2bSectorCode?.name,
                    required: true,
                    maxWidth: '3fr',
                },
                {
                    name: 'commission_rate',
                    label: this.$tc('views.commission.rate-adjustment.column-title--rate', 1),
                    align: 'left',
                    field: row => row.model.formattedCommissionRate,
                    required: true,
                },
                {
                    name: 'adjusted_commission_rate',
                    label: this.$tc('views.commission.rate-adjustment.column-title--adjusted-rate', 1),
                    align: 'left',
                    field: row => row.model.formattedAdjustedCommissionRate,
                    required: true,
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left', required: true, autoWidth: true },
            ],
        }
    },
    created () {
        if (!this.commissionRateAdjustmentRules.length) this.addRule()
        EventBus.$on('shortcut:newEntity', this.addRule)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.addRule)
    },
    methods: {
        addRule () {
            const rule = { viewMode: ViewMode.CREATE, editMode: true, id: `${this.tmpRuleIdPrefix}-${this.tmpRuleIdCounter++}` }
            this.commissionRateAdjustmentRules.push(rule)
        },
        removeRule (rule) {
            const ruleToRemoveIndex = this.commissionRateAdjustmentRules.findIndex(commissionRateAdjustmentRule => commissionRateAdjustmentRule.id === rule.id)
            if (ruleToRemoveIndex > -1) this.commissionRateAdjustmentRules.splice(ruleToRemoveIndex, 1)
        },
        toggleUpdateFormVisibility (commissionRateAdjustmentRule) {
            this.$refs[`updateForm-${commissionRateAdjustmentRule.id}`].resetFormErrorMessage()
            this.$refs[`updateForm-${commissionRateAdjustmentRule.id}`].resetFormSubmitStatus()
            this.$refs[`updateForm-${commissionRateAdjustmentRule.id}`].resetInitialFormData()
            commissionRateAdjustmentRule.editMode = !commissionRateAdjustmentRule.editMode
        },
        handleSubmit (index, formData) {
            // As this function is called once for each rule form, we reset the collected formData on the first call.
            if (index === 0) this.formData = []

            // Add formData to collection.
            this.formData.push(formData)

            // All forms are valid, emit collected formData to proceed with the processing.
            if (this.formData.length === this.commissionRateAdjustmentRules.length) EventBus.$emit('commissionRateAdjustmentRuleSet:emit-rule-forms-data', this.formData)
        },
        triggerCommissionRateAdjustmentRuleDelete(commissionRateAdjustmentRule, $event) {
            $event.preventDefault()
            this.commissionRateAdjustmentRuleToDelete = commissionRateAdjustmentRule
            this.$refs.commissionRateAdjustmentRuleDeleteDialog.open()
        },
        handleDelete () {
            const ruleToDeleteIndex = this.commissionRateAdjustmentRules.findIndex(rule => rule.model.id === this.commissionRateAdjustmentRuleToDelete.model.id)
            if (ruleToDeleteIndex > -1) {
                this.commissionRateAdjustmentRules.splice(ruleToDeleteIndex, 1)
                this.$refs.commissionRateAdjustmentRuleDeleteDialog.close()
                EventBus.$emit('commissionRateAdjustmentRuleSet:submit-rule-forms-any-dirty-state', true)
            }
        },
    },
}
</script>
