<template>
    <form
        data-test="form:product-update"
        class="row q-col-gutter-y-sm"
        @submit.prevent="onFormSubmit"
        @keyup.esc="cancel"
    >
        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="formErrorMessage"
            type="negative"
            data-test="error:global-message"
            v-html="formErrorMessage"
        />
        <!-- eslint-enable -->

        <div class="col-xs-12">
            <form-builder item-key="name" autofocus />
        </div>

        <div class="col-xs-12 q-mt-sm q-gutter-sm">
            <base-button
                :label="$t('common.term.cancel')"
                flat
                primary-button
                @click="cancel"
            />
            <base-button
                data-test="btn:submit"
                type="submit"
                :label="$t('common.term.save')"
                :disable="formSubmitStatus"
                :loading="formSubmitStatus"
                primary-button
            />
        </div>
    </form>
</template>

<script>
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import PRODUCT_FIELDS from '@/forms/product.fields.json'

export default {
    name: 'ProductUpdateForm',
    mixins: [formBuilderMixin],
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    formBuilderSettings: {
        schema: PRODUCT_FIELDS,
        fields: ['name'],
    },
    data () {
        return {
            formData: {
                name: this.product.name,
            },
        }
    },
    methods: {
        handleFormData ({ name }) {
            this.product.update(name)
                .then(() => {
                    this.$emit('update')
                })
                .catch(error => {
                    this.showFormErrorMessage(error)
                    this.resetFormSubmitStatus()
                })
        },
        cancel () {
            this.$emit('cancel')
        },
    },
}
</script>
