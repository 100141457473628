import { Extension } from '@tiptap/core'

export const StructureBlockSelectAll = Extension.create({
    name: 'structureBlockSelectAll',

    addKeyboardShortcuts() {
        return {
            'Mod-a': () => {
                const from = this.editor.state.selection.$anchor.start(1)
                const to = this.editor.state.selection.$anchor.end(1)
                this.editor.commands.setTextSelection({ from, to })
                return true
            },
        }
    },
})
