import gql from 'graphql-tag'

export const PRODUCT_FIELD_GROUPS_QUERY = gql`
    query productFieldGroups {
        productFieldGroups {
            id
            label
            key
            children {
                id
                key
                label
                input_type
                data_type
                validators
                extra_attributes
            }
        }
    }
`
