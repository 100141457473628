<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('views.dev.design.design')" :to="{ name: 'dev-design' }" />
                <q-breadcrumbs-el :label="$t('views.dev.design.icons.title')" :to="{ name: 'dev-design-icons' }" />
            </template>

            <h1>{{ $t('views.dev.design.icons.title') }} <span v-if="maxIcons.length && maxIconsBold.length" class="subtitle">{{ `(${maxIcons.length + maxIconsBold.length} ${$tc('common.term.icon', maxIcons.length)})` }}</span></h1>

            <template v-slot:afterContent>
                <div class="row">
                    <div class="col-xs-12 col-lg-4">
                        <q-slider
                            v-model="iconSize"
                            :min="0.7"
                            :max="3"
                            :step="0.1"
                            label
                            :label-value="`${$tc('common.term.size', 1)}: ${iconSize}em`"
                            color="primary"
                        />
                    </div>
                    <div class="col-xs-12 col-lg-8 q-gutter-sm text-center">
                        <q-radio
                            v-model="iconColor"
                            val="text-primary"
                            label="text-primary"
                            color="text-primary"
                            keep-color
                        />
                        <q-radio
                            v-for="color in mainAppColors"
                            :key="`color-${color.name}`"
                            v-model="iconColor"
                            :val="color.name"
                            :label="color.name"
                            :color="color.name"
                            keep-color
                        />
                    </div>
                </div>
            </template>
        </page-header>

        <!-- TODO improvement @MTR: Add table/list view with icon info. -->
        <!-- TODO improvement @MTR: Add filter functionality to filter/search for icon/file name -->
        <div class="row q-mb-xl">
            <div class="col-xs-12">
                <h2>max-icons <span v-if="maxIcons.length" class="subtitle">{{ `(${maxIcons.length} ${$tc('common.term.icon', maxIcons.length)})` }}</span></h2>
                <inline-svg
                    id="max-icons-wrapper"
                    class="svg-font-wrapper"
                    :src="require('@/assets/fonts/icon-fonts/max-icons.svg')"
                />

                <div class="row items-start q-gutter-md">
                    <template v-if="maxIcons.length">
                        <q-card
                            v-for="icon in maxIcons"
                            :key="icon"
                            class="icon-wrapper cursor-pointer"
                            square
                            flat
                        >
                            <q-card-section class="q-pa-none text-center">
                                <q-btn
                                    unelevated
                                    class="icon-context-menu-trigger"
                                    :size="`${iconSize}em`"
                                    :icon="`mi-${icon}`"
                                    :text-color="iconColor"
                                >
                                    <dev-icon-context-menu :icon="icon" icon-prefix="mi-" :icon-color="iconColor" />
                                </q-btn>
                            </q-card-section>
                        </q-card>
                    </template>
                    <loading-indicator v-else auto-height size="1.5em" />
                </div>
            </div>
        </div>

        <q-separator />

        <div class="row">
            <div class="col-xs-12">
                <h2>max-icons-bold <span v-if="maxIconsBold.length" class="subtitle">{{ `(${maxIconsBold.length} ${$tc('common.term.icon', maxIconsBold.length)})` }}</span></h2>
                <inline-svg
                    id="max-icons-bold-wrapper"
                    class="svg-font-wrapper"
                    :src="require('@/assets/fonts/icon-fonts/max-icons-bold.svg')"
                />

                <div class="row items-start q-gutter-md">
                    <template v-if="maxIconsBold.length">
                        <q-card
                            v-for="icon in maxIconsBold"
                            :key="icon"
                            class="icon-wrapper cursor-pointer"
                            square
                            flat
                        >
                            <q-card-section class="q-pa-none text-center">
                                <q-btn
                                    unelevated
                                    class="icon-context-menu-trigger"
                                    :size="`${iconSize}em`"
                                    :icon="`mib-${icon}`"
                                    :text-color="iconColor"
                                >
                                    <dev-icon-context-menu :icon="icon" icon-prefix="mib-" :icon-color="iconColor" />
                                </q-btn>
                            </q-card-section>
                        </q-card>
                    </template>
                    <loading-indicator v-else auto-height size="1.5em" />
                </div>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import DevIconContextMenu from '@/components/dev/DevIconContextMenu.vue'

export default {
    name: 'DevDesignIcons',
    meta () {
        return {
            title: this.$t('views.dev.design.icons.title'),
        }
    },
    components: {
        DevIconContextMenu,
    },
    data () {
        return {
            iconSize: 1.2,
            iconColor: 'text-primary',
            mainAppColors: [
                { name: 'primary' },
                { name: 'secondary' },
                { name: 'accent' },
                { name: 'positive' },
                { name: 'negative' },
                { name: 'warning' },
                { name: 'info' },
            ],
            maxIcons: [],
            maxIconsBold: [],
        }
    },
    mounted () {
        this.waitForElement('#max-icons-wrapper').then(el => this.maxIcons = this.getIconsFromSVGFont(el))
        this.waitForElement('#max-icons-bold-wrapper').then(el => this.maxIconsBold = this.getIconsFromSVGFont(el))
    },
    methods: {
        // TODO @TFU: Review and discuss with @MTR. (Naming? Would it maybe even make sense to have this available globally?)
        waitForElement (selector) {
            // https://stackoverflow.com/a/61511955
            return new Promise(resolve => {
                if (document.querySelector(selector)) {
                    return resolve(document.querySelector(selector))
                }

                const observer = new MutationObserver(mutations => {
                    if (document.querySelector(selector)) {
                        resolve(document.querySelector(selector))
                        observer.disconnect()
                    }
                })

                observer.observe(document.body, {
                    childList: true,
                    subtree: true,
                })
            })
        },
        getIconsFromSVGFont (svg) {
            // Creative workaround to access file content of the SVG font file without the need to access the file system.
            // Use existing inline SVG loader (see implementation above) and access the (text) content of the element afterwards.
            let str = svg.innerHTML
            // Create an array of all icon / glyph names.
            let regex = /glyph-name="(.+?)"/gmi
            let result = regex[Symbol.matchAll](str)
            // TODO improvement: Tweak this so that not both replacements are necessary.
            result = Array.from(result, x => x[0])
            result = result.map(function (x) {
                return x.replace(regex, /$1/)
            })
            result = result.map(function (x) {
                return x.replace(/\//g, '')
            })
            return result
        },
    },
}
</script>

<style lang="scss" scoped>
.svg-font-wrapper {
    @include visuallyhidden;
}

.icon-wrapper {
    width: auto;
    border: 1px solid var(--color-border-primary);
}
</style>
