<template>
    <div>
        <div class="row print-row-lg">
            <div class="col-xs-12 q-gutter-x-sm q-mb-md">
                <p class="inline-block">{{ $tc('common.term.quick-filter', 2) }}:</p>
                <base-button-toggle
                    v-model="filterCreatorId"
                    :options="[
                        { label: $t('common.comment.filter-only-own-comments'), value: $store.state.user.consultant.id }
                    ]"
                    clearable
                    data-test="btn:comments-only-mine"
                />
                <base-button-toggle
                    v-model="filterPinned"
                    :options="[
                        { label: $t('common.comment.filter-only-pinned-comments'), value: true }
                    ]"
                    clearable
                    data-test="btn:comments-only-pinned"
                />
            </div>

            <div class="col-xs-12 col-md-7 col-lg-7 col-xl-6">
                <ul v-if="filteredItems.length" class="comment-list" data-test="list:comment-list">
                    <li
                        v-for="comment in filteredItems"
                        :id="`c${comment.id}`"
                        :key="comment.id"
                        :class="['comment-item q-mb-md', { 'pinned-comment': comment.pinned, 'target-comment': ($route.hash === `#c${comment.id}`)}]"
                        data-test="item:comment"
                    >
                        <q-avatar
                            class="comment-avatar"
                            color="background-secondary"
                            :style="`color: ${stringToColor(comment.creatorName)};`"
                            data-test="text:comment-avatar"
                        >
                            {{ getContactInitials(comment.creator, { maxLength: 2 }) }}
                        </q-avatar>

                        <div class="comment-item-content-wrapper">
                            <div v-if="comment.pinned || editMode" class="comment-actions-wrapper">
                                <div v-if="!editMode" class="pinned-comment-info q-pt-xs q-pr-xs">
                                    <q-icon name="mib-flag-1" color="accent" />
                                    <q-tooltip
                                        :delay="1000"
                                        anchor="center right"
                                        self="center left"
                                        :offset="[10, 0]"
                                        transition-show="scale"
                                        transition-hide="scale"
                                    >
                                        {{ $tc('common.comment.pinned-comment-info', 1) }}
                                    </q-tooltip>
                                </div>

                                <base-button
                                    v-if="editMode && $can('update', comment)"
                                    data-test="btn:update-comment-pinned"
                                    round
                                    color="none"
                                    text-color="edit-mode-link"
                                    size="xs"
                                    class="q-ml-xs"
                                    :icon="(comment.pinned) ? 'mib-unflag-1' : 'mib-flag-1'"
                                    @click="comment.togglePinned()"
                                >
                                    <q-tooltip
                                        :delay="1000"
                                        anchor="center right"
                                        self="center left"
                                        :offset="[10, 0]"
                                        transition-show="scale"
                                        transition-hide="scale"
                                    >
                                        {{ (comment.pinned) ? $tc('common.comment.unpin-comment', 1) : $tc('common.comment.pin-comment', 1) }}
                                    </q-tooltip>
                                </base-button>

                                <base-button
                                    v-if="editMode && $can('update', comment)"
                                    data-test="btn:update-comment"
                                    round
                                    color="none"
                                    text-color="edit-mode-link"
                                    size="xs"
                                    class="q-ml-xs"
                                    icon="mib-pencil"
                                    @click="triggerUpdateItem(comment)"
                                >
                                    <q-tooltip
                                        :delay="1000"
                                        anchor="center right"
                                        self="center left"
                                        :offset="[10, 0]"
                                        transition-show="scale"
                                        transition-hide="scale"
                                    >
                                        {{ $tc('common.comment.edit-comment', 1) }}
                                    </q-tooltip>
                                </base-button>

                                <base-button
                                    v-if="editMode && $can('delete', comment)"
                                    data-test="btn:delete-comment"
                                    round
                                    color="none"
                                    text-color="edit-mode-link"
                                    size="xs"
                                    icon="mib-bin"
                                    @click="triggerDeleteItem(comment)"
                                >
                                    <q-tooltip
                                        :delay="1000"
                                        anchor="center right"
                                        self="center left"
                                        :offset="[10, 0]"
                                        transition-show="scale"
                                        transition-hide="scale"
                                    >
                                        {{ $tc('common.comment.delete-comment', 1) }}
                                    </q-tooltip>
                                </base-button>
                            </div>

                            <div class="comment-content" data-test="text:comment-message">{{ comment.text }}</div>

                            <div class="comment-meta additional-info">
                                <!-- TODO: Custom relative date function -->
                                <span class="comment-creator" :class="{ 'is-current-user': comment.createdByCurrentUser }" data-test="text:comment-creator">{{ comment.creatorName }}</span> – <span class="comment-date" data-test="text:comment-datetime">{{ comment.formattedCreatedAtDate }}, {{ comment.formattedCreatedAtTime }}</span>
                                <span v-if="comment.created_at !== comment.updated_at" class="comment-edited-info"> ({{ $t('common.term.edited') }})<q-tooltip
                                    :delay="1000"
                                    anchor="center right"
                                    self="center left"
                                    :offset="[10, 0]"
                                    transition-show="scale"
                                    transition-hide="scale"
                                >{{ $t('common.term.updated-on--date-time', { date: comment.formattedUpdatedAtDate, time: comment.formattedUpdatedAtTime }) }}</q-tooltip></span>
                            </div>
                        </div>
                    </li>
                </ul>

                <p v-else class="additional-info" data-test="text:comment-message">{{ $tc('common.comment.no-comment', 2) }}</p>
            </div>
        </div>

        <!-- Create item -->
        <form-dialog
            v-if="currentMode === ViewMode.CREATE"
            ref="createItemDialog"
            :form-builder-settings="formBuilderSettings"
            :initial-form-data="initialFormData"
            dont-close-on-confirm
            no-backdrop-dismiss
            no-route-dismiss
            @confirm="handleCreateItem"
            @hide="cancelAction"
        >
            <template v-slot:title>{{ $tc('common.comment.add-comment', 1) }}</template>
        </form-dialog>

        <!-- Update item -->
        <form-dialog
            v-if="currentMode === ViewMode.UPDATE && currentItem"
            ref="updateItemDialog"
            :form-builder-settings="formBuilderSettings"
            :initial-form-data="initialFormData"
            dont-close-on-confirm
            no-backdrop-dismiss
            no-route-dismiss
            @confirm="handleUpdateItem"
            @hide="cancelAction"
        >
            <template v-slot:title>{{ $tc('common.comment.edit-comment', 1) }}</template>
        </form-dialog>

        <!-- Delete item -->
        <form-dialog
            v-if="currentMode === ViewMode.DELETE && currentItem"
            ref="deleteItemDialog"
            :item="currentItem"
            dont-close-on-confirm
            @confirm="handleDeleteItem"
            @hide="cancelAction"
        >
            <template v-slot:title>{{ $tc('common.comment.delete-comment', 1) }}</template>
            <template v-slot:default="slotProps">
                <i18n v-if="slotProps.item" :path="`common.comment.delete-${(slotProps.item.createdByCurrentUser) ? 'own-comment' : 'comment'}--confirm`" tag="div">
                    <template v-slot:author><b>{{ slotProps.item.creatorName }}</b></template>
                    <template v-slot:date><span>{{ slotProps.item.formattedCreatedAtDate }}</span></template>
                    <template v-slot:comment>
                        <blockquote class="pre-wrap">{{ slotProps.item.text }}</blockquote>
                    </template>
                </i18n>
            </template>
        </form-dialog>

        <in-page-footer>
            <base-button
                :label="$tc('common.comment.add-comment', 1)"
                icon="mib-messages-bubble-square-add"
                primary-button
                data-test="btn:create-comment"
                @click="triggerCreateItem"
            />
        </in-page-footer>
    </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import { UserSettings } from '@/helpers/user'
import { viewModeManagerMixin } from '@/mixins/viewModeManagerMixin'

import FormDialog from '@/components/form/FormDialog'

import { ViewMode } from '@/enums'
import { Comment } from '@/models/comment'
import COMMENT_FORM from '@/forms/comment.fields.json'
import BaseButtonToggle from '@/components/form/BaseButtonToggle.vue'
import { getContactInitials } from '@/helpers/contact'
import { stringToColor } from '@/helpers'

export default {
    name: 'CommentList',
    components: {
        FormDialog,
        BaseButtonToggle,
    },
    mixins: [
        viewModeManagerMixin,
    ],
    props: {
        items: {
            type: Array,
            required: true,
        },
        targetObjectType: {
            type: String,
            required: true,
        },
        targetObjectId: {
            required: true,
        },
    },
    data () {
        const userSettings = new UserSettings(this.$store.state.user)
        return {
            userSettings,
            loading: false,
            filterCreatorId: userSettings.getItem(`Comments.${this.targetObjectType}.filterCreatorId`) === 'true' ? this.$store.state.user.consultant.id : null,
            filterPinned: userSettings.getItem(`Comments.${this.targetObjectType}.filterPinned`) === 'true' ? true : null,
        }
    },
    computed: {
        formBuilderSettings () {
            const schema = JSON.parse(JSON.stringify(COMMENT_FORM))
            const settings = { schema }
            return settings
        },
        initialFormData () {
            if (this.currentItem) {
                return {
                    text: this.currentItem.text,
                    pinned: this.currentItem.pinned,
                }
            } else {
                return {}
            }
        },
        filteredItems () {
            let filteredItems = Array.from(this.items)
            if (this.filterCreatorId !== null) filteredItems = filteredItems.filter(item => item.creator.id === this.filterCreatorId)
            if (this.filterPinned !== null) filteredItems = filteredItems.filter(item => item.pinned)
            return filteredItems
        },
    },
    watch: {
        targetObjectId () {
            this.fetchObjects()
        },
        filterCreatorId (newValue) {
            const value = (newValue !== null)
            if (value === true) {
                this.userSettings.setItem(`Comments.${this.targetObjectType}.filterCreatorId`, value)
            } else {
                this.userSettings.removeItem(`Comments.${this.targetObjectType}.filterCreatorId`)
            }
        },
        filterPinned (newValue) {
            if (newValue !== null) {
                this.userSettings.setItem(`Comments.${this.targetObjectType}.filterPinned`, newValue)
            } else {
                this.userSettings.removeItem(`Comments.${this.targetObjectType}.filterPinned`)
            }
        },
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
        this.$on(ViewMode.UPDATE.toLowerCase(), this.onUpdate)
        this.$on(ViewMode.DELETE.toLowerCase(), this.onDelete)
        this.fetchObjects()
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        fetchObjects () {
            this.loading = true
            Comment.objects.all({
                targetObject: {
                    object_type: this.targetObjectType,
                    object_id: this.targetObjectId,
                },
            }).then(response => {
                this.loading = false
                this.items.length = 0
                this.items.push(...response.data)
                this.$emit('numberOfComments', response.paginatorInfo.total)
            })
        },
        handleCreateItem (formData) {
            Comment.create({ objectType: this.targetObjectType, objectId: this.targetObjectId }, formData)
                .then(comment => {
                    this.items.unshift(comment)
                    this.emitCreate(comment)
                    this.$emit('numberOfComments', this.items.length)
                    this.$refs.createItemDialog.close()
                    // TODO @MTR: Add notification?
                })
                .catch(error => {
                    this.$refs.createItemDialog.showFormErrorMessage(error)
                    this.$refs.createItemDialog.resetFormSubmitStatus()
                })
        },
        onUpdate (comment) {
            const index = this.items.findIndex(item => item.id === comment.id)
            if (index > -1) {
                this.items.splice(index, 1, comment)
            }
        },
        onDelete (comment) {
            const index = this.items.findIndex(item => item.id === comment.id)
            if (index > -1) {
                this.items.splice(index, 1)
                this.$emit('numberOfComments', this.items.length)
            }
        },
        onNewEntity () {
            this.triggerCreateItem()
        },
        getContactInitials (...args) {
            return getContactInitials(...args)
        },
        stringToColor (...args) {
            return stringToColor(...args)
        },
    },
}
</script>

<style lang="scss" scoped>
@keyframes commentHighlighting {
    0%, 50% {
        box-shadow: (-($sizeSpacingXs)) (-($sizeSpacingXs)) 0 var(--q-color-accent),
        $sizeSpacingXs (-($sizeSpacingXs)) 0 var(--q-color-accent),
            (-($sizeSpacingXs)) $sizeSpacingXs 0 var(--q-color-accent),
        $sizeSpacingXs $sizeSpacingXs 0 var(--q-color-accent);

        //outline: 2px solid var(--q-color-accent);

        background-color: var(--q-color-accent) !important;
    }
    100% {
        //box-shadow: 0 0 0 transparent;
        box-shadow: 0 0 $sizeSpacingXs transparent;

        //outline: 2px solid transparent;

        //box-shadow: $sizeSpacingXs 0 0 transparent inset;

        background-color: currentColor !important;
    }
}

.comment-list,
.pinned-comment-list {
    @include resetList();

    display: flex;
    flex-flow: column;
}

.comment-item {
    display: flex;

    &.target-comment {

        ::v-deep .comment-item-content-wrapper, {
            //color: var(--q-color-accent) !important;
            //outline: 2px solid var(--q-color-accent);

            animation-name: commentHighlighting;
            //animation-delay: 1s; // Added to give the app time to navigate to the route before the animation starts.
            animation-duration: 3s;
            animation-timing-function: ease-in-out;
        }
    }

    .q-avatar {
        align-self: flex-end;
    }
}

// Pinned comment info in regular comment list
.pinned-comment-info {
    display: inline-block;
    font-size: 12px;
}

.comment-item-content-wrapper {
    $triangleSize: #{$sizeSpacingSm};

    position: relative;
    flex: 1 1 100%;
    margin-left: #{$sizeSpacingMd};
    padding: #{$sizeSpacingSm} (#{$sizeSpacingSm + $sizeSpacingXs});
    border-radius: #{$sizeSpacingXs} #{$sizeSpacingXs} #{$sizeSpacingXs} 0;

    background-color: var(--q-color-primary-lighter);

    &::before {
        content: "";
        position: absolute;
        width: $triangleSize;
        height: $triangleSize;
        left: (-($triangleSize));
        bottom: 0;

        border: calc(#{$triangleSize} / 2) solid transparent;

        border-right-color: var(--q-color-primary-lighter);
        border-bottom-color: var(--q-color-primary-lighter);
    }

    .pinned-comment & {
        background-color: var(--q-color-accent-lighter);

        &::before {
            border-right-color: var(--q-color-accent-lighter);
            border-bottom-color: var(--q-color-accent-lighter);
        }
    }

}

.comment-actions-wrapper {
    float: right;
    margin-top: (-($sizeSpacingXs / 2));
    margin-right: (-($sizeSpacingXs / 2));
    margin-left: $sizeSpacingSm;
    margin-bottom: $sizeSpacingSm;
}

.comment-content {
    white-space: pre-wrap;
}

.comment-meta {
    margin-top: $sizeSpacingSm;
    font-size: small;
}

.comment-edited-info {
    cursor: help;

    font-size: smaller;
}

// Pinned comments (e.g. on contact and contract detail views)
.pinned-comment-list {

    .comment-actions-wrapper {
        margin-top: (-($sizeSpacingXs));
        //margin-right: (-($sizeSpacingXs));
        margin-bottom: $sizeSpacingXs;
    }

    .q-message-text:last-child {
        min-height: auto;
    }
}
</style>
