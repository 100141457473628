<template>
    <div>
        <page-header no-breadcrumbs>
            <h2>{{ $tc('common.contact.consulting-setting', 2) }}</h2>
        </page-header>

        <div class="row q-col-gutter-sm">
            <div class="col-xs-12">
                <consulting-settings-form
                    v-if="showCreateForm"
                    ref="consultingSettingsForm"
                    :contact="contact"
                    @cancel="showCreateForm = false"
                    @post-handle-form-data="onSubmit"
                />
                <info-box
                    v-if="!showCreateForm"
                    type="warning"
                    :inline-actions="$q.screen.width > 1300"
                >
                    <p>{{ $t(`views.admin.consulting-settings.description-text-${contact.type.toLowerCase()}`) }}</p>
                    <p>{{ $tc('views.admin.consulting-settings.contact-does-not-have-consulting-settings-yet--name', 1, { name: contact.getContactName() }) }}</p>

                    <template v-slot:action>
                        <base-button
                            :label="$t(`views.admin.consulting-settings.create-consulting-entity--${toKebabCase(contact.type.toLowerCase())}${contact.type === ContactType.PERSON ? '--' + toKebabCase(contact.gender.toLowerCase()) : ''}`)"
                            primary-button
                            outline
                            @click="showCreateForm = !showCreateForm"
                        />
                    </template>
                </info-box>
            </div>
        </div>
    </div>
</template>

<script>
import { kebabCase } from 'lodash'
import { ContactType } from '@/enums/graphql'
import { ConsultingSettings } from '@/models/consultingSettings'
import { Contact } from '@/models/contact'
import { validateConsultingSettingsFormData, parseFormDataToConsultingSettingsInput } from '@/helpers/contact'
import consultingSettingsForm from '@/components/consulting-settings/ConsultingSettingsForm'

export default {
    name: 'ContactConsultingSettingsCreate',
    components: {
        consultingSettingsForm,
    },
    props: {
        contact: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            Contact,
            ContactType,
            showCreateForm: false,
        }
    },
    methods: {
        onSubmit (formData) {
            const errorMessages = validateConsultingSettingsFormData(formData)

            this.$refs.consultingSettingsForm.resetFormSubmitStatus()

            if (errorMessages.graphQLErrors.length) {
                this.$refs.consultingSettingsForm.showFormErrorMessage(errorMessages)
                return
            } else {
                this.$refs.consultingSettingsForm.resetFormErrorMessage()
            }

            const variables = parseFormDataToConsultingSettingsInput(formData, this.$store.state.user)
            if (typeof formData.signature !== 'undefined') variables.signature = formData.signature

            ConsultingSettings.create(this.contact.id, variables)
                .then(consultingSettings => {
                    this.contact.consultingSettings = consultingSettings
                    this.$router.replace({ name: 'contact-settings-consulting-settings-update' })
                    this.$q.notify({
                        type: 'positive',
                        message: this.$t(`common.notifications.consulting-settings.consulting-settings-created-success--${kebabCase(this.contact.type.toLowerCase())}${this.contact.type === ContactType.PERSON ? '--' + this.contact.gender.toLowerCase() : ''}`, { name: this.contact.getContactName() }),
                    })
                })
                .catch(error => {
                    this.$refs.consultingSettingsForm.showFormErrorMessage(error)
                    this.$refs.consultingSettingsForm.resetFormSubmitStatus()
                })
        },
        cancel () {
            this.showCreateForm = false
        },
        toKebabCase: function (text) {
            return kebabCase(text)
        },
    },
}
</script>
