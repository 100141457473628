<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="`${$tc('views.correspondence.mail-processing.cover-letter', 2)} (${$t('common.mail-processing.mail-processing')})`" :to="{ name: 'mail-processing-template-list' }" data-test="breadcrumb:mail-processing-template-list" />
            </template>

            <h1>{{ $tc('views.correspondence.mail-processing.cover-letter', 2) }} <span class="subtitle">({{ $t('common.mail-processing.mail-processing') }})</span></h1>
        </page-header>

        <base-table
            :columns="state.columns"
            :fetch-objects-fn="MailProcessingTemplateService.all"
            user-settings-base-path="MailProcessingTemplateList"
            pagination-sort-by-default-key="name"
            enable-visible-columns
            no-top-padding
            @row-dblclick="onDblClick"
        >
            <template v-slot:body-cell-billable="props">
                <q-td :props="props">
                    <q-icon v-if="props.row.billable === true" name="mib-check-1" color="positive" />
                    <span v-if="props.row.billable === false" class="additional-info">–</span>
                </q-td>
            </template>

            <template v-slot:body-cell-actions="props">
                <q-td :props="props" auto-width>
                    <q-btn
                        class="do-not-print"
                        color="primary"
                        size="sm"
                        flat
                        round
                        icon="mib-pencil"
                        :to="{ name: 'mail-processing-template-update', params: { mailProcessingTemplateId: props.row.id } }"
                    >
                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.mail-processing.update-mail-processing-template', 1) }}</q-tooltip>
                    </q-btn>
                </q-td>
            </template>
        </base-table>

        <in-page-footer>
            <base-button
                :label="$tc('common.mail-processing.add-mail-processing-template', 1)"
                :to="{ name: 'mail-processing-template-create' }"
                icon="mib-common-file-text-add"
                primary-button
                data-test="btn:create-mail-processing-template"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { MailProcessingTemplateService } from '@/services'
import { reactive } from 'vue'
import { useI18n } from '@/composables/i18n'
import { useRouter } from '@/composables/router'
import { useOnNewEntity } from '@/composables/onNewEntity'
import BaseTable from '@/components/BaseTable'
import { kebabCase } from 'lodash'

export default {
    name: 'MailProcessingTemplateList',
    meta () {
        return {
            title: `${this.$tc('views.correspondence.mail-processing.cover-letter', 2)} (${this.$t('common.mail-processing.mail-processing')})`,
        }
    },
    components: {
        BaseTable,
    },
    setup (props) {
        // Composables
        const { tc } = useI18n()
        const router = useRouter()

        // Data
        const state = reactive({
            // TODO @TFU: Ensure that sorting and filtering work
            columns: [
                {
                    name: 'name',
                    label: tc('views.admin.correspondence.mail-processing.cover-letter.column-title--name', 1),
                    field: row => row.name,
                    align: 'left',
                    sortable: true,
                    required: true,
                },{
                    name: 'target_object_type',
                    label: tc('views.admin.correspondence.mail-processing.cover-letter.column-title--target-object-type', 1),
                    field: row => tc(`common.correspondence.target-object.target-object-type--${kebabCase(row.target_object_type)}`, 1),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'billable',
                    label: tc('views.admin.correspondence.mail-processing.cover-letter.column-title--billable', 1),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'updated_at',
                    label: tc('views.admin.correspondence.mail-processing.cover-letter.column-title--updated-at', 1),
                    field: row => row.formattedUpdatedAt,
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'actions',
                    label: tc('common.term.action', 2),
                    align: 'left',
                    required: true,
                },
            ],
        })

        // Functions
        function onDblClick (event, mailProcessingTemplate) {
            router.push({ name: 'mail-processing-template-update', params: { mailProcessingTemplateId: mailProcessingTemplate.id } })
        }
        function onNewEntity () {
            router.push({ name: 'mail-processing-template-create' })
        }

        // Lifecycle hooks
        useOnNewEntity(onNewEntity)

        return {
            // Static
            MailProcessingTemplateService,

            // Data
            state,

            // Functions
            onDblClick,
        }
    },
}
</script>

<style scoped>

</style>
