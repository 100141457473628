import { apolloClient } from '@/apollo/client'

import { CORRESPONDENCE_SALUTATION_QUERY } from '@/graphql/correspondenceSalutation/correspondenceSalutation'
import { CORRESPONDENCE_SALUTATIONS_QUERY } from '@/graphql/correspondenceSalutation/correspondenceSalutations'
import { CORRESPONDENCE_SALUTATION_CREATE_MUTATION } from '@/graphql/correspondenceSalutation/correspondenceSalutationCreate'
import { CORRESPONDENCE_SALUTATION_UPDATE_MUTATION } from '@/graphql/correspondenceSalutation/correspondenceSalutationUpdate'
import { CORRESPONDENCE_SALUTATION_SET_DEFAULT_MUTATION } from '@/graphql/correspondenceSalutation/correspondenceSalutationSetDefault'
import { CORRESPONDENCE_SALUTATION_DELETE_MUTATION } from '@/graphql/correspondenceSalutation/correspondenceSalutationDelete'

export const CorrespondenceSalutationService = {
    get (id) {
        return apolloClient.query({
            query: CORRESPONDENCE_SALUTATION_QUERY,
            variables: {
                id,
            },
        }).then(response => response.data.correspondenceSalutation)
    },
    all (variables) {
        return apolloClient.query({
            query: CORRESPONDENCE_SALUTATIONS_QUERY,
            variables,
        }).then(response => response.data.correspondenceSalutations)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: CORRESPONDENCE_SALUTATION_CREATE_MUTATION,
            variables,
        }).then(response => response.data.correspondenceSalutationCreate)
    },
    update (id, salutation) {
        return apolloClient.mutate({
            mutation: CORRESPONDENCE_SALUTATION_UPDATE_MUTATION,
            variables: {
                id,
                salutation,
            },
        }).then(response => response.data.correspondenceSalutationUpdate)
    },
    setDefault (id) {
        return apolloClient.mutate({
            mutation: CORRESPONDENCE_SALUTATION_SET_DEFAULT_MUTATION,
            variables: {
                id,
            },
        }).then(response => response.data.correspondenceSalutationSetDefault)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: CORRESPONDENCE_SALUTATION_DELETE_MUTATION,
            variables: {
                id,
            },
        }).then(response => response.data.correspondenceSalutationDelete)
    },
}
