import { i18n } from '@/i18n'
import { ActivityLogParser } from '@/parsers/activityLog'

export class PhoneNumberActivityLogParser extends ActivityLogParser {
    static attributeDataTypes = {
        'label.id': 'phoneNumberLabel',
        'is_main_phone_number': 'boolean',
    }

    static getLogDetailsCreated (activityLog, data) {
        return [
            {
                key: 'phoneNumber',
                newValue: `${activityLog.properties.attributes.phone_number} <span class="additional-info">(${this.getFormattedLogDetailValue(activityLog, 'addressLabel', this.attributeDataTypes['label.id'] ?? 'undefined', activityLog.properties.attributes['label.id'])})</span>`,
            },
            {
                key: 'isMain',
                label: i18n.tc('forms.field.label.phone-number.is-main-phone-number', 1),
                newValue: this.getFormattedLogDetailValue(activityLog, 'is_main_phone_number', this.attributeDataTypes['is_main_phone_number'] ?? 'undefined', activityLog.properties.attributes['is_main_phone_number']),
            },
        ]
    }

    static getLogDetailsDeleted (activityLog, data) {
        return [
            {
                key: 'phoneNumber',
                oldValue: `${activityLog.properties.attributes.phone_number} <span class="additional-info">(${this.getFormattedLogDetailValue(activityLog, 'addressLabel', this.attributeDataTypes['label.id'] ?? 'undefined', activityLog.properties.attributes['label.id'])})</span>`,
            },
            {
                key: 'isMain',
                label: i18n.tc('forms.field.label.phone-number.is-main-phone-number', 1),
                oldValue: this.getFormattedLogDetailValue(activityLog, 'is_main_phone_number', this.attributeDataTypes['is_main_phone_number'] ?? 'undefined', activityLog.properties.attributes['is_main_phone_number']),
            },
        ]
    }
}
