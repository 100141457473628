import gql from 'graphql-tag'

export const DXP_INSURER_LIST_QUERY = gql`
    query dxpInsurerList(
        $hasActiveAgreement: Boolean!
    ) {
        dxpInsurerList(
            has_active_agreement: $hasActiveAgreement
        ) {
            download_permissions_granted
            upload_permissions_granted
        }
    }
`
