<template>
    <global-search
        ref="globalSearch"
        v-bind="$attrs"
        :placeholder="placeholder"
        enable-fetch-contracts
        :enabled-result-types="enabledResultTypes"
        @open-search-result="handleOpenSearchResult"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" /><!-- Named slots -->
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template><!-- Scoped slots -->
    </global-search>
</template>

<script>
import GlobalSearch from '@/components/search/GlobalSearch'

export default {
    name: 'FetchContractsSearch',
    components: {
        GlobalSearch,
    },
    props: {
        placeholder: {
            type: String,
            default: '',
        },
        enabledResultTypes: {
            type: Array,
            default () {
                return ['Person', 'Company', 'Contract']
            },
        },
    },
    methods: {
        handleOpenSearchResult (searchResultWrapper) {
            this.$emit('open-search-result', searchResultWrapper.model)
            this.$refs.globalSearch.triggerHideSearchResults()
            this.$refs.globalSearch.clearSearch()
        },
    },
}
</script>
