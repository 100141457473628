<template>
    <form
        data-test="form:commission-list-excel-form"
        @submit.prevent="onFormSubmit"
    >
        <div class="row q-col-gutter-sm">
            <div class="col-xs-12">
                <!-- eslint-disable vue/no-v-html -->
                <info-box
                    v-if="formErrorMessage"
                    type="negative"
                    data-test="error:global-message"
                    class="q-mb-xs"
                    no-margin
                    v-html="formErrorMessage"
                />
                <!-- eslint-enable -->

                <div class="row q-col-gutter-md">
                    <div class="col-xs-12">
                        <form-builder item-key="excelMapping" />
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                        <form-builder item-key="exchangeDateFrom" />
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                        <form-builder item-key="exchangeDateTo" />
                    </div>
                    <div class="col-xs-12 col-md-4">
                        <form-builder item-key="commissionTotal" prefix="CHF" />
                    </div>

                    <div class="col-xs-12">
                        <div v-if="currentMode === ViewMode.UPDATE && commissionList.file">
                            <p>
                                {{ commissionList.file.name }} ({{ commissionList.file.formattedFileSize }})
                                <q-btn
                                    color="primary"
                                    size="sm"
                                    flat
                                    round
                                    icon="mib-cloud-download"
                                    @click="commissionList.file.download()"
                                >
                                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.term.download', 1) }}</q-tooltip>
                                </q-btn>
                            </p>
                        </div>

                        <file-upload v-else v-model="$v.formData.file.$model" file-type="excel" />

                        <info-box v-if="$v.formData && $v.formData.file && $v.formData.file.$error" type="negative">
                            <ul>
                                <li v-if="!$v.formData.file.required">{{ $tc('views.commission.commission-list.upload-error--file-required', 1, { fileType: $t('views.commission.commission-list.select-file-type--user-choose-action-sentence--excel') }) }}</li>
                                <li v-if="!$v.formData.file.maxLength">{{ $tc('common.error-message.max-n-files-allowed', $v.formData.file.$params.maxLength.max) }}</li>
                                <li v-if="$v.formData.file.$each.$error">{{ $tc('common.error-message.only-specific-file-types-allowed', 1, { type: allowedFileTypes }) }}</li>
                            </ul>
                        </info-box>
                    </div>
                </div>
            </div>
        </div>

        <in-page-footer>
            <base-button
                :label="$t('common.term.cancel')"
                flat
                primary-button
                @click="cancel"
            />
            <template v-if="currentMode === ViewMode.UPDATE">
                <base-button
                    :label="$t('common.term.save')"
                    primary-button
                    type="submit"
                    :disable="$v.formData.$invalid"
                />
            </template>
            <template v-else>
                <base-button
                    :label="$tc('views.commission.commission-list.upload-commission-list--upload-and-import', 1)"
                    primary-button
                    type="submit"
                    :disable="$v.formData.$invalid"
                    @click="setTriggerImport(true)"
                />
                <base-button
                    :label="$tc('views.commission.commission-list.upload-commission-list', 1)"
                    primary-button
                    outline
                    type="submit"
                    :disable="$v.formData.$invalid"
                    @click="setTriggerImport(false)"
                />
            </template>
        </in-page-footer>
    </form>
</template>

<script>
import { ViewMode } from '@/enums'
import { CommissionListType } from '@/enums/graphql'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import COMMISSION_LIST_FORM from '@/forms/commissionListExcel.form'
import FileUpload from '@/components/form/FileUpload'
import { isMediaType } from '@/helpers/form'
import { maxLength, required } from 'vuelidate/lib/validators'

export default {
    name: 'CommissionListExcelForm',
    components: {
        FileUpload,
    },
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: COMMISSION_LIST_FORM,
    },
    props: {
        commissionList: {
            type: Object,
            required: false,
            default () {
                return undefined
            },
        },
    },
    data () {
        const data = {
            ViewMode,
            currentMode: (this.commissionList) ? ViewMode.UPDATE : ViewMode.CREATE,
            formData: {
                type: CommissionListType.EXCEL,
                excelMappingId: null,
                file: [],
            },
            selectedExcelMappingOption: null,
            allowedFileTypes: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
            triggerImport: false,
        }

        if (data.currentMode === ViewMode.UPDATE) {
            Object.assign(data.formData, {
                excelMapping: this.commissionList.excelMapping.id,
                excelMappingId: this.commissionList.excelMapping.id,
                productProviderId: this.commissionList.productProvider.id,
                exchangeDateFrom: this.commissionList.exchange_date_from,
                exchangeDateTo: this.commissionList.exchange_date_to,
                commissionTotal: this.commissionList.commission_total,
            })
        }

        return data
    },
    watch: {
        'formData.excelMapping' (newValue) {
            if (newValue) {
                this.formData.excelMappingId = newValue.id
                this.formData.productProviderId = newValue.productProvider.id
            }
        },
    },
    validations () {
        const validations = {}
        if (this.currentMode === ViewMode.CREATE) {
            validations.formData = {
                file: {
                    required,
                    maxLength: maxLength(1),
                    $each: {
                        type: isMediaType(this.allowedFileTypes),
                    },
                },
            }
        }
        return validations
    },
    methods: {
        handleFormData (data) {
            const formData = data
            if (this.formData.file.length) {
                formData.file = this.formData.file[0] // Grab upload directly from formData, as the one processed by formBuilderMixin loses it's binary data. See TODO in formBuilderMixin.
            } else {
                delete formData.file
            }

            if (formData.excelMapping) delete formData.excelMapping

            this.$emit('post-handle-form-data', formData, this.triggerImport)
        },
        setTriggerImport (triggerImport) {
            this.triggerImport = triggerImport
        },
        cancel () {
            this.$router.push({ name: 'commission-lists' })
        },
    },
}
</script>
