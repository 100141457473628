import { apolloClient } from '@/apollo/client'

import { MAIL_PROCESSING_GENERATE_COVER_LETTER_MUTATION } from '@/graphql/mailProcessing/mailProcessingGenerateCoverLetter'

export const MailProcessingService = {
    generateCoverLetter (variables) {
        return apolloClient.mutate({
            mutation: MAIL_PROCESSING_GENERATE_COVER_LETTER_MUTATION,
            variables,
        }).then(response => response.data.mailProcessingGenerateCoverLetter)
    },
}
