u<template>
    <page-loading-indicator
        v-if="!correspondenceEmail"
        :number-of-breadcrumb-elements="2"
        actions
        :number-of-next-actions="0"
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.correspondence.email.email', 2)" :to="{ name: 'correspondence-emails' }" />
                <q-breadcrumbs-el :label="$tc('common.correspondence.email.write-email', 1)" :to="{ name: 'correspondence-email-update', params: { id: correspondenceEmail.id } }" />
            </template>

            <h1>{{ $tc('common.correspondence.email.write-email', 1) }}</h1>

            <template v-slot:actions>
                <actions
                    v-if="correspondenceEmail.actions"
                    :actions="correspondenceEmail.actions"
                    :number-of-next-best-actions="0"
                    @click="handleActions"
                />

                <form-dialog
                    ref="correspondenceEmailDeleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleCorrespondenceEmailDelete"
                >
                    <template v-slot:title>{{ $tc('common.correspondence.email.delete-email', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.correspondence.email.delete-email--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:name><b>{{ correspondenceEmail.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <correspondence-item-compose ref="correspondenceItemCompose" :correspondence-item="correspondenceEmail" @submit="onSubmit">
                    <template v-slot:afterEditor>
                        <h2>{{ $tc('common.term.attachment', 2) }}</h2>
                        <p :class="{ 'text-negative': ($v.attachmentsTotalSize.$error && !$v.attachmentsTotalSize.maxValue) }">{{ $tc('common.term.of--x-of-n', 1, { x: humanStorageSize(attachmentsTotalSize), n: humanStorageSize(attachmentsTotalSizeMax) }) }}</p>
                        <div class="row q-col-gutter-md">
                            <div class="col-xs-12 col-lg-4">
                                <file-upload ref="fileUpload" v-model="formData.attachments" multiple />
                                <info-box v-if="$v.attachmentsTotalSize.$error" type="negative">
                                    <p v-if="!$v.attachmentsTotalSize.maxValue" class="error-message">{{ $tc('common.error-message.max-upload-size--total', 1, { maxUploadSize: humanStorageSize(attachmentsTotalSizeMax) }) }}</p>
                                </info-box>
                            </div>

                            <div class="col-xs-12 col-lg-8">
                                <!-- TODO improvement @MTR: Create file library component. -->
                                <!-- TODO @MTR with @TFU: Check why getFileTypeIcon() is called twice? -->
                                <div class="file-library-wrapper">
                                    <q-card
                                        v-for="attachment in correspondenceEmail.attachments"
                                        :key="attachment.id"
                                        :class="['file-library-item', { 'delete': attachmentToDelete && attachmentToDelete.id === attachment.id }]"
                                        square
                                        flat
                                        bordered
                                    >
                                        <q-card-section horizontal>
                                            <q-item class="items-start">
                                                <q-item-section side>
                                                    <q-avatar square>
                                                        <q-icon
                                                            v-if="attachment.mime_type"
                                                            :name="getFileTypeIcon(attachment.mime_type)"
                                                            size="sm"
                                                            color="secondary"
                                                        />
                                                    </q-avatar>
                                                </q-item-section>

                                                <q-item-section>
                                                    <q-item-label>{{ attachment.name }}</q-item-label>
                                                    <q-item-label v-if="attachment.size" caption>{{ humanStorageSize(attachment.size) }}</q-item-label>
                                                </q-item-section>
                                            </q-item>

                                            <q-card-actions vertical>
                                                <q-btn
                                                    icon="mib-bin"
                                                    flat
                                                    round
                                                    color="negative"
                                                    size="sm"
                                                    @click="triggerCorrespondenceEmailDeleteAttachment(attachment)"
                                                />

                                                <q-btn
                                                    color="primary"
                                                    size="sm"
                                                    flat
                                                    round
                                                    icon="mib-cloud-download"
                                                    @click="attachment.download()"
                                                >
                                                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.term.download-item', 1, { item: attachment.name }) }}</q-tooltip>
                                                </q-btn>
                                            </q-card-actions>
                                        </q-card-section>
                                    </q-card>
                                </div>
                            </div>
                        </div>

                        <!-- Delete Attachment -->
                        <form-dialog
                            ref="correspondenceEmailDeleteAttachmentDialog"
                            :item="attachmentToDelete"
                            dont-close-on-confirm
                            @confirm="handleCorrespondenceEmailDeleteAttachment"
                            @hide="attachmentToDelete = null"
                        >
                            <template v-slot:title>{{ $tc('common.attachment.delete-attachment', 1) }}</template>
                            <template v-slot:default="slotProps">
                                <i18n v-if="slotProps.item" path="common.attachment.delete-attachment--confirm--temp-component-interpolation-count" tag="p">
                                    <template v-slot:name><b>{{ slotProps.item.name }}</b></template>
                                </i18n>
                            </template>
                        </form-dialog>
                    </template>
                </correspondence-item-compose>

                <in-page-footer>
                    <base-button
                        :label="$t('common.term.cancel')"
                        flat
                        primary-button
                        @click="cancel"
                    />
                    <base-button
                        data-test="btn:submit"
                        :label="$t('common.term.save')"
                        primary-button
                        outline
                        :disable="isProcessing"
                        :loading="isProcessing && !continueToPreviewAfterProcessing"
                        @click="triggerProcessing(false)"
                    />
                    <base-button
                        data-test="btn:submit"
                        :label="$t('common.term.save-and-continue')"
                        primary-button
                        :disable="isProcessing"
                        :loading="isProcessing && continueToPreviewAfterProcessing"
                        @click="triggerProcessing(true)"
                    />
                </in-page-footer>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import { maxValue } from 'vuelidate/lib/validators'
import { prepareFileUploadWrappers, getFileTypeIcon, humanStorageSize } from '@/helpers/file'
import { FileUploadTargetObjectType } from '@/enums'
import { CorrespondenceEmail } from '@/models/correspondenceEmail'
import { CorrespondenceEmailService } from '@/services'
import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'
import CorrespondenceItemCompose from '@/components/correspondence/CorrespondenceItemCompose'
import FileUpload from '@/components/form/FileUpload'

export default {
    name: 'CorrespondenceEmailUpdate',
    meta () {
        return {
            title: this.$tc('common.correspondence.email.write-email', 1),
        }
    },
    components: {
        Actions,
        FormDialog,
        CorrespondenceItemCompose,
        FileUpload,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            correspondenceEmail: null,
            formData: {
                attachments: [],
            },
            attachmentsTotalSizeMax: 15 * 1024 * 1024, // 15 MB in Bytes,
            attachmentToDelete: null,
            isProcessing: false,
            continueToPreviewAfterProcessing: false,
        }
    },
    computed: {
        attachmentsTotalSize () {
            return (this.correspondenceEmail) ? this.formData.attachments.reduce((total, file) => (total += file.size), 0) + this.correspondenceEmail.attachments.reduce((total, file) => (total += file.size), 0) : 0
        },
    },
    watch: {
        'formData.attachments' () {
            this.$v.attachmentsTotalSize.$touch()
        },
    },
    validations () {
        return {
            attachmentsTotalSize: {
                maxValue: maxValue(this.attachmentsTotalSizeMax),
            },
        }
    },
    created () {
        // TODO improvement @TFU: Redirect if item with given ID should not have this view. (Due to its status.)
        EventBus.$on('fileUploadManager:allUploadsDone', this.checkRefetchAttachments)

        CorrespondenceEmail.objects.get(this.id).then(correspondenceEmail => {
            this.correspondenceEmail = correspondenceEmail
        })
    },
    beforeDestroy () {
        EventBus.$off('fileUploadManager:allUploadsDone', this.checkRefetchAttachments)
    },
    methods: {
        fetchAttachments () {
            this.correspondenceEmail.reloadAttachments()
        },
        checkRefetchAttachments (targetObjects) {
            if (targetObjects.some(targetObject => targetObject.type === FileUploadTargetObjectType.CORRESPONDENCE_EMAIL_ATTACHMENT && targetObject.id === this.id)) {
                this.fetchAttachments()
            }
        },
        getFileTypeIcon (...args) {
            return getFileTypeIcon(...args)
        },
        humanStorageSize (...args) {
            return humanStorageSize(...args)
        },
        triggerProcessing (continueToPreviewAfterProcessing) {
            if (continueToPreviewAfterProcessing === true) this.continueToPreviewAfterProcessing = true
            this.$refs.correspondenceItemCompose.triggerSubmit()
        },
        onSubmit (data) {
            if (!this.$v.$invalid) {
                this.isProcessing = true
                const { name, languageId, differentiateFormality, targetObjects, contents, emailAddressOverrides, senderOverrides } = data

                const variables = {
                    name,
                    targetObjectIds: targetObjects.map(targetObject => targetObject.id),
                    languageId,
                    emailAddressOverrides: (emailAddressOverrides === null) ? null : JSON.stringify(emailAddressOverrides),
                    senderOverrides: (senderOverrides === null) ? null : JSON.stringify(senderOverrides),
                    differentiateFormality,
                    contents: contents.map(documentContent => documentContent.getVariablesObject()),
                }

                this.correspondenceEmail.update(variables)
                    .then(async () => {
                        if (this.formData.attachments.length) {
                            // Add attachments to file upload manager queue.
                            const fileUploadWrappers = await prepareFileUploadWrappers(this.formData.attachments, {
                                targetObjectType: FileUploadTargetObjectType.CORRESPONDENCE_EMAIL_ATTACHMENT,
                                targetObjectId: this.id,
                                meta: { name: name },
                            })
                            EventBus.$emit('fileUploadManager:addToUploadQueue', fileUploadWrappers)
                            this.$refs.fileUpload.clearFiles()
                        }
                        this.$q.notify({
                            type: 'positive',
                            message: this.$tc('common.notifications.correspondence.correspondence-email-saved-success', 1),
                        })

                        if (this.continueToPreviewAfterProcessing) this.$router.push({ name: 'correspondence-email-preview', params: { id: this.id } })
                    })
                    .catch(error => {
                        this.continueToPreviewAfterProcessing = false
                        this.$refs.correspondenceItemCompose.$refs.correspondenceItemForm.showFormErrorMessage(error)
                        this.$refs.correspondenceItemCompose.$refs.correspondenceItemForm.resetFormSubmitStatus()
                        this.$q.notify({
                            type: 'negative',
                            message: this.$tc('common.term.error', 1),
                            caption: this.$tc('common.notifications.correspondence.correspondence-email-saved-error', 1),
                        })
                    })
                    .finally(() => {
                        this.isProcessing = false
                    })
            }
        },
        cancel () {
            this.$router.push({ name: 'correspondence-email-list', params: { statusGroup: 'pending' } })
        },
        handleActions (action) {
            switch (action.key) {
                case CorrespondenceEmail.action.DELETE:
                    this.$refs.correspondenceEmailDeleteDialog.open()
                    break
            }
        },
        handleCorrespondenceEmailDelete () {
            this.correspondenceEmail.delete()
                .then(() => {
                    this.$refs.correspondenceEmailDeleteDialog.close()
                    this.$router.replace({ name: 'correspondence-email-list' })
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.correspondence.correspondence-email-deleted-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.correspondenceEmailDeleteDialog.showFormErrorMessage(error)
                    this.$refs.correspondenceEmailDeleteDialog.resetFormSubmitStatus()
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$tc('common.notifications.correspondence.correspondence-email-deleted-error', 1),
                    })
                })
        },
        async triggerCorrespondenceEmailDeleteAttachment (attachment) {
            this.attachmentToDelete = attachment
            await this.$nextTick()
            this.$refs.correspondenceEmailDeleteAttachmentDialog.open()
        },
        handleCorrespondenceEmailDeleteAttachment () {
            CorrespondenceEmailService.deleteAttachment(this.attachmentToDelete.id)
                .then(() => {
                    this.$refs.correspondenceEmailDeleteAttachmentDialog.close()
                    const attachmentIndex = this.correspondenceEmail.attachments.findIndex(attachment => attachment.id === this.attachmentToDelete.id)
                    if (attachmentIndex > -1) {
                        this.correspondenceEmail.attachments.splice(attachmentIndex, 1)
                    }
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.correspondence.correspondence-email-attachment-deleted-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.correspondenceEmailDeleteAttachmentDialog.showFormErrorMessage(error)
                    this.$refs.correspondenceEmailDeleteAttachmentDialog.resetFormSubmitStatus()
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$tc('common.notifications.correspondence.correspondence-email-attachment-deleted-error', 1),
                    })
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.file-library-wrapper {
    --file-library-items-per-line: 1;
    --column-gap: #{$sizeSpacingSm};
    --row-gap: #{$sizeSpacingSm};

    @media screen and (min-width: $sizeBreakpointSm), print {
        --file-library-items-per-line: 2;
    }

    @media screen and (min-width: $sizeBreakpointFormBuilderMd), print {
        --file-library-items-per-line: 3;
    }

    @media screen and (min-width: $sizeBreakpointMd), print {
        --column-gap: #{$sizeSpacingMd};
        --row-gap: #{$sizeSpacingMd};
    }

    @media screen and (min-width: $sizeBreakpointXl), print and (min-width: 150mm) {
        --file-library-items-per-line: 4;
    }

    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(var(--file-library-items-per-line), 1fr);
    column-gap: var(--column-gap);
    row-gap: var(--row-gap);
}

.file-library-item.file-library-item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    transition: all 1s $defaultTransitionTimingFunction 0s;

    &.delete {
        border-style: dashed;
        border-color: var(--q-color-negative-light);
        background-color: var(--q-color-negative-lighter);
    }

    & > .q-card__section {
        width: 100%;
        justify-content: space-between;
    }
}
</style>
