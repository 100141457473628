import { apolloClient } from '@/apollo/client'

import { COMMISSION_TYPE_ACCOUNTING_CONFIGURATION_QUERY } from '@/graphql/commissionTypeAccountingConfiguration/commissionTypeAccountingConfiguration'
import { COMMISSION_TYPE_ACCOUNTING_CONFIGURATIONS_QUERY } from '@/graphql/commissionTypeAccountingConfiguration/commissionTypeAccountingConfigurations'
import { COMMISSION_TYPE_ACCOUNTING_CONFIGURATION_UPDATE_MUTATION } from '@/graphql/commissionTypeAccountingConfiguration/commissionTypeAccountingConfigurationUpdate'

export const CommissionTypeAccountingConfigurationService = {
    get (id) {
        return apolloClient.query({
            query: COMMISSION_TYPE_ACCOUNTING_CONFIGURATION_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.commissionTypeAccountingConfiguration)
    },
    all (variables) {
        return apolloClient.query({
            query: COMMISSION_TYPE_ACCOUNTING_CONFIGURATIONS_QUERY,
            variables,
        }).then(response => response.data.commissionTypeAccountingConfigurations)
    },
    update (id, commissionTypeAccountingConfigurationVariables) {
        return apolloClient.mutate({
            mutation: COMMISSION_TYPE_ACCOUNTING_CONFIGURATION_UPDATE_MUTATION,
            variables: {
                id: id,
                ...commissionTypeAccountingConfigurationVariables,
            },
        }).then(response => response.data.commissionTypeAccountingConfigurationUpdate)
    },
}
