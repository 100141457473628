<template>
    <q-dialog
        ref="dialog"
        content-class="form-dialog"
        @hide="$emit('cancel')"
    >
        <q-card v-if="state.formInstance">
            <q-card-section :class="{ 'empty-form': !state.formInstance.items.length }">
                <h1 v-if="$slots.title"><slot name="title" /></h1>
                <slot></slot>
                <form-builder-next
                    :form="state.formInstance"
                    :initial-data="initialData"
                    hide-default-buttons
                    @setup="setFormBuilder"
                    @submit="onSubmit"
                >
                    <template v-slot:default="{ formBuilder }"><slot name="form" :form-builder="formBuilder"></slot></template>
                </form-builder-next>
            </q-card-section>
            <q-card-actions align="right" class="q-gutter-sm" v-if="state.formBuilder">
                <base-button
                    :label="$t('common.term.cancel')"
                    flat
                    primary-button
                    data-test="btn:cancel"
                    @click="cancel"
                />
                <base-button
                    data-test="btn:submit"
                    :label="submitButtonLabel ? submitButtonLabel : doubleConfirm ? $t('common.term.confirm') : $t('common.term.save')"
                    :disable="state.formBuilder.formSubmitStatus"
                    :loading="state.formBuilder.formSubmitStatus"
                    primary-button
                    @click="state.formBuilder.onFormSubmit()"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { reactive, ref } from 'vue'
import { useI18n } from '@/composables/i18n'
import { Form, FormField } from '@/libs/form'

export default {
    name: 'FormDialog',
    props: {
        form: {
            type: [Function, Object],
            default () {
                return new Form()
            },
        },
        formContext: {
            type: Object,
            default () {
                return {}
            },
        },
        initialData: {
            type: Object,
            default: undefined,
        },
        doubleConfirm: {
            type: Boolean,
            default: false,
        },
        doubleConfirmAltLabel: {
            type: Boolean,
            default: false,
        },
        doubleConfirmLabel: {
            type: String,
            default: '',
        },
        autoClose: {
            type: Boolean,
            default: false,
        },
        submitButtonLabel: {
            type: String,
            default: '',
        },
    },
    setup (props, context) {
        // Composable
        const { t } = useI18n()

        // State
        const state = reactive({
            formInstance: null,
            formBuilder: null,
        })

        // Template refs
        const dialog = ref(null)
        const formComponent = ref(null)

        // Functions
        function setupForm () {
            const formInstance = (typeof props.form === 'function') ? new props.form({ context: props.formContext }) : props.form

            if (props.doubleConfirm) {
                formInstance.addField(new FormField('doubleConfirmation', {
                    label: props.doubleConfirmLabel || t(`forms.confirmation-toggle${props.doubleConfirmAltLabel ? '--alt' : ''}`),
                    inputType: 'toggle',
                    validators: [
                        {
                            'type': 'isTrue',
                            'errorMessage': 'common.error-message.is-true--double-confirmation',
                        },
                    ],
                }))
            }

            state.formInstance = formInstance
        }
        function setFormBuilder (formBuilder) {
            state.formBuilder = formBuilder
            context.emit('setup', formBuilder)
        }
        function open () {
            setupForm()
            dialog.value.show()
        }
        function close () {
            dialog.value.hide()
        }
        function cancel () {
            context.emit('cancel')
            close()
        }
        function onSubmit (formData) {
            context.emit('submit', formData)
            if (props.autoClose) close()
        }
        function showFormErrorMessage (error) {
            state.formBuilder.showFormErrorMessage(error)
        }
        function resetFormSubmitStatus () {
            state.formBuilder.resetFormSubmitStatus()
        }

        return {
            // State
            state,

            // Template refs
            dialog,
            formComponent,

            setFormBuilder,
            open,
            close,
            cancel,
            onSubmit,
            showFormErrorMessage,
            resetFormSubmitStatus,
        }
    },
}
</script>

<style lang="scss" scoped>
.form-dialog {

    .q-dialog__inner {

        // This is the actual dialog element
        & > div {
            min-width: 22rem;
            width: 60vw;
            max-width: 40rem;

            @include mq($from: $sizeBreakpointMd) {
                min-width: 30rem;
                width: 25vw;
            }
        }
    }
}

h1 {
    margin: 0 0 1em;

    // = h3
    font-size: 1.5rem;
    line-height: 1.8rem;
    letter-spacing: 0;
}

::v-deep h2 {
    margin: 0 0 1em;

    // = h4
    font-size: 1.25rem;
    font-weight: 800;
    line-height: 1.5rem;
    letter-spacing: 0;
}

::v-deep h3 {
    margin: 0 0 1em;

    // = h5
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.2rem;
    letter-spacing: 0.0125em;
}

// Adjust spacing/margin when there are no form fields being rendered
.empty-form {
    ::v-deep {
        p:nth-last-child(2),
        .form-item-form {
            margin-bottom: 0;
        }
    }
}

// Styling of error message when toggle is not checked
@keyframes tempErrorHighlighting {
    0% {
        color: var(--q-color-negative);
    }
    100% {
        color: inherit;
    }
}

::v-deep div[error="true"] {
    animation: tempErrorHighlighting 1.5s ease-in-out 0s;
}

::v-deep .error-message {
    color: var(--q-color-negative);
}
</style>
