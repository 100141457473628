import gql from 'graphql-tag'

export const PRODUCT_TEMPLATE_CREATE_MUTATION = gql`
    mutation productTemplateCreate(
        $productTemplate: ProductTemplateInput!
    ) {
        productTemplateCreate(
            productTemplate: $productTemplate
        ) {
            id
            name
            status
            category {
                id
                name
            }
            subCategory {
                id
                name
            }
            version
        }
    }
`
