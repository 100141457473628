<template>
    <loading-indicator v-if="isLoading" auto-height />
    <div v-else class="activity-log-wrapper">
        <ul v-if="activityLogs.length > 0" class="activity-log-list">
            <li
                v-for="activityLog in activityLogs"
                :key="activityLog.id"
                :class="['activity-log-item', { 'new-date': activityLog.displayDate }]"
                :data-activity-log-log-name="activityLog.log_name"
                :data-activity-log-description="activityLog.description"
            >
                <div class="activity-log-timestamp">
                    <span v-if="activityLog.displayDate" class="date" aria-hidden="true">{{ activityLog.formattedCreatedAtDate }}</span>
                    <time :datetime="activityLog.created_at" class="time">{{ activityLog.formattedCreatedAtTime }}</time>

                    <!-- TODO improvement @MTR: Insert icon per subject (contact, contract, address, email, phone, …) and per action (created, updated, deleted) so that the list easier to "scan" if a user looks for something specific. -->
                </div>
                <div class="activity-log-content">
                    <!-- TODO improvement: Indicate if causer is the user himself or a user from another consulting company? -->
                    <p v-html="DOMPurify.sanitize(activityLog.data.logDescription)"></p>
                    <ul v-if="activityLog.data.logDetails.length" class="activity-log-details-list">
                        <li v-for="logDetail in activityLog.data.logDetails" :key="logDetail.key">
                            <span v-if="logDetail.label" class="label">{{ logDetail.label }}: </span>
                            <del v-if="logDetail.oldValue !== undefined" class="old-value" v-html="DOMPurify.sanitize(logDetail.oldValue)"></del>
                            <span v-if="logDetail.oldValue !== undefined && logDetail.newValue !== undefined"> → </span>
                            <ins v-if="logDetail.newValue !== undefined" class="new-value" v-html="DOMPurify.sanitize(logDetail.newValue)"></ins>
                        </li>
                    </ul>

                    <!--<div v-if="['created', 'deleted'].includes(activityLog.rendering.event)">-->
                    <!--    <component :is="(activityLog.rendering.event === 'created') ? 'ins' : 'del'">-->
                    <!--        <div v-if="activityLog.log_name === 'address'">-->
                    <!--            <address>{{ getContactAddress(activityLog.properties.attributes, { multiline: true }) }}</address>-->
                    <!--        </div>-->
                    <!--        <div v-if="activityLog.log_name === 'emailAddress'">{{ activityLog.properties.attributes.email }}</div>-->
                    <!--        <div v-if="activityLog.log_name === 'phoneNumber'">{{ activityLog.properties.attributes.phone_number }}</div>-->
                    <!--    </component>-->
                    <!--</div>-->

                    <!--<div v-else-if="activityLog.rendering.event === 'updated'">-->
                    <!--    <i18n :path="`common.change-log.someone-${activityLog.rendering.event}-something`" tag="p">-->
                    <!--        <template v-slot:someone>{{ activityLog.rendering.causer }}</template>-->
                    <!--        <template v-slot:value>{{ $tc('common.change-log.someone-updated-something&#45;&#45;the-field', activityLog.attributeKeys.length) }} <b>{{ activityLog.attributeLabels }}</b></template>-->
                    <!--        &lt;!&ndash; TODO refactoring @MTR: This part needs to be more generic, so it supports all kinds of ActivityLogs. Discuss with @TFU. &ndash;&gt;-->
                    <!--        <template v-if="activityLog.subject && activityLog.subject.type !== ContactType.PERSON" v-slot:ofSomething>{{ $tc(`common.change-log.updated-entity-${toKebabCase(activityLog.log_name)}-with-id`, 1, { id: activityLog.subject.id }) }}</template>-->
                    <!--        <template v-else v-slot:ofSomething>{{ $tc(`common.change-log.updated-entity-${toKebabCase(activityLog.log_name)}`, 1) }}</template>-->
                    <!--    </i18n>-->
                    <!--    <ul>-->
                    <!--        <li v-for="attribute in activityLog.attributeChangeLog" :key="attribute.key">-->
                    <!--            {{ attribute.labelLocalized }}:-->
                    <!--            <del v-if="attribute.old !== '' && attribute.old !== null && attribute.old !== undefined" class="old-value">{{ getDisplayValueFromId(activityLog, attribute, attribute.oldLocalized) }}</del>-->
                    <!--            <span-->
                    <!--                v-if="attribute.old !== '' &&-->
                    <!--                    attribute.old !== null &&-->
                    <!--                    attribute.old !== undefined &&-->
                    <!--                    attribute.value !== '' &&-->
                    <!--                    attribute.value !== null &&-->
                    <!--                    attribute.value !== undefined-->
                    <!--                "-->
                    <!--            > → </span>-->
                    <!--            <ins v-if="attribute.value !== '' && attribute.value !== null && attribute.value !== undefined">{{ getDisplayValueFromId(activityLog, attribute, attribute.valueLocalized) }}</ins>-->
                    <!--        </li>-->
                    <!--    </ul>-->
                    <!--</div>-->
                </div>
            </li>
        </ul>
        <!-- No activity log entries available -->
        <info-box v-else no-margin>
            <p>{{ $tc('common.change-log.no-changes-logged-yet', 1) }}</p>
        </info-box>
    </div>
</template>

<script>
import { ActivityLogService } from '@/services'
import { getContactAddress } from '@/helpers/contact'
import { ContactType } from '@/enums/graphql'
import kebabCase from 'lodash.kebabcase'
import DOMPurify from 'dompurify'

export default {
    name: 'ActivityLog',
    props: {
        filterSubjects: {
            type: Array,
            required: true,
        },
        filterIncludeSubjectRelations: {
            type: Array,
        },
    },
    data () {
        return {
            ContactType,
            DOMPurify,
            isLoading: false,
            activityLogs: [],
        }
    },
    created () {
        this.fetchObjects()
    },
    methods: {
        fetchObjects () {
            this.isLoading = true
            const variables = {
                filterSubjects: this.filterSubjects,
                filterIncludeSubjectRelations: this.filterIncludeSubjectRelations,
            }
            ActivityLogService.all(variables).then(response => {
                let currentDate = null
                const activityLogs = response.data.map(activityLog => {
                    if (activityLog.formattedCreatedAtDate !== currentDate) {
                        activityLog.displayDate = true
                        currentDate = activityLog.formattedCreatedAtDate
                    }
                    if (variables.filterSubjects && variables.filterSubjects.length && variables.filterIncludeSubjectRelations && variables.filterIncludeSubjectRelations.length) {
                        if (variables.filterSubjects[0].type.toLowerCase() !== activityLog.log_name.toLowerCase()) activityLog.isSubjectRelation = true
                    }
                    return activityLog
                })
                this.activityLogs = activityLogs
                this.isLoading = false
            })
        },
        getContactAddress (...args) {
            return getContactAddress(...args)
        },
        toKebabCase: function (text) {
            return kebabCase(text)
        },
    },
}
</script>

<style lang="scss" scoped>
$sizeListIndent: 140px;

.activity-log-wrapper {
}

.activity-log-list {
    padding: 0;
}

.activity-log-item {
    display: flex;
    margin-bottom: 1rem;

    &::before {
        display: none;
    }

    &.new-date {
        padding-top: $sizeSpacingSm;
        border-top: 1px solid var(--color-border-primary);
    }

    p {
        margin-bottom: $sizeSpacingXs;
    }
}

.activity-log-timestamp {
    display: flex;
    flex: 0 0 $sizeListIndent;
    justify-content: space-between;

    margin-right: $sizeSpacingSm;

    color: var(--color-text-secondary);

    .date {
        font-weight: bold;
        color: var(--color-text-primary);
    }

    .time {
        flex: 1 0;
        text-align: right;
    }

    .action-icon {
        margin-left: 0.5em;
    }
}

.activity-log-content {
}

.activity-log-details-list {
    .label {
        vertical-align: top;
    }
}

@media print {
    .activity-log-item {
        page-break-inside: avoid;
    }
}
</style>
