import gql from 'graphql-tag'

export const MAIL_PROCESSING_TEMPLATE_QUERY = gql`
    query mailProcessingTemplate(
        $id: ID!
    ) {
        mailProcessingTemplate(
            id: $id
        ) {
            id
            name
            description
            file_name
            mailProcessingActiveDocumentTemplate {
                id
            }
            mailProcessingInactiveDocumentTemplate {
                id
            }
            target_object_type
            consultingFileType {
                ... on ContactFileType {
                    id
                }
                ... on ContractFileType {
                    id
                }
                ... on ApplicationFileType {
                    id
                }
            }
            billable
            created_at
            updated_at
        }
    }
`
