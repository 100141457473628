<template>
    <form
        data-test="form:commission-rate-adjustment-rule"
        @submit.prevent="onFormSubmit"
    >
        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="formErrorMessage"
            type="negative"
            no-margin
            data-test="error:global-message"
            v-html="formErrorMessage"
        />
        <!-- eslint-enable -->

        <markup-table-cell
            :column="{ name: 'sector_code' }"
            :class="['', { 'has-error': formErrorMessage }]"
            :grid-column-start="1"
            :col-span="2"
        >
            <div class="row q-col-gutter-sm">
                <div class="col-xs-5">
                    <form-builder
                        item-key="igb2bSectorCodeId"
                        :disable="useCustomSectorCode"
                    />
                </div>

                <div class="col-xs-2 text-center">{{ $t('common.term.or') }}</div>

                <div class="col-xs-5">
                    <form-builder
                        item-key="customSectorCode"
                        :disable="!useCustomSectorCode"
                    >
                        <template v-slot:before>
                            <base-checkbox v-model="useCustomSectorCode" data-test="input:use-custom-sector-code" />
                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.commission.rate-adjustment.use-custom-sector-code', 1) }}</q-tooltip>
                        </template>
                    </form-builder>
                </div>
            </div>
        </markup-table-cell>

        <markup-table-cell :column="{ name: 'commission_rate' }" :class="{ 'has-error': formErrorMessage }">
            <form-builder item-key="commissionRate" />
        </markup-table-cell>

        <markup-table-cell :column="{ name: 'adjusted_commission_rate' }" :class="{ 'has-error': formErrorMessage }">
            <form-builder item-key="adjustedCommissionRate" />
        </markup-table-cell>

        <markup-table-cell :column="{ name: 'actions' }">
            <!--
            <base-button
                v-if="currentMode === ViewMode.UPDATE"
                primary-button
                type="submit"
                data-test="btn:save"
                :label="$t('common.term.save')"
                class="q-mr-sm"
                :disable="formSubmitStatus"
                :loading="formSubmitStatus"
            />
            -->
            <base-button
                primary-button
                flat
                data-test="btn:cancel"
                :label="(currentMode === ViewMode.CREATE) ? $t('common.term.remove') : $t('common.term.cancel')"
                :disable="formSubmitStatus"
                @click="$emit('cancel')"
            />
        </markup-table-cell>
    </form>
</template>

<script>
import { ViewMode } from '@/enums'
import { EventBus } from '@/event-bus'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import COMMISSION_RATE_ADJUSTMENT_RULE_FORM from '@/forms/commissionRateAdjustmentRule.form'
import BaseCheckbox from '@/components/form/BaseCheckbox.vue'
import MarkupTableCell from '@/components/MarkupTableCell'

export default {
    name: 'CommissionRateAdjustmentRuleForm',
    components: {
        MarkupTableCell,
        BaseCheckbox,
    },
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: COMMISSION_RATE_ADJUSTMENT_RULE_FORM,
    },
    props: {
        commissionRateAdjustmentRule: {
            type: Object,
            required: false,
            default () {
                return undefined
            },
        },
    },
    data () {
        const data = {
            ViewMode,
            currentMode: this.commissionRateAdjustmentRule ? ViewMode.UPDATE : ViewMode.CREATE,
            formData: {},
            useCustomSectorCode: false,
        }

        if (data.currentMode === ViewMode.UPDATE) this.updateFormData(data.formData)

        return data
    },
    watch: {
        useCustomSectorCode (newVal) {
            if (newVal) {
                this.resetIgb2bSectorCodeId()
            } else {
                this.resetCustomSectorCode()
            }
        },
        '$v.$anyDirty' (newVal) {
            if (newVal) EventBus.$emit('commissionRateAdjustmentRuleSet:submit-rule-forms-any-dirty-state', newVal)
        },
    },
    created () {
        EventBus.$on('commissionRateAdjustmentRuleSet:submit-rule-forms', this.onFormSubmit)
    },
    beforeDestroy () {
        EventBus.$off('commissionRateAdjustmentRuleSet:submit-rule-forms', this.onFormSubmit)
    },
    methods: {
        resetInitialFormData () {
            this.updateFormData(this.formData)
        },
        updateFormData (targetObject) {
            Object.assign(targetObject, {
                id: this.commissionRateAdjustmentRule.id,
                igb2bSectorCodeId: this.commissionRateAdjustmentRule.igb2bSectorCode?.id || null,
                customSectorCode: this.commissionRateAdjustmentRule.custom_sector_code,
                commissionRate: this.commissionRateAdjustmentRule.commission_rate,
                adjustedCommissionRate: this.commissionRateAdjustmentRule.adjusted_commission_rate,
            })
            this.useCustomSectorCode = !!this.commissionRateAdjustmentRule.custom_sector_code
        },
        resetIgb2bSectorCodeId () {
            this.formData.igb2bSectorCodeId = null
        },
        resetCustomSectorCode () {
            this.formData.customSectorCode = null
        },
    },
}
</script>
