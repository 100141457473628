import { FormBuilderConfiguration } from '@/form-builder-configuration'
import { initializeFormValidations, localizeProperties } from '@/libs/form/helpers'
import useVuelidate from '@vuelidate/core'
import { ref } from 'vue'
import { getValueFromProperty } from '@/helpers'

// TODO improvement @TFU: If the `FormField` is a select, it really should handle all the data loading etc and only use the component to render the UI. This way the options would also be available for all code to use and not being trapped inside the component.
export class FormField {
    __type = 'field'
    #value
    #validations

    constructor (key, configuration) {
        this.id = Symbol()
        this.key = key
        if (!configuration.inputType) configuration.inputType = configuration.type // TODO: Remove after refactoring.
        this.configuration = localizeProperties(configuration)

        this.#value = ref(getValueFromProperty(FormBuilderConfiguration.supportedInputTypes[this.configuration.inputType].initialValue, this.configuration))

        const validators = (typeof this.configuration.validators !== 'undefined' && this.configuration.validators.length > 0) ? this.configuration.validators : [{ type: 'dirtyChecker' }] // TODO: Use dirtyChecker validator function directly?
        this.#validations = useVuelidate({ value: initializeFormValidations(validators) }, { value: this.#value }, { $scope: this.id, $stopPropagation: true, $autoDirty: true, $lazy: true })

        this.meta = {
            depth: 0,
        }
    }

    get data () {
        return this.value
    }

    setData (value) {
        this.value = value
    }

    get value () {
        return this.#value.value
    }

    set value (value) {
        return this.#value.value = value
    }

    setDefaultValue () {
        if (typeof this.configuration.defaultValue !== 'undefined') {
            this.value = getValueFromProperty(this.configuration.defaultValue)
            this.resetValidations()
        }
    }

    // Alias for setDefaultValue, used when calling .setDefaultValues on the form instance.
    setDefaultValues () {
        this.setDefaultValue()
    }

    resetValue () {
        this.value = getValueFromProperty(FormBuilderConfiguration.supportedInputTypes[this.configuration.inputType].initialValue, this.configuration)
        this.resetValidations()
    }

    get validations () {
        return this.#validations.value.value
    }

    validate () {
        return this.#validations.value.$validate()
    }

    get isDirty () {
        return this.#validations.value.$dirty
    }

    get hasError () {
        return this.#validations.value.$error
    }

    resetValidations () {
        this.validations.$reset()
    }

    get label () {
        return this.configuration.label
    }
}
