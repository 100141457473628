import { ViewMode } from '@/enums'

export const viewModeManagerMixin = {
    props: {
        items: {
            type: Array,
            required: true,
        },
        targetObjectId: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            ViewMode,
            currentMode: ViewMode.LIST,
            currentItem: null,
        }
    },
    computed: {
        editMode () {
            return this.$store.state.editMode
        },
    },
    methods: {
        async triggerCreateItem () {
            this.currentMode = ViewMode.CREATE
            await this.$nextTick()
            this.$refs.createItemDialog.open()
        },
        async triggerUpdateItem (item) {
            this.currentItem = item
            this.currentMode = ViewMode.UPDATE
            await this.$nextTick()
            this.$refs.updateItemDialog.open()
        },
        handleUpdateItem (formData) {
            this.currentItem.update(formData)
                .then(item => {
                    this.emitUpdate(item)
                    this.$refs.updateItemDialog.close()
                    // TODO: Add notification?
                })
                .catch(error => {
                    this.$refs.updateItemDialog.showFormErrorMessage(error)
                    this.$refs.updateItemDialog.resetFormSubmitStatus()
                })
        },
        async triggerDeleteItem (item) {
            this.currentItem = item
            this.currentMode = ViewMode.DELETE
            await this.$nextTick()
            this.$refs.deleteItemDialog.open()
        },
        handleDeleteItem () {
            this.currentItem.delete()
                .then(() => {
                    this.emitDelete(this.currentItem)
                    this.$refs.deleteItemDialog.close()
                    // TODO: Add notification?
                })
                .catch(error => {
                    this.$refs.deleteItemDialog.showFormErrorMessage(error)
                    this.$refs.deleteItemDialog.resetFormSubmitStatus()
                })
        },
        cancelAction () {
            this.currentMode = ViewMode.LIST
            this.currentItem = null
        },
        emitCreate (item) {
            this.$emit(ViewMode.CREATE.toLowerCase(), item)
        },
        emitUpdate (item) {
            this.$emit(ViewMode.UPDATE.toLowerCase(), item)
        },
        emitDelete (item) {
            this.$emit(ViewMode.DELETE.toLowerCase(), item)
        },
    },
    watch: {
        editMode (newVal, oldVal) {
            if (newVal === false) {
                this.cancelAction()
            }
        },
    },
}
