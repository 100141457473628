<template>
    <div class="row">
        <div class="col-12">
            <!--
                TODO improvement: Change to base-table
                TODO improvement: Extend with additional columns (accounts, commission distribution rule sets, …)
            -->
            <q-table
                :data="consultingSettings"
                :columns="columns"
                row-key="id"
                :pagination.sync="pagination"
                :loading="loading"
                :rows-per-page-options="[10, 20, 50, 100]"
                binary-state-sort
                flat
                square
                dense
                @request="onRequest"
                @row-dblclick="(evt, row) => { $router.push({ name: 'contact-settings-consulting-settings-update', params: { id: row.id } }) }"
            >
                <template v-slot:top>
                    <q-tabs
                        dense
                        no-caps
                        inline-label
                        align="left"
                        class="q-mr-sm bg-background-secondary"
                        indicator-color="accent"
                    >
                        <q-route-tab :to="{ name: 'admin-consulting-settings-list' }" :label="$t('common.status.active')" />
                        <q-route-tab :to="{ name: 'admin-consulting-settings-list', params: { tab: 'inactive' } }" :label="$t('common.status.inactive')" />
                    </q-tabs>

                    <q-space />

                    <filter-input v-model="filter" @clear-filter="clearFilter" />
                </template>

                <template v-slot:body-cell-contact_name="props">
                    <q-td :props="props" data-test="td:contact-name">
                        <contact-name
                            :contact="props.row"
                            type-icon
                            highlighted
                            comma-separated
                        />
                        <status-badge v-if="props.row.is_tenant_company" status="system-owner" />
                    </q-td>
                </template>

                <template v-slot:body-cell-consulting_settings_company_name="props">
                    <q-td :props="props">
                        <template v-if="props.row.is_tenant_company">–</template>
                        <template v-else>
                            {{ props.row.consultingSettings.company.company_name }}
                        </template>
                    </q-td>
                </template>

                <template v-slot:body-cell-consulting_settings_status="props">
                    <q-td :props="props">
                        <status-badge :status="props.row.consultingSettings.status" />
                    </q-td>
                </template>

                <template v-slot:body-cell-actions="props">
                    <q-td :props="props" auto-width>
                        <q-btn
                            color="primary"
                            size="sm"
                            flat
                            round
                            icon="mib-pencil"
                            :to="{ name: 'contact-settings-consulting-settings-update', params: { id: props.row.id } }"
                        >
                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.admin.consulting-settings.edit-consulting-settings', 1, { contactName: props.row.getContactName() }) }}</q-tooltip>
                        </q-btn>
                    </q-td>
                </template>
            </q-table>
        </div>
    </div>
</template>

<script>
import { OrderDirection } from '@/enums/graphql'

import ContactName from '@/components/contact/ContactName.vue'
import StatusBadge from '@/components/StatusBadge.vue'
import FilterInput from '@/components/FilterInput'

import { Contact } from '@/models/contact'

export default {
    name: 'ConsultingSettingsList',
    components: {
        ContactName,
        StatusBadge,
        FilterInput,
    },
    props: {
        tab: {
            type: String,
        },
    },
    meta () {
        return {
            title: this.$tc('common.contact.consulting-setting', 2),
        }
    },
    data () {
        return {
            activeTab: this.tab || 'active',
            filter: '',
            loading: false,
            pagination: {
                sortBy: 'contact_name',
                page: 1,
                rowsPerPage: parseInt(localStorage.getItem('ConsultingSettingsList.pagination.rowsPerPage')) || 50,
                rowsNumber: 0,
            },
            columns: [
                {
                    name: 'contact_number',
                    label: this.$tc('common.contact.contact-number', 1),
                    field: row => row.contactNumber.number,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'contact_name',
                    label: this.$tc('common.term.name'),
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'consulting_settings_company_name',
                    label: this.$t('views.admin.consulting-settings.overview-table-company-column-label'),
                    align: 'left',
                },
                {
                    name: 'consulting_settings_status',
                    label: this.$tc('common.term.status'),
                    align: 'left',
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left' },
            ],
            consultingSettings: [],
        }
    },
    watch: {
        'filter' () {
            this.fetchObjects()
        },
        '$route' (to) {
            this.activeTab = to.params.tab || 'active'
            this.fetchObjects()
        },
        'pagination.rowsPerPage' (rowsPerPage) {
            localStorage.setItem('ConsultingSettingsList.pagination.rowsPerPage', rowsPerPage) // TODO @TFU: Refactor to new format with user hash, after https://bitbucket.org/itxpert/max-frontend/branch/feature/comments has been merged.
        },
    },
    created () {
        this.fetchObjects()
    },
    methods: {
        clearFilter () {
            this.filter = ''
        },
        onRequest (props) {
            let { page, rowsPerPage, sortBy, descending } = props.pagination

            this.pagination.page = page
            this.pagination.rowsPerPage = rowsPerPage
            this.pagination.sortBy = sortBy
            this.pagination.descending = descending

            this.fetchObjects()
        },
        fetchObjects () {
            this.loading = true
            Contact.objects.all({
                page: this.pagination.page,
                count: this.pagination.rowsPerPage,
                orderField: this.pagination.sortBy.toUpperCase(),
                orderDirection: (this.pagination.descending) ? OrderDirection.DESC : OrderDirection.ASC,
                filterQuery: this.filter,
                hasConsultingSettings: true,
                consultingSettingsStatus: [this.activeTab.toUpperCase()],
            }).then(response => {
                this.pagination.rowsNumber = response.paginatorInfo.total
                this.loading = false
                this.consultingSettings = response.data
            })
        },
    },
}
</script>
