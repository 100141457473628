<template>
    <loading-indicator v-if="isValidatingToken" />
    <q-page v-else>
        <div class="row q-col-gutter-md q-pa-lg justify-center">
            <div class="q-pt-xl col-xs-12 col-sm-8 col-md-6 col-lg-5 col-xl-4 self-center">
                <illustration-heading :title="$t('views.auth.setup.title')" img="password-lock-circle" :error="formHasErrors" />

                <p>{{ $t('views.auth.setup.text') }}</p>

                <!-- eslint-disable vue/no-v-html -->
                <info-box
                    v-if="tokenErrorMessage"
                    type="negative"
                    data-test="error:global-token-message"
                    v-html="tokenErrorMessage"
                />
                <!-- eslint-enable -->

                <q-stepper
                    v-else
                    ref="stepper"
                    v-model="activeStep"
                    alternative-labels
                    :vertical="$q.screen.lt.lg"
                    color="primary"
                    flat
                    class="auth"
                >
                    <q-step
                        name="password"
                        :title="$tc('common.term.password')"
                        icon="mib-lock-1"
                        active-icon="mib-lock-1"
                        done-color="secondary"
                    >
                        <auth-set-password ref="authSetPassword" @submit="onSetPasswordSubmit" />
                    </q-step>

                    <q-step
                        name="2fa"
                        :title="$tc('common.auth.2fa-code')"
                        icon="mib-qr-code-scan"
                        active-icon="mib-qr-code-scan"
                        done-color="secondary"
                    >
                        <auth-set-2fa-code ref="authSet2faCode" :qr-code="qrCode" @submit="onSet2faCodeSubmit" />
                    </q-step>

                    <q-step
                        name="backupcode"
                        :title="$tc('common.auth.2fa-backup-code')"
                        icon="mib-common-file-text"
                        active-icon="mib-common-file-text"
                        done-color="secondary"
                    >
                        <auth-set-2fa-backup-code ref="authSet2faBackupCode" :two-f-a-backup-code="twoFABackupCode" @submit="onSet2faBackupCodeSubmit" />
                    </q-step>

                    <q-step
                        name="confirmation"
                        :title="$t('common.status.done')"
                        icon="mib-check-circle-1"
                        active-icon="mib-check"
                        done-color="secondary"
                    >
                        <p>{{ $t('views.auth.setup.confirmation-text') }}</p>

                        <div class="col-xs-12" :class="{ 'text-center': $q.screen.gt.md }">
                            <q-btn
                                unelevated
                                no-caps
                                color="primary"
                                :label="$t('common.auth.go-to-login')"
                                :to="{ name: 'auth-login' }"
                                data-test="btn:login"
                            />
                        </div>
                    </q-step>
                </q-stepper>
            </div>
        </div>
    </q-page>
</template>

<script>
import { extractErrorMessage } from '@/helpers/form'

import AuthSetPassword from '@/components/auth/AuthSetPassword.vue'
import AuthSet2faCode from '@/components/auth/AuthSet2faCode.vue'
import AuthSet2faBackupCode from '@/components/auth/AuthSet2faBackupCode.vue'

import { AuthService } from '@/services'

export default {
    name: 'AuthSetup',
    components: {
        AuthSetPassword,
        'auth-set-2fa-code': AuthSet2faCode,
        'auth-set-2fa-backup-code': AuthSet2faBackupCode,
    },
    props: {
        token: {
            type: String,
            required: true,
        },
        step: {
            type: String,
            required: true,
        },
    },
    meta () {
        return {
            title: this.$t('views.auth.setup.title'),
        }
    },
    data () {
        return {
            activeStep: this.step,
            formHasErrors: false,

            isValidatingToken: false,
            tokenErrorMessage: '',
            processToken: null,

            qrCode: '',
            twoFABackupCode: '',
        }
    },
    watch: {
        '$route' (to) {
            this.activeStep = to.params.step
        },
    },
    created () {
        // Redirect if page was reloaded but required information are not present anymore
        switch (this.activeStep) {
            case '2fa':
                if (this.qrCode === '') this.$router.replace({ name: 'auth-setup', params: { token: this.token, step: 'password' } })
                break
            case 'backupcode':
                if (this.twoFABackupCode === '') this.$router.replace({ name: 'auth-setup', params: { token: this.token, step: 'password' } })
                break
        }

        // Validate token
        this.isValidatingToken = true
        AuthService.registration.validateToken(this.token)
            .then(response => {
                this.isValidatingToken = false
                this.processToken = response.token
            })
            .catch(error => {
                this.isValidatingToken = false
                this.tokenErrorMessage = extractErrorMessage(error)
            })
    },
    methods: {
        onSetPasswordSubmit ({ setPassword: password, confirmPassword: passwordConfirmation }) {
            AuthService.registration.setPassword(this.processToken, password, passwordConfirmation)
                .then(response => {
                    this.qrCode = 'data:image/png;base64,' + response.qr_code
                    this.$router.replace({ name: 'auth-setup', params: { token: this.token, step: '2fa' } })
                })
                .catch(error => {
                    this.formHasErrors = true
                    this.$refs.authSetPassword.showFormErrorMessage(error)
                    this.$refs.authSetPassword.resetFormData()
                    this.$refs.authSetPassword.resetFormSubmitStatus()
                })
        },
        onSet2faCodeSubmit ({ twoFACode }) {
            AuthService.registration.validate2fa(this.processToken, twoFACode.split(' ').join(''))
                .then(response => {
                    this.twoFABackupCode = response.two_fa_backup_code
                    this.$router.replace({ name: 'auth-setup', params: { token: this.token, step: 'backupcode' } })
                })
                .catch(error => {
                    this.formHasErrors = true
                    this.$refs.authSet2faCode.showFormErrorMessage(error)
                    this.$refs.authSet2faCode.resetFormData()
                    this.$refs.authSet2faCode.resetFormSubmitStatus()
                })
        },
        onSet2faBackupCodeSubmit ({ twoFABackupCode }) {
            AuthService.registration.validate2faBackupCode(this.processToken, twoFABackupCode.split('-').join(''))
                .then(() => {
                    this.$router.replace({ name: 'auth-setup', params: { token: this.token, step: 'confirmation' } })
                })
                .catch(error => {
                    this.formHasErrors = true
                    this.$refs.authSet2faBackupCode.showFormErrorMessage(error)
                    this.$refs.authSet2faBackupCode.resetFormData()
                    this.$refs.authSet2faBackupCode.resetFormSubmitStatus()
                })
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .q-stepper__step-inner.q-stepper__step-inner {
    @include mq($from: $sizeBreakpointLg) {
        padding-left: 0;
        padding-right: 0;
    }
}

::v-deep .q-stepper--vertical .q-stepper__step-inner.q-stepper__step-inner {
    padding-bottom: 1rem;
}
</style>
