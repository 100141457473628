<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="`${$tc('views.correspondence.mail-processing.cover-letter', 2)} (${$t('common.mail-processing.mail-processing')})`" :to="{ name: 'mail-processing-template-list' }" data-test="breadcrumb:mail-processing-template-list" />
                <q-breadcrumbs-el :label="$tc('common.mail-processing.add-mail-processing-template', 1)" :to="{ name: 'mail-processing-template-create' }" data-test="breadcrumb:mail-processing-template-create" />
            </template>

            <h1>{{ $tc('views.admin.correspondence.mail-processing.cover-letter.create-cover-letter', 1) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12 col-lg-10 col-xl-8">
                <mail-processing-template-form ref="mailProcessingTemplateForm" @cancel="onCancel" @submit="onSubmit" />
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { useQuasar } from '@/composables/quasar'
import { MailProcessingTemplate } from '@/models/mailProcessingTemplate'
import { reactive, ref } from 'vue'
import { useI18n } from '@/composables/i18n'
import { useRouter } from '@/composables/router'
import MailProcessingTemplateForm from '@/components/correspondence/MailProcessingTemplateForm'

export default {
    name: 'MailProcessingTemplateCreate',
    meta () {
        return {
            title: this.$tc('views.admin.correspondence.mail-processing.cover-letter.create-cover-letter', 1),
        }
    },
    components: {
        MailProcessingTemplateForm,
    },
    setup (props) {
        // Composables
        const { t, tc } = useI18n()
        const router = useRouter()
        const $q = useQuasar()

        // Data
        const state = reactive({})

        // Template refs
        const mailProcessingTemplateForm = ref(null)

        // Functions
        function onCancel () {
            router.push({ name: 'mail-processing-template-list' })
        }
        function onSubmit (formData) {
            MailProcessingTemplate.create(formData)
                .then(() => {
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.correspondence.mail-processing-template-created-success', 1),
                    })
                    router.replace({ name: 'mail-processing-template-list' })
                })
                .catch(error => {
                    $q.notify({
                        type: 'negative',
                        title: t('common.term.error'),
                        message: tc('common.notifications.correspondence.mail-processing-template-created-error', 1),
                    })
                    mailProcessingTemplateForm.value.formComponent.formBuilder.showFormErrorMessage(error)
                    mailProcessingTemplateForm.value.formComponent.formBuilder.resetFormSubmitStatus()
                })
        }

        return {
            // Data
            state,

            // Template refs
            mailProcessingTemplateForm,

            // Functions
            onCancel,
            onSubmit,
        }
    },
}
</script>
