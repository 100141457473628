import { isRef, unref } from 'vue'

export function defaultValues (values, defaultValues) {
    return Object.assign({}, defaultValues, values)
}

export function unrefProxy (Class) {
    Class.prototype.getRef = function (property) {
        return this[property]
    }

    return new Proxy(Class, {
        construct (target, argumentsList) {
            const instance = new target(...argumentsList)
            const proxy = new Proxy(instance, {
                get (target, property, receiver) {
                    const value = Reflect.get(...arguments)
                    if (typeof value === 'function' && property === 'getRef') return value.bind(target)
                    return unref(value)
                },
                set (target, property, value) {
                    const originalValue = Reflect.get(target, property)
                    if (isRef(originalValue)) {
                        originalValue.value = value
                        return true
                    } else {
                        return Reflect.set(...arguments)
                    }
                },
            })
            return proxy
        },
    })
}
