<template>
    <q-list padding class="main-menu">
        <q-item :to="{ name: 'dashboard' }" exact>
            <q-item-section avatar>
                <q-icon name="mi-browser-gauge-1" />
            </q-item-section>
            <q-item-section>{{ $tc('common.term.dashboard') }}</q-item-section>
        </q-item>

        <q-item :to="{ name: 'contact-list' }">
            <q-item-section avatar>
                <q-icon name="mi-single-neutral-phone-book" />
            </q-item-section>
            <q-item-section>{{ $tc('common.term.contact', 2) }}</q-item-section>
        </q-item>

        <q-item v-can="'Feature:tasks:core'" :to="{ name: 'task-list' }">
            <q-item-section avatar>
                <q-icon name="mi-checklist" />
            </q-item-section>
            <q-item-section>{{ $tc('common.task.task', 2) }}</q-item-section>
        </q-item>

        <q-item v-can="'Feature:accounting:core'" :to="{ name: 'drive' }">
            <q-item-section avatar>
                <q-icon name="mi-hard-drive" />
            </q-item-section>
            <q-item-section>{{ $tc('common.drive.drive', 1) }}</q-item-section>
        </q-item>

        <q-expansion-item
            v-model="navGroupState.correspondence"
            v-can="'Feature:correspondence:core'"
            :to="{ name: 'correspondence-dashboard' }"
            :label="$tc('common.term.correspondence', 1)"
            icon="mi-read-email-letter"
            expand-icon="mib-arrow-left-1"
            expanded-icon="mib-arrow-down-1"
            :content-inset-level="1"
        >
            <q-list dense>
                <q-item :to="{ name: 'correspondence-document-template-list' }">
                    <q-item-section>{{ $tc('common.correspondence.document-template.document-template', 2) }}</q-item-section>
                </q-item>

                <q-expansion-item
                    v-model="subNavGroupState.letters"
                    :to="{ name: 'correspondence-letters' }"
                    :label="$tc('common.correspondence.letter.letter', 2)"
                    expand-icon="mib-arrow-left-1"
                    expanded-icon="mib-arrow-down-1"
                    dense
                    exact-path
                >
                    <q-list dense>
                        <q-item :to="{ name: 'correspondence-letter-list', params: { statusGroup: 'pending' } }" exact-path>
                            <q-item-section>{{ $tc('common.correspondence.letter.status--pending', 2) }}</q-item-section>
                        </q-item>
                        <q-item :to="{ name: 'correspondence-letter-list', params: { statusGroup: 'completed' } }" exact-path>
                            <q-item-section>{{ $tc('common.correspondence.letter.status--completed', 2) }}</q-item-section>
                        </q-item>
                        <q-item v-can="'ProcessedCorrespondenceItem:read:all'" :to="{ name: 'processed-correspondence-letter-list' }" exact-path>
                            <q-item-section>{{ $tc('common.correspondence.letter.created-letter', 2) }}</q-item-section>
                        </q-item>
                    </q-list>
                </q-expansion-item>

                <q-expansion-item
                    v-model="subNavGroupState.emails"
                    :to="{ name: 'correspondence-emails' }"
                    :label="$tc('common.correspondence.email.email', 2)"
                    expand-icon="mib-arrow-left-1"
                    expanded-icon="mib-arrow-down-1"
                    dense
                >
                    <q-list dense>
                        <q-item :to="{ name: 'correspondence-email-list', params: { statusGroup: 'pending' } }" exact-path>
                            <q-item-section>{{ $tc('common.correspondence.email.status--pending', 2) }}</q-item-section>
                        </q-item>
                        <q-item :to="{ name: 'correspondence-email-list', params: { statusGroup: 'completed' } }" exact-path>
                            <q-item-section>{{ $tc('common.correspondence.email.status--completed', 2) }}</q-item-section>
                        </q-item>
                        <q-item v-can="'ProcessedCorrespondenceItem:read:all'" :to="{ name: 'processed-correspondence-email-list' }" exact-path>
                            <q-item-section>{{ $tc('common.correspondence.email.sent-email', 2) }}</q-item-section>
                        </q-item>
                    </q-list>
                </q-expansion-item>

                <q-item v-if="['Feature:mailProcessing:core', 'MailProcessing:use'].every(permission => $can(permission))" :to="{ name: 'correspondence-mail-processing-generate-cover-letter' }">
                    <q-item-section>{{ $t('common.mail-processing.mail-processing') }}</q-item-section>
                </q-item>
            </q-list>
        </q-expansion-item>

        <q-expansion-item
            v-if="['Feature:accounting:core', 'Accounting:manage'].every(permission => $can(permission))"
            v-model="navGroupState.accounting"
            :to="{ name: 'accounting' }"
            :label="$tc('common.accounting.accounting', 1)"
            icon="mi-accounting-calculator-2"
            expand-icon="mib-arrow-left-1"
            expanded-icon="mib-arrow-down-1"
            :content-inset-level="1"
        >
            <q-list dense>
                <q-item :to="{ name: 'accounting-postings' }" exact-path>
                    <q-item-section>{{ $tc('common.accounting.posting.posting', 2) }}</q-item-section>
                </q-item>
                <q-item :to="{ name: 'accounting-transfer-postings' }" exact-path>
                    <q-item-section>{{ $tc('views.accounting.transfer-posting.transfer-posting', 2) }}</q-item-section>
                </q-item>
                <q-item :to="{ name: 'accounting-statements' }" exact-path>
                    <q-item-section>{{ $tc('common.accounting.statement.statement', 2) }}</q-item-section>
                </q-item>
                <q-item :to="{ name: 'accounting-account-balance-reports' }" exact-path>
                    <q-item-section>{{ $tc('common.accounting.account-balance-report.account-balance-report', 2) }}</q-item-section>
                </q-item>
                <q-item :to="{ name: 'accounting-account-statements' }" exact-path>
                    <q-item-section>{{ $tc('common.accounting.account-statement.account-statement', 2) }}</q-item-section>
                </q-item>
            </q-list>
        </q-expansion-item>

        <q-expansion-item
            v-if="$can('Feature:commissions:core') && ['Commissions:manage', 'CommissionRevenueReport:create'].some(permission => $can(permission))"
            v-model="navGroupState.commissions"
            :to="{ name: 'commissions' }"
            :label="$tc('common.commission.commission', 2)"
            icon="mi-accounting-coins-bill"
            expand-icon="mib-arrow-left-1"
            expanded-icon="mib-arrow-down-1"
            :content-inset-level="1"
        >
            <q-list dense>
                <q-item :to="{ name: 'commission-lists' }" exact-path>
                    <q-item-section>{{ $tc('views.commission.commission-list.commission-list', 2) }}</q-item-section>
                </q-item>
                <q-item :to="{ name: 'commission-list-manual-entries' }" exact-path>
                    <q-item-section>{{ $tc('views.commission.manual-entry.manual-entry', 2) }}</q-item-section>
                </q-item>
                <q-item v-if="$can('CommissionRevenueReport:create')" :to="{ name: 'commission-revenue-report-create' }" exact-path>
                    <q-item-section>{{ $tc('common.commission.commission-income-report.commission-income-report', 1) }}</q-item-section>
                </q-item>
                <q-item v-if="$can('CommissionTurnoverReport:create')" :to="{ name: 'commission-turnover-report-create' }" exact-path>
                    <q-item-section>{{ $tc('common.commission.commission-turnover-report.commission-turnover-report', 1) }}</q-item-section>
                </q-item>
            </q-list>
        </q-expansion-item>
    </q-list>
</template>

<script>
export default {
    name: 'MainMenuDefault',
    data () {
        return {
            navGroupState: {
                correspondence: this.$route.meta.navGroup === 'correspondence',
                accounting: this.$route.meta.navGroup === 'accounting',
                commissions: this.$route.meta.navGroup === 'commissions',
            },
            subNavGroupState: {
                letters: this.$route.meta.subNavGroup === 'letters',
                emails: this.$route.meta.subNavGroup === 'emails',
            },
        }
    },
    watch: {
        $route (to) {
            if (this.navGroupState[to.meta.navGroup] !== undefined) this.navGroupState[to.meta.navGroup] = true
            if (this.subNavGroupState[to.meta.subNavGroup] !== undefined) this.subNavGroupState[to.meta.subNavGroup] = true
        },
    },
}
</script>
