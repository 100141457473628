<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.commission.commission-distribution.commission-distribution', 1)" :to="{ name: 'commission-distribution-rule-sets' }" />
            </template>

            <h1>{{ $tc('views.commission.commission-distribution.commission-distribution', 1) }}</h1>
        </page-header>

        <base-table
            :columns="columns"
            :fetch-objects-fn="CommissionDistributionRuleSet.objects.all"
            user-settings-base-path="CommissionDistributionRuleSetList"
            pagination-sort-by-default-key="name"
            data-test="table:commission-distribution-rule-set-list"
            @row-dblclick="onDblClick"
        >
            <template v-slot:body-cell-name="slotProps">
                <q-td :props="slotProps">
                    {{ slotProps.row.name }}
                    <info-icon
                        v-if="slotProps.row.is_assigned"
                        data-test="btn:tooltip-details"
                        name="mib-lock-1"
                        :text="$tc('views.commission.commission-distribution.rule-set-assigned--info', 1)"
                    />
                </q-td>
            </template>

            <template v-slot:body-cell-is_assigned="slotProps">
                <q-td :props="slotProps" auto-width>
                    <info-icon
                        v-if="slotProps.row.is_assigned"
                        name="mib-check"
                        color="text-secondary"
                        data-test="btn:tooltip-used"
                        :text="$tc('views.commission.commission-distribution.rule-set-assigned--info', 1)"
                    />
                    <info-icon
                        v-else
                        name="mib-subtract"
                        color="text-secondary"
                        data-test="btn:tooltip-used"
                        :text="$tc('views.commission.commission-distribution.rule-set-unassigned--info', 1)"
                    />
                </q-td>
            </template>

            <template v-slot:body-cell-actions="slotProps">
                <q-td :props="slotProps" auto-width>
                    <q-btn
                        color="primary"
                        size="sm"
                        flat
                        round
                        :icon="slotProps.row.is_assigned ? 'mib-view-1' : 'mib-pencil'"
                        :to="{ name: 'commission-distribution-rule-set-update', params: { id: slotProps.row.id } }"
                        data-test="link:tooltip-actions"
                    >
                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc(`common.rule.${slotProps.row.is_assigned ? 'show' : 'update'}-rule-set`, 1) }}</q-tooltip>
                    </q-btn>

                    <q-btn
                        color="primary"
                        size="sm"
                        flat
                        round
                        icon="mib-common-file-double-1"
                        :to="{ name: 'commission-distribution-rule-set-create', query: { prefill: slotProps.row.id } }"
                    >
                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.rule.duplicate-rule-set', 1) }}</q-tooltip>
                    </q-btn>
                </q-td>
            </template>
        </base-table>

        <in-page-footer>
            <base-button
                :to="{ name: 'commission-distribution-rule-set-create' }"
                :label="$tc('common.rule.add-rule-set', 1)"
                primary-button
                data-test="link:add-commission-distribution-rule-set"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import BaseTable from '@/components/BaseTable'
import { CommissionDistributionRuleSet } from '@/models/models'

export default {
    name: 'CommissionDistributionRuleSetList',
    components: {
        BaseTable,
    },
    meta () {
        return {
            title: this.$tc('views.commission.commission-distribution.commission-distribution-rule', 2),
        }
    },
    data () {
        return {
            CommissionDistributionRuleSet,
            columns: [
                {
                    name: 'name',
                    label: this.$tc('common.rule.rule-set', 1),
                    align: 'left',
                    sortable: true,
                    required: true,
                },
                {
                    name: 'description',
                    label: this.$tc('common.term.description', 1),
                    field: row => row.description,
                    align: 'left',
                    required: true,
                },
                {
                    name: 'is_assigned',
                    label: this.$t('common.term.used'),
                    align: 'center',
                    required: true,
                },
                {
                    name: 'actions',
                    label: this.$tc('common.term.action', 2),
                    align: 'left',
                    required: true,
                },
            ],
        }
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        onNewEntity () {
            this.$router.push({
                name: 'commission-distribution-rule-set-create',
            })
        },
        onDblClick (evt, row) {
            this.$router.push({
                name: 'commission-distribution-rule-set-update',
                params: { id: row.id },
            })
        },
    },
}
</script>
