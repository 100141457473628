import gql from 'graphql-tag'

export const CORRESPONDENCE_EMAIL_PREVIEW_CREATE_MUTATION = gql`
    mutation correspondenceEmailPreviewCreate(
        $id: ID!
        $targetObjectId: ID!
    ) {
        correspondenceEmailPreviewCreate(
            id: $id
            target_object_id: $targetObjectId
        )
    }
`
