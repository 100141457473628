import { checkRouteAccessPermissions, checkRouteAccessPermissionsNested } from '@/helpers/acl'
import DefaultLayout from '@/layouts/Default'
import CorrespondenceDashboard from '@/views/correspondence/CorrespondenceDashboard'
import CorrespondenceLetterList from '@/views/correspondence/CorrespondenceLetterList'
import CorrespondenceLetterCreate from '@/views/correspondence/CorrespondenceLetterCreate'
import CorrespondenceLetterSummary from '@/views/correspondence/CorrespondenceLetterSummary'
import CorrespondenceLetterUpdate from '@/views/correspondence/CorrespondenceLetterUpdate'
import CorrespondenceLetterPreview from '@/views/correspondence/CorrespondenceLetterPreview'
import ProcessedCorrespondenceLetterList from '@/views/correspondence/ProcessedCorrespondenceLetterList'
import CorrespondenceEmailList from '@/views/correspondence/CorrespondenceEmailList'
import CorrespondenceEmailCreate from '@/views/correspondence/CorrespondenceEmailCreate'
import CorrespondenceEmailSummary from '@/views/correspondence/CorrespondenceEmailSummary'
import CorrespondenceEmailUpdate from '@/views/correspondence/CorrespondenceEmailUpdate'
import CorrespondenceEmailPreview from '@/views/correspondence/CorrespondenceEmailPreview'
import ProcessedCorrespondenceEmailList from '@/views/correspondence/ProcessedCorrespondenceEmailList'
import DocumentTemplateList from '@/views/correspondence/DocumentTemplateList'
import DocumentTemplateCreate from '@/views/correspondence/DocumentTemplateCreate'
import DocumentTemplateUpdate from '@/views/correspondence/DocumentTemplateUpdate'
import MailProcessingGenerateCoverLetter from '@/views/correspondence/MailProcessingGenerateCoverLetter'
import MailProcessingTemplateList from '@/views/correspondence/MailProcessingTemplateList'
import MailProcessingTemplateCreate from '@/views/correspondence/MailProcessingTemplateCreate'
import MailProcessingTemplateUpdate from '@/views/correspondence/MailProcessingTemplateUpdate'
import CorrespondenceSalutationList from '@/views/correspondenceSalutation/CorrespondenceSalutationList.vue'
import CorrespondenceSalutationCreate from '@/views/correspondenceSalutation/CorrespondenceSalutationCreate.vue'
import CorrespondenceSalutationUpdate from '@/views/correspondenceSalutation/CorrespondenceSalutationUpdate.vue'

export default [
    // System preferences
    {
        path: '/admin/correspondence/',
        name: 'admin-correspondence',
        redirect: {
            name: 'admin-correspondence-salutation',
        },
    },
    {
        path: '/admin/correspondence/salutations',
        name: 'admin-correspondence-salutation',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:correspondence:core', 'CorrespondenceSalutation:manage'],
            can: checkRouteAccessPermissionsNested,
            appSubSection: 'admin',
            navGroup: 'correspondence',
        },
        redirect: {
            name: 'admin-correspondence-salutation-list',
        },
        children: [
            {
                path: '/',
                name: 'admin-correspondence-salutation-list',
                component: CorrespondenceSalutationList,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'correspondence',
                },
            },
            {
                path: 'create',
                name: 'admin-correspondence-salutation-create',
                component: CorrespondenceSalutationCreate,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'correspondence',
                },
            },
            {
                path: ':id(\\d+)',
                name: 'admin-correspondence-salutation-update',
                component: CorrespondenceSalutationUpdate,
                props: true,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'correspondence',
                },
            },
        ],
    },
    {
        path: '/admin/correspondence/mail-processing-templates',
        name: 'admin-correspondence-mail-processing-template',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:mailProcessing:core', 'MailProcessingSettings:manage'],
            can: checkRouteAccessPermissionsNested,
            appSubSection: 'admin',
            navGroup: 'correspondence',
        },
        children: [
            {
                path: '/',
                name: 'mail-processing-template-list',
                component: MailProcessingTemplateList,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'correspondence',
                    subNavGroup: 'mailProcessingTemplate',
                },
            },
            {
                path: 'create',
                name: 'mail-processing-template-create',
                component: MailProcessingTemplateCreate,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'correspondence',
                    subNavGroup: 'mailProcessingTemplate',
                },
            },
            {
                path: ':mailProcessingTemplateId(\\d+)/update',
                name: 'mail-processing-template-update',
                component: MailProcessingTemplateUpdate,
                props: true,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'correspondence',
                    subNavGroup: 'mailProcessingTemplate',
                },
            },
        ],
    },

    {
        path: '/correspondence',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'correspondence-dashboard',
            component: CorrespondenceDashboard,
            meta: {
                can: 'Feature:correspondence:core',
                navGroup: 'correspondence',
            },
            redirect: { name: 'correspondence-document-template-list'},
        }],
    },

    // MailProcessing
    {
        path: '/correspondence/mail-processing/generate-cover-letter',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'correspondence-mail-processing-generate-cover-letter',
            component: MailProcessingGenerateCoverLetter,
            props: true,
            meta: {
                can: 'Feature:correspondence:core',
                navGroup: 'correspondence',
            },
        }],
    },

    // Templates
    {
        path: '/correspondence/templates',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'correspondence-document-template-list',
            component: DocumentTemplateList,
            meta: {
                can: 'Feature:correspondence:core',
                navGroup: 'correspondence',
            },
        }],
    },
    {
        path: '/correspondence/templates/create',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'correspondence-document-template-create',
            component: DocumentTemplateCreate,
            meta: {
                can: 'Feature:correspondence:core',
                navGroup: 'correspondence',
            },
        }],
    },
    {
        path: '/correspondence/templates/:id(\\d+)',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'correspondence-document-template-update',
            component: DocumentTemplateUpdate,
            props: true,
            meta: {
                can: 'Feature:correspondence:core',
                navGroup: 'correspondence',
            },
        }],
    },

    // Letters
    {
        path: '/correspondence/letters',
        name: 'correspondence-letters',
        redirect: { name: 'correspondence-letter-list', params: { statusGroup: 'pending' } },
    },
    {
        path: '/correspondence/letters/:statusGroup(pending|completed)',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'correspondence-letter-list',
            component: CorrespondenceLetterList,
            props: true,
            meta: {
                can: 'Feature:correspondence:core',
                navGroup: 'correspondence',
                subNavGroup: 'letters',
            },
        }],
    },
    {
        path: '/correspondence/letters/create',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'correspondence-letter-create',
            component: CorrespondenceLetterCreate,
            props: route => {
                const props = {}
                if (route.params.correspondenceItemTargetObjectType) props.correspondenceItemTargetObjectType = route.params.correspondenceItemTargetObjectType
                if (route.params.correspondenceItemTargetObjectId) props.correspondenceItemTargetObjectId = route.params.correspondenceItemTargetObjectId
                if (route.params.correspondenceItemInitialDataSource) props.correspondenceItemInitialDataSource = route.params.correspondenceItemInitialDataSource
                return props
            },
            meta: {
                can: 'Feature:correspondence:core',
                navGroup: 'correspondence',
                subNavGroup: 'letters',
            },
        }],
    },
    {
        path: '/correspondence/letters/pending/:id(\\d+)/update',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'correspondence-letter-update',
            component: CorrespondenceLetterUpdate,
            props: true,
            meta: {
                can: 'Feature:correspondence:core',
                navGroup: 'correspondence',
                subNavGroup: 'letters',
            },
        }],
    },
    {
        path: '/correspondence/letters/pending/:id(\\d+)/preview',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'correspondence-letter-preview',
            component: CorrespondenceLetterPreview,
            props: true,
            meta: {
                navGroup: 'correspondence',
                subNavGroup: 'letters',
            },
        }],
    },
    {
        path: '/correspondence/letters/created',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'processed-correspondence-letter-list',
            component: ProcessedCorrespondenceLetterList,
            props: true,
            meta: {
                can: 'Feature:correspondence:core',
                navGroup: 'correspondence',
                subNavGroup: 'letters',
            },
        }],
    },
    {
        path: '/correspondence/letters/completed/:id(\\d+)/summary',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'correspondence-letter-summary',
            component: CorrespondenceLetterSummary,
            props: true,
            meta: {
                can: 'Feature:correspondence:core',
                navGroup: 'correspondence',
                subNavGroup: 'letters',
            },
        }],
    },

    // Emails
    {
        path: '/correspondence/emails',
        name: 'correspondence-emails',
        redirect: { name: 'correspondence-email-list', params: { statusGroup: 'pending' } },
    },
    {
        path: '/correspondence/emails/:statusGroup(pending|completed)',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'correspondence-email-list',
            component: CorrespondenceEmailList,
            props: true,
            meta: {
                can: 'Feature:correspondence:core',
                navGroup: 'correspondence',
                subNavGroup: 'emails',
            },
        }],
    },
    {
        path: '/correspondence/emails/create',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'correspondence-email-create',
            component: CorrespondenceEmailCreate,
            props: route => {
                const props = {}
                if (route.params.correspondenceItemTargetObjectType) props.correspondenceItemTargetObjectType = route.params.correspondenceItemTargetObjectType
                if (route.params.correspondenceItemTargetObjectId) props.correspondenceItemTargetObjectId = route.params.correspondenceItemTargetObjectId
                if (route.params.correspondenceItemInitialDataSource) props.correspondenceItemInitialDataSource = route.params.correspondenceItemInitialDataSource
                return props
            },
            meta: {
                can: 'Feature:correspondence:core',
                navGroup: 'correspondence',
                subNavGroup: 'emails',
            },
        }],
    },
    {
        path: '/correspondence/emails/pending/:id(\\d+)/update',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'correspondence-email-update',
            component: CorrespondenceEmailUpdate,
            props: true,
            meta: {
                can: 'Feature:correspondence:core',
                navGroup: 'correspondence',
                subNavGroup: 'emails',
            },
        }],
    },
    {
        path: '/correspondence/emails/pending/:id(\\d+)/preview',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'correspondence-email-preview',
            component: CorrespondenceEmailPreview,
            props: true,
            meta: {
                can: 'Feature:correspondence:core',
                navGroup: 'correspondence',
                subNavGroup: 'emails',
            },
        }],
    },
    {
        path: '/correspondence/emails/sent',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'processed-correspondence-email-list',
            component: ProcessedCorrespondenceEmailList,
            props: true,
            meta: {
                can: 'Feature:correspondence:core',
                navGroup: 'correspondence',
                subNavGroup: 'emails',
            },
        }],
    },
    {
        path: '/correspondence/emails/completed/:id(\\d+)/summary',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'correspondence-email-summary',
            component: CorrespondenceEmailSummary,
            props: true,
            meta: {
                can: 'Feature:correspondence:core',
                navGroup: 'correspondence',
                subNavGroup: 'emails',
            },
        }],
    },
]
