import gql from 'graphql-tag'

export const COMMISSION_LIST_IMPORT_EXCEL_MAPPING_CREATE_MUTATION = gql`
    mutation commissionListImportExcelMappingCreate(
        $productProviderId: ID!
        $commissionListImportExcelMapping: CommissionListImportExcelMappingInput!
    ) {
        commissionListImportExcelMappingCreate(
            product_provider_id: $productProviderId
            commissionListImportExcelMapping: $commissionListImportExcelMapping
        ) {
            id
            productProvider {
                id
            }
            name
            commission_entries_start_row
            contract_number_column
            commission_type_column
            commission_amount_column
            premium_from_column
            premium_from_date_format
            premium_to_column
            premium_to_date_format
            customer_full_name_column
            created_at
            updated_at
        }
    }
`
