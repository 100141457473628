import gql from 'graphql-tag'

export const POSTING_CREATE_MUTATION = gql`
    mutation postingCreate(
        $posting: PostingInput!
    ) {
        postingCreate(
            posting: $posting
        ) {
            id
            type {
                id
                key
                name
                name_language_versions
                created_at
                updated_at
            }
            group {
                id
            }
            date
            number {
                id
                number
            }
            text
            debitAccount {
                id
                name
            }
            creditAccount {
                id
                name
            }
            amount
            attachments {
                id
                name
                size
                mime_type
            }
            is_confidential
            created_at
            updated_at
        }
    }
`
