import { i18n } from '@/i18n'
import { kebabCase } from 'lodash'
import { ActivityLogParser } from '@/parsers/activityLog'

export class CommissionListEntryPositionActivityLogParser extends ActivityLogParser {
    static attributeDataTypes = {
        'premium_from': 'date',
        'premium_to': 'date',
        'commission_amount': 'currency',
    }

    static preprocess (activityLog) {
        if (activityLog.properties.attributes && activityLog.properties.attributes.commission_type) {
            activityLog.properties.attributes.commission_type = i18n.t(`common.commission.commission-type.${kebabCase(activityLog.properties.attributes.commission_type)}--type-number-prefix`)
        }
        if (activityLog.properties.old && activityLog.properties.old.commission_type) {
            activityLog.properties.old.commission_type = i18n.t(`common.commission.commission-type.${kebabCase(activityLog.properties.old.commission_type)}--type-number-prefix`)
        }

        return activityLog
    }

    static getLogDescriptionCreated (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `<b>${i18n.tc('common.change-log.subject.this.commission-list-entry-position', 1)}</b>`,
        }
        return i18n.t('common.change-log.someone-created-something', interpolationParams)
    }

    static getLogDescriptionUpdated (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `${i18n.tc('common.change-log.someone-updated-something--field', activityLog.attributeKeys.length)} <b>${activityLog.attributeLabels}</b>`,
        }
        return i18n.t('common.change-log.someone-updated-something', interpolationParams)
    }

    static getLogDescriptionDeleted (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `<b>${i18n.tc('common.change-log.subject.this.commission-list-entry-position', 1)}</b>`,
        }
        return i18n.t('common.change-log.someone-deleted-something', interpolationParams)
    }

    static getLogDetailsCreated (activityLog, data) {
        const logDetails = []

        if (activityLog.properties.attributes.premium_from) {
            logDetails.push (
                {
                    key: 'premium_from',
                    label: i18n.tc('forms.field.label.commission-list-entry-position.premium-from', 1),
                    newValue: this.getFormattedLogDetailValue(activityLog, 'premium_from', this.attributeDataTypes['premium_from'] ?? 'undefined', activityLog.properties.attributes.premium_from),
                }
            )
        }

        if (activityLog.properties.attributes.premium_to) {
            logDetails.push (
                {
                    key: 'premium_to',
                    label: i18n.tc('forms.field.label.commission-list-entry-position.premium-to', 1),
                    newValue: this.getFormattedLogDetailValue(activityLog, 'premium_to', this.attributeDataTypes['premium_to'] ?? 'undefined', activityLog.properties.attributes.premium_to),
                }
            )
        }

        logDetails.push (
            {
                key: 'commission_type',
                label: i18n.tc('forms.field.label.commission-list-entry-position.commission-type', 1),
                newValue: activityLog.properties.attributes.commission_type,
            },
            {
                key: 'commission_amount',
                label: i18n.tc('forms.field.label.commission-list-entry-position.commission-amount', 1),
                newValue: this.getFormattedLogDetailValue(activityLog, 'commission_amount', this.attributeDataTypes['commission_amount'] ?? 'undefined', activityLog.properties.attributes.commission_amount),
            }
        )

        return logDetails
    }
}
