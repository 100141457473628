import { ActivityLogParser } from '@/parsers/activityLog'

export class ContactActivityLogParser extends ActivityLogParser {
    static attributeDataTypes = {
        'customer_since': 'date',
        'customer_until': 'date',
        'correspondenceLanguage.id': 'correspondenceLanguage',
        'nationality.id': 'country',
    }
}
