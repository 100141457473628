import gql from 'graphql-tag'

export const POSTING_DELETE_ATTACHMENT_MUTATION = gql`
    mutation postingDeleteAttachment(
        $id: ID!
        $fileId: ID!
    ) {
        postingDeleteAttachment(
            id: $id
            file_id: $fileId
        ) {
            status
        }
    }
`
