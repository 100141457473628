<template>
    <page-loading-indicator
        v-if="!contract"
        :number-of-breadcrumb-elements="5"
        subtitle
    />
    <page-wrapper v-else>
        <page-header :back-link-route="{ name: 'contract-detail', params: { id: contract.id } }" :back-link-label="$tc('views.contract.settings.back-to-contract', 1)">
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el
                    :label="contract.customer.getContactName()"
                    :to="{ name: 'contact-detail', params: { id: contactId } }"
                />
                <q-breadcrumbs-el
                    :label="$tc('common.term.contract', 2)"
                    :to="{ name: 'contact-detail', params: { id: contactId } }"
                />
                <q-breadcrumbs-el
                    :label="contract.currentContractNumber"
                    :to="{ name: 'contract-detail', params: { id: contract.id } }"
                />
                <q-breadcrumbs-el
                    :label="$tc('views.contract.settings.settings', 2)"
                    :to="{ name: 'contract-settings', params: { id: id } }"
                />
                <q-breadcrumbs-el v-if="$route.meta && $route.meta.title" :label="$route.meta.title" :to="{ name: $route.name }" />
            </template>

            <h1>{{ contract.currentContractNumber }} <span class="subtitle">({{ contract.currentContractInformation.product.providerName }})</span></h1>
        </page-header>

        <div class="row q-mt-md q-col-gutter-x-md">
            <div class="col-xs-12 col-md-3 col-lg-2">
                <in-page-navigation>
                    <q-route-tab
                        :to="{
                            name: 'contract-settings-commission-settings',
                            params: {
                                id: id,
                                contactId: contactId
                            }
                        }"
                        :label="$tc('views.contract.settings.commission.commission-setting', 2)"
                        name="contract-settings-commission-settings"
                        :ripple="false"
                        data-test="tab:contract-settings-commission-settings"
                    />
                </in-page-navigation>
            </div>
            <div class="col-xs-12 col-md-9 col-lg-10">
                <router-view
                    :contract="contract"
                />
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import InPageNavigation from '@/components/InPageNavigation'

import { Contract } from '@/models/contract'

export default {
    name: 'ContractSettings',
    meta () {
        return {
            title: !this.contract
                ? `${this.$t('common.term.loading')} …`
                : this.pageTitle,
        }
    },
    components: {
        InPageNavigation,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        contactId: {
            type:String,
            required:true,
        },
    },
    data () {
        return {
            Contract,
            contract: null,
        }
    },
    computed: {
        pageTitle () {
            const currentPage = (this.$route.meta && this.$route.meta.title)
                ? `${this.$tc('common.term.setting', 2)}: ${this.$route.meta.title}`
                : this.$tc('common.term.setting', 2)

            return `${this.contract.currentContractNumber} – ${currentPage}`
        },
    },
    created () {
        this.fetchObject()
    },
    methods: {
        fetchObject () {
            Contract.objects.get(this.id).then(contract => {
                this.contract = contract
            })
        },
    },
}

</script>

