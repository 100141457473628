import { apolloClient } from '@/apollo/client'

import { DRIVE_QUERY } from '@/graphql/drive/drive'

export const DriveService = {
    all (variables) {
        return apolloClient.query({
            query: DRIVE_QUERY,
            variables,
        }).then(response => response.data.drive)
    },
}
