<template>
    <div class="display-contents">
        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="errorMessage"
            type="negative"
            data-test="error:global-message"
            v-html="errorMessage"
        />
        <!-- eslint-enable -->

        <markup-table
            :columns="columns"
            :data="ruleRangesData"
            dense
            :data-test="`table:commission-distribution-rule-set`"
        >
            <template v-slot:body-row="slotProps">
                <markup-table-row v-if="!slotProps.row.editMode" :columns="columns" :row="slotProps.row">
                    <template v-slot:body-cell-actions class="actions text-right" :class="{ 'has-error': formErrorMessage }">
                        <div>
                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-pencil"
                                :disable="disableForm"
                                data-test="btn:tooltip-actions-update"
                                @click="toggleUpdateFormVisibility(slotProps.row, slotProps.rowIndex)"
                            />
                            <q-tooltip
                                v-if="disableForm"
                                :delay="1000"
                                :offset="[0, 10]"
                                max-width="50ch"
                            >
                                {{ $tc('views.commission.commission-distribution.rule-set-assigned--info', 1) }}
                            </q-tooltip>
                            <q-tooltip v-else :delay="1000" :offset="[0, 10]">{{ $tc('common.table.update-row', 1) }}</q-tooltip>
                        </div>

                        <div v-if="slotProps.rowIndex > 0">
                            <q-btn
                                color="negative"
                                size="sm"
                                flat
                                round
                                icon="mib-bin"
                                :disable="disableForm"
                                data-test="btn:tooltip-actions-delete"
                                @click="deleteCommissionDistributionRuleRange(slotProps.rowIndex, $event)"
                            />
                            <q-tooltip
                                v-if="disableForm"
                                :delay="1000"
                                :offset="[0, 10]"
                                max-width="50ch"
                            >
                                {{ $tc('views.commission.commission-distribution.rule-set-assigned--info', 1) }}
                            </q-tooltip>
                            <q-tooltip v-else :delay="1000" :offset="[0, 10]">{{ $tc('common.table.delete-row', 1) }}</q-tooltip>
                        </div>
                    </template>
                </markup-table-row>

                <markup-table-row v-show="slotProps.row.editMode">
                    <commission-distribution-rule-range-form
                        :ref="`updateForm-${slotProps.row.id}`"
                        :commission-distribution-rule-range="slotProps.row.model"
                        @submit="formData => handleUpdate(slotProps.rowIndex, slotProps.row.id, formData)"
                        @cancel="handleCancel(slotProps.rowIndex, slotProps.row.id)"
                    />
                </markup-table-row>
            </template>

            <template v-if="displayCreateForm" v-slot:afterContent>
                <markup-table-row>
                    <commission-distribution-rule-range-form
                        ref="createForm"
                        :current-amount-from="currentAmountFrom"
                        :disable-mounted-autofocus="currentMode === ViewMode.CREATE"
                        @submit="handleAdd"
                    />
                </markup-table-row>
            </template>
        </markup-table>

        <form-dialog
            v-if="commissionDistributionRuleRangeToDelete"
            ref="commissionDistributionRuleRangeDeleteDialog"
            :item="commissionDistributionRuleRangeToDelete"
            :double-confirm="lastRuleRangeDelete"
            dont-close-on-confirm
            @confirm="handleDelete"
            @hide="resetCommissionDistributionRuleRangeToDelete"
        >
            <template v-slot:title>{{ $tc('common.table.delete-row', 1) }}</template>
            <template v-slot:default="slotProps">
                <p>{{ $tc('common.table.delete-row--confirm', 1) }}</p>
                <ul v-if="slotProps.item && slotProps.item.model">
                    <li><b>{{ $tc('common.term.from-x-to-y--short', 1, { x: slotProps.item.model.formattedAmountFrom, y: slotProps.item.model.formattedAmountTo }) }}</b></li>
                    <li>{{ $tc('views.commission.commission-distribution.column-title--payout-ratio', 1) }}: <b>{{ slotProps.item.model.formattedPayoutRatio }}</b></li>
                    <li>{{ $tc('views.commission.commission-distribution.column-title--cancellation-reserve', 1) }}: <b>{{ slotProps.item.model.formattedCancellationReserve }}</b></li>
                </ul>

                <info-box v-if="lastRuleRangeDelete" type="warning">
                    <i18n path="views.commission.commission-distribution.delete-row-reset-previous-row-info" tag="p">
                        <template v-slot:newAmountTo><b>∞</b></template>
                    </i18n>
                </info-box>
            </template>
        </form-dialog>
    </div>
</template>

<script>
import { i18n } from '@/i18n'
import {
    validateFormDataRange,
    validateBiggerFormDataAmountTo,
    validateSmallerFormDataAmountTo,
    parseFormDataToRangeInput
} from '@/helpers/commissionDistributionRuleSet'
import { extractErrorMessage } from '@/helpers/form'
import FormDialog from '@/components/form/FormDialog'
import MarkupTable from '@/components/MarkupTable'
import MarkupTableRow from '@/components/MarkupTableRow'
import { CommissionDistributionRuleRange } from '@/models/commissionDistributionRuleRange'
import CommissionDistributionRuleRangeForm from '@/components/commission/commissionDistributionRuleRange/CommissionDistributionRuleRangeForm.vue'
import { EventBus } from '@/event-bus'
import { ViewMode } from '@/enums'

export default {
    name: 'CommissionDistributionRuleRangeManage',
    components: {
        CommissionDistributionRuleRangeForm,
        FormDialog,
        MarkupTable,
        MarkupTableRow,
    },
    props: {
        commissionDistributionRule: {
            type: Object,
            required: true,
        },
        disableForm: {
            type: Boolean,
            default: false,
        },
        currentMode: {
            type: String,
            default: ViewMode.CREATE,
        },
    },
    data () {
        const data = {
            ViewMode,
            amountFromThreshold: 0.01,
            ruleRangesData: [],
            errorMessage: '',
            tmpRuleIdCounter: 0,
            tmpRuleIdPrefix: 'tmp',
            commissionDistributionRuleRangeIndexToDelete: null,
            commissionDistributionRuleRangeToDelete: null,
            columns: [
                {
                    name: 'amount_from',
                    label: this.$tc('views.commission.commission-distribution.column-title--amount-from', 1),
                    align: 'right',
                    field: row => row.model.formattedAmountFrom,
                    minWidth: '11em',
                    maxWidth: '14em',
                    required: true,
                },
                {
                    name: 'amount_to',
                    label: this.$tc('views.commission.commission-distribution.column-title--amount-to', 1),
                    align: 'right',
                    field: row => row.model.formattedAmountTo,
                    required: true,
                },
                {
                    name: 'payout_ratio',
                    label: this.$tc('views.commission.commission-distribution.column-title--payout-ratio', 1),
                    align: 'right',
                    field: row => row.model.formattedPayoutRatio,
                    required: true,
                },
                {
                    name: 'cancellation_reserve',
                    label: this.$tc('views.commission.commission-distribution.column-title--cancellation-reserve', 1),
                    align: 'right',
                    field: row => row.model.formattedCancellationReserve,
                    required: true,
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left', required: true, autoWidth: true },
            ],
        }

        if (this.commissionDistributionRule.ranges) {
            data.ruleRangesData = this.commissionDistributionRule.ranges.map(rangeInput => ({
                id: rangeInput.id,
                editMode: false,
                model: rangeInput,
            }))
        }

        return data
    },
    computed: {
        lastRuleRangeDelete () {
            return this.commissionDistributionRuleRangeToDelete.id === this.lastRuleRangeData.id
        },
        lastRuleRangeData () {
            return this.ruleRangesData[this.ruleRangesData.length - 1]
        },
        currentAmountFrom () {
            const lastRuleRangeData = this.lastRuleRangeData
            if (lastRuleRangeData) {
                return parseFloat((this.lastRuleRangeData.model.amount_to + this.amountFromThreshold).toFixed(2))
            } else {
                return null
            }
        },
        containsRuleRangesData () {
            return !!this.ruleRangesData.length
        },
        displayCreateForm () {
            return !(this.lastRuleRangeData && this.lastRuleRangeData.model.amount_to === null)
        },
        commissionType () {
            return this.commissionDistributionRule.commission_type
        },
    },
    methods: {
        handleAdd (formData) {
            const errorMessages = this.validateCreateFormData(formData)
            this.$refs.createForm.resetFormSubmitStatus()

            if (errorMessages.graphQLErrors.length) {
                this.$refs.createForm.showFormErrorMessage(errorMessages)
                return
            } else {
                this.$refs.createForm.resetFormErrorMessage()
            }

            const ruleRange = {
                editMode: false,
                id: `${this.tmpRuleIdPrefix}-${this.tmpRuleIdCounter++}`,
                model: new CommissionDistributionRuleRange(parseFormDataToRangeInput(formData)),
            }
            this.ruleRangesData.push(ruleRange)

            this.$refs.createForm.resetFormData()
            this.$refs.createForm.setFocus('amountTo')
            this.resetErrorMessage()
        },
        handleUpdate (index, id, formData) {
            const errorMessages = this.validateUpdateFormData(index, formData)
            const form = this.$refs[`updateForm-${id}`]
            form.resetFormSubmitStatus()

            if (errorMessages.graphQLErrors.length) {
                form.showFormErrorMessage(errorMessages)
                return
            } else {
                form.resetFormErrorMessage()
            }

            // Update range model from formData
            Object.assign(this.ruleRangesData[index].model, parseFormDataToRangeInput(formData))

            // Update next rule range date amountTo
            const nextRuleRangeData = this.ruleRangesData[index + 1]
            if (nextRuleRangeData) {
                const nextAmountFrom = parseFloat(formData.amountTo) + this.amountFromThreshold
                nextRuleRangeData.model.amount_from = parseFloat(nextAmountFrom.toFixed(2))
            }

            this.toggleUpdateFormVisibility(this.ruleRangesData[index])
        },
        handleCancel (index, id) {
            const form = this.$refs[`updateForm-${id}`]
            form.resetFormErrorMessage()
            form.resetFormSubmitStatus()
            form.resetInitialFormData()

            this.toggleUpdateFormVisibility(this.ruleRangesData[index])
        },
        async deleteCommissionDistributionRuleRange(index, $event) {
            $event.preventDefault()

            this.commissionDistributionRuleRangeIndexToDelete = index
            this.commissionDistributionRuleRangeToDelete = this.ruleRangesData[this.commissionDistributionRuleRangeIndexToDelete]
            await this.$nextTick()
            this.$refs.commissionDistributionRuleRangeDeleteDialog.open()
        },
        resetCommissionDistributionRuleRangeToDelete () {
            this.commissionDistributionRuleRangeIndexToDelete = null
            this.commissionDistributionRuleRangeToDelete = null
        },
        handleDelete () {
            const lastRuleRangeDelete = this.lastRuleRangeDelete
            const removedRange = this.ruleRangesData.splice(this.commissionDistributionRuleRangeIndexToDelete, 1).pop()

            if (this.ruleRangesData[this.commissionDistributionRuleRangeIndexToDelete]) {
                this.ruleRangesData[this.commissionDistributionRuleRangeIndexToDelete].model.amount_from = removedRange.model.amount_from
            }

            if (lastRuleRangeDelete) {
                this.lastRuleRangeData.model.amount_to = null
            }

            this.resetErrorMessage()

            this.$refs.commissionDistributionRuleRangeDeleteDialog.close()
            EventBus.$emit('commissionDistributionRuleSet:submit-rule-range-forms-any-dirty-state', true)
        },
        // TODO improvement @TFU: Validate with vuelidate if possible.
        validateCreateFormData (formData) {
            const errorMessages = { graphQLErrors: [] }

            // amountTo > amountFrom
            if (!validateBiggerFormDataAmountTo(formData, this.currentAmountFrom)) {
                errorMessages.graphQLErrors.push({ message: i18n.t('common.error-message.more-than-value', { field: i18n.tc('views.commission.commission-distribution.column-title--amount-to', 1), value: `CHF ${this.currentAmountFrom}` }) })
            }

            // cancellationReserve less or equal payoutRatio
            if (!validateFormDataRange(formData)) {
                errorMessages.graphQLErrors.push({ message: i18n.t('common.error-message.less-than-or-equal-value', { field: i18n.tc('views.commission.commission-distribution.column-title--cancellation-reserve', 1), value: `${formData.payoutRatio} %` }) })
            }

            return errorMessages
        },
        // TODO improvement @TFU: Validate with vuelidate if possible.
        validateUpdateFormData (index, formData) {
            const errorMessages = { graphQLErrors: [] }

            const nextRuleRangeData = this.ruleRangesData[index + 1]
            const nextAllowedAmountTo = nextRuleRangeData?.model?.amount_to
                ? parseFloat(nextRuleRangeData.model.amount_to) - this.amountFromThreshold
                : null

            // Only last row can have an empty `amountTo` field.
            if (nextRuleRangeData && formData.amountTo === '') {
                errorMessages.graphQLErrors.push({ message: i18n.t('views.commission.commission-distribution.error--amount-to-can-only-be-empty-in-last-row') })
            }

            // amountTo < nextAllowedAmountTo
            if (formData.amountTo && nextAllowedAmountTo && !validateSmallerFormDataAmountTo(formData, parseFloat(nextAllowedAmountTo.toFixed(2)))) {
                errorMessages.graphQLErrors.push({ message: i18n.t('common.error-message.less-than-value', { field: i18n.tc('views.commission.commission-distribution.column-title--amount-to', 1), value: parseFloat(nextAllowedAmountTo.toFixed(2)) }) })
            }

            // amountTo > amountFrom
            if (!validateBiggerFormDataAmountTo(formData, formData.amountFrom)) {
                errorMessages.graphQLErrors.push({ message: i18n.t('common.error-message.more-than-value', { field: i18n.tc('views.commission.commission-distribution.column-title--amount-to', 1), value: `CHF ${formData.amountFrom}` }) })
            }

            // cancellationReserve less or equal payoutRatio
            if (!validateFormDataRange(formData)) {
                errorMessages.graphQLErrors.push({ message: i18n.t('common.error-message.less-than-or-equal-value', { field: i18n.tc('views.commission.commission-distribution.column-title--cancellation-reserve', 1), value: `${formData.payoutRatio} %` }) })
            }

            return errorMessages
        },
        toggleUpdateFormVisibility (ruleRangeData) {
            ruleRangeData.editMode = !ruleRangeData.editMode
            if (ruleRangeData.editMode) {
                this.$refs[`updateForm-${ruleRangeData.id}`].setFocus('amountTo')
            }
        },
        showErrorMessage (error) {
            this.errorMessage = extractErrorMessage(error)
        },
        resetErrorMessage () {
            this.errorMessage = ''
        },
    },
}
</script>

<style lang="scss" scoped>
// (Forcefully) Hide info icon in all "amount to" fields but the last row per rule
::v-deep .markup-table-tbody:not(:last-child) {
    .markup-table-td--amount_to {
        .q-field__append {
            display: none;
        }
    }
}
</style>
