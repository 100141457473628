/**
 * Parse form data values.
 * @export
 * @param  {Object} formData[] - [{notes}]
 * @return {Object} - typeof ContactRelationshipInput
 */
export function parseFormDataToInput(formData) {
    return {
        notes: formData.notes,
    }
}
