<template>
    <form-builder-next
        :form="form"
        :initial-data="initialData"
        in-page-footer
        @submit="formData => $emit('submit', formData)"
    >
        <template v-slot:default="{ formBuilder }">
            <card
                type="primary"
                class="basic-information"
                :heading="$t('common.contract.basic-info')"
            >
                <!-- Application -->
                <div class="row q-col-gutter-md">
                    <div
                        v-if="formBuilder.form.getItem('externalReferenceNumber')"
                        class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3"
                    >
                        <form-builder-next :item="formBuilder.form.getItem('externalReferenceNumber')" autofocus />
                    </div>
                    <!-- TODO @TFU: Pass "isChangeApplication" – scenario createChangeApplication triggers ViewMode.UPDATE -->
                    <div
                        v-if="(currentMode === ViewMode.CREATE || isChangeApplication) && formBuilder.form.getItem('status')"
                        class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-2"
                    >
                        <form-builder-next :item="formBuilder.form.getItem('status')" />
                    </div>
                    <div
                        v-if="formBuilder.form.getItem('onHoldUntil') && !formBuilder.form.getItem('onHoldUntil').configuration.attributes.disable"
                        class="col-xs-12 col-sm-6 col-md-3 col-lg-2 col-xl-2"
                    >
                        <form-builder-next :item="formBuilder.form.getItem('onHoldUntil')" />
                    </div>
                    <div
                        v-if="formBuilder.form.getItem('submissionSendDate') && !formBuilder.form.getItem('submissionSendDate').configuration.attributes.disable"
                        class="col-xs-12 col-sm-6 col-md-3 col-lg-2 col-xl-2"
                    >
                        <form-builder-next :item="formBuilder.form.getItem('submissionSendDate')" />
                    </div>
                    <!-- TODO @TFU: Pass "scenarioUpdateApplication" – scenarioCreateChangeApplication triggers ViewMode.UPDATE -->
                    <div
                        v-if="currentMode === ViewMode.UPDATE && formBuilder.form.getItem('submissionResponseDate')"
                        class="col-xs-12 col-sm-6 col-md-3 col-lg-2 col-xl-2"
                    >
                        <form-builder-next :item="formBuilder.form.getItem('submissionResponseDate')" />
                    </div>

                    <!-- Contract -->
                    <div
                        v-if="scenarioCreateContract && formBuilder.form.getItem('contractNumber')"
                        class="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-3"
                    >
                        <form-builder-next :item="formBuilder.form.getItem('contractNumber')" autofocus />
                    </div>

                    <!-- Both -->
                    <div
                        :class="[
                            'col-xs-12 col-sm-6 col-lg-2 col-xl-2',
                            {
                                'col-md-4': scenarioCreateApplication || scenarioCreateChangeApplication || scenarioUpdateApplication || scenarioUpdateContract,
                                'col-md-3': scenarioCreateContract
                            }
                        ]"
                    >
                        <form-builder-next :item="formBuilder.form.getItem('startDate')" />
                    </div>
                    <div
                        :class="[
                            'col-xs-12 col-sm-6 col-lg-2 col-xl-2',
                            {
                                'col-md-4': scenarioCreateApplication || scenarioCreateChangeApplication || scenarioUpdateApplication || scenarioUpdateContract,
                                'col-md-3': scenarioCreateContract
                            }
                        ]"
                    >
                        <form-builder-next :item="formBuilder.form.getItem('endDate')" />
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-2">
                        <form-builder-next :item="formBuilder.form.getItem('premium')" prefix="CHF" />
                    </div>
                    <div
                        :class="[
                            'col-xs-12 col-sm-12 col-lg-5',
                            {
                                'col-md-6 col-xl-4': scenarioCreateApplication || scenarioCreateChangeApplication || scenarioUpdateApplication || scenarioUpdateContract,
                                'col-md-8 col-xl-3': scenarioCreateContract
                            }
                        ]"
                    >
                        <form-builder-next v-if="currentMode === ViewMode.CREATE" :item="formBuilder.form.getItem('consultantId')" />
                        <form-builder-next
                            v-else
                            :item="formBuilder.form.getItem('consultantId')"
                            :inject-consultants="[productDerivedEntity.consultant]"
                        />
                    </div>

                    <div
                        :class="[
                            'col-xs-12 col-sm-12 col-md-6 col-lg-4',
                            {
                                'col-xl-3': scenarioCreateApplication || scenarioCreateChangeApplication || scenarioUpdateApplication || scenarioCreateContract,
                                'col-xl-4': scenarioUpdateContract
                            }
                        ]"
                    >
                        <form-builder-next
                            :item="formBuilder.form.getItem('generalAgencyId')"
                            :product-provider-id="productProviderId"
                            :disable="disableGeneralAgencyId"
                            @fetched-objects="setGeneralAgencyOptions"
                        />
                        <q-tooltip
                            v-if="disableGeneralAgencyId"
                            :delay="1000"
                            anchor="center end"
                            self="center start"
                            :offset="[10, 0]"
                        >{{ $t('forms.field.label.application.managed-by--disabled-info', { providerName: productProviderName }) }}</q-tooltip>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-2">
                        <form-builder-next :item="formBuilder.form.getItem('managedByStatus')" />
                    </div>
                    <div
                        v-if="!formBuilder.form.getItem('consultingMandateEnquirySendDate').configuration.attributes.disable"
                        class="col-xs-12 col-sm-6 col-md-3 col-lg-2 col-xl-2"
                    >
                        <form-builder-next :item="formBuilder.form.getItem('consultingMandateEnquirySendDate')" :label="consultingMandateEnquirySendDateLabel" />
                    </div>
                </div>
            </card>

            <form-builder-next :item="formBuilder.form.getItem('productFields')" auto-layout />
        </template>

        <template v-slot:buttons="{ formBuilder }">
            <!-- TODO: Check if the differentiation between "create" and "save" is needed -->
            <!--
                        <base-button
                            data-test="btn:submit"
                            type="submit"
                            :label="(currentMode === ViewMode.CREATE) ? $tc('views.contract.add-contract.add-contract', 1) : $t('common.term.save')"
                            :disable="formSubmitStatus"
                            :loading="formSubmitStatus"
                        />
            -->

            <confirmation-dialog
                :bypass-confirm="!formBuilder.form.isDirty"
                @confirm="$router.go(-1)"
            >
                <template v-slot:subject>{{ $t('common.term.cancel--confirm-potential-data-loss') }}</template>
                <template v-slot:button>
                    <base-button
                        data-test="btn:cancel"
                        :label="$t('common.term.cancel')"
                        :disable="formBuilder.formSubmitStatus"
                        primary-button
                        flat
                    />
                </template>
            </confirmation-dialog>

            <base-button
                v-if="currentMode === ViewMode.CREATE"
                data-test="btn:save-and-add-another"
                :label="$t('views.contract.add-contract.save-and-add-another')"
                :disable="formBuilder.formSubmitStatus"
                :loading="formBuilder.formSubmitStatus"
                primary-button
                outline
                @click="saveAndAddAnother(formBuilder)"
            />

            <base-button
                data-test="btn:submit"
                type="submit"
                :label="$t('common.term.save')"
                :disable="formBuilder.formSubmitStatus"
                :loading="formBuilder.formSubmitStatus"
                icon="mib-check"
                primary-button
            />

            <base-button
                v-if="currentMode === ViewMode.CREATE"
                data-test="btn:save-and-add-files"
                :label="$t('views.contract.add-contract.save-and-add-files')"
                :disable="formBuilder.formSubmitStatus"
                :loading="formBuilder.formSubmitStatus"
                icon="mib-common-file-text-upload"
                primary-button
                @click="saveAndAddFiles(formBuilder)"
            />
        </template>
    </form-builder-next>
</template>

<script>
import { ViewMode } from '@/enums'
import ConfirmationDialog from '@/components/ConfirmationDialog'

import { useI18n } from '@/composables/i18n'
import { createProductFieldsNameValuePairs } from '@/helpers/contract'
import { computed, reactive, ref } from 'vue'
import { ApplicationStatus, ContractManagedByStatus } from '@/enums/graphql'
import { ApplicationForm } from '@/forms/application'
import { ContractForm } from '@/forms/contract'
import { Application } from '@/models/application'
import { composeProductFieldsSection } from '@/helpers/productDerivedEntity'

export default {
    name: 'ProductDerivedEntityForm',
    components: {
        ConfirmationDialog,
    },
    props: {
        formClass: {
            type: Function,
            required: true,
        },
        product: {
            type: Object,
            default: undefined,
        },
        productDerivedEntity: {
            type: Object,
            default: undefined,
        },
        consultantId: {
            type: String,
            default: '',
        },
        isChangeApplication: {
            type: Boolean,
            default: false,
        },
    },
    setup (props, context) {
        const { t } = useI18n()

        // Template refs
        const formComponent = ref(null)

        // Data
        const state = reactive({
            generalAgencyOptions: [],
        })
        const currentMode = ref(props.productDerivedEntity ? ViewMode.UPDATE : ViewMode.CREATE)
        const form = new props.formClass({ context: { currentMode: currentMode.value } })
        const isApplicationForm = form instanceof ApplicationForm
        const isContractForm = form instanceof ContractForm
        const scenarioCreateContract = currentMode.value === ViewMode.CREATE && isContractForm
        const scenarioUpdateContract = currentMode.value === ViewMode.UPDATE && isContractForm
        const scenarioCreateApplication = currentMode.value === ViewMode.CREATE && isApplicationForm
        const scenarioUpdateApplication = currentMode.value === ViewMode.UPDATE && isApplicationForm
        const scenarioCreateChangeApplication = props.isChangeApplication && isApplicationForm

        // Computed
        const disableGeneralAgencyId = computed(() => {
            return state.generalAgencyOptions.length === 0
        })
        const productProviderId = computed(() => {
            return props.product?.basicProvider?.id ?? props.productDerivedEntity.currentContractInformation.product.basicProvider.id
        })
        const productProviderName = computed(() => {
            return props.product?.basicProvider?.display_name || props.product?.basicProvider?.name
        })
        const consultingMandateEnquirySendDateLabel = computed(() => {
            return form.getItem('managedByStatus').value === ContractManagedByStatus.CONSULTING_MANDATE_CANCELLATION_PENDING ? t('forms.field.label.application.mandate-cancellation-submission-date') : t('forms.field.label.application.mandate-submission-date')
        })

        // Product fields
        const templateFormConfiguration = (props.productDerivedEntity) ? props.productDerivedEntity.currentContractInformation.productTemplate.form_configuration : props.product.template.form_configuration
        composeProductFieldsSection(form, templateFormConfiguration)

        // Initial data
        const initialData = {
            managedByStatus: ContractManagedByStatus.INTERNAL,
        }
        if (props.consultantId) initialData.consultantId = props.consultantId
        if (form instanceof ApplicationForm) {
            initialData.status = ApplicationStatus.PENDING_AT_PRODUCT_PROVIDER
        }

        if (currentMode.value === ViewMode.CREATE) {
            form.setDefaultValues()
        } else if (currentMode.value === ViewMode.UPDATE) {
            const productFields = createProductFieldsNameValuePairs(props.productDerivedEntity)
            Object.assign(initialData, {
                consultantId: props.productDerivedEntity.consultant.id,
                startDate: props.productDerivedEntity.currentContractInformation.start_date || '',
                endDate: props.productDerivedEntity.currentContractInformation.end_date || '',
                premium: props.productDerivedEntity.currentContractInformation.premium || '',
                generalAgencyId: props.productDerivedEntity.currentContractInformation.generalAgency?.id ?? null,
                managedByStatus: props.productDerivedEntity.currentContractInformation.managed_by_status,
                consultingMandateEnquirySendDate: props.productDerivedEntity.currentContractInformation.consulting_mandate_enquiry_send_date || '',
                productFields,
            })

            if (form instanceof ApplicationForm && props.productDerivedEntity instanceof Application) {
                Object.assign(initialData, {
                    externalReferenceNumber: props.productDerivedEntity.external_reference_number,
                    status: props.productDerivedEntity.status,
                    submissionSendDate: props.productDerivedEntity.submission_send_date || '',
                    submissionResponseDate: props.productDerivedEntity.submission_response_date || '',
                    onHoldUntil: props.productDerivedEntity.on_hold_until || '',
                })
            } else if (form instanceof ContractForm) {
                Object.assign(initialData, {
                    contractNumber: props.productDerivedEntity.currentContractNumber,
                })
            }
        }

        // Functions
        function setGeneralAgencyOptions(options) {
            state.generalAgencyOptions = options
        }
        async function saveAndAddFiles (formBuilder) {
            const formData = await formBuilder.processFormSubmit()
            if (formData) {
                context.emit('submit', formData, 'saveAndAddFiles')
            }
        }
        async function saveAndAddAnother (formBuilder) {
            const formData = await formBuilder.processFormSubmit()
            if (formData) {
                context.emit('submit', formData, 'saveAndAddAnother')
            }
        }

        return {
            // Static
            ViewMode,

            // Template refs
            formComponent,

            // Data
            currentMode,
            form,
            initialData,
            isApplicationForm,
            isContractForm,
            scenarioCreateContract,
            scenarioUpdateContract,
            scenarioCreateApplication,
            scenarioUpdateApplication,
            scenarioCreateChangeApplication,

            // Computed
            disableGeneralAgencyId,
            productProviderId,
            productProviderName,
            consultingMandateEnquirySendDateLabel,

            // Functions
            setGeneralAgencyOptions,
            saveAndAddFiles,
            saveAndAddAnother,
        }
    },
}
</script>

<style lang="scss" scoped>
$sizeGutterWidth: $sizeSpacingMd;

::v-deep .basic-information {
    margin-bottom: $sizeSpacingMd;
}

// TODO @MTR: Re-add / finalize print styles
/*::v-deep .basic-information {

    // TODO: Finalize styles
    .inner-wrapper {
        grid-template-columns: 1fr;
        margin: 0;
        padding: $sizeGutterWidth;

        //border: 1px solid var(--q-color-primary-light);
        background-color: var(--q-color-primary-lighter);

        .q-field__control {
            !*border: 1px solid var(--q-color-primary-light);*!
            border: 1px solid var(--color-border-secondary);
            background-color: var(--color-background-primary);
        }

        @media screen and (min-width: $sizeBreakpointFormBuilderMd), print and (min-width: 100mm) {
            grid-template-columns: repeat(4, 1fr);

            .contract-number,
            .premium,
            .consultant {
                grid-column-end: span 2;
            }
        }

        @media screen and (min-width: $sizeBreakpointLg), print and (min-width: 120mm) {
            grid-template-columns: repeat(6, 1fr);
        }

        @media screen and (min-width: $sizeBreakpointXl), print and (min-width: 150mm) {
            grid-template-columns: repeat(12, 1fr);

            .contract-number,
            .consultant {
                grid-column-end: span 3;
            }

            .date,
            .premium {
                grid-column-end: span 2;
            }
        }
    }
}*/
</style>
