<script>
import { getFormItemByKey, createFormBuilders } from '@/helpers/form'
import { i18n } from '@/i18n'
import kebabCase from 'lodash.kebabcase'
import { FormBuilderConfiguration } from '@/form-builder-configuration'

export default {
    name: 'FormBuilder',
    functional: true,
    inheritAttrs: false,
    props: {
        item: {
            type: Object,
        },
        itemKey: {
            type: String,
        },
        modelPath: {
            type: String,
            default: 'formData',
        },
        model: {
            type: Object,
        },
        $v: {
            type: Object,
        },
        filter: {
            type: Object,
        },
    },
    render (h, context) {
        const { props, data, listeners } = context

        if (!props.item && props.itemKey) props.item = getFormItemByKey(context.parent.formConfiguration, props.itemKey)
        if (!props.model) props.model = context.parent.formData
        if (!props.$v) props.$v = context.parent.$v
        let output = h('div', `DEBUG: Item ${props.item.key} not supported yet.`)

        switch (props.item.type) {
            case 'root':
                if (props.item.children && props.item.children.length) {
                    output = createFormBuilders(props.item.children, h, props)
                }
                break

            case 'section': {
                let children = []
                if (props.item.children && props.item.children.length) {
                    children = createFormBuilders(props.item.children, h, props)
                }
                output = h('fieldset', [
                    h('legend', props.item.label),
                    h('div', {
                        class: 'inner-wrapper',
                    }, children),
                ])
                break
            }

            case 'field': {
                if (props.item.inputType === 'date') props.item.inputType = 'calendar' // TODO: check if aliases is a thing; maybe just re-factor calendar to date?
                if (props.item.inputType === 'currency') props.item.inputType = 'text' // TODO: check if aliases is a thing; maybe create currency component?

                const supportedInputTypes = Object.keys(FormBuilderConfiguration.supportedInputTypes)
                if (supportedInputTypes.includes(props.item.inputType)) {
                    const children = []
                    const options = {
                        attrs: {
                            // TODO: Re-think if form field keys really also should get kebab-cased.
                            'data-test': `input:${kebabCase(props.item.key)}`,
                            label: props.item.label,
                            ...data.attrs,
                        },
                        props: {},
                        on: Object.assign({}, listeners),
                        scopedSlots: context.scopedSlots,
                    }

                    if (['text', 'password', 'textarea', 'number'].includes(props.item.inputType)) options.attrs.type = props.item.inputType
                    if (['consultants', 'roles'].includes(props.item.inputType)) options.attrs.multiple = true
                    if (props.item.inputType === 'select') options.attrs.options = props.item.extra_attributes.list.options
                    if (props.item.hint) options.attrs.hint = props.item.hint
                    if (props.item.mask) options.attrs.mask = props.item.mask
                    if (props.item.prefix) options.attrs.prefix = props.item.prefix
                    if (props.item.infoTitle) options.attrs.infoTitle = props.item.infoTitle
                    if (props.item.infoText) options.attrs.infoText = props.item.infoText
                    if (data.class) options.class = data.class
                    if (data.staticClass) options.staticClass = data.staticClass
                    if (props.filter) options.props.filter = props.filter
                    if (props.item.extra_attributes) {
                        if (props.item.extra_attributes.disable) options.attrs.disable = true
                        if (props.item.extra_attributes.injectConsultants) options.attrs.injectConsultants = props.item.extra_attributes.injectConsultants
                        if (typeof props.item.extra_attributes.multiple !== 'undefined') options.attrs.multiple = props.item.extra_attributes.multiple
                        if (typeof props.item.extra_attributes.optionValue !== 'undefined') options.attrs.optionValue = props.item.extra_attributes.optionValue
                        if (typeof props.item.extra_attributes.displayMode !== 'undefined') options.attrs.displayMode = props.item.extra_attributes.displayMode
                        if (typeof props.item.extra_attributes.smartObjectTreeDisplayMode !== 'undefined') options.attrs.smartObjectTreeDisplayMode = props.item.extra_attributes.smartObjectTreeDisplayMode
                    }

                    // TODO @TFU: Check if this should be possible for all other attributes (generic solution).
                    if (['number'].includes(props.item.inputType) && props.item.extra_attributes) {
                        for (const [key,value] of Object.entries(props.item.extra_attributes)) {
                            options.attrs[key] = value
                        }
                    }

                    // Has validators
                    if (props.item.validators && props.item.validators.length) {
                        // No v-model available in render functions – handle manually
                        options.props.value = props.$v[props.modelPath][props.item.key].$model
                        options.on.input = value => {
                            props.$v[props.modelPath][props.item.key].$model = value
                        }

                        // Let q-components know if there is an error
                        options.attrs.error = props.$v[props.modelPath][props.item.key].$error

                        // Error messages
                        const errorMessages = []
                        props.item.validators.forEach(validator => {
                            if (['required', 'requiredProperty'].includes(validator.type)) options.class = 'required'
                            if (props.$v[props.modelPath][props.item.key][validator.type] === false) { // Determines if the errorMessage should be rendered
                                let errorMessageText = ''
                                if (validator.errorMessage) {
                                    errorMessageText = validator.errorMessage
                                } else {
                                    switch (validator.type) {
                                        case 'required':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.required', { field: props.item.label }) // TODO: How to handle pluralization?
                                            break
                                        case 'requiredIf':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.required-if', { field: props.item.label, target: i18n.tc(validator.params.targetLabel) }) // TODO: How to handle pluralization?
                                            break
                                        case 'requiredUnless':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.required-unless', { field: props.item.label, target: i18n.tc(validator.params.targetLabel) }) // TODO: How to handle pluralization?
                                            break
                                        case 'requiredProperty':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.required', { field: props.item.label }) // TODO: How to handle pluralization?
                                            break
                                        case 'email':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.type-email', { field: props.item.label }) // TODO: How to handle pluralization?
                                            break
                                        case 'decimal':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.type-decimal', { field: props.item.label }) // TODO: How to handle pluralization?
                                            break
                                        case 'maxLength':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.max-length', { field: props.item.label, n: validator.params.max }) // TODO: How to handle pluralization?
                                            break
                                        case 'maxValue':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.max-value', { field: props.item.label, n: validator.params.max }) // TODO: How to handle pluralization?
                                            break
                                        case 'minLength':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.min-length', { field: props.item.label, n: validator.params.minErrorMessage || validator.params.min }) // TODO: How to handle pluralization?
                                            break
                                        case 'minValue':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.min-value', { field: props.item.label, n: validator.params.min }) // TODO: How to handle pluralization?
                                            break
                                        case 'minRequirementsLowercase':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.min-requirements-lowercase', { field: props.item.label }) // TODO: How to handle pluralization?
                                            break
                                        case 'minRequirementsUppercase':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.min-requirements-uppercase', { field: props.item.label }) // TODO: How to handle pluralization?
                                            break
                                        case 'minRequirementsSpecialCharOrNumber':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.min-requirements-special-char-number', { field: props.item.label }) // TODO: How to handle pluralization?
                                            break
                                        case 'sameAs':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.same-as', { field: props.item.label, equalTo: validator.params.equalToLabel }) // TODO: How to handle pluralization?
                                            break
                                        case 'notSameAs':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.not-same-as', { field: props.item.label, target: i18n.tc(validator.params.targetLabel) }) // TODO: How to handle pluralization? TODO translation
                                            break
                                        case 'date':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.type-date', { field: props.item.label }) // TODO: How to handle pluralization?
                                            break
                                        case 'isTrue':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.is-true', { field: props.item.label }) // TODO: How to handle pluralization?
                                            break
                                        case 'url':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.type-url', { field: props.item.label }) // TODO: How to handle pluralization?
                                            break
                                        case 'notEqualTo':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.not-equal-to', { field: props.item.label }) // TODO: How to handle pluralization?
                                            break
                                        case 'isBeforeOrEqualToDate':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.is-before-or-equal-to-date', { field: props.item.label, target: i18n.tc(validator.params.targetLabel) }) // TODO: How to handle pluralization?
                                            break
                                        case 'isAfterOrEqualToDate':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.is-after-or-equal-to-date', { field: props.item.label, target: i18n.tc(validator.params.targetLabel) }) // TODO: How to handle pluralization?
                                            break
                                        case 'lessThan':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.less-than', { field: props.item.label, target: i18n.tc(validator.params.targetLabel) }) // TODO: How to handle pluralization?
                                            break
                                        case 'moreThan':
                                            errorMessageText = i18n.t(validator.errorMessageTranslationKey || 'common.error-message.more-than', { field: props.item.label, target: i18n.tc(validator.params.targetLabel) }) // TODO: How to handle pluralization?
                                            break
                                    }
                                }
                                errorMessages.push(h('div', {
                                    attrs: {
                                        // TODO: Re-think if form field keys really also should get kebab-cased.
                                        'data-test': `error:${kebabCase(props.item.key)}-${kebabCase(validator.type)}`,
                                    },
                                    key: validator.type,
                                    class: 'error-message',
                                }, errorMessageText))
                            }
                        })

                        children.push(h('template', { slot: 'error' }, errorMessages))
                    } else {
                        // No v-model available in render functions – handle manually
                        options.props.value = props.model[props.item.key]
                        options.on.input = value => {
                            props.model[props.item.key] = value
                        }
                    }

                    output = h(FormBuilderConfiguration.supportedInputTypes[props.item.inputType].component, options, children)
                }
                break
            }
        }

        return output
    },
}
</script>
