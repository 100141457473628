import gql from 'graphql-tag'

export const CORRESPONDENCE_LETTER_CREATE_MUTATION = gql`
    mutation correspondenceLetterCreate(
        $targetObjectType: CorrespondenceItemTargetObjectType!
        $correspondenceLetter: CorrespondenceLetterInput!
    ) {
        correspondenceLetterCreate(
            target_object_type: $targetObjectType
            correspondenceLetter: $correspondenceLetter
        ) {
            id
            creator {
                id
                first_name
                last_name
            }
            status
            name
            target_object_type
            target_object_ids
            targetContacts {
                id
            }
            targetApplications {
                id
            }
            targetContracts {
                id
            }
            address_overrides
            sender_overrides
            language {
                id
                name
            }
            confidentiality_notice
            attention_ofs
            differentiate_formality
            contents {
                id
                formality
                content {
                    schemaType
                    schemaVersion
                    contentJSON
                    contentHTML
                    attrs
                }
            }
            printPDF {
                id
            }
            created_at
            updated_at
        }
    }
`
