import { Extension } from '@tiptap/core'

export const StructureBlockTabbing = Extension.create({
    name: 'structureBlockTabbing',

    addKeyboardShortcuts() {
        return {
            'Tab': () => {
                if (!this.editor.can().sinkListItem('listItem')) {
                    const cursorPositionAtBeginningOfNextStructureBlock = this.editor.state.selection.$anchor.after(1) + 1
                    if (cursorPositionAtBeginningOfNextStructureBlock < this.editor.state.doc.content.size) {
                        const cursorPositionAtEndOfNextStructureBlock = this.editor.state.doc.resolve(cursorPositionAtBeginningOfNextStructureBlock).end(1)
                        if (cursorPositionAtEndOfNextStructureBlock < this.editor.state.doc.content.size) {
                            this.editor.chain().setTextSelection(cursorPositionAtEndOfNextStructureBlock).scrollIntoView().run()
                            return true
                        }
                    }
                }
                return false
            },
            'Shift-Tab': () => {
                if (!this.editor.can().liftListItem('listItem')) {
                    const cursorPositionAtEndOfPreviousStructureBlock = this.editor.state.selection.$anchor.before(1) - 1
                    if (cursorPositionAtEndOfPreviousStructureBlock > 0) {
                        this.editor.chain().setTextSelection(cursorPositionAtEndOfPreviousStructureBlock).scrollIntoView().run()
                        return true
                    }
                }
                return false
            },
        }
    },
})
