import gql from 'graphql-tag'

export const PRODUCT_TEMPLATE_DELETE_MUTATION = gql`
    mutation productTemplateDelete(
        $id: ID!
    ) {
        productTemplateDelete(
            id: $id
        ) {
            status
        }
    }
`
