<template>
    <page-loading-indicator
        v-if="state.isLoading"
        :number-of-breadcrumb-elements="6"
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="state.contract.customer.getContactName()" :to="{ name: 'contact-detail', params: { id: contactId } }" data-test="breadcrumb:contact-detail" />
                <q-breadcrumbs-el :label="$tc('common.term.contract', 2)" :to="{ name: 'contact-detail', params: { id: contactId }}" data-test="breadcrumb:contracts" />
                <q-breadcrumbs-el :label="state.contract.currentContractNumber" :to="{ name: 'contract-detail', params: { id: state.contract.id }}" data-test="breadcrumb:contract-detail" />
                <q-breadcrumbs-el :label="$tc('common.premium-invoice.premium-invoice', 2)" :to="{ name: 'contract-detail', params: { id: state.contract.id, tab: 'premium-invoices' }}" data-test="breadcrumb:premium-invoices" />
                <q-breadcrumbs-el :label="state.premiumInvoice.formattedIdentifier" :to="{ name: 'premium-invoice-detail', params: { id: state.contract.id }}" data-test="breadcrumb:premium-invoice-detail" />
                <q-breadcrumbs-el :label="$tc('common.premium-invoice.update-premium-invoice', 1)" :to="{ name: 'premium-invoice-update', params: { id: state.contract.id }}" data-test="breadcrumb:premium-invoice-update" />
            </template>

            <h1>{{ $tc('common.premium-invoice.update-premium-invoice', 1) }}</h1>
        </page-header>

        <premium-invoice-form
            v-if="state.premiumInvoice"
            ref="premiumInvoiceForm"
            :premium-invoice="state.premiumInvoice"
            :target-object="state.contract"
            @submit="onSubmit"
            @cancel="onCancel"
        />
    </page-wrapper>
</template>

<script>

import PremiumInvoiceForm from '@/components/contract/PremiumInvoiceForm'
import { onBeforeMount, reactive, ref } from 'vue'
import { PremiumInvoice } from '@/models/premiumInvoice'
import { useRouter } from '@/composables/router'
import { useI18n } from '@/composables/i18n'
import { useQuasar } from '@/composables/quasar'
import { Contract } from '@/models/contract'

export default {
    name: 'PremiumInvoiceUpdate',
    meta () {
        return {
            title: this.$tc('common.premium-invoice.update-premium-invoice', 1),
        }
    },
    components: {
        PremiumInvoiceForm,
    },
    props: {
        contactId: {
            type: String,
            required: true,
        },
        contractId: {
            type: String,
            required: true,
        },
        premiumInvoiceId: {
            type: String,
            required: true,
        },
    },
    setup (props) {
        // Composables
        const router = useRouter()
        const $q = useQuasar()
        const { t, tc } = useI18n()

        // Data
        const state = reactive({
            isLoading: true,
            premiumInvoice: null,
        })

        // Template refs
        const premiumInvoiceForm = ref(null)

        // Functions
        async function fetchObjects () {
            state.contract = await Contract.objects.get(props.contractId)
            state.premiumInvoice = await PremiumInvoice.objects.get(props.premiumInvoiceId)
            state.isLoading = false
        }
        function onSubmit (formData) {
            state.premiumInvoice.update(formData)
                .then(() => {
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.premium-invoice.premium-invoice-updated-success', 1),
                    })
                    router.replace({ name: 'premium-invoice-detail', params: { id: state.contract.id } })
                })
                .catch(error => {
                    $q.notify({
                        type: 'negative',
                        title: t('common.term.error'),
                        message: tc('common.notifications.premium-invoice.premium-invoice-updated-error', 1),
                    })
                    premiumInvoiceForm.value.formComponent.formBuilder.showFormErrorMessage(error)
                    premiumInvoiceForm.value.formComponent.formBuilder.resetFormSubmitStatus()
                })
        }
        function onCancel () {
            router.go(-1)
        }

        // Lifecycle hooks
        onBeforeMount(() => {
            fetchObjects()
        })

        return {
            // Data
            state,

            // Template refs
            premiumInvoiceForm,

            // Functions
            onSubmit,
            onCancel,
        }
    },
}
</script>
