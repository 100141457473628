<template>
    <page-loading-indicator v-if="!correspondenceEmail" :number-of-breadcrumb-elements="3" subtitle />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.correspondence.email.email', 2)" :to="{ name: 'correspondence-emails' }" />
                <q-breadcrumbs-el :label="$tc('common.correspondence.email.write-email', 1)" :to="{ name: 'correspondence-email-update', params: { id: correspondenceEmail.id } }" />
                <q-breadcrumbs-el :label="$tc('common.term.preview', 1)" :to="{ name: 'correspondence-email-summary', params: { id: correspondenceEmail.id } }" />
            </template>

            <h1>{{ $tc('common.correspondence.email.email-preview', 1) }} <span v-if="previewContent" class="subtitle">{{ correspondenceEmail.name }}</span></h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12 col-lg-10 col-xl-8">
                <info-box v-if="attachmentsUploadInProgress" type="warning">{{ $tc('views.correspondence.email.preview.email-attachments--upload-in-progress', queuedAttachments.length) }}</info-box>

                <info-box>
                    <i18n path="common.correspondence.email.email-preview--text" tag="p">
                        <template v-slot:numberOfRecipients>
                            <b>{{ $tc('common.correspondence.email.email-preview--text--part--number-of-recipients', correspondenceEmail.target_object_ids.length) }}</b>
                        </template>
                        <template v-slot:numberOfAttachments>
                            <b>{{ $tc('common.correspondence.email.email-preview--text--part--number-of-attachments--total-file-size', correspondenceEmail.attachments.length, { 'totalFileSize': humanStorageSize(attachmentsTotalSize) }) }}</b>
                        </template>
                        <template v-slot:correspondenceLanguage>{{ correspondenceEmail.language.name }}</template>
                        <template v-slot:differentiateFormality>{{ $t(`common.correspondence.email.email-preview--text--part--differentiate-formality--${correspondenceEmail.differentiate_formality.toString()}`) }}</template>
                    </i18n>
                </info-box>

                <q-card flat bordered>
                    <q-item class="q-pt-md q-pb-md">
                        <q-item-section avatar>
                            <q-avatar
                                class="sender-avatar"
                                color="background-secondary"
                                size="3.5em"
                                :style="`color: ${stringToColor(correspondenceEmail.targetObjects[0].sender.getContactName())};`"
                            >
                                {{ getContactInitials(correspondenceEmail.targetObjects[0].sender, { maxLength: 2 }) }}
                            </q-avatar>
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>
                                <span class="additional-info">{{ $t('common.term.to') }}:</span> {{ correspondenceEmail.targetObjects[0].recipient.getContactName({ salutation: false, title: true }) }} <span class="additional-info">&lt;{{ correspondenceEmail.targetObjects[0].recipient_email_address.email }}&gt;</span>
                            </q-item-label>
                            <q-item-label>
                                <span class="additional-info">{{ $t('common.term.from') }}:</span> {{ correspondenceEmail.targetObjects[0].sender.getContactName() }}
                            </q-item-label>
                            <q-item-label>
                                <span class="additional-info">{{ $tc('common.correspondence.email.subject', 1) }}:</span> <span>{{ previewContent ? previewContent.subject : `${$t('common.term.loading')} …` }}</span>
                            </q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-separator />

                    <div class="row q-col-gutter-sm q-pa-lg">
                        <div class="col-xs-12">
                            <div v-if="previewContent" v-html="previewContent.content" />
                            <loading-indicator v-else auto-height />
                        </div>
                    </div>

                    <q-separator />

                    <div class="row q-col-gutter-sm q-pl-sm">
                        <div :class="['col-xs-12 q-pt-md q-pr-lg q-pl-lg bg-background-secondary', { 'q-pb-lg': correspondenceEmail.attachments.length }]">
                            <p :class="{ 'additional-info': !correspondenceEmail.attachments.length }">{{ $tc('common.attachment.number-of-attachments', correspondenceEmail.attachments.length) }} <template v-if="correspondenceEmail.attachments.length"><span class="additional-info">({{ humanStorageSize(attachmentsTotalSize) }})</span>:</template></p>

                            <!-- TODO improvement @MTR: Create file library component. -->
                            <!-- TODO @MTR with @TFU: Check why getFileTypeIcon() is called twice? -->
                            <div v-if="correspondenceEmail.attachments.length" class="file-library-wrapper">
                                <q-card
                                    v-for="attachment in correspondenceEmail.attachments"
                                    :key="attachment.id"
                                    class="file-library-item"
                                    square
                                    flat
                                    bordered
                                >
                                    <q-card-section horizontal>
                                        <q-item class="items-start">
                                            <q-item-section side>
                                                <q-avatar square>
                                                    <q-icon
                                                        v-if="attachment.mime_type"
                                                        :name="getFileTypeIcon(attachment.mime_type)"
                                                        size="sm"
                                                        color="secondary"
                                                    />
                                                </q-avatar>
                                            </q-item-section>

                                            <q-item-section>
                                                <q-item-label>{{ attachment.name }}</q-item-label>
                                                <q-item-label v-if="attachment.size" caption>{{ humanStorageSize(attachment.size) }}</q-item-label>
                                            </q-item-section>
                                        </q-item>

                                        <q-card-actions vertical>
                                            <q-btn
                                                color="primary"
                                                size="sm"
                                                flat
                                                round
                                                icon="mib-cloud-download"
                                                class="do-not-print"
                                                @click="attachment.download()"
                                            >
                                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.term.download-item', 1, { item: attachment.name }) }}</q-tooltip>
                                            </q-btn>
                                        </q-card-actions>
                                    </q-card-section>
                                </q-card>
                            </div>
                        </div>
                    </div>
                </q-card>
            </div>
        </div>

        <in-page-footer>
            <!-- Start processing -->
            <form-dialog
                ref="correspondenceEmailStartProcessingDialog"
                :double-confirm="correspondenceEmail.target_object_ids.length > 1"
                dont-close-on-confirm
                @confirm="handleCorrespondenceEmailStartProcessing"
            >
                <template v-slot:title>{{ $tc('common.correspondence.email.send-email', correspondenceEmail.target_object_ids.length) }}</template>
                <template v-slot:default>
                    <ul>
                        <li>{{ $tc('common.correspondence.email.send-email--confirm--description--sent-to-recipient', correspondenceEmail.target_object_ids.length) }}</li>
                        <li v-if="correspondenceEmail.attachments.length">{{ $tc(`common.correspondence.email.send-email--confirm--description--stored-as-correspondence--${correspondenceEmail.target_object_type.toLowerCase()}--incl-attachments`, correspondenceEmail.target_object_ids.length) }}</li>
                        <li v-else>{{ $tc(`common.correspondence.email.send-email--confirm--description--stored-as-correspondence--${correspondenceEmail.target_object_type.toLowerCase()}`, correspondenceEmail.target_object_ids.length) }}</li>
                    </ul>
                    <info-box type="warning" no-margin>{{ $t('common.correspondence.email.send-email--confirm--hint-email-communication') }}</info-box>
                </template>
            </form-dialog>

            <base-button
                data-test="btn:back"
                :label="$t('common.term.back')"
                flat
                primary-button
                :to="{ name: 'correspondence-email-update', params: { id: correspondenceEmail.id } }"
            />
            <base-button
                :label="$tc('common.correspondence.email.send-email', correspondenceEmail.target_object_ids.length)"
                :disable="attachmentsUploadInProgress"
                primary-button
                @click="triggerStartProcessing"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import { FileUploadWrapperStatus, FileUploadTargetObjectType } from '@/enums'
import { getFileTypeIcon, humanStorageSize } from '@/helpers/file'
import { getContactInitials } from '@/helpers/contact'
import { stringToColor } from '@/helpers'
import { CorrespondenceEmail } from '@/models/correspondenceEmail'
import FormDialog from '@/components/form/FormDialog'

export default {
    name: 'CorrespondenceEmailPreview',
    meta () {
        return {
            title: this.$tc('common.correspondence.email.email-preview', 1),
        }
    },
    components: {
        FormDialog,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            correspondenceEmail: null,
            previewContent: null,
            queuedAttachments: [],
        }
    },
    computed: {
        attachmentsTotalSize () {
            return (this.correspondenceEmail) ? this.correspondenceEmail.attachments.reduce((total, file) => (total += file.size), 0) : 0
        },
        attachmentsUploadInProgress () {
            if (this.queuedAttachments.length) {
                return this.queuedAttachments.some(fileUploadWrapper => {
                    return [FileUploadWrapperStatus.QUEUED, FileUploadWrapperStatus.UPLOADING].includes(fileUploadWrapper.status)
                })
            } else {
                return false
            }
        },
    },
    watch: {
        'attachmentsUploadInProgress' (inProgress) {
            if (this.correspondenceEmail && !inProgress) this.correspondenceEmail.reloadAttachments() // TODO @TFU: Check/verify condition in detail (if this.correspondenceEmail part)
        },
    },
    created () {
        // TODO improvement @TFU: Redirect if item with given ID should not have this view. (Due to its status.)
        CorrespondenceEmail.objects.get(this.id).then(correspondenceEmail => {
            this.correspondenceEmail = correspondenceEmail
            this.correspondenceEmail.preview(this.correspondenceEmail.target_object_ids[0]).then(previewContent => {
                this.previewContent = previewContent
            })
        })
    },
    mounted () {
        EventBus.$emit('fileUploadManager:api', {
            action: 'getQueueItems',
            callbackFn: queuedAttachments => { this.queuedAttachments = queuedAttachments },
            params: {
                targetObjectId: this.id,
                targetObjectType: FileUploadTargetObjectType.CORRESPONDENCE_EMAIL_ATTACHMENT,
            },
        })
    },
    methods: {
        getFileTypeIcon (...args) {
            return getFileTypeIcon(...args)
        },
        humanStorageSize (...args) {
            return humanStorageSize(...args)
        },
        triggerStartProcessing () {
            this.$refs.correspondenceEmailStartProcessingDialog.open()
        },
        handleCorrespondenceEmailStartProcessing () {
            this.correspondenceEmail.startProcessing()
                .then(() => {
                    this.$refs.correspondenceEmailStartProcessingDialog.close()
                    this.$router.replace({ name: 'correspondence-email-list', params: { statusGroup: 'pending' } })
                })
                .catch(error => {
                    this.$refs.correspondenceEmailStartProcessingDialog.showFormErrorMessage(error)
                    this.$refs.correspondenceEmailStartProcessingDialog.resetFormSubmitStatus()
                })
        },
        getContactInitials (...args) {
            return getContactInitials(...args)
        },
        stringToColor (...args) {
            return stringToColor(...args)
        },
    },
}
</script>

<style lang="scss" scoped>
.file-library-wrapper {
    --file-library-items-per-line: 1;
    --column-gap: #{$sizeSpacingSm};
    --row-gap: #{$sizeSpacingSm};

    @media screen and (min-width: $sizeBreakpointSm), print {
        --file-library-items-per-line: 2;
    }

    @media screen and (min-width: $sizeBreakpointFormBuilderMd), print {
        --column-gap: #{$sizeSpacingMd};
        --row-gap: #{$sizeSpacingMd};
    }

    @media screen and (min-width: $sizeBreakpointFormBuilderLg), print {
        --file-library-items-per-line: 3;
    }

    @media screen and (min-width: $sizeBreakpointXl), print and (min-width: 150mm) {
        --file-library-items-per-line: 4;
    }

    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(var(--file-library-items-per-line), 1fr);
    column-gap: var(--column-gap);
    row-gap: var(--row-gap);
}

.file-library-item.file-library-item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    transition: all 1s $defaultTransitionTimingFunction 0s;

    &.delete {
        border-style: dashed;
        border-color: var(--q-color-negative-light);
        background-color: var(--q-color-negative-lighter);
    }

    & > .q-card__section {
        width: 100%;
        justify-content: space-between;
    }
}
</style>
