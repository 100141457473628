import { lightFormat, parseISO } from 'date-fns'
import { de, enUS } from 'date-fns/locale'

export function formatDate (
    isoDateString,
    { noLeadingZero = false } = { noLeadingZero: false }
) {
    const dateStringFormat = (noLeadingZero) ? 'd.M.yyyy' : 'dd.MM.yyyy'
    return (isoDateString) ? lightFormat(parseISO(isoDateString), dateStringFormat) : isoDateString
}

export function formatMonthDay (
    isoDateString,
    { noLeadingZero = false } = { noLeadingZero: false }
) {
    const dateStringFormat = (noLeadingZero) ? 'd.M.' : 'dd.MM.'
    return (isoDateString) ? lightFormat(parseISO(isoDateString), dateStringFormat) : isoDateString
}

export function isoDateToMonthDay (isoDateString) {
    return isoDateString.replace(/^\d{4}-/, '--')
}

export function monthDayToIsoDate (monthDayString, placeholderYear = '0004') {
    return monthDayString.replace('--', `${placeholderYear}-`)
}

export function formatDateTime (
    isoDateTimeString,
    {
        noLeadingZeroDate = false,
        noLeadingZeroTime = false,
    } = {
        noLeadingZeroDate: false,
        noLeadingZeroTime: false,
    }
) {
    const dateStringFormat = (noLeadingZeroDate) ? 'd.M.yyyy' : 'dd.MM.yyyy'
    const timeStringFormat = (noLeadingZeroTime) ? 'H:mm' : 'HH:mm'
    return (isoDateTimeString) ? lightFormat(parseISO(isoDateTimeString), `${dateStringFormat} – ${timeStringFormat}`) : isoDateTimeString
}

export function formatTime (
    isoDateTimeString,
    { noLeadingZero = false } = { noLeadingZero: false }
) {
    const timeStringFormat = (noLeadingZero) ? 'H:mm' : 'HH:mm'
    return (isoDateTimeString) ? lightFormat(parseISO(isoDateTimeString), timeStringFormat) : isoDateTimeString
}

export function getDateFnsLocale (locale) {
    const locales = { de, enUS }
    switch (locale) {
        case 'de-CH':
            return locales['de']
        case 'en':
            return locales['enUS']
    }
}

export function getCurrentDateFnsLocale () {
    return getDateFnsLocale(localStorage.getItem('locale'))
}

export function guessCentury (
    year,
    {
        twentiethCenturyWeightFactor = 1,
        twentyFirstCenturyWeightFactor = 1,
    } = {
        twentiethCenturyWeightFactor: 1,
        twentyFirstCenturyWeightFactor: 1,
    }
) {
    if (year < 100) {
        const twentiethCentury = 1900 + year
        const twentyFirstCentury = 2000 + year
        const currentYear = new Date().getFullYear()
        const twentiethCenturyDiff = Math.abs(twentiethCentury - currentYear)
        const twentyFirstCenturyDiff = Math.abs(twentyFirstCentury - currentYear)
        return (twentiethCenturyDiff / twentiethCenturyWeightFactor < twentyFirstCenturyDiff / twentyFirstCenturyWeightFactor) ? twentiethCentury : twentyFirstCentury
    } else {
        return 2000 + year
    }
}
