import gql from 'graphql-tag'

export const CONTACT_RELATIONSHIP_DEFINITIONS_QUERY = gql`
    query contactRelationshipDefinitions(
        $page: Int = 1
        $count: Int = 200
        $search: ContactRelationshipDefinitionInputSearch,
    ) {
        contactRelationshipDefinitions(
            page: $page
            count: $count
            search: $search,
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                type
                categories
                node1_type
                node1_valid_contact_types
                node2_type
                node2_valid_contact_types
                created_at
                updated_at
            }
        }
    }
`
