<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.accounting.account.account', 2)" :to="{ name: 'accounting-account-list' }" />
                <q-breadcrumbs-el :label="$tc('views.accounting.account.add-account', 1)" :to="{ name: 'accounting-account-create' }" />
            </template>

            <h1>{{ $tc('views.accounting.account.add-account', 1) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12 col-lg-10 col-xl-8">
                <account-form ref="accountForm" @cancel="onCancel" @submit="onSubmit" />
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { Account } from '@/models/account'
import AccountForm from '@/components/accounting/AccountForm.vue'
import { useRouter } from '@/composables/router'
import { ref } from 'vue'
import { useQuasar } from '@/composables/quasar'
import { useI18n } from '@/composables/i18n'

export default {
    name: 'AccountCreate',
    meta () {
        return {
            title: this.$tc('views.accounting.account.add-account', 1),
        }
    },
    components: {
        AccountForm,
    },
    setup () {
        const $q = useQuasar()
        const router = useRouter()
        const { t, tc } = useI18n()
        const accountForm = ref(null)

        function onCancel () {
            router.push({ name: 'accounting-account-list' })
        }
        function onSubmit (formData) {
            const account = { ...formData, number: parseInt(formData.number) }
            Account.create(account)
                .then(() => {
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.accounting.account-created-success', 1),
                    })
                    router.replace({ name: 'accounting-account-list' })
                })
                .catch(error => {
                    $q.notify({
                        type: 'negative',
                        title: t('common.term.error'),
                        message: tc('common.notifications.accounting.account-created-error', 1),
                    })
                    accountForm.value.formComponent.formBuilder.showFormErrorMessage(error)
                    accountForm.value.formComponent.formBuilder.resetFormSubmitStatus()
                })
        }

        return {
            accountForm,
            onCancel,
            onSubmit,
        }
    },
}
</script>
