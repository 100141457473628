<template>
    <div class="row q-col-gutter-y-sm">
        <slot name="error" />

        <div class="col-xs-12 do-not-print">
            <file-upload-drop-zone
                :multiple="multiple"
                :drop-zone-small="dropZoneSmall"
                :file-type="fileType || null"
                @drop="addFiles"
            />
        </div>
        <div class="col-xs-12 do-not-print">
            <input
                ref="selectFilesInput"
                type="file"
                :multiple="multiple"
                style="display: none"
                @change="onChange"
            >
            <base-button
                :label="$tc('common.file.choose-file', multiple ? 2 : 1)"
                icon="mib-folder-search-alternate"
                :primary-button="primaryButton"
                @click="openFileDialog"
            />
        </div>

        <div v-if="value.length" class="col-xs-12">
            <!--
            TODO improvement: Only show this message if the form does not have any errors and the files could really be uploaded.
            (F.e. don't show the message if multiple="false" and value.length > 1.)
            Please note: Error handling/messages are not handled here but in the forms where the upload is done.
            -->
            <!-- TODO improvement/idea (related to the one above): Maybe we could wrap this info-box in a slot and show the error message here if the box should not be shown. -->
            <info-box v-if="value.length" type="warning">{{ $tc('common.file.not-uploaded-yet-info', value.length) }}</info-box>
            <ul>
                <li v-for="file in value" :key="getKey(file)">
                    {{ file.name }} ({{ humanStorageSize(file.size) }})
                    <base-button
                        data-test="btn:remove-file-from-upload"
                        round
                        color="none"
                        text-color="primary"
                        size="xs"
                        icon="mib-bin"
                        @click="removeFile(file)"
                    >
                        <q-tooltip
                            :delay="1000"
                            anchor="center right"
                            self="center left"
                            :offset="[10, 0]"
                            transition-show="scale"
                            transition-hide="scale"
                        >
                            {{ $tc('common.file.remove-file', 1) }}
                        </q-tooltip>
                    </base-button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { filterFiles, humanStorageSize } from '@/helpers/file'
import FileUploadDropZone from '@/components/form/FileUploadDropZone.vue'

export default {
    name: 'FileUpload',
    components: {
        FileUploadDropZone,
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        primaryButton: {
            type: Boolean,
            default: false,
        },
        fileType: {
            type: String,
            default: '',
        },
        // TODO improvement @MTR: Get rid of drop-zone-small (or fix/enhance behavior)
        dropZoneSmall: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            values: [],
        }
    },
    methods: {
        openFileDialog () {
            this.$refs.selectFilesInput.click()
        },
        onChange (event) {
            const files = Array.from(event.target.files)
            const filteredFiles = filterFiles(files)
            if (filteredFiles.length) {
                this.addFiles(filteredFiles)
            }
        },
        getKey (file) {
            return `${file.name}${file.size}${file.lastModified}`
        },
        addFiles (files) {
            // TODO @TFU: Validate (and possibly strip) that input only 1 file is emitted if multiple === false. Specially with dropzone as it is not possible to limit with an attribute as with input[type=file].
            // TODO @TFU: reject all files if more than 1 and multiple === true. What about the validation?
            this.$emit('input', files) // TODO @TFU: How should this behave? Currently this overwrites existing selected files if the user selects new ones. Replace or add new selection?
            this.$refs.selectFilesInput.value = ''
        },
        removeFile (file) {
            const values = Array.from(this.value)
            const index = values.indexOf(file)
            if (index > -1) {
                values.splice(index, 1)
                this.$emit('input', values)
            }
        },
        clearFiles () {
            this.$emit('input', [])
        },
        humanStorageSize (...args) {
            return humanStorageSize(...args)
        },
    },
}
</script>

<!-- TODO @MTR -->
<style lang="scss" scoped>
// Error messages
.error-message {
    width: 100%;
    padding: $sizeSpacingSm $sizeSpacingSm $sizeSpacingXs;

    border-bottom: 2px solid var(--q-color-negative);

    font-size: 11px;
    color: var(--q-color-negative);
}
</style>
