export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'typeId',
            label: 'forms.field.label.posting.type-name',
            inputType: 'postingType',
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'date',
            label: 'forms.field.label.posting.date',
            inputType: 'calendar',
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'text',
            label: 'forms.field.label.posting.text',
            inputType: 'text',
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'debitAccountId',
            label: 'forms.field.label.posting.debit-account',
            inputType: 'account',
            validators: [
                { type: 'required' },
                {
                    type: 'notSameAs',
                    params: {
                        target: 'creditAccountId',
                        targetLabel: 'common.accounting.posting.credit-account',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'creditAccountId',
            label: 'forms.field.label.posting.credit-account',
            inputType: 'account',
            validators: [
                { type: 'required' },
                {
                    type: 'notSameAs',
                    params: {
                        target: 'debitAccountId',
                        targetLabel: 'common.accounting.posting.debit-account',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'amount',
            label: 'forms.field.label.posting.amount',
            inputType: 'currency',
            prefix: 'CHF',
            validators: [
                { type: 'required' },
                { type: 'decimal' },
                {
                    type: 'minValue',
                    params: {
                        'min': 0.01,
                    },
                },
            ],
        },
    ],
}
