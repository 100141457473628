import gql from 'graphql-tag'

export const STATEMENT_QUERY = gql`
    query statement(
        $id: ID!
    ) {
        statement(
            id: $id
        ) {
            id
            consultingEntity {
                ... on Consultant {
                    id
                    contact_number
                    first_name
                    last_name
                    gender
                    consulting_company_name
                }
                ... on ConsultingCompany {
                    id
                    contact_number
                    company_name
                }
            }
            recipients {
                consultants {
                    id
                    contact_number
                    first_name
                    last_name
                    gender
                    consultingCompany {
                        id
                    }
                    consulting_company_name
                }
            }
            date_from
            date_until
            file_types
            include_attachments
            trigger_file_storage_item_sharing
            file_storage_item_shared_at
            currentQueueItem {
                id
                status
                status_message
            }
            queueItems {
                id
                status
                status_message
            }
            fileStorageItem {
                id
                type
                files {
                    id
                    name
                    size
                    mime_type
                }
                acknowledged_at
            }
            created_at
            updated_at
        }
    }
`
