<template>
    <page-loading-indicator
        v-if="!commissionListEntry"
        :number-of-breadcrumb-elements="2"
        actions
        :number-of-next-actions="1"
        subtitle
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.commission.manual-entry.manual-commission-entry', 2)" :to="{ name: 'commission-list-manual-entries' }" />
                <q-breadcrumbs-el :label="$tc('views.commission.manual-entry.edit-commission-entry', 1)" :to="{ name: 'commission-list-manual-entry-update', params: { id: id } }" />
            </template>

            <h1>{{ $tc('views.commission.manual-entry.edit-commission-entry', 1) }}</h1>

            <template v-slot:actions>
                <actions :actions="commissionListEntry.actions" @click="handleActions" />

                <form-dialog
                    ref="deleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleDelete"
                >
                    <template v-slot:title>{{ $tc('common.actions.commission-list-entry.delete', 1) }}</template>
                    <template v-slot:default>
                        <p>{{ $tc('views.commission.manual-entry.delete-entry--confirm', 1) }}</p>
                        <ul>
                            <li v-if="commissionListEntry.matchedContract">{{ commissionListEntry.matchedContract.currentContractNumber }} / {{ commissionListEntry.matchedContract.customer.getContactName() }}</li>
                            <li v-if="commissionListEntry.totalPosition.premium_from || commissionListEntry.totalPosition.premium_to">
                                <span v-if="commissionListEntry.totalPosition.premium_from">{{ commissionListEntry.totalPosition.formattedPremiumFrom }}</span>
                                <span v-if="commissionListEntry.totalPosition.premium_from && commissionListEntry.totalPosition.premium_to"> - </span>
                                <span v-if="commissionListEntry.totalPosition.premium_to">{{ commissionListEntry.totalPosition.formattedPremiumTo }}</span>
                            </li>
                            <li>{{ $t(`common.commission.commission-type.${toKebabCase(commissionListEntry.totalPosition.commission_type)}`) }}</li>
                            <li>{{ commissionListEntry.totalPosition.formattedCommissionAmount }}</li>
                        </ul>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <div class="row q-col-gutter-sm">
            <div class="col-xs-12 col-lg-10 col-xl-8">
                <commission-list-manual-entry-form
                    ref="commissionListManualEntryForm"
                    :commission-list-entry="commissionListEntry"
                    @submit="handleUpdate"
                />
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { kebabCase } from 'lodash'
import { CommissionListEntry } from '@/models/commissionListEntry'

import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'
import CommissionListManualEntryForm from '@/components/commission/commissionListManualEntry/CommissionListManualEntryForm'

export default {
    name: 'CommissionListManualEntryUpdate',
    meta () {
        return {
            title: this.$tc('views.commission.manual-entry.edit-commission-entry', 1),
        }
    },
    components: {
        CommissionListManualEntryForm,
        Actions,
        FormDialog,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            commissionListEntry: null,
        }
    },
    created () {
        CommissionListEntry.objects.get(this.id)
            .then(commissionListEntry => {
                this.commissionListEntry = commissionListEntry
            })
            .catch(error => {
                this.$router.replace({ name: '404' })
            })
    },
    methods: {
        handleUpdate (formData) {
            this.commissionListEntry.update(this.parseFormData(formData))
                .then(() => {
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.commission.manual-commission-entry-updated-success', 1),
                    })
                    this.$router.replace({ name: 'commission-list-manual-entries' })
                }).catch(error => {
                    this.$refs.commissionListManualEntryForm.showFormErrorMessage(error)
                    this.$refs.commissionListManualEntryForm.resetFormSubmitStatus()
                })
        },
        handleDelete () {
            this.commissionListEntry.delete()
                .then(() => {
                    this.$refs.deleteDialog.close()

                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.commission.manual-commission-entry-deleted-success', 1),
                    })
                    this.$router.replace({ name: 'commission-list-manual-entries' })
                })
                .catch(error => {
                    this.$refs.deleteDialog.showFormErrorMessage(error)
                    this.$refs.deleteDialog.resetFormSubmitStatus()
                })
        },
        handleActions (action) {
            switch (action.key) {
                case CommissionListEntry.action.DELETE:
                    this.$refs.deleteDialog.open()
                    break
            }
        },
        parseFormData(formData) {
            return {
                ...formData,
                premiumFrom: formData.premiumFrom || null,
                premiumTo: formData.premiumTo || null,
                commissionAmount: parseFloat(formData.commissionAmount),
            }
        },
        toKebabCase: function (text) {
            return kebabCase(text)
        },
    },
}
</script>
