<template>
    <!-- TODO improvement @MTR: Use styled `q-scroll-area` instead of just CSS overflow (similar to Card component) -->
    <q-banner
        v-bind="$attrs"
        :class="['info-box', `info-box--${type}`, buttonTextColor, { 'no-border': noBorder, 'q-mb-md': !noMargin, 'q-pa-none': noPadding, 'auto-width': autoWidth, 'centered': centered, 'has-actions': $slots.action }]"
        :style="maxHeight ? `max-height: ${maxHeight}; overflow-y: scroll;` : false"
        v-on="$listeners"
    >
        <template v-if="icon" v-slot:avatar>
            <q-icon :name="icon" :color="iconColor" size="xs" />
        </template>
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" /><!-- Named slots -->
    </q-banner>
</template>

<script>
export default {
    name: 'InfoBox',
    props: {
        type: {
            type: String,
            default: 'info', // Options: 'primary', 'secondary', 'accent', 'positive', 'negative', 'warning', 'info'
        },
        icon: {
            type: String,
            default: '',
        },
        iconColor: {
            type: String,
            default: '',
        },
        noBorder: {
            type: Boolean,
            default: false,
        },
        noMargin: {
            type: Boolean,
            default: false,
        },
        noPadding: {
            type: Boolean,
            default: false,
        },
        autoWidth: {
            type: Boolean,
            default: false,
        },
        maxHeight: {
            type: String,
            default: '',
        },
        centered: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        buttonTextColor () {
            if (['primary', 'secondary', 'positive', 'negative'].includes(this.type)) {
                return 'button-text-color-inverted'
            } else {
                return 'button-text-color-default'
            }
        },
    },
}
</script>

<style lang="scss">
.info-box,
.q-drawer-container .info-box {
    // Default styles
	position: relative;
    min-height: auto;
    //margin-bottom: 1.5rem;
    padding: $sizeSpacingMd $sizeSpacingMd ($sizeSpacingMd - 1px);

    border-left: $sizeSpacingXs solid var(--q-color-info);
    background-color: var(--q-color-info-lighter);

    transition: background-color $defaultTransitionStyles, border-color $defaultTransitionStyles;

    word-break: break-word; // Added due to prevent (unlikely) long error messages breaking the layout.

    // Different variations
    @each $variation in ("primary", "secondary", "accent", "positive", "negative", "warning", "info") {
        &--#{$variation} {
            @extend .info-box;

            // Change colors to match info box type
            --color-base-action: var(--q-color-#{$variation});
            --color-base-action-interaction: var(--q-color-#{$variation}-dark);
            --color-base-action-faded: var(--q-color-#{$variation}-light);
            --color-primary-action: var(--q-color-#{$variation});
            --color-primary-action-interaction: var(--q-color-#{$variation}-dark);
            --color-primary-action-faded: var(--q-color-#{$variation}-light);

            // Default styles
            border-left-color: var(--q-color-#{$variation});
            background-color: var(--q-color-#{$variation}-lighter);

            ul:not(.diff) li::before {
                color: var(--q-color-#{$variation});
                transition: color $defaultTransitionStyles;
            }
        }
    }

    // auto-width option
    &.auto-width {
        display: table;

        // Center info-box horizontally
        &.centered {
            margin-left: auto;
            margin-right: auto;
        }
    }

    // Styling of optional icon/avatar
    .q-banner__avatar {
        align-self: center;
    }

    // Styling of nested paragraphs
    p {
        &:only-child,
        &:last-child {
            margin-bottom: 0;
        }
    }

    // Styling of lists inside of info-boxes (e.g. for generic errors)
    & > ul,
    & > .q-banner__content ul {
        margin-top: 0.15em; // Optically center

        &:last-child {
            margin-bottom: 0;
        }

        & > li:first-child {
            margin-top: 0;
        }

        // Reformat list when there’s only one list with just a single item
        &:only-child > li:only-child {
            margin-left: -1.1rem;

            &::before {
                content: '';
            }
        }

        li {
            & > code {
                position: relative;
                background-color: transparent;
                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background-color: var(--color-background-inverted);
                    opacity: 0.1;
                    pointer-events: none;
                }
            }
        }
    }

    // Alignment of QBanner actions
    .q-banner__actions {
        align-self: flex-end;

        // Re-add spacing between info--box content and actions (when not `inline-actions`)
        &.col-all {
            margin-top: $sizeSpacingSm;
        }
    }

    // Set text color of nested QBtn text (default buttons only [not outline or flat ones])
    &.button-text-color-default {

        .q-btn__content {
            color: var(--color-text-primary);
        }
    }

    &.button-text-color-inverted {
        color: var(--color-text-primary);

        .q-btn__content {
            color: var(--color-text-inverted);
        }
    }

    // Styling of nested QBtns
    .q-btn {
        color: var(--color-base-action);

        // Outline
        &.q-btn--outline {
            .q-btn__wrapper {
                color: var(--color-base-action);
            }

            // Reset text color of button text
            .q-btn__content {
                color: var(--color-text-primary);
            }
        }

        // Flat
        &.q-btn--flat,
        &.q-btn--flat:not(.no-text-decoration),
        &.q-btn--flat:not(.q-btn--round) {
            text-decoration-color: var(--color-base-action);

            // Reset text color of button text
            .q-btn__content {
                color: var(--color-text-primary);
            }
        }

        // Interaction effects
        &.q-hoverable {

            &:hover,
            &:focus,
            &:active {
                & > .q-focus-helper {
                    background-color: var(--color-base-action-interaction);
                }
            }
        }
    }

    // Styling of nested links
    a {
        position: relative;
        color: var(--color-text-primary);
        text-decoration-color: var(--color-base-action);

        // TODO improvement/refactoring @MTR: Refactor this when colors/theming has been refactored.
        // We need to fake the background-color until the theming/colors are changed to a more modern approach and don’t use HEX colors anymore (as it’s not [easily] possible to add alpha value to a color that is stored as a CSS custom property).
        &:hover,
        &:focus,
        &:active {
            color: inherit;
            background-color: var(--color-base-action-faded);
        }
    }
}

@media print {
    .q-banner__actions {
        display: none;
    }
}
</style>
