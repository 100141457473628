import DefaultLayout from '@/layouts/Default.vue'
import DevDashboard from '@/views/dev/DevDashboard.vue'
import DevRolesConfigurator from '@/views/dev/DevRolesConfigurator.vue'
import DevDesignColorPalette from '@/views/dev/DevDesignColorPalette.vue'
import DevDesignTypography from '@/views/dev/DevDesignTypography.vue'
import DevDesignIcons from '@/views/dev/DevDesignIcons.vue'
import DevDesignIllustrations from '@/views/dev/DevDesignIllustrations.vue'
import DevComponentInfoBox from '@/views/dev/DevComponentInfoBox.vue'
import DevComponentCards from '@/views/dev/DevComponentCards.vue'
import DevComponentMarkupTable from '@/views/dev/DevComponentMarkupTable.vue'
import DevLibsForm from '@/views/dev/DevLibsForm'
import DevLibsWizard from '@/views/dev/DevLibsWizard'
import DevAutomationContractFileManagement from '@/views/dev/DevAutomationContractFileManagement.vue'
import DevAutomationPremiumInvoicePremiumReminder from '@/views/dev/DevAutomationPremiumInvoicePremiumReminder.vue'

export default [
    {
        path: '/dev',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'dev-dashboard',
            component: DevDashboard,
            meta: {
                role: 'administrator',
                appSubSection: 'dev',
            },
        }],
    },
    {
        path: '/dev/roles-configurator',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'dev-roles-configurator',
            component: DevRolesConfigurator,
            meta: {
                role: 'administrator',
                appSubSection: 'dev',
                navGroup: 'roles-configurator',
            },
        }],
    },
    {
        path: '/dev/design',
        name: 'dev-design',
        component: DefaultLayout,
        meta: {
            role: 'administrator',
            appSubSection: 'dev',
            navGroup: 'design',
        },
        redirect: {
            name: 'dev-design-color-palette',
        },
        children: [
            {
                path: 'color-palette',
                name: 'dev-design-color-palette',
                component: DevDesignColorPalette,
                meta: {
                    role: 'administrator',
                    appSubSection: 'dev',
                    navGroup: 'design',
                },
            },
            {
                path: 'icons',
                name: 'dev-design-icons',
                component: DevDesignIcons,
                meta: {
                    role: 'administrator',
                    appSubSection: 'dev',
                    navGroup: 'design',
                },
            },
            {
                path: 'illustrations',
                name: 'dev-design-illustrations',
                component: DevDesignIllustrations,
                meta: {
                    role: 'administrator',
                    appSubSection: 'dev',
                    navGroup: 'design',
                },
            },
            {
                path: 'typography',
                name: 'dev-design-typography',
                component: DevDesignTypography,
                meta: {
                    role: 'administrator',
                    appSubSection: 'dev',
                    navGroup: 'design',
                },
            },
        ],
    },
    {
        path: '/dev/components',
        name: 'dev-components',
        component: DefaultLayout,
        meta: {
            role: 'administrator',
            appSubSection: 'dev',
            navGroup: 'components',
        },
        redirect: {
            name: 'dev-component-info-box',
        },
        children: [
            {
                path: 'info-box',
                name: 'dev-component-info-box',
                component: DevComponentInfoBox,
                meta: {
                    role: 'administrator',
                    appSubSection: 'dev',
                    navGroup: 'components',
                },
            },
            {
                path: 'cards',
                name: 'dev-component-cards',
                component: DevComponentCards,
                meta: {
                    role: 'administrator',
                    appSubSection: 'dev',
                    navGroup: 'components',
                },
            },
            {
                path: 'markup-table',
                name: 'dev-component-markup-table',
                component: DevComponentMarkupTable,
                meta: {
                    role: 'administrator',
                    appSubSection: 'dev',
                    navGroup: 'components',
                },
            },
        ],
    },
    {
        path: '/dev/libs',
        name: 'dev-libs',
        component: DefaultLayout,
        meta: {
            role: 'administrator',
            appSubSection: 'dev',
            navGroup: 'libs',
        },
        redirect: {
            name: 'dev-libs-form',
        },
        children: [
            {
                path: 'form',
                name: 'dev-libs-form',
                component: DevLibsForm,
                meta: {
                    role: 'administrator',
                    appSubSection: 'dev',
                    navGroup: 'libs',
                },
            },
            {
                path: 'wizard',
                name: 'dev-libs-wizard',
                component: DevLibsWizard,
                meta: {
                    role: 'administrator',
                    appSubSection: 'dev',
                    navGroup: 'libs',
                },
            },
        ],
    },
    {
        path: '/dev/automation',
        name: 'dev-automation',
        component: DefaultLayout,
        meta: {
            role: 'administrator',
            appSubSection: 'dev',
            navGroup: 'automation',
        },
        redirect: {
            name: 'dev-automation-contract-file-management',
        },
        children: [
            {
                path: 'contract-file-management',
                name: 'dev-automation-contract-file-management',
                component: DevAutomationContractFileManagement,
                meta: {
                    role: 'administrator',
                    appSubSection: 'dev',
                    navGroup: 'automation',
                },
            },
            {
                path: 'premium-invoice-premium-reminder',
                name: 'dev-automation-premium-invoice-premium-reminder',
                component: DevAutomationPremiumInvoicePremiumReminder,
                meta: {
                    role: 'administrator',
                    appSubSection: 'dev',
                    navGroup: 'automation',
                },
            },
        ],
    },
]
