<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('views.account.change-password.title')" :to="{ name: 'account-security-reset-2fa' }" />
            </template>

            <h1>{{ $t('views.auth.reset-2fa.title') }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12 col-sm-10 col-md-10 col-lg-6 col-xl-5">
                <wizard-component
                    :wizard="wizard"
                    alternative-labels
                    :vertical="$q.screen.lt.md"
                    color="primary"
                >
                    <template v-slot:step-password>
                        <p>{{ $t('views.auth.reset-2fa.enter-password') }}</p>
                        <form-builder-next :form="passwordForm" @submit="onPasswordSubmit">
                            <template v-slot:buttons>
                                <div class="row q-mt-xs">
                                    <div :class="['col-xs-12', { 'text-right': $q.screen.gt.md }]">
                                        <base-button
                                            data-test="btn:submit"
                                            type="submit"
                                            :label="$t('common.term.next')"
                                        />
                                    </div>
                                </div>
                            </template>
                        </form-builder-next>
                    </template>
                    <template v-slot:step-2fa-code>
                        <auth-set-2fa-code ref="authSet2faCode" :qr-code="state.qrCode" @submit="onSet2faCodeSubmit" />
                    </template>
                    <template v-slot:step-2fa-backup-code>
                        <auth-set-2fa-backup-code ref="authSet2faBackupCode" :two-f-a-backup-code="state.twoFABackupCode" @submit="onSet2faBackupCodeSubmit" />
                    </template>
                    <template v-slot:step-confirmation>
                        <p>{{ $t('views.auth.reset-2fa.confirmation-text') }}</p>
                    </template>
                </wizard-component>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { i18n } from '@/i18n'
import WizardComponent from '@/libs/wizard/components/WizardComponent'
import AuthSet2faCode from '@/components/auth/AuthSet2faCode'
import AuthSet2faBackupCode from '@/components/auth/AuthSet2faBackupCode'
import { Form, FormField } from '@/libs/form'
import { UserService } from '@/services'
import { reactive, ref } from 'vue'
import { Wizard, WizardStep } from '@/libs/wizard'

export default {
    name: 'AccountSecurityReset2fa',
    components: {
        WizardComponent,
        'auth-set-2fa-code': AuthSet2faCode,
        'auth-set-2fa-backup-code': AuthSet2faBackupCode,
    },
    meta () {
        return {
            title: this.$tc('views.auth.reset-2fa.title', 1),
        }
    },
    setup () {
        // State
        const state = reactive({
            qrCode: '',
            twoFABackupCode: '',
        })

        // Wizard
        const wizard = new Wizard([
            new WizardStep('password',{ title: i18n.tc('common.term.password', 1), icon: 'mib-lock-1', activeIcon: 'mib-lock-1', doneColor: 'secondary' }),
            new WizardStep('2fa-code', { title: i18n.tc('common.auth.2fa-qr-code', 1), icon: 'mib-qr-code-scan', activeIcon: 'mib-qr-code-scan', doneColor: 'secondary' }),
            new WizardStep('2fa-backup-code', { title: i18n.tc('common.auth.2fa-backup-code', 1), icon: 'mib-common-file-text', activeIcon: 'mib-common-file-text', doneColor: 'secondary' }),
            new WizardStep('confirmation', { title: i18n.tc('common.status.done', 1), icon: 'mib-check-circle-1', activeIcon: 'mib-check', doneColor: 'secondary' }),
        ])

        // Password
        const formComponent = ref(null)
        const passwordForm = new Form()
        passwordForm.addField(new FormField('password', {
            label: 'common.term.password',
            inputType: 'password',
            validators: [
                { type: 'required' },
            ],
        }))
        function onPasswordSubmit ({ password }) {
            UserService.reset2FA(password)
                .then(response => {
                    state.qrCode = `data:image/png;base64,${response.qr_code}`
                    wizard.setStep('2fa-code')
                })
                .catch(error => {
                    formComponent.value.formBuilder.showFormErrorMessage(error)
                    formComponent.value.formBuilder.resetFormSubmitStatus()
                })
        }

        // 2FA code
        const authSet2faCode = ref(null)
        function onSet2faCodeSubmit ({ twoFACode }) {
            UserService.validate2FA(twoFACode.split(' ').join(''))
                .then(response => {
                    state.twoFABackupCode = response.two_fa_backup_code
                    wizard.setStep('2fa-backup-code')
                })
                .catch(error => {
                    authSet2faCode.value.showFormErrorMessage(error)
                    authSet2faCode.value.resetFormData()
                    authSet2faCode.value.resetFormSubmitStatus()
                })
        }

        // 2FA backup code
        const authSet2faBackupCode = ref(null)
        function onSet2faBackupCodeSubmit ({ twoFABackupCode }) {
            UserService.validate2faBackupCode(twoFABackupCode.split('-').join(''))
                .then(() => {
                    wizard.setStep('confirmation')
                })
                .catch(error => {
                    authSet2faBackupCode.value.showFormErrorMessage(error)
                    authSet2faBackupCode.value.resetFormData()
                    authSet2faBackupCode.value.resetFormSubmitStatus()
                })
        }

        return {
            // State
            state,

            // Wizard
            wizard,

            // Password
            formComponent,
            passwordForm,
            onPasswordSubmit,

            // 2FA code
            authSet2faCode,
            onSet2faCodeSubmit,

            // 2FA backup code
            authSet2faBackupCode,
            onSet2faBackupCodeSubmit,
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
