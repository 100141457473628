import { checkRouteAccessPermissions, checkRouteAccessPermissionsNested } from '@/helpers/acl'

import DefaultLayout from '@/layouts/Default.vue'
import AccountList from '@/views/accounting/AccountList'
import AccountCreate from '@/views/accounting/AccountCreate'
import AccountUpdate from '@/views/accounting/AccountUpdate'
import PostingTypeList from '@/views/accounting/PostingTypeList'
import PostingTypeCreate from '@/views/accounting/PostingTypeCreate'
import PostingTypeUpdate from '@/views/accounting/PostingTypeUpdate'
import PostingCreate from '@/views/accounting/PostingCreate'
import PostingUpdate from '@/views/accounting/PostingUpdate'
import PostingList from '@/views/accounting/PostingList'
import AccountingTransferPosting from '@/views/accounting/AccountingTransferPosting'
import StatementList from '@/views/statement/StatementList'
import StatementCreate from '@/views/statement/StatementCreate'
import StatementDetail from '@/views/statement/StatementDetail'
import AccountBalanceReportCreate from '@/views/accountBalanceReport/AccountBalanceReportCreate'
import AccountBalanceReportList from '@/views/accountBalanceReport/AccountBalanceReportList'
import AccountBalanceReportDetail from '@/views/accountBalanceReport/AccountBalanceReportDetail'
import AccountStatementReportCreate from '@/views/accountStatementReport/AccountStatementReportCreate'
import AccountStatementReportList from '@/views/accountStatementReport/AccountStatementReportList'
import AccountStatementReportDetail from '@/views/accountStatementReport/AccountStatementReportDetail'

export default [
    // TODO: Check/verify needed permissions/roles to be able to access ALL /admin/accounting/ routes (IMPORTANT!)
    {
        path: '/accounting/',
        name: 'accounting',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:accounting:core', 'Accounting:manage'],
            can: checkRouteAccessPermissions,
            navGroup: 'accounting',
        },
        redirect: {
            name: 'accounting-postings',
        },
    },
    {
        path: '/accounting/postings/',
        name: 'accounting-postings',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:accounting:core', 'Accounting:manage'],
            can: checkRouteAccessPermissionsNested,
            navGroup: 'accounting',
        },
        redirect: {
            name: 'accounting-posting-list',
        },
        children: [
            {
                path: '',
                name: 'accounting-posting-list',
                component: PostingList,
                meta: {
                    navGroup: 'accounting',
                },
            },
            {
                path: 'create',
                name: 'accounting-posting-create',
                component: PostingCreate,
                meta: {
                    navGroup: 'accounting',
                },
            },
            {
                path: ':id(\\d+)',
                name: 'accounting-posting-update',
                component: PostingUpdate,
                props: true,
                meta: {
                    navGroup: 'accounting',
                },
            },
        ],
    },
    {
        path: '/accounting/transfer-postings/',
        name: 'accounting-transfer-postings',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:accounting:core', 'Accounting:manage'],
            can: checkRouteAccessPermissionsNested,
            navGroup: 'accounting',
        },
        redirect: {
            name: 'accounting-transfer-postings-list', // TODO @MTR: Naming? This is not a list component.
        },
        children: [
            {
                path: '',
                name: 'accounting-transfer-postings-list',
                component: AccountingTransferPosting,
                meta: {
                    navGroup: 'accounting',
                },
            },
        ],
    },
    {
        path: '/accounting/statements/',
        name: 'accounting-statements',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:accounting:core', 'Accounting:manage'],
            can: checkRouteAccessPermissionsNested,
            navGroup: 'accounting',
        },
        redirect: {
            name: 'accounting-statement-list',
        },
        children: [
            {
                path: '',
                name: 'accounting-statement-list',
                component: StatementList,
                meta: {
                    navGroup: 'accounting',
                },
            },
            {
                path: 'create',
                name: 'accounting-statement-create',
                component: StatementCreate,
                meta: {
                    navGroup: 'accounting',
                },
            },
            {
                path: ':id(\\d+)',
                name: 'accounting-statement-detail',
                component: StatementDetail,
                props: true,
                meta: {
                    navGroup: 'accounting',
                },
            },
        ],
    },
    {
        path: '/accounting/account-balance-reports/',
        name: 'accounting-account-balance-reports',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:accounting:core', 'Accounting:manage'],
            can: checkRouteAccessPermissionsNested,
            navGroup: 'accounting',
        },
        redirect: {
            name: 'accounting-account-balance-report-list',
        },
        children: [
            {
                path: '',
                name: 'accounting-account-balance-report-list',
                component: AccountBalanceReportList,
                meta: {
                    navGroup: 'accounting',
                },
            },
            {
                path: 'create',
                name: 'accounting-account-balance-report-create',
                component: AccountBalanceReportCreate,
                meta: {
                    navGroup: 'accounting',
                },
            },
            {
                path: ':id(\\d+)',
                name: 'accounting-account-balance-report-detail',
                component: AccountBalanceReportDetail,
                props: true,
                meta: {
                    navGroup: 'accounting',
                },
            },
        ],
    },
    {
        path: '/accounting/account-statements/',
        name: 'accounting-account-statements',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:accounting:core', 'Accounting:manage'],
            can: checkRouteAccessPermissionsNested,
            navGroup: 'accounting',
        },
        redirect: {
            name: 'accounting-account-statement-list',
        },
        children: [
            {
                path: '',
                name: 'accounting-account-statement-list',
                component: AccountStatementReportList,
                meta: {
                    navGroup: 'accounting',
                },
            },
            {
                path: 'create',
                name: 'accounting-account-statement-create',
                component: AccountStatementReportCreate,
                meta: {
                    navGroup: 'accounting',
                },
            },
            {
                path: ':id(\\d+)',
                name: 'accounting-account-statement-detail',
                component: AccountStatementReportDetail,
                props: true,
                meta: {
                    navGroup: 'accounting',
                },
            },
        ],
    },

    // System preferences
    {
        path: '/admin/accounting/',
        name: 'admin-accounting',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:accounting:core', 'Accounting:manage'],
            can: checkRouteAccessPermissions,
            appSubSection: 'admin',
            navGroup: 'accounting',
        },
        redirect: {
            name: 'accounting-accounts',
        },
    },
    {
        path: '/admin/accounting/accounts/',
        name: 'accounting-accounts',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:accounting:core', 'Accounting:manage'],
            can: checkRouteAccessPermissionsNested,
            appSubSection: 'admin',
            navGroup: 'accounting',
        },
        redirect: {
            name: 'accounting-account-list',
        },
        children: [
            {
                path: ':tab(inactive)?',
                name: 'accounting-account-list',
                component: AccountList,
                props: true,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'accounting',
                },
            },
            {
                path: 'create',
                name: 'accounting-account-create',
                component: AccountCreate,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'accounting',
                },
            },
            {
                path: ':id(\\d+)',
                name: 'accounting-account-update',
                component: AccountUpdate,
                props: true,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'accounting',
                },
            },
        ],
    },
    {
        path: '/admin/accounting/posting-types/',
        name: 'accounting-posting-types',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:accounting:core', 'Accounting:manage'],
            can: checkRouteAccessPermissionsNested,
            appSubSection: 'admin',
            navGroup: 'accounting',
        },
        redirect: {
            name: 'accounting-posting-type-list',
        },
        children: [
            {
                path: '',
                name: 'accounting-posting-type-list',
                component: PostingTypeList,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'accounting',
                },
            },
            {
                path: 'create',
                name: 'accounting-posting-type-create',
                component: PostingTypeCreate,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'accounting',
                },
            },
            {
                path: ':id(\\d+)',
                name: 'accounting-posting-type-update',
                component: PostingTypeUpdate,
                props: true,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'accounting',
                },
            },
        ],
    },
]
