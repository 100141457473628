<template>
    <q-dialog
        ref="dialog"
        v-bind="$attrs"
        @show="onShow"
        @hide="onHide"
    >
        <q-card class="overlay-size-lg">
            <q-card-section>
                <h1>{{ $tc('common.accounting.posting.posting-preview', 1) }}</h1>

                <template v-if="errorMessage">
                    <p>{{ $tc('common.accounting.posting.show-posting-preview', 0) }}.</p>
                    <!-- eslint-disable vue/no-v-html -->
                    <info-box
                        type="negative"
                        data-test="error:global-message"
                        v-html="errorMessage"
                    />
                    <!-- eslint-enable -->
                </template>

                <q-table
                    v-else-if="postings"
                    :data="postings"
                    :columns="columns"
                    :loading="loading"
                    :pagination="{ rowsPerPage: 0 }"
                    hide-pagination
                    flat
                    square
                    dense
                >
                    <!-- TODO improvement: This could potentially be refactored as it uses the *mostly* same (inner) code as the BaseTable in `src/views/accounting/PostingList.vue` -->
                    <template v-slot:body-cell-debitAccount="slotProps">
                        <q-td :props="slotProps" class="truncate-text" data-test="td:debit-account-number">
                            <span>
                                <span class="account-number">{{ slotProps.row.debitAccount.number }}</span> <span class="account-name additional-info">– {{ slotProps.row.debitAccount.name }}</span>
                                <q-tooltip
                                    :delay="1000"
                                    anchor="center start"
                                    self="center start"
                                    :offset="[0, 0]"
                                    max-width="70ch"
                                >{{ slotProps.row.debitAccount.number }} – {{ slotProps.row.debitAccount.name }}</q-tooltip>
                            </span>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-creditAccount="slotProps">
                        <q-td :props="slotProps" class="truncate-text" data-test="td:credit-account-number">
                            <span>
                                <span class="account-number">{{ slotProps.row.creditAccount.number }}</span> <span class="account-name additional-info">– {{ slotProps.row.creditAccount.name }}</span>
                                <q-tooltip
                                    :delay="1000"
                                    anchor="center start"
                                    self="center start"
                                    :offset="[0, 0]"
                                    max-width="70ch"
                                >{{ slotProps.row.creditAccount.number }} – {{ slotProps.row.creditAccount.name }}</q-tooltip>
                            </span>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-amount="slotProps">
                        <q-td :props="slotProps" data-test="td:amount">
                            <span :class="['amount', { 'text-negative-darker': slotProps.row.amount < 0 }]">{{ slotProps.row.formattedAmount }}</span>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-text="slotProps">
                        <q-td :props="slotProps" class="truncate-text" data-test="td:posting-text">
                            <info-icon
                                v-if="slotProps.row.is_confidential"
                                name="mib-lock-1"
                                :text="$tc('common.accounting.posting.is-confidential--description', 1)"
                                class="q-mr-xs"
                                color="secondary"
                            />
                            <span>
                                {{ slotProps.row.text }}
                                <q-tooltip
                                    :delay="1000"
                                    anchor="center start"
                                    self="center start"
                                    :offset="[0, 0]"
                                    max-width="70ch"
                                >{{ slotProps.row.text }}</q-tooltip>
                            </span>
                        </q-td>
                    </template>
                </q-table>
            </q-card-section>

            <q-card-actions align="right" class="q-gutter-sm">
                <base-button
                    data-test="btn:close"
                    :label="$tc('common.accounting.posting.close-posting-preview', 1)"
                    primary-button
                    @click="close"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { extractErrorMessage } from '@/helpers/form'

export default {
    name: 'CommissionListEntryPostingPreviewOverlay',
    data() {
        return {
            postings: null,
            errorMessage: null,
            loading: false,
            columns: [
                {
                    name: 'date',
                    label: this.$tc('common.accounting.posting.date', 1),
                    field: row => row.formattedDate,
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'debitAccount',
                    label: this.$tc('common.accounting.posting.debit-account', 1),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'creditAccount',
                    label: this.$tc('common.accounting.posting.credit-account', 1),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'amount',
                    label: this.$tc('common.accounting.posting.amount', 1),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'text',
                    label: this.$tc('common.accounting.posting.text', 1),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'type',
                    label: this.$tc('common.accounting.posting.type', 1),
                    field: row => row.type.name,
                    align: 'left',
                    sortable: true,
                },
            ],
        }
    },
    methods: {
        open (commissionListEntry) {
            this.loading = true

            commissionListEntry.postingPreview().then(postingGroup => {
                this.postings = postingGroup.postings
            }).catch(error => {
                this.errorMessage = extractErrorMessage(error)
            }).finally(() => {
                this.loading = false
                this.$refs.dialog.show()
            })
        },
        close () {
            this.$refs.dialog.hide()
        },
        onShow () {
            this.$emit('show')
        },
        onHide () {
            this.resetInitialValues()
            this.$emit('hide')
        },
        resetInitialValues () {
            this.postings = null
            this.errorMessage = null
            this.loading = false
        },
    },
}
</script>

<style lang="scss" scoped>
.truncate-text {
    max-width: 40ch;
    overflow: hidden;
    text-overflow: ellipsis;

    @include mq($from: $sizeBreakpointXl) {
        max-width: none;
    }
}

.account-number,
.amount {
    font-family: $fontFamilyMono; // TODO @MTR: Check if this makes sense (and is needed).
    font-weight: bold;
    font-variant-numeric: slashed-zero;
}
</style>
