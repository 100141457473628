import gql from 'graphql-tag'

export const REGISTRATION_SET_PASSWORD_MUTATION = gql`
    mutation registrationSetPassword($token: String!, $password: String!, $password_confirmation: String!) {
        registrationSetPassword(
            token: $token,
            password: $password,
            password_confirmation: $password_confirmation
        ) {
            qr_code
        }
    }
`
