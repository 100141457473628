export const AccountAssignedAsType = Object.freeze({
    'CONSULTING_SETTINGS_CURRENT_ACCOUNT': 'CONSULTING_SETTINGS_CURRENT_ACCOUNT',
    'CONSULTING_SETTINGS_CANCELLATION_RESERVE_ACCOUNT': 'CONSULTING_SETTINGS_CANCELLATION_RESERVE_ACCOUNT',
    'CONSULTING_SETTINGS_OTHER_INCOME_ACCOUNT': 'CONSULTING_SETTINGS_OTHER_INCOME_ACCOUNT',
    'CONSULTING_SETTINGS_COMMISSION_INCOME_ACCOUNT': 'CONSULTING_SETTINGS_COMMISSION_INCOME_ACCOUNT',
    'CONSULTING_SETTINGS_MAIL_PROCESSING_REVENUE_ACCOUNT': 'CONSULTING_SETTINGS_MAIL_PROCESSING_REVENUE_ACCOUNT',
    'COMMISSION_TYPE_ACCOUNTING_CONFIGURATION_COMMISSION_INCOME_ACCOUNT': 'COMMISSION_TYPE_ACCOUNTING_CONFIGURATION_COMMISSION_INCOME_ACCOUNT',
    'COMMISSION_TYPE_ACCOUNTING_CONFIGURATION_COMMISSION_EXPENSE_ACCOUNT': 'COMMISSION_TYPE_ACCOUNTING_CONFIGURATION_COMMISSION_EXPENSE_ACCOUNT',
})

export const AccountAssignedToType = Object.freeze({
    'PERSON': 'PERSON',
    'COMPANY': 'COMPANY',
    'COMMISSION_TYPE_ACCOUNTING_CONFIGURATION': 'COMMISSION_TYPE_ACCOUNTING_CONFIGURATION',
})

export const AccountBalanceReportOrderField = Object.freeze({
    'DATE_FROM': 'DATE_FROM',
    'DATE_UNTIL': 'DATE_UNTIL',
    'CURRENT_QUEUE_ITEM_STATUS': 'CURRENT_QUEUE_ITEM_STATUS',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const AccountOrderField = Object.freeze({
    'TYPE': 'TYPE',
    'STATUS': 'STATUS',
    'NUMBER': 'NUMBER',
    'NAME': 'NAME',
    'BALANCE': 'BALANCE',
    'ASSIGNED_TO_NAME': 'ASSIGNED_TO_NAME',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const AccountStatementReportOrderField = Object.freeze({
    'DATE_FROM': 'DATE_FROM',
    'DATE_UNTIL': 'DATE_UNTIL',
    'CURRENT_QUEUE_ITEM_STATUS': 'CURRENT_QUEUE_ITEM_STATUS',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const AccountStatus = Object.freeze({
    'ACTIVE': 'ACTIVE',
    'INACTIVE': 'INACTIVE',
})

export const AccountType = Object.freeze({
    'ASSETS': 'ASSETS',
    'LIABILITIES': 'LIABILITIES',
    'EQUITY': 'EQUITY',
    'REVENUE': 'REVENUE',
    'EXPENSES': 'EXPENSES',
})

export const ActivityLogCauserType = Object.freeze({
    'USER': 'USER',
    'SYSTEM': 'SYSTEM',
})

export const ActivityLogName = Object.freeze({
    'ACCOUNT': 'ACCOUNT',
    'ACCOUNT_BALANCE_REPORT': 'ACCOUNT_BALANCE_REPORT',
    'ACCOUNT_STATEMENT_REPORT': 'ACCOUNT_STATEMENT_REPORT',
    'APPLICATION': 'APPLICATION',
    'APPLICATION_FILE': 'APPLICATION_FILE',
    'AREA_OF_RESPONSIBILITY': 'AREA_OF_RESPONSIBILITY',
    'COMMISSION_DISTRIBUTION_RULE_SET': 'COMMISSION_DISTRIBUTION_RULE_SET',
    'COMMISSION_LIST': 'COMMISSION_LIST',
    'COMMISSION_LIST_ENTRY': 'COMMISSION_LIST_ENTRY',
    'COMMISSION_LIST_IMPORT_EXCEL_MAPPING': 'COMMISSION_LIST_IMPORT_EXCEL_MAPPING',
    'COMMISSION_RATE_ADJUSTMENT_RULE_SET': 'COMMISSION_RATE_ADJUSTMENT_RULE_SET',
    'COMMISSION_SPLIT': 'COMMISSION_SPLIT',
    'COMMISSION_TYPE_ACCOUNTING_CONFIGURATION': 'COMMISSION_TYPE_ACCOUNTING_CONFIGURATION',
    'CONTACT': 'CONTACT',
    'CONTACT_FILE': 'CONTACT_FILE',
    'CONTACT_RELATIONSHIP': 'CONTACT_RELATIONSHIP',
    'CONTRACT': 'CONTRACT',
    'CONTRACT_FILE': 'CONTRACT_FILE',
    'POSTING': 'POSTING',
    'POSTING_TYPE': 'POSTING_TYPE',
    'STATEMENT': 'STATEMENT',
    'USER': 'USER',
    'PRODUCT_PROVIDER_SETTINGS': 'PRODUCT_PROVIDER_SETTINGS',
    'ECO_HUB_TECH_USER': 'ECO_HUB_TECH_USER',
    'PREMIUM_INVOICE': 'PREMIUM_INVOICE',
    'PREMIUM_INVOICE_POSITION': 'PREMIUM_INVOICE_POSITION',
    'PREMIUM_REMINDER': 'PREMIUM_REMINDER',
    'MAIL_PROCESSING_TEMPLATE': 'MAIL_PROCESSING_TEMPLATE',
})

export const ActivityLogOrderField = Object.freeze({
    'ACTIVITY_LOG_CREATED_AT': 'ACTIVITY_LOG_CREATED_AT',
})

export const ActivityLogSubjectRelation = Object.freeze({
    'ADDRESSES': 'ADDRESSES',
    'AREAS_OF_RESPONSIBILITY': 'AREAS_OF_RESPONSIBILITY',
    'COMMENTS': 'COMMENTS',
    'CONSULTING_SETTINGS': 'CONSULTING_SETTINGS',
    'CONTACT_RELATIONSHIPS': 'CONTACT_RELATIONSHIPS',
    'EMAIL_ADDRESSES': 'EMAIL_ADDRESSES',
    'PRODUCT_PROVIDER_SETTINGS': 'PRODUCT_PROVIDER_SETTINGS',
    'PHONE_NUMBERS': 'PHONE_NUMBERS',
    'CONTRACTS': 'CONTRACTS',
    'APPLICATIONS': 'APPLICATIONS',
    'CONTACT_FILES': 'CONTACT_FILES',
    'COMMISSION_SPLITS': 'COMMISSION_SPLITS',
    'CONTRACT_NUMBERS': 'CONTRACT_NUMBERS',
    'CANCELLATIONS': 'CANCELLATIONS',
    'PREMIUM_INVOICE': 'PREMIUM_INVOICE',
    'CONTRACT_FILES': 'CONTRACT_FILES',
    'APPLICATION_FILES': 'APPLICATION_FILES',
    'CONTRACT_INFORMATION': 'CONTRACT_INFORMATION',
    'COMMISSION_DISTRIBUTION_RULE_RANGES': 'COMMISSION_DISTRIBUTION_RULE_RANGES',
    'COMMISSION_LIST_ENTRY_POSITIONS': 'COMMISSION_LIST_ENTRY_POSITIONS',
    'COMMISSION_RATE_ADJUSTMENT_RULES': 'COMMISSION_RATE_ADJUSTMENT_RULES',
    'PREMIUM_INVOICE_POSITION': 'PREMIUM_INVOICE_POSITION',
    'DXP_SERVICE_PROVIDER_SETTINGS': 'DXP_SERVICE_PROVIDER_SETTINGS',
    'PREMIUM_REMINDER': 'PREMIUM_REMINDER',
})

export const ActivityLogSubjectType = Object.freeze({
    'ACCOUNT': 'ACCOUNT',
    'ACCOUNT_BALANCE_REPORT': 'ACCOUNT_BALANCE_REPORT',
    'ACCOUNT_STATEMENT_REPORT': 'ACCOUNT_STATEMENT_REPORT',
    'APPLICATION': 'APPLICATION',
    'APPLICATION_FILE': 'APPLICATION_FILE',
    'AREA_OF_RESPONSIBILITY': 'AREA_OF_RESPONSIBILITY',
    'COMMISSION_DISTRIBUTION_RULE_SET': 'COMMISSION_DISTRIBUTION_RULE_SET',
    'COMMISSION_LIST': 'COMMISSION_LIST',
    'COMMISSION_LIST_ENTRY': 'COMMISSION_LIST_ENTRY',
    'COMMISSION_LIST_IMPORT_EXCEL_MAPPING': 'COMMISSION_LIST_IMPORT_EXCEL_MAPPING',
    'COMMISSION_RATE_ADJUSTMENT_RULE_SET': 'COMMISSION_RATE_ADJUSTMENT_RULE_SET',
    'COMMISSION_SPLIT': 'COMMISSION_SPLIT',
    'COMMISSION_TYPE_ACCOUNTING_CONFIGURATION': 'COMMISSION_TYPE_ACCOUNTING_CONFIGURATION',
    'CONTACT': 'CONTACT',
    'CONTACT_FILE': 'CONTACT_FILE',
    'CONTACT_RELATIONSHIP': 'CONTACT_RELATIONSHIP',
    'CONTRACT': 'CONTRACT',
    'CONTRACT_FILE': 'CONTRACT_FILE',
    'POSTING': 'POSTING',
    'POSTING_TYPE': 'POSTING_TYPE',
    'STATEMENT': 'STATEMENT',
    'USER': 'USER',
    'PRODUCT_PROVIDER_SETTINGS': 'PRODUCT_PROVIDER_SETTINGS',
    'ECO_HUB_TECH_USER': 'ECO_HUB_TECH_USER',
    'PREMIUM_INVOICE': 'PREMIUM_INVOICE',
    'PREMIUM_INVOICE_POSITION': 'PREMIUM_INVOICE_POSITION',
    'MAIL_PROCESSING_TEMPLATE': 'MAIL_PROCESSING_TEMPLATE',
    'DXP_INBOX_ITEM': 'DXP_INBOX_ITEM',
    'PREMIUM_REMINDER': 'PREMIUM_REMINDER',
})

export const AddressStatus = Object.freeze({
    'ACTIVE': 'ACTIVE',
    'INACTIVE': 'INACTIVE',
})

export const ApplicationFileTypeStatus = Object.freeze({
    'ACTIVE': 'ACTIVE',
    'DELETED': 'DELETED',
})

export const ApplicationStatus = Object.freeze({
    'ON_HOLD': 'ON_HOLD',
    'PENDING_AT_PRODUCT_PROVIDER': 'PENDING_AT_PRODUCT_PROVIDER',
    'APPROVED_BY_PRODUCT_PROVIDER': 'APPROVED_BY_PRODUCT_PROVIDER',
    'DECLINED_BY_PRODUCT_PROVIDER': 'DECLINED_BY_PRODUCT_PROVIDER',
    'COUNTER_PROPOSAL_PENDING_AT_CUSTOMER': 'COUNTER_PROPOSAL_PENDING_AT_CUSTOMER',
    'COUNTER_PROPOSAL_DECLINED_BY_CUSTOMER': 'COUNTER_PROPOSAL_DECLINED_BY_CUSTOMER',
    'WITHDRAWN': 'WITHDRAWN',
})

export const AreaOfResponsibilityOrderField = Object.freeze({
    'NAME': 'NAME',
    'DESCRIPTION': 'DESCRIPTION',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const CancellationStatus = Object.freeze({
    'PENDING': 'PENDING',
    'WITHDRAWN': 'WITHDRAWN',
    'APPROVED': 'APPROVED',
    'DECLINED': 'DECLINED',
})

export const CivilStatus = Object.freeze({
    'SINGLE': 'SINGLE',
    'MARRIED': 'MARRIED',
    'CONCUBINAGE': 'CONCUBINAGE',
    'REGISTERED_PARTNERSHIP': 'REGISTERED_PARTNERSHIP',
    'DIVORCED': 'DIVORCED',
    'WIDOWED': 'WIDOWED',
    'SEPARATED': 'SEPARATED',
})

export const CommentOrderField = Object.freeze({
    'CREATED_AT': 'CREATED_AT',
})

export const CommentTargetObjectType = Object.freeze({
    'CONTACT': 'CONTACT',
    'CONTRACT': 'CONTRACT',
    'APPLICATION': 'APPLICATION',
})

export const CommissionDistributionRuleSetOrderField = Object.freeze({
    'NAME': 'NAME',
    'IS_ASSIGNED': 'IS_ASSIGNED',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const CommissionListEntryCustomerNameMatch = Object.freeze({
    'FULL': 'FULL',
    'PARTIAL': 'PARTIAL',
    'NONE': 'NONE',
})

export const CommissionListEntryOrderField = Object.freeze({
    'STATUS': 'STATUS',
    'CUSTOMER_NAME': 'CUSTOMER_NAME',
    'CONTRACT_NUMBER': 'CONTRACT_NUMBER',
    'CREATED_MANUALLY': 'CREATED_MANUALLY',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const CommissionListEntryPositionType = Object.freeze({
    'DETAIL': 'DETAIL',
    'TOTAL': 'TOTAL',
})

export const CommissionListEntryStatus = Object.freeze({
    'IMPORTED': 'IMPORTED',
    'PROCESSED': 'PROCESSED',
    'FAILED': 'FAILED',
    'QUEUED_FOR_POSTING': 'QUEUED_FOR_POSTING',
    'POSTING_FAILED': 'POSTING_FAILED',
    'POSTING_CANCELLED': 'POSTING_CANCELLED',
    'POSTED': 'POSTED',
})

export const CommissionListErrorScope = Object.freeze({
    'FILE': 'FILE',
    'LIST': 'LIST',
    'ENTRY': 'ENTRY',
})

export const CommissionListErrorType = Object.freeze({
    'UNUSABLE_FILE': 'UNUSABLE_FILE',
    'MISSING_VALUE': 'MISSING_VALUE',
    'INVALID_VALUE': 'INVALID_VALUE',
    'NOT_ALLOWED': 'NOT_ALLOWED',
})

export const CommissionListImportErrorOrderField = Object.freeze({
    'SCOPE': 'SCOPE',
    'ENTRY': 'ENTRY',
    'CREATED_AT': 'CREATED_AT',
})

export const CommissionListImportExcelMappingOrderField = Object.freeze({
    'NAME': 'NAME',
    'PRODUCT_PROVIDER_NAME': 'PRODUCT_PROVIDER_NAME',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const CommissionListOrderField = Object.freeze({
    'STATUS': 'STATUS',
    'SOURCE': 'SOURCE',
    'TYPE': 'TYPE',
    'PRODUCT_PROVIDER_NAME': 'PRODUCT_PROVIDER_NAME',
    'FILENAME': 'FILENAME',
    'EXCHANGE_DATE_FROM': 'EXCHANGE_DATE_FROM',
    'EXCHANGE_DATE_TO': 'EXCHANGE_DATE_TO',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const CommissionListSource = Object.freeze({
    'UPLOAD': 'UPLOAD',
    'IGB2B_DXP': 'IGB2B_DXP',
})

export const CommissionListStatus = Object.freeze({
    'PENDING': 'PENDING',
    'IMPORTING': 'IMPORTING',
    'PROCESSING': 'PROCESSING',
    'INCOMPLETELY_PROCESSED': 'INCOMPLETELY_PROCESSED',
    'PROCESSED': 'PROCESSED',
    'FAILED': 'FAILED',
    'CANCELED': 'CANCELED',
    'POSTED': 'POSTED',
})

export const CommissionListType = Object.freeze({
    'XML': 'XML',
    'EXCEL': 'EXCEL',
})

export const CommissionRateAdjustmentRuleSetOrderField = Object.freeze({
    'PRODUCT_PROVIDER_NAME': 'PRODUCT_PROVIDER_NAME',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const CommissionType = Object.freeze({
    'TYPE_00': 'TYPE_00',
    'TYPE_01': 'TYPE_01',
    'TYPE_02': 'TYPE_02',
    'TYPE_03': 'TYPE_03',
    'TYPE_04': 'TYPE_04',
    'TYPE_05': 'TYPE_05',
    'TYPE_06': 'TYPE_06',
    'TYPE_09': 'TYPE_09',
    'TYPE_71': 'TYPE_71',
    'TYPE_72': 'TYPE_72',
    'TYPE_73': 'TYPE_73',
    'TYPE_74': 'TYPE_74',
    'TYPE_75': 'TYPE_75',
    'TYPE_76': 'TYPE_76',
})

export const CommissionTypeAccountingConfigurationOrderField = Object.freeze({
    'COMMISSION_TYPE': 'COMMISSION_TYPE',
    'POSTING_TYPE_NAME': 'POSTING_TYPE_NAME',
    'POSTING_TEXT_PART': 'POSTING_TEXT_PART',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const ConsultantFileOrderField = Object.freeze({
    'FILE_NAME': 'FILE_NAME',
    'TYPE_ID': 'TYPE_ID',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
    'CONSULTANT_NAME': 'CONSULTANT_NAME',
})

export const ConsultantOrderField = Object.freeze({
    'CONTACT_NAME': 'CONTACT_NAME',
})

export const ConsultingFileTargetObjectType = Object.freeze({
    'CONTACT': 'CONTACT',
    'CONTRACT': 'CONTRACT',
    'APPLICATION': 'APPLICATION',
})

export const ConsultingSettingsStatus = Object.freeze({
    'ACTIVE': 'ACTIVE',
    'INACTIVE': 'INACTIVE',
})

export const ContactFileTypeStatus = Object.freeze({
    'ACTIVE': 'ACTIVE',
    'DELETED': 'DELETED',
})

export const ContactOrderField = Object.freeze({
    'CONTACT_NUMBER': 'CONTACT_NUMBER',
    'CONTACT_NAME': 'CONTACT_NAME',
    'CONSULTING_COMPANY_NAME': 'CONSULTING_COMPANY_NAME',
    'MAIN_ADDRESS_ADDRESS_1': 'MAIN_ADDRESS_ADDRESS_1',
    'MAIN_ADDRESS_ADDRESS_ZIP': 'MAIN_ADDRESS_ADDRESS_ZIP',
    'MAIN_ADDRESS_ADDRESS_CITY': 'MAIN_ADDRESS_ADDRESS_CITY',
})

export const ContactRelationshipCategory = Object.freeze({
    'PERSONAL': 'PERSONAL',
    'BUSINESS': 'BUSINESS',
})

export const ContactRelationshipNodeType = Object.freeze({
    'PARENT': 'PARENT',
    'CHILD': 'CHILD',
    'GRANDPARENT': 'GRANDPARENT',
    'GRANDCHILD': 'GRANDCHILD',
    'SIBLING': 'SIBLING',
    'PARTNER': 'PARTNER',
    'REPRESENTATIVE': 'REPRESENTATIVE',
    'PRINCIPAL': 'PRINCIPAL',
    'ASSOCIATION': 'ASSOCIATION',
    'MEMBER': 'MEMBER',
    'GUARDIAN': 'GUARDIAN',
    'PATRONIZED': 'PATRONIZED',
    'EMPLOYER': 'EMPLOYER',
    'EMPLOYEE': 'EMPLOYEE',
    'TRUSTEE': 'TRUSTEE',
    'TRUSTOR': 'TRUSTOR',
    'PARTNER_COMPANY': 'PARTNER_COMPANY',
    'PARENT_COMPANY': 'PARENT_COMPANY',
    'SUBSIDIARY_COMPANY': 'SUBSIDIARY_COMPANY',
    'PRODUCT_PROVIDER': 'PRODUCT_PROVIDER',
    'GENERAL_AGENCY': 'GENERAL_AGENCY',
    'MANAGER': 'MANAGER',
    'MANAGED': 'MANAGED',
    'AUDITOR': 'AUDITOR',
    'REVISED': 'REVISED',
})

export const ContactRelationshipOrderField = Object.freeze({
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const ContactRelationshipType = Object.freeze({
    'PARENT__CHILD': 'PARENT__CHILD',
    'GRANDPARENT__GRANDCHILD': 'GRANDPARENT__GRANDCHILD',
    'SIBLING__SIBLING': 'SIBLING__SIBLING',
    'PARTNER__PARTNER': 'PARTNER__PARTNER',
    'REPRESENTATIVE__PRINCIPAL': 'REPRESENTATIVE__PRINCIPAL',
    'ASSOCIATION__MEMBER': 'ASSOCIATION__MEMBER',
    'GUARDIAN__PATRONIZED': 'GUARDIAN__PATRONIZED',
    'EMPLOYER__EMPLOYEE': 'EMPLOYER__EMPLOYEE',
    'TRUSTEE__TRUSTOR': 'TRUSTEE__TRUSTOR',
    'PARTNER_COMPANY__PARTNER_COMPANY': 'PARTNER_COMPANY__PARTNER_COMPANY',
    'PARENT_COMPANY__SUBSIDIARY_COMPANY': 'PARENT_COMPANY__SUBSIDIARY_COMPANY',
    'PRODUCT_PROVIDER__GENERAL_AGENCY': 'PRODUCT_PROVIDER__GENERAL_AGENCY',
    'MANAGER__MANAGED': 'MANAGER__MANAGED',
    'AUDITOR__REVISED': 'AUDITOR__REVISED',
})

export const ContactStatus = Object.freeze({
    'ACTIVE': 'ACTIVE',
    'ARCHIVED': 'ARCHIVED',
})

export const ContactType = Object.freeze({
    'PERSON': 'PERSON',
    'COMPANY': 'COMPANY',
})

export const ContractFileTypeStatus = Object.freeze({
    'ACTIVE': 'ACTIVE',
    'DELETED': 'DELETED',
})

export const ContractManagedByStatus = Object.freeze({
    'CONSULTING_MANDATE_SUBMISSION_PENDING': 'CONSULTING_MANDATE_SUBMISSION_PENDING',
    'INTERNAL': 'INTERNAL',
    'CONSULTING_MANDATE_CANCELLATION_PENDING': 'CONSULTING_MANDATE_CANCELLATION_PENDING',
    'EXTERNAL': 'EXTERNAL',
})

export const ContractOrderField = Object.freeze({
    'CONTRACT_NUMBER': 'CONTRACT_NUMBER',
    'CONTRACT_STATUS': 'CONTRACT_STATUS',
    'PRODUCT_NAME': 'PRODUCT_NAME',
    'PRODUCT_PROVIDER_DISPLAY_NAME': 'PRODUCT_PROVIDER_DISPLAY_NAME',
    'PRODUCT_PROVIDER_COMPANY_NAME': 'PRODUCT_PROVIDER_COMPANY_NAME',
    'CUSTOMER_NAME': 'CUSTOMER_NAME',
    'CONSULTANT_NAME': 'CONSULTANT_NAME',
    'START_DATE': 'START_DATE',
    'END_DATE': 'END_DATE',
    'PREMIUM': 'PREMIUM',
})

export const ContractStatus = Object.freeze({
    'ACTIVE': 'ACTIVE',
    'TERMINATED': 'TERMINATED',
    'ARCHIVED': 'ARCHIVED',
})

export const CooperationStatus = Object.freeze({
    'ONGOING': 'ONGOING',
    'CANCELLED': 'CANCELLED',
})

export const CorrespondenceItemOrderField = Object.freeze({
    'NAME': 'NAME',
    'UPDATED_AT': 'UPDATED_AT',
})

export const CorrespondenceItemStatus = Object.freeze({
    'DRAFT': 'DRAFT',
    'PROCESSING': 'PROCESSING',
    'PROCESSED': 'PROCESSED',
    'DONE': 'DONE',
    'FAILED': 'FAILED',
    'CANCELLED': 'CANCELLED',
})

export const CorrespondenceItemTargetObjectType = Object.freeze({
    'CONTACT': 'CONTACT',
    'CONTRACT': 'CONTRACT',
    'APPLICATION': 'APPLICATION',
})

export const CorrespondenceItemType = Object.freeze({
    'EMAIL': 'EMAIL',
    'LETTER': 'LETTER',
})

export const CorrespondenceLetterConfidentialityNotice = Object.freeze({
    'PERSONAL': 'PERSONAL',
    'CONFIDENTIAL': 'CONFIDENTIAL',
})

export const CorrespondenceSalutationOrderField = Object.freeze({
    'CONTACT_TYPE': 'CONTACT_TYPE',
    'FORMALITY': 'FORMALITY',
    'GENDER': 'GENDER',
    'IS_DEFAULT': 'IS_DEFAULT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const CountryOrderField = Object.freeze({
    'COUNTRY_NAME': 'COUNTRY_NAME',
})

export const CustomerStatus = Object.freeze({
    'LEAD': 'LEAD',
    'ACTIVE': 'ACTIVE',
    'INACTIVE': 'INACTIVE',
})

export const DocumentContentFormality = Object.freeze({
    'FORMAL': 'FORMAL',
    'INFORMAL': 'INFORMAL',
})

export const DocumentLayoutOrderField = Object.freeze({
    'NAME': 'NAME',
    'UPDATED_AT': 'UPDATED_AT',
})

export const DocumentTemplateOrderField = Object.freeze({
    'NAME': 'NAME',
    'TYPE': 'TYPE',
    'LANGUAGE_NAME': 'LANGUAGE_NAME',
    'OWNER_NAME': 'OWNER_NAME',
    'UPDATED_AT': 'UPDATED_AT',
})

export const DocumentTemplateTargetObjectType = Object.freeze({
    'CONTACT': 'CONTACT',
    'APPLICATION': 'APPLICATION',
    'CONTRACT': 'CONTRACT',
})

export const DocumentType = Object.freeze({
    'LETTER': 'LETTER',
    'EMAIL': 'EMAIL',
})

export const DxpBillingPremiumKind = Object.freeze({
    'NORMAL_RENEWAL_PREMIUM': 'NORMAL_RENEWAL_PREMIUM',
    'RENEWAL_PREMIUM_WITH_TARIFF_ADJUSTMENT': 'RENEWAL_PREMIUM_WITH_TARIFF_ADJUSTMENT',
    'INVOICED_PREMIUM_FINAL_PREMIUM_INVOICE': 'INVOICED_PREMIUM_FINAL_PREMIUM_INVOICE',
    'INITIAL_AND_PARTIAL_PREMIUM_PRO_RATA_PREMIUM': 'INITIAL_AND_PARTIAL_PREMIUM_PRO_RATA_PREMIUM',
    'SHARE_IN_PROFIT_SURPLUS': 'SHARE_IN_PROFIT_SURPLUS',
    'OTHERS': 'OTHERS',
})

export const DxpBillingPremiumType = Object.freeze({
    'PREMIUMS': 'PREMIUMS',
    'SURCHARGES_OR_DISCOUNTS': 'SURCHARGES_OR_DISCOUNTS',
    'ADMINISTRATIVE_CHARGES': 'ADMINISTRATIVE_CHARGES',
    'ROUNDING_DIFFERENCES': 'ROUNDING_DIFFERENCES',
    'STAMP_DUTY': 'STAMP_DUTY',
    'ROAD_TRAFFIC_ACT_SVG_74_76_SURCHARGE': 'ROAD_TRAFFIC_ACT_SVG_74_76_SURCHARGE',
    'ACCIDENT_PREVENTION_SURCHARGE': 'ACCIDENT_PREVENTION_SURCHARGE',
    'PREMIUMS_AS_DEFINED_BY_INDIVIDUAL_INSURER': 'PREMIUMS_AS_DEFINED_BY_INDIVIDUAL_INSURER',
    'COMBINATION_OF_MULTIPLE_PREMIUM_TYPES': 'COMBINATION_OF_MULTIPLE_PREMIUM_TYPES',
})

export const DxpCustomerNameMatch = Object.freeze({
    'FULL': 'FULL',
    'PARTIAL': 'PARTIAL',
    'NONE': 'NONE',
})

export const DxpDocumentRecipient = Object.freeze({
    'BROKER': 'BROKER',
    'CUSTOMER': 'CUSTOMER',
    'CUSTOMER_E_BILL': 'CUSTOMER_E_BILL',
    'BROKER_AND_CUSTOMER': 'BROKER_AND_CUSTOMER',
    'NONE': 'NONE',
})

export const DxpDocumentTypeIdentifier = Object.freeze({
    'BILLING': 'BILLING',
    'REMINDER': 'REMINDER',
    'COMMISSION': 'COMMISSION',
    'CLAIMS_EXPERIENCE': 'CLAIMS_EXPERIENCE',
    'CLAIM': 'CLAIM',
    'OFFER': 'OFFER',
    'INFORMATION': 'INFORMATION',
    'CONTRACT': 'CONTRACT',
    'MANDATE': 'MANDATE',
    'ERROR_TECHNICAL': 'ERROR_TECHNICAL',
    'ERROR_BUSINESS': 'ERROR_BUSINESS',
})

export const DxpInboxItemCreatedAtDateFilterType = Object.freeze({
    'CREATED_AT': 'CREATED_AT',
    'FILE_INFO_CREATED_AT': 'FILE_INFO_CREATED_AT',
})

export const DxpInboxItemOrderField = Object.freeze({
    'PRODUCT_PROVIDER_NAME': 'PRODUCT_PROVIDER_NAME',
    'DOCUMENT_TYPE_IDENTIFIER': 'DOCUMENT_TYPE_IDENTIFIER',
    'CURRENT_QUEUE_ITEM_STATUS': 'CURRENT_QUEUE_ITEM_STATUS',
    'FILE_INFO_CREATED_AT': 'FILE_INFO_CREATED_AT',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const DxpStandardVersion = Object.freeze({
    'VERSION_4_1': 'VERSION_4_1',
    'VERSION_5_2': 'VERSION_5_2',
    'VERSION_5_2_1': 'VERSION_5_2_1',
    'VERSION_5_4': 'VERSION_5_4',
    'VERSION_5_4_1': 'VERSION_5_4_1',
})

export const EntityNumberType = Object.freeze({
    'CONTACT': 'CONTACT',
})

export const FileStorageItemType = Object.freeze({
    'STATEMENT': 'STATEMENT',
    'ACCOUNT_STATEMENT_REPORT': 'ACCOUNT_STATEMENT_REPORT',
    'ACCOUNT_BALANCE_REPORT': 'ACCOUNT_BALANCE_REPORT',
})

export const FileStorageItemsOrderField = Object.freeze({
    'TYPE': 'TYPE',
    'ACKNOWLEDGED_AT': 'ACKNOWLEDGED_AT',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const FileType = Object.freeze({
    'PDF': 'PDF',
    'EXCEL': 'EXCEL',
})

export const GenderChoice = Object.freeze({
    'FEMALE': 'FEMALE',
    'MALE': 'MALE',
})

export const GeneralAgencyOrderField = Object.freeze({
    'COMPANY_NAME': 'COMPANY_NAME',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const IGB2BInsurerCodeOrderField = Object.freeze({
    'ID': 'ID',
    'NAME': 'NAME',
})

export const IGB2BSectorCodeOrderField = Object.freeze({
    'CODE': 'CODE',
    'NAME': 'NAME',
})

export const LicenseStatus = Object.freeze({
    'ACTIVE': 'ACTIVE',
    'INACTIVE': 'INACTIVE',
})

export const MailProcessingTemplateOrderField = Object.freeze({
    'NAME': 'NAME',
    'FILE_NAME': 'FILE_NAME',
    'TARGET_OBJECT_TYPE': 'TARGET_OBJECT_TYPE',
    'BILLABLE': 'BILLABLE',
    'UPDATED_AT': 'UPDATED_AT',
})

export const OrderDirection = Object.freeze({
    'ASC': 'ASC',
    'DESC': 'DESC',
})

export const PostingOrderField = Object.freeze({
    'TYPE': 'TYPE',
    'DATE': 'DATE',
    'NUMBER': 'NUMBER',
    'TEXT': 'TEXT',
    'DEBIT_ACCOUNT_NUMBER': 'DEBIT_ACCOUNT_NUMBER',
    'CREDIT_ACCOUNT_NUMBER': 'CREDIT_ACCOUNT_NUMBER',
    'AMOUNT': 'AMOUNT',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const PostingTypeOrderField = Object.freeze({
    'NAME': 'NAME',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const PremiumInvoiceOrderField = Object.freeze({
    'INVOICE_NUMBER': 'INVOICE_NUMBER',
    'INVOICE_DATE': 'INVOICE_DATE',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const PremiumInvoicePositionType = Object.freeze({
    'DETAIL': 'DETAIL',
    'TOTAL': 'TOTAL',
})

export const PremiumInvoicePremiumKind = Object.freeze({
    'TYPE_11': 'TYPE_11',
    'TYPE_12': 'TYPE_12',
    'TYPE_20': 'TYPE_20',
    'TYPE_30': 'TYPE_30',
    'TYPE_40': 'TYPE_40',
    'TYPE_99': 'TYPE_99',
})

export const PremiumInvoicePremiumType = Object.freeze({
    'TYPE_01': 'TYPE_01',
    'TYPE_02': 'TYPE_02',
    'TYPE_03': 'TYPE_03',
    'TYPE_04': 'TYPE_04',
    'TYPE_05': 'TYPE_05',
    'TYPE_06': 'TYPE_06',
    'TYPE_07': 'TYPE_07',
    'TYPE_08': 'TYPE_08',
    'TYPE_09': 'TYPE_09',
})

export const PremiumReminderOrderField = Object.freeze({
    'INVOICE_NUMBER': 'INVOICE_NUMBER',
    'INVOICE_DATE': 'INVOICE_DATE',
    'REMINDER_DATE': 'REMINDER_DATE',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const PrinterStatus = Object.freeze({
    'ONLINE': 'ONLINE',
    'OFFLINE': 'OFFLINE',
})

export const ProcessedCorrespondenceItemOrderField = Object.freeze({
    'SUBJECT': 'SUBJECT',
    'CREATOR_NAME': 'CREATOR_NAME',
    'SENDER_NAME': 'SENDER_NAME',
    'RECIPIENT_ADDRESS': 'RECIPIENT_ADDRESS',
    'UPDATED_AT': 'UPDATED_AT',
})

export const ProcessedCorrespondenceItemStatus = Object.freeze({
    'PENDING': 'PENDING',
    'PROCESSING': 'PROCESSING',
    'PROCESSED': 'PROCESSED',
    'DONE': 'DONE',
    'FAILED': 'FAILED',
    'CANCELLED': 'CANCELLED',
})

export const ProcessedCorrespondenceItemType = Object.freeze({
    'EMAIL': 'EMAIL',
    'LETTER': 'LETTER',
})

export const ProductCategoryOrderField = Object.freeze({
    'CATEGORY_NAME': 'CATEGORY_NAME',
})

export const ProductDerivedEntityOrderField = Object.freeze({
    'NUMBER': 'NUMBER',
    'PRODUCT_NAME': 'PRODUCT_NAME',
    'PRODUCT_PROVIDER_NAME': 'PRODUCT_PROVIDER_NAME',
    'CONSULTANT_NAME': 'CONSULTANT_NAME',
    'START_DATE': 'START_DATE',
    'END_DATE': 'END_DATE',
    'PREMIUM': 'PREMIUM',
})

export const ProductDerivedEntityType = Object.freeze({
    'APPLICATION': 'APPLICATION',
    'CONTRACT': 'CONTRACT',
})

export const ProductOrderField = Object.freeze({
    'PRODUCT_NAME': 'PRODUCT_NAME',
    'TEMPLATE_NAME': 'TEMPLATE_NAME',
    'PROVIDER_DISPLAY_NAME': 'PROVIDER_DISPLAY_NAME',
    'PROVIDER_COMPANY_NAME': 'PROVIDER_COMPANY_NAME',
    'STATUS': 'STATUS',
})

export const ProductProviderSettingsStatus = Object.freeze({
    'ACTIVE': 'ACTIVE',
    'INACTIVE': 'INACTIVE',
})

export const ProductStatus = Object.freeze({
    'ACTIVE': 'ACTIVE',
    'ARCHIVED': 'ARCHIVED',
})

export const ProductTemplateOrderField = Object.freeze({
    'PRODUCT_TEMPLATE_NAME': 'PRODUCT_TEMPLATE_NAME',
    'PRODUCT_TEMPLATE_CATEGORY_NAME': 'PRODUCT_TEMPLATE_CATEGORY_NAME',
    'PRODUCT_TEMPLATE_SUB_CATEGORY_NAME': 'PRODUCT_TEMPLATE_SUB_CATEGORY_NAME',
})

export const ProductTemplateStatus = Object.freeze({
    'ACTIVE': 'ACTIVE',
    'ARCHIVED': 'ARCHIVED',
})

export const QueueItemStatus = Object.freeze({
    'QUEUED': 'QUEUED',
    'PROCESSING': 'PROCESSING',
    'FAILED': 'FAILED',
    'CANCELLED': 'CANCELLED',
    'DONE': 'DONE',
})

export const RoleOrderField = Object.freeze({
    'ROLE_NAME': 'ROLE_NAME',
})

export const SearchOperator = Object.freeze({
    'EQUAL_TO': 'EQUAL_TO',
    'NOT_EQUAL_TO': 'NOT_EQUAL_TO',
})

export const SortOrder = Object.freeze({
    'ASC': 'ASC',
    'DESC': 'DESC',
})

export const StatementOrderField = Object.freeze({
    'CONSULTING_ENTITY_NAME': 'CONSULTING_ENTITY_NAME',
    'DATE_FROM': 'DATE_FROM',
    'DATE_UNTIL': 'DATE_UNTIL',
    'INCLUDE_ATTACHMENTS': 'INCLUDE_ATTACHMENTS',
    'FILE_STORAGE_ITEM_SHARED_AT': 'FILE_STORAGE_ITEM_SHARED_AT',
    'CURRENT_QUEUE_ITEM_STATUS': 'CURRENT_QUEUE_ITEM_STATUS',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const TaskOrderField = Object.freeze({
    'STATUS': 'STATUS',
    'CREATOR_NAME': 'CREATOR_NAME',
    'ASSIGNEE_NAME': 'ASSIGNEE_NAME',
    'SUBJECT': 'SUBJECT',
    'TARGET_OBJECT_TYPE': 'TARGET_OBJECT_TYPE',
    'TARGET_OBJECT_ID': 'TARGET_OBJECT_ID',
    'DUE_DATE': 'DUE_DATE',
    'DONE_AT': 'DONE_AT',
    'CREATED_AT': 'CREATED_AT',
    'UPDATED_AT': 'UPDATED_AT',
})

export const TaskStatus = Object.freeze({
    'TODO': 'TODO',
    'IN_PROGRESS': 'IN_PROGRESS',
    'DONE': 'DONE',
})

export const TaskTargetObjectType = Object.freeze({
    'CONTACT': 'CONTACT',
    'APPLICATION': 'APPLICATION',
    'CONTRACT': 'CONTRACT',
})

export const TaskVisibility = Object.freeze({
    'PUBLIC': 'PUBLIC',
    'PRIVATE': 'PRIVATE',
})

export const TokenStatus = Object.freeze({
    'INVALID': 'INVALID',
    'ACTIVE': 'ACTIVE',
    'EXPIRED': 'EXPIRED',
    'REDEEMED': 'REDEEMED',
})

export const TokenType = Object.freeze({
    'USER_REGISTRATION': 'USER_REGISTRATION',
    'USER_REGISTRATION_FLOW': 'USER_REGISTRATION_FLOW',
})

export const Trashed = Object.freeze({
    'ONLY': 'ONLY',
    'WITH': 'WITH',
    'WITHOUT': 'WITHOUT',
})

export const UserOrderField = Object.freeze({
    'USER_EMAIL': 'USER_EMAIL',
    'USER_STATUS': 'USER_STATUS',
    'PERSON_FIRST_NAME': 'PERSON_FIRST_NAME',
    'PERSON_LAST_NAME': 'PERSON_LAST_NAME',
})

export const UserStatus = Object.freeze({
    'INACTIVE': 'INACTIVE',
    'ACTIVE': 'ACTIVE',
    'INVITED': 'INVITED',
})
