var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{attrs:{"data-test":"form:commission-type-accounting-configuration"},on:{"submit":function($event){$event.preventDefault();return _vm.onFormSubmit.apply(null, arguments)}}},[(_vm.formErrorMessage)?_c('info-box',{attrs:{"type":"negative","data-test":"error:global-message"},domProps:{"innerHTML":_vm._s(_vm.formErrorMessage)}}):_vm._e(),_c('div',{staticClass:"row q-col-gutter-md"},[_c('div',{staticClass:"col-xs-12"},[_c('form-builder',{attrs:{"item-key":"postingTextPart"}})],1),_c('div',{staticClass:"col-xs-12 col-md-6"},[_c('form-builder',{attrs:{"item-key":"commissionIncomeAccountId","filter":{
                    filterIsAssigned: false,
                    filterType: [_vm.AccountType.REVENUE]
                },"inject-options":_vm.injectOptions('commissionIncomeAccount')}})],1),_c('div',{staticClass:"col-xs-12 col-md-6"},[_c('form-builder',{attrs:{"item-key":"commissionExpenseAccountId","filter":{
                    filterIsAssigned: false,
                    filterType: [_vm.AccountType.EXPENSES]
                },"inject-options":_vm.injectOptions('commissionExpenseAccount')}})],1)]),_c('in-page-footer',[_c('base-button',{attrs:{"label":_vm.$t('common.term.cancel'),"primary-button":"","flat":""},on:{"click":_vm.cancel}}),_c('base-button',{attrs:{"type":"submit","data-test":"btn:submit","label":_vm.$t('common.term.save'),"primary-button":""}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }