<template>
    <page-wrapper>
        <page-header>
            <h1>{{ $tc('common.app.release-note', 2) }} <span class="subtitle">({{ $tc('common.term.version', 1) }} {{ $store.state.release }})</span></h1>
            <!-- TODO: Adjust breadcrumbs after release notes have been moved to their correct place (e.g. "About", "System info", "Help" or the like) -->
            <template v-slot:breadcrumbRoot>
                <q-breadcrumbs-el icon="mib-file-code-information-1" :label="$tc('common.app.release-note', 2)" :to="{ name: 'release-notes' }" />
            </template>
        </page-header>

        <release-notes-content />
    </page-wrapper>
</template>

<script>
import ReleaseNotesContent from '@/components/ReleaseNotesContent'

export default {
    name: 'ReleaseNotes',
    components: {
        ReleaseNotesContent,
    },
    meta () {
        return {
            title: this.$tc('common.app.release-note', 2),
        }
    },
}
</script>
