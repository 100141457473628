import { kebabCase } from 'lodash'
import { ContractManagedByStatus } from '@/enums/graphql'

const managedByStatusOptions = Object.values(ContractManagedByStatus).map(value => ({ value: value, label: `forms.field.label.application.managed-by-status.${kebabCase(value)}` }))
export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'contractNumber',
            label: 'common.contract.contract-number',
            inputType: 'text',
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'consultant',
            label: 'common.contact.consultant',
            inputType: 'consultant',
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'startDate',
            label: 'common.contract.contract-start',
            inputType: 'calendar',
            validators: [
                { type: 'date' },
            ],
        },
        {
            type: 'field',
            key: 'endDate',
            label: 'common.contract.contract-end',
            inputType: 'calendar',
            validators: [
                { type: 'date' },
            ],
        },
        {
            type: 'field',
            key: 'premium',
            label: 'common.contract.premium',
            inputType: 'text',
            validators: [
                { type: 'decimal' },
                {
                    type: 'minValue',
                    params: {
                        min: 0,
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'generalAgencyId',
            label: 'forms.field.label.application.managed-by',
            inputType: 'generalAgency',
        },
        {
            type: 'field',
            key: 'managedByStatus',
            label: 'forms.field.label.application.managed-by-status.managed-by-status',
            inputType: 'select',
            extra_attributes: {
                list: {
                    options: managedByStatusOptions,
                },
            },
            validators: [
                { type: 'required' },
            ],
        },
        // TODO:
        //  - Must and only can be provided if:
        //      - `managed_by_status === ContractManagedByStatus.CONSULTING_MANDATE_SUBMISSION_PENDING`
        //      - OR `managed_by_status === ContractManagedByStatus.CONSULTING_MANDATE_CANCELLATION_PENDING`
        {
            type: 'field',
            key: 'consultingMandateEnquirySendDate',
            label: 'forms.field.label.application.submission-date', // Note: The label depends on the status; it is overwritten in the UI.
            inputType: 'calendar',
            validators: [
                { type: 'date' },
            ],
        },
    ],
}
