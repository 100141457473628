import gql from 'graphql-tag'

export const CORRESPONDENCE_SALUTATION_QUERY = gql`
    query correspondenceSalutation(
        $id: ID!
    ) {
        correspondenceSalutation(
            id: $id
        ) {
            id
            contact_type
            formality
            gender
            language {
                id
                name
            }
            is_default
            salutation {
                schemaType
                schemaVersion
                contentJSON
                contentHTML
                attrs
            }
            created_at
            updated_at
        }
    }
`
