import gql from 'graphql-tag'

export const MAIL_PROCESSING_TEMPLATE_UPDATE_MUTATION = gql`
    mutation mailProcessingTemplateUpdate(
        $id: ID!
        $mailProcessingTemplate: MailProcessingTemplateInput!
    ) {
        mailProcessingTemplateUpdate(
            id: $id
            mailProcessingTemplate: $mailProcessingTemplate
        ) {
            id
            name
            description
            file_name
            mailProcessingActiveDocumentTemplate {
                id
            }
            mailProcessingInactiveDocumentTemplate {
                id
            }
            target_object_type
            consultingFileType {
                ... on ContactFileType {
                    id
                }
                ... on ContractFileType {
                    id
                }
                ... on ApplicationFileType {
                    id
                }
            }
            billable
            created_at
            updated_at
        }
    }
`
