<template>
    <q-checkbox
        :value="value"
        v-bind="$attrs"
        @input="updateValue"
        @change="onChange"
    >
        <info-icon
            v-if="infoText || infoTitle"
            :title="infoTitle"
            :text="infoText"
            :color="infoIconColor"
            class="all-pointer-events q-ml-xs"
            size="1em"
        />
    </q-checkbox>
</template>

<script>
export default {
    name: 'BaseCheckbox',
    inheritAttrs: false,
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        infoTitle: {
            type: String,
            default: '',
        },
        infoText: {
            type: String,
            default: '',
        },
        infoIconColor: {
            type: String,
            default: 'secondary-light',
        },
    },
    methods: {
        updateValue (value) {
            this.$emit('input', value)
        },
        onChange (event) {
            this.$emit('change', event.target.value)
        },
    },
}
</script>
