import { Model } from '@/models'

import { ContactService } from '@/services'
import { computed } from 'vue'

export class PhoneNumber extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'category',
            'label',
            'phone_number',
            'is_main_phone_number',
        ], data)

        // Computed
        this.formattedCategoryLabel = computed(() => {
            return this.label ? `${this.category?.name}: ${this.label?.label}` : this.category?.name
        })
    }

    static create (contactId, { category, label, phoneNumber, isMain }) {
        const variables = {
            contact_id: contactId,
            phoneNumber: {
                category_id: category,
                label_id: label,
                phone_number: phoneNumber,
                is_main_phone_number: isMain,
            },
        }
        return ContactService.createPhoneNumber(variables)
    }

    update ({ category, label, phoneNumber, isMain }) {
        const phoneNumberVariables = {
            category_id: category,
            label_id: label,
            phone_number: phoneNumber,
            is_main_phone_number: isMain,
        }
        // TODO: Directly update phone number object?
        return ContactService.updatePhoneNumber(this.id, phoneNumberVariables)
    }

    delete () {
        return ContactService.deletePhoneNumber(this.id)
    }
}
