<template>
    <base-badge
        v-bind="$attrs"
        :align="align"
        :class="`${kebabCase(defaultTranslationBasePath)}-${kebabCase(status)}`"
        :status="status"
        data-test="text:badge-status"
    >
        <component
            v-if="loading && loadingSpinnerPosition === 'left'"
            :is="loadingSpinnerType ? `q-spinner-${loadingSpinnerType}` : 'q-spinner'"
            class="q-mr-xs"
            size="0.8em"
        />

        <q-icon v-if="icon && iconPosition === 'left'" :name="icon" />
        {{ $tc(`${defaultTranslationBasePath}.${kebabCase(status)}`, 1) }}
        <q-icon v-if="icon && iconPosition === 'right'" :name="icon" />

        <component
            v-if="loading && loadingSpinnerPosition === 'right'"
            :is="loadingSpinnerType ? `q-spinner-${loadingSpinnerType}` : 'q-spinner'"
            class="q-ml-xs"
            size="0.8em"
        />

        <info-icon
            v-if="infoTitle || infoText"
            :title="infoTitle"
            :text="infoText"
            :status-code="infoStatusCode"
            color="currentColor"
            size="1em"
            class="q-ml-xs"
        />
    </base-badge>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import BaseBadge from '@/components/BaseBadge'

export default {
    name: 'StatusBadge',
    components: {
        BaseBadge,
    },
    props: {
        align: {
            type: String,
            default: 'middle',
        },
        status: {
            type: String,
            required: true,
        },
        defaultTranslationBasePath: {
            type: String,
            default: 'common.status', // i18n translation base path (folder)
        },

        icon: {
            type: String,
            default: '',
        },
        iconPosition: {
            type: String,
            default: 'left',
        },

        infoTitle: {
            type: String,
            default: '',
        },
        infoText: {
            type: String,
            default: '',
        },
        infoStatusCode: {
            type: String,
            default: '',
        },

        loading: {
            type: Boolean,
            default: false,
        },
        loadingSpinnerType: {
            type: String,
            default: '',
        },
        loadingSpinnerPosition: {
            type: String,
            default: 'left',
        },
    },
    methods: {
        kebabCase: function (text) {
            return kebabCase(text)
        },
    },
}
</script>

<style lang="scss" scoped>
.q-badge {

    td &:first-child {
        margin-left: 0;
    }
}
</style>
