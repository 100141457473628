<template>
    <page-loading-indicator v-if="!state.contact" :number-of-breadcrumb-elements="3" />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="state.contact.getContactName()" :to="{ name: 'contact-detail', params: { id: state.contact.id } }" data-test="breadcrumb:contact-detail" />
                <q-breadcrumbs-el :label="$tc('common.term.contract', 2)" :to="{ name: 'contact-detail', params: { id: contactId }}" data-test="breadcrumb:contracts" />
                <q-breadcrumbs-el :label="$tc('views.contract.add-contract.add-contract', 1)" :to="{ name: 'contract-create', params: { id: state.contact.id } }" data-test="breadcrumb:contract-create" />
            </template>

            <h1>{{ $tc('views.contract.add-contract.add-contract', 1) }}</h1>
        </page-header>

        <wizard-component
            :wizard="wizard"
            alternative-labels
            :vertical="$q.screen.lt.md"
            flat
            class="create-contract-wrapper"
        >
            <template v-slot:step-product>
                <contract-select-product-form @submit="getProduct" />
            </template>

            <template v-slot:step-form>
                <info-box
                    v-if="state.hasActiveCommissionSplits || state.hasCommissionRecipientOverrides"
                    type="warning"
                    data-test="info:commission-recipient-splitting-hint"
                >
                    <p>
                        <template v-if="state.hasCommissionRecipientOverrides && state.hasActiveCommissionSplits">{{ $tc('views.contract.add-contract.commission.has-commission-recipient-override--has-active-commission-splitting', 1) }}</template>
                        <template v-else-if="state.hasCommissionRecipientOverrides">{{ $tc('views.contract.add-contract.commission.has-commission-recipient-override', 1) }}</template>
                        <template v-else-if="state.hasActiveCommissionSplits">{{ $tc('views.contract.add-contract.commission.has-active-commission-splitting', 1) }}</template>
                        {{ $tc('views.contract.add-contract.commission.please-check-also-needed', 1) }}
                    </p>
                </info-box>

                <product-derived-entity-form
                    ref="productDerivedEntityForm"
                    :form-class="ContractForm"
                    :product="state.product"
                    :consultant-id="prefillConsultantId"
                    @submit="createProductDerivedEntity"
                    @cancel="wizard.reset"
                />
            </template>

            <template v-slot:step-files>
                <div v-if="state.productDerivedEntity" class="col-xs-12 q-gutter-y-md">
                    <consulting-file-browser
                        :target-object-type="ConsultingFileTargetObjectType.CONTRACT"
                        :target-object-id="state.productDerivedEntity.id"
                        :target-object-consultants="[state.productDerivedEntity.consultant]"
                        :meta="{ contactId: state.contact.id, contractNumber: state.productDerivedEntity.currentContractNumber, contactName: state.contact.getContactName() }"
                        @currentFileTypeIdChange="fileTypeId => state.consultingFileBrowserFileTypeId = fileTypeId"
                    >
                        <consulting-file-upload-button
                            :target-object-type="ConsultingFileTargetObjectType.CONTRACT"
                            :target-object-id="state.productDerivedEntity.id"
                            :file-type-id="state.consultingFileBrowserFileTypeId"
                            :meta="{ contactId: state.contact.id, contractNumber: state.productDerivedEntity.currentContractNumber, contactName: state.contact.getContactName() }"
                        />
                    </consulting-file-browser>

                    <in-page-footer>
                        <base-button
                            :label="$t('views.contract.add-contract.add-another')"
                            outline
                            primary-button
                            @click="reset"
                        />
                        <base-button
                            :to="{ name: 'contract-detail', params: { contactId: contactId, id: state.productDerivedEntity.id } }"
                            :label="$t('views.contract.add-contract.go-to-contract')"
                            icon="mib-common-file-text-alternate"
                            primary-button
                        />
                    </in-page-footer>
                </div>
            </template>
        </wizard-component>
    </page-wrapper>
</template>

<script>
import { fetchPaginatedObjects } from '@/helpers'
import WizardComponent from '@/libs/wizard/components/WizardComponent'
import ContractSelectProductForm from '@/components/contract/ContractSelectProductForm'
import ProductDerivedEntityForm from '@/components/contract/ProductDerivedEntityForm'

import { Product } from '@/models/product'
import { Contact } from '@/models/contact'
import { Contract } from '@/models/contract'

import { ConsultingFileTargetObjectType } from '@/enums/graphql'
import ConsultingFileBrowser from '@/components/consultingFile/ConsultingFileBrowser'
import ConsultingFileUploadButton from '@/components/consultingFile/ConsultingFileUploadButton'
import { FormWizard, FormWizardStep } from '@/libs/form/wizard'
import { useI18n } from '@/composables/i18n'
import { ContractService } from '@/services'
import { computed, onBeforeMount, reactive, ref } from 'vue'
import { useStore } from '@/composables/store'
import { useRouter } from '@/composables/router'
import { ContractForm } from '@/forms/contract'

export default {
    name: 'ContractCreate',
    meta () {
        return {
            title: this.$tc('views.contract.add-contract.add-contract', 1),
        }
    },
    components: {
        WizardComponent,
        ContractSelectProductForm,
        ProductDerivedEntityForm,
        ConsultingFileBrowser,
        ConsultingFileUploadButton,
    },
    props: {
        contactId: {
            type: String,
            required: true,
        },
    },
    setup (props) {
        const store = useStore()
        const router = useRouter()
        const { t, tc } = useI18n()

        // Data
        const state = reactive({
            contact: null,
            product: null,
            productDerivedEntity: null,
            consultingFileBrowserFileTypeId: null,
            hasActiveCommissionSplits: false,
            hasCommissionRecipientOverrides: false,
        })

        // Template refs
        const productDerivedEntityForm = ref()

        // Computed
        const productName = computed(() => {
            return state.product ? `${state.product?.name} (${(state.product?.basicProvider?.display_name || state.product?.basicProvider?.name)})` : ''
        })
        const productDerivedEntityNumber = computed(() => {
            return state.productDerivedEntity?.currentContractNumber ?? ''
        })
        const prefillConsultantId = computed(() => {
            // Preselect consultant of logged-in user if it has active consulting settings and is in the contact's consultants list
            if (store.state.user.consultant.isActive && state.contact.consultants.findIndex(consultant => consultant.id === store.state.user.consultant.id) !== -1) {
                return store.state.user.consultant.id
            } else if (state.contact.activeConsultants.length) {
                // Preselect the first active consultant in the contact's consultants list
                return state.contact.activeConsultants[0].id
            }
            return null
        })

        // Wizard
        const wizard = new FormWizard([
            new FormWizardStep('product',{
                title: tc('views.contract.add-contract.select-product', 1),
                caption: productName,
                icon: 'mib-shipment-box',
                activeIcon: 'mib-shipment-box',
                doneIcon: 'mib-check',
                doneColor: 'secondary',
            }),
            new FormWizardStep('form', {
                title: tc('common.contract.contract-info', 2),
                caption: productDerivedEntityNumber,
                icon: 'mib-pencil-write-3-alternate',
                activeIcon: 'mib-pencil-write-3-alternate',
                doneIcon: 'mib-check',
                doneColor: 'secondary',
            }),
            new FormWizardStep('files', {
                title: tc('views.contract.add-contract.upload-files', 2),
                icon: 'mib-common-file-text-upload',
                activeIcon: 'mib-common-file-text-upload',
                doneIcon: 'mib-check',
            }),
        ])

        // Methods
        function getProduct ({ productId }) {
            Product.objects.get(productId).then(product => {
                state.product = product
                wizard.nextStep()
            })
        }

        function createProductDerivedEntity (formData, submitMode) {
            Contract.create({
                product: state.product,
                productFields: formData.productFields,
                contractNumber: formData.contractNumber,
                customerId: props.contactId,
                consultantId: formData.consultantId,
                startDate: formData.startDate,
                endDate: formData.endDate,
                premium: formData.premium,
                generalAgencyId: formData.generalAgencyId,
                managedByStatus: formData.managedByStatus,
                consultingMandateEnquirySendDate: formData.consultingMandateEnquirySendDate,
            }).then(contract => {
                switch (submitMode) {
                    case 'saveAndAddFiles':
                        state.productDerivedEntity = contract
                        wizard.nextStep()
                        // TODO: Add notification?
                        break
                    case 'saveAndAddAnother':
                        reset()
                        // TODO: Add notification?
                        break
                    default:
                        router.replace({ name: 'contract-detail', params: { contactId: props.contactId, id: contract.id } })
                }
            }).catch(error => {
                productDerivedEntityForm.value.formComponent.formBuilder.showFormErrorMessage(error)
                productDerivedEntityForm.value.formComponent.formBuilder.resetFormSubmitStatus()
            })
        }

        function reset () {
            state.product = null
            state.productDerivedEntity = null
            wizard.reset()
        }

        async function fetchContact () {
            state.contact = await Contact.objects.get(props.contactId)
        }
        async function fetchContracts () {
            const contracts = await fetchPaginatedObjects({ fn: ContractService.all }, { filterCustomerId: props.contactId }, `
                id
                commissionSplits {
                    id
                    is_active
                }
                commissionRecipientOverride {
                    id
                }
            `)

            let hasActiveCommissionSplits = false
            let hasCommissionRecipientOverrides = false
            for (const contract of contracts) {
                if (contract.commissionSplits.some(commissionSplit => commissionSplit.is_active)) hasActiveCommissionSplits = true
                if (contract.commissionRecipientOverride) hasCommissionRecipientOverrides = true
                if (hasActiveCommissionSplits && hasCommissionRecipientOverrides) break
            }

            state.hasActiveCommissionSplits = hasActiveCommissionSplits
            state.hasCommissionRecipientOverrides = hasCommissionRecipientOverrides
        }

        onBeforeMount(() => {
            fetchContact()
            fetchContracts()
        })

        return {
            // Static
            ConsultingFileTargetObjectType,
            ContractForm,

            // Data
            wizard,
            state,

            // Template refs
            productDerivedEntityForm,

            // Computed
            prefillConsultantId,

            // Methods
            getProduct,
            createProductDerivedEntity,
            reset,
        }
    },
}
</script>

<style lang="scss" scoped>
::v-deep .create-contract-wrapper {
    // Adjust buttons to height of input/select fields
    .q-btn {
        min-height: 40px;
    }

    // Adjust spacing of default file browser integration
    .file-browser-wrapper {
        margin-top: (-($sizeSpacingSm));
    }

    @include mq($from: $sizeBreakpointMd) {
        .q-stepper__step-inner {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
</style>
