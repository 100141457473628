<template>
    <!-- TODO improvement: Enhance default text color with `lightOrDark()` from `DevDesignColorPalette.vue`. -->
    <q-btn
        ref="qBtn"
        v-bind="$attrs"
        unelevated
        no-caps
        :color="buttonColor"
        :text-color="buttonTextColor"
        :class="{ 'primary-button': primaryButton, 'no-text-decoration': noTextDecoration, 'show-as-link': showAsLink }"
        v-on="$listeners"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" /><!-- Named slots -->
        <!-- TODO improvement @TFU: Check if this is needed. (QButton does not have any scoped slots according to the docs.) -->
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
            <slot :name="name" v-bind="slotData" />
        </template><!-- Scoped slots -->
    </q-btn>
</template>

<script>
export default {
    name: 'BaseButton',
    inheritAttrs: false,
    props: {
        textColor: {
            type: String,
        },
        primaryButton: {
            type: Boolean,
            default: false,
        },
        noTextDecoration: {
            type: Boolean,
            default: false,
        },
        showAsLink: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        focus () {
            this.$refs.qBtn.$el.focus()
        },
    },
    computed: {
        buttonColor () {
            if (this.$attrs.color !== undefined) {
                return this.$attrs.color
            } else {
                return this.primaryButton ? 'primary-action' : 'base-action'
            }
        },
        buttonTextColor () {
            if (this.textColor !== undefined) {
                return this.textColor
            } else {
                // flat or outline buttons
                if (this.$attrs.flat !== undefined || this.$attrs.outline !== undefined) {
                    if (this.$attrs.color !== undefined && this.$attrs.color !== 'none') {
                        return this.$attrs.color
                    } else {
                        return this.primaryButton ? 'text-primary' : 'primary'
                    }
                } else {
                    return this.primaryButton ? 'text-primary' : 'text-inverted'
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@mixin baseButtonHoverStyles(
    $color: var(--color-base-action-interaction),
) {
    &.q-hoverable {

        &:hover,
        &:focus,
        &:active {
            background-color: transparent; // Needed for cases where buttons have a `:to` property / are links (and thus inherit the styling of <a> anchors).

            & > ::v-deep .q-focus-helper {
                background-color: #{$color}; // background-color: currentColor;
            }
        }
    }
}

.q-btn {
    transition: color 0.5s 0s ease-in-out, background-color 0.5s 0s ease-in-out, text-decoration-color $defaultTransitionStyles;

    // Outline
    &.q-btn--outline {
        ::v-deep .q-btn__content {
            color: var(--color-text-primary);
        }
    }

    // Flat
    &.q-btn--flat:not(.no-text-decoration),
    &.q-btn--flat:not(.q-btn--round) {
        text-decoration: underline;
        text-decoration-color: currentColor;

        ::v-deep {
            .q-btn__content {
                color: var(--color-text-primary);
            }

            // Remove text-decoration from icon and re-align it properly.
            .max-icon {
                margin-top: -4px;

                &::before {
                    display: inline-block !important; // !important needed as Quasar already applies it.
                }
            }
        }

        &:hover,
        &:focus,
        &:active {
            text-decoration-color: transparent;
        }

        // Reset flat buttons in some cases
        &.no-text-decoration,
        &.q-btn--round {
            text-decoration: none;

            ::v-deep .q-btn__content {
                color: currentColor;
            }
        }
    }
}

// Special styles for primary buttons (main actions of a form/view)
.q-btn.primary-button {
    @include baseButtonHoverStyles($color: var(--color-primary-action-interaction, currentColor));

    // Outline
    &.q-btn--outline {
        ::v-deep .q-btn__wrapper:before {
            border: 1px solid var(--color-primary-action, currentColor); // border: 1px solid currentColor;
        }
    }

    // Flat
    &.q-btn--flat {
        text-decoration-color: var(--color-primary-action);

        &:hover,
        &:focus,
        &:active {
            text-decoration-color: transparent;
        }
    }
}

// Special styles for buttons that should look like text links
.q-btn.show-as-link.show-as-link {
    border-radius: 0;

    background-color: transparent !important; // !important needed due to Quasar defining the background color like this already

    font-size: inherit;
    color: var(--color-link);
    line-height: inherit;

    transition-duration: 0s;
    transition-delay: 0s;

    ::v-deep {
        .q-btn__wrapper.q-btn__wrapper {
            min-height: 0;
            padding: 0;

            &::before {
                border: 0; // Prevent `outline` prop borders
            }
        }

        .q-btn__content {
            color: var(--color-link);
            text-decoration: underline;
            text-decoration-color: var(--color-link-decoration);
        }
    }

    &:hover,
    &:focus,
    &:active {
        background-color: var(--color-link-action-background) !important; // !important needed due to Quasar defining the background color like this already

        // Prevent `primary-button` background-color
        & > ::v-deep .q-focus-helper {
            background-color: transparent;
        }

        ::v-deep {
            .q-btn__content {
                color: var(--color-link-action);
                text-decoration: none;
            }
        }
    }

    // Proper alignment if used within a `p` tag
    p & {
        display: inline-block;

        ::v-deep {
            .q-btn__wrapper {
                margin-top: -0.15em;
            }
        }
    }
}
</style>
