<template>
    <div>
        <q-dialog ref="dialog" @hide="reset">
            <q-card>
                <q-card-section class="row items-center">
                    <slot name="header"><h1>{{ $t('common.term.confirm--please-confirm') }}</h1></slot>
                    <slot name="subject" />
                    <q-toggle
                        v-if="doubleConfirm"
                        v-model="doubleConfirmValue"
                        :label="doubleConfirmLabel || $t(`forms.confirmation-toggle${doubleConfirmAltLabel ? '--alt' : ''}`)"
                    />
                </q-card-section>
                <q-card-actions align="right" class="q-gutter-sm">
                    <span @click="close">
                        <slot name="cancelButton">
                            <base-button
                                :label="$t('common.term.cancel')"
                                flat
                                primary-button
                            />
                        </slot>
                    </span>
                    <span @click="confirm">
                        <slot name="confirmButton">
                            <base-button
                                :label="$t('common.term.confirm')"
                                :disabled="doubleConfirm && !doubleConfirmValue"
                                primary-button
                            />
                        </slot>
                    </span>
                </q-card-actions>
            </q-card>
        </q-dialog>
        <span @click="open">
            <slot name="button">
                <base-button
                    v-if="label"
                    :label="label"
                />
            </slot>
        </span>
    </div>
</template>

<script>
export default {
    name: 'ConfirmationDialog',
    props: {
        doubleConfirm: {
            type: Boolean,
            default: false,
        },
        doubleConfirmAltLabel: {
            type: Boolean,
            default: false,
        },
        doubleConfirmLabel: {
            type: String,
            default: '',
        },
        bypassConfirm: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
        },
    },
    data () {
        return {
            doubleConfirmValue: false,
        }
    },
    methods: {
        reset () {
            this.doubleConfirmValue = false
        },
        open () {
            if (this.bypassConfirm) {
                this.$emit('confirm')
            } else {
                this.$refs.dialog.show()
            }
        },
        close () {
            this.$refs.dialog.hide()
            this.reset()
        },
        confirm () {
            if (this.doubleConfirm) {
                if (this.doubleConfirmValue) {
                    this.$emit('confirm')
                    this.close()
                }
            } else {
                this.$emit('confirm')
                this.close()
            }
        },
    },
}
</script>
