import gql from 'graphql-tag'

export const USER_CHANGE_PASSWORD_MUTATION = gql`
    mutation changePassword(
        $password: String!
        $new_password: String!
        $new_password_confirmation: String!
    ) {
        changePassword(
            password: $password
            new_password: $new_password
            new_password_confirmation: $new_password_confirmation
        ) {
            status
        }
    }
`
