import gql from 'graphql-tag'

export const USER_ASSIGN_PERSON_MUTATION = gql`
    mutation userAssignPerson(
        $input: userAssignPersonInput!
    ) {
        userAssignPerson(
            input: $input
        ) {
            id
            email
            new_email
            consultant {
                id
                contact_number
                first_name
                last_name
                gender
                title
                consultingCompany {
                    id
                    contact_number
                    company_name
                    consulting_settings_status
                }
                consulting_company_name
                consulting_settings_status
                created_at
                updated_at
            }
        }
    }
`
