<template>
    <div>
        <q-dialog ref="stagedFilesDialog" content-class="consulting-file-upload-staging-dialog" @hide="onHideDialog">
            <q-card>
                <q-card-section>
                    <h2>{{ $tc('common.file.upload-file', stagedFiles.length) }}</h2>

                    <div v-if="includesContactTargetObjectType" class="q-mb-md">
                        <p class="q-mb-sm">{{ $tc('common.consulting-file.add-file--please-select-consultant--temp-component-interpolation-count', stagedFiles.length) }}</p>
                        <consultant-select
                            ref="consultantSelect"
                            v-model="$v.consultantId.$model"
                            :error="$v.consultantId.$error"
                            :label="$tc('common.contact.consultant', 1)"
                            :inject-consultants="targetObjectConsultants"
                            @fetchedObjects="prefillConsultantId"
                        >
                            <template v-slot:error>
                                <div v-show="!$v.consultantId.required" data-test="error:consultant-id-required">{{ $t('common.error-message.required', { field: $tc('common.contact.consultant', 1) }) }}</div>
                            </template>
                        </consultant-select>
                    </div>

                    <p class="q-mb-sm">{{ $tc('common.file.add-file--please-choose-type', stagedFiles.length) }}</p>

                    <q-list v-if="stagedFiles.length" class="file-list">
                        <q-item
                            v-for="(fileUploadWrapper, index) in stagedFiles"
                            :key="fileUploadWrapper.id"
                            class="file-item"
                        >
                            <q-item-section top class="file-item-info">
                                <q-item-label>
                                    <div class="q-mb-xs">
                                        <q-icon :name="getFileTypeIcon(fileUploadWrapper.file.type)" size="xs" class="file-item-icon" />
                                        <b>{{ fileUploadWrapper.file.name }}</b>
                                        <base-button
                                            round
                                            color="none"
                                            text-color="primary"
                                            icon="mib-bin"
                                            size="xs"
                                            class="remove-item-trigger"
                                            @click="removeFile(fileUploadWrapper)"
                                        >
                                            <q-tooltip
                                                :delay="1000"
                                                anchor="center right"
                                                self="center left"
                                                :offset="[10, 0]"
                                                transition-show="scale"
                                                transition-hide="scale"
                                            >
                                                {{ $t('common.term.remove') }}
                                            </q-tooltip>
                                        </base-button>
                                    </div>
                                    <base-select-filter
                                        v-model="$v.stagedFiles.$each[index].$model.fileTypeId"
                                        :error="$v.stagedFiles.$each[index].$error"
                                        :options="fileTypes[fileUploadWrapper.targetObjectType]"
                                        option-value="id"
                                        option-label="name"
                                        dense
                                    >
                                        <template v-slot:error>
                                            <div v-show="!$v.stagedFiles.$each[index].required" data-test="error:file-type-required">{{ $t('common.error-message.required', { field: $tc('common.document.document-type', 1) }) }}</div>
                                        </template>
                                    </base-select-filter>
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-card-section>

                <q-card-actions align="right">
                    <base-button
                        v-close-popup
                        :label="$t('common.term.cancel')"
                        flat
                        primary-button
                    />
                    <base-button
                        data-test="btn:confirm"
                        :label="$tc('common.file.upload-n-files', stagedFiles.length)"
                        primary-button
                        @click="triggerUpload"
                    />
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { EventBus } from '@/event-bus'
import { ConsultingFileTargetObjectType } from '@/enums/graphql'
import { ConsultingFileService } from '@/services'
import { getFileTypeIcon } from '@/helpers/file'
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import ConsultantSelect from '@/components/contact/ConsultantSelect'

export default {
    name: 'ConsultingFileUploadStagingDialog',
    components: {
        BaseSelectFilter,
        ConsultantSelect,
    },
    data () {
        return {
            fileTypes: {},
            consultantId: null,
            targetObjectConsultants: [],
            stagedFiles: [],
        }
    },
    computed: {
        includesContactTargetObjectType () {
            return this.stagedFiles.some(stagedFile => stagedFile.targetObjectType === ConsultingFileTargetObjectType.CONTACT)
        },
    },
    validations () {
        const validations = {
            stagedFiles: {
                $each: {
                    fileTypeId: {
                        required,
                    },
                },
            },
        }
        if (this.includesContactTargetObjectType) {
            validations.consultantId = {
                required,
            }
        }
        return validations
    },
    created () {
        EventBus.$on('consultingFileUpload:addToStaging', this.addFiles)
    },
    beforeDestroy () {
        EventBus.$off('consultingFileUpload:addToStaging', this.addFiles)
    },
    methods: {
        prefillConsultantId (consultants) {
            this.consultantId = null
            if (consultants.length) {
                if (this.targetObjectConsultants.length) {
                    // Preselect consultant of logged-in user if it has active consulting settings and is in the target object's consultants list
                    if (this.$store.state.user.consultant.isActive && this.targetObjectConsultants.findIndex(targetObjectConsultant => targetObjectConsultant.id === this.$store.state.user.consultant.id) !== -1) {
                        this.consultantId = this.$store.state.user.consultant.id
                    } else {
                        // Preselect the first active consultant in the target object's consultants list
                        const activeConsultant = this.targetObjectConsultants.find(targetObjectConsultant => targetObjectConsultant.isActive)
                        if (activeConsultant) this.consultantId = activeConsultant.id
                    }
                }
            }
        },
        loadFileTypes (targetObjectType) {
            switch (targetObjectType) {
                case ConsultingFileTargetObjectType.CONTACT:
                    return ConsultingFileService.getContactFileTypes().then(contactFileTypes => {
                        this.fileTypes[ConsultingFileTargetObjectType.CONTACT] = contactFileTypes
                        return contactFileTypes
                    })
                case ConsultingFileTargetObjectType.APPLICATION:
                    return ConsultingFileService.getApplicationFileTypes().then(applicationFileTypes => {
                        this.fileTypes[ConsultingFileTargetObjectType.APPLICATION] = applicationFileTypes
                        return applicationFileTypes
                    })
                case ConsultingFileTargetObjectType.CONTRACT:
                    return ConsultingFileService.getContractFileTypes().then(contractFileTypes => {
                        this.fileTypes[ConsultingFileTargetObjectType.CONTRACT] = contractFileTypes
                        return contractFileTypes
                    })
            }
        },
        getFileTypeName (targetObjectType, fileTypeId) {
            const fileType = this.fileTypes[targetObjectType].find(fileType => fileType.id === fileTypeId)
            return (fileType) ? fileType.name : ''
        },
        getFileTypeIcon (...args) {
            return getFileTypeIcon(...args)
        },
        async addFiles (fileUploadWrappers) {
            this.targetObjectConsultants = []
            const stagedFiles = []
            for (const fileUploadWrapper of fileUploadWrappers) {
                if (this.stagedFiles.every(item => item.id !== fileUploadWrapper.id)) {
                    if (!this.fileTypes[fileUploadWrapper.targetObjectType]) {
                        await this.loadFileTypes(fileUploadWrapper.targetObjectType)
                    }
                    stagedFiles.push(fileUploadWrapper)
                    if (!this.targetObjectConsultants.length && fileUploadWrapper.targetObjectConsultants) this.targetObjectConsultants.push(...fileUploadWrapper.targetObjectConsultants)
                }
            }
            this.stagedFiles.push(...stagedFiles)
            this.$v.$reset()
            this.$refs.stagedFilesDialog.show()
        },
        removeFile (fileUploadWrapper) {
            const index = this.stagedFiles.findIndex(stagedFile => stagedFile.id === fileUploadWrapper.id)
            this.stagedFiles.splice(index, 1)
            if (this.stagedFiles.length === 0) this.$refs.stagedFilesDialog.hide()
        },
        clearFiles () {
            this.stagedFiles = []
        },
        onHideDialog () {
            this.clearFiles()
        },
        triggerUpload () {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                EventBus.$emit('fileUploadManager:addToUploadQueue', this.stagedFiles.map(stagedFile => {
                    stagedFile.fileTypeName = this.getFileTypeName(stagedFile.targetObjectType, stagedFile.fileTypeId)
                    if (this.consultantId) stagedFile.consultantId = this.consultantId
                    return stagedFile
                }))
                this.clearFiles()
                this.$refs.stagedFilesDialog.hide()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.file-item {
    padding: 0;
    margin-top: $sizeSpacingMd;
    min-height: auto;

    &:last-child {
        padding-bottom: 0;
    }

    &-icon {
        padding-right: $sizeSpacingXs;
    }

    &-info {
        padding-top: 2px;
    }
}

::v-deep .remove-item-trigger {
    margin: -4px 0 -2px 0;
}
</style>
