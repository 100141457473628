<template>
    <q-dialog
        ref="dialog"
        v-bind="$attrs"
        position="top"
        @show="onShow"
        @hide="onHide"
    >
        <q-card v-if="commissionListEntry" class="overlay-size-md">
            <q-card-section>
                <h1>{{ overlayTitle }}</h1>

                <q-table
                    :columns="tableColumns"
                    :data="tableData"
                    :pagination="{ rowsPerPage: 0 }"
                    hide-pagination
                    row-key="id"
                    class="base-table matched-contract-table"
                    flat
                    square
                    dense
                >
                    <template v-slot:body-cell-source="slotProps">
                        <q-td :props="slotProps">
                            <!-- Show different text if there already is a matched contract but it’s not this one -->
                            <template v-if="slotProps.row.source !== 'import' && commissionListEntry.matchedContract && commissionListEntry.matchedContract.id !== slotProps.row.contract.id">
                                {{ $t(`views.commission.commission-list.matched-contract.source--${slotProps.row.source}--other`) }}
                            </template>
                            <template v-else>
                                {{ $t(`views.commission.commission-list.matched-contract.source--${slotProps.row.source}`) }}
                            </template>
                        </q-td>
                    </template>

                    <!-- TODO improvement @MTR: Add the same/similar highlighting of contract number and customer name as in the CommissionListDetail list -->
                    <template v-slot:body-cell-product_provider="slotProps">
                        <!-- TODO @TFU: Adapt slotProps.row.source comparison values if source should be extracted to an enum -->
                        <q-td :props="slotProps">
                            <span v-if="slotProps.row.source === 'import'">
                                {{ commissionListProvider.display_name || commissionListProvider.name }}
                            </span>

                            <span v-else :class="productProviderClasses(slotProps.row)">
                                {{ slotProps.row.contract.currentContractInformation.product.basicProvider.display_name || slotProps.row.contract.currentContractInformation.product.basicProvider.name }}
                                <info-icon
                                    v-if="slotProps.row.contract && slotProps.row.contract.currentContractInformation.product.basicProvider.id !== commissionListProvider.id"
                                    name="mib-alert-triangle"
                                    :title="`${$t('common.term.attention')}!`"
                                    :text="$tc('views.commission.commission-list.matched-contract.other-provider', 1, { listProvider: commissionListProvider.display_name || commissionListProvider.name, otherProvider: slotProps.row.contract.currentContractInformation.product.basicProvider.display_name || slotProps.row.contract.currentContractInformation.product.basicProvider.name })"
                                    color="negative-light"
                                />
                            </span>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-customer_name="slotProps">
                        <q-td :props="slotProps">
                            {{ slotProps.row.contract.customer.getContactName({ commaSeparated: true }) }}
                            <span v-if="slotProps.row.contract.customer.contactNumber" class="additional-info">({{ slotProps.row.contract.customer.contactNumber.number }})</span>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-actions="slotProps">
                        <q-td auto-width>
                            <template v-if="slotProps.row.source !== 'import' && mode === 'update'">
                                <template v-if="commissionListEntry.matchedContract && commissionListEntry.matchedContract.id === slotProps.row.contract.id">
                                    <q-btn
                                        color="primary"
                                        size="sm"
                                        flat
                                        round
                                        icon="mib-undo"
                                        :disabled="!enableSelectContractButton(slotProps.row)"
                                        @click="resetContract"
                                    />
                                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $t('views.commission.commission-list.matched-contract.reassign-to-contract') }}</q-tooltip>
                                </template>

                                <template v-else>
                                    <q-btn
                                        color="primary"
                                        size="sm"
                                        flat
                                        round
                                        icon="mib-check"
                                        :disabled="!enableSelectContractButton(slotProps.row)"
                                        @click="selectContract(slotProps.row.contract)"
                                    />
                                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $t('views.commission.commission-list.matched-contract.assign-to-contract') }}</q-tooltip>
                                </template>
                            </template>
                        </q-td>
                    </template>
                </q-table>
            </q-card-section>

            <q-card-section v-if="mode === 'update'">
                <h4>{{ $tc('views.commission.commission-list.matched-contract.search-contract', 1) }} <info-icon :text="$t('views.commission.commission-list.matched-contract.search-contract--info')" /></h4>
                <fetch-contracts-search :enabled-result-types="['Contract']" @open-search-result="selectContract" />
            </q-card-section>

            <q-card-section v-if="mode === 'confirm'">
                <info-box>
                    <i18n path="views.commission.commission-list.matched-contract.selected-contract--confirm" tag="p">
                        <template v-slot:contract><b>{{ commissionListEntry.matchedContract.currentContractNumber }}</b></template>
                        <template v-slot:customerName><b>{{ commissionListEntry.matchedContract.customer.getContactName({ contactNumber: true }) }}</b></template>
                    </i18n>
                </info-box>
            </q-card-section>

            <q-card-section v-if="selectedContract">
                <info-box :type="isCommissionListProvider(selectedContract.currentContractInformation.product.basicProvider.id) ? 'info' : 'warning'">
                    <dl>
                        <dt>{{ $t('views.commission.commission-list.matched-contract.selected-contract') }}: </dt>
                        <dd>
                            {{ selectedContract.currentContractNumber }}
                            <span :class="[{ 'text-negative': !isCommissionListProvider(selectedContract.currentContractInformation.product.basicProvider.id) }]">
                                ({{ selectedContract.currentContractInformation.product.basicProvider.display_name || selectedContract.currentContractInformation.product.basicProvider.name }}<info-icon
                                    v-if="!isCommissionListProvider(selectedContract.currentContractInformation.product.basicProvider.id)"
                                    name="mib-alert-triangle"
                                    class="q-ml-xs"
                                    :title="`${$t('common.term.attention')}!`"
                                    :text="$tc('views.commission.commission-list.matched-contract.other-provider', 1, { listProvider: commissionListProvider.display_name || commissionListProvider.name, otherProvider: selectedContract.currentContractInformation.product.basicProvider.display_name || selectedContract.currentContractInformation.product.basicProvider.name })"
                                    color="negative-light"
                                />)
                            </span>
                        </dd>
                    </dl>
                    <i18n path="views.commission.commission-list.matched-contract.selected-contract--confirm" tag="p">
                        <template v-slot:contract><b>{{ selectedContract.currentContractNumber }}</b></template>
                        <template v-slot:customerName><b>{{ selectedContract.customer.getContactName({ contactNumber: true }) }}</b></template>
                    </i18n>
                </info-box>
            </q-card-section>

            <q-card-actions align="right" class="q-gutter-sm">
                <base-button
                    :label="$t('common.term.cancel')"
                    flat
                    primary-button
                    @click="close"
                />
                <base-button
                    data-test="btn:confirm"
                    :label="mode === 'update' ? $t('common.term.save') : $t('common.term.confirm')"
                    :disable="!selectedContract && mode === 'update'"
                    primary-button
                    @click="confirm"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { kebabCase } from 'lodash'
import { CommissionListEntry } from '@/models/models'
import { CommissionListEntryNextStep } from '@/enums'
import FetchContractsSearch from '@/components/search/FetchContractsSearch'

export default {
    name: 'CommissionListEntryMatchedContractUpdateOverlay',
    components: {
        FetchContractsSearch,
    },
    props: {
        commissionListProvider: {
            type: Object,
            default: null,
        },
        commissionListEntry: {
            type: Object,
            default: null,
        },
        mode: {
            type: String,
            default: 'update', // TODO @TFU: Extract to ENUM?
        },
    },
    data () {
        const data = {
            CommissionListEntryNextStep,
            CommissionListEntry,
            selectedContract: null,
            tableColumns: [
                {
                    name: 'source',
                    label: this.$tc('common.term.source', 1),
                    field: row => this.$t(`views.commission.commission-list.matched-contract.source--${row.source}`), // TODO @MTR: alternative wording when confirming
                    align: 'left',
                    classes: row => (row.source === 'matched') ? 'source text-bold' : 'source',
                },
                {
                    name: 'contract_number',
                    label: this.$tc('common.contract.contract-number', 1),
                    field: row => row.contract.currentContractNumber,
                    align: 'left',
                    classes: row => (row.source === 'matched') ? 'text-bold' : '',
                },
                {
                    name: 'product_provider',
                    label: this.$tc('common.product-provider.product-provider', 1),
                    align: 'left',
                },
                {
                    name: 'customer_name',
                    label: this.$tc('common.term.customer', 1),
                    align: 'left',
                    classes: row => (row.source === 'matched') ? 'text-bold' : '',
                },
                {
                    name: 'actions',
                    label: this.$tc('common.term.action', 2),
                    align: 'left',
                },
            ],
            tableData: [],
        }

        return data
    },
    computed: {
        overlayTitle () {
            if (this.commissionListEntry.nextStep === CommissionListEntryNextStep.CONFIRM_SELECTED_CONTRACT && this.mode === 'update') {
                return this.$t(`views.commission.commission-list.entry.next-step--${kebabCase(CommissionListEntryNextStep.SELECT_CONTRACT)}`)
            }
            return this.$t(`views.commission.commission-list.entry.next-step--${kebabCase(this.commissionListEntry.nextStep)}`)
        },
    },
    watch: {
        commissionListEntry (newValue) {
            const tableData = []

            if (newValue) {
                // Import
                tableData.push({
                    id: 'import',
                    source: 'import', // TODO @TFU: Extract to ENUM?
                    contract: {
                        currentContractNumber: this.commissionListEntry.contract_number,
                        customer: {
                            getContactName: () => {
                                return this.commissionListEntry.customerName
                            },
                        },
                    },
                })

                // Matched contract
                if (this.commissionListEntry.matchedContract) {
                    tableData.push({
                        id: `matched`,
                        source: 'matched', // TODO @TFU: Extract to ENUM?
                        contract: this.commissionListEntry.matchedContract,
                    })
                }

                // Shortlist
                if (this.commissionListEntry.contractMatchShortlist.length > 1) {
                    this.commissionListEntry.contractMatchShortlist.forEach(contractMatch => {
                        if (this.commissionListEntry.matchedContract && this.commissionListEntry.matchedContract.id === contractMatch.contract.id) return
                        tableData.push({
                            id: `shortlist-${contractMatch.contract.id}`,
                            source: 'shortlist', // TODO @TFU: Extract to ENUM?
                            contract: contractMatch.contract,
                        })
                    })
                }
            }

            this.tableData = tableData
        },
    },
    methods: {
        open () {
            this.selectedContract = null
            this.$refs.dialog.show()
        },
        close () {
            this.$refs.dialog.hide()
            this.selectedContract = null
        },
        onShow () {
            this.$emit('show')
        },
        onHide () {
            this.$emit('hide')
        },
        enableSelectContractButton (row) {
            if (!this.selectedContract && this.commissionListEntry.matchedContract?.id === row.contract.id) return false
            if (row.contract.id === this.selectedContract?.id) return false
            return true
        },
        selectContract (contract) {
            this.selectedContract = contract
        },
        resetContract () {
            this.selectedContract = null
        },
        confirm () {
            const contract = this.mode === 'confirm' ? this.commissionListEntry.matchedContract : this.selectedContract // TODO @TFU: Extract to ENUM?
            this.commissionListEntry.updateMatchedContract(contract.id)
                .then(commissionListEntry => {
                    // TODO: Notification?
                    this.close()
                })
                .catch(error => {
                    // TODO: Handle error
                    console.log('TODO: Handle error', error)
                })
        },
        productProviderClasses (entry) {
            const classes = []

            // TODO @TFU: Adapt entry.source comparison values if source should be extracted to an enum -->
            if (entry.source === 'matched') {
                classes.push('text-bold')
            }

            if (!this.isCommissionListProvider(entry.contract?.currentContractInformation?.product?.basicProvider?.id)) {
                classes.push('text-negative')
            }

            return classes.join(' ')
        },
        isCommissionListProvider(id) {
            return id === this.commissionListProvider.id
        },
        kebabCase (...args) {
            return kebabCase(...args)
        },
    },
}
</script>

<style lang="scss" scoped>
.matched-contract-table.matched-contract-table {
    ::v-deep tr:first-of-type {
        td {
            border-bottom: 1px solid var(--q-color-secondary-dark);
            background-color: var(--color-background-tertiary);

            &.source {
                font-style: italic;
            }
        }
    }

    td {
        height: 36px; // Adapt to height of rows with small round buttons
    }
}
</style>
