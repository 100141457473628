<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('views.dev.roles-configurator')" :to="{ name: 'dev-roles-configurator' }" />
            </template>

            <h1 class="q-pt-md">{{ $t('views.dev.roles-configurator') }}</h1>
        </page-header>

        <div class="row">
            <div class="col-12">
                <q-tabs
                    v-model="currentPermissionsTab"
                    dense
                    no-caps
                    inline-label
                    align="left"
                    class="q-mb-sm bg-background-secondary"
                    indicator-color="accent"
                >
                    <q-tab
                        v-for="permissionType in permissionTypes"
                        :key="`${permissionType}-tab`"
                        :name="permissionType"
                        :label="permissionType"
                    />
                </q-tabs>

                <q-tab-panels
                    v-model="currentPermissionsTab"
                    keep-alive
                    animated
                    transition-prev="fade"
                    transition-next="fade"
                >
                    <q-tab-panel
                        v-for="permissionType in permissionTypes"
                        :key="`${permissionType}-tab-panel`"
                        :name="permissionType"
                        class="permission-type q-pa-none"
                    >
                        <q-table
                            :data="permissions[permissionType]"
                            :columns="columns"
                            :pagination.sync="pagination"
                            :rows-per-page-options="[]"
                            row-key="id"
                            class="permissions-table"
                            flat
                            square
                            dense
                        >
                            <template v-slot:header-cell="props">
                                <q-th :props="props">
                                    <div class="table-th-content">
                                        {{ props.col.label }}
                                        <q-tooltip
                                            anchor="top middle"
                                            self="center middle"
                                            :delay="250"
                                            max-width="20rem"
                                        >
                                            <b>{{ props.col.label }}</b><br>{{ props.col.description }}
                                        </q-tooltip>
                                    </div>
                                </q-th>
                            </template>

                            <template v-slot:body-cell="props">
                                <q-td :props="props">
                                    <template v-if="props.col.name === 'permission'">{{ props.row.key }}</template>
                                    <template v-else-if="props.col.name === 'description'">
                                        <!-- TODO: Add separate column with the name of the imported permissions (group, e.g. "Contact" – might be done using the imported filename play around with it) or at least for the entity -->
                                        <!-- TODO: Make columns (including checkboxes) sortable -->
                                        {{ props.row.name[locale] }}
                                        <q-icon name="mib-information-circle" color="secondary-light">
                                            <q-tooltip
                                                anchor="center right"
                                                self="center left"
                                                :delay="250"
                                                max-width="20rem"
                                            >
                                                <b>{{ props.row.name[locale] }}</b><br>{{ props.row.description[locale] }}
                                            </q-tooltip>
                                        </q-icon>
                                    </template>
                                    <base-checkbox v-else v-model="formData[props.col.name][props.row.key]" dense />
                                </q-td>
                            </template>
                        </q-table>
                    </q-tab-panel>
                </q-tab-panels>
            </div>
        </div>

        <in-page-footer>
            <base-button :label="$t('common.term.copy-to-clipboard')" icon="mib-copy-paste" @click="onGenerate" />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import BaseCheckbox from '@/components/form/BaseCheckbox'

import ROLES from '#/apollo-server/graphql/Users/roles.seeder.json'
import ACCOUNTING_PERMISSIONS from '#/apollo-server/graphql/Accounting/accountingPermissions.seeder.json'
import COMMENT_PERMISSIONS from '#/apollo-server/graphql/Comments/commentPermissions.seeder.json'
import COMMISSION_PERMISSIONS from '#/apollo-server/graphql/Commissions/commissionPermissions.seeder.json'
import CONTACT_PERMISSIONS from '#/apollo-server/graphql/Contacts/contactPermissions.seeder.json'
import APPLICATION_PERMISSIONS from '#/apollo-server/graphql/Contracts/applicationPermissions.seeder.json'
import CONTRACT_PERMISSIONS from '#/apollo-server/graphql/Contracts/contractPermissions.seeder.json'
import CORRESPONDENCE_PERMISSIONS from '#/apollo-server/graphql/Correspondence/correspondencePermissions.seeder.json'
import DOCUMENT_PERMISSIONS from '#/apollo-server/graphql/Documents/documentPermissions.seeder.json'
import ECOHUB_PERMISSIONS from '#/apollo-server/graphql/EcoHub/ecoHubPermissions.seeder.json'
import FILE_PERMISSIONS from '#/apollo-server/graphql/Files/filePermissions.seeder.json'
import MAIL_PROCESSING_PERMISSIONS from '#/apollo-server/graphql/MailProcessing/mailProcessingPermissions.seeder.json'
import PRODUCT_PERMISSIONS from '#/apollo-server/graphql/Products/productPermissions.seeder.json'
import TASK_PERMISSIONS from '#/apollo-server/graphql/Tasks/taskPermissions.seeder.json'
import USER_PERMISSIONS from '#/apollo-server/graphql/Users/userPermissions.seeder.json'

import { copyToClipboard } from 'quasar'

export default {
    name: 'DevRolesConfigurator',
    meta () {
        return {
            title: this.$t('views.dev.roles-configurator'),
        }
    },
    components: {
        BaseCheckbox,
    },
    data () {
        const data = {
            currentPermissionsTab: 'user',
            locale: localStorage.locale,
            columns: [
                { name: 'permission', label: this.$tc('common.term.permission', 1), align: 'left' },
                { name: 'description', label: this.$tc('common.term.description', 1), align: 'left' },
            ],
            pagination: {
                rowsPerPage: 0,
            },
            permissions: {
                user: USER_PERMISSIONS,
                product: PRODUCT_PERMISSIONS,
                contact: CONTACT_PERMISSIONS,
                application: APPLICATION_PERMISSIONS,
                contract: CONTRACT_PERMISSIONS,
                document: DOCUMENT_PERMISSIONS,
                comment: COMMENT_PERMISSIONS,
                correspondence: CORRESPONDENCE_PERMISSIONS,
                task: TASK_PERMISSIONS,
                accounting: ACCOUNTING_PERMISSIONS,
                commission: COMMISSION_PERMISSIONS,
                file: FILE_PERMISSIONS,
                mailProcessing: MAIL_PROCESSING_PERMISSIONS,
                ecoHub: ECOHUB_PERMISSIONS,
            },
            formData: {},
        }

        ROLES.forEach(role => {
            // Create table columns
            data.columns.push({
                name: role.key,
                label: role.name[localStorage.locale],
                description: role.description[localStorage.locale],
                align: 'center',
            })

            // Prepare reactive properties for form data
            data.formData[role.key] = {}
            Object.values(data.permissions).forEach(permissions => {
                permissions.forEach(permission => {
                    data.formData[role.key][permission.key] = (role.permissions.includes(permission.key))
                })
            })
        })

        return data
    },
    computed: {
        permissionTypes () {
            return Object.keys(this.permissions)
        },
    },
    methods: {
        onGenerate () {
            ROLES.forEach(role => {
                const permissionsInRole = []
                Object.keys(this.formData[role.key]).forEach(permission => {
                    if (this.formData[role.key][permission]) permissionsInRole.push(permission)
                })
                role.permissions = permissionsInRole
            })

            copyToClipboard(JSON.stringify(ROLES, null, 4))
                .then(() => {
                    this.$q.notify({
                        type: 'positive',
                        message: this.$t('common.notifications.copied-to-clipboard-success'),
                    })
                })
                .catch(() => {
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$t('common.notifications.copied-to-clipboard-error'),
                    })
                })
        },
        clearFilter () {
            this.filter = ''
        },
        translationCount (permissionType) {
            return (['correspondence'].includes(permissionType)) ? 1 : 2
        },
    },
}
</script>

<style lang="scss" scoped>
// Quick and dirty copy-paste solution form the Quasar docs (with some minor tweaks)
// PLEASE NOTE: This CSS results in a somewhat laggy hover effect (when hovering over a checkbox and moving the cursor up/down over multiple lines).
// Also, it very likely isn’t really performant. It must therefore only be used in this special DEV ONLY case.
::v-deep .permissions-table {
    max-width: 100%;

    .q-table__middle {
        max-height: calc(100vh - 335px);
    }

    tr {
        th {
            position: sticky;
            z-index: 20;
            border-right: 1px solid;
            border-color: var(--color-border-secondary);
            background: var(--color-background-primary);

            &:last-child {
                border-right: none;
            }

            &:nth-child(1n+3) {
                $sizeThHeight: 10em;
                width: 4em;
                height: $sizeThHeight;
                vertical-align: bottom;

                .table-th-content {
                    position: absolute;
                    left: 50%;
                    width: $sizeThHeight;
                    height: auto;
                    padding: 0.5em;
                    transform: rotate(-90deg) translateY(-50%);
                    transform-origin: top left;
                    text-align: left;
                    word-break: break-word;
                    white-space: normal;
                }
            }
        }

        &:first-child {
            th {
                &:first-child {
                    position: sticky;
                    left: 0;
                    z-index: 30;
                }
            }
        }

        &:nth-child(2n+1) {
            td {
                &:first-child {
                    background-color: var(--color-background-secondary) !important;
                }
            }
        }

        &:nth-child(2n+2) {
            td {
                &:first-child {
                    background-color: var(--color-background-primary) !important;
                }
            }
        }

        &:hover {
            td {
                &:first-child {
                    background-color: var(--color-background-tertiary) !important;
                }
            }
        }
    }

    td {
        border-right: 1px solid;
        border-color: var(--color-border-secondary);

        &:first-child {
            z-index: 10;
            position: sticky;
            left: 0;
        }

        &:last-child {
            border-right: none;
        }
    }

    thead {
        tr {
            &:last-child {
                th {
                    top: 48px;
                    z-index: 30;
                }
            }

            &:first-child {
                th {
                    top: 0;
                    z-index: 10;
                }
            }
        }
    }
}
</style>
