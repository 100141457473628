<template>
    <node-view-wrapper :class="['structure-block', { 'selected': selected }]" data-structure-block="metadata">
        <span class="fake-field-label" contenteditable="false">{{ $tc('common.editor.structure-block.metadata.metadata', 2) }}</span>

        <div class="row">
            <div class="col-xs-10 col-sm-11 q-pl-sm">
                <!-- TODO @TFU: Add `.has-focus` class to node-view-content element when it is active (document.activeElement)  -->
                <node-view-content
                    :class="['structure-block-content', { 'is-empty': !hasContent }]"
                    :data-placeholder="$t('common.editor.structure-block.metadata.placeholder')"
                />
            </div>

            <div class="col-xs-2 col-sm-1 q-pr-xs q-pl-xs text-right self-center" contenteditable="false">
                <q-btn
                    color="negative"
                    size="xs"
                    flat
                    round
                    icon="mib-bin"
                    @click="$refs.removeDialog.show()"
                >
                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.editor.structure-block.metadata.remove-metadata-block', 1) }}</q-tooltip>
                </q-btn>

                <!-- q-dialog used directly because of FormDialog not working inside of a NodeView. -->
                <q-dialog ref="removeDialog">
                    <q-card>
                        <q-card-section>
                            <h1 class="text-h3">{{ $tc('common.editor.structure-block.metadata.remove-metadata-block', 1) }}</h1>
                            <p>{{ $tc('common.editor.structure-block.metadata.remove-metadata-block--confirm', 1) }}</p>
                        </q-card-section>
                        <q-card-actions align="right" class="q-gutter-sm">
                            <base-button
                                :label="$t('common.term.cancel')"
                                primary-button
                                flat
                                @click="$refs.removeDialog.hide()"
                            />
                            <base-button
                                type="submit"
                                :label="$t('common.term.confirm')"
                                data-test="btn:submit"
                                primary-button
                                @click="deleteNode()"
                            />
                        </q-card-actions>
                    </q-card>
                </q-dialog>
            </div>
        </div>

    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-2'

export default {
    name: 'StructureBlockMetadataNodeView',
    components: {
        NodeViewWrapper,
        NodeViewContent,
    },
    props: nodeViewProps,
    computed: {
        hasContent () {
            return this.node.content.size > 2 // As the structureBlockContent extension will always force an empty paragraph to exist, the threshold we use to check if it has content is actually 2.
        },
        schemaType () {
            return this.editor.schema.spec.topNode
        },
    },
}
</script>
