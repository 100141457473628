import gql from 'graphql-tag'

export const DOCUMENT_TEMPLATE_CREATE_MUTATION = gql`
    mutation documentTemplateCreate(
        $ownerId: ID!
        $documentTemplate: DocumentTemplateInput!
    ) {
        documentTemplateCreate(
            owner_id: $ownerId
            documentTemplate: $documentTemplate
        ) {
            id
                owner {
                    ... on Consultant {
                        id
                        first_name
                        last_name
                    }
                    ... on ConsultingCompany {
                        id
                        company_name
                    }
                }
                available_for_subordinate_companies
                name
                description
                type
                supported_target_object_types
                language {
                    id
                    name
                }
                confidentiality_notice
                differentiate_formality
                contents {
                    id
                    formality
                    content {
                        schemaType
                        schemaVersion
                        contentJSON
                        contentHTML
                        attrs
                    }
                }
                created_at
                updated_at
        }
    }
`
