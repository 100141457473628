import { apolloClient } from '@/apollo/client'

import { COMMENTS_QUERY } from '@/graphql/comment/comments'
import { COMMENT_CREATE_MUTATION } from '@/graphql/comment/commentCreate'
import { COMMENT_UPDATE_MUTATION } from '@/graphql/comment/commentUpdate'
import { COMMENT_DELETE_MUTATION } from '@/graphql/comment/commentDelete'

export const CommentService = {
    all (variables) {
        return apolloClient.query({
            query: COMMENTS_QUERY,
            variables,
        }).then(response => response.data.comments)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: COMMENT_CREATE_MUTATION,
            variables,
        }).then(response => response.data.commentCreate)
    },
    update (id, commentVariables) {
        return apolloClient.mutate({
            mutation: COMMENT_UPDATE_MUTATION,
            variables: {
                id: id,
                comment: commentVariables,
            },
        }).then(response => response.data.commentUpdate)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: COMMENT_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.commentDelete)
    },
}
