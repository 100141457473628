import gql from 'graphql-tag'

export const COMMISSION_REVENUE_REPORTS_CREATE_MUTATION = gql`
    mutation commissionRevenueReportsCreate(
        $consultingEntityIds: [ID!]!
        $dateFrom: Date!
        $dateUntil: Date!
    ) {
        commissionRevenueReportsCreate(
            consulting_entity_ids: $consultingEntityIds
            date_from: $dateFrom
            date_until: $dateUntil
        ) {
            status
        }
    }
`
