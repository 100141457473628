<template>
    <div class="q-gutter-md">
        <markup-table
            v-if="containsCommissionSplits || createFormIsVisible"
            :columns="columns"
            :data="commissionSplits"
            dense
        >
            <template v-if="containsCommissionSplits" v-slot:body-row="slotProps">
                <markup-table-row v-if="!slotProps.row.editMode" :columns="columns" :row="slotProps.row">
                    <template v-slot:body-cell-is_active class="status">
                        <status-badge :status="slotProps.row.model.is_active ? 'active' : 'inactive'" />
                    </template>

                    <template v-slot:body-cell-actions class="actions text-right">
                        <q-btn
                            color="primary"
                            size="sm"
                            flat
                            round
                            icon="mib-pencil"
                            @click="toggleUpdateFormVisibility(slotProps.row, slotProps.rowIndex)"
                        >
                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $t('common.term.update-action') }}</q-tooltip>
                        </q-btn>
                        <q-btn
                            color="negative"
                            size="sm"
                            flat
                            round
                            icon="mib-bin"
                            @click="deleteCommissionSplit(slotProps.row, $event)"
                        >
                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $t('common.term.delete') }}</q-tooltip>
                        </q-btn>
                    </template>
                </markup-table-row>
                <markup-table-row v-show="slotProps.row.editMode">
                    <commission-split-form
                        :ref="`updateForm-${slotProps.rowIndex}`"
                        :commission-split="slotProps.row.model"
                        @submit="formData => handleUpdate(slotProps.row.model, formData, slotProps.rowIndex)"
                        @cancel="toggleUpdateFormVisibility(slotProps.row, slotProps.rowIndex)"
                    />
                </markup-table-row>
            </template>

            <template v-if="createFormIsVisible" v-slot:afterContent>
                <markup-table-row>
                    <commission-split-form
                        ref="createForm"
                        @submit="handleCreate"
                        @cancel="toggleCreateFormVisibility"
                    />
                </markup-table-row>
            </template>
        </markup-table>

        <info-box v-else inline-actions no-margin>
            <p>{{ $tc('views.contract.settings.commission.commission-splitting.no-commission-splittings-found', 1) }}</p>

            <template v-slot:action>
                <base-button
                    v-if="!createFormIsVisible"
                    outline
                    data-test="btn:add"
                    :label="$tc('views.contract.settings.commission.commission-splitting.add-commission-splitting', 1)"
                    @click="toggleCreateFormVisibility"
                />
            </template>
        </info-box>

        <base-button
            v-if="containsCommissionSplits && !createFormIsVisible"
            outline
            data-test="btn:add"
            :label="$tc('views.contract.settings.commission.commission-splitting.add-commission-splitting', 1)"
            @click="toggleCreateFormVisibility"
        />
        <form-dialog
            ref="commissionSplitDeleteDialog"
            :item="commissionSplitToDelete"
            @confirm="handleDelete"
            @hide="commissionSplitToDelete = null"
        >
            <template v-slot:title>{{ $tc('views.contract.settings.commission.commission-splitting.delete-commission-splitting', 1) }}</template>
            <template v-slot:default>
                <p>{{ $tc('views.contract.settings.commission.commission-splitting.delete-commission-splitting--confirm', 1) }}</p>

                <dl v-if="commissionSplitToDelete" class="q-mb-none">
                    <dt>{{ $tc('views.contract.settings.commission.commission-splitting.additional-recipient', 1) }}: </dt>
                    <dd>{{ commissionSplitToDelete.model.additionalRecipient.getContactName() }}</dd>
                    <br>
                    <dt>{{ $tc('views.contract.settings.commission.commission-splitting.split-rate', 1) }}: </dt>
                    <dd>{{ commissionSplitToDelete.model.formattedSplitRate }}</dd>
                    <br>
                    <template v-if="commissionSplitToDelete.model.active_from">
                        <dt>{{ $t('views.contract.settings.commission.commission-splitting.active-from') }}: </dt>
                        <dd>{{ commissionSplitToDelete.model.formattedActiveFrom }}</dd>
                        <br>
                    </template>
                    <template v-if="commissionSplitToDelete.model.active_until">
                        <dt>{{ $t('views.contract.settings.commission.commission-splitting.active-until') }}: </dt>
                        <dd>{{ commissionSplitToDelete.model.formattedActiveUntil }}</dd>
                    </template>
                </dl>
            </template>
        </form-dialog>
    </div>
</template>

<script>
import { CommissionSplit } from '@/models/commissionSplit'
import { createIntervals, validateIntervals, parseFormDataToInput } from '@/helpers/commissionSplit'
import MarkupTable from '@/components/MarkupTable.vue'
import MarkupTableRow from '@/components/MarkupTableRow.vue'
import CommissionSplitForm from '@/components/commissionSplits/CommissionSplitForm'
import FormDialog from '@/components/form/FormDialog'
import StatusBadge from '@/components/StatusBadge'

export default {
    name: 'CommissionSplitManage',
    components: {
        MarkupTable,
        MarkupTableRow,
        CommissionSplitForm,
        FormDialog,
        StatusBadge,
    },
    props: {
        contract: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            maximumSplitRate: 100,
            createFormIsVisible: false,
            commissionSplitToDelete: null,
            commissionSplits: this.contract.commissionSplits
                ? this.contract.commissionSplits.map(entry => ({
                    id: entry.id,
                    editMode: false,
                    model: entry,
                }))
                : [],
            columns: [
                {
                    name: 'additional_recipient',
                    label: this.$tc('views.contract.settings.commission.commission-splitting.additional-recipient', 1),
                    align: 'left',
                    field: row => row.model.additionalRecipient.getContactName(),
                    minWidth: '25ch',
                    maxWidth: '2fr',
                },
                {
                    name: 'rate',
                    label: this.$tc('views.contract.settings.commission.commission-splitting.split-rate', 1),
                    align: 'left',
                    field: row => row.model.formattedSplitRate,
                },
                {
                    name: 'active_from',
                    label: this.$t('views.contract.settings.commission.commission-splitting.active-from'),
                    align: 'left',
                    field: row => row.model.formattedActiveFrom,
                },
                {
                    name: 'active_until',
                    label: this.$t('views.contract.settings.commission.commission-splitting.active-until'),
                    align: 'left',
                    field: row => row.model.formattedActiveUntil,
                },
                {
                    name: 'is_active',
                    label: this.$tc('common.term.status', 1),
                    align: 'left',
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left', autoWidth: true },
            ],
        }
    },
    computed: {
        containsCommissionSplits () {
            return !!(this.commissionSplits && this.commissionSplits.length)
        },
    },
    methods: {
        handleCreate (formData) {
            const input = this.parseFormDataToInput(formData)

            const validateIntervals = this.validateIntervals([
                ...this.commissionSplits.map(entry => entry.model),
                input,
            ])

            if (!validateIntervals.valid) {
                this.handleInvalidIntervals(validateIntervals.interval, this.$refs.createForm)
                return
            }

            CommissionSplit.create(this.contract.id, input)
                .then(response => {
                    this.commissionSplits.push({ editMode: false, model: response })

                    this.toggleCreateFormVisibility()

                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contract.settings.commission-splitting-added-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.createForm.showFormErrorMessage(error)
                    this.$refs.createForm.resetFormSubmitStatus()
                })
        },
        handleDelete () {
            this.commissionSplitToDelete.model.delete()
                .then(() => {
                    this.commissionSplits = this.commissionSplits.filter(
                        entry => entry.model.id != this.commissionSplitToDelete.model.id
                    )

                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contract.settings.commission-splitting-deleted-success', 1),
                    })

                    this.$refs.commissionSplitDeleteDialog.close()
                })
                .catch(error => {
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$tc('common.notifications.contract.settings.commission-splitting-deleted-error', 1),
                    })
                })
        },
        handleUpdate (commissionSplitModel, formData, index) {
            const { id } = commissionSplitModel
            const updateForm = this.$refs[`updateForm-${index}`]

            const input = this.parseFormDataToInput(formData)

            const validateIntervals = this.validateIntervals([
                ...this.commissionSplits.filter(entry => entry.model.id != id).map(entry => entry.model),
                input,
            ])

            if (!validateIntervals.valid) {
                this.handleInvalidIntervals(validateIntervals.interval, updateForm)
                return
            }

            commissionSplitModel.update(input)
                .then(response => {
                    Object.assign(
                        this.commissionSplits[index],
                        {
                            editMode: true,
                            model: response,
                        }
                    )

                    this.toggleUpdateFormVisibility(this.commissionSplits.find(entry => entry.model.id === response.id), index)
                    updateForm.resetFormSubmitStatus()

                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contract.settings.commission-splitting-updated-success', 1),
                    })
                })
                .catch(error => {
                    updateForm.showFormErrorMessage(error)
                    updateForm.resetFormSubmitStatus()
                })
        },
        toggleCreateFormVisibility () {
            this.createFormIsVisible = !this.createFormIsVisible
        },
        toggleUpdateFormVisibility (commissionSplit, index) {
            this.$refs[`updateForm-${index}`].resetFormErrorMessage()
            this.$refs[`updateForm-${index}`].resetFormSubmitStatus()
            this.$refs[`updateForm-${index}`].resetInitialFormData()
            commissionSplit.editMode = !commissionSplit.editMode
        },
        parseFormDataToInput (input) {
            return parseFormDataToInput(input)
        },
        validateIntervals (commissionSplits) {
            const intervals = createIntervals(commissionSplits)

            return validateIntervals(intervals, this.maximumSplitRate)
        },
        handleInvalidIntervals (interval, form) {
            const { rate, start, startFormatted, end, endFormatted } = interval

            form.showFormErrorMessage({
                graphQLErrors: [
                    {
                        message: this.$tc(`views.contract.settings.commission.commission-splitting.error.exceeds-rate${start || end ? '-' : ''}${start ? '-from' : ''}${end ? '-until' : ''}`, 1, { rate: `${(rate - 100)} %`, fromDate: startFormatted, untilDate: endFormatted }),
                    },
                ],
            })

            form.resetFormSubmitStatus()
        },
        deleteCommissionSplit(commissionSplit, $event) {
            $event.preventDefault()
            this.commissionSplitToDelete = commissionSplit
            this.$refs.commissionSplitDeleteDialog.open()
        },
    },
}
</script>
