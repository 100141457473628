import gql from 'graphql-tag'

export const ACTIVITY_LOGS_QUERY = gql`
    query activityLogs(
        $page: Int = 1
        $count: Int = 200
        $orderField: ActivityLogOrderField = ACTIVITY_LOG_CREATED_AT
        $orderDirection: OrderDirection = DESC
        $filterSubjects: [ActivityLogSubjectInput!]!
        $filterIncludeSubjectRelations: [ActivityLogSubjectRelation!]
        $filterDateFrom: Date
        $filterDateUntil: Date
    ) {
        activityLogs(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                subjects: $filterSubjects
                includeSubjectRelations: $filterIncludeSubjectRelations
                date_from: $filterDateFrom
                date_until: $filterDateUntil
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                log_name
                description
                subject {
                    ... on Person {
                        id
                    }
                    ... on Company {
                        id
                    }
                    ... on Address {
                        id
                    }
                    ... on EmailAddress {
                        id
                    }
                    ... on PhoneNumber {
                        id
                        label {
                            label
                        }
                    }
                    ... on BasicContactRelationship {
                        id
                        node1 {
                            id
                            ... on Person {
                                gender
                                personal_pronoun
                                first_name
                                last_name
                            }
                            ... on Company {
                                company_name
                            }
                        }
                        node2 {
                            id
                            ... on Person {
                                gender
                                personal_pronoun
                                first_name
                                last_name
                            }
                            ... on Company {
                                company_name
                            }
                        }
                    }
                    ... on Account {
                        number
                        name
                    }
                    ... on CommissionDistributionRuleRange {
                        rule {
                            commission_type
                        }
                    }
                }
                causer {
                    ... on BasicUser {
                        consultant {
                            first_name
                            last_name
                            consultingCompany {
                                id
                                company_name
                            }
                        }
                    }
                }
                properties
                created_at
                updated_at
            }
        }
    }
`
