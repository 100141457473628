import { EventBus } from '@/event-bus'

export const ShortcurtsManager = {
    shortcuts: [
        { key: 'f', id: 'globalSearch' },
        { key: 'e', id: 'editMode' },
        { key: 'n', id: 'newEntity' },
        { key: 'N', id: 'newAlternativeEntity' },
        { key: '1', id: 'tab', args: [1] },
        { key: '2', id: 'tab', args: [2] },
        { key: '3', id: 'tab', args: [3] },
        { key: '4', id: 'tab', args: [4] },
        { key: '5', id: 'tab', args: [5] },
        { key: '6', id: 'tab', args: [6] },
        { key: '7', id: 'tab', args: [7] },
        { key: '8', id: 'tab', args: [8] },
        { key: '9', id: 'tab', args: [9] },
    ],
    attachEvents () {
        document.addEventListener('keyup', ShortcurtsManager.onKeyup)
    },
    detachEvents () {
        document.removeEventListener('keyup', ShortcurtsManager.onKeyup)
    },
    onKeyup (event) {
        switch (event.key) {
            case 'Esc': // IE/Edge specific value
            case 'Escape':
                EventBus.$emit(`shortcut:escape`)
                break
            default:
                if (!['INPUT', 'TEXTAREA'].includes(event.target.tagName) && event.target.getAttribute('contenteditable') !== 'true') {
                    const shortcut = ShortcurtsManager.shortcuts.find(shortcut => shortcut.key === event.key)
                    if (shortcut) {
                        if (shortcut.args) {
                            EventBus.$emit(`shortcut:${shortcut.id}`, ...shortcut.args)
                        } else {
                            EventBus.$emit(`shortcut:${shortcut.id}`)
                        }
                    }
                }
        }
    },
}
