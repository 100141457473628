import { Model } from '@/models'
import { DriveService } from '@/services'

export class Drive extends Model {
    /**
     * Create a Drive model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'fileStorageItems',
            'total_of_items_per_type',
            'total_of_not_yet_acknowledged_items_per_type',
        ], data)
    }

    static get service () { return DriveService }
}
