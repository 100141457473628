<template>
    <q-select
        :value="value"
        v-bind="$attrs"
        map-options
        emit-value
        dense
        filled
        square
        class="truncate"
        @input="updateValue"

        @change="onChange"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" /><!-- Named slots -->

        <template v-if="infoText || infoTitle" v-slot:append>
            <info-icon
                :title="infoTitle"
                :text="infoText"
                size="0.5em"
                class="all-pointer-events"
            />
        </template>
    </q-select>
</template>

<script>
export default {
    name: 'BaseSelect',
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        infoTitle: {
            type: String,
            default: '',
        },
        infoText: {
            type: String,
            default: '',
        },
    },
    methods: {
        updateValue (value) {
            this.$emit('input', value)
        },
        onChange (event) {
            this.$emit('change', event.target.value)
        },
    },
}
</script>
