import gql from 'graphql-tag'

export const COMMISSION_LIST_ENTRY_CANCEL_POSTING_MUTATION = gql`
    mutation commissionListEntriesCancelPosting(
        $ids: [ID!]
        $stopAll: Boolean!
    ) {
        commissionListEntriesCancelPosting(
            ids: $ids
            stop_all: $stopAll

        ) {
            status
            number_of_affected_entries
        }
    }
`
