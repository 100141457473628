<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <template>
                    <q-breadcrumbs-el :label="$tc('common.term.glossary', 1)" :to="{ name: 'glossary' }" />
                </template>
            </template>

            <h1>{{ $tc('common.term.glossary', 1) }}</h1>
        </page-header>

        <div class="row q-col-gutter-md md-content-wrapper">
            <div class="col-xs-12 md-content-inner-wrapper">
                <div
                    v-for="glossaryEntry in localizedGlossary"
                    :key="glossaryEntry[0]"
                    class="glossary-entry"
                >
                    <h2>{{ glossaryEntry[1].term }}</h2>
                    <p v-if="glossaryEntry[1].description" class="pre-wrap">{{ glossaryEntry[1].description }}</p>
                </div>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import glossaryDeCH from '@/locales/glossary/de-CH.json'
import glossaryEn from '@/locales/glossary/en.json'

export default {
    name: 'Glossary',
    meta () {
        return {
            title: this.$tc('common.term.glossary', 1),
        }
    },
    props: {
    },
    data () {
        return {
            glossary: {
                'en': glossaryEn,
                'de-CH': glossaryDeCH,
            },
        }
    },
    computed: {
        localizedGlossary () {
            const glossary = Object.entries(this.glossary[this.$i18n.locale])

            // List terms alphabetically
            glossary.sort(function (a, b) {
                let termA = a[1].term.toUpperCase() // Ignore upper and lowercase
                let termB = b[1].term.toUpperCase() // Ignore upper and lowercase
                if (termA < termB) {
                    return -1
                }
                if (termA > termB) {
                    return 1
                }
                // If terms should be equal
                return 0
            })

            return glossary
        },
    },
    methods: {
    },
}
</script>

<style lang="scss" scoped>
.glossary-entry {

    & + & {
        margin-top: 2em;
    }
}
</style>
