import { apolloClient } from '@/apollo/client'

import { IGB2B_SECTOR_CODES_QUERY } from '@/graphql/igb2bSectorCode/igb2bSectorCodes'

export const IGB2BSectorCodeService = {
    all (variables) {
        return apolloClient.query({
            query: IGB2B_SECTOR_CODES_QUERY,
            variables,
        }).then(response => response.data.igb2bSectorCodes)
    },
}
