<template>
    <div>
        <base-table
            ref="table"
            :columns="state.columns"
            :fetch-objects-fn="ConsultingFileService.all"
            :additional-filters="additionalFilters"
            :show-filter="false"
            user-settings-base-path="ConsultingFileSelectList"
            pagination-sort-by-default-key="created_at"
            selection="multiple"
            @update:selected="onUpdateSelected"
        />
        <slot name="error" />
    </div>
</template>

<script>
import BaseTable from '@/components/BaseTable'
import { ConsultingFileService } from '@/services'
import { computed, reactive } from 'vue'
import { useI18n } from '@/composables/i18n'

export default {
    name: 'ConsultingFileSelect',
    components: {
        BaseTable,
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
        targetObjectType: {
            type: String,
            required: true,
        },
        targetObjectId: {
            type: String,
            required: true,
        },
        filters: {
            type: Object,
            default () {
                return {}
            },
        },
    },
    setup (props, context) {
        // Composables
        const { t, tc } = useI18n()

        // Data
        const state = reactive({
            columns: [
                {
                    name: 'file_name',
                    label: tc('common.term.name', 1),
                    field: row => row.file.name,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'type_id',
                    label: tc('common.term.type', 1),
                    field: row => row.type.name,
                    sortable: true,
                    align: 'left',
                },
                // TODO: Check if it would make sense to have a column showing the file type (PDF, Excel, …)
                /*
                                {
                                    name: 'file_size',
                                    label: tc('common.file.file-type', 1),
                                    // TODO: Add file type
                                    // field: row => row.file.type,
                                    sortable: true,
                                    align: 'left'
                                },
                */
                {
                    name: 'file_size',
                    label: tc('common.file.file-size', 1),
                    field: row => row.file.formattedFileSize,
                    // sortable: true, // TODO: so far not sortable...
                },
                {
                    name: 'created_at',
                    label: t('common.term.added-on'),
                    field: row => row.file.formattedCreatedAt,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'consultant_name',
                    label: tc('common.contact.consultant', 1),
                    field: row => row.consultant.getContactName({ consultingCompanyName: !row.consultant.worksForUserConsultingCompany }),
                    sortable: true,
                    align: 'left',
                },
            ],
        })

        // Computed
        const additionalFilters = computed(() => {
            const filters = {
                consultingFileTargetObject: {
                    object_type: props.targetObjectType,
                    object_id: props.targetObjectId,
                },
            }
            Object.assign(filters, props.filters)
            return filters
        })

        // Functions
        function onUpdateSelected (selectedItems) {
            context.emit('input', selectedItems.map(selectedItem => selectedItem.id))
        }

        return {
            // Static
            ConsultingFileService,

            // Data
            state,

            // Computed
            additionalFilters,

            // Functions
            onUpdateSelected,
        }
    },
}
</script>
