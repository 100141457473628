<template>
    <div class="form-item-section" :data-key="kebabCase(item.key)">
        <fieldset v-if="nestedSection">
            <legend>{{ item.label }}</legend>
            <div class="inner-wrapper">
                <slot :item="item"></slot>
            </div>
        </fieldset>
        <grid-card v-else :heading="item.label">
            <div class="inner-wrapper">
                <slot :item="item"></slot>
            </div>
        </grid-card>
    </div>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { FormSection } from '@/libs/form'
import { computed } from 'vue'

export default {
    name: 'FormSectionComponent',
    props: {
        item: {
            type: FormSection,
            required: true,
        },
    },
    setup (props) {
        const nestedSection = computed(() => props.item.meta.depth > 1)

        return {
            nestedSection,
            kebabCase,
        }
    },
}
</script>
