import { Model } from '@/models'
import { CorrespondenceEmailService } from '@/services'
import { CorrespondenceEmailActions } from '@/enums'
import { CorrespondenceItemType, CorrespondenceItemTargetObjectType } from '@/enums/graphql'
import { wrapCorrespondenceItemTargetObject } from '@/helpers/correspondence'

export class CorrespondenceEmail extends Model {
    /**
     * Create a CorrespondenceEmail model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'creator',
            'status',
            'name',
            'target_object_type',
            'target_object_ids',
            'targetContacts',
            'targetApplications',
            'targetContracts',
            'email_address_overrides',
            'sender_overrides',
            'language',
            'differentiate_formality',
            'contents',
            'attachments',
        ], data)
        this.type = CorrespondenceItemType.EMAIL
    }

    static get service () { return CorrespondenceEmailService }
    static action = CorrespondenceEmailActions

    get actions () {
        const actions = super.actions
        actions.push({
            key: CorrespondenceEmailActions.DELETE,
            item: this,
        })
        return actions
    }

    /**
     * Create a new correspondenceEmail.
     * @param {Object} variables - The values used to create the correspondenceEmail.
     * @param {string} variables.targetObjectType
     * @param {string} variables.name
     * @param {string} variables.targetObjectIds
     * @param {string} variables.languageId
     * @param {string} variables.emailAddressOverrides
     * @param {string} variables.senderOverrides
     * @param {string} variables.differentiateFormality
     * @param {string} variables.contents
     * @returns {Promise<*>}
     */
    static create ({ targetObjectType, name, targetObjectIds, languageId, emailAddressOverrides, senderOverrides, differentiateFormality, contents }) {
        const variables = {
            targetObjectType,
            correspondenceEmail: {
                name,
                target_object_ids: targetObjectIds,
                language_id: languageId,
                differentiate_formality: differentiateFormality,
                contents,
            },
        }

        if (emailAddressOverrides) variables.correspondenceEmail.email_address_overrides = emailAddressOverrides
        if (senderOverrides) variables.correspondenceEmail.sender_overrides = senderOverrides

        return CorrespondenceEmailService.create(variables)
    }

    /**
     * Update this correspondenceEmail.
     * @param {Object} variables - The values used to update the correspondenceEmail.
     * @param {string} variables.name
     * @param {string} variables.targetObjectIds
     * @param {string} variables.languageId
     * @param {string} variables.emailAddressOverrides
     * @param {string} variables.senderOverrides
     * @param {string} variables.differentiateFormality
     * @param {string} variables.contents
     * @returns {Promise<*>}
     */
    update ({ name, targetObjectIds, languageId, emailAddressOverrides, senderOverrides, differentiateFormality, contents }) {
        const correspondenceEmailVariables = {
            name,
            target_object_ids: targetObjectIds,
            language_id: languageId,
            differentiate_formality: differentiateFormality,
            contents,
        }

        if (typeof emailAddressOverrides !== 'undefined') correspondenceEmailVariables.email_address_overrides = emailAddressOverrides
        if (typeof senderOverrides !== 'undefined') correspondenceEmailVariables.sender_overrides = senderOverrides

        return CorrespondenceEmailService.update(this.id, correspondenceEmailVariables).then(correspondenceEmail => {
            Object.assign(this, correspondenceEmail)
            return correspondenceEmail
        })
    }

    /**
     * Delete this correspondenceEmail.
     * @returns {Promise<*>}
     */
    delete () {
        return CorrespondenceEmailService.delete(this.id)
    }

    /**
     * Generates a preview of this emails content by calling a microservice via the API that interpolates placeholders.
     * @returns {Promise<*>}
     */
    preview (targetObjectId) {
        return CorrespondenceEmailService.createPreview(this.id, targetObjectId)
    }

    /**
     * Processes the content and sends the email.
     * @returns {Promise<*>}
     */
    startProcessing () {
        return CorrespondenceEmailService.startProcessing(this.id)
    }

    /**
     * Refetches and replaces the attachments on this instance.
     * @returns {Promise<*>}
     */
    reloadAttachments () {
        return CorrespondenceEmailService.getAttachments(this.id).then(correspondenceEmail => {
            this.attachments = correspondenceEmail.attachments
            return correspondenceEmail
        })
    }

    /**
     * Get the appropriate targetObjects based on the `target_object_type`.
     * Returns a wrapper object that has its prototype set to the corresponding targetObject.
     * Mixes in some helper properties/functions and a reference to this `CorrespondenceItem`.
     */
    get targetObjects () {
        let targetObjects

        switch (this.target_object_type) {
            case CorrespondenceItemTargetObjectType.CONTACT:
                targetObjects = this.targetContacts
                break
            case CorrespondenceItemTargetObjectType.APPLICATION:
                targetObjects = this.targetApplications
                break
            case CorrespondenceItemTargetObjectType.CONTRACT:
                targetObjects = this.targetContracts
                break
        }

        if (typeof targetObjects !== 'undefined') {
            const additionalProps = { creatorId: this.creator.id }
            return targetObjects.map(targetObject => {
                const wrappedTargetObject = wrapCorrespondenceItemTargetObject(targetObject, this.target_object_type, additionalProps)
                if (this.email_address_overrides !== null && typeof this.email_address_overrides[wrappedTargetObject.recipient.id] !== 'undefined') wrappedTargetObject.emailAddressOverrideId = this.email_address_overrides[wrappedTargetObject.recipient.id]
                if (this.sender_overrides !== null && typeof this.sender_overrides[wrappedTargetObject.recipient.id] !== 'undefined') wrappedTargetObject.senderOverrideId = this.sender_overrides[wrappedTargetObject.recipient.id]
                return wrappedTargetObject
            })
        } else {
            return null
        }
    }
}
