import gql from 'graphql-tag'

export const RESET_PASSWORD_MUTATION = gql`
    mutation resetPassword($token: String!, $password: String!, $password_confirmation: String!) {
        resetPassword(
            token: $token,
            password: $password,
            password_confirmation: $password_confirmation
        ) {
            status
        }
    }
`
