<template>
    <div class="breakpoint-info-wrapper q-pa-sm">
        <div class="content">
            <p><b>Currently active breakpoint: <code>{{ currentBreakpoint() }}</code></b></p>
            <p>Viewport dimensions: {{ $q.screen.width }} × {{ $q.screen.height }} px</p>
            <p>(Quasar) Breakpoints: {{ $q.screen.sizes.sm }} → sm | {{ $q.screen.sizes.md }} → md | {{ $q.screen.sizes.lg }} → lg | {{ $q.screen.sizes.xl }} → xl</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DevBreakpointInfo',
    data () {
        return {
            showBreakpointInfo: false,
        }
    },
    methods: {
        currentBreakpoint () {
            const breakpoints = ['sm', 'md', 'lg', 'xl']
            for (const breakpoint of breakpoints) {
                if (this.$q.screen[breakpoint]) return breakpoint
            }
            return 'xs'
        },
    },
}
</script>

<style lang="scss" scoped>
.breakpoint-info-wrapper {
    position: fixed;
    bottom: 1rem;
    left: 0;
    z-index: 10000; // Quasar uses "z-index: 2000;" for fixed elements.
    pointer-events: none;

    border: 1px solid var(--q-color-accent);
    border-left: none;
    background: rgba(#fff, .7);

    color: var(--color-text-primary);
    font-size: smaller;

    p {
        margin-bottom: 0.4em;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
