<template>
    <q-item-section side class="submenu-indicator">
        <q-icon name="mib-arrow-right-1" size="0.6em" />
    </q-item-section>
</template>

<script>
export default {
    name: 'QItemSubmenuIndicator',
}
</script>
