import gql from 'graphql-tag'

export const COMMISSION_DISTRIBUTION_RULE_SET_CREATE_MUTATION = gql`
    mutation commissionDistributionRuleSetCreate(
        $commissionDistributionRuleSet: CommissionDistributionRuleSetInput!
    ) {
        commissionDistributionRuleSetCreate(
            commissionDistributionRuleSet: $commissionDistributionRuleSet
        ) {
            id
            name
            description
            rules {
                id
                set {
                    id
                    name
                    description
                }
                commission_type
                ranges {
                    id
                    rule {
                        id
                    }
                    amount_from
                    amount_to
                    payout_ratio
                    cancellation_reserve
                    created_at
                    updated_at
                }
                created_at
                updated_at
            }
            is_assigned
            created_at
            updated_at
        }
    }
`
