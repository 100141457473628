import { Model } from '@/models'
import { ApplicationService } from '@/services'
import { createProductFieldIdValuePairs } from '@/helpers/contract'
import { computed } from 'vue'
import { ProductDerivedEntity } from '@/models/productDerivedEntity'
import { ApplicationActions } from '@/enums'
import { ApplicationStatus, ProductDerivedEntityType } from '@/enums/graphql'
import { getTargetObjectType } from '@/helpers'
import { formatDate } from '@/helpers/date'
import { isBefore, parseISO, isToday, endOfDay, addDays } from 'date-fns'
import { i18n } from '@/i18n'
import { kebabCase } from 'lodash'
import { formatCurrency } from '@/helpers/number'

export class Application extends ProductDerivedEntity {
    /**
     * Create an Application model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super(data)
        Model.initializeFields(this, [
            'number',
            'external_reference_number',
            'sourceContract',
            'targetContract',
            'is_change_application',
            'on_hold_until',
            'submission_send_date',
            'submission_response_date',
        ], data)
        this.type = ProductDerivedEntityType.APPLICATION

        // Transitions
        this.statusTransitions[ApplicationStatus.ON_HOLD] = [ApplicationStatus.PENDING_AT_PRODUCT_PROVIDER, ApplicationStatus.WITHDRAWN]
        this.statusTransitions[ApplicationStatus.PENDING_AT_PRODUCT_PROVIDER] = [
            ApplicationStatus.APPROVED_BY_PRODUCT_PROVIDER,
            ApplicationStatus.DECLINED_BY_PRODUCT_PROVIDER,
            ApplicationStatus.COUNTER_PROPOSAL_PENDING_AT_CUSTOMER,
            ApplicationStatus.WITHDRAWN,
        ]
        this.statusTransitions[ApplicationStatus.COUNTER_PROPOSAL_PENDING_AT_CUSTOMER] = [ApplicationStatus.PENDING_AT_PRODUCT_PROVIDER, ApplicationStatus.COUNTER_PROPOSAL_DECLINED_BY_CUSTOMER]

        // Action mapping
        this.statusActionMapping[ApplicationStatus.PENDING_AT_PRODUCT_PROVIDER] = ApplicationActions.SUBMIT_TO_PRODUCT_PROVIDER
        this.statusActionMapping[ApplicationStatus.APPROVED_BY_PRODUCT_PROVIDER] = ApplicationActions.SET_APPROVED_BY_PRODUCT_PROVIDER
        this.statusActionMapping[ApplicationStatus.DECLINED_BY_PRODUCT_PROVIDER] = ApplicationActions.SET_DECLINED_BY_PRODUCT_PROVIDER
        this.statusActionMapping[ApplicationStatus.COUNTER_PROPOSAL_PENDING_AT_CUSTOMER] = ApplicationActions.SUBMIT_COUNTER_PROPOSAL_TO_CUSTOMER
        this.statusActionMapping[ApplicationStatus.COUNTER_PROPOSAL_DECLINED_BY_CUSTOMER] = ApplicationActions.SET_COUNTER_PROPOSAL_DECLINED_BY_CUSTOMER
        this.statusActionMapping[ApplicationStatus.WITHDRAWN] = ApplicationActions.WITHDRAW

        // Computed
        this.formattedNumber = computed(() => {
            return this.external_reference_number || this.number?.number || ''
        })
        this.formattedOnHoldUntil = computed(() => {
            return formatDate(this.on_hold_until)
        })
        this.formattedSubmissionSendDate = computed(() => {
            return formatDate(this.submission_send_date)
        })
        this.formattedSubmissionResponseDate = computed(() => {
            return formatDate(this.submission_response_date)
        })
        this.statusInfo = computed(() => {
            let statusInfo = {}
            const now = new Date()
            const overdueThreshold = {
                // Threshold in days until the application should be marked as "overdue". (In order to notify the user that they should check if there are any updates.)
                ON_HOLD: 0,
                PENDING_AT_PRODUCT_PROVIDER: 14, // TODO: Verify (and adjust) threshold.
                COUNTER_PROPOSAL_PENDING_AT_CUSTOMER: 14, // TODO: Verify (and adjust) threshold.
            }

            switch (this.status) {
                case ApplicationStatus.ON_HOLD:
                    statusInfo = {
                        date: this.formattedOnHoldUntil.value,
                        isDueToday: isToday(parseISO(this.on_hold_until)),
                        isOverdue: isBefore(addDays(endOfDay(parseISO(this.on_hold_until)), overdueThreshold.ON_HOLD), now),
                    }
                    statusInfo.type = statusInfo.isDueToday || statusInfo.isOverdue ? 'warning' : 'info'
                    break

                case ApplicationStatus.PENDING_AT_PRODUCT_PROVIDER:
                    statusInfo = {
                        date: this.formattedSubmissionSendDate.value,
                        isOverdue: !this.submission_response_date && isBefore(addDays(endOfDay(parseISO(this.submission_send_date)), overdueThreshold.PENDING_AT_PRODUCT_PROVIDER), now),
                    }
                    statusInfo.type = statusInfo.isOverdue ? 'warning' : 'info'
                    break

                case ApplicationStatus.COUNTER_PROPOSAL_PENDING_AT_CUSTOMER:
                    statusInfo = {
                        date: this.formattedSubmissionSendDate.value,
                        isOverdue: isBefore(addDays(endOfDay(parseISO(this.submission_response_date)), overdueThreshold.COUNTER_PROPOSAL_PENDING_AT_CUSTOMER), now),
                    }
                    statusInfo.type = statusInfo.isOverdue ? 'warning' : 'info'
                    break

                case ApplicationStatus.APPROVED_BY_PRODUCT_PROVIDER:
                    statusInfo = {
                        type: 'positive',
                    }
                    break
            }

            let translationIdText = `common.application.status-info.${kebabCase(this.status)}`
            if (statusInfo.isDueToday) translationIdText += '--due-today'
            if (statusInfo.isOverdue) translationIdText += '--overdue'
            if (this.changeApplicationTargetContractAction) translationIdText += `--change-application--${this.changeApplicationTargetContractAction}-contract`
            statusInfo.text = i18n.te(translationIdText) ? i18n.t(translationIdText, { date: statusInfo.date }) : ''

            let translationIdSubmissionResponseDateLabel = `common.application.status-info.${kebabCase(this.status)}--submission-response-date-label`
            statusInfo.submissionResponseDateLabel = i18n.te(translationIdSubmissionResponseDateLabel) ? i18n.t(translationIdSubmissionResponseDateLabel) : i18n.t('common.application.submission-response-date')

            return statusInfo
        })

        this.formattedStartDate = computed( () => {
            return formatDate(this.start_date)
        })

        this.formattedEndDate = computed( () => {
            return formatDate(this.end_date)
        })

        this.formattedPremium = computed( () => {
            return formatCurrency(this.premium)
        })
    }

    static get service () { return ApplicationService }
    static status = ApplicationStatus
    static action = ApplicationActions

    // TODO: define params.
    /**
     * Create a new application.
     * @param {Object} variables - The values used to create the application.
     * @param {string} variables.param1
     * @param {string} variables.param2
     * @param {string} variables.param3
     * @returns {Promise<*>}
     */
    static create ({
        product,
        customerId,
        status,
        submissionSendDate,
        isChangeApplication,
        sourceContractId,

        productFields,
        externalReferenceNumber,
        consultantId,
        onHoldUntil,
        startDate,
        endDate,
        premium,
        generalAgencyId,
        managedByStatus,
        consultingMandateEnquirySendDate,
    }) {
        const productFieldValues = createProductFieldIdValuePairs(product.template, productFields)
        const variables = {
            productId: product.id,
            productTemplateVersion: product.template.version,
            customerId: customerId,
            status: status,
            submissionSendDate: (submissionSendDate === '') ? null : submissionSendDate,
            isChangeApplication: isChangeApplication,
            sourceContractId: sourceContractId,
            application: {
                product_field_values: JSON.stringify(productFieldValues),
                external_reference_number: externalReferenceNumber,
                consultant_id: consultantId,
                on_hold_until: (onHoldUntil === '') ? null : onHoldUntil,
                start_date: (startDate === '') ? null : startDate,
                end_date: (endDate === '') ? null : endDate,
                premium: (premium === '') ? null : parseFloat(premium),
                general_agency_id: generalAgencyId ?? null,
                managed_by_status: managedByStatus,
                consulting_mandate_enquiry_send_date: (consultingMandateEnquirySendDate === '') ? null : consultingMandateEnquirySendDate,
            },
        }
        return ApplicationService.create(variables)
    }

    // TODO: define params.
    /**
     * Update this application.
     * @param {Object} variables - The values used to update the application.
     * @param {string} variables.param1
     * @param {string} variables.param2
     * @param {string} variables.param3
     * @returns {Promise<*>}
     */
    update ({
        submissionSendDate,
        submissionResponseDate,

        productFields,
        externalReferenceNumber,
        consultantId,
        onHoldUntil,
        startDate,
        endDate,
        premium,
        generalAgencyId,
        managedByStatus,
        consultingMandateEnquirySendDate,
    }) {
        const productFieldValues = createProductFieldIdValuePairs(this.currentContractInformation.productTemplate, productFields)
        const variables = {
            id: this.id,
            submissionSendDate: (submissionSendDate === '') ? null : submissionSendDate,
            submissionResponseDate: (submissionResponseDate === '') ? null : submissionResponseDate,
            application: {
                product_field_values: JSON.stringify(productFieldValues),
                external_reference_number: externalReferenceNumber,
                consultant_id: consultantId,
                on_hold_until: (onHoldUntil === '') ? null : onHoldUntil,
                start_date: (startDate === '') ? null : startDate,
                end_date: (endDate === '') ? null : endDate,
                premium: (premium === '') ? null : parseFloat(premium),
                general_agency_id: generalAgencyId ?? null,
                managed_by_status: managedByStatus,
                consulting_mandate_enquiry_send_date: (consultingMandateEnquirySendDate === '') ? null : consultingMandateEnquirySendDate,
            },
        }

        return ApplicationService.update(variables).then(application => {
            Object.assign(this, application)
            return application
        })
    }

    updateToProductTemplateVersion (productTemplateVersion) {
        return ApplicationService.updateToProductTemplateVersion(this.id, productTemplateVersion)
    }

    submitToProductProvider (submissionSendDate) {
        return ApplicationService.changeStatus({
            id: this.id,
            status: ApplicationStatus.PENDING_AT_PRODUCT_PROVIDER,
            submissionSendDate,
        }).then(application => {
            Object.assign(this, application)
            return application
        })
    }

    setApprovedByProductProvider ({
        submissionResponseDate,
        createNewContract,
        contractNumber,
        productFields,
        startDate,
        endDate,
        premium,
        generalAgencyId,
    }) {
        const productFieldValues = createProductFieldIdValuePairs(this.currentContractInformation.productTemplate, productFields)
        const variables = {
            id: this.id,
            status: ApplicationStatus.APPROVED_BY_PRODUCT_PROVIDER,
            submissionResponseDate,
            productFieldValues: JSON.stringify(productFieldValues),
            startDate: (startDate === '') ? null : startDate,
            endDate: (endDate === '') ? null : endDate,
            premium: (premium === '') ? null : parseFloat(premium),
            generalAgencyId: generalAgencyId ?? null,
        }
        if (this.is_change_application) variables.createNewContract = createNewContract
        if (this.is_change_application === false || createNewContract === true) variables.contractNumber = contractNumber
        return ApplicationService.changeStatus(variables).then(application => {
            Object.assign(this, application)
            return application
        })
    }

    setDeclinedByProductProvider (submissionResponseDate) {
        return ApplicationService.changeStatus({
            id: this.id,
            status: ApplicationStatus.DECLINED_BY_PRODUCT_PROVIDER,
            submissionResponseDate,
        }).then(application => {
            Object.assign(this, application)
            return application
        })
    }

    submitCounterProposalToCustomer (submissionResponseDate) {
        return ApplicationService.changeStatus({
            id: this.id,
            status: ApplicationStatus.COUNTER_PROPOSAL_PENDING_AT_CUSTOMER,
            submissionResponseDate,
        }).then(application => {
            Object.assign(this, application)
            return application
        })
    }

    setCounterProposalDeclinedByCustomer () {
        return ApplicationService.changeStatus({
            id: this.id,
            status: ApplicationStatus.COUNTER_PROPOSAL_DECLINED_BY_CUSTOMER,
        }).then(application => {
            Object.assign(this, application)
            return application
        })
    }

    withdraw () {
        return ApplicationService.changeStatus({
            id: this.id,
            status: ApplicationStatus.WITHDRAWN,
        }).then(application => {
            Object.assign(this, application)
            return application
        })
    }

    /**
     * Delete this application.
     * @returns {Promise<*>}
     */
    delete () {
        return ApplicationService.delete(this.id)
    }

    get actions () {
        const actions = []

        actions.push(...super.actions)

        actions.push({
            key: ApplicationActions.DELETE,
            item: this,
        })

        return actions
    }

    get targetObjectType () {
        return getTargetObjectType(this)
    }

    /**
     * Differentiate if an accepted change application updated the existing contract or created a new one.
     * @returns {string|null}
     */
    get changeApplicationTargetContractAction () {
        if (this.is_change_application && this.sourceContract && this.targetContract) {
            return (this.sourceContract.id === this.targetContract.id) ? 'update-existing' : 'create-new'
        } else {
            return null
        }
    }
}
