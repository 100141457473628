import gql from 'graphql-tag'

export const USER_RESET_2FA_MUTATION = gql`
    mutation userReset2FA(
        $password: String!
    ) {
        userReset2FA(
            password: $password
        ) {
            qr_code
        }
    }
`
