import gql from 'graphql-tag'

export const USER_VALIDATE_2FA_BACKUP_CODE_MUTATION = gql`
    mutation userValidate2FABackupCode($two_fa_backup_code: String!) {
        userValidate2FABackupCode(
            two_fa_backup_code: $two_fa_backup_code
        ) {
            status
        }
    }
`
