<template>
    <form
        class="row q-col-gutter-md"
        data-test="form:commission-distribution-rule-set"
        @submit.prevent="onFormSubmit"
    >
        <div v-if="formErrorMessage" class="col-xs-12">
            <!-- eslint-disable vue/no-v-html -->
            <info-box
                v-if="formErrorMessage"
                type="negative"
                data-test="error:global-message"
                v-html="formErrorMessage"
            />
            <!-- eslint-enable -->
        </div>

        <div class="col-xs-12 col-md-6 col-lg-4">
            <form-builder item-key="name" :disable="disableForm" autofocus />
        </div>
        <div class="col-xs-12 col-md-6 col-lg-8">
            <form-builder item-key="description" :disable="disableForm" />
        </div>
        <div class="col-xs-12">
            <q-separator />
        </div>

        <div class="col-xs-12">
            <div class="row q-col-gutter-lg">
                <grid-card
                    v-for="commissionDistributionRule in commissionDistributionRules"
                    :key="commissionDistributionRule.commission_type"
                    :columns="{ xs: 12 }"
                    :heading="$t(`common.commission.commission-type.${toKebabCase(commissionDistributionRule.commission_type)}`)"
                    :subheading="` – ${$tc('views.commission.accounting.commission-type-configuration.commission-type-igb2b', 1)}: ${commissionDistributionRule.commission_type.replace('TYPE_', '')}`"
                    subheading-inline
                >
                    <commission-distribution-rule-range-manage
                        ref="ruleManage"
                        :commission-distribution-rule="commissionDistributionRule"
                        :disable-form="disableForm"
                        :current-mode="currentMode"
                    />
                </grid-card>
            </div>
        </div>

        <in-page-footer>
            <base-button
                primary-button
                flat
                data-test="btn:cancel"
                :label="$t('common.term.cancel')"
                @click="handleCancel"
            />
            <base-button
                primary-button
                type="submit"
                :label="currentMode === ViewMode.CREATE ? $t('common.term.create') : $t('common.term.save')"
                :disabled="disableForm || !anyDirty"
                data-test="btn:save"
            />
        </in-page-footer>
    </form>
</template>

<script>
import { i18n } from '@/i18n'
import { EventBus } from '@/event-bus'
import { ViewMode } from '@/enums'
import { CommissionType } from '@/enums/graphql'
import { kebabCase } from 'lodash'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import COMMISSION_DISTRIBUTION_RULE_SET_FORM from '@/forms/commissionDistributionRuleSet.form'
import CommissionDistributionRuleRangeManage from '@/components/commission/commissionDistributionRuleRange/CommissionDistributionRuleRangeManage.vue'

export default {
    name: 'CommissionDistributionRuleSetForm',
    components: {
        CommissionDistributionRuleRangeManage,
    },
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: COMMISSION_DISTRIBUTION_RULE_SET_FORM,
    },
    props: {
        commissionDistributionRuleSet: {
            type: Object,
            required: false,
            default () {
                return undefined
            },
        },
        disableForm: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        const data = {
            ViewMode,
            currentMode: this.commissionDistributionRuleSet ? ViewMode.UPDATE : ViewMode.CREATE,
            CommissionType,
            commissionDistributionRules: Object.keys(CommissionType).map(name => ({ commission_type: name })),
            formData: {},
            anyDirtyState: false,
        }

        if (data.currentMode === ViewMode.UPDATE) {
            Object.assign(data.formData, {
                name: this.commissionDistributionRuleSet.name,
                description: this.commissionDistributionRuleSet.description,
            })
            data.commissionDistributionRules = this.commissionDistributionRuleSet.rules
        }

        return data
    },
    computed: {
        anyDirty () {
            const isDirty = this.anyDirtyState || this.$v.$anyDirty
            this.$emit('update:dirty-state', isDirty)
            return isDirty
        },
    },
    created () {
        EventBus.$on('commissionDistributionRuleSet:get-rule-set', this.getRuleSet)
        EventBus.$on('commissionDistributionRuleSet:submit-rule-range-forms-any-dirty-state', this.handleAnyDirtyState)
    },
    beforeDestroy () {
        EventBus.$off('commissionDistributionRuleSet:get-rule-set', this.getRuleSet)
        EventBus.$off('commissionDistributionRuleSet:submit-rule-range-forms-any-dirty-state', this.handleAnyDirtyState)
    },
    methods: {
        handleAnyDirtyState (anyDirtyState) {
            this.anyDirtyState = true
        },
        handleCancel () {
            this.$router.push({ name: 'commission-distribution-rule-set-list' })
        },
        getRuleSet () {
            const rules = []
            let isValid = true

            this.$refs.ruleManage.forEach(ruleManage => {
                // Validate rule.
                const ruleErrorMessages = this.validateRuleManage(ruleManage)

                // Validate range and show error messages.
                ruleManage.ruleRangesData.forEach((rangeData, index) => {
                    const rangeErrorMessages = this.validateRuleRange(rangeData)
                    if (rangeErrorMessages.graphQLErrors.length) {
                        isValid = false
                        ruleManage.$refs[`updateForm-${rangeData.id}`].showFormErrorMessage(rangeErrorMessages)
                    }
                })

                // Show rule error messages.
                if (ruleErrorMessages.graphQLErrors.length) {
                    isValid = false
                    ruleManage.showErrorMessage(ruleErrorMessages)
                    return
                } else {
                    ruleManage.resetErrorMessage()
                }

                rules.push({
                    commission_type: ruleManage.commissionType,
                    ranges: ruleManage.ruleRangesData.map(entry => entry.model),
                })
            })

            if (!isValid) {
                return
            } else {
                this.resetFormErrorMessage()
            }

            EventBus.$emit('commissionDistributionRuleSet:get-rule-set:response', rules)
        },
        validateRuleManage (ruleManage) {
            const errorMessages = { graphQLErrors: [] }

            // At least one range.
            if (!ruleManage.ruleRangesData.length) {
                errorMessages.graphQLErrors.push({ message: i18n.t('common.rule.error--at-least-one-rule') })
            }

            // Last range must be open.
            if (ruleManage.lastRuleRangeData && ruleManage.lastRuleRangeData.model.amount_to !== null) {
                errorMessages.graphQLErrors.push({ message: i18n.t('views.commission.commission-distribution.error--last-amount-to-must-be-empty') })
            }

            return errorMessages
        },
        validateRuleRange (rangeData) {
            const errorMessages = { graphQLErrors: [] }

            if (rangeData.editMode) {
                errorMessages.graphQLErrors.push({ message: i18n.t('views.commission.commission-distribution.error--save-rule-before-saving-rule-set') })
            }

            return errorMessages
        },
        toKebabCase: function (text) {
            return kebabCase(text)
        },
    },
}
</script>
