<template>
    <form-builder-next
        :form="CommissionRevenueReportForm"
        :initial-data="initialData"
        in-page-footer
        @submit="handleFormData"
    >
        <template v-slot:default="{ formBuilder }">
            <grid-card
                :columns="{ xs: 12 }"
                :heading="$tc('common.term.configuration')"
            >
                <div class="row q-col-gutter-md">
                    <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
                        <form-builder-next :item="formBuilder.form.getItem('dateFrom')" />
                    </div>

                    <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
                        <form-builder-next :item="formBuilder.form.getItem('dateUntil')" />
                    </div>
                </div>
            </grid-card>

            <div class="col-xs-12">
                <base-table
                    ref="table"
                    :columns="columns"
                    :fetch-objects-fn="Contact.service.all"
                    :additional-filters="additionalFilters"
                    user-settings-base-path="CommissionRevenueReportConsultingEntities"
                    pagination-sort-by-default-key="contact_name"
                    selection="multiple"
                    pagination-initial-order-direction-ascending
                    :selected.sync="state.selectedConsultingEntities"
                    :selection-row-condition="selectionRowCondition"
                    enable-visible-columns
                    @update:selected="onUpdateSelected"
                >
                    <template v-slot:tabs>
                        <base-toggle
                            v-model="state.includeInactive"
                            :label="$t('common.report.include-inactive-consulting-entities')"
                        />
                    </template>

                    <template v-slot:selectionRowConditionInfo>
                        <q-checkbox
                            :value="false"
                            class="not-selectable"
                            color="secondary-light"
                            keep-color
                            dense
                            disable
                        />
                    </template>

                    <template v-slot:body-cell-contact_name="slotProps">
                        <q-td :props="slotProps" class="contact-name-td" data-test="text:contact-name">
                            <contact-name
                                :contact="slotProps.row"
                                highlighted
                                comma-separated
                            />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-current_account="slotProps">
                        <q-td
                            :props="slotProps"
                            :class="['truncate-text', {'text-negative': !(slotProps.row.consultingSettings && slotProps.row.consultingSettings.currentAccount)}]"
                            data-test="td:current_account"
                        >
                            <span v-if="slotProps.row.consultingSettings && slotProps.row.consultingSettings.currentAccount">
                                <span class="account-number">{{ slotProps.row.consultingSettings.currentAccount.number }}</span> <span class="account-name additional-info">– {{ slotProps.row.consultingSettings.currentAccount.name }}</span>
                                <q-tooltip
                                    :delay="1000"
                                    anchor="center start"
                                    self="center start"
                                    :offset="[0, 0]"
                                    max-width="70ch"
                                >{{ slotProps.row.consultingSettings.currentAccount.number }} – {{ slotProps.row.consultingSettings.currentAccount.name }}</q-tooltip>
                            </span>
                            <template v-else>{{ $tc('common.accounting.account.no-account-assigned', 1) }}</template>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-status="slotProps">
                        <q-td :props="slotProps" auto-width>
                            <status-badge :status="slotProps.row.consultingSettings.status" />
                        </q-td>
                    </template>
                </base-table>
            </div>
        </template>

        <template v-slot:buttons="formBuilder">
            <!-- TODO improvement @TFU: Check if handling could be the same as on the list (n of x / eligibility) -->
            <base-button
                type="submit"
                :label="$tc('common.commission.commission-income-report.create-commission-income-report', state.selectedConsultingEntities.length || 1)"
                data-test="btn:submit"
                :disable="formBuilder.formSubmitStatus || !state.selectedConsultingEntities.length"
                :loading="formBuilder.formSubmitStatus"
                primary-button
            />
        </template>
    </form-builder-next>
</template>

<script>
import { useI18n } from '@/composables/i18n'
import { CommissionRevenueReportForm } from '@/forms/commission/commissionRevenueReport'
import { lightFormat, startOfMonth, endOfMonth } from 'date-fns'
import { Contact } from '@/models/contact'
import { ConsultingSettingsStatus } from '@/enums/graphql'

import BaseToggle from '@/components/form/BaseToggle.vue'
import BaseTable from '@/components/BaseTable.vue'
import ContactName from '@/components/contact/ContactName.vue'
import StatusBadge from '@/components/StatusBadge.vue'
import { computed, reactive, ref } from 'vue'

export default {
    name: 'CommissionRevenueReportForm',
    meta () {
        return {
            title: this.$tc('common.commission.commission-income-report.commission-income-report', 1),
        }
    },
    components: {
        BaseToggle,
        BaseTable,
        ContactName,
        StatusBadge,
    },
    setup (props, context) {
        // Composables
        const { tc } = useI18n()

        // Template refs
        const table = ref(null)

        // State
        const state = reactive({
            includeInactive: false,
            selectedConsultingEntities: [],
        })

        // Form
        const formComponent = ref(null)
        const initialData = {
            dateFrom: lightFormat(startOfMonth(new Date()), 'yyyy-MM-dd'),
            dateUntil: lightFormat(endOfMonth(new Date()), 'yyyy-MM-dd'),
        }

        // Computed
        const additionalFilters = computed(() => {
            const filters = {
                consultingSettingsStatus: state.includeInactive ? [ConsultingSettingsStatus.ACTIVE, ConsultingSettingsStatus.INACTIVE] : [ConsultingSettingsStatus.ACTIVE],
                hasConsultingSettings: true,
            }
            return filters
        })

        // Functions
        function handleFormData (formData) {
            const consultingEntityIds = state.selectedConsultingEntities.map(consultant => consultant.id)
            context.emit('submit', { formData: formData, consultingEntityIds: consultingEntityIds })
        }
        function selectionRowCondition (row) {
            return row.consultingSettings.currentAccount
        }
        function resetSelectedItems () {
            state.selectedConsultingEntities.length = 0
            if (table.value) table.value.$refs.qTable.clearSelection()
        }
        function onUpdateSelected () {
            const validSelectedItems = state.selectedConsultingEntities.filter(selectionRowCondition)
            state.selectedConsultingEntities.length = 0
            state.selectedConsultingEntities.push(...validSelectedItems)
        }

        return {
            // Static
            Contact,

            // Template refs
            table,

            // State
            state,

            // Form
            formComponent,
            CommissionRevenueReportForm,
            initialData,

            // Table
            columns: [
                {
                    name: 'contact_name',
                    label: tc('common.term.name'),
                    sortable: true,
                    align: 'left',
                    required: true,
                },
                {
                    name: 'company',
                    label: tc('common.term.company'),
                    field: row => row.consultingSettings?.company?.company_name || '–',
                    align: 'left',
                    classes: row => !row.consultingSettings?.company?.company_name ? 'additional-info' : null,
                },
                {
                    name: 'current_account',
                    label: tc('common.accounting.account.type.current-account'),
                    align: 'left',
                    required: true,
                },
                {
                    name: 'status',
                    label: tc('common.term.status'),
                    align: 'left',
                    required: true,
                },
            ],

            // Computed
            additionalFilters,

            // Functions
            handleFormData,
            selectionRowCondition,
            resetSelectedItems,
            onUpdateSelected,
        }
    },
}
</script>

<style lang="scss" scoped>
.truncate-text {
    max-width: 40ch;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
