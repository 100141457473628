import { ActivityLogParser } from '@/parsers/activityLog'

export class CommissionTypeAccountingConfigurationActivityLogParser extends ActivityLogParser {
    static preprocess (activityLog) {
        ['commissionIncomeAccount', 'commissionExpenseAccount'].forEach(accountType => {
            const accountNumberKey = `${accountType}.number`
            const accountNameKey = `${accountType}.name`
            if (activityLog.properties.attributes && activityLog.properties.attributes[accountNumberKey] && activityLog.properties.attributes[accountNameKey]) {
                activityLog.properties.attributes[accountType] = `${activityLog.properties.attributes[accountNumberKey]} – ${activityLog.properties.attributes[accountNameKey]}`
                delete activityLog.properties.attributes[accountNumberKey]
                delete activityLog.properties.attributes[accountNameKey]
            }

            if (activityLog.properties.old && activityLog.properties.old[accountNumberKey] && activityLog.properties.old[accountNameKey]) {
                activityLog.properties.old[accountType] = `${activityLog.properties.old[accountNumberKey]} – ${activityLog.properties.old[accountNameKey]}`
                delete activityLog.properties.old[accountNumberKey]
                delete activityLog.properties.old[accountNameKey]
            }
        })
        return activityLog
    }
}
