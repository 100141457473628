<template>
    <!-- The additional "color" `btn-toggle-active` in `toggle-color` is used as a workaround to add an additional class to a button when it’s toggled. -->
    <q-btn-toggle
        v-bind="$attrs"
        :value="value"
        :options="options"
        unelevated
        no-caps
        :color="$attrs.color || 'background-secondary'"
        :text-color="$attrs.textColor || 'text-primary'"
        :toggle-color="$attrs.toggleColor || 'primary-lighter btn-toggle-active'"
        :toggle-text-color="$attrs.toggleTextColor || 'text-primary'"
        :ripple="false"
        v-on="$listeners"
    />
</template>

<script>
export default {
    name: 'BaseButtonToggle',
    props: {
        value: {
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .btn-toggle-active {
    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0;

        // TODO improvement: Add high-contrast version for better accessibility (also for the `toggle-color` above)
        border-bottom: 2px solid var(--q-color-primary-light);

        pointer-events: none;
    }

    &:first-child {
        &::after {
            border-bottom-left-radius: 3px;
        }
    }

    &:last-child {
        &::after {
            border-bottom-right-radius: 3px;
        }
    }
}

</style>
