import gql from 'graphql-tag'

export const CONTRACT_UPDATE_MUTATION = gql`
    mutation contractUpdate(
        $id: ID!
        $contract: ContractInputUpdate!
    ) {
        contractUpdate(
            id: $id
            contract: $contract
        ) {
            id
            commissionRecipientOverride {
                id
                first_name
                last_name
                contact_number
                gender
                title
                consulting_company_name
            }
        }
    }
`
