import { Model } from '@/models'

import { UserService } from '@/services'
import { UserStatus } from '@/enums/graphql'
import { UserActions } from '@/enums'
import { getUserName } from '@/helpers/user'

export class User extends Model {
    #roles = []
    #tenantSettings

    constructor (data) {
        super()
        this.aclFeatures = []
        this.aclRoles = []
        this.aclPermissions = []

        Model.initializeFields(this, [
            'id',
            'email',
            'new_email',
            'status',
            'consultant',
            'roles',
            'tenantCompany',
            'tenantSettings',

            // Timestamps
            'created_at',
            'updated_at',
        ], data)

        this.statusTransitions[UserStatus.INVITED] = [UserStatus.INACTIVE]
        this.statusTransitions[UserStatus.ACTIVE] = [UserStatus.INACTIVE]
        this.statusTransitions[UserStatus.INACTIVE] = [UserStatus.INVITED]

        this.statusActionMapping[UserStatus.INVITED] = UserActions.INVITE
        this.statusActionMapping[UserStatus.INACTIVE] = UserActions.DEACTIVATE
    }

    static get service () { return UserService }
    static status = UserStatus
    static action = UserActions

    static get objects () {
        const objectsServiceInterface = super.objects
        objectsServiceInterface.me = this.service.me
        return objectsServiceInterface
    }

    static create (email, roleIds) {
        return UserService.create(email, roleIds)
    }

    get roles () {
        return this.#roles
    }

    set roles (roles) {
        const aclRoles = []
        const aclPermissions = []

        roles.forEach(role => {
            aclRoles.push(role.key)
            if (role.permissions) {
                role.permissions.forEach(permission => {
                    if (!aclPermissions.includes(permission.key)) aclPermissions.push(permission.key)
                })
            }
        })

        this.#roles = roles
        this.aclRoles = aclRoles
        this.aclPermissions = aclPermissions
    }

    set tenantSettings (tenantSettings) {
        if (tenantSettings?.license?.packages) {
            const aclFeatures = []
            tenantSettings.license.packages.forEach(_package => {
                _package.features.forEach(feature => {
                    if (!aclFeatures.includes(feature.feature_key)) aclFeatures.push(feature.feature_key)
                })
            })
            this.aclFeatures = aclFeatures
        }
        this.#tenantSettings = tenantSettings
    }

    get tenantSettings () {
        return this.#tenantSettings
    }

    /**
     * Set ACL features.
     * Only use for development (implementing permissions/debugging).
     */
    setAclFeatures (aclFeatures) {
        aclFeatures = Array.isArray(aclFeatures) ? aclFeatures : Array.from(arguments)
        this.aclFeatures = aclFeatures
    }

    /**
     * Remove ACL features.
     * Only use for development (implementing permissions/debugging).
     */
    removeAclFeatures (aclFeatures) {
        aclFeatures = Array.isArray(aclFeatures) ? aclFeatures : Array.from(arguments)
        this.aclFeatures = this.aclFeatures.filter(feature => !aclFeatures.includes(feature))
    }

    /**
     * Set ACL roles.
     * Only use for development (implementing permissions/debugging).
     */
    setAclRoles (aclRoles) {
        aclRoles = Array.isArray(aclRoles) ? aclRoles : Array.from(arguments)
        this.aclRoles = aclRoles
    }

    /**
     * Remove ACL roles.
     * Only use for development (implementing permissions/debugging).
     */
    removeAclRoles (aclRoles) {
        aclRoles = Array.isArray(aclRoles) ? aclRoles : Array.from(arguments)
        this.aclRoles = this.aclRoles.filter(role => !aclRoles.includes(role))
    }

    /**
     * Set ACL permissions.
     * Only use for development (implementing permissions/debugging).
     */
    setAclPermissions (aclPermissions) {
        aclPermissions = Array.isArray(aclPermissions) ? aclPermissions : Array.from(arguments)
        this.aclPermissions = aclPermissions
    }

    /**
     * Remove ACL permissions.
     * Only use for development (implementing permissions/debugging).
     */
    removeAclPermissions (aclPermissions) {
        aclPermissions = Array.isArray(aclPermissions) ? aclPermissions : Array.from(arguments)
        this.aclPermissions = this.aclPermissions.filter(permission => !aclPermissions.includes(permission))
    }

    getUserName (args) {
        return getUserName(this, args)
    }

    delete () {
        return UserService.delete(this.id)
    }

    get actions () {
        const actions = []
        if (this.status === UserStatus.INVITED) {
            actions.push({
                key: UserActions.RESENDINVITE,
                item: this,
            })
        }

        if (this.status === UserStatus.ACTIVE) {
            actions.push({
                key: UserActions.CHANGE_EMAIL,
                item: this,
            })
        }

        actions.push(...super.actions)

        if (this.status === UserStatus.INACTIVE) {
            actions.push({
                key: UserActions.DELETE,
                item: this,
            })
        }
        return actions
    }

    changeEmail (email) {
        return UserService.changeEmail(this.id, email).then(user => {
            Object.assign(this, user)
            return user
        })
    }

    get hasPendingEmailChange () {
        return this.new_email !== null
    }

    resendInvitation () {
        return UserService.resendInvitation(this.id)
    }

    deactivate () {
        return UserService.deactivate(this.id).then(user => {
            this.status = user.status
            return user
        })
    }

    invite () {
        return UserService.invite(this.id).then(user => {
            this.status = user.status
            return user
        })
    }

    assignPerson (personId) {
        return UserService.assignPerson(this.id, personId).then(user => {
            this.consultant = user.consultant
            return user
        })
    }

    assignRoles (roleIds) {
        return UserService.assignRoles(this.id, roleIds).then(user => {
            this.roles = user.roles
            return user
        })
    }

    changePassword (password, newPassword, newPasswordConfirmation) {
        return UserService.changePassword(password, newPassword, newPasswordConfirmation)
    }
}
