<template>
    <page-wrapper>
        <q-breadcrumbs class="q-mt-none">
            <q-breadcrumbs-el icon="mib-alert-triangle" :label="$tc('common.term.error', 1)" />
            <q-breadcrumbs-el :label="title" />
        </q-breadcrumbs>

        <div class="text-center">
            <illustration-heading :title="errorTitle" :subtitle="errorName" img="bike-fail" />

            <p>{{ text }}</p>

            <i18n path="views.error.general.text-instructions" tag="p">
                <template v-slot:backlink>
                    <a @click="$router.go(-1)">{{ $t('views.error.general.text-instructions-back-link') }}</a>
                </template>
            </i18n>
        </div>
    </page-wrapper>
</template>

<script>
export default {
    name: 'ErrorGeneral',
    meta () {
        return {
            title: (this.errorTitle && this.errorName) ? `${this.errorTitle} (${this.errorName})` : `${this.errorTitle}`,
        }
    },
    data () {
        return {
            title: (this.$route.params.title) ? this.$route.params.title : this.$t('views.error.general.title'),
            text: (this.$route.params.text) ? this.$route.params.text : this.$t('views.error.general.text'),
        }
    },
    computed: {
        errorNumber () {
            return (this.$route.params.errorNumber) ? this.$route.params.errorNumber : false
        },
        errorName () {
            return (this.errorNumber) ? `${this.$tc('common.term.error')} ${this.errorNumber}` : ''
        },
        errorTitle () {
            return (this.$route.params.title) ? this.$route.params.title : this.$t('views.error.general.title')
        },
    },
}
</script>
