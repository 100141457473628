import gql from 'graphql-tag'

export const CONTACT_PHONE_NUMBER_UPDATE_MUTATION = gql`
    mutation contactPhoneNumberUpdate(
        $id: ID!
        $phoneNumber: PhoneNumberInput!
    ) {
        contactPhoneNumberUpdate(
            id: $id
            phoneNumber: $phoneNumber
        ) {
            id
            category {
                id
                name
            }
            label {
                id
                label
            }
            phone_number
            is_main_phone_number
        }
    }
`
