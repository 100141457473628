<template>
    <q-dialog
        ref="dialog"
        v-bind="$attrs"
        @show="onShow"
        @hide="onHide"
    >
        <q-card class="overlay-size-md">
            <q-card-section>
                <h1>{{ $tc('common.term.change-log', 1) }}</h1>

                <activity-log
                    :filter-subjects="filterSubjects"
                    :filter-include-subject-relations="filterIncludeSubjectRelations"
                />
            </q-card-section>

            <q-card-actions align="right" class="q-gutter-sm">
                <base-button
                    data-test="btn:close"
                    :label="$tc('common.term.close', 1)"
                    primary-button
                    @click="close"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import ActivityLog from '@/components/ActivityLog'

export default {
    name: 'ActivityLogOverlay',
    components: {
        ActivityLog,
    },
    data () {
        return {
            filterSubjects: [],
            filterIncludeSubjectRelations: [],
        }
    },
    methods: {
        open (configuration) {
            Object.assign(this, configuration)
            this.$refs.dialog.show()
        },
        close () {
            this.$refs.dialog.hide()
        },
        onShow () {
            this.$emit('show')
        },
        onHide () {
            this.$emit('hide')
            this.resetFilterValues()
        },
        resetFilterValues () {
            this.filterSubjects = []
            this.filterIncludeSubjectRelations = []
        },
    },
}
</script>
