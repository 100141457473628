<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.product.product-provider', 2)" :to="{ name: 'admin-product-provider-list' }" />
                <q-breadcrumbs-el :label="$t('views.admin.products.product-providers.add-product-provider-settings')" :to="{ name: 'admin-consulting-settings-create' }" />
            </template>

            <h1>{{ $t('views.admin.products.product-providers.add-product-provider-settings') }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <p>{{ $t('views.admin.products.product-providers.description-text') }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 q-gutter-y-sm">
                <product-provider-settings-form ref="productProviderSettingsForm" @cancel="onCancel" @submit="onSubmit" />
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { ProductProviderSettings } from '@/models/productProviderSettings'
import ProductProviderSettingsForm from '@/components/product/ProductProviderSettingsForm'
import { useRouter } from '@/composables/router'
import { ref } from 'vue'

export default {
    name: 'ProductProviderCreate',
    meta () {
        return {
            title: this.$t('views.admin.products.product-providers.add-product-provider-settings'),
        }
    },
    components: {
        ProductProviderSettingsForm,
    },
    setup () {
        // Composables
        const router = useRouter()

        // Template refs
        const productProviderSettingsForm = ref(null)

        // Functions
        function onCancel () {
            router.push({ name: 'admin-product-provider-list' })
        }
        function onSubmit (formData) {
            ProductProviderSettings.create(formData)
                .then(() => {
                    router.replace({ name: 'admin-product-provider-list' })
                })
                .catch(error => {
                    productProviderSettingsForm.value.formComponent.formBuilder.showFormErrorMessage(error)
                    productProviderSettingsForm.value.formComponent.formBuilder.resetFormSubmitStatus()
                })
        }

        return {
            productProviderSettingsForm,
            onCancel,
            onSubmit,
        }
    },
}
</script>
