import { Model } from '@/models'
import { CommissionSplitService } from '@/services'
import { formatInputValues } from '@/helpers/commissionSplit'
import { formatDate } from '@/helpers/date'

export class CommissionSplit extends Model {
    /**
     * Create a commissionSplit model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor(data) {
        super()
        Model.initializeFields(this, [
            'id',
            'contract',
            'additionalRecipient',
            'split_rate',
            'active_from',
            'active_until',
            'is_active',
            'created_at',
            'updated_at',
        ], data)
    }

    static get service () {
        return CommissionSplitService
    }

    static create (contractId, input) {
        return CommissionSplitService.create(contractId, formatInputValues(input))
    }

    update (input) {
        return CommissionSplitService.update(this.id, formatInputValues(input)).then(response => {
            Object.assign(this, response)
            return response
        })
    }

    delete () {
        return CommissionSplitService.delete(this.id)
    }

    get formattedActiveFrom () {
        return formatDate(this.active_from) || '–'
    }

    get formattedActiveUntil () {
        return formatDate(this.active_until) || '–'
    }

    get formattedSplitRate () {
        // Typographically correct would be to use a four-per-em space (U+2005) between the number and the % sign. / Also known as "mid space". One fourth of an em wide.
        return `${this.split_rate}\u2005%`
    }
}
