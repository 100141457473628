<template>
    <q-menu
        anchor="bottom start"
        self="top start"
    >
        <q-list dense>
            <q-item v-close-popup clickable @click="copyToClipboard(`${iconPrefix}${icon}`)">
                <q-item-section class="q-pt-sm q-pb-sm">
                    <q-item-label>{{ $t('views.dev.design.icons.copy-icon-name') }}</q-item-label>
                </q-item-section>
            </q-item>
            <q-separator />
            <q-item v-close-popup clickable>
                <q-item-section @click='copyToClipboard(`<q-icon name="${iconPrefix}${icon}" />`)'>{{ $t('views.dev.design.icons.copy-q-icon-component') }}</q-item-section>
            </q-item>
            <q-item v-close-popup clickable>
                <q-item-section @click='copyToClipboard(`<q-icon name="${iconPrefix}${icon}" color="${iconColor}" />`)'>{{ $t('views.dev.design.icons.copy-q-icon-component-including-color') }}</q-item-section>
            </q-item>
            <!-- TODO improvement @MTR: Add "Copy (round flat) action button; (including :to and tooltip?)" -->
            <q-separator />
            <q-item class="bg-background-primary">
                <q-item-section>
                    <q-item-label caption>{{ $tc('common.term.icon', 1) }}: <code>{{ `${iconPrefix}${icon}` }}</code></q-item-label>
                </q-item-section>
            </q-item>
        </q-list>
    </q-menu>
</template>

<script>
import { copyToClipboard } from 'quasar'

export default {
    name: 'DevIconContextMenu',
    props: {
        icon: {
            type: String,
            required: true,
        },
        iconPrefix: {
            type: String,
            default: 'mib-',
        },
        iconColor: {
            type: String,
            default: 'text-primary',
        },
    },
    methods: {
        copyToClipboard (content) {
            content = content.replace(/<br>/g, '\n')

            copyToClipboard(content)
                .then(() => {
                    this.$q.notify({
                        type: 'positive',
                        message: this.$t('common.notifications.copied-to-clipboard-success'),
                        caption: content,
                    })
                })
                .catch(() => {
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$t('common.notifications.copied-to-clipboard-error'),
                    })
                })
        },
    },
}
</script>
