import { kebabCase } from 'lodash'
import { Form, FormField } from '@/libs/form'
import { ContractManagedByStatus } from '@/enums/graphql'
import { requiredIf } from '@vuelidate/validators'
import { computed, watch } from 'vue'
import { i18n } from '@/i18n'
import { isAfterOrEqualToDate, isBeforeOrEqualToDate } from '@/libs/form/validators'

export function addCommonContractInformationFields (formInstance) {
    formInstance.addField(new FormField('startDate', {
        label: 'common.contract.contract-start',
        inputType: 'calendar',
        validators: [
            { type: 'date' },
            {
                type: 'isBeforeOrEqualToDate',
                validator: () => {
                    return isBeforeOrEqualToDate(formInstance.getItem('startDate').value, formInstance.getItem('endDate').value)
                },
                params: {
                    targetLabel: 'common.contract.contract-end',
                },
            },
        ],
    }))

    formInstance.addField(new FormField('endDate', {
        label: 'common.contract.contract-end',
        inputType: 'calendar',
        validators: [
            { type: 'date' },
            {
                type: 'isAfterOrEqualToDate',
                validator: () => {
                    return isAfterOrEqualToDate(formInstance.getItem('endDate').value, formInstance.getItem('startDate').value)
                },
                params: {
                    targetLabel: 'common.contract.contract-start',
                },
            },
        ],
    }))

    formInstance.addField(new FormField('premium', {
        label: 'common.contract.premium',
        inputType: 'text',
        validators: [
            { type: 'decimal' },
            {
                type: 'minValue',
                params: {
                    min: 0,
                },
            },
        ],
    }))

    formInstance.addField(new FormField('generalAgencyId', {
        label: 'forms.field.label.application.managed-by',
        inputType: 'generalAgency',
    }))
}

export class ProductDerivedEntityForm extends Form {
    compose () {
        this.addField(new FormField('consultantId', {
            label: 'common.contact.consultant',
            inputType: 'consultant',
            validators: [
                { type: 'required' },
            ],
        }))

        // Contract information
        addCommonContractInformationFields(this)

        const managedByStatusOptions = Object.values(ContractManagedByStatus).map(value => ({ value: value, label: `forms.field.label.application.managed-by-status.${kebabCase(value)}` }))
        this.addField(new FormField('managedByStatus', {
            label: 'forms.field.label.application.managed-by-status.managed-by-status',
            inputType: 'select',
            extra_attributes: {
                list: {
                    options: managedByStatusOptions,
                },
            },
            validators: [
                { type: 'required' },
            ],
        }))

        const consultingMandateEnquirySendDateRequiredIfCondition = () => {
            return [ContractManagedByStatus.CONSULTING_MANDATE_SUBMISSION_PENDING, ContractManagedByStatus.CONSULTING_MANDATE_CANCELLATION_PENDING].includes(this.getItem('managedByStatus').value)
        }
        this.addField(new FormField('consultingMandateEnquirySendDate', {
            label: 'forms.field.label.application.submission-date', // Note: The label depends on the status; it is overwritten in the UI.
            inputType: 'calendar',
            validators: [
                { type: 'date' },
                {
                    type: 'requiredIfSpecificValues',
                    validator: requiredIf(consultingMandateEnquirySendDateRequiredIfCondition),
                    requiredIfCondition: consultingMandateEnquirySendDateRequiredIfCondition,
                    // TODO translation @MTR: improvement: Adjust message according to dynamic label (see comment on `label` above)
                    errorMessage: i18n.t('common.error-message.required', { field: i18n.tc('forms.field.label.application.submission-date', 1) }),
                },
            ],
            attributes: {
                disable: computed(() => {
                    return ![ContractManagedByStatus.CONSULTING_MANDATE_SUBMISSION_PENDING, ContractManagedByStatus.CONSULTING_MANDATE_CANCELLATION_PENDING].includes(this.getItem('managedByStatus').value)
                }),
            },
        }))
        watch(() => this.getItem('managedByStatus').value, (newValue, oldValue) => {
            if (oldValue !== null) {
                this.getItem('consultingMandateEnquirySendDate').resetValue()
            }
        })
    }
}
