<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.commission.commission-list.commission-list', 2)" :to="{ name: 'commission-lists' }" />
                <q-breadcrumbs-el :label="$tc('views.commission.commission-list.upload-commission-list', 1)" :to="{ name: 'commission-list-create' }" />
            </template>

            <h1>{{ $tc('views.commission.commission-list.upload-commission-list', 1) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12 col-lg-8 offset-lg-2">
                <q-stepper
                    v-model="currentStep"
                    alternative-labels
                    :vertical="$q.screen.lt.md"
                    flat
                    class="commission-list-create-stepper-wrapper"
                >
                    <q-step
                        name="configuration"
                        :title="$tc('views.commission.commission-list.select-file-type', 1)"
                        caption=""
                        icon="mib-pencil-write-3-alternate"
                        active-icon="mib-pencil-write-3-alternate"
                        done-icon="mib-check"
                        done-color="secondary"
                        :done="currentStep !== 'configuration'"
                    >
                        <form
                            @submit.prevent="onConfigurationFormSubmit"
                        >
                            <q-btn-toggle
                                v-model="listType"
                                :options="[
                                    {
                                        value: CommissionListType.EXCEL,
                                        slot: CommissionListType.EXCEL.toLowerCase()
                                    },
                                    {
                                        value: CommissionListType.XML,
                                        slot: CommissionListType.XML.toLowerCase()
                                    },
                                ]"
                                unelevated
                                no-caps
                                flat
                                :ripple="false"
                                spread
                            >
                                <template v-slot:excel>
                                    <div class="items-center text-center no-wrap">
                                        <illustration
                                            name="max-file-check--excel"
                                            width="10vw"
                                            min-width="8rem"
                                            max-width="11rem"
                                            faded
                                            interactive
                                            :selected="listType === CommissionListType.EXCEL"
                                        />
                                        <div class="text-center">
                                            <q-radio
                                                v-model="listType"
                                                :val="CommissionListType.EXCEL"
                                                :label="$tc(`common.file.type.excel`, 1)"
                                            />
                                        </div>
                                    </div>
                                </template>

                                <template v-slot:xml>
                                    <div class="items-center text-center no-wrap">
                                        <illustration
                                            name="max-file-check--xml"
                                            width="10vw"
                                            min-width="8rem"
                                            max-width="11rem"
                                            faded
                                            interactive
                                            :selected="listType === CommissionListType.XML"
                                        />
                                        <div class="text-center">
                                            <q-radio
                                                v-model="listType"
                                                :val="CommissionListType.XML"
                                                :label="$tc('common.file.type.xml', 1)"
                                            />
                                        </div>
                                    </div>
                                </template>
                            </q-btn-toggle>

                            <div class="col-xs-12 q-mt-lg text-center">
                                <info-box type="secondary" auto-width centered>
                                    <i18n path="views.commission.commission-list.select-file-type--user-choose-action-sentence" tag="p">
                                        <template v-slot:fileType>
                                            <span :class="['gap-text', { 'is-empty': !listType }]"><template v-if="listType">{{ $t(`views.commission.commission-list.select-file-type--user-choose-action-sentence--${listType.toLowerCase()}`) }}</template></span>
                                        </template>
                                    </i18n>
                                </info-box>

                                <base-button
                                    data-test="btn:submit"
                                    type="submit"
                                    :label="$t('common.term.continue')"
                                    :disable="!listType"
                                    primary-button
                                />
                            </div>
                        </form>
                    </q-step>

                    <q-step
                        name="form"
                        :title="$t('views.commission.commission-list.settings-and-file-upload')"
                        caption=""
                        icon="mib-common-file-upload"
                        active-icon="mib-common-file-upload"
                        done-icon="mib-check"
                        done-color="secondary"
                    >
                        <commission-list-xml-form
                            v-if="listType === CommissionListType.XML"
                            :ref="CommissionListType.XML"
                            @post-handle-form-data="onXmlFormSubmit"
                        />
                        <commission-list-excel-form
                            v-else
                            :ref="CommissionListType.EXCEL"
                            @post-handle-form-data="onExcelFormSubmit"
                        />
                    </q-step>
                </q-stepper>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import CommissionListXmlForm from '@/components/commission/CommissionListXmlForm.vue'
import CommissionListExcelForm from '@/components/commission/CommissionListExcelForm.vue'
import { CommissionList } from '@/models/commissionList'
import { CommissionListType } from '@/enums/graphql'

export default {
    name: 'CommissionListImportExcelMappingCreate',
    meta () {
        return {
            title: this.$tc('views.commission.commission-list.upload-commission-list', 1),
        }
    },
    components: {
        CommissionListXmlForm,
        CommissionListExcelForm,
    },
    data () {
        return {
            CommissionListType,
            currentStep: 'configuration',
            listType: null,
        }
    },
    methods: {
        onConfigurationFormSubmit () {
            this.currentStep = 'form'
        },
        onXmlFormSubmit (formData, triggerImport) {
            const submitData = {
                type: formData.type,
                commissionList: {
                    product_provider_id: formData.productProviderId,
                },
                file: formData.file,
                triggerImport: triggerImport,
            }
            this.handleSubmit(submitData)
        },
        onExcelFormSubmit (formData, triggerImport) {
            const submitData = {
                type: formData.type,
                commissionList: {
                    commission_total: parseFloat(formData.commissionTotal),
                    excel_mapping_id: formData.excelMappingId,
                    exchange_date_from: formData.exchangeDateFrom,
                    exchange_date_to: formData.exchangeDateTo,
                    product_provider_id: formData.productProviderId,
                },
                file: formData.file,
                triggerImport: triggerImport,
            }
            this.handleSubmit(submitData)
        },
        handleSubmit ({ type, commissionList, file, triggerImport }) {
            CommissionList.create({ type, commissionList, file, triggerImport})
                .then(() => {
                    this.$router.replace({ name: 'commission-lists' })
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.commission.commission-list-uploaded-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs[type].showFormErrorMessage(error)
                    this.$refs[type].resetFormSubmitStatus()
                })
        },
        toKebabCase: function (text) {
            return kebabCase(text)
        },
    },
}
</script>
