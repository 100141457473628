import { FormField } from '@/libs/form'
import { ContactForm } from '@/forms/contact/contact'
import { CivilStatus, GenderChoice } from '@/enums/graphql'
import { kebabCase } from 'lodash'

export class PersonForm extends ContactForm {
    compose () {
        super.compose()

        const genderOptions = [
            { label: 'common.term.please-choose', value: null, disable: true },
            ...Object.values(GenderChoice).map(value => ({ label: `common.contact.gender-${kebabCase(value.toLowerCase())}`, value: value })),
        ]
        this.addField(new FormField('gender', {
            label: 'common.contact.gender',
            inputType: 'select',
            validators: [
                { type: 'required' },
            ],
            extra_attributes: {
                list: {
                    options: genderOptions,
                },
            },
        }))

        this.addField(new FormField('title', {
            label: 'common.term.title',
            inputType: 'text',
        }))

        this.addField(new FormField('firstName', {
            label: 'common.contact.first-name',
            inputType: 'text',
            validators: [
                { type: 'required' },
            ],
        }))

        this.addField(new FormField('lastName', {
            label: 'common.contact.last-name',
            inputType: 'text',
            validators: [
                { type: 'required' },
            ],
        }))

        this.addField(new FormField('profession', {
            label: 'common.contact.profession',
            inputType: 'text',
        }))

        this.addField(new FormField('role', {
            label: 'common.contact.role',
            inputType: 'text',
        }))

        this.addField(new FormField('nationality', {
            label: 'common.contact.nationality',
            inputType: 'country',
        }))

        const civilStatusOptions = [
            { label: 'common.term.please-choose', value: null },
            ...Object.values(CivilStatus).map(value => ({ label: `common.contact.civil-status.${kebabCase(value.toLowerCase())}`, value: value })),
        ]
        this.addField(new FormField('civilStatus', {
            label: 'common.contact.civil-status.civil-status',
            inputType: 'select',
            extra_attributes: {
                list: {
                    options: civilStatusOptions,
                },
            },
        }))

        this.addField(new FormField('dateOfBirth', {
            label: 'common.contact.date-of-birth',
            inputType: 'calendar',
            validators: [
                { type: 'date' },
            ],
        }))

        this.addField(new FormField('drivingLicenceSince', {
            label: 'common.contact.driving-license-since',
            inputType: 'calendar',
            validators: [
                { type: 'date' },
            ],
        }))

        this.addField(new FormField('personalPronoun', {
            label: 'common.contact.personal-pronouns',
            inputType: 'text',
        }))
    }
}
