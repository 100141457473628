import { i18n } from '@/i18n'
import kebabCase from 'lodash.kebabcase'
import { lightFormat, formatISO } from 'date-fns'

const dateFormats = [
    { php: 'd.m.Y', jsDateFns: 'dd.MM.yyyy' },
    { php: 'j.n.Y', jsDateFns: 'd.M.yyyy' },
    { php: 'Y-m-d', jsDateFns: 'yyyy-MM-dd' },
    { php: 'c', jsDateFns: 'ISO 8601' }, // use `formatISO()` for last option
]
const options = [
    { label: `${i18n.t(`common.time.date-format--${kebabCase(dateFormats[0].jsDateFns)}`)} <i class="additional-info">(${lightFormat(new Date(), dateFormats[0].jsDateFns)})</i>`, value: dateFormats[0].php },
    { label: `${i18n.t(`common.time.date-format--${kebabCase(dateFormats[1].jsDateFns)}`)} <i class="additional-info">(${lightFormat(new Date(), dateFormats[1].jsDateFns)})</i>`, value: dateFormats[1].php },
    { label: `${i18n.t(`common.time.date-format--${kebabCase(dateFormats[2].jsDateFns)}`)} <i class="additional-info">(${lightFormat(new Date(), dateFormats[2].jsDateFns)})</i>`, value: dateFormats[2].php },
    { label: `${i18n.t(`common.time.date-format--${kebabCase(dateFormats[3].jsDateFns)}`)} <i class="additional-info">(${formatISO(new Date())})</i>`, value: dateFormats[3].php },
]

export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'productProvider',
            label: 'common.product.product-provider',
            inputType: 'productProvider',
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'name',
            label: 'views.commission.excel-mapping.mapping-name',
            inputType: 'text',
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'commissionEntriesStartRow',
            label: 'views.commission.excel-mapping.start-row',
            infoText: 'views.commission.excel-mapping.start-row--description',
            inputType: 'number',
            validators: [
                { type: 'required' },
                {
                    'type': 'minValue',
                    'params': {
                        'min': 0,
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'contractNumberColumn',
            label: 'views.commission.excel-mapping.contract-number-column',
            inputType: 'text',
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'commissionTypeColumn',
            label: 'views.commission.excel-mapping.commission-type-column',
            inputType: 'text',
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'commissionAmountColumn',
            label: 'views.commission.excel-mapping.commission-amount-column',
            inputType: 'text',
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'premiumFromColumn',
            label: 'views.commission.excel-mapping.premium-from-column',
            inputType: 'text',
            validators: [
                // TODO @TFU: General behavior of "multi dependency" validations: Only show errors that really occur. (Currently, _all_ errors are shown even if only one of them is true.)
                {
                    type: 'requiredIf',
                    params: {
                        'target': 'premiumToDateFormat',
                        'targetLabel': 'views.commission.excel-mapping.premium-to-date-format',
                    },
                },
                {
                    type: 'requiredIf',
                    params: {
                        'target': 'premiumToColumn',
                        'targetLabel': 'views.commission.excel-mapping.premium-to-column',
                    },
                },
                {
                    type: 'requiredIf',
                    params: {
                        'target': 'premiumFromDateFormat',
                        'targetLabel': 'views.commission.excel-mapping.premium-from-date-format',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'premiumFromDateFormat',
            label: 'views.commission.excel-mapping.premium-from-date-format',
            inputType: 'select',
            extra_attributes: {
                list: {
                    options: options,
                },
            },
            validators: [
                {
                    type: 'requiredIf',
                    params: {
                        'target': 'premiumFromColumn',
                        'targetLabel': 'forms.field.label.commission.premium-from-column',
                    },
                },
                {
                    type: 'requiredIf',
                    params: {
                        'target': 'premiumToColumn',
                        'targetLabel': 'forms.field.label.commission.premium-to-column',
                    },
                },
                {
                    type: 'requiredIf',
                    params: {
                        'target': 'premiumToDateFormat',
                        'targetLabel': 'views.commission.excel-mapping.premium-to-date-format',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'premiumToColumn',
            label: 'views.commission.excel-mapping.premium-to-column',
            inputType: 'text',
            validators: [
                {
                    type: 'requiredIf',
                    params: {
                        'target': 'premiumFromColumn',
                        'targetLabel': 'views.commission.excel-mapping.premium-from-column',
                    },
                },
                {
                    type: 'requiredIf',
                    params: {
                        'target': 'premiumToDateFormat',
                        'targetLabel': 'views.commission.excel-mapping.premium-to-date-format',
                    },
                },
                {
                    type: 'requiredIf',
                    params: {
                        'target': 'premiumFromDateFormat',
                        'targetLabel': 'views.commission.excel-mapping.premium-from-date-format',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'premiumToDateFormat',
            label: 'views.commission.excel-mapping.premium-to-date-format',
            inputType: 'select',
            extra_attributes: {
                list: {
                    options: options,
                },
            },
            validators: [
                {
                    type: 'requiredIf',
                    params: {
                        'target': 'premiumFromColumn',
                        'targetLabel': 'views.commission.excel-mapping.premium-from-column',
                    },
                },
                {
                    type: 'requiredIf',
                    params: {
                        'target': 'premiumToColumn',
                        'targetLabel': 'views.commission.excel-mapping.premium-to-column',
                    },
                },
                {
                    type: 'requiredIf',
                    params: {
                        'target': 'premiumFromDateFormat',
                        'targetLabel': 'views.commission.excel-mapping.premium-from-date-format',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'customerFullNameColumn',
            label: 'views.commission.excel-mapping.insuree-name-column',
            inputType: 'text',
            validators: [
                { type: 'required' },
            ],
        },
    ],
}
