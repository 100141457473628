import { FormBase, FormCore } from '@/libs/form/base'
import { computed, ref } from 'vue'
import { initializeFormValidations } from '@/libs/form/helpers'
import useVuelidate from '@vuelidate/core'

export class FormRepeater extends FormCore {
    __type = 'repeater'
    #items
    #state
    #data
    #validations
    #isDirty
    #hasError

    constructor (key, configuration = {}) {
        super(key, configuration)
        this.#items = ref([])

        // Data
        this.#state = ref([])
        this.#data = computed(() => {
            return this.items.map(item => item.data)
        })

        // Validations
        const validators = (typeof this.configuration.validators !== 'undefined' && this.configuration.validators.length > 0) ? this.configuration.validators : [{ type: 'dirtyChecker' }]
        const validations = initializeFormValidations(validators)
        this.#validations = useVuelidate(validations, this.state, { $scope: this.id, $stopPropagation: true, $autoDirty: true, $lazy: true })

        // Dirty state
        this.#isDirty = computed(() => {
            // TODO: Add validation on this level.
            return this.items.some(item => item.isDirty)
        })
        // computed(() => this.validations.$anyDirty || this.items.some(repeaterItem => repeaterItem.isDirty))

        // Error state
        this.#hasError = computed(() => {
            // TODO: Add validation on this level.
            return this.items.some(item => item.hasError)
        })
        // computed(() => this.validations.$error || this.items.some(repeaterItem => repeaterItem.hasError))
    }

    get state () {
        return this.#state.value
    }

    get data () {
        return this.#data.value
    }

    get items () {
        return this.#items.value
    }

    get validations () {
        return this.#validations.value
    }

    get isDirty () {
        return this.#isDirty.value
    }

    get hasError () {
        return this.#hasError.value
    }

    setData (data) {
        this.items.forEach(item => {
            if (Object.hasOwn(data, item.key)) item.setData(data[item.key])
        })
        data.forEach(itemData => {
            const RepeaterItemClass = this.configuration.repeaterItemClass
            const repeaterItem = new RepeaterItemClass(this.key, {}, this)
            repeaterItem.setData(itemData)
            this.items.push(repeaterItem)
            this.state.push(repeaterItem.data)
        })
    }

    addItem () {
        const RepeaterItemClass = this.configuration.repeaterItemClass
        const repeaterItem = new RepeaterItemClass(this.key, {}, this)
        if (this.configuration.initialData) repeaterItem.setData(this.configuration.initialData)
        repeaterItem.meta.depth = this.meta.depth + 1
        this.items.push(repeaterItem)
        this.state.push(repeaterItem.data)
    }

    reset () {
        this.items.length = 0
        this.validations.$reset()
    }
}

export class FormRepeaterItem extends FormBase {
    __type = 'repeaterItem'

    constructor (key, configuration = {}, repeater) {
        super(key, configuration)
        this.repeater = repeater
    }

    remove () {
        if (this.index >= 0 && this.index < this.repeater.items.length) {
            this.repeater.items.splice(this.index, 1)
            this.repeater.state.splice(this.index, 1)
        }
    }

    get index () {
        return this.repeater.items.findIndex(repeaterItem => repeaterItem.id === this.id)
    }
}
