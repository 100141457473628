<template>
    <div
        :class="['markup-table-td', `markup-table-td--${column.name || 'unnamed'}`, column.class, `text-${column.align || 'left'}`, { 'auto-width': column.autoWidth }]"
        :style="cellStyle || null"
        role="cell"
        :aria-labelledby="ariaLabelledBy"
    >
        <slot name="default">
            <slot :name="`body-cell-${column.name}`" :row="row">
                <template v-if="typeof column.field === 'function'">{{ column.field(row) }}</template>
                <template v-else-if="typeof row[column.name] !== 'undefined'">{{ row[column.name] }}</template>
            </slot>
        </slot>
    </div>
</template>

<script>
export default {
    name: 'MarkupTableCell',
    props: {
        column: {
            type: Object,
            default: () => {
                return {}
            },
        },
        row: {
            type: Object,
            default: () => {
                return {}
            },
        },
        ariaLabelledby: {
            type: String,
            default: '',
        },
        colSpan: {
            type: Number,
            default: null,
        },
        gridColumnStart: {
            type: Number,
            default: null,
        },
        gridColumnEnd: {
            type: Number,
            default: null,
        },
    },
    computed: {
        ariaLabelledBy () {
            if (this.ariaLabelledby) {
                return this.ariaLabelledby
            } else if (this.column?.name) {
                return `markup-table-th-${this.column.name}`
            }

            return null
        },
        cellStyle () {
            let style = []

            if (this.colSpan) {
                let spanStart = 1
                let spanEnd = -1

                if (this.gridColumnStart && typeof this.gridColumnStart === 'number') {
                    spanStart = this.gridColumnStart
                    spanEnd = spanStart + this.colSpan
                }

                style.push(`grid-column-start: ${spanStart};`)
                style.push(`grid-column-end: ${spanEnd};`)
            }
            if (this.gridColumnStart) style.push(`grid-column-start: ${this.gridColumnStart};`)
            if (this.gridColumnEnd) style.push(`grid-column-end: ${this.gridColumnEnd};`)

            return style.join(' ')
        },
    },
}
</script>
