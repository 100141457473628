<template>
    <page-loading-indicator
        v-if="state.isLoading"
        :number-of-breadcrumb-elements="3"
        subtitle
        actions
        settings-button
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="state.application.customer.getContactName()" :to="{ name: 'contact-detail', params: { id: contactId } }" data-test="breadcrumb:contact-detail" />
                <q-breadcrumbs-el :label="$tc('common.term.contract', 2)" :to="{ name: 'contact-detail', params: { id: contactId }}" data-test="breadcrumb:contracts" />
                <q-breadcrumbs-el :label="state.application.formattedNumber" :to="{ name: 'application-detail', params: { id: state.application.id } }" data-test="breadcrumb:application-detail">
                    <status-badge :status="state.application.is_change_application ? 'change-application' : 'application'" default-translation-base-path="common.status.application-status" class="q-ml-xs" />
                    <contract-status-badge :contract="state.application" default-translation-base-path="common.status.application-status" />
                </q-breadcrumbs-el>
            </template>

            <h1>{{ state.application.formattedNumber }} <span class="subtitle">({{ state.application.currentContractInformation?.product?.providerName }})</span></h1>

            <router-link
                v-if="store.state.editMode"
                data-test="btn:update-contract-info"
                :to="{ name: 'application-update', params: { contactId: state.application.customer.id, id: state.application.id } }"
                class="edit-link q-link do-not-print"
            >
                <q-icon name="mib-pencil" class="q-mr-xs" />
                {{ $tc('common.application.edit-application-info', 2) }}
            </router-link>

            <template v-slot:actions>
                <actions
                    v-if="state.application.actions"
                    :actions="state.application.actions"
                    @click="handleActions"
                />

                <!-- Submitted to ProductProvider -->
                <form-dialog
                    ref="applicationSubmitToProductProviderDialog"
                    :form="ApplicationSubmissionSendDateForm"
                    :form-context="{ application: state.application }"
                    @submit="handleApplicationSubmitToProductProvider"
                >
                    <template v-slot:title>{{ $tc(`common.actions.application.${state.application.status === ApplicationStatus.ON_HOLD ? '' : 're-'}submit-to-product-provider`, 1) }}</template>
                    <template v-slot:default>
                        <i18n :path="`common.application.${state.application.status === ApplicationStatus.ON_HOLD ? '' : 're-'}submit-application-to-product-provider--confirm--temp-component-interpolation-count`" tag="p">
                            <template v-slot:referenceNumber><b>{{ state.application.formattedNumber }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Set declined by ProductProvider -->
                <form-dialog
                    ref="applicationSetDeclinedByProductProviderDialog"
                    :form="ApplicationSubmissionResponseDateForm"
                    :form-context="{ application: state.application }"
                    double-confirm
                    double-confirm-alt-label
                    @submit="handleApplicationSetDeclinedByProductProvider"
                >
                    <template v-slot:title>{{ $tc('common.actions.application.set-declined-by-product-provider', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.application.set-declined-by-product-provider--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:referenceNumber><b>{{ state.application.formattedNumber }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Submitted counter proposal to customer -->
                <form-dialog
                    ref="applicationSubmitCounterProposalToCustomerDialog"
                    :form="ApplicationSubmissionResponseDateForm"
                    :form-context="{ application: state.application, action: ApplicationActions.SUBMIT_COUNTER_PROPOSAL_TO_CUSTOMER }"
                    @submit="handleApplicationSubmitCounterProposalToCustomer"
                >
                    <template v-slot:title>{{ $tc('common.actions.application.submit-counter-proposal-to-customer', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.application.submit-counter-proposal-to-customer-confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:referenceNumber><b>{{ state.application.formattedNumber }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Set counter proposal declined by customer -->
                <form-dialog
                    ref="applicationSetCounterProposalDeclinedByCustomerDialog"
                    double-confirm
                    double-confirm-alt-label
                    @submit="handleApplicationSetCounterProposalDeclinedByCustomer"
                >
                    <template v-slot:title>{{ $tc('common.actions.application.set-counter-proposal-declined-by-customer', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.application.set-counter-proposal-declined-by-customer--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:referenceNumber><b>{{ state.application.formattedNumber }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Withdraw Application -->
                <form-dialog
                    ref="applicationWithdrawDialog"
                    double-confirm
                    double-confirm-alt-label
                    @submit="handleApplicationWithdraw"
                >
                    <template v-slot:title>{{ $tc('common.actions.application.withdraw', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.application.withdraw--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:referenceNumber><b>{{ state.application.formattedNumber }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Delete Application -->
                <form-dialog
                    ref="applicationDeleteDialog"
                    double-confirm
                    @submit="handleApplicationDelete"
                >
                    <template v-slot:title>{{ $tc('common.actions.application.delete', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.application.delete-application--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:referenceNumber><b>{{ state.application.formattedNumber }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>

            <template v-if="state.application.couldUpdateProductTemplateVersion" v-slot:afterContent>
                <product-template-diff :product-derived-entity="state.application" />
            </template>
        </page-header>

        <div v-if="state.application.statusInfo.text" class="row q-mb-md">
            <div class="col-xs-12">
                <info-box :type="state.application.statusInfo.type" no-margin inline-actions>
                    <p>{{ state.application.statusInfo.text }} <template v-if="state.application.targetContract?.currentContractNumber"><br>({{ $tc('common.contract.contract-number', 1) }}: <b>{{ state.application.targetContract.currentContractNumber }}</b>)</template></p>

                    <template v-slot:action>
                        <base-button
                            v-if="state.application.targetContract"
                            :to="{ name: 'contract-detail', params: { contactId: state.application.customer.id, id: state.application.targetContract.id } }"
                            :label="$tc('common.application.go-to-contract', 1)"
                            outline
                            primary-button
                        />
                    </template>
                </info-box>
            </div>
        </div>

        <div class="row print-row-lg q-col-gutter-md entity-info-wrapper">
            <grid-card
                :columns="{
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 4,
                    xl: 4,
                }"
                class="entity-info contact-info"
                data-test="wrapper:contact-info"
            >
                <address data-test="wrapper:contact-main-address">
                    {{ state.application.customer.getContactName({ salutation: true, title: true }) }}<br>
                    {{ state.application.customer.getContactAddress(state.application.customer.mainAddress, { multiline: true }) }}
                </address>

                <template v-slot:actions>
                    <div v-can="'Feature:correspondence:core'" class="do-not-print">
                        <!-- TODO: Enable/disable button -->
                        <correspondence-item-create-button
                            :correspondence-item-target-object-type="CorrespondenceItemTargetObjectType.APPLICATION"
                            :correspondence-item-target-object-id="state.application.id"
                            :disable-email="!state.application.customer.emailAddresses.length"
                            :disabled="!state.application.consultantAlsoConsultsCustomer"
                        />
                        <q-tooltip v-if="!state.application.consultantAlsoConsultsCustomer" :delay="1000" :offset="[0, 10]">{{ $t('common.status.code.error.sender.no_access') }}</q-tooltip>
                    </div>

                </template>
            </grid-card>

            <grid-card
                :columns="{
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 8,
                    xl: 8,
                }"
                class="entity-info application-info"
                data-test="wrapper:application-info"
            >
                <dl class="q-mb-none">
                    <dt>{{ $tc('common.term.product', 1) }}: </dt>
                    <dd data-test="text:contract-product-name">{{ state.application.currentContractInformation?.product.name }}</dd>
                    <br>

                    <dt>{{ $tc('common.product.product-provider', 1) }}: </dt>
                    <dd data-test="text:contract-provider-name">{{ state.application.currentContractInformation?.product.providerName }}</dd>
                    <br>

                    <dt>{{ $tc('common.contact.consultant', 1) }}: </dt>
                    <dd data-test="text:contract-consultant">{{ state.application.consultant.getContactName({ consultingCompanyName: !state.application.consultant.worksForUserConsultingCompany }) }}</dd>
                    <br>

                    <dt>{{ $tc('views.contract.settings.commission.commission-recipient-override.commission-recipient', 1) }}: </dt>
                    <dd data-test="text:contract-commission-recipient" :class="[{ 'additional-info': !state.application.commissionRecipientOverride }]">{{ state.application.commissionRecipientOverride ? state.application.commissionRecipientOverride.getContactName({ consultingCompanyName: !state.application.commissionRecipientOverride.worksForUserConsultingCompany }) : state.application.consultant.getContactName({ consultingCompanyName: !state.application.consultant.worksForUserConsultingCompany }) }}
                        <info-icon
                            v-if="state.application.commissionRecipientOverride"
                            :text="$t('views.contract.settings.commission.commission-recipient-override.contract-has-override-text')"
                            class="q-ml-xs"
                        />
                    </dd>
                </dl>

                <dl class="q-mb-none">
                    <dt>{{ $t('common.contract.contract-start') }}: </dt>
                    <dd data-test="text:contract-start" :class="getFormattedFieldValue(state.application.currentContractInformation?.start_date).cssClass">{{ getFormattedFieldValue(state.application.currentContractInformation?.formattedStartDate).value }}</dd>
                    <br>

                    <dt>{{ $t('common.contract.contract-end') }}: </dt>
                    <dd data-test="text:contract-end" :class="getFormattedFieldValue(state.application.currentContractInformation?.end_date).cssClass">{{ getFormattedFieldValue(state.application.currentContractInformation?.formattedEndDate).value }}</dd>
                    <br>

                    <dt v-if="state.application.termination_date">{{ $t('common.contract.termination-date') }}: </dt>
                    <dd v-if="state.application.termination_date" data-test="text:contract-termination">{{ state.application.formattedTerminationDate }}</dd>
                    <br v-if="state.application.termination_date">

                    <dt>{{ $t('common.contract.premium') }}: </dt>
                    <dd data-test="text:contract-premium" :class="getFormattedFieldValue(state.application.currentContractInformation?.premium).cssClass">{{ getFormattedFieldValue(state.application.currentContractInformation?.formattedPremium).value }}</dd>
                </dl>

                <dl v-if="state.application.sourceContract || state.application.on_hold_until || state.application.submission_send_date || state.application.targetContract" class="q-mb-none">
                    <template v-if="state.application.sourceContract">
                        <dt>{{ $tc('common.application.change-application-for', 1) }}: </dt>
                        <dd><router-link :to="{ name: 'contract-detail', params: { contactId: state.application.customer.id, id: state.application.sourceContract.id } }">{{ state.application.sourceContract.currentContractNumber }}</router-link></dd>
                        <br>
                    </template>

                    <template v-if="state.application.on_hold_until">
                        <dt>{{ $t('common.application.on-hold-until') }}: </dt>
                        <dd :class="{ 'is-overdue': state.application.statusInfo.isOverdue }" data-test="text:on-hold-until">{{ state.application.formattedOnHoldUntil }}</dd>
                        <br v-if="state.application.submission_send_date">
                    </template>

                    <template v-if="state.application.submission_send_date">
                        <dt>{{ $t('common.application.submission-send-date') }}: </dt>
                        <dd :class="{ 'additional-info': !state.application.formattedSubmissionSendDate, 'is-overdue' : !state.application.formattedSubmissionResponseDate && state.application.statusInfo.isOverdue }" data-test="text:submission-send-date">{{ state.application.formattedSubmissionSendDate }}</dd>
                        <br>

                        <dt>{{ state.application.statusInfo.submissionResponseDateLabel }}: </dt>
                        <dd :class="{ 'additional-info': !state.application.formattedSubmissionResponseDate, 'is-overdue' : state.application.formattedSubmissionResponseDate && state.application.statusInfo.isOverdue }" data-test="text:submission-response-date">{{ state.application.formattedSubmissionResponseDate || '–' }}</dd>
                    </template>
                </dl>
            </grid-card>
        </div>

        <div class="row q-mt-md q-col-gutter-md">
            <div class="col-xs-12">
                <main-tabs>
                    <q-tabs
                        v-model="state.activeTab"
                        dense
                        no-caps
                        inline-label
                        align="left"
                        class="bg-grey-2"
                        indicator-color="accent"
                        data-test="tabs:links"
                    >
                        <q-route-tab
                            name="application-details"
                            :to="{ name: 'application-detail', params: { contactId: contactId, id: id } }"
                            icon="mib-common-file-text-alternate"
                            :label="$tc('common.application.application-detail', 2)"
                            data-test="tab:details"
                        />
                        <q-route-tab
                            name="documents"
                            :to="{ name: 'application-detail', params: { contactId: contactId, id: id, tab: 'documents' } }"
                            icon="mib-common-file-stack-alternate"
                            :label="$tc('common.term.document', 2)"
                            data-test="tab:documents"
                        >
                            <q-badge
                                v-if="state.numberOfDocuments !== null"
                                color="secondary-light"
                                text-color="secondary-dark"
                                class="q-ml-xs"
                            >
                                {{ state.numberOfDocuments }}
                            </q-badge>
                        </q-route-tab>

                        <q-route-tab
                            name="comments"
                            :to="{ name: 'application-detail', params: { contactId: contactId, id: id, tab: 'comments' } }"
                            icon="mib-messages-bubble-square-text-alternate"
                            :label="$tc('common.term.comment', 2)"
                            data-test="tab:comments"
                        >
                            <q-badge
                                v-if="state.numberOfComments !== null"
                                color="secondary-light"
                                text-color="secondary-dark"
                                class="q-ml-xs"
                            >
                                {{ state.numberOfComments }}
                            </q-badge>
                        </q-route-tab>
                    </q-tabs>
                </main-tabs>

                <q-tab-panels
                    v-model="state.activeTab"
                    animated
                    transition-prev="fade"
                    transition-next="fade"
                >
                    <q-tab-panel name="application-details" class="q-pl-none q-pr-none" data-test="tab-panel:details">
                        <h2>{{ $tc('common.application.application-detail', 2) }}</h2>

                        <product-fields :product-fields="state.application.currentContractInformation?.nestedProductFields.children" />

                        <dl class="application-product-template-info">
                            <dt>{{ $tc('common.product.product-template', 1) }}: </dt>
                            <dd data-test="text:application-template">
                                <span class="additional-info">{{ state.application.currentContractInformation?.productTemplate.category.name }} / {{ state.application.currentContractInformation?.productTemplate.subCategory.name }} /</span> {{ state.application.currentContractInformation?.productTemplate.name }} ({{ $tc('common.term.version', 1) }} {{ state.application.currentContractInformation?.productTemplate.version }})
                            </dd>
                        </dl>
                    </q-tab-panel>

                    <q-tab-panel name="documents" class="q-pl-none q-pr-none" data-test="tab-panel:documents">
                        <h2>{{ $tc('common.term.document', 2) }}</h2>
                        <!-- TODO: Define meta information -->
                        <consulting-file-browser
                            :target-object-type="ConsultingFileTargetObjectType.APPLICATION"
                            :target-object-id="state.application.id"
                            :target-object-consultants="[state.application.consultant]"
                            :meta="{ contactId: state.application.customer.id, applicationNumber: state.application.formattedNumber, contactName: state.application.customer.getContactName() }"
                            @numberOfDocuments="amount => state.numberOfDocuments = amount"
                            @currentFileTypeIdChange="fileTypeId => state.consultingFileBrowserFileTypeId = fileTypeId"
                        />
                        <in-page-footer>
                            <consulting-file-upload-button
                                :target-object-type="ConsultingFileTargetObjectType.APPLICATION"
                                :target-object-id="state.application.id"
                                :file-type-id="state.consultingFileBrowserFileTypeId"
                                :meta="{ contactId: state.application.customer.id, contractNumber: state.application.currentContractNumber, contactName: state.application.customer.getContactName() }"
                            />
                        </in-page-footer>
                    </q-tab-panel>

                    <q-tab-panel
                        name="comments"
                        class="q-pl-none q-pr-none"
                        data-test="tab-panel:comments"
                    >
                        <div class="block-wrapper">
                            <h2>{{ $tc('common.term.comment', 2) }}</h2>
                            <comment-list
                                ref="productDerivedEntityCommentList"
                                :items="state.comments"
                                :target-object-type="CommentTargetObjectType.APPLICATION"
                                :target-object-id="state.application.id"
                                @numberOfComments="amount => { state.numberOfComments = amount }"
                            />
                        </div>
                    </q-tab-panel>
                </q-tab-panels>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { kebabCase } from 'lodash'
import { Application } from '@/models/models'
import Actions from '@/components/Actions'
import { computed, onBeforeMount, onUnmounted, reactive, ref, watch } from 'vue'
import { useRouter } from '@/composables/router'
import { useStore } from '@/composables/store'
import { ApplicationStatus, CommentTargetObjectType, ConsultingFileTargetObjectType, CorrespondenceItemTargetObjectType } from '@/enums/graphql'
import { ApplicationActions } from '@/enums'
import { getFormattedFieldValue } from '@/helpers/form'
import { formatDate } from '@/helpers/date'
import ProductTemplateDiff from '@/components/product/ProductTemplateDiff'
import ConsultingFileBrowser from '@/components/consultingFile/ConsultingFileBrowser'
import ConsultingFileUploadButton from '@/components/consultingFile/ConsultingFileUploadButton'
import MainTabs from '@/components/MainTabs'
import ProductFields from '@/components/productDerivedEntity/ProductFields'
import CommentList from '@/components/CommentList'
import StatusBadge from '@/components/StatusBadge'
import ContractStatusBadge from '@/components/contract/ContractStatusBadge.vue'
import CorrespondenceItemCreateButton from '@/components/correspondence/CorrespondenceItemCreateButton'
import { ApplicationSubmissionResponseDateForm, ApplicationSubmissionSendDateForm } from '@/forms/application'
import FormDialog from '@/libs/form/components/FormDialog'
import { useQuasar } from '@/composables/quasar'
import { useI18n } from '@/composables/i18n'
import { EventBus } from '@/event-bus'

export default {
    name: 'ApplicationDetail',
    meta () {
        return {
            title: !this.state.application ? `${this.$t('common.term.loading')} …` : `${this.state.application.formattedNumber} (${this.state.application.currentContractInformation?.product?.providerName})`,
        }
    },
    components: {
        Actions,
        FormDialog,
        ProductTemplateDiff,
        ConsultingFileBrowser,
        ConsultingFileUploadButton,
        MainTabs,
        CommentList,
        ProductFields,
        StatusBadge,
        ContractStatusBadge,
        CorrespondenceItemCreateButton,
    },
    props: {
        contactId: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        tab: {
            type: String,
            default: '',
        },
    },
    setup (props) {
        // Composables
        const store = useStore()
        const router = useRouter()
        const $q = useQuasar()
        const { tc } = useI18n()

        // Data
        const state = reactive({
            isLoading: true,
            activeTab: props.tab,
            application: null,
            comments: [],
            numberOfDocuments: null,
            numberOfComments: null,
            consultingFileBrowserFileTypeId: null,
        })

        // Template refs
        const applicationSubmitToProductProviderDialog = ref(null)
        const applicationSetDeclinedByProductProviderDialog = ref(null)
        const applicationSubmitCounterProposalToCustomerDialog = ref(null)
        const applicationSetCounterProposalDeclinedByCustomerDialog = ref(null)
        const applicationWithdrawDialog = ref(null)
        const applicationDeleteDialog = ref(null)

        // Computed
        // // TODO @MTR: Move logic to MODEL
        const infoBoxDate = computed(() => {
            const infoBoxDate = {
                date: '',
                isOverdue: false,
            }

            switch (state.application.status) {
                case ApplicationStatus.ON_HOLD:
                    infoBoxDate.date = formatDate(state.application.on_hold_until)
            }

            return infoBoxDate
        })

        // Watchers
        watch(() => props.id, () => {
            fetchObject()
        })

        // Methods
        async function fetchObject () {
            state.application = await Application.objects.get(props.id)

            // Set task panel target object
            EventBus.$emit('taskPanel:setTargetObject', state.application)

            // TODO: Set after properties have been implemented in API (currently missing).
            // Reset tab number badges
            // this.numberOfDocuments = this.contract.number_of_contract_files
            // this.numberOfComments = this.contract.number_of_comments

            // TODO: Is this required on `Applications` as well?
            // Add pinnedComments as initial comments data, but only if the tab comment component hasn't populated it already.
            // if (this.contract.pinnedComments && this.contract.pinnedComments.length && this.comments.length === 0) {
            //     this.comments = this.contract.pinnedComments
            // }

            state.isLoading = false
        }

        function handleActions (action) {
            switch (action.key) {
                case Application.action.SUBMIT_TO_PRODUCT_PROVIDER:
                    applicationSubmitToProductProviderDialog.value.open()
                    break
                case Application.action.SET_APPROVED_BY_PRODUCT_PROVIDER:
                    router.push({ name: 'application-approve', params: { contactId: props.contactId, id: props.id } })
                    break
                case Application.action.SET_DECLINED_BY_PRODUCT_PROVIDER:
                    applicationSetDeclinedByProductProviderDialog.value.open()
                    break
                case Application.action.SUBMIT_COUNTER_PROPOSAL_TO_CUSTOMER:
                    applicationSubmitCounterProposalToCustomerDialog.value.open()
                    break
                case Application.action.SET_COUNTER_PROPOSAL_DECLINED_BY_CUSTOMER:
                    applicationSetCounterProposalDeclinedByCustomerDialog.value.open()
                    break
                case Application.action.WITHDRAW:
                    applicationWithdrawDialog.value.open()
                    break
                case Application.action.DELETE:
                    applicationDeleteDialog.value.open()
                    break
            }
        }

        function handleApplicationSubmitToProductProvider ({ submissionSendDate }) {
            state.application.submitToProductProvider(submissionSendDate)
                .then(() => {
                    applicationSubmitToProductProviderDialog.value.close()
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.application.application-updated-success', 1),
                    })
                })
                .catch(error => {
                    applicationSubmitToProductProviderDialog.value.showFormErrorMessage(error)
                    applicationSubmitToProductProviderDialog.value.resetFormSubmitStatus()
                })
        }

        function handleApplicationSetDeclinedByProductProvider ({ submissionResponseDate }) {
            state.application.setDeclinedByProductProvider(submissionResponseDate)
                .then(() => {
                    applicationSetDeclinedByProductProviderDialog.value.close()
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.application.application-updated-success', 1),
                    })
                })
                .catch(error => {
                    applicationSetDeclinedByProductProviderDialog.value.showFormErrorMessage(error)
                    applicationSetDeclinedByProductProviderDialog.value.resetFormSubmitStatus()
                })
        }

        function handleApplicationSubmitCounterProposalToCustomer ({ submissionResponseDate }) {
            state.application.submitCounterProposalToCustomer(submissionResponseDate)
                .then(() => {
                    applicationSubmitCounterProposalToCustomerDialog.value.close()
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.application.application-updated-success', 1),
                    })
                })
                .catch(error => {
                    applicationSubmitCounterProposalToCustomerDialog.value.showFormErrorMessage(error)
                    applicationSubmitCounterProposalToCustomerDialog.value.resetFormSubmitStatus()
                })
        }

        function handleApplicationSetCounterProposalDeclinedByCustomer () {
            state.application.setCounterProposalDeclinedByCustomer()
                .then(() => {
                    applicationSetCounterProposalDeclinedByCustomerDialog.value.close()
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.application.application-updated-success', 1),
                    })
                })
                .catch(error => {
                    applicationSetCounterProposalDeclinedByCustomerDialog.value.showFormErrorMessage(error)
                    applicationSetCounterProposalDeclinedByCustomerDialog.value.resetFormSubmitStatus()
                })
        }

        function handleApplicationWithdraw () {
            state.application.withdraw()
                .then(() => {
                    applicationWithdrawDialog.value.close()
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.application.application-updated-success', 1),
                    })
                })
                .catch(error => {
                    applicationWithdrawDialog.value.showFormErrorMessage(error)
                    applicationWithdrawDialog.value.resetFormSubmitStatus()
                })
        }

        function handleApplicationDelete () {
            state.application.delete()
                .then(() => {
                    applicationDeleteDialog.value.close()
                    router.replace({ name: 'contact-detail', params: { id: state.application.customer.id } })
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.application.application-deleted-success', 1),
                    })
                })
                .catch(error => {
                    applicationDeleteDialog.value.showFormErrorMessage(error)
                    applicationDeleteDialog.value.resetFormSubmitStatus()
                })
        }

        // Lifecycle hooks
        onBeforeMount(() => {
            fetchObject()
        })

        onUnmounted(() => {
            EventBus.$emit('taskPanel:setTargetObject', undefined)
        })

        return {
            // Static
            ApplicationStatus,
            ApplicationActions,
            ConsultingFileTargetObjectType,
            CommentTargetObjectType,
            CorrespondenceItemTargetObjectType,
            ApplicationSubmissionSendDateForm,
            ApplicationSubmissionResponseDateForm,

            // Composables
            store,

            // Data
            state,

            // Template refs
            applicationSubmitToProductProviderDialog,
            applicationSetDeclinedByProductProviderDialog,
            applicationSubmitCounterProposalToCustomerDialog,
            applicationSetCounterProposalDeclinedByCustomerDialog,
            applicationWithdrawDialog,
            applicationDeleteDialog,

            // Computed
            infoBoxDate,

            // Methods
            handleActions,
            handleApplicationSubmitToProductProvider,
            handleApplicationSetDeclinedByProductProvider,
            handleApplicationSubmitCounterProposalToCustomer,
            handleApplicationSetCounterProposalDeclinedByCustomer,
            handleApplicationWithdraw,
            handleApplicationDelete,
            kebabCase,
            getFormattedFieldValue,
        }
    },
}
</script>

<style lang="scss" scoped>
.application-product-template-info {
    margin: 0;
    padding: $sizeSpacingMd 0 0;

    border-top: 1px solid var(--color-border-primary);

    font-size: 0.75rem;
}

dd {
    &.is-overdue {
        color: var(--q-color-negative-dark);
    }
}
</style>
