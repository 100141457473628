import { apolloClient } from '@/apollo/client'

import { CORRESPONDENCE_EMAIL_QUERY } from '@/graphql/correspondenceEmail/correspondenceEmail'
import { CORRESPONDENCE_EMAIL_CREATE_MUTATION } from '@/graphql/correspondenceEmail/correspondenceEmailCreate'
import { CORRESPONDENCE_EMAIL_UPDATE_MUTATION } from '@/graphql/correspondenceEmail/correspondenceEmailUpdate'
import { CORRESPONDENCE_EMAIL_DELETE_MUTATION } from '@/graphql/correspondenceEmail/correspondenceEmailDelete'
import { CORRESPONDENCE_EMAIL_ATTACHMENTS_QUERY } from '@/graphql/correspondenceEmail/correspondenceEmailAttachments'
import { CORRESPONDENCE_EMAIL_ADD_ATTACHMENT_MUTATION } from '@/graphql/correspondenceEmail/correspondenceEmailAddAttachment'
import { CORRESPONDENCE_EMAIL_DELETE_ATTACHMENT_MUTATION } from '@/graphql/correspondenceEmail/correspondenceEmailDeleteAttachment'
import { CORRESPONDENCE_EMAIL_PREVIEW_CREATE_MUTATION } from '@/graphql/correspondenceEmail/correspondenceEmailPreviewCreate'
import { CORRESPONDENCE_EMAIL_START_PROCESSING_MUTATION } from '@/graphql/correspondenceEmail/correspondenceEmailStartProcessing'

export const CorrespondenceEmailService = {
    get (id) {
        return apolloClient.query({
            query: CORRESPONDENCE_EMAIL_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.correspondenceEmail)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: CORRESPONDENCE_EMAIL_CREATE_MUTATION,
            variables,
        }).then(response => response.data.correspondenceEmailCreate)
    },
    update (id, correspondenceEmailVariables) {
        return apolloClient.mutate({
            mutation: CORRESPONDENCE_EMAIL_UPDATE_MUTATION,
            variables: {
                id: id,
                correspondenceEmail: correspondenceEmailVariables,
            },
        }).then(response => response.data.correspondenceEmailUpdate)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: CORRESPONDENCE_EMAIL_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.correspondenceEmailDelete)
    },

    // Attachments
    getAttachments (correspondenceEmailId) {
        return apolloClient.query({
            query: CORRESPONDENCE_EMAIL_ATTACHMENTS_QUERY,
            variables: {
                id: correspondenceEmailId,
            },
        }).then(response => response.data.correspondenceEmail)
    },
    addAttachment (correspondenceEmailId, file, abortController) {
        return apolloClient.mutate({
            mutation: CORRESPONDENCE_EMAIL_ADD_ATTACHMENT_MUTATION,
            variables: {
                correspondenceEmailId: correspondenceEmailId,
                file: file,
            },
            context: {
                fetchOptions: {
                    signal: abortController.signal,
                },
            },
        }).then(response => response.data.correspondenceEmailAddAttachment)
    },
    deleteAttachment (fileId) {
        return apolloClient.mutate({
            mutation: CORRESPONDENCE_EMAIL_DELETE_ATTACHMENT_MUTATION,
            variables: {
                fileId: fileId,
            },
        }).then(response => response.data.correspondenceEmailDeleteAttachment)
    },

    // Preview
    createPreview (id, targetObjectId) {
        return apolloClient.mutate({
            mutation: CORRESPONDENCE_EMAIL_PREVIEW_CREATE_MUTATION,
            variables: {
                id: id,
                targetObjectId: targetObjectId,
            },
        }).then(response => response.data.correspondenceEmailPreviewCreate)
    },

    // Processing
    startProcessing (id) {
        return apolloClient.mutate({
            mutation: CORRESPONDENCE_EMAIL_START_PROCESSING_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.correspondenceEmailStartProcessing)
    },
}
