import gql from 'graphql-tag'

export const APPLICATION_UPDATE_MUTATION = gql`
    mutation applicationUpdate(
        $id: ID!
        $submissionSendDate: Date
        $submissionResponseDate: Date
        $application: ApplicationInput!
    ) {
        applicationUpdate(
            id: $id
            submission_send_date: $submissionSendDate
            submission_response_date: $submissionResponseDate
            application: $application
        ) {
            id
            created_at
            updated_at
        }
    }
`
