import { Model } from '@/models'

import { CommentService } from '@/services'
import { formatDate, formatDateTime, formatTime } from '@/helpers/date'
import store from '@/store/store'
import { i18n } from '@/i18n'

export class Comment extends Model {
    /**
     * Create a comment model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'creator',
            'text',
            'pinned',

            // Timestamps
            'created_at',
            'updated_at',
        ], data)
    }

    static get service () { return CommentService }

    get formattedCreatedAt () {
        return formatDateTime(this.created_at, { noLeadingZeroDate: true, noLeadingZeroTime: false })
    }

    get formattedCreatedAtDate () {
        return formatDate(this.created_at, { noLeadingZero: true })
    }

    get formattedCreatedAtTime () {
        return formatTime(this.created_at, { noLeadingZero: false })
    }

    get formattedUpdatedAt () {
        return formatDateTime(this.updated_at, { noLeadingZeroDate: true, noLeadingZeroTime: false })
    }

    get formattedUpdatedAtDate () {
        return formatDate(this.updated_at, { noLeadingZero: true })
    }

    get formattedUpdatedAtTime () {
        return formatTime(this.updated_at, { noLeadingZero: false })
    }

    /**
     * Create a new comment.
     * @param {Object} targetObject
     * @param {CommentTargetObjectType} targetObject.objectType
     * @param {string} targetObject.objectId
     * @param {Object} variables - The values used to create the comment.
     * @param {string} variables.text
     * @param {boolean} variables.pinned
     * @returns {Promise<*>}
     */
    static create ({ objectType, objectId }, { text, pinned }) {
        const variables = {
            comment: {
                targetObject: {
                    object_type: objectType,
                    object_id: objectId,
                },
                text,
                pinned,
            },
        }

        return CommentService.create(variables)
    }

    /**
     * Update this comment.
     * @param {Object} variables - The values used to update the comment.
     * @param {string} variables.text
     * @param {boolean} variables.pinned
     * @returns {Promise<*>}
     */
    update ({ text, pinned }) {
        const commentVariables = {
            text,
            pinned,
        }

        return CommentService.update(this.id, commentVariables).then(comment => {
            Object.assign(this, comment)
            return comment
        })
    }

    /**
     * Delete this comment.
     * @returns {Promise<*>}
     */
    delete () {
        return CommentService.delete(this.id)
    }

    /**
     * Toggle pinned flag.
     * @returns {Promise<*>}
     */
    togglePinned () {
        const commentVariables = {
            text: this.text,
            pinned: !this.pinned,
        }

        return CommentService.update(this.id, commentVariables).then(comment => {
            Object.assign(this, comment)
            return comment
        })
    }

    /**
     * Get the name of the creator of a comment.
     * @returns {string}
     */
    get creatorName () {
        // TODO: Add proper check/handling for the case if the contact that created a comment should no longer be available or exist in the system (e.g. when they were archived or deleted).
        const commentCreatorName = []

        // Add comment creator’s name.
        if (this.creator.first_name && this.creator.last_name) {
            commentCreatorName.push(this.creator.getContactName({ consultingCompanyName: !this.creator.worksForUserConsultingCompany }))
        } else {
            commentCreatorName.push(i18n.tc('common.comment.unknown-creator', 1))
        }

        // Add hint if the author is the currently logged-in user.
        if (this.createdByCurrentUser) {
            commentCreatorName.push(`(${i18n.t('common.term.me')})`)
        }

        return commentCreatorName.join(' ')
    }

    /**
     * Simple way to check if a comment was created by the current user.
     * @returns {boolean}
     */
    get createdByCurrentUser () {
        return (this.creator.id && this.creator.id === store.state.user.consultant.id)
    }
}
