import gql from 'graphql-tag'

const searchResultsFields = `
    id
`

const contactFields = `
    status
    contactNumber {
        id
        number
    }
     mainAddress {
        po_box
        address1
        zip
        city
    }
    customer_status
    customer_since
    customer_until
    consultants {
        id
        first_name
        last_name
        consultingCompany {
            id
        }
        consulting_company_name
    }
`

const productDerivedEntityFields = `
    customer {
        ... on Person {
            gender
            first_name
            last_name
            date_of_birth
            date_of_death
            personal_pronoun
            is_deceased
            ${contactFields}
            ${searchResultsFields}
        }
        ... on Company {
            company_name
            is_tenant_company
            ${contactFields}
            ${searchResultsFields}
        }
    }
    currentContractInformation {
        product {
            name
            basicProvider {
                id
                name
                display_name
            }
        }
        productTemplate {
            form_configuration
        }
        product_field_values
        start_date
        end_date
        premium
        managed_by_status
        consulting_mandate_enquiry_send_date
    }
`

export const GLOBAL_SEARCH_APPLICATION_FIELDS = `
    ${searchResultsFields}
    ${productDerivedEntityFields}
    number {
        number
    }
    external_reference_number
    applicationStatus: status
    sourceContract {
        id
        contractNumbers {
            number
        }
    }
    targetContract {
        id
        contractNumbers {
            number
        }
    }
    is_change_application
    applicationConsultant: consultant {
        contact_number
        first_name
        last_name
        gender
        consultingCompany {
            id
        }
        consulting_company_name
    }
    on_hold_until
    submission_send_date
    submission_response_date
`

export const GLOBAL_SEARCH_CONTRACT_FIELDS = `
    ${searchResultsFields}
    ${productDerivedEntityFields}
    contractStatus: status
    contractNumbers {
        id
        number
    }
    currentChangeApplication {
        id
        number {
            number
        }
        external_reference_number
    }
    cancellations {
        status
        cancellation_date
    }
    termination_date
    contractConsultant: consultant {
        contact_number
        first_name
        last_name
        gender
        consultingCompany {
            id
        }
        consulting_company_name
    }
`

export const GLOBAL_SEARCH_QUERY = gql`
    query globalSearch(
        $count: Int = 50
        $searchTerm: String!
    ) {
        globalSearch(
            count: $count
            search_term: $searchTerm
        ) {
            ... on Person {
                ${searchResultsFields}
                ${contactFields}
                gender
                first_name
                last_name
                date_of_birth
                personal_pronoun
            }
            ... on Company {
                ${searchResultsFields}
                ${contactFields}
                company_name
                uid
                is_tenant_company
            }
            ... on Application {
                ${GLOBAL_SEARCH_APPLICATION_FIELDS}
            }
            ... on Contract {
                ${GLOBAL_SEARCH_CONTRACT_FIELDS}
            }
        }
    }
`
