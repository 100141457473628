import { FormField } from '@/libs/form'
import { ContactForm } from '@/forms/contact/contact'

export class CompanyForm extends ContactForm {
    compose () {
        super.compose()

        this.addField(new FormField('companyName', {
            label: 'common.contact.company-name',
            inputType: 'text',
            validators: [
                { type: 'required' },
            ],
        }))

        this.addField(new FormField('uid', {
            label: 'common.contact.uid',
            inputType: 'text',
            attributes: {
                infoText: 'common.contact.uid--full',
            },
        }))

        this.addField(new FormField('isLiableToVat', {
            label: 'common.term.liable-to-vat',
            inputType: 'checkbox',
        }))
    }
}
