<template>
    <page-loading-indicator
        v-if="!prefillCheckDone"
        :number-of-breadcrumb-elements="2"
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.correspondence.document-template.document-template', 2)" :to="{ name: 'correspondence-document-template-list' }" />
                <q-breadcrumbs-el :label="$tc('common.correspondence.document-template.add-document-template', 1)" :to="{ name: 'correspondence-document-template-create' }" />
            </template>

            <h1 v-if="currentStep !== 'configuration' && formData.configuration.documentType">{{ $tc(`common.correspondence.${formData.configuration.documentType.toLowerCase()}.${formData.configuration.documentType.toLowerCase()}-template--add-template`, 1) }}</h1>
            <h1 v-else>{{ $tc('common.correspondence.document-template.add-document-template', 1) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <q-stepper
                    v-model="currentStep"
                    alternative-labels
                    :vertical="$q.screen.lt.md"
                    flat
                    class="correspondence-document-template-create-stepper-wrapper"
                >
                    <q-step
                        name="configuration"
                        :title="$tc('common.term.configuration', 1)"
                        :caption="(currentStep !== 'configuration') ? $tc(`common.correspondence.${formData.configuration.documentType.toLowerCase()}.${formData.configuration.documentType.toLowerCase()}-template`, 1) + ' / ' + $t(`views.correspondence.document-template-create.template-owner--${formData.configuration.documentTemplateOwnerType.toLowerCase()}`) : ''"
                        icon="mib-cog-double-3"
                        active-icon="mib-cog-double-3"
                        done-icon="mib-check"
                        done-color="secondary"
                        :done="currentStep !== 'configuration'"
                    >
                        <form
                            class="row q-col-gutter-md"
                            @submit.prevent="onConfigurationFormSubmit"
                        >
                            <div :class="['col-xs-12 col-md-6 col-lg-4 text-center', { 'offset-lg-1 q-mr-md-xl': $can('manage:own-company', 'DocumentTemplate'), 'offset-lg-4': $can.not('manage:own-company', 'DocumentTemplate') }]">
                                <h3>
                                    {{ $t('views.correspondence.document-template-create.configuration-what') }}
                                    <info-icon :text="$t('views.correspondence.document-template-create.configuration-what--description')" />
                                </h3>

                                <!-- TODO improvement @MTR: Create `illustration-toggle` component for this -->
                                <q-btn-toggle
                                    v-model="formData.configuration.documentType"
                                    :options="[
                                        {
                                            value: DocumentType.LETTER,
                                            slot: 'letter'
                                        },
                                        {
                                            value: DocumentType.EMAIL,
                                            slot: 'email'
                                        },
                                    ]"
                                    unelevated
                                    no-caps
                                    flat
                                    :ripple="false"
                                    spread
                                >
                                    <template v-slot:letter>
                                        <div class="items-center text-center no-wrap">
                                            <illustration
                                                name="max-file-check"
                                                width="10vw"
                                                min-width="8rem"
                                                max-width="11rem"
                                                faded
                                                interactive
                                                :selected="formData.configuration.documentType === DocumentType.LETTER"
                                            />
                                            <div class="text-center">
                                                <q-radio
                                                    v-model="formData.configuration.documentType"
                                                    :val="DocumentType.LETTER"
                                                    :label="$tc('common.correspondence.letter.letter-template', 1)"
                                                />
                                            </div>
                                        </div>
                                    </template>

                                    <template v-slot:email>
                                        <div class="items-center text-center no-wrap">
                                            <illustration
                                                name="max-envelope-check"
                                                width="10vw"
                                                min-width="8rem"
                                                max-width="11rem"
                                                faded
                                                interactive
                                                :selected="formData.configuration.documentType === DocumentType.EMAIL"
                                            />
                                            <div class="text-center">
                                                <q-radio
                                                    v-model="formData.configuration.documentType"
                                                    :val="DocumentType.EMAIL"
                                                    :label="$tc('common.correspondence.email.email-template', 1)"
                                                />
                                            </div>
                                        </div>
                                    </template>
                                </q-btn-toggle>
                            </div>

                            <div v-can="['manage:own-company', 'DocumentTemplate']" class="col-xs-12 col-md-6 col-lg-4 offset-lg-2 text-center q-ml-md-xl">
                                <h3>
                                    {{ $t('views.correspondence.document-template-create.configuration-for-whom') }}
                                    <info-icon :text="$t('views.correspondence.document-template-create.configuration-for-whom--description')" />
                                </h3>

                                <!-- TODO improvement @MTR: Create `illustration-toggle` component for this -->
                                <q-btn-toggle
                                    v-model="formData.configuration.documentTemplateOwnerType"
                                    :options="[
                                        {
                                            value: DocumentTemplateOwnerType.CONSULTANT,
                                            slot: 'consultant'
                                        },
                                        {
                                            value: DocumentTemplateOwnerType.CONSULTING_COMPANY,
                                            slot: 'consulting_company'
                                        },
                                    ]"
                                    unelevated
                                    no-caps
                                    flat
                                    :ripple="false"
                                    spread
                                >
                                    <template v-slot:consultant>
                                        <div class="items-center text-center no-wrap">
                                            <illustration
                                                name="max-person-check"
                                                width="10vw"
                                                min-width="8rem"
                                                max-width="11rem"
                                                faded
                                                interactive
                                                :selected="formData.configuration.documentTemplateOwnerType === DocumentTemplateOwnerType.CONSULTANT"
                                            />
                                            <div class="text-center">
                                                <q-radio
                                                    v-model="formData.configuration.documentTemplateOwnerType"
                                                    :val="DocumentTemplateOwnerType.CONSULTANT"
                                                    :label="$t('views.correspondence.document-template-create.template-owner--consultant')"
                                                />
                                            </div>
                                        </div>
                                    </template>

                                    <template v-slot:consulting_company>
                                        <div class="items-center text-center no-wrap">
                                            <illustration
                                                name="max-office-building-check"
                                                width="10vw"
                                                min-width="8rem"
                                                max-width="11rem"
                                                faded
                                                interactive
                                                :selected="formData.configuration.documentTemplateOwnerType === DocumentTemplateOwnerType.CONSULTING_COMPANY"
                                            />
                                            <div class="text-center">
                                                <q-radio
                                                    v-model="formData.configuration.documentTemplateOwnerType"
                                                    :val="DocumentTemplateOwnerType.CONSULTING_COMPANY"
                                                    :label="$t('views.correspondence.document-template-create.template-owner--consulting-company')"
                                                />
                                            </div>
                                        </div>
                                    </template>
                                </q-btn-toggle>
                            </div>

                            <div class="col-xs-12 q-mt-lg text-center">
                                <info-box
                                    v-can="['manage:own-company', 'DocumentTemplate']"
                                    type="secondary"
                                    auto-width
                                    centered
                                >
                                    <i18n :path="`views.correspondence.document-template-create.user-choose-action-sentence`" tag="p">
                                        <template v-slot:documentType>
                                            <span :class="['gap-text', { 'is-empty': !formData.configuration.documentType }]"><template v-if="formData.configuration.documentType">{{ $t(`views.correspondence.document-template-create.user-choose-action-sentence--document-template-type--${formData.configuration.documentType.toLowerCase()}`) }}</template></span>
                                        </template>
                                        <template v-slot:documentTemplateOwnerType>
                                            <span :class="['gap-text', { 'is-empty': !formData.configuration.documentTemplateOwnerType }]"><template v-if="formData.configuration.documentTemplateOwnerType">{{ $t(`views.correspondence.document-template-create.user-choose-action-sentence--document-template-owner--${formData.configuration.documentTemplateOwnerType.toLowerCase()}`) }}</template></span>
                                        </template>
                                    </i18n>
                                </info-box>

                                <base-button
                                    data-test="btn:submit"
                                    type="submit"
                                    :label="$t('common.term.continue')"
                                    :disable="$v.formData.configuration.$invalid"
                                    primary-button
                                />
                            </div>
                        </form>
                    </q-step>

                    <q-step
                        name="compose"
                        :title="$t('common.correspondence.compose')"
                        caption=""
                        icon="mib-pencil-write-3-alternate"
                        active-icon="mib-pencil-write-3-alternate"
                        done-icon="mib-check"
                        done-color="secondary"
                    >
                        <div v-if="!$v.formData.$invalid && currentStep === 'compose'">
                            <document-template-form
                                ref="documentTemplateForm"
                                :document-type="formData.configuration.documentType"
                                :document-template-owner-type="formData.configuration.documentTemplateOwnerType"
                                :document-template="documentTemplate"
                                :supported-target-object-types.sync="supportedTargetObjectTypes"
                                @submit="onSubmit"
                            />
                            <document-content-formality-editor
                                :contents="contents"
                                :validator-configuration="editorValidations"
                                :smart-object-valid-target-object-types="smartObjectValidTargetObjectTypes"
                                :differentiate-formality.sync="differentiateFormality"
                            />
                            <in-page-footer>
                                <base-button
                                    :label="$t('common.term.cancel')"
                                    flat
                                    primary-button
                                    @click="cancel"
                                />
                                <!-- TODO @TFU: Fix heuristic fragment matching issue when saving template -->
                                <base-button
                                    data-test="btn:submit"
                                    :label="$t('common.term.save')"
                                    primary-button
                                    @click="triggerSubmit"
                                />
                            </in-page-footer>
                        </div>
                    </q-step>
                </q-stepper>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { EditorContentSchemaType } from '@max/tiptap-extensions'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { DocumentTemplateOwnerType } from '@/enums'
import { ContactType, DocumentType, DocumentTemplateTargetObjectType } from '@/enums/graphql'
import DocumentTemplateForm from '@/components/documents/DocumentTemplateForm'
import DocumentContentFormalityEditor from '@/components/documents/DocumentContentFormalityEditor'
import { DocumentTemplate, DocumentContent, EditorContent } from '@/models/models'
import { StructureBlockSubject } from '@/editor/structureBlocks/StructureBlockSubject'
import { StructureBlockContent } from '@/editor/structureBlocks/StructureBlockContent'

export default {
    name: 'DocumentTemplateCreate',
    meta () {
        return {
            title: this.$tc('common.correspondence.document-template.add-document-template', 1),
        }
    },
    components: {
        DocumentTemplateForm,
        DocumentContentFormalityEditor,
    },
    data () {
        const data = {
            DocumentTemplateOwnerType,
            DocumentType,
            documentTemplate: null,
            prefillCheckDone: false,
            currentStep: 'configuration',
            formData: {
                configuration: {
                    documentType: null,
                    documentTemplateOwnerType: null,
                },
            },
            supportedTargetObjectTypes: [],
            differentiateFormality: false,
            contents: [],
            editorValidations: {
                [StructureBlockSubject.name]: { required },
                [StructureBlockContent.name]: { required },
            },
        }

        // Preset value if the user does not have a choice based on lacking permissions.
        if (this.$can.not('manage:own-company', 'DocumentTemplate')) data.formData.configuration.documentTemplateOwnerType = DocumentTemplateOwnerType.CONSULTANT

        return data
    },
    computed: {
        smartObjectValidTargetObjectTypes () {
            const validTargetObjectTypes = []
            if (this.supportedTargetObjectTypes.includes(DocumentTemplateTargetObjectType.CONTACT)) validTargetObjectTypes.push(ContactType.PERSON, ContactType.COMPANY)
            if (this.supportedTargetObjectTypes.includes(DocumentTemplateTargetObjectType.APPLICATION)) {
                validTargetObjectTypes.push(DocumentTemplateTargetObjectType.APPLICATION)
                if (!validTargetObjectTypes.includes(ContactType.PERSON)) validTargetObjectTypes.push(ContactType.PERSON)
                if (!validTargetObjectTypes.includes(ContactType.COMPANY)) validTargetObjectTypes.push(ContactType.COMPANY)
            }
            if (this.supportedTargetObjectTypes.includes(DocumentTemplateTargetObjectType.CONTRACT)) {
                validTargetObjectTypes.push(DocumentTemplateTargetObjectType.CONTRACT)
                if (!validTargetObjectTypes.includes(ContactType.PERSON)) validTargetObjectTypes.push(ContactType.PERSON)
                if (!validTargetObjectTypes.includes(ContactType.COMPANY)) validTargetObjectTypes.push(ContactType.COMPANY)
            }
            return validTargetObjectTypes
        },
    },
    validations () {
        return {
            formData: {
                configuration: {
                    documentType: {
                        required,
                    },
                    documentTemplateOwnerType: {
                        required,
                    },
                },
            },
            contents: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(2),
            },
        }
    },
    created () {
        if (this.$route.query?.prefill) {
            DocumentTemplate.objects.get(this.$route.query.prefill)
                .then(documentTemplate => {
                    delete documentTemplate.id
                    documentTemplate.contents.forEach(content => delete content.id)

                    this.formData.configuration.documentType = documentTemplate.type
                    this.formData.configuration.documentTemplateOwnerType = documentTemplate.owner.id === this.$store.state.user.consultant.id ? DocumentTemplateOwnerType.CONSULTANT : DocumentTemplateOwnerType.CONSULTING_COMPANY
                    this.differentiateFormality = documentTemplate.contents.length > 1
                    this.contents.push(...documentTemplate.contents)

                    this.documentTemplate = documentTemplate
                    this.currentStep = 'compose'
                })
                .catch(() => {
                    this.prefillCheckDone = true
                })
                .finally(() => {
                    this.prefillCheckDone = true
                })
        } else {
            this.prefillCheckDone = true
        }
    },
    methods: {
        onConfigurationFormSubmit () {
            this.contents.push(new DocumentContent({
                content: EditorContent.create({
                    schemaType: EditorContentSchemaType[this.formData.configuration.documentType],
                }),
            }))
            this.currentStep = 'compose'
        },
        triggerSubmit () {
            if (Object.keys(this.editorValidations).length) this.contents.forEach(documentContent => documentContent.content.$v.$touch()) // Trigger editor content validation.
            this.$refs.documentTemplateForm.onFormSubmit()
        },
        onSubmit ({ availableForSubordinateCompanies, name, description, supportedTargetObjectTypes, languageId, confidentialityNotice }) {
            const invalidEditorContent = (Object.keys(this.editorValidations).length && this.contents.some(documentContent => documentContent.content.$v.$invalid))

            if (!this.$v.$invalid && !invalidEditorContent) {
                const ownerId = (this.formData.configuration.documentTemplateOwnerType === DocumentTemplateOwnerType.CONSULTANT) ? this.$store.state.user.consultant.id : this.$store.state.user.consultant.consultingCompany.id

                DocumentTemplate.create(ownerId, {
                    availableForSubordinateCompanies,
                    name,
                    description,
                    type: this.formData.configuration.documentType,
                    supportedTargetObjectTypes,
                    languageId,
                    confidentialityNotice,
                    differentiateFormality: this.differentiateFormality,
                    contents: this.contents.map(documentContent => documentContent.getVariablesObject()),
                })
                    .then(() => {
                        this.$router.replace({ name: 'correspondence-document-template-list' })
                    })
                    .catch(error => {
                        this.$refs.documentTemplateForm.showFormErrorMessage(error)
                        this.$refs.documentTemplateForm.resetFormSubmitStatus()
                    })
            }
        },
        cancel () {
            this.$router.go(-1)
        },
    },
}
</script>
