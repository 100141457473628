<template>
    <page-loading-indicator
        v-if="!commissionDistributionRuleSet"
        :number-of-breadcrumb-elements="2"
        actions
        :number-of-next-actions="1"
        no-more-actions-button
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.commission.commission-distribution.commission-distribution', 1)" :to="{ name: 'commission-distribution-rule-sets' }" />
                <q-breadcrumbs-el :label="commissionDistributionRuleSet.name" :to="{ name: 'commission-distribution-rule-set-update', params: { id: id } }" data-test="breadcrumb:commission-distribution-rule-set-detail" />
            </template>

            <h1>{{ commissionDistributionRuleSet.name }}</h1>

            <template v-slot:actions>
                <actions
                    :actions="commissionDistributionRuleSet.actions"
                    @click="handleActions"
                />
                <form-dialog
                    ref="deleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleDelete"
                >
                    <template v-slot:title>{{ $tc('common.rule.delete-rule-set', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.rule.delete-rule-set--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:name><b>{{ commissionDistributionRuleSet.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>

            <template v-slot:afterContent>
                <!-- TODO improvement @TFU: Create query to receive list of consulting entities which have this rule set assigned. (To be able to provide an option to admins to "Show all which have this rule assigned".) -->
                <info-box
                    v-if="commissionDistributionRuleSet.is_assigned"
                    icon="mib-lock-1"
                    icon-color="secondary"
                >
                    <p>{{ $tc('views.commission.commission-distribution.rule-set-assigned--info', 1) }}</p>
                </info-box>

                <info-box
                    v-if="anyDirty"
                    type="warning"
                    data-test="warning:unsaved-changes"
                >
                    <i18n path="common.rule.unsaved-changes">
                        <template v-slot:item>{{ $tc('common.rule.unsaved-changes--item-part--rule-set', 1) }}</template>
                    </i18n>
                </info-box>
            </template>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <commission-distribution-rule-set-form
                    ref="updateForm"
                    :commission-distribution-rule-set="commissionDistributionRuleSet"
                    :disable-form="commissionDistributionRuleSet.is_assigned"
                    @submit="handleSubmit"
                    @update:dirty-state="handleAnyDirtyState"
                />
            </div>
        </div>

        <div>
            <p>
                <small>
                    <base-button
                        show-as-link
                        @click="$refs.activityLogOverlay.open({ filterSubjects: [{ id: id, type: ActivityLogSubjectType.COMMISSION_DISTRIBUTION_RULE_SET }], filterIncludeSubjectRelations: [ActivityLogSubjectRelation.COMMISSION_DISTRIBUTION_RULE_RANGES] })"
                    >{{ $tc('common.term.created-on--date-time', 1, { date: commissionDistributionRuleSet.formattedCreatedAtDate, time: commissionDistributionRuleSet.formattedCreatedAtTime }) }}</base-button>
                </small>
            </p>
        </div>

        <!-- Activity log -->
        <activity-log-overlay ref="activityLogOverlay" />
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import { CommissionDistributionRuleSet } from '@/models/commissionDistributionRuleSet'
import { parseFormDataToRuleSetInput } from '@/helpers/commissionDistributionRuleSet'
import { ActivityLogSubjectType, ActivityLogSubjectRelation } from '@/enums/graphql'
import CommissionDistributionRuleSetForm from '@/components/commission/commissionDistributionRuleSet/CommissionDistributionRuleSetForm.vue'
import ActivityLogOverlay from '@/components/ActivityLogOverlay'

import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'

export default {
    name: 'CommissionDistributionRuleSetUpdate',
    meta () {
        return {
            title: this.$tc('common.rule.update-rule-set', 1),
        }
    },
    components: {
        Actions,
        FormDialog,
        CommissionDistributionRuleSetForm,
        ActivityLogOverlay,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            ActivityLogSubjectType,
            ActivityLogSubjectRelation,
            name: null,
            description: null,
            commissionDistributionRuleSet: null,
            anyDirty: false,
        }
    },
    created () {
        CommissionDistributionRuleSet.objects.get(this.id)
            .then(commissionDistributionRuleSet => {
                this.commissionDistributionRuleSet = commissionDistributionRuleSet
                this.name = commissionDistributionRuleSet.name
                this.description = commissionDistributionRuleSet.description
            })
            .catch(() => {
                this.$router.replace({ name: '403' }) // TODO @TFU: Differentiate between 403 and 404.
            })

        EventBus.$on('commissionDistributionRuleSet:get-rule-set:response', this.handleUpdate)
    },
    beforeDestroy () {
        EventBus.$off('commissionDistributionRuleSet:get-rule-set:response', this.handleUpdate)
    },
    methods: {
        handleAnyDirtyState (isDirty) {
            this.anyDirty = isDirty
        },
        handleActions (action) {
            switch (action.key) {
                case CommissionDistributionRuleSet.action.DELETE:
                    this.$refs.deleteDialog.open()
                    break
            }
        },
        handleDelete () {
            this.commissionDistributionRuleSet.delete()
                .then(() => {
                    this.$refs.deleteDialog.close()
                    this.$router.replace({ name: 'commission-distribution-rule-set-list' })

                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.commission.commission-distribution-rule-set-deleted-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.deleteDialog.showFormErrorMessage(error)
                    this.$refs.deleteDialog.resetFormSubmitStatus()
                })
        },
        handleSubmit (formData) {
            this.name = formData.name
            this.description = formData.description

            EventBus.$emit('commissionDistributionRuleSet:get-rule-set')
        },
        handleUpdate (rules) {
            const input = parseFormDataToRuleSetInput(this.name, this.description, rules)

            this.commissionDistributionRuleSet.update(input)
                .then(() => {
                    this.handleAnyDirtyState(false)
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.commission.commission-distribution-rule-set-updated-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.updateForm.showFormErrorMessage(error)
                    this.$refs.updateForm.resetFormSubmitStatus()

                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$tc('common.notifications.commission.commission-distribution-rule-set-updated-error', 1),
                    })
                })
        },
    },
}
</script>
