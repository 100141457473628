import { checkRouteAccessPermissions, checkRouteAccessPermissionsNested } from '@/helpers/acl'

import DefaultLayout from '@/layouts/Default.vue'
import AdminUserList from '@/views/admin/AdminUserList.vue'
import AdminUserCreate from '@/views/admin/AdminUserCreate.vue'
import AdminUserDetail from '@/views/admin/AdminUserDetail.vue'
import AdminUserRolesPermissions from '@/views/admin/AdminUserRolesPermissions.vue'

export default [
    // System preferences
    {
        path: '/admin/user-management/',
        name: 'admin-user-management',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['User:manage'],
            can: checkRouteAccessPermissionsNested,
            appSubSection: 'admin',
            navGroup: 'users',
        },
        redirect: {
            name: 'admin-user-list',
        },
    },
    {
        path: '/admin/user-management/users/',
        name: 'admin-user',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['User:manage'],
            can: checkRouteAccessPermissionsNested,
            appSubSection: 'admin',
            navGroup: 'users',
        },
        redirect: {
            name: 'admin-user-list',
        },
        children: [
            {
                path: ':tab(active|invited|inactive)?',
                name: 'admin-user-list',
                component: AdminUserList,
                props: true,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'users',
                },
            },
            {
                path: 'create',
                name: 'admin-user-create',
                component: AdminUserCreate,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'users',
                },
            },
            {
                path: ':id(\\d+)',
                name: 'admin-user-detail',
                component: AdminUserDetail,
                props: true,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'users',
                },
            },
            // {
            //     path: 'roles-permissions',
            //     name: 'admin-user-roles-permissions',
            //     component: AdminUserRolesPermissions,
            //     meta: {
            //         appSubSection: 'admin',
            //         navGroup: 'users',
            //     },
            // },
        ],
    },
    {
        path: '/admin/user-management/roles-permissions',
        name: 'admin-user-roles-permissions-management',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['User:manage'],
            can: checkRouteAccessPermissionsNested,
            appSubSection: 'admin',
            navGroup: 'users',
        },
        redirect: {
            name: 'admin-user-roles-permissions',
        },
        children: [
            {
                path: '',
                name: 'admin-user-roles-permissions',
                component: AdminUserRolesPermissions,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'users',
                },
            },
        ],
    },
]
