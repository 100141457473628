<template>
    <page-loading-indicator
        v-if="!prefillCheckDone"
        :number-of-breadcrumb-elements="2"
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.correspondence.letter.letter', 2)" :to="{ name: 'correspondence-letters' }" />
                <q-breadcrumbs-el :label="$tc('common.correspondence.letter.write-letter', 1)" :to="{ name: 'correspondence-letter-create' }" />
            </template>

            <h1>{{ $tc('common.correspondence.letter.write-letter', 1) }}</h1>
        </page-header>

        <div class="row">
            <!-- TODO: Check if compose view needs the full width (add/remove the according part after decision) -->
            <!--<div class="" :class="['col-xs-12', { 'col-lg-8 offset-lg-2': (currentStep === 'configuration') }]">-->
            <div class="col-xs-12">
                <q-stepper
                    v-model="currentStep"
                    alternative-labels
                    :vertical="$q.screen.lt.md"
                    flat
                    class="correspondence-letter-create-stepper-wrapper"
                >
                    <q-step
                        name="configuration"
                        :title="$tc('common.term.configuration', 1)"
                        :caption="(currentStep !== 'configuration' && correspondenceLetter.target_object_type) ? `${$tc('common.term.' + correspondenceLetter.target_object_type.toLowerCase(), 1)}` : ''"
                        icon="mib-cog-double-3"
                        active-icon="mib-cog-double-3"
                        done-icon="mib-check"
                        done-color="secondary"
                        :done="currentStep !== 'configuration'"
                    >
                        <loading-indicator v-if="isLoadingTargetObject" auto-height />
                        <correspondence-item-create-configurator
                            v-else
                            :correspondence-item="correspondenceLetter"
                            :correspondence-item-target-object-type="correspondenceItemTargetObjectType"
                            :correspondence-item-initial-data-source="correspondenceItemInitialDataSource"
                            @submit="onCreateConfiguratorSubmit"
                        />
                    </q-step>

                    <q-step
                        name="compose"
                        :title="$t('common.correspondence.compose')"
                        caption=""
                        icon="mib-pencil-write-3-alternate"
                        active-icon="mib-pencil-write-3-alternate"
                        done-icon="mib-check"
                        done-color="secondary"
                    >
                        <correspondence-item-compose ref="correspondenceItemCompose" :correspondence-item="correspondenceLetter" @submit="onComposeSubmit" />

                        <in-page-footer>
                            <base-button
                                :label="$t('common.term.cancel')"
                                flat
                                primary-button
                                @click="cancel"
                            />
                            <base-button
                                data-test="btn:submit"
                                :label="$t('common.term.save')"
                                primary-button
                                outline
                                :disable="isProcessing"
                                :loading="isProcessing && !continueToPreviewAfterProcessing"
                                @click="triggerProcessing(false)"
                            />
                            <base-button
                                data-test="btn:submit"
                                :label="$t('common.term.save-and-continue')"
                                primary-button
                                :disable="isProcessing"
                                :loading="isProcessing && continueToPreviewAfterProcessing"
                                @click="triggerProcessing(true)"
                            />
                        </in-page-footer>
                    </q-step>
                </q-stepper>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { EditorContentSchemaType } from '@max/tiptap-extensions'
import { CorrespondenceItemTargetObjectType } from '@/enums/graphql'
import { CorrespondenceLetter, DocumentContent, EditorContent, Contact, Application, Contract } from '@/models/models'
import { targetObjectContractQueryFields } from '@/graphql/contract/contract'
import CorrespondenceItemCreateConfigurator from '@/components/correspondence/CorrespondenceItemCreateConfigurator'
import CorrespondenceItemCompose from '@/components/correspondence/CorrespondenceItemCompose'
import { targetObjectApplicationQueryFields } from '@/graphql/application/application'

export default {
    name: 'CorrespondenceLetterCreate',
    components: {
        CorrespondenceItemCreateConfigurator,
        CorrespondenceItemCompose,
    },
    meta () {
        return {
            title: this.$tc('common.correspondence.letter.write-letter', 1),
        }
    },
    props: {
        correspondenceItemTargetObjectType: {
            type: String,
            default: null,
        },
        correspondenceItemTargetObjectId: {
            type: String,
            default: null,
        },
        correspondenceItemInitialDataSource: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            prefillCheckDone: false,
            currentStep: 'configuration',
            isLoadingTargetObject: false,
            correspondenceLetter: new CorrespondenceLetter({
                creator: this.$store.state.user.consultant,
                attention_ofs: null,
                address_overrides: null,
                sender_overrides: null,
                contents: [new DocumentContent({
                    content: EditorContent.create({
                        schemaType: EditorContentSchemaType.LETTER,
                    }),
                })],
            }),
            isProcessing: false,
            continueToPreviewAfterProcessing: false,
        }
    },
    created () {
        if (this.$route.query?.prefill) {
            CorrespondenceLetter.objects.get(this.$route.query.prefill)
                .then(correspondenceLetter => {
                    delete correspondenceLetter.id
                    correspondenceLetter.contents.forEach(content => delete content.id)

                    this.correspondenceLetter = correspondenceLetter
                    this.currentStep = 'compose'
                })
                .catch(() => {
                    this.prefillCheckDone = true
                })
                .finally(() => {
                    this.prefillCheckDone = true
                })
        } else {
            this.prefillCheckDone = true
        }
    },
    methods: {
        async onCreateConfiguratorSubmit (configuration) {
            if (this.correspondenceItemTargetObjectId) {  // Preload and set targetObject.
                this.isLoadingTargetObject = true
                switch (configuration.target_object_type) {
                    case CorrespondenceItemTargetObjectType.CONTACT:
                        await Contact.objects.get(this.correspondenceItemTargetObjectId).then(contact => {
                            this.correspondenceLetter.targetContacts = [contact]
                        })
                        break
                    case CorrespondenceItemTargetObjectType.APPLICATION:
                        await Application.objects.get(this.correspondenceItemTargetObjectId, targetObjectApplicationQueryFields).then(application => {
                            this.correspondenceLetter.targetApplications = [application]
                        })
                        break
                    case CorrespondenceItemTargetObjectType.CONTRACT:
                        await Contract.objects.get(this.correspondenceItemTargetObjectId, targetObjectContractQueryFields).then(contract => {
                            this.correspondenceLetter.targetContracts = [contract]
                        })
                        break
                }
            }

            Object.assign(this.correspondenceLetter, configuration)
            this.currentStep = 'compose'
            this.isLoadingTargetObject = false
        },
        triggerProcessing (continueToPreviewAfterProcessing) {
            if (continueToPreviewAfterProcessing === true) this.continueToPreviewAfterProcessing = true
            this.$refs.correspondenceItemCompose.triggerSubmit()
        },
        onComposeSubmit (data) {
            this.isProcessing = true
            const { name, languageId, confidentialityNotice, differentiateFormality, targetObjects, contents, attentionOfs, addressOverrides, senderOverrides } = data

            CorrespondenceLetter.create({
                targetObjectType: this.correspondenceLetter.target_object_type,
                name,
                targetObjectIds: targetObjects.map(targetObject => targetObject.id),
                languageId,
                confidentialityNotice,
                attentionOfs: (attentionOfs === null) ? null : JSON.stringify(attentionOfs),
                addressOverrides: (addressOverrides === null) ? null : JSON.stringify(addressOverrides),
                senderOverrides: (senderOverrides === null) ? null : JSON.stringify(senderOverrides),
                differentiateFormality,
                contents: contents.map(documentContent => documentContent.getVariablesObject()),
            })
                .then(correspondenceLetter => {
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.correspondence.correspondence-letter-saved-success', 1),
                    })

                    if (this.continueToPreviewAfterProcessing) {
                        this.$router.replace({ name: 'correspondence-letter-preview', params: { id: correspondenceLetter.id } })
                    } else {
                        this.$router.replace({ name: 'correspondence-letter-update', params: { id: correspondenceLetter.id } })
                    }
                })
                .catch(error => {
                    this.continueToPreviewAfterProcessing = false
                    this.$refs.correspondenceItemCompose.$refs.correspondenceItemForm.showFormErrorMessage(error)
                    this.$refs.correspondenceItemCompose.$refs.correspondenceItemForm.resetFormSubmitStatus()
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$tc('common.notifications.correspondence.correspondence-letter-saved-error', 1),
                    })
                })
                .finally(() => {
                    this.isProcessing = false
                })
        },
        cancel () {
            this.$router.go(-1)
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep {
    // Needed for editor elements with `position: sticky;` to work
    .correspondence-letter-create-stepper-wrapper {
        .q-panel-parent,
        .q-panel.scroll {
            overflow: visible;
        }
    }

    .q-stepper__step-inner.q-stepper__step-inner {
        @include mq($from: $sizeBreakpointMd) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
</style>
