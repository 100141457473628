<template>
    <div>
        <input
            ref="selectFilesInput"
            type="file"
            multiple
            style="display: none"
            @change="onChange"
        >
        <base-button
            data-test="btn:consulting-file-open-file-dialog"
            :label="$tc('common.document.upload-document', 2)"
            icon="mib-cloud-upload"
            :primary-button="primaryButton"
            @click="openFileDialog"
        />
    </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import { filterFiles, prepareFileUploadWrappers } from '@/helpers/file'

export default {
    name: 'ConsultingFileUploadButton',
    props: {
        targetObjectType: {
            type: String,
            required: true,
        },
        targetObjectId: {
            type: String,
            required: true,
        },
        targetObjectConsultants: {
            type: Array,
        },
        fileTypeId: {
            type: String,
        },
        meta: {
            type: Object,
        },
        primaryButton: {
            type: Boolean,
            default: true,
        },
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.openFileDialog)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.openFileDialog)
    },
    methods: {
        openFileDialog () {
            this.$refs.selectFilesInput.value = null
            this.$refs.selectFilesInput.click()
        },
        async onChange (event) {
            const files = Array.from(event.target.files)
            const filteredFiles = filterFiles(files)
            await this.emitFiles(filteredFiles)
        },
        async emitFiles (files) {
            if (files.length) {
                const fileUploadWrappers = await prepareFileUploadWrappers(files, {
                    targetObjectType: this.targetObjectType,
                    targetObjectId: this.targetObjectId,
                    targetObjectConsultants: this.targetObjectConsultants,
                    fileTypeId: this.fileTypeId,
                    meta: this.meta,
                })
                EventBus.$emit('consultingFileUpload:addToStaging', fileUploadWrappers)
            }
        },
    },
}
</script>
