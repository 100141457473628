<template>
    <page-loading-indicator
        v-if="!postingType"
        :number-of-breadcrumb-elements="2"
        actions
        :number-of-next-actions="1"
        no-more-actions-button
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.accounting.posting-type.posting-type', 2)" :to="{ name: 'accounting-posting-type-list' }" />
                <q-breadcrumbs-el :to="{ name: 'accounting-posting-type-update', params: { id: postingType.id } }" data-test="breadcrumb:posting-type-detail">
                    {{ postingType.name }}
                    <info-icon
                        v-if="postingType.isSystemPostingType"
                        name="mib-lock-1"
                        class="q-ml-xs"
                        :title="$tc('views.accounting.posting-type.system-posting-type', 1)"
                        :text="$t('views.accounting.posting-type.system-posting-type--description')"
                    />
                </q-breadcrumbs-el>
            </template>

            <h1>{{ postingType.name }}</h1>

            <template v-slot:actions>
                <actions
                    :actions="postingType.actions"
                    translation-base-key="common.actions.accounting"
                    @click="handleActions"
                />

                <!-- Delete PostingType -->
                <form-dialog
                    ref="deleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleDelete"
                >
                    <template v-slot:title>{{ $tc('views.accounting.posting-type.delete-posting-type', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.accounting.posting-type.delete-posting-type--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:name><b>{{ postingType.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <div v-if="postingType.isSystemPostingType" class="row">
            <div class="col-xs-12">
                <info-box
                    no-margin
                    icon="mib-lock-1"
                    icon-color="secondary"
                    data-test="info:posting-type-system"
                >
                    <p>{{ $t('views.accounting.posting-type.system-posting-type--description') }}</p>
                </info-box>
            </div>
        </div>

        <posting-type-form
            ref="postingTypeForm"
            :posting-type="postingType"
            @submit="onSubmit"
        />

        <div class="col-xs-12">
            <p>
                <small>
                    <base-button
                        show-as-link
                        @click="$refs.activityLogOverlay.open({ filterSubjects: [{ id: id, type: ActivityLogSubjectType.POSTING_TYPE }] })"
                    >{{ $tc('common.term.created-on--date-time', 1, { date: postingType.formattedCreatedAtDate, time: postingType.formattedCreatedAtTime }) }}</base-button>
                </small>
            </p>
        </div>

        <!-- Activity log -->
        <activity-log-overlay ref="activityLogOverlay" />
    </page-wrapper>
</template>

<script>
import { PostingType } from '@/models/postingType'
import { ActivityLogSubjectType } from '@/enums/graphql'
import PostingTypeForm from '@/components/accounting/PostingTypeForm'
import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'
import ActivityLogOverlay from '@/components/ActivityLogOverlay'

export default {
    name: 'PostingTypeUpdate',
    meta () {
        return {
            title: this.$tc('views.accounting.posting-type.update-posting-type', 1),
        }
    },
    components: {
        PostingTypeForm,
        Actions,
        FormDialog,
        ActivityLogOverlay,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            ActivityLogSubjectType,
            postingType: null,
        }
    },
    created () {
        PostingType.service.get(this.id)
            .then(postingType => {
                this.postingType = postingType
            }).catch(error => {
                // Forward to `forbidden` error view.
                this.$router.replace({ name: '403' }) // TODO @TFU: Differentiate between 403 and 404.
            })
    },
    methods: {
        onSubmit (postingType) {
            this.postingType.update(postingType)
                .then(() => {
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.accounting.posting-type-updated-success', 1),
                    })
                    this.$router.replace({ name: 'accounting-posting-type-list' })
                })
                .catch(error => {
                    this.$refs.postingTypeForm.showFormErrorMessage(error)
                    this.$refs.postingTypeForm.resetFormSubmitStatus()
                })
        },
        handleActions (action) {
            switch (action.key) {
                case PostingType.action.DELETE:
                    this.$refs.deleteDialog.open()
                    break
            }
        },
        handleDelete () {
            this.postingType.delete()
                .then(() => {
                    this.$refs.deleteDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.accounting.posting-type-deleted-success', 1),
                    })
                    this.$router.replace({ name: 'accounting-posting-type-list' })
                })
                .catch(error => {
                    this.$refs.deleteDialog.showFormErrorMessage(error)
                    this.$refs.deleteDialog.resetFormSubmitStatus()
                })
        },
    },
}
</script>
