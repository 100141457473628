import gql from 'graphql-tag'

export const CONTRACT_FILE_TYPES_QUERY = gql`
    query contractFileTypes(
        $status: ContractFileTypeStatus = ACTIVE
    ) {
        contractFileTypes(
            status: $status
        ) {
            id
            status
            key
            name
        }
    }
`
