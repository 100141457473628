<template>
    <page-loading-indicator
        v-if="!contact"
        subtitle
        actions
        :number-of-next-actions="0"
        settings-button
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el
                    :label="contact.getContactName()"
                    :to="{ name: 'contact-detail', params: { id: contact.id } }"
                    data-test="breadcrumb:contact-detail"
                >
                    <status-badge v-if="contact.customer_status" :status="contact.customer_status" default-translation-base-path="common.contact.customer-status" />
                    <contact-type-badges :contact="contact" show-system-owner />
                    <status-badge v-if="contact.status !== ContactStatus.ACTIVE" :status="contact.status" />
                    <status-badge v-if="contact.is_deceased" status="deceased" />
                </q-breadcrumbs-el>
            </template>

            <h1 class="contact-name">
                <contact-name :contact="contact" salutation />
                <small v-if="editMode" class="subtitle">
                    (<span data-test="text:contact-number">{{ contact.contactNumber.number }}</span>
                    <base-button
                        data-test="btn:update-contact-number"
                        round
                        color="none"
                        text-color="edit-mode-link"
                        size="xs"
                        class="edit-link q-ml-xs"
                        icon="mib-pencil"
                        @click="updateContactNumber"
                    >
                        <q-tooltip
                            :delay="1000"
                            anchor="center right"
                            self="center left"
                            :offset="[10, 0]"
                            transition-show="scale"
                            transition-hide="scale"
                        >{{ $tc('common.contact.edit-contact-number', 1) }}</q-tooltip>
                    </base-button>)
                    <form-dialog
                        ref="contactNumberUpdateDialog"
                        :form-builder-settings="{ schema: CONTACT_NUMBER_UPDATE_SCHEMA }"
                        :initial-form-data="{ contactNumberValueStructure: contact.contactNumber.value_structure }"
                        dont-close-on-confirm
                        @confirm="handleContactNumberUpdate"
                    >
                        <template v-slot:title>{{ $tc('common.contact.edit-contact-number', 1) }}</template>
                    </form-dialog>
                </small>
                <small v-else class="subtitle">
                    (<span data-test="text:contact-number">{{ contact.contactNumber.number }}</span>)
                </small>
                <small v-if="contact.personal_pronoun" class="subtitle" data-test="text:contact-personal-pronoun"> ({{ contact.personal_pronoun }})</small>
            </h1>
            <a
                v-if="editMode && !areaEditModeContactInfo"
                class="edit-link q-link do-not-print"
                tabindex="0"
                data-test="link:edit-contact-info"
                @click="areaEditModeContactInfo = !areaEditModeContactInfo"
                @keyup.enter="areaEditModeContactInfo = !areaEditModeContactInfo"
            ><q-icon name="mib-pencil" class="q-mr-xs" />{{ $tc('common.contact.edit-contact-info', 2) }}</a>
            <!-- TODO: Maybe find a nicer solution for this (so that the content does not jump when areaEditModeContactInfo is enabled) -->
            <span v-if="editMode && areaEditModeContactInfo" class="text-border-secondary"><q-icon name="mib-pencil" class="q-mr-xs" />{{ $tc('common.contact.edit-contact-info', 2) }}</span>

            <template v-slot:actions>
                <actions
                    v-if="contact.actions"
                    class="inline-block"
                    :actions="contact.actions"
                    :number-of-next-best-actions="0"
                    :settings-link="{ name: 'contact-settings', params: { id: contact.id } }"
                    @click="handleActions"
                />

                <!-- Archive Contact -->
                <form-dialog
                    ref="contactArchiveDialog"
                    dont-close-on-confirm
                    @confirm="handleContactArchive"
                >
                    <template v-slot:title>{{ $tc('common.actions.contact.archive', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.contact.archive-contact--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:contactName><b>{{ contact.getContactName({ contactNumber: true }) }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Unarchive Contact -->
                <form-dialog
                    ref="contactUnarchiveDialog"
                    :form-builder-settings="unarchiveFormBuilderSettings"
                    :initial-form-data="{ consultants: contact.activeConsultants.map(consultant => consultant.id) }"
                    dont-close-on-confirm
                    @confirm="handleContactUnarchive"
                >
                    <template v-slot:title>{{ $tc('common.actions.contact.unarchive', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.contact.unarchive-contact--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:contactName><b>{{ contact.getContactName({ contactNumber: true }) }}</b></template>
                        </i18n>

                        <div v-if="contact.archivedConsultants.length">
                            <i18n path="common.contact.unarchive-contact--confirm-archived-consultant-hint--temp-component-interpolation-count" tag="p">
                                <template v-slot:consultantName>
                                    <!-- TODO: Re-add the part below when the Vue i18n component interpolation count issue is fixed / after upgrading Vue i18n to v9.x -->
                                    <!--
                                        <b v-if="contact.archivedConsultants.length === 1">{{ contact.archivedConsultants[0].getContactName({ consultingCompanyName: !consultant.worksForUserConsultingCompany }) }}</b>
                                        <ul v-else>
                                    -->
                                    <ul>
                                        <li v-for="consultant in contact.archivedConsultants" :key="consultant.id">{{ consultant.getContactName({ consultingCompanyName: !consultant.worksForUserConsultingCompany }) }}</li>
                                    </ul>
                                </template>
                            </i18n>
                            <p>{{ $tc('common.contact.unarchive-contact--confirm-archived-consultant-verify-add-new', contact.activeConsultants.length) }}</p>
                        </div>
                    </template>
                </form-dialog>

                <!-- Delete Contact -->
                <form-dialog
                    ref="contactDeleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleContactDelete"
                >
                    <template v-slot:title>{{ $tc('common.actions.contact.delete', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.contact.delete-contact--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:contactName><b>{{ contact.getContactName({ contactNumber: true }) }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <div class="row print-row-lg q-col-gutter-md entity-info-wrapper">
            <grid-card
                :columns="{
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 4,
                    xl: 4,
                }"
                class="entity-info contact-info"
                data-test="wrapper:contact-info"
            >
                <div class="contact-info--main-address">
                    <address data-test="wrapper:contact-main-address">
                        {{ contact.getContactName({ salutation: true, title: true }) }}<br>
                        {{ contact.getContactAddress(contact.mainAddress, { multiline: true }) }}
                    </address>
                    <base-button
                        v-if="editMode"
                        round
                        color="none"
                        text-color="edit-mode-link"
                        size="xs"
                        class="q-ml-xs do-not-print"
                        icon="mib-pencil"
                        data-test="btn:update-main-address"
                        @click="triggerUpdateAddress(contact.mainAddress)"
                    >
                        <q-tooltip
                            :delay="1000"
                            anchor="center right"
                            self="center left"
                            :offset="[10, 0]"
                            transition-show="scale"
                            transition-hide="scale"
                        >{{ $tc('common.address.edit-address', 1) }}</q-tooltip>
                    </base-button>

                    <br>

                    <a
                        class="q-link q-mr-sm do-not-print"
                        tabindex="0"
                        data-test="link:copy-address"
                        @click="copyToClipboard(contact.getContactName({ salutation: true, title: true }) + '\n' + contact.getContactAddress(contact.mainAddress, { multiline: true }))"
                        @keyup.enter="copyToClipboard(contact.getContactName({ salutation: true, title: true }) + '\n' + contact.getContactAddress(contact.mainAddress, { multiline: true }))"
                    ><q-icon name="mib-copy-paste" class="q-mr-xs" />{{ $tc('common.address.copy-address', 1) }}</a>
                    <a
                        class="q-link q-mr-sm do-not-print"
                        :href="`https://www.google.ch/maps/dir//${ encodeURIComponent(contact.getContactAddress(contact.mainAddress, { noPOBox: true }))}`"
                        target="_blank"
                        rel="noopener"
                        data-test="link:google-maps-address"
                    ><q-icon name="mib-style-two-pin-marker" class="q-mr-xs" />{{ $t('common.address.open-in-google-maps') }}</a>
                    <template v-if="editMode">
                        <br class="do-not-print">
                        <a
                            class="edit-link q-link q-mr-sm do-not-print"
                            tabindex="0"
                            data-test="link:create-address"
                            @click="triggerCreateAddress"
                            @keyup.enter="triggerCreateAddress"
                        ><q-icon name="mib-add" class="q-mr-xs" />{{ $tc('common.address.add-address', 1) }}</a>

                        <br class="do-not-print">
                        <a
                            class="edit-link q-link q-mr-sm do-not-print"
                            tabindex="0"
                            data-test="link:create-contact-with-same-address"
                            @click="triggerCreateNewContactWithSameAddress"
                            @keyup.enter="triggerCreateNewContactWithSameAddress"
                        ><q-icon name="mib-single-neutral-actions-add" class="q-mr-xs" />{{ $tc('views.contact.detail.create-another-contact-with-same-address', 1) }}</a>
                    </template>
                </div>

                <contact-email-address-list
                    :items="contact.emailAddresses"
                    :target-object="contact"
                    @create="createEmailAddress"
                    @update="updateEmailAddress"
                    @delete="deleteEmailAddress"
                />

                <contact-phone-number-list
                    :items="contact.phoneNumbers"
                    :target-object="contact"
                    @create="createPhoneNumber"
                    @update="updatePhoneNumber"
                    @delete="deletePhoneNumber"
                />

                <template v-slot:actions>
                    <correspondence-item-create-button
                        v-can="'Feature:correspondence:core'"
                        class="do-not-print"
                        :correspondence-item-target-object-type="CorrespondenceItemTargetObjectType.CONTACT"
                        :correspondence-item-target-object-id="contact.id"
                        :disable-email="!contact.emailAddresses.length"
                    />
                </template>
            </grid-card>

            <grid-card
                :columns="{
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 8,
                    xl: 8,
                }"
                :class="['entity-info contact-info--details', { 'area-edit-mode-active': editMode && areaEditModeContactInfo }]"
                data-test="wrapper:contact-info-details"
            >
                <template v-if="!areaEditModeContactInfo">
                    <dl class="q-mb-none">
                        <template v-if="contact.type === Contact.type.PERSON">
                            <dt>{{ $tc('common.contact.birthday', 1) }}: </dt>
                            <!-- TODO: Add countdown to birthday when its less than 1 week away: (Turns {n} in {x} days.) -->
                            <!-- TODO: Add highlighting when birthday == today: (Turns {n} today.) -->
                            <dd v-if="contact.date_of_birth" data-test="text:contact-person-birthdate">{{ contact.formattedDateOfBirth }}
                                <span v-if="!contact.is_deceased" class="additional-info" data-test="text:contact-person-age">({{ $tc('common.contact.age--n-years-old', 2, { age: contact.age }) }})</span>
                            </dd>
                            <dd v-else class="additional-info">{{ $t('common.term.not-provided--alt') }}</dd>

                            <template v-if="contact.is_deceased">
                                <br>
                                <dt>{{ $t('common.contact.deceased-on') }}: </dt>
                                <dd v-if="contact.date_of_death">{{ contact.formattedDateOfDeath }}
                                    <span class="additional-info">
                                        († {{ $tc('common.contact.age--n-years-old', 2, { age: contact.getAge(contact.date_of_death) }) }})
                                    </span>
                                </dd>
                                <dd v-else class="additional-info">{{ $t('common.term.not-provided--alt') }}</dd>
                            </template>
                            <br>

                            <dt>{{ $tc('common.contact.profession', 1) }}: </dt>
                            <dd :class="getFormattedFieldValue(contact.profession).cssClass" data-test="text:contact-person-profession">{{ getFormattedFieldValue(contact.profession).value }}</dd>
                            <br>

                            <dt>{{ $tc('common.contact.role', 1) }}: </dt>
                            <dd :class="getFormattedFieldValue(contact.role).cssClass" data-test="text:contact-person-role">{{ getFormattedFieldValue(contact.role).value }}</dd>
                            <br>

                            <dt>{{ $tc('common.contact.nationality', 1) }}: </dt>
                            <dd :class="getFormattedFieldValue(contact.nationality ? contact.nationality.name : null).cssClass" data-test="text:contact-person-nationality">{{ getFormattedFieldValue(contact.nationality ? contact.nationality.name : null).value }}</dd>
                            <br>

                            <dt>{{ $t('common.contact.civil-status.civil-status') }}: </dt>
                            <dd v-if="contact.civil_status" data-test="text:contact-person-civil-status">{{ $t('common.contact.civil-status.' + toKebabCase(contact.civil_status.toLowerCase())) }}</dd>
                            <dd v-else class="additional-info">{{ $t('common.term.not-provided--alt') }}</dd>
                            <br>

                            <dt>{{ $tc('common.contact.correspondence-language', 1) }}: </dt>
                            <dd :class="getFormattedFieldValue(contact.correspondenceLanguage ? contact.correspondenceLanguage.name : null).cssClass" data-test="text:contact-person-correspondence-language">{{ getFormattedFieldValue(contact.correspondenceLanguage ? contact.correspondenceLanguage.name : null).value }}</dd>
                            <br>

                            <dt>{{ $t('common.contact.driving-license-since') }}: </dt>
                            <dd :class="getFormattedFieldValue(contact.formattedDrivingLicenceSince).cssClass" data-test="text:contact-person-driving-license-since">{{ getFormattedFieldValue(contact.formattedDrivingLicenceSince).value }}</dd>
                            <br>

                            <dt>{{ $tc('common.term.website', 1) }}: </dt>
                            <dd v-if="contact.website" class="website" data-test="text:contact-person-website"><a
                                class="q-link q-mr-sm"
                                :href="encodeURI(contact.website)"
                                target="_blank"
                                rel="noopener"
                            >{{ contact.website }}</a></dd>
                            <dd v-else class="additional-info">{{ getFormattedFieldValue(contact.website).value }}</dd>
                            <br>
                        </template>

                        <template v-else>
                            <dt>{{ $t('common.contact.uid') }}: </dt>
                            <dd :class="getFormattedFieldValue(contact.uid).cssClass" data-test="text:contact-company-uid">{{ getFormattedFieldValue(contact.uid).value }}</dd>
                            <br>

                            <dt>{{ $t('common.term.liable-to-vat') }}: </dt>
                            <dd :class="getFormattedFieldValue(contact.is_liable_to_vat).cssClass" data-test="text:contact-company-vat-liability">{{ getFormattedFieldValue(contact.is_liable_to_vat).value }}</dd>
                            <br>

                            <dt>{{ $tc('common.contact.correspondence-language', 1) }}: </dt>
                            <dd :class="getFormattedFieldValue(contact.correspondenceLanguage ? contact.correspondenceLanguage.name : null).cssClass" data-test="text:contact-company-correspondence-language">{{ getFormattedFieldValue(contact.correspondenceLanguage ? contact.correspondenceLanguage.name : null).value }}</dd>
                            <br>

                            <dt>{{ $tc('common.term.website', 1) }}: </dt>
                            <dd v-if="contact.website" data-test="text:contact-company-website"><a
                                class="q-link q-mr-sm"
                                :href="encodeURI(contact.website)"
                                target="_blank"
                                rel="noopener"
                            >{{ contact.website }}</a></dd>
                            <dd v-else class="additional-info" data-test="text:contact-company-website">{{ getFormattedFieldValue(contact.website).value }}</dd>
                            <br>
                        </template>
                    </dl>

                    <dl class="q-mb-none">
                        <dt>{{ $t('common.contact.customer-status.title') }}: </dt>
                        <dd :class="getFormattedFieldValue(contact.customer_status).cssClass" data-test="text:contact-status">{{ getFormattedFieldValue(contact.customer_status, { defaultTranslationBasePath: 'common.contact.customer-status' }).value }}</dd>
                        <br>

                        <dt>{{ $t('common.contact.customer-since') }}: </dt>
                        <dd :class="getFormattedFieldValue(contact.formattedCustomerSince).cssClass" data-test="text:contact-customer-since">{{ getFormattedFieldValue(contact.formattedCustomerSince).value }}</dd>
                        <br>

                        <dt>{{ $t('common.contact.customer-until') }}: </dt>
                        <dd :class="getFormattedFieldValue(contact.formattedCustomerUntil).cssClass">{{ getFormattedFieldValue(contact.formattedCustomerUntil).value }}</dd>
                        <br>

                        <dt>{{ $tc('common.contact.consultant', 2) }}: </dt>
                        <dd data-test="text:contact-consultants">
                            <div v-for="(consultant, index) in contact.consultants" :key="`consultant-${index}`">{{ consultant.getContactName() }} <span v-if="!consultant.worksForUserConsultingCompany" class="additional-info">({{ consultant.consultingCompanyName }})</span></div>
                        </dd>
                    </dl>
                </template>

                <template v-if="editMode && areaEditModeContactInfo">
                    <contact-person-update-form
                        v-if="contact.type === Contact.type.PERSON"
                        :contact="contact"
                        @update="updateContact"
                        @cancel="areaEditModeContactInfo = false"
                        @done="areaEditModeContactInfo = false"
                    />
                    <contact-company-update-form
                        v-else
                        :contact="contact"
                        @update="updateContact"
                        @cancel="areaEditModeContactInfo = false"
                        @done="areaEditModeContactInfo = false"
                    />
                </template>
            </grid-card>
        </div>

        <div class="col-xs-12 q-mt-md do-not-print">
            <p class="q-mb-none">
                <small>
                    <base-button
                        show-as-link
                        @click="$refs.activityLogOverlay.open({ filterSubjects: [{ id: id, type: ActivityLogSubjectType.CONTACT }], filterIncludeSubjectRelations: [ActivityLogSubjectRelation.ADDRESSES, ActivityLogSubjectRelation.EMAIL_ADDRESSES, ActivityLogSubjectRelation.PHONE_NUMBERS, ActivityLogSubjectRelation.CONTACT_RELATIONSHIPS] })"
                    >{{ $tc('common.term.created-on--date-time', 1, { date: contact.formattedCreatedAtDate, time: contact.formattedCreatedAtTime }) }}</base-button>
                </small>
            </p>
        </div>

        <div class="row q-mt-sm q-col-gutter-md">
            <div
                class="col-xs-12"
                data-test="tabs"
            >
                <main-tabs>
                    <q-tabs
                        v-model="activeTab"
                        dense
                        no-caps
                        inline-label
                        align="left"
                        class="bg-grey-2"
                        indicator-color="accent"
                        data-test="tabs:links"
                    >
                        <q-route-tab
                            name="contracts"
                            :to="{ name: 'contact-detail', params: { id: id } }"
                            icon="mib-common-file-text-alternate"
                            :label="$tc('common.term.contract', 2)"
                            data-test="tab:contracts"
                        >
                            <q-badge
                                v-if="numberOfContracts !== null"
                                color="secondary-light"
                                text-color="secondary-dark"
                                class="q-ml-xs"
                            >{{ numberOfContracts }}</q-badge>
                        </q-route-tab>
                        <q-route-tab
                            name="documents"
                            :to="{ name: 'contact-detail', params: { id: id, tab: 'documents' } }"
                            icon="mib-common-file-stack-alternate"
                            :label="$tc('common.term.document', 2)"
                            data-test="tab:documents"
                        >
                            <q-badge
                                v-if="numberOfDocuments !== null"
                                color="secondary-light"
                                text-color="secondary-dark"
                                class="q-ml-xs"
                            >{{ numberOfDocuments }}</q-badge>
                        </q-route-tab>
                        <q-route-tab
                            name="addresses"
                            :to="{ name: 'contact-detail', params: { id: id, tab: 'addresses' } }"
                            icon="mib-maps-pin-1"
                            :label="$tc('common.term.address', 2)"
                            data-test="tab:addresses"
                        >
                            <q-badge
                                v-if="contact.addresses"
                                color="secondary-light"
                                text-color="secondary-dark"
                                class="q-ml-xs"
                            >{{ contact.addresses.length }}</q-badge>
                        </q-route-tab>
                        <q-route-tab
                            name="comments"
                            :to="{ name: 'contact-detail', params: { id: id, tab: 'comments' } }"
                            icon="mib-messages-bubble-square-text-alternate"
                            :label="$tc('common.term.comment', 2)"
                            data-test="tab:comments"
                        >
                            <q-badge
                                v-if="numberOfComments !== null"
                                color="secondary-light"
                                text-color="secondary-dark"
                                class="q-ml-xs"
                            >{{ numberOfComments }}</q-badge>
                        </q-route-tab>
                        <q-route-tab
                            name="contact-relationships"
                            :to="{ name: 'contact-detail', params: { id: id, tab: 'relationships' } }"
                            icon="mib-human-resources-hierarchy-1"
                            :label="$tc('common.contact.relationship.relationship', 2)"
                            data-test="tab:contact-relationships"
                        />
                        <q-route-tab
                            v-if="contact.type === ContactType.COMPANY"
                            name="areas-of-responsibility"
                            :to="{ name: 'contact-detail', params: { id: id, tab: 'areas-of-responsibility' } }"
                            icon="mib-hierarchy-6"
                            :label="$tc('common.contact.relationship.area-of-responsibility.area-of-responsibility', 2)"
                            data-test="tab:areas-of-responsibility"
                        />
                    </q-tabs>
                </main-tabs>

                <q-tab-panels
                    v-model="activeTab"
                    animated
                    transition-prev="fade"
                    transition-next="fade"
                    data-test="tabs:panels"
                >
                    <!-- TODO: check if keep-alive should be used (performance wise) -->
                    <q-tab-panel
                        name="contracts"
                        class="q-pl-none q-pr-none"
                        data-test="tab-panel:contracts"
                    >
                        <h2>{{ $tc('common.term.contract', 2) }}</h2>
                        <contact-contract-list ref="contactContractList" :contact-id="id" @numberOfContracts="amount => { numberOfContracts = amount }" />
                    </q-tab-panel>

                    <q-tab-panel
                        name="documents"
                        class="q-pl-none q-pr-none"
                        data-test="tab-panel:documents"
                    >
                        <h2>{{ $tc('common.term.document', 2) }}</h2>
                        <consulting-file-browser
                            :target-object-type="ConsultingFileTargetObjectType.CONTACT"
                            :target-object-id="contact.id"
                            :target-object-consultants="contact.consultants"
                            :meta="{ contactName: contact.getContactName() }"
                            @numberOfDocuments="amount => numberOfDocuments = amount"
                            @currentFileTypeIdChange="fileTypeId => consultingFileBrowserFileTypeId = fileTypeId"
                        />
                        <!-- TODO: Find solution to stop flickering of in-page-footer when tabs change -->
                        <in-page-footer>
                            <consulting-file-upload-button
                                :target-object-type="ConsultingFileTargetObjectType.CONTACT"
                                :target-object-id="contact.id"
                                :target-object-consultants="contact.consultants"
                                :file-type-id="consultingFileBrowserFileTypeId"
                                :meta="{ contactName: contact.getContactName() }"
                            />
                        </in-page-footer>
                    </q-tab-panel>

                    <q-tab-panel
                        name="addresses"
                        class="q-pl-none q-pr-none"
                        data-test="tab-panel:addresses"
                    >
                        <div class="block-wrapper">
                            <h2>{{ $tc('common.term.address', contact.addresses.length) }}</h2>
                            <contact-address-list
                                ref="contactAddressList"
                                :items="contact.addresses"
                                :target-object="contact"
                                @create="createAddress"
                                @update="updateAddress"
                                @delete="deleteAddress"
                            />
                        </div>
                    </q-tab-panel>

                    <q-tab-panel
                        name="comments"
                        class="q-pl-none q-pr-none"
                        data-test="tab-panel:comments"
                    >
                        <div class="block-wrapper">
                            <h2>{{ $tc('common.term.comment', 2) }}</h2>
                            <comment-list
                                ref="contactCommentList"
                                :items="comments"
                                :target-object-type="CommentTargetObjectType.CONTACT"
                                :target-object-id="contact.id"
                                @numberOfComments="amount => { numberOfComments = amount }"
                            />
                        </div>
                    </q-tab-panel>

                    <q-tab-panel
                        name="contact-relationships"
                        class="q-pl-none q-pr-none"
                        data-test="tab-panel:contact-relationships"
                    >
                        <div class="block-wrapper">
                            <h2>{{ $tc('common.contact.relationship.relationship', 2) }}</h2>
                            <contact-relationship-list
                                ref="contactRelationshipList"
                                :contact="contact"
                            />
                        </div>
                    </q-tab-panel>

                    <q-tab-panel
                        v-if="contact.type === ContactType.COMPANY"
                        name="areas-of-responsibility"
                        class="q-pl-none q-pr-none"
                        data-test="tab-panel:areas-of-responsibility"
                    >
                        <div class="block-wrapper">
                            <h2>{{ $tc('common.contact.relationship.area-of-responsibility.area-of-responsibility', 2) }}</h2>
                            <area-of-responsibility-list
                                ref="areaOfResponsibilityList"
                                :contact="contact"
                            />
                        </div>
                    </q-tab-panel>
                </q-tab-panels>
            </div>
        </div>

        <!-- Activity log -->
        <activity-log-overlay ref="activityLogOverlay" />
    </page-wrapper>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { EventBus } from '@/event-bus'
import { Contact } from '@/models/contact'
import { copyToClipboard } from 'quasar'
import CONTACT_FIELDS from '@/forms/contact.fields.json'
import { getFormItemByKey, getFormattedFieldValue } from '@/helpers/form'

import { ConsultingFileTargetObjectType, CommentTargetObjectType, ActivityLogSubjectRelation, ActivityLogSubjectType, CorrespondenceItemTargetObjectType, ContactType, ContactStatus } from '@/enums/graphql'

import Actions from '@/components/Actions'
import ActivityLogOverlay from '@/components/ActivityLogOverlay'
import AreaOfResponsibilityList from '@/components/areaOfResponsibility/AreaOfResponsibilityList.vue'
import CommentList from '@/components/CommentList.vue'
import ConsultingFileBrowser from '@/components/consultingFile/ConsultingFileBrowser'
import ConsultingFileUploadButton from '@/components/consultingFile/ConsultingFileUploadButton'
import ContactAddressList from '@/components/contact/ContactAddressList.vue'
import ContactCompanyUpdateForm from '@/components/contact/ContactCompanyUpdateForm.vue'
import ContactContractList from '@/components/contact/ContactContractList.vue'
import ContactEmailAddressList from '@/components/contact/ContactEmailAddressList.vue'
import ContactName from '@/components/contact/ContactName.vue'
import ContactPersonUpdateForm from '@/components/contact/ContactPersonUpdateForm.vue'
import ContactPhoneNumberList from '@/components/contact/ContactPhoneNumberList.vue'
import ContactRelationshipList from '@/components/contactRelationship/ContactRelationshipList.vue'
import ContactTypeBadges from '@/components/contact/ContactTypeBadges.vue'
import CorrespondenceItemCreateButton from '@/components/correspondence/CorrespondenceItemCreateButton'
import FormDialog from '@/components/form/FormDialog'
import MainTabs from '@/components/MainTabs'
import StatusBadge from '@/components/StatusBadge.vue'

export default {
    name: 'ContactDetail',
    meta () {
        return {
            title: !this.contact ? `${this.$t('common.term.loading')} …` : `${this.contact.getContactName({
                salutation: true,
                title: true,
                contactNumber: true,
            })} – ${this.getActiveTabName()}`,
        }
    },
    components: {
        Actions,
        ActivityLogOverlay,
        AreaOfResponsibilityList,
        CommentList,
        ConsultingFileBrowser,
        ConsultingFileUploadButton,
        ContactAddressList,
        ContactCompanyUpdateForm,
        ContactContractList,
        ContactEmailAddressList,
        ContactName,
        ContactPersonUpdateForm,
        ContactPhoneNumberList,
        ContactRelationshipList,
        ContactTypeBadges,
        CorrespondenceItemCreateButton,
        FormDialog,
        MainTabs,
        StatusBadge,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        tab: {
            type: String,
        },
    },
    data () {
        return {
            CONTACT_NUMBER_UPDATE_SCHEMA: {
                type: 'root',
                children: [{
                    type: 'field',
                    key: 'contactNumberValueStructure',
                    label: this.$tc('common.contact.contact-number', 1),
                    inputType: 'entityNumber',
                    validators: [
                        { 'type': 'required' },
                    ],
                }],
            },
            ConsultingFileTargetObjectType,
            CommentTargetObjectType,
            ActivityLogSubjectRelation,
            ActivityLogSubjectType,
            CorrespondenceItemTargetObjectType,
            Contact,
            ContactType,
            contact: null,
            comments: [],
            activeTab: this.tab,
            areaEditModeContactInfo: false,
            numberOfContracts: null,
            numberOfDocuments: null,
            numberOfComments: null,
            consultingFileBrowserFileTypeId: null,
        }
    },
    computed: {
        ContactStatus () {
            return ContactStatus
        },
        editMode () {
            return this.$store.state.editMode
        },
        unarchiveFormBuilderSettings () {
            if (this.contact.archivedConsultants.length) {
                return {
                    schema: {
                        type: 'root',
                        children: [getFormItemByKey(CONTACT_FIELDS, 'consultants')],
                    },
                }
            }
            return {}
        },
        pinnedComments () {
            return this.comments.filter(comment => comment.pinned)
        },
    },
    watch: {
        editMode (newVal) {
            if (newVal === false) {
                this.areaEditModeContactInfo = false
            }
        },
        '$route' (to, from) {
            // Re-fetch if contact changes while view stays the same (e.g. navigate to other contact via global search).
            if (to.params.id !== from.params.id) {
                this.areaEditModeContactInfo = false // Cancel editing view.
                this.fetchObject()
            }
        },
    },
    created () {
        this.fetchObject()
    },
    beforeDestroy () {
        EventBus.$emit('taskPanel:setTargetObject', undefined)
    },
    methods: {
        fetchObject () {
            Contact.objects.get(this.id).then(contact => {
                this.contact = contact
                EventBus.$emit('taskPanel:setTargetObject', this.contact)

                // Reset tab number badges
                this.numberOfContracts = this.contact.number_of_contracts
                this.numberOfDocuments = this.contact.number_of_contact_files
                this.numberOfComments = this.contact.number_of_comments

                // Add pinnedComments as initial comments data, but only if the tab comment component hasn't populated it already.
                if (this.contact.pinnedComments && this.contact.pinnedComments.length && this.comments.length === 0) {
                    this.comments = this.contact.pinnedComments
                }
            })
        },
        getActiveTabName () {
            switch (this.activeTab) {
                case 'contracts':
                    return this.$tc('common.term.contract', 2)
                case 'documents':
                    return this.$tc('common.term.document', 2)
                case 'addresses':
                    return this.$tc('common.term.address', 2)
                case 'comments':
                    return this.$tc('common.term.comment', 2)
                case 'contact-details':
                    return this.$tc('views.contact.detail.title', 2)
                case 'change-log':
                    return this.$tc('common.term.change-log')
                default:
                    return ''
            }
        },
        copyToClipboard (content) {
            content = content.replace(/<br>/g, '\n')

            copyToClipboard(content)
                .then(() => {
                    this.$q.notify({
                        type: 'positive',
                        message: this.$t('common.notifications.copied-to-clipboard-success'),
                    })
                })
                .catch(() => {
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$t('common.notifications.copied-to-clipboard-error'),
                    })
                })
        },
        handleActions (action) {
            switch (action.key) {
                case Contact.action.ARCHIVE:
                    this.$refs.contactArchiveDialog.open()
                    break
                case Contact.action.UNARCHIVE:
                    this.$refs.contactUnarchiveDialog.open()
                    break
                case Contact.action.DELETE:
                    this.$refs.contactDeleteDialog.open()
                    break
                case Contact.action.EXPORT:
                    this.contact.export()
                    break
            }
        },
        handleContactArchive () {
            this.contact.archive()
                .then(() => {
                    this.$refs.contactArchiveDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contact.contact-archived-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.contactArchiveDialog.showFormErrorMessage(error)
                    this.$refs.contactArchiveDialog.resetFormSubmitStatus()
                })
        },
        handleContactUnarchive ({ consultants }) {
            this.contact.unarchive(consultants)
                .then(() => {
                    this.$refs.contactUnarchiveDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contact.contact-unarchived-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.contactUnarchiveDialog.showFormErrorMessage(error)
                    this.$refs.contactUnarchiveDialog.resetFormSubmitStatus()
                })
        },
        handleContactDelete () {
            this.contact.delete()
                .then(() => {
                    this.$refs.contactDeleteDialog.close()
                    this.$router.replace({ name: 'contact-list' })
                })
                .catch(error => {
                    this.$refs.contactDeleteDialog.showFormErrorMessage(error)
                    this.$refs.contactDeleteDialog.resetFormSubmitStatus()
                })
        },
        updateContactNumber () {
            this.$refs.contactNumberUpdateDialog.open()
        },
        handleContactNumberUpdate ({ contactNumberValueStructure }) {
            if (JSON.stringify(contactNumberValueStructure) !== JSON.stringify(this.contact.contactNumber.value_structure)) {
                this.contact.updateContactNumber(contactNumberValueStructure)
                    .then(() => {
                        this.$refs.contactNumberUpdateDialog.close()
                        this.$q.notify({
                            type: 'positive',
                            message: this.$tc('common.notifications.contact.contact-number-updated-success', 1),
                        })
                    })
                    .catch(error => {
                        this.$refs.contactNumberUpdateDialog.showFormErrorMessage(error)
                        this.$refs.contactNumberUpdateDialog.resetFormSubmitStatus()
                    })
            } else {
                this.$refs.contactNumberUpdateDialog.formErrorMessage = this.$tc('common.contact.edit-contact-number--error--same-value', 1)
                this.$refs.contactNumberUpdateDialog.resetFormSubmitStatus()
            }
        },
        updateContact (contact) {
            Object.assign(this.contact, contact)
        },
        triggerCreateAddress () {
            const location = this.$router.resolve({ name: 'contact-detail', params: { id: this.id, tab: 'addresses' } })
            if (location.href !== this.$route.path) {
                this.$router.push(location.href).then(async () => {
                    this.activeTab = 'addresses'
                    await this.$nextTick()
                    this.$refs.contactAddressList.triggerCreateItem()
                })
            } else {
                this.$refs.contactAddressList.triggerCreateItem()
            }
        },
        createAddress (address) {
            if (address.is_main_correspondence_address) {
                this.contact.addresses.find(item => item.is_main_correspondence_address).is_main_correspondence_address = false
                this.contact.mainAddress = address
                this.contact.addresses.unshift(address)
            } else {
                this.contact.addresses.push(address)
            }
        },
        triggerUpdateAddress (address) {
            const location = this.$router.resolve({ name: 'contact-detail', params: { id: this.id, tab: 'addresses' } })
            if (location.href !== this.$route.path) {
                this.$router.push(location.href).then(async () => {
                    this.activeTab = 'addresses'
                    await this.$nextTick()
                    this.$refs.contactAddressList.triggerUpdateItemWrapper(address)
                })
            } else {
                this.$refs.contactAddressList.triggerUpdateItemWrapper(address)
            }
        },
        updateAddress (address) {
            const index = this.contact.addresses.findIndex(item => item.id === address.id)
            if (address.is_main_correspondence_address) {
                this.contact.addresses.find(item => item.is_main_correspondence_address).is_main_correspondence_address = false
                this.contact.addresses.splice(index, 1)
                this.contact.mainAddress = address
                this.contact.addresses.unshift(address)
            } else {
                this.contact.addresses.splice(index, 1, address)
            }
        },
        deleteAddress (address) {
            const index = this.contact.addresses.findIndex(item => item.id === address.id)
            this.contact.addresses.splice(index, 1)
        },
        createEmailAddress (emailAddress) {
            if (emailAddress.is_main_email_address) {
                const currentMainEmailAddress = this.contact.emailAddresses.find(item => item.is_main_email_address)
                if (currentMainEmailAddress) currentMainEmailAddress.is_main_email_address = false
                this.contact.mainEmailAddress = emailAddress
                this.contact.emailAddresses.unshift(emailAddress)
            } else {
                this.contact.emailAddresses.push(emailAddress)
            }
        },
        updateEmailAddress (emailAddress) {
            const index = this.contact.emailAddresses.findIndex(item => item.id === emailAddress.id)
            if (emailAddress.is_main_email_address) {
                this.contact.emailAddresses.find(item => item.is_main_email_address).is_main_email_address = false
                this.contact.emailAddresses.splice(index, 1)
                this.contact.mainEmailAddress = emailAddress
                this.contact.emailAddresses.unshift(emailAddress)
            } else {
                this.contact.emailAddresses.splice(index, 1, emailAddress)
            }
        },
        deleteEmailAddress (emailAddress) {
            const index = this.contact.emailAddresses.findIndex(item => item.id === emailAddress.id)
            this.contact.emailAddresses.splice(index, 1)
        },
        createPhoneNumber (phoneNumber) {
            if (phoneNumber.is_main_phone_number) {
                const currentMainPhoneNumber = this.contact.phoneNumbers.find(item => item.is_main_phone_number)
                if (currentMainPhoneNumber) currentMainPhoneNumber.is_main_phone_number = false
                this.contact.mainPhoneNumber = phoneNumber
                this.contact.phoneNumbers.unshift(phoneNumber)
            } else {
                this.contact.phoneNumbers.push(phoneNumber)
            }
        },
        updatePhoneNumber (phoneNumber) {
            const index = this.contact.phoneNumbers.findIndex(item => item.id === phoneNumber.id)
            if (phoneNumber.is_main_phone_number) {
                this.contact.phoneNumbers.find(item => item.is_main_phone_number).is_main_phone_number = false
                this.contact.phoneNumbers.splice(index, 1)
                this.contact.mainPhoneNumber = phoneNumber
                this.contact.phoneNumbers.unshift(phoneNumber)
            } else {
                this.contact.phoneNumbers.splice(index, 1, phoneNumber)
            }
        },
        deletePhoneNumber (phoneNumber) {
            const index = this.contact.phoneNumbers.findIndex(item => item.id === phoneNumber.id)
            this.contact.phoneNumbers.splice(index, 1)
        },
        getFormattedFieldValue (...args) {
            return getFormattedFieldValue(...args)
        },
        triggerCreateNewContactWithSameAddress () {
            const addressQueryObject = {
                category: this.contact.mainAddress.category.id,
                poBox: this.contact.mainAddress.po_box,
                zip: this.contact.mainAddress.zip,
                city: this.contact.mainAddress.city,
                country: this.contact.mainAddress.country.id,
                validFrom: this.contact.mainAddress.valid_from,
            }
            if (this.contact.mainAddress.label) addressQueryObject.label = this.contact.mainAddress.label.id
            if (this.contact.mainAddress.address1) addressQueryObject.address1 = this.contact.mainAddress.address1
            if (this.contact.mainAddress.address2) addressQueryObject.address2 = this.contact.mainAddress.address2
            if (this.contact.mainAddress.address3) addressQueryObject.address3 = this.contact.mainAddress.address3
            this.$router.push({ name: 'contact-create', params: { type: 'person' }, query: addressQueryObject })
        },
        triggerUpdateComment (comment) {
            const location = this.$router.resolve({ name: 'contact-detail', params: { id: this.id, tab: 'comments' } })
            if (location.href !== this.$route.path) {
                this.$router.push(location.href).then(async () => {
                    this.activeTab = 'comments'
                    await this.$nextTick()
                    this.$refs.contactCommentList.triggerUpdateItem(comment)
                })
            } else {
                this.$refs.contactCommentList.triggerUpdateItem(comment)
            }
        },
        triggerDeleteComment (comment) {
            const location = this.$router.resolve({ name: 'contact-detail', params: { id: this.id, tab: 'comments' } })
            if (location.href !== this.$route.path) {
                this.$router.push(location.href).then(async () => {
                    this.activeTab = 'comments'
                    await this.$nextTick()
                    this.$refs.contactCommentList.triggerDeleteItem(comment)
                })
            } else {
                this.$refs.contactCommentList.triggerDeleteItem(comment)
            }
        },
        toKebabCase: function (text) {
            return kebabCase(text)
        },
    },
}
</script>

<style lang="scss" scoped>
// Make contact details form use the full width of the parent’s grid
.area-edit-mode-active {
    ::v-deep .card-content {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (min-width: $sizeBreakpointXl) {
    ::v-deep .website {
        max-width: 45ch;
        overflow: hidden;
        word-break: keep-all;

        a {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}


/*
.contact-details-wrapper {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.contact-info {
    width: 100%;

    @media screen and (min-width: $sizeBreakpointMd), print {
        flex: 1 1 30%;
        min-width: 350px;
        max-width: 450px;
        margin-right: $sizeSpacingMd;
        padding-right: $sizeSpacingMd;

        border-right: 1px solid var(--color-border-primary);
    }

    @media print and (min-width: 120mm) {
        min-width: auto;
        max-width: 90mm;
    }
}

// TODO: Add fix for certain screen sizes when drawerRight is visible
::v-deep .contact-info--details {
    flex: 0 1 auto;

    @media screen and (min-width: $sizeBreakpointXl) {
        .website {
            max-width: 45ch;
            overflow: hidden;
            word-break: keep-all;

            a {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    @media screen and (max-width: $sizeBreakpointMd - 1), print {
        flex: 1 1 100%;
        margin-top: 1.15em;
    }

    @media screen and (min-width: $sizeBreakpointMd), print and (min-width: 120mm) {
        flex: 1 1 35%;
        margin-top: 0;

        &.area-edit-mode-active {
            max-width: none;
        }
    }

    // TODO: Fix issue with dl layout being broken when drawerRight is shown
    @media screen and (min-width: $sizeBreakpointLg), print and (min-width: 120mm) {
        dl {
            display: inline-block;
            flex: 1 1 auto;
            min-width: 280px;
            max-width: 50%;

            vertical-align: top;

            & + dl {
                margin-left: $sizeSpacingMd * 2;
                padding-left: $sizeSpacingMd;
                border-left: 1px solid var(--color-border-primary);
            }
        }
    }

    @media print and (min-width: 120mm) {
        dl {
            min-width: auto;
            max-width: 50%;
        }
    }
}
*/
</style>
