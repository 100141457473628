import gql from 'graphql-tag'

export const DOCUMENT_TEMPLATES_QUERY = gql`
    query documentTemplates(
        $page: Int = 1
        $count: Int = 100
        $orderField: DocumentTemplateOrderField = NAME
        $orderDirection: OrderDirection = ASC
        $filterQuery: String
        $filterOwnerId: ID
        $filterType: DocumentType
        $filterSupportedTargetObjectTypes: [DocumentTemplateTargetObjectType!]
        $filterLanguageId: ID
    ) {
        documentTemplates(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                query: $filterQuery
                owner_id: $filterOwnerId
                type: $filterType
                supported_target_object_types: $filterSupportedTargetObjectTypes
                language_id: $filterLanguageId
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                owner {
                    ... on Consultant {
                        id
                        first_name
                        last_name
                    }
                    ... on ConsultingCompany {
                        id
                        company_name
                    }
                }
                available_for_subordinate_companies
                name
                description
                type
                supported_target_object_types
                language {
                    id
                    name
                }
                confidentiality_notice
                differentiate_formality
                contents {
                    id
                    formality
                }
                created_at
                updated_at
            }
        }
    }
`
