<template>
    <div v-if="queue.length" class="q-pt-sm">
        <h2 class="text-h3">{{ $tc('common.content-store.import-from-content-store', 1) }}</h2>

        <i18n path="common.term.importing-item" tag="p">
            <template v-slot:item>{{ $t('common.term.of--x-of-n', { x: (numberOfItemsTotal - queue.length + 1), n: numberOfItemsTotal }) }}: <b>{{ queue[0].item[displayPropertyName] }}</b></template>
        </i18n>
        <base-button
            primary-button
            flat
            @click="abort"
            @keypress.enter="abort"
        >
            {{ $t('common.term.abort') }}
        </base-button>
    </div>
    <div v-else-if="log.length" class="q-pt-sm">
        <template v-for="status in ['DONE', 'ABORTED', 'FAILED']">
            <div v-if="logItemsByStatus[status].length" :key="`status-${status.toLowerCase()}`" class="q-mb-lg">
                <h2 class="text-h3">{{ $t(`common.content-store.status.${status.toLowerCase()}`) }}</h2>
                <p>{{ $tc(`common.content-store.status.${status.toLowerCase()}--description`, logItemsByStatus[status].length) }}</p>

                <q-list class="bulk-item-list" :data-test="`bulk-item-list-${status.toLowerCase()}`">
                    <q-item
                        v-for="logItem in logItemsByStatus[status]"
                        :key="logItem.id"
                        class="log-item"
                        data-test="log-item"
                    >
                        <q-item-section thumbnail top class="log-item-icon">
                            <bulk-item-status-indicator :status="ContentStoreItemWrapperStatus[status]" base-icon="common-file" status-translation-base-path="common.content-store.status" />
                        </q-item-section>

                        <q-item-section class="log-item-info" data-test="log-item-info">
                            <q-item-label>
                                <span class="log-item-name" data-test="log-item-name">{{ logItem.item[displayPropertyName] }}</span>
                            </q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
            </div>
        </template>

        <base-button
            primary-button
            @click="acknowledge"
            @keypress.enter="acknowledge"
        >
            {{ $t('common.term.acknowledge') }}
        </base-button>
    </div>
</template>

<script>
import { ContentStoreItemWrapperStatus } from '@/enums'
import BulkItemStatusIndicator from '@/components/BulkItemStatusIndicator'

export default {
    name: 'ContentStoreImport',
    components: {
        BulkItemStatusIndicator,
    },
    props: {
        queue: {
            type: Array,
            required: true,
        },
        log: {
            type: Array,
            required: true,
        },
        displayPropertyName: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            ContentStoreItemWrapperStatus,
            numberOfItemsTotal: null,
        }
    },
    computed: {
        logItemsByStatus () {
            const items = {}
            Object.keys(ContentStoreItemWrapperStatus).forEach(contentStoreItemWrapperStatus => {
                items[contentStoreItemWrapperStatus] = this.log.filter(logItem => logItem.status === contentStoreItemWrapperStatus)
            })
            return items
        },
    },
    created () {
        this.numberOfItemsTotal = this.queue.length
    },
    methods: {
        abort () {
            this.$emit('abort')
        },
        acknowledge () {
            this.$emit('acknowledge')
        },
    },
}
</script>

<style lang="scss" scoped>
.log-item {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    min-height: auto;

    &-icon {
        padding-right: $sizeSpacingXs;
    }

    &-info {
    }

    &-name {
        word-break: break-word;
    }
}
</style>
