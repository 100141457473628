import { Model } from '@/models'

export class FileStorageItemAccessControl extends Model {
    /**
     * Create a FileStorageItemAccessControl model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'fileStorageItem',
            'accessors',
            'created_at',
            'updated_at',
        ], data)
    }
}
