import { Model } from '@/models'
import { formatDate } from '@/helpers/date'
import { i18n } from '@/i18n'
import { textList } from '@/helpers'

export class BasicAccountStatementReport extends Model {
    /**
     * Create a BasicAccountStatementReport model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'accounts',
            'accountStatementReportAccounts',
            'date_from',
            'date_until',
        ], data)
    }

    get formattedDateFrom () {
        return formatDate(this.date_from)
    }

    get formattedDateUntil () {
        return formatDate(this.date_until)
    }

    get formattedPeriod () {
        return `${this.formattedDateFrom}–${this.formattedDateUntil}`
    }

    // TODO improvement: Extract to helper function.
    get formattedAccountList () {
        const threshold = 3
        let accountList = {}

        // Individual accounts
        if (this.accountStatementReportAccounts?.length) {
            // TODO @TFU: Check if it would make more sense to already get the accounts sorted correctly (by `account.number` ASC) from the API.
            // Get account numbers (Integers) from array and sort them
            accountList.accounts = this.accountStatementReportAccounts.map(account => account.number).sort(function(a, b) { return a - b })

            if (this.accountStatementReportAccounts.length > threshold) {
                accountList.teaser = {
                    full: `${accountList.accounts.slice(0, threshold).join(', ')} … ${i18n.t('common.term.more--and-n-other--entity', { amount: this.accountStatementReportAccounts.length - threshold, entity: i18n.tc('common.accounting.account.account', 2) })}`,
                    short: `${accountList.accounts.slice(0, threshold).join(', ')} … ${i18n.t('common.term.more--and-n-others', { amount: this.accountStatementReportAccounts.length - threshold })}`,
                    accounts: accountList.accounts.slice(0, threshold).join(', '),
                    numberOfAdditionalAccounts: this.accountStatementReportAccounts.length - threshold,
                }
            }

            if (this.accountStatementReportAccounts.length === 1) {
                accountList.accounts = `${this.accountStatementReportAccounts[0].number} – ${this.accountStatementReportAccounts[0].name}`
            } else {
                accountList.accounts = textList(accountList.accounts)
            }
        } else {
            // Account range
            accountList.accounts = i18n.tc('common.term.from-x-to-y--short', 1, { x: this.account_number_from, y: this.account_number_to})
        }

        return accountList
    }
}
