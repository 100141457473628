import { QueueItemStatus } from '@/enums/graphql'

/**
 * Parse form data values.
 * @export
 * @param  {Object} formData - formData - {dateFrom, dateUntil, fileTypes[], accountNumberFrom, accountNumberTo, accountIds}
 * @return {Object} typeof AccountStatementReportInput
 */

export function parseFormDataToInput(formData) {
    return {
        date_from: formData.dateFrom,
        date_until: formData.dateUntil,
        file_types: formData.fileTypes,
        account_number_from: !formData.accountIds.length ? parseFloat(formData.accountNumberFrom) : null,
        account_number_to: !formData.accountIds.length ? parseFloat(formData.accountNumberTo) : null,
        account_ids: formData.accountIds,
    }
}

/**

 * Filter account balance report with cancel creation condition.
 * @export
 * @param  {Array} accountBalanceReports - typeof AccountBalanceReport
 * @return {Array} typeof AccountBalanceReport
 */

export function cancelCreationCondition (accountBalanceReports) {
    return accountBalanceReports.filter(accountBalanceReport => accountBalanceReport.currentQueueItem.status === QueueItemStatus.QUEUED)
}

/**

 * Filter account balance report with delete condition.
 * @export
 * @param  {Array} accountBalanceReports - typeof AccountBalanceReport
 * @return {Array} typeof AccountBalanceReport
 */

export function deleteCondition (accountBalanceReports) {
    return accountBalanceReports.filter(accountBalanceReport => [QueueItemStatus.FAILED, QueueItemStatus.CANCELLED, QueueItemStatus.DONE].includes(accountBalanceReport.currentQueueItem.status))
}
