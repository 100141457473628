import { returnValueFromFunctionCallOrArgument } from '@/helpers'
import { setupFormLocale, setupFormData, setupFormValidations, extractErrorMessage } from '@/helpers/form'
import FormBuilder from '@/components/form/FormBuilder.vue'

export const formBuilderMixin = {
    components: {
        FormBuilder,
    },
    data () {
        const localize = (this.$options.formBuilderSettings.localize === undefined) ? true : this.$options.formBuilderSettings.localize
        const schema = returnValueFromFunctionCallOrArgument(this, this.$options.formBuilderSettings.schema)
        const fields = returnValueFromFunctionCallOrArgument(this, this.$options.formBuilderSettings.fields)
        return {
            formConfiguration: (localize) ? setupFormLocale(schema) : schema,
            formFields: fields,
            formData: setupFormData(schema, fields),
            formSubmitStatus: false,
            formErrorMessage: '',
        }
    },
    validations () {
        return {
            formData: setupFormValidations(this.formConfiguration, this.formFields),
        }
    },
    methods: {
        processFormSubmit () {
            this.formErrorMessage = ''
            this.$v.$touch()
            if (!this.$v.$invalid) {
                const formData = JSON.parse(JSON.stringify(this.formData)) // TODO refactor @TFU: This is a problem as soon as binary data (e.g. uploads) are part of formData.
                this.formSubmitStatus = true
                return formData
            }
        },
        onFormSubmit () {
            const formData = this.processFormSubmit()
            if (formData) {
                this.handleFormData(formData)
                this.$emit('submit', formData)
            }
        },
        handleFormData (formData) {},
        resetFormData () {
            this.$v.$reset()
            Object.assign(this.formData, setupFormData(this.formConfiguration, this.formFields))
            this.$nextTick(() => {
                // Set autofocus on first field that has it configured
                this.$children.some(child => {
                    if (child.$attrs.autofocus !== undefined && child.$attrs.autofocus !== false && child.$attrs['data-test']) {
                        document.querySelector(`[data-test="${child.$attrs['data-test']}"]`).focus()
                        return true
                    }
                    return false
                })
            })
        },
        resetFormSubmitStatus () {
            this.formSubmitStatus = false
        },
        resetFormErrorMessage () {
            this.formErrorMessage = ''
        },
        showFormErrorMessage (error) {
            this.formErrorMessage = extractErrorMessage(error)
        },
    },
    created () {
        const localize = (this.$options.formBuilderSettings.localize === undefined) ? true : this.$options.formBuilderSettings.localize
        if (localize) {
            this.$watch('$i18n.locale', () => {
                this.formConfiguration = setupFormLocale(returnValueFromFunctionCallOrArgument(this, this.$options.formBuilderSettings.schema))
            })
        }
    },
}
