import gql from 'graphql-tag'

export const COMMISSION_LIST_ENTRY_CREATE_MUTATION = gql`
    mutation commissionListEntryCreate(
        $contractId: ID!
        $premiumFrom: Date
        $premiumTo: Date
        $commissionType: CommissionType!
        $commissionAmount: Float!
    ) {
        commissionListEntryCreate(
            contract_id: $contractId
            premium_from: $premiumFrom
            premium_to: $premiumTo
            commission_type: $commissionType
            commission_amount: $commissionAmount
        ) {
            id
            status
            ready_to_post
            commissionList {
                id
            }
            contractMatchShortlist {
                id
            }
            matchedContract {
                id
            }
            contract_number
            framework_agreement_number
            positions {
                id
            }
            customer_first_name
            customer_last_name
            customer_company_name
            customer_full_name
            created_manually
            created_at
            updated_at
        }
    }
`
