export const markdownContentMixin = {
    methods: {
        handleAnchorNavigation (event) {
            if (event.target.nodeName === 'A') {
                event.preventDefault()

                // Internal
                if (event.target.hostname === window.location.hostname && !event.metaKey && !event.ctrlKey) {
                    const targetPath = event.target.href.replace(`${event.target.protocol}//${event.target.host}`, '')
                    if (targetPath !== this.$route.fullPath) this.$router.push(targetPath)
                } else { // External
                    window.open(event.target.href)
                }
            }
        },
    },
}
