import gql from 'graphql-tag'

export const APPLICATION_PRODUCT_TEMPLATE_VERSION_UPDATE_MUTATION = gql`
    mutation applicationProductTemplateVersionUpdate(
        $id: ID!
        $productTemplateVersion: Float!
    ) {
        applicationProductTemplateVersionUpdate(
            id: $id
            product_template_version: $productTemplateVersion
        ) {
            id
            currentContractInformation {
                product {
                    template {
                        version
                        form_configuration
                        template_configuration
                    }
                }
                productTemplate {
                    version
                    form_configuration
                    template_configuration
                }
            }
        }
    }
`
