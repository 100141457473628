import { Form, FormField } from '@/libs/form'
import { addCommonContractInformationFields, ProductDerivedEntityForm } from '@/forms/productDerivedEntity'
import { ApplicationStatus } from '@/enums/graphql'
import { requiredIf } from '@vuelidate/validators'
import { kebabCase } from 'lodash'
import { computed, watch } from 'vue'
import { i18n } from '@/i18n'
import { ApplicationActions, ViewMode } from '@/enums'

export class ApplicationForm extends ProductDerivedEntityForm {
    compose () {
        super.compose()

        this.addField(new FormField('externalReferenceNumber', {
            label: 'forms.field.label.application.application-number',
            inputType: 'text',
        }))

        this.addField(new FormField('status', {
            label: 'forms.field.label.application.status.status',
            inputType: 'select',
            extra_attributes: {
                list: {
                    options: [ApplicationStatus.ON_HOLD, ApplicationStatus.PENDING_AT_PRODUCT_PROVIDER].map(value => ({ value: value, label: `forms.field.label.application.status.${kebabCase(value)}` })),
                },
            },
            validators: [
                { type: 'required' },
            ],
        }))
        watch(() => this.getItem('status').value, (newValue) => {
            if (newValue !== ApplicationStatus.PENDING_AT_PRODUCT_PROVIDER) {
                this.getItem('submissionSendDate').resetValue()
            }
            if (newValue !== ApplicationStatus.ON_HOLD) {
                this.getItem('onHoldUntil').resetValue()
            }
        })

        const submissionSendDateRequiredIfCondition = () => {
            return this.getItem('status').value === ApplicationStatus.PENDING_AT_PRODUCT_PROVIDER
        }
        this.addField(new FormField('submissionSendDate', {
            label: 'forms.field.label.application.submission-date',
            inputType: 'calendar',
            validators: [
                { type: 'date' },
                {
                    type: 'requiredIfSpecificValues',
                    validator: requiredIf(submissionSendDateRequiredIfCondition),
                    requiredIfCondition: submissionSendDateRequiredIfCondition,
                    errorMessage: i18n.t('common.error-message.required', { field: i18n.tc('forms.field.label.application.submission-date', 1) }),
                },
            ],
            attributes: {
                disable: computed(() => {
                    return this.getItem('status').value !== ApplicationStatus.PENDING_AT_PRODUCT_PROVIDER
                }),
            },
        }))

        if (this.context?.currentMode === ViewMode.UPDATE) {
            this.addField(new FormField('submissionResponseDate', {
                label: 'forms.field.label.application.submission-response-date',
                inputType: 'calendar',
                validators: [
                    { type: 'date' },
                ],
                attributes: {
                    disable: computed(() => {
                        return ![ApplicationStatus.APPROVED_BY_PRODUCT_PROVIDER, ApplicationStatus.DECLINED_BY_PRODUCT_PROVIDER, ApplicationStatus.COUNTER_PROPOSAL_PENDING_AT_CUSTOMER].includes(this.getItem('status').value)
                    }),
                },
            }))
        }

        const onHoldUntilRequiredIfCondition = () => {
            return this.getItem('status').value === ApplicationStatus.ON_HOLD
        }
        this.addField(new FormField('onHoldUntil', {
            label: 'forms.field.label.application.on-hold-until',
            inputType: 'calendar',
            validators: [
                { type: 'date' },
                {
                    type: 'requiredIfSpecificValues',
                    validator: requiredIf(onHoldUntilRequiredIfCondition),
                    requiredIfCondition: onHoldUntilRequiredIfCondition,
                    errorMessage: i18n.t('common.error-message.required', { field: i18n.tc('forms.field.label.application.on-hold-until', 1) }),
                },
            ],
            attributes: {
                disable: computed(() => {
                    return this.getItem('status').value !== ApplicationStatus.ON_HOLD
                }),
            },
        }))
    }
}

export class ApplicationSubmissionSendDateForm extends Form {
    compose () {
        this.addField(new FormField('submissionSendDate', {
            label: this.context?.application?.status === ApplicationStatus.COUNTER_PROPOSAL_PENDING_AT_CUSTOMER ? 'common.application.submission-send-date--re-submitted' : 'forms.field.label.application.submission-date',
            inputType: 'calendar',
            validators: [
                { type: 'required' },
                { type: 'date' },
            ],
        }))
    }
}

export class ApplicationSubmissionResponseDateForm extends Form {
    compose () {
        this.addField(new FormField('submissionResponseDate', {
            label: this.context?.action === ApplicationActions.SUBMIT_COUNTER_PROPOSAL_TO_CUSTOMER ? 'common.application.status-info.counter-proposal-pending-at-customer--submission-response-date-label' : 'forms.field.label.application.submission-response-date',
            inputType: 'calendar',
            validators: [
                { type: 'required' },
                { type: 'date' },
            ],
        }))
    }
}

export class ApplicationApproveForm extends Form {
    compose () {
        this.addField(new FormField('createNewContract', {
            label: i18n.tc('views.application.approve.create-new-contract', 1),
            inputType: 'checkbox',
        }))

        const contractNumberRequiredIfCondition = () => {
            return this.context.application.is_change_application === false || this.getItem('createNewContract').value === true
        }
        this.addField(new FormField('contractNumber', {
            label: 'common.contract.contract-number',
            inputType: 'text',
            validators: [
                {
                    type: 'requiredIfSpecificValues',
                    validator: requiredIf(contractNumberRequiredIfCondition),
                    requiredIfCondition: contractNumberRequiredIfCondition,
                    errorMessage: i18n.t('common.error-message.required', { field: i18n.tc('common.contract.contract-number', 1) }),
                },
            ],
        }))

        this.addField(new FormField('submissionResponseDate', {
            label: 'forms.field.label.application.submission-response-date',
            inputType: 'calendar',
            validators: [
                { type: 'required' },
                { type: 'date' },
            ],
        }))

        addCommonContractInformationFields(this)
    }
}
