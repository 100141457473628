<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.commission.rate-adjustment.rate-adjustment', 2)" :to="{ name: 'commission-rate-adjustment-rule-set-list' }" />
                <q-breadcrumbs-el :label="$tc('common.rule.add-rule-set', 1)" :to="{ name: 'commission-rate-adjustment-rule-set-create' }" />
            </template>

            <h1>{{ $tc('common.rule.add-rule-set', 1) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <commission-rate-adjustment-rule-set-form
                    v-if="!allProductProvidersConfigured"
                    ref="commissionRateAdjustmentRuleSetForm"
                    @submit="handleSubmit"
                    @all-product-providers-configured="allProductProvidersConfigured = true"
                />

                <info-box v-else type="warning" :inline-actions="$q.screen.width > 1300">
                    {{ $t('views.commission.rate-adjustment.rule-sets-for-all-providers-created-info') }}

                    <template v-slot:action>
                        <base-button
                            :to="{ name: 'commission-rate-adjustment-rule-set-list' }"
                            :label="$tc('common.rule.go-to-rule-set-list', 1)"
                            outline
                        />
                    </template>
                </info-box>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import { CommissionRateAdjustmentRuleSet } from '@/models/commissionRateAdjustmentRuleSet'
import { parseFormDataToInput } from '@/helpers/commissionRateAdjustmentRuleSet'
import CommissionRateAdjustmentRuleSetForm from '@/components/commission/commissionRateAdjustmentRuleSet/CommissionRateAdjustmentRuleSetForm'

export default {
    name: 'CommissionRateAdjustmentRuleSetCreate',
    meta () {
        return {
            title: this.$tc('common.rule.add-rule-set', 1),
        }
    },
    components: {
        CommissionRateAdjustmentRuleSetForm,
    },
    props: {},
    data () {
        return {
            productProviderId: null,
            allProductProvidersConfigured: false,
        }
    },
    created () {
        EventBus.$on('commissionRateAdjustmentRuleSet:emit-rule-forms-data', this.handleCreate)
    },
    beforeDestroy () {
        EventBus.$off('commissionRateAdjustmentRuleSet:emit-rule-forms-data', this.handleCreate)
    },
    methods: {
        handleSubmit (formData) {
            this.productProviderId = formData.productProviderId
            EventBus.$emit('commissionRateAdjustmentRuleSet:submit-rule-forms')
        },
        handleCreate (formData) {
            const input = parseFormDataToInput(formData)

            CommissionRateAdjustmentRuleSet
                .create(this.productProviderId, input)
                .then(() => {
                    this.$router.replace({ name: 'commission-rate-adjustment-rule-set-list' })
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.commission.commission-rate-adjustment-rule-set-created-success', 1),
                    })
                }).catch(error => {
                    this.$refs.commissionRateAdjustmentRuleSetForm.showFormErrorMessage(error)
                    this.$refs.commissionRateAdjustmentRuleSetForm.resetFormSubmitStatus()
                })
        },
    },
}
</script>
