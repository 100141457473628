import gql from 'graphql-tag'

export const PRODUCT_CREATE_MUTATION = gql`
    mutation productCreate(
        $product: ProductInputCreate!
    ) {
        productCreate(
            product: $product
        ) {
            id
        }
    }
`
