import gql from 'graphql-tag'

export const CORRESPONDENCE_SALUTATION_DELETE_MUTATION = gql`
    mutation correspondenceSalutationDelete(
        $id: ID!
    ) {
        correspondenceSalutationDelete(
            id: $id
        ) {
            status
        }
    }
`
