import gql from 'graphql-tag'

export const USER_RESEND_REGISTRATION_INVITE_MUTATION = gql`
    mutation userResendRegistrationInvite($id: ID!) {
        userResendRegistrationInvite(
            id: $id
        ) {
            status
        }
    }
`
