import { Model } from '@/models'
import { formatCurrency } from '@/helpers/number'
import { formatDate } from '@/helpers/date'

export class CommissionListEntryPosition extends Model {
    /**
     * Create a CommissionListEntryPosition model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'commissionListEntry',
            'type',
            'premium_from',
            'premium_to',
            'sector_code',
            'premium',
            'commission_type',
            'commission_rate',
            'commission_amount',
        ], data)
    }

    get formattedCommissionAmount () {
        return formatCurrency(this.commission_amount)
    }

    get formattedPremiumTo() {
        return formatDate(this.premium_to) || ' — '
    }

    get formattedPremiumFrom() {
        return formatDate(this.premium_from) || ' — '
    }
}
