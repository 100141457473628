import { Model } from '@/models'
import { QueueItemStatus } from '@/enums/graphql'

export class QueueItem extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'status',
            'status_message',
        ], data)
    }

    get isQueuedOrProcessing () {
        return [QueueItemStatus.QUEUED, QueueItemStatus.PROCESSING].includes(this.status)
    }
}
