import gql from 'graphql-tag'
import { visit } from 'graphql'

export const CONTACT_CREATE_MUTATION = gql`
    mutation ContactCreate(
        $type: ContactType!
        $customerStatus: CustomerStatus
        $customerSince: Date
        $website: String
        $consultantIds: [ID!]!
        $correspondenceLanguageId: ID!
        $personInput: PersonInput
        $companyInput: CompanyInput
        $addressInputs: [AddressInput!]!
        $emailAddressInputs: [EmailAddressInput!]
        $phoneNumberInputs: [PhoneNumberInput!]
    ) {
        contactCreate(
            input: {
                type: $type
                status: ACTIVE
                customer_status: $customerStatus
                customer_since: $customerSince
                website: $website
                consultant_ids: $consultantIds
                correspondence_language_id: $correspondenceLanguageId
                person: $personInput
                company: $companyInput
                addresses: $addressInputs,
                emailAddresses: $emailAddressInputs
                phoneNumbers: $phoneNumberInputs
            }
        ) {
            id
            contactNumber {
                id
                value_structure
                type
            }
        }
    }
`

export function buildContactCreateMutation (
    {
        includeEmailAddressInputs = false,
        includePhoneNumberInputs = false,
    } = {
        includeEmailAddressInputs: false,
        includePhoneNumberInputs: false,
    }
) {
    return visit(CONTACT_CREATE_MUTATION, {
        VariableDefinition (node) {
            let returnValue = node
            switch (node.variable.name.value) {
                case 'emailAddressInputs':
                    if (!includeEmailAddressInputs) returnValue = null
                    break
                case 'phoneNumberInputs':
                    if (!includePhoneNumberInputs) returnValue = null
                    break
            }
            return returnValue
        },
        ObjectField (node) {
            let returnValue = node
            switch (node.name.value) {
                case 'emailAddresses':
                    if (!includeEmailAddressInputs) returnValue = null
                    break
                case 'phoneNumbers':
                    if (!includePhoneNumberInputs) returnValue = null
                    break
            }
            return returnValue
        },
    })
}
