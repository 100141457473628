import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { languageMiddleware, authMiddleware, initializeModelsAfterware, processJwtPayloadAfterware, onLogout } from '@/apollo'
import router from '@/routes'

const uploadLink = createUploadLink({
    uri: process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000/graphql',
    credentials: 'include',
    headers: {
        'TenantDomain': process.env.VUE_APP_TENANT_DOMAIN || window.location.hostname,
    },
})

async function handleGraphQLErrors (graphQLErrors) {
    const isInvalidAuthState = graphQLErrors.some(({ message, extensions }) => {
        return (['not_authenticated', 'token_expired'].includes(extensions.error))
    })
    if (isInvalidAuthState) {
        await onLogout(apolloClient)
        window.location.assign(router.resolve({ name: 'auth-logout', params: { reason: 'authentication' } }).href)
    }
}

async function handleNetworkError (networkError) {
    if ([401].includes(networkError?.statusCode)) {
        await onLogout(apolloClient)
        window.location.assign(router.resolve({ name: 'auth-logout', params: { reason: 'unauthorized' } }).href)
    } else {
        console.log(`[Network error]: ${networkError}`)
    }
}

const errorHandler = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) handleGraphQLErrors(graphQLErrors)
    if (networkError) handleNetworkError(networkError)
})

export const apolloClient = new ApolloClient({
    link: ApolloLink.from([
        languageMiddleware,
        authMiddleware,
        errorHandler,
        initializeModelsAfterware,
        processJwtPayloadAfterware,
        uploadLink,
    ]),
    cache: new InMemoryCache(),
    defaultOptions: {
        query: {
            fetchPolicy: 'no-cache', // Don't change this without consideration! Responses get modified sometimes and they could have a reference into the cache!
        },
    },
    queryDeduplication: false,
})
