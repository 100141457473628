<template>
    <div :class="['card-wrapper', columnClasses]">
        <card v-bind="$attrs">
            <slot v-for="(_, name) in $slots" :slot="name" :name="name" /><!-- Named slots -->
        </card>
    </div>
</template>

<script>
export default {
    name: 'GridCard',
    inheritAttrs: false,
    props: {
        columns: {
            type: Object,
            default: () => {
                return {
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 4,
                    xl: 3,
                }
            },
        },
    },
    computed: {
        columnClasses () {
            const columnClasses = []
            const sizes = Object.keys(this.columns)

            // Fallback if 'xs' should not be provided
            if (!this.columns['xs']) {
                columnClasses.push('col-xs-12')
            }

            sizes.forEach((size, index) => {
                columnClasses.push(`col-${size}-${this.columns[size]}`)
            })

            return columnClasses.join(' ')
        },
    },
}
</script>
