<template>
    <div
        class="loading-indicator-wrapper row justify-center items-center"
        :class="{ 'auto-height': autoHeight }"
        :style="autoHeight ? `margin-top: ${marginTop};` : null"
    >
        <div class="col-auto">
            <component :is="type ? `q-spinner-${type}` : 'q-spinner'" :color="color" :size="size" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoadingIndicator',
    props: {
        type: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: 'primary',
        },
        size: {
            type: String,
            default: '3rem',
        },
        autoHeight: {
            type: Boolean,
            default: false,
        },
        marginTop: {
            type: String,
            default: '0',
        },
    },
}
</script>

<style lang="scss" scoped>
.loading-indicator-wrapper {
    height: calc(100vh - #{$sizeLayoutHeaderHeight});
    overflow: hidden;

    &.auto-height {
        height: auto;
    }
}
</style>
