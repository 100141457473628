import gql from 'graphql-tag'

export const CORRESPONDENCE_LETTER_DELETE_MUTATION = gql`
    mutation correspondenceLetterDelete(
        $id: ID!
    ) {
        correspondenceLetterDelete(
            id: $id
        ) {
            status
        }
    }
`
