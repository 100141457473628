import { Model } from '@/models'
import { i18n } from '@/i18n'
import { AccountBalanceReportService } from '@/services'
import { formatDate } from '@/helpers/date'
import { AccountBalanceReportActions } from '@/enums'
import { QueueItemStatus } from '@/enums/graphql'
import { textList } from '@/helpers'

export class AccountBalanceReport extends Model {
    /**
     * Create a AccountBalanceReport model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'owner',
            'accounts',
            'account_number_from',
            'account_number_to',
            'date_from',
            'date_until',
            'file_types',
            'currentQueueItem',
            'queueItems',
            'fileStorageItem',
        ], data)
    }

    static get service () { return AccountBalanceReportService }
    static action = AccountBalanceReportActions

    /**
     * Add custom actions.
     * @returns {Array}
     */
    get actions () {
        const actions = super.actions

        // Cancel creation
        if (this.currentQueueItem.status === QueueItemStatus.QUEUED) {
            actions.push({
                key: AccountBalanceReportActions.CANCEL_CREATION,
                item: this,
            })
        }

        // Delete
        if ([QueueItemStatus.FAILED, QueueItemStatus.CANCELLED, QueueItemStatus.DONE].includes(this.currentQueueItem.status)) {
            actions.push({
                key: AccountBalanceReportActions.DELETE,
                item: this,
            })
        }

        return actions
    }

    /**
     * Create a new accountBalanceReport.
     * @param {Object} accountBalanceReport - Typeof AccountBalanceReportInput.
     * @returns {Promise<*>}
     */
    static create (accountBalanceReport) {
        return AccountBalanceReportService.create({ accountBalanceReport: accountBalanceReport })
    }

    /**
     * Stops already queued `AccountBalanceReports` from being created.
     * @param {Array} ids
     * @returns {Promise<*>}
     */
    static cancelCreation (ids) {
        const variables = {
            account_balance_report_ids: ids,
        }
        return AccountBalanceReportService.cancelCreation(variables)
    }

    /**
     * Stops this already queued `AccountBalanceReport` from being created.
     * @returns {Promise<*>}
     */
    cancelCreation () {
        return AccountBalanceReport.cancelCreation([this.id])
    }

    /**
     * Deletes `AccountBalanceReports`.
     * @param {Array} ids
     * @returns {Promise<*>}
     */
    static delete (ids) {
        const variables = {
            account_balance_report_ids: ids,
        }
        return AccountBalanceReportService.delete(variables)
    }

    get formattedDateFrom () {
        return formatDate(this.date_from)
    }

    get formattedDateUntil () {
        return formatDate(this.date_until)
    }

    get formattedPeriod () {
        return `${this.formattedDateFrom}–${this.formattedDateUntil}`
    }

    // TODO improvement: Extract to helper function.
    get formattedAccountList () {
        const threshold = 3
        let accountList = {}

        // Individual accounts
        if (this.accounts?.length) {
            // TODO @TFU: Check if it would make more sense to already get the accounts sorted correctly (by `account.number` ASC) from the API.
            // Get account numbers (Integers) from array and sort them
            accountList.accounts = this.accounts.map(account => account.number).sort(function(a, b) { return a - b })

            if (this.accounts.length > threshold) {
                accountList.teaser = {
                    full: `${accountList.accounts.slice(0, threshold).join(', ')} … ${i18n.t('common.term.more--and-n-other--entity', { amount: this.accounts.length - threshold, entity: i18n.tc('common.accounting.account.account', 2) })}`,
                    short: `${accountList.accounts.slice(0, threshold).join(', ')} … ${i18n.t('common.term.more--and-n-others', { amount: this.accounts.length - threshold })}`,
                    accounts: accountList.accounts.slice(0, threshold).join(', '),
                    numberOfAdditionalAccounts: this.accounts.length - threshold,
                }
            }

            if (this.accounts.length === 1) {
                accountList.accounts = `${this.accounts[0].number} – ${this.accounts[0].name}`
            } else {
                accountList.accounts = textList(accountList.accounts)
            }
        } else {
            // Account range
            accountList.accounts = i18n.tc('common.term.from-x-to-y--short', 1, { x: this.account_number_from, y: this.account_number_to})
        }

        return accountList
    }
}
