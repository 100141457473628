<template>
    <div id="upload-manager">
        <h1 class="text-h2">{{ $t('common.file.upload-manager.title') }}</h1>
        <div>
            <h2 class="text-h4">{{ $tc('common.term.queue', 1) }}</h2>
            <q-list v-if="queuedFiles.length" class="file-list" data-test="upload-manager-queue">
                <q-item
                    v-for="fileUploadWrapper in queuedFiles"
                    :key="fileUploadWrapper.id"
                    class="file-item"
                    data-test="file-item"
                    :class="`file-status-${fileUploadWrapper.status.toLowerCase()}`"
                >
                    <q-item-section thumbnail top class="file-item-icon">
                        <consulting-file-upload-status-indicator :status="fileUploadWrapper.status" :file-name="fileUploadWrapper.file.name" :file-type="fileUploadWrapper.file.type" />
                    </q-item-section>

                    <q-item-section class="file-item-info" data-test="file-item-info">
                        <q-item-label>
                            <span class="file-name" data-test="file-name">{{ fileUploadWrapper.file.name }}</span>
                            <base-button
                                v-if="fileUploadWrapper.status === FileUploadWrapperStatus.UPLOADING"
                                round
                                color="none"
                                text-color="negative"
                                icon="mib-remove-circle"
                                size="xs"
                                class="clear-history-item-trigger"
                                @click="handleUploadAbort(fileUploadWrapper)"
                            >
                                <q-tooltip
                                    :delay="1000"
                                    anchor="center right"
                                    self="center left"
                                    :offset="[10, 0]"
                                    transition-show="scale"
                                    transition-hide="scale"
                                >
                                    {{ $t('common.file.upload-manager.cancel-upload') }}
                                </q-tooltip>
                            </base-button>
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>
            <div v-else class="additional-info" data-test="no-files-in-queue">{{ $tc('common.file.upload-manager.no-files-in-queue', 1) }}</div>

            <h2 class="text-h4">
                {{ $t('common.term.history') }}
                <base-button
                    v-if="history.length"
                    round
                    color="none"
                    text-color="primary"
                    icon="mib-bin"
                    size="xs"
                    class="clear-history-trigger"
                    @click="clearHistory"
                >
                    <q-tooltip
                        :delay="1000"
                        anchor="center right"
                        self="center left"
                        :offset="[10, 0]"
                        transition-show="scale"
                        transition-hide="scale"
                    >
                        {{ $t('common.file.upload-manager.delete-history') }}
                    </q-tooltip>
                </base-button>
            </h2>
            <template v-if="history.length">
                <q-list class="file-list" data-test="upload-manager-history">
                    <q-item
                        v-for="fileUploadWrapper in history"
                        :key="fileUploadWrapper.id"
                        class="file-item"
                        data-test="file-item"
                        :class="`file-status-${fileUploadWrapper.status.toLowerCase()}`"
                    >
                        <q-item-section thumbnail top class="file-item-icon">
                            <consulting-file-upload-status-indicator :status="fileUploadWrapper.status" :file-name="fileUploadWrapper.file.name" :file-type="fileUploadWrapper.file.type" />
                        </q-item-section>

                        <q-item-section top class="file-item-info" data-test="file-item-info">
                            <q-item-label>
                                <span class="file-name" data-test="file-name">{{ fileUploadWrapper.file.name }}</span>
                                <div class="file-actions">
                                    <base-button
                                        round
                                        color="none"
                                        text-color="primary"
                                        icon="mib-delete-2-alternate"
                                        size="xs"
                                        class="clear-history-item-trigger"
                                        @click="removeFileFromHistory(fileUploadWrapper)"
                                    >
                                        <q-tooltip
                                            :delay="1000"
                                            anchor="center right"
                                            self="center left"
                                            :offset="[10, 0]"
                                            transition-show="scale"
                                            transition-hide="scale"
                                        >
                                            {{ $t('common.file.upload-manager.remove-item-from-history') }}
                                        </q-tooltip>
                                    </base-button>
                                </div>
                            </q-item-label>

                            <q-item-label caption data-test="file-item-status-description">
                                <!-- TODO: Write unit tests and remove comment afterwards -->
                                <!-- TODO @MTR: Extend with tasks. -->
                                <!--
                                CASES to be covered (each for "aborted", "done", "failed"):

                                √√ CONTACT
                                    √√ meta.contactName     >>> "uploaded to [meta.contactName](link)"
                                    √√ no meta              >>> "uploaded to the contact with the ID [fileUploadWrapper.targetObjectId]"
                                APPLICATION
                                    same as contract
                                √√ CONTRACT
                                    √√ meta.contactId        && meta.contractNumber   && meta.contactName   >>> linked - "uploaded to contract [meta.contractNumber](link) of [meta.contactName]"
                                    √√ meta.contactId        && meta.contractNumber                         >>> linked - "uploaded to contract [meta.contractNumber](link)"
                                    √√ meta.contactId                                 && meta.contactName   >>> linked - "uploaded to a contract of [meta.contactName](link)"
                                    √√ meta.contactId                                                       >>> linked - "uploaded to the contract with the ID [meta.contactId](link)"

                                    √√                          meta.contractNumber   && meta.contactName   >>> without link - "uploaded to contract [meta.contractNumber] of [meta.contactName]"
                                    √√                          meta.contractNumber                         >>> without link - "uploaded to contract [meta.contractNumber]"
                                    √√                                                   meta.contactName   >>> without link - "uploaded to a contract of [meta.contactName]"

                                    √√ ((no meta))                                                          >>> without link - "uploaded to the contract with the ID [fileUploadWrapper.targetObjectId]"
                                √√ CORRESPONDENCE-EMAIL-ATTACHMENT
                                    √√ targetObjectId       && meta.name              >>> linked (default) - "uploaded to email [emailName](link)"
                                -->

                                <!-- CONTACT -->
                                <i18n v-if="fileUploadWrapper.targetObjectType === ConsultingFileTargetObjectType.CONTACT" :path="getTargetObjectTranslationKey(fileUploadWrapper)">
                                    <template v-slot:[getMetaInfo(fileUploadWrapper).slotName]>
                                        <router-link :to="{ name: 'contact-detail', params: { id: fileUploadWrapper.targetObjectId, tab: 'documents' } }">{{ getMetaInfo(fileUploadWrapper).linkText }}</router-link>
                                    </template>
                                </i18n>

                                <!-- APPLICATION/CONTRACT -->
                                <template v-if="fileUploadWrapper.targetObjectType === ConsultingFileTargetObjectType.APPLICATION || fileUploadWrapper.targetObjectType === ConsultingFileTargetObjectType.CONTRACT">
                                    <!-- with meta.contactId (= can be linked) -->
                                    <template v-if="fileUploadWrapper.meta && fileUploadWrapper.meta.contactId">
                                        <i18n :path="getTargetObjectTranslationKey(fileUploadWrapper)">
                                            <template v-slot:[getMetaInfo(fileUploadWrapper).slotName]>
                                                <router-link :to="{ name: `${fileUploadWrapper.targetObjectType.toLowerCase()}-detail`, params: { contactId: fileUploadWrapper.meta.contactId, id: fileUploadWrapper.targetObjectId, tab: 'documents' } }">{{ getMetaInfo(fileUploadWrapper).linkText }}</router-link>
                                            </template>
                                            <template v-if="(fileUploadWrapper.meta.applicationNumber || fileUploadWrapper.meta.contractNumber) && fileUploadWrapper.meta.contactName" v-slot:contactName>{{ fileUploadWrapper.meta.contactName }}</template>
                                        </i18n>
                                    </template>

                                    <!-- without meta.contactId (= can NOT be linked but meaningful text can be provided) -->
                                    <template v-else-if="fileUploadWrapper.meta && !fileUploadWrapper.meta.contactId && ((fileUploadWrapper.meta.applicationNumber || fileUploadWrapper.meta.contractNumber) || fileUploadWrapper.meta.contactName)">
                                        {{ $tc(`${getTargetObjectTranslationKey(fileUploadWrapper)}`, 1, { applicationNumber: fileUploadWrapper.meta.applicationNumber, contractNumber: fileUploadWrapper.meta.contractNumber, contactName: fileUploadWrapper.meta.contactName }) }}
                                    </template>

                                    <!-- without meta (= can NOT be linked and only the targetObjectId can be provided) -->
                                    <template v-else>
                                        {{ $tc(`${getTargetObjectTranslationKey(fileUploadWrapper)}`, 1, { applicationId: fileUploadWrapper.targetObjectId, contractId: fileUploadWrapper.targetObjectId }) }}
                                    </template>
                                </template>

                                <!-- CORRESPONDENCE-EMAIL-ATTACHMENT -->
                                <!-- with targetObjectId and meta.name (= can be linked – this is the default case; emailAttachments will always have a targetObjectId and a name – no need to maintain a fallback.) -->
                                <template v-if="fileUploadWrapper.targetObjectType === FileUploadTargetObjectType.CORRESPONDENCE_EMAIL_ATTACHMENT">
                                    <i18n :path="getTargetObjectTranslationKey(fileUploadWrapper)">
                                        <template v-slot:[getMetaInfo(fileUploadWrapper).slotName]>
                                            <router-link :to="{ name: 'correspondence-email-update', params: { id: fileUploadWrapper.targetObjectId } }">{{ getMetaInfo(fileUploadWrapper).linkText }}</router-link>
                                        </template>
                                    </i18n>
                                </template>
                            </q-item-label>

                            <div v-if="fileUploadWrapper.errorMessage" class="failed-upload-info">
                                <q-expansion-item
                                    :label="$t('common.term.show-details')"
                                    expand-icon="mib-arrow-right-1"
                                    expanded-icon="mib-arrow-down-1"
                                    dense
                                    dense-toggle
                                >
                                    <!-- eslint-disable-next-line vue/no-v-html -->
                                    <info-box v-if="fileUploadWrapper.errorMessage" type="negative" v-html="fileUploadWrapper.errorMessage" />
                                </q-expansion-item>
                            </div>
                        </q-item-section>
                    </q-item>
                </q-list>
            </template>
            <div v-else class="additional-info">{{ $tc('common.file.upload-manager.no-files-in-history', 1) }}</div>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import { ConsultingFileTargetObjectType } from '@/enums/graphql'
import { FileUploadWrapperStatus, FileUploadTargetObjectType } from '@/enums'
import { ConsultingFileService, CorrespondenceEmailService, TaskService, PostingService } from '@/services'
import { getFileTypeIcon } from '@/helpers/file'
import { extractErrorMessage } from '@/helpers/form'

import ConsultingFileUploadStatusIndicator from '@/components/consultingFile/ConsultingFileUploadStatusIndicator'

export default {
    name: 'FileUploadManager',
    components: {
        ConsultingFileUploadStatusIndicator,
    },
    data () {
        const data = {
            ConsultingFileTargetObjectType,
            FileUploadWrapperStatus,
            FileUploadTargetObjectType,
            queuedFiles: [],
            history: [],
        }
        return data
    },
    computed: {
        failedHistoryItems () {
            return this.history.filter(historyItem => historyItem.status === FileUploadWrapperStatus.FAILED)
        },
    },
    watch: {
        'queuedFiles' () {
            this.$emit('updateNumberOfQueuedFiles', this.queuedFiles.length)
            if (this.queuedFiles.length) {
                this.queuedFiles.forEach(fileUploadWrapper => {
                    if (fileUploadWrapper.status === FileUploadWrapperStatus.QUEUED) {
                        this.uploadFile(fileUploadWrapper)
                    }
                })
            } else {
                const targetObjects = []
                this.history.forEach(fileUploadWrapper => {
                    if (!targetObjects.find(item => item.type === fileUploadWrapper.targetObjectType && item.id === fileUploadWrapper.targetObjectId)) {
                        targetObjects.push({
                            type: fileUploadWrapper.targetObjectType,
                            id: fileUploadWrapper.targetObjectId,
                        })
                    }
                })
                EventBus.$emit('fileUploadManager:allUploadsDone', targetObjects)
            }
        },
        'failedHistoryItems' () {
            this.$emit('updateNumberOfFailedHistoryItems', this.failedHistoryItems.length)
        },
    },
    created () {
        EventBus.$on('fileUploadManager:addToUploadQueue', this.addFilesToQueue)
        EventBus.$on('fileUploadManager:api', this.apiRequestHandler)
    },
    beforeDestroy () {
        EventBus.$off('fileUploadManager:addToUploadQueue', this.addFilesToQueue)
        EventBus.$off('fileUploadManager:api', this.apiRequestHandler)
    },
    methods: {
        apiRequestHandler (req) {
            switch (req.action) {
                case 'getQueueItems':
                    req.callbackFn(this.queuedFiles.filter(fileUploadWrapper => (fileUploadWrapper.targetObjectId === req.params.targetObjectId && fileUploadWrapper.targetObjectType === req.params.targetObjectType)))
                    break
            }
        },
        addFilesToQueue (fileUploadWrappers) {
            const queuedFiles = []
            fileUploadWrappers.forEach(fileUploadWrapper => {
                // Only add to queue if the exact same file is not already there.
                if (this.queuedFiles.every(item => item.id !== fileUploadWrapper.id)) {
                    fileUploadWrapper.status = FileUploadWrapperStatus.QUEUED
                    fileUploadWrapper.abortController = new AbortController()
                    queuedFiles.push(fileUploadWrapper)
                }
            })
            this.queuedFiles.push(...queuedFiles)
        },
        uploadFile (fileUploadWrapper) {
            switch (fileUploadWrapper.targetObjectType) {
                case ConsultingFileTargetObjectType.CONTACT:
                    fileUploadWrapper.status = FileUploadWrapperStatus.UPLOADING
                    ConsultingFileService.createContactFile(fileUploadWrapper.targetObjectId, fileUploadWrapper.fileTypeId, fileUploadWrapper.consultantId, fileUploadWrapper.file, fileUploadWrapper.abortController)
                        .then(() => {
                            this.handleUploadSuccess(fileUploadWrapper)
                        })
                        .catch(error => {
                            fileUploadWrapper.errorMessage = extractErrorMessage(error)
                            this.handleUploadFailure(fileUploadWrapper)
                            this.$q.notify({
                                type: 'negative',
                                message: this.$tc('common.term.error', 1),
                                caption: this.$t('common.notifications.file-upload-error'),
                                timeout: 10000,
                            })
                        })
                    break
                case ConsultingFileTargetObjectType.APPLICATION:
                    fileUploadWrapper.status = FileUploadWrapperStatus.UPLOADING
                    ConsultingFileService.createApplicationFile(fileUploadWrapper.targetObjectId, fileUploadWrapper.fileTypeId, fileUploadWrapper.file, fileUploadWrapper.abortController)
                        .then(() => {
                            this.handleUploadSuccess(fileUploadWrapper)
                        })
                        .catch(error => {
                            fileUploadWrapper.errorMessage = extractErrorMessage(error)
                            this.handleUploadFailure(fileUploadWrapper)
                            this.$q.notify({
                                type: 'negative',
                                message: this.$tc('common.term.error', 1),
                                caption: this.$t('common.notifications.file-upload-error'),
                                timeout: 10000,
                            })
                        })
                    break
                case ConsultingFileTargetObjectType.CONTRACT:
                    fileUploadWrapper.status = FileUploadWrapperStatus.UPLOADING
                    ConsultingFileService.createContractFile(fileUploadWrapper.targetObjectId, fileUploadWrapper.fileTypeId, fileUploadWrapper.file, fileUploadWrapper.abortController)
                        .then(() => {
                            this.handleUploadSuccess(fileUploadWrapper)
                        })
                        .catch(error => {
                            fileUploadWrapper.errorMessage = extractErrorMessage(error)
                            this.handleUploadFailure(fileUploadWrapper)
                            this.$q.notify({
                                type: 'negative',
                                message: this.$tc('common.term.error', 1),
                                caption: this.$t('common.notifications.file-upload-error'),
                                timeout: 10000,
                            })
                        })
                    break
                case FileUploadTargetObjectType.CORRESPONDENCE_EMAIL_ATTACHMENT:
                    fileUploadWrapper.status = FileUploadWrapperStatus.UPLOADING
                    CorrespondenceEmailService.addAttachment(fileUploadWrapper.targetObjectId, fileUploadWrapper.file, fileUploadWrapper.abortController)
                        .then(() => {
                            this.handleUploadSuccess(fileUploadWrapper)
                        })
                        .catch(error => {
                            fileUploadWrapper.errorMessage = extractErrorMessage(error)
                            this.handleUploadFailure(fileUploadWrapper)
                            this.$q.notify({
                                type: 'negative',
                                message: this.$tc('common.term.error', 1),
                                caption: this.$t('common.notifications.file-upload-error'),
                                timeout: 10000,
                            })
                        })
                    break
                case FileUploadTargetObjectType.TASK:
                    fileUploadWrapper.status = FileUploadWrapperStatus.UPLOADING
                    TaskService.addAttachment(fileUploadWrapper.targetObjectId, fileUploadWrapper.file, fileUploadWrapper.abortController)
                        .then(() => {
                            this.handleUploadSuccess(fileUploadWrapper)
                        })
                        .catch(error => {
                            fileUploadWrapper.errorMessage = extractErrorMessage(error)
                            this.handleUploadFailure(fileUploadWrapper)
                            this.$q.notify({
                                type: 'negative',
                                message: this.$tc('common.term.error', 1),
                                caption: this.$t('common.notifications.file-upload-error'),
                                timeout: 10000,
                            })
                        })
                    break
                case FileUploadTargetObjectType.POSTING:
                    fileUploadWrapper.status = FileUploadWrapperStatus.UPLOADING
                    PostingService.addAttachment(fileUploadWrapper.targetObjectId, fileUploadWrapper.file, fileUploadWrapper.abortController)
                        .then(() => {
                            this.handleUploadSuccess(fileUploadWrapper)
                        })
                        .catch(error => {
                            fileUploadWrapper.errorMessage = extractErrorMessage(error)
                            this.handleUploadFailure(fileUploadWrapper)
                            this.$q.notify({
                                type: 'negative',
                                message: this.$tc('common.term.error', 1),
                                caption: this.$t('common.notifications.file-upload-error'),
                                timeout: 10000,
                            })
                        })
                    break
            }
        },
        handleUploadSuccess (fileUploadWrapper) {
            this.addFileToHistory(fileUploadWrapper, FileUploadWrapperStatus.DONE)
        },
        handleUploadFailure (fileUploadWrapper) {
            this.addFileToHistory(fileUploadWrapper, FileUploadWrapperStatus.FAILED)
        },
        handleUploadAbort (fileUploadWrapper) {
            fileUploadWrapper.abortController.abort()
            this.addFileToHistory(fileUploadWrapper, FileUploadWrapperStatus.ABORTED)
        },
        addFileToHistory (fileUploadWrapper, status) {
            const index = this.queuedFiles.findIndex(item => item.id === fileUploadWrapper.id)
            this.queuedFiles.splice(index, 1)

            fileUploadWrapper.id += `:${Date.now()}`
            fileUploadWrapper.status = status
            this.history.unshift(fileUploadWrapper)
        },
        removeFileFromHistory (fileUploadWrapper) {
            const index = this.history.findIndex(item => item.id === fileUploadWrapper.id)
            this.history.splice(index, 1)
        },
        clearHistory () {
            this.history = []
        },
        getFileTypeIcon (...args) {
            return getFileTypeIcon(...args)
        },
        getTargetObjectTranslationKey (fileUploadWrapper) {
            const status = (fileUploadWrapper.status) ? fileUploadWrapper.status.toLowerCase() : 'done'
            const targetObjectType = fileUploadWrapper.targetObjectType.toLowerCase()
            let translationKey = 'without-meta'

            // CORRESPONDENCE-EMAIL-ATTACHMENT
            if (fileUploadWrapper.targetObjectType === FileUploadTargetObjectType.CORRESPONDENCE_EMAIL_ATTACHMENT && fileUploadWrapper.targetObjectId && fileUploadWrapper.meta.name) {
                translationKey = 'meta-target-object-id-and-email-name'
            }

            // CONTACT/APPLICATION/CONTRACT: Check if useful metadata is provided
            if (fileUploadWrapper.meta && (fileUploadWrapper.meta.applicationNumber || fileUploadWrapper.meta.contractNumber || fileUploadWrapper.meta.contactName)) {
                const entityNumber =  fileUploadWrapper.meta.applicationNumber || fileUploadWrapper.meta.contractNumber || ''

                if (entityNumber && fileUploadWrapper.meta.contactName) translationKey = `meta-${targetObjectType}-number-and-contact-name`
                if (entityNumber && !fileUploadWrapper.meta.contactName) translationKey = `meta-${targetObjectType}-number`
                if (!entityNumber && fileUploadWrapper.meta.contactName) translationKey = `meta-contact-name`
            }
            return `common.file.status.${status}--description-short--${targetObjectType}--${translationKey}`
        },
        getMetaInfo (fileUploadWrapper) {
            const metaInfo = {
                slotName: `${fileUploadWrapper.targetObjectType.toLowerCase()}Id`, // 'contactId' || 'contractId'
                linkText: fileUploadWrapper.targetObjectId,
            }

            // CORRESPONDENCE-EMAIL-ATTACHMENT
            if (fileUploadWrapper.targetObjectType === FileUploadTargetObjectType.CORRESPONDENCE_EMAIL_ATTACHMENT) {
                Object.assign(metaInfo, {
                    slotName: 'emailName',
                    linkText: fileUploadWrapper.meta.name,
                })
            }

            // CONTACT/CONTRACT: Assign values if there is useful metadata
            if (fileUploadWrapper.meta) {
                switch (fileUploadWrapper.targetObjectType) {
                    // targetObjectType is an APPLICATION
                    case ConsultingFileTargetObjectType.APPLICATION:
                        // If the application/reference number is known, it is always the most useful/important information.
                        if (fileUploadWrapper.meta.applicationNumber) {
                            Object.assign(metaInfo, {
                                slotName: 'applicationNumber',
                                linkText: fileUploadWrapper.meta.applicationNumber,
                            })
                        }
                        // If the application/reference number is not known but the contactName is, the contactName becomes the most useful info.
                        if (fileUploadWrapper.meta.contactName && !fileUploadWrapper.meta.applicationNumber) {
                            Object.assign(metaInfo, {
                                slotName: 'contactName',
                                linkText: fileUploadWrapper.meta.contactName,
                            })
                        }
                        break

                    // targetObjectType is a CONTRACT
                    case ConsultingFileTargetObjectType.CONTRACT:
                        // If the contractNumber is known, it is always the most useful/important information.
                        if (fileUploadWrapper.meta.contractNumber) {
                            Object.assign(metaInfo, {
                                slotName: 'contractNumber',
                                linkText: fileUploadWrapper.meta.contractNumber,
                            })
                        }
                        // If the contractNumber is not known but the contactName is, the contactName becomes the most useful info.
                        if (fileUploadWrapper.meta.contactName && !fileUploadWrapper.meta.contractNumber) {
                            Object.assign(metaInfo, {
                                slotName: 'contactName',
                                linkText: fileUploadWrapper.meta.contactName,
                            })
                        }
                        break

                    // targetObjectType is a CONTACT
                    case ConsultingFileTargetObjectType.CONTACT:
                    default:
                        // If contactName is known, it makes the message a lot more user-friendly.
                        if (fileUploadWrapper.meta.contactName) {
                            Object.assign(metaInfo, {
                                slotName: 'contactName',
                                linkText: fileUploadWrapper.meta.contactName,
                            })
                        }
                        break
                }
            }

            return metaInfo
        },
    },
}
</script>

<style lang="scss" scoped>
.file-item {
    padding-top: 0;
    padding-right: 0;
    min-height: auto;

    &-icon {
        padding-right: $sizeSpacingXs;
    }

    &-info {
        padding-top: 2px;
    }

    &.file-status-failed {
        margin: 0 (-($sizeSpacingSm)) $sizeSpacingSm (-($sizeSpacingSm));
        padding: $sizeSpacingSm $sizeSpacingSm $sizeSpacingSm ($sizeSpacingSm + $sizeSpacingMd);
        background-color: var(--q-color-negative-lighter);

        .file-name {
            color: var(--q-color-negative-dark);
        }

        ::v-deep .q-expansion-item {

            .q-item {
                padding-left: 0;
                padding-right: $sizeSpacingXs;
            }

            .info-box {
                margin: 0 0 $sizeSpacingSm 0;
                padding: 0 $sizeSpacingSm 0 $sizeSpacingSm;
                border: none;
                border-left: $sizeSpacingXs solid var(--q-color-negative-light);
            }
        }
    }
}

.file-name {
    word-break: break-word;
}

.file-actions {
    display: inline-block
}

.clear-history-trigger,
.clear-history-item-trigger {
    margin: -4px 0 -2px 0;
}
</style>
