export default {
    type: 'root',
    children: [
        {
            type: "field",
            key: "additionalRecipientId",
            label: "views.contract.settings.commission.commission-splitting.additional-recipient",
            inputType: "consultant",
            validators: [
                {
                    type: "required",
                },
            ],
        },
        {
            type: "field",
            key: "splitRate",
            label: "views.contract.settings.commission.commission-splitting.split-rate",
            inputType: 'number',
            validators: [
                {
                    type: "required",
                },
            ],
            extra_attributes: {
                step: '0.01',
                max: '100',
                min: '0.01',
            },
        },
        {
            type: "field",
            key: "activeFrom",
            label: "views.contract.settings.commission.commission-splitting.active-from",
            inputType: "calendar",
            validators: [
                {
                    type: "date",
                },
            ],
        },
        {
            type: "field",
            key: "activeUntil",
            label: "views.contract.settings.commission.commission-splitting.active-until",
            inputType: "calendar",
            validators: [
                {
                    type: "date",
                },
            ],
        },
    ],
}
