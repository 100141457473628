import { checkRouteAccessPermissions, checkRouteAccessPermissionsNested } from '@/helpers/acl'

import DefaultLayout from '@/layouts/Default.vue'
import EcoHubTechUserDetail from '@/views/ecoHub/EcoHubTechUserDetail'
import EcoHubTechUserCreate from '@/views/ecoHub/EcoHubTechUserCreate'
import EcoHubTechUserUpdate from '@/views/ecoHub/EcoHubTechUserUpdate'


export default [
    // System preferences
    {
        path: '/admin/eco-hub/',
        name: 'admin-eco-hub',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:ecoHub:core'],
            can: checkRouteAccessPermissions,
            appSubSection: 'admin',
            navGroup: 'ecoHub',
        },
        redirect: {
            name: 'eco-hub-tech-user-detail',
        },
    },

    {
        path: '/admin/eco-hub/tech-user/',
        name: 'eco-hub-tech-user',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:ecoHub:core', 'EcoHubTechUser:manage'],
            can: checkRouteAccessPermissionsNested,
            appSubSection: 'admin',
            navGroup: 'ecoHub',
        },
        children: [
            {
                path: '/',
                name: 'eco-hub-tech-user-detail',
                component: EcoHubTechUserDetail,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'ecoHub',
                    subNavGroup: 'techUser',
                },
            },
            {
                path: 'create',
                name: 'eco-hub-tech-user-create',
                component: EcoHubTechUserCreate,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'ecoHub',
                    subNavGroup: 'techUser',
                },
            },
            {
                path: 'update',
                name: 'eco-hub-tech-user-update',
                component: EcoHubTechUserUpdate,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'ecoHub',
                    subNavGroup: 'techUser',
                },
            },
        ],
    },
]
