<template>
    <page-wrapper class="q-gutter-y-md" :class="{ 'show-color-values': toggleColorValues }">
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('views.dev.design.design')" :to="{ name: 'dev-design' }" />
                <q-breadcrumbs-el :label="$t('views.dev.design.color-palette.color-palette')" :to="{ name: 'dev-design-color-palette' }" />
            </template>

            <h1 class="q-mb-none">{{ $t('views.dev.design.color-palette.color-palette') }}</h1>
            <q-toggle v-model="toggleColorValues" :label="$t('views.dev.design.color-palette.show-color-values')" />
        </page-header>

        <div class="row q-mt-md q-col-gutter-x-md">
            <div class="col-xs-12 q-mt-lg">
                <h2 id="main-app-colors" class="q-mb-md">{{ $t('views.dev.design.color-palette.main-app-colors') }}</h2>
                <div class="row q-col-gutter-md col-xs-12 flex main-app-colors-wrapper" :class="{ 'screen-xl': $q.screen.gt.md }">
                    <q-card
                        v-for="color in mainAppColors"
                        :key="`card-${color.name}`"
                        flat
                        class="color flex justify-start color-field column col-xs-4 col-sm-4 col-md-3 col-lg-2 text-center"
                    >
                        <q-card-section :id="`field-main-app-${color.name}`" class="color color-field flex justify-center items-center" :class="`bg-${color.name}`">
                            {{ color.name }}
                            <dev-color-context-menu :color="`${color.name}`" color-variable-prefix="q-" :color-field-id="`field-main-app-${color.name}`" />
                        </q-card-section>
                        <q-card-section class="flex justify-center items-center q-pa-none">
                            <template v-for="variation in themeColorVariations">
                                <div
                                    :id="`field-main-app-${color.name}-${variation.name}`"
                                    :key="`field-main-app-${color.name}-${variation.name}`"
                                    class="detailed-color color-field flex justify-center items-center"
                                    :class="`bg-${color.name}-${variation.name}`"
                                >
                                    <div class="color-name">{{ color.name }}-{{ variation.name }}</div>
                                    <dev-color-context-menu :color="`${color.name}-${variation.name}`" color-variable-prefix="q-" :color-field-id="`field-main-app-${color.name}-${variation.name}`" />
                                </div>
                            </template>
                        </q-card-section>
                    </q-card>
                </div>
            </div>

            <div class="col-xs-12 q-mt-lg">
                <q-separator color="secondary-light" />
                <h2 class="q-mb-md">{{ $t('views.dev.design.color-palette.theme-colors.theme-colors') }}</h2>
                <!-- TODO: Check if there’s another (easy) way to achieve the same without using v-html -->
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p class="q-mb-lg" v-html="$t('views.dev.design.color-palette.theme-colors.theme-colors-text')" />
                <div v-for="group in themeColors" :key="`theme-color-group-${group.name}`" class="row col-xs-12 q-mt-md">
                    <h3 class="text-h4 q-mt-md">{{ $tc(`views.dev.design.color-palette.theme-colors.${group.name}`, 1) }}</h3>
                    <div class="row q-col-gutter-md col-xs-12 flex">
                        <q-card
                            v-for="color in group.colors"
                            :key="`card-${color.name}`"
                            flat
                            class="color flex justify-start color-field column col-xs-4 col-sm-4 col-md-3 col-lg-2 col-xl-1 text-center"
                        >
                            <q-card-section :id="`field-${color.name}`" class="color color-field flex justify-center items-center" :class="`bg-${color.name}`">
                                {{ color.name }}
                                <dev-color-context-menu :color="`${color.name}`" :color-field-id="`field-${color.name}`" />
                            </q-card-section>
                        </q-card>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 q-mt-lg">
                <q-separator color="secondary-light" />
                <h2 class="q-mb-md">{{ $tc('common.term.label', 2) }}/{{ $tc('common.term.status', 2) }}</h2>
                <p class="q-mb-lg">{{ $t('views.dev.design.color-palette.labels-text') }}</p>
                <q-badge class="q-mr-sm q-mb-sm default">{{ $tc('common.term.default', 1) }} {{ $tc('common.term.label', 1) }}</q-badge>
                <span class="q-mr-sm q-mb-sm">–</span>
                <status-badge
                    v-for="status in statuses"
                    :key="status.defaultTranslationBasePath ? `${status.defaultTranslationBasePath}-${status.name}` : `status-${status.name}`"
                    :status="status.name"
                    :default-translation-base-path="status.defaultTranslationBasePath"
                    :loading="status.loading"
                />
            </div>

            <div class="col-xs-12 q-mt-lg">
                <q-separator color="secondary-light" />
                <h2 class="q-mb-md">Quasar Colors</h2>
                <p class="q-mb-lg">
                    <mark>{{ $t('views.dev.design.color-palette.quasar-colors-text') }}</mark>
                </p>
                <div class="q-col-gutter-md row col-xs-12">
                    <q-card
                        v-for="color in colorList"
                        :key="`card-${color.name}`"
                        flat
                        class="color flex justify-start color-field column col-xs-4 col-sm-4 col-md-3 col-lg-2 col-xl-1 text-center"
                    >
                        <q-card-section :id="`field-${color.name}`" class="color color-field flex justify-center items-center" :class="`bg-${color.name}`">
                            {{ color.name }}
                            <dev-color-context-menu :color="`${color.name}`" color-variable-prefix="q-" :color-field-id="`field-${color.name}`" />
                        </q-card-section>

                        <q-card-section class="flex justify-center items-center q-pa-none">
                            <template v-for="n in 10">
                                <div
                                    :id="`field-${color.name}-${n}`"
                                    :key="`field-${color.name}-${n}`"
                                    class="detailed-color color-field flex justify-center items-center"
                                    :class="`bg-${color.name}-${n}`"
                                >
                                    <div :class="`color-name`">
                                        {{ color.name }}-{{ n }}
                                    </div>
                                    <dev-color-context-menu :color="`${color.name}-${n}`" color-variable-prefix="q-" :color-field-id="`field-${color.name}-${n}`" />
                                </div>
                            </template>
                        </q-card-section>
                    </q-card>
                </div>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import DevColorContextMenu from '@/components/dev/DevColorContextMenu.vue'
import { copyToClipboard } from 'quasar'

import StatusBadge from '@/components/StatusBadge.vue'

export default {
    name: 'DevDesignColorPalette',
    components: {
        DevColorContextMenu,
        StatusBadge,
    },
    meta () {
        return {
            title: 'Color Palette',
        }
    },
    apollo: {},
    data () {
        return {
            toggleColorValues: false,
            mainAppColors: [
                { name: 'primary' },
                { name: 'secondary' },
                { name: 'accent' },
                { name: 'positive' },
                { name: 'negative' },
                { name: 'warning' },
                { name: 'info' },
            ],
            themeColorVariations: [
                { name: 'lighter' },
                { name: 'light' },
                { name: 'dark' },
                { name: 'darker' },
            ],
            // TODO: Adjust to match "Theming & colors" in quasar.variables.styl
            themeColors: [
                {
                    // Basic colors
                    name: 'basic-colors',
                    colors: [
                        { name: 'white' },
                        { name: 'black' },
                    ],
                },
                {
                    // Background colors
                    name: 'background-colors',
                    colors: [
                        { name: 'background-primary' },
                        { name: 'background-secondary' },
                        { name: 'background-tertiary' },
                        { name: 'background-inverted' },
                        { name: 'background-faded' },
                        { name: 'background-kbd' },
                    ],
                },
                {
                    // Border colors
                    name: 'border-colors',
                    colors: [
                        { name: 'border-primary' },
                        { name: 'border-secondary' },
                        { name: 'border-tertiary' },
                        { name: 'border-inverted' },
                    ],
                },
                {
                    // Text colors
                    name: 'text-colors',
                    colors: [
                        { name: 'text-primary' },
                        { name: 'text-secondary' },
                        { name: 'text-tertiary' },
                        { name: 'text-inverted' },
                        { name: 'text-selected' },
                        { name: 'heading-primary' },
                        { name: 'heading-secondary' },
                        { name: 'heading-tertiary' },
                        { name: 'text-highlighted-background' },
                    ],
                },
                {
                    // Actions / Buttons
                    name: 'actions-buttons',
                    colors: [
                        { name: 'base-action' },
                        { name: 'base-action-interaction' },
                        { name: 'primary-action' },
                        { name: 'primary-action-interaction' },
                    ],
                },
                {
                    // Links
                    name: 'links',
                    colors: [
                        { name: 'link' },
                        { name: 'link-background' },
                        { name: 'link-decoration' },
                        { name: 'link-action' },
                        { name: 'link-action-background' },
                        { name: 'link-action-decoration' },
                        { name: 'link-visited' },
                        { name: 'link-visited-background' },
                        { name: 'link-visited-decoration' },
                    ],
                },
                {
                    // Form elements
                    name: 'form-elements',
                    colors: [
                        { name: 'background-input-field' },
                        { name: 'background-input-field-focused' },
                        { name: 'background-input-field-disabled' },
                        { name: 'background-input-field-highlighted' },
                        { name: 'background-input-field-selected' },
                        { name: 'text-input-field-disabled' },
                    ],
                },
                {
                    // Editor colors
                    name: 'editor',
                    colors: [
                        { name: 'text-smobj' },
                        { name: 'background-smobj' },
                        { name: 'text-smobj-group-general' },
                        { name: 'background-smobj-group-general' },
                        { name: 'text-smobj-group-recipient' },
                        { name: 'background-smobj-group-recipient' },
                        { name: 'text-smobj-group-sender' },
                        { name: 'background-smobj-group-sender' },
                        { name: 'text-smobj-group-contract' },
                        { name: 'background-smobj-group-contract' },
                    ],
                },
                {
                    // Illustrations
                    name: 'illustrations',
                    colors: [
                        { name: 'illustration-primary' },
                        { name: 'illustration-primary-light' },
                        { name: 'illustration-primary-lighter' },
                        { name: 'illustration-accent' },
                        { name: 'illustration-accent-light' },
                        { name: 'illustration-accent-lighter' },
                        { name: 'illustration-white' },
                    ],
                },
                {
                    // Edit mode
                    name: 'edit-mode',
                    colors: [
                        { name: 'edit-mode-link' },
                        { name: 'edit-mode-link-background' },
                        { name: 'edit-mode-link-decoration' },
                        { name: 'edit-mode-link-action' },
                        { name: 'edit-mode-link-action-background' },
                        { name: 'edit-mode-link-action-decoration' },
                    ],
                },
                {
                    // App sub section colors
                    name: 'app-sub-sections',
                    colors: [
                        { name: 'section-main' },
                        { name: 'section-account' },
                        { name: 'section-user-manual' },
                        { name: 'section-about' },
                        { name: 'section-admin' },
                        { name: 'section-dev' },
                    ],
                },
                {
                    // Additional colors / Special colors
                    name: 'additional-colors',
                    colors: [
                        { name: 'system-owner' },
                        { name: 'product-provider' },
                        { name: 'consulting-company' },
                        { name: 'consultant' },
                    ],
                },
            ],
            statuses: [
                // Statuses
                { name: 'active' },
                { name: 'archived' },
                { name: 'blocked' },
                { name: 'canceled' },
                { name: 'cancellation-approved' },
                { name: 'cancellation-pending' },
                { name: 'cancelled' },
                { name: 'completed' },
                { name: 'deceased' },
                { name: 'done' },
                { name: 'draft' },
                { name: 'expired' },
                { name: 'failed' },
                { name: 'importing' },
                { name: 'in-progress' },
                { name: 'inactive' },
                { name: 'invitation-expired' },
                { name: 'invited' },
                { name: 'partially-failed' },
                { name: 'pending' },
                { name: 'processing' },
                { name: 'ready' },
                { name: 'replaced' },
                { name: 'suspended' },
                { name: 'terminated' },
                { name: 'todo' },
                { name: 'warning' },
                // Application statuses
                { name: 'on-hold', defaultTranslationBasePath: 'common.status.application-status' },
                { name: 'pending-at-product-provider', defaultTranslationBasePath: 'common.status.application-status' },
                { name: 'approved-by-product-provider', defaultTranslationBasePath: 'common.status.application-status' },
                { name: 'declined-by-product-provider', defaultTranslationBasePath: 'common.status.application-status' },
                { name: 'counter-proposal-pending-at-customer', defaultTranslationBasePath: 'common.status.application-status' },
                { name: 'counter-proposal-declined-by-customer', defaultTranslationBasePath: 'common.status.application-status' },
                { name: 'withdrawn', defaultTranslationBasePath: 'common.status.application-status' },
                // Commission list (entry) statuses
                { name: 'incompletely-processed', defaultTranslationBasePath: 'common.status.commission-list-status' },
                { name: 'queued-for-posting', defaultTranslationBasePath: 'common.status.commission-list-entry-status', loading: true },
                { name: 'posted', defaultTranslationBasePath: 'common.status.commission-list-status' },
                { name: 'posting-cancelled', defaultTranslationBasePath: 'common.status.commission-list-entry-status' },
                { name: 'posting-failed', defaultTranslationBasePath: 'common.status.commission-list-entry-status' },
                // Customer statuses
                { name: 'lead', defaultTranslationBasePath: 'common.contact.customer-status' },
                { name: 'active', defaultTranslationBasePath: 'common.contact.customer-status' },
                { name: 'inactive', defaultTranslationBasePath: 'common.contact.customer-status' },
                // Special contact types
                { name: 'system-owner' },
                { name: 'product-provider' },
                { name: 'consulting-company' },
                { name: 'consultant' },
                // Due date statuses
                { name: 'due-today', defaultTranslationBasePath: 'common.status.due-date-status' },
                { name: 'upcoming', defaultTranslationBasePath: 'common.status.due-date-status' },
                { name: 'overdue', defaultTranslationBasePath: 'common.status.due-date-status' },
            ],
            colorList: [
                { name: 'red' },
                { name: 'pink' },
                { name: 'purple' },
                { name: 'deep-purple' },
                { name: 'indigo' },
                { name: 'blue' },
                { name: 'light-blue' },
                { name: 'cyan' },
                { name: 'teal' },
                { name: 'green' },
                { name: 'light-green' },
                { name: 'lime' },
                { name: 'yellow' },
                { name: 'amber' },
                { name: 'orange' },
                { name: 'deep-orange' },
                // Neutral colors
                { name: 'brown' },
                { name: 'grey' },
                { name: 'blue-grey' },
            ],
        }
    },
    computed: {},
    mounted: function () {
        let colorFields = document.querySelectorAll('.color,.detailed-color')
        Array.prototype.forEach.call(colorFields, function (colorField) {
            if (colorField.id) {
                let colorCodeRgb = document.createTextNode(this.getColorValue(this.getStyle(colorField.id, 'background-color')))
                let colorCodeRgbWrapper = document.createElement('div')
                colorCodeRgbWrapper.classList.add('color-value')
                colorCodeRgbWrapper.appendChild(colorCodeRgb)

                let colorCodeHex = document.createTextNode(this.getColorValue(this.getStyle(colorField.id, 'background-color'), 'hex'))
                let colorCodeHexWrapper = document.createElement('div')
                colorCodeHexWrapper.classList.add('color-value')
                colorCodeHexWrapper.appendChild(colorCodeHex)

                if (this.getColorValue(this.getStyle(colorField.id, 'background-color'), 'hex') === '#00000000') {
                    colorField.classList.add('color-transparent')
                }

                colorField.classList.add(((this.lightOrDark(this.getStyle(colorField.id, 'background-color')) === 'dark') ? 'bg-color-dark' : 'bg-color-light'))
                colorField.appendChild(colorCodeHexWrapper)
                colorField.appendChild(colorCodeRgbWrapper)
            }
        }.bind(this))
    },
    methods: {
        copyToClipboard (content) {
            content = content.replace(/<br>/g, '\n')

            copyToClipboard(content)
                .then(() => {
                    this.$q.notify({
                        type: 'positive',
                        message: this.$t('common.notifications.copied-to-clipboard-success'),
                        caption: content,
                    })
                })
                .catch(() => {
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$t('common.notifications.copied-to-clipboard-error'),
                    })
                })
        },
        getStyle (element, property) {
            let style = getComputedStyle(document.getElementById(element), '')

            return style[property.replace(/-([a-z])/g, function (g) {
                return g[1].toUpperCase()
            })]
        },
        getColorValue (color, colorSpace) {
            let space = colorSpace || 'rgb'
            let rgbRegex = /^rgb\((((((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5]),\s?)){2}|((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5])\s)){2})((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5]))|((((([1-9]?\d(\.\d+)?)|100|(\.\d+))%,\s?){2}|((([1-9]?\d(\.\d+)?)|100|(\.\d+))%\s){2})(([1-9]?\d(\.\d+)?)|100|(\.\d+))%))\)$/i
            let rgbaRegex = /^rgba\((((((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5]),\s?)){3})|(((([1-9]?\d(\.\d+)?)|100|(\.\d+))%,\s?){3}))|(((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5])\s){3})|(((([1-9]?\d(\.\d+)?)|100|(\.\d+))%\s){3}))\/\s)((0?\.\d+)|[01]|(([1-9]?\d(\.\d+)?)|100|(\.\d+))%)\)$/i
            let hexRegex = /^#([\da-f]{3}){1,2}$/i
            let hexaRegex = /^#([\da-f]{4}){1,2}$/i

            switch (space) {
                case 'hex':
                    if (rgbRegex.test(color) || rgbaRegex.test(color)) {
                        return this.rgbToHex(color)
                    } else if (hexRegex.test(color) || hexaRegex.test(color)) {
                        return color
                    } else {
                        console.log('There was an error getting the color value.')
                        break
                    }
                default:
                    return color
            }
        },
        rgbToHex (color) {
            let rgbaRegex = /^rgba\((((((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5]),\s?)){3})|(((([1-9]?\d(\.\d+)?)|100|(\.\d+))%,\s?){3}))|(((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5])\s){3})|(((([1-9]?\d(\.\d+)?)|100|(\.\d+))%\s){3}))\/\s)((0?\.\d+)|[01]|(([1-9]?\d(\.\d+)?)|100|(\.\d+))%)\)$/i
            // Check if it’s an rgba() color
            let rgba = !!rgbaRegex.test(color)

            // Choose correct separator
            let sep = color.indexOf(',') > -1 ? ',' : ' '
            // Turn 'color(r,g,b)' into [r,g,b]
            color = color.substr(rgba ? 5 : 4).split(')')[0].split(sep)

            // Strip the slash if using space-separated syntax for rgba values
            if (color.indexOf('/') > -1) {
                color.splice(3, 1)
            }

            // Convert %s to 0–255
            for (let R in color) {
                let r = color[R]
                if (r.indexOf('%') > -1) {
                    let p = r.substr(0, r.length - 1) / 100

                    if (R < 3) {
                        color[R] = Math.round(p * 255)
                    } else {
                        color[R] = p
                    }
                }
            }

            let r = (+color[0]).toString(16)
            let g = (+color[1]).toString(16)
            let b = (+color[2]).toString(16)

            if (r.length === 1) {
                r = '0' + r
            }
            if (g.length === 1) {
                g = '0' + g
            }
            if (b.length === 1) {
                b = '0' + b
            }
            if (rgba) {
                let a = Math.round(+color[3] * 255).toString(16)
                if (a.length === 1) {
                    a = '0' + a
                }

                return '#' + r + g + b + a
            } else {
                return '#' + r + g + b
            }
        },
        lightOrDark (color) {
            // Original from
            // https://awik.io/determine-color-bright-dark-using-javascript/
            // Enhanced to be able to deal with rgba() colors by MME

            // Variables for red, green, blue values
            let r, g, b, a, hsp

            // Check the format of the color, HEX or RGB?
            if (color.match(/^rgb/)) {
                // If HEX --> store the red, green, blue values in separate variables
                color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

                r = color[1]
                g = color[2]
                b = color[3]
                a = color[4]
            } else {
                // If RGB --> Convert it to HEX: http://gist.github.com/983661
                color = +('0x' + color.slice(1).replace(
                    color.length < 5 && /./g, '$&$&'))

                r = color >> 16
                g = color >> 8 & 255
                b = color & 255
            }

            // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
            hsp = Math.sqrt(
                0.299 * (r * r) +
                0.587 * (g * g) +
                0.114 * (b * b)
            )

            // Using the HSP value, determine whether the color is light or dark
            if (hsp > 127.5 || a < 0.5) {
                return 'light'
            } else {
                return 'dark'
            }
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .main-app-colors-wrapper {
    &.screen-xl {
        .q-card {
            width: (100% / 7) !important;
        }
    }
}

::v-deep {
    .color {
        min-height: 60px;
        background-color: transparent;
    }

    .detailed-color {
        position: relative;
        min-height: 40px;
        padding: 8px;
    }

    .bg-color-light {
        color: var(--color-text-primary);
    }

    .bg-color-dark {
        color: var(--color-text-tertiary);
    }

    .color-value {
        display: none;
        width: 100%;
        opacity: 0.8;
        font-size: smaller;

        .show-color-values & {
            display: block;
        }
    }

    .color-field {
        width: 100%;

        &.color-transparent {
            background-image: linear-gradient(45deg, var(--color-background-secondary) 25%, transparent 25%), linear-gradient(-45deg, var(--color-background-secondary) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, var(--color-background-secondary) 75%), linear-gradient(-45deg, transparent 75%, var(--color-background-secondary) 75%);
            background-size: 20px 20px;
            background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
        }
    }

    // DevColorContextMenu
    .color-field {

        .color-field-menu {
            opacity: 0;
            transition: opacity 0.2s 0s ease-in-out;
        }

        &:hover,
        &:focus,
        &:active {
            & > .color-field-menu {
                opacity: 1;
            }
        }
    }
}

</style>
