import gql from 'graphql-tag'

export const APPLICATION_FILE_DELETE_MUTATION = gql`
    mutation applicationFileDelete(
        $id: ID!
    ) {
        applicationFileDelete(
            id: $id
        ) {
            status
        }
    }
`
