import { apolloClient } from '@/apollo/client'

import { IGB2B_INSURER_CODES_QUERY } from '@/graphql/igb2bInsurerCode/igb2bInsurerCodes'

export const IGB2BInsurerCodeService = {
    all (variables) {
        return apolloClient.query({
            query: IGB2B_INSURER_CODES_QUERY,
            variables,
        }).then(response => response.data.igb2bInsurerCodes)
    },
}
