<template>
    <base-select-filter
        v-if="$store.state.user.tenantSettings.correspondenceLanguages.length"

        :value="value"
        v-bind="$attrs"
        :options="$store.state.user.tenantSettings.correspondenceLanguages"

        option-value="id"
        option-label="name"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import BaseSelectFilter from '@/components/form/BaseSelectFilter'

export default {
    name: 'CorrespondenceLanguageSelect',
    components: {
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
    },
}
</script>
