<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.accounting.account-balance-report.account-balance-report', 2)" :to="{ name: 'accounting-account-balance-reports' }" />
                <q-breadcrumbs-el :label="$tc('common.accounting.account-balance-report.create-report', 1)" :to="{ name: 'accounting-account-balance-report-create' }" />
            </template>

            <h1>{{ $tc('common.accounting.account-balance-report.create-report', 1) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <account-balance-report-form
                    ref="accountBalanceReportForm"
                    @post-handle-form-data="handleSubmit"
                />
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { AccountBalanceReport } from '@/models/accountBalanceReport'
import { parseFormDataToInput }  from '@/helpers/accountBalanceReport'
import AccountBalanceReportForm from '@/components/accounting/acountBalanceReport/AccountBalanceReportForm.vue'

export default {
    name: 'AccountBalanceReportCreate',
    meta () {
        return {
            title: this.$tc('common.accounting.account-balance-report.create-report', 1),
        }
    },
    components: {
        AccountBalanceReportForm,
    },
    methods: {
        handleSubmit (formData) {
            const accountBalanceReportInput = parseFormDataToInput(formData)

            AccountBalanceReport.create(accountBalanceReportInput)
                .then(() => {
                    this.$router.replace({ name: 'accounting-account-balance-reports' })

                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.accounting.account-balance-report-created-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.accountBalanceReportForm.showFormErrorMessage(error)
                    this.$refs.accountBalanceReportForm.resetFormSubmitStatus()
                })
        },
    },
}
</script>
