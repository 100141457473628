import gql from 'graphql-tag'

export const CORRESPONDENCE_SALUTATIONS_QUERY = gql`
    query correspondenceSalutations(
        $page: Int = 1
        $count: Int = 1000
        $orderField: CorrespondenceSalutationOrderField = CONTACT_TYPE
        $orderDirection: OrderDirection = DESC
        $filterContactType: [ContactType!]
        $filterFormality: [DocumentContentFormality!]
        $filterGender: [GenderChoice!]
        $filterLanguageId: ID
        $filterIsDefault: Boolean
    ) {
        correspondenceSalutations(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                contact_type: $filterContactType
                formality: $filterFormality
                gender: $filterGender
                language_id: $filterLanguageId
                is_default: $filterIsDefault
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                contact_type
                formality
                gender
                language {
                    id
                    name
                }
                is_default
                salutation {
                    schemaType
                    schemaVersion
                    contentJSON
                    contentHTML
                    attrs
                }
                created_at
                updated_at
            }
        }
    }
`
