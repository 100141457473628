<template>
    <!-- TODO: Re-think if form field keys really also should get kebab-cased. -->
    <div class="form-item-field" :data-key="kebabCase(item.key)"><slot></slot></div>
</template>

<script>
import { kebabCase } from 'lodash'
import { FormField } from '@/libs/form'

export default {
    name: 'FormFieldComponent',
    props: {
        item: {
            type: FormField,
            required: true,
        },
    },
    setup () {
        return {
            kebabCase,
        }
    },
}
</script>
