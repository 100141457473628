<template>
    <page-wrapper>
        <page-header>
            <h1>{{ $t('views.admin.consulting-settings.overview-title') }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <info-box>
                    <p>{{ $t('views.admin.consulting-settings.description-text-person') }}</p>
                    <p>{{ $t('views.admin.consulting-settings.description-text-company') }}</p>
                </info-box>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <base-button-toggle
                    v-model="currentViewMode"
                    :options="[
                        { label: $t('common.term.list-view'), value: 'list', icon: 'mib-list-bullets-1', to: { name: 'admin-consulting-settings-list' } },
                        { label: $t('common.term.tree-view'), value: 'tree', icon: 'mib-hierarchy-2', to: { name: 'admin-consulting-settings-list', params: { viewMode: 'tree' } } }
                    ]"
                />
            </div>
        </div>

        <consulting-settings-list v-if="currentViewMode === 'list'" />
        <consulting-settings-tree v-else />
    </page-wrapper>
</template>

<script>
import ConsultingSettingsTree from '@/components/consulting-settings/ConsultingSettingsTree.vue'
import ConsultingSettingsList from '@/components/consulting-settings/ConsultingSettingsList.vue'
import BaseButtonToggle from '@/components/form/BaseButtonToggle.vue'

export default {
    name: 'ConsultingSettingsListView',
    components: {
        ConsultingSettingsList,
        ConsultingSettingsTree,
        BaseButtonToggle,
    },
    props: {
        viewMode: {
            type: String,
            default: 'list',
        },
    },
    meta () {
        return {
            title: this.$tc('common.contact.consulting-setting', 2),
        }
    },
    data () {
        return {
            currentViewMode: this.viewMode,
        }
    },
}
</script>
