<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.correspondence.email.email', 2)" :to="{ name: 'correspondence-emails' }" />
                <q-breadcrumbs-el :label="$tc(`common.correspondence.email.status--${activeStatusGroup}`, 2)" :to="{ name: 'correspondence-email-list', params: { statusGroup: activeStatusGroup } }" />
            </template>

            <h1 class="q-mb-none">{{ $tc('common.correspondence.email.email', 2) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-12">
                <base-table
                    :columns="columns"
                    :fetch-objects-fn="CorrespondenceService.items.all"
                    :additional-filters="additionalFilters"
                    user-settings-base-path="CorrespondenceEmailList"
                    pagination-sort-by-default-key="updated_at"
                    :pagination-initial-order-direction-descending="true"
                    @row-dblclick="onDblClick"
                >
                    <template v-slot:body-cell-target_object_type="slotProps">
                        <q-td :props="slotProps">
                            {{ $tc(`common.term.${slotProps.row.target_object_type.toLowerCase()}`, 1) }}
                        </q-td>
                    </template>

                    <template v-slot:body-cell-status="slotProps">
                        <q-td :props="slotProps">
                            <status-badge :status="slotProps.row.status" default-translation-base-path="common.status.correspondence-item-status" />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-actions="slotProps">
                        <q-td :props="slotProps" auto-width>
                            <q-btn
                                v-if="slotProps.row.status === CorrespondenceItemStatus.DRAFT"
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-pencil"
                                :to="{ name: 'correspondence-email-update', params: { id: slotProps.row.id } }"
                            />
                            <q-btn
                                v-else
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-view-1"
                                :to="{ name: 'correspondence-email-summary', params: { id: slotProps.row.id } }"
                            />
                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-common-file-double-1"
                                :to="{ name: 'correspondence-email-create', query: { prefill: slotProps.row.id } }"
                            >
                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.correspondence.email.duplicate-email', 1) }}</q-tooltip>
                            </q-btn>
                        </q-td>
                    </template>
                </base-table>
            </div>
        </div>

        <in-page-footer>
            <base-button
                :to="{ name: 'correspondence-email-create' }"
                :label="$tc('common.correspondence.email.write-new-email', 1)"
                icon="mib-email-action-add"
                primary-button
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import { CorrespondenceService } from '@/services/correspondence'
import { CorrespondenceItemType, CorrespondenceItemStatus } from '@/enums/graphql'
import BaseTable from '@/components/BaseTable'
import StatusBadge from '@/components/StatusBadge.vue'

export default {
    name: 'CorrespondenceEmailList',
    components: {
        BaseTable,
        StatusBadge,
    },
    meta () {
        return {
            title: `${this.$tc('common.correspondence.email.email', 2)}: ${this.$tc('common.correspondence.email.status--' + this.activeStatusGroup, 2)}`,
        }
    },
    props: {
        statusGroup: {
            type: String,
            default: 'pending',
        },
    },
    data () {
        return {
            activeStatusGroup: this.statusGroup,
            CorrespondenceService,
            CorrespondenceItemStatus,
            columns: [
                {
                    name: 'name',
                    label: this.$tc('common.term.name'),
                    field: row => row.name,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'target_object_type',
                    label: this.$tc('common.correspondence.target-object.target-object-type', 1),
                    field: row => row.target_object_type,
                    align: 'left',
                },
                {
                    name: 'language_name',
                    label: this.$tc('common.term.language'),
                    field: row => row.language.name,
                    align: 'left',
                },
                {
                    name: 'attachments',
                    label: this.$tc('common.term.attachment', 2),
                    field: row => row.attachments.length,
                    align: 'left',
                },
                {
                    name: 'updated_at',
                    label: this.$tc('common.term.updated--last-updated', 1),
                    field: row => row.formattedUpdatedAt,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'status',
                    label: this.$tc('common.term.status'),
                    field: row => row.status,
                    align: 'left',
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left' },
            ],
        }
    },
    computed: {
        additionalFilters () {
            const filters = {
                filterCorrespondenceItemType: CorrespondenceItemType.EMAIL,
            }

            switch (this.activeStatusGroup) {
                case 'pending':
                    filters.filterStatus = [
                        CorrespondenceItemStatus.DRAFT,
                        CorrespondenceItemStatus.PROCESSING,
                        CorrespondenceItemStatus.PROCESSED,
                        CorrespondenceItemStatus.FAILED,
                    ]
                    break
                case 'completed':
                    filters.filterStatus = [
                        CorrespondenceItemStatus.DONE,
                        CorrespondenceItemStatus.CANCELLED,
                    ]
                    break
            }

            return filters
        },
    },
    watch: {
        '$route' (to) {
            this.activeStatusGroup = to.params.statusGroup || this.statusGroup
        },
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        onDblClick (evt, row) {
            if (row.status ===  CorrespondenceItemStatus.DRAFT) {
                this.$router.push({ name: 'correspondence-email-update', params: { id: row.id } })
            } else {
                this.$router.push({ name: 'correspondence-email-summary', params: { id: row.id } })
            }
        },
        onNewEntity () {
            this.$router.push({ name: 'correspondence-email-create' })
        },
    },
}
</script>
