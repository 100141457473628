import gql from 'graphql-tag'

export const COMMISSION_LIST_CANCEL_POSTING_MUTATION = gql`
    mutation commissionListCancelPosting(
        $id: ID!,
        $stopAll: Boolean!
    ) {
        commissionListCancelPosting(
            id: $id
            stop_all: $stopAll
        ) {
            number_of_affected_entries
            status
        }
    }
`
