<template>
    <q-btn
        unelevated
        round
        size="sm"
        icon="mib-copy-paste"
        class="color-field-menu"
    >
        <q-menu>
            <q-list dense>
                <q-item v-close-popup clickable>
                    <q-item-section @click="copyToClipboard(`${color}`)">{{ $t('views.dev.design.color-palette.copy-color-name') }}</q-item-section>
                </q-item>
                <q-item v-close-popup clickable>
                    <q-item-section @click="copyToClipboard(`var(--${colorVariablePrefix}color-${color})`)">{{ $t('views.dev.design.color-palette.copy-css-variable-name') }}</q-item-section>
                </q-item>
                <q-item v-close-popup clickable>
                    <q-item-section @click="copyToClipboard(`var(--${colorVariablePrefix}color-${color}, ${ rgbToHex( getStyle(colorFieldId, 'background-color') ) })`)">{{ $t('views.dev.design.color-palette.copy-css-variable-name-with-fallback') }}</q-item-section>
                </q-item>
                <q-separator />
                <q-item v-close-popup clickable>
                    <q-item-section @click="copyToClipboard( rgbToHex( getStyle(colorFieldId, 'background-color') ) )">{{ $t('views.dev.design.color-palette.copy-hexa-color') }}</q-item-section>
                </q-item>
                <q-item v-close-popup clickable>
                    <q-item-section @click="copyToClipboard( getStyle(colorFieldId, 'background-color') )">{{ $t('views.dev.design.color-palette.copy-rgba-color') }}</q-item-section>
                </q-item>
            </q-list>
        </q-menu>
    </q-btn>
</template>

<script>
import { copyToClipboard } from 'quasar'

export default {
    name: 'DevColorContextMenu',
    props: {
        color: {
            type: String,
            required: true,
        },
        colorVariablePrefix: {
            type: String,
            default: '',
        },
        colorFieldId: {
            type: String,
            required: true,
        },
    },
    methods: {
        copyToClipboard (content) {
            content = content.replace(/<br>/g, '\n')

            copyToClipboard(content)
                .then(() => {
                    this.$q.notify({
                        type: 'positive',
                        message: this.$t('common.notifications.copied-to-clipboard-success'),
                        caption: content,
                    })
                })
                .catch(() => {
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$t('common.notifications.copied-to-clipboard-error'),
                    })
                })
        },
        getStyle (element, property) {
            const elm = document.getElementById(element)
            if (elm !== null) {
                let style = getComputedStyle(elm, '')
                return style[property.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase() })]
            }
        },
        rgbToHex (color) {
            let rgbaRegex = /^rgba\((((((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5]),\s?)){3})|(((([1-9]?\d(\.\d+)?)|100|(\.\d+))%,\s?){3}))|(((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5])\s){3})|(((([1-9]?\d(\.\d+)?)|100|(\.\d+))%\s){3}))\/\s)((0?\.\d+)|[01]|(([1-9]?\d(\.\d+)?)|100|(\.\d+))%)\)$/i
            // Check if it’s an rgba() color
            let rgba = !!rgbaRegex.test(color)

            // Choose correct separator
            let sep = color.indexOf(',') > -1 ? ',' : ' '
            // Turn 'color(r,g,b)' into [r,g,b]
            color = color.substr(rgba ? 5 : 4).split(')')[0].split(sep)

            // Strip the slash if using space-separated syntax for rgba values
            if (color.indexOf('/') > -1) {
                color.splice(3, 1)
            }

            // Convert %s to 0–255
            for (let R in color) {
                let r = color[R]
                if (r.indexOf('%') > -1) {
                    let p = r.substr(0, r.length - 1) / 100

                    if (R < 3) {
                        color[R] = Math.round(p * 255)
                    } else {
                        color[R] = p
                    }
                }
            }

            let r = (+color[0]).toString(16)
            let g = (+color[1]).toString(16)
            let b = (+color[2]).toString(16)

            if (r.length === 1) {
                r = '0' + r
            }
            if (g.length === 1) {
                g = '0' + g
            }
            if (b.length === 1) {
                b = '0' + b
            }
            if (rgba) {
                let a = Math.round(+color[3] * 255).toString(16)
                if (a.length === 1) {
                    a = '0' + a
                }

                return '#' + r + g + b + a
            } else {
                return '#' + r + g + b
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.color-field-menu {
    position: absolute;
    bottom: 0.2em;
    right: 0.2em;
    z-index: 100;
}
</style>
