import { CorrespondenceItemTargetObjectType } from '@/enums/graphql'
import {
    correspondenceItemTargetObjectApplicationMixin,
    correspondenceItemTargetObjectContactMixin,
    correspondenceItemTargetObjectContractMixin
} from '@/mixins/correspondenceItemTargetObjectMixins'

export function wrapCorrespondenceItemTargetObject (targetObject, targetObjectType, additionalProps) {
    let mixin

    switch (targetObjectType) {
        case CorrespondenceItemTargetObjectType.CONTACT:
            mixin = correspondenceItemTargetObjectContactMixin
            break
        case CorrespondenceItemTargetObjectType.APPLICATION:
            mixin = correspondenceItemTargetObjectApplicationMixin
            break
        case CorrespondenceItemTargetObjectType.CONTRACT:
            mixin = correspondenceItemTargetObjectContractMixin
            break
    }

    return Object.assign(Object.create(targetObject, Object.getOwnPropertyDescriptors(mixin)), additionalProps)
}
