import { Model } from '@/models'

import { ProductService } from '@/services'
import { ProductStatus } from '@/enums/graphql'
import { ProductActions } from '@/enums'

export class Product extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'name',
            'template',
            'basicProvider',
            'status',

            // Timestamps
            'created_at',
            'updated_at',
        ], data)

        this.statusTransitions[ProductStatus.ACTIVE] = [ProductStatus.ARCHIVED]
        this.statusTransitions[ProductStatus.ARCHIVED] = [ProductStatus.ACTIVE]

        this.statusActionMapping[ProductStatus.ACTIVE] = ProductActions.UNARCHIVE
        this.statusActionMapping[ProductStatus.ARCHIVED] = ProductActions.ARCHIVE
    }

    static get service () { return ProductService }
    static status = ProductStatus
    static action = ProductActions

    static create ({ name, productTemplate, productProvider }) {
        return ProductService.create({ name, productTemplate, productProvider })
    }

    update (name) {
        return ProductService.update(this.id, name).then(product => {
            Object.assign(this, product)
            return product
        })
    }

    get actions () {
        const actions = super.actions
        actions.push({
            key: ProductActions.DELETE,
            item: this,
        })
        return actions
    }

    get providerName () {
        return this.basicProvider.display_name || this.basicProvider.name
    }

    archive () {
        return ProductService.changeStatus(this.id, ProductStatus.ARCHIVED).then(product => {
            this.status = product.status
            return product
        })
    }

    unarchive () {
        return ProductService.changeStatus(this.id, ProductStatus.ACTIVE).then(product => {
            this.status = product.status
            return product
        })
    }

    delete () {
        return ProductService.delete(this.id)
    }
}
