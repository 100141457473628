<template>
    <!-- TODO: Replace this component with BulkItemStatusIndicator component. -->
    <div class="consulting-file-upload-status-indicator" :class="`status-${status.toLowerCase()}`">
        <inline-svg :src="require(`@/assets/img/icons/max-icons-bold/${statusIcon}.svg`)" />
        <q-tooltip
            :delay="1000"
            anchor="center left"
            self="center right"
            :offset="[10, 0]"
            transition-show="scale"
            transition-hide="scale"
        >
            {{ $tc(`common.file.status.${status.toLowerCase()}--description`, 1) }}
        </q-tooltip>
    </div>
</template>

<script>
import { FileUploadWrapperStatus } from '@/enums'

export default {
    name: 'ConsultingFileUploadStatusIndicator',
    props: {
        status: {
            type: String,
            required: true,
        },
        fileName: {
            type: String,
            required: true,
        },
        fileType: {
            type: String,
            required: false, // The getFileTypeIcon() helper provides a fallback if the fileType should not be provided.
        },
    },
    data () {
        return {
            FileUploadWrapperStatus,
        }
    },
    computed: {
        statusIcon () {
            switch (this.status) {
                case FileUploadWrapperStatus.QUEUED:
                    return 'cloud-clock'
                case FileUploadWrapperStatus.UPLOADING:
                    return 'cloud-sync'
                case FileUploadWrapperStatus.ABORTED:
                    return 'cloud-disable'
                case FileUploadWrapperStatus.DONE:
                    return 'cloud-check'
                case FileUploadWrapperStatus.FAILED:
                    return 'cloud-warning'
                default:
                    return 'cloud-upload' // Fallback only
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.consulting-file-upload-status-indicator {
    padding-top: 3px;
    width: $sizeSpacingLg;

    cursor: help;

    svg {
        width: $sizeSpacingMd;

        ::v-deep path {
            fill: var(--color-text-secondary);
        }
    }
}

.status {

    &-uploading {

        ::v-deep .inner-icon {
            @include rotate();
            fill: var(--q-color-accent);
        }
    }

    &-done {

        ::v-deep .inner-icon {
            fill: var(--q-color-positive);
        }
    }

    &-aborted {

        ::v-deep .inner-icon {
            fill: var(--q-color-negative);
        }
    }

    &-failed {

        ::v-deep .inner-icon {
            fill: var(--q-color-negative);
        }
    }
}
</style>
