import gql from 'graphql-tag'

export const COMMISSION_LIST_CANCEL_PROCESSING_MUTATION = gql`
    mutation commissionListCancelProcessing(
        $id: ID!
    ) {
        commissionListCancelProcessing(
            id: $id
        ) {
            id
            status
        }
    }
`
