<template>
    <page-loading-indicator v-if="!state.isLoaded" :number-of-breadcrumb-elements="4" />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="state.productDerivedEntity.customer.getContactName()" :to="{ name: 'contact-detail', params: { id: contactId } }" data-test="breadcrumb:contact-detail" />
                <q-breadcrumbs-el :label="$tc('common.term.contract', 2)" :to="{ name: 'contact-detail', params: { id: contactId } }" data-test="breadcrumb:contracts" />
                <q-breadcrumbs-el :label="state.productDerivedEntity.formattedNumber" :to="{ name: 'application-detail', params: { id: state.productDerivedEntity.id } }" data-test="breadcrumb:application-detail">
                    <status-badge :status="`${state.productDerivedEntity.is_change_application ? 'change-' : ''}application`" default-translation-base-path="common.status.application-status" class="q-ml-xs" />
                    <status-badge :status="state.productDerivedEntity.status" default-translation-base-path="common.status.application-status" />
                </q-breadcrumbs-el>
                <q-breadcrumbs-el :label="$tc('views.application.approve.application-approved', 1)" :to="{ name: 'application-update', params: { id: state.productDerivedEntity.id } }" data-test="breadcrumb:application-update" />
            </template>

            <h1>{{ $tc(`views.application.approve.${state.productDerivedEntity.is_change_application ? 'change-' : ''}application-approved`, 1) }}</h1>
        </page-header>

        <form-builder-next
            :form="state.form"
            :initial-data="state.initialData"
            :submit-button-label="$tc(`views.application.approve.save--${submissionUpdatesExistingContract ? 'update' : 'create'}-contract`, 1)"
            in-page-footer
            @submit="onSubmit"
            @cancel="onCancel"
        >
            <template v-slot:default="{ formBuilder }">
                <info-box
                    v-if="state.hasActiveCommissionSplits || state.hasCommissionRecipientOverrides"
                    type="warning"
                    data-test="info:commission-recipient-splitting-hint"
                >
                    <p>
                        <template v-if="state.hasCommissionRecipientOverrides && state.hasActiveCommissionSplits">{{ $tc('views.application.approve.commission.has-commission-recipient-override--has-active-commission-splitting', 1) }}</template>
                        <template v-else-if="state.hasCommissionRecipientOverrides">{{ $tc('views.application.approve.commission.has-commission-recipient-override', 1) }}</template>
                        <template v-else-if="state.hasActiveCommissionSplits">{{ $tc('views.application.approve.commission.has-active-commission-splitting', 1) }}</template>
                        {{ $tc('views.application.approve.commission.please-check-also-needed', 1) }}
                    </p>
                </info-box>

                <card
                    type="primary"
                    class="basic-information q-mb-md"
                    :heading="$t('common.contract.basic-info')"
                >
                    <div class="row q-col-gutter-md">
                        <div
                            v-if="state.productDerivedEntity.is_change_application"
                            :class="formBuilder.form.getItem('createNewContract').value === true ? 'col-xs-12 col-sm-6 col-md-4 col-lg-2 col-xl-2' : 'col-xs-12 col-lg-4 col-xl-2'"
                        >
                            <form-builder-next :item="formBuilder.form.getItem('createNewContract')" />
                        </div>
                        <div
                            v-if="state.productDerivedEntity.is_change_application === false || formBuilder.form.getItem('createNewContract').value === true"
                            :class="state.productDerivedEntity.is_change_application ? 'col-xs-12 col-sm-6 col-md-8 col-lg-4' : 'col-xs-12 col-sm-6 col-md-8 col-lg-4 col-xl-4'"
                        >
                            <form-builder-next :item="formBuilder.form.getItem('contractNumber')" />
                        </div>

                        <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                            <form-builder-next :item="formBuilder.form.getItem('submissionResponseDate')" />
                        </div>

                        <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                            <form-builder-next :item="formBuilder.form.getItem('startDate')" />
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                            <form-builder-next :item="formBuilder.form.getItem('endDate')" />
                        </div>

                        <div class="col-xs-6 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                            <form-builder-next :item="formBuilder.form.getItem('premium')" />
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-8 col-lg-4 col-xl-4">
                            <form-builder-next
                                :item="formBuilder.form.getItem('generalAgencyId')"
                                :product-provider-id="state.productProviderId"
                                :disable="disableGeneralAgencyId"
                                @fetched-objects="setGeneralAgencyOptions"
                            />
                            <q-tooltip
                                v-if="disableGeneralAgencyId"
                                :delay="1000"
                                anchor="center end"
                                self="center start"
                                :offset="[10, 0]"
                            >{{ $t('forms.field.label.application.managed-by--disabled-info', { providerName: productProviderName }) }}</q-tooltip>
                        </div>
                    </div>
                </card>

                <form-builder-next :item="formBuilder.form.getItem('productFields')" auto-layout />
            </template>
        </form-builder-next>
    </page-wrapper>
</template>

<script>
import StatusBadge from '@/components/StatusBadge.vue'
import { fetchPaginatedObjects } from '@/helpers'
import { ContractService } from '@/services'
import { onBeforeMount, reactive, ref, computed } from 'vue'
import { Application } from '@/models/application'
import { useRouter } from '@/composables/router'
import { ApplicationApproveForm } from '@/forms/application'
import { composeProductFieldsSection } from '@/helpers/productDerivedEntity'
import { createProductFieldsNameValuePairs } from '@/helpers/contract'

export default {
    name: 'ApplicationApprove',
    meta () {
        return {
            title: !this.state.productDerivedEntity ? `${this.$t('common.term.loading')} …` : `${this.state.productDerivedEntity.formattedNumber} (${this.state.productDerivedEntity.currentContractInformation.product.providerName}) – ${this.$tc(`views.application.approve.${this.state.productDerivedEntity.is_change_application ? 'change-' : ''}application-approved`, 1)}`,
        }
    },
    components: {
        StatusBadge,
    },
    props: {
        contactId: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
    },
    setup (props) {
        // Composables
        const router = useRouter()

        // Data
        const state = reactive({
            isLoaded: false,
            productDerivedEntity: null,
            productProviderId: null,
            form: null,
            initialData: {},
            generalAgencyOptions: [],
            hasActiveCommissionSplits: false,
            hasCommissionRecipientOverrides: false,
        })

        // Template refs
        const formComponent = ref(null)

        // Computed
        const submissionUpdatesExistingContract = computed(() => {
            return state.productDerivedEntity.is_change_application === true && formComponent.value?.formBuilder.form.getItem('createNewContract').value === false
        })
        const disableGeneralAgencyId = computed(() => {
            return state.generalAgencyOptions.length === 0
        })
        const productProviderName = computed(() => {
            return state.productDerivedEntity.currentContractInformation.product?.basicProvider?.display_name || state.productDerivedEntity.currentContractInformation.product?.basicProvider?.name
        })

        // Methods
        async function fetchObject () {
            state.productDerivedEntity = await Application.objects.get(props.id)
            state.productProviderId = state.productDerivedEntity.currentContractInformation.product.basicProvider.id
        }
        async function fetchContracts () {
            const contracts = await fetchPaginatedObjects({ fn: ContractService.all }, { filterCustomerId: props.contactId }, `
                id
                commissionSplits {
                    id
                    is_active
                }
                commissionRecipientOverride {
                    id
                }
            `)

            let hasActiveCommissionSplits = false
            let hasCommissionRecipientOverrides = false
            for (const contract of contracts) {
                if (contract.commissionSplits.some(commissionSplit => commissionSplit.is_active)) hasActiveCommissionSplits = true
                if (contract.commissionRecipientOverride) hasCommissionRecipientOverrides = true
                if (hasActiveCommissionSplits && hasCommissionRecipientOverrides) break
            }

            state.hasActiveCommissionSplits = hasActiveCommissionSplits
            state.hasCommissionRecipientOverrides = hasCommissionRecipientOverrides
        }
        async function setupForm () {
            state.form = new ApplicationApproveForm({ context: { application: state.productDerivedEntity } })
            const templateFormConfiguration = state.productDerivedEntity.currentContractInformation.productTemplate.form_configuration
            composeProductFieldsSection(state.form, templateFormConfiguration)
        }
        function composeInitialData () {
            const productFields = createProductFieldsNameValuePairs(state.productDerivedEntity)
            Object.assign(state.initialData, {
                startDate: state.productDerivedEntity.currentContractInformation.start_date || '',
                endDate: state.productDerivedEntity.currentContractInformation.end_date || '',
                premium: state.productDerivedEntity.currentContractInformation.premium || '',
                generalAgencyId: state.productDerivedEntity.currentContractInformation.generalAgency?.id ?? null,
                productFields,
            })
        }
        function onSubmit (formData) {
            state.productDerivedEntity.setApprovedByProductProvider(formData).then(productDerivedEntity => {
                router.replace({
                    name: 'contract-detail',
                    params: { contactId: props.contactId, id: productDerivedEntity.targetContract.id },
                })
            }).catch(error => {
                formComponent.value.formBuilder.showFormErrorMessage(error)
                formComponent.value.formBuilder.resetFormSubmitStatus()
            })
        }
        function onCancel () {
            router.go(-1)
        }
        function setGeneralAgencyOptions(options) {
            state.generalAgencyOptions = options
        }

        // Lifecycle hooks
        onBeforeMount(async () => {
            fetchContracts()
            await fetchObject()
            await setupForm()
            composeInitialData()
            state.isLoaded = true
        })

        return {
            // State
            state,

            // Template refs
            formComponent,

            // Computed
            submissionUpdatesExistingContract,
            disableGeneralAgencyId,
            productProviderName,

            // Methods
            onSubmit,
            onCancel,
            setGeneralAgencyOptions,
        }
    },
}
</script>

<style lang="scss" scoped>
::v-deep .basic-information {

    .q-checkbox__inner--falsy .q-checkbox__bg,
    .q-field__control {
        border: 1px solid var(--color-border-secondary);
        background-color: var(--color-background-primary);
    }
}
</style>
