import { Model } from '@/models'
import { CommissionListService } from '@/services'
import { CommissionListActions } from '@/enums'
import { CommissionListStatus } from '@/enums/graphql'
import { formatDate } from '@/helpers/date'

export class CommissionList extends Model {
    /**
     * Create a CommissionList model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'productProvider',
            'source',
            'type',
            'status',
            'file',
            'filename',
            'excelMapping',
            'file_import_identification_number',
            'exchange_date_from',
            'exchange_date_to',
            'commission_total',
            'total_amount_of_imported_commission',
            'total_amount_of_imported_commission_grouped_by_commission_type',
            'total_number_of_commission_entries',
            'total_number_of_imported_commission_entries',
            'total_number_of_commission_list_import_errors',
            'posted_at',
        ], data)

        this.statusTransitions[CommissionListStatus.PENDING] = [CommissionListStatus.IMPORTING]
        this.statusTransitions[CommissionListStatus.IMPORTING] = [CommissionListStatus.CANCELED]
        this.statusTransitions[CommissionListStatus.PROCESSING] = [CommissionListStatus.CANCELED]

        this.statusActionMapping[CommissionListStatus.IMPORTING] = CommissionListActions.START_PROCESSING
        this.statusActionMapping[CommissionListStatus.CANCELED] = CommissionListActions.CANCEL_PROCESSING
    }

    static get service () { return CommissionListService }
    static status = CommissionListStatus
    static action = CommissionListActions

    get actions () {
        const actions = super.actions
        actions.push({
            key: CommissionListActions.DELETE,
            item: this,
        })
        return actions
    }

    get formattedExchangeDateFrom () {
        return formatDate(this.exchange_date_from)
    }

    get formattedExchangeDateTo () {
        return formatDate(this.exchange_date_to)
    }

    /**
     * Create a new CommissionList.
     * @param {CommissionListType} type
     * @param {Upload} file
     * @param {CommissionListInput} commissionList
     * @param {boolean} triggerImport
     * @returns {Promise<*>}
     */
    static create ({ type, file, commissionList, triggerImport }) {
        const variables = {
            type,
            file,
            commissionList,
            triggerImport,
        }

        return CommissionListService.create(variables)
    }

    /**
     * Update this CommissionList.
     * @param {CommissionListInput} commissionList
     * @returns {Promise<*>}
     */
    update (commissionList) {
        return CommissionListService.update(this.id, commissionList).then(commissionList => {
            Object.assign(this, commissionList)
            return commissionList
        })
    }

    /**
     * Delete this CommissionList.
     * @returns {Promise<*>}
     */
    delete () {
        return CommissionListService.delete(this.id)
    }

    /**
     * Start processing this CommissionList.
     * @returns {Promise<*>}
     */
    startProcessing () {
        return CommissionListService.startProcessing(this.id).then(commissionList => {
            Object.assign(this, commissionList)
            return commissionList
        })
    }

    /**
     * Cancel processing this CommissionList.
     * @returns {Promise<*>}
     */
    cancelProcessing () {
        return CommissionListService.cancelProcessing(this.id).then(commissionList => {
            Object.assign(this, commissionList)
            return commissionList
        })
    }

    /*
     * Start posting selected CommissionList entries.
     * @param {Array} ids
     * @returns {Promise<*>}
     */
    startPosting (ids) {
        const variables = {
            id: this.id,
            commissionListEntryIds: ids,
            startAll: false,
        }

        return CommissionListService.startPosting(variables)
    }

    /**
     * Cancel posting CommissionList.
     * @returns {Promise<*>}
     */
    cancelPosting () {
        const variables = {
            id: this.id,
            stopAll: false,
        }

        return CommissionListService.cancelPosting(variables)
    }
}
