import { Form, FormField } from '@/libs/form'
import { CustomerStatus } from '@/enums/graphql'
import { kebabCase } from 'lodash'

export class ContactForm extends Form {
    compose () {
        const customerStatusOptions = [
            { label: 'common.term.please-choose', value: null, disable: true },
            ...Object.values(CustomerStatus).map(value => ({ label: `common.contact.customer-status.${kebabCase(value.toLowerCase())}`, value: value })),
        ]
        this.addField(new FormField('customerStatus', {
            label: 'common.contact.customer-status.title',
            inputType: 'select',
            extra_attributes: {
                list: {
                    options: customerStatusOptions,
                },
            },
        }))

        this.addField(new FormField('website', {
            label: 'common.term.website',
            inputType: 'text',
            attributes: {
                hint: 'common.term.website-hint',
            },
            validators: [
                { type: 'url' },
            ],
        }))

        this.addField(new FormField('customerSince', {
            label: 'common.contact.customer-since',
            inputType: 'calendar',
            validators: [
                { type: 'date' },
            ],
        }))

        this.addField(new FormField('customerUntil', {
            label: 'common.contact.customer-until',
            inputType: 'calendar',
            validators: [
                { type: 'date' },
            ],
        }))

        this.addField(new FormField('consultants', {
            label: {
                key: 'common.contact.consultant',
                count: 2,
            },
            inputType: 'consultants',
            validators: [
                { type: 'required' },
            ],
            attributes: {
                multiple: true,
            },
        }))

        this.addField(new FormField('correspondenceLanguageId', {
            label: 'common.contact.correspondence-language',
            inputType: 'correspondenceLanguage',
            validators: [
                { type: 'required' },
            ],
        }))
    }
}
