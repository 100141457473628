import gql from 'graphql-tag'

export const POSTING_TYPE_UPDATE_MUTATION = gql`
    mutation postingTypeUpdate(
        $id: ID!
        $postingType: PostingTypeInput!
    ) {
        postingTypeUpdate(
            id: $id
            postingType: $postingType
        ) {
            id
            key
            name
            name_language_versions
            created_at
            updated_at
        }
    }
`
