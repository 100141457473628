<template>
    <div class="display-contents">
        <base-badge
            v-if="contact.consultingSettings && contact.consultingSettings.status === ConsultingSettingsStatus.ACTIVE"
            :status="(contact.type === ContactType.COMPANY) ? 'consulting-company' : 'consultant'"
            :align="align"
            :class="(contact.type === ContactType.COMPANY) ? 'status-consulting-company' : 'status-consultant'"
            data-test="text:badge-contact-type"
        >
            <template v-if="contact.type === ContactType.COMPANY">{{ $tc('common.status.consulting-company', 1) }}</template>
            <template v-if="contact.type === ContactType.PERSON">
                <template v-if="contact.gender && !contact.personal_pronoun">{{ $tc('common.status.consultant--' + contact.gender.toLowerCase(), 1) }}</template>
                <template v-else>{{ $tc('common.status.consultant', 1) }}</template>
            </template>
        </base-badge>

        <base-badge
            v-if="contact.productProviderSettings && contact.productProviderSettings.status === ProductProviderSettingsStatus.ACTIVE"
            v-bind="$attrs"
            status="product-provider"
            :align="align"
            class="status-product-provider"
            data-test="text:badge-product-provider"
        >
            <template v-if="contact.type === ContactType.COMPANY">{{ $tc('common.status.product-provider', 1) }}</template>
            <template v-if="contact.type === ContactType.PERSON">
                <template v-if="contact.gender && !contact.personal_pronoun">{{ $tc('common.status.product-provider--' + contact.gender.toLowerCase(), 1) }}</template>
                <template v-else>{{ $tc('common.status.product-provider', 1) }}</template>
            </template>
        </base-badge>

        <base-badge
            v-if="contact.is_tenant_company && showSystemOwner"
            v-bind="$attrs"
            status="system-owner"
            :align="align"
            class="status-system-owner"
            data-test="text:badge-system-owner"
        >
            {{ $tc('common.term.system-owner', 1) }}
        </base-badge>
    </div>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { ConsultingSettingsStatus, ProductProviderSettingsStatus, ContactType } from '@/enums/graphql'

import BaseBadge from '@/components/BaseBadge'

export default {
    name: 'ContactTypeBadges',
    components: {
        BaseBadge,
    },
    inheritAttrs: false,
    props: {
        contact: {
            type: Object,
            required: true,
        },
        align: {
            type: String,
            default: 'middle',
        },
        showSystemOwner: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            ConsultingSettingsStatus,
            ProductProviderSettingsStatus,
            ContactType,
        }
    },
    methods: {
        toKebabCase: function (text) {
            return kebabCase(text)
        },
    },
}
</script>
