<template>
    <base-input
        :value="displayValue"
        v-bind="$attrs"
        @change="updateValue"
    >
        <slot slot="error" name="error">&nbsp;</slot><!-- &nbsp; needs to be there in order to make this work for cases without error messages. Needs to be re-worked with Vue 3 -->
        <template v-slot:append>
            <q-icon name="mib-calendar-3" class="cursor-pointer">
                <q-popup-proxy ref="proxy" transition-show="scale" transition-hide="scale">
                    <q-date
                        :value="value"
                        :title="title"
                        mask="YYYY-MM-DD"
                        first-day-of-week="1"
                        today-btn
                        @input="updateValueAndHide"
                    />
                </q-popup-proxy>
            </q-icon>
        </template>
    </base-input>
</template>

<script>
import { parse, lightFormat, format, parseISO } from 'date-fns'
import { isValidDate, isValidDateInstance, isValidIsoDate } from '@/helpers/form'
import { formatDate, getCurrentDateFnsLocale, guessCentury } from '@/helpers/date'
import BaseInput from '@/components/form/BaseInput.vue'

export default {
    name: 'CalendarInput',
    components: {
        BaseInput,
    },
    inheritAttrs: false,
    props: {
        value: {
            type: String,
            required: true,
        },
    },
    computed: {
        displayValue () {
            if (this.value) {
                if (isValidIsoDate(this.value)) {
                    return formatDate(this.value)
                }
            }
            return this.value
        },
        title () {
            if (this.value) {
                if (isValidIsoDate(this.value)) {
                    return format(parseISO(this.value), 'dd. MMMM', { locale: getCurrentDateFnsLocale() })
                } else {
                    return '---'
                }
            } else {
                return '---'
            }
        },
    },
    methods: {
        updateValue (value) {
            if (value) {
                if (isValidDate(value)) {
                    const dateParts = value.split('.')
                    const year = dateParts[2]
                    if (year.length < 4) {
                        const options = {}
                        const twentiethCenturyWeightFactor = parseFloat(this.$attrs.twentiethCenturyWeightFactor)
                        const twentyFirstCenturyWeightFactor = parseFloat(this.$attrs.twentyFirstCenturyWeightFactor)
                        if (twentiethCenturyWeightFactor) options.twentiethCenturyWeightFactor = twentiethCenturyWeightFactor
                        if (twentyFirstCenturyWeightFactor) options.twentyFirstCenturyWeightFactor = twentyFirstCenturyWeightFactor
                        dateParts[2] = guessCentury(parseInt(year), options)
                    }
                    this.$emit('input', lightFormat(parse(dateParts.join('.'), 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd'))
                    return
                } else {
                    const currentDate = new Date()
                    const dateInstance = parse(value, 'dd.MM.', currentDate)
                    if (isValidDateInstance(dateInstance)) {
                        dateInstance.setFullYear(currentDate.getFullYear())
                        this.$emit('input', lightFormat(dateInstance, 'yyyy-MM-dd'))
                        return
                    }
                }
            }
            this.$emit('input', value)
        },
        updateValueAndHide (value) {
            this.$emit('input', value)
            this.$refs.proxy.hide()
        },
    },
}
</script>
