<template>
    <form-builder-next
        v-slot="{ formBuilder }"
        :form="MailProcessingTemplateForm"
        :initial-data="initialData"
        in-page-footer
        :submit-button-label="mailProcessingTemplate ? $t('common.term.save') : $tc('common.mail-processing.add-mail-processing-template', 1)"
        @cancel="$emit('cancel')"
        @submit="formData => $emit('submit', formData)"
    >
        <div class="row q-col-gutter-md">
            <div class="col-xs-12 col-sm-6 col-lg-6">
                <form-builder-next :item="formBuilder.form.getItem('name')" autofocus />
            </div>
            <div class="col-xs-12 col-sm-6 col-lg-6">
                <form-builder-next :item="formBuilder.form.getItem('fileName')" />
            </div>
            <div class="col-xs-12">
                <form-builder-next :item="formBuilder.form.getItem('description')" />
            </div>

            <div class="col-xs-12 col-sm-6 col-lg-6">
                <form-builder-next :item="formBuilder.form.getItem('mailProcessingActiveDocumentTemplate')" />
            </div>
            <div class="col-xs-12 col-sm-6 col-lg-6">
                <form-builder-next :item="formBuilder.form.getItem('mailProcessingInactiveDocumentTemplate')" />
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4">
                <form-builder-next :item="formBuilder.form.getItem('targetObjectType')" />
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4">
                <form-builder-next
                    v-if="formBuilder.form.getItem('targetObjectType').value"
                    :item="formBuilder.form.getItem('consultingFileType')"
                    :target-object-type="formBuilder.form.getItem('targetObjectType').value"
                />
                <div v-else>
                    <fake-input-field
                        :label="$tc('common.consulting-file.consulting-file-type', 1)"
                        required
                        disabled
                    >
                        {{ $t('views.correspondence.mail-processing.please-select-target-object') }}
                    </fake-input-field>
                </div>
            </div>
            <div class="col-xs-12 col-md-4">
                <form-builder-next :item="formBuilder.form.getItem('billable')" />
            </div>
        </div>
    </form-builder-next>
</template>

<script>
import { ref } from 'vue'
import { MailProcessingTemplateForm } from '@/forms/mailProcessingTemplate'
import FakeInputField from '@/components/FakeInputField'

export default {
    name: 'MailProcessingTemplateForm',
    components: {
        FakeInputField,
    },
    props: {
        mailProcessingTemplate: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
    setup (props) {
        const formComponent = ref(null)

        let initialData = null
        if (props.mailProcessingTemplate) {
            initialData = {
                name: props.mailProcessingTemplate.name,
                description: props.mailProcessingTemplate.description,
                fileName: props.mailProcessingTemplate.file_name,
                mailProcessingActiveDocumentTemplate: props.mailProcessingTemplate.mailProcessingActiveDocumentTemplate.id,
                mailProcessingInactiveDocumentTemplate: props.mailProcessingTemplate.mailProcessingInactiveDocumentTemplate.id,
                targetObjectType: props.mailProcessingTemplate.target_object_type,
                consultingFileType: props.mailProcessingTemplate.consultingFileType.id,
                billable: props.mailProcessingTemplate.billable,
            }
        }

        return {
            formComponent,
            MailProcessingTemplateForm,
            initialData,
        }
    },
}
</script>
