<template>
    <page-loading-indicator v-if="!state.productDerivedEntity" :number-of-breadcrumb-elements="4" />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="state.productDerivedEntity.customer.getContactName({ contactNumber: true })" :to="{ name: 'contact-detail', params: { id: contactId } }" />
                <q-breadcrumbs-el :label="$tc('common.term.contract', 2)" :to="{ name: 'contact-detail', params: { id: contactId } }" />
                <q-breadcrumbs-el :label="state.productDerivedEntity.currentContractNumber" :to="{ name: 'contract-detail', params: { id: state.productDerivedEntity.id } }">
                    <status-badge v-if="state.productDerivedEntity.currentCancellation" :status="`cancellation-${state.productDerivedEntity.currentCancellation.status}`" />
                    <status-badge v-else :status="state.productDerivedEntity.status" />
                    <status-badge v-if="state.productDerivedEntity.currentContractInformation.productFields.suspended && state.productDerivedEntity.currentContractInformation.productFields.suspended.value" status="suspended" />
                </q-breadcrumbs-el>
                <q-breadcrumbs-el :label="$t('common.contract.edit-contract-info')" />
            </template>

            <h1>{{ $t('common.contract.edit-contract-info') }}</h1>
        </page-header>

        <product-derived-entity-form
            ref="productDerivedEntityForm"
            :form-class="ContractForm"
            :product="state.productDerivedEntity.currentContractInformation.product"
            :product-derived-entity="state.productDerivedEntity"
            @submit="updateProductDerivedEntity"
        />
    </page-wrapper>
</template>

<script>
import StatusBadge from '@/components/StatusBadge.vue'
import ProductDerivedEntityForm from '@/components/contract/ProductDerivedEntityForm'
import { useRouter } from '@/composables/router'
import { onBeforeMount, reactive, ref } from 'vue'
import { Contract } from '@/models/contract'
import { ContractForm } from '@/forms/contract'

export default {
    name: 'ContractUpdate',
    meta () {
        return {
            title: !this.state.productDerivedEntity ? `${this.$t('common.term.loading')} …` : `${this.state.productDerivedEntity.currentContractNumber} (${this.state.productDerivedEntity.currentContractInformation.product.providerName}) – ${this.$t('common.contract.edit-contract-info')}`,
        }
    },
    components: {
        StatusBadge,
        ProductDerivedEntityForm,
    },
    props: {
        contactId: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
    },
    setup (props) {
        // Composables
        const router = useRouter()

        // Data
        const state = reactive({
            productDerivedEntity: null,
        })

        // Template refs
        const productDerivedEntityForm = ref(null)

        // Methods
        async function fetchObject () {
            state.productDerivedEntity = await Contract.objects.get(props.id)
        }
        function updateProductDerivedEntity (formData) {
            state.productDerivedEntity.update(formData).then(productDerivedEntity => {
                router.replace({
                    name: 'contract-detail',
                    params: { contactId: props.contactId, id: productDerivedEntity.id },
                })
            }).catch(error => {
                productDerivedEntityForm.value.formComponent.formBuilder.showFormErrorMessage(error)
                productDerivedEntityForm.value.formComponent.formBuilder.resetFormSubmitStatus()
            })
        }

        // Lifecycle hooks
        onBeforeMount(() => {
            fetchObject()
        })

        return {
            // Static
            ContractForm,

            // Data
            state,

            // Template refs
            productDerivedEntityForm,

            // Methods
            updateProductDerivedEntity,
        }
    },
}
</script>
