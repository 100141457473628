import gql from 'graphql-tag'

export const PRODUCT_TEMPLATES_QUERY = gql`
    query productTemplates(
        $page: Int = 1
        $count: Int = 100
        $orderField: ProductTemplateOrderField = PRODUCT_TEMPLATE_NAME
        $orderDirection: OrderDirection = ASC
        $filterQuery: String
        $filterStatus: [ProductTemplateStatus!] = [ACTIVE]
    ) {
        productTemplates(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                product_template_name: $filterQuery
                product_template_status: $filterStatus
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                name
                status
                category {
                    id
                    name
                    key
                }
                subCategory {
                    id
                    name
                    key
                }
                version
            }
        }
    }
`
