import gql from 'graphql-tag'

export const CONTACT_FILE_CREATE_MUTATION = gql`
    mutation contactFileCreate(
        $contactId: ID!
        $typeId: ID!
        $consultantId: ID!
        $file: Upload!
    ) {
        contactFileCreate(
            contact_id: $contactId
            type_id: $typeId
            consultant_id: $consultantId
            file: $file
        ) {
            id
            file {
                id
                name
            }
            type {
                id
                status
                key
                name
            }
            consultant {
                id
                contact_number
                first_name
                last_name
                gender
                title
                consulting_company_name
                consulting_settings_status
            }
        }
    }
`
