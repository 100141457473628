import { apolloClient } from '@/apollo/client'

import { TASK_QUERY } from '@/graphql/task/task'
import { TASKS_QUERY } from '@/graphql/task/tasks'
import { TASK_PANEL_QUERY } from '@/graphql/task/taskPanel'
import { TASK_CREATE_MUTATION } from '@/graphql/task/taskCreate'
import { TASK_UPDATE_MUTATION } from '@/graphql/task/taskUpdate'
import { TASK_CHANGE_STATUS_MUTATION } from '@/graphql/task/taskChangeStatus'
import { TASK_DELETE_MUTATION } from '@/graphql/task/taskDelete'
import { TASK_ADD_ATTACHMENT_MUTATION } from '@/graphql/task/taskAddAttachment'
import { TASK_DELETE_ATTACHMENT_MUTATION } from '@/graphql/task/taskDeleteAttachment'

export const TaskService = {
    get (id) {
        return apolloClient
            .query({
                query: TASK_QUERY,
                variables: {
                    id: id,
                },
            })
            .then((response) => response.data.task)
    },
    all (variables) {
        return apolloClient
            .query({
                query: TASKS_QUERY,
                variables,
            })
            .then((response) => response.data.tasks)
    },
    panel (variables) {
        return apolloClient
            .query({
                query: TASK_PANEL_QUERY,
                variables,
            })
            .then((response) => response.data)
    },
    create (variables) {
        return apolloClient
            .mutate({
                mutation: TASK_CREATE_MUTATION,
                variables,
            })
            .then((response) => response.data.taskCreate)
    },
    update (id, taskInput) {
        return apolloClient
            .mutate({
                mutation: TASK_UPDATE_MUTATION,
                variables: {
                    id: id,
                    taskInput: taskInput,
                },
            })
            .then((response) => response.data.taskUpdate)
    },
    changeStatus (id, status) {
        return apolloClient
            .mutate({
                mutation: TASK_CHANGE_STATUS_MUTATION,
                variables: {
                    id: id,
                    status: status,
                },
            })
            .then((response) => response.data.taskChangeStatus)
    },
    delete (id) {
        return apolloClient
            .mutate({
                mutation: TASK_DELETE_MUTATION,
                variables: {
                    id: id,
                },
            })
            .then((response) => response.data.taskDelete)
    },
    addAttachment (taskId, file, abortController) {
        return apolloClient.mutate({
            mutation: TASK_ADD_ATTACHMENT_MUTATION,
            variables: {
                task_id: taskId,
                file: file,
            },
            context: {
                fetchOptions: {
                    signal: abortController.signal,
                },
            },
        }).then(response => response.data.taskAddAttachment)
    },
    deleteAttachment (taskId, fileId) {
        return apolloClient.mutate({
            mutation: TASK_DELETE_ATTACHMENT_MUTATION,
            variables: {
                taskId,
                fileId,
            },
        }).then(response => response.data.correspondenceEmailDeleteAttachment)
    },
}
