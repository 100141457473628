<template>
    <q-dialog v-model="show" @hide="$emit('hide')">
        <q-card class="add-form-elements-wrapper" square flat>
            <h2>{{ $tc('forms.add-item', 1) }}</h2>

            <div class="form-element-type-wrapper">
                <b>{{ $tc('common.term.structure', 1) }}</b>
                <q-list dense>
                    <q-item v-ripple clickable class="form-element-type-item form-element-type-section">
                        <q-item-section @click="onStructureItemSelect()">{{ $tc('common.term.section', 1) }}</q-item-section>
                    </q-item>
                </q-list>
            </div>

            <div class="form-element-type-wrapper q-mt-md">
                <b class="q-mt-xl">{{ $tc('common.term.field', 2) }}</b>
                <base-input ref="filter" v-model="filter" :label="$tc('common.term.filter', 1)">
                    <template v-slot:append>
                        <q-icon v-if="filter !== ''" name="mib-close" @click="resetFilter" />
                    </template>
                </base-input>

                <q-tree
                    ref="productFieldsTree"
                    :nodes="productFieldGroups"
                    node-key="key"
                    :filter="filter"
                >
                    <template v-slot:default-header="prop">
                        <!-- TODO: Check if we should handle this with checkboxes so that it’s possible to add multiple items at once. -->
                        <!-- TODO: Make it accessible with keyboard again (only after we decided about the checkboxes above). -->
                        <div v-if="prop.node.selectable !== false" class="form-element-type-field" @click="onProductFieldItemSelect(prop.node)">{{ prop.node.label }}</div>
                        <div v-else class="form-element-type-field">{{ prop.node.label }}</div>
                    </template>
                </q-tree>
            </div>
        </q-card>
    </q-dialog>
</template>

<script>
import BaseInput from '@/components/form/BaseInput'

export default {
    name: 'ProductTemplateBuilderOverlay',
    components: { BaseInput },
    props: {
        productFieldGroups: {
            type: Array,
            required: true,
        },
    },
    data () {
        return {
            show: true,
            filter: '',
        }
    },
    methods: {
        resetFilter () {
            this.filter = ''
        },
        onStructureItemSelect () {
            const section = {
                key: window.crypto.getRandomValues(new Uint32Array(1))[0],
                type: 'section',
                is_collapsible: false,
                label: {
                    'de-CH': '',
                    'en': '',
                },
                children: [],
                // Meta properties used for UI
                removeFlag: false,
                newlyInserted: true,
            }

            this.$emit('addStructureItem', section)
            this.show = false
        },
        onProductFieldItemSelect (node) {
            const productField = JSON.parse(JSON.stringify(node))
            productField.type = 'field'
            productField.required = false
            delete productField.selectable
            // Meta properties used for UI
            productField.removeFlag = false
            productField.newlyInserted = true

            this.$emit('addProductFieldItem', productField)
            this.show = false
        },
    },
}
</script>

<style lang="scss" scoped>
.add-form-elements-wrapper {
    min-width: pxToRem(300);
    width: 20vw;
    max-width: pxToRem(600);
    padding: pxToRem(20);
}

// Form element types
.form-element-type-section {
    padding-top: 2px !important; // Optically center
    border: 1px solid var(--q-color-accent-light);
}

// Nodes
::v-deep .q-tree__node-header {
    cursor: pointer;

    // Selected nodes
    &.q-tree__node--disabled {
        opacity: 0.4;

        .q-tree__node-header-content::after {
            content: "✓︎";
            margin-left: 0.2rem;
        }
    }
}
</style>
