import { Policy } from '@/acl'

export class ContactPolicy extends Policy {
    constructor () {
        super()
        this.create = true
    }

    beforeAll (verb, user, subject) {
        const verdict = super.beforeAll(verb, user, subject)
        if (verdict === false) return false
        if (!user.consultant) return false
    }

    'read:own' (user, contact) {
        return contact.consultants.some(consultant => consultant.id === user.consultant.id)
    }

    'update:own' (user, contact) {
        return contact.consultants.some(consultant => consultant.id === user.consultant.id)
    }

    'delete:own' (user, contact) {
        return contact.consultants.some(consultant => consultant.id === user.consultant.id)
    }

    'read:assigned-consultant-works-for-same-consulting-company' (user, contact) {
        return true // TODO: Implement
    }

    'update:assigned-consultant-works-for-same-consulting-company' (user, contact) {
        return true // TODO: Implement
    }

    'delete:assigned-consultant-works-for-same-consulting-company' (user, contact) {
        return true // TODO: Implement
    }

    'read:assigned-consultant-works-for-subordinate-consulting-company' (user, contact) {
        return true // TODO: Implement
    }

    'update:assigned-consultant-works-for-subordinate-consulting-company' (user, contact) {
        return true // TODO: Implement
    }

    'delete:assigned-consultant-works-for-subordinate-consulting-company' (user, contact) {
        return true // TODO: Implement
    }
}

export class ContactContactNumberPolicy extends Policy {
    constructor () {
        super()
        this.update = true
    }
}

export class ContactConsultantsPolicy extends Policy {
    constructor () {
        super()
        this['assign:all'] = true
    }
}
