<template>
    <div class="bulk-item-status-indicator" :class="`status-${status.toLowerCase()}`">
        <inline-svg :src="require(`@/assets/img/icons/max-icons-bold/${statusIcon}.svg`)" />
        <q-tooltip
            :delay="1000"
            anchor="center left"
            self="center right"
            :offset="[10, 0]"
            transition-show="scale"
            transition-hide="scale"
        >
            {{ $tc(`${statusTranslationBasePath}.${status.toLowerCase()}--description`, 1) }}
        </q-tooltip>
    </div>
</template>

<script>
import { BulkItemWrapperStatus } from '@/enums'

export default {
    name: 'BulkItemStatusIndicator',
    props: {
        status: {
            type: String,
            required: true,
        },
        baseIcon: {
            type: String,
            required: true, // e.g. 'cloud'
        },
        statusTranslationBasePath: {
            type: String,
            required: true, // e.g. 'common.file.status'
        },
        iconQueued: {
            type: String,
        },
        iconProcessing: {
            type: String,
        },
        iconDone: {
            type: String,
        },
        iconFailed: {
            type: String,
        },
        iconAborted: {
            type: String,
        },
    },
    computed: {
        statusIcon () {
            switch (this.status) {
                case BulkItemWrapperStatus.QUEUED:
                    return this.iconQueued || this.baseIcon + '-clock'
                case BulkItemWrapperStatus.PROCESSING:
                    return this.iconProcessing || this.baseIcon + '-sync'
                case BulkItemWrapperStatus.DONE:
                    return this.iconDone || this.baseIcon + '-check'
                case BulkItemWrapperStatus.FAILED:
                    return this.iconFailed || this.baseIcon + '-warning'
                case BulkItemWrapperStatus.ABORTED:
                    return this.iconAborted || this.baseIcon + '-disable'
                default:
                    return this.iconQueued || this.baseIcon + '-clock' // Fallback only
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.bulk-item-status-indicator {
    display: inline-block;
    padding-top: 3px;
    width: $sizeSpacingLg;

    cursor: help;

    svg {
        width: $sizeSpacingMd;

        ::v-deep path {
            fill: var(--color-text-secondary);
        }
    }
}

.status {
    &-queued {
    }

    &-processing {

        ::v-deep .inner-icon {
            @include rotate();
            fill: var(--q-color-accent);
        }
    }

    &-done {

        ::v-deep .inner-icon {
            fill: var(--q-color-positive);
        }
    }

    &-failed {

        ::v-deep .inner-icon {
            fill: var(--q-color-negative);
        }
    }

    &-aborted {

        ::v-deep .inner-icon {
            fill: var(--q-color-negative);
        }
    }
}
</style>
