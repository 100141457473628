import { Model } from '@/models'

import { i18n } from '@/i18n'
import kebabCase from 'lodash.kebabcase'
import { AccountService } from '@/services'
import { AccountActions } from '@/enums'
import { AccountStatus, AccountAssignedAsType, AccountAssignedToType, ContactType } from '@/enums/graphql'
import { formatCurrency } from '@/helpers/number'

export class Account extends Model {
    /**
     * Create a Account model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'type',
            'status',
            'number',
            'name',
            'description',
            'balance',
            'has_postings',
            'is_assigned',
            'assignedTo',
            'assigned_as_type',
            'activityLogs',
        ], data)

        this.statusTransitions[AccountStatus.ACTIVE] = [AccountStatus.INACTIVE]
        this.statusTransitions[AccountStatus.INACTIVE] = [AccountStatus.ACTIVE]

        this.statusActionMapping[AccountStatus.ACTIVE] = AccountActions.ACTIVATE
        this.statusActionMapping[AccountStatus.INACTIVE] = AccountActions.DEACTIVATE
    }

    static get service () { return AccountService }
    static status = AccountStatus
    static action = AccountActions

    get formattedBalance () {
        return formatCurrency(this.balance)
    }

    get assignedToType () {
        if (this.is_assigned) {
            switch (this.assigned_as_type) {
                case AccountAssignedAsType.CONSULTING_SETTINGS_CURRENT_ACCOUNT:
                case AccountAssignedAsType.CONSULTING_SETTINGS_CANCELLATION_RESERVE_ACCOUNT:
                case AccountAssignedAsType.CONSULTING_SETTINGS_OTHER_INCOME_ACCOUNT:
                case AccountAssignedAsType.CONSULTING_SETTINGS_COMMISSION_INCOME_ACCOUNT:
                    switch (this.assignedTo.type) {
                        case ContactType.PERSON:
                            return AccountAssignedToType.PERSON
                        case ContactType.COMPANY:
                            return AccountAssignedToType.COMPANY
                    }
                    break
                case AccountAssignedAsType.COMMISSION_TYPE_ACCOUNTING_CONFIGURATION_COMMISSION_INCOME_ACCOUNT:
                case AccountAssignedAsType.COMMISSION_TYPE_ACCOUNTING_CONFIGURATION_COMMISSION_EXPENSE_ACCOUNT:
                    return AccountAssignedToType.COMMISSION_TYPE_ACCOUNTING_CONFIGURATION
            }
        }

        return null
    }

    get formattedAssignedTo () {
        if (this.assignedTo?.type && [AccountAssignedToType.PERSON, AccountAssignedToType.COMPANY].includes(this.assignedTo.type)) {
            return this.assignedTo.getContactName()
        }
        return '–'
    }

    get formattedAssignedAs () {
        if (this.assigned_as_type) {
            return i18n.t(`common.accounting.account.type.${kebabCase(this.assigned_as_type.replace(/CONSULTING_SETTINGS_|COMMISSION_TYPE_ACCOUNTING_CONFIGURATION_/gi, ''))}`)
        }
        return '–'
    }

    get actions () {
        const actions = super.actions
        actions.push({
            key: AccountActions.DELETE,
            item: this,
        })
        return actions
    }

    /**
     * Create a new account.
     * @param {AccountInput} account
     * @returns {Promise<*>}
     */
    static create (account) {
        const variables = { account }
        return AccountService.create(variables)
    }

    /**
     * Update this account.
     * @param {AccountInput} account
     * @returns {Promise<*>}
     */
    update (account) {
        return AccountService.update(this.id, account).then(account => {
            Object.assign(this, account)
            return account
        })
    }

    /**
     * Change this account status.
     * @param {AccountStatus} status
     * @returns {Promise<*>}
     */
    changeStatus (status) {
        return AccountService.changeStatus(this.id, status).then(account => {
            Object.assign(this, account)
            return account
        })
    }

    /**
     * Delete this account.
     * @returns {Promise<*>}
     */
    delete () {
        return AccountService.delete(this.id)
    }

    activate () {
        return this.changeStatus(AccountStatus.ACTIVE)
    }

    deactivate () {
        return this.changeStatus(AccountStatus.INACTIVE)
    }
}
