<template>
    <q-btn
        v-if="showButton"
        color="primary"
        size="sm"
        flat
        round
        icon="mib-single-neutral-actions-view"
    >
        <q-menu>
            <q-list separator>
                <q-item
                    v-for="relationship in state.relationships"
                    :key="relationship.id"
                    v-close-popup
                    clickable
                    @click="() => { handleRelationshipSelect(relationship) }"
                >
                    <q-item-section>
                        <q-item-label>{{ formatRelationship(relationship) }}</q-item-label>
                    </q-item-section>
                </q-item>
            </q-list>
        </q-menu>
    </q-btn>
</template>

<script>
import { computed, onBeforeMount, reactive } from 'vue'
import { ContactRelationshipService } from '@/services'

export default {
    name: 'ContactRelationshipMenu',
    props: {
        nodeId: {
            type: String,
            required: true,
        },
        filter: {
            type: Object,
            default: () => null,
        },
        formatRelationship: {
            type: Function,
            default: relationship => relationship.getRelationshipFullText(),
        },
        hideIfEmpty: {
            type: Boolean,
            default: false,
        },
    },
    setup (props, context) {
        // State
        const state = reactive({
            relationships: [],
        })

        // Computed
        const variables = computed(() => {
            return {
                search: Object.assign({ node_id: props.nodeId }, props.filter),
            }
        })
        const showButton = computed(() => {
            return props.hideIfEmpty ? !!state.relationships.length : true
        })

        // Functions
        async function fetchObjects () {
            const response = await ContactRelationshipService.all(variables.value)
            state.relationships = response.data
        }
        function handleRelationshipSelect (relationship) {
            context.emit('select', relationship)
        }

        // Watchers
        // TODO improvement @TFU: Re-fetch if nodeId or filter changes.

        // Lifecycle hooks
        onBeforeMount(() => {
            fetchObjects()
        })

        return {
            state,
            showButton,
            handleRelationshipSelect,
        }
    },
}
</script>

<style scoped>

</style>
