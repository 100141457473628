import gql from 'graphql-tag'

export const FILES_PACKAGE_QUERY = gql`
    mutation filesPackage(
        $file_ids: [ID!]!
        $file_name_prefix: String
    ) {
        filesPackage(
            file_ids: $file_ids
            file_name_prefix: $file_name_prefix
        ) {
            id
            file {
                id
                name
                size
                mime_type
                created_at
                updated_at
            }
            user {
                id
                email
                new_email
                status
                created_at
                updated_at
            }
            expires_at
            created_at
            updated_at
        }
    }
`
