<template>
    <div class="display-contents">
        <base-table
            ref="table"
            :columns="columns"
            :fetch-objects-fn="contract.contractCommissionPostings"
            user-settings-base-path="ContractCommissionPostingsList"
            pagination-sort-by-default-key="date"
            pagination-initial-order-direction-descending
            :additional-filters="additionalFilters"
            no-top-padding
            @update:paginator-info="handleUpdatePaginatorInfo"
        >
            <template v-slot:tabs>
                <h2>{{ $tc('common.contract.posting.commission-posting', 2) }}</h2>
            </template>

            <!-- TODO improvement: This could potentially be refactored as it uses the same code as the BaseTable in `src/views/accounting/PostingList.vue` -->
            <template v-slot:body-cell-number="slotProps">
                <q-td
                    :props="slotProps"
                    class="text-right"
                    data-test="td:posting-number"
                    auto-width
                >
                    {{ slotProps.row.number.number }}
                </q-td>
            </template>

            <template v-slot:body-cell-debit_account_number="slotProps">
                <q-td :props="slotProps" class="truncate-text" data-test="td:debit-account-number">
                    <span>
                        <span class="account-number">{{ slotProps.row.debitAccount.number }}</span> <span class="account-name additional-info">– {{ slotProps.row.debitAccount.name }}</span>
                        <q-tooltip
                            :delay="1000"
                            anchor="center start"
                            self="center start"
                            :offset="[0, 0]"
                            max-width="70ch"
                        >{{ slotProps.row.debitAccount.number }} – {{ slotProps.row.debitAccount.name }}</q-tooltip>
                    </span>
                </q-td>
            </template>

            <template v-slot:body-cell-credit_account_number="slotProps">
                <q-td :props="slotProps" class="truncate-text" data-test="td:credit-account-number">
                    <span>
                        <span class="account-number">{{ slotProps.row.creditAccount.number }}</span> <span class="account-name additional-info">– {{ slotProps.row.creditAccount.name }}</span>
                        <q-tooltip
                            :delay="1000"
                            anchor="center start"
                            self="center start"
                            :offset="[0, 0]"
                            max-width="70ch"
                        >{{ slotProps.row.creditAccount.number }} – {{ slotProps.row.creditAccount.name }}</q-tooltip>
                    </span>
                </q-td>
            </template>

            <template v-slot:body-cell-amount="slotProps">
                <q-td :props="slotProps" data-test="td:amount">
                    <span :class="['amount', { 'text-negative-darker': slotProps.row.amount < 0 }]">{{ slotProps.row.formattedAmount }}</span>
                </q-td>
            </template>

            <template v-slot:body-cell-text="slotProps">
                <q-td :props="slotProps" class="truncate-text" data-test="td:posting-text">
                    <info-icon
                        v-if="slotProps.row.is_confidential"
                        name="mib-lock-1"
                        :text="$tc('common.accounting.posting.is-confidential--description', 1)"
                        class="q-mr-xs"
                        color="secondary"
                    />
                    <span>
                        {{ slotProps.row.text }}
                        <q-tooltip
                            :delay="1000"
                            anchor="center start"
                            self="center start"
                            :offset="[0, 0]"
                            max-width="70ch"
                        >{{ slotProps.row.text }}</q-tooltip>
                    </span>
                </q-td>
            </template>
        </base-table>
    </div>
</template>

<script>
import BaseTable from '@/components/BaseTable'

export default {
    name: 'ContractCommissionPostingsList',
    components: {
        BaseTable,
    },
    props: {
        contract: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            columns: [
                {
                    name: 'date',
                    label: this.$tc('common.contract.posting.date', 1),
                    field: row => row.formattedDate,
                    align: 'center',
                    sortable: true,
                },
                {
                    name: 'number',
                    label: this.$tc('common.contract.posting.number', 1),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'debit_account_number',
                    label: this.$tc('common.contract.posting.debit-account', 1),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'credit_account_number',
                    label: this.$tc('common.contract.posting.credit-account', 1),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'amount',
                    label: this.$tc('common.contract.posting.amount', 1),
                    align: 'right',
                    sortable: true,
                },
                {
                    name: 'text',
                    label: this.$tc('common.contract.posting.text', 1),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'type',
                    label: this.$tc('common.contract.posting.type', 1),
                    field: row => row.type.name,
                    align: 'left',
                    sortable: true,
                },
            ],
        }
    },
    computed: {
        additionalFilters () {
            return {
                contractId: this.contract.id,
            }
        },

    },
    methods: {
        handleUpdatePaginatorInfo (paginatorInfo) {
            this.$emit('numberOfCommissionPostings', paginatorInfo.total)
        },
    },
}
</script>

<style lang="scss" scoped>
.truncate-text {
    max-width: 40ch;
    overflow: hidden;
    text-overflow: ellipsis;
}

.account-number,
.amount {
    font-family: $fontFamilyMono; // TODO @MTR: Check if this makes sense (and is needed).
    font-weight: bold;
    font-variant-numeric: slashed-zero;
}
</style>
