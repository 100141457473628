<template>
    <page-loading-indicator v-if="!task" actions :number-of-next-actions="2" />
    <page-wrapper v-else>
        <template>
            <page-header>
                <template v-slot:breadcrumbs>
                    <q-breadcrumbs-el :label="task.subject" :to="{ name: 'task-detail', params: { id: task.id } }" data-test="breadcrumb:task-detail">
                        <status-badge :status="task.status" />
                    </q-breadcrumbs-el>
                </template>

                <h1 data-test="text:task-subject">{{ task.subject }}</h1>

                <template v-slot:actions>
                    <base-button
                        :label="$tc('common.task.edit-task', 1)"
                        class="next-action-button-lookalike"
                        flat
                        no-text-decoration
                        data-test="btn:task-edit"
                        @click="() => EventBus.$emit('taskFormDialog:open', { taskId: task.id })"
                    />

                    <q-btn-dropdown
                        :label="$tc(`common.status.${toKebabCase(task.status.toLowerCase())}`, 1)"
                        :class="`change-status-button status-button ${toKebabCase(task.status.toLowerCase())} q-ml-sm`"
                        unelevated
                        no-caps
                        :ripple="false"
                        :text-color="(task.status === TaskStatus.DONE) ? 'text-inverted' : 'text-secondary-dark'"
                        menu-anchor="bottom left"
                        menu-self="top left"
                        data-test="btn:dropdown-status"
                    >
                        <q-list>
                            <q-item
                                v-if="task.status !== TaskStatus.TODO"
                                v-close-popup
                                clickable
                                data-test="btn:task-status-todo"
                                @click="handleChangeStatus(TaskStatus.TODO)"
                            >
                                <q-item-section>
                                    <q-item-label>{{ $tc(`common.status.${toKebabCase(TaskStatus.TODO.toLowerCase())}`, 1) }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item
                                v-if="task.status !== TaskStatus.IN_PROGRESS"
                                v-close-popup
                                clickable
                                data-test="btn:task-status-in-progress"
                                @click="handleChangeStatus(TaskStatus.IN_PROGRESS)"
                            >
                                <q-item-section>
                                    <q-item-label>{{ $tc(`common.status.${toKebabCase(TaskStatus.IN_PROGRESS)}`, 1) }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-item
                                v-if="task.status !== TaskStatus.DONE"
                                v-close-popup
                                clickable
                                data-test="btn:task-status-done"
                                @click="handleChangeStatus(TaskStatus.DONE)"
                            >
                                <q-item-section>
                                    <q-item-label>{{ $tc(`common.status.${toKebabCase(TaskStatus.DONE.toLowerCase())}`, 1) }}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </q-btn-dropdown>

                    <actions
                        v-if="task.actions"
                        :actions="task.actions"
                        :number-of-next-best-actions="0"
                        @click="handleActions"
                    />

                    <!-- Delete Task -->
                    <form-dialog
                        ref="taskDeleteDialog"
                        double-confirm
                        dont-close-on-confirm
                        @confirm="handleTaskDelete"
                    >
                        <template v-slot:title>{{ $tc('common.actions.task.delete', 1) }}</template>
                        <template v-slot:default>
                            <i18n path="common.task.delete-task--confirm--temp-component-interpolation-count" tag="p">
                                <template v-slot:taskSubject><b>{{ task.subject }}</b></template>
                            </i18n>
                        </template>
                    </form-dialog>
                </template>
            </page-header>

            <div class="row q-col-gutter-md">
                <div class="col-xs-12 col-lg-10 col-xl-8">
                    <template v-if="task.status !== TaskStatus.DONE && ['UPCOMING', 'DUE-TODAY', 'OVERDUE'].includes(task.dueDateStatus)">
                        <info-box
                            v-if="task.dueDateDiffDays >= 0"
                            type="warning"
                            :icon="task.dueDateDiffDays > 0 ? 'mib-calendar-clock' : 'mib-calendar-information'"
                            data-test="text:task-message-warning"
                        >
                            {{ $tc('common.task.task-due-in-n-days', task.dueDateDiffDays) }}
                        </info-box>
                        <info-box
                            v-if="task.dueDateDiffDays < 0"
                            type="negative"
                            icon="mib-calendar-warning"
                            data-test="text:task-message-negative"
                        >
                            <template v-if="task.dueDateDiffDays > -15">{{ $tc('common.task.task-overdue-since-n-days', -task.dueDateDiffDays) }}</template>
                            <template v-else>{{ $tc('common.task.task-overdue-since-date', 1, { date: formatDate(task.due_date, { noLeadingZero: true }) }) }}</template>
                        </info-box>
                    </template>

                    <q-card flat bordered>
                        <div class="row task-meta q-pt-md q-pr-md q-pb-sm q-pl-md">
                            <div class="col-xs-12 col-md-8 col-lg-6">
                                <dl class="q-mb-none">
                                    <dt>{{ $t('common.term.created-by') }}: </dt>
                                    <dd data-test="text:task-creator">
                                        <template v-if="task.creator">
                                            <b>
                                                <!-- TODO improvement: Re-add `avatar` -->
                                                <contact-name :contact="task.creator" />
                                                <!-- eslint-disable-next-line vue/max-attributes-per-line -->
                                            </b> <contact-name :contact="task.creator" no-first-name no-last-name consulting-company-name class="font-weight-normal" />
                                        </template>
                                        <!-- TODO improvement @MTR: Add meaningful text/info if value is not set. -->
                                        <span v-else class="additional-info">–</span>
                                    </dd>
                                    <br>

                                    <dt>{{ $tc('common.task.assignee', 1) }}: </dt>
                                    <dd data-test="text:task-assignee">
                                        <template v-if="task.assignee">
                                            <b>
                                                <!-- TODO improvement: Re-add `avatar` -->
                                                <contact-name :contact="task.assignee" />
                                                <!-- eslint-disable-next-line vue/max-attributes-per-line -->
                                            </b> <contact-name :contact="task.assignee" no-first-name no-last-name consulting-company-name class="font-weight-normal" />
                                        </template>
                                        <!-- TODO improvement @MTR: Add meaningful text/info if value is not set. -->
                                        <span v-else class="additional-info">–</span>
                                    </dd>
                                    <br>

                                    <dt>{{ $tc('common.term.due-on', 1) }}: </dt>
                                    <dd data-test="text:task-due-date">
                                        <span v-if="task.due_date" :class="[{ 'text-negative-dark': task.displayStatus === TaskDueDateStatus.OVERDUE }]">{{ formatDate(task.due_date) }}</span>
                                        <!-- TODO improvement @MTR: Add meaningful text/info if value is not set. -->
                                        <span v-else class="additional-info">–</span>
                                    </dd>
                                </dl>
                            </div>
                            <div class="col-xs-12 col-md-4 col-lg-6">
                                <dl class="q-mb-none">
                                    <dt>{{ $t('common.term.visibility') }}: </dt>
                                    <dd data-test="text:task-visibility">
                                        {{ $tc(`common.task.visibility-${toKebabCase(task.visibility.toLowerCase())}`, 1) }}
                                        <info-icon :text="$tc(`common.task.visibility-${toKebabCase(task.visibility.toLowerCase())}--description`, 1)" />
                                    </dd>
                                    <br>

                                    <i18n
                                        v-if="task.target_object_type && task.targetObject"
                                        :path="`common.task.target-object--${toKebabCase(task.target_object_type.toLowerCase())}--link-text`"
                                        tag="p"
                                        class="additional-info"
                                        data-test="text:task-target"
                                    >
                                        <template v-slot:contactName>
                                            <template v-if="task.target_object_type === TaskTargetObjectType.CONTACT">
                                                <router-link :to="{ name: 'contact-detail', params: { id: task.target_object_id }}" data-test="link:task-target"> {{ task.targetObject.getContactName({ contactNumber: true }) }}</router-link>
                                            </template>
                                            <template v-if="task.target_object_type === TaskTargetObjectType.APPLICATION || task.target_object_type === TaskTargetObjectType.CONTRACT" data-test="link:task-target">{{ task.targetObject.customer.getContactName({ contactNumber: true }) }}</template>
                                        </template>
                                        <template v-if="task.target_object_type === TaskTargetObjectType.APPLICATION" v-slot:referenceNumber>
                                            <router-link :to="{ name: 'application-detail', params: { contactId: task.targetObject.customer.id, id: task.target_object_id }}" data-test="link:task-target">{{ task.targetObject.formattedNumber }}</router-link>
                                        </template>
                                        <template v-if="task.target_object_type === TaskTargetObjectType.CONTRACT" v-slot:contractNumber>
                                            <router-link :to="{ name: 'contract-detail', params: { contactId: task.targetObject.customer.id, id: task.target_object_id }}" data-test="link:task-target">{{ task.targetObject.currentContractNumber }}</router-link>
                                        </template>
                                    </i18n>
                                    <p v-if="task.target_object_type && !task.targetObject" class="additional-info">{{ $tc(`common.task.target-object--${toKebabCase(task.target_object_type.toLowerCase())}--no-access`, 1) }}</p>
                                </dl>
                            </div>
                        </div>

                        <q-separator />

                        <div class="task-description-wrapper row q-col-gutter-sm q-pa-lg" data-test="text:task-description">
                            <!-- eslint-disable vue/no-v-html -->
                            <div v-if="task && task.description && task.description.contentHTML" class="task-description col-xs-12 q-pt-xs" v-html="DOMPurify.sanitize(task.description.contentHTML)" />
                            <!-- eslint-enable -->
                            <!-- TODO improvement: Make task description directly editable (maybe in conjunction with editMode only?) -->
                            <div v-else class="task-description additional-info col-xs-12 q-pt-xs">
                                {{ $tc('common.task.no-description', 1) }}
                                <!-- TODO improvement: Add edit icon (editMode only?) that opens update overlay with focus in description field. -->
                            </div>
                        </div>

                        <q-separator />

                        <div class="row q-col-gutter-sm q-pl-sm">
                            <div :class="['col-xs-12 q-pt-md q-pr-lg q-pl-lg bg-background-secondary', { 'q-pb-lg': task.attachments.length }]">
                                <p data-test="text:task-attachment-counter" :class="{ 'additional-info': !task.attachments.length }">{{ $tc('common.attachment.number-of-attachments', task.attachments.length) }} <template v-if="task.attachments.length"><span class="additional-info">({{ humanStorageSize(attachmentsTotalSize) }})</span>:</template></p>
                                <div class="row q-col-gutter-md">
                                    <div class="col-xs-12">
                                        <!-- TODO improvement @MTR: Create file library component. -->
                                        <div class="file-library-wrapper">
                                            <q-card
                                                v-for="attachment in task.attachments"
                                                :key="attachment.id"
                                                class="file-library-item"
                                                square
                                                flat
                                                bordered
                                                data-test="item:task-attachment"
                                            >
                                                <q-card-section horizontal>
                                                    <q-item class="items-start">
                                                        <q-item-section side>
                                                            <q-avatar square>
                                                                <q-icon
                                                                    v-if="attachment.mime_type"
                                                                    :name="getFileTypeIcon(attachment.mime_type)"
                                                                    size="sm"
                                                                    color="secondary"
                                                                />
                                                            </q-avatar>
                                                        </q-item-section>

                                                        <q-item-section>
                                                            <q-item-label data-test="text:task-attachment-name">{{ attachment.name }}</q-item-label>
                                                            <q-item-label v-if="attachment.size" caption data-test="text:task-attachment-size">{{ humanStorageSize(attachment.size) }}</q-item-label>
                                                        </q-item-section>
                                                    </q-item>

                                                    <q-card-actions vertical>
                                                        <q-btn
                                                            color="primary"
                                                            size="sm"
                                                            flat
                                                            round
                                                            icon="mib-cloud-download"
                                                            @click="attachment.download()"
                                                        >
                                                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.term.download-item', 1, { item: attachment.name }) }}</q-tooltip>
                                                        </q-btn>
                                                    </q-card-actions>
                                                </q-card-section>
                                            </q-card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </q-card>

                    <p class="q-mt-sm q-mb-none" data-test="text:task-created-at"><small class="additional-info">{{ $tc('common.term.created-on--date-time', 1, { date: formatDate(task.created_at), time: formatTime(task.created_at) }) }}.</small></p>
                    <p v-if="task.created_at !== task.updated_at" class="q-mb-none" data-test="text:task-updated-at"><small class="additional-info">{{ $tc('common.term.updated-on--date-time--last-updated', 1, { date: formatDate(task.updated_at), time: formatTime(task.updated_at) }) }}.</small></p>
                    <p v-if="task.done_at" :class="['task-done-at q-mb-none', { 'done-in-time': task.displayStatus === TaskDisplayStatus.DONE_IN_TIME, 'done-after-due-date': task.displayStatus === TaskDisplayStatus.DONE_AFTER_DUE_DATE }]" data-test="text:task-done-at"><small>{{ $tc('common.term.done-on--date-time', 1, { date: formatDate(task.done_at), time: formatTime(task.done_at) }) }}.</small></p>
                </div>
            </div>
        </template>
    </page-wrapper>
</template>

<script>
// TODO @TFU: Make DOMpurify available globally (also see https://itxpert.atlassian.net/browse/MAX-937)
import { EventBus } from '@/event-bus'
import DOMPurify from 'dompurify'
import kebabCase from 'lodash.kebabcase'
import { getFormattedFieldValue } from '@/helpers/form'
import { formatDate, formatTime } from '@/helpers/date'
import { getFileTypeIcon, humanStorageSize } from '@/helpers/file'
import { TaskActions, TaskDueDateStatus, TaskDisplayStatus, FileUploadTargetObjectType } from '@/enums'
import { TaskStatus, TaskTargetObjectType, TaskVisibility } from '@/enums/graphql'

import StatusBadge from '@/components/StatusBadge.vue'
import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'
import ContactName from '@/components/contact/ContactName'

import { Task } from '@/models/models'

export default {
    name: 'TaskDetail',
    meta () {
        return {
            title: !this.task ? `${this.$t('common.term.loading')} …` : `${this.$tc('common.task.task', 1)}: ${this.task.subject}`,
        }
    },
    components: {
        StatusBadge,
        Actions,
        FormDialog,
        ContactName,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            EventBus,
            DOMPurify,
            task: null,
            TaskActions,
            TaskDueDateStatus,
            TaskDisplayStatus,
            TaskStatus,
            TaskTargetObjectType,
            TaskVisibility,
        }
    },
    computed: {
        editMode () {
            return this.$store.state.editMode
        },
        attachmentsTotalSize () {
            return (this.task) ? this.task.attachments.reduce((total, file) => (total += file.size), 0) : 0
        },
    },
    watch: {
        '$route' (to, from) {
            // Re-fetch if task changes while view stays the same (e.g. navigate to other task via task panel).
            if (to.params.id !== from.params.id) {
                this.task = null
                this.fetchObject()
            }
        },
    },
    created () {
        EventBus.$on('task:update', this.fetchObject)
        EventBus.$on('fileUploadManager:allUploadsDone', this.checkRefetchObjects)
        EventBus.$on('task:delete-attachment', this.handleRemoteAttachmentDelete)

        this.fetchObject()
    },
    beforeDestroy () {
        EventBus.$off('task:update', this.fetchObject)
        EventBus.$off('fileUploadManager:allUploadsDone', this.checkRefetchObjects)
        EventBus.$off('task:delete-attachment', this.handleRemoteAttachmentDelete)
    },
    methods: {
        fetchObject () {
            Task.objects.get(this.id).then(task => {
                this.task = task
            }).catch(error => {
                // Forward to `forbidden` error view.
                this.$router.replace({ name: '403' })
            })
        },
        checkRefetchObjects (targetObjects) {
            if (targetObjects.some(targetObject => targetObject.type === FileUploadTargetObjectType.TASK && targetObject.id === this.id)) {
                this.fetchObject()
            }
        },
        handleRemoteAttachmentDelete (task) {
            if (this.task) Object.assign(this.task, task)
        },
        handleActions (action) {
            switch (action.key) {
                case Task.action.DELETE:
                    this.$refs.taskDeleteDialog.open()
                    break
            }
        },
        handleChangeStatus (status) {
            this.task.changeStatus(status)
                .then(task => {
                    EventBus.$emit('task:update', task)
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.task.task-updated-success', 1),
                    })
                })
                .catch(error => {
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$tc('common.notifications.task.task-updated-error', 1),
                    })
                })
        },
        handleTaskDelete () {
            this.task.delete()
                .then(() => {
                    this.$refs.taskDeleteDialog.close()
                    EventBus.$emit('task:delete', this.task.id)
                    this.$router.replace({ name: 'task-list' })
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.task.task-deleted-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.taskDeleteDialog.showFormErrorMessage(error)
                    this.$refs.taskDeleteDialog.resetFormSubmitStatus()
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$tc('common.notifications.task.task-deleted-error', 1),
                    })
                })
        },
        toKebabCase: function (text) {
            return kebabCase(text)
        },
        formatDate (...args) {
            return formatDate(...args)
        },
        formatTime (...args) {
            return formatTime(...args)
        },
        getFormattedFieldValue (...args) {
            return getFormattedFieldValue(...args)
        },
        getFileTypeIcon (...args) {
            return getFileTypeIcon(...args)
        },
        humanStorageSize (...args) {
            return humanStorageSize(...args)
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .page-header .actions-wrapper {
    display: inline-block;
}

::v-deep .task-description > *:last-child {
    margin-bottom: 0;
}

.task-done-at {
    &.done-in-time {
        color: var(--q-color-positive-dark);
    }

    &.done-after-due-date {
        color: var(--q-color-negative-dark);
    }
}

.file-library-wrapper {
    --file-library-items-per-line: 1;
    --column-gap: #{$sizeSpacingSm};
    --row-gap: #{$sizeSpacingSm};

    @media screen and (min-width: $sizeBreakpointSm), print {
        --file-library-items-per-line: 2;
    }

    @media screen and (min-width: $sizeBreakpointMd), print {
        --column-gap: #{$sizeSpacingMd};
        --row-gap: #{$sizeSpacingMd};
    }

    @media screen and (min-width: $sizeBreakpointFormBuilderLg), print {
        --file-library-items-per-line: 3;
    }

    @media screen and (min-width: $sizeBreakpointXl + $sizeAppDrawerRightWidth), print and (min-width: 150mm) {
        --file-library-items-per-line: 4;
    }

    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(var(--file-library-items-per-line), 1fr);
    column-gap: var(--column-gap);
    row-gap: var(--row-gap);
}

.file-library-item.file-library-item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    transition: all 1s $defaultTransitionTimingFunction 0s;

    &.delete {
        border-style: dashed;
        border-color: var(--q-color-negative-light);
        background-color: var(--q-color-negative-lighter);
    }

    & > .q-card__section {
        width: 100%;
        justify-content: space-between;
    }
}
</style>
