import { Model } from '@/models'
import { CommissionListEntryService } from '@/services/commissionListEntry'
import { CommissionListEntryNextStep, CommissionListEntryMatchPostStatus, CommissionListEntryActions } from '@/enums'
import {
    CommissionListEntryCustomerNameMatch,
    CommissionListEntryPositionType,
    CommissionListEntryStatus
} from '@/enums/graphql'

export class CommissionListEntry extends Model {
    /**
     * Create a CommissionListEntry model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'status',
            'ready_to_post',
            'commissionList',
            'contractMatchShortlist',
            'matchedContract',
            'contract_number',
            'framework_agreement_number',
            'positions',
            'customer_first_name',
            'customer_last_name',
            'customer_company_name',
            'customer_full_name',
            'created_manually',
            'status_message',
        ], data)
    }

    static get service () { return CommissionListEntryService }
    static action = CommissionListEntryActions

    /**
     * Add custom actions.
     * @returns {Array}
     */
    get actions () {
        const actions = super.actions

        if (![CommissionListEntryStatus.POSTED, CommissionListEntryStatus.QUEUED_FOR_POSTING].includes(this.status)) {
            actions.push({
                key: CommissionListEntryActions.DELETE,
                item: this,
            })
        }

        return actions
    }

    get customerName () {
        if (this.customer_last_name && this.customer_first_name) {
            return `${this.customer_last_name}, ${this.customer_first_name}`
        } else if (this.customer_company_name) {
            return this.customer_company_name
        } else if (this.customer_full_name) {
            return this.customer_full_name
        }

        return ''
    }

    get totalPosition () {
        return this.positions.find(position => position.type === CommissionListEntryPositionType.TOTAL)
    }

    // TODO @TFU: Revisit, define and refactor.
    get matchPostStatus () {
        if (this.ready_to_post) return CommissionListEntryMatchPostStatus.READY

        // One contract matched.
        if (this.contractMatchShortlist?.length === 1) {
            switch (this.contractMatchShortlist[0].customer_name_match) {
                case CommissionListEntryCustomerNameMatch.PARTIAL:
                    return CommissionListEntryMatchPostStatus.CONTRACT_MATCH__NAME_MATCH_PARTIAL
                case CommissionListEntryCustomerNameMatch.NONE:
                    return CommissionListEntryMatchPostStatus.CONTRACT_MATCH__NAME_MATCH_NONE
            }
        } else if (this.contractMatchShortlist?.length > 1) { // Multiple contracts matched.
            if (this.getNumberOfMatches(CommissionListEntryCustomerNameMatch.FULL) === 1) return CommissionListEntryMatchPostStatus.CONTRACT_SHORTLIST__NAME_MATCH_FULL
            if (this.getNumberOfMatches(CommissionListEntryCustomerNameMatch.PARTIAL) === 1) return CommissionListEntryMatchPostStatus.CONTRACT_SHORTLIST__NAME_MATCH_PARTIAL
        }

        return `${CommissionListEntryMatchPostStatus.NO_MATCH}--${this.nextStep}`
    }

    get nextStep () {
        // TODO @TFU: `ready_to_post && amount === 0` (see TODO at the top)
        if (this.ready_to_post) return CommissionListEntryNextStep.POST
        if (this.matchedContract) return CommissionListEntryNextStep.CONFIRM_SELECTED_CONTRACT
        if (this.contractMatchShortlist?.length) return CommissionListEntryNextStep.SELECT_CONTRACT_FROM_SHORTLIST
        return CommissionListEntryNextStep.SELECT_CONTRACT
    }

    /**
     * Create a new CommissionListEntry.
     * @param {ID} contractId
     * @param {Date} premiumFrom
     * @param {Date} premiumTo
     * @param {CommissionType} commissionType
     * @param {Float} commissionAmount
     * @returns {Promise<*>}
     */
    static create ({ contractId, premiumFrom, premiumTo, commissionType, commissionAmount }) {
        const variables = {
            contractId,
            premiumFrom,
            premiumTo,
            commissionType,
            commissionAmount,
        }

        return CommissionListEntryService.create(variables)
    }

    /**
     * Update this CommissionListEntry.
     * @param {ID} contractId
     * @param {Date} premiumFrom
     * @param {Date} premiumTo
     * @param {CommissionType} commissionType
     * @param {Float} commissionAmount
     * @returns {Promise<*>}
     */
    update ({ contractId, premiumFrom, premiumTo, commissionType, commissionAmount }) {
        const variables = {
            id: this.id,
            contractId,
            premiumFrom,
            premiumTo,
            commissionType,
            commissionAmount,
        }
        return CommissionListEntryService.update(variables).then(commissionListEntry => {
            Object.assign(this, commissionListEntry)
            return commissionListEntry
        })
    }

    /**
     * Update the matched contract of this CommissionListEntry.
     * @param {ID} contractId
     * @returns {Promise<*>}
     */
    updateMatchedContract (contractId) {
        return CommissionListEntryService.updateMatchedContract(this.id, contractId).then(commissionListEntry => {
            Object.assign(this, commissionListEntry)
            return commissionListEntry
        })
    }

    /**
     * Delete this CommissionListEntry.
     * @returns {Promise<*>}
     */
    delete () {
        return CommissionListEntryService.delete(this.id)
    }

    /**
     * Calculates the commission distribution of this `CommissionListEntry`.
     *
     * @returns {Promise<*>}
     */
    postingPreview () {
        return CommissionListEntryService.postingPreview(this.id)
    }

    getNumberOfMatches (commissionListEntryCustomerNameMatch) {
        if (this.contractMatchShortlist) {
            if (commissionListEntryCustomerNameMatch) return this.contractMatchShortlist.filter(contractMatch => contractMatch.customer_name_match === commissionListEntryCustomerNameMatch).length
            return this.contractMatchShortlist.length
        }

        return 0
    }

    /**
     * Start posting selected CommissionListEntries.
     * @param {Array} ids
     * @returns {Promise<*>}
     */
    static startPosting (ids) {
        const variables = {
            ids: ids,
            startAll: false,
        }
        return CommissionListEntryService.startPosting(variables)
    }

    /**
     * Cancel posting selected CommissionListEntries.
     * @returns {Promise<*>}
     */
    static cancelPosting (ids) {
        const variables = {
            stopAll: true,
        }
        return CommissionListEntryService.cancelPosting(variables)
    }
}
