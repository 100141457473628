<template>
    <page-loading-indicator
        v-if="!state.contact"
        :number-of-breadcrumb-elements="2"
        actions
        no-more-actions-button
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.product.product-provider', 2)" :to="{ name: 'admin-product-provider-list' }" />
                <q-breadcrumbs-el :label="state.contact.getContactName({ contactNumber: true })">
                    <status-badge :status="state.contact.status" />
                    <contact-type-badges :contact="state.contact" show-system-owner />
                    <status-badge v-if="state.contact.is_deceased" status="deceased" />
                </q-breadcrumbs-el>
            </template>

            <h1>{{ $t('views.admin.products.product-providers.update-title', { contact: state.contact.getContactName() }) }}<status-badge :status="state.contact.productProviderSettings.status" align="top" /></h1>

            <template v-slot:actions>
                <actions
                    v-if="state.contact.productProviderSettings.actions"
                    :actions="state.contact.productProviderSettings.actions"
                    @click="handleActions"
                />

                <!-- Activate ProductProviderSettings -->
                <form-dialog
                    ref="activateDialog"
                    @confirm="handleActivate"
                >
                    <template v-slot:title>{{ $tc('common.actions.product-provider-settings.activate', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.products.product-providers.activate-product-provider-settings--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:contact><b>{{ state.contact.getContactName() }}</b></template>
                            <template v-slot:type>
                                <template v-if="state.contact.gender && !state.contact.personal_pronoun">{{ $tc('common.product.product-provider--' + state.contact.getGender(), 1) }}</template>
                                <template v-else>{{ $tc('common.product.product-provider', 1) }}</template>
                            </template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Deactivate ProductProviderSettings -->
                <form-dialog
                    ref="deactivateDialog"
                    @confirm="handleDeactivate"
                >
                    <template v-slot:title>{{ $tc('common.actions.product-provider-settings.deactivate', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.products.product-providers.deactivate-product-provider-settings--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:contact><b>{{ state.contact.getContactName() }}</b></template>
                            <template v-slot:type>
                                <template v-if="state.contact.gender && !state.contact.personal_pronoun">{{ $tc('common.product.product-provider--' + state.contact.getGender(), 1) }}</template>
                                <template v-else>{{ $tc('common.product.product-provider', 1) }}</template>
                            </template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Delete ProductProviderSettings -->
                <!-- TODO: Enhance v-if with defined conditions which must be met to be able to delete product provider settings -->
                <form-dialog
                    ref="deleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleDelete"
                >
                    <template v-slot:title>{{ $tc('common.actions.product-provider-settings.delete', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.products.product-providers.delete-product-provider-settings--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:contact>{{ state.contact.getContactName() }}</template>
                            <template v-slot:type>
                                <template v-if="state.contact.gender && !state.contact.personal_pronoun">{{ $tc('common.product.product-provider--' + state.contact.getGender(), 1) }}</template>
                                <template v-else>{{ $tc('common.product.product-provider', 1) }}</template>
                            </template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <!--
            TODO:
            - [x] Add `dxpProcesses` with their info (enabled / auto processing) to `ProductProviderSettings` and adjust overview table accordingly.
            - [x] Make info "generally" available and show relevant info boxes (same as in `ProductProviderSettingsForm.vue`)
                - [x] no-igb2b-insurer-code
                - [x] no-supported-processes-for-insurer-code
                - [x] no-tech-user
                - [x] permissions--no-permissions
                - [x] permissions--partial-permissions
        -->

        <div v-if="!state.showProductProviderSettingsUpdateForm" class="row q-col-gutter-md">
            <grid-card
                :columns="{
                    xs: 12,
                    sm: 12,
                    md: 6,
                    lg: 6,
                    xl: 4
                }"
                :heading="$tc('common.product-provider.product-provider-info', 2)"
            >
                <dl>
                    <dt>{{ $tc('common.term.display-name', 1) }}: </dt>
                    <dd>
                        <template v-if="state.contact.productProviderSettings.display_name || state.contact.productProviderSettings.igb2bInsurerCode">
                            <template v-if="state.contact.productProviderSettings.display_name">{{ state.contact.productProviderSettings.display_name }}</template>
                            <template v-else>{{ state.contact.getContactName() }}</template>
                        </template>
                        <info-icon class="q-ml-xs" :text="$tc('common.product-provider.display-name--description', 1)" />
                    </dd>

                    <br>
                    <dt>{{ $tc('common.product-provider.igb2b-insurer-code', 1) }}: </dt>
                    <dd>
                        <template v-if="state.contact.productProviderSettings.igb2bInsurerCode">{{ state.contact.productProviderSettings.igb2bInsurerCode.id }} <span class="font-weight-normal">({{ state.contact.productProviderSettings.igb2bInsurerCode.name }})</span></template>
                        <span v-else class="additional-info">{{ $t('common.term.assigned--not-assigned') }}</span>
                        <info-icon class="q-ml-xs" :text="$tc('common.product-provider.igb2b-insurer-code--description', 1)" />
                    </dd>
                </dl>

                <template v-slot:actions>
                    <base-button
                        :label="$tc('views.admin.products.product-providers.go-to-contact', 1)"
                        :to="{ name: 'contact-detail', params: { id: state.contact.id } }"
                        primary-button
                        flat
                    />
                </template>
            </grid-card>

            <grid-card
                v-can="'Feature:ecoHub:core'"
                :columns="{
                    xs: 12,
                    sm: 12,
                    md: 6,
                    lg: 6,
                    xl: 4
                }"
                :heading="$tc('views.admin.products.product-providers.eco-hub.eco-hub-process-integration', 1)"
            >
                <template v-if="!store.state.ecoHubTechUser || !state.contact.productProviderSettings.igb2bInsurerCode">
                    <info-box v-if="!store.state.ecoHubTechUser" type="warning">
                        <p>{{ $t('views.admin.products.product-providers.eco-hub.status-message.no-tech-user') }}</p>

                        <template v-slot:action>
                            <base-button
                                :to="{ name: 'eco-hub-tech-user-detail' }"
                                :label="$tc('views.admin.eco-hub.tech-user.enroll-tech-user', 1)"
                                primary-button
                                outline
                            />
                        </template>
                    </info-box>

                    <info-box v-if="!state.contact.productProviderSettings.igb2bInsurerCode" no-margin>
                        <p>{{ $t('views.admin.products.product-providers.eco-hub.status-message.no-igb2b-insurer-code') }}</p>
                    </info-box>
                </template>

                <template v-else>
                    <info-box
                        v-if="agreementPermissions && agreementPermissions !== 'FULL_PERMISSIONS' && availableDxpProcessVersionMappings.length"
                        type="negative"
                        icon="mib-alert-triangle"
                        icon-color="negative"
                    >
                        <p>{{ $t(`views.admin.products.product-providers.eco-hub.status-message.permissions--${kebabCase(agreementPermissions)}`) }}</p>
                    </info-box>

                    <!-- Processes -->
                    <info-box v-if="!availableDxpProcessVersionMappings.length" type="warning" no-margin>
                        <i18n path="views.admin.products.product-providers.eco-hub.status-message.no-supported-processes-for-insurer-code" tag="p">
                            <template v-slot:igb2bInsurerCode><b>{{ state.contact.productProviderSettings.igb2bInsurerCode.id }}</b></template>
                        </i18n>
                    </info-box>
                    <template v-else>
                        <q-markup-table
                            flat
                            square
                            dense
                        >
                            <thead>
                                <tr>
                                    <th class="text-left">{{ $tc('views.admin.products.product-providers.eco-hub.eco-hub-process', 1) }}</th>
                                    <th class="text-center">{{ $t('views.admin.products.product-providers.eco-hub.column-title--activated') }}</th>
                                    <th class="text-center">{{ $t('views.admin.products.product-providers.eco-hub.column-title--automatic-processing') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="dxpProcessVersionMapping in availableDxpProcessVersionMappings" :key="`enabled-process-${dxpProcessVersionMapping.dxpProcess.id}`">
                                    <td class="text-left">{{ $t(`views.admin.products.product-providers.eco-hub.document-type.${kebabCase(dxpProcessVersionMapping.dxpProcess.id)}`) }}</td>

                                    <td class="text-center">
                                        <span>
                                            <q-icon v-if="state.contact.productProviderSettings?.dxpServiceProviderSettings?.isEnabled(dxpProcessVersionMapping.dxpProcess)" name="mib-check-1" color="positive" />
                                            <span v-else class="additional-info">–</span>
                                            <q-tooltip
                                                anchor="top middle"
                                                self="center middle"
                                                :delay="500"
                                                max-width="25rem"
                                            >
                                                <i18n :path="`views.admin.products.product-providers.eco-hub.eco-hub-process-integration--activated--${state.contact.productProviderSettings?.dxpServiceProviderSettings?.isEnabled(dxpProcessVersionMapping.dxpProcess)}`">
                                                    <template v-slot:process><b>{{ $t(`views.admin.products.product-providers.eco-hub.document-type.${kebabCase(dxpProcessVersionMapping.dxpProcess.id)}`) }}</b></template>
                                                </i18n>
                                            </q-tooltip>
                                        </span>
                                    </td>

                                    <td class="text-center">
                                        <span>
                                            <q-icon v-if="state.contact.productProviderSettings?.dxpServiceProviderSettings?.isEnabledForAutomaticProcessing(dxpProcessVersionMapping.dxpProcess)" name="mib-check-1" color="positive" />
                                            <span v-else class="additional-info">–</span>
                                            <q-tooltip
                                                anchor="top middle"
                                                self="center middle"
                                                :delay="500"
                                                max-width="25rem"
                                            >
                                                <i18n :path="`views.admin.products.product-providers.eco-hub.eco-hub-process-integration--automatic-processing--${state.contact.productProviderSettings?.dxpServiceProviderSettings?.isEnabledForAutomaticProcessing(dxpProcessVersionMapping.dxpProcess)}`">
                                                    <template v-slot:process><b>{{ $t(`views.admin.products.product-providers.eco-hub.document-type.${kebabCase(dxpProcessVersionMapping.dxpProcess.id)}`) }}</b></template>
                                                </i18n>
                                            </q-tooltip>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </q-markup-table>
                    </template>
                </template>
            </grid-card>

            <in-page-footer v-if="!state.showProductProviderSettingsUpdateForm">
                <base-button
                    :label="$t('common.actions.product-provider-settings.edit')"
                    primary-button
                    outline
                    class="do-not-print"
                    @click="state.showProductProviderSettingsUpdateForm = true"
                />
            </in-page-footer>
        </div>

        <div v-else class="row q-col-gutter-md">
            <product-provider-settings-form
                ref="productProviderSettingsForm"
                :product-provider-settings="state.contact.productProviderSettings"
                @cancel="state.showProductProviderSettingsUpdateForm = false"
                @submit="onSubmit"
            />
        </div>
    </page-wrapper>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { ContactType, ProductProviderSettingsStatus } from '@/enums/graphql'

import StatusBadge from '@/components/StatusBadge.vue'
import ContactTypeBadges from '@/components/contact/ContactTypeBadges.vue'
import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'
import ProductProviderSettingsForm from '@/components/product/ProductProviderSettingsForm'

import { Contact } from '@/models/contact'
import { ProductProviderSettings } from '@/models/productProviderSettings'
import { computed, onBeforeMount, reactive, ref } from 'vue'
import { getAgreementPermissions, getAvailableDxpProcessVersionMappings } from '@/helpers/dxp'
import { useQuasar } from '@/composables/quasar'
import { useI18n } from '@/composables/i18n'
import { useRouter } from '@/composables/router'
import { useStore } from '@/composables/store'

export default {
    name: 'ProductProviderUpdate',
    meta () {
        return {
            title: !this.state.contact ? `${this.$t('common.term.loading')} …` : this.$t('views.admin.products.product-providers.update-title', { contact: this.state.contact.getContactName({ contactNumber: true }) }),
        }
    },
    components: {
        StatusBadge,
        ContactTypeBadges,
        Actions,
        FormDialog,
        ProductProviderSettingsForm,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup (props) {
        // Composables
        const store = useStore()
        const $q = useQuasar()
        const router = useRouter()
        const { tc } = useI18n()

        // Data
        const state = reactive({
            ContactType,
            ProductProviderSettingsStatus,
            contact: null,
            showProductProviderSettingsUpdateForm: false,
        })

        // Template refs
        const productProviderSettingsForm = ref(null)
        const activateDialog = ref(null)
        const deactivateDialog = ref(null)
        const deleteDialog = ref(null)

        // Computed
        const agreementPermissions = computed(() => {
            return getAgreementPermissions(state.contact?.productProviderSettings.igb2bInsurerCode.id)
        })
        const availableDxpProcessVersionMappings = computed(() => {
            return getAvailableDxpProcessVersionMappings(state.contact?.productProviderSettings.igb2bInsurerCode.id)
        })

        // Functions
        async function fetchObject () {
            state.contact = await Contact.objects.get(props.id)
        }
        function onSubmit (formData) {
            state.contact.productProviderSettings.update(formData)
                .then(() => {
                    state.showProductProviderSettingsUpdateForm = false
                })
                .catch((error) => {
                    productProviderSettingsForm.value.formComponent.formBuilder.showFormErrorMessage(error)
                    productProviderSettingsForm.value.formComponent.formBuilder.resetFormSubmitStatus()
                })
        }
        function handleActions (action) {
            switch (action.key) {
                case ProductProviderSettings.action.ACTIVATE:
                    activateDialog.value.open()
                    break
                case ProductProviderSettings.action.DEACTIVATE:
                    deactivateDialog.value.open()
                    break
                case ProductProviderSettings.action.DELETE:
                    deleteDialog.value.open()
                    break
            }
        }
        function handleActivate () {
            state.contact.productProviderSettings.activate()
                .then(() => {
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.product.product-provider-settings-activated-success', 1),
                    })
                })
        }
        function handleDeactivate () {
            state.contact.productProviderSettings.deactivate()
                .then(() => {
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.product.product-provider-settings-deactivated-success', 1),
                    })
                })
        }
        function handleDelete () {
            state.contact.productProviderSettings.delete()
                .then(() => {
                    deleteDialog.value.close()
                    $q.notify({
                        type: 'positive',
                        message: tc('common.notifications.product.product-provider-settings-deleted-success', 1),
                    })
                    router.replace({ name: 'admin-product-provider-list' })
                })
                .catch(error => {
                    deleteDialog.value.showFormErrorMessage(error)
                    deleteDialog.value.resetFormSubmitStatus()
                })
        }

        // Lifecycle hooks
        onBeforeMount(() => {
            fetchObject()
        })

        return {
            // Data
            store,
            state,

            // Template refs
            productProviderSettingsForm,
            activateDialog,
            deactivateDialog,
            deleteDialog,

            // Computed
            agreementPermissions,
            availableDxpProcessVersionMappings,

            // Functions
            kebabCase,
            onSubmit,
            handleActions,
            handleActivate,
            handleDeactivate,
            handleDelete,
        }
    },
}
</script>
