import gql from 'graphql-tag'

export const CONTRACT_COMMISSION_POSTINGS = gql`
    query contractCommissionPostings(
        $contractId: ID!
        $page: Int = 1
        $count: Int = 1000
        $orderField: PostingOrderField = TYPE
        $orderDirection: OrderDirection = DESC
        $filterQuery: String
    ) {
        contractCommissionPostings(
            contract_id: $contractId
            page: $page
            count: $count
            order: [
                {
                    field: $orderField
                    direction: $orderDirection
                }
            ]
            search: {
                query: $filterQuery,
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                number {
                    number
                }
                date
                text
                debitAccount {
                    id
                    name
                    number
                    type
                }
                creditAccount {
                    id
                    name
                    number
                    type
                }
                type {
                    key
                    name
                }
                amount
            }
        }
    }
`
