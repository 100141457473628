import { Form, FormField, FormRepeater, FormRepeaterItem } from '@/libs/form'
import { computed, watch } from 'vue'

export class ContactRelationshipForm extends Form {
    compose () {
        this.addField(new FormField('contactId', {
            label: 'forms.field.label.contact-relationship.contact-id',
            type: 'contact',
            validators: [
                { type: 'required' },
            ],
        }))
        watch(() => this.getItem('contactId').value, (newValue, oldValue) => {
            if (oldValue !== null) {
                this.getItem('definition').resetValue()
                this.getItem('notes').resetValue()
                this.getItem('areasOfResponsibility').reset()
            }
        })

        this.addField(new FormField('definition', {
            label: 'forms.field.label.contact-relationship.definition',
            type: 'contactRelationshipDefinition',
            validators: [
                { type: 'required' },
            ],
            attributes: {
                disable: computed(() => {
                    return this.getItem('contactId').value === null
                }),
            },
        }))

        this.addField(new FormField('notes', {
            label: { key: 'forms.field.label.contact-relationship.notes', count: 2 },
            type: 'textarea',
        }))

        this.addRepeater(new FormRepeater('areasOfResponsibility', {
            repeaterItemClass: ContactRelationshipAreasOfResponsibility,
        }))
    }
}

// Areas of Responsibility
export class ContactRelationshipAreasOfResponsibility extends FormRepeaterItem {
    compose () {
        // Will not be rendered, but needed for updating the areas instead of deleting and creating new ones.
        this.addField(new FormField('id', {
            type: 'text',
        }))

        this.addField(new FormField('name', {
            label: 'forms.field.label.contact-relationship.area-of-responsibility',
            type: 'text',
            validators: [
                { type: 'required' },
            ],
        }))

        this.addField(new FormField('description', {
            label: 'forms.field.label.contact-relationship.description',
            type: 'textarea',
        }))

        this.addField(new FormField('emailAddressId', {
            label: 'forms.field.label.contact-relationship.email-address',
            type: 'select',
        }))

        this.addField(new FormField('phoneNumberId', {
            label: 'forms.field.label.contact-relationship.phone-number',
            type: 'select',
        }))
    }
}
