<template>
    <form
        data-test="form:user-assign-roles"
        class="row q-col-gutter-y-sm"
        @submit.prevent="onFormSubmit"
        @keyup.esc="cancel"
    >
        <div class="col-xs-12">
            <!-- eslint-disable vue/no-v-html -->
            <info-box
                v-if="formErrorMessage"
                type="negative"
                data-test="error:global-message"
                v-html="formErrorMessage"
            />
            <!-- eslint-enable -->
        </div>

        <div class="col-xs-12">
            <form-builder item-key="roleIds" autofocus />
        </div>

        <div class="col-xs-12 q-gutter-x-sm">
            <base-button
                :label="$t('common.term.cancel')"
                outline
                flat
                primary-button
                @click="cancel"
            />

            <base-button
                data-test="btn:submit"
                type="submit"
                :label="$t('common.term.save')"
                :disable="formSubmitStatus"
                :loading="formSubmitStatus"
                primary-button
            />
        </div>
    </form>
</template>

<script>
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import USER_FIELDS from '@/forms/user.fields.json'

export default {
    name: 'UserAssignRolesForm',
    mixins: [formBuilderMixin],
    props: {
        roleIds: {
            type: Array,
            required: true,
        },
    },
    formBuilderSettings: {
        schema: USER_FIELDS,
        fields: ['roleIds'],
    },
    data () {
        return {
            formData: {
                roleIds: this.roleIds,
            },
        }
    },
    methods: {
        cancel () {
            this.$emit('cancel')
        },
    },
}
</script>
