<template>
    <div :class="['q-field row no-wrap items-start truncate q-input q-field--float q-validation-component', { 'q-field--filled': filled, 'q-field--square': square, 'q-field--dense': dense, 'q-field--labeled': label, 'required': required }]">
        <div class="q-field__inner relative-position col self-stretch">
            <div tabindex="-1" class="q-field__control relative-position row no-wrap">
                <div v-if="$slots.prepend" class="q-field__append q-field__marginal row no-wrap items-center q-anchor--skip">
                    <slot name="prepend" />
                </div>

                <div class="q-field__control-container col relative-position row no-wrap q-anchor--skip">
                    <div :aria-label="label || null" class="q-field__native q-placeholder">
                        <div class="fake-input-content-wrapper">
                            <slot name="default">
                                <span v-if="placeholder" class="q-field__label placeholder">{{ placeholder }}</span>
                            </slot>
                        </div>
                    </div>
                    <div v-if="label" class="q-field__label no-pointer-events absolute ellipsis">{{ label }}</div>
                </div>

                <div v-if="$slots.append || clearable" class="q-field__append q-field__marginal row no-wrap items-center q-anchor--skip">
                    <slot name="append" />

                    <button
                        v-if="clearable"
                        class="q-field__focusable-action max-icon mib-remove-circle q-icon notranslate"
                        type="button"
                        @click="$emit('clear')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FakeInputField',
    props: {
        filled: {
            type: Boolean,
            default: true,
        },
        square: {
            type: Boolean,
            default: true,
        },
        dense: {
            type: Boolean,
            default: true,
        },

        placeholder: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },

        clearable: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.fake-input-content-wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.placeholder {
    color: var(--color-text-secondary);
}
</style>
