import { Model } from '@/models'

export class ProductDerivedEntity extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            ['status', 'applicationStatus', 'contractStatus'], // Gets returned with aliases when using SearchResult or productDerivedEntity union.
            'customer',
            ['consultant', 'applicationConsultant', 'contractConsultant'], // Gets returned with aliases when using productDerivedEntity union.
            'contractInformation',
            'currentContractInformation',
        ], data)
    }

    get couldUpdateProductTemplateVersion () {
        return this.currentContractInformation.productTemplate.version !== this.currentContractInformation.product.template.version
    }

    get consultantAlsoConsultsCustomer () {
        const customerConsultants = this?.customer?.consultants
        return (typeof customerConsultants !== 'undefined') ? customerConsultants.findIndex(consultant => consultant.id === this.consultant.id) > -1 : undefined
    }
}
