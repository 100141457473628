import DefaultLayout from '@/layouts/Default.vue'
import TaskDetail from '@/views/task/TaskDetail.vue'
import TaskList from '@/views/task/TaskList.vue'

export default [
    {
        path: '/tasks/:id(\\d+)',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'task-detail',
            component: TaskDetail,
            props: true,
            meta: {
                can: 'Feature:tasks:core',
                navGroup: 'tasks',
            },
        }],
    },
    {
        path: '/tasks/:tab(done)?',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'task-list',
            component: TaskList,
            props: true,
            meta: {
                can: 'Feature:tasks:core',
                navGroup: 'tasks',
            },
        }],
    },
]
