<template>
    <div class="row q-col-gutter-y-lg">
        <div class="col-xs-12">
            <correspondence-item-form
                ref="correspondenceItemForm"
                :correspondence-item="correspondenceItem"
                @submit="onSubmit"
            />
        </div>

        <div class="col-xs-12">
            <p class="q-mb-xs">
                <span class="fake-field-label required">{{ $tc('common.correspondence.target-object.target-object', 1) }}/{{ $tc('common.term.recipient', 1) }}</span> <info-icon :text="$t('common.correspondence.target-object.target-object--description')" />
                <q-icon
                    v-if="$v.targetObjects.$error && !$v.targetObjects.required"
                    name="mib-alert-circle"
                    color="negative"
                    class="q-ml-xs"
                />
            </p>
            <correspondence-item-target-object-configurator v-model="targetObjects" :correspondence-item="correspondenceItem" />
            <div v-if="$v.targetObjects.$error">
                <small v-if="!$v.targetObjects.required" class="text-negative">{{ $tc('common.error-message.required--must-be-chosen', 1, { field: `${$tc('common.correspondence.target-object.target-object', 1)}/${$tc('common.term.recipient', 1)}` }) }}</small>
            </div>
        </div>


        <div class="col-xs-12">
            <document-content-formality-editor
                :contents="contents"
                :validator-configuration="editorValidations"
                :smart-object-valid-target-object-types="smartObjectValidTargetObjectTypes"
                :differentiate-formality.sync="differentiateFormality"
            />
        </div>

        <div v-if="$slots.afterEditor" class="col-xs-12 after-editor">
            <slot name="afterEditor" />
        </div>
    </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { DocumentType, CorrespondenceItemTargetObjectType, DocumentContentFormality, ContactType } from '@/enums/graphql'
import CorrespondenceItemForm from '@/components/correspondence/CorrespondenceItemForm'
import CorrespondenceItemTargetObjectConfigurator from '@/components/correspondence/CorrespondenceItemTargetObjectConfigurator'
import DocumentContentFormalityEditor from '@/components/documents/DocumentContentFormalityEditor'
import { StructureBlockSubject } from '@/editor/structureBlocks/StructureBlockSubject'
import { StructureBlockContent } from '@/editor/structureBlocks/StructureBlockContent'

export default {
    name: 'CorrespondenceItemCompose',
    components: {
        CorrespondenceItemForm,
        CorrespondenceItemTargetObjectConfigurator,
        DocumentContentFormalityEditor,
    },
    props: {
        correspondenceItem: {
            type: Object,
            required: true,
        },
    },
    data () {
        const data = {
            DocumentType,
            DocumentContentFormality,
            targetObjects: [],
            differentiateFormality: false,
            contents: [],
            editorValidations: {
                [StructureBlockSubject.name]: { required },
                [StructureBlockContent.name]: { required },
            },
        }

        if (this.correspondenceItem.targetObjects && this.correspondenceItem.targetObjects.length) data.targetObjects = [...this.correspondenceItem.targetObjects]
        if (this.correspondenceItem.contents && this.correspondenceItem.contents.length) data.contents = this.correspondenceItem.contents
        if (this.correspondenceItem.differentiate_formality) {
            data.differentiateFormality = this.correspondenceItem.differentiate_formality
            data.activeFormality = data.contents[0].formality
        }

        return data
    },
    computed: {
        smartObjectValidTargetObjectTypes () {
            const validTargetObjectTypes = []

            // Deduce valid target objects types from selected target objects (more specific) …
            if (this.targetObjects.length) {
                this.targetObjects.forEach(targetObject => {
                    if (!validTargetObjectTypes.includes(targetObject.type)) validTargetObjectTypes.push(targetObject.type)
                    if (!validTargetObjectTypes.includes(targetObject.recipient.type)) validTargetObjectTypes.push(targetObject.recipient.type)
                })
            } else { // … or from the target object type on the correspondence item.
                switch (this.correspondenceItem.target_object_type) {
                    case CorrespondenceItemTargetObjectType.CONTACT:
                        validTargetObjectTypes.push(ContactType.PERSON, ContactType.COMPANY)
                        break
                    case CorrespondenceItemTargetObjectType.APPLICATION:
                        validTargetObjectTypes.push(CorrespondenceItemTargetObjectType.APPLICATION, ContactType.PERSON, ContactType.COMPANY)
                        break
                    case CorrespondenceItemTargetObjectType.CONTRACT:
                        validTargetObjectTypes.push(CorrespondenceItemTargetObjectType.CONTRACT, ContactType.PERSON, ContactType.COMPANY)
                        break
                }
            }

            return validTargetObjectTypes
        },
    },
    validations () {
        return {
            targetObjects: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(1), // TODO @TFU: Re-adjust maxLength validator once implementing bulk.
            },
            contents: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(2),
            },
        }
    },
    methods: {
        triggerSubmit () {
            this.$v.$touch() // Trigger targetObjects validation.
            if (Object.keys(this.editorValidations).length) this.contents.forEach(documentContent => documentContent.content.$v.$touch()) // Trigger editor content validation.
            this.$refs.correspondenceItemForm.onFormSubmit()
        },
        onSubmit (formData) {
            const invalidEditorContent = (Object.keys(this.editorValidations).length && this.contents.some(documentContent => documentContent.content.$v.$invalid))

            if (!this.$v.$invalid && !invalidEditorContent) {
                const attentionOfs = {}
                const addressOverrides = {}
                const emailAddressOverrides = {}
                const senderOverrides = {}
                this.targetObjects.forEach(targetObject => {
                    if (targetObject.attentionOf) attentionOfs[targetObject.recipient.id] = targetObject.attentionOf
                    if (targetObject.addressOverrideId) addressOverrides[targetObject.recipient.id] = targetObject.addressOverrideId
                    if (targetObject.emailAddressOverrideId) emailAddressOverrides[targetObject.recipient.id] = targetObject.emailAddressOverrideId
                    if (targetObject.senderOverrideId) senderOverrides[targetObject.recipient.id] = targetObject.senderOverrideId
                })

                const values = {
                    targetObjects: this.targetObjects,
                    differentiateFormality: this.differentiateFormality,
                    contents: this.contents,
                    attentionOfs: (Object.keys(attentionOfs).length) ? attentionOfs : null,
                    addressOverrides: (Object.keys(addressOverrides).length) ? addressOverrides : null,
                    emailAddressOverrides: (Object.keys(emailAddressOverrides).length) ? emailAddressOverrides : null,
                    senderOverrides: (Object.keys(senderOverrides).length) ? senderOverrides : null,
                }
                Object.assign(values, formData)
                this.$emit('submit', values)
            }
        },
    },
}
</script>
