<template>
    <page-loading-indicator
        v-if="!commissionRateAdjustmentRuleSet"
        :number-of-breadcrumb-elements="2"
        actions
        :number-of-next-actions="1"
        no-more-actions-button
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.commission.rate-adjustment.rate-adjustment', 2)" :to="{ name: 'commission-rate-adjustment-rule-set-list' }" />
                <q-breadcrumbs-el :label="commissionRateAdjustmentRuleSet.productProvider.name" :to="{ name: 'commission-rate-adjustment-rule-set-update', params: { id: id } }" data-test="breadcrumb:commission-rate-adjustment-rule-set-detail" />
            </template>

            <h1>{{ commissionRateAdjustmentRuleSet.productProvider.name }} ({{ commissionRateAdjustmentRuleSet.productProvider.contact_number }})</h1>

            <template v-slot:actions>
                <actions
                    :actions="commissionRateAdjustmentRuleSet.actions"
                    @click="handleActions"
                />
                <form-dialog
                    ref="deleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleDelete"
                >
                    <template v-slot:title>{{ $tc('common.rule.delete-rule-set', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.rule.delete-rule-set--for-entity--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:entity><b>{{ commissionRateAdjustmentRuleSet.productProvider.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <commission-rate-adjustment-rule-set-form
                    ref="commissionRateAdjustmentRuleSetForm"
                    :key="commissionRateAdjustmentRuleSetFormForceReRender"
                    :commission-rate-adjustment-rule-set="commissionRateAdjustmentRuleSet"
                    @submit="handleSubmit"
                />
            </div>
        </div>

        <div>
            <p>
                <small>
                    <base-button
                        show-as-link
                        @click="$refs.activityLogOverlay.open({ filterSubjects: [{ id: id, type: ActivityLogSubjectType.COMMISSION_RATE_ADJUSTMENT_RULE_SET }], filterIncludeSubjectRelations: [ActivityLogSubjectRelation.COMMISSION_RATE_ADJUSTMENT_RULES] })"
                    >{{ $tc('common.term.created-on--date-time', 1, { date: commissionRateAdjustmentRuleSet.formattedCreatedAtDate, time: commissionRateAdjustmentRuleSet.formattedCreatedAtTime }) }}</base-button>
                </small>
            </p>
        </div>

        <!-- Activity log -->
        <activity-log-overlay ref="activityLogOverlay" />
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import { ActivityLogSubjectType, ActivityLogSubjectRelation } from '@/enums/graphql'
import { CommissionRateAdjustmentRuleSet } from '@/models/commissionRateAdjustmentRuleSet'
import { parseFormDataToInput } from '@/helpers/commissionRateAdjustmentRuleSet'

import CommissionRateAdjustmentRuleSetForm from '@/components/commission/commissionRateAdjustmentRuleSet/CommissionRateAdjustmentRuleSetForm'
import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'
import ActivityLogOverlay from '@/components/ActivityLogOverlay'

export default {
    name: 'CommissionRateAdjustmentRuleSetUpdate',
    meta () {
        return {
            title: this.$tc('common.rule.update-rule-set', 1),
        }
    },
    components: {
        Actions,
        FormDialog,
        CommissionRateAdjustmentRuleSetForm,
        ActivityLogOverlay,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            ActivityLogSubjectType,
            ActivityLogSubjectRelation,
            commissionRateAdjustmentRuleSetFormForceReRender: 0, // Will be increased by one after successful update in order to trigger re-render of form component.
            commissionRateAdjustmentRuleSet: null,
        }
    },
    created () {
        CommissionRateAdjustmentRuleSet.objects.get(this.id)
            .then(commissionRateAdjustmentRuleSet => {
                this.commissionRateAdjustmentRuleSet = commissionRateAdjustmentRuleSet
            })
            .catch(() => {
                this.$router.replace({ name: '403' }) // TODO @TFU: Differentiate between 403 and 404.
            })

        EventBus.$on('commissionRateAdjustmentRuleSet:emit-rule-forms-data', this.handleUpdate)
    },
    beforeDestroy () {
        EventBus.$off('commissionRateAdjustmentRuleSet:emit-rule-forms-data', this.handleUpdate)
    },
    methods: {
        handleActions (action) {
            switch (action.key) {
                case CommissionRateAdjustmentRuleSet.action.DELETE:
                    this.$refs.deleteDialog.open()
                    break
            }
        },
        handleDelete () {
            this.commissionRateAdjustmentRuleSet.delete()
                .then(() => {
                    this.$refs.deleteDialog.close()
                    this.$router.replace({ name: 'commission-rate-adjustment-rule-set-list' })
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.commission.commission-rate-adjustment-rule-set-deleted-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.deleteDialog.showFormErrorMessage(error)
                    this.$refs.deleteDialog.resetFormSubmitStatus()
                })
        },
        handleSubmit () {
            EventBus.$emit('commissionRateAdjustmentRuleSet:submit-rule-forms')
        },
        handleUpdate (formData) {
            const input = parseFormDataToInput(formData)

            this.commissionRateAdjustmentRuleSet
                .update(input)
                .then(commissionRateAdjustmentRuleSet => {
                    this.commissionRateAdjustmentRuleSet = commissionRateAdjustmentRuleSet
                    this.commissionRateAdjustmentRuleSetFormForceReRender++
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.commission.commission-rate-adjustment-rule-set-updated-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.commissionRateAdjustmentRuleSetForm.showFormErrorMessage(error)
                    this.$refs.commissionRateAdjustmentRuleSetForm.resetFormSubmitStatus()
                })
        },
    },
}
</script>
