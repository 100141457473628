import gql from 'graphql-tag'

export const COMMISSION_LIST_IMPORT_ERRORS_QUERY = gql`
    query commissionListImportErrors(
        $page: Int = 1
        $count: Int = 1000
        $orderField: CommissionListImportErrorOrderField = ENTRY
        $orderDirection: OrderDirection = DESC
        $filterCommissionListIds: [ID!]
        $filterScope: [CommissionListErrorScope!]
    ) {
        commissionListImportErrors(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                commission_list_ids: $filterCommissionListIds
                scope: $filterScope
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                commissionList {
                    id
                }
                scope
                messages {
                    subject
                    code
                    type
                    params
                }
                source_entry_number
                source_data
                parsed_data
                created_at
                updated_at
            }
        }
    }
`
