<template>
    <form
        data-test="form:document-layout-create"
        @submit.prevent="onFormSubmit"
        @keyup.esc="cancel"
    >
        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="formErrorMessage"
            type="negative"
            data-test="error:global-message"
            v-html="formErrorMessage"
        />
        <!-- eslint-enable -->

        <div class="row q-col-gutter-md">
            <div class="col-xs-12 q-mt-none q-pb-md">
                <localized-input
                    v-model="formData.name"
                    :label="$tc('common.term.name', 1)"
                    :validation="$v.formData.name"
                    autofocus
                />
            </div>

            <div class="col-xs-12 col-lg-6 col-xl-6">
                <h3>{{ $tc('common.document-layout.page-background', 1) }}</h3>

                <div v-if="currentMode === ViewMode.UPDATE && documentLayout.pageBackground && !removeCurrentPageBackground">
                    <p>{{ $tc('views.contact.settings.document-layout.page-background--current-page-background', 1) }}:<br>
                        <a @click="documentLayout.pageBackground.download()">{{ documentLayout.pageBackground.name }} ({{ documentLayout.pageBackground.formattedFileSize }})</a>

                        <q-btn
                            color="negative"
                            size="xs"
                            flat
                            round
                            icon="mib-bin"
                            class="do-not-print"
                            @click="removeCurrentPageBackground = true"
                        >
                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.contact.settings.document-layout.page-background--remove-current-page-background', 1) }}</q-tooltip>
                        </q-btn>
                    </p>
                </div>
                <div v-else>
                    <p>{{ $tc('views.contact.settings.document-layout.page-background--description', 1) }}</p>

                    <file-upload v-model="$v.formData.pageBackground.$model" />

                    <info-box v-if="$v.formData.pageBackground.$error" type="negative">
                        <ul>
                            <li v-if="!$v.formData.pageBackground.maxLength">{{ $tc('common.error-message.max-n-files-allowed', $v.formData.pageBackground.$params.maxLength.max) }}</li>
                            <li v-if="$v.formData.pageBackground.$each.$error">{{ $tc('common.error-message.only-specific-file-types-allowed', 1, { type: allowedFileTypes }) }}</li>
                        </ul>
                    </info-box>
                </div>

                <info-box class="q-mt-md">
                    <!-- TODO improvement @MTR: Change content to "sanitized markdown v-html" to be able to have a "real" list (and other formatting) -->
                    <p>{{ $tc('views.contact.settings.document-layout.page-background--requirements', 1) }}</p>
                </info-box>
            </div>

            <div class="col-xs-12 col-lg-6 col-xl-6">
                <h3>{{ $tc('common.document-layout.page-margin', 2) }}</h3>
                <p>{{ $tc('views.contact.settings.document-layout.page-margin--description', 1) }}</p>
                <div class="row page-margin-wrapper q-col-gutter-md">
                    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 page-margin-inner-wrapper">
                        <form-builder
                            item-key="marginTop"
                            suffix="mm"
                            step="0.01"
                            class="no-input-spinner"
                        />
                    </div>
                    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 page-margin-inner-wrapper">
                        <form-builder
                            item-key="marginLeft"
                            suffix="mm"
                            step="0.01"
                            class="no-input-spinner"
                        />
                    </div>
                    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 page-margin-inner-wrapper">
                        <form-builder
                            item-key="marginRight"
                            suffix="mm"
                            step="0.01"
                            class="no-input-spinner"
                        />
                    </div>
                    <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 page-margin-inner-wrapper">
                        <form-builder
                            item-key="marginBottom"
                            suffix="mm"
                            step="0.01"
                            class="no-input-spinner"
                        />
                    </div>
                </div>

                <!-- TODO improvement: Dynamically add page dimensions from the document layout -->
                <div
                    class="row layout-preview-wrapper"
                    :style="`
                        --size-page-width: 210;
                        --size-page-height: 297;
                        --size-margin-top: ${formData.marginTop};
                        --size-margin-right: ${formData.marginRight};
                        --size-margin-bottom: ${formData.marginBottom};
                        --size-margin-left: ${formData.marginLeft};
                    `"
                >
                    <div class="layout-preview-inner-wrapper">
                        <div class="page">
                            <div class="guides">
                                <div class="guide page-top" />
                                <div class="guide page-right" />
                                <div class="guide page-bottom" />
                                <div class="guide page-left" />
                            </div>
                            <div class="header-area" />
                            <div class="content-area" />
                            <div class="footer-area" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <in-page-footer>
            <base-button
                :label="$t('common.term.cancel')"
                flat
                primary-button
                @click="cancel"
            />
            <base-button
                data-test="btn:submit"
                type="submit"
                :label="(currentMode === ViewMode.CREATE) ? $t('common.term.add') : $t('common.term.save')"
                :disable="formSubmitStatus || $v.formData.pageBackground.$error"
                :loading="formSubmitStatus"
                primary-button
            />
        </in-page-footer>
    </form>
</template>

<script>
import { ViewMode } from '@/enums'
import { required, maxLength } from 'vuelidate/lib/validators'
import { isMediaType } from '@/helpers/form'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import DOCUMENT_LAYOUT_FIELDS from '@/forms/document-layout.fields.json'

import LocalizedInput from '@/components/form/LocalizedInput.vue'
import FileUpload from '@/components/form/FileUpload.vue'

export default {
    name: 'DocumentLayoutForm',
    components: {
        LocalizedInput,
        FileUpload,
    },
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: DOCUMENT_LAYOUT_FIELDS,
        fields: ['marginTop', 'marginRight', 'marginBottom', 'marginLeft'],
    },
    props: {
        ownerId: {
            type: String,
            required: true,
        },
        documentLayout: {
            type: Object,
        },
    },
    data () {
        const data = {
            ViewMode,
            currentMode: (this.documentLayout) ? ViewMode.UPDATE : ViewMode.CREATE,
            formData: {
                name: {},
                marginTop: '45', // Margin defaults according to DIN 5008 B (https://upload.wikimedia.org/wikipedia/commons/0/00/DIN_5008_Form_B.svg).
                marginRight: '20',
                marginBottom: '20',
                marginLeft: '25',
                pageBackground: [],
            },
            allowedFileTypes: ['application/pdf'],
            removeCurrentPageBackground: false,
        }
        this.$store.state.user.tenantSettings.systemLanguages.forEach(systemLanguage => {
            data.formData.name[systemLanguage.id] = ''
        })

        if (data.currentMode === ViewMode.UPDATE) {
            Object.assign(data.formData, {
                name: this.documentLayout.name_language_versions,
                marginTop: this.documentLayout.page_margins['margin-top'].replace('mm', ''),
                marginRight: this.documentLayout.page_margins['margin-right'].replace('mm', ''),
                marginBottom: this.documentLayout.page_margins['margin-bottom'].replace('mm', ''),
                marginLeft: this.documentLayout.page_margins['margin-left'].replace('mm', ''),
            })
        }
        return data
    },
    validations () {
        const validations = {
            formData: {
                name: {},
                pageBackground: {
                    maxLength: maxLength(1),
                    $each: {
                        type: isMediaType(this.allowedFileTypes),
                    },
                },
            },
        }
        validations.formData.name[this.$store.state.user.tenantSettings.defaultSystemLanguage.id] = {
            required,
        }
        return validations
    },
    methods: {
        handleFormData (formData) {
            if (this.formData.pageBackground.length) {
                formData.pageBackground = this.formData.pageBackground[0] // Grab upload directly from formData, as the one processed by formBuilderMixin loses it's binary data. See TODO in formBuilderMixin.
            } else if (this.removeCurrentPageBackground) {
                formData.pageBackground = null // Sending 'null' to the API deletes the current pageBackground.
            } else {
                delete formData.pageBackground
            }
            this.$emit('post-handle-form-data', formData)
        },
        cancel () {
            this.$router.go(-1)
        },
    },
}
</script>

<style lang="scss">
.layout-preview-wrapper {
    width: 100% !important;
    margin: $sizeSpacingMd 0 0;
    padding: $sizeSpacingMd;
    background-color: var(--color-background-faded);
}

.layout-preview-inner-wrapper {
    min-width: 10em;
    width: 60%;
    max-width: 25em;

    margin: 0 auto;
}

.page {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: calc(var(--size-page-height) / var(--size-page-width) * 100%);

    border: 1px solid var(--color-border-primary);
    background-color: var(--color-background-primary);

    // TODO improvement: Find solution for (PDF as) background image (--> pdf.js)
}

.guides {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-bottom: calc(var(--size-page-height) / var(--size-page-width) * 100%);
}

.guide {
    $guidePositions: (
            ('top', 'horizontal'),
            ('right', 'vertical'),
            ('bottom', 'horizontal'),
            ('left', 'vertical')
    );
    position: absolute;
    border-width: 0;
    border-style: solid;
    border-color: var(--q-color-primary-light);

    &.page {
        @each $position, $orientation in $guidePositions {
            &-#{$position} {
                @if $orientation == 'horizontal' {
                    width: 100%;
                    height: 1px;
                    #{$position}: calc(var(--size-margin-#{$position}) / var(--size-page-height) * 100%);
                } @else {
                    width: 1px;
                    height: 100%;
                    #{$position}: calc(var(--size-margin-#{$position}) / var(--size-page-width) * 100%);
                }

                border-#{$position}-width: 1px;
            }
        }
    }
}

.content-area {
    position: absolute;
    top: calc(var(--size-margin-top) / var(--size-page-height) * 100%);
    right: calc(var(--size-margin-right) / var(--size-page-width) * 100%);
    bottom: calc(var(--size-margin-bottom) / var(--size-page-height) * 100%);
    left: calc(var(--size-margin-left) / var(--size-page-width) * 100%);
    overflow: hidden;

    border-width: 1px;
    border-style: solid;
    border-color: var(--q-color-red);
}

@media print {
    .page-margin-wrapper {
        background-color: transparent;
    }

    .page-margin-inner-wrapper {
        width: calc(100% / 6) !important;
    }

    .page {
        background-color: transparent;
    }

    .layout-preview-wrapper {
        margin-left: 0;
        margin-right: 0;
        padding: 0;

        background-color: transparent;
    }

    .layout-preview-inner-wrapper {
        width: calc((100% - (2 * #{$sizeSpacingMd})) / 3);
        margin: 0;
    }
}
</style>
