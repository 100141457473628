<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.term.correspondence', 1)" :to="{ name: 'admin-correspondence' }" />
                <q-breadcrumbs-el :label="$tc('common.correspondence.salutation.salutation', 2)" :to="{ name: 'admin-correspondence-salutation-list' }" />
                <q-breadcrumbs-el :label="$tc('views.admin.correspondence.salutation.add-salutation', 1)" :to="{ name: 'admin-correspondence-salutation-create' }" />
            </template>

            <h1>{{ $tc('views.admin.correspondence.salutation.add-salutation', 1) }}</h1>
        </page-header>

        <div class="row">
            <info-box>
                <p>{{ $tc('views.admin.correspondence.salutation.default-salutation--description', 1) }}</p>
            </info-box>
        </div>

        <div class="row">
            <div class="col-xs-12 col-md-10 col-lg-8 col-xl-7 q-col-gutter-sm">
                <correspondence-salutation-create-form ref="correspondenceSalutationCreateForm" @submit="onSubmit" />
            </div>
        </div>

        <in-page-footer>
            <base-button
                :label="$t('common.term.back')"
                flat
                primary-button
                @click="$router.go(-1)"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import CorrespondenceSalutationCreateForm from '@/components/correspondence/CorrespondenceSalutationCreateForm'
import { CorrespondenceSalutation } from '@/models/correspondenceSalutation'

export default {
    name: 'CorrespondenceSalutationCreate',
    meta () {
        return {
            title: this.$tc('views.admin.correspondence.salutation.add-salutation', 1),
        }
    },
    components: {
        CorrespondenceSalutationCreateForm,
    },
    methods: {
        onSubmit (formData) {
            CorrespondenceSalutation.create(formData)
                .then(() => {
                    this.$router.replace({ name: 'admin-correspondence-salutation-list' })
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.correspondence.correspondence-salutation-saved-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.correspondenceSalutationCreateForm.showFormErrorMessage(error)
                    this.$refs.correspondenceSalutationCreateForm.resetFormSubmitStatus()
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$tc('common.notifications.correspondence.correspondence-salutation-saved-error', 1),
                    })
                })
        },
    },
}
</script>
