import { i18n } from '@/i18n'
import kebabCase from 'lodash.kebabcase'
import { TargetObjectType } from '@/enums'

export function walkTree (item, callback) {
    callback(item)
    if (item.children && Array.isArray(item.children) && item.children.length) {
        item.children.forEach(child => {
            walkTree(child, callback)
        })
    }
}

export function returnValueFromFunctionCallOrArgument (thisArg, arg) {
    return (typeof arg === 'function') ? arg.apply(thisArg) : arg
}

export function getValueFromProperty (property, args) {
    return (typeof property === 'function') ? property(args) : property
}

// TODO refactor @TFU: This should be called something like `walkObject` or `traverseObject` (it actually has nothing to do with JSON).
export function walkJSON (node, callback, parent, path = '') {
    if (isPlainObject(node)) {
        for (let [key, value] of Object.entries(node)) {
            const currentPath = path.length ? `${path}.${key}` : key
            let callbackReturn = callback(value, key, node, parent, currentPath)
            if (callbackReturn === false) return false
            let walkReturn = walkJSON(value, callback, { value, key, node }, currentPath)
            if (walkReturn === false) return false
        }
    } else if (Array.isArray(node)) {
        node.forEach((value, index) => {
            const currentPath = path.length ? `${path}[${index}]` : index
            let callbackReturn = callback(value, index, node, parent, currentPath)
            if (callbackReturn === false) return false
            let walkReturn = walkJSON(value, callback, { value, index, node }, currentPath)
            if (walkReturn === false) return false
        })
    }
}

export function isPlainObject (obj) {
    // Basic check for type object that's not null
    if (typeof obj === 'object' && obj !== null) {
        const proto = Object.getPrototypeOf(obj)
        return proto === Object.prototype || proto === null
    }

    // Not an object
    return false
}

function throwReadOnlyError () {
    throw new Error('Read-only')
}

// TODO @TFU: Can be refactored using readonly() once upgraded to Vue 3?
export const readOnlyProxyHandler = {
    get (target, prop, receiver) {
        const originalValue = Reflect.get(...arguments)
        if (isPlainObject(originalValue)) return new Proxy(originalValue, this)
        return originalValue
    },
    set: throwReadOnlyError,
    defineProperty: throwReadOnlyError,
    deleteProperty: throwReadOnlyError,
    preventExtensions: throwReadOnlyError,
    setPrototypeOf: throwReadOnlyError,
}

export async function sha256 (str) {
    const buffer = await crypto.subtle.digest('SHA-256', new TextEncoder('utf-8').encode(str))
    return Array.prototype.map.call(new Uint8Array(buffer), x => (('00' + x.toString(16)).slice(-2))).join('')
}

export async function wrapBulkItems (items, { initialStatus }) {
    const bulkItemWrappers = []

    for (let i = 0; i < items.length; i++) {
        const item = items[i]
        const hash = await sha256(JSON.stringify(item))
        const bulkItemWrapper = {
            id: hash,
            status: initialStatus || null,
            item,
        }
        bulkItemWrappers.push(bulkItemWrapper)
    }

    return bulkItemWrappers
}

/**
 * Normalizes a given translation ID by converting its individual parts to kebab-case.
 * @param {string} translationId - The translation ID that should be normalized.
 * @returns {string}
 */
export function normalizeTranslationId (translationId) {
    return translationId
        .split('.')
        .map(translationIdPart => kebabCase(translationIdPart))
        .join('.')
}

/**
 * Returns the values of an array as a formatted (prose text) list. (F.e. [x, y, z] --> "x, y and z")
 * @param {Array} values - The array that should be formatted as text list.
 * @returns {string|*}
 */
export function textList (values) {
    // TODO improvement @TFU: Check if parts of this can be replaced with Intl.ListFormat. (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/ListFormat)
    return values.length > 1 ? `${values.slice(0, -1).join(', ')} ${i18n.t('common.term.and')} ${values.slice(-1)}` : values[0]
}

/**
 * Converts a given string to (the hue value of) a HSL color. (As used e.g. for the different `comment.creator` avatar colors.)
 * @param {string} str - The string that should be converted to a color.
 * @param {number} [saturation=100] - The saturation value of the returned color. (A value between 0 and 100; a percentage value is used for hsl().)
 * @param {number} [lightness=30] - The lightness value of the returned color. (A value between 0 and 100; a percentage value is used for hsl().)
 * @param {number} [alpha=1] - The alpha value / opacity of the returned color. (A value between 0 and 1; where 1 is opaque and 0 is transparent.)
 * @returns {string}
 */
export function stringToColor (
    str,
    {
        saturation = 100,
        lightness = 30,
        alpha = 1,
    } = {
        saturation: 100,
        lightness: 30,
        alpha: 1,
    }
) {
    let hash = 0
    let h = hash
    let s = (saturation < 0) ? 0 : (saturation > 100) ? 100 : saturation // Value must be between 0 and 100.
    let l = (lightness < 0) ? 0 : (lightness > 100) ? 100 : lightness // Value must be between 0 and 100.
    let a = (alpha < 0) ? 0 : (alpha > 1) ? 1 : alpha // Value must be between 0 and 1.

    if (str.length > 0) {
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash)
            hash = hash & hash // Convert to 32bit integer
        }

        h = hash % 360
    }

    return `hsl(${h}, ${s}%, ${l}%, ${a})`
}

export function stripHtmlTagsFromString (str) {
    return str.replace(/(<([^>]+)>)/gi, '')
}


// TODO: Refactor this when editor is ready/implemented (and [parts of] this can be replaced with micro moustache)
// TODO: Add check for missing translations (in such cases the placeholder is assumed to be invalid)
/**
 * Replaces placeholders [%= some.placeholder_field %] in a string with the localized name of the corresponding field.
 * @param {string} string - String in which the placeholders should be replaced with placeholder tags.
 * @param {Boolean} [showConditionalSpaceLabel=false] - Option to return the (localized) name of the field the condition is based on.
 * @param {string} [defaultTranslationBasePath=common.correspondence.smart-object] - Base path / first part of the translation ID.
 * @returns {string}
 */
export function stringWithPlaceholderTags (
    string,
    {
        showConditionalSpaceLabel = false,
        defaultTranslationBasePath = 'common.correspondence.smart-object',
    } = {
        showConditionalSpaceLabel: false,
        defaultTranslationBasePath: 'common.correspondence.smart-object', // i18n translation base path (folder)
    }
) {
    // Strip HTML tags.
    const strippedString = stripHtmlTagsFromString(string)

    // Create array of all found placeholders (text between '[%=' and '%]', ignoring whitespace inside of the placeholder tag).
    //
    // Regex (tested on and documentation from: https://regex101.com/):
    const regex = /(?<openingTag>\[%=\s*?)((?<conditionalSpace>\S*?__conditional_space)|(?<placeholder>\S*?))(?<closingTag>\s*%])/gm
    // Named Capture Group openingTag (?<openingTag>\[%=\s)
    //     \[ matches the character [ literally (case sensitive)
    //     %= matches the characters %= literally (case sensitive)
    //     \s matches any whitespace character (equivalent to [\r\n\t\f\v \u00a0\u1680\u2000-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff])
    //         *? matches the previous token between zero and unlimited times, as few times as possible, expanding as needed (lazy)
    // 2nd Capturing Group ((?<conditionalSpace>\S__conditional_space)|(?<placeholder>\S))
    //     1st Alternative (?<conditionalSpace>\S__conditional_space)
    //         Named Capture Group conditionalSpace (?<conditionalSpace>\S__conditional_space)
    //             \S matches any non-whitespace character (equivalent to [^\r\n\t\f\v \u00a0\u1680\u2000-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff])
    //                 *? matches the previous token between zero and unlimited times, as few times as possible, expanding as needed (lazy)
    //                 __conditional_space matches the characters __conditional_space literally (case sensitive)
    //     2nd Alternative (?<placeholder>\S)
    //         Named Capture Group placeholder (?<placeholder>\S)
    //             \S matches any non-whitespace character (equivalent to [^\r\n\t\f\v \u00a0\u1680\u2000-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff])
    //                 *? matches the previous token between zero and unlimited times, as few times as possible, expanding as needed (lazy)
    // Named Capture Group closingTag (?<closingTag>\s%])
    //     \s matches any whitespace character (equivalent to [\r\n\t\f\v \u00a0\u1680\u2000-\u200a\u2028\u2029\u202f\u205f\u3000\ufeff])
    //         * matches the previous token between zero and unlimited times, as many times as possible, giving back as needed (greedy)
    //     %] matches the characters %] literally (case sensitive)
    // Global pattern flags
    //     g modifier: global. All matches (don't return after first match)
    //     m modifier: multi line. Causes ^ and $ to match the begin/end of each line (not only begin/end of string)

    const placeholders = strippedString.matchAll(regex)
    const placeholderTags = []

    for (const item of placeholders) {
        // Accessing groups via destructuring `placeholder`
        // eslint-disable-next-line
        const { groups: { conditionalSpace, placeholder }, index } = item
        // Set item type
        item.type = conditionalSpace ? 'conditionalSpace' : 'placeholder'

        // Replace underscores with dashes and ensure everything is lowercase to be able to use consistent translation IDs
        const placeholderId = item.groups[`${item.type}`].toLowerCase().replace(/_/g, '-')
        // Create object to hold placeholder tag details
        const placeholderTag = {}

        // Set content depending on whether it’s a conditional space or a "real" placeholder
        if (conditionalSpace) {
            // Create placeholderId for the field the condition is based on (separated for better readability/maintainability.)
            const referencedPlaceholderId = placeholderId.replace('--conditional-space', '')

            // Check if content should show what field the conditional space is dependent on
            if (showConditionalSpaceLabel) {
                // Set localized placeholder name as element content
                // placeholderTag.content = i18n.tc('common.correspondence.smart-object.general.conditional-space--for-field', 1, { fieldName: i18n.tc(`${defaultTranslationBasePath}.${referencedPlaceholderId}`, 1) })
                placeholderTag.content = `&blank; <small>(${i18n.tc(defaultTranslationBasePath + '.' + referencedPlaceholderId, 1)})</small>`
            } else {
                // Set a space as element content
                placeholderTag.content = '&blank;'
            }
            // Push placeholder tag to array
            placeholderTags.push(`<span class="smart-object" data-smart-object="conditionalSpace" data-id="general.conditional-space" title="${i18n.tc('common.correspondence.smart-object.general.conditional-space--for-field--description', 1, { fieldName: i18n.tc(defaultTranslationBasePath + '.' + referencedPlaceholderId, 1) })}">${placeholderTag.content}</span>`)
        } else {
            // Set localized placeholder name as element content
            placeholderTag.content = i18n.tc(`${defaultTranslationBasePath}.${placeholderId}`, 1)
            // Push placeholder tag to array
            placeholderTags.push(`<span class="smart-object" data-smart-object="simplePlaceholder" data-id="${item.groups[`${item.type}`]}" title="${i18n.tc('common.correspondence.smart-object.general.placeholder--for-field', 1, { fieldName: i18n.tc(defaultTranslationBasePath + '.' + placeholderId, 1) })}">${placeholderTag.content}</span>`)
        }
    }

    // Replace placeholders in original string with content from array
    // Return new string with placeholder tags
    return strippedString.replace(regex, () => placeholderTags.shift())
}

/**
 * Returns the type of the given targetObject.
 * @param {Object} targetObject - Target object of which the type should be returned (based on its `.__typename`).
 * @param {string} targetObject.__typename - The targetObject’s `__typename`.
 * @returns {string}
 */
export function getTargetObjectType (targetObject) {
    const target_object_type = {
        'Person': TargetObjectType.CONTACT,
        'Company': TargetObjectType.CONTACT,
        'Application': TargetObjectType.APPLICATION,
        'Contract': TargetObjectType.CONTRACT,
    }

    return target_object_type[targetObject.__typename]
}


export async function fetchPaginatedObjects (configuration, ...fnArguments) {
    const currentPaginatorInfo = { currentPage: 0, hasMorePages: true }
    const results = []
    function* getNextPage() {
        while (currentPaginatorInfo.hasMorePages) {
            yield currentPaginatorInfo.currentPage + 1
        }
    }

    for await (const nextPage of getNextPage()) {
        Object.assign(fnArguments[0] || {}, { page: nextPage })
        const result = await configuration.fn(...fnArguments)
        results.push(...result.data)
        if (typeof configuration.maxPage !== 'undefined' && result.paginatorInfo.currentPage === configuration.maxPage) result.paginatorInfo.hasMorePages = false
        Object.assign(currentPaginatorInfo, result.paginatorInfo)
    }

    return results
}
