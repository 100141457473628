import gql from 'graphql-tag'

export const DOCUMENT_LAYOUT_CREATE_MUTATION = gql`
    mutation documentLayoutCreate(
        $ownerCompanyId: ID!
        $documentLayout: DocumentLayoutInput!
    ) {
        documentLayoutCreate(
            owner_company_id: $ownerCompanyId
            documentLayout: $documentLayout
        ) {
            id
            name
            name_language_versions
            owner {
                id
                company_name
            }
            pageBackground {
                name
            }
            page_margins
            is_default
            created_at
            updated_at
        }
    }
`
