<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('views.admin.users.roles-permissions.title')" :to="{ name: 'admin-user-roles-permissions' }" />
            </template>

            <h1>{{ $t('views.admin.users.roles-permissions.title') }}</h1>
        </page-header>

        <div class="row">
            <div class="col-12">
                <q-table
                    :data="permissions"
                    :columns="columns"
                    :pagination.sync="pagination"
                    :rows-per-page-options="[]"
                    row-key="id"
                    class="permissions-table"
                    flat
                    square
                    dense
                >
                    <template v-slot:header-cell="props">
                        <q-th :props="props">
                            <div class="table-th-content">
                                {{ props.col.label }}
                                <q-tooltip
                                    v-if="props.col.name !== 'permission'"
                                    anchor="top middle"
                                    self="center middle"
                                    :delay="1000"
                                    max-width="20rem"
                                >
                                    <b>{{ props.col.label }}</b><br>{{ props.col.description }}
                                </q-tooltip>
                            </div>
                        </q-th>
                    </template>

                    <template v-slot:body-cell="props">
                        <template v-if="props.row.isModule">
                            <q-td
                                v-if="props.col.name === 'permission'"
                                :props="props"
                                colspan="100"
                                class="in-table-heading"
                            >
                                {{ props.row.label }}
                            </q-td>
                        </template>
                        <q-td v-else :props="props">
                            <template v-if="props.col.name === 'permission'">
                                {{ props.row.name[locale] }}
                                <info-icon
                                    :title="props.row.name[locale]"
                                    :text="props.row.description[locale]"
                                    size="1em"
                                    icon-position-y-offset="0.175em"
                                />
                            </template>
                            <template v-else>
                                <span :class="`permission permission-${flags[props.col.name][props.row.key]}`">
                                    <q-icon v-if="flags[props.col.name][props.row.key] === true" name="mib-check-1" color="positive" />
                                    <span v-if="flags[props.col.name][props.row.key] === false" class="additional-info">–</span>

                                    <q-tooltip
                                        anchor="top middle"
                                        self="center middle"
                                        :delay="500"
                                        max-width="25rem"
                                    >
                                        <i18n :path="`views.admin.users.roles-permissions.role-x-has-permission-y--${flags[props.col.name][props.row.key]}`">
                                            <template v-slot:role><b>{{ props.col.label }}</b></template>
                                            <template v-slot:permission><b>{{ props.row.name[locale] }}</b></template>
                                        </i18n>
                                    </q-tooltip>
                                </span>
                            </template>
                        </q-td>
                    </template>
                </q-table>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import ROLES from '#/apollo-server/graphql/Users/roles.seeder.json'
import USER_PERMISSIONS from '#/apollo-server/graphql/Users/userPermissions.seeder.json'
import PRODUCT_PERMISSIONS from '#/apollo-server/graphql/Products/productPermissions.seeder.json'
import CONTACT_PERMISSIONS from '#/apollo-server/graphql/Contacts/contactPermissions.seeder.json'
import APPLICATION_PERMISSIONS from '#/apollo-server/graphql/Contracts/applicationPermissions.seeder.json'
import CONTRACT_PERMISSIONS from '#/apollo-server/graphql/Contracts/contractPermissions.seeder.json'
import DOCUMENT_PERMISSIONS from '#/apollo-server/graphql/Documents/documentPermissions.seeder.json'
import COMMENT_PERMISSIONS from '#/apollo-server/graphql/Comments/commentPermissions.seeder.json'
import CORRESPONDENCE_PERMISSIONS from '#/apollo-server/graphql/Correspondence/correspondencePermissions.seeder.json'
// import MAIL_PROCESSING_PERMISSIONS from '#/apollo-server/graphql/MailProcessing/mailProcessingPermissions.seeder.json'
import TASK_PERMISSIONS from '#/apollo-server/graphql/Tasks/taskPermissions.seeder.json'
import ACCOUNTING_PERMISSIONS from '#/apollo-server/graphql/Accounting/accountingPermissions.seeder.json'
import COMMISSION_PERMISSIONS from '#/apollo-server/graphql/Commissions/commissionPermissions.seeder.json'
// import ECOHUB_PERMISSIONS from '#/apollo-server/graphql/EcoHub/ecoHubPermissions.seeder.json'
import FILE_PERMISSIONS from '#/apollo-server/graphql/Files/filePermissions.seeder.json'

export default {
    name: 'AdminUserRolesPermissions',
    meta () {
        return {
            title: this.$t('views.admin.users.roles-permissions.title'),
        }
    },
    data () {
        // TODO: handle language switch; currently the view needs to be reloaded to change the locale used in the table
        const data = {
            locale: localStorage.locale,
            columns: [
                { name: 'permission', label: this.$tc('common.term.permission', 1), align: 'left' },
            ],
            pagination: {
                rowsPerPage: 0,
            },
            permissions: [
                { label: this.$tc('common.term.user', 2), isModule: true },
                ...USER_PERMISSIONS,

                { label: this.$tc('common.term.product', 2), isModule: true },
                ...PRODUCT_PERMISSIONS,

                { label: this.$tc('common.term.contact', 2), isModule: true },
                ...CONTACT_PERMISSIONS,

                { label: this.$tc('common.term.application', 2), isModule: true },
                ...APPLICATION_PERMISSIONS,

                { label: this.$tc('common.term.contract', 2), isModule: true },
                ...CONTRACT_PERMISSIONS,

                { label: this.$tc('common.term.document', 2), isModule: true },
                ...DOCUMENT_PERMISSIONS,

                { label: this.$tc('common.term.comment', 2), isModule: true },
                ...COMMENT_PERMISSIONS,

                { label: this.$tc('common.term.correspondence', 1), isModule: true },
                ...CORRESPONDENCE_PERMISSIONS,

                // { label: this.$tc('common.term.mail-processing', 1), isModule: true },
                // ...MAIL_PROCESSING_PERMISSIONS,

                { label: this.$tc('common.task.task', 2), isModule: true },
                ...TASK_PERMISSIONS,

                { label: this.$tc('common.accounting.accounting', 1), isModule: true },
                ...ACCOUNTING_PERMISSIONS,

                { label: this.$tc('common.commission.commission', 2), isModule: true },
                ...COMMISSION_PERMISSIONS,

                // { label: this.$tc('common.eco-hub.eco-hub', 1), isModule: true },
                // ...ECOHUB_PERMISSIONS,

                { label: `${this.$tc('common.drive.drive', 1)} (${this.$tc('common.file-storage.file-storage', 1)})`, isModule: true },
                ...FILE_PERMISSIONS,
            ],
            flags: {},
            highlightCol: '',
        }

        ROLES.forEach((role, index) => {
            // Create table columns
            data.columns.push({
                index,
                name: role.key,
                label: role.name[data.locale],
                description: role.description[data.locale],
                align: 'center',
            })

            // Prepare boolean flags
            data.flags[role.key] = {}
            data.permissions.forEach(permission => {
                if (!permission.isModule) data.flags[role.key][permission.key] = (role.permissions.includes(permission.key))
            })
        })

        return data
    },
}
</script>

<style lang="scss" scoped>
::v-deep .permissions-table {
    max-width: 100%;

    .q-table__middle {
        max-height: calc(100vh - 235px);
    }

    .q-table {
        overflow: clip;
        //overflow: hidden;
    }

    // Column highlighting
    th,
    td {
        &:not(:first-child):hover {
            &::after {
                content: "";
                position: absolute;
                top: -300em;
                left: -1px;
                width: calc(100% + 2px);
                height: 1000rem;
                z-index: 1000;
                border-left: 1px solid var(--color-border-secondary);
                border-right: 1px solid var(--color-border-secondary);
                background-color: rgba(0,0,0,0.06); // Quasar default color
            }
        }
    }

    // Sticky header
    tr {
        th {
            position: sticky;
            z-index: 20;
            border-right: 1px solid;
            border-color: var(--color-border-secondary);
            background: var(--color-background-primary);

            &:last-child {
                border-right: none;
            }

            &:nth-child(1n+2) {
                $sizeThHeight: 10em;
                width: 2em;
                padding: 0.5em;
                height: $sizeThHeight;
                vertical-align: bottom;

                .table-th-content {
                    position: absolute;
                    left: 50%;
                    width: $sizeThHeight;
                    height: auto;
                    transform: rotate(-90deg) translateY(-50%);
                    transform-origin: top left;
                    text-align: left;
                    word-break: break-word;
                    white-space: normal;
                }
            }
        }
    }

    td {
        padding: 0 1em !important;
        border-right: 1px solid;
        border-color: var(--color-border-secondary);
        width: 3em;

        //&:first-child {
        //    z-index: 10;
        //    position: sticky;
        //    left: 0;
        //}

        &:last-child {
            border-right: none;
        }
    }

    thead {
        tr {
            &:last-child {
                th {
                    top: 48px;
                    z-index: 30;
                }
            }

            &:first-child {
                th {
                    top: 0;
                    z-index: 10;
                }
            }
        }
    }
}

.permission {
    cursor: help;
}
</style>
