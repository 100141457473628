<template>
    <div class="license-plate" :class="`country-${countryCode.toLowerCase()} canton-${cantonCode.toLowerCase()}`">
        {{ licensePlate }}
    </div>
</template>

<script>
export default {
    name: 'LicensePlate',
    props: {
        countryCode: {
            type: String,
            default: 'CH',
        },
        licensePlate: {
            type: String,
            required: true,
        },
    },
    data () {
        return {}
    },
    computed: {
        cantonCode () {
            const swissCantonCodes = ['AG', 'AI', 'AR', 'BE', 'BL', 'BS', 'FR', 'GE', 'GL', 'GR', 'JU', 'LU', 'NE', 'NW', 'OW', 'SG', 'SH', 'SO', 'SZ', 'TG', 'TI', 'UR', 'VD', 'VS', 'ZG', 'ZH']
            const cantonCode = this.licensePlate.substr(0, 2).toUpperCase()

            if (this.countryCode.toUpperCase() === 'CH' && swissCantonCodes.includes(cantonCode)) {
                return cantonCode
            } else {
                return 'none'
            }
        },
    },
}
</script>

<style lang="scss" scoped>
// General styling of number plates
.license-plate {
    display: inline-block;
    position: relative;
    margin-top: -2px;
    padding: ($sizeSpacingXs / 2) $sizeSpacingXs;

    border: 1px solid var(--color-border-secondary);
    border-radius: $sizeSpacingXs;
    background-color: var(--color-white);
    box-shadow: $defaultBoxShadow;

    font-weight: bold;
    white-space: nowrap;

    // Country specific styling

    // Switzerland
    $swissCantonCodes: AG, AI, AR, BE, BL, BS, FR, GE, GL, GR, JU, LU, NE, NW, OW, SG, SH, SO, SZ, TG, TI, UR, VD, VS, ZG, ZH;

    &.country-ch {
        $sizeCoatOfArmsWidth: 10px;

        padding-left: pxToRem($sizeCoatOfArmsWidth * 2);
        padding-right: pxToRem($sizeCoatOfArmsWidth * 2);

        &::before,
        &::after {
            content: "";
            position: absolute;
            width: pxToRem($sizeCoatOfArmsWidth);
            height: 100%;

            background: 50% 50% no-repeat;
            background-size: pxToRem($sizeCoatOfArmsWidth);
        }

        &::before {
            left: pxToRem($sizeCoatOfArmsWidth / 2);
            top: 0;
            // TODO: Find better solution for ../../ path
            background-image: url("../../assets/img/coat_of_arms/CH.svg");
        }

        &::after {
            right: pxToRem($sizeCoatOfArmsWidth / 2);
            top: 0;
        }

        // Coat of arms of individual cantons
        @each $canton in $swissCantonCodes {
            &.canton-#{to-lower-case($canton)} {
                &::after {
                    // TODO: Find better solution for ../../ path
                    background-image: url("../../assets/img/coat_of_arms/#{to-upper-case($canton)}.svg");
                }
            }
        }
    }

    // TODO: Re-implement this if there should be non-CH cars in the system
    // Countries with European number plates
    $euroNumberPlateCountries: (
        AT: "A",
        BE: "B",
        BG: "BG",
        CY: "CY",
        CZ: "CZ",
        DE: "D",
        DK: "DK",
        EE: "EST",
        ES: "E",
        FI: "FIN",
        FR: "F",
        GB: "GB",
        GR: "GR",
        HR: "HR",
        HU: "H",
        IE: "IRL",
        IT: "I",
        LT: "LT",
        LU: "L",
        LV: "LV",
        MT: "M",
        NL: "NL",
        PL: "PL",
        PT: "P",
        RO: "RO",
        SE: "S",
        SI: "SLO",
        SK: "SK",
    );

    // TODO improvement: Tidy up code so that less code is duplicated in loop
    @each $country, $abbreviation in $euroNumberPlateCountries {
        $sizeCountryAbbr: 15px;
        $colorCountryBackground: #039;

        &.country-#{to-lower-case($country)} {
            padding-left: pxToRem($sizeCountryAbbr + 5);

            &::before {
                content: "#{$abbreviation}";
                position: absolute;
                top: 0;
                left: 0;
                width: pxToRem($sizeCountryAbbr);
                height: 100%;
                padding: 0 pxToRem(2);

                border-radius: pxToRem(3) 0 0 pxToRem(3);
                // TODO: Find better solution for ../../ path
                background: $colorCountryBackground url("../../assets/img/flag_of_europe.svg") 50% 1px no-repeat;
                background-size: pxToRem($sizeCountryAbbr);

                color: var(--color-white);
                font-size: pxToRem(6);
                line-height: pxToRem(32);
                text-align: center;
            }
        }
    }
}
</style>
