<template>
    <q-stepper
        v-model="wizard.currentStepKey"
        v-bind="$attrs"
        flat
    >
        <q-step
            v-for="(step, index) in wizard.steps"
            :key="step.key"
            :name="step.key"
            :title="step.configuration.title"
            :caption="step.configuration.caption"
            :icon="step.configuration.icon"
            :active-icon="step.configuration.activeIcon"
            :done-icon="step.configuration.doneIcon"
            :done-color="step.configuration.doneColor"
            :done="wizard.currentStepIndex > index"
        >
            <slot :name="`step-${step.key}`" v-bind="{ step }" />
        </q-step>
    </q-stepper>
</template>

<script>
export default {
    name: 'WizardComponent',
    props: {
        wizard: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .q-stepper__step-inner.q-stepper__step-inner {
    @include mq($from: $sizeBreakpointLg) {
        padding-left: 0;
        padding-right: 0;
    }
}

::v-deep .q-stepper--vertical .q-stepper__step-inner.q-stepper__step-inner {
    padding-bottom: 1rem;
}
</style>
