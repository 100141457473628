import gql from 'graphql-tag'

export function buildProductDerivedEntitiesQuery (fields) {
    return gql`
        query productDerivedEntities(
            $page: Int = 1
            $count: Int = 1000
            $orderField: ProductDerivedEntityOrderField = PRODUCT_NAME
            $orderDirection: OrderDirection = DESC
            $filterQuery: String
            $filterCustomerId: ID
            $filterConsultantId: ID
            $filterEntityTypes: [ProductDerivedEntityType]
            $filterContractStatus: [ContractStatus]
            $filterApplicationStatus: [ApplicationStatus]
        ) {
            productDerivedEntities(
                page: $page
                count: $count
                order: [{ field: $orderField, direction: $orderDirection }]
                search: {
                    query: $filterQuery,
                    customer_id: $filterCustomerId
                    consultant_id: $filterConsultantId
                    entity_types: $filterEntityTypes
                    contract_status: $filterContractStatus
                    application_status: $filterApplicationStatus
                }
            ) {
                paginatorInfo {
                    count
                    firstItem
                    lastItem
                    total
                    currentPage
                    lastPage
                    perPage
                    hasMorePages
                }
                data {
                    ${fields}
                }
            }
        }
    `
}

const productDerivedEntityFields = `
    id
    currentContractInformation {
        product {
            name
            basicProvider {
                name
                display_name
            }
        }
        productTemplate {
            name
            category {
                name
            }
            subCategory {
                name
            }
            version
            form_configuration
        }
        product_field_values
        start_date
        end_date
        premium
        generalAgency {
            id
            company_name
        }
        managed_by_status
        consulting_mandate_enquiry_send_date
    }
    created_at
    updated_at
`

const defaultProductDerivedEntityQueryFields = `
    ... on Application {
        number {
            number
        }
        applicationStatus: status
        applicationConsultant: consultant {
            id
            first_name
            last_name
            consultingCompany {
                id
                company_name
            }
        }
        external_reference_number
        is_change_application
        ${productDerivedEntityFields}
    }
    ... on Contract {
        contractNumbers {
            number
        }
        contractStatus: status
        contractConsultant: consultant {
            id
            first_name
            last_name
            consultingCompany {
                id
                company_name
            }
        }
        cancellations {
            id
            status
            cancellation_date
        }
        commissionRecipientOverride {
            id
            first_name
            last_name
            contact_number
            gender
            title
            consultingCompany {
                id
            }
            consulting_company_name
        }
        commissionSplits {
            id
            is_active
        }
        ${productDerivedEntityFields}
    }
`

export const PRODUCT_DERIVED_ENTITIES_QUERY = buildProductDerivedEntitiesQuery(defaultProductDerivedEntityQueryFields)
