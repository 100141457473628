import { Form, FormField } from '@/libs/form'

export class EcoHubTechUserForm extends Form {
    compose () {
        this.addField(new FormField('idpUserId', {
            label: 'forms.field.label.eco-hub.tech-user.user-id',
            type: 'text',
            validators: [
                { type: 'required' },
            ],
        }))

        this.addField(new FormField('password', {
            label: 'forms.field.label.eco-hub.tech-user.password',
            type: 'password',
            validators: [
                { type: 'required' },
            ],
        }))

        this.addField(new FormField('iak', {
            label: 'forms.field.label.eco-hub.tech-user.iak',
            type: 'text',
            validators: [
                { type: 'required' },
            ],
        }))
    }
}

