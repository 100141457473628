import { Model } from '@/models'
import { EditorContent } from '@/models/models'

import { i18n } from '@/i18n'
import { lightFormat, parseISO, toISOString } from 'date-fns'
import { ContactService } from '@/services'
import { ContactStatus, ContactType, AddressStatus } from '@/enums/graphql'
import { ContactActions } from '@/enums'
import { getContactTypeIcon, getContactGender, getContactName, getContactInitials, getContactAddress, getAge } from '@/helpers/contact'
import { getTargetObjectType } from '@/helpers'
import { formatDate } from '@/helpers/date'
import { UserSettings } from '@/helpers/user'
import { saveAs } from 'file-saver'
import store from '@/store/store'
import Vue from 'vue'

export class Contact extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'contactNumber',
            'status',
            'customer_status',
            'consultingSettings',
            'productProviderSettings',
            'picture',
            'addresses',
            'mainAddress',
            'emailAddresses',
            'mainEmailAddress',
            'phoneNumbers',
            'mainPhoneNumber',
            'consultants',
            'website',
            'customer_since',
            'customer_until',
            'pinnedComments',
            'correspondenceLanguage',
            'contactConsultantRelationSettings',
            'number_of_contracts',
            'number_of_contact_files',
            'number_of_comments',

            // Meta
            '__typename',
        ], data)

        // Sorting
        if (this.addresses) {
            this.addresses.sort((a, b) => {
                if (a.is_main_correspondence_address) return -1
                if (b.is_main_correspondence_address) return 1
                if (a.status === AddressStatus.ACTIVE && b.status === AddressStatus.INACTIVE) return -1
                if (a.status === AddressStatus.INACTIVE && b.status === AddressStatus.ACTIVE) return 1
                if (a.status === AddressStatus.INACTIVE && b.status === AddressStatus.INACTIVE) {
                    if (a.isNotValidYet && b.isNoLongerValid) return -1
                    if (a.isNoLongerValid && b.isNotValidYet) return 1
                    return 0
                }
                return 0
            })
        }
        if (this.emailAddresses) this.emailAddresses.sort(a => (a.is_main_email_address) ? -1 : 0)
        if (this.phoneNumbers) this.phoneNumbers.sort(a => (a.is_main_phone_number) ? -1 : 0)

        // Transitions
        this.statusTransitions[ContactStatus.ACTIVE] = [ContactStatus.ARCHIVED]
        this.statusTransitions[ContactStatus.ARCHIVED] = [ContactStatus.ACTIVE]

        this.statusActionMapping[ContactStatus.ACTIVE] = ContactActions.UNARCHIVE
        this.statusActionMapping[ContactStatus.ARCHIVED] = ContactActions.ARCHIVE
    }

    static get service () { return ContactService }
    static status = ContactStatus
    static action = ContactActions
    static type = ContactType

    get isPerson () {
        return this.type === ContactType.PERSON
    }

    get isCompany () {
        return this.type === ContactType.COMPANY
    }

    get formattedCustomerSince () {
        return formatDate(this.customer_since)
    }

    get formattedCustomerUntil () {
        return formatDate(this.customer_until)
    }

    get typeIcon () {
        return getContactTypeIcon(this)
    }

    getContactName (args) {
        return getContactName(this, args)
    }

    getContactInitials (args) {
        return getContactInitials(this, args)
    }

    getContactAddress (address, args) {
        return getContactAddress(address, args)
    }

    getConsultantNames (args) {
        const consultantNames = []

        this.consultants.forEach(consultant => {
            // TODO improvement: Wrap consultingCompanyName in span.additonal-info
            // By default, the consultingCompanyName is shown if a consultant does not work for the same consultingCompany as the current user. It can explicitly be forced or disabled for all entries.
            consultantNames.push(consultant.getContactName({ consultingCompanyName: args.consultingCompanyName ?? !consultant.worksForUserConsultingCompany }))
        })

        if (args.multiline) {
            return consultantNames.join('\n')
        } else {
            return consultantNames.join(', ')
        }
    }

    get activeConsultants () {
        return this.consultants.filter(consultant => {
            if (consultant.isActive) return consultant
        })
    }

    get inactiveConsultants () {
        return this.consultants.filter(consultant => {
            if (!consultant.isActive) return consultant
        })
    }

    get archivedConsultants () {
        return this.consultants.filter(consultant => consultant.status === ContactStatus.ARCHIVED)
    }

    get validAddresses () {
        return this.addresses.filter(address => address.isValid === true)
    }

    get invalidAddresses () {
        return this.addresses.filter(address => address.isValid === false)
    }

    get targetObjectType () {
        return getTargetObjectType(this)
    }

    get actions () {
        const actions = super.actions
        actions.push({
            key: ContactActions.DELETE,
            item: this,
        })

        if (this.export) {
            const userSettings = new UserSettings(store.state.user)
            if (['console', 'file'].includes(userSettings.getItem('Contact.export'))) {
                actions.push({
                    key: ContactActions.EXPORT,
                    item: this,
                })
            }
        }

        return actions
    }

    archive () {
        const variables = {
            id: this.id,
            status: ContactStatus.ARCHIVED,
        }
        return ContactService.changeStatus(variables).then(contact => {
            this.status = contact.status
            return contact
        })
    }

    unarchive (consultantIds) {
        const variables = {
            id: this.id,
            status: ContactStatus.ACTIVE,
        }
        if (consultantIds) variables.consultantIds = consultantIds
        return ContactService.changeStatus(variables).then(contact => {
            Object.assign(this, contact)
            return contact
        })
    }

    delete () {
        return ContactService.delete(this.id)
    }

    updateContactNumber (contactNumberValueStructure) {
        return ContactService.updateContactNumber(this.id, contactNumberValueStructure).then(contact => {
            Object.assign(this, contact)
            return contact
        })
    }

    /**
     * Update the current consultant's ContactConsultantRelationSettings of this contact.
     * @param {Object} variables - The values used to update the correspondenceLetter.
     * @param {string} variables.formality
     * @param {string} variables.correspondenceSalutationOverride
     * @param {string} variables.correspondenceSalutationId
     * @returns {Promise<*>}
     */
    updateConsultantRelationSettings ({ formality, correspondenceSalutationOverride, correspondenceSalutationId }) {
        const variables = {
            contact_id: this.id,
            formality,
        }

        if (new Vue().$can('Feature:correspondence:core')) {
            variables.correspondence_salutation_override = (correspondenceSalutationOverride.contentHTML.length) ? new EditorContent(correspondenceSalutationOverride).getVariablesObject() : null
            variables.correspondence_salutation_id = correspondenceSalutationId
        }

        return ContactService.updateConsultantRelationSettings(variables).then(contactConsultantRelationSettings => {
            this.contactConsultantRelationSettings ? Object.assign(this.contactConsultantRelationSettings, contactConsultantRelationSettings) : this.contactConsultantRelationSettings = contactConsultantRelationSettings
            return contactConsultantRelationSettings
        })
    }
}

export class Person extends Contact {
    constructor (data) {
        super(data)
        Model.initializeFields(this, [
            'gender',
            'personal_pronoun',
            'title',
            'profession',
            'role',
            'first_name',
            'last_name',
            'nationality',
            'civil_status',
            'driving_licence_since',
            'date_of_birth',
            'date_of_death',
            'is_deceased',
            'user',
        ], data)
        this.type = ContactType.PERSON
    }

    static create ({
        // Contact
        customerStatus,
        website,
        customerSince,
        consultants,
        correspondenceLanguageId,

        // Person
        gender,
        personalPronoun,
        title,
        profession,
        role,
        firstName,
        lastName,
        nationality,
        civilStatus,
        drivingLicenceSince,
        dateOfBirth,

        // Address
        addresses,

        // EmailAddress
        emailAddresses,

        // PhoneNumber
        phoneNumbers,
    }) {
        const variables = {
            type: ContactType.PERSON,
            customerStatus: customerStatus,
            customerSince: customerSince || null,
            website: website,
            consultantIds: consultants,
            correspondenceLanguageId,
            personInput: {
                gender: gender,
                personal_pronoun: personalPronoun,
                title: title,
                profession: profession,
                role: role,
                first_name: firstName,
                last_name: lastName,
                nationality_id: nationality,
                civil_status: civilStatus,
                driving_licence_since: drivingLicenceSince || null,
                date_of_birth: dateOfBirth || null,
            },
            addressInputs: [],
        }

        addresses.forEach((address, index) => {
            variables.addressInputs.push({
                category_id: address.category,
                label_id: address.label,
                po_box: address.poBox,
                address1: address.address1,
                address2: address.address2,
                address3: address.address3,
                zip: address.zip,
                city: address.city,
                valid_from: address.validFrom,
                valid_until: address.validUntil || null,
                is_main_correspondence_address: index === 0,
                country_id: address.country,
            })
        })

        if (emailAddresses && emailAddresses.length) {
            variables.emailAddressInputs = []
            emailAddresses.forEach((emailAddress, index) => {
                variables.emailAddressInputs.push({
                    category_id: emailAddress.category,
                    label_id: emailAddress.label,
                    email: emailAddress.emailAddress,
                    is_main_email_address: index === 0,
                })
            })
        }

        if (phoneNumbers && phoneNumbers.length) {
            variables.phoneNumberInputs = []
            phoneNumbers.forEach((phoneNumber, index) => {
                variables.phoneNumberInputs.push({
                    category_id: phoneNumber.category,
                    label_id: phoneNumber.label,
                    phone_number: phoneNumber.phoneNumber,
                    is_main_phone_number: index === 0,
                })
            })
        }

        return ContactService.create(variables)
    }

    get isConsultant () {
        return !!this.consultingSettings
    }

    get isCurrentUser () {
        return this.id === store.state.user.consultant?.id
    }

    get consultingCompanyName () {
        return this.consultingSettings?.company?.company_name ?? ''
    }

    get worksForUserConsultingCompany () {
        return this.consultingSettings?.company?.id && this.consultingSettings?.company?.id === store.state.user.consultant?.consultingCompany?.id
    }

    get formattedDrivingLicenceSince () {
        return formatDate(this.driving_licence_since)
    }

    get formattedDateOfBirth () {
        return formatDate(this.date_of_birth)
    }

    get formattedDateOfDeath () {
        return formatDate(this.date_of_death)
    }

    get age () {
        return getAge(this.date_of_birth)
    }

    getAge (ageByDate) {
        return getAge(this.date_of_birth, ageByDate)
    }

    getGender (args) {
        return getContactGender(this, args)
    }

    update ({
        // Contact
        website,
        customerStatus,
        customerSince,
        customerUntil,
        consultants,
        correspondenceLanguageId,

        // Person
        gender,
        personalPronoun,
        title,
        profession,
        role,
        firstName,
        lastName,
        nationality,
        civilStatus,
        drivingLicenceSince,
        dateOfBirth,
    }) {
        const variables = {
            input: {
                id: this.id,
                website: website,
                customer_status: customerStatus,
                customer_since: customerSince || null,
                customer_until: customerUntil || null,
                consultant_ids: consultants,
                correspondence_language_id: correspondenceLanguageId,
                person: {
                    gender: gender,
                    personal_pronoun: personalPronoun,
                    title: title,
                    profession: profession,
                    role: role,
                    first_name: firstName,
                    last_name: lastName,
                    nationality_id: nationality,
                    civil_status: civilStatus,
                    driving_licence_since: drivingLicenceSince || null,
                    date_of_birth: dateOfBirth || null,
                    // date_of_death: formData.dateOfDeath
                },
            },
        }

        return ContactService.update(variables)
    }
}

export class Company extends Contact {
    constructor (data) {
        super(data)
        Model.initializeFields(this, [
            'cooperation_status',
            'company_name',
            'uid',
            'is_liable_to_vat',
            'is_tenant_company',
        ], data)
        this.type = ContactType.COMPANY
    }

    get isProductProvider () {
        return !!this.productProviderSettings
    }

    get isConsultingCompany () {
        return !!this.consultingSettings
    }

    static create ({
        // Contact
        website,
        customerStatus,
        customerSince,
        consultants,
        correspondenceLanguageId,

        // Company
        companyName,
        uid,
        isLiableToVat,

        // Address
        addresses,

        // EmailAddress
        emailAddresses,

        // PhoneNumber
        phoneNumbers,
    }) {
        const variables = {
            type: ContactType.COMPANY,
            customerStatus: customerStatus,
            customerSince: customerSince || null,
            website: website,
            consultantIds: consultants,
            correspondenceLanguageId,
            companyInput: {
                company_name: companyName,
                uid: uid,
                is_liable_to_vat: isLiableToVat,
            },
            addressInputs: [],
        }

        addresses.forEach((address, index) => {
            variables.addressInputs.push({
                category_id: address.category,
                label_id: address.label,
                po_box: address.poBox,
                address1: address.address1,
                address2: address.address2,
                address3: address.address3,
                zip: address.zip,
                city: address.city,
                valid_from: address.validFrom,
                valid_until: address.validUntil || null,
                is_main_correspondence_address: index === 0,
                country_id: address.country,
            })
        })

        if (emailAddresses && emailAddresses.length) {
            variables.emailAddressInputs = []
            emailAddresses.forEach((emailAddress, index) => {
                variables.emailAddressInputs.push({
                    category_id: emailAddress.category,
                    label_id: emailAddress.label,
                    email: emailAddress.emailAddress,
                    is_main_email_address: index === 0,
                })
            })
        }

        if (phoneNumbers && phoneNumbers.length) {
            variables.phoneNumberInputs = []
            phoneNumbers.forEach((phoneNumber, index) => {
                variables.phoneNumberInputs.push({
                    category_id: phoneNumber.category,
                    label_id: phoneNumber.label,
                    phone_number: phoneNumber.phoneNumber,
                    is_main_phone_number: index === 0,
                })
            })
        }

        return ContactService.create(variables)
    }

    update ({
        // Contact
        website,
        customerStatus,
        customerSince,
        customerUntil,
        consultants,
        correspondenceLanguageId,

        // Company
        companyName,
        uid,
        isLiableToVat,
    }) {
        const variables = {
            input: {
                id: this.id,
                website: website,
                customer_status: customerStatus,
                customer_since: customerSince || null,
                customer_until: customerUntil || null,
                consultant_ids: consultants, // TODO: refactor to consultantIds
                correspondence_language_id: correspondenceLanguageId,
                company: {
                    company_name: companyName,
                    uid: uid,
                    is_liable_to_vat: isLiableToVat,
                },
            },
        }
        return ContactService.update(variables)
    }

    export () {
        const exportObject = {
            // Contact
            id: this.id,
            contactNumber: this.contactNumber.number,
            status: this.status,
            website: this.website,
            customerStatus: this.customer_status,
            customerSince: this.customer_since,
            customerUntil: this.customer_until,
            consultants: this.consultants.map(consultant => {
                return {
                    id: consultant.id,
                    contactNumber: consultant.contact_number,
                    firstName: consultant.first_name,
                    lastName: consultant.last_name,
                    gender: consultant.gender,
                    title: consultant.title,
                    consultingCompanyName: consultant.consulting_company_name,
                    consultingSettingsStatus: consultant.consulting_settings_status,
                }
            }),
            correspondenceLanguage: {
                id: this.correspondenceLanguage.id,
                name: this.correspondenceLanguage.name,
            },

            // Company
            companyName: this.company_name,
            uid: this.uid,
            isLiableToVat: this.is_liable_to_vat,

            // Timestamps
            createdAt: this.created_at,
            updatedAt: this.updated_at,
        }

        // Addresses
        if (this.addresses.length) {
            exportObject.addresses = this.addresses.map(address => {
                const output = {
                    id: address.id,
                    category: {
                        id: address.category.id,
                        key: address.category.key,
                        name: address.category.name,
                    },
                    label: null,
                    poBox: address.po_box,
                    address1: address.address1,
                    address2: address.address2,
                    address3: address.address3,
                    zip: address.zip,
                    city: address.city,
                    country: {
                        id: address.country.id,
                        name: address.country.name,
                    },
                    validFrom: address.valid_from,
                    validUntil: address.valid_until,
                    isMain: address.is_main_correspondence_address,
                }

                if (address.label) {
                    output.label = {
                        id: address.label.id,
                        key: address.label.key,
                        label: address.label.label,
                    }
                }

                return output
            })
        }

        // Email addresses
        if (this.emailAddresses.length) {
            exportObject.emailAddresses = this.emailAddresses.map(emailAddress => {
                const output = {
                    id: emailAddress.id,
                    category: {
                        id: emailAddress.category.id,
                        key: emailAddress.category.key,
                        name: emailAddress.category.name,
                    },
                    label: null,
                    email: emailAddress.email,
                    isMain: emailAddress.is_main_email_address,
                }

                if (emailAddress.label) {
                    output.label = {
                        id: emailAddress.label.id,
                        key: emailAddress.label.key,
                        label: emailAddress.label.label,
                    }
                }

                return output
            })
        }

        // Phone numbers
        if (this.phoneNumbers.length) {
            exportObject.phoneNumbers = this.phoneNumbers.map(phoneNumber => {
                const output = {
                    id: phoneNumber.id,
                    category: {
                        id: phoneNumber.category.id,
                        key: phoneNumber.category.key,
                        name: phoneNumber.category.name,
                    },
                    label: null,
                    phoneNumber: phoneNumber.phone_number,
                    isMain: phoneNumber.is_main_phone_number,
                }

                if (phoneNumber.label) {
                    output.label = {
                        id: phoneNumber.label.id,
                        key: phoneNumber.label.key,
                        label: phoneNumber.label.label,
                    }
                }

                return output
            })
        }

        // Product provider settings
        if (this.productProviderSettings) {
            exportObject.productProviderSettings = {
                status: this.productProviderSettings.status,
                displayName: this.productProviderSettings.display_name,
            }
        }

        const userSettings = new UserSettings(store.state.user)
        switch (userSettings.getItem('Contact.export')) {
            case 'console':
                console.log(JSON.stringify(exportObject, null, 4))
                break
            case 'file':
                saveAs(new Blob([JSON.stringify(exportObject, null, 4)], { type: 'application/json;charset=utf-8' }), `MAX Contact – ${this.getContactName()}.json`)
                break
        }
    }
}
