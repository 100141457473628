import gql from 'graphql-tag'

export const COMMISSION_LIST_CREATE_MUTATION = gql`
    mutation commissionListCreate(
        $type: CommissionListType!
        $file: Upload!
        $commissionList: CommissionListInput!
        $triggerImport: Boolean = false
    ) {
        commissionListCreate(
            type: $type
            file: $file
            commissionList: $commissionList
            trigger_import: $triggerImport
        ) {
            id
            productProvider {
                id
            }
            source
            type
            status
            file {
                id
            }
            filename
            excelMapping {
                id
            }
            file_import_identification_number
            exchange_date_from
            exchange_date_to
            commission_total
            total_amount_of_imported_commission
            total_amount_of_imported_commission_grouped_by_commission_type
            total_number_of_commission_entries
            total_number_of_imported_commission_entries
            total_number_of_commission_list_import_errors
            created_at
            updated_at
            posted_at
        }
    }
`
