<template>
    <info-box type="warning" :inline-actions="$q.screen.gt.sm" class="do-not-print">
        <h5>{{ $t('common.product.product-template-diff.title') }}</h5>
        <p>{{ $t('common.product.product-template-diff.description') }}</p>

        <h5>
            {{ $tc('common.term.change', state.diffs.length) }}
            <i18n path="common.product.product-template-diff.version-comparison" tag="span" class="subtitle">
                <template v-slot:currentVersion>{{ productDerivedEntity.currentContractInformation.productTemplate.version }}</template>
                <template v-slot:newVersion><b>{{ productDerivedEntity.currentContractInformation.product.template.version }}</b></template>
            </i18n>
        </h5>
        <ul class="diff dense q-mb-none">
            <li v-for="(diff, index) in state.diffs" :key="index" :class="getDiffChange(diff)">
                <i18n :path="`common.product.product-template-diff.text-${getDiffChange(diff)}`">
                    <template v-slot:field><b>{{ diff.label }}</b></template>
                </i18n>
            </li>
        </ul>
        <template v-slot:action>
            <base-button
                :label="$t('common.term.update-action')"
                icon="mib-common-file-text-sync"
                primary-button
                @click="triggerUpdateToProductTemplateVersion"
            />
            <form-dialog
                ref="updateToProductTemplateVersionDialog"
                double-confirm
                dont-close-on-confirm
                @confirm="handleUpdateToProductTemplateVersion"
            >
                <template v-slot:title>{{ $t('common.product.product-template-diff.confirmation-title') }}</template>
                <template v-slot:default>
                    <i18n path="common.product.product-template-diff.version-comparison" tag="p">
                        <template v-slot:currentVersion>{{ productDerivedEntity.currentContractInformation.productTemplate.version }}</template>
                        <template v-slot:newVersion><b>{{ productDerivedEntity.currentContractInformation.product.template.version }}</b></template>
                    </i18n>

                    <p>{{ $t('common.product.product-template-diff.confirmation-description') }}</p>
                </template>
            </form-dialog>
        </template>
    </info-box>
</template>

<script>
import { reactive, ref } from 'vue'
import { ProductTemplate } from '@/models/productTemplate'
import FormDialog from '@/components/form/FormDialog'

export default {
    name: 'ProductTemplateDiff',
    components: {
        FormDialog,
    },
    props: {
        productDerivedEntity: {
            type: Object,
            required: true,
        },
    },
    setup (props) {
        // Data
        const state = reactive({
            diffs: ProductTemplate.compareVersions(props.productDerivedEntity.currentContractInformation.productTemplate, props.productDerivedEntity.currentContractInformation.product.template),
        })

        // Template refs
        const updateToProductTemplateVersionDialog = ref(null)

        // Methods
        function triggerUpdateToProductTemplateVersion () {
            updateToProductTemplateVersionDialog.value.open()
        }
        function handleUpdateToProductTemplateVersion () {
            props.productDerivedEntity.updateToProductTemplateVersion(props.productDerivedEntity.currentContractInformation.product.template.version)
                .then(productDerivedEntity => {
                    Object.assign(props.productDerivedEntity.currentContractInformation.product.template, productDerivedEntity.currentContractInformation.product.template)
                    Object.assign(props.productDerivedEntity.currentContractInformation.productTemplate, productDerivedEntity.currentContractInformation.productTemplate)
                    updateToProductTemplateVersionDialog.value.close()
                })
                .catch(error => {
                    updateToProductTemplateVersionDialog.value.showFormErrorMessage(error)
                    updateToProductTemplateVersionDialog.value.resetFormSubmitStatus()
                })
        }
        function getDiffChange (diff) {
            switch (diff.modification) {
                case 'addField':
                    return diff.required ? 'mandatory-field-added' : 'field-added'
                case 'removeField':
                    return diff.required ? 'mandatory-field-removed' : 'field-removed'
                case 'addMandatory':
                    return 'mandatory-now'
                case 'removeMandatory':
                    return 'not-mandatory-anymore'
            }
        }

        return {
            // Static
            ProductTemplate,

            // Data
            state,

            // Template refs,
            updateToProductTemplateVersionDialog,

            // Methods
            triggerUpdateToProductTemplateVersion,
            handleUpdateToProductTemplateVersion,
            getDiffChange,
        }
    },
}
</script>
