import { ref } from 'vue'
import { extractErrorMessage } from '@/helpers/form'
import { readOnlyProxyHandler } from '@/helpers'

export function useFormBuilder(context, form) {
    // Data
    const formSubmitStatus = ref(false)
    const formErrorMessage = ref('')
    let formDataHandler = null

    // Methods
    function resetFormSubmitStatus () {
        formSubmitStatus.value = false
    }

    function resetFormErrorMessage () {
        formErrorMessage.value = ''
    }

    function showFormErrorMessage (error) {
        formErrorMessage.value = extractErrorMessage(error)
    }

    function setFormDataHandler (formDataHandlerFn) {
        if (typeof formDataHandlerFn === 'function') formDataHandler = formDataHandlerFn
    }

    async function processFormSubmit () {
        resetFormErrorMessage()
        const isValid = await form.validate()
        if (isValid) {
            formSubmitStatus.value = true
            return new Proxy(form.data, readOnlyProxyHandler)
        }
    }

    async function onFormSubmit () {
        const formData = await processFormSubmit()
        if (formData) (formDataHandler) ? formDataHandler(formData) : context.emit('submit', formData)
    }

    return {
        form,

        formSubmitStatus,
        formErrorMessage,

        resetFormSubmitStatus,
        resetFormErrorMessage,
        showFormErrorMessage,
        setFormDataHandler,
        processFormSubmit,
        onFormSubmit,
    }
}
