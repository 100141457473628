import { Salutation as SalutationBase } from '@max/tiptap-extensions'

import Gapcursor from '@tiptap/extension-gapcursor'
import Dropcursor from '@tiptap/extension-dropcursor'
import History from '@tiptap/extension-history'

import { SmartObjectSuggestion } from '@/editor/smartObjects/SmartObjectSuggestion'
import { SmartObjectSimplePlaceholder } from '@/editor/smartObjects/SmartObjectSimplePlaceholder'
import { SmartObjectConditionalSpace } from '@/editor/smartObjects/SmartObjectConditionalSpace'

export const Salutation = SalutationBase.extend({
    addExtensions() {
        const letterBaseExtensions = this.parent?.() || []
        return [
            ...letterBaseExtensions.filter(extension => { // Filter out SmartObject* extensions …
                return ![
                    SmartObjectSimplePlaceholder.name,
                    SmartObjectConditionalSpace.name,
                ].includes(extension.name)
            }),

            Gapcursor,
            Dropcursor,
            History,

            SmartObjectSuggestion.configure({
                blockEditorComponentInstance: this.options.blockEditorComponentInstance,
            }),
            SmartObjectSimplePlaceholder, // … and include the versions with a NodeView.
            SmartObjectConditionalSpace,
        ]
    },
})
