import { apolloClient } from '@/apollo/client'

import { COMMISSION_LIST_ENTRIES_QUERY } from '@/graphql/commissionListEntry/commissionListEntries'
import { COMMISSION_LIST_ENTRY_QUERY } from '@/graphql/commissionListEntry/commissionListEntry'
import { COMMISSION_LIST_ENTRY_CREATE_MUTATION } from '@/graphql/commissionListEntry/commissionListEntryCreate'
import { COMMISSION_LIST_ENTRY_UPDATE_MUTATION } from '@/graphql/commissionListEntry/commissionListEntryUpdate'
import { COMMISSION_LIST_ENTRY_DELETE_MUTATION } from '@/graphql/commissionListEntry/commissionListEntryDelete'
import { COMMISSION_LIST_ENTRY_UPDATE_MATCHED_CONTRACT_MUTATION } from '@/graphql/commissionListEntry/commissionListEntryUpdateMatchedContract'
import { COMMISSION_LIST_ENTRY_POSTING_PREVIEW_MUTATION } from '@/graphql/commissionListEntry/commissionListEntryPostingPreview'
import { COMMISSION_LIST_ENTRY_START_POSTING_MUTATION }  from '@/graphql/commissionListEntry/commissionListEntriesStartPosting'
import { COMMISSION_LIST_ENTRY_CANCEL_POSTING_MUTATION }  from '@/graphql/commissionListEntry/commissionListEntrieCancelPosting'

export const CommissionListEntryService = {
    get (id) {
        return apolloClient.query({
            query: COMMISSION_LIST_ENTRY_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.commissionListEntry)
    },
    all (variables) {
        return apolloClient.query({
            query: COMMISSION_LIST_ENTRIES_QUERY,
            variables,
        }).then(response => response.data.commissionListEntries)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: COMMISSION_LIST_ENTRY_CREATE_MUTATION,
            variables,
        }).then(response => response.data.commissionListEntryCreate)
    },
    update (variables) {
        return apolloClient.mutate({
            mutation: COMMISSION_LIST_ENTRY_UPDATE_MUTATION,
            variables,
        }).then(response => response.data.commissionListEntryUpdate)
    },
    updateMatchedContract (id, contractId) {
        return apolloClient.mutate({
            mutation: COMMISSION_LIST_ENTRY_UPDATE_MATCHED_CONTRACT_MUTATION,
            variables: {
                id: id,
                contractId: contractId,
            },
        }).then(response => response.data.commissionListEntryMatchedContractUpdate)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: COMMISSION_LIST_ENTRY_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.commissionListEntryDelete)
    },
    postingPreview(id) {
        return apolloClient.mutate({
            mutation: COMMISSION_LIST_ENTRY_POSTING_PREVIEW_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.commissionListEntryPostingPreview)
    },
    startPosting (variables) {
        return apolloClient.mutate({
            mutation: COMMISSION_LIST_ENTRY_START_POSTING_MUTATION,
            variables,
        }).then(response => response.data.commissionListEntriesStartPosting)
    },
    cancelPosting (variables) {
        return apolloClient.mutate({
            mutation: COMMISSION_LIST_ENTRY_CANCEL_POSTING_MUTATION,
            variables,
        }).then(response => response.data.commissionListEntriesCancelPosting)
    },
}
