<template>
    <page-loading-indicator v-if="!state.contact" :number-of-breadcrumb-elements="3" />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="state.contact.getContactName()" :to="{ name: 'contact-detail', params: { id: state.contact.id } }" data-test="breadcrumb:contact-detail" />
                <q-breadcrumbs-el :label="$tc('common.term.contract', 2)" :to="{ name: 'contact-detail', params: { id: contactId }}" data-test="breadcrumb:contracts" />
                <q-breadcrumbs-el
                    v-if="isChangeApplication && state.sourceContract"
                    :label="state.sourceContract.currentContractNumber"
                    :to="{ name: 'contract-detail', params: { id: state.sourceContract.id }}"
                    data-test="breadcrumb:contract-detail"
                />
                <q-breadcrumbs-el :label="$tc(`views.contract.add-update-application.add-${isChangeApplication ? 'change-' : ''}application`, 1)" :to="{ name: 'application-create', params: { id: state.contact.id } }" data-test="breadcrumb:contact-detail" />
            </template>

            <h1><span v-if="isChangeApplication && state.sourceContract">{{ state.sourceContract.currentContractNumber }} – </span>{{ $tc(`views.contract.add-update-application.add-${isChangeApplication ? 'change-' : ''}application`, 1) }}</h1>
        </page-header>

        <wizard-component
            :wizard="wizard"
            alternative-labels
            :vertical="$q.screen.lt.md"
            flat
            class="create-contract-wrapper"
        >
            <template v-slot:step-product>
                <contract-select-product-form @submit="fetchProduct" />
            </template>

            <template v-slot:step-form>
                <product-derived-entity-form
                    ref="productDerivedEntityForm"
                    :form-class="ApplicationForm"
                    :product="state.product"
                    :product-derived-entity="state.sourceContract"
                    :consultant-id="prefillConsultantId"
                    :is-change-application="isChangeApplication"
                    @submit="createProductDerivedEntity"
                    @cancel="wizard.reset"
                />
            </template>

            <template v-slot:step-files>
                <div v-if="state.productDerivedEntity" class="col-xs-12 q-gutter-y-md">
                    <!-- TODO: Define meta information -->
                    <consulting-file-browser
                        :target-object-type="ConsultingFileTargetObjectType.APPLICATION"
                        :target-object-id="state.productDerivedEntity.id"
                        :target-object-consultants="[state.productDerivedEntity.consultant]"
                        :meta="{ contactId: state.contact.id, applicationNumber: state.productDerivedEntity.formattedNumber, contactName: state.contact.getContactName() }"
                        @currentFileTypeIdChange="fileTypeId => state.consultingFileBrowserFileTypeId = fileTypeId"
                    >
                        <consulting-file-upload-button
                            :target-object-type="ConsultingFileTargetObjectType.APPLICATION"
                            :target-object-id="state.productDerivedEntity.id"
                            :file-type-id="state.consultingFileBrowserFileTypeId"
                            :meta="{ contactId: state.contact.id, applicationNumber: state.productDerivedEntity.formattedNumber, contactName: state.contact.getContactName() }"
                        />
                    </consulting-file-browser>

                    <in-page-footer>
                        <base-button
                            :label="$t('views.contract.add-update-application.add-another')"
                            outline
                            primary-button
                            @click="reset"
                        />
                        <base-button
                            :to="{ name: 'application-detail', params: { contactId: contactId, id: state.productDerivedEntity.id } }"
                            :label="$t('views.contract.add-update-application.go-to-application')"
                            icon="mib-common-file-text-alternate"
                            primary-button
                        />
                    </in-page-footer>
                </div>
            </template>
        </wizard-component>
    </page-wrapper>
</template>

<script>

import ContractSelectProductForm from '@/components/contract/ContractSelectProductForm'
import ProductDerivedEntityForm from '@/components/contract/ProductDerivedEntityForm'

import { Product } from '@/models/product'
import { Contact } from '@/models/contact'
import { Application } from '@/models/application'

import { ConsultingFileTargetObjectType } from '@/enums/graphql'
import ConsultingFileBrowser from '@/components/consultingFile/ConsultingFileBrowser'
import ConsultingFileUploadButton from '@/components/consultingFile/ConsultingFileUploadButton'
import { FormWizard, FormWizardStep } from '@/libs/form/wizard'
import { useI18n } from '@/composables/i18n'
import { computed, onBeforeMount, reactive, ref } from 'vue'
import { useStore } from '@/composables/store'
import { useRouter } from '@/composables/router'
import { ApplicationForm } from '@/forms/application'
import { Contract } from '@/models/contract'
import WizardComponent from '@/libs/wizard/components/WizardComponent'
import { useQuasar } from '@/composables/quasar'

export default {
    name: 'ApplicationCreate',
    meta () {
        return {
            title: this.$tc(`views.contract.add-update-application.add-${this.isChangeApplication ? 'change-' : ''}application`, 1),
        }
    },
    components: {
        WizardComponent,
        ContractSelectProductForm,
        ProductDerivedEntityForm,
        ConsultingFileBrowser,
        ConsultingFileUploadButton,
    },
    props: {
        contactId: {
            type: String,
            required: true,
        },
        sourceContractId: {
            type: String,
            default: '',
        },
    },
    setup (props) {
        const store = useStore()
        const router = useRouter()
        const { t, tc } = useI18n()
        const $q = useQuasar()

        // Data
        const state = reactive({
            contact: null,
            sourceContract: null,
            product: null,
            productDerivedEntity: null,
            consultingFileBrowserFileTypeId: null,
        })

        // Template refs
        const productDerivedEntityForm = ref(null)

        // Computed
        const isChangeApplication = computed(() => {
            return !!props.sourceContractId
        })
        const productName = computed(() => {
            return state.product ? `${state.product?.name} (${(state.product?.basicProvider?.display_name || state.product?.basicProvider?.name)})` : ''
        })
        const productDerivedEntityNumber = computed(() => {
            return state.productDerivedEntity?.external_reference_number ?? state.productDerivedEntity?.number?.number ?? '' // TODO: External reference number might be ''…
        })
        const prefillConsultantId = computed(() => {
            // Preselect consultant of logged-in user if it has active consulting settings and is in the contact's consultants list
            if (store.state.user.consultant.isActive && state.contact.consultants.findIndex(consultant => consultant.id === store.state.user.consultant.id) !== -1) {
                return store.state.user.consultant.id
            } else if (state.contact.activeConsultants.length) {
                // Preselect the first active consultant in the contact's consultants list
                return state.contact.activeConsultants[0].id
            }
            return null
        })

        // Wizard
        const wizard = new FormWizard([
            new FormWizardStep('product',{
                title: tc('views.contract.add-contract.select-product', 1),
                caption: productName,
                icon: 'mib-shipment-box',
                activeIcon: 'mib-shipment-box',
                doneIcon: 'mib-check',
                doneColor: 'secondary',
            }),
            new FormWizardStep('form', {
                title: t('common.application.application-info'),
                caption: productDerivedEntityNumber,
                icon: 'mib-pencil-write-3-alternate',
                activeIcon: 'mib-pencil-write-3-alternate',
                doneIcon: 'mib-check',
                doneColor: 'secondary',
            }),
            new FormWizardStep('files', {
                title: tc('views.contract.add-contract.upload-files', 2),
                icon: 'mib-common-file-text-upload',
                activeIcon: 'mib-common-file-text-upload',
                doneIcon: 'mib-check',
            }),
        ])

        // Methods
        function fetchProduct ({ productId }) {
            return Product.objects.get(productId).then(product => {
                state.product = product
                wizard.setStep('form')
                return product
            })
        }

        async function fetchDependencies () {
            if (props.sourceContractId) {
                state.sourceContract = await Contract.objects.get(props.sourceContractId)
                await fetchProduct({ productId: state.sourceContract.currentContractInformation.product.id })
            }
            state.contact = await Contact.objects.get(props.contactId)
        }

        function createProductDerivedEntity (formData, submitMode) {
            Application.create({
                product: state.product,
                customerId: props.contactId,
                isChangeApplication: isChangeApplication.value,
                sourceContractId: props.sourceContractId || null,
                ...formData,
            }).then(application => {
                switch (submitMode) {
                    case 'saveAndAddFiles':
                        state.productDerivedEntity = application
                        wizard.nextStep()
                        $q.notify({
                            type: 'positive',
                            message: tc('common.notifications.application.application-added-success', 1),
                        })
                        break
                    case 'saveAndAddAnother':
                        reset()
                        $q.notify({
                            type: 'positive',
                            message: tc('common.notifications.application.application-added-success', 1),
                        })
                        break
                    default:
                        router.replace({ name: 'application-detail', params: { contactId: props.contactId, id: application.id } })
                }
            }).catch(error => {
                productDerivedEntityForm.value.formComponent.formBuilder.showFormErrorMessage(error)
                productDerivedEntityForm.value.formComponent.formBuilder.resetFormSubmitStatus()
            })
        }

        function reset () {
            state.product = null
            state.productDerivedEntity = null
            wizard.reset()
        }

        // Lifecycle hooks
        onBeforeMount(() => {
            fetchDependencies()
        })

        return {
            // Static
            ConsultingFileTargetObjectType,
            ApplicationForm,

            // Data
            wizard,
            state,

            // Computed
            isChangeApplication,
            prefillConsultantId,

            // Template refs
            productDerivedEntityForm,

            // Methods
            fetchProduct,
            createProductDerivedEntity,
            reset,
        }
    },
}
</script>

<style lang="scss" scoped>
::v-deep .create-contract-wrapper {
    // Adjust buttons to height of input/select fields
    .q-btn {
        min-height: 40px;
    }

    // Adjust spacing of default file browser integration
    .file-browser-wrapper {
        margin-top: (-($sizeSpacingSm));
    }

    @include mq($from: $sizeBreakpointMd) {
        .q-stepper__step-inner {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
</style>
