import { ContactType, CorrespondenceItemTargetObjectType } from '@/enums/graphql'
import { EditorContentSchemaType } from '@max/tiptap-extensions'

const validSchemaTypes = [
    EditorContentSchemaType.LETTER,
    EditorContentSchemaType.EMAIL,
    EditorContentSchemaType.SALUTATION,
    EditorContentSchemaType.SIMPLE_RICH_TEXT,
]

const addSmartObjectIcon = function (smartObject) {
    switch (smartObject.type) {
        case 'date':
            smartObject.icon = 'mib-calendar'
            break
        case 'image':
            smartObject.icon = 'mib-itx-file-type-image'
            break
        case 'currency':
            smartObject.icon = 'mib-money-wallet-open'
            break
        case 'conditionalSpace':
            smartObject.icon = 'mib-itx-open-box-character'
            break
    }
}

export const generalSmartObjectItems = [
    { id: 'general.current_date', type: 'date' },
    { id: 'general.date', type: 'date' },
    { id: 'general.conditional_space', type: 'conditionalSpace' },
].map(item => {
    if (typeof item.type === 'undefined') item.type = 'simplePlaceholder'
    if (typeof item.icon === 'undefined') addSmartObjectIcon(item)
    if (typeof item.validSchemaTypes === 'undefined') item.validSchemaTypes = validSchemaTypes
    item.validTargetObjectTypes = [ContactType.PERSON, ContactType.COMPANY, CorrespondenceItemTargetObjectType.CONTRACT]
    return item
})

export const recipientPersonOnlySmartObjectItems = [
    { id: 'recipient.gender' },
    { id: 'recipient.title' },
    { id: 'recipient.profession' },
    { id: 'recipient.role' },
    { id: 'recipient.first_name' },
    { id: 'recipient.last_name' },
    { id: 'recipient.nationality.name' },
    { id: 'recipient.nationality.id' },
    { id: 'recipient.civil_status' },
    { id: 'recipient.driving_licence_since', type: 'date' },
    { id: 'recipient.date_of_birth', type: 'date' },
].map(item => {
    if (typeof item.type === 'undefined') item.type = 'simplePlaceholder'
    if (typeof item.icon === 'undefined') addSmartObjectIcon(item)
    if (typeof item.validSchemaTypes === 'undefined') item.validSchemaTypes = validSchemaTypes
    item.validTargetObjectTypes = [ContactType.PERSON]
    return item
})

export const recipientCompanyOnlySmartObjectItems = [
    { id: 'recipient.company_name' },
    { id: 'recipient.uid' },
    { id: 'recipient.is_liable_to_vat' },
].map(item => {
    if (typeof item.type === 'undefined') item.type = 'simplePlaceholder'
    if (typeof item.icon === 'undefined') addSmartObjectIcon(item)
    if (typeof item.validSchemaTypes === 'undefined') item.validSchemaTypes = validSchemaTypes
    item.validTargetObjectTypes = [ContactType.COMPANY]
    return item
})

export const recipientMixedSmartObjectItems = [
    { id: 'recipient.salutation' },
    { id: 'recipient.contactNumber' },
    // TODO improvement @TFU: Create smart object for "recipient.address" (or "recipient.full_address") that returns the full address of the recipient as its used in the address block
    { id: 'recipient.address.label.label' },
    { id: 'recipient.address.po_box' },
    { id: 'recipient.address.address1' },
    { id: 'recipient.address.address2' },
    { id: 'recipient.address.address3' },
    { id: 'recipient.address.zip' },
    { id: 'recipient.address.city' },
    { id: 'recipient.address.country.name' },
    { id: 'recipient.address.country.id' },
    { id: 'recipient.address.valid_from', type: 'date' },
    { id: 'recipient.address.valid_until', type: 'date' },
    { id: 'recipient.emailAddress.label.label' },
    { id: 'recipient.emailAddress.email' },
    { id: 'recipient.phoneNumber.label.label' },
    { id: 'recipient.phoneNumber.phone_number' },
    { id: 'recipient.customer_since', type: 'date' },
    // { id: 'recipient.customer_until', type: 'date' },
].map(item => {
    if (typeof item.type === 'undefined') item.type = 'simplePlaceholder'
    if (typeof item.icon === 'undefined') addSmartObjectIcon(item)
    if (typeof item.validSchemaTypes === 'undefined') item.validSchemaTypes = validSchemaTypes
    item.validTargetObjectTypes = [ContactType.PERSON, ContactType.COMPANY]
    return item
})

export const senderSmartObjectItems = [
    { id: 'sender.title' },
    { id: 'sender.first_name' },
    { id: 'sender.last_name' },
    { id: 'sender.consultingCompany.company_name' },
    { id: 'sender.consultingCompany.address.city' },
    { id: 'sender.signature', type: 'image', attrs: { maxWidth: 50, maxHeight: 20 }, validSchemaTypes: [EditorContentSchemaType.LETTER] },
].map(item => {
    if (typeof item.type === 'undefined') item.type = 'simplePlaceholder'
    if (typeof item.icon === 'undefined') addSmartObjectIcon(item)
    if (typeof item.validSchemaTypes === 'undefined') item.validSchemaTypes = validSchemaTypes
    item.validTargetObjectTypes = [ContactType.PERSON, ContactType.COMPANY, CorrespondenceItemTargetObjectType.APPLICATION, CorrespondenceItemTargetObjectType.CONTRACT]
    return item
})

export const applicationSmartObjectItems = [
    { id: 'application.formatted_number' },
    { id: 'application.product.name' },
    { id: 'application.product.provider.name' },
    { id: 'application.product.provider.display_name' },
    { id: 'application.start_date', type: 'date' },
    { id: 'application.end_date', type: 'date' },
    { id: 'application.premium', type: 'currency' },
].map(item => {
    if (typeof item.type === 'undefined') item.type = 'simplePlaceholder'
    if (typeof item.icon === 'undefined') addSmartObjectIcon(item)
    if (typeof item.validSchemaTypes === 'undefined') item.validSchemaTypes = validSchemaTypes
    item.validTargetObjectTypes = [CorrespondenceItemTargetObjectType.APPLICATION]
    return item
})

export const contractSmartObjectItems = [
    { id: 'contract.currentContractNumber' },
    { id: 'contract.product.name' },
    { id: 'contract.product.provider.name' },
    { id: 'contract.product.provider.display_name' },
    { id: 'contract.currentCancellation.status' },
    { id: 'contract.currentCancellation.cancellation_date', type: 'date' },
    { id: 'contract.start_date', type: 'date' },
    { id: 'contract.end_date', type: 'date' },
    { id: 'contract.termination_date', type: 'date' },
    { id: 'contract.premium', type: 'currency' },
].map(item => {
    if (typeof item.type === 'undefined') item.type = 'simplePlaceholder'
    if (typeof item.icon === 'undefined') addSmartObjectIcon(item)
    if (typeof item.validSchemaTypes === 'undefined') item.validSchemaTypes = validSchemaTypes
    item.validTargetObjectTypes = [CorrespondenceItemTargetObjectType.CONTRACT]
    return item
})
