import { apolloClient } from '@/apollo/client'

import { COMMISSION_LIST_IMPORT_ERROR_QUERY } from '@/graphql/commissionListImportError/commissionListImportError'
import { COMMISSION_LIST_IMPORT_ERRORS_QUERY } from '@/graphql/commissionListImportError/commissionListImportErrors'

export const CommissionListImportErrorService = {
    get (id) {
        return apolloClient.query({
            query: COMMISSION_LIST_IMPORT_ERROR_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.commissionListImportError)
    },
    all (variables) {
        return apolloClient.query({
            query: COMMISSION_LIST_IMPORT_ERRORS_QUERY,
            variables,
        }).then(response => response.data.commissionListImportErrors)
    },
}
