import gql from 'graphql-tag'

export const TASK_QUERY = gql`
    query task(
        $id: ID!
    ) {
        task(
            id: $id
        ) {
            id
            status
            creator {
                id
                first_name
                last_name
                gender
                consultingCompany {
                    id
                }
                consulting_company_name
            }
            assignee {
                id
                first_name
                last_name
                gender
                consultingCompany {
                    id
                }
                consulting_company_name
            }
            visibility
            subject
            description {
                schemaType
                schemaVersion
                contentJSON
                contentHTML
                attrs
            }
            attachments {
                id
                name
                size
                mime_type
            }
            target_object_type
            target_object_id
            targetObject {
                ... on Person {
                    id
                    first_name
                    last_name
                    gender
                    contactNumber {
                        id
                        number
                    }
                }
                ... on Company {
                    id
                    company_name
                    contactNumber {
                        id
                        number
                    }
                }
                ... on Application {
                    id
                    number {
                        number
                    }
                    external_reference_number
                    customer {
                        id
                        ... on Person {
                            first_name
                            last_name
                            contactNumber {
                                id
                                number
                            }
                        }
                        ... on Company {
                            company_name
                            contactNumber {
                                id
                                number
                            }
                        }
                    }
                }
                ... on Contract {
                    id
                    contractNumbers {
                        id
                        number
                    }
                    customer {
                        id
                        ... on Person {
                            first_name
                            last_name
                            contactNumber {
                                id
                                number
                            }
                        }
                        ... on Company {
                            company_name
                            contactNumber {
                                id
                                number
                            }
                        }
                    }
                }
            }
            due_date
            created_at
            updated_at
            done_at
        }
    }
`
