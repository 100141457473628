<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.accounting.account.account', 2)" :to="{ name: 'accounting-account-list' }" />
            </template>

            <h1>{{ $tc('common.accounting.account.account', 2) }}</h1>
        </page-header>
        <base-table
            :columns="columns"
            :fetch-objects-fn="Account.service.all"
            :additional-filters="additionalFilters"
            user-settings-base-path="AccountList"
            pagination-sort-by-default-key="number"
            data-test="table:account-list"
            @row-dblclick="onDblClick"
        >
            <template v-slot:tabs>
                <q-tabs
                    dense
                    no-caps
                    inline-label
                    align="left"
                    class="q-mr-sm bg-background-secondary"
                    indicator-color="accent"
                    data-test="tabs:links"
                >
                    <q-route-tab :to="{ name: 'accounting-account-list'}" :label="$t('common.status.active')" data-test="tab:account-active" />
                    <q-route-tab :to="{ name: 'accounting-account-list', params: { tab: 'inactive' } }" :label="$t('common.status.inactive')" data-test="tab:account-inactive" />
                </q-tabs>
            </template>

            <template v-slot:body-cell-number="slotProps">
                <q-td :props="slotProps" class="text-right" auto-width>
                    <span>{{ slotProps.row.number }}</span>
                </q-td>
            </template>

            <template v-slot:body-cell-name="slotProps">
                <q-td :props="slotProps">
                    <b>{{ slotProps.row.name }}</b> <info-icon v-if="slotProps.row.description" :text="slotProps.row.description" />
                </q-td>
            </template>

            <!-- TODO @MTR: Decide which information should be displayed. -->
            <template v-slot:body-cell-assignedTo="slotProps">
                <q-td :props="slotProps">
                    <template v-if="slotProps.row.is_assigned">
                        <contact-name
                            v-if="[AccountAssignedToType.PERSON, AccountAssignedToType.COMPANY].includes(slotProps.row.assignedToType)"
                            :contact="slotProps.row.assignedTo"
                            :consulting-company-name="!slotProps.row.assignedTo.worksForUserConsultingCompany"
                        /><!-- TODO @MTR: Use slotProps.row.assigned_as_type -->
                        <template v-else>
                            {{ $t('views.commission.accounting.commission-type-configuration.commission-type-configuration') }}<!-- TODO @MTR: Use slotProps.row.assigned_as_type -->
                        </template>
                    </template>
                </q-td>
            </template>

            <template v-slot:body-cell-status="slotProps">
                <q-td :props="slotProps" auto-width>
                    <status-badge :status="slotProps.row.status" />
                </q-td>
            </template>

            <template v-slot:body-cell-actions="slotProps">
                <q-td :props="slotProps" auto-width>
                    <q-btn
                        color="primary"
                        size="sm"
                        flat
                        round
                        icon="mib-pencil"
                        :to="{ name: 'accounting-account-update', params: { id: slotProps.row.id } }"
                        data-test="link:tooltip-actions"
                    >
                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.accounting.account.update-account', 1) }}</q-tooltip>
                    </q-btn>
                </q-td>
            </template>
        </base-table>

        <in-page-footer>
            <base-button
                :label="$tc('views.accounting.account.add-account', 1)"
                primary-button
                :to="{ name: 'accounting-account-create' }"
                data-test="link:add-account"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { Account } from '@/models/account'
import { AccountStatus, AccountAssignedToType } from '@/enums/graphql'
import { EventBus } from '@/event-bus'

import BaseTable from '@/components/BaseTable'
import ContactName from '@/components/contact/ContactName.vue'
import StatusBadge from '@/components/StatusBadge.vue'

export default {
    name: 'AccountList',
    meta () {
        return {
            title: this.$tc('common.accounting.account.account', 2),
        }
    },
    components: {
        BaseTable,
        ContactName,
        StatusBadge,
    },
    props: {
        tab: {
            type: String,
            default: 'active',
        },
    },
    data() {
        return {
            Account,
            AccountStatus,
            AccountAssignedToType,
            columns: [
                {
                    name: 'number',
                    label: this.$tc('common.accounting.account.account-number', 1),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'name',
                    label: this.$tc('common.accounting.account.name', 1),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'type',
                    label: this.$tc('common.accounting.account.type.type', 1),
                    field: row => this.$t(`common.accounting.account.type.${kebabCase(row.type.toLowerCase())}`),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'assignedTo',
                    label: this.$t('common.accounting.account.assigned-to'),
                    align: 'left',
                },
                {
                    name: 'balance',
                    label: this.$tc('common.accounting.account.balance', 1),
                    field: row => row.formattedBalance,
                    align: 'right',
                    sortable: true,
                    classes: row => `text-bold monospace ${row.balance < 0 ? 'text-negative-darker' : null}`,
                },
                {
                    name: 'status',
                    label: this.$tc('common.term.status', 1),
                    align: 'left',
                    sortable: true,
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left' },
            ],
        }
    },
    computed: {
        additionalFilters () {
            const filters = {
                filterStatus: this.tab === 'inactive' ? [this.AccountStatus.INACTIVE] : [this.AccountStatus.ACTIVE],
            }
            return filters
        },
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        onNewEntity () {
            this.$router.push({ name: 'accounting-account-create' })
        },
        onDblClick (evt, row) {
            this.$router.push({ name: 'accounting-account-update', params: { id: row.id } })
        },
    },
}
</script>
