<template>
    <q-page>
        <div class="row q-col-gutter-md q-pa-lg justify-center">
            <div class="q-pt-xl col-xs-12 col-sm-8 col-md-6 col-lg-6 col-xl-4 self-center">
                <illustration-heading
                    v-if="activeStep !== 'confirmation'"
                    :title="$t('views.auth.forgot-password.title')"
                    img="mail-reply"
                    :error="formHasErrors"
                />
                <illustration-heading
                    v-if="activeStep === 'confirmation'"
                    :title="$t('views.auth.forgot-password.confirmation-title')"
                    icon="mib-check"
                    img="mail-reply"
                    data-test="forgot-password-confirmation-title-wrapper"
                />

                <q-stepper
                    ref="stepper"
                    v-model="activeStep"
                    alternative-labels
                    :vertical="$q.screen.lt.md"
                    color="primary"
                    flat
                    animated
                    transition-prev="fade"
                    transition-next="fade"
                    class="auth"
                    data-test="forgot-password-stepper"
                >
                    <q-step
                        name="username"
                        :title="$tc('common.term.username')"
                        icon="mib-single-neutral"
                        active-icon="mib-single-neutral"
                        done-color="secondary"
                    >
                        <div class="col-xs-12 q-gutter-y-md">
                            <auth-forgot-password-username ref="authForgotPasswordUsername" :prefill-username="prefillUsername" @submit="onUsernameSubmit" />
                        </div>
                    </q-step>

                    <q-step
                        name="2fa"
                        :title="$tc('common.auth.2fa-code')"
                        icon="mib-phone-type"
                        active-icon="mib-phone-type"
                        done-color="secondary"
                    >
                        <div class="col-xs-12 q-gutter-y-md">
                            <auth2fa-code ref="auth2faCode" @submit="on2faCodeSubmit" @back="onBack" />
                        </div>
                    </q-step>

                    <q-step
                        name="confirmation"
                        :title="$t('common.status.done')"
                        icon="mib-check"
                        active-icon="mib-check"
                        done-color="secondary"
                    >
                        <div class="col-xs-12 q-gutter-y-md">
                            <i18n path="views.auth.forgot-password.confirmation-text" tag="p">
                                <template v-slot:for-username>
                                    <span v-if="username">{{ $t('common.term.for') }} <b>{{ username }}</b></span>
                                </template>
                            </i18n>
                        </div>
                    </q-step>
                </q-stepper>
            </div>
        </div>
    </q-page>
</template>

<script>
import AuthForgotPasswordUsername from '@/components/auth/AuthForgotPasswordUsername.vue'
import Auth2faCode from '@/components/auth/Auth2faCode.vue'

import { AuthService } from '@/services'

export default {
    name: 'AuthForgotPassword',
    components: {
        AuthForgotPasswordUsername,
        Auth2faCode,
    },
    meta () {
        return {
            title: this.$t('views.auth.forgot-password.title'),
        }
    },
    data () {
        return {
            activeStep: 'username',
            prefillUsername: (this.$route.query.username) ? this.$route.query.username : '',
            username: '',
            formHasErrors: false,
        }
    },
    methods: {
        onUsernameSubmit ({ username }) {
            this.username = username
            this.activeStep = '2fa'
        },
        on2faCodeSubmit ({ twoFACode }) {
            this.formHasErrors = false
            AuthService.forgotPassword(this.username, twoFACode.split(' ').join(''))
                .then(() => {
                    this.activeStep = 'confirmation'
                })
                .catch(error => {
                    this.formHasErrors = true
                    this.$refs.auth2faCode.showFormErrorMessage(error)
                    this.$refs.auth2faCode.resetFormData()
                    this.$refs.auth2faCode.resetFormSubmitStatus()
                })
        },
        onBack () {
            this.activeStep = 'username'
        },
    },
}
</script>
