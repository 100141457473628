// import indexPageEn from '#/user-manual/contacts/index.en.md'
import indexPageDeCH from '#/user-manual/contacts/index.de-CH.md'
// import contactsPageEn from '#/user-manual/contacts/contacts.en.md'
// import contactsPageDeCH from '#/user-manual/contacts/contacts.de-CH.md'
// import addressPageEn from '#/user-manual/contacts/addresses.en.md'
// import addressPageDeCH from '#/user-manual/contacts/addresses.de-CH.md'
// import consultingSettingsPageEn from '#/user-manual/contacts/consulting-settings.en.md'
// import consultingSettingsPageDeCH from '#/user-manual/contacts/consulting-settings.de-CH.md'

export default {
    key: 'contacts',
    label: { 'en': 'Contacts', 'de-CH': 'Kontakte' },
    navIcon: 'mi-single-neutral-phone-book',
    pages: [
        { 'key': 'index', 'en': indexPageDeCH, 'de-CH': indexPageDeCH }, // TODO: Re-add english version
        // { 'key': 'contact', 'en': contactsPageEn, 'de-CH': contactsPageDeCH },
        // { 'key': 'addresses', 'en': addressPageEn, 'de-CH': addressPageDeCH },
        // { 'key': 'consultingiSettings', 'en': consultingSettingsPageEn, 'de-CH': consultingSettingsPageDeCH },
    ],
}
