<template>
    <q-page>
        <div class="row q-col-gutter-md q-pa-lg justify-center">
            <div class="q-pt-xl col-xs-12 col-sm-8 col-md-6 col-lg-6 col-xl-4 self-center text-center">
                <illustration-heading :title="$t('views.auth.logout.title')" img="password" />

                <p>{{ $t(`views.auth.logout.reason.${logoutReason}`) }}</p>

                <p><router-link :to="{ name: 'auth-login' }" replace>{{ $t('views.auth.logout.login-again') }}</router-link></p>
            </div>
        </div>
    </q-page>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { i18n } from '@/i18n'

export default {
    name: 'AuthLogout',
    meta () {
        return {
            title: this.$t('views.auth.logout.title'),
        }
    },
    data () {
        return {
            reason: this.$route.params.reason,
        }
    },
    computed: {
        logoutReason () {
            // Check if a translation for the according key exists (i18n.te(…)); if so, we assume the prop represents a valid reason
            if (typeof this.reason === 'string' && i18n.te(`views.auth.logout.reason.${kebabCase(this.reason.toLowerCase())}`)) {
                return kebabCase(this.reason.toLowerCase())
            } else {
                return 'none'
            }
        },
    },
}
</script>
