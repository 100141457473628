<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('views.dev.libs.libs')" :to="{ name: 'dev-libs' }" />
                <q-breadcrumbs-el :label="$tc('views.dev.libs.form.form', 1)" :to="{ name: 'dev-libs-form' }" />
            </template>

            <h1>{{ $tc('views.dev.libs.form.form', 1) }}</h1>
        </page-header>

        <form-dialog
            ref="testDialog"
            :form="AddressForm"
            double-confirm
            auto-close
            @submit="onSubmit"
        >
            <template v-slot:form="{ formBuilder }">
                <h2>My Form</h2>
                <div><form-builder-next :item="formBuilder.form.getItem('label')" /></div>
                <div><form-builder-next :item="formBuilder.form.getItem('doubleConfirmation')" /></div>
            </template>
        </form-dialog>

        <p><base-button label="Open dialog" @click="$refs.testDialog.open()" /></p>

        <hr>

        <h2>SimpleForm</h2>

        <h3><small><pre>default-layout</pre></small></h3>
        <form-builder-next
            :form="EmailAddressForm"
            :initial-data="{ emailAddress: 'tobias.fuhlroth@itxpert.ch' }"
            @submit="onSubmit"
        />

        <hr>

        <h3><small><pre>default-layout, exclude field</pre></small></h3>
        <form-builder-next
            :form="emailAddressForm"
            :initial-data="{ emailAddress: 'tobias.fuhlroth@itxpert.ch' }"
            @submit="onSubmit"
        />

        <hr>

        <h3><small><pre>auto-layout</pre></small></h3>
        <form-builder-next
            :form="EmailAddressForm"
            :initial-data="{ emailAddress: 'tobias.fuhlroth@itxpert.ch' }"
            auto-layout
            @submit="onSubmit"
        />

        <hr>

        <h3><small><pre>manual-layout</pre></small></h3>
        <form-builder-next
            :form="EmailAddressForm"
            :initial-data="{ emailAddress: 'tobias.fuhlroth@itxpert.ch' }"
            manual-layout
            @submit="onSubmit"
        />


        <hr>


        <h2>ComplexForm (Auto layout)</h2>
        <form-builder-next :form="complexForm" auto-layout @submit="onSubmit" />
        <ul>
            <li>Dirty: {{ complexForm.isDirty }}</li>
            <li>Error: {{ complexForm.hasError }}</li>
        </ul>
        <p><base-button label="Add field" @click="addField()" /></p>
        <p><base-button label="Validate" @click="validate()" /></p>
        <p><base-button label="Get data" @click="getData()" /></p>

        <hr>

        <h2>ComplexForm (manual layout)</h2>
        <form-builder-next
            v-slot="{ formBuilder }"
            :form="complexForm"
            manual-layout
        >
            <div class="row q-col-gutter-md">
                <div class="col-xs-12 col-md-12 col-lg-6">
                    <form-builder-next :item="formBuilder.form.getItem('employerName')" />
                </div>
                <div class="col-xs-12 col-md-6 col-lg-6">
                    <form-builder-next v-slot="{ item }" :item="formBuilder.form.getItem('team')">
                        <h2>Members</h2>
                        <form-builder-next :item="item.getItem('members')">
                            <template v-slot:repeater-item="{ item: repeaterItem }">
                                <h3>Firstname</h3>
                                <form-builder-next :item="repeaterItem.getItem('firstName')" />
                                <h3>Lastname</h3>
                                <form-builder-next :item="repeaterItem.getItem('lastName')" />
                            </template>
                        </form-builder-next>
                    </form-builder-next>
                </div>
                <div class="col-xs-12 col-md-6 col-lg-12">
                    <form-builder-next v-slot="{ item }" :item="formBuilder.form.getItem('additionalInformation')">
                        <p>Custom Title</p>
                        <form-builder-next :item="item.getItem('financialInformation')" auto-layout />
                        <hr>
                        <form-builder-next :item="item.getItem('legalInformation')" />
                    </form-builder-next>
                </div>
            </div>
        </form-builder-next>
    </page-wrapper>
</template>

<script>
import { Form, FormField, FormRepeater, FormRepeaterItem, FormSection } from '@/libs/form'
import { ref } from 'vue'
import FormDialog from '@/libs/form/components/FormDialog'
import { AddressForm } from '@/forms/contact/address'
import { EmailAddressForm } from '@/forms/contact/emailAddress'

class TeamMember extends FormRepeaterItem {
    compose () {
        this.addField(new FormField('firstName', {
            label: 'Firstname',
            inputType: 'text',
            validators: [{ type: 'required' }],
        }))

        this.addField(new FormField('lastName', {
            label: 'Lastname',
            inputType: 'text',
            validators: [{ type: 'required' }],
        }))
    }
}

class ComplexForm extends Form {
    compose () {
        this.addField(new FormField('premiumDueDate', {
            label: 'Premium due date',
            inputType: 'monthDay',
            validators: [{ type: 'monthDay' }],
            defaultValue: '--10-15',
        }))

        this.addField(new FormField('validFrom', {
            label: 'Valid from',
            inputType: 'calendar',
            validators: [{ type: 'date' }],
            defaultValue: () => new Date().toISOString().substring(0, 10),
        }))

        this.addField(new FormField('employerName', {
            label: 'Employer name',
            inputType: 'text',
            validators: [{ type: 'required' }],
        }))

        const mainAddress = this.addSection(new FormSection('mainAddress', { label: 'Main address' }))
        mainAddress.addItems(new AddressForm().items)

        const team = this.addSection(new FormSection('team', { label: 'Team' }))
        team.addRepeater(new FormRepeater('members', {
            repeaterItemClass: TeamMember,
            validators: [{ type: 'minLength', params: { min: 2 }}],
        }))

        const additionalInformation = this.addSection(new FormSection('additionalInformation', { label: 'Additional information' }))

        const financialInformation = additionalInformation.addSection(new FormSection('financialInformation', { label: 'Financial information' }))
        financialInformation.addField(new FormField('revenue', {
            label: 'Revenue',
            inputType: 'text',
        }))
        financialInformation.addField(new FormField('operatingIncome', {
            label: 'Operating income',
            inputType: 'text',
        }))
        financialInformation.addField(new FormField('currency', {
            label: 'Currency',
            inputType: 'text',
        }))

        const nestedFinancialInformation1 = financialInformation.addSection(new FormSection('nestedFinancialInformation1', { label: 'Nested financial information 1' }))
        nestedFinancialInformation1.addField(new FormField('revenue', {
            label: 'Revenue',
            inputType: 'text',
        }))
        nestedFinancialInformation1.addField(new FormField('operatingIncome', {
            label: 'Operating income',
            inputType: 'text',
        }))
        nestedFinancialInformation1.addField(new FormField('currency', {
            label: 'Currency',
            inputType: 'text',
        }))

        const nestedFinancialInformation2 = nestedFinancialInformation1.addSection(new FormSection('nestedFinancialInformation2', { label: 'Nested financial information 2' }))
        nestedFinancialInformation2.addField(new FormField('revenue', {
            label: 'Revenue',
            inputType: 'text',
        }))
        nestedFinancialInformation2.addField(new FormField('operatingIncome', {
            label: 'Operating income',
            inputType: 'text',
        }))
        nestedFinancialInformation2.addField(new FormField('currency', {
            label: 'Currency',
            inputType: 'text',
        }))

        const nestedFinancialInformation3 = nestedFinancialInformation2.addSection(new FormSection('nestedFinancialInformation3', { label: 'Nested financial information 3' }))
        nestedFinancialInformation3.addField(new FormField('revenue', {
            label: 'Revenue',
            inputType: 'text',
        }))
        nestedFinancialInformation3.addField(new FormField('operatingIncome', {
            label: 'Operating income',
            inputType: 'text',
        }))
        nestedFinancialInformation3.addField(new FormField('currency', {
            label: 'Currency',
            inputType: 'text',
        }))

        const nestedFinancialInformation1a = financialInformation.addSection(new FormSection('nestedFinancialInformation1a', { label: 'Nested financial information 1a' }))
        nestedFinancialInformation1a.addField(new FormField('revenue', {
            label: 'Revenue',
            inputType: 'text',
        }))
        nestedFinancialInformation1a.addField(new FormField('operatingIncome', {
            label: 'Operating income',
            inputType: 'text',
        }))
        nestedFinancialInformation1a.addField(new FormField('currency', {
            label: 'Currency',
            inputType: 'text',
        }))



        const legalInformation = additionalInformation.addSection(new FormSection('legalInformation', { label: 'Legal information' }))
        legalInformation.addField(new FormField('headquarters', {
            label: 'Headquarters',
            inputType: 'text',
        }))
    }
}

export default {
    name: 'DevLibsForm',
    meta () {
        return {
            title: this.$tc('views.dev.libs.form.form', 1),
        }
    },
    components: {
        FormDialog,
    },
    setup () {
        // Complex form
        const complexForm = new ComplexForm()
        complexForm.setDefaultValues()

        // Email address form
        const emailAddressForm = new EmailAddressForm({ excludeItems: ['emailAddress', 'isMain']})

        // Template refs
        const testForm = ref(null)
        const testDialog = ref(null)

        // Methods
        function onCancel () {
            console.log('cancel')
        }
        function onSubmit (formData) {
            console.log('onSubmit', formData)
        }
        function showErrorMessage () {
            testForm.value.formBuilder.showFormErrorMessage({ graphQLErrors: [{ message: 'Wicked Error' }] })
        }
        function addField () {
            complexForm.addField(new FormField('location', {
                label: 'Location',
                inputType: 'text',
            }))
        }
        async function validate () {
            console.log('validate', await complexForm.validate())
        }
        function getData () {
            console.log(complexForm.data)
        }

        return {
            // Forms
            AddressForm,
            EmailAddressForm,
            complexForm,
            emailAddressForm,

            // Template refs
            testForm,
            testDialog,

            // Methods
            onCancel,
            onSubmit,
            showErrorMessage,
            addField,
            validate,
            getData,
        }
    },
}
</script>

<style scoped>

</style>
