<template>
    <form
        data-test="form:product-select-product-provider"
        @submit.prevent="onFormSubmit"
    >
        <div class="row q-col-gutter-sm justify-center">
            <div class="col-xs-12">
                <!-- eslint-disable vue/no-v-html -->
                <info-box
                    v-if="formErrorMessage"
                    type="negative"
                    data-test="error:global-message"
                    v-html="formErrorMessage"
                />
                <!-- eslint-enable -->
            </div>

            <div class="col-xs-12 col-md-8 col-lg-6">
                <div class="row product-provider-selection row q-col-gutter-sm">
                    <div class="col-xs-9 col-md-10">
                        <form-builder item-key="productProviderId" autofocus class="column" />

                        <q-card flat class="additional-info q-mt-sm">
                            <q-item>
                                <q-item-section avatar>
                                    <q-avatar icon="mib-information-circle" />
                                </q-item-section>

                                <q-item-section>
                                    <i18n path="views.admin.products.create-product.select-product-provider--hint" tag="p" class="q-ma-none">
                                        <template v-slot:productProviderSettings><router-link :to="{ name: 'admin-product-provider-list' }">{{ $tc('common.product.product-provider-settings', 2) }}</router-link></template>
                                    </i18n>
                                </q-item-section>
                            </q-item>
                        </q-card>
                    </div>
                    <div class="col-xs-3 col-md-2">
                        <base-button
                            data-test="btn:submit"
                            type="submit"
                            :label="$t('common.term.continue')"
                            :disable="formSubmitStatus"
                            :loading="formSubmitStatus"
                            primary-button
                            class="column"
                        />
                    </div>
                </div>
            </div>
        </div>

        <in-page-footer>
            <base-button
                :label="$t('common.term.cancel')"
                primary-button
                flat
                @click="$router.go(-1)"
            />
        </in-page-footer>
    </form>
</template>

<script>
import { formBuilderMixin } from '@/mixins/formBuilderMixin'

export default {
    name: 'ProductSelectProductProviderForm',
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: {
            type: 'root',
            children: [{
                type: 'field',
                key: 'productProviderId',
                label: 'common.product.product-provider',
                inputType: 'productProvider',
                validators: [
                    {
                        type: 'required',
                    },
                ],
            }],
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .product-provider-selection {
    @include mq($to: $sizeBreakpointMd) {
        justify-content: start;
    }
}
</style>
