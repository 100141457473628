/**
 * Parse form data values.
 * @export
 * @param  {Object} formData[] - [{id, igb2bSectorCodeId, customSectorCode, commissionRate, adjustedCommissionRate}]
 * @return {Object} - CommissionRateAdjustmentRuleSetInput
 */
export function parseFormDataToInput(formData) {
    return {
        rules: formData.map(entry => {
            const rule = {
                igb2b_sector_code_id: !entry.customSectorCode ? entry.igb2bSectorCodeId : null,
                custom_sector_code: entry.customSectorCode,
                commission_rate: parseFloat(entry.commissionRate),
                adjusted_commission_rate: parseFloat(entry.adjustedCommissionRate),
            }

            if (entry.id) rule.id = entry.id

            return rule
        }),
    }
}

/**
 * Parse input values.
 * @export
 * @param  {Object} CommissionRateAdjustmentRuleInput - CommissionRateAdjustmentRuleInput
 * @return {Object} - {id, igb2bSectorCodeId, customSectorCode, commissionRate, adjustedCommissionRate}
 */
export function parseInputToFormData(input) {
    return {
        id: input.id,
        igb2bSectorCodeId: input.igb2bSectorCode?.id || null,
        customSectorCode: input.custom_sector_code,
        commissionRate: input.commission_rate,
        adjustedCommissionRate: input.adjusted_commission_rate,
    }
}

/**
 *
 * Format create variables.
 * @param  {String} productProviderId
 * @param  {Object} CommissionRateAdjustmentRuleSetInput - CommissionRateAdjustmentRuleSetInput
 * @return {Object} - {productProviderId, commissionRateAdjustmentRuleSet}
 */
export function formatCreateVariables(productProviderId, CommissionRateAdjustmentRuleSetInput) {
    return {
        product_provider_id: productProviderId,
        commissionRateAdjustmentRuleSet: CommissionRateAdjustmentRuleSetInput,
    }
}

/**
 *
 * Format update variables.
 * @param  {String} id
 * @param  {Object} CommissionRateAdjustmentRuleSetInput - CommissionRateAdjustmentRuleSetInput
 * @return {Object} - {productProviderId, commissionRateAdjustmentRuleSet}
 */
export function formatUpdateVariables(id, CommissionRateAdjustmentRuleSetInput) {
    return {
        id: id,
        commissionRateAdjustmentRuleSet: CommissionRateAdjustmentRuleSetInput,
    }
}

/**
 * Validate formData rates.
 *
 * @param {Object} formData - {id, igb2bSectorCodeId, customSectorCode, commissionRate, adjustedCommissionRate}
 * @returns {Boolean}
 */
export function validateFormDataRates(formData) {
    return parseInt(formData.adjustedCommissionRate) < parseInt(formData.commissionRate)
}
