/**
 * Parse form data values.
 * @export
 * @param  {Object} formData[] - [{ id, name, description, emailAddressId, phoneNumberId }]
 * @return {Object} - typeof AreaOfResponsibilityInput
 */
export function parseFormDataToInput(formData) {
    return {
        id: formData.id,
        name: formData.name,
        description: formData.description,
        email_address_id: formData.emailAddressId,
        phone_number_id: formData.phoneNumberId,
    }
}
