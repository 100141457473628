import gql from 'graphql-tag'

export const FILE_STORAGE_ITEM_SET_ACKNOWLEDGE_AT_MUTATION = gql`
    mutation fileStorageItemSetAcknowledgedAt(
        $ids: [ID!]!
    ) {
        fileStorageItemSetAcknowledgedAt(
            ids: $ids
        ) {
            id
            acknowledged_at
            created_at
            updated_at
        }
    }
`
