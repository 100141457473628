import { formatDate } from '@/helpers/date'
import {  lightFormat } from 'date-fns'

const IntervalSectionBoundaryType = Object.freeze({
    'START': 'START',
    'END': 'END',
})

/**
 * Parse form data values.
 * @export
 * @param  {Object} formData - {additionalRecipientId, activeFrom, activeUntil, splitRate}
 * @return {Object} CommissionSplitInput
 */
export function parseFormDataToInput(formData) {
    return {
        additional_recipient_id: formData.additionalRecipientId,
        active_from: formData.activeFrom,
        active_until: formData.activeUntil,
        split_rate: parseFloat(formData.splitRate),
    }
}

/**
 * Format CommissionSplitInput values.
 * @export
 * @param  {Object} input - CommissionSplitInput
 * @return {Object} CommissionSplitInput
 */
export function formatInputValues(input) {
    const {
        additional_recipient_id,
        split_rate,
        active_from,
        active_until,
    } = input

    return {
        additional_recipient_id: additional_recipient_id,
        split_rate: split_rate,
        active_from: active_from ? `${lightFormat(new Date(active_from), 'yyyy-MM-dd')}T00:00:00+00:00` : null, // Set to UTC by purpose.
        active_until: active_until ? `${lightFormat(new Date(active_until), 'yyyy-MM-dd')}T23:59:59+00:00` : null, // Set to UTC by purpose.
    }
}

/**
 * Create array of object intervals with start time, end time and rate.
 * @export
 * @param  {Object[]} commissionSplits - CommissionSplit array
 * @return {Array} {start, end, rate}
 */
export function createIntervals (commissionSplits) {
    return commissionSplits.map(entry => {
        const start = entry.active_from ? `${lightFormat(new Date(entry.active_from), 'yyyy-MM-dd')}T00:00:00+00:00` : '0000-01-01T00:00:00+00:00' // Set to UTC by purpose.
        const end = entry.active_until ? `${lightFormat(new Date(entry.active_until), 'yyyy-MM-dd')}T23:59:59+00:00` : '9999-12-31T23:59:59+00:00' // Set to UTC by purpose.

        return {
            start: new Date(start).getTime(),
            end: new Date(end).getTime(),
            rate: parseFloat(entry.split_rate),
        }
    })
}

/**
 * Validates each given interval by start, end and rate.
 * @export
 * @param  {Object[]} intervals - {start, end, rate} Array
 * @param  {number} maximum - Maximum rate on all intervals.
 * @return {Object} - {valid, interval}
 */
export function validateIntervals (intervals, maximum) {
    if (intervals.length < 2) return { valid: true }

    // Create a list of all the intervals' start and end positions.
    const sectionBoundaries = []

    intervals.forEach(interval => {
        sectionBoundaries.push({
            position: interval.start,
            type: IntervalSectionBoundaryType.START,
            rate: interval.rate,
        })
        sectionBoundaries.push({
            position: interval.end,
            type: IntervalSectionBoundaryType.END,
            rate: -interval.rate,
        })
    })

    // Sort the boundaries by position. If positions are equal, sort by type (ENDs come before STARTs).
    sectionBoundaries.sort((a, b) => {
        const diff = a.position - b.position
        return (diff === 0) ? a.type - b.type : diff
    })

    let currentBoundaryPosition = sectionBoundaries[0].position
    let overlappingRate = 0

    // Go through all boundaries.
    for (let i=0; i < sectionBoundaries.length; i++) {
        const currentBoundary = sectionBoundaries[i]
        const nextBoundary = sectionBoundaries[i+1]
        if (!nextBoundary) break

        // Everytime an interval starts or ends its rate is added to or subtracted from `overlappingRate`.
        overlappingRate += currentBoundary.rate

        // Fix decimal subtraction which gives excess decimals
        overlappingRate = parseFloat(overlappingRate.toFixed(2))

        // If all values of the same position are absorbed, check if the overlapping rate exceeds the threshold.
        if (nextBoundary.position !== currentBoundaryPosition) {
            currentBoundaryPosition = nextBoundary.position
            if (overlappingRate > maximum) {
                const validationResponse = {
                    valid: false,
                    interval: {
                        start: currentBoundary.position,
                        startFormatted: formatDate(new Date(currentBoundary.position).toISOString()),
                        end: nextBoundary.position,
                        endFormatted: formatDate(new Date(nextBoundary.position).toISOString()),
                        rate: overlappingRate,
                    },
                }
                // TODO @TFU: Refactoring (01.01.001 / 01.01.10000)
                if (currentBoundary.position === -62167219200000) {
                    validationResponse.interval.start = null
                    validationResponse.interval.startFormatted = ''
                }
                // TODO @TFU: Refactoring (01.01.001 / 01.01.10000)
                if (nextBoundary.position === 253402300799000) {
                    validationResponse.interval.end = null
                    validationResponse.interval.endFormatted = ''
                }

                return validationResponse
            }
        }
    }

    return { valid: true }
}
