<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.product.product-provider', 2)" :to="{ name: 'admin-product-provider-list' }" />
            </template>

            <h1>{{ $t('views.admin.products.product-providers.overview-title') }}</h1>
        </page-header>

        <div class="row">
            <div class="col-12">
                <info-box>
                    <p>{{ $t('views.admin.products.product-providers.description-text') }}</p>
                </info-box>

                <q-table
                    :data="productProviders"
                    :columns="columns"
                    row-key="id"
                    :pagination.sync="pagination"
                    :loading="loading"
                    :rows-per-page-options="[10, 20, 50, 100]"
                    binary-state-sort
                    flat
                    square
                    dense
                    @request="onRequest"
                    @row-dblclick="(evt, row) => $router.push({ name: 'admin-product-provider-update', params: { id: row.id } })"
                >
                    <template v-slot:top>
                        <q-tabs
                            dense
                            no-caps
                            inline-label
                            align="left"
                            class="q-mr-sm bg-background-secondary"
                            indicator-color="accent"
                        >
                            <q-route-tab :to="{ name: 'admin-product-provider-list' }" :label="$t('common.status.active')" />
                            <q-route-tab :to="{ name: 'admin-product-provider-list', params: { tab: 'inactive' } }" :label="$t('common.status.inactive')" />
                        </q-tabs>

                        <q-space />

                        <filter-input v-model="filter" @clear-filter="clearFilter" />
                    </template>

                    <template v-slot:body-cell-contact_name="props">
                        <q-td :props="props">
                            <contact-name
                                :contact="props.row"
                                type-icon
                                :highlighted="!props.row.productProviderSettings.display_name || props.row.productProviderSettings.display_name === props.row.getContactName()"
                                comma-separated
                            />
                            <template v-if="(props.row.productProviderSettings.display_name && props.row.productProviderSettings.display_name !== props.row.getContactName())">
                                (<b>{{ props.row.productProviderSettings.display_name }}</b>)
                            </template>
                            <status-badge v-if="props.row.is_tenant_company" status="system-owner" />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-product_provider_status="props">
                        <q-td :props="props">
                            <status-badge :status="props.row.productProviderSettings.status" />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-actions="props">
                        <q-td :props="props" auto-width>
                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-pencil"
                                :to="{ name: 'admin-product-provider-update', params: { id: props.row.id } }"
                            >
                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.admin.products.product-providers.edit-product-provider-settings', 1, { contactName: props.row.productProviderSettings.display_name ? props.row.productProviderSettings.display_name : props.row.getContactName() }) }}</q-tooltip>
                            </q-btn>
                        </q-td>
                    </template>
                </q-table>
            </div>
        </div>

        <content-store-dialog ref="contentStoreDialog" content-type="productProvider" @import-finished="fetchObjects" />

        <in-page-footer>
            <base-button
                :to="{ name: 'admin-product-provider-create' }"
                :label="$t('views.admin.products.product-providers.add-settings-to-contact')"
                icon="mib-warehouse-package"
                primary-button
            />
            <base-button
                :label="$t('common.content-store.import-from-content-store')"
                icon="mib-folder-file"
                primary-button
                outline
                @click="$refs.contentStoreDialog.show()"
                @keyup.enter="$refs.contentStoreDialog.show()"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import { UserSettings } from '@/helpers/user'
import { OrderDirection } from '@/enums/graphql'

import ContactName from '@/components/contact/ContactName.vue'
import StatusBadge from '@/components/StatusBadge.vue'
import FilterInput from '@/components/FilterInput'
import ContentStoreDialog from '@/components/content-store/ContentStoreDialog'

import { Contact } from '@/models/contact'

export default {
    name: 'ProductProviderList',
    components: {
        ContentStoreDialog,
        ContactName,
        StatusBadge,
        FilterInput,
    },
    props: {
        tab: {
            type: String,
            default: 'active',
        },
    },
    meta () {
        return {
            title: this.$tc('common.product.product-provider', 2),
        }
    },
    data () {
        const userSettings = new UserSettings(this.$store.state.user)
        return {
            userSettings,
            activeTab: this.tab,
            filter: '',
            loading: false,
            pagination: {
                sortBy: 'contact_name',
                descending: false,
                page: 1,
                rowsPerPage: parseInt(userSettings.getItem('ProductProviderList.pagination.rowsPerPage')) || 50,
                rowsNumber: 0,
            },
            columns: [
                {
                    name: 'contact_number',
                    label: this.$tc('common.contact.contact-number', 1),
                    field: row => row.contactNumber.number,
                    sortable: true,
                    align: 'left',
                },
                // {
                //     // TODO improvement: Check if it makes more sense to have this in a separate column.
                //     name: 'product_provider_display_name',
                //     label: this.$tc('common.term.display-name'),
                //     field: row => row.productProviderSettings.display_name,
                //     align: 'left'
                // },
                {
                    // TODO improvement: Also make display name searchable/filterable as users will likely remember that name better.
                    name: 'contact_name',
                    label: this.$tc('common.term.name'),
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'main_address_address_1',
                    label: this.$tc('common.term.address'),
                    field: row => row.mainAddress.address1,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'main_address_address_zip',
                    label: this.$tc('common.address.zip'),
                    field: row => row.mainAddress.zip,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'main_address_address_city',
                    label: this.$tc('common.address.city'),
                    field: row => row.mainAddress.city,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'igb2b_insurer_code',
                    label: this.$tc('common.product-provider.igb2b-insurer-code', 1),
                    field: row => row.productProviderSettings?.igb2bInsurerCode?.id,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'product_provider_status',
                    label: this.$tc('common.term.status'),
                    align: 'left',
                },
                {
                    name: 'actions',
                    label: this.$tc('common.term.action', 2),
                    align: 'left',
                },
            ],
            productProviders: [],
        }
    },
    watch: {
        'filter' () {
            this.fetchObjects()
        },
        '$route' (to) {
            this.activeTab = to.params.tab || 'active'
            this.fetchObjects()
        },
        'pagination.rowsPerPage' (rowsPerPage) {
            this.userSettings.setItem('ProductProviderList.pagination.rowsPerPage', rowsPerPage)
        },
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
        this.fetchObjects()
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        clearFilter () {
            this.filter = ''
        },
        onRequest (props) {
            let { page, rowsPerPage, sortBy, descending } = props.pagination

            this.pagination.page = page
            this.pagination.rowsPerPage = rowsPerPage
            this.pagination.sortBy = sortBy
            this.pagination.descending = descending

            this.fetchObjects()
        },
        fetchObjects () {
            this.loading = true
            Contact.objects.all({
                page: this.pagination.page,
                count: this.pagination.rowsPerPage,
                orderField: this.pagination.sortBy.toUpperCase(),
                orderDirection: (this.pagination.descending) ? OrderDirection.DESC : OrderDirection.ASC,
                filterQuery: this.filter,
                // hasProductProviderSettings: true, // TODO: Uncomment once https://itxpert.atlassian.net/browse/MAX-506 has been fixed
                productProviderSettingsStatus: this.activeTab.toUpperCase(),
            }).then(response => {
                this.pagination.rowsNumber = response.paginatorInfo.total
                this.loading = false
                this.productProviders = response.data
            })
        },
        onNewEntity () {
            this.$router.push({ name: 'admin-product-provider-create' })
        },
    },
}
</script>
