import gql from 'graphql-tag'

export const COMMISSION_LISTS_QUERY = gql`
    query commissionLists(
        $page: Int = 1
        $count: Int = 1000
        $orderField: CommissionListOrderField = STATUS
        $orderDirection: OrderDirection = DESC
        $filterQuery: String
        $filterStatus: [CommissionListStatus!]
    ) {
        commissionLists(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                query: $filterQuery
                status: $filterStatus
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                productProvider {
                    id
                    name
                    display_name
                }
                source
                type
                status
                file {
                    id
                }
                filename
                excelMapping {
                    id
                }
                file_import_identification_number
                exchange_date_from
                exchange_date_to
                commission_total
                total_amount_of_imported_commission
                total_amount_of_imported_commission_grouped_by_commission_type
                total_number_of_commission_entries
                total_number_of_imported_commission_entries
                total_number_of_commission_list_import_errors
                created_at
                updated_at
                posted_at
            }
        }
    }
`
