<template>
    <div class="do-not-print"><slot /></div>
</template>

<script>
import { EventBus } from '@/event-bus'

export default {
    name: 'MainTabs',
    data () {
        return {
            qTabsVNode: null,
            shortcuts: [],
        }
    },
    watch: {
        $route () {
            this.setup()
        },
    },
    created () {
        this.setup()
        EventBus.$on('shortcut:tab', this.setTab)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:tab', this.setTab)
    },
    methods: {
        setTab (tabNumber) {
            const shortcut = this.shortcuts[tabNumber - 1]
            if (shortcut) {
                if (shortcut.to) {
                    const location = this.$router.resolve(shortcut.to)
                    if (location.href !== this.$route.path) this.$router.push(location.href)
                } else if (shortcut.name) {
                    if (this.qTabsVNode) {
                        this.qTabsVNode.componentInstance.$emit('input', shortcut.name)
                    }
                }
            }
        },
        setup () {
            this.shortcuts = []
            const tabs = []
            const extractTabs = (vNode) => {
                if (vNode.componentOptions) {
                    if (vNode.componentOptions.tag === 'q-tabs') this.qTabsVNode = vNode
                    if (['q-route-tab', 'q-tab'].includes(vNode.componentOptions.tag)) tabs.push(vNode)
                    if (vNode.componentOptions.children) {
                        vNode.componentOptions.children.forEach(childVNode => {
                            extractTabs(childVNode)
                        })
                    }
                }
            }
            this.$slots.default.forEach(vNode => extractTabs(vNode))

            tabs.forEach(tab => {
                if (tab.componentOptions.propsData && tab.componentOptions.propsData.to) {
                    this.shortcuts.push({
                        to: tab.componentOptions.propsData.to,
                    })
                } else if (tab.componentOptions.propsData && tab.componentOptions.propsData.name) {
                    this.shortcuts.push({
                        name: tab.componentOptions.propsData.name,
                    })
                }
            })
        },
    },
}
</script>
