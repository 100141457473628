import { apolloClient } from '@/apollo/client'

import { COMMISSION_DISTRIBUTION_RULE_SET_QUERY } from '@/graphql/commissionDistributionRuleSet/commissionDistributionRuleSet'
import { buildCommissionDistributionRuleSetsQuery, COMMISSION_DISTRIBUTION_RULE_SETS_QUERY } from '@/graphql/commissionDistributionRuleSet/commissionDistributionRuleSets'
import { COMMISSION_DISTRIBUTION_RULE_SET_CREATE_MUTATION } from '@/graphql/commissionDistributionRuleSet/commissionDistributionRuleSetCreate'
import { COMMISSION_DISTRIBUTION_RULE_SET_UPDATE_MUTATION } from '@/graphql/commissionDistributionRuleSet/commissionDistributionRuleSetUpdate'
import { COMMISSION_DISTRIBUTION_RULE_SET_DELETE_MUTATION } from '@/graphql/commissionDistributionRuleSet/commissionDistributionRuleSetDelete'

export const CommissionDistributionRuleSetService = {
    get (id) {
        return apolloClient.query({
            query: COMMISSION_DISTRIBUTION_RULE_SET_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.commissionDistributionRuleSet)
    },
    all (variables, fields) {
        const QUERY = (typeof fields !== 'undefined') ? buildCommissionDistributionRuleSetsQuery(fields) : COMMISSION_DISTRIBUTION_RULE_SETS_QUERY
        return apolloClient.query({
            query: QUERY,
            variables,
        }).then(response => response.data.commissionDistributionRuleSets)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: COMMISSION_DISTRIBUTION_RULE_SET_CREATE_MUTATION,
            variables,
        }).then(response => response.data.commissionDistributionRuleSetCreate)
    },
    update (id, commissionDistributionRuleSetVariables) {
        return apolloClient.mutate({
            mutation: COMMISSION_DISTRIBUTION_RULE_SET_UPDATE_MUTATION,
            variables: {
                id: id,
                commissionDistributionRuleSet: commissionDistributionRuleSetVariables,
            },
        }).then(response => response.data.commissionDistributionRuleSetUpdate)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: COMMISSION_DISTRIBUTION_RULE_SET_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.commissionDistributionRuleSetDelete)
    },
}
