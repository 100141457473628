import MinimalLayout from '@/layouts/Minimal.vue'
import AuthSetup from '@/views/auth/AuthSetup.vue'
import AuthLogin from '@/views/auth/AuthLogin.vue'
import AuthForgotPassword from '@/views/auth/AuthForgotPassword'
import AuthResetPassword from '@/views/auth/AuthResetPassword'
import AuthLogout from '@/views/auth/AuthLogout.vue'
import AuthConfirmEmail from '@/views/auth/AuthConfirmEmail'

export default [
    {
        path: '/setup/:token',
        redirect: to => {
            return { name: 'auth-setup', params: { token: to.params.token, step: 'password' } }
        },
    },
    {
        path: '/setup/:token/:step(password|2fa|backupcode|confirmation)',
        component: MinimalLayout,
        meta: { noAuth: true },
        children: [{
            path: '',
            name: 'auth-setup',
            component: AuthSetup,
            props: true,
            meta: { noAuth: true },
        }],
    },
    {
        path: '/login',
        component: MinimalLayout,
        meta: { noAuth: true },
        children: [{
            path: '',
            name: 'auth-login',
            component: AuthLogin,
            meta: { noAuth: true },
        }],
    },
    {
        path: '/forgot-password',
        component: MinimalLayout,
        meta: { noAuth: true },
        children: [{
            path: '',
            name: 'auth-forgot-password',
            component: AuthForgotPassword,
            meta: { noAuth: true },
        }],
    },
    {
        path: '/reset-password/:token',
        component: MinimalLayout,
        meta: { noAuth: true },
        children: [{
            path: '',
            name: 'auth-reset-password',
            component: AuthResetPassword,
            props: true,
            meta: { noAuth: true },
        }],
    },
    {
        path: '/logout/:reason',
        component: MinimalLayout,
        meta: { noAuth: true },
        children: [{
            path: '',
            name: 'auth-logout',
            component: AuthLogout,
            meta: { noAuth: true },
        }],
    },
    {
        path: '/confirm-email/:token',
        component: MinimalLayout,
        meta: {
            noAuth: true,
            noAuthDoNotRedirect: true,
        },
        children: [{
            path: '',
            name: 'auth-confirm-email',
            component: AuthConfirmEmail,
            props: true,
            meta: {
                noAuth: true,
                noAuthDoNotRedirect: true,
            },
        }],
    },
]
