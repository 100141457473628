import { apolloClient } from '@/apollo/client'

import { CORRESPONDENCE_LETTER_QUERY } from '@/graphql/correspondenceLetter/correspondenceLetter'
import { CORRESPONDENCE_LETTER_CREATE_MUTATION } from '@/graphql/correspondenceLetter/correspondenceLetterCreate'
import { CORRESPONDENCE_LETTER_UPDATE_MUTATION } from '@/graphql/correspondenceLetter/correspondenceLetterUpdate'
import { CORRESPONDENCE_LETTER_DELETE_MUTATION } from '@/graphql/correspondenceLetter/correspondenceLetterDelete'
import { CORRESPONDENCE_LETTER_PREVIEW_CREATE_MUTATION } from '@/graphql/correspondenceLetter/correspondenceLetterPreviewCreate'
import { CORRESPONDENCE_LETTER_START_PROCESSING_MUTATION } from '@/graphql/correspondenceLetter/correspondenceLetterStartProcessing'
import { PROCESSED_CORRESPONDENCE_LETTER_DELETE_MUTATION } from '@/graphql/correspondenceLetter/processedCorrespondenceLetterDelete'

export const CorrespondenceLetterService = {
    get (id) {
        return apolloClient.query({
            query: CORRESPONDENCE_LETTER_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.correspondenceLetter)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: CORRESPONDENCE_LETTER_CREATE_MUTATION,
            variables,
        }).then(response => response.data.correspondenceLetterCreate)
    },
    update (id, correspondenceLetterVariables) {
        return apolloClient.mutate({
            mutation: CORRESPONDENCE_LETTER_UPDATE_MUTATION,
            variables: {
                id: id,
                correspondenceLetter: correspondenceLetterVariables,
            },
        }).then(response => response.data.correspondenceLetterUpdate)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: CORRESPONDENCE_LETTER_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.correspondenceLetterDelete)
    },

    // Preview
    createPreview (id, targetObjectId) {
        return apolloClient.mutate({
            mutation: CORRESPONDENCE_LETTER_PREVIEW_CREATE_MUTATION,
            variables: {
                id: id,
                targetObjectId: targetObjectId,
            },
        }).then(response => response.data.correspondenceLetterPreviewCreate)
    },

    // Processing
    startProcessing (id) {
        return apolloClient.mutate({
            mutation: CORRESPONDENCE_LETTER_START_PROCESSING_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.correspondenceLetterStartProcessing)
    },

    // Processed
    processedCorrespondenceLetterDelete (id, deleteConsultingFile) {
        return apolloClient.mutate({
            mutation: PROCESSED_CORRESPONDENCE_LETTER_DELETE_MUTATION,
            variables: {
                id,
                deleteConsultingFile,
            },
        }).then(response => response.data.processedCorrespondenceLetterDelete)
    },
}
