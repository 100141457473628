<template>
    <div :class="`search-result-contact contact-type-company contact-status-${kebabCase(item.model.status)}`" data-test="wrapper:global-search-result-company">
        <q-item
            :to="linkSearchResultComponents && !disabled ? { name: 'contact-detail', params: { id: item.model.id } } : null"
            :class="{ selected: item.selected }"
            exact
            clickable
            @click="!linkSearchResultComponents && !disabled ? $emit('open-search-result', item) : null"
        >
            <q-item-section>
                <q-item-label>
                    <b>
                        <contact-name
                            :contact="item.model"
                            type-icon
                            title
                            contact-number
                        />
                    </b>

                    <!-- TODO improvement: contact-type-badges are not shown currently due to performance considerations. Consider re-adding the according info to `graphql/globalSearch.js` after some performance testing. -->
                    <!--<contact-type-badges :contact="item.model" showSystemOwner />-->
                </q-item-label>
                <q-item-label caption lines="2">
                    <address>{{ item.model.getContactAddress(item.model.mainAddress) }}</address>
                </q-item-label>
            </q-item-section>

            <q-item-section side top class="no-highlighting entry-status-section">
                <status-badge v-if="item.model.customer_status" :status="item.model.customer_status" default-translation-base-path="common.contact.customer-status" />

                <info-icon
                    size="1em"
                    tooltip-max-width="50ch"
                    class="q-ml-xs"
                    icon-position-y-offset="-0.08em"
                >
                    <p class="q-mb-none text-bold">
                        <contact-name
                            :contact="item.model"
                            type-icon
                            title
                        />
                    </p>

                    <q-separator class="q-mt-xs q-mb-sm" color="text-secondary" />

                    <dl>
                        <div v-if="item.model.uid">
                            <dt>{{ $tc('common.contact.uid', 1) }}: </dt>
                            <dd>{{ item.model.uid }}</dd>
                        </div>

                        <div v-if="item.model.customer_since || item.model.customer_since || item.model.customer_until" class="q-mt-sm">
                            <div v-if="item.model.customer_since">
                                <dt>{{ $t('common.contact.customer-status.title') }}: </dt>
                                <dd>{{ $t(`common.contact.customer-status.${kebabCase(item.model.customer_status)}`) }}</dd>
                            </div>

                            <div v-if="item.model.customer_since">
                                <dt>{{ $tc('common.contact.customer-since', 1) }}: </dt>
                                <dd>{{ item.model.formattedCustomerSince }}</dd>
                            </div>

                            <div v-if="item.model.customer_until">
                                <dt>{{ $tc('common.contact.customer-until', 1) }}: </dt>
                                <dd>{{ item.model.formattedCustomerUntil }}</dd>
                            </div>
                        </div>

                        <q-separator class="q-mt-xs q-mb-sm" color="text-secondary" />

                        <div class="q-mt-sm">
                            <dt>{{ $tc('common.contact.consultant', item.model.consultants.length) }}: </dt>
                            <dd>{{ item.model.getConsultantNames({ multiline: true }) }}</dd>
                        </div>
                    </dl>
                </info-icon>
            </q-item-section>
        </q-item>

        <q-list class="search-results-contracts-wrapper">
            <template v-if="item.children.length">
                <div
                    v-for="searchResultWrapper in item.children"
                    :key="searchResultWrapper.model.id"
                >
                    <global-search-result-application
                        v-if="searchResultWrapper.model.__typename === 'Application'"
                        :item="searchResultWrapper"
                        :link-search-result-components="linkSearchResultComponents"
                        :disabled="!enabledResultTypes.includes('Application')"
                        @open-search-result="searchResultWrapper => $emit('open-search-result', searchResultWrapper)"
                    />
                    <global-search-result-contract
                        v-else
                        :item="searchResultWrapper"
                        :link-search-result-components="linkSearchResultComponents"
                        :disabled="!enabledResultTypes.includes('Contract')"
                        @open-search-result="searchResultWrapper => $emit('open-search-result', searchResultWrapper)"
                    />
                </div>
            </template>
        </q-list>

        <div v-if="enableFetchContracts && ![FetchContractsSearchStatus.ADDITIONAL_CONTRACTS_LOADED].includes(item.fetchContractsSearchStatus)" class="fetch-contracts-content-wrapper">
            <div v-if="item.fetchContractsSearchStatus" :class="['fetch-contracts-content text', { 'additional-info': [FetchContractsSearchStatus.NO_ADDITIONAL_CONTRACTS_LOADED, FetchContractsSearchStatus.NO_CONTRACTS_LOADED].includes(item.fetchContractsSearchStatus) }]">{{ $t(`common.contract.show-all-contracts--fetch--${kebabCase(item.fetchContractsSearchStatus)}`) }}</div>
            <q-btn
                v-else
                class="fetch-contracts-content"
                :label="$tc('common.contract.show-all-contracts--of-contact', 1, { contact: item.model.getContactName() })"
                icon="mib-common-file-text-sync"
                unelevated
                no-caps
                flat
                stretch
                @click="triggerFetchContracts"
            />
        </div>
    </div>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { FetchContractsSearchStatus } from '@/enums'
import GlobalSearchResultContract from '@/components/search/GlobalSearchResultContract'

import ContactName from '@/components/contact/ContactName.vue'
import StatusBadge from '@/components/StatusBadge.vue'
import GlobalSearchResultApplication from '@/components/search/GlobalSearchResultApplication'
// import ContactTypeBadges from '@/components/contact/ContactTypeBadges.vue'

export default {
    name: 'GlobalSearchResultCompany',
    components: {
        GlobalSearchResultApplication,
        GlobalSearchResultContract,
        ContactName,
        // ContactTypeBadges,
        StatusBadge,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        linkSearchResultComponents: {
            type: Boolean,
            default: false,
        },
        enableFetchContracts: {
            type: Boolean,
            default: false,
        },
        enabledResultTypes: {
            type: Array,
            default () {
                return ['Person', 'Company', 'Application', 'Contract']
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            FetchContractsSearchStatus,
        }
    },
    methods: {
        triggerFetchContracts (event) {
            event.preventDefault()
            event.stopPropagation()
            this.$emit('fetch-contracts', this.item)
        },
        kebabCase: function (text) {
            return kebabCase(text)
        },
    },
}
</script>
