<template>
    <!--
    TODO:
        - [ ] Filter by…
            - [ ] targetNode contact name
            - [ ] relationship type
            - [ ] areas of responsibility (?)
        - [ ] Order by…
            - [ ] targetNode contact name
            - [ ] relationship type
    -->
    <div>
        <base-table
            ref="table"
            :columns="columns"
            :fetch-objects-fn="ContactRelationship.service.all"
            :additional-filters="additionalFilters"
            :show-filter="false"
            enable-visible-columns
            user-settings-base-path="ContactRelationshipList"
            pagination-sort-by-default-key="created_at"
            class="contact-relationship-list"
            data-test="table:contact-relationship-list"
            @row-dblclick="(evt, row) => contact.status === ContactStatus.ARCHIVED ? null : $refs.contactRelationshipOverlay.open({ contactRelationship: row, contactRelationships: contactRelationships })"
            @update:response="updateContactRelationShips"
        >
            <template v-slot:header-cell-areas_of_responsibility="slotProps">
                <q-th :props="slotProps">
                    {{ $t('views.contact-relationship.list.colum-title--area-of-responsibility') }}
                    <info-icon :text="$t('common.contact.relationship.area-of-responsibility.area-of-responsibility--info-text')" />
                </q-th>
            </template>

            <template v-slot:body-cell-target_node="props">
                <q-td :props="props" class="target-node-contact-name" data-test="text:target-node-contact-name">
                    <contact-name
                        :contact="props.row.getTargetNode(contact.id)"
                        type-icon
                        highlighted
                        comma-separated
                        linked
                    />
                </q-td>
            </template>

            <template v-slot:body-cell-target_label="props">
                <q-td :props="props" class="target-label" data-test="text:target-node-label">
                    <span>
                        {{ props.row.getTargetNodeTypeText(contact.id) }} <span class="additional-info">{{ $t(`common.contact.relationship.node-type-prepositions.${kebabCase(props.row.getTargetNodeType(contact.id))}`) }} <contact-name :contact="contact" /></span>
                        <q-tooltip :delay="1000" anchor="center right" self="center left">{{ props.row.getRelationshipFullText(contact.id, { inverted: true }) }}</q-tooltip>
                    </span>
                </q-td>
            </template>

            <template v-slot:body-cell-areas_of_responsibility="props">
                <q-td :props="props" class="areas-of-responsibility" data-test="text:areas-of-responsibility">
                    <component :is="props.row.areasOfResponsibility.length > 1 ? 'ul' : 'div'" class="q-mb-none">
                        <component
                            :is="props.row.areasOfResponsibility.length > 1 ? 'li' : 'div'"
                            v-for="area in props.row.areasOfResponsibility"
                            :key="area.id"
                        >
                            <span class="area-name">{{ area.name }} <info-icon
                                v-if="area.description"
                                :title="area.name"
                                :text="area.description"
                            />
                            </span>
                            <span v-if="area.emailAddress || area.phoneNumber" class="contact-info additional-info"> (<a v-if="area.emailAddress" :href="`mailto:${area.emailAddress.email}`" target="_blank">{{ area.emailAddress.email }}</a>
                                <template v-if="area.emailAddress && area.phoneNumber">, </template>
                                <span class="phone-number" v-if="area.phoneNumber">{{ area.phoneNumber.phone_number }}</span>)
                            </span>
                        </component>
                    </component>
                </q-td>
            </template>

            <template v-slot:body-cell-actions="props">
                <q-td :props="props" auto-width>
                    <q-btn
                        v-if="contact.status !== ContactStatus.ARCHIVED"
                        class="overflow-hidden"
                        color="primary"
                        size="sm"
                        flat
                        round
                        icon="mib-navigation-menu-horizontal"
                        data-test="btn:tooltip-actions"
                    >
                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $t('common.term.more') }}</q-tooltip>

                        <q-menu anchor="top left" self="top right">
                            <div class="q-pl-xs q-pr-xs">
                                <q-btn
                                    color="primary"
                                    size="sm"
                                    flat
                                    round
                                    icon="mib-pencil"
                                    data-test="btn:tooltip-actions-update"
                                    @click="$refs.contactRelationshipOverlay.open({ contactRelationship: props.row, contactRelationships: contactRelationships })"
                                >
                                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.contact-relationship.list.update-relationship', 1) }}</q-tooltip>
                                </q-btn>

                                <span>
                                    <q-btn
                                        color="primary"
                                        size="sm"
                                        flat
                                        round
                                        icon="mib-bin"
                                        text-color="negative"
                                        data-test="btn:tooltip-actions-delete"
                                        :disabled="!!props.row.areasOfResponsibility.length"
                                        @click="openDeleteDialog(props.row, $event)"
                                    />
                                    <q-tooltip v-if="!!props.row.areasOfResponsibility.length" :delay="1000" :offset="[0, 10]">{{ $tc('views.contact-relationship.list.delete-relationship--not-possible', 1) }}</q-tooltip>
                                    <q-tooltip v-else :delay="1000" :offset="[0, 10]">{{ $tc('views.contact-relationship.list.delete-relationship', 1) }}</q-tooltip>
                                </span>
                            </div>
                        </q-menu>
                    </q-btn>
                </q-td>
            </template>
        </base-table>

        <!-- Create / update item -->
        <contact-relationship-overlay
            ref="contactRelationshipOverlay"
            :contact="contact"
            @refetch:objects="refetchObjects"
        />

        <!-- Delete item -->
        <form-dialog
            ref="deleteDialog"
            :item="contactRelationshipToDelete"
            :double-confirm="true"
            :dont-close-on-confirm="true"
            @confirm="handleDelete"
            @hide="resetContactRelationshipToDelete"
        >
            <template v-slot:title>{{ $tc('views.contact-relationship.list.delete-relationship', 1) }}</template>
            <template v-slot:default>
                <i18n v-if="contactRelationshipToDelete" path="views.contact-relationship.list.delete-relationship--confirm--temp-component-interpolation-count" tag="p">
                    <template v-slot:relationship><b>{{ contactRelationshipToDelete.getRelationshipTypeText(contact.id) }}-{{ $tc('common.contact.relationship.relationship', 1) }}</b></template>
                    <template v-slot:targetContact><b>{{ contactRelationshipToDelete.getTargetNode(contact.id).getContactName() }}</b> ({{ contactRelationshipToDelete.getTargetNodeTypeText(contact.id) }})</template>
                    <template v-slot:sourceContact><b>{{ contactRelationshipToDelete.getSourceNode(contact.id).getContactName() }}</b> ({{ contactRelationshipToDelete.getSourceNodeTypeText(contact.id) }})</template>
                </i18n>
            </template>
        </form-dialog>

        <in-page-footer v-if="contact.status !== ContactStatus.ARCHIVED">
            <base-button
                :label="$tc('common.contact.relationship.add-relationship', 1)"
                icon="mib-itx-human-resources-hierarchy-add"
                primary-button
                data-test="btn:create-relationship"
                @click="$refs.contactRelationshipOverlay.open({ contactRelationship: null, contactRelationships: contactRelationships })"
            />
        </in-page-footer>
    </div>
</template>

<script>
import { kebabCase } from 'lodash'
import { ContactRelationship } from '@/models/contactRelationship'
import { ContactStatus } from '@/enums/graphql'

import BaseTable from '@/components/BaseTable'
import FormDialog from '@/components/form/FormDialog'
import ContactName from '@/components/contact/ContactName.vue'
import ContactRelationshipOverlay from '@/components/contactRelationship/ContactRelationshipOverlay.vue'
import { EventBus } from '@/event-bus'

export default {
    name: 'ContactRelationshipList',
    components: {
        BaseTable,
        FormDialog,
        ContactName,
        ContactRelationshipOverlay,
    },
    props: {
        contact: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            ContactRelationship,
            ContactStatus,
            contactRelationships: [],
            contactRelationshipToDelete: null,
            columns: [
                {
                    name: 'target_node',
                    label: this.$tc('views.contact-relationship.list.colum-title--target-node-name', 1),
                    align: 'left',
                    required: true,
                },
                {
                    name: 'target_label',
                    label: this.$tc('views.contact-relationship.list.colum-title--target-node-label', 1),
                    align: 'left',
                    required: true,
                },
                {
                    name: 'notes',
                    label: this.$tc('common.term.note', 2),
                    field: row => row.notes,
                    align: 'left',
                    classes: 'break-spaces',
                },
                {
                    name: 'areas_of_responsibility',
                    label: this.$t('views.contact-relationship.list.colum-title--area-of-responsibility'),
                    align: 'left',
                },
                {
                    name: 'actions',
                    label: this.$tc('common.term.action', 2),
                    align: 'left',
                    required: true,
                },
            ],
        }
    },
    computed: {
        additionalFilters () {
            const filters = {
                search: {
                    node_id: this.contact.id,
                },
            }
            return filters
        },
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        onNewEntity () {
            this.$refs.contactRelationshipOverlay.open({ contactRelationship: null, contactRelationships: this.contactRelationships })
        },
        openDeleteDialog (contactRelationship, $event) {
            $event.preventDefault()
            this.contactRelationshipToDelete = contactRelationship
            this.$refs.deleteDialog.open()
        },
        handleDelete () {
            this.contactRelationshipToDelete.delete()
                .then(() => {
                    this.$refs.deleteDialog.close()

                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contact-relationship.contact-relationship-deleted-success', 1),
                    })

                    this.refetchObjects()
                }).catch(error => {
                    this.$refs.deleteDialog.showFormErrorMessage(error)
                    this.$refs.deleteDialog.resetFormSubmitStatus()
                })
        },
        resetContactRelationshipToDelete () {
            this.contactRelationshipToDelete = null
        },
        refetchObjects () {
            this.$refs.table.fetchObjects()
        },
        updateContactRelationShips (response) {
            this.contactRelationships = response.data
        },
        kebabCase (...args) {
            return kebabCase(...args)
        },
    },
}
</script>

<style lang="scss" scoped>
.areas-of-responsibility {
    min-width: 25ch;
    max-width: 40ch;
}

.area-name {
    white-space: break-spaces;
}

.contact-info {
    max-width: 100%;
    white-space: break-spaces;
    overflow-wrap: break-word;
}

.phone-number {
    white-space: nowrap;
}
</style>
