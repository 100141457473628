<template>
    <form data-test="form:auth-set-password" @submit.prevent="onFormSubmit">
        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="formErrorMessage"
            type="negative"
            data-test="error:global-message"
            v-html="formErrorMessage"
        />
        <!-- eslint-enable -->

        <div class="row q-col-gutter-sm">
            <div class="col-xs-12">
                <form-builder item-key="setPassword" autofocus /><!-- TODO: Implement revealPassword -->
            </div>
            <div class="col-xs-12">
                <form-builder item-key="confirmPassword" />
            </div>

            <q-stepper-navigation class="col-xs-12" :class="{ 'text-right': $q.screen.gt.md }">
                <base-button
                    data-test="btn:submit"
                    type="submit"
                    :label="$t('common.term.continue')"
                    :disable="formSubmitStatus"
                    :loading="formSubmitStatus"
                />
            </q-stepper-navigation>
        </div>
    </form>
</template>

<script>
import { formBuilderMixin } from '@/mixins/formBuilderMixin'

import AUTH_CREDENTIALS_FIELDS from '@/forms/auth.fields.json'

export default {
    name: 'AuthSetPassword',
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: AUTH_CREDENTIALS_FIELDS,
        fields: ['setPassword', 'confirmPassword'],
    },
    data () {
        return {
            revealPassword: false,
        }
    },
}
</script>
