import gql from 'graphql-tag'

export const CONTRACT_CONTRACT_NUMBER_UPDATE_MUTATION = gql`
    mutation contractContractNumberUpdate(
        $contractId: ID!
        $contractNumber: String!
    ) {
        contractContractNumberUpdate(
            contract_id: $contractId
            contract_number: $contractNumber
        ) {
            id
            contractNumbers {
                id
                number
            }
        }
    }
`
