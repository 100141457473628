import gql from 'graphql-tag'

export const CONTACT_PRODUCT_PROVIDER_SETTINGS_DELETE_MUTATION = gql`
    mutation contactProductProviderSettingsDelete(
        $contact_id: ID!
    ) {
        contactProductProviderSettingsDelete(
            contact_id: $contact_id
        ) {
            status
        }
    }
`
