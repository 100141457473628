import gql from 'graphql-tag'

export const PRODUCT_TEMPLATE_CHANGE_STATUS_MUTATION = gql`
    mutation productTemplateChangeStatus(
        $id: ID!
        $status: ProductTemplateStatus!
    ) {
        productTemplateChangeStatus(
            id: $id
            status: $status
        ) {
            id
            status
        }
    }
`
