export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'igb2bSectorCodeId',
            label: 'forms.field.label.commission-rate-adjustment-rule.igb2b-sector-code',
            inputType: 'igb2bSectorCode',
            validators: [
                {
                    type: 'requiredUnless',
                    params: {
                        'target': 'customSectorCode',
                        'targetLabel': 'forms.field.label.commission-rate-adjustment-rule.custom-sector-code',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'customSectorCode',
            label: 'forms.field.label.commission-rate-adjustment-rule.custom-sector-code',
            inputType: 'text',
            validators: [
                {
                    type: 'requiredUnless',
                    params: {
                        'target': 'igb2bSectorCodeId',
                        'targetLabel': 'forms.field.label.commission-rate-adjustment-rule.igb2b-sector-code',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'commissionRate',
            label: 'forms.field.label.commission-rate-adjustment-rule.commission-rate',
            inputType: 'number',
            validators: [
                {
                    type: 'required',
                },
            ],
            extra_attributes: {
                step: '0.01',
                max: '100',
                min: '0.01',
            },
        },
        {
            type: 'field',
            key: 'adjustedCommissionRate',
            label: 'forms.field.label.commission-rate-adjustment-rule.adjusted-commission-rate',
            inputType: 'number',
            validators: [
                {
                    type: 'required',
                },
                {
                    type: 'lessThan',
                    params: {
                        'target': 'commissionRate',
                        'targetLabel': 'forms.field.label.commission-rate-adjustment-rule.commission-rate',
                    },
                },
            ],
            extra_attributes: {
                step: '0.01',
                max: '100',
                min: '0.01',
            },
        },
    ],
}
