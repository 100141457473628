var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-button',{attrs:{"label":_vm.$tc('common.correspondence.compose-correspondence', 1),"icon":"mib-read-email-letter","flat":"","data-test":"btn:create-correspondence-item"}},[_c('q-menu',{attrs:{"anchor":"top end","self":"top start"}},[_c('q-list',{attrs:{"dense":""}},[_c('q-item',{attrs:{"clickable":"","data-test":"btn:create-letter"}},[_c('q-item-section',[_c('q-item-label',[_vm._v(_vm._s(_vm.$tc('common.correspondence.letter.write-letter', 1)))])],1),_c('q-menu',{attrs:{"anchor":"top end","self":"top start"}},[_c('q-list',{attrs:{"dense":""}},[_c('q-item',{attrs:{"to":{
                                name: 'correspondence-letter-create',
                                params: {
                                    correspondenceItemTargetObjectType: _vm.correspondenceItemTargetObjectType,
                                    correspondenceItemTargetObjectId: _vm.correspondenceItemTargetObjectId,
                                    correspondenceItemInitialDataSource: _vm.CorrespondenceItemInitialDataSource.TEMPLATE
                                }
                            },"data-test":"link:create-template"}},[_c('q-item-section',[_c('q-item-label',[_vm._v(_vm._s(_vm.$tc('common.correspondence.document-template.choose-template', 1)))])],1)],1),_c('q-item',{attrs:{"to":{
                                name: 'correspondence-letter-create',
                                params: {
                                    correspondenceItemTargetObjectType: _vm.correspondenceItemTargetObjectType,
                                    correspondenceItemTargetObjectId: _vm.correspondenceItemTargetObjectId,
                                    correspondenceItemInitialDataSource: _vm.CorrespondenceItemInitialDataSource.BLANK
                                }
                            },"data-test":"link:create-blank"}},[_c('q-item-section',[_c('q-item-label',[_vm._v(_vm._s(_vm.$tc('common.correspondence.letter.write-letter--blank', 1)))])],1)],1)],1)],1),_c('q-item-submenu-indicator')],1),_c('q-item',{attrs:{"clickable":"","data-test":"btn:create-email","disable":_vm.disableEmail}},[_c('q-item-section',[_c('q-item-label',[_vm._v(_vm._s(_vm.$tc('common.correspondence.email.write-email', 1)))])],1),(_vm.disableEmail)?_c('q-tooltip',{attrs:{"delay":1000,"offset":[0, 10],"anchor":"center right","self":"center left"}},[_vm._v(" "+_vm._s(_vm.$tc('common.email-address.no-email-address-on-record', 1))+" ")]):_c('q-menu',{attrs:{"anchor":"top end","self":"top start"}},[_c('q-list',{attrs:{"dense":""}},[_c('q-item',{attrs:{"to":{
                                name: 'correspondence-email-create',
                                params: {
                                    correspondenceItemTargetObjectType: _vm.correspondenceItemTargetObjectType,
                                    correspondenceItemTargetObjectId: _vm.correspondenceItemTargetObjectId,
                                    correspondenceItemInitialDataSource: _vm.CorrespondenceItemInitialDataSource.TEMPLATE
                                }
                            },"data-test":"link:create-template"}},[_c('q-item-section',[_c('q-item-label',[_vm._v(_vm._s(_vm.$tc('common.correspondence.document-template.choose-template', 1)))])],1)],1),_c('q-item',{attrs:{"to":{
                                name: 'correspondence-email-create',
                                params: {
                                    correspondenceItemTargetObjectType: _vm.correspondenceItemTargetObjectType,
                                    correspondenceItemTargetObjectId: _vm.correspondenceItemTargetObjectId,
                                    correspondenceItemInitialDataSource: _vm.CorrespondenceItemInitialDataSource.BLANK
                                }
                            },"data-test":"link:create-blank"}},[_c('q-item-section',[_c('q-item-label',[_vm._v(_vm._s(_vm.$tc('common.correspondence.email.write-email--blank', 1)))])],1)],1)],1)],1),_c('q-item-submenu-indicator')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }