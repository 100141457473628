import { Model } from '@/models'

import { EcoHubTechUserService } from '@/services'
import { EcoHubTechUserActions } from '@/enums'


export class EcoHubTechUser extends Model {
    /**
     * Create a EcoHubTechUser model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'idp_user_id',
        ], data)
    }

    static get service () { return EcoHubTechUserService }
    static action = EcoHubTechUserActions

    get actions () {
        const actions = super.actions

        actions.push({
            key: EcoHubTechUserActions.RE_ENROLL,
            item: this,
        })

        actions.push({
            key: EcoHubTechUserActions.DELETE,
            item: this,
        })

        return actions
    }

    /**
     * Create a new ecoHubTechUser.
     * @param {Object} variables - The values used to create the ecoHubTechUser.
     * @param {string} variables.idpUserId
     * @param {string} variables.password
     * @param {string} variables.iak
     * @returns {Promise<*>}
     */
    static create ({ idpUserId, password, iak }) {
        const variables = {
            idp_user_id: idpUserId,
            password,
            iak,
        }
        return EcoHubTechUserService.create(variables)
    }

    /**
     * Update this ecoHubTechUser.
     * @param {Object} variables - The values used to update the ecoHubTechUser.
     * @param {string} variables.idpUserId
     * @param {string} variables.password
     * @param {string} variables.iak
     * @returns {Promise<*>}
     */
    update ({ idpUserId, password, iak }) {
        const variables = {
            idp_user_id: idpUserId,
            password,
            iak,
        }

        return EcoHubTechUserService.update(variables).then(ecoHubTechUser => {
            Object.assign(this, ecoHubTechUser)
            return ecoHubTechUser
        })
    }

    /**
     * Delete this ecoHubTechUser.
     * @returns {Promise<*>}
     */
    delete () {
        return EcoHubTechUserService.delete()
    }
}
