<template>
    <page-loading-indicator
        v-if="!user"
        subtitle
        subtitle-on-new-line
        actions
        no-more-actions-button
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="user.getUserName({ email: true })">
                    <status-badge :status="user.status" />
                </q-breadcrumbs-el>
            </template>

            <h1 v-if="user.consultant">
                {{ user.getUserName() }}
                <div v-if="user.consultant" class="subtitle" data-test="text:user-email">{{ user.email }}</div>
            </h1>
            <h1 v-else data-test="text:user-email">{{ user.getUserName() }}</h1>

            <template v-slot:actions>
                <actions
                    v-if="user.actions"
                    :actions="user.actions"
                    :number-of-next-best-actions="1"
                    @click="handleActions"
                />

                <!-- Resend invitation -->
                <form-dialog
                    ref="resendInviteDialog"
                    dont-close-on-confirm
                    @confirm="handleResendInvite"
                >
                    <template v-slot:title>{{ $tc('common.actions.user.resend-invite', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.users.detail.resend-invite--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:userName><b>{{ user.email }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Change email -->
                <form-dialog
                    ref="changeEmailDialog"
                    :form-builder-settings="formBuilderSettings"
                    dont-close-on-confirm
                    @confirm="handleChangeEmail"
                >
                    <template v-slot:title>{{ $tc('common.actions.user.change-email', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.users.detail.change-email--confirm--temp-component-interpolation-count" tag="p" class="q-mb-lg">
                            <template v-slot:userName><b>{{ user.getUserName() }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Deactivate user -->
                <form-dialog
                    ref="deactivateDialog"
                    dont-close-on-confirm
                    @confirm="handleDeactivate"
                >
                    <template v-slot:title>{{ $tc('common.actions.user.deactivate', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.users.detail.deactivate--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:userName><b>{{ user.email }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Re-invite user -->
                <form-dialog
                    ref="inviteDialog"
                    dont-close-on-confirm
                    @confirm="handleInvite"
                >
                    <template v-slot:title>{{ $tc('common.actions.user.invite', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.users.detail.invite--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:userName><b>{{ user.email }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Delete user -->
                <form-dialog
                    ref="deleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleDelete"
                >
                    <template v-slot:title>{{ $tc('common.actions.user.delete', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.users.detail.delete--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:userName><b>{{ user.email }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <info-box v-if="user.hasPendingEmailChange">
            <p>{{ $tc('views.admin.users.detail.change-email-address--pending-request--info', 1) }}</p>
            <del>{{ user.email }}</del> → {{ user.new_email }}
        </info-box>

        <div class="row q-col-gutter-md">
            <grid-card
                :columns="{ xs: 12, sm: 6, md: 6, lg: 4, xl: 4 }"
                :heading="$tc('views.admin.users.detail.linked-consultant', user.consultant ? 1 : 0)"
            >
                <template v-if="!showAssignPersonForm">
                    <template v-if="user.consultant">
                        <contact-name :contact="user.consultant" salutation />
                    </template>
                    <info-box v-else type="warning">
                        <p>{{ $tc('views.admin.users.detail.linked-consultant--description', 1) }}</p>
                    </info-box>
                </template>

                <user-assign-person-form
                    v-else
                    ref="userAssignPersonForm"
                    :person-id="(user.consultant) ? user.consultant.id : undefined"
                    @submit="assignPerson"
                    @cancel="showAssignPersonForm = false"
                />

                <template v-slot:actions>
                    <template v-if="!user.consultant">
                        <base-button
                            v-if="!showAssignPersonForm"
                            :label="$tc('views.admin.users.detail.link-to-consultant', 1)"
                            primary-button
                            @click="showAssignPersonForm = true"
                        />
                    </template>
                    <template v-else>
                        <base-button
                            v-if="!showAssignPersonForm"
                            :label="$tc('views.admin.users.detail.edit-linked-consultant', 1)"
                            flat
                            @click="showAssignPersonForm = true"
                        />
                    </template>
                </template>
            </grid-card>

            <grid-card
                :columns="{ xs: 12, sm: 6, md: 6, lg: 4, xl: 4 }"
                :heading="$tc('common.term.role', 2)"
            >
                <template v-if="showAssignRolesForm">
                    <user-assign-roles-form
                        ref="userAssignRolesForm"
                        :role-ids="user.roles.map(role => role.id)"
                        @submit="assignRoles"
                        @cancel="showAssignRolesForm = false"
                    />
                </template>
                <template v-else>
                    <ul v-if="user.roles.length" data-test="text:user-assigned-roles">
                        <li v-for="(role, index) in user.roles" :key="role.id">{{ role.name }} <info-icon :text="role.description" /></li>
                    </ul>
                    <p v-else>
                        <a
                            v-if="!showAssignPersonForm"
                            class="q-link do-not-print"
                            tabindex="0"
                            @click="showAssignPersonForm = true"
                        >{{ $tc('views.admin.users.detail.assign-role', 1) }}</a>
                    </p>
                </template>

                <template v-slot:actions>
                    <base-button
                        v-if="!showAssignRolesForm"
                        :label="$tc('views.admin.users.detail.edit-role', 2)"
                        flat
                        data-test="btn:user-edit-roles"
                        @click="showAssignRolesForm = true"
                    />
                </template>
            </grid-card>
        </div>
    </page-wrapper>
</template>

<script>
import StatusBadge from '@/components/StatusBadge.vue'
import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'
import ContactName from '@/components/contact/ContactName.vue'
import UserAssignPersonForm from '@/components/user/UserAssignPersonForm.vue'
import UserAssignRolesForm from '@/components/user/UserAssignRolesForm.vue'

import { User } from '@/models/user'

export default {
    name: 'AdminUserDetail',
    components: {
        StatusBadge,
        Actions,
        FormDialog,
        ContactName,
        UserAssignPersonForm,
        UserAssignRolesForm,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    meta () {
        return {
            title: !this.user ? `${this.$t('common.term.loading')} …` : `${this.$tc('common.term.user', 1)}: ${this.user.getUserName()}`,
        }
    },
    data () {
        return {
            User,
            user: null,
            showAssignPersonForm: false,
            showAssignRolesForm: false,
        }
    },
    computed: {
        formBuilderSettings () {
            const settings = {
                schema: {
                    type: 'root',
                    children: [{
                        type: 'field',
                        key: 'username',
                        label: 'forms.field.label.email-address.email-address',
                        inputType: 'text',
                        validators: [
                            { type: 'required' },
                            { type: 'email' },
                        ],
                    }],
                },
            }
            return settings
        },
    },
    created () {
        User.objects.get(this.id).then(user => {
            this.user = user
        })
    },
    methods: {
        handleActions (action) {
            switch (action.key) {
                case User.action.RESENDINVITE:
                    this.$refs.resendInviteDialog.open()
                    break
                case User.action.CHANGE_EMAIL:
                    this.$refs.changeEmailDialog.open()
                    break
                case User.action.DEACTIVATE:
                    this.$refs.deactivateDialog.open()
                    break
                case User.action.INVITE:
                    this.$refs.inviteDialog.open()
                    break
                case User.action.DELETE:
                    this.$refs.deleteDialog.open()
                    break
            }
        },
        handleResendInvite () {
            this.user.resendInvitation()
                .then(() => {
                    this.$refs.resendInviteDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.user.user-resent-invite-success', 1),
                        attrs: {
                            'data-test': 'notification:user-resent-invite-success',
                        },
                    })
                })
                .catch(error => {
                    this.$refs.resendInviteDialog.showFormErrorMessage(error)
                    this.$refs.resendInviteDialog.resetFormSubmitStatus()
                })
        },
        handleChangeEmail (formData) {
            this.user.changeEmail(formData.username)
                .then(() => {
                    this.$refs.changeEmailDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.user.user-change-email-success', 1, { userEmail: this.user.new_email }),
                        attrs: {
                            'data-test': 'notification:user-change-email-success',
                        },
                    })
                })
                .catch(error => {
                    this.$refs.changeEmailDialog.showFormErrorMessage(error)
                    this.$refs.changeEmailDialog.resetFormSubmitStatus()
                })
        },
        handleDeactivate () {
            this.user.deactivate()
                .then(() => {
                    this.$refs.deactivateDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.user.user-deactivated-success', 1),
                        attrs: {
                            'data-test': 'notification:user-deactivated-success',
                        },
                    })
                })
                .catch(error => {
                    this.$refs.deactivateDialog.showFormErrorMessage(error)
                    this.$refs.deactivateDialog.resetFormSubmitStatus()
                })
        },
        handleInvite () {
            this.user.invite()
                .then(() => {
                    this.$refs.inviteDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.user.user-invited-success', 1),
                        attrs: {
                            'data-test': 'notification:user-invited-success',
                        },
                    })
                })
                .catch(error => {
                    this.$refs.inviteDialog.showFormErrorMessage(error)
                    this.$refs.inviteDialog.resetFormSubmitStatus()
                })
        },
        handleDelete () {
            this.user.delete()
                .then(() => {
                    this.$refs.deleteDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.user.user-deleted-success', 1),
                        attrs: {
                            'data-test': 'notification:user-deleted-success',
                        },
                    })
                    this.$router.replace({ name: 'admin-user-list' })
                })
                .catch(error => {
                    this.$refs.deleteDialog.showFormErrorMessage(error)
                    this.$refs.deleteDialog.resetFormSubmitStatus()
                })
        },
        assignPerson ({ personId }) {
            this.user.assignPerson(personId)
                .then(() => {
                    this.hideForms()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.user.user-assigned-person-success', 1),
                        attrs: {
                            'data-test': 'notification:user-assigned-person-success',
                        },
                    })
                })
                .catch(error => {
                    this.$refs.userAssignPersonForm.showFormErrorMessage(error)
                    this.$refs.userAssignPersonForm.resetFormSubmitStatus()
                })
        },
        assignRoles ({ roleIds }) {
            this.user.assignRoles(roleIds)
                .then(() => {
                    this.hideForms()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.user.user-assigned-roles-success', 1),
                        attrs: {
                            'data-test': 'notification:user-assigned-roles-success',
                        },
                    })
                })
                .catch(error => {
                    this.$refs.userAssignRolesForm.showFormErrorMessage(error)
                    this.$refs.userAssignRolesForm.resetFormSubmitStatus()
                })
        },
        hideForms () {
            this.showAssignPersonForm = false
            this.showAssignRolesForm = false
        },
    },
}
</script>
