<template>
    <component :is="component" class="form-item-repeater-item">
        <slot v-bind="{ item }"></slot>
        <slot name="buttons" v-bind="{ item }"><base-button
            :label="$t('common.term.delete')"
            color="negative"
            outline
            @click="item.remove()"
        /></slot>
    </component>
</template>

<script>
import { FormRepeaterItem } from '@/libs/form'

export default {
    name: 'FormRepeaterItemComponent',
    props: {
        item: {
            type: FormRepeaterItem,
            required: true,
        },
        component: {
            type: String,
            default: 'div',
        },
    },
}
</script>
