import { EditorContentSchemaType } from '@max/tiptap-extensions'
import { EditorDisplayMode, SmartObjectTreeDisplayMode } from '@/enums'
import { ContactType, DocumentContentFormality, GenderChoice } from '@/enums/graphql'

const contactTypeOptions = Object.values(ContactType).map(value => ({ value: value, label: `common.contact.contact-type.contact-type--${value.toLowerCase()}` }))
const formalityOptions = Object.values(DocumentContentFormality).map(value => ({ value: value, label: `common.contact.formality.formality--${value.toLowerCase()}` }))
const genderOptions = Object.values(GenderChoice).map(value => ({ value: value, label: `common.contact.gender-${value.toLowerCase()}` }))

export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'contactType',
            label: 'common.contact.contact-type.contact-type',
            inputType: 'select',
            validators: [
                { type: 'required' },
            ],
            extra_attributes: {
                list: {
                    options: contactTypeOptions,
                },
            },
        },
        {
            type: 'field',
            key: 'formality',
            label: 'common.contact.formality.formality',
            inputType: 'select',
            validators: [
                { type: 'required' },
            ],
            extra_attributes: {
                list: {
                    options: formalityOptions,
                },
            },
        },
        {
            type: 'field',
            key: 'gender',
            label: 'common.contact.gender',
            inputType: 'select',
            validators: [
                { type: 'required' },
            ],
            extra_attributes: {
                list: {
                    options: genderOptions,
                },
            },
        },
        {
            type: 'field',
            key: 'languageId',
            label: 'common.contact.correspondence-language',
            inputType: 'correspondenceLanguage',
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'salutation',
            label: 'common.contact.salutation',
            inputType: 'editor',
            validators: [
                { type: 'requiredProperty', params: { propertyName: 'contentHTML' } },
            ],
            extra_attributes: {
                schemaType: EditorContentSchemaType.SALUTATION,
                displayMode: EditorDisplayMode.MINIMAL,
                smartObjectTreeDisplayMode: SmartObjectTreeDisplayMode.NONE,
            },
        },
    ],
}
