import { Model } from '@/models'
import { CommissionDistributionRuleSetService } from '@/services'
import { CommissionDistributionRuleSetActions } from '@/enums'
import { i18n } from '@/i18n'

export class CommissionDistributionRuleSet extends Model {
    /**
     * Create a CommissionDistributionRuleSet model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'name',
            'description',
            'rules',
            'is_assigned',
        ], data)
    }

    static get service () {
        return CommissionDistributionRuleSetService
    }

    static action = CommissionDistributionRuleSetActions

    /**
     * Add custom actions.
     * @returns {Array}
     */
    get actions () {
        const actions = super.actions

        const deleteAction = {
            key: CommissionDistributionRuleSetActions.DELETE,
            item: this,
        }

        if (this.is_assigned) {
            Object.assign(deleteAction, {
                disable: true,
                infoText: i18n.tc('views.commission.commission-distribution.rule-set-assigned--info', 1),
            })
        }

        actions.push(deleteAction)
        return actions
    }

    /**
     * Create a new commissionDistributionRuleSet.
     * @param {CommissionDistributionRuleSetInput} commissionDistributionRuleSet - The values used to create the commissionDistributionRuleSet.
     * @returns {Promise<*>}
     */
    static create (commissionDistributionRuleSet) {
        const variables = {
            commissionDistributionRuleSet: commissionDistributionRuleSet,
        }
        return CommissionDistributionRuleSetService.create(variables)
    }

    /**
     * Update this commissionDistributionRuleSet.
     * @param {CommissionDistributionRuleSetInput} commissionDistributionRuleSet - The values used to update the commissionDistributionRuleSet.
     * @returns {Promise<*>}
     */
    update (commissionDistributionRuleSet) {
        return CommissionDistributionRuleSetService.update(this.id, commissionDistributionRuleSet).then(commissionDistributionRuleSet => {
            Object.assign(this, commissionDistributionRuleSet)
            return commissionDistributionRuleSet
        })
    }

    /**
     * Delete this commissionDistributionRuleSet.
     * @returns {Promise<*>}
     */
    delete () {
        return CommissionDistributionRuleSetService.delete(this.id)
    }
}
