import { Model } from '@/models'

import { ContactService } from '@/services'
import { computed } from 'vue'

export class EmailAddress extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'category',
            'label',
            'email',
            'is_main_email_address',

            // Timestamps
            'created_at',
            'updated_at',
        ], data)

        // Computed
        this.formattedCategoryLabel = computed(() => {
            return this.label ? `${this.category?.name}: ${this.label?.label}` : this.category?.name
        })
    }

    static create (contactId, { category, label, emailAddress, isMain }) {
        const variables = {
            contact_id: contactId,
            emailAddress: {
                category_id: category,
                label_id: label,
                email: emailAddress,
                is_main_email_address: isMain,
            },
        }
        return ContactService.createEmailAddress(variables)
    }

    update ({ category, label, emailAddress, isMain }) {
        const emailAddressVariables = {
            category_id: category,
            label_id: label,
            email: emailAddress,
            is_main_email_address: isMain,
        }
        // TODO: Directly update email address object?
        return ContactService.updateEmailAddress(this.id, emailAddressVariables)
    }

    delete () {
        return ContactService.deleteEmailAddress(this.id)
    }
}
