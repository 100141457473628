<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.accounting.transfer-posting.transfer-posting', 2)" :to="{ name: 'accounting-posting-list' }" />
            </template>

            <h1>{{ $tc('views.accounting.transfer-posting.transfer-posting', 2) }}</h1>
        </page-header>

        <div class="row q-col-gutter-md">
            <grid-card
                :columns="{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }"
                :heading="$tc('common.accounting.account.choose-account', 2)"
            >
                <account-search-form ref="accountSearchForm" @submit="onFilterSubmit" />
            </grid-card>

            <grid-card
                :columns="{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }"
                :heading="$tc('views.accounting.transfer-posting.configure-posting', 2)"
            >
                <posting-configuration-form ref="postingConfigurationForm" />
            </grid-card>
        </div>

        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="errorMessage"
            type="negative"
            data-test="error:global-message"
            v-html="errorMessage"
        />
        <!-- eslint-enable -->

        <base-table
            v-if="accountSearchFilters"
            ref="table"
            :columns="columns"
            :fetch-objects-fn="fetchAccounts"
            :fetch-objects-post-processing-fn="setTableItemObject"
            :additional-filters="additionalFilters"
            user-settings-base-path="AccountingTransferPostingList"
            pagination-sort-by-default-key="number"
            selection="multiple"
            :selected.sync="selectedItems"
            :selection-row-condition="selectionRowCondition"
            enable-visible-columns
            disable-selection-dblclick
            @update:selected="setSelectedItems"
        >
            <!-- TODO improvement @TFU: We should get the reason(s) why a row is not selectable here to be able to show according tooltips. -->
            <template v-slot:selectionRowConditionInfo>
                <q-checkbox
                    :value="false"
                    class="not-selectable"
                    color="secondary-light"
                    keep-color
                    dense
                    disable
                />
                <!-- TODO improvement @MTR: Add tooltip with explanation why a row cannot be selected. -->
            </template>

            <template v-slot:header-cell-balance="slotProps">
                <q-th class="text-right" :props="slotProps">
                    {{ $tc('common.accounting.account.balance', 1) }}<br>
                    <small class="additional-info">{{ $tc('views.accounting.transfer-posting.column-title--balance-change', 1) }}</small>
                </q-th>
            </template>

            <template v-slot:header-cell-swap>
                <q-th class="swap-col border-left border-right truncate-text text-center additional-info">
                    <q-icon name="mib-data-transfer-horizontal" class="cursor-help" />
                    <q-tooltip
                        :delay="1000"
                        anchor="top middle"
                        self="bottom middle"
                        :offset="[0, 0]"
                    >{{ $t('views.accounting.transfer-posting.swap-accounts--description') }}</q-tooltip>
                </q-th>
            </template>

            <template v-slot:header-cell-credit_account_balance>
                <q-th class="text-right">
                    {{ $tc('common.accounting.account.balance', 1) }}<br>
                    <small class="additional-info">{{ $tc('views.accounting.transfer-posting.column-title--balance-change', 1) }}</small>
                </q-th>
            </template>

            <template v-slot:body-cell-number="slotProps">
                <q-td :props="slotProps" class="truncate-text" data-test="td:debit-account-number">
                    <template v-if="slotProps.row.debitAccount">
                        <span class="account-number monospace text-bold">{{ slotProps.row.debitAccount.number }}</span> <span class="account-name additional-info">– {{ slotProps.row.debitAccount.name }}</span>
                        <q-tooltip
                            :delay="500"
                            anchor="center start"
                            self="center start"
                            :offset="[0, 0]"
                            max-width="70ch"
                        >{{ slotProps.row.debitAccount.number }} – {{ slotProps.row.debitAccount.name }}
                        </q-tooltip>
                    </template>
                    <span v-else class="additional-info">–</span>
                </q-td>
            </template>

            <template v-slot:body-cell-balance="slotProps">
                <q-td :props="slotProps" data-test="td:debit-account-balance">
                    <template v-if="slotProps.row.debitAccount">
                        <div class="cursor-help">
                            <span :class="['account-balance monospace text-bold', { 'text-negative-dark': slotProps.row.debitAccount.balance < 0 }]">
                                <template v-if="slotProps.row.debitAccount.formattedBalance">
                                    {{ slotProps.row.debitAccount.formattedBalance }}
                                </template>
                                <info-icon
                                    v-else
                                    :text="$t('views.accounting.transfer-posting.account-balance-not-available--info')"
                                />
                            </span>
                            <template v-if="slotProps.row.superOrdinateAccount">
                                <div v-if="slotProps.row.postingAmount > 0" :class="`posting-balance-change text-${slotProps.row.getAccountBalanceChangeDetails(slotProps.row.debitAccount).color}`">
                                    {{ slotProps.row.getFormattedAccountBalanceChange(slotProps.row.debitAccount, true) }}
                                </div>
                                <div v-else class="additional-info">–</div>
                            </template>
                        </div>
                        <q-tooltip
                            v-if="slotProps.row.superOrdinateAccount"
                            :delay="500"
                            :offset="[0, 10]"
                            max-width="50ch"
                        >
                            <template v-if="slotProps.row.postingAmount > 0">
                                <q-icon
                                    v-if="slotProps.row.getAccountBalanceChangeDetails(slotProps.row.debitAccount).change"
                                    :name="slotProps.row.getAccountBalanceChangeDetails(slotProps.row.debitAccount).icon"
                                    :color="slotProps.row.getAccountBalanceChangeDetails(slotProps.row.debitAccount).color"
                                    class="q-mr-xs"
                                />
                                <i18n :path="`views.accounting.transfer-posting.posting-balance-change--${slotProps.row.getAccountBalanceChangeDetails(slotProps.row.debitAccount).change || 'unchanged'}`">
                                    <template v-slot:account><b>{{ `${slotProps.row.debitAccount.number} – ${slotProps.row.debitAccount.name}` }}</b></template>
                                    <template v-slot:diffAmount><b>{{ slotProps.row.getFormattedAccountBalanceChange(slotProps.row.debitAccount) }}</b></template>
                                </i18n>
                                <template v-if="!slotProps.row.isSwapped && slotProps.row.getAccountBalanceChangeDetails(slotProps.row.debitAccount).change"> {{ $tc('views.accounting.transfer-posting.posting-balance-change--estimated-balance', 1, { newBalance: slotProps.row.getAccountBalanceChangeDetails(slotProps.row.debitAccount).formattedProjectedBalance }) }}</template>
                            </template>
                            <template v-else>{{ $tc('views.accounting.transfer-posting.posting-balance-change--unchanged', 1, { account : `${slotProps.row.debitAccount.number} – ${slotProps.row.debitAccount.name}` }) }}</template>
                        </q-tooltip>
                    </template>
                    <span v-else class="additional-info">–</span>
                </q-td>
            </template>

            <template v-slot:body-cell-swap="slotProps">
                <q-td :props="slotProps" data-test="td:swap">
                    <base-button
                        v-if="slotProps.row.superOrdinateAccount"
                        size="sm"
                        :flat="!slotProps.row.isSwapped"
                        round
                        :color="slotProps.row.isSwapped ? 'accent-light' : 'primary'"
                        :text-color="slotProps.row.isSwapped ? 'secondary' : 'primary'"
                        :icon="slotProps.row.isSwapped ? 'mib-itx-swap' : 'mib-data-transfer-horizontal'"
                        :class="['swap-button', { 'is-swapped': slotProps.row.isSwapped, 'flip-vertical': !slotProps.row.isSwapped }]"
                        @click="toggleSwapItem(slotProps.row)"
                    >
                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.accounting.transfer-posting.swap-accounts', 1) }}</q-tooltip>
                    </base-button>
                </q-td>
            </template>

            <template v-slot:body-cell-credit_account="slotProps">
                <q-td :props="slotProps" class="truncate-text" data-test="td:credit-account-number">
                    <template v-if="slotProps.row.creditAccount">
                        <span class="account-number monospace text-bold">{{ slotProps.row.creditAccount.number }}</span> <span class="account-name additional-info">– {{ slotProps.row.creditAccount.name }}</span>
                        <q-tooltip
                            :delay="1000"
                            anchor="center start"
                            self="center start"
                            :offset="[0, 0]"
                            max-width="70ch"
                        >{{ slotProps.row.creditAccount.number }} – {{ slotProps.row.creditAccount.name }}
                        </q-tooltip>
                    </template>
                    <span v-else class="additional-info">–</span>
                </q-td>
            </template>

            <template v-slot:body-cell-credit_account_balance="slotProps">
                <q-td :props="slotProps" data-test="td:credit-account-balance">
                    <template v-if="slotProps.row.creditAccount">
                        <div class="cursor-help">
                            <!-- TODO improvement @TFU: Make superordinateAccount.balance available -->
                            <span :class="['account-balance monospace text-bold', { 'text-negative-dark': slotProps.row.creditAccount.balance < 0 }]">
                                <template v-if="slotProps.row.creditAccount.formattedBalance">
                                    {{ slotProps.row.creditAccount.formattedBalance }}
                                </template>
                                <info-icon
                                    v-else
                                    :text="$t('views.accounting.transfer-posting.account-balance-not-available--info')"
                                />
                            </span>
                            <template v-if="slotProps.row.superOrdinateAccount">
                                <div v-if="slotProps.row.postingAmount > 0" :class="`posting-balance-change text-${slotProps.row.getAccountBalanceChangeDetails(slotProps.row.creditAccount).color}`">
                                    {{ slotProps.row.getFormattedAccountBalanceChange(slotProps.row.creditAccount, true) }}
                                </div>
                                <div v-else class="additional-info">–</div>
                            </template>
                        </div>
                        <q-tooltip
                            v-if="slotProps.row.superOrdinateAccount"
                            :delay="500"
                            :offset="[0, 10]"
                            max-width="50ch"
                        >
                            <template v-if="slotProps.row.postingAmount > 0">
                                <q-icon
                                    v-if="slotProps.row.getAccountBalanceChangeDetails(slotProps.row.creditAccount).change"
                                    :name="slotProps.row.getAccountBalanceChangeDetails(slotProps.row.creditAccount).icon"
                                    :color="slotProps.row.getAccountBalanceChangeDetails(slotProps.row.creditAccount).color"
                                    class="q-mr-xs"
                                />
                                <i18n :path="`views.accounting.transfer-posting.posting-balance-change--${slotProps.row.getAccountBalanceChangeDetails(slotProps.row.creditAccount).change || 'unchanged'}`">
                                    <template v-slot:account><b>{{ `${slotProps.row.creditAccount.number} – ${slotProps.row.creditAccount.name}` }}</b></template>
                                    <template v-slot:diffAmount><b>{{ slotProps.row.getFormattedAccountBalanceChange(slotProps.row.creditAccount) }}</b></template>
                                </i18n>
                                <template v-if="slotProps.row.isSwapped && slotProps.row.getAccountBalanceChangeDetails(slotProps.row.creditAccount).change"> {{ $tc('views.accounting.transfer-posting.posting-balance-change--estimated-balance', 1, { newBalance: slotProps.row.getAccountBalanceChangeDetails(slotProps.row.creditAccount).formattedProjectedBalance }) }}</template>
                            </template>
                            <template v-else>{{ $tc('views.accounting.transfer-posting.posting-balance-change--unchanged', 1, { account : `${slotProps.row.creditAccount.number} – ${slotProps.row.creditAccount.name}` }) }}</template>
                        </q-tooltip>
                    </template>
                    <span v-else class="additional-info">–</span>
                </q-td>
            </template>

            <template v-slot:body-cell-posting_date="props">
                <q-td :props="props">
                    <calendar-input
                        v-if="!props.row.itemAccount.assignedTo.is_tenant_company"
                        v-model="props.row.postingDate"
                        :disable="$refs.postingConfigurationForm.postingDateType === PostingDateType.UNIVERSAL"
                        :error="props.row.validations.postingDate.$error"
                    >
                        <template v-slot:error>
                            <div v-if="props.row.validations.postingDate.required.$invalid">{{ $tc('common.error-message.required', 1, { field: $tc('common.accounting.posting.date', 1) }) }}</div>
                            <div v-if="props.row.validations.postingDate.isValidIsoDate.$invalid">{{ $tc('common.error-message.type-date', 1, { field: $tc('common.accounting.posting.date', 1) }) }}</div>
                        </template>
                    </calendar-input>
                    <span v-else class="additional-info">–</span>
                </q-td>
            </template>

            <template v-slot:body-cell-posting_balance="props">
                <q-td :props="props">
                    <base-input
                        v-if="!props.row.itemAccount.assignedTo.is_tenant_company"
                        v-model="props.row.postingAmount"
                        type="number"
                        prefix="CHF"
                        min="0"
                        :step="props.row.postingConfigurationForm.formData.postingTypeRounding"
                        :error="props.row.validations.postingAmount.$error"
                    >
                        <template v-slot:error>
                            <div v-if="props.row.validations.postingAmount.required.$invalid">{{ $tc('common.error-message.required', 1, { field: $tc('common.accounting.posting.amount', 1) }) }}</div>
                            <div v-if="props.row.validations.postingAmount.minValue.$invalid">{{ $tc('common.error-message.more-than-value', 1, { field: $tc('common.accounting.posting.amount', 1), value: '0.00' }) }}</div>
                        </template>
                    </base-input>
                    <span v-else class="additional-info">–</span>
                </q-td>
            </template>

            <template v-slot:body-cell-posting_text="props">
                <q-td :props="props">
                    <base-input
                        v-if="!props.row.itemAccount.assignedTo.is_tenant_company"
                        v-model="props.row.postingText"
                        :error="props.row.validations.postingText.$error"
                        :error-message="$tc('common.error-message.required', 1, { field: $tc('common.accounting.posting.text', 1) })"
                    />
                    <span v-else class="additional-info">–</span>
                </q-td>
            </template>
        </base-table>

        <in-page-footer>
            <!-- TODO improvement @TFU: We should get the reason(s) why it is not possible to create the transfer postings to be able to show according tooltips. -->
            <base-button
                :label="$tc('views.accounting.transfer-posting.transfer-amount', selectedItems.length || 2)"
                :disable="disableCreateButton"
                :loading="isPosting"
                primary-button
                @click="onCreateSubmit"
            />
            <!-- TODO improvement @MTR: Add tooltip with explanation why it is not possible to create the transfer postings. -->
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { AccountAssignedAsType, ConsultingSettingsStatus } from '@/enums/graphql'
import { i18n } from '@/i18n'
import { kebabCase } from 'lodash'
import { required, minLength } from 'vuelidate/lib/validators'
import { AccountingTransferPostingAccount, AccountingTransferPostingItem } from '@/helpers/posting'
import { extractErrorMessage, isValidIsoDate } from '@/helpers/form'
import { formatCurrency } from '@/helpers/number'
import { Account } from '@/models/account'
import { Posting } from '@/models/posting'
import { PostingDateType } from '@/enums'

import BaseTable from '@/components/BaseTable'
import BaseInput from '@/components/form/BaseInput'
import CalendarInput from '@/components/form/CalendarInput'
import AccountSearchForm from '@/components/accounting/AccountSearchForm'
import PostingConfigurationForm from '@/components/accounting/PostingConfigurationForm'

export default {
    name: 'AccountingTransferPosting',
    meta () {
        return {
            title: this.$tc('views.accounting.transfer-posting.transfer-posting', 2),
        }
    },
    components: {
        AccountSearchForm,
        PostingConfigurationForm,
        BaseTable,
        BaseInput,
        CalendarInput,
    },
    data () {
        return {
            PostingDateType,
            accountSearchFilters: null,
            selectedItems: [],
            selectedItemIds: [],
            swappedItemIds: [],
            errorMessage: '',
            isPosting: false,
            columns: [
                {
                    name: 'number',
                    label: this.$tc('common.accounting.account.type.debit-account', 1),
                    align: 'left',
                    sortable: true,
                    required: true,
                },
                {
                    name: 'debit_account_assigned_as_type',
                    label: this.$t('common.accounting.account.assigned-as'),
                    align: 'left',
                    field: row => row.debitAccount ? i18n.t(`common.accounting.account.type.${kebabCase(row.debitAccount.assigned_as_type.replace('CONSULTING_SETTINGS_', ''))}`) : '–',
                    hideInitially: true,
                    classes: 'truncate-text',
                },
                {
                    name: 'assigned_to_name',
                    label: this.$t('common.accounting.account.assigned-to'),
                    align: 'left',
                    sortable: true,
                    field: row => row.debitAccount ? row.debitAccount.assignedTo.getContactName() : '–',
                },
                {
                    name: 'balance',
                    label: this.$tc('common.accounting.account.balance', 1),
                    align: 'right',
                    sortable: true,
                    required: true,
                },
                {
                    name: 'swap',
                    label: this.$t('views.accounting.transfer-posting.swap-accounts'),
                    align: 'center',
                    classes: 'swap-col border-left border-right',
                    required: true,
                },
                {
                    name: 'credit_account',
                    label: this.$tc('common.accounting.account.type.credit-account', 1),
                    align: 'left',
                    required: true,
                },
                {
                    name: 'credit_account_assigned_as_type',
                    label: this.$t('common.accounting.account.assigned-as'),
                    align: 'left',
                    field: row => row.creditAccount ? i18n.t(`common.accounting.account.type.${kebabCase(row.creditAccount.assigned_as_type.replace('CONSULTING_SETTINGS_', ''))}`) : '–',
                    hideInitially: true,
                    classes: 'truncate-text',
                },
                {
                    name: 'credit_account_holder',
                    label: this.$tc('common.accounting.account.assigned-to', 1),
                    align: 'left',
                    field: row => row.creditAccount ? row.creditAccount.assignedTo.getContactName() : '–',
                },
                {
                    name: 'credit_account_balance',
                    label: this.$tc('common.accounting.account.balance', 1),
                    align: 'right',
                    required: true,
                },
                {
                    name: 'posting_date',
                    label: this.$tc('common.accounting.posting.date', 1),
                    align: 'left',
                    required: true,
                    classes: 'border-left',
                    headerClasses: 'border-left',
                    style: 'min-width: 15ch',
                },
                {
                    name: 'posting_balance',
                    label: this.$tc('common.accounting.posting.amount', 1),
                    align: 'left',
                    required: true,
                    style: 'min-width: 18ch',
                },
                {
                    name: 'posting_text',
                    label: this.$tc('common.accounting.posting.text', 1),
                    align: 'left',
                    required: true,
                    style: 'min-width: 20ch',
                },
            ],
        }
    },
    computed: {
        additionalFilters () {
            return this.accountSearchFilters
        },
        disableCreateButton () {
            if (this.isPosting) return true
            if (this.$refs.postingConfigurationForm) {
                return !(!this.$v.$invalid && !this.$refs.postingConfigurationForm.$v.$invalid)
            }
            return this.$v.$invalid
        },
    },
    mounted () {
        this.$watch(() => this.$refs.postingConfigurationForm.formData.postingTypeRounding, () => {
            this.updateAllPostingsBalance()
        })
    },
    methods: {
        isValidIsoDate,
        formatCurrency,
        onFilterSubmit (formData) {
            const consultingSettingsStatus = formData.assignedToConsultingSettingsStatus
                ? [ConsultingSettingsStatus.ACTIVE, ConsultingSettingsStatus.INACTIVE]
                : [ConsultingSettingsStatus.ACTIVE]

            this.accountSearchFilters = {
                filterIsAssigned: true,
                filterAssignedToType: formData.accountAssignedToType,
                filterAssignedAsType: formData.accountAssignedAsType,
                filterAssignedToConsultingSettingsStatus: consultingSettingsStatus,
                balancePostingDateUntil: formData.balancePerDate,
            }

            this.resetSelectedItems()
        },
        onCreateSubmit () {
            this.isPosting = true
            const inputs = this.selectedItems.map(item => {
                return {
                    type_id: this.$refs.postingConfigurationForm.formData.postingType,
                    date: item.postingDate,
                    amount: parseFloat(item.postingAmount),
                    text: item.postingText,
                    debit_account_id: item.debitAccount.id,
                    credit_account_id: item.creditAccount.id,
                }
            })

            Posting.createBulk(inputs)
                .then(() => {
                    this.resetErrorMessage()

                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.accounting.posting-created-success', this.selectedItems.length),
                    })

                    this.resetSelectedItems()
                    this.resetSwappedItems()
                    this.$refs.table.fetchObjects()
                    this.isPosting = false
                })
                .catch(error => {
                    this.showErrorMessage(error)
                })
        },
        selectionRowCondition (row, mode = 'checkboxState') {
            if (row.itemAccount.assignedTo.is_tenant_company) return false // Tenant company is already at the top of the food chain…
            if (row.superOrdinateAccount === null) return false // Superordinate company does not have a `currentAccount` set.
            if (mode === 'checkboxState' && this.getSelectedItem(row.id)) return true
            return !row.validations.$invalid
        },
        updateAllPostingsBalance () {
            if (this.$refs?.table?.items) {
                this.$refs.table.items.forEach(item => {
                    if (item.formData.postingAmount) item.formData.postingAmount = item.getRoundedAmount(item.formData.postingAmount)
                })
            }
        },
        setTableItemObject (data) {
            const wrappedData = data.map(account => {
                let wrappedItem;

                const selectedItem = this.getSelectedItem(account.id)
                if (selectedItem) {
                    wrappedItem = selectedItem
                } else {
                    const wrappedItemArguments = {
                        id: account.id,
                        itemAccount: new AccountingTransferPostingAccount(account),
                        postingDate: '',
                        postingText: '',
                        postingAmount: '',
                        postingConfigurationForm: this.$refs.postingConfigurationForm,
                    }
                    if (account.assignedTo.consultingSettings?.company?.consultingSettings?.currentAccount) {
                        wrappedItemArguments.superOrdinateAccount = new AccountingTransferPostingAccount(Object.assign({
                            assignedTo: account.assignedTo.consultingSettings.company,
                            assigned_as_type: AccountAssignedAsType.CONSULTING_SETTINGS_CURRENT_ACCOUNT,
                        }, account.assignedTo.consultingSettings.company.consultingSettings.currentAccount))
                    }
                    wrappedItem = new AccountingTransferPostingItem(wrappedItemArguments)
                    if (account.balance > 0) wrappedItem.postingAmount.value = account.balance
                    if (this.isSwapped(wrappedItem)) wrappedItem.swap()
                }

                return wrappedItem
            })
            return wrappedData
        },
        getSelectedItem (id) {
            return this.selectedItems.find(entry => entry.id === id)
        },
        setSelectedItems () {
            const validSelectedItems = this.selectedItems.filter(row => {
                if (this.selectedItemIds.includes(row.id)) return true
                return this.selectionRowCondition(row, 'filter')
            })
            this.selectedItemIds = validSelectedItems.map(item => item.id)
            this.selectedItems.length = 0
            this.selectedItems.push(...validSelectedItems)
        },
        resetSelectedItems () {
            this.selectedItems.length = 0
            if (this.$refs.table) this.$refs.table.$refs.qTable.clearSelection()
        },
        showErrorMessage (error) {
            this.errorMessage = extractErrorMessage(error)
        },
        resetErrorMessage () {
            this.errorMessage = ''
        },
        toggleSwapItem (item) {
            if (this.isSwapped(item)) {
                this.swappedItemIds = this.swappedItemIds.filter(id => id !== item.id)
            } else {
                this.swappedItemIds.push(item.id)
            }
            item.swap()
        },
        isSwapped (item) {
            return this.swappedItemIds.includes(item.id)
        },
        resetSwappedItems () {
            this.swappedItemIds.length = 0
        },
        fetchAccounts (variables) {
            return Account.objects.all(variables, `
                id
                type
                status
                number
                name
                balance(posting_date_from: $balancePostingDateFrom, posting_date_until: $balancePostingDateUntil)
                assignedTo {
                    ... on Person {
                        id
                        first_name
                        last_name
                        consultingSettings {
                            company {
                                id
                                company_name
                                consultingSettings {
                                    currentAccount {
                                        id
                                        type
                                        name
                                        number
                                    }
                                }
                            }
                        }
                    }
                    ... on Company {
                        id
                        company_name
                        is_tenant_company
                        consultingSettings {
                            company {
                                id
                                company_name
                                consultingSettings {
                                    currentAccount {
                                        id
                                        type
                                        name
                                        number
                                    }
                                }
                            }
                        }
                    }
                    ... on CommissionTypeAccountingConfiguration {
                        id
                    }
                }
                assigned_as_type
                created_at
                updated_at
            `, `
                $balancePostingDateFrom: Date
                $balancePostingDateUntil: Date
            `)
        },
    },
    validations () {
        const validateRowFormData = (row) => !row.validations.$invalid

        return {
            selectedItems: {
                required,
                minLength: minLength(1),
                $each: {
                    validateRowFormData,
                },
            },
        }
    },
}
</script>

<style lang="scss" scoped>
.posting-balance-change {
    font-size: small;
}

::v-deep {
    .truncate-text {
        max-width: 25ch;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .swap-col {
        width: 5ch;
        max-width: 5ch;
    }
}
</style>
