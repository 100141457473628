import { defaultValues } from '@/libs/core/helpers'
import { computed, ref, unref } from 'vue'

// TODO: Write unwrap Proxy? Use `construct` trap?
export class Wizard {
    #currentStepIndex
    #currentStep

    constructor (steps, options) {
        this.steps = steps
        this.options = defaultValues(options, {})

        this.#currentStepIndex = ref(0)
        this.#currentStep = computed(() => {
            return this.#currentStepIndex.value + 1
        })
    }

    get currentStepIndex () {
        return this.#currentStepIndex.value
    }

    get currentStepKey () {
        return this.getCurrentStep().key
    }

    get currentStep () {
        return this.#currentStep.value
    }

    getCurrentStep () {
        return this.steps[this.currentStepIndex]
    }

    setStep (key) {
        const stepIndex = this.steps.findIndex(step => step.key === key)
        if (stepIndex > -1) this.#currentStepIndex.value = stepIndex
    }

    previousStep () {
        if (this.currentStepIndex > 0) this.#currentStepIndex.value--
    }

    nextStep () {
        if (this.currentStepIndex < this.steps.length - 1) this.#currentStepIndex.value++
    }

    reset () {
        this.#currentStepIndex.value = 0
    }
}

export class WizardStep {
    #configuration

    constructor (key, configuration) {
        this.key = key
        this.#configuration = configuration
        this.configuration = new Proxy(this.#configuration, {
            get (target, prop) {
                const originalValue = Reflect.get(...arguments)
                return unref(originalValue) // TODO @TFU: Check if this fails, if originalValue is `undefined`.
            },
        })
    }
}
