<script>
import kebabCase from 'lodash.kebabcase'
import { FormBuilderConfiguration } from '@/form-builder-configuration'
import FormComponent from '@/libs/form/components/FormComponent'
import FormFieldComponent from '@/libs/form/components/FormFieldComponent'
import FormSectionComponent from '@/libs/form/components/FormSectionComponent'
import FormRepeaterComponent from '@/libs/form/components/FormRepeaterComponent'
import FormRepeaterItemComponent from '@/libs/form/components/FormRepeaterItemComponent'

export default {
    name: 'FormBuilder',
    functional: true,
    inheritAttrs: false,
    props: {
        form: {
            type: [Function, Object],
            default: null,
        },
        item: {
            type: Object,
            default: null,
        },
        autoLayout: {
            type: Boolean,
            default: false,
        },
        manualLayout: {
            type: Boolean,
            default: false,
        },
    },
    render (h, context) {
        const { props, data, listeners } = context
        let output = h('div', `DEBUG: Item not supported yet.`)

        const children = []
        const options = { class: [] }

        // Layout CSS classes
        if (props.manualLayout) {
            options.class.push('form--manual-layout')
        } else {
            options.class.push(props.autoLayout ? 'form--auto-layout' : 'form--default-layout')
        }

        if (props.form) {
            output = h(FormComponent, Object.assign(options, {
                props: Object.assign({
                    form: props.form,
                    autoLayout: props.autoLayout,
                    manualLayout: props.manualLayout,
                }, data.attrs),
                scopedSlots: context.scopedSlots,
                on: listeners,
                ref: 'formComponent',
            }))
        } else if (props.item) {
            // Generate FormBuilder components for subitems.
            if (props.item.items?.length) {
                children.push(...props.item.items.map(item => {
                    return h('form-builder-next', {
                        props: {
                            item: item,
                            autoLayout: props.autoLayout,
                            manualLayout: props.manualLayout,
                        },
                        key: item.key,
                    })
                }))
            }

            switch (props.item.__type) {
                case 'field': {
                    const supportedInputTypes = Object.keys(FormBuilderConfiguration.supportedInputTypes)
                    if (supportedInputTypes.includes(props.item.configuration.inputType)) {
                        Object.assign(options, {
                            attrs: Object.assign({
                                // TODO: Re-think if form field keys really also should get kebab-cased.
                                'data-test': `input:${kebabCase(props.item.key)}`,
                                label: props.item.configuration.label,
                            }, data.attrs, props.item.configuration.attributes), // TODO: Verify
                            props: Object.assign({}, props.item.configuration.props), // TODO: Verify
                            on: Object.assign({}, listeners),
                            scopedSlots: context.scopedSlots,
                        })
                        // console.log(props.item.key, options)

                        if (['text', 'password', 'textarea', 'number'].includes(props.item.configuration.inputType)) options.attrs.type = props.item.configuration.inputType

                        // TODO: Get rid of external structure. Make this completely generic. Everything needs to be transformed "outside" of this component.
                        // Use props.item.configuration.props and props.item.configuration.attributes instead.

                        // if (['consultants', 'roles'].includes(props.item.inputType)) options.attrs.multiple = true
                        if (props.item.configuration.inputType === 'select' && props.item.configuration?.extra_attributes?.list?.options) options.attrs.options = props.item.configuration.extra_attributes.list.options
                        // if (props.item.hint) options.attrs.hint = props.item.hint
                        // if (props.item.mask) options.attrs.mask = props.item.mask
                        // if (props.item.prefix) options.attrs.prefix = props.item.prefix
                        // if (props.item.infoTitle) options.attrs.infoTitle = props.item.infoTitle
                        // if (props.item.infoText) options.attrs.infoText = props.item.infoText
                        if (data.class) {
                            Array.isArray(data.class) ? options.class.push(...data.class) : options.class.push(data.class)
                        }
                        if (data.staticClass) options.staticClass = data.staticClass
                        // if (props.filter) options.props.filter = props.filter
                        if (props.item.configuration.extra_attributes) {
                            if (props.item.configuration.extra_attributes.disable) options.attrs.disable = true
                        //     if (props.item.extra_attributes.injectConsultants) options.attrs.injectConsultants = props.item.extra_attributes.injectConsultants
                        //     if (typeof props.item.extra_attributes.multiple !== 'undefined') options.attrs.multiple = props.item.extra_attributes.multiple
                        //     if (typeof props.item.extra_attributes.optionValue !== 'undefined') options.attrs.optionValue = props.item.extra_attributes.optionValue
                        //     if (typeof props.item.extra_attributes.displayMode !== 'undefined') options.attrs.displayMode = props.item.extra_attributes.displayMode
                        //     if (typeof props.item.extra_attributes.smartObjectTreeDisplayMode !== 'undefined') options.attrs.smartObjectTreeDisplayMode = props.item.extra_attributes.smartObjectTreeDisplayMode
                        }
                        //
                        // // TODO @TFU: Check if this should be possible for all other attributes (generic solution).
                        // if (['number'].includes(props.item.inputType) && props.item.extra_attributes) {
                        //     for (const [key,value] of Object.entries(props.item.extra_attributes)) {
                        //         options.attrs[key] = value
                        //     }
                        // }

                        // No v-model available in render functions – handle manually
                        options.props.value = props.item.value
                        options.on.input = value => props.item.value = value

                        // Error handling
                        if (typeof props.item.configuration.validators !== 'undefined' && props.item.configuration.validators.length > 0) {
                            // Let q-components know if there is an error
                            options.attrs.error = props.item.validations.$error

                            // Error messages
                            const errorMessages = []
                            props.item.configuration.validators.forEach(validator => {
                                if (['required', 'requiredProperty'].includes(validator.type) || validator.requiredIfCondition?.() || (typeof validator.requiredUnlessCondition !== 'undefined' && !validator.requiredUnlessCondition?.())) options.class.push('required')
                                if (props.item.validations[validator.type].$invalid) {
                                    let errorMessageText = '' // TODO: Define default error message.
                                    if (validator.errorMessage) {
                                        errorMessageText = validator.errorMessage
                                    } else if (FormBuilderConfiguration.errorMessages[validator.type]) {
                                        errorMessageText = FormBuilderConfiguration.errorMessages[validator.type](props.item, validator)
                                    }
                                    errorMessages.push(h('div', {
                                        attrs: {
                                            // TODO: Re-think if form field keys really also should get kebab-cased.
                                            'data-test': `error:${kebabCase(props.item.configuration.key)}-${kebabCase(validator.type)}`,
                                        },
                                        key: validator.type,
                                        class: 'error-message',
                                    }, errorMessageText))
                                }
                            })

                            children.push(h('template', { slot: 'error' }, errorMessages))
                        }

                        output = h(FormFieldComponent, {
                            props: {
                                item: props.item,
                            },
                        }, [h(FormBuilderConfiguration.supportedInputTypes[props.item.configuration.inputType].component, options, children)])
                    }
                    break
                }

                case 'section': {
                    output = h(FormSectionComponent, Object.assign(options, {
                        props: {
                            item: props.item,
                        },
                        scopedSlots: context.scopedSlots,
                    }), children)
                    break
                }

                case 'repeater': {
                    // TODO @TFU: This is a POC. Make generic if we use this.
                    const children = []
                    if (props.item.items?.length) {
                        children.push(...props.item.items.map(item => {
                            const options = {
                                props: {
                                    item,
                                    autoLayout: props.autoLayout,
                                    manualLayout: props.manualLayout,
                                },
                                key: item.key,
                                scopedSlots: {},
                            }
                            if (context.scopedSlots['repeater-item']) options.scopedSlots.default = context.scopedSlots['repeater-item']
                            if (context.scopedSlots['repeater-item-buttons']) options.scopedSlots.buttons = context.scopedSlots['repeater-item-buttons']
                            return h('form-builder-next', options)
                        }))
                    }
                    delete context.scopedSlots['repeater-item']
                    delete context.scopedSlots['repeater-item-buttons']

                    if (data.class) options.class = data.class
                    if (data.staticClass) options.staticClass = data.staticClass

                    output = h(FormRepeaterComponent, Object.assign(options, {
                        props: {
                            item: props.item,
                        },
                        scopedSlots: context.scopedSlots,
                        // TODO: Add keys everywhere?
                    }), children)
                    break
                }

                case 'repeaterItem': {
                    if (data.class) options.class = data.class
                    if (data.staticClass) options.staticClass = data.staticClass

                    output = h(FormRepeaterItemComponent, Object.assign(options, {
                        props: {
                            item: props.item,
                        },
                        scopedSlots: context.scopedSlots,
                    }), children)
                    break
                }

                case 'form': {
                    output = h('div', children)
                    break
                }
            }
        }

        return output
    },
}
</script>
