import gql from 'graphql-tag'

export const COMMISSION_TYPE_ACCOUNTING_CONFIGURATION_UPDATE_MUTATION = gql`
    mutation commissionTypeAccountingConfigurationUpdate(
        $id: ID!
        $postingTextPart: String!
        $commissionExpenseAccountId: ID!
        $commissionIncomeAccountId: ID!
    ) {
        commissionTypeAccountingConfigurationUpdate(
            id: $id
            posting_text_part: $postingTextPart
            commission_expense_account_id: $commissionExpenseAccountId
            commission_income_account_id: $commissionIncomeAccountId
        ) {
            id
            commission_type
            postingType {
                id
                key
                name
                name_language_versions
                created_at
                updated_at
            }
            posting_text_part
            commissionExpenseAccount {
                id
                type
                status
                number
                name
            }
            commissionIncomeAccount {
                id
                type
                status
                number
                name
            }
            created_at
            updated_at
        }
    }
`
