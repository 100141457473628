import { ActivityLogParser } from '@/parsers/activityLog'
import { i18n } from '@/i18n'

export class StatementActivityLogParser extends ActivityLogParser {
    static getLogDescriptionCancelCreation (activityLog, data) {
        return i18n.tc('common.change-log.someone-cancelled-something', 1, { 'someone': data.causer, 'something': `<b>${ i18n.tc('common.change-log.action.creation', 1) }</b>` })
    }

    static getLogDescriptionShare (activityLog, data) {
        return i18n.tc('common.change-log.someone-shared-something', 1, { 'someone': data.causer, 'something': `<b>${ i18n.tc('common.change-log.subject.this.statement', 1) }</b>` })
    }
}
