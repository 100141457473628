export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'amountFrom',
            label: 'forms.field.label.commission-distribution-rule-range.amount-from',
            inputType: 'number',
            extra_attributes: {
                step: '0.01',
                readonly: true,
            },
        },
        {
            type: 'field',
            key: 'amountTo',
            label: 'forms.field.label.commission-distribution-rule-range.amount-to',
            inputType: 'number',
            infoText: 'views.commission.commission-distribution.last-row-amount-to-info',
            validators: [
                { type: 'dirtyChecker' },
            ],
            extra_attributes: {
                step: '0.01',
            },
        },
        {
            type: 'field',
            key: 'payoutRatio',
            label: 'forms.field.label.commission-distribution-rule-range.payout-ratio',
            inputType: 'number',
            validators: [
                {
                    type: 'required',
                },
            ],
            extra_attributes: {
                step: '0.01',
                max: '100',
                min: '0',
            },
        },
        {
            type: 'field',
            key: 'cancellationReserve',
            label: 'forms.field.label.commission-distribution-rule-range.cancellation-reserve',
            inputType: 'number',
            validators: [
                {
                    type: 'required',
                },
            ],
            extra_attributes: {
                step: '0.01',
                max: '100',
                min: '0',
            },
        },
    ],
}
