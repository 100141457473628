import { apolloClient } from '@/apollo/client'

import { MAIL_PROCESSING_TEMPLATE_QUERY } from '@/graphql/mailProcessingTemplate/mailProcessingTemplate'
import { MAIL_PROCESSING_TEMPLATES_QUERY } from '@/graphql/mailProcessingTemplate/mailProcessingTemplates'
import { MAIL_PROCESSING_TEMPLATE_CREATE_MUTATION } from '@/graphql/mailProcessingTemplate/mailProcessingTemplateCreate'
import { MAIL_PROCESSING_TEMPLATE_UPDATE_MUTATION } from '@/graphql/mailProcessingTemplate/mailProcessingTemplateUpdate'
import { MAIL_PROCESSING_TEMPLATE_DELETE_MUTATION } from '@/graphql/mailProcessingTemplate/mailProcessingTemplateDelete'

export const MailProcessingTemplateService = {
    get (id) {
        return apolloClient.query({
            query: MAIL_PROCESSING_TEMPLATE_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.mailProcessingTemplate)
    },
    all (variables) {
        return apolloClient.query({
            query: MAIL_PROCESSING_TEMPLATES_QUERY,
            variables,
        }).then(response => response.data.mailProcessingTemplates)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: MAIL_PROCESSING_TEMPLATE_CREATE_MUTATION,
            variables,
        }).then(response => response.data.mailProcessingTemplateCreate)
    },
    update (id, mailProcessingTemplateVariables) {
        return apolloClient.mutate({
            mutation: MAIL_PROCESSING_TEMPLATE_UPDATE_MUTATION,
            variables: {
                id: id,
                mailProcessingTemplate: mailProcessingTemplateVariables,
            },
        }).then(response => response.data.mailProcessingTemplateUpdate)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: MAIL_PROCESSING_TEMPLATE_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.mailProcessingTemplateDelete)
    },
}
