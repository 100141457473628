import { Model } from '@/models'
import { CommissionTypeAccountingConfigurationService } from '@/services'

export class CommissionTypeAccountingConfiguration extends Model {
    /**
     * Create a CommissionTypeAccountingConfiguration model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'commission_type',
            'postingType',
            'posting_text_part',
            'commissionExpenseAccount',
            'commissionIncomeAccount',
        ], data)
    }

    static get service () { return CommissionTypeAccountingConfigurationService }

    get igb2bCommissionTypeCode () {
        return this.commission_type.replace('TYPE_', '')
    }

    /**
     * Update this commissionTypeAccountingConfiguration.
     * @param {string} postingTextPart
     * @param {ID} commissionExpenseAccountId
     * @param {ID} commissionIncomeAccountId
     * @returns {Promise<*>}
     */
    update ({ postingTextPart, commissionExpenseAccountId, commissionIncomeAccountId }) {
        const variables = { postingTextPart, commissionExpenseAccountId, commissionIncomeAccountId }

        return CommissionTypeAccountingConfigurationService.update(this.id, variables).then(commissionTypeAccountingConfiguration => {
            Object.assign(this, commissionTypeAccountingConfiguration)
            return commissionTypeAccountingConfiguration
        })
    }
}
