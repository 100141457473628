import gql from 'graphql-tag'

export const CORRESPONDENCE_EMAIL_DELETE_MUTATION = gql`
    mutation correspondenceEmailDelete(
        $id: ID!
    ) {
        correspondenceEmailDelete(
            id: $id
        ) {
            status
        }
    }
`
