import { i18n } from '@/i18n'
import { ActivityLogParser } from '@/parsers/activityLog'

export class CommissionRateAdjustmentRuleActivityLogParser extends ActivityLogParser {
    static attributeDataTypes = {
        'commission_rate': 'percentage',
        'adjusted_commission_rate': 'percentage',
    }

    static getLogDescriptionCreated (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `<b>${data.subject}</b>`,
            // TODO @MTR: Adjust message when a soltion to always log the sector code has been implemented (API/backend; fixes `undefined` issue).
            toSomething: i18n.tc('common.change-log.subject.for-this.sector-code', 1, { sectorCode: `<b>${activityLog.properties.attributes['igb2bSectorCode.code'] || activityLog.properties.attributes.custom_sector_code}</b>` }),
        }
        return i18n.t(`common.change-log.someone-added-something-to-something`, interpolationParams)
    }

    static getLogDescriptionUpdated (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `<b>${i18n.tc('common.change-log.subject.this.commission-rate-adjustment-rule', 1)}</b>`,
            // TODO @MTR: Adjust message when a soltion to always log the sector code has been implemented (API/backend; fixes `undefined` issue).
            ofSomething: i18n.tc('common.change-log.subject.for-this.sector-code', 1, { sectorCode: `<b>${activityLog.properties.attributes['igb2bSectorCode.code'] || activityLog.properties.attributes.custom_sector_code}</b>` }),
        }
        return i18n.t(`common.change-log.someone-updated-something-of-something`, interpolationParams)
    }

    static getLogDescriptionDeleted (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `<b>${i18n.tc('common.change-log.subject.this.commission-rate-adjustment-rule', 1)}</b>`,
            // TODO @MTR: Adjust message when a soltion to always log the sector code has been implemented (API/backend; fixes `undefined` issue).
            ofSomething: i18n.tc('common.change-log.subject.for-this.sector-code', 1, { sectorCode: `<b>${activityLog.properties.attributes['igb2bSectorCode.code'] || activityLog.properties.attributes.custom_sector_code}</b>` }),
        }
        return i18n.t(`common.change-log.someone-deleted-something-of-something`, interpolationParams)
    }

    static getLogDetailsCreated (activityLog, data) {
        return [
            {
                key: 'commission_rate',
                label: i18n.tc('forms.field.label.commission-rate-adjustment-rule.commission-rate', 1),
                newValue: this.getFormattedLogDetailValue(activityLog, 'commission_rate', this.attributeDataTypes['commission_rate'], activityLog.properties.attributes.commission_rate),
            },
            {
                key: 'adjusted_commission_rate',
                label: i18n.tc('forms.field.label.commission-rate-adjustment-rule.adjusted-commission-rate', 1),
                newValue: this.getFormattedLogDetailValue(activityLog, 'adjusted_commission_rate', this.attributeDataTypes['adjusted_commission_rate'], activityLog.properties.attributes.adjusted_commission_rate),
            },
        ]
    }
}
