import gql from 'graphql-tag'

export const DASHBOARD_QUERY = gql`
    query dashboard(
        $page: Int = 1
        $count: Int = 10
        $orderField: TaskOrderField = DUE_DATE
        $orderDirection: OrderDirection = ASC
        $filterUserId: ID
        $statusDone: TaskStatus = DONE
        $statusInProgress: TaskStatus = IN_PROGRESS
        $statusTodo: TaskStatus = TODO
        $filterTasksStatisticsDueDateStatusDueTodayFrom: DateTimeTz
        $filterTasksStatisticsDueDateStatusDueTodayUntil: DateTimeTz
        $filterTasksStatisticsDueDateStatusOverdueUntil: DateTimeTz
        $filterTasksStatisticsDueDateStatusUpcomingFrom: DateTimeTz
        $filterTasksStatisticsDueDateStatusUpcomingUntil: DateTimeTz
        $filterTasksStatisticsDueDateStatusFutureFrom: DateTimeTz
    ) {
        tasksAssigned: tasks(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                assignee_id: $filterUserId
                status: [$statusInProgress, $statusTodo]
            }
        ) {
            paginatorInfo {
                total
                count
            }
            data {
                id
                status
                creator {
                    id
                    first_name
                    last_name
                    gender
                    consultingCompany {
                        id
                    }
                    consulting_company_name
                }
                assignee {
                    id
                    first_name
                    last_name
                    gender
                    consultingCompany {
                        id
                    }
                    consulting_company_name
                }
                visibility
                subject
                attachments {
                    id
                }
                due_date
                created_at
                updated_at
                done_at
            }
        }
        tasksCreated: tasks(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                creator_id: $filterUserId
                status: [$statusInProgress, $statusTodo]
            }
        ) {
            paginatorInfo {
                total
                count
            }
            data {
                id
                status
                creator {
                    id
                    first_name
                    last_name
                    gender
                    consultingCompany {
                        id
                    }
                    consulting_company_name
                }
                assignee {
                    id
                    first_name
                    last_name
                    gender
                    consultingCompany {
                        id
                    }
                    consulting_company_name
                }
                visibility
                subject
                attachments {
                    id
                }
                due_date
                created_at
                updated_at
                done_at
            }
        }
        tasksStatisticsStatusTodo: tasks(
            page: $page
            count: 1
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                status: [$statusTodo]
            }
        ) {
            paginatorInfo {
                total
            }
        }
        tasksStatisticsStatusInProgress: tasks(
            page: $page
            count: 1
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                status: [$statusInProgress]
            }
        ) {
            paginatorInfo {
                total
            }
        }
        tasksStatisticsStatusDone: tasks(
            page: $page
            count: 1
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                status: [$statusDone]
            }
        ) {
            paginatorInfo {
                total
            }
        }
        tasksStatisticsDueDateStatusDueToday: tasks(
            page: $page
            count: 1
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                due_date_from: $filterTasksStatisticsDueDateStatusDueTodayFrom
                due_date_until: $filterTasksStatisticsDueDateStatusDueTodayUntil
                status: [$statusInProgress, $statusTodo]
            }
        ) {
            paginatorInfo {
                total
            }
        }
        tasksStatisticsDueDateStatusOverdue: tasks(
            page: $page
            count: 1
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                due_date_until: $filterTasksStatisticsDueDateStatusOverdueUntil
                status: [$statusInProgress, $statusTodo]
            }
        ) {
            paginatorInfo {
                total
            }
        }
        tasksStatisticsDueDateStatusUpcoming: tasks(
            page: $page
            count: 1
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                due_date_from: $filterTasksStatisticsDueDateStatusUpcomingFrom
                due_date_until: $filterTasksStatisticsDueDateStatusUpcomingUntil
                status: [$statusInProgress, $statusTodo]
            }
        ) {
            paginatorInfo {
                total
            }
        }
        tasksStatisticsDueDateStatusFuture: tasks(
            page: $page
            count: 1
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                due_date_from: $filterTasksStatisticsDueDateStatusFutureFrom
                status: [$statusInProgress, $statusTodo]
            }
        ) {
            paginatorInfo {
                total
            }
        }
    }
`
