import { Model } from '@/models'
import { PostingService } from '@/services'
import { PostingActions } from '@/enums'
import { formatDate } from '@/helpers/date'
import { formatCurrency } from '@/helpers/number'

export class Posting extends Model {
    /**
     * Create a Posting model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'type',
            'group',
            'date',
            'number',
            'text',
            'debitAccount',
            'creditAccount',
            'amount',
            'attachments',
            'is_confidential',
        ], data)
    }

    static get service () { return PostingService }
    static action = PostingActions

    get formattedDate () {
        return formatDate(this.date)
    }

    get formattedAmount () {
        return formatCurrency(this.amount)
    }

    get actions () {
        const actions = super.actions
        actions.push({
            key: PostingActions.DELETE,
            item: this,
        })
        return actions
    }

    /**
     * Create a new posting.
     * @param {PostingInput} postingInput - The values used to create the posting.
     * @returns {Promise<*>}
     */
    static create (postingInput) {
        const variables = {
            posting: {
                type_id: postingInput.typeId,
                date: postingInput.date,
                text: postingInput.text,
                debit_account_id: postingInput.debitAccountId,
                credit_account_id: postingInput.creditAccountId,
                amount: parseFloat(postingInput.amount),
            },
        }

        return PostingService.create(variables)
    }

    /**
     * Create a bulk of new posting.
     * @param {Array} postingInputs - An array of postingInput
     * @returns {Promise<*>}
     */
    static createBulk (postingInputs) {
        return PostingService.createBulk({ postings: postingInputs })
    }

    /**
     * Update this posting.
     * @param {PostingInput} postingInput - The values used to create the posting.
     * @returns {Promise<*>}
     */
    update (postingInput) {
        const variables = {
            type_id: postingInput.typeId,
            date: postingInput.date,
            text: postingInput.text,
            debit_account_id: postingInput.debitAccountId,
            credit_account_id: postingInput.creditAccountId,
            amount: parseFloat(postingInput.amount),
        }

        return PostingService.update(this.id, variables).then(posting => {
            Object.assign(this, posting)
            return posting
        })
    }

    /**
     * Delete this posting.
     * @returns {Promise<*>}
     */
    delete () {
        return PostingService.delete(this.id)
    }
}
