import { apolloClient } from '@/apollo/client'

import { APPLICATION_QUERY } from '@/graphql/application/application'
import { APPLICATION_CREATE_MUTATION } from '@/graphql/application/applicationCreate'
import { APPLICATION_UPDATE_MUTATION } from '@/graphql/application/applicationUpdate'
import { APPLICATION_PRODUCT_TEMPLATE_VERSION_UPDATE_MUTATION } from '@/graphql/application/applicationProductTemplateVersionUpdate'
import { APPLICATION_CHANGE_STATUS_MUTATION } from '@/graphql/application/applicationChangeStatus'
import { APPLICATION_DELETE_MUTATION } from '@/graphql/application/applicationDelete'

export const ApplicationService = {
    get (id) {
        return apolloClient.query({
            query: APPLICATION_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.application)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: APPLICATION_CREATE_MUTATION,
            variables,
        }).then(response => response.data.applicationCreate)
    },
    update (variables) {
        return apolloClient.mutate({
            mutation: APPLICATION_UPDATE_MUTATION,
            variables,
        }).then(response => response.data.applicationUpdate)
    },
    updateToProductTemplateVersion (id, productTemplateVersion) {
        return apolloClient.mutate({
            mutation: APPLICATION_PRODUCT_TEMPLATE_VERSION_UPDATE_MUTATION,
            variables: {
                id: id,
                productTemplateVersion: productTemplateVersion,
            },
        }).then(response => response.data.applicationProductTemplateVersionUpdate)
    },
    changeStatus (variables) {
        return apolloClient.mutate({
            mutation: APPLICATION_CHANGE_STATUS_MUTATION,
            variables,
        }).then(response => response.data.applicationChangeStatus)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: APPLICATION_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.applicationDelete)
    },
}
