import { apolloClient } from '@/apollo/client'

import { FILES_PACKAGE_QUERY } from '@/graphql/temporaryDownloadFile/filesPackage'

export const TemporaryDownloadFileService = {
    filesPackage ({ file_ids, file_name_prefix }) {
        return apolloClient.mutate({
            mutation: FILES_PACKAGE_QUERY,
            variables: {
                file_ids: file_ids,
                file_name_prefix,
            },
        }).then(response => response.data.filesPackage)
    },
}
