import gql from 'graphql-tag'

export const STATEMENTS_CREATE_MUTATION = gql`
    mutation statementsCreate(
        $consulting_entity_ids: [ID!]!
        $statement: StatementInput!
    ) {
        statementsCreate(
            consulting_entity_ids: $consulting_entity_ids
            statement: $statement
        ) {
            id
            consultingEntity {
                ... on Consultant {
                    id
                    contact_number
                    first_name
                    last_name
                    consulting_company_name
                }
                ... on ConsultingCompany {
                    id
                    contact_number
                    company_name
                }
            }
            date_from
            date_until
            file_types
            include_attachments
            trigger_file_storage_item_sharing
            file_storage_item_shared_at
            currentQueueItem {
                id
                status
                status_message
            }
            queueItems {
                id
                status
                status_message
            }
            fileStorageItem {
                id
                type
                files {
                    id
                    name
                    size
                    mime_type
                }
            }
            created_at
            updated_at
        }
    }
`
