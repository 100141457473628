import gettingStartedChapter from '#/user-manual/getting-started'
import contactsChapter from '#/user-manual/contacts'
// import contractsChapter from '#/user-manual/contracts'
// import documentsChapter from '#/user-manual/documents'
import tasksChapter from '#/user-manual/tasks'
import correspondenceChapter from '#/user-manual/correspondence'
// import editorChapter from '#/user-manual/editor'
// import productsChapter from '#/user-manual/products'
// import usersChapter from '#/user-manual/users'

export default {
    chapters: [
        gettingStartedChapter,
        contactsChapter,
        // contractsChapter,
        // documentsChapter,
        tasksChapter,
        correspondenceChapter,
        // productsChapter,
        // editorChapter,
    ],
}
