<template>
    <q-option-group
        :value="value"
        :options="options"
        :type="type"
        v-bind="$attrs"
        @input="onInput"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" /><!-- Named slots -->
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template><!-- Scoped slots -->
    </q-option-group>
</template>

<script>
export default {
    name: 'BaseOptionGroup',
    inheritAttrs: false,
    props: {
        value: {
            type: [Boolean, Array],
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        type: {
            type: String,
            default: 'radio',
        },
    },
    setup (props, context) {
        // Functions
        function onInput (value) {
            context.emit('input', value)
        }

        return {
            // Functions
            onInput,
        }
    },
}
</script>
