<template>
    <q-tab-panels
        v-model="activeStep"
        animated
        transition-prev="fade"
        transition-next="fade"
    >
        <q-tab-panel name="download" class="auth-2fa-backup-code-download">
            <p>{{ $t('views.auth.setup.2fa-backup-code-text') }}</p>

            <div class="col-xs-12 q-mt-lg" :class="{ 'text-center': $q.screen.gt.md }">
                <q-btn
                    type="a"
                    unelevated
                    no-caps
                    color="primary"
                    icon="mib-cloud-download"
                    :label="$t('views.auth.setup.2fa-backup-code-download-link')"
                    data-test="btn:download"
                    @click="download"
                />
            </div>
        </q-tab-panel>

        <q-tab-panel name="form" class="auth-2fa-backup-code-verify">
            <p>{{ $t('views.auth.setup.2fa-backup-code-text-step-2') }}</p>

            <form data-test="form:auth-set-2fa-backup-code" @submit.prevent="onFormSubmit">
                <!-- eslint-disable vue/no-v-html -->
                <info-box
                    v-if="formErrorMessage"
                    type="negative"
                    data-test="error:global-message"
                    v-html="formErrorMessage"
                />
                <!-- eslint-enable -->

                <form-builder item-key="twoFABackupCode" autofocus />

                <q-stepper-navigation class="col-xs-12 q-gutter-x-sm" :class="{ 'text-right': $q.screen.gt.md }">
                    <base-button
                        data-test="btn:back"
                        outline
                        :label="$t('common.term.back')"
                        @click="activeStep = 'download'"
                    />
                    <base-button
                        data-test="btn:submit"
                        type="submit"
                        :label="$t('common.term.continue')"
                        :disable="formSubmitStatus"
                        :loading="formSubmitStatus"
                    />
                </q-stepper-navigation>
            </form>
        </q-tab-panel>
    </q-tab-panels>
</template>

<script>
import { saveAs } from 'file-saver'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'

import AUTH_CREDENTIALS_FIELDS from '@/forms/auth.fields.json'

export default {
    name: 'AuthSet2faBackupCode',
    mixins: [formBuilderMixin],
    props: {
        twoFABackupCode: {
            type: String,
            required: true,
        },
    },
    formBuilderSettings: {
        schema: AUTH_CREDENTIALS_FIELDS,
        fields: ['twoFABackupCode'],
    },
    data () {
        return {
            activeStep: 'download',
        }
    },
    computed: {
        formattedTwoFABackupCode () {
            const backupCodeChars = this.twoFABackupCode.split('')
            const backupCodeParts = []
            while (backupCodeChars.length) {
                backupCodeParts.push(backupCodeChars.splice(0, 4).join(''))
            }
            return backupCodeParts.join('-')
        },
    },
    methods: {
        download () {
            this.triggerDownload()
            this.activeStep = 'form'
        },
        triggerDownload () {
            const blob = new Blob([this.formattedTwoFABackupCode], { type: 'text/plain;charset=utf-8' })
            saveAs(blob, 'MAX-2FA-Backup-Code.txt')
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .q-tab-panel {
    padding: 0;
}
</style>
