import { Model } from '@/models'

export class DxpServiceProviderSettings extends Model {
    /**
     * Create a DxpServiceProviderSettings model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'enabledProcesses',
            'enabledForAutomaticProcessing',
        ], data)
    }

    isEnabled (dxpProcess) {
        return this.enabledProcesses.some(enabledProcess => enabledProcess.id === dxpProcess.id)
    }

    isEnabledForAutomaticProcessing (dxpProcess) {
        return this.enabledForAutomaticProcessing.some(enabledProcess => enabledProcess.id === dxpProcess.id)
    }
}
