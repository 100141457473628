import gql from 'graphql-tag'

export const MAIL_PROCESSING_GENERATE_COVER_LETTER_MUTATION = gql`
    mutation mailProcessingGenerateCoverLetter(
        $mailProcessingTemplateId: ID!
        $targetObjectId: ID!
        $consultantId: ID
        $printerId: ID!
    ) {
        mailProcessingGenerateCoverLetter(
            mail_processing_template_id: $mailProcessingTemplateId
            target_object_id: $targetObjectId
            consultant_id: $consultantId
            printer_id: $printerId
        ) {
            status
        }
    }
`
