<template>
    <page-wrapper>
        <page-header>
            <h1>{{ $tc('common.term.user', 2) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-12">
                <q-table
                    :data="users"
                    :columns="columns"
                    row-key="id"
                    :pagination.sync="pagination"
                    :loading="loading"
                    :rows-per-page-options="[10, 20, 50, 100]"
                    binary-state-sort
                    flat
                    square
                    dense
                    data-test="table:user-list"
                    @request="onRequest"
                    @row-dblclick="(evt, row) => $router.push({ name: 'admin-user-detail', params: { id: row.id } })"
                >
                    <template v-slot:top>
                        <q-tabs
                            dense
                            no-caps
                            inline-label
                            align="left"
                            class="q-mr-sm"
                            indicator-color="accent"
                        >
                            <q-route-tab :to="{ name: 'admin-user-list' }" :label="$t('common.status.active')" />
                            <q-route-tab :to="{ name: 'admin-user-list', params: { tab: 'invited' } }" :label="$t('common.status.invited')" />
                            <q-route-tab :to="{ name: 'admin-user-list', params: { tab: 'inactive' } }" :label="$t('common.status.inactive')" />
                        </q-tabs>

                        <q-space />

                        <filter-input v-model="filter" @clear-filter="clearFilter" />
                    </template>

                    <template v-slot:body-cell-user_email="props">
                        <q-td :props="props" data-test="td:user-email">
                            {{ props.row.email }}
                            <div v-if="props.row.hasPendingEmailChange" class="additional-info">
                                {{ props.row.new_email }}
                                <info-icon :text="`${$tc('views.admin.users.detail.change-email-address--pending-request--info', 1)}<br><br><del>${props.row.email}</del> → ${props.row.new_email}`" />
                            </div>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-user_roles="props">
                        <q-td :props="props" class="user-roles-col">
                            <!-- TODO: Get localized role names and descriptions -->
                            <q-badge
                                v-for="role in props.row.roles"
                                :key="'user-' + props.row.id + '-' + toKebabCase(role.name)"
                                color="secondary"
                                text-color="text-inverted"
                                align="middle"
                                class="user-role q-mr-xs"
                            >
                                {{ role.name }}
                                <q-tooltip
                                    anchor="top middle"
                                    self="bottom middle"
                                    :delay="250"
                                    max-width="20rem"
                                >
                                    <b>{{ role.name }}</b><br>{{ role.description }}
                                </q-tooltip>
                            </q-badge>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-user_status="props">
                        <q-td :props="props">
                            <status-badge :status="props.row.status" />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-actions="props">
                        <q-td :props="props" auto-width>
                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-view-1"
                                :to="{ name: 'admin-user-detail', params: { id: props.row.id } }"
                            >
                                <q-tooltip :delay="1000" :offset="[0, 10]">
                                    <template v-if="props.row.person">{{ $t('views.admin.users.show-details-of-user', { username: `${props.row.person.first_name} ${props.row.person.last_name}` }) }}</template>
                                    <template v-else>{{ $t('views.admin.users.show-details-of-user', { username: props.row.email }) }}</template>
                                </q-tooltip>
                            </q-btn>
                        </q-td>
                    </template>
                </q-table>
            </div>
        </div>

        <in-page-footer>
            <base-button
                :to="{ name: 'admin-user-create' }"
                :label="$tc('views.admin.users.add-user', 1)"
                icon="mib-single-neutral-actions-add"
                primary-button
                data-test="btn:create-user"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import { UserSettings } from '@/helpers/user'
import { User } from '@/models/user'
import { OrderDirection } from '@/enums/graphql'
import kebabCase from 'lodash.kebabcase'

import StatusBadge from '@/components/StatusBadge.vue'
import FilterInput from '@/components/FilterInput'

export default {
    name: 'UserList',
    components: {
        StatusBadge,
        FilterInput,
    },
    props: {
        tab: {
            type: String,
            default: 'active',
        },
    },
    meta () {
        return {
            title: this.$tc('common.term.user', 2),
        }
    },
    data () {
        const userSettings = new UserSettings(this.$store.state.user)
        return {
            userSettings,
            activeTab: this.tab,
            filter: '',
            loading: false,
            locale: localStorage.locale,
            pagination: {
                sortBy: 'user_email',
                descending: false,
                page: 1,
                rowsPerPage: parseInt(userSettings.getItem('UserList.pagination.rowsPerPage')) || 50,
                rowsNumber: 0,
            },
            columns: [
                {
                    name: 'user_email',
                    label: this.$tc('common.term.user'),
                    field: 'email',
                    sortable: true, align: 'left',
                    required: true,
                },
                {
                    name: 'person_first_name',
                    label: this.$tc('common.contact.first-name'),
                    field: row => (row.consultant) ? row.consultant.first_name : '',
                    sortable: true, align: 'left',
                    required: true,
                },
                {
                    name: 'person_last_name',
                    label: this.$tc('common.contact.last-name'),
                    field: row => (row.consultant) ? row.consultant.last_name : '',
                    sortable: true, align: 'left',
                    required: true,
                },
                {
                    name: 'company_name',
                    label: this.$tc('common.contact.consulting-company', 1),
                    field: row => (row.consultant?.consultingCompany) ? row.consultant.consultingCompany.company_name : '',
                    align: 'left',
                },
                {
                    name: 'user_roles',
                    label: this.$tc('common.term.role', 2),
                    align: 'left',
                    required: true,
                },
                {
                    name: 'user_status',
                    label: this.$tc('common.term.status'),
                    field: 'status',
                    sortable: true,
                    align: 'left',
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left', required: true },
            ],
            users: [],
        }
    },
    watch: {
        'filter' () {
            this.fetchUsers()
        },
        '$route' (to) {
            this.activeTab = to.params.tab || 'active'
            this.fetchUsers()
        },
        'pagination.rowsPerPage' (rowsPerPage) {
            this.userSettings.setItem('UserList.pagination.rowsPerPage', rowsPerPage)
        },
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
        this.fetchUsers()
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        toKebabCase: function (text) {
            return kebabCase(text)
        },
        clearFilter () {
            this.filter = ''
        },
        onRequest (props) {
            let { page, rowsPerPage, sortBy, descending } = props.pagination

            this.pagination.page = page
            this.pagination.rowsPerPage = rowsPerPage
            this.pagination.sortBy = sortBy
            this.pagination.descending = descending

            this.fetchUsers()
        },
        fetchUsers () {
            this.loading = true
            User.objects.all({
                page: this.pagination.page,
                count: this.pagination.rowsPerPage,
                orderField: this.pagination.sortBy.toUpperCase(),
                orderDirection: (this.pagination.descending) ? OrderDirection.DESC : OrderDirection.ASC,
                filterQuery: this.filter,
                filterStatus: this.activeTab.toUpperCase(),
            }).then(response => {
                this.pagination.rowsNumber = response.paginatorInfo.total
                this.loading = false
                this.users = response.data
            })
        },
        onNewEntity () {
            this.$router.push({ name: 'admin-user-create' })
        },
    },
}
</script>
<style lang="scss" scoped>
.user-roles-col {
    max-width: 20vw;
    white-space: pre-wrap;
}
.user-role {
    &:hover {
        cursor: help;
    }
}
</style>
