import { Model } from '@/models'
import { isPlainObject } from '@/helpers'
import { EditorContentSchemaType } from '@max/tiptap-extensions'

export class EditorContent extends Model {
    /**
     * Create an EditorContent model wrapper.
     * @param {Object} data - Object holding the field values.
     * @param {number} data.schemaType – The schema type that is used for this content (relevant for loading the correct editor schema).
     * @param {number} data.schemaVersion – The schema version number, currently all new contents are generated with structure version 1.
     * @param {Object} data.contentJSON – Holds the editors content serialized as JSON. Used to re-initialize the editor for editing.
     * @param {string} data.contentHTML – Holds the editors content serialized as HTML. Used in the process to generate the final medium.
     * @param {Object} data.attrs – Object holding additional attributes related to the content.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'schemaType',
            'schemaVersion',
            'contentJSON',
            'contentHTML',
            'attrs',
        ], data)
    }

    static create (initialValues) {
        const schema = {
            schemaType: '',
            schemaVersion: 1,
            contentJSON: {}, // TODO @TFU: Check if this should be initialized with `null`.
            contentHTML: '',
            attrs: null,
        }

        if (isPlainObject(initialValues)) {
            if (typeof initialValues.schemaType === 'undefined') throw Error('The param `initialValues` must be provided with at least the property `schemaType`.')

            if (typeof initialValues.schemaType === 'string') schema.schemaType = initialValues.schemaType
            if (isPlainObject(initialValues.contentJSON)) {
                schema.contentJSON = initialValues.contentJSON // TODO @TFU: Check if we should create a copy with JSON.parse(JSON.stringify()) or pass the reference.
            } else {
                schema.contentJSON = EditorContent.getInitialContentforSchemaType(schema.schemaType)
            }
            if (typeof initialValues.contentHTML === 'string') schema.contentHTML = initialValues.contentHTML
            if (isPlainObject(initialValues.attrs)) schema.attrs = JSON.parse(JSON.stringify(initialValues.attrs)) // TODO @TFU: Check if we should create a copy with JSON.parse(JSON.stringify()) or pass the reference.
        } else {
            throw Error('The param `initialValues` must be provided with at least the property `schemaType`.')
        }

        return new EditorContent(schema)
    }

    static getInitialContentforSchemaType (schemaType) {
        const initialContents = {}

        initialContents[EditorContentSchemaType.LETTER] = {
            type: 'letter',
            content: [
                {
                    type: 'structureBlock_address',
                },
                {
                    type: 'structureBlock_subject',
                },
                {
                    type: 'structureBlock_content',
                    content: [
                        { type: 'paragraph' },
                    ],
                },
            ],
        }

        initialContents[EditorContentSchemaType.EMAIL] = {
            type: 'email',
            content: [
                {
                    type: 'structureBlock_subject',
                },
                {
                    type: 'structureBlock_content',
                    content: [
                        { type: 'paragraph' },
                    ],
                },
            ],
        }

        return initialContents[schemaType] ?? {}
    }

    getVariablesObject () {  // TODO @TFU: Introduce argument to optionally remove contentHTML property?
        const variables = {
            schemaType: this.schemaType,
            schemaVersion: this.schemaVersion,
            contentHTML: this.contentHTML,
            attrs: null,
        }

        variables.contentJSON = JSON.stringify(this.contentJSON)
        if (isPlainObject(this.attrs)) variables.attrs = JSON.stringify(this.attrs)

        return variables
    }
}
