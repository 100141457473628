import { apolloClient } from '@/apollo/client'

import { DASHBOARD_QUERY } from '@/graphql/dashboard'

export const DashboardService = {
    all(variables) {
        return apolloClient
            .query({
                query: DASHBOARD_QUERY,
                variables,
            })
            .then((response) => response.data)
    },
}
