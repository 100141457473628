<template>
    <div>
        <h2>{{ $tc('views.contact.settings.document-layout.create-document-layout', 1) }}</h2>
        <document-layout-form ref="documentLayoutForm" :owner-id="ownerId" @post-handle-form-data="onSubmit" />
    </div>
</template>

<script>
import DocumentLayoutForm from '@/components/documentLayout/DocumentLayoutForm'
import { Contact } from '@/models/contact'
import { DocumentLayout } from '@/models/documentLayout'

export default {
    name: 'DocumentLayoutCreate',
    components: {
        DocumentLayoutForm,
    },
    props: {
        ownerId: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            contact: null,
        }
    },
    created () {
        Contact.objects.get(this.ownerId).then(contact => {
            this.contact = contact
        })
    },
    methods: {
        onSubmit ({ name, pageBackground, marginTop, marginRight, marginBottom, marginLeft }) {
            const variables = {
                name: JSON.stringify(name),
                pageMargins: JSON.stringify({
                    'margin-top': `${marginTop}mm`,
                    'margin-right': `${marginRight}mm`,
                    'margin-bottom': `${marginBottom}mm`,
                    'margin-left': `${marginLeft}mm`,
                }),
            }
            if (typeof pageBackground !== 'undefined') variables.pageBackground = pageBackground

            DocumentLayout.create(this.ownerId, variables)
                .then(documentLayout => {
                    if (!this.contact.consultingSettings.defaultDocumentLayout) {
                        documentLayout.setDefault()
                            .then(() => {
                                this.$router.replace({ name: 'contact-settings-document-layout-list', params: { id: this.ownerId } })
                            })
                            .catch(error => {
                                this.$refs.documentLayoutForm.showFormErrorMessage(error)
                                this.$refs.documentLayoutForm.resetFormSubmitStatus()
                            })
                    } else {
                        this.$router.replace({ name: 'contact-settings-document-layout-list', params: { id: this.ownerId } })
                    }
                })
                .catch(error => {
                    this.$refs.documentLayoutForm.showFormErrorMessage(error)
                    this.$refs.documentLayoutForm.resetFormSubmitStatus()
                })
        },
    },
}
</script>
