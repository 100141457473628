<template>
    <div class="actions-wrapper">
        <template v-if="nextBestActions.length > 0">
            <span
                v-for="action in nextBestActions"
                :key="action.key"
            >
                <base-button
                    :label="$tc(getTranslationKey(action.key), 1)"
                    class="action-button next-action"
                    :class="{ 'q-ml-sm': nextBestActions.length > 1 }"
                    flat
                    text-color="primary"
                    no-text-decoration
                    :data-test="`btn:${toKebabCase(action.key)}`"
                    :disable="action.disable"
                    @click="$emit('click', action)"
                />
                <q-tooltip
                    v-if="action.infoText"
                    :delay="1000"
                    :offset="[0, 18]"
                    max-width="250px"
                >
                    {{ action.infoText }}
                </q-tooltip>
            </span>
        </template>

        <base-button
            v-if="settingsLink"
            :to="settingsLink"
            icon="mib-cog-double-3"
            class="q-ml-sm"
            flat
            round
            text-color="primary"
            size="sm"
            data-test="btn:action-settings"
        >
            <q-tooltip :delay="1000" :offset="[0, 10]">{{ settingsTooltip }}</q-tooltip>
        </base-button>

        <base-button
            v-if="furtherActions.length > 0"
            icon="mib-navigation-menu-horizontal"
            class="action-button further-actions-menu q-ml-sm"
            flat
            round
            text-color="primary"
            size="sm"
            data-test="btn:further-actions"
        >
            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $t('common.term.more') }}</q-tooltip>
            <q-menu anchor="bottom right" self="top right">
                <q-list dense>
                    <div v-for="(action, index) in furtherActions" :key="action.key">
                        <q-item
                            v-close-popup
                            clickable
                            :data-test="`link:${toKebabCase(action.key)}`"
                            :disable="action.disable"
                            @click="$emit('click', action)"
                        >
                            <q-item-section>{{ $tc(getTranslationKey(action.key), 1) }}</q-item-section>
                            <q-tooltip
                                v-if="action.infoText"
                                :delay="1000"
                                :offset="[10, 0]"
                                anchor="center left"
                                self="center right"
                            >
                                {{ action.infoText }}
                            </q-tooltip>
                        </q-item>
                        <q-separator v-if="index < furtherActions.length - 1" />
                    </div>
                </q-list>
            </q-menu>
        </base-button>
    </div>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { i18n } from '@/i18n'

export default {
    name: 'Actions',
    props: {
        actions: {
            type: Array,
            required: true,
        },
        numberOfNextBestActions: {
            type: Number,
            default: 1,
        },
        translationBaseKey: {
            type: String,
            default: 'common.actions',
        },
        translationMapping: {
            type: Object,
            default () {
                return {}
            },
        },
        settingsLink: {
            type: Object,
            default: () => null,
        },
        settingsTooltip: {
            type: String,
            default: i18n.tc('common.term.setting', 2), // TODO improvement: Check if correct language is shown after upgrading to new i18n version
        },
    },
    computed: {
        nextBestActions () {
            return this.actions.slice(0, this.numberOfNextBestActions)
        },
        furtherActions () {
            const furtherActions = Array.from(this.actions)
            furtherActions.splice(0, this.numberOfNextBestActions)
            return furtherActions
        },
    },
    methods: {
        toKebabCase: function (text) {
            return kebabCase(text)
        },
        getTranslationKey (key) {
            return this.translationMapping[key] || `${this.translationBaseKey}.${key.toLowerCase()}`
        },
    },
}
</script>

<style lang="scss" scoped>
.next-action {
    background-color: var(--color-background-secondary);
}
</style>
