import { Form, FormField, FormRepeaterItem } from '@/libs/form'
import { computed, watch } from 'vue'

export class EmailAddressForm extends Form {
    compose () {
        this.addField(new FormField('emailAddress', {
            label: 'common.email-address.email-address',
            inputType: 'text',
            validators: [
                { 'type': 'required' },
                { 'type': 'email' },
            ],
        }))

        this.addField(new FormField('category', {
            label: 'common.term.category',
            inputType: 'emailAddressCategory',
            validators: [
                { type: 'required' },
            ],
        }))

        this.addField(new FormField('label', {
            label: 'common.term.label',
            inputType: 'emailAddressLabel',
            props: {
                filter: computed(() => {
                    const filter = {}
                    if (this.getItem('category').value !== null) filter.filterCategoryIds = [this.getItem('category').value]
                    return filter
                }),
            },
            attributes: {
                disable: computed(() => {
                    return this.getItem('category').value === null
                }),
            },
        }))
        watch(() => this.getItem('category').value, (newValue, oldValue) => {
            if (oldValue !== null) this.getItem('label').resetValue()
        })

        this.addField(new FormField('isMain', {
            label: 'common.contact.main-email-address',
            inputType: 'checkbox',
            attributes: {
                disable: false,
            },
        }))
    }
}

export class EmailAddressRepeaterItem extends FormRepeaterItem {
    compose () {
        const form = new EmailAddressForm({ excludeItems: ['isMain'] })
        this.addItems(form.items)
    }
}
