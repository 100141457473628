<template>
    <base-select-filter
        v-if="options"

        :value="value"
        v-bind="$attrs"
        :options="options"

        option-value="id"
        option-label="company_name"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { ContactService } from '@/services'

export default {
    name: 'GeneralAgencySelect',
    components: {
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        productProviderId: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            options: null,
        }
    },
    created () {
        const variables = {
            filterProductProviderId: this.productProviderId,
        }

        ContactService.generalAgencies.all(variables).then(response => {
            this.options = response.data
            this.$emit('fetched-objects', this.options)
        })
    },
}
</script>
