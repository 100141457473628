import gql from 'graphql-tag'

export const LOGIN_VALIDATE_2FA_MUTATION = gql`
    mutation loginValidate2FA($token: String!, $two_fa_code: String!) {
        loginValidate2FA(
            token: $token,
            two_fa_code: $two_fa_code
        ) {
            user {
                id
                email
                new_email
                status
                consultant {
                    id
                    contact_number
                    first_name
                    last_name
                    gender
                    title
                    consultingCompany {
                        id
                        contact_number
                        company_name
                        consulting_settings_status
                    }
                    consulting_company_name
                    consulting_settings_status
                    created_at
                    updated_at
                }
                roles {
                    key
                    permissions {
                        key
                    }
                }
                tenantCompany {
                    id
                }
                tenantSettings {
                    license {
                        status
                        packages {
                            package_key
                            name
                            features {
                                id
                                feature_key
                                name
                                description
                            }
                        }
                    }
                    systemLanguages {
                        id
                        name
                    }
                    defaultSystemLanguage {
                        id
                        name
                    }
                    correspondenceLanguages {
                        id
                        name
                    }
                }
            },
            jwtToken {
                token,
                type,
                expires_in
            }
        }
    }
`
