<template>
    <page-wrapper>
        <!--
        TODO @TFU: Tasks for CommissionRateAdjustmentRules/RuleSets

        GENERAL:
        - [x] Make default q-input slots available in form-builder items
            - [x] Move custom sector code checkbox in rule form to before slot

        CREATE:
        - [x] Create new: Only show product providers in select which do not already have a rule set
        - Error if no rules have been added is not shown (there must be at least 1 rule) -> Mitigated by creating an initial form.

        UPDATE:
        - [x] Dirty check: if _any_ rule of the set has been edited or n new ones have been added
            - [x] Disable submit/save button if there is nothing to be saved (if !dirty)
            - [x] Only show according info-box when dirty
            - TODO improvement: only show hint if the user navigates away ("useful" dirty check that can/should easy to be used in several places)
        - [x] When updating an existing rule with a custom sector code, the checkbox is not checked initially
        - Only show igb2b sector codes that have not been added to a rule -> According to the agreed upon specification it is valid to have more than one rule per sector code. It must be for a different rate though.
        - [x] Save bug
            - Save button only works with newly added rules (data)
            - When a new rule is added and the rule set is save, all previous rules are deleted / the whole rule set overwritten with the newly added rules only
        -->

        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.commission.rate-adjustment.rate-adjustment', 2)" :to="{ name: 'commission-rate-adjustment-rule-set-list' }" />
            </template>

            <h1>
                {{ $tc('views.commission.rate-adjustment.automatic-adjustment-of-commission-rate', 2) }} <info-icon
                    size="0.6em"
                    :text="$t('views.commission.rate-adjustment.rate-adjustment--description')"
                    data-test="tooltip:commission-rate-adjustment-description"
                />
            </h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <base-table
                    :columns="columns"
                    :fetch-objects-fn="CommissionRateAdjustmentRuleSet.objects.all"
                    user-settings-base-path="CommissionRateAdjustmentRuleSetList"
                    pagination-sort-by-default-key="product_provider_name"
                    data-test="table:commission-rate-adjustment-rule-set-list"
                    @row-dblclick="onDblClick"
                >
                    <template v-slot:body-cell-actions="slotProps">
                        <q-td :props="slotProps" auto-width>
                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-pencil"
                                :to="{
                                    name: 'commission-rate-adjustment-rule-set-update',
                                    params: { id: slotProps.row.id } }"
                                data-test="link:tooltip-actions"
                            >
                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.rule.update-rule-set', 1) }}</q-tooltip>
                            </q-btn>
                        </q-td>
                    </template>
                </base-table>
            </div>
        </div>

        <in-page-footer>
            <base-button
                :to="{ name: 'commission-rate-adjustment-rule-set-create' }"
                :label="$tc('common.rule.add-rule-set', 1)"
                primary-button
                data-test="link:add-commission-rate-adjustment-rule-set"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import BaseTable from '@/components/BaseTable'
import { CommissionRateAdjustmentRuleSet } from '@/models/models'
import { EventBus } from '@/event-bus'

export default {
    name: 'CommissionRateAdjustmentRuleSetList',
    components: {
        BaseTable,
    },
    meta () {
        return {
            title: this.$tc('views.commission.rate-adjustment.rate-adjustment', 2),
        }
    },
    data () {
        return {
            CommissionRateAdjustmentRuleSet,
            columns: [
                {
                    name: 'product_provider_name',
                    label: this.$tc('common.product.product-provider', 1),
                    field: row => `${row.productProvider.name} (${row.productProvider.contact_number})`,
                    align: 'left',
                    sortable: true,
                    required: true,
                },
                {
                    name: 'rules_length',
                    label: this.$tc('common.rule.rule', 2),
                    field: row => row.rules.length,
                    align: 'left',
                },
                {
                    name: 'actions',
                    label: this.$tc('common.term.action', 2),
                    align: 'left',
                    required: true,
                },
            ],
        }
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        onNewEntity () {
            this.$router.push({
                name: 'commission-rate-adjustment-rule-set-create',
            })
        },
        onDblClick (evt, row) {
            this.$router.push({
                name: 'commission-rate-adjustment-rule-set-update',
                params: { id: row.id },
            })
        },
    },
}
</script>
