import gql from 'graphql-tag'

export const CONTRACT_DELETE_MUTATION = gql`
    mutation contractDelete(
        $id: ID!
    ) {
        contractDelete(
            id: $id
        ) {
            status
        }
    }
`
