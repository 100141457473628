import gql from 'graphql-tag'

export const COUNTRIES_QUERY = gql`
    query {
        countries(
            page: 1
            count: 300
            order: [
                {
                    field: COUNTRY_NAME
                    direction: ASC
                }
            ]
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                name
            }
        }
    }
`
