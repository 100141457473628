<template>
    <node-view-wrapper class="structure-block" data-structure-block="address" contenteditable="false">
        <span class="fake-field-label">
            {{ $tc('common.term.address', 1) }}
        </span>

        <div class="row">
            <div class="col-xs-10 col-sm-11 q-pl-sm">
                <p class="additional-info q-pt-xs q-mb-sm">{{ $tc('common.editor.structure-block.address.addressed-to-recipient--description', 1) }}</p>
            </div>

            <div class="col-xs-2 col-sm-1 q-pr-xs q-pl-xs text-right">
                <q-btn
                    color="negative"
                    size="xs"
                    flat
                    round
                    icon="mib-bin"
                    @click="$refs.removeDialog.show()"
                >
                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.editor.structure-block.address.remove-address-block', 1) }}</q-tooltip>
                </q-btn>

                <!-- q-dialog used directly because of FormDialog not working inside of a NodeView. -->
                <q-dialog ref="removeDialog">
                    <q-card>
                        <q-card-section>
                            <h1 class="text-h3">{{ $tc('common.editor.structure-block.address.remove-address-block', 1) }}</h1>
                            <p>{{ $tc('common.editor.structure-block.address.remove-address-block--confirm', 1) }}</p>
                        </q-card-section>
                        <q-card-actions align="right" class="q-gutter-sm">
                            <base-button
                                :label="$t('common.term.cancel')"
                                primary-button
                                flat
                                @click="$refs.removeDialog.hide()"
                            />
                            <base-button
                                type="submit"
                                :label="$t('common.term.confirm')"
                                data-test="btn:submit"
                                primary-button
                                @click="deleteNode()"
                            />
                        </q-card-actions>
                    </q-card>
                </q-dialog>
            </div>
        </div>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'

export default {
    name: 'StructureBlockAddressNodeView',
    components: {
        NodeViewWrapper,
    },
    props: nodeViewProps,
}
</script>

<style lang="scss" scoped>
.q-dialog__inner {
    h1 {
        margin: 0 0 1em;
    }

    // This is the actual dialog element
    & > div {
        min-width: 22rem;
        width: 60vw;
        max-width: 40rem;

        @include mq($from: $sizeBreakpointMd) {
            min-width: 30rem;
            width: 25vw;
        }
    }
}
</style>
