<template>
    <page-loading-indicator v-if="!state.productDerivedEntity" :number-of-breadcrumb-elements="4" />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="state.productDerivedEntity.customer.getContactName()" :to="{ name: 'contact-detail', params: { id: contactId } }" data-test="breadcrumb:contact-detail" />
                <q-breadcrumbs-el :label="$tc('common.term.contract', 2)" :to="{ name: 'contact-detail', params: { id: contactId } }" data-test="breadcrumb:contracts" />
                <q-breadcrumbs-el :label="state.productDerivedEntity.formattedNumber" :to="{ name: 'application-detail', params: { id: state.productDerivedEntity.id } }" data-test="breadcrumb:application-detail">
                    <status-badge status="application" default-translation-base-path="common.status.application-status" class="q-ml-xs" />
                    <status-badge :status="state.productDerivedEntity.status" default-translation-base-path="common.status.application-status" />
                </q-breadcrumbs-el>
                <q-breadcrumbs-el :label="$tc('views.contract.add-update-application.update-application', 1)" :to="{ name: 'application-update', params: { id: state.productDerivedEntity.id } }" data-test="breadcrumb:application-update" />
            </template>

            <h1>{{ $tc('views.contract.add-update-application.update-application', 1) }}</h1>
        </page-header>

        <product-derived-entity-form
            ref="productDerivedEntityForm"
            :form-class="ApplicationForm"
            :product="state.productDerivedEntity.currentContractInformation.product"
            :product-derived-entity="state.productDerivedEntity"
            :is-change-application="state.productDerivedEntity.is_change_application"
            @submit="updateProductDerivedEntity"
        />
    </page-wrapper>
</template>

<script>
import ProductDerivedEntityForm from '@/components/contract/ProductDerivedEntityForm'
import StatusBadge from '@/components/StatusBadge.vue'
import { onBeforeMount, reactive, ref } from 'vue'
import { Application } from '@/models/application'
import { useRouter } from '@/composables/router'
import { ApplicationForm } from '@/forms/application'

export default {
    name: 'ApplicationUpdate',
    meta () {
        return {
            title: !this.state.productDerivedEntity ? `${this.$t('common.term.loading')} …` : `${this.state.productDerivedEntity.formattedNumber} (${this.state.productDerivedEntity.currentContractInformation.product.providerName}) – ${this.$tc('views.contract.add-update-application.update-application', 1)}`,
        }
    },
    components: {
        StatusBadge,
        ProductDerivedEntityForm,
    },
    props: {
        contactId: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
    },
    setup (props) {
        // Composables
        const router = useRouter()

        // Data
        const state = reactive({
            productDerivedEntity: null,
        })

        // Template refs
        const productDerivedEntityForm = ref(null)

        // Methods
        async function fetchObject () {
            state.productDerivedEntity = await Application.objects.get(props.id)
        }
        function updateProductDerivedEntity (formData) {
            state.productDerivedEntity.update(formData).then(productDerivedEntity => {
                router.replace({
                    name: 'application-detail',
                    params: { contactId: props.contactId, id: productDerivedEntity.id },
                })
            }).catch(error => {
                productDerivedEntityForm.value.formComponent.formBuilder.showFormErrorMessage(error)
                productDerivedEntityForm.value.formComponent.formBuilder.resetFormSubmitStatus()
            })
        }

        // Lifecycle hooks
        onBeforeMount(() => {
            fetchObject()
        })

        return {
            // Static
            ApplicationForm,

            // Data
            state,

            // Template refs
            productDerivedEntityForm,

            // Methods
            updateProductDerivedEntity,
        }
    },
}
</script>
