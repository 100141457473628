<template>
    <q-select
        v-bind="$attrs"
        :value="null"
        :options="[]"
        :loading="loading"
        :disabled="disabled"
        dense
        filled
        square
        class="truncate"
    >
    </q-select>
</template>

<script>
export default {
    name: 'FakeSelect',
    props: {
        loading: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: true,
        },
    },
}
</script>
