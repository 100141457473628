import gql from 'graphql-tag'

export const COMMISSION_TYPE_ACCOUNTING_CONFIGURATIONS_QUERY = gql`
    query commissionTypeAccountingConfigurations(
        $page: Int = 1
        $count: Int = 1000
        $orderField: CommissionTypeAccountingConfigurationOrderField = CREATED_AT
        $orderDirection: OrderDirection = ASC
        $filterQuery: String
    ) {
        commissionTypeAccountingConfigurations(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                query: $filterQuery
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                commission_type
                postingType {
                    id
                    key
                    name
                    name_language_versions
                    created_at
                    updated_at
                }
                posting_text_part
                commissionExpenseAccount {
                    id
                    type
                    status
                    number
                    name
                }
                commissionIncomeAccount {
                    id
                    type
                    status
                    number
                    name
                }
                created_at
                updated_at
            }
        }
    }
`
