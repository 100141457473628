import { Model } from '@/models'
import { DocumentTemplateService } from '@/services'
import { DocumentTemplateActions } from '@/enums'
import { DocumentType } from '@/enums/graphql'

export class DocumentTemplate extends Model {
    /**
     * Create a DocumentTemplate model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'owner',
            'available_for_subordinate_companies',
            'name',
            'description',
            'type',
            'supported_target_object_types',
            'language',
            'confidentiality_notice',
            'differentiate_formality',
            'contents',
            'used_by_mail_processing_templates',
        ], data)
    }

    static get service () { return DocumentTemplateService }
    static action = DocumentTemplateActions

    get actions () {
        const actions = super.actions
        actions.push({
            key: DocumentTemplateActions.DELETE,
            item: this,
        })
        return actions
    }

    /**
     * Create a new documentTemplate.
     * @param {number} ownerId
     * @param {Object} variables - The values used to create the documentTemplate.
     * @param {Boolean} variables.availableForSubordinateCompanies
     * @param {string} variables.name
     * @param {string} variables.description
     * @param {string} variables.type
     * @param {Array} variables.supportedTargetObjectTypes
     * @param {string} variables.languageId
     * @param {string} variables.confidentialityNotice
     * @param {Boolean} variables.differentiateFormality
     * @param {Array} variables.contents
     * @returns {Promise<*>}
     */
    static create (ownerId, { availableForSubordinateCompanies, name, description, type, supportedTargetObjectTypes, languageId, confidentialityNotice, differentiateFormality, contents }) {
        const variables = {
            ownerId,
            documentTemplate: {
                available_for_subordinate_companies: availableForSubordinateCompanies,
                name,
                description,
                type,
                supported_target_object_types: supportedTargetObjectTypes,
                language_id: languageId,
                differentiate_formality: differentiateFormality,
                contents,
            },
        }
        if (type === DocumentType.LETTER) variables.documentTemplate.confidentiality_notice = confidentialityNotice ?? null

        return DocumentTemplateService.create(variables)
    }

    /**
     * Update this documentTemplate.
     * @param {Object} variables - The values used to update the documentTemplate.
     * @param {Boolean} variables.availableForSubordinateCompanies
     * @param {string} variables.name
     * @param {string} variables.description
     * @param {Array} variables.supportedTargetObjectTypes
     * @param {string} variables.languageId
     * @param {string} variables.confidentialityNotice
     * @param {Boolean} variables.differentiateFormality
     * @param {Array} variables.contents
     * @returns {Promise<*>}
     */
    update ({ availableForSubordinateCompanies, name, description, supportedTargetObjectTypes, languageId, confidentialityNotice, differentiateFormality, contents }) {
        const variables = {
            available_for_subordinate_companies: availableForSubordinateCompanies,
            name,
            description,
            type: this.type, // Although technically possible, we don't want this to be able to change for now.
            supported_target_object_types: supportedTargetObjectTypes,
            language_id: languageId,
            differentiate_formality: differentiateFormality,
            contents,
        }
        if (this.type === DocumentType.LETTER) variables.confidentiality_notice = confidentialityNotice ?? null

        return DocumentTemplateService.update(this.id, variables).then(documentTemplate => {
            Object.assign(this, documentTemplate)
            return documentTemplate
        })
    }

    /**
     * Delete this documentTemplate.
     * @returns {Promise<*>}
     */
    delete () {
        return DocumentTemplateService.delete(this.id)
    }

    getOwnerName () { // TODO improvement @TFU: make this work with getContactName and Consultant or ConsultingCompany
        if (typeof this.owner.first_name !== 'undefined') { // Consultant
            return `${this.owner.last_name} ${this.owner.first_name}`
        } else { // ConsultingCompany
            return this.owner.company_name
        }
    }
}
