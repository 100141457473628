import gql from 'graphql-tag'

export const CONTACT_EMAIL_ADDRESS_UPDATE_MUTATION = gql`
    mutation contactEmailAddressUpdate(
        $id: ID!
        $emailAddress: EmailAddressInput!
    ) {
        contactEmailAddressUpdate(
            id: $id
            emailAddress: $emailAddress
        ) {
            id
            category {
                id
                name
            }
            label {
                id
                label
            }
            email
            is_main_email_address
        }
    }
`
