import gql from 'graphql-tag'

export const GENERAL_AGENCIES_QUERY = gql`
    query generalAgencies(
        $page: Int = 1
        $count: Int = 200
        $orderField: GeneralAgencyOrderField = COMPANY_NAME
        $orderDirection: OrderDirection = ASC
        $filterProductProviderId: ID!
        $filterCompanyName: String
    ) {
        generalAgencies(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                product_provider_id: $filterProductProviderId
                company_name: $filterCompanyName
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                company_name
            }
        }
    }
`
