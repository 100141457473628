<template>
    <q-list padding class="main-menu">
        <q-item :to="{ name: 'admin-dashboard' }" exact>
            <q-item-section avatar>
                <q-icon name="mi-desktop-monitor-settings" />
            </q-item-section>
            <q-item-section>{{ $t('views.admin.dashboard.title') }}</q-item-section>
        </q-item>

        <q-expansion-item
            v-if="['User:manage'].every(permission => $can(permission))"
            v-model="navGroupState.users"
            :to="{ name: 'admin-user-management' }"
            :label="$tc('views.admin.users.user-management', 1)"
            expand-icon-toggle
            icon="mi-multiple-actions-address"
            expand-icon="mib-arrow-left-1"
            expanded-icon="mib-arrow-down-1"
            :content-inset-level="1"
            class="subitem-points-to-same-route"
        >
            <q-list dense>
                <q-item :to="{ name: 'admin-user-list' }" exact-path>
                    <q-item-section>{{ $tc('common.term.user', 2) }}</q-item-section>
                </q-item>
                <q-item :to="{ name: 'admin-user-roles-permissions-management' }" exact-path>
                    <q-item-section>{{ $t('views.admin.users.roles-permissions.title') }}</q-item-section>
                </q-item>
            </q-list>
        </q-expansion-item>

        <q-item
            v-if="['ConsultingSettings:create', 'ConsultingSettings:read'].every(permission => $can(permission))"
            :to="{ name: 'admin-consulting-settings-list' }"
        >
            <q-item-section avatar>
                <q-icon name="mi-tools-box" />
            </q-item-section>
            <q-item-section>{{ $tc('common.contact.consulting-setting', 2) }}</q-item-section>
        </q-item>

        <q-expansion-item
            v-if="['Product:manage', 'ProductProviderSettings:create', 'ProductTemplate:manage'].some(permission => $can(permission))"
            v-model="navGroupState.products"
            :to="{ name: 'admin-product-list' }"
            :label="$tc('views.admin.products.management.product-management', 1)"
            icon="mi-module-three-2"
            expand-icon="mib-arrow-left-1"
            expanded-icon="mib-arrow-down-1"
            :content-inset-level="1"
            class="subitem-points-to-same-route"
        >
            <q-list dense>
                <q-item
                    v-if="['Product:manage'].every(permission => $can(permission))"
                    :to="{ name: 'admin-product-list' }"
                    exact-path
                >
                    <q-item-section>
                        {{ $tc('common.term.product', 2) }}
                    </q-item-section>
                </q-item>
                <q-item
                    v-if="['ProductProviderSettings:create', 'ProductProviderSettings:read'].every(permission => $can(permission))"
                    :to="{ name: 'admin-product-provider-list' }"
                    exact-path
                >
                    <q-item-section>
                        {{ $tc('common.product.product-provider', 2) }}
                    </q-item-section>
                </q-item>
                <q-item
                    v-if="['ProductTemplate:manage'].every(permission => $can(permission))"
                    :to="{ name: 'admin-product-template-list' }"
                    exact-path
                >
                    <q-item-section>
                        {{ $tc('common.product.product-template', 2) }}
                    </q-item-section>
                </q-item>
            </q-list>
        </q-expansion-item>

        <q-expansion-item
            v-if="['Feature:correspondence:core'].every(permission => $can(permission)) && ['CorrespondenceSalutation:manage'].some(permission => $can(permission))"
            v-model="navGroupState.correspondence"
            :to="{ name: 'admin-correspondence' }"
            :label="$tc('common.term.correspondence', 1)"
            icon="mi-read-email-letter"
            expand-icon="mib-arrow-left-1"
            expanded-icon="mib-arrow-down-1"
            :content-inset-level="1"
            class="subitem-points-to-same-route"
        >
            <q-list dense>
                <q-item
                    v-if="['CorrespondenceSalutation:manage'].every(permission => $can(permission))"
                    :to="{ name: 'admin-correspondence-salutation-list' }"
                    exact-path
                >
                    <q-item-section>
                        {{ $tc('common.correspondence.salutation.salutation', 2) }}
                    </q-item-section>
                </q-item>
                <q-item v-if="['Feature:mailProcessing:core', 'MailProcessingSettings:manage'].every(permission => $can(permission))" :to="{ name: 'mail-processing-template-list' }" exact-path>
                    <q-item-section>
                        {{ $tc('views.correspondence.mail-processing.cover-letter', 2) }} ({{ $t('common.mail-processing.mail-processing') }})
                    </q-item-section>
                </q-item>
            </q-list>
        </q-expansion-item>

        <q-expansion-item
            v-if="['Feature:accounting:core', 'Accounting:manage'].every(permission => $can(permission))"
            v-model="navGroupState.accounting"
            :to="{ name: 'admin-accounting' }"
            :label="$tc('common.accounting.accounting', 1)"
            icon="mi-accounting-calculator-2"
            expand-icon="mib-arrow-left-1"
            expanded-icon="mib-arrow-down-1"
            :content-inset-level="1"
        >
            <q-list dense>
                <q-item :to="{ name: 'accounting-accounts' }" exact-path>
                    <q-item-section>
                        {{ $tc('common.accounting.account.account', 2) }}
                    </q-item-section>
                </q-item>
                <q-item :to="{ name: 'accounting-posting-types' }" exact-path>
                    <q-item-section>
                        {{ $tc('common.accounting.posting-type.posting-type', 2) }}
                    </q-item-section>
                </q-item>
                <q-item
                    v-if="['Feature:commissions:core', 'Commissions:manage'].every(permission => $can(permission))"
                    :to="{ name: 'commission-type-accounting-configuration' }"
                    exact-path
                >
                    <q-item-section>
                        {{ $t('views.commission.accounting.commission-type-configuration.commission-type-configuration') }}
                    </q-item-section>
                </q-item>
            </q-list>
        </q-expansion-item>

        <q-expansion-item
            v-if="['Feature:commissions:core', 'Commissions:manage'].every(permission => $can(permission))"
            v-model="navGroupState.commissions"
            :to="{ name: 'admin-commissions' }"
            :label="$tc('common.commission.commission', 2)"
            icon="mi-accounting-coins-bill"
            expand-icon="mib-arrow-left-1"
            expanded-icon="mib-arrow-down-1"
            :content-inset-level="1"
        >
            <q-list dense>
                <q-item :to="{ name: 'commission-distribution-rule-sets' }" exact-path>
                    <q-item-section>{{ $tc('views.commission.commission-distribution.commission-distribution', 1) }}</q-item-section>
                </q-item>
                <q-item :to="{ name: 'commission-list-import-excel-mappings' }" exact-path>
                    <q-item-section>{{ $tc('common.commission.excel-mapping', 2) }}</q-item-section>
                </q-item>
                <q-item v-can="'Feature:commissions:commission-rate-adjustment'" :to="{ name: 'commission-rate-adjustment' }" exact-path>
                    <q-item-section>{{ $tc('views.commission.rate-adjustment.rate-adjustment', 2) }}</q-item-section>
                </q-item>
            </q-list>
        </q-expansion-item>

        <q-expansion-item
            v-can="'Feature:ecoHub:core'"
            v-model="navGroupState.ecoHub"
            :to="{ name: 'admin-eco-hub' }"
            :label="$t('views.admin.eco-hub.eco-hub')"
            icon="mi-itx-browser-eco-hub"
            expand-icon="mib-arrow-left-1"
            expanded-icon="mib-arrow-down-1"
            :content-inset-level="1"
        >
            <q-list dense>
                <q-item :to="{ name: 'eco-hub-tech-user-detail' }" exact-path>
                    <q-item-section>{{ $tc('views.admin.eco-hub.tech-user.tech-user', 1) }}</q-item-section>
                </q-item>
            </q-list>
        </q-expansion-item>
    </q-list>
</template>

<script>
export default {
    name: 'MainMenuAdmin',
    data () {
        return {
            navGroupState: {
                users: this.$route.meta.navGroup === 'users',
                consultingSettings: this.$route.meta.navGroup === 'consultingSettings',
                products: this.$route.meta.navGroup === 'products',
                correspondence: this.$route.meta.navGroup === 'correspondence',
                accounting: this.$route.meta.navGroup === 'accounting',
                commissions: this.$route.meta.navGroup === 'commissions',
                ecoHub: this.$route.meta.navGroup === 'ecoHub',
            },
        }
    },
    watch: {
        $route (to) {
            if (this.navGroupState[to.meta.navGroup] !== undefined) {
                this.navGroupState[to.meta.navGroup] = true
            }
        },
    },
}
</script>
