import { apolloClient } from '@/apollo/client'
import { buildProductDerivedEntitiesQuery, PRODUCT_DERIVED_ENTITIES_QUERY } from '@/graphql/productDerivedEntity/productDerivedEntities'
import { PRODUCT_DERIVED_ENTITIES_EXPORT_QUERY } from '@/graphql/productDerivedEntity/productDerivedEntitiesExport'

export const ProductDerivedEntityService = {
    all (variables, fields) {
        const QUERY = (typeof fields !== 'undefined') ? buildProductDerivedEntitiesQuery(fields) : PRODUCT_DERIVED_ENTITIES_QUERY
        return apolloClient.query({
            query: QUERY,
            variables,
        }).then(response => response.data.productDerivedEntities)
    },
    exportAll () {
        return apolloClient.query({
            query: PRODUCT_DERIVED_ENTITIES_EXPORT_QUERY,
        }).then(response => response.data.productDerivedEntitiesExport)
    },
}
