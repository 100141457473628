import { apolloClient } from '@/apollo/client'

import { CONTACT_CONSULTING_SETTINGS_UPDATE_MUTATION } from '@/graphql/contact/contactConsultingSettingsUpdate'
import { CONTACT_CONSULTING_SETTINGS_CHANGE_STATUS_MUTATION } from '@/graphql/contact/contactConsultingSettingsChangeStatus'
import { CONTACT_CONSULTING_SETTINGS_DELETE_MUTATION } from '@/graphql/contact/contactConsultingSettingsDelete'

export const ConsultingSettingsService = {
    create (contactId, consultingSettingsVariables) {
        return ConsultingSettingsService.update(contactId, consultingSettingsVariables)
    },
    update (contactId, consultingSettingsVariables) {
        return apolloClient.mutate({
            mutation: CONTACT_CONSULTING_SETTINGS_UPDATE_MUTATION,
            variables: {
                contact_id: contactId,
                consultingSettings: consultingSettingsVariables,
            },
        }).then(response => response.data.contactConsultingSettingsUpdate)
    },
    changeStatus (contactId, status) {
        return apolloClient.mutate({
            mutation: CONTACT_CONSULTING_SETTINGS_CHANGE_STATUS_MUTATION,
            variables: {
                contact_id: contactId,
                status: status,
            },
        }).then(response => response.data.contactConsultingSettingsChangeStatus)
    },
    delete (contactId) {
        return apolloClient.mutate({
            mutation: CONTACT_CONSULTING_SETTINGS_DELETE_MUTATION,
            variables: {
                contact_id: contactId,
            },
        }).then(response => response.data.contactConsultingSettingsDelete)
    },
}
