<template>
    <q-page>
        <div class="row q-col-gutter-md q-pa-lg justify-center reset-password-wrapper">
            <div class="col-xs-12 col-sm-8 col-md-6 col-lg-6 col-xl-4 self-center">
                <template v-if="activeStep === 'passwords'">
                    <div class="q-mt-xl">
                        <illustration-heading :title="$t('views.auth.reset-password.title')" img="password-lock-circle" :error="formHasErrors" />
                        <p>{{ $t('views.auth.reset-password.text') }}</p>
                    </div>

                    <p>{{ $t('common.auth.password-requirements') }}</p>

                    <form class="row" data-test="form:auth-reset-password" @submit.prevent="onFormSubmit">
                        <div class="col-xs-12 q-gutter-y-md">
                            <info-box v-if="formErrorMessage" type="negative" data-test="error:global-message">{{ formErrorMessage }}</info-box>

                            <form-builder item-key="setPassword" autofocus /><!-- TODO: Implement revealPassword -->
                            <form-builder item-key="confirmPassword" />

                            <form-navigation>
                                <template v-slot:col2>
                                    <base-button
                                        data-test="btn:submit"
                                        type="submit"
                                        :label="$t('views.auth.reset-password.reset-password-button')"
                                        :disable="formSubmitStatus"
                                        :loading="formSubmitStatus"
                                    />
                                </template>
                            </form-navigation>
                        </div>
                    </form>
                </template>

                <template v-else>
                    <div class="q-mt-xl">
                        <illustration-heading :title="$t('views.auth.reset-password.confirmation-title')" img="password-lock-circle" icon="check" />
                        <p>{{ $t('views.auth.reset-password.confirmation-text') }}</p>
                        <q-btn
                            unelevated
                            no-caps
                            class="q-mt-lg q-mr-sm"
                            color="primary"
                            :label="$t('common.auth.go-to-login')"
                            :to="{ name: 'auth-login' }"
                            data-test="btn:login"
                        /><!-- TODO: get username instead of status from apollo call and add it as query -->
                    </div>
                </template>
            </div>
        </div>
    </q-page>
</template>

<script>
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import FormNavigation from '@/components/form/FormNavigation'

import AUTH_CREDENTIALS_FIELDS from '@/forms/auth.fields.json'

import { AuthService } from '@/services'

export default {
    name: 'AuthResetPassword',
    components: {
        FormNavigation,
    },
    mixins: [formBuilderMixin],
    props: {
        token: {
            type: String,
            required: true,
        },
    },
    formBuilderSettings: {
        schema: AUTH_CREDENTIALS_FIELDS,
        fields: ['setPassword', 'confirmPassword'],
    },
    meta () {
        return {
            title: this.$t('views.auth.reset-password.title'),
        }
    },
    data () {
        return {
            activeStep: 'passwords',
            formHasErrors: false,
        }
    },
    methods: {
        handleFormData ({ setPassword, confirmPassword }) {
            this.formHasErrors = false
            AuthService.resetPassword(this.token, setPassword, confirmPassword)
                .then(() => {
                    this.activeStep = 'confirmation'
                })
                .catch(error => {
                    this.formHasErrors = true
                    this.showFormErrorMessage(error)
                    this.resetFormData()
                    this.resetFormSubmitStatus()
                })
        },
    },
}
</script>
