import { apolloClient } from '@/apollo/client'

import { CORRESPONDENCE_ITEMS_QUERY } from '@/graphql/correspondence/correspondenceItems'
import { PROCESSED_CORRESPONDENCE_ITEMS_QUERY } from '@/graphql/correspondence/processedCorrespondenceItems'

export const CorrespondenceService = {
    items: {
        all (variables) {
            return apolloClient.query({
                query: CORRESPONDENCE_ITEMS_QUERY,
                variables,
            }).then(response => response.data.correspondenceItems)
        },
        processed (variables) {
            return apolloClient.query({
                query: PROCESSED_CORRESPONDENCE_ITEMS_QUERY,
                variables,
            }).then(response => response.data.processedCorrespondenceItems)
        },
    },
}
