import { Email as EmailBase } from '@max/tiptap-extensions'

import Dropcursor from '@tiptap/extension-dropcursor'
import History from '@tiptap/extension-history'

import { StructureBlockContent } from '@/editor/structureBlocks/StructureBlockContent'
import { StructureBlockSubject } from '@/editor/structureBlocks/StructureBlockSubject'

import { SmartObjectSuggestion } from '@/editor/smartObjects/SmartObjectSuggestion'
import { SmartObjectSimplePlaceholder } from '@/editor/smartObjects/SmartObjectSimplePlaceholder'
import { SmartObjectCurrency } from '@/editor/smartObjects/SmartObjectCurrency'
import { SmartObjectDate } from '@/editor/smartObjects/SmartObjectDate'
import { SmartObjectConditionalSpace } from '@/editor/smartObjects/SmartObjectConditionalSpace'

export const Email = EmailBase.extend({
    addExtensions() {
        const emailBaseExtensions = this.parent?.() || []
        return [
            // Filter out StructureBlock… and SmartObject… extensions …
            ...emailBaseExtensions.filter(extension => {
                return ![
                    StructureBlockContent.name,
                    StructureBlockSubject.name,
                    SmartObjectSimplePlaceholder.name,
                    SmartObjectCurrency.name,
                    SmartObjectDate.name,
                    SmartObjectConditionalSpace.name,
                ].includes(extension.name)
            }),

            Dropcursor,
            History,

            SmartObjectSuggestion.configure({
                blockEditorComponentInstance: this.options.blockEditorComponentInstance,
            }),

            // … and include the versions with a NodeView.
            StructureBlockContent,
            StructureBlockSubject,
            SmartObjectSimplePlaceholder,
            SmartObjectCurrency,
            SmartObjectDate,
            SmartObjectConditionalSpace,
        ]
    },
})
