import gql from 'graphql-tag'

export const TASK_ADD_ATTACHMENT_MUTATION = gql`
    mutation taskAddAttachment(
        $task_id: ID!
        $file: Upload!
    ) {
        taskAddAttachment(
            task_id: $task_id
            file: $file
        ) {
            id
            name
            size
            mime_type

            created_at
            updated_at
        }
    }
`
