<template>
    <page-wrapper>
        <page-header :progress-bar="loading">
            <h1>{{ $tc('views.contact.list.title', 2) }}</h1>

            <template v-slot:actions>
                <actions
                    :actions="actions"
                    :number-of-next-best-actions="0"
                    translation-base-key="common.actions.contact"
                    @click="handleActions"
                />

                <!-- contactsExport -->
                <form-dialog
                    ref="contactsExportDialog"
                    submit-button-label="Export"
                    @submit="handleContactsExport"
                >
                    <template v-slot:title>{{ $t('views.contact.list.export-contacts') }}</template>
                    <template v-slot:default><p>{{ $t('views.contact.list.export-contacts--confirm') }}</p></template>
                </form-dialog>

                <!-- productDerivedEntitiesExport -->
                <form-dialog
                    ref="productDerivedEntitiesExportDialog"
                    submit-button-label="Export"
                    @submit="handleProductDerivedEntitiesExport"
                >
                    <template v-slot:title>{{ $tc('views.contact.list.export-portfolio', 1) }}</template>
                    <template v-slot:default><p>{{ $t('views.contact.list.export-portfolio--confirm') }}</p></template>
                </form-dialog>
            </template>
        </page-header>

        <div class="row">
            <div class="col-12">
                <q-table
                    :data="contacts"
                    :columns="columns"
                    row-key="id"
                    :pagination.sync="pagination"
                    :loading="loading"
                    :rows-per-page-options="[10, 20, 50, 100]"
                    binary-state-sort
                    flat
                    square
                    dense
                    data-test="table:contact-list"
                    @request="onRequest"
                    @row-dblclick="(evt, row) => $router.push({ name: 'contact-detail', params: { id: row.id } })"
                >
                    <template v-slot:top>
                        <q-tabs
                            dense
                            no-caps
                            inline-label
                            align="left"
                            class="q-mr-sm bg-background-secondary"
                            indicator-color="accent"
                        >
                            <q-route-tab :to="{ name: 'contact-list' }" :label="$t('common.status.active')" />
                            <q-route-tab :to="{ name: 'contact-list', params: { tab: 'archived' } }" :label="$t('common.status.archived')" />
                        </q-tabs>

                        <q-space />

                        <filter-input v-model="filter" @clear-filter="clearFilter" />
                    </template>

                    <template v-slot:body-cell-contact_number="props">
                        <q-td
                            :props="props"
                            data-test="text:contact-number"
                        >
                            {{ props.row.contactNumber.number }}
                        </q-td>
                    </template>

                    <template v-slot:body-cell-contact_name="props">
                        <q-td
                            :props="props"
                            class="contact-name-td"
                            data-test="text:contact-name"
                        >
                            <contact-name
                                :contact="props.row"
                                type-icon
                                highlighted
                                comma-separated
                            />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-status="props">
                        <q-td :props="props" auto-width>
                            <status-badge v-if="props.row.status !== ContactStatus.ACTIVE" :status="props.row.status" />
                            <status-badge v-if="props.row.customer_status" :status="props.row.customer_status" default-translation-base-path="common.contact.customer-status" />
                            <contact-type-badges :contact="props.row" />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-actions="props">
                        <q-td :props="props" auto-width>
                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-view-1"
                                :to="{ name: 'contact-detail', params: { id: props.row.id } }"
                                data-test="btn:tooltip-details"
                            >
                                <q-tooltip
                                    :delay="1000"
                                    :offset="[0, 10]"
                                >
                                    {{ $t('views.contact.list.show-contact-details-of-contact', { contactName: props.row.getContactName() }) }}
                                </q-tooltip>
                            </q-btn>

                            <q-btn
                                class="overflow-hidden"
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-navigation-menu-horizontal"
                                data-test="btn:tooltip-actions"
                            >
                                <q-tooltip
                                    :delay="1000"
                                    :offset="[0, 10]"
                                >
                                    {{ $t('common.term.more') }}
                                </q-tooltip>
                                <q-menu anchor="top left" self="top right">
                                    <div class="q-pl-xs q-pr-xs">
                                        <q-btn
                                            color="primary"
                                            size="sm"
                                            flat
                                            round
                                            icon="mib-common-file-stack-alternate"
                                            :to="{ name: 'contact-detail', params: { id: props.row.id, tab: 'documents' } }"
                                            data-test="btn:tooltip-show-documents"
                                        >
                                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $t('views.contact.list.show-documents-of-contact', { contactName: props.row.getContactName() }) }}</q-tooltip>
                                        </q-btn>
                                        <q-btn
                                            color="primary"
                                            size="sm"
                                            flat
                                            round
                                            icon="mib-style-two-pin-marker"
                                            :to="{ name: 'contact-detail', params: { id: props.row.id, tab: 'addresses' } }"
                                            data-test="btn:tooltip-show-addresses"
                                        >
                                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $t('views.contact.list.show-addresses-of-contact', { contactName: props.row.getContactName() }) }}</q-tooltip>
                                        </q-btn>
                                    </div>
                                </q-menu>
                            </q-btn>
                        </q-td>
                    </template>
                </q-table>
            </div>
        </div>

        <in-page-footer>
            <q-btn-dropdown
                ref="contactCreateButtonDropdown"
                v-can="['create', 'Contact']"
                :label="$t('views.contact.list.add-contact')"
                class="primary-button"
                unelevated
                no-caps
                color="primary-action"
                text-color="text-primary"
                icon="mib-add"
                menu-anchor="bottom left"
                menu-self="top left"
                data-test="btn:dropdown-actions"
            >
                <q-list>
                    <q-item
                        v-close-popup
                        clickable
                        :to="{ name: 'contact-create', params: { type: 'person' } }"
                        data-test="link:contact-add-person"
                    >
                        <q-item-section side>
                            <q-icon name="mib-single-neutral-actions-add" />
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{ $t('views.contact.create.add-person') }}</q-item-label>
                        </q-item-section>
                    </q-item>
                    <q-item
                        v-close-popup
                        clickable
                        :to="{ name: 'contact-create', params: { type: 'company' } }"
                        data-test="link:contact-add-company"
                    >
                        <q-item-section side>
                            <q-icon name="mib-real-estate-action-building-add" />
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{ $t('views.contact.create.add-company') }}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
            </q-btn-dropdown>
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import Actions from '@/components/Actions'
import { EventBus } from '@/event-bus'
import { UserSettings } from '@/helpers/user'
import FormDialog from '@/libs/form/components/FormDialog'
import { contactMixin } from '@/mixins/contactMixin'
import { ContactStatus, CustomerStatus, OrderDirection } from '@/enums/graphql'

import ContactName from '@/components/contact/ContactName.vue'
import StatusBadge from '@/components/StatusBadge.vue'
import ContactTypeBadges from '@/components/contact/ContactTypeBadges.vue'
import FilterInput from '@/components/FilterInput'

import { Contact } from '@/models/contact'
import { ContactService, ProductDerivedEntityService } from '@/services'

export default {
    name: 'ContactList',
    meta () {
        return {
            title: this.$tc('views.contact.list.title', 2),
        }
    },
    components: {
        Actions,
        FormDialog,
        ContactName,
        ContactTypeBadges,
        StatusBadge,
        FilterInput,
    },
    mixins: [contactMixin],
    props: {
        tab: {
            type: String,
            default: 'active',
        },
    },
    data () {
        const userSettings = new UserSettings(this.$store.state.user)

        // Actions
        const actions = []
        const rolesPermittedToUseContactsExport = [
            'administrator',
            'accountingCommissions',
            'management',
            'managementAssistance',
            'headOfFieldService',
            'fieldService',
            'consultingCompanyManagement',
            'consultingCompanyHeadOfBackoffice',
        ]
        if (rolesPermittedToUseContactsExport.some(role => this.$can(role))) actions.push({ key: 'EXPORT-CONTACTS' })
        const rolesPermittedToUseProductDerivedEntitiesExport = [
            'administrator',
            'backoffice',
            'accountingCommissions',
            'management',
            'managementAssistance',
            'headOfFieldService',
            'fieldService',
            'consultingCompanyManagement',
            'consultingCompanyHeadOfBackoffice',
        ]
        if (rolesPermittedToUseProductDerivedEntitiesExport.some(role => this.$can(role))) actions.push({ key: 'EXPORT-PRODUCT-DERIVED-ENTITIES' })

        return {
            userSettings,
            activeTab: this.tab,
            filter: '',
            loading: false,
            abortController: null,
            pagination: {
                sortBy: 'contact_name',
                descending: false,
                page: 1,
                rowsPerPage: parseInt(userSettings.getItem('ContactList.pagination.rowsPerPage')) || 50,
                rowsNumber: 0,
            },
            columns: [
                // { name: 'picture', label: this.$t('common.term.picture'), required: true, field: row => row.picture, sortable: false, align: 'left' },
                {
                    name: 'contact_number',
                    label: this.$tc('common.contact.contact-number', 1),
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'contact_name',
                    label: this.$tc('common.term.name'),
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'main_address_address_1',
                    label: this.$tc('common.term.address'),
                    field: row => row.mainAddress.address1,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'main_address_address_zip',
                    label: this.$tc('common.address.zip'),
                    field: row => row.mainAddress.zip,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'main_address_address_city',
                    label: this.$tc('common.address.city'),
                    field: row => row.mainAddress.city,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'status',
                    label: this.$tc('common.term.status'),
                    align: 'left',
                },
                // TODO: Make table sortable by status (currently returns an error).
                // { name: 'status', label: this.$tc('common.term.status'), field: row => row.status, sortable: true, align: 'left' },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left' },
            ],
            contacts: [],
            actions,
            ContactStatus,
            CustomerStatus,
        }
    },
    watch: {
        'filter' () {
            this.fetchObjects()
        },
        '$route' (to) {
            this.activeTab = to.params.tab || 'active'
            this.fetchObjects()
        },
        'pagination.rowsPerPage' (rowsPerPage) {
            this.userSettings.setItem('ContactList.pagination.rowsPerPage', rowsPerPage)
        },
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
        this.fetchObjects()
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        clearFilter () {
            this.filter = ''
        },
        onRequest (props) {
            let { page, rowsPerPage, sortBy, descending } = props.pagination

            this.pagination.page = page
            this.pagination.rowsPerPage = rowsPerPage
            this.pagination.sortBy = sortBy
            this.pagination.descending = descending

            this.fetchObjects()
        },
        fetchObjects () {
            if (this.abortController) this.abortController.abort()
            this.loading = true
            this.abortController = new AbortController()
            Contact.service.allWithOptions({
                page: this.pagination.page,
                count: this.pagination.rowsPerPage,
                orderField: this.pagination.sortBy.toUpperCase(),
                orderDirection: (this.pagination.descending) ? OrderDirection.DESC : OrderDirection.ASC,
                filterQuery: this.filter,
                contact_status: this.activeTab.toUpperCase(),
            }, {
                fields: `
                    id
                    status
                    consultingSettings {
                        status
                    }
                    productProviderSettings {
                        status
                    }
                    contactNumber {
                        number
                    }
                    mainAddress {
                        address1
                        zip
                        city
                    }
                    customer_status
                    ... on Person {
                        first_name
                        last_name
                        gender
                        personal_pronoun
                    }
                    ... on Company {
                        company_name
                        is_tenant_company
                    }
                `,
                abortController: this.abortController,
            }).then(response => {
                this.pagination.rowsNumber = response.paginatorInfo.total
                this.abortController = null
                this.loading = false
                this.contacts = response.data
            })
        },
        handleActions (action) {
            switch (action.key) {
                case 'EXPORT-CONTACTS':
                    this.$refs.contactsExportDialog.open()
                    break
                case 'EXPORT-PRODUCT-DERIVED-ENTITIES':
                    this.$refs.productDerivedEntitiesExportDialog.open()
                    break
            }
        },
        handleContactsExport () {
            ContactService.exportAll()
                .then(() => {
                    this.$refs.contactsExportDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contact.contact-export-creation-started-success', 1),
                        caption: this.$t('common.notifications.contact.export-email-notification-hint'),
                    })
                })
                .catch(error => {
                    this.$refs.contactsExportDialog.showFormErrorMessage(error)
                    this.$refs.contactsExportDialog.resetFormSubmitStatus()
                })
        },
        handleProductDerivedEntitiesExport () {
            ProductDerivedEntityService.exportAll()
                .then(() => {
                    this.$refs.productDerivedEntitiesExportDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contact.portfolio-export-creation-started-success', 1),
                        caption: this.$t('common.notifications.contact.export-email-notification-hint'),
                    })
                })
                .catch(error => {
                    this.$refs.productDerivedEntitiesExportDialog.showFormErrorMessage(error)
                    this.$refs.productDerivedEntitiesExportDialog.resetFormSubmitStatus()
                })
        },
        onNewEntity () {
            this.$refs.contactCreateButtonDropdown.show()
        },
    },
}
</script>

<style lang="scss" scoped>
.primary-button {
    &.q-hoverable {

        &:hover,
        &:focus,
        &:active {

            & > ::v-deep .q-focus-helper {
                background-color: var(--color-primary-button); // background-color: currentColor;
            }
        }
    }
}

.contact-name-td {
    max-width: 30vw;
    overflow: hidden;
    text-overflow: ellipsis;

    @include mq($to: $sizeBreakpointMd) {
        min-width: 30vw;
        max-width: 50vw;
    }
}
</style>
