import { apolloClient } from '@/apollo/client'
import { ProductStatus } from '@/enums/graphql'

import { PRODUCT_QUERY } from '@/graphql/product/product'
import { PRODUCTS_QUERY } from '@/graphql/product/products'
import { PRODUCT_CATEGORIES_QUERY } from '@/graphql/product/productCategories'
import { PRODUCT_FIELD_GROUPS_QUERY } from '@/graphql/product/productFieldGroups'
import { PRODUCT_CREATE_MUTATION } from '@/graphql/product/productCreate'
import { PRODUCT_UPDATE_MUTATION } from '@/graphql/product/productUpdate'
import { PRODUCT_DELETE_MUTATION } from '@/graphql/product/productDelete'

export const ProductService = {
    get (id) {
        return apolloClient.query({
            query: PRODUCT_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.product)
    },
    all (variables) {
        return apolloClient.query({
            query: PRODUCTS_QUERY,
            variables,
        }).then(response => response.data.products)
    },
    create ({ name, productTemplate, productProvider }) {
        return apolloClient.mutate({
            mutation: PRODUCT_CREATE_MUTATION,
            variables: {
                product: {
                    name: name,
                    product_template_id: productTemplate,
                    provider_id: productProvider,
                    status: ProductStatus.ACTIVE,
                },
            },
        }).then(response => response.data.productCreate)
    },
    update (id, name) {
        return apolloClient.mutate({
            mutation: PRODUCT_UPDATE_MUTATION,
            variables: {
                id: id,
                product: {
                    name: name,
                },
            },
        }).then(response => response.data.productUpdate)
    },
    changeStatus (id, status) {
        return apolloClient.mutate({
            mutation: PRODUCT_UPDATE_MUTATION,
            variables: {
                id: id,
                product: {
                    status: status,
                },
            },
        }).then(response => response.data.productUpdate)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: PRODUCT_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.productDelete)
    },
    getCategories (variables) {
        return apolloClient.query({
            query: PRODUCT_CATEGORIES_QUERY,
            variables,
        }).then(response => response.data.productCategories)
    },
    getProductFields (variables) {
        return apolloClient.query({
            query: PRODUCT_FIELD_GROUPS_QUERY,
            variables,
        }).then(response => response.data.productFieldGroups)
    },
}
