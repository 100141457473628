import { Form, FormField } from '@/libs/form'
import {
    ConsultingFileTargetObjectType,
    DxpDocumentRecipient
} from '@/enums/graphql'
import { kebabCase } from 'lodash'
import { requiredUnless } from '@vuelidate/validators'
import { i18n } from '@/i18n'

export class PremiumInvoiceForm extends Form {
    compose () {
        // Invoice number
        this.addField(new FormField('invoiceNumber', {
            label: 'common.premium-invoice.invoice-number',
            inputType: 'text',
            validators: [
                { type: 'maxLength', params: { max: 20 } },
            ],
        }))

        // Invoice date
        this.addField(new FormField('invoiceDate', {
            label: 'common.premium-invoice.invoice-date',
            inputType: 'calendar',
            validators: [
                { type: 'date' },
            ],
        }))

        // Document recipient
        const documentRecipientOptions = [
            ...Object.values(DxpDocumentRecipient).map(value => ({ label: `common.premium-invoice.document-recipient.${kebabCase(value.toLowerCase())}`, value: value })),
        ]
        this.addField(new FormField('documentRecipient', {
            label: 'common.premium-invoice.document-recipient.document-recipient',
            inputType: 'select',
            attributes: {
                options: documentRecipientOptions,
            },
        }))

        // Files to delete
        const deleteFileIdsOptions = this.context.premiumInvoice?.files?.map(file => ({ label: file.name, value: file.id })) || []
        this.addField(new FormField('deleteFileIds', {
            label: 'forms.field.label.premium-invoice.files-to-be-deleted',
            inputType: 'optionGroup',
            props: {
                options: deleteFileIdsOptions,
                type: 'checkbox',
            },
        }))

        // Files
        const filesRequiredUnlessCondition = () => {
            if (this.context.premiumInvoice?.files) {
                return !(this.getItem('deleteFileIds').value.length === this.context.premiumInvoice.files.length && this.getItem('contractFileIds').value.length === 0)
            } else {
                return this.getItem('contractFileIds').value.length
            }
        }
        this.addField(new FormField('files', {
            label: 'forms.field.label.premium-invoice.file',
            inputType: 'fileUpload',
            validators: [
                {
                    type: 'requiredUnlessContractFileIds',
                    validator: requiredUnless(filesRequiredUnlessCondition),
                    requiredUnlessCondition: filesRequiredUnlessCondition,
                    errorMessage: i18n.t('common.error-message.required', { field: i18n.tc('forms.field.label.premium-invoice.file', 1) }),
                },
            ],
        }))

        // Consulting files
        const contractFileIdsRequiredUnlessCondition = () => {
            if (this.context.premiumInvoice?.files) {
                return !(this.getItem('deleteFileIds').value.length === this.context.premiumInvoice.files.length && this.getItem('files').value.length === 0)
            } else {
                return this.getItem('files').value.length
            }
        }
        this.addField(new FormField('contractFileIds', {
            label: 'forms.field.label.premium-invoice.contract-file',
            inputType: 'consultingFiles',
            props: {
                targetObjectType: ConsultingFileTargetObjectType.CONTRACT,
                targetObjectId: this.context.targetObjectId,
                filters: {
                    typeId: this.context.typeId,
                },
            },
            validators: [
                {
                    type: 'requiredUnlessFiles',
                    validator: requiredUnless(contractFileIdsRequiredUnlessCondition),
                    requiredUnlessCondition: contractFileIdsRequiredUnlessCondition,
                    errorMessage: i18n.t('common.error-message.required', { field: i18n.tc('forms.field.label.premium-invoice.contract-file', 1) }),
                },
            ],
        }))
    }
}
