<template>
    <form-builder-next
        v-slot="{ formBuilder }"
        :form="EcoHubTechUserForm"
        :initial-data="initialData"
        in-page-footer
        :submit-button-label="ecoHubTechUser ? $t('common.term.save') : $tc('views.admin.eco-hub.tech-user.enroll-tech-user', 1)"
        @cancel="$emit('cancel')"
        @submit="formData => $emit('submit', formData)"
    />
</template>

<script>
import { ref } from 'vue'
import { EcoHubTechUserForm } from '@/forms/ecoHubTechUser'

export default {
    name: 'EcoHubTechUserForm',
    props: {
        ecoHubTechUser: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
    setup (props) {
        const formComponent = ref(null)

        let initialData = null
        if (props.ecoHubTechUser) {
            initialData = {
                idpUserId: props.ecoHubTechUser.idp_user_id,
            }
        }

        return {
            formComponent,
            EcoHubTechUserForm,
            initialData,
        }
    },
}
</script>
