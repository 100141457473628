<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.correspondence.salutation.salutation', 2)" :to="{ name: 'admin-correspondence-salutation-list' }" />
            </template>

            <h1 class="q-mb-none">{{ $tc('common.correspondence.salutation.salutation', 2) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-12">
                <info-box>
                    <p>{{ $tc('views.admin.correspondence.salutation.default-salutation--description', 1) }}</p>
                </info-box>

                <q-table
                    :data="correspondenceSalutations"
                    :columns="columns"
                    row-key="id"
                    :pagination.sync="pagination"
                    :loading="loading"
                    :rows-per-page-options="[10, 20, 50, 100]"
                    binary-state-sort
                    flat
                    square
                    dense
                    @request="onRequest"
                    @row-dblclick="(evt, row) => $router.push({ name: 'admin-correspondence-salutation-update', params: { id: row.id } })"
                >
                    <template v-slot:top>
                        <q-space />
                        <!-- TODO improvement: Add quickfilters? -->
                    </template>

                    <template v-slot:body-cell-salutation="props">
                        <q-td :props="props"><span v-html="stringWithPlaceholderTags(props.row.salutation.contentHTML)">{{ props.row.salutation }}</span></q-td>
                    </template>

                    <template v-slot:body-cell-contact_type="props">
                        <q-td :props="props">
                            <q-icon :name="(props.row.contact_type === ContactType.COMPANY) ? 'mib-building-1' : 'mib-single-neutral'" color="grey" class="q-mr-xs" />
                            {{ $tc(`common.contact.contact-type.contact-type--${props.row.contact_type.toLowerCase()}`, 1) }}
                        </q-td>
                    </template>

                    <template v-slot:body-cell-gender="props">
                        <q-td :props="props">
                            <template v-if="props.row.gender">
                                <q-icon :name="(props.row.gender === GenderChoice.FEMALE) ? 'mib-single-woman' : 'mib-single-man'" color="grey" class="q-mr-xs" />
                                {{ $tc(`common.contact.gender-${props.row.gender.toLowerCase()}`, 1) }}
                            </template>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-formality="props">
                        <q-td :props="props">{{ $tc(`common.contact.formality.formality--${props.row.formality.toLowerCase()}`, 1) }}</q-td>
                    </template>

                    <template v-slot:body-cell-language="props">
                        <q-td :props="props">{{ props.row.language.name }}</q-td>
                    </template>

                    <template v-slot:body-cell-is_default="props">
                        <q-td :props="props" auto-width>
                            <base-badge v-if="props.row.is_default" :label="$tc('views.admin.correspondence.salutation.default-salutation', 1)" />
                            <q-tooltip :delay="1000" :offset="[0, 10]">
                                {{
                                    $tc('views.admin.correspondence.salutation.default-salutation--badge-tooltip', 1, {
                                        salutationFormality: $tc(`common.correspondence.salutation.salutation--${props.row.formality.toLowerCase()}`, 1),
                                        contactType: (props.row.contact_type === ContactType.COMPANY) ? $tc(`common.contact.contact-type.contact-type--company`, 2) : $tc(`common.contact.gender-${props.row.gender.toLowerCase()}`, 2),
                                        language: props.row.language.name,
                                    })
                                }}
                            </q-tooltip>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-actions="props">
                        <q-td :props="props" auto-width>
                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-pencil"
                                :to="{ name: 'admin-correspondence-salutation-update', params: { id: props.row.id } }"
                            >
                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.admin.correspondence.salutation.edit-salutation', 1) }}</q-tooltip>
                            </q-btn>
                        </q-td>
                    </template>
                </q-table>
            </div>
        </div>

        <in-page-footer>
            <base-button
                :to="{ name: 'admin-correspondence-salutation-create' }"
                :label="$tc('views.admin.correspondence.salutation.add-salutation', 1)"
                icon="mib-social-profile-bubble"
                primary-button
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import { UserSettings } from '@/helpers/user'
import { OrderDirection, ContactType, GenderChoice } from '@/enums/graphql'
import { CorrespondenceSalutation } from '@/models/correspondenceSalutation'
import { stringWithPlaceholderTags } from '@/helpers'
import BaseBadge from '@/components/BaseBadge'

export default {
    name: 'CorrespondenceSalutationList',
    meta () {
        return {
            title: this.$tc('common.correspondence.salutation.salutation', 2),
        }
    },
    components: {
        BaseBadge,
    },
    data () {
        const userSettings = new UserSettings(this.$store.state.user)
        return {
            userSettings,
            ContactType,
            GenderChoice,
            loading: false,
            pagination: {
                sortBy: 'contact_type',
                descending: false,
                page: 1,
                rowsPerPage: parseInt(userSettings.getItem('CorrespondenceSalutationList.pagination.rowsPerPage')) || 50,
                rowsNumber: 0,
            },
            columns: [
                {
                    name: 'salutation',
                    label: this.$tc('common.correspondence.salutation.salutation', 1),
                    align: 'left',
                },
                {
                    name: 'contact_type',
                    label: this.$tc('common.contact.contact-type.contact-type', 1),
                    field: row => row.contact_type,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'gender',
                    label: this.$tc('common.contact.gender', 1),
                    field: row => row.gender,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'formality',
                    label: this.$tc('common.contact.formality.formality', 1),
                    field: row => row.formality,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'language',
                    label: this.$tc('common.term.language', 1),
                    field: row => row.language.name,
                    align: 'left',
                },
                {
                    name: 'is_default',
                    label: this.$tc('views.admin.correspondence.salutation.default-salutation', 1),
                    field: row => row.is_default,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'updated_at',
                    label: this.$t('common.term.updated--last-updated'),
                    field: row => row.formattedUpdatedAt,
                    sortable: true,
                    align: 'left',
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left' },
            ],
            correspondenceSalutations: [],
        }
    },
    watch: {
        'pagination.rowsPerPage' (rowsPerPage) {
            this.userSettings.setItem('CorrespondenceSalutationList.pagination.rowsPerPage', rowsPerPage)
        },
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
        this.fetchObjects()
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        onRequest (props) {
            let { page, rowsPerPage, sortBy, descending } = props.pagination

            this.pagination.page = page
            this.pagination.rowsPerPage = rowsPerPage
            this.pagination.sortBy = sortBy
            this.pagination.descending = descending

            this.fetchObjects()
        },
        fetchObjects () {
            this.loading = true
            CorrespondenceSalutation.objects.all({
                page: this.pagination.page,
                count: this.pagination.rowsPerPage,
                orderField: this.pagination.sortBy.toUpperCase(),
                orderDirection: (this.pagination.descending) ? OrderDirection.DESC : OrderDirection.ASC,
            }).then(response => {
                this.pagination.rowsNumber = response.paginatorInfo.total
                this.loading = false
                this.correspondenceSalutations = response.data
            })
        },
        onNewEntity () {
            this.$router.push({ name: 'admin-correspondence-salutation-create' })
        },
        stringWithPlaceholderTags (...args) {
            return stringWithPlaceholderTags(...args)
        },
    },
}
</script>
