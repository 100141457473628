<template>
    <q-dialog ref="authAutoLogoutDialog" persistent @hide="clear">
        <q-card class="auth-logout-dialog-wrapper">
            <q-card-section>
                <illustration-heading
                    :title="$t('common.auth.auto-logout.title')"
                    img="password-lock-circle"
                    img-min-width="8rem"
                    img-max-width="12rem"
                    :error="runningOutOfTime"
                />

                <i18n v-if="now" path="common.auth.auto-logout.text" tag="p">
                    <template v-slot:timer>
                        <b :class="{ 'running-out-of-time': runningOutOfTime }">{{ formattedTimeUntilLogout }}</b>
                    </template>
                </i18n>
            </q-card-section>

            <q-card-actions align="center" class="q-pb-lg">
                <base-button
                    :label="$t('common.auth.auto-logout.stay-logged-in')"
                    icon="mib-synchronize-arrow-clock"
                    @click="refreshToken"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { formatDistanceStrict } from 'date-fns'
import { getCurrentDateFnsLocale } from '@/helpers/date'
import { EventBus } from '@/event-bus'
import { AutoLogout } from '@/auto-logout'
import { UserService } from '@/services'
import { apolloClient } from '@/apollo/client'
import { onLogout } from '@/apollo'
import { Cookies } from 'quasar'

export default {
    name: 'AuthAutoLogoutOverlay',
    data () {
        return {
            intervalDuration: 1000,
            intervalId: undefined,
            now: null,
            runningOutOfTime: false,
        }
    },
    // TODO: Add meta info when AutoLogoutDialog is visible (change favicon, change title, add timer to title)
    computed: {
        formattedTimeUntilLogout () {
            return formatDistanceStrict(
                new Date(AutoLogout.expirationTimestamp),
                new Date(this.now),
                { addSuffix: true, locale: getCurrentDateFnsLocale() }
            )
        },
    },
    created () {
        EventBus.$on('autoLogout:startTimeout', this.hide)
        EventBus.$on('autoLogout:onTimeout', this.show)
    },
    beforeDestroy () {
        EventBus.$off('autoLogout:startTimeout', this.hide)
        EventBus.$off('autoLogout:onTimeout', this.show)
    },
    methods: {
        show () {
            this.updateNow()
            this.$refs.authAutoLogoutDialog.show()
            this.intervalId = setInterval(this.updateNow.bind(this), this.intervalDuration)
        },
        hide () {
            this.$refs.authAutoLogoutDialog.hide()
            this.clear()
        },
        updateNow () {
            let payload
            if (Cookies.has('jwt_header_payload')) payload = JSON.parse(atob(Cookies.get('jwt_header_payload').split('.')[1]))

            if (payload && payload.exp * 1000 - Date.now() >= AutoLogout.warningPeriod) {
                this.hide()
            } else if (AutoLogout.expirationTimestamp - this.now < this.intervalDuration) {
                this.clear()
                this.logout()
            } else {
                if ((AutoLogout.expirationTimestamp - this.now) < (1000 * 10)) {
                    this.runningOutOfTime = true
                }
                this.now = Date.now()
            }
        },
        clear () {
            this.intervalId = clearInterval(this.intervalId)
            this.now = null
            this.runningOutOfTime = false
        },
        refreshToken () {
            UserService.me()
        },
        logout: async function () {
            await onLogout(apolloClient)
            window.location.assign(this.$router.resolve({ name: 'auth-logout', params: { reason: 'auto-logout' } }).href)
        },
    },
}
</script>

<style lang="scss" scoped>
.auth-logout-dialog-wrapper {
    width: 40vw;
    min-width: $sizeAppMinWidth;
    max-width: 500px;

    text-align: center;
    font-variant-numeric: tabular-nums; // Unfortunately, most of the available web fonts do not have/offer this feature.
}

.running-out-of-time {
    color: var(--q-color-negative);
}
</style>
