import { Model } from '@/models'
import { StatementService } from '@/services'
import { StatementActions } from '@/enums'
import { QueueItemStatus } from '@/enums/graphql'
import { formatDate } from '@/helpers/date'
import { formatStatementRecipients } from '@/helpers/accounting'

export class Statement extends Model {
    /**
     * Create a Statement model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'consultingEntity',
            'recipients',
            'date_from',
            'date_until',
            'file_types',
            'include_attachments',
            'trigger_file_storage_item_sharing',
            'file_storage_item_shared_at',
            'currentQueueItem',
            'queueItems',
            'fileStorageItem',
        ], data)
    }

    static get service () { return StatementService }
    static action = StatementActions

    /**
     * Add custom actions.
     * @returns {Array}
     */
    get actions () {
        const actions = super.actions

        // Cancel creation
        if (this.currentQueueItem.status === QueueItemStatus.QUEUED) {
            actions.push({
                key: StatementActions.CANCEL_CREATION,
                item: this,
            })
        }

        // Share
        if (this.currentQueueItem.status === QueueItemStatus.DONE && this.file_storage_item_shared_at === null && this.recipients !== null && this.recipients.consultants.length > 0) {
            actions.push({
                key: StatementActions.SHARE,
                item: this,
            })
        }

        // Delete
        if ([QueueItemStatus.FAILED, QueueItemStatus.CANCELLED, QueueItemStatus.DONE].includes(this.currentQueueItem.status)) {
            actions.push({
                key: StatementActions.DELETE,
                item: this,
            })
        }

        return actions
    }

    /**
     * Create a new statement.
     * @param {Array} consultingEntityIds
     * @param {Object} statementInput - typeof StatementInput
     * @returns {Promise<*>}
     */
    static create ({ consultingEntityIds, statementInput }) {
        const variables = {
            consulting_entity_ids: consultingEntityIds,
            statement: statementInput,
        }
        return StatementService.create(variables)
    }

    /**
     * Stops already queued `Statements` from being created.
     * @param {Array} statementIds
     * @returns {Promise<*>}
     */
    static cancelCreation (statementIds) {
        return StatementService.cancelCreation(statementIds)
    }

    /**
     * Stops this already queued `Statements` from being created.
     * @returns {Promise<*>}
     */
    cancelCreation () {
        return Statement.cancelCreation([this.id])
    }

    /**
     * Shares the `Statements`.
     * @param {Array} statementIds
     * @returns {Promise<*>}
     */
    static share (statementIds) {
        return StatementService.share(statementIds)
    }

    /**
     * Delete this statement.
     * @param {Array} statementIds
     * @returns {Promise<*>}
     */
    static delete (statementIds) {
        return StatementService.delete(statementIds)
    }

    get formattedDateFrom () {
        return formatDate(this.date_from)
    }

    get formattedDateUntil () {
        return formatDate(this.date_until)
    }

    get formattedPeriod () {
        return `${this.formattedDateFrom}–${this.formattedDateUntil}`
    }

    get formattedFileStorageItemSharedAt () {
        return formatDate(this.file_storage_item_shared_at)
    }

    get formattedAcknowledgedAt () {
        return formatDate(this.fileStorageItem.acknowledged_at)
    }

    get hasRecipients () {
        return !!this.recipients?.consultants?.length > 0
    }

    formattedRecipients (showConsultingCompanyName = false, forceShowConsultingCompanyName = false, divider = ', ') {
        return formatStatementRecipients(this.recipients, showConsultingCompanyName, forceShowConsultingCompanyName, divider)
    }

    get isShared () {
        return this.file_storage_item_shared_at !== null
    }
}
