import gql from 'graphql-tag'

export const USER_CREATE_MUTATION = gql`
    mutation userCreate(
        $email: String!
        $role_ids: [ID!]!
    ) {
        userCreate(
            email: $email
            role_ids: $role_ids
        ) {
            status
        }
    }
`
