<template>
    <form
        data-test="form:correspondence-salutation-update"
        class="row q-col-gutter-y-sm"
        @submit.prevent="onFormSubmit"
        @keyup.esc="cancel"
    >
        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="formErrorMessage"
            type="negative"
            data-test="error:global-message"
            v-html="formErrorMessage"
        />
        <!-- eslint-enable -->

        <div class="col-xs-12">
            <form-builder item-key="salutation" autofocus />
        </div>

        <in-page-footer>
            <base-button
                :label="$t('common.term.back')"
                flat
                primary-button
                @click="$router.go(-1)"
            />
            <base-button
                data-test="btn:submit"
                type="submit"
                :label="$t('common.term.save')"
                :disable="formSubmitStatus"
                :loading="formSubmitStatus"
                primary-button
            />
        </in-page-footer>
    </form>
</template>

<script>
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import CORRESPONDENCE_SALUTATION_FIELDS from '@/forms/correspondence-salutation.fields'

export default {
    name: 'ProductProviderSettingsUpdateForm',
    mixins: [formBuilderMixin],
    props: {
        correspondenceSalutation: {
            type: Object,
            required: true,
        },
    },
    formBuilderSettings: {
        schema: CORRESPONDENCE_SALUTATION_FIELDS,
        fields: ['salutation'],
    },
    data () {
        return {
            formData: {
                salutation: this.correspondenceSalutation.salutation,
            },
        }
    },
    methods: {
        cancel () {
            this.$emit('cancel')
        },
    },
}
</script>
