<template>
    <div class="item-add-wrapper do-not-print">
        <a
            data-test="link:product-template-builder-item-add"
            class="product-template-builder-item-add"
            @click="onClick"
        >{{ $tc('forms.add-item', 1) }}</a>
    </div>
</template>

<script>
export default {
    name: 'ProductTemplateBuilderItemAdd',
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    methods: {
        onClick () {
            this.$emit('showOverlay', this.items)
        },
    },
}
</script>

<style lang="scss" scoped>
.item-add-wrapper {
    text-align: center;
}
</style>
