<template>
    <div>
        <base-table
            ref="table"
            :columns="columns"
            :fetch-objects-fn="AreaOfResponsibility.service.all"
            :additional-filters="additionalFilters"
            user-settings-base-path="AreaOfResponsibilityList"
            pagination-sort-by-default-key="name"
            data-test="table:area-of-responsibility-list"
            enable-visible-columns
            @row-dblclick="(evt, row) => contact.status === ContactStatus.ARCHIVED ? null : $refs.areaOfResponsibilityOverlay.open({ areaOfResponsibility: row })"
        >
            <template v-slot:body-cell-target_node="props">
                <q-td :props="props" :class="['contact-name-td', { 'additional-info': props.row.is_anonymous }]" data-test="text:contact-name">
                    <contact-name
                        v-if="!props.row.is_anonymous"
                        :contact="props.row.contactRelationship.getTargetNode(contact.id)"
                        type-icon
                        highlighted
                        comma-separated
                        consulting-company-name
                    />
                    <template v-else>–</template>
                </q-td>
            </template>

            <template v-slot:body-cell-email="props">
                <q-td :props="props" :class="['email-td', { 'additional-info': !props.row.emailAddress }]" data-test="text:email">
                    <a v-if="props.row.emailAddress" :href="`mailto:${props.row.emailAddress.email}`" target="_blank">{{ props.row.emailAddress.email }}</a>
                    <template v-else>–</template>
                </q-td>
            </template>

            <template v-slot:body-cell-phone_number="props">
                <q-td :props="props" :class="['phone-td', { 'additional-info': !props.row.phoneNumber }]" data-test="text:phone_number">
                    <template v-if="props.row.phoneNumber">{{ props.row.phoneNumber.phone_number }} <span class="additional-info">({{ props.row.phoneNumber.category.name }}<template v-if="props.row.phoneNumber.label"> / {{ props.row.phoneNumber.label.label }}</template>)</span></template>
                    <template v-else>–</template>
                </q-td>
            </template>

            <template v-slot:body-cell-actions="props">
                <q-td :props="props" auto-width>
                    <q-btn
                        v-if="contact.status !== ContactStatus.ARCHIVED"
                        class="overflow-hidden"
                        color="primary"
                        size="sm"
                        flat
                        round
                        icon="mib-navigation-menu-horizontal"
                        data-test="btn:tooltip-actions"
                    >
                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $t('common.term.more') }}</q-tooltip>

                        <q-menu anchor="top left" self="top right">
                            <div class="q-pl-xs q-pr-xs">
                                <q-btn
                                    color="primary"
                                    size="sm"
                                    flat
                                    round
                                    icon="mib-pencil"
                                    data-test="btn:tooltip-actions-update"
                                    @click="$refs.areaOfResponsibilityOverlay.open({ areaOfResponsibility: props.row })"
                                >
                                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.area-of-responsibility.list.update-area-of-responsibility', 1) }}</q-tooltip>
                                </q-btn>
                                <q-btn
                                    color="primary"
                                    size="sm"
                                    flat
                                    round
                                    icon="mib-bin"
                                    text-color="negative"
                                    data-test="btn:tooltip-actions-delete"
                                    @click="openDeleteDialog(props.row, $event)"
                                >
                                    <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.area-of-responsibility.list.delete-area-of-responsibility', 1) }}</q-tooltip>
                                </q-btn>
                            </div>
                        </q-menu>
                    </q-btn>
                </q-td>
            </template>
        </base-table>

        <!-- Create / update item -->
        <area-of-responsibility-overlay
            ref="areaOfResponsibilityOverlay"
            :contact="contact"
            @refetch:objects="refetchObjects"
        />

        <!-- Delete item -->
        <form-dialog
            ref="deleteDialog"
            :item="areaOfResponsibilityToDelete"
            :double-confirm="true"
            :dont-close-on-confirm="true"
            @confirm="handleDelete"
            @hide="resetAreaOfResponsibilityToDelete"
        >
            <template v-slot:title>{{ $tc('views.area-of-responsibility.list.delete-area-of-responsibility', 1) }}</template>
            <template v-slot:default>
                <i18n path="views.area-of-responsibility.list.delete-area-of-responsibility--confirm--temp-component-interpolation-count" tag="p">
                    <template v-slot:area><b v-if="areaOfResponsibilityToDelete">{{ areaOfResponsibilityToDelete.name }}</b></template>
                </i18n>
            </template>
        </form-dialog>

        <in-page-footer v-if="contact.status !== ContactStatus.ARCHIVED">
            <base-button
                :label="$tc('common.contact.relationship.area-of-responsibility.add-area-of-responsibility', 1)"
                icon="mib-itx-hierarchy-6-add"
                primary-button
                data-test="btn:create-area-of-responsibility"
                @click="$refs.areaOfResponsibilityOverlay.open({ areaOfResponsibility: null })"
            />
        </in-page-footer>
    </div>
</template>

<script>
import { AreaOfResponsibility } from '@/models/areaOfResponsibility'
import { ContactStatus } from '@/enums/graphql'

import BaseTable from '@/components/BaseTable'
import FormDialog from '@/components/form/FormDialog'
import ContactName from '@/components/contact/ContactName.vue'
import AreaOfResponsibilityOverlay from '@/components/areaOfResponsibility/AreaOfResponsibilityOverlay.vue'
import { EventBus } from '@/event-bus'

export default {
    name: 'AreaOfResponsibilityList',
    components: {
        BaseTable,
        FormDialog,
        ContactName,
        AreaOfResponsibilityOverlay,
    },
    props: {
        contact: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            ContactStatus,
            AreaOfResponsibility,
            areaOfResponsibilityToDelete: null,
            columns: [
                {
                    name: 'name',
                    label: this.$tc('views.contact-relationship.list.colum-title--area-of-responsibility', 1),
                    field: row => row.name,
                    align: 'left',
                    sortable: true,
                    required: true,
                    classes: 'text-bold',
                },
                {
                    name: 'description',
                    label: this.$tc('common.term.note', 2),
                    field: row => row.description,
                    align: 'left',
                    sortable: true,
                    classes: 'break-spaces',
                },
                {
                    name: 'target_node',
                    label: this.$tc('common.term.contact', 1),
                    align: 'left',
                    required: true,
                },
                {
                    name: 'email',
                    label: this.$tc('forms.field.label.contact-relationship.email-address', 1),
                    align: 'left',
                },
                {
                    name: 'phone_number',
                    label: this.$tc('forms.field.label.contact-relationship.phone-number', 1),
                    align: 'left',
                },
                {
                    name: 'actions',
                    label: this.$tc('common.term.action', 2),
                    align: 'left',
                    required: true,
                },
            ],
        }
    },
    computed: {
        additionalFilters () {
            const filters = {
                filterCompanyId: this.contact.id,
            }
            return filters
        },
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        onNewEntity () {
            this.$refs.areaOfResponsibilityOverlay.open({ areaOfResponsibility: null })
        },
        openDeleteDialog (areaOfResponsibility, $event) {
            $event.preventDefault();
            this.areaOfResponsibilityToDelete = areaOfResponsibility
            this.$refs.deleteDialog.open()
        },
        handleDelete () {
            this.areaOfResponsibilityToDelete.delete(this.contact.id)
                .then(() => {
                    this.$refs.deleteDialog.close()

                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.area-of-responsibility.area-of-responsibility-deleted-success', 1),
                    })

                    this.refetchObjects()
                }).catch(error => {
                    this.$refs.deleteDialog.showFormErrorMessage(error)
                    this.$refs.deleteDialog.resetFormSubmitStatus()
                })
        },
        resetAreaOfResponsibilityToDelete () {
            this.areaOfResponsibilityToDelete = null
        },
        refetchObjects () {
            this.$refs.table.fetchObjects()
        },
    },
}
</script>
