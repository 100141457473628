import gql from 'graphql-tag'

export const POSTING_ADD_ATTACHMENT_MUTATION = gql`
    mutation postingAddAttachment(
        $id: ID!
        $file: Upload!
    ) {
        postingAddAttachment(
            id: $id
            file: $file
        ) {
            id
            name
            size
            mime_type

            created_at
            updated_at
        }
    }
`
