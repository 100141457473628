<template>
    <page-wrapper>
        <page-header>
            <h1>{{ $tc('common.term.product', 2) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-12">
                <q-table
                    :data="products"
                    :columns="columns"
                    row-key="id"
                    :pagination.sync="pagination"
                    :loading="loading"
                    :rows-per-page-options="[10, 20, 50, 100]"
                    binary-state-sort
                    flat
                    square
                    dense
                    @request="onRequest"
                    @row-dblclick="(evt, row) => { $router.push({ name: 'admin-product-detail', params: { id: row.id } }) }"
                >
                    <template v-slot:top>
                        <q-tabs
                            dense
                            no-caps
                            inline-label
                            align="left"
                            class="q-mr-sm bg-background-secondary"
                            indicator-color="accent"
                        >
                            <q-route-tab :to="{ name: 'admin-product-list' }" :label="$t('common.status.active')" />
                            <q-route-tab :to="{ name: 'admin-product-list', params: { tab: 'archived' } }" :label="$t('common.status.archived')" />
                        </q-tabs>

                        <q-space />

                        <filter-input v-model="filter" @clear-filter="clearFilter" />
                    </template>

                    <template v-slot:body-cell-product_name="props">
                        <q-td :props="props">
                            <b>{{ props.row.name }}</b>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-status="props">
                        <q-td :props="props" auto-width>
                            <status-badge :status="props.row.status" />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-actions="props">
                        <q-td :props="props" auto-width>
                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-pencil"
                                :to="{ name: 'admin-product-detail', params: { id: props.row.id } }"
                            />
                        </q-td>
                    </template>
                </q-table>
            </div>
        </div>

        <in-page-footer>
            <base-button
                :to="{ name: 'admin-product-create-bulk' }"
                :label="$tc('common.product.add-product', 2)"
                icon="mib-shipment-add"
                primary-button
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import { UserSettings } from '@/helpers/user'
import { OrderDirection } from '@/enums/graphql'
import { Product } from '@/models/product'

import StatusBadge from '@/components/StatusBadge.vue'
import FilterInput from '@/components/FilterInput'

export default {
    name: 'ProductList',
    meta () {
        return {
            title: this.$tc('common.term.product', 2),
        }
    },
    components: {
        StatusBadge,
        FilterInput,
    },
    props: {
        tab: {
            type: String,
            default: 'active',
        },
    },
    data () {
        const userSettings = new UserSettings(this.$store.state.user)
        return {
            userSettings,
            activeTab: this.tab,
            filter: '',
            loading: false,
            pagination: {
                sortBy: 'product_name',
                page: 1,
                rowsPerPage: parseInt(userSettings.getItem('ProductList.pagination.rowsPerPage')) || 50,
                rowsNumber: 0,
            },
            columns: [
                {
                    name: 'product_name',
                    label: this.$tc('common.product.product-name', 1),
                    field: row => row.name,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'provider_display_name',
                    label: this.$tc('common.product.product-provider', 1),
                    field: row => row.providerName,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'template_name',
                    label: this.$tc('common.product.product-template'),
                    field: row => row.template.name,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'status',
                    label: this.$tc('common.term.status'),
                    field: row => row.status,
                    align: 'left',
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left' },
            ],
            products: [],
        }
    },
    watch: {
        'filter' () {
            this.fetchObjects()
        },
        '$route' (to) {
            this.activeTab = to.params.tab || 'active'
            this.fetchObjects()
        },
        'pagination.rowsPerPage' (rowsPerPage) {
            this.userSettings.setItem('ProductList.pagination.rowsPerPage', rowsPerPage)
        },
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
        this.fetchObjects()
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        clearFilter () {
            this.filter = ''
        },
        onRequest (props) {
            let { page, rowsPerPage, sortBy, descending } = props.pagination

            this.pagination.page = page
            this.pagination.rowsPerPage = rowsPerPage
            this.pagination.sortBy = sortBy
            this.pagination.descending = descending

            this.fetchObjects()
        },
        fetchObjects () {
            this.loading = true
            Product.objects.all({
                page: this.pagination.page,
                count: this.pagination.rowsPerPage,
                orderField: this.pagination.sortBy.toUpperCase(),
                orderDirection: (this.pagination.descending) ? OrderDirection.DESC : OrderDirection.ASC,
                filterQuery: this.filter,
                productStatus: this.activeTab.toUpperCase(),
            }).then(response => {
                this.pagination.rowsNumber = response.paginatorInfo.total
                this.loading = false
                this.products = response.data
            })
        },
        onNewEntity () {
            this.$router.push({ name: 'admin-product-create' })
        },
    },
}
</script>
