import gql from 'graphql-tag'

export const PREMIUM_INVOICE_DELETE_MUTATION = gql`
    mutation premiumInvoiceDelete(
        $id: ID!
    ) {
        premiumInvoiceDelete(
            id: $id
        ) {
            status
        }
    }
`
