<template>
    <div id="app" :data-app-sub-section="appSubSection" :style="`--current-app-sub-section-color: var(--color-section-${appSubSection})`">
        <!-- TODO: Do not render this for production (and/or remove completely after usage) -->
        <dev-header />
        <router-view />
        <auth-auto-logout-overlay />
    </div>
</template>

<script>
import { ShortcurtsManager } from '@/shortcuts'
import AuthAutoLogoutOverlay from '@/components/auth/AuthAutoLogoutOverlay'

export default {
    meta: {
        // TODO: Edit default title (used whenever no specific title is set).
        title: 'Default title',
        // TODO: We should check if it makes sense to append the system owner’s name to the title.
        titleTemplate: title => `${title} | MAX`,
        meta: {
            // TODO: Edit default description (used whenever no specific description is set).
            // This will very likely end up being the _only_ description for all pages/views as the description does not add any value except for when links to
            // the app are shared in some other tool… which very likely will only have access to the login view.
            description: { name: 'description', content: 'TODO: Default description' },
        },
    },
    components: {
        AuthAutoLogoutOverlay,
    },
    computed: {
        appSubSection () {
            return this.$route.meta.appSubSection || 'main'
        },
    },
    created () {
        // TODO: Check if this can be moved to main.js
        // Support for using custom icons with QIcon component <q-icon name="mi-… / mib-…" />
        // This includes support for all "icon" props of Quasar components
        this.$q.iconMapFn = (iconName) => {
            if (/^mi(-|l-|r-|b-)/.test(iconName) === true) {
                return {
                    cls: 'max-icon ' + iconName,
                }
            }
        }
        ShortcurtsManager.attachEvents()
    },
    beforeDestroy () {
        ShortcurtsManager.detachEvents()
    },
}
</script>
