<template>
    <form
        data-test="form:commission-distribution-rule-range"
        @submit.prevent="onFormSubmit"
    >
        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="formErrorMessage"
            type="negative"
            no-margin
            class="inline-error"
            data-test="error:global-message"
            v-html="formErrorMessage"
        />
        <!-- eslint-enable -->

        <markup-table-cell :column="{ name: 'amount_from' }" :class="{ 'has-error': formErrorMessage }" data-test="cell:amount-from">
            <div class="amount-from">{{ formData.amountFrom === '' || formData.amountFrom === null ? '-∞' : formatCurrency(formData.amountFrom) }}</div>
        </markup-table-cell>

        <markup-table-cell :column="{ name: 'amount_to' }" :class="{ 'has-error': formErrorMessage }">
            <form-builder item-key="amountTo" prefix="CHF" />
        </markup-table-cell>

        <markup-table-cell :column="{ name: 'payout_ratio' }" :class="{ 'has-error': formErrorMessage }">
            <form-builder item-key="payoutRatio" />
        </markup-table-cell>

        <markup-table-cell :column="{ name: 'cancellation_reserve' }" :class="{ 'has-error': formErrorMessage }">
            <form-builder item-key="cancellationReserve" />
        </markup-table-cell>

        <markup-table-cell :column="{ name: 'actions' }" :class="{ 'has-error': formErrorMessage }">
            <base-button
                type="submit"
                data-test="btn:submit"
                :label="$t('common.term.save')"
                primary-button
                :disable="formSubmitStatus"
                :loading="formSubmitStatus"
            />

            <base-button
                v-if="currentMode === ViewMode.UPDATE"
                class="q-ml-sm"
                data-test="btn:cancel"
                :label="$t('common.term.cancel')"
                primary-button
                flat
                :disable="formSubmitStatus"
                @click="$emit('cancel')"
            />
        </markup-table-cell>
    </form>
</template>

<script>
import { ViewMode } from '@/enums'
import { formatCurrency } from '@/helpers/number'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import MarkupTableCell from '@/components/MarkupTableCell'
import COMMISSION_DISTRIBUTION_RULE_RANGE_FORM from '@/forms/commissionDistributionRuleRange.form'
import { EventBus } from '@/event-bus'
import kebabCase from 'lodash.kebabcase'

export default {
    name: 'CommissionDistributionRuleRangeForm',
    components: {
        MarkupTableCell,
    },
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: COMMISSION_DISTRIBUTION_RULE_RANGE_FORM,
    },
    props: {
        currentAmountFrom: {
            type: Number,
            default: null,
        },
        commissionDistributionRuleRange: {
            type: Object,
            required: false,
            default () {
                return undefined
            },
        },
        disableMountedAutofocus: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        const data = {
            ViewMode,
            currentMode: this.commissionDistributionRuleRange ? ViewMode.UPDATE : ViewMode.CREATE,
            formData: {},
        }

        if (data.currentMode === ViewMode.CREATE && this.currentAmountFrom !== null) {
            Object.assign(data.formData, {
                amountFrom: this.currentAmountFrom,
            })
        }

        if (data.currentMode === ViewMode.UPDATE) this.updateFormData(data.formData)

        return data
    },
    watch: {
        currentAmountFrom (newVal) {
            if (newVal !== null) this.formData.amountFrom = newVal
        },
        commissionDistributionRuleRange: {
            handler (newVal) {
                if (newVal) this.updateFormData(this.formData)
            },
            deep: true,
        },
        '$v.$anyDirty' (newVal) {
            if (newVal) EventBus.$emit('commissionDistributionRuleSet:submit-rule-range-forms-any-dirty-state', newVal)
        },
    },
    mounted () {
        if (this.currentMode === ViewMode.CREATE && !this.disableMountedAutofocus) this.setFocus('amountTo')
    },
    methods: {
        resetInitialFormData () {
            this.updateFormData(this.formData)
        },
        updateFormData (targetObject) {
            Object.assign(targetObject, {
                amountFrom: this.commissionDistributionRuleRange.amount_from,
                amountTo: this.commissionDistributionRuleRange.amount_to,
                payoutRatio: this.commissionDistributionRuleRange.payout_ratio,
                cancellationReserve: this.commissionDistributionRuleRange.cancellation_reserve,
            })
        },
        formatCurrency (...args) {
            return formatCurrency(...args)
        },
        setFocus (fieldKey) {
            const field = this.$el.querySelector(`input[data-test="input:${kebabCase(fieldKey)}"]`)
            this.$nextTick(() => {
                if (field) field.focus()
            })
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .amount-from {
    display: flex;
    flex-direction: column;
    place-content: center;
    height: 100%;

    text-align: right;
}
</style>
