<template>
    <status-badge v-if="contract.status === ContractStatus.TERMINATED" :status="contract.status" :align="align" />
    <status-badge v-else-if="contract.currentCancellation" :status="`cancellation-${contract.currentCancellation.status}`" :align="align" />
    <span v-else>
        <status-badge :status="contract.status" :default-translation-base-path="defaultTranslationBasePath" :align="align" />
        <status-badge v-if="contract.currentContractInformation?.productFields?.suspended?.value" status="suspended" :align="align" />
    </span>
</template>

<script>
import { ContractStatus } from '@/enums/graphql'
import StatusBadge from '@/components/StatusBadge'

export default {
    name: 'ContractStatusBadge',
    components: {
        StatusBadge,
    },
    props: {
        align: {
            type: String,
            default: 'middle',
        },
        contract: {
            type: Object,
            required: true,
        },
        defaultTranslationBasePath: {
            type: String,
            default: 'common.status', // i18n translation base path (folder)
        },
    },
    data () {
        return {
            ContractStatus,
        }
    },
}
</script>
