import { formatCurrency } from '@/helpers/number'
import { Model } from '@/models'

export class CommissionDistributionRuleRange extends Model {
    /**
     * Create a CommissionDistributionRuleRange model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'rule',
            'amount_from',
            'amount_to',
            'payout_ratio',
            'cancellation_reserve',
        ], data)
    }

    get formattedAmountFrom () {
        return this.amount_from !== null ? formatCurrency(parseFloat(this.amount_from)) : '-∞'
    }

    get formattedAmountTo () {
        return this.amount_to !== null ? formatCurrency(parseFloat(this.amount_to)) : '∞'
    }

    get formattedPayoutRatio () {
        // Typographically correct would be to use a four-per-em space (U+2005) between the number and the % sign. / Also known as "mid space". One fourth of an em wide.
        return `${this.payout_ratio}\u2005%`
    }

    get formattedCancellationReserve () {
        // Typographically correct would be to use a four-per-em space (U+2005) between the number and the % sign. / Also known as "mid space". One fourth of an em wide.
        return `${this.cancellation_reserve}\u2005%`
    }
}
