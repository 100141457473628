import gql from 'graphql-tag'

export const CORRESPONDENCE_EMAIL_ADD_ATTACHMENT_MUTATION = gql`
    mutation correspondenceEmailAddAttachment(
        $correspondenceEmailId: ID!
        $file: Upload!
    ) {
        correspondenceEmailAddAttachment(
            correspondence_email_id: $correspondenceEmailId
            file: $file
        ) {
            id
            name
            size
            mime_type

            created_at
            updated_at
        }
    }
`
