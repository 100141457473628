<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.accounting.account-statement.account-statement', 2)" :to="{ name: 'accounting-account-statements' }" />
                <q-breadcrumbs-el :label="$tc('common.accounting.account-statement.create-statement', 1)" :to="{ name: 'accounting-account-statement-create' }" />
            </template>

            <h1>{{ $tc('common.accounting.account-statement.create-statement', 1) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <account-statement-report-form
                    ref="accountStatementReportForm"
                    @post-handle-form-data="handleSubmit"
                />
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { AccountStatementReport } from '@/models/accountStatementReport'
import { parseFormDataToInput }  from '@/helpers/accountStatementReport'
import AccountStatementReportForm  from '@/components/accounting/accountStatementReport/AccountStatementReportForm.vue'

export default {
    name: 'AccountStatementReportCreate',
    meta () {
        return {
            title: this.$tc('common.accounting.account-statement.create-statement', 1),
        }
    },
    components: {
        AccountStatementReportForm,
    },
    methods: {
        handleSubmit (formData) {
            const accountStatementReportInput = parseFormDataToInput(formData)

            AccountStatementReport.create(accountStatementReportInput).then(() => {
                this.$router.replace({ name: 'accounting-account-statements' })
                this.$q.notify({
                    type: 'positive',
                    message: this.$tc('common.notifications.accounting.account-statement-created-success', 1),
                })
            }).catch(error => {
                this.$refs.accountStatementReportForm.showFormErrorMessage(error)
                this.$refs.accountStatementReportForm.resetFormSubmitStatus()
            })
        },
    },
}
</script>
