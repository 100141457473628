<template>
    <form-builder-next
        v-slot="{ formBuilder }"
        :form="AccountForm"
        :initial-data="initialData"
        in-page-footer
        :submit-button-label="account ? $t('common.term.save') : $tc('views.accounting.account.add-account', 1)"
        @cancel="$emit('cancel')"
        @submit="formData => $emit('submit', formData)"
    >
        <div class="row q-col-gutter-md">
            <div class="col-xs-12 col-sm-4 col-lg-2">
                <form-builder-next :item="formBuilder.form.getItem('number')" autofocus />
            </div>
            <div class="col-xs-12 col-sm-8 col-lg-7">
                <form-builder-next :item="formBuilder.form.getItem('name')" />
            </div>
            <div class="col-xs-12 col-lg-3">
                <!-- TODO: Disable when certain conditions (tbd) are met (e.g. when assigned as currentAccount or the like) -->
                <form-builder-next :item="formBuilder.form.getItem('type')" />
            </div>
            <div class="col-xs-12">
                <form-builder-next :item="formBuilder.form.getItem('description')" />
            </div>
        </div>
    </form-builder-next>
</template>

<script>
import { ref } from 'vue'
import { AccountForm } from '@/forms/account'

export default {
    name: 'AccountForm',
    props: {
        account: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
    setup (props) {
        const formComponent = ref(null)

        let initialData = null
        if (props.account) {
            initialData = {
                type: props.account.type,
                name: props.account.name,
                number: props.account.number,
                description: props.account.description,
            }
        }

        return {
            formComponent,
            AccountForm,
            initialData,
        }
    },
}
</script>
