import { EditorContentSchemaType } from '@max/tiptap-extensions'
import { EditorDisplayMode, SmartObjectTreeDisplayMode } from '@/enums'

export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'subject',
            label: 'common.term.subject',
            inputType: 'text',
            validators: [
                { type: 'required' },
                { type: 'maxLength', 'params': { 'max': 200 }  },
            ],
        },
        {
            type: 'field',
            key: 'dueDate',
            label: 'common.term.due-on',
            inputType: 'calendar',
            validators: [
                { type: 'date' },
            ],
        },
        {
            type: 'field',
            key: 'assignee',
            label: 'forms.field.label.task.assignee',
            inputType: 'consultant',
        },
        {
            type: 'field',
            key: 'isPrivate',
            label: 'common.task.visibility-private--private-task',
            infoTitle: 'common.task.visibility',
            infoText: 'common.task.visibility--task-overlay--description',
            inputType: 'toggle',
        },
        {
            type: 'field',
            key: 'description',
            label: 'common.term.description',
            inputType: 'editor',
            extra_attributes: {
                schemaType: EditorContentSchemaType.SIMPLE_RICH_TEXT,
                displayMode: EditorDisplayMode.COMPACT,
                smartObjectTreeDisplayMode: SmartObjectTreeDisplayMode.NONE,
            },
        },
    ],
}
