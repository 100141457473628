import { apolloClient } from '@/apollo/client'

import { CONTRACT_QUERY, buildContractQuery } from '@/graphql/contract/contract'
import { CONTRACTS_QUERY, buildContractsQuery } from '@/graphql/contract/contracts'
import { CONTRACT_CREATE_MUTATION } from '@/graphql/contract/contractCreate'
import { CONTRACT_UPDATE_MUTATION } from '@/graphql/contract/contractUpdate'
import { CONTRACT_CHANGE_STATUS_MUTATION } from '@/graphql/contract/contractChangeStatus'
import { CONTRACT_DELETE_MUTATION } from '@/graphql/contract/contractDelete'
import { CONTRACT_CONTRACT_NUMBER_UPDATE_MUTATION } from '@/graphql/contract/contractContractNumberUpdate'
import { CONTRACT_CONTRACT_NUMBER_DELETE_MUTATION } from '@/graphql/contract/contractContractNumberDelete'
import { CONTRACT_PRODUCT_TEMPLATE_VERSION_UPDATE_MUTATION } from '@/graphql/contract/contractProductTemplateVersionUpdate'
import { CONTRACT_COMMISSION_POSTINGS } from '@/graphql/contract/contractCommissionPostings'

export const ContractService = {
    get (id, fields) {
        const QUERY = (typeof fields !== 'undefined') ? buildContractQuery(fields) : CONTRACT_QUERY
        return apolloClient.query({
            query: QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.contract)
    },
    all (variables, fields) {
        const QUERY = (typeof fields !== 'undefined') ? buildContractsQuery(fields) : CONTRACTS_QUERY
        return apolloClient.query({
            query: QUERY,
            variables,
        }).then(response => response.data.contracts)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: CONTRACT_CREATE_MUTATION,
            variables,
        }).then(response => response.data.contractCreate)
    },
    update (variables) {
        return apolloClient.mutate({
            mutation: CONTRACT_UPDATE_MUTATION,
            variables,
        }).then(response => response.data.contractUpdate)
    },
    changeStatus (variables) {
        return apolloClient.mutate({
            mutation: CONTRACT_CHANGE_STATUS_MUTATION,
            variables,
        }).then(response => response.data.contractChangeStatus)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: CONTRACT_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.contractDelete)
    },
    updateContractNumber (id, contractNumber) {
        return apolloClient.mutate({
            mutation: CONTRACT_CONTRACT_NUMBER_UPDATE_MUTATION,
            variables: {
                contractId: id,
                contractNumber: contractNumber,
            },
        }).then(response => response.data.contractContractNumberUpdate)
    },
    deleteContractNumber (contractNumberId) {
        return apolloClient.mutate({
            mutation: CONTRACT_CONTRACT_NUMBER_DELETE_MUTATION,
            variables: {
                id: contractNumberId,
            },
        }).then(response => response.data.contractContractNumberDelete)
    },
    updateToProductTemplateVersion (id, productTemplateVersion) {
        return apolloClient.mutate({
            mutation: CONTRACT_PRODUCT_TEMPLATE_VERSION_UPDATE_MUTATION,
            variables: {
                id: id,
                productTemplateVersion: productTemplateVersion,
            },
        }).then(response => response.data.contractProductTemplateVersionUpdate)
    },
    contractCommissionPostings(variables) {
        return apolloClient.query({
            query: CONTRACT_COMMISSION_POSTINGS,
            variables,
        }).then(response => response.data.contractCommissionPostings)
    },
}
