<template>
    <div
        :class="{ 'active': isActive }"
        class="dropzone"
        @dragenter="onDragEnter"
        @dragover="onDragOver"
        @dragleave="onDragLeave"
        @drop="onDrop"
    >
        <div v-if="isActive" class="dropzone-indicator" :class="`dropzone-type-${zoneType}`">
            <template v-if="zoneType === 'browser'">
                <illustration name="cloud-upload-in-progress" class="indicator-illustration" />
                <p class="indicator-text">{{ $tc('common.file.upload-file', 2) }}</p>
            </template>
            <template v-else>
                <q-icon class="indicator-icon" name="mib-common-file-text-add" />
            </template>
        </div>

        <slot />
    </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import { getAllFiles, filterFiles, prepareFileUploadWrappers } from '@/helpers/file'

export default {
    name: 'ConsultingFileUploadDropzone',
    props: {
        targetObjectType: {
            type: String,
            required: true,
        },
        targetObjectId: {
            type: String,
            required: true,
        },
        targetObjectConsultants: {
            type: Array,
        },
        fileTypeId: {
            type: String,
        },
        meta: {
            type: Object,
        },
        zoneType: {
            type: String,
            default: 'filter',
        },
    },
    data () {
        return {
            isActive: false,
            deactivateTimeoutId: null,
        }
    },
    methods: {
        onDragEnter (event) {
            event.preventDefault()
            event.stopPropagation()
            this.activate()
        },
        onDragOver (event) {
            event.preventDefault()
            event.stopPropagation()
            this.activate()
        },
        onDragLeave (event) {
            event.preventDefault()
            event.stopPropagation()
            this.deactivateTimeoutId = window.setTimeout(this.deactivate, 5)
        },
        async onDrop (event) {
            event.preventDefault()
            event.stopPropagation()
            this.deactivate()
            const files = await getAllFiles(event.dataTransfer.items)
            const filteredFiles = filterFiles(files)
            await this.emitFiles(filteredFiles)
        },
        activate () {
            this.deactivateTimeoutId = window.clearTimeout(this.deactivateTimeoutId)
            this.isActive = true
        },
        deactivate () {
            this.isActive = false
        },
        async emitFiles (files) {
            if (files.length) {
                const fileUploadWrappers = await prepareFileUploadWrappers(files, {
                    targetObjectType: this.targetObjectType,
                    targetObjectId: this.targetObjectId,
                    targetObjectConsultants: this.targetObjectConsultants,
                    fileTypeId: this.fileTypeId,
                    meta: this.meta,
                })
                EventBus.$emit('consultingFileUpload:addToStaging', fileUploadWrappers)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@mixin dropZoneOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    pointer-events: none; // This is important
}

.dropzone {
    position: relative;
}

.dropzone-indicator {
    @include dropZoneOverlay;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-end;
    justify-content: center;
    padding: 0 $sizeSpacingSm;
    z-index: 100;
    overflow: hidden;

    color: var(--q-color-primary-dark);
    text-align: center;
    font-weight: bold;

    &::before {
        @include dropZoneOverlay;
        content: "";
        z-index: -1;

        background-color: var(--q-color-primary);
        opacity: 0.2;
    }

    &.dropzone-type-browser {
        align-items: center;

        border: 2px dashed var(--q-color-primary);
    }

    .indicator-icon {
        font-size: 1.3em;
    }

    .indicator-text {
        margin-top: -1em;
        padding: $sizeSpacingXs $sizeSpacingSm;

        background: var(--color-illustration-primary-lighter);

        color: var(--color-illustration-primary);
        font-size: 1.5em;
        font-weight: 900;
    }
}
</style>
