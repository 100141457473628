import { apolloClient } from '@/apollo/client'

import { POSTING_TYPE_QUERY } from '@/graphql/postingType/postingType'
import { POSTING_TYPES_QUERY } from '@/graphql/postingType/postingTypes'
import { POSTING_TYPE_CREATE_MUTATION } from '@/graphql/postingType/postingTypeCreate'
import { POSTING_TYPE_UPDATE_MUTATION } from '@/graphql/postingType/postingTypeUpdate'
import { POSTING_TYPE_DELETE_MUTATION } from '@/graphql/postingType/postingTypeDelete'

export const PostingTypeService = {
    get (id) {
        return apolloClient.query({
            query: POSTING_TYPE_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.postingType)
    },
    all (variables) {
        return apolloClient.query({
            query: POSTING_TYPES_QUERY,
            variables,
        }).then(response => response.data.postingTypes)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: POSTING_TYPE_CREATE_MUTATION,
            variables,
        }).then(response => response.data.postingTypeCreate)
    },
    update (id, postingType) {
        return apolloClient.mutate({
            mutation: POSTING_TYPE_UPDATE_MUTATION,
            variables: {
                id: id,
                postingType: postingType,
            },
        }).then(response => response.data.postingTypeUpdate)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: POSTING_TYPE_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.postingTypeDelete)
    },
}
