<template>
    <page-loading-indicator v-if="!state.correspondenceLetter" :number-of-breadcrumb-elements="3" subtitle />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="tc('common.correspondence.letter.letter', 2)" :to="{ name: 'correspondence-letters' }" />
                <q-breadcrumbs-el :label="tc('common.correspondence.letter.status--completed', 2)" :to="{ name: 'correspondence-letter-list', params: { statusGroup: 'completed' } }" />
                <q-breadcrumbs-el :to="{ name: 'correspondence-letter-summary', params: { id: state.correspondenceLetter.id } }">
                    {{ state.correspondenceLetter.name }}
                    <status-badge :status="state.correspondenceLetter.status.toLowerCase()" />
                </q-breadcrumbs-el>
            </template>

            <h1>{{ tc('common.correspondence.letter.letter-summary', 1) }} <span class="subtitle">{{ state.correspondenceLetter.name }}</span></h1>

            <template v-slot:actions>
                <actions
                    v-if="state.correspondenceLetter.actions"
                    :actions="state.correspondenceLetter.actions"
                    :number-of-next-best-actions="0"
                    @click="handleActions"
                />

                <!-- Delete Account -->
                <form-dialog
                    ref="deleteDialog"
                    :form="deleteDialogForm"
                    double-confirm
                    @submit="handleDelete"
                >
                    <template v-slot:title>{{ tc('common.correspondence.letter.delete-letter', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.correspondence.letter.delete-letter--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:name><b>{{ state.correspondenceLetter.name }}</b></template>
                        </i18n>
                        <info-box>
                            <p>{{ $tc('common.correspondence.letter.delete-letter--confirm--consulting-file--info', state.correspondenceLetter.target_object_ids.length, { targetObjectTypeReference: $tc(`common.correspondence.letter.delete-letter--confirm--consulting-file--info--target-object-type--${kebabCase(state.correspondenceLetter.target_object_type)}`, state.correspondenceLetter.target_object_ids.length) }) }}</p>
                        </info-box>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <div class="row q-col-gutter-md">
            <div class="col-xs-12 col-lg-10 col-xl-8 full-width-block">
                <info-box
                    type="secondary"
                    :inline-actions="$q.screen.width > 1300"
                >
                    <i18n path="common.correspondence.letter.letter-summary--text" tag="p">
                        <template v-slot:numberOfRecipients>
                            <b>{{ tc('common.correspondence.letter.letter-summary--text--part--number-of-recipients', state.correspondenceLetter.target_object_ids.length) }}</b>
                        </template>
                        <template v-slot:correspondenceLanguage>{{ state.correspondenceLetter.language.name }}</template>
                        <template v-slot:differentiateFormality>{{ $t(`common.correspondence.letter.letter-summary--text--part--differentiate-formality--${state.correspondenceLetter.differentiate_formality.toString()}`) }}</template>
                    </i18n>

                    <!-- TODO: Remove when bulk correspondence has been implemented. -->
                    <template v-slot:action>
                        <base-button
                            v-if="state.processedItems[0]?.targetObject"
                            data-test="btn:go-to-entity"
                            :label="tc(`common.${state.correspondenceLetter.target_object_type.toLowerCase()}.go-to-${state.correspondenceLetter.target_object_type.toLowerCase()}`, 1)"
                            primary-button
                            :to="{ name: `${state.correspondenceLetter.target_object_type.toLowerCase()}-detail`, params: { id: state.processedItems[0]?.targetObject.id, contactId: state.processedItems[0]?.targetObject.customer?.id, tab: 'documents' } }"
                        />
                        <base-button
                            v-else
                            :label="tc(`common.${state.correspondenceLetter.target_object_type.toLowerCase()}.go-to-${state.correspondenceLetter.target_object_type.toLowerCase()}`, 1)"
                            primary-button
                            disabled
                        />
                    </template>
                </info-box>

                <info-box
                    v-if="state.correspondenceLetter.printPDF"
                    type="positive"
                    :inline-actions="$q.screen.width > 1300"
                    no-margin
                >
                    {{ tc('views.correspondence.letter.summary.print-pdf--has-been-created', 1) }} {{ state.correspondenceLetter.printPDF.name }} <span class="additional-info">({{ state.correspondenceLetter.printPDF.formattedFileSize }})</span>

                    <template v-slot:action>
                        <base-button
                            :label="tc('common.correspondence.letter.print-pdf--download-print-pdf', 1)"
                            primary-button
                            :disabled="!state.correspondenceLetter.printPDF"
                            @click="state.correspondenceLetter.printPDF.download()"
                        />
                    </template>
                </info-box>
                <info-box v-else type="negative" no-margin>
                    {{ tc('views.correspondence.letter.summary.print-pdf--not-available', 1) }}
                </info-box>
            </div>
        </div>

        <q-separator />

        <div class="row">
            <div class="col-12">
                <h2>{{ tc('common.correspondence.letter.created-letter', 2) }}</h2>
                <base-table
                    :columns="state.columns"
                    :fetch-objects-fn="CorrespondenceService.items.processed"
                    :additional-filters="additionalFilters"
                    enable-visible-columns
                    user-settings-base-path="SummaryProcessedCorrespondenceLetterList"
                    pagination-sort-by-default-key="updated_at"
                    :pagination-initial-order-direction-descending="true"
                    :show-filter="false"
                    @update:items="setProcessedItems"
                >
                    <template v-slot:body-cell-status="slotProps">
                        <q-td :props="slotProps">
                            <!-- TODO @TFU: Please verify that these work as expected. -->
                            <status-badge
                                v-if="slotProps.row.status_message"
                                :status="slotProps.row.status"
                                default-translation-base-path="common.status.correspondence-item-status"
                                :info-text="t(`common.status.code.${slotProps.row.status_message.type}.${slotProps.row.status_message.code.replace('_', '.')}`)"
                            />
                            <status-badge
                                v-else
                                :status="slotProps.row.status"
                                default-translation-base-path="common.status.correspondence-item-status"
                            />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-actions="slotProps">
                        <q-td :props="slotProps" auto-width>
                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-navigation-next-alternate"
                                :to="slotProps.row.targetObject ? { name: `${getTargetObjectType(slotProps.row.targetObject).toLowerCase()}-detail`, params: { id: slotProps.row.targetObject.id, contactId: slotProps.row.targetObject.customer?.id, tab: 'documents' } } : null"
                                :disable="!slotProps.row.targetObject"
                            >
                                <q-tooltip v-if="slotProps.row.targetObject" :delay="1000" :offset="[0, 10]">{{ $tc(`common.${getTargetObjectType(slotProps.row.targetObject).toLowerCase()}.go-to-${getTargetObjectType(slotProps.row.targetObject).toLowerCase()}`, 1) }}</q-tooltip>
                                <q-tooltip v-else :delay="1000" :offset="[0, 10]">{{ $t('common.target-object.target-object--no-target-object-available') }}</q-tooltip>
                            </q-btn>
                        </q-td>
                    </template>
                </base-table>
            </div>
        </div>

        <in-page-footer>
            <base-button
                data-test="btn:back"
                :label="t('common.term.back')"
                flat
                primary-button
                :to="{ name: 'correspondence-letter-list', params: { statusGroup: 'completed' } }"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { CorrespondenceService } from '@/services/correspondence'
import { CorrespondenceItemStatus } from '@/enums/graphql'
import { CorrespondenceLetter } from '@/models/correspondenceLetter'
import BaseTable from '@/components/BaseTable'
import StatusBadge from '@/components/StatusBadge.vue'
import FormDialog from '@/libs/form/components/FormDialog'
import Actions from '@/components/Actions.vue'
import { computed, onBeforeMount, reactive, ref } from 'vue'
import { useQuasar } from '@/composables/quasar'
import { useRouter } from '@/composables/router'
import { useI18n } from '@/composables/i18n'
import { Form, FormField } from '@/libs/form'
import { kebabCase } from 'lodash'

export default {
    name: 'CorrespondenceLetterSummary',
    meta () {
        return {
            title: `${this.$tc('common.correspondence.letter.letter-summary', 1)} (${this.$tc('common.correspondence.letter.letter', 1)})`,
        }
    },
    components: {
        Actions,
        FormDialog,
        BaseTable,
        StatusBadge,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup (props) {
        // Composables
        const $q = useQuasar()
        const router = useRouter()
        const { t, tc } = useI18n()

        // Data
        const state = reactive({
            correspondenceLetter: null,
            processedItems: [],
            columns: [
                {
                    name: 'subject',
                    label: tc('common.correspondence.letter.subject', 1),
                    field: row => row.subject,
                    sortable: true,
                    align: 'left',
                    required: true,
                },
                {
                    name: 'recipient_address',
                    label: tc('common.term.recipient', 1),
                    field: row => row.recipient_address,
                    align: 'left',
                    required: true,
                },
                {
                    name: 'target_object_type',
                    label: tc('common.correspondence.target-object.target-object-type', 1),
                    field: row => (row.targetObject) ? tc(`common.term.${getTargetObjectType(row.targetObject).toLowerCase()}`, 1) : '—',
                    align: 'left',
                    hideInitially: true,
                },
                {
                    name: 'language_name',
                    label: tc('common.term.language'),
                    field: row => row.language.name,
                    align: 'left',
                    hideInitially: true,
                },
                {
                    name: 'creator_name',
                    label: t('common.term.created-by'),
                    // TODO improvement @TFU: Extend available info with consulting company name
                    field: row => (row.creator) ? row.creator.getContactName({ consultingCompanyName: !row.creator.worksForUserConsultingCompany }) : '—', // TODO improvement @MTR: Maybe display a tooltip with possible reasons why?
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'sender_name',
                    label: tc('common.term.sender', 1),
                    // TODO improvement @TFU: Extend available info with consulting company name
                    field: row => (row.sender) ? row.sender.getContactName({ consultingCompanyName: !row.sender.worksForUserConsultingCompany }) : '—', // TODO improvement @MTR: Maybe display a tooltip with possible reasons why?
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'updated_at',
                    label: tc('common.term.updated--last-updated', 1),
                    field: row => row.formattedUpdatedAt,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'status',
                    label: tc('common.term.status'),
                    field: row => row.status,
                    align: 'left',
                    required: true,
                },
                { name: 'actions', label: tc('common.term.action', 2), align: 'left', required: true },
            ],
        })

        // Template refs
        const deleteDialog = ref(null)

        // Computed
        const additionalFilters = computed(() => {
            return {
                filterCorrespondenceItemId: props.id,
            }
        })

        // Form
        const deleteDialogForm = new Form()

        // Functions
        function fetchObject () {
            // TODO improvement @TFU: Redirect if item with given ID should not have this view. (Due to its status.)
            CorrespondenceLetter.objects.get(props.id).then(correspondenceLetter => {
                state.correspondenceLetter = correspondenceLetter

                // Moved here to be able to access info from `state.correspondenceLetter`
                deleteDialogForm.addField(new FormField('deleteConsultingFile',{
                    label: tc('common.correspondence.letter.delete-letter--confirm--delete-consulting-file', state.correspondenceLetter.target_object_ids.length, { targetObjectTypeReference: tc(`common.correspondence.letter.delete-letter--confirm--consulting-file--info--target-object-type--${kebabCase(state.correspondenceLetter.target_object_type)}`, state.correspondenceLetter.target_object_ids.length) }),
                    type: 'checkbox',
                }))
            })
        }
        function setProcessedItems (items) {
            state.processedItems = items
        }
        function handleActions (action) {
            switch (action.key) {
                case CorrespondenceLetter.action.DELETE:
                    deleteDialog.value.open()
                    break
            }
        }
        function handleDelete (formData) {
            if (state.processedItems.length) {
                state.processedItems.at(0).delete(formData.deleteConsultingFile) // Temporary solution. Needs to be refactored once we implement bulk.
                    .then(({ status }) => {
                        if (status) {
                            state.correspondenceLetter.delete()
                                .then(() => {
                                    deleteDialog.value.close()
                                    router.replace({ name: 'correspondence-letter-list', params: { statusGroup: state.correspondenceLetter.status === CorrespondenceItemStatus.DONE ? 'completed' : 'pending' } })
                                    $q.notify({
                                        type: 'positive',
                                        message: tc('common.notifications.correspondence.correspondence-letter-deleted-success', 1),
                                    })
                                })
                                .catch(error => {
                                    deleteDialog.value.showFormErrorMessage(error)
                                    deleteDialog.value.resetFormSubmitStatus()
                                    $q.notify({
                                        type: 'negative',
                                        message: tc('common.term.error', 1),
                                        caption: tc('common.notifications.correspondence.correspondence-letter-deleted-error', 1),
                                    })
                                })
                        }
                    })
                    .catch(error => {
                        deleteDialog.value.showFormErrorMessage(error)
                        deleteDialog.value.resetFormSubmitStatus()
                        $q.notify({
                            type: 'negative',
                            message: tc('common.term.error', 1),
                            caption: tc('common.notifications.correspondence.correspondence-letter-deleted-error', 1),
                        })
                    })
            }
        }

        function getTargetObjectType (targetObject) {
            return ['Person', 'Company'].includes(targetObject.__typename) ? 'Contact' : targetObject.__typename
        }

        // Lifecycle hooks
        onBeforeMount(() => {
            fetchObject()
        })

        return {
            // Static
            CorrespondenceService,
            CorrespondenceItemStatus,

            // Composables
            t,
            tc,

            // Data
            state,

            // Template refs
            deleteDialog,

            // Computed
            additionalFilters,

            // Form
            deleteDialogForm,

            // Functions
            setProcessedItems,
            handleActions,
            handleDelete,
            kebabCase,
            getTargetObjectType,
        }
    },
}
</script>

<style lang="scss" scoped>
.address-preview {
    position: relative;
    margin: $sizeSpacingMd $sizeSpacingLg;

    address {
        padding: $sizeSpacingMd;
        display: block;
        max-width: 100mm;
        min-height: 45mm;

        border: 1px solid var(--color-border-primary);
        border-radius: $sizeSpacingSm !important; // !important necessary
        background: var(--color-background-primary);
    }
}
</style>
