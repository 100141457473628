import gql from 'graphql-tag'

const correspondenceItemFields = `
    id
    creator {
        id
        contact_number
        first_name
        last_name
    }
    status
    name
    target_object_type
    language {
        id
        name
    }
    differentiate_formality
    created_at
    updated_at
`

export const CORRESPONDENCE_ITEMS_QUERY = gql`
    query correspondenceItems(
        $page: Int = 1
        $count: Int = 1000
        $orderField: CorrespondenceItemOrderField = UPDATED_AT
        $orderDirection: OrderDirection = DESC
        $filterQuery: String
        $filterStatus: [CorrespondenceItemStatus!]
        $filterTargetObjectType: [CorrespondenceItemTargetObjectType!]
        $filterLanguageId: ID
        $filterCorrespondenceItemType: [CorrespondenceItemType!]
    ) {
        correspondenceItems(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                query: $filterQuery
                status: $filterStatus
                target_object_type: $filterTargetObjectType
                language_id: $filterLanguageId
                correspondence_item_type: $filterCorrespondenceItemType
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                ... on CorrespondenceLetter {
                    ${correspondenceItemFields}
                }
                ... on CorrespondenceEmail {
                    ${correspondenceItemFields}
                    attachments {
                        id
                    }
                }
            }
        }
    }
`
