export function prepareProductTemplateFormData (item) {
    switch (item.type) {
        case 'section':
            // Mandatory properties: type, is_collapsible, label
            delete item.key
            if (item.children && item.children.length === 0) delete item.children
            delete item.removeFlag
            delete item.newlyInserted
            break
        case 'field':
            // Mandatory properties: type, id, required
            // delete item.label
            delete item.__typename
            delete item.data_type
            delete item.removeFlag
            delete item.newlyInserted
            break
    }
}
