<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.commission.commission-income-report.commission-income-report', 1)" :to="{ name: 'commission-revenue-report' }" />
            </template>

            <h1>{{ $tc('common.commission.commission-income-report.commission-income-report', 1) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <commission-revenue-report-form
                    ref="commissionRevenueReportForm"
                    @submit="handleSubmit"
                />
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { useI18n } from '@/composables/i18n'
import { useQuasar } from '@/composables/quasar'
import { CommissionRevenueReportService } from '@/services/commissionRevenueReport'
import { ref } from 'vue'
import CommissionRevenueReportForm from '@/components/commission/CommissionRevenueReportForm'

export default {
    name: 'CommissionRevenueReportCreate',
    components: {
        CommissionRevenueReportForm,
    },
    setup () {
        // Composables
        const { t, tc } = useI18n()
        const $q = useQuasar()

        // Template refs
        const commissionRevenueReportForm = ref(null)

        // Functions
        function handleSubmit ({ formData, consultingEntityIds }) {
            CommissionRevenueReportService.createBulk({
                consultingEntityIds,
                dateFrom: formData.dateFrom,
                dateUntil: formData.dateUntil,
            }).then(() => {
                commissionRevenueReportForm.value.resetSelectedItems()

                $q.notify({
                    type: 'positive',
                    message: tc('common.notifications.commission.commission-income-report-creation-started-success', consultingEntityIds.length || 1),
                    caption: t('common.notifications.commission.commission-income-report-email-notification-hint'),
                })
            }).catch(error => {
                commissionRevenueReportForm.value.formComponent.formBuilder.showFormErrorMessage(error)
                commissionRevenueReportForm.value.formComponent.formBuilder.resetFormSubmitStatus()
            })
        }

        return {
            // Template refs
            commissionRevenueReportForm,

            // Functions
            handleSubmit,
        }
    },
}
</script>
