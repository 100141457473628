<template>
    <!-- TODO improvement: Check if adding optional `no-backdrop-dismiss` (or also `persistent`) attribute might make sense. -->
    <q-dialog
        :id="id"
        ref="dialog"
        v-bind="$attrs"
        content-class="form-dialog"
        @show="onShow"
        @hide="onHide"
    >
        <div>
            <!-- Needs to have a wrapper element around form. If form tag is first element in q-dialog it gets closed on every click… -->
            <form data-test="form:dialog" @submit.prevent="onFormSubmit">
                <q-card>
                    <q-card-section>
                        <h1 v-if="$slots.title"><slot name="title" /></h1>

                        <slot :item="item" />

                        <!-- eslint-disable vue/no-v-html -->
                        <info-box
                            v-if="formErrorMessage"
                            type="negative"
                            data-test="error:global-message"
                            v-html="formErrorMessage"
                        />
                        <!-- eslint-enable -->

                        <div v-if="renderForm" class="q-gutter-sm">
                            <template v-if="formFields">
                                <form-builder v-for="field in formFields" :key="field" :item-key="field" />
                            </template>
                            <form-builder v-else :item="formConfiguration" />
                        </div>
                    </q-card-section>
                    <q-card-actions align="right" class="q-gutter-sm">
                        <base-button
                            :label="$t('common.term.cancel')"
                            flat
                            primary-button
                            data-test="btn:cancel"
                            @click="cancel"
                        />
                        <base-button
                            data-test="btn:submit"
                            type="submit"
                            :label="$t('common.term.confirm')"
                            :disable="formSubmitStatus"
                            :loading="formSubmitStatus"
                            primary-button
                        />
                    </q-card-actions>
                </q-card>
            </form>
        </div>
    </q-dialog>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { walkTree } from '@/helpers'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'

export default {
    name: 'FormDialog',
    mixins: [formBuilderMixin],
    props: {
        item: {
            type: Object,
        },
        formBuilderSettings: {
            type: Object,
        },
        initialFormData: {
            type: Object,
        },
        doubleConfirm: {
            type: Boolean,
            default: false,
        },
        doubleConfirmAltLabel: {
            type: Boolean,
            default: false,
        },
        doubleConfirmLabel: {
            type: String,
            default: '',
        },
        dontCloseOnConfirm: {
            type: Boolean,
            default: false,
        },
    },
    formBuilderSettings: {
        schema () {
            const schema = (this.formBuilderSettings && this.formBuilderSettings.schema) ? this.formBuilderSettings.schema : { type: 'root', children: [] }
            if (this.doubleConfirm) {
                schema.children.splice(0, 0, {
                    type: 'field',
                    key: 'doubleConfirmation',
                    label: this.doubleConfirmLabel || this.$t(`forms.confirmation-toggle${this.doubleConfirmAltLabel ? '--alt' : ''}`),
                    inputType: 'toggle',
                    'validators': [
                        {
                            'type': 'isTrue',
                            'errorMessage': 'common.error-message.is-true--double-confirmation',
                        },
                    ],
                })
            }
            return schema
        },
        fields () {
            const fields = (this.formBuilderSettings && this.formBuilderSettings.fields) ? this.formBuilderSettings.fields : undefined
            if (this.doubleConfirm && fields && fields.length) {
                fields.splice(0, 0, 'doubleConfirmation')
            }
            return fields
        },
    },
    data () {
        return {
            id: window.crypto.getRandomValues(new Uint32Array(1))[0],
        }
    },
    computed: {
        renderForm () {
            if (this.formConfiguration) {
                return !!this.formConfiguration.children.length
            } else {
                return false
            }
        },
    },
    methods: {
        open () {
            this.setInitialFormData()
            this.$refs.dialog.show()
        },
        close () {
            this.$refs.dialog.hide()
        },
        cancel () {
            this.$emit('cancel')
            this.close()
        },
        reset () {
            this.resetFormData()
            this.resetFormSubmitStatus()
            this.resetFormErrorMessage()
        },
        setInitialFormData () {
            if (this.initialFormData) Object.assign(this.formData, this.initialFormData)
        },
        handleFormData (formData) {
            this.$emit('confirm', formData, this.item)
            if (!this.dontCloseOnConfirm) {
                this.close()
            }
        },
        onShow () {
            // Set autofocus on first field
            if (this.renderForm) {
                let autoFocusFieldKey = ''
                walkTree(this.formConfiguration, node => {
                    if (node.type === 'field' && autoFocusFieldKey === '') autoFocusFieldKey = node.key
                })

                const dialogElement = document.getElementById(this.id)
                if (dialogElement) {
                    let field
                    ['input', 'textarea'].some(tagName => {
                        field = dialogElement.querySelector(`${tagName}[data-test="input:${kebabCase(autoFocusFieldKey)}"]`) // TODO @TFU: Refactor to use new data-attribute -key or -id instead of data-test.
                        if (field) return true
                    })
                    if (field) field.focus()
                }
            }
        },
        onHide () {
            this.reset()
            this.$emit('hide')
        },
    },
}
</script>

<style lang="scss" scoped>
.form-dialog {

    .q-dialog__inner {

        // This is the actual dialog element
        & > div {
            min-width: 22rem;
            width: 60vw;
            max-width: 40rem;

            @include mq($from: $sizeBreakpointMd) {
                min-width: 30rem;
                width: 25vw;
            }
        }
    }
}

h1 {
    margin: 0 0 1em;

    // = h3
    font-size: 1.5rem;
    line-height: 1.8rem;
    letter-spacing: 0;
}

::v-deep h2 {
    margin: 0 0 1em;

    // = h4
    font-size: 1.25rem;
    font-weight: 800;
    line-height: 1.5rem;
    letter-spacing: 0;
}

::v-deep h3 {
    margin: 0 0 1em;

    // = h5
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.2rem;
    letter-spacing: 0.0125em;
}

// Styling of error message when toggle is not checked
@keyframes tempErrorHighlighting {
    0% {
        color: var(--q-color-negative);
    }
    100% {
        color: inherit;
    }
}

::v-deep div[error="true"] {
    animation: tempErrorHighlighting 1.5s ease-in-out 0s;
}

::v-deep .error-message {
    color: var(--q-color-negative);
}
</style>
