import gql from 'graphql-tag'

export const PRODUCT_CATEGORIES_QUERY = gql`
    query productCategories(
        $page: Int = 1
        $count: Int = 100
        $orderField: ProductCategoryOrderField = CATEGORY_NAME
        $orderDirection: OrderDirection = ASC
        $filterQuery: String
    ) {
        productCategories(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: { category_name: $filterQuery }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                name
                key
                subCategories {
                    id
                    name
                    key
                }
            }
        }
    }
`
