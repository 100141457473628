import { computed, ref } from 'vue'
import { localizeProperties } from '@/libs/form/helpers'

export class FormCore {
    constructor (key, configuration) {
        this.id = Symbol()
        this.key = key
        this.configuration = localizeProperties(configuration)
        this.meta = {
            depth: 0,
        }
    }

    validate () {
        const validations = this.items.map(item => item.validate())
        // validations.push(this.validations.$validate()) // TODO: Add after validations on this level are initiated.
        return Promise.allSettled(validations).then(validationResults => validationResults.every(validationResult => validationResult.value))
    }

    resetValidations () {
        // this.validations.$reset()
        this.items.forEach(item => {
            item.resetValidations()
        })
    }

    resetValue () {
        this.items.forEach(item => item.resetValue())
        this.resetValidations()
    }

    setDefaultValues () {
        this.items.forEach(item => item.setDefaultValues())
        this.resetValidations()
    }

    setInitialData (data) {
        this.setData(data)
        this.resetValidations()
    }
}

export class FormBase extends FormCore {
    #items
    #data
    // #validators
    // #validations
    #isDirty
    #hasError

    constructor (key, configuration) {
        super(key, configuration)
        this.context = configuration.context
        delete configuration.context
        this.#items = ref([]) // TODO improvement @TFU: Upgrade to reactive Map after update to Vue3.

        // Data
        this.#data = computed(() => {
            const data = {}
            this.items.forEach(item => data[item.key] = item.data)
            return data
        })

        // TODO: Currently it's not possible to create validators that are dependent on more than one value (restriction by vuelidate).
        // Validation
        // this.#validators = ref((typeof this.configuration.validators !== 'undefined' && Array.isArray(this.configuration.validators)) ? this.configuration.validators : [])
        // const validationRules = computed(() => {
        //     const rules = {}
        //     this.#validators.value.forEach(validator => {
        //         const rule = initializeFormValidations([validator])
        //         validator.fields.forEach(field => rules[field] = rule)
        //     })
        //     return rules
        // })
        // this.#validations = useVuelidate(validationRules, this.data, { $scope: this.id, $stopPropagation: true, $autoDirty: true, $lazy: true })

        // Compose
        this.compose()

        // Dirty state
        this.#isDirty = computed(() => {
            // TODO: Add validation on this level.
            return this.items.some(item => item.isDirty)
        })

        // Error state
        this.#hasError = computed(() => {
            // TODO: Add validation on this level.
            return this.items.some(item => item.hasError)
        })
    }

    compose () {}

    get data () {
        return this.#data.value
    }

    get items () {
        return this.#items.value
    }

    _findItem (key) {
        return this.items.find(item => item.key === key)
    }

    getItem (key) {
        const paths = key.split('.')
        const currentPath = paths.shift()
        return (paths.length) ? this._findItem(currentPath).getItem(paths.join('.')) : this._findItem(key)
    }

    _addFormItem (instance) {
        if (this.configuration.excludeItems && this.configuration.excludeItems.includes(instance.key)) return
        instance.meta.depth = this.meta.depth + 1
        this.items.push(instance)
        return instance
    }

    addItems (itemInstances) {
        itemInstances.forEach(itemInstance => {
            this._addFormItem(itemInstance)
        })
    }

    addField (fieldInstance) {
        return this._addFormItem(fieldInstance)
    }

    addSection (sectionInstance) {
        return this._addFormItem(sectionInstance)
    }

    addRepeater (repeaterInstance) {
        return this._addFormItem(repeaterInstance)
    }

    // TODO: Add 'remove' item.

    // addValidator (validator) {
    //     this.#validators.value.push(validator)
    // }
    //
    // get validations () {
    //     return this.#validations.value
    // }

    get isDirty () {
        return this.#isDirty.value
    }

    get hasError () {
        return this.#hasError.value
    }

    setData (data) {
        this.items.forEach(item => {
            if (Object.hasOwn(data, item.key)) item.setData(data[item.key])
        })
    }

    get label () {
        return this.configuration.label
    }
}
