import gql from 'graphql-tag'

export const PRODUCT_TEMPLATE_QUERY = gql`
    query productTemplate(
        $id: ID!
        $version: Float
    ) {
        productTemplate(
            id: $id
            version: $version
        ) {
            id
            name
            status
            category {
                id
                name
                key
            }
            subCategory {
                id
                name
                key
            }
            version
            template_configuration
        }
    }
`
