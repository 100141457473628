<template>
    <div>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Polling',
    props: {
        pollFn: {
            type: Function,
            required: true,
        },
        stopConditionFn: {
            type: Function,
            required: true,
        },
        pollFilters: {
            type: [Object, String],
            default: () => {},
        },
        timeoutTm: {
            type: Number,
            default: 1000,
        },
        autoStart: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            timeout: null,
            isRunning: false,
        }
    },
    mounted () {
        if (this.autoStart) this.start()
    },
    beforeDestroy () {
        this.stop()
    },
    methods: {
        start () {
            this.runPollFn()
        },
        stop () {
            this.timeout = clearTimeout(this.timeout)
        },
        restart () {
            this.stop()
            this.start()
        },
        runPollFn () {
            if (this.isRunning) return

            this.isRunning = true
            this.pollFn(this.pollFilters).then(response => {
                this.isRunning = false

                const stopCondition = this.stopConditionFn(response)
                this.$emit('condition-check', stopCondition)
                if (stopCondition) {
                    this.stop()
                    return
                }

                this.timeout = setTimeout(this.runPollFn, this.timeoutTm)
            })
        },
    },

}
</script>
