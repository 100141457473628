<template>
    <div
        :class="{ 'active': isActive, 'small': dropZoneSmall }"
        class="dropzone do-not-print"
        @dragenter="onDragEnter"
        @dragover="onDragOver"
        @dragleave="onDragLeave"
        @drop="onDrop"
    >
        <div class="dropzone-indicator" :class="[isActive ? 'is-active' : 'is-idle', `dropzone-type-${zoneType}`]">
            <template v-if="zoneType === 'browser'">
                <!--<illustration name="cloud-upload" class="indicator-illustration" />-->
                <illustration
                    :name="fileType ? `max-file-add--${fileType}` : 'max-file-add'"
                    class="indicator-illustration"
                    :min-width="dropZoneSmall ? '4rem' : undefined"
                    :max-width="dropZoneSmall ? '6rem' : undefined"
                />
                <p class="indicator-text">{{ $tc('common.file.upload-file', multiple ? 2 : 1) }}</p>
            </template>
            <template v-else>
                <q-icon class="indicator-icon" name="mib-common-file-text-add" />
            </template>
        </div>

        <slot />
    </div>
</template>

<script>
import { getAllFiles, filterFiles } from '@/helpers/file'

export default {
    name: 'FileUploadDropZone',
    props: {
        zoneType: {
            type: String,
            default: 'browser',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        fileType: {
            type: String,
            default: '',
        },
        // TODO improvement @MTR: Get rid of drop-zone-small (or fix/enhance behavior)
        dropZoneSmall: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            isActive: false,
            deactivateTimeoutId: null,
        }
    },
    methods: {
        onDragEnter (event) {
            event.preventDefault()
            event.stopPropagation()
            this.activate()
        },
        onDragOver (event) {
            event.preventDefault()
            event.stopPropagation()
            this.activate()
        },
        onDragLeave (event) {
            event.preventDefault()
            event.stopPropagation()
            this.deactivateTimeoutId = window.setTimeout(this.deactivate, 5)
        },
        async onDrop (event) {
            event.preventDefault()
            event.stopPropagation()
            this.deactivate()
            const files = await getAllFiles(event.dataTransfer.items)
            const filteredFiles = filterFiles(files)
            if (filteredFiles.length) {
                this.$emit('drop', filteredFiles)
            }
        },
        activate () {
            this.deactivateTimeoutId = window.clearTimeout(this.deactivateTimeoutId)
            this.isActive = true
        },
        deactivate () {
            this.isActive = false
        },
    },
}
</script>

<style lang="scss" scoped>
@mixin dropZoneOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    pointer-events: none; // This is important
}

.dropzone {
    position: relative;

    height: 100%;
    min-height: 280px;

    &.small {
        min-height: 140px;
        .indicator-illustration {
            svg {
                max-width: 10vh;
            }
        }
    }
}

.dropzone-indicator {
    @include dropZoneOverlay;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-end;
    justify-content: center;
    padding: 0 $sizeSpacingSm;
    z-index: 100;
    overflow: hidden;

    background-color: var(--color-background-faded);
    color: var(--q-color-primary-dark);
    text-align: center;
    font-weight: bold;

    &::before {
        @include dropZoneOverlay;
        content: "";
        z-index: -1;
    }

    &.dropzone-type-browser {
        align-items: center;
        border: 2px dashed var(--q-color-primary-light);
    }

    // Illustration
    // TODO improvement @MTR: Use new <illustration> component props instead (`faded`, `selected`)
    .indicator-illustration {
        --color-illustration-accent: var(--color-illustration-primary);
        --color-illustration-accent-light: var(--color-illustration-primary-light);
        --color-illustration-accent-lighter: var(--color-illustration-primary-lighter);
        .small & {
            > * {
                max-width: 10vh;
            }
        }
    }

    // Indicator icon
    .indicator-icon {
        font-size: 1.3em;
    }

    // Indicator text
    .indicator-text {
        margin-top: -1em;
        padding: $sizeSpacingXs $sizeSpacingSm;

        //background: var(--color-illustration-primary-lighter);

        color: var(--color-illustration-primary);
        font-size: 1.5em;
        font-weight: 900;
    }

    // Active state (files being dropped)
    &.is-active {

        &.dropzone-type-browser {
            border: 2px dashed var(--color-illustration-accent);
        }

        // Illustration
        // TODO improvement @MTR: Use new <illustration> component props instead (`faded`, `selected`)
        .indicator-illustration {
            --color-illustration-accent: var(--color-illustration-accent);
            --color-illustration-accent-light: var(--color-illustration-accent-light);
            --color-illustration-accent-lighter: var(--color-illustration-accent-lighter);
        }
    }
}
</style>
