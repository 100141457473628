<template>
    <page-loading-indicator
        v-if="!documentTemplate"
        :number-of-breadcrumb-elements="2"
        actions
        :number-of-next-actions="0"
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.correspondence.document-template.document-template', 2)" :to="{ name: 'correspondence-document-template-list' }" />
                <q-breadcrumbs-el :label="$tc(`common.correspondence.${documentTemplate.type.toLowerCase()}.${documentTemplate.type.toLowerCase()}-template--edit-template`, 1)" :to="{ name: 'correspondence-document-template-create' }" />
            </template>

            <h1>{{ $tc(`common.correspondence.${documentTemplate.type.toLowerCase()}.${documentTemplate.type.toLowerCase()}-template--edit-template`, 1) }}</h1>

            <template v-slot:actions>
                <actions
                    v-if="documentTemplate.actions"
                    v-can="['update', documentTemplate]"
                    :actions="documentTemplate.actions"
                    :number-of-next-best-actions="0"
                    @click="handleActions"
                />

                <form-dialog
                    ref="documentTemplateDeleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleDocumentTemplateDelete"
                >
                    <template v-slot:title>{{ $tc('common.correspondence.document-template.delete-document-template', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.correspondence.document-template.delete-document-template--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:name><b>{{ documentTemplate.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <document-template-form
                    ref="documentTemplateForm"
                    :document-type="documentTemplate.type"
                    :document-template-owner-type="documentTemplateOwnerType"
                    :document-template="documentTemplate"
                    :supported-target-object-types.sync="supportedTargetObjectTypes"
                    @submit="onSubmit"
                />
                <document-content-formality-editor
                    :contents="contents"
                    :validator-configuration="editorValidations"
                    :smart-object-valid-target-object-types="smartObjectValidTargetObjectTypes"
                    :differentiate-formality.sync="differentiateFormality"
                    :readonly="$can.not('update', documentTemplate)"
                />
                <in-page-footer>
                    <base-button
                        :label="$can('update', documentTemplate) ? $t('common.term.cancel') : $t('common.term.back')"
                        flat
                        primary-button
                        @click="cancel"
                    />
                    <base-button
                        v-can="['update', documentTemplate]"
                        data-test="btn:submit"
                        :label="$t('common.term.save')"
                        primary-button
                        @click="triggerSubmit"
                    />
                </in-page-footer>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { DocumentTemplateOwnerType } from '@/enums'
import { ContactType, DocumentType, DocumentTemplateTargetObjectType } from '@/enums/graphql'
import DocumentTemplateForm from '@/components/documents/DocumentTemplateForm'
import DocumentContentFormalityEditor from '@/components/documents/DocumentContentFormalityEditor'
import { DocumentTemplate } from '@/models/documentTemplate'
import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'
import { StructureBlockSubject } from '@/editor/structureBlocks/StructureBlockSubject'
import { StructureBlockContent } from '@/editor/structureBlocks/StructureBlockContent'

export default {
    name: 'DocumentTemplateUpdate',
    meta () {
        return {
            title: this.documentTemplate ? this.$tc(`common.correspondence.${this.documentTemplate.type.toLowerCase()}.${this.documentTemplate.type.toLowerCase()}-template--edit-template`, 1) : this.$tc('views.correspondence.document-template-update.document-template-update', 1),
        }
    },
    components: {
        DocumentTemplateForm,
        DocumentContentFormalityEditor,
        Actions,
        FormDialog,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            DocumentTemplateOwnerType,
            DocumentType,
            documentTemplate: null,
            supportedTargetObjectTypes: [],
            differentiateFormality: false,
            contents: [],
            editorValidations: {
                [StructureBlockSubject.name]: { required },
                [StructureBlockContent.name]: { required },
            },
        }
    },
    computed: {
        documentTemplateOwnerType () {
            if (this.documentTemplate) {
                return (typeof this.documentTemplate.owner.first_name !== 'undefined') ? DocumentTemplateOwnerType.CONSULTANT : DocumentTemplateOwnerType.CONSULTING_COMPANY
            } else {
                return null
            }
        },
        smartObjectValidTargetObjectTypes () {
            const validTargetObjectTypes = []
            if (this.supportedTargetObjectTypes.includes(DocumentTemplateTargetObjectType.CONTACT)) validTargetObjectTypes.push(ContactType.PERSON, ContactType.COMPANY)
            if (this.supportedTargetObjectTypes.includes(DocumentTemplateTargetObjectType.APPLICATION)) {
                validTargetObjectTypes.push(DocumentTemplateTargetObjectType.APPLICATION)
                if (!validTargetObjectTypes.includes(ContactType.PERSON)) validTargetObjectTypes.push(ContactType.PERSON)
                if (!validTargetObjectTypes.includes(ContactType.COMPANY)) validTargetObjectTypes.push(ContactType.COMPANY)
            }
            if (this.supportedTargetObjectTypes.includes(DocumentTemplateTargetObjectType.CONTRACT)) {
                validTargetObjectTypes.push(DocumentTemplateTargetObjectType.CONTRACT)
                if (!validTargetObjectTypes.includes(ContactType.PERSON)) validTargetObjectTypes.push(ContactType.PERSON)
                if (!validTargetObjectTypes.includes(ContactType.COMPANY)) validTargetObjectTypes.push(ContactType.COMPANY)
            }
            return validTargetObjectTypes
        },
    },
    validations () {
        return {
            contents: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(2),
            },
        }
    },
    created () {
        DocumentTemplate.objects.get(this.id).then(documentTemplate => {
            this.documentTemplate = documentTemplate
            this.supportedTargetObjectTypes = documentTemplate.supported_target_object_types
            this.differentiateFormality = documentTemplate.differentiate_formality
            this.contents = documentTemplate.contents
        })
    },
    methods: {
        triggerSubmit () {
            if (Object.keys(this.editorValidations).length) this.contents.forEach(documentContent => documentContent.content.$v.$touch()) // Trigger editor content validation.
            this.$refs.documentTemplateForm.onFormSubmit()
        },
        onSubmit ({ availableForSubordinateCompanies, name, description, supportedTargetObjectTypes, languageId, confidentialityNotice }) {
            const invalidEditorContent = (Object.keys(this.editorValidations).length && this.contents.some(documentContent => documentContent.content.$v.$invalid))

            if (!this.$v.$invalid && !invalidEditorContent) {
                this.documentTemplate.update({
                    availableForSubordinateCompanies,
                    name,
                    description,
                    supportedTargetObjectTypes,
                    languageId,
                    confidentialityNotice,
                    differentiateFormality: this.differentiateFormality,
                    contents: this.contents.map(documentContent => documentContent.getVariablesObject()),
                })
                    .then(() => {
                        this.$router.replace({ name: 'correspondence-document-template-list' })
                    })
                    .catch(error => {
                        this.$refs.documentTemplateForm.showFormErrorMessage(error)
                        this.$refs.documentTemplateForm.resetFormSubmitStatus()
                    })
            }
        },
        cancel () {
            this.$router.go(-1)
        },
        handleActions (action) {
            switch (action.key) {
                case DocumentTemplate.action.DELETE:
                    this.$refs.documentTemplateDeleteDialog.open()
                    break
            }
        },
        handleDocumentTemplateDelete () {
            this.documentTemplate.delete()
                .then(() => {
                    this.$refs.documentTemplateDeleteDialog.close()
                    this.$router.replace({ name: 'correspondence-document-template-list' })
                })
                .catch(error => {
                    this.$refs.documentTemplateDeleteDialog.showFormErrorMessage(error)
                    this.$refs.documentTemplateDeleteDialog.resetFormSubmitStatus()
                })
        },
    },
}
</script>
