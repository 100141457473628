<template>
    <base-select-filter
        v-if="addressCategories.length"

        :value="value"
        v-bind="$attrs"
        :options="addressCategories"

        option-value="id"
        option-label="name"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { ContactService } from '@/services'

export default {
    name: 'ContactAddressCategorySelect',
    components: {
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        preSelectKey: {
            type: String,
        },
    },
    data () {
        return {
            addressCategories: [],
        }
    },
    created () {
        ContactService.getAddressCategories().then(addressCategories => {
            this.addressCategories = addressCategories
            if (this.preSelectKey && this.value === null) {
                const category = this.addressCategories.find(addressCategory => addressCategory.key === this.preSelectKey)
                if (category) this.$emit('input', category.id)
            }
        })
    },
}
</script>
