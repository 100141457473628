import gql from 'graphql-tag'

export const CORRESPONDENCE_EMAIL_CREATE_MUTATION = gql`
    mutation correspondenceEmailCreate(
        $targetObjectType: CorrespondenceItemTargetObjectType!
        $correspondenceEmail: CorrespondenceEmailInput!
    ) {
        correspondenceEmailCreate(
            target_object_type: $targetObjectType
            correspondenceEmail: $correspondenceEmail
        ) {
            id
            creator {
                id
                first_name
                last_name
            }
            status
            name
            target_object_type
            target_object_ids
            targetContacts {
                id
            }
            targetApplications {
                id
            }
            targetContracts {
                id
            }
            email_address_overrides
            sender_overrides
            language {
                id
                name
            }
            differentiate_formality
            contents {
                id
                formality
                content {
                    schemaType
                    schemaVersion
                    contentJSON
                    contentHTML
                    attrs
                }
            }
            attachments {
                id
            }
            created_at
            updated_at
        }
    }
`
