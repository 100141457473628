import { Model } from '@/models'

export class CommissionRateAdjustmentRule extends Model {
    /**
     * Create a CommissionRateAdjustmentRule model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'igb2bSectorCode',
            'custom_sector_code',
            'commission_rate',
            'adjusted_commission_rate',
        ], data)
    }

    get formattedIgb2bSectorCodeName () {
        return this.igb2bSectorCode ? `${this.igb2bSectorCode.code} – ${this.igb2bSectorCode.name}` : '–'
    }

    get formattedCustomSectorCode () {
        return this.custom_sector_code || '–'
    }

    get formattedSectorCodeName () {
        return this.igb2bSectorCode ? this.formattedIgb2bSectorCodeName : this.formattedCustomSectorCode
    }

    get formattedCommissionRate () {
        // Typographically correct would be to use a four-per-em space (U+2005) between the number and the % sign. / Also known as "mid space". One fourth of an em wide.
        return `${this.commission_rate || 0}\u2005%`
    }

    get formattedAdjustedCommissionRate () {
        // Typographically correct would be to use a four-per-em space (U+2005) between the number and the % sign. / Also known as "mid space". One fourth of an em wide.
        return `${this.adjusted_commission_rate || 0}\u2005%`
    }
}
