import gql from 'graphql-tag'

export const USER_CONFIRM_EMAIL_MUTATION = gql`
    mutation userConfirmEmail($token: String!) {
        userConfirmEmail(
            token: $token
        ) {
            status
        }
    }
`
