import gql from 'graphql-tag'

export const PREMIUM_INVOICE_CREATE_MUTATION = gql`
    mutation premiumInvoiceCreate(
        $matchedContractId: ID!
        $premiumInvoice: PremiumInvoiceInput!
    ) {
        premiumInvoiceCreate(
            matched_contract_id: $matchedContractId
            premiumInvoice: $premiumInvoice
        ) {
            id
            file_import_identification_number
            contract_number
            replaced_contract_number
            framework_agreement_number
            invoice_number
            invoice_date
            document_recipient
            customer_first_name
            customer_last_name
            customer_company_name
            files {
                id
                name
                size
                created_at
            }
            positions {
                id
                premium_from
                premium_to
                premium_kind
                premium_type
                sectorCode {
                    id
                }
                annual_premium_amount
                instalment_surcharge
                taxes_statutory_charges
                invoiced_amount
            }
            created_manually
            created_at
            updated_at
        }
    }
`
