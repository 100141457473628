import { isBefore, parseISO, isToday, subDays, isFuture, isWithinInterval, differenceInCalendarDays } from 'date-fns'
import { TaskActions, TaskDisplayStatus, TaskDueDateStatus } from '@/enums'
import { Model } from '@/models'
import { TaskService } from '@/services'
import { TaskStatus } from '@/enums/graphql'

export class Task extends Model {
    static UPCOMING_DUE_DATE_AMOUNT_OF_DAYS = 5

    /**
     * Create a Task model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(
            this,
            [
                'id',
                'status',
                'creator',
                'assignee',
                'visibility',
                'subject',
                'description',
                'attachments',
                'target_object_type',
                'target_object_id',
                'targetObject',
                'due_date',
                'done_at',
            ],
            data
        )
    }

    static get service () { return TaskService }
    static status = TaskStatus
    static action = TaskActions

    /**
     * Add custom actions.
     * @returns {Array}
     */
    get actions () {
        const actions = super.actions
        actions.push({
            key: TaskActions.DELETE,
            item: this,
        })
        return actions
    }

    /**
     * Create a new task.
     * @param {Object} taskInput - typeof TaskInput.
     * @returns {Promise<*>}
     */
    static create (taskInput) {
        return TaskService.create(taskInput)
    }

    /**
     * Update this task.
     * @param {Object} task - typeof TaskInput.
     * @returns {Promise<*>}
     */
    update ({ taskInput }) {
        return TaskService.update(this.id, taskInput).then((task) => {
            Object.assign(this, task)
            return task
        })
    }

    /**
     * Update the status of this task.
     * @param {string} status - typeof TaskStatus.
     * @returns {Promise<*>}
     */
    changeStatus (status) {
        return TaskService.changeStatus(this.id, status).then((task) => {
            Object.assign(this, task)
            return task
        })
    }

    /**
     * Delete this task.
     * @returns {Promise<*>}
     */
    delete () {
        return TaskService.delete(this.id)
    }

    /**
     * Get number of days until due date.
     * @returns {number | null}
     */
    get dueDateDiffDays () {
        if (!this.due_date) return null

        const today = new Date()
        const dueDate = parseISO(this.due_date)

        return differenceInCalendarDays(dueDate, today)
    }

    /**
     * Get due date status.
     * @returns {TaskDueDateStatus | null}
     */
    get dueDateStatus() {
        if (!this.due_date) return null

        const today = new Date()
        const dueDate = parseISO(this.due_date)
        const upcomingDate = subDays(dueDate, Task.UPCOMING_DUE_DATE_AMOUNT_OF_DAYS)

        if (isToday(dueDate)) return TaskDueDateStatus.DUE_TODAY
        if (isBefore(dueDate, today)) return TaskDueDateStatus.OVERDUE
        if (this.due_date && isWithinInterval(today, { start: upcomingDate, end: dueDate })) return TaskDueDateStatus.UPCOMING
        if (isFuture(dueDate)) return TaskDueDateStatus.FUTURE
        return null
    }

    /**
     * Get display status.
     * @returns {string: TaskDisplayStatus | TaskDueDateStatus }
     */
    get displayStatus () {
        if (this.status === TaskStatus.DONE && this.due_date) {
            const doneInTime = +parseISO(this.done_at) <= +parseISO(this.due_date)
            return doneInTime
                ? TaskDisplayStatus.DONE_IN_TIME
                : TaskDisplayStatus.DONE_AFTER_DUE_DATE
        } else {
            return this.dueDateStatus
        }
    }
}
