<template>
    <form-builder-next
        v-slot="{ formBuilder }"
        :form="form"
        :initial-data="state.initialData"
        in-page-footer
        @submit="onSubmit"
        @cancel="$emit('cancel')"
    >
        <div class="row q-mb-md">
            <div class="col-xs-12">
                <!-- PremiumInvoice -->
                <card
                    class="basic-information"
                    :heading="$tc('common.premium-invoice.invoice-info', 2)"
                >
                    <div class="row print-row-md q-col-gutter-sm">
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <form-builder-next :item="formBuilder.form.getItem('invoiceNumber')" />
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            <form-builder-next :item="formBuilder.form.getItem('invoiceDate')" />
                        </div>
                        <div class="col-xs-12 col-md-5 col-lg-5">
                            <form-builder-next :item="formBuilder.form.getItem('documentRecipient')" />
                        </div>
                    </div>
                </card>
            </div>
        </div>

        <div class="row q-col-gutter-md">
            <div class="col-xs-12">
                <!-- PremiumInvoicePositions -->
                <!--
                    PLEASE NOTE:

                    This hacky/creative workaround has been added due to some special requirements. Since the "invoice total" should be treated/rendered
                    differently but actually just is the first of n "invoice positions", this "duplicated" loop has been added which renders only the first
                    position (= invoice total) in the first run and all the (optional) other invoice positions in the second run.

                    This seemed to be the only possible solution at the time to render different markup (including wrappers) depending on the index of the item.
                -->
                <template v-for="group in ['total', 'details']">
                    <component
                        :is="group === 'total' ? 'div' : 'card'"
                        :key="`invoice-${group}-wrapper`"
                        :heading="group === 'details' ? $tc('common.premium-invoice.invoice-detail', 2) : null"
                        :full-height="false"
                    >
                        <form-builder-next
                            :key="`invoice-${group}`"
                            :item="formBuilder.form.getItem('positions')"
                            manual-layout
                        >
                            <template v-slot:repeater-item="{ item: repeaterItem }">
                                <component
                                    :is="repeaterItem.index === 0 ? 'card' : 'fieldset'"
                                    v-if="group === 'total' && repeaterItem.index === 0 || group === 'details' && repeaterItem.index >=1"
                                    :class="`premium-invoice-position-${repeaterItem.index}`"
                                    :heading="repeaterItem.index === 0 ? $t('common.premium-invoice.total-amount') : null"
                                >
                                    <legend v-if="repeaterItem.index >= 1">{{ $tc('common.term.position', 1) }} {{ repeaterItem.index }}</legend>
                                    <div class="row q-col-gutter-sm">
                                        <div class="col-xs-6 col-md-3 col-lg-2">
                                            <form-builder-next :item="repeaterItem.getItem('premiumFrom')" />
                                        </div>
                                        <div class="col-xs-6 col-md-3 col-lg-2">
                                            <form-builder-next :item="repeaterItem.getItem('premiumTo')" />
                                        </div>
                                        <div class="col-xs-12 col-md-6 col-lg-4">
                                            <form-builder-next :item="repeaterItem.getItem('premiumKind')" />
                                        </div>
                                        <div class="col-xs-12 col-md-6 col-lg-4">
                                            <form-builder-next :item="repeaterItem.getItem('premiumType')" />
                                        </div>
                                        <div class="col-xs-12 col-md-6 col-lg-4">
                                            <form-builder-next :item="repeaterItem.getItem('sectorCodeId')" />
                                        </div>
                                        <div class="col-xs-6 col-md-3 col-lg-2">
                                            <form-builder-next :item="repeaterItem.getItem('annualPremiumAmount')" />
                                        </div>
                                        <div class="col-xs-6 col-md-3 col-lg-2">
                                            <form-builder-next :item="repeaterItem.getItem('instalmentSurcharge')" />
                                        </div>
                                        <div class="col-xs-6 col-md-3 col-lg-2">
                                            <form-builder-next :item="repeaterItem.getItem('taxesStatutoryCharges')" />
                                        </div>
                                        <div class="col-xs-6 col-md-3 col-lg-2">
                                            <form-builder-next :item="repeaterItem.getItem('invoicedAmount')" />
                                        </div>
                                    </div>

                                    <div v-if="group === 'details' && repeaterItem.index >=1" class="row q-mt-md">
                                        <div class="col-xs-12">
                                            <base-button
                                                v-if="repeaterItem.index > 0"
                                                :label="$tc('common.premium-invoice.detail-position.delete-detail-position', 1)"
                                                class="do-not-print"
                                                outline
                                                text-color="negative"
                                                @click="repeaterItem.remove()"
                                            />
                                        </div>
                                    </div>
                                </component>

                                <hr v-if="group === 'total' && repeaterItem.index === 0" class="q-mt-lg q-mb-lg">
                            </template>

                            <template v-slot:repeater-item-buttons="{ item: repeaterItem }">
                                <!-- Empty element needed to overwrite default content. -->
                                <div />
                            </template>

                            <template v-slot:buttons="{ item }">
                                <base-button
                                    v-if="group === 'details'"
                                    :label="$tc('common.premium-invoice.detail-position.add-detail-position', 1)"
                                    class="q-mt-md do-not-print"
                                    primary-button
                                    outline
                                    @click="addDetailPosition"
                                />
                                <!-- Empty element needed to overwrite default content. -->
                                <div v-else />
                            </template>
                        </form-builder-next>
                    </component>
                </template>
            </div>

            <div class="col-xs-12">
                <!-- Files -->
                <card :heading="$tc('common.premium-invoice.file.file', 2)" heading-class="required">
                    <template v-if="premiumInvoice?.files?.length">
                        <template v-if="!state.replaceFiles">
                            <!-- TODO improvement @MTR: Create file library component. -->
                            <div class="file-library-wrapper">
                                <q-card
                                    v-for="file in premiumInvoice.files"
                                    :key="`premium-invoice-file-${file.id}`"
                                    class="file-library-item"
                                    square
                                    flat
                                    bordered
                                    data-test="item:premium-invoice-files"
                                >
                                    <q-card-section horizontal>
                                        <q-item class="items-start">
                                            <q-item-section side>
                                                <q-avatar square>
                                                    <q-icon
                                                        :name="getFileTypeIcon(file.mime_type)"
                                                        size="sm"
                                                        color="secondary"
                                                    />
                                                </q-avatar>
                                            </q-item-section>

                                            <q-item-section>
                                                <q-item-label class="file-name" data-test="text:premium-invoice-file-name">{{ file.name }}</q-item-label>
                                                <q-item-label v-if="file.size" caption data-test="text:premium-invoice-file-size">{{ humanStorageSize(file.size) }}</q-item-label>
                                            </q-item-section>
                                        </q-item>

                                        <q-card-actions vertical>
                                            <q-btn
                                                color="primary"
                                                size="sm"
                                                flat
                                                round
                                                icon="mib-cloud-download"
                                                @click="file.download()"
                                            >
                                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.term.download-item', 1, { item: file.name }) }}</q-tooltip>
                                            </q-btn>
                                        </q-card-actions>
                                    </q-card-section>
                                </q-card>
                            </div>

                            <base-button
                                :label="$tc('common.premium-invoice.file.replace-file', 2)"
                                class="q-mt-md do-not-print"
                                primary-button
                                outline
                                @click="state.replaceFiles = true"
                            />
                        </template>
                        <template v-else>
                            <p>{{ $tc('common.premium-invoice.file.replace-file--select-file', 2) }}</p>
                            <form-builder-next :item="formBuilder.form.getItem('deleteFileIds')" />
                        </template>
                    </template>

                    <div v-if="!premiumInvoice || state.replaceFiles" class="row q-mt-md">
                        <div class="col-xs-12">
                            <template v-if="state.uploadNewFiles">
                                <h3 v-if="premiumInvoice" class="text-h4">{{ $tc('common.premium-invoice.file.upload-file', 2) }}</h3>
                                <form-builder-next :item="formBuilder.form.getItem('files')" />
                                <base-button
                                    :label="$t('common.premium-invoice.file.select-contract-files-instead')"
                                    class="q-mt-md do-not-print"
                                    primary-button
                                    flat
                                    @click="showSelectExistingContractFiles"
                                />
                            </template>
                            <template v-else>
                                <h3 class="text-h4">{{ $tc('common.premium-invoice.file.contract-file', 2) }}</h3>
                                <form-builder-next :item="formBuilder.form.getItem('contractFileIds')" />
                                <base-button
                                    :label="$tc('common.premium-invoice.file.upload-files-instead', 2)"
                                    class="q-mt-md do-not-print"
                                    primary-button
                                    flat
                                    @click="showUploadNewFiles"
                                />
                            </template>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </form-builder-next>
</template>

<script>

import { reactive, ref, watch } from 'vue'
import { PremiumInvoiceForm } from '@/forms/premiumInvoice/premiumInvoice'
import { FormRepeater } from '@/libs/form'
import { PremiumInvoicePositionRepeaterItem } from '@/forms/premiumInvoice/premiumInvoicePosition'
import { useStore } from '@/composables/store'
import { getFileTypeIcon, humanStorageSize } from '@/helpers/file'
import { Contract } from '@/models/contract'
import { addMonths, addYears, differenceInCalendarDays, isValid, lightFormat, subDays } from 'date-fns'
import { PremiumInvoice } from '@/models/premiumInvoice'

export default {
    name: 'PremiumInvoiceForm',
    props: {
        premiumInvoice: {
            type: Object,
            default: null,
        },
        targetObject: {
            type: Contract,
            required: true,
        },
    },
    setup (props, context) {
        // Constants
        const BILLING_PERIOD_NUMBER_OF_DAYS_THRESHOLD = 60

        // Composables
        const store = useStore()

        // Data
        const state = reactive({
            initialData: {},
            uploadNewFiles: true,
            replaceFiles: false,
        })

        // Set initial data.
        if (props.premiumInvoice) { // Update
            Object.assign(state.initialData, {
                invoiceNumber: props.premiumInvoice.invoice_number,
                invoiceDate: props.premiumInvoice.invoice_date || '',
                documentRecipient: props.premiumInvoice.document_recipient,
                positions: props.premiumInvoice.positions.map(position => {
                    return {
                        id: position.id,
                        premiumFrom: position.premium_from,
                        premiumTo: position.premium_to,
                        premiumKind: position.premium_kind,
                        premiumType: position.premium_type,
                        sectorCodeId: position.sectorCode.id,
                        annualPremiumAmount: position.annual_premium_amount,
                        instalmentSurcharge: position.instalment_surcharge,
                        taxesStatutoryCharges: position.taxes_statutory_charges,
                        invoicedAmount: position.invoiced_amount,
                    }
                }),
            })
        } else { // Create
            // Total position
            const totalPosition = {}
            if (!props.targetObject.currentContractInformation.productFields?.premiumDueDate?.value && props.targetObject.currentContractInformation.productFields?.paymentMode?.rawValue === 0) { // One time payments.
                if (props.targetObject.currentContractInformation.start_date) totalPosition.premiumFrom = props.targetObject.currentContractInformation.start_date
                if (props.targetObject.currentContractInformation.end_date) totalPosition.premiumTo = props.targetObject.currentContractInformation.end_date
            }

            // Assign initial data
            Object.assign(state.initialData, {
                positions: [totalPosition],
            })
        }

        // Template refs
        const formComponent = ref(null)

        // Form
        const form = new PremiumInvoiceForm({
            context: {
                targetObjectId: props.targetObject.id,
                typeId: store.state.contractFileTypes.invoice.id,
                premiumInvoice: props.premiumInvoice,
            },
        })

        // PremiumInvoicePositions
        const positions = form.addRepeater(new FormRepeater('positions', {
            repeaterItemClass: PremiumInvoicePositionRepeaterItem,
            validators: [{ type: 'minLength', params: { min: 1 }}],
        }))

        // Watchers
        watch(() => form.getItem('invoiceDate').value, () => {
            if (!props.premiumInvoice) {
                const invoiceDate = new Date(form.getItem('invoiceDate').value)
                const totalPosition = positions.items.at(0)

                if (isValid(invoiceDate) && !totalPosition.getItem('premiumFrom').isDirty && !totalPosition.getItem('premiumFrom').value && !totalPosition.getItem('premiumTo').isDirty && !totalPosition.getItem('premiumTo').value) {
                    if (props.targetObject.currentContractInformation.productFields?.premiumDueDate?.value) {
                        // premiumFrom
                        const [month, day] = props.targetObject.currentContractInformation.productFields?.premiumDueDate.value.substring(2).split('-')
                        let premiumFrom = new Date(`${invoiceDate.getFullYear()}-${month}-${day}`)
                        if (differenceInCalendarDays(new Date(), premiumFrom) > BILLING_PERIOD_NUMBER_OF_DAYS_THRESHOLD) premiumFrom = addYears(premiumFrom, 1)
                        totalPosition.getItem('premiumFrom').value = lightFormat(premiumFrom, 'yyyy-MM-dd')

                        // premiumTo
                        if (props.targetObject.currentContractInformation.productFields?.paymentMode?.rawValue) {
                            totalPosition.getItem('premiumTo').value = lightFormat(subDays(addMonths(premiumFrom, props.targetObject.currentContractInformation.productFields?.paymentMode.rawValue), 1), 'yyyy-MM-dd')
                        }
                    } else if (props.targetObject.currentContractInformation.productFields?.paymentMode?.rawValue === 0) { // One time payments.
                        if (props.targetObject.currentContractInformation.start_date) totalPosition.premiumFrom = props.targetObject.currentContractInformation.start_date
                        if (props.targetObject.currentContractInformation.end_date) totalPosition.premiumTo = props.targetObject.currentContractInformation.end_date
                    }
                }
            }
        })

        // Functions
        function addDetailPosition () {
            positions.addItem()

            const totalPosition = positions.items.at(0)
            const detailPosition = positions.items.at(-1)
            if (totalPosition.getItem('premiumFrom').value) detailPosition.getItem('premiumFrom').value = totalPosition.getItem('premiumFrom').value
            if (totalPosition.getItem('premiumTo').value) detailPosition.getItem('premiumTo').value = totalPosition.getItem('premiumTo').value
        }
        function showUploadNewFiles () {
            state.uploadNewFiles = true
            form.getItem('contractFileIds').resetValue()
        }
        function showSelectExistingContractFiles () {
            state.uploadNewFiles = false
            form.getItem('files').resetValue()
        }
        function onSubmit (formData) {
            context.emit('submit', formData)
        }

        return {
            // Data
            state,

            // Template refs
            formComponent,

            // Form
            form,

            // Functions
            addDetailPosition,
            showUploadNewFiles,
            showSelectExistingContractFiles,
            onSubmit,
            getFileTypeIcon,
            humanStorageSize,
        }
    },
}
</script>

<style lang="scss" scoped>
.premium-invoice-position-0 {
    [data-key="invoiced-amount"] {
        border-bottom: 2px solid var(--q-color-secondary);

        ::v-deep {
            .q-field__native,
            .q-field__prefix,
            .q-field__suffix,
            .q-field__input {
                font-weight: 700;
            }
        }
    }
}
</style>
