<template>
    <page-loading-indicator
        v-if="!contract"
        :number-of-breadcrumb-elements="3"
        subtitle
        actions
        settings-button
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="contract.customer.getContactName()" :to="{ name: 'contact-detail', params: { id: contactId } }" data-test="breadcrumb:contact-detail" />
                <q-breadcrumbs-el :label="$tc('common.term.contract', 2)" :to="{ name: 'contact-detail', params: { id: contactId }}" data-test="breadcrumb:contracts" />
                <q-breadcrumbs-el :label="contract.currentContractNumber" :to="{ name: 'contract-detail', params: { id: contract.id }}" data-test="breadcrumb:contract-detail">
                    <contract-status-badge :contract="contract" />
                </q-breadcrumbs-el>
            </template>

            <!-- TODO: Add information and remove edit functionality from UI if contract is archived -->
            <h1 data-test="text:contract-number">
                {{ contract.currentContractNumber }}
                <base-button
                    v-if="editMode"
                    tabindex="0"
                    unelevated
                    round
                    color="none"
                    text-color="edit-mode-link"
                    size="sm"
                    class="edit-link do-not-print"
                    icon="mib-pencil"
                    data-test="btn:update-contract-number"
                    @click="updateContractNumber"
                    @keyup.enter.native="updateContractNumber"
                >
                    <q-tooltip
                        :delay="1000"
                        anchor="center right"
                        self="center left"
                        :offset="[10, 0]"
                        transition-show="scale"
                        transition-hide="scale"
                    >
                        {{ $tc('common.contract.edit-contract-number', 1) }}
                    </q-tooltip>
                </base-button> <span class="subtitle">({{ contract.currentContractInformation.product.providerName }})</span>
                <form-dialog
                    ref="contractNumberUpdateDialog"
                    :form-builder-settings="{ schema: CONTRACT_FIELDS, fields: ['contractNumber'] }"
                    :initial-form-data="{ contractNumber: contract.currentContractNumber }"
                    dont-close-on-confirm
                    @confirm="handleContractNumberUpdate"
                >
                    <template v-slot:title>{{ $tc('common.contract.edit-contract-number', 1) }}</template>
                </form-dialog>
            </h1>

            <dl v-if="contract.contractNumberHistory" class="q-mb-none items-baseline">
                <dt>{{ $tc('common.contract.contract-number--former-contract-number', contract.contractNumberHistory.length) }}: </dt>
                <dd data-test="text:contract-number-history">
                    <span v-for="(contractNumber, index) in contract.contractNumberHistory" :key="contractNumber.id">{{ contractNumber.number }}<base-button
                        v-if="editMode"
                        unelevated
                        round
                        color="none"
                        text-color="edit-mode-link"
                        size="xs"
                        icon="mib-bin"
                        class="do-not-print"
                        @click="deleteContractNumber(contractNumber)"
                    >
                        <q-tooltip
                            :delay="1000"
                            anchor="center right"
                            self="center left"
                            :offset="[10, 0]"
                            transition-show="scale"
                            transition-hide="scale"
                        >{{ $tc('common.contract.delete-contract-number', 1) }}</q-tooltip>
                    </base-button><template v-if="index < (contract.contractNumberHistory.length - 1)">, </template></span>
                </dd>
                <form-dialog
                    ref="contractNumberDeleteDialog"
                    :item="contractNumberToDelete"
                    @confirm="handleContractNumberDelete"
                    @hide="contractNumberToDelete = null"
                >
                    <template v-slot:title>{{ $tc('common.contract.delete-contract-number', 1) }}</template>
                    <template v-slot:default="slotProps">
                        <i18n v-if="slotProps.item" path="common.contract.delete-contract-number--confirm--temp-component-interpolation-count" tag="span">
                            <template v-slot:contractNumber><b>{{ slotProps.item.number }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </dl>

            <router-link
                v-if="editMode"
                data-test="btn:update-contract-info"
                :to="{ name: 'contract-update', params: { contactId: contract.customer.id, id: contract.id } }"
                class="edit-link q-link do-not-print"
            >
                <q-icon name="mib-pencil" class="q-mr-xs" />
                {{ $tc('common.contract.edit-contract-info', 2) }}
            </router-link>

            <template v-slot:actions>
                <!-- Permissions for `settings-link` need to be adjusted as soon as there are other (non commissions related) settings in there. -->
                <actions
                    v-if="contract.actions"
                    :actions="contract.actions"
                    :number-of-next-best-actions="2"
                    :settings-link="['Feature:commissions:core', 'CommissionSplit:manage'].every(permission => $can(permission)) ? { name: 'contract-settings', params: { id: contract.id } } : null"
                    @click="handleActions"
                />

                <!-- Terminate Contract -->
                <form-dialog
                    ref="contractTerminateDialog"
                    :form-builder-settings="{ schema: CONTRACT_TERMINATION_FIELDS }"
                    dont-close-on-confirm
                    @confirm="handleContractTerminate"
                >
                    <template v-slot:title>{{ $tc('common.actions.contract.terminate', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.contract.terminate-contract--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:contractNumber><b>{{ contract.currentContractNumber }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Activate Contract -->
                <form-dialog
                    ref="contractActivateDialog"
                    dont-close-on-confirm
                    @confirm="handleContractActivate"
                >
                    <template v-slot:title>{{ $tc('common.actions.contract.activate', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.contract.activate-contract--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:contractNumber><b>{{ contract.currentContractNumber }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Archive Contract -->
                <form-dialog
                    ref="contractArchiveDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleContractArchive"
                >
                    <template v-slot:title>{{ $tc('common.actions.contract.archive', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.contract.archive-contract--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:contractNumber><b>{{ contract.currentContractNumber }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Unarchive Contract -->
                <form-dialog
                    ref="contractUnarchiveDialog"
                    dont-close-on-confirm
                    @confirm="handleContractUnarchive"
                >
                    <template v-slot:title>{{ $tc('common.actions.contract.unarchive', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.contract.unarchive-contract--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:contractNumber><b>{{ contract.currentContractNumber }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Cancel Contract -->
                <form-dialog
                    ref="contractCancelDialog"
                    :form-builder-settings="{ schema: CONTRACT_CANCELLATION_FIELDS }"
                    dont-close-on-confirm
                    @confirm="handleContractCancel"
                >
                    <template v-slot:title>{{ $tc('common.actions.contract.cancel', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.contract.cancel-contract--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:contractNumber><b>{{ contract.currentContractNumber }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Delete Contract -->
                <form-dialog
                    ref="contractDeleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleContractDelete"
                >
                    <template v-slot:title>{{ $tc('common.actions.contract.delete', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.contract.delete-contract--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:contractNumber><b>{{ contract.currentContractNumber }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Update Cancellation -->
                <form-dialog
                    v-if="contract.currentCancellation"
                    ref="cancellationUpdateDialog"
                    :item="contract.currentCancellation"
                    :form-builder-settings="{ schema: CONTRACT_CANCELLATION_FIELDS }"
                    :initial-form-data="{ cancellationDate: contract.currentCancellation.cancellation_date }"
                    dont-close-on-confirm
                    @confirm="handleCancellationUpdate"
                >
                    <template v-slot:title>{{ $tc('common.contract.cancellation-date--update-cancellation-date', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.contract.cancellation-date--update--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:contractNumber><b>{{ contract.currentContractNumber }}</b></template>
                            <template v-slot:cancellationDate><b>{{ contract.currentCancellation.formattedCancellationDate }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Approve Cancellation -->
                <form-dialog
                    v-if="contract.currentCancellation"
                    ref="cancellationApproveDialog"
                    :item="contract.currentCancellation"
                    :form-builder-settings="{ schema: CONTRACT_CANCELLATION_FIELDS }"
                    :initial-form-data="{ cancellationDate: contract.currentCancellation.cancellation_date }"
                    dont-close-on-confirm
                    @confirm="handleCancellationApprove"
                >
                    <template v-slot:title>{{ $tc('common.actions.cancellation.approve', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.contract.cancellation-approve--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:contractNumber><b>{{ contract.currentContractNumber }}</b></template>
                            <template v-slot:cancellationDate><b>{{ contract.currentCancellation.formattedCancellationDate }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Decline Cancellation -->
                <form-dialog
                    v-if="contract.currentCancellation"
                    ref="cancellationDeclineDialog"
                    :item="contract.currentCancellation"
                    @confirm="handleCancellationDecline"
                >
                    <template v-slot:title>{{ $tc('common.actions.cancellation.decline', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.contract.cancellation-decline--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:contractNumber><b>{{ contract.currentContractNumber }}</b></template>
                            <template v-slot:cancellationDate><b>{{ contract.currentCancellation.formattedCancellationDate }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Withdraw Cancellation -->
                <form-dialog
                    v-if="contract.currentCancellation"
                    ref="cancellationWithdrawDialog"
                    :item="contract.currentCancellation"
                    @confirm="handleCancellationWithdraw"
                >
                    <template v-slot:title>{{ $tc('common.actions.cancellation.withdraw', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.contract.cancellation-withdraw--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:contractNumber><b>{{ contract.currentContractNumber }}</b></template>
                            <template v-slot:cancellationDate><b>{{ contract.currentCancellation.formattedCancellationDate }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>

            <template v-if="contract.couldUpdateProductTemplateVersion" v-slot:afterContent><product-template-diff :product-derived-entity="contract" /></template>
        </page-header>

        <div class="row print-row-lg q-col-gutter-md entity-info-wrapper">
            <grid-card
                :columns="{
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 4,
                    xl: 4,
                }"
                class="entity-info contact-info"
                data-test="wrapper:contact-info"
            >
                <address data-test="wrapper:contact-main-address">
                    {{ contract.customer.getContactName({ salutation: true, title: true }) }}<br>
                    {{ contract.customer.getContactAddress(contract.customer.mainAddress, { multiline: true }) }}
                </address>

                <template v-slot:actions>
                    <div v-can="'Feature:correspondence:core'" class="do-not-print">
                        <correspondence-item-create-button
                            :correspondence-item-target-object-type="CorrespondenceItemTargetObjectType.CONTRACT"
                            :correspondence-item-target-object-id="contract.id"
                            :disable-email="!contract.customer.emailAddresses.length"
                            :disabled="!contract.consultantAlsoConsultsCustomer"
                        />
                        <q-tooltip v-if="!contract.consultantAlsoConsultsCustomer" :delay="1000" :offset="[0, 10]">{{ $t('common.status.code.error.sender.no_access') }}</q-tooltip>
                    </div>
                </template>
            </grid-card>

            <grid-card
                :columns="{
                    xs: 12,
                    sm: 6,
                    md: 6,
                    lg: 8,
                    xl: 8,
                }"
                class="entity-info contract-info"
                data-test="wrapper:contract-info"
            >
                <dl class="q-mb-none">
                    <dt>{{ $tc('common.term.product', 1) }}: </dt>
                    <dd data-test="text:contract-product-name">{{ contract.currentContractInformation.product.name }}</dd>
                    <br>

                    <dt>{{ $tc('common.product.product-provider', 1) }}: </dt>
                    <dd data-test="text:contract-provider-name">{{ contract.currentContractInformation.product.providerName }}</dd>
                    <br>

                    <template v-if="contract.currentContractInformation.generalAgency">
                        <dt>{{ $tc('common.term.general-agency', 1) }}: </dt>
                        <dd data-test="text:contract-general-agency">{{ contract.currentContractInformation.generalAgency.company_name }}</dd>
                        <br>
                    </template>

                    <dt>{{ $tc('common.contact.consultant', 1) }}: </dt>
                    <dd data-test="text:contract-consultant">{{ contract.consultant.getContactName({ consultingCompanyName: !contract.consultant.worksForUserConsultingCompany }) }}</dd>
                    <br>

                    <dt>{{ $tc('views.contract.settings.commission.commission-recipient-override.commission-recipient', 1) }}: </dt>
                    <dd
                        data-test="text:contract-commission-recipient"
                        :class="[{ 'additional-info': !contract.hasCommissionRecipientOverrides && !contract.hasActiveCommissionSplits }]"
                    >{{ contract.commissionRecipientOverride ? contract.commissionRecipientOverride.getContactName({ consultingCompanyName: !contract.commissionRecipientOverride.worksForUserConsultingCompany }) : contract.consultant.getContactName({ consultingCompanyName: !contract.consultant.worksForUserConsultingCompany }) }}
                        <info-icon
                            v-if="contract.hasCommissionRecipientOverrides || contract.hasActiveCommissionSplits"
                            :text="$t('views.contract.settings.commission.commission-recipient-override.contract-has-override-text')"
                        >
                            <p v-if="contract.hasCommissionRecipientOverrides && contract.hasActiveCommissionSplits">{{ $tc('common.contract.commission.has-commission-recipient-override--has-active-commission-splitting', 1) }}</p>
                            <p v-else-if="contract.hasCommissionRecipientOverrides">{{ $tc('common.contract.commission.has-commission-recipient-override', 1) }}</p>
                            <p v-else-if="contract.hasActiveCommissionSplits">{{ $tc('common.contract.commission.has-active-commission-splitting', 1) }}</p>
                        </info-icon>
                    </dd>
                    <br>

                    <dt>{{ $tc('common.contract.managed-by-status.managed-by-status', 1) }}: </dt>
                    <dd data-test="text:contract-managed-by-status">{{ $tc(`common.contract.managed-by-status.${kebabCase(contract.currentContractInformation.managed_by_status)}`, 1) }}</dd>
                    <br>
                </dl>

                <dl class="q-mb-none">
                    <dt>{{ $t('common.contract.contract-start') }}: </dt>
                    <dd data-test="text:contract-start" :class="getFormattedFieldValue(contract.currentContractInformation.start_date).cssClass">{{ getFormattedFieldValue(contract.currentContractInformation.formattedStartDate).value }}</dd>
                    <br>

                    <dt>{{ $t('common.contract.contract-end') }}: </dt>
                    <dd data-test="text:contract-end" :class="getFormattedFieldValue(contract.currentContractInformation.end_date).cssClass">{{ getFormattedFieldValue(contract.currentContractInformation.formattedEndDate).value }}</dd>
                    <br>

                    <dt v-if="contract.termination_date">{{ $t('common.contract.termination-date') }}: </dt>
                    <dd v-if="contract.termination_date" data-test="text:contract-termination">{{ contract.formattedTerminationDate }}</dd>
                    <br v-if="contract.termination_date">

                    <dt>{{ $t('common.contract.premium') }}: </dt>
                    <dd data-test="text:contract-premium" :class="getFormattedFieldValue(contract.currentContractInformation.premium).cssClass">{{ getFormattedFieldValue(contract.currentContractInformation.formattedPremium).value }}</dd>
                </dl>
            </grid-card>
        </div>

        <info-box
            v-if="contract.currentContractInformation.managed_by_status !== ContractManagedByStatus.INTERNAL"
            :type="([ContractManagedByStatus.EXTERNAL, ContractManagedByStatus.CONSULTING_MANDATE_CANCELLATION_PENDING]).includes(contract.currentContractInformation.managed_by_status) ? 'warning' : 'info'"
            :icon="contract.currentContractInformation.managed_by_status === ContractManagedByStatus.EXTERNAL ? 'mib-information-circle': null"
            data-test="info:contract-managed-by-status"
        >
            <p><component :is="contract.currentContractInformation.managed_by_status === ContractManagedByStatus.EXTERNAL ? 'strong' : 'span'">{{ $tc(`common.contract.managed-by-status.${kebabCase(contract.currentContractInformation.managed_by_status)}--info`, 1, { date: contract.currentContractInformation.formattedConsultingMandateEnquirySendDate }) }}</component></p>
        </info-box>

        <info-box
            v-if="contract.currentCancellation"
            type="warning"
            :inline-actions="$q.screen.width > 1300"
            data-test="warning:contract-cancellation"
        >
            <strong>{{ $tc('common.contract.cancellation-info--contract-cancelled-per', 1, { cancellationDate: contract.currentCancellation.formattedCancellationDate }) }}</strong> {{ $tc(`common.contract.cancellation-info--status-${contract.currentCancellation.status.toLowerCase()}`, 1) }}

            <template v-if="contract.pendingCancellation" v-slot:action>
                <base-button
                    :label="$tc('common.contract.cancellation-date--update-cancellation-date', 1)"
                    class="q-mr-sm"
                    primary-button
                    flat
                    data-test="btn:contract-update-cancellation"
                    @click="$refs.cancellationUpdateDialog.open()"
                />
                <base-button
                    :label="$tc('common.actions.cancellation.approve', 1)"
                    primary-button
                    data-test="btn:contract-approve-cancellation"
                    @click="$refs.cancellationApproveDialog.open()"
                />
            </template>
        </info-box>

        <info-box
            v-if="contract.currentChangeApplication"
            no-margin
            inline-actions
        >
            <i18n path="common.contract.change-application-info--number" tag="p">
                <template v-slot:number><b>{{ contract.currentChangeApplication.formattedNumber }}</b></template>
            </i18n>

            <template v-slot:action>
                <base-button
                    :to="{ name: 'application-detail', params: { contactId: contract.customer.id, id: contract.currentChangeApplication.id } }"
                    :label="$tc('common.application.go-to-application', 1)"
                    outline
                    primary-button
                />
            </template>
        </info-box>

        <div class="row q-mt-md q-col-gutter-md">
            <div class="col-xs-12">
                <main-tabs>
                    <q-tabs
                        v-model="activeTab"
                        dense
                        no-caps
                        inline-label
                        align="left"
                        class="bg-grey-2"
                        indicator-color="accent"
                        data-test="tabs:links"
                    >
                        <q-route-tab
                            name="contract-details"
                            :to="{ name: 'contract-detail', params: { contactId: contactId, id: id } }"
                            icon="mib-common-file-text-alternate"
                            :label="$tc('common.contract.contract-detail', 2)"
                            data-test="tab:details"
                        />
                        <q-route-tab
                            name="documents"
                            :to="{ name: 'contract-detail', params: { contactId: contactId, id: id, tab: 'documents' } }"
                            icon="mib-common-file-stack-alternate"
                            :label="$tc('common.term.document', 2)"
                            data-test="tab:documents"
                        >
                            <q-badge
                                v-if="numberOfDocuments !== null"
                                color="secondary-light"
                                text-color="secondary-dark"
                                class="q-ml-xs"
                            >
                                {{ numberOfDocuments }}
                            </q-badge>
                        </q-route-tab>

                        <q-route-tab
                            name="comments"
                            :to="{ name: 'contract-detail', params: { contactId: contactId, id: id, tab: 'comments' } }"
                            icon="mib-messages-bubble-square-text-alternate"
                            :label="$tc('common.term.comment', 2)"
                            data-test="tab:comments"
                        >
                            <q-badge
                                v-if="numberOfComments !== null"
                                color="secondary-light"
                                text-color="secondary-dark"
                                class="q-ml-xs"
                            >
                                {{ numberOfComments }}
                            </q-badge>
                        </q-route-tab>

                        <q-route-tab
                            v-if="$can('Feature:commissions:core') && $can('read:*', 'Posting')"
                            name="commission-postings"
                            :to="{ name: 'contract-detail', params: { contactId: contactId, id: id, tab: 'commission-postings' } }"
                            icon="mib-messages-bubble-square-text-alternate"
                            :label="$tc('common.contract.posting.commission-posting', 2)"
                            data-test="tab:commission-postings"
                        >
                            <q-badge
                                v-if="numberOfCommissionPostings !== null"
                                color="secondary-light"
                                text-color="secondary-dark"
                                class="q-ml-xs"
                            >
                                {{ numberOfCommissionPostings }}
                            </q-badge>
                        </q-route-tab>

                        <q-route-tab
                            v-if="$can('Feature:ecoHub:dxp') && $can('PremiumInvoice:manage')"
                            name="premium-invoices"
                            :to="{ name: 'contract-detail', params: { contactId: contactId, id: id, tab: 'premium-invoices' } }"
                            icon="mib-accounting-invoice"
                            :label="$tc('common.premium-invoice.premium-invoice', 2)"
                            data-test="tab:premium-invoices"
                        >
                            <!--<q-badge-->
                            <!--    v-if="numberOfCommissionPostings !== null"-->
                            <!--    color="secondary-light"-->
                            <!--    text-color="secondary-dark"-->
                            <!--    class="q-ml-xs"-->
                            <!--&gt;-->
                            <!--    {{ numberOfCommissionPostings }}-->
                            <!--</q-badge>-->
                        </q-route-tab>

                        <!-- TODO: Uncomment this when history tab has been reintroduced. -->
                        <!--
                                                <q-route-tab name="history" :to="{ name: 'contract-detail', params: { contactId: contactId, id: id, tab: 'history' } }" icon="mib-synchronize-arrow-clock" :label="$tc('common.term.history')">
                                                </q-route-tab>
                        -->
                        <!-- TODO: Remove after including this info in the contract history -->
                        <!--
                        <q-tab name="cancellations" icon="mib-common-file-text-remove" :label="`${$tc('common.term.cancellation', 2)}`">
                        </q-tab>
-->
                    </q-tabs>
                </main-tabs>

                <q-tab-panels
                    v-model="activeTab"
                    animated
                    transition-prev="fade"
                    transition-next="fade"
                >
                    <!-- TODO: check if keep-alive should be used (performance wise) -->
                    <q-tab-panel name="contract-details" class="q-pl-none q-pr-none" data-test="tab-panel:details">
                        <h2>{{ $tc('common.contract.contract-detail', 2) }}</h2>

                        <product-fields :product-fields="contract.currentContractInformation.nestedProductFields.children" />

                        <dl class="contract-product-template-info">
                            <dt>{{ $tc('common.product.product-template', 1) }}: </dt>
                            <dd data-test="text:contract-template">
                                <span class="additional-info">{{ contract.currentContractInformation.productTemplate.category.name }} / {{ contract.currentContractInformation.productTemplate.subCategory.name }} /</span> {{ contract.currentContractInformation.productTemplate.name }} ({{ $tc('common.term.version', 1) }} {{ contract.currentContractInformation.productTemplate.version }})
                            </dd>
                        </dl>
                    </q-tab-panel>

                    <q-tab-panel name="documents" class="q-pl-none q-pr-none" data-test="tab-panel:documents">
                        <h2>{{ $tc('common.term.document', 2) }}</h2>
                        <consulting-file-browser
                            :target-object-type="ConsultingFileTargetObjectType.CONTRACT"
                            :target-object-id="contract.id"
                            :target-object-consultants="[contract.consultant]"
                            :meta="{ contactId: contract.customer.id, contractNumber: contract.currentContractNumber, contactName: contract.customer.getContactName() }"
                            @numberOfDocuments="amount => numberOfDocuments = amount"
                            @currentFileTypeIdChange="fileTypeId => consultingFileBrowserFileTypeId = fileTypeId"
                        />
                        <in-page-footer>
                            <consulting-file-upload-button
                                :target-object-type="ConsultingFileTargetObjectType.CONTRACT"
                                :target-object-id="contract.id"
                                :file-type-id="consultingFileBrowserFileTypeId"
                                :meta="{ contactId: contract.customer.id, contractNumber: contract.currentContractNumber, contactName: contract.customer.getContactName() }"
                            />
                        </in-page-footer>
                    </q-tab-panel>

                    <q-tab-panel
                        name="comments"
                        class="q-pl-none q-pr-none"
                        data-test="tab-panel:comments"
                    >
                        <div class="block-wrapper">
                            <h2>{{ $tc('common.term.comment', 2) }}</h2>
                            <comment-list
                                ref="contractCommentList"
                                :items="comments"
                                :target-object-type="CommentTargetObjectType.CONTRACT"
                                :target-object-id="contract.id"
                                @numberOfComments="amount => { numberOfComments = amount }"
                            />
                        </div>
                    </q-tab-panel>

                    <q-tab-panel
                        v-if="$can('Feature:commissions:core') && $can('read:*', 'Posting')"
                        name="commission-postings"
                        class="q-pl-none q-pr-none"
                    >
                        <div class="block-wrapper">
                            <contract-commission-postings-list
                                ref="contractCommissionPostingsList"
                                :contract="contract"
                                @numberOfCommissionPostings="amount => { numberOfCommissionPostings = amount }"
                            />
                        </div>
                    </q-tab-panel>

                    <!-- TODO @TFU: Define numberOfPremiumInvoices. -->
                    <q-tab-panel
                        v-if="$can('Feature:ecoHub:dxp') && $can('PremiumInvoice:manage')"
                        name="premium-invoices"
                        class="q-pl-none q-pr-none"
                    >
                        <div class="block-wrapper">
                            <contract-premium-invoice-list
                                ref="contractPremiumInvoiceList"
                                :contract="contract"
                            />
                        </div>
                    </q-tab-panel>

                    <!-- TODO: Uncomment this when history tab has been reintroduced. -->
                    <!--
                    <q-tab-panel name="history" class="q-pl-none q-pr-none">
                        <h2>{{ $tc('common.term.history', 2) }}</h2>
                    </q-tab-panel>
-->

                    <!-- TODO: Remove after including this info in the contract history -->
                    <!--
                    <q-tab-panel name="cancellations" class="q-pl-none q-pr-none">
                        <h2>{{ $tc('common.term.cancellation', 2) }} <small><mark>DEV ONLY</mark></small></h2>
                        <template v-if="contract.currentCancellation">
                            <h3>Current Cancellation</h3>
                            <ul>
                                <li>Status: {{ contract.currentCancellation.status }}</li>
                                <li>Cancellation Date: {{ contract.currentCancellation.cancellation_date }}</li>
                            </ul>
                            <div v-if="contract.pendingCancellation">
                                <base-button v-if="!showCancellationUpdateForm" :label="$tc('common.contract.cancellation-date--update-cancellation-date', 1)" @click="showCancellationUpdateForm = true"></base-button>
                                <cancellation-form v-if="showCancellationUpdateForm" ref="cancellationUpdateForm" :cancellation="contract.pendingCancellation" @submit="updatePendingCancellation" @cancel="showCancellationUpdateForm = false" />
                            </div>
                        </template>

                        <h3>Cancellation History</h3>
                        <ul>
                            <li v-for="cancellation in contract.cancellationHistory" :key="cancellation.id">
                                <ul>
                                    <li>Status: {{ cancellation.status }}</li>
                                    <li>Cancellation Date: {{ cancellation.cancellation_date }}</li>
                                </ul>
                            </li>
                        </ul>
                    </q-tab-panel>
-->
                </q-tab-panels>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import { contactMixin } from '@/mixins/contactMixin'
import { extractErrorMessage, getFormattedFieldValue } from '@/helpers/form'
import { kebabCase } from 'lodash'

import StatusBadge from '@/components/StatusBadge.vue'
import ContractStatusBadge from '@/components/contract/ContractStatusBadge.vue'
import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'
import CommentList from '@/components/CommentList.vue'
import CorrespondenceItemCreateButton from '@/components/correspondence/CorrespondenceItemCreateButton'

import { Contract } from '@/models/contract'
import { Cancellation } from '@/models/cancellation'
import { ProductTemplate } from '@/models/productTemplate'

import CONTRACT_FIELDS from '@/forms/contract.fields'
import CONTRACT_TERMINATION_FIELDS from '@/forms/contract.termination.fields.json'
import CONTRACT_CANCELLATION_FIELDS from '@/forms/contract.cancellation.fields.json'

import { ContractStatus, ContractManagedByStatus, ConsultingFileTargetObjectType, CommentTargetObjectType, CorrespondenceItemTargetObjectType } from '@/enums/graphql'
import ConsultingFileBrowser from '@/components/consultingFile/ConsultingFileBrowser'
import ConsultingFileUploadButton from '@/components/consultingFile/ConsultingFileUploadButton'

import ContractCommissionPostingsList from '@/components/contract/ContractCommissionPostingsList'
import ContractPremiumInvoiceList from '@/components/contract/ContractPremiumInvoiceList'

import MainTabs from '@/components/MainTabs'
import ProductTemplateDiff from '@/components/product/ProductTemplateDiff'
import ProductFields from '@/components/productDerivedEntity/ProductFields'

export default {
    name: 'ContractDetail',
    meta () {
        return {
            title: !this.contract ? `${this.$t('common.term.loading')} …` : `${this.contract.currentContractNumber} (${this.contract.currentContractInformation.product.providerName}) – ${this.getActiveTabName()}`,
        }
    },
    components: {
        StatusBadge,
        ContractStatusBadge,
        Actions,
        FormDialog,
        ConsultingFileBrowser,
        ConsultingFileUploadButton,
        MainTabs,
        CommentList,
        ContractCommissionPostingsList,
        ContractPremiumInvoiceList,
        CorrespondenceItemCreateButton,
        ProductTemplateDiff,
        ProductFields,
    },
    mixins: [contactMixin],
    props: {
        contactId: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        tab: {
            type: String,
        },
    },
    data () {
        return {
            ProductTemplate,
            ConsultingFileTargetObjectType,
            CommentTargetObjectType,
            Cancellation,
            CorrespondenceItemTargetObjectType,
            contract: null,
            comments: [],
            activeTab: this.tab,
            areaEditModeContractInfo: false,
            showCancellationUpdateForm: false,
            contractNumberToDelete: null,
            ContractStatus,
            ContractManagedByStatus,
            CONTRACT_FIELDS,
            CONTRACT_TERMINATION_FIELDS,
            CONTRACT_CANCELLATION_FIELDS,
            numberOfDocuments: null,
            numberOfComments: null,
            numberOfCommissionPostings: null,
            consultingFileBrowserFileTypeId: null,
        }
    },
    computed: {
        editMode () {
            return this.$store.state.editMode
        },
        pinnedComments () {
            return this.comments.filter(comment => comment.pinned)
        },
    },
    watch: {
        '$route' (to, from) {
            // Re-fetch if contract changes while view stays the same (e.g. navigate to other contract via global search).
            if (to.params.id !== from.params.id) {
                this.fetchObject()
            }
        },
    },
    created () {
        this.fetchObject()
    },
    beforeDestroy () {
        EventBus.$emit('taskPanel:setTargetObject', undefined)
    },
    methods: {
        fetchObject () {
            Contract.objects.get(this.id)
                .then(contract => {
                    this.contract = contract

                    EventBus.$emit('taskPanel:setTargetObject', this.contract)

                    // Reset tab number badges
                    this.numberOfDocuments = this.contract.number_of_contract_files
                    this.numberOfComments = this.contract.number_of_comments

                    // Add pinnedComments as initial comments data, but only if the tab comment component hasn't populated it already.
                    if (this.contract.pinnedComments && this.contract.pinnedComments.length && this.comments.length === 0) {
                        this.comments = this.contract.pinnedComments
                    }
                })
                .catch(() => {
                    this.$router.replace({ name: '404' })
                })
        },
        getActiveTabName () {
            switch (this.activeTab) {
                case 'contract-details':
                    return this.$tc('common.contract.contract-detail', 2)
                case 'documents':
                    return this.$tc('common.term.document', 2)
                case 'comments':
                    return this.$tc('common.term.comment', 2)
                case 'history':
                    return this.$tc('common.term.history')
                default:
                    return ''
            }
        },
        handleActions (action) {
            switch (action.key) {
                case Contract.action.TERMINATE:
                    this.$refs.contractTerminateDialog.open()
                    break
                case Contract.action.ACTIVATE:
                    this.$refs.contractActivateDialog.open()
                    break
                case Contract.action.ARCHIVE:
                    this.$refs.contractArchiveDialog.open()
                    break
                case Contract.action.UNARCHIVE:
                    this.$refs.contractUnarchiveDialog.open()
                    break
                case Contract.action.CANCEL:
                    this.$refs.contractCancelDialog.open()
                    break
                case Contract.action.DELETE:
                    this.$refs.contractDeleteDialog.open()
                    break
                case Cancellation.action.APPROVE:
                    this.$refs.cancellationApproveDialog.open()
                    break
                case Cancellation.action.DECLINE:
                    this.$refs.cancellationDeclineDialog.open()
                    break
                case Cancellation.action.WITHDRAW:
                    this.$refs.cancellationWithdrawDialog.open()
                    break
                case Contract.action.CREATE_CHANGE_APPLICATION:
                    this.$router.push({ name: 'application-create', params: { contactId: this.contactId, sourceContractId: this.id } })
                    break
            }
        },
        handleContractTerminate ({ terminationDate }) {
            this.contract.terminate(terminationDate)
                .then(() => {
                    this.$refs.contractTerminateDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contract.contract-terminated-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.contractTerminateDialog.showFormErrorMessage(error)
                    this.$refs.contractTerminateDialog.resetFormSubmitStatus()
                })
        },
        handleContractActivate () {
            this.contract.activate()
                .then(() => {
                    this.$refs.contractActivateDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contract.contract-activated-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.contractActivateDialog.showFormErrorMessage(error)
                    this.$refs.contractActivateDialog.resetFormSubmitStatus()
                })
        },
        handleContractArchive () {
            this.contract.archive()
                .then(() => {
                    this.$refs.contractArchiveDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contract.contract-archived-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.contractArchiveDialog.showFormErrorMessage(error)
                    this.$refs.contractArchiveDialog.resetFormSubmitStatus()
                })
        },
        handleContractUnarchive () {
            this.contract.unarchive()
                .then(() => {
                    this.$refs.contractUnarchiveDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contract.contract-unarchived-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.contractUnarchiveDialog.showFormErrorMessage(error)
                    this.$refs.contractUnarchiveDialog.resetFormSubmitStatus()
                })
        },
        handleContractCancel ({ cancellationDate }) {
            Cancellation.create(this.contract.id, cancellationDate)
                .then(cancellation => {
                    this.contract.cancellations.splice(0, 0, cancellation)
                    this.$refs.contractCancelDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contract.contract-cancelled-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.contractCancelDialog.showFormErrorMessage(error)
                    this.$refs.contractCancelDialog.resetFormSubmitStatus()
                })
        },
        handleContractDelete () {
            this.contract.delete()
                .then(() => {
                    this.$refs.contractDeleteDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contract.contract-deleted-success', 1),
                    })
                    this.$router.replace({ name: 'contact-detail', params: { id: this.contactId } })
                })
                .catch(error => {
                    this.$refs.contractDeleteDialog.showFormErrorMessage(error)
                    this.$refs.contractDeleteDialog.resetFormSubmitStatus()
                })
        },
        handleCancellationUpdate ({ cancellationDate }, cancellation) {
            cancellation.update(cancellationDate)
                .then(() => {
                    this.$refs.cancellationUpdateDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contract.contract-cancellation-date-updated-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.cancellationUpdateDialog.showFormErrorMessage(error)
                    this.$refs.cancellationUpdateDialog.resetFormSubmitStatus()
                })
        },
        handleCancellationApprove ({ cancellationDate }, cancellation) {
            cancellation.approve(cancellationDate)
                .then(cancellation => {
                    const index = this.contract.cancellations.findIndex(item => item.id === cancellation.id)
                    this.contract.cancellations.splice(index, 1, cancellation)
                    this.$refs.cancellationApproveDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contract.contract-cancellation-approved-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.cancellationApproveDialog.showFormErrorMessage(error)
                    this.$refs.cancellationApproveDialog.resetFormSubmitStatus()
                })
        },
        handleCancellationDecline (formData, cancellation) {
            cancellation.decline()
                .then(cancellation => {
                    const index = this.contract.cancellations.findIndex(item => item.id === cancellation.id)
                    this.contract.cancellations.splice(index, 1, cancellation)
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contract.contract-cancellation-declined-success', 1),
                    })
                })
        },
        handleCancellationWithdraw (formData, cancellation) {
            cancellation.withdraw()
                .then(cancellation => {
                    const index = this.contract.cancellations.findIndex(item => item.id === cancellation.id)
                    this.contract.cancellations.splice(index, 1, cancellation)
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contract.contract-cancellation-withdrawn-success', 1),
                    })
                })
                .catch(error => {
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: extractErrorMessage(error),
                        html: true,
                    })
                })
        },
        updateContractNumber () {
            this.$refs.contractNumberUpdateDialog.open()
        },
        handleContractNumberUpdate ({ contractNumber }) {
            if (contractNumber !== this.contract.currentContractNumber) {
                this.contract.updateContractNumber(contractNumber)
                    .then(() => {
                        this.$refs.contractNumberUpdateDialog.close()
                        this.$q.notify({
                            type: 'positive',
                            message: this.$tc('common.notifications.contract.contract-number-updated-success', 1),
                        })
                    })
                    .catch(error => {
                        this.$refs.contractNumberUpdateDialog.showFormErrorMessage(error)
                        this.$refs.contractNumberUpdateDialog.resetFormSubmitStatus()
                    })
            } else {
                this.$refs.contractNumberUpdateDialog.formErrorMessage = this.$tc('common.contract.edit-contract-number--error--same-value', 1)
                this.$refs.contractNumberUpdateDialog.resetFormSubmitStatus()
            }
        },
        deleteContractNumber (contractNumber) {
            this.contractNumberToDelete = contractNumber
            this.$refs.contractNumberDeleteDialog.open()
        },
        handleContractNumberDelete (formData, contractNumber) {
            this.contract.deleteContractNumber(contractNumber.id)
                .then(() => {
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contract.contract-number-deleted-success', 1),
                    })
                })
                .catch(error => {
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: extractErrorMessage(error),
                        html: true,
                    })
                })
        },
        getFormattedFieldValue (...args) {
            return getFormattedFieldValue(...args)
        },
        triggerUpdateComment (comment) {
            const location = this.$router.resolve({ name: 'contract-detail', params: { id: this.id, tab: 'comments' } })
            if (location.href !== this.$route.path) {
                this.$router.push(location.href).then(async () => {
                    this.activeTab = 'comments'
                    await this.$nextTick()
                    this.$refs.contractCommentList.triggerUpdateItem(comment)
                })
            } else {
                this.$refs.contractCommentList.triggerUpdateItem(comment)
            }
        },
        triggerDeleteComment (comment) {
            const location = this.$router.resolve({ name: 'contract-detail', params: { id: this.id, tab: 'comments' } })
            if (location.href !== this.$route.path) {
                this.$router.push(location.href).then(async () => {
                    this.activeTab = 'comments'
                    await this.$nextTick()
                    this.$refs.contractCommentList.triggerDeleteItem(comment)
                })
            } else {
                this.$refs.contractCommentList.triggerDeleteItem(comment)
            }
        },
        kebabCase: function (text) {
            return kebabCase(text)
        },
    },
}
</script>

<style lang="scss" scoped>
.contract-product-template-info {
    margin: 0;
    padding: $sizeSpacingMd 0 0;

    border-top: 1px solid var(--color-border-primary);

    font-size: 0.75rem;
}

::v-deep .license-plate {
    margin-bottom: (-($sizeSpacingXs));
}
</style>
