// import indexPageEn from '#/user-manual/tasks/index.en.md'
import indexPageDeCH from '#/user-manual/tasks/index.de-CH.md'

export default {
    key: 'tasks',
    label: { 'en': 'Tasks', 'de-CH': 'Aufgaben' },
    navIcon: 'mi-checklist',
    pages: [
        { 'key': 'index', 'en': indexPageDeCH, 'de-CH': indexPageDeCH },
    ],
}
