import gql from 'graphql-tag'

export const COMMISSION_LIST_ENTRY_UPDATE_MATCHED_CONTRACT_MUTATION = gql`
    mutation commissionListEntryUpdateMatchedContract(
        $id: ID!
        $contractId: ID!
    ) {
        commissionListEntryMatchedContractUpdate(
            id: $id
            contract_id: $contractId
        ) {
            id
            status
            ready_to_post
            commissionList {
                id
            }
            contractMatchShortlist {
                id
                customer_name_match
                contract {
                    id
                    contractNumbers {
                        id
                        number
                    }
                    currentContractInformation {
                        product {
                            name
                            basicProvider {
                                id
                                name
                                display_name
                            }
                        }
                    }
                    customer {
                        id
                        ... on Person {
                            first_name
                            last_name
                            gender
                            personal_pronoun
                            title
                        }
                        ... on Company {
                            company_name
                        }
                        contactNumber {
                            number
                        }
                    }
                }
            }
            matchedContract {
                id
                contractNumbers {
                    id
                    number
                }
                currentContractInformation {
                    product {
                        basicProvider {
                            id
                            name
                            display_name
                        }
                    }
                }
                customer {
                    id
                    ... on Person {
                        first_name
                        last_name
                        gender
                        personal_pronoun
                        title
                    }
                    ... on Company {
                        company_name
                    }
                    contactNumber {
                        number
                    }
                }
            }
            contract_number
            framework_agreement_number
            positions {
                id
                type
                commission_amount
            }
            customer_first_name
            customer_last_name
            customer_company_name
            customer_full_name
            created_manually
            created_at
            updated_at
        }
    }
`
