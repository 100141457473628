<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.accounting.posting.posting', 2)" :to="{ name: 'accounting-posting-list' }" />
            </template>

            <h1>{{ $tc('common.accounting.posting.posting', 2) }}</h1>
        </page-header>

        <base-table
            ref="PostingListTable"
            :columns="columns"
            :fetch-objects-fn="Posting.service.all"
            user-settings-base-path="PostingList"
            pagination-sort-by-default-key="number"
            pagination-initial-order-direction-descending
            @row-dblclick="onDblClick"
        >
            <!-- TODO improvement: This could potentially be refactored as it uses the same code as the BaseTable in `src/components/contract/ContractCommissionPostingsList.vue` -->
            <template v-slot:body-cell-number="slotProps">
                <q-td
                    :props="slotProps"
                    class="text-right"
                    data-test="td:posting-number"
                    auto-width
                >
                    {{ slotProps.row.number.number }}
                </q-td>
            </template>

            <template v-slot:body-cell-debit_account_number="slotProps">
                <q-td :props="slotProps" class="truncate-text" data-test="td:debit-account-number">
                    <span>
                        <span class="account-number">{{ slotProps.row.debitAccount.number }}</span> <span class="account-name additional-info">– {{ slotProps.row.debitAccount.name }}</span>
                        <q-tooltip
                            :delay="1000"
                            anchor="center start"
                            self="center start"
                            :offset="[0, 0]"
                            max-width="70ch"
                        >{{ slotProps.row.debitAccount.number }} – {{ slotProps.row.debitAccount.name }}</q-tooltip>
                    </span>
                </q-td>
            </template>

            <template v-slot:body-cell-credit_account_number="slotProps">
                <q-td :props="slotProps" class="truncate-text" data-test="td:credit-account-number">
                    <span>
                        <span class="account-number">{{ slotProps.row.creditAccount.number }}</span> <span class="account-name additional-info">– {{ slotProps.row.creditAccount.name }}</span>
                        <q-tooltip
                            :delay="1000"
                            anchor="center start"
                            self="center start"
                            :offset="[0, 0]"
                            max-width="70ch"
                        >{{ slotProps.row.creditAccount.number }} – {{ slotProps.row.creditAccount.name }}</q-tooltip>
                    </span>
                </q-td>
            </template>

            <template v-slot:body-cell-amount="slotProps">
                <q-td :props="slotProps" data-test="td:amount">
                    <span :class="['amount', { 'text-negative-darker': slotProps.row.amount < 0 }]">{{ slotProps.row.formattedAmount }}</span>
                </q-td>
            </template>

            <template v-slot:body-cell-text="slotProps">
                <q-td :props="slotProps" class="truncate-text" data-test="td:posting-text">
                    <info-icon
                        v-if="slotProps.row.is_confidential"
                        name="mib-lock-1"
                        :text="$tc('common.accounting.posting.is-confidential--description', 1)"
                        class="q-mr-xs"
                        color="secondary"
                    />
                    <span>
                        {{ slotProps.row.text }}
                        <q-tooltip
                            :delay="1000"
                            anchor="center start"
                            self="center start"
                            :offset="[0, 0]"
                            max-width="70ch"
                        >{{ slotProps.row.text }}</q-tooltip>
                    </span>
                </q-td>
            </template>

            <template v-slot:body-cell-actions="slotProps">
                <q-td :props="slotProps" data-test="td:actions" auto-width>
                    <q-btn
                        color="primary"
                        size="sm"
                        flat
                        round
                        icon="mib-pencil"
                        :to="{ name: 'accounting-posting-update', params: { id: slotProps.row.id } }"
                    >
                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.accounting.posting.show-posting', 1) }}</q-tooltip>
                    </q-btn>
                </q-td>
            </template>
        </base-table>

        <in-page-footer>
            <base-button
                :label="$tc('views.accounting.posting.add-posting', 1)"
                primary-button
                :to="{ name: 'accounting-posting-create' }"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import { Posting } from '@/models/posting'
import BaseTable from '@/components/BaseTable'

export default {
    name: 'PostingList',
    meta () {
        return {
            title: this.$tc('common.accounting.posting.posting', 2),
        }
    },
    components: {
        BaseTable,
    },
    data () {
        return {
            Posting,
            columns: [
                {
                    name: 'date',
                    label: this.$tc('common.accounting.posting.date', 1),
                    field: row => row.formattedDate,
                    align: 'center',
                    sortable: true,
                },
                {
                    name: 'number',
                    label: this.$tc('common.accounting.posting.number', 1),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'debit_account_number',
                    label: this.$tc('common.accounting.posting.debit-account', 1),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'credit_account_number',
                    label: this.$tc('common.accounting.posting.credit-account', 1),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'amount',
                    label: this.$tc('common.accounting.posting.amount', 1),
                    align: 'right',
                    sortable: true,
                },
                {
                    name: 'text',
                    label: this.$tc('common.accounting.posting.text', 1),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'type',
                    label: this.$tc('common.accounting.posting.type', 1),
                    field: row => row.type.name,
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'attachments',
                    label: this.$tc('common.accounting.posting.attachment', 2),
                    field: row => row.attachments.length,
                    align: 'center',
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left' },
            ],
        }
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        onNewEntity () {
            this.$router.push({ name: 'accounting-posting-create' })
        },
        onDblClick (evt, row) {
            this.$router.push({ name: 'accounting-posting-update', params: { id: row.id } })
        },
    },
}
</script>

<style lang="scss" scoped>
.truncate-text {
    max-width: 40ch;
    overflow: hidden;
    text-overflow: ellipsis;
}

.account-number,
.amount {
    font-family: $fontFamilyMono; // TODO @MTR: Check if this makes sense (and is needed).
    font-weight: bold;
    font-variant-numeric: slashed-zero;
}
</style>
