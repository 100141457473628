import gql from 'graphql-tag'

export const APPLICATION_CREATE_MUTATION = gql`
    mutation applicationCreate(
        $productId: ID!
        $productTemplateVersion: Float!
        $customerId: ID!
        $status: ApplicationStatus!
        $submissionSendDate: Date
        $isChangeApplication: Boolean!
        $sourceContractId: ID
        $application: ApplicationInput!
    ) {
        applicationCreate(
            product_id: $productId
            product_template_version: $productTemplateVersion
            customer_id: $customerId
            status: $status
            submission_send_date: $submissionSendDate
            is_change_application: $isChangeApplication
            source_contract_id: $sourceContractId
            application: $application
        ) {
            id
            number {
                number
            }
            consultant {
                id
                contact_number
                first_name
                last_name
                gender
                title
                consultingCompany {
                    id
                }
                consulting_company_name
                consulting_settings_status
            }
        }
    }
`
