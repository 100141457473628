import { kebabCase } from 'lodash'
import { FileType } from '@/enums/graphql'

export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'dateFrom',
            label: 'forms.field.label.account-balance-report.period-from',
            inputType: 'calendar',
            validators: [
                { type: 'required' },
                { type: 'date' },
                {
                    type: 'isBeforeOrEqualToDate',
                    params: {
                        target: 'dateUntil',
                        targetLabel: 'forms.field.label.account-balance-report.period-to',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'dateUntil',
            label: 'forms.field.label.account-balance-report.period-to',
            inputType: 'calendar',
            validators: [
                { type: 'required' },
                { type: 'date' },
                {
                    type: 'isAfterOrEqualToDate',
                    params: {
                        target: 'dateFrom',
                        targetLabel: 'forms.field.label.account-balance-report.period-from',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'fileTypes',
            label: 'forms.field.label.account-balance-report.file-type',
            inputType: 'select',
            extra_attributes: {
                list: {
                    options: [{
                        label: `common.file.type.${kebabCase(FileType.EXCEL)}`,
                        value: FileType.EXCEL,
                    }],
                },
                multiple: false,
            },
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'selectSpecificAccounts', // This field is needed for the functionality of the form, but not rendered. Don't remove!
            inputType: 'checkbox',
        },
        {
            type: 'field',
            key: 'accountNumberFrom',
            label: 'forms.field.label.account-balance-report.account-range--from',
            inputType: 'number',
            validators: [
                {
                    type: 'requiredUnless',
                    errorMessageTranslationKey: 'common.error-message.required',
                    params: {
                        'target': 'selectSpecificAccounts',
                        'targetLabel': 'common.accounting.statement.account-balance-report.select-specific-accounts',
                    },
                },
                { type: 'maxLength', params: { max: 9 } },
            ],
        },
        {
            type: 'field',
            key: 'accountNumberTo',
            label: 'forms.field.label.account-balance-report.account-range--to',
            inputType: 'number',
            validators: [
                {
                    type: 'requiredUnless',
                    errorMessageTranslationKey: 'common.error-message.required',
                    params: {
                        'target': 'selectSpecificAccounts',
                        'targetLabel': 'common.accounting.statement.account-balance-report.select-specific-accounts',
                    },
                },
                {
                    type: 'moreThan',
                    params: {
                        'target': 'accountNumberFrom',
                        'targetLabel': 'common.accounting.account.account-range--from',
                    },
                },
                { type: 'maxLength', params: { max: 9 } },
            ],
        },
    ],
}
