<template>
    <base-input
        :value="displayValue"
        v-bind="$attrs"
        @change="updateValue"
    >
        <slot slot="error" name="error">&nbsp;</slot><!-- &nbsp; needs to be there in order to make this work for cases without error messages. Needs to be re-worked with Vue 3 -->
        <template v-slot:append>
            <q-icon name="mib-calendar-3" class="cursor-pointer">
                <q-popup-proxy
                    ref="proxy"
                    transition-show="scale"
                    transition-hide="scale"
                >
                    <q-date
                        ref="datePicker"
                        :value="datePickerValue"
                        mask="YYYY-MM-DD"
                        first-day-of-week="1"
                        navigation-min-year-month="0004/01"
                        navigation-max-year-month="0004/12"
                        default-year-month="0004/01"
                        minimal
                        @input="updateValueAndHide"
                    />
                </q-popup-proxy>
            </q-icon>
        </template>
    </base-input>
</template>

<script>
import { parse, lightFormat } from 'date-fns'
import { isValidDateInstance, isValidIsoDate } from '@/helpers/form'
import { formatMonthDay, isoDateToMonthDay, monthDayToIsoDate } from '@/helpers/date'
import BaseInput from '@/components/form/BaseInput.vue'

export default {
    name: 'MonthDayInput',
    components: {
        BaseInput,
    },
    inheritAttrs: false,
    props: {
        value: {
            type: String,
            required: true,
        },
    },
    computed: {
        displayValue () {
            if (this.value) {
                const value = monthDayToIsoDate(this.value)
                if (isValidIsoDate(value)) {
                    return formatMonthDay(value)
                }
            }
            return this.value
        },
        datePickerValue () {
            if (this.value) {
                const value = monthDayToIsoDate(this.value)
                if (isValidIsoDate(value)) return value
            }
            return this.value
        },
    },
    methods: {
        updateValue (value) {
            if (value) {
                const dateInstance = parse(value, 'dd.MM.', new Date('0004'))
                if (isValidDateInstance(dateInstance)) {
                    this.$emit('input', isoDateToMonthDay(lightFormat(dateInstance, 'yyyy-MM-dd')))
                    return
                }
            }
            this.$emit('input', value)
        },
        updateValueAndHide (value) {
            this.$emit('input', `-${value.substring(1)}`)
            this.$refs.proxy.hide()
        },
    },
}
</script>

<!-- TODO: Review once we upgrade Quasar. -->
<style lang="scss">
// Only works as long that Quasar doesn't change the HTML structure.
.q-date__navigation > div:nth-last-child(-n + 3) {
    display: none;
}
</style>
