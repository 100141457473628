<template>
    <form
        class="row q-col-gutter-y-md"
        data-test="form:statement"
        @submit.prevent="onFormSubmit"
    >
        <grid-card
            :columns="{ xs: 12 }"
            :heading="$tc('common.term.configuration')"
        >
            <div class="row q-col-gutter-md">
                <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
                    <form-builder item-key="dateFrom" />
                </div>

                <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2">
                    <form-builder item-key="dateUntil" />
                </div>

                <div class="col-xs-6 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                    <form-builder item-key="fileTypes" />
                </div>

                <div class="col-xs-6 col-sm-4 col-md-3 col-lg-5 col-xl-6">
                    <form-builder item-key="includeAttachments" />
                </div>
            </div>
        </grid-card>

        <div class="col-xs-12">
            <base-table
                ref="table"
                :columns="columns"
                :fetch-objects-fn="Contact.service.all"
                :additional-filters="additionalFilters"
                user-settings-base-path="StatementConsultingEntities"
                pagination-sort-by-default-key="contact_name"
                selection="multiple"
                pagination-initial-order-direction-ascending
                :selected.sync="selectedConsultingEntities"
                :selection-row-condition="selectionRowCondition"
                enable-visible-columns
                @update:selected="onUpdateSelected"
            >
                <template v-slot:tabs>
                    <base-toggle
                        v-model="includeInactive"
                        :label="$t('views.accounting.statement.include-inactive-consulting-entities')"
                    />
                </template>

                <template v-slot:selectionRowConditionInfo>
                    <q-checkbox
                        :value="false"
                        class="not-selectable"
                        color="secondary-light"
                        keep-color
                        dense
                        disable
                    />
                </template>

                <template v-slot:body-cell-contact_name=slotProps>
                    <q-td :props=slotProps class="contact-name-td" data-test="text:contact-name">
                        <contact-name
                            :contact="slotProps.row"
                            highlighted
                            comma-separated
                        />
                    </q-td>
                </template>

                <template v-slot:body-cell-current_account="slotProps">
                    <q-td
                        :props="slotProps"
                        :class="['truncate-text', {'text-negative': !(slotProps.row.consultingSettings && slotProps.row.consultingSettings.currentAccount)}]"
                        data-test="td:current_account"
                    >
                        <span v-if="slotProps.row.consultingSettings && slotProps.row.consultingSettings.currentAccount">
                            <span class="account-number">{{ slotProps.row.consultingSettings.currentAccount.number }}</span> <span class="account-name additional-info">– {{ slotProps.row.consultingSettings.currentAccount.name }}</span>
                            <q-tooltip
                                :delay="1000"
                                anchor="center start"
                                self="center start"
                                :offset="[0, 0]"
                                max-width="70ch"
                            >{{ slotProps.row.consultingSettings.currentAccount.number }} – {{ slotProps.row.consultingSettings.currentAccount.name }}</q-tooltip>
                        </span>
                        <template v-else>{{ $tc('common.accounting.account.no-account-assigned', 1) }}</template>
                    </q-td>
                </template>

                <template v-slot:body-cell-cancellation_reserve_account="slotProps">
                    <q-td
                        :props="slotProps"
                        :class="['truncate-text', {'additional-info': !(slotProps.row.consultingSettings && slotProps.row.consultingSettings.cancellationReserveAccount)}]"
                        data-test="td:current_account"
                    >
                        <span v-if="slotProps.row.consultingSettings && slotProps.row.consultingSettings.cancellationReserveAccount">
                            <span class="account-number">{{ slotProps.row.consultingSettings.cancellationReserveAccount.number }}</span> <span class="account-name additional-info">– {{ slotProps.row.consultingSettings.cancellationReserveAccount.name }}</span>
                            <q-tooltip
                                :delay="1000"
                                anchor="center start"
                                self="center start"
                                :offset="[0, 0]"
                                max-width="70ch"
                            >{{ slotProps.row.consultingSettings.cancellationReserveAccount.number }} – {{ slotProps.row.consultingSettings.cancellationReserveAccount.name }}</q-tooltip>
                        </span>
                        <template v-else>{{ $tc('common.accounting.account.no-account-assigned', 1) }}</template>
                    </q-td>
                </template>

                <template v-slot:body-cell-statement_recipient="slotProps">
                    <q-td
                        :props="slotProps"
                        :class="['truncate-text', {'additional-info': !slotProps.row.consultingSettings.hasStatementRecipients}]"
                        data-test="td:statement_recipient"
                    >
                        <span v-if="slotProps.row.consultingSettings.hasStatementRecipients">
                            <!-- TODO improvement @MTR: Highlight recipients if they work for another company than the consultant which the statement is created for -->
                            <span>{{ slotProps.row.consultingSettings.formattedStatementRecipients(true) }}</span>
                            <q-tooltip
                                :delay="1000"
                                anchor="center start"
                                self="center start"
                                :offset="[0, 0]"
                                max-width="70ch"
                            >{{ slotProps.row.consultingSettings.formattedStatementRecipients(true) }}</q-tooltip>
                        </span>
                        <template v-else>{{ $tc('views.contact.settings.consulting-settings.statement-recipient.no-recipient-defined', 1) }}</template>
                    </q-td>
                </template>

                <template v-slot:body-cell-status="slotProps">
                    <q-td :props="slotProps" auto-width>
                        <status-badge :status="slotProps.row.consultingSettings.status" />
                    </q-td>
                </template>
            </base-table>
        </div>

        <in-page-footer>
            <base-button
                :label="$t('common.term.cancel')"
                data-test="btn:cancel"
                primary-button
                flat
                @click="handleCancel"
            />
            <!-- TODO improvement @TFU: Check if handling could be the same as on the list (n of x / eligibility) -->
            <base-button
                type="submit"
                :label="$tc('common.accounting.statement.create-statement', selectedConsultingEntities.length)"
                data-test="btn:submit"
                :disable="formSubmitStatus || !selectedConsultingEntities.length"
                :loading="formSubmitStatus"
                primary-button
                @click="formData.triggerFileStorageItemSharing = false"
            />
            <!-- TODO improvement @TFU: Check if handling could be the same as on the list (n of x / eligibility) -->
            <base-button
                type="submit"
                :label="$tc('common.accounting.statement.create-and-share', selectedConsultingEntities.length)"
                data-test="btn:submit"
                :disable="formSubmitStatus || !selectedConsultingEntities.length || disableCreateAndShare"
                :loading="formSubmitStatus"
                primary-button
                outline
                @click="formData.triggerFileStorageItemSharing = true"
            />
        </in-page-footer>
    </form>
</template>

<script>
import { lightFormat, startOfMonth, endOfMonth } from 'date-fns'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import STATEMENT_FORM from '@/forms/statement.form'
import { Contact } from '@/models/contact'
import { ConsultingSettingsStatus } from '@/enums/graphql'

import BaseToggle from '@/components/form/BaseToggle.vue'
import BaseTable from '@/components/BaseTable.vue'
import ContactName from '@/components/contact/ContactName.vue'
import StatusBadge from '@/components/StatusBadge.vue'

export default {
    name: 'StatementForm',
    meta () {
        return {
            title: this.$tc('common.accounting.statement.create-statement', 1),
        }
    },
    components: {
        BaseToggle,
        BaseTable,
        ContactName,
        StatusBadge,
    },
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: STATEMENT_FORM,
    },
    data () {
        return {
            Contact,
            loading: false,
            includeInactive: false,
            selectedConsultingEntities: [],
            columns: [
                {
                    name: 'contact_name',
                    label: this.$tc('common.term.name'),
                    sortable: true,
                    align: 'left',
                    required: true,
                },
                {
                    name: 'company',
                    label: this.$tc('common.term.company'),
                    field: row => row.consultingSettings?.company?.company_name || '–',
                    align: 'left',
                    classes: row => !row.consultingSettings?.company?.company_name ? 'additional-info' : null,
                },
                {
                    name: 'current_account',
                    label: this.$tc('common.accounting.account.type.current-account'),
                    align: 'left',
                    required: true,
                },
                {
                    name: 'cancellation_reserve_account',
                    label: this.$tc('common.accounting.account.type.cancellation-reserve-account'),
                    align: 'left',
                    required: true,
                },
                {
                    name: 'statement_recipient',
                    label: this.$tc('common.accounting.statement.statement-recipient', 2),
                    align: 'left',
                },
                {
                    name: 'status',
                    label: this.$tc('common.term.status'),
                    align: 'left',
                    required: true,
                },
            ],
            formData: {
                dateFrom: lightFormat(startOfMonth(new Date()), 'yyyy-MM-dd'),
                dateUntil: lightFormat(endOfMonth(new Date()), 'yyyy-MM-dd'),
                triggerFileStorageItemSharing: false,
            },
        }
    },
    computed: {
        additionalFilters () {
            const filters = {
                consultingSettingsStatus: this.includeInactive ? [ConsultingSettingsStatus.ACTIVE, ConsultingSettingsStatus.INACTIVE] : [ConsultingSettingsStatus.ACTIVE],
                hasConsultingSettings: true,
            }
            return filters
        },
        disableCreateAndShare () {
            return !this.selectedConsultingEntities.every(contact => contact.consultingSettings.statementRecipients !== null && contact.consultingSettings.statementRecipients.consultants.length > 0)
        },
    },
    methods: {
        handleFormData (formData) {
            const consultingEntityIds = this.selectedConsultingEntities.map(consultant => consultant.id)
            this.$emit('post-handle-form-data', { formData: formData, consultingEntityIds: consultingEntityIds })
        },
        handleCancel () {
            this.$router.push({ name: 'accounting-statements' })
        },
        selectionRowCondition (row) {
            return row.consultingSettings.currentAccount
        },
        resetSelectedItems () {
            this.selectedConsultingEntities.length = 0
            if (this.$refs.table) this.$refs.table.$refs.qTable.clearSelection()
        },
        onUpdateSelected () {
            const validSelectedItems = this.selectedConsultingEntities.filter(this.selectionRowCondition)
            this.selectedConsultingEntities.length = 0
            this.selectedConsultingEntities.push(...validSelectedItems)
        },
    },
}
</script>

<style lang="scss" scoped>
.truncate-text {
    max-width: 40ch;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
