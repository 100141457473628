import gql from 'graphql-tag'

export const TASKS_QUERY = gql`
    query tasks(
        $page: Int = 1
        $count: Int = 1000
        $orderField: TaskOrderField = DUE_DATE
        $orderDirection: OrderDirection = ASC
        $filterQuery: String
        $filterStatus: [TaskStatus!]
        $filterAssigneeId: ID
        $filterCreatorId: ID
        $filterDueDateFrom: DateTimeTz
        $filterDueDateUntil: DateTimeTz
        $filterTargetObjectType: TaskTargetObjectType
        $filterTargetObjectId: ID
    ) {
        tasks(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                query: $filterQuery
                status: $filterStatus
                assignee_id: $filterAssigneeId
                creator_id: $filterCreatorId
                due_date_from: $filterDueDateFrom
                due_date_until: $filterDueDateUntil
                target_object_id: $filterTargetObjectId
                target_object_type: $filterTargetObjectType
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                status
                creator {
                    id
                    first_name
                    last_name
                    consultingCompany {
                        id
                    }
                    consulting_company_name
                }
                assignee {
                    id
                    first_name
                    last_name
                    consultingCompany {
                        id
                    }
                    consulting_company_name
                }
                visibility
                subject
                attachments {
                    id
                }
                target_object_type
                target_object_id
                targetObject {
                    ... on Person {
                        contactNumber {
                            id
                        }
                        first_name
                        last_name
                    }
                    ... on Company {
                        contactNumber {
                            id
                        }
                        company_name
                    }
                    ... on Application {
                        number {
                            number
                        }
                        customer {
                            ... on Person {
                                contactNumber {
                                    id
                                }
                                first_name
                                last_name
                            }
                            ... on Company {
                                contactNumber {
                                    id
                                }
                                company_name
                            }
                        }
                    }
                    ... on Contract {
                        contractNumbers {
                            number
                        }
                        customer {
                            ... on Person {
                                contactNumber {
                                    id
                                }
                                first_name
                                last_name
                            }
                            ... on Company {
                                contactNumber {
                                    id
                                }
                                company_name
                            }
                        }
                    }
                }
                due_date
                created_at
                updated_at
                done_at
            }
        }
    }
`
