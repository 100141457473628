import gql from 'graphql-tag'

export const CONTACT_RELATIONSHIP_CREATE_MUTATION = gql`
    mutation contactRelationshipCreate(
        $definitionId: ID!
        $node1Id: ID!
        $node2Id: ID!
        $contactRelationship: ContactRelationshipInput!
        $areasOfResponsibility: [AreaOfResponsibilityInput!]
    ) {
        contactRelationshipCreate(
            definition_id: $definitionId,
            node1_id: $node1Id,
            node2_id: $node2Id,
            contactRelationship: $contactRelationship,
            areasOfResponsibility: $areasOfResponsibility
        ) {
            id
            definition {
                id
                type
                categories
                node1_type
                node1_valid_contact_types
                node2_type
                node2_valid_contact_types
            }
            node1 {
                id
                contactNumber {
                    id
                    number
                }
                ... on Person {
                    gender
                    personal_pronoun
                    title
                    first_name
                    last_name
                }
                ... on Company {
                    company_name
                    is_tenant_company
                }
            }
            node2 {
                id
                contactNumber {
                    id
                    number
                }
                ... on Person {
                    gender
                    personal_pronoun
                    title
                    first_name
                    last_name
                }
                ... on Company {
                    company_name
                    is_tenant_company
                }
            }
            notes
            areasOfResponsibility {
                id
            }
            created_at
            updated_at
        }
    }
`
