import Vue from 'vue'
import VueI18n from 'vue-i18n'
import defaultMessages from '@/locales/de-CH.json'
import { Quasar } from 'quasar'
import langDe from 'quasar/lang/de'

Vue.use(VueI18n)

// App
// TODO: Check if these labels should also be translated
export const availableLanguages = [
    {
        label: 'Deutsch',
        value: 'de-CH',
    },
    {
        label: 'English',
        value: 'en',
    },
]
const availableLocales = ['de-CH', 'en']
const loadedLocales = ['de-CH']
const initialLocale = process.env.VUE_APP_I18N_LOCALE || 'de-CH'
const initialFallbacklocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de-CH'

// Quasar
const appToQuasarLocaleMapping = {
    'de-CH': 'de',
    'en': 'en-us',
}
const loadedQuasarLanguagePacks = {
    'de': langDe,
}

export const i18n = new VueI18n({
    locale: initialLocale,
    fallbackLocale: initialFallbacklocale,
    messages: {
        'de-CH': defaultMessages,
    },
})

function setLocale (locale) {
    // Quasar
    const quasarLocale = appToQuasarLocaleMapping[locale]
    const quasarLanguagePack = loadedQuasarLanguagePacks[quasarLocale]
    if (quasarLanguagePack) Quasar.lang.set(quasarLanguagePack)

    // App
    i18n.locale = locale
    document.querySelector('html').setAttribute('lang', locale)
    localStorage.setItem('locale', locale)
    return locale
}

export async function loadLocaleAsync (locale) {
    // Check if locale is supported
    if (!availableLocales.includes(locale)) return false

    // If current locale or locale already loaded
    if (i18n.locale === locale || loadedLocales.includes(locale)) {
        return Promise.resolve(setLocale(locale))
    }

    // Load quasar language pack
    const quasarLocale = appToQuasarLocaleMapping[locale]
    const quasarLanguagePack = await import(
        /* webpackInclude: /(de|en-us)\.js$/ */
        'quasar/lang/' + quasarLocale
    )
    loadedQuasarLanguagePacks[quasarLocale] = quasarLanguagePack.default

    // If the locale hasn't been loaded yet
    return import(/* webpackChunkName: "lang-[request]" */ '@/locales/' + locale + '.json').then( // Can't use template literals because of issue described here: https://github.com/babel/babel-eslint/issues/681#issuecomment-652141552
        messages => {
            i18n.setLocaleMessage(locale, messages.default)
            loadedLocales.push(locale)
            return setLocale(locale)
        }
    )
}

/**
 * Checks if the current locale is one of the systemLanguages defined by the tenant.
 * Switches to defaultSystemLanguage instead, if this not the case.
 * @param {Object[]} systemLanguages
 * @param {string} systemLanguages[].id
 * @param {string} systemLanguages[].name
 * @param {Object} defaultSystemLanguage
 * @param {string} defaultSystemLanguage.id
 * @param {string} defaultSystemLanguage.name
 * @returns {boolean}
 */
export function ensureLocaleConformity (systemLanguages, defaultSystemLanguage) {
    if (systemLanguages.findIndex(systemLanguage => systemLanguage.id === i18n.locale) > -1) {
        return true
    } else {
        loadLocaleAsync(defaultSystemLanguage.id)
        return false
    }
}

const localStorageLocale = localStorage.getItem('locale')

if (localStorageLocale) { // Load initial locale from localStorage
    if (localStorageLocale !== initialLocale && availableLocales.includes(localStorageLocale)) {
        loadLocaleAsync(localStorageLocale)
    }
} else if (navigator.languages.length) { // Derive initial locale from browser settings
    navigator.languages.some(language => {
        if (availableLocales.includes(language)) { // Direct match
            if (language === initialLocale) {
                localStorage.setItem('locale', language)
            } else {
                loadLocaleAsync(language)
            }
            return true
        } else { // Partial match
            const locale = availableLocales.find(locale => {
                return locale.split('-')[0] === language.split('-')[0]
            })
            if (locale) {
                if (locale === initialLocale) {
                    localStorage.setItem('locale', locale)
                } else {
                    loadLocaleAsync(locale)
                }
                return true
            }
        }
    })
}
