import { FormField } from '@/libs/form'
import { ProductDerivedEntityForm } from '@/forms/productDerivedEntity'

export class ContractForm extends ProductDerivedEntityForm {
    compose () {
        super.compose()

        this.addField(new FormField('contractNumber', {
            label: 'common.contract.contract-number',
            inputType: 'text',
            validators: [
                { type: 'required' },
            ],
        }))
    }
}
