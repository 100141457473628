import gql from 'graphql-tag'

export const USERS_QUERY = gql`
    query Users(
        $page: Int!
        $count: Int!
        $orderField: UserOrderField = USER_EMAIL
        $orderDirection: OrderDirection = DESC
        $filterQuery: String
        $filterStatus: UserStatus = null
    ) {
        users(
            page: $page
            count: $count
            order: [
                {
                    field: $orderField
                    direction: $orderDirection
                }
            ]
            search: {
                user_email: $filterQuery,
                user_status: $filterStatus
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                email
                new_email
                status
                consultant {
                    first_name
                    last_name
                    consultingCompany {
                        id
                        company_name
                    }
                }
                roles {
                    name
                    description
                }
            }
        }
    }
`
