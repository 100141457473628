<template>
    <page-loading-indicator
        v-if="!productTemplate"
        :number-of-breadcrumb-elements="2"
        subtitle
        actions
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.product.product-template', 2)" :to="{ name: 'admin-product-template-list' }" />
                <q-breadcrumbs-el :label="productTemplate.name" :to="{ name: 'admin-product-template-update', params: { id: productTemplate.id } }" />
            </template>

            <h1>{{ $t('views.admin.products.product-templates.update-title') }}: {{ productTemplate.name }} <span class="subtitle">({{ $tc('common.term.version', 1) }} {{ productTemplate.version }})</span></h1>

            <template v-slot:actions>
                <actions
                    v-if="productTemplate.actions"
                    :actions="productTemplate.actions"
                    @click="handleActions"
                />

                <form-dialog
                    ref="productTemplateArchiveDialog"
                    @confirm="handleProductTemplateArchive"
                >
                    <template v-slot:title>{{ $tc('common.actions.product-template.archive', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.products.product-templates.archive-product-template--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:name><b>{{ productTemplate.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
                <form-dialog
                    ref="productTemplateUnarchiveDialog"
                    @confirm="handleProductTemplateUnarchive"
                >
                    <template v-slot:title>{{ $tc('common.actions.product-template.unarchive', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.products.product-templates.unarchive-product-template--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:name><b>{{ productTemplate.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
                <!-- TODO: enhance v-if with defined conditions which must be met to be able to delete: Only possible if no active or archived Products exist that have been created based on this ProductTemplate -->
                <form-dialog
                    ref="productTemplateDeleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleProductTemplateDelete"
                >
                    <template v-slot:title>{{ $tc('common.actions.product-template.delete', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.products.product-templates.delete-product-template--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:name><b>{{ productTemplate.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <div class="row">
            <div class="col-xs-12 col-md-7 col-lg-6 col-xl-5 q-gutter-y-sm">
                <product-template-form ref="productTemplateForm" :product-template="productTemplate" @submit="onSubmit" />
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import ProductTemplateForm from '@/components/product/ProductTemplateForm.vue'
import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'

import { ProductTemplate } from '@/models/productTemplate'

export default {
    name: 'ProductTemplateUpdate',
    meta () {
        return {
            title: !this.productTemplate ? `${this.$t('common.term.loading')} …` : `${this.$t('views.admin.products.product-templates.update-title')}: ${this.productTemplate.name}`,
        }
    },
    components: {
        ProductTemplateForm,
        Actions,
        FormDialog,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            ProductTemplate,
            productTemplate: null,
            confirmDeletion: false,
        }
    },
    created () {
        ProductTemplate.objects.get(this.id).then(productTemplate => {
            this.productTemplate = productTemplate
        })
    },
    methods: {
        onSubmit ({ name, productCategory, templateConfiguration }) {
            this.productTemplate.update(name, productCategory.category, productCategory.subCategory, templateConfiguration)
                .then(() => {
                    this.$router.replace({ name: 'admin-product-template-list' })
                })
                .catch(error => {
                    this.$refs.productTemplateForm.showFormErrorMessage(error)
                    this.$refs.productTemplateForm.resetFormSubmitStatus()
                })
        },
        handleActions (action) {
            switch (action.key) {
                case ProductTemplate.action.ARCHIVE:
                    this.$refs.productTemplateArchiveDialog.open()
                    break
                case ProductTemplate.action.UNARCHIVE:
                    this.$refs.productTemplateUnarchiveDialog.open()
                    break
                case ProductTemplate.action.DELETE:
                    this.$refs.productTemplateDeleteDialog.open()
                    break
                case ProductTemplate.action.EXPORT:
                    this.productTemplate.export()
                    break
            }
        },
        handleProductTemplateArchive () {
            this.productTemplate.archive()
                .then(() => {
                    // TODO: Add notification?
                })
        },
        handleProductTemplateUnarchive () {
            this.productTemplate.unarchive()
                .then(() => {
                    // TODO: Add notification?
                })
        },
        handleProductTemplateDelete () {
            this.productTemplate.delete()
                .then(() => {
                    this.$refs.productTemplateDeleteDialog.close()
                    this.$router.replace({ name: 'admin-product-template-list' })
                })
                .catch(error => {
                    this.$refs.productTemplateDeleteDialog.showFormErrorMessage(error)
                    this.$refs.productTemplateDeleteDialog.resetFormSubmitStatus()
                })
        },
    },
}
</script>
