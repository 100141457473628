import { Model } from '@/models'
import { EditorContent } from '@/models/models'
import { CorrespondenceSalutationService } from '@/services'
import { CorrespondenceSalutationActions } from '@/enums'

export class CorrespondenceSalutation extends Model {
    /**
     * Create a CorrespondenceSalutation model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'contact_type',
            'formality',
            'gender',
            'language',
            'is_default',
            'salutation',
        ], data)
    }

    static get service () { return CorrespondenceSalutationService }
    static action = CorrespondenceSalutationActions

    /**
     * Create a new correspondenceSalutation.
     * @param {Object} variables - The values used to create the correspondenceSalutation.
     * @param {string} variables.contactType
     * @param {string} variables.formality
     * @param {string} variables.gender
     * @param {string} variables.languageId
     * @param {string} variables.salutation
     * @returns {Promise<*>}
     */
    static create ({ contactType, formality, gender, languageId, salutation }) {
        const variables = {
            contactType,
            formality,
            gender,
            languageId,
            salutation: new EditorContent(salutation).getVariablesObject(),
        }

        return CorrespondenceSalutationService.create(variables)
    }

    /**
     * Update this correspondenceSalutation.
     * @param {Object} variables - The values used to update the correspondenceSalutation.
     * @param {string} variables.salutation
     * @returns {Promise<*>}
     */
    update ({ salutation }) {
        return CorrespondenceSalutationService.update(this.id, new EditorContent(salutation).getVariablesObject()).then(correspondenceSalutation => {
            Object.assign(this, correspondenceSalutation)
            return correspondenceSalutation
        })
    }

    /**
     * Sets this correspondenceSalutation as default.
     * @returns {Promise<*>}
     */
    setDefault () {
        return CorrespondenceSalutationService.setDefault(this.id).then(correspondenceSalutation => {
            Object.assign(this, correspondenceSalutation)
            return correspondenceSalutation
        })
    }

    /**
     * Delete this correspondenceSalutation.
     * @returns {Promise<*>}
     */
    delete () {
        return CorrespondenceSalutationService.delete(this.id)
    }

    get actions () {
        const actions = super.actions

        if (!this.is_default) {
            actions.push({
                key: CorrespondenceSalutationActions.SET_DEFAULT,
                item: this,
            },
            {
                key: CorrespondenceSalutationActions.DELETE,
                item: this,
            })
        }

        return actions
    }
}
