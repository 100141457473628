import gql from 'graphql-tag'

export const DRIVE_QUERY = gql`
    query drive(
        $page: Int!,
        $count: Int!,
        $orderField: FileStorageItemsOrderField = CREATED_AT
        $orderDirection: OrderDirection = DESC
        $search: FileStorageItemsInputSearch,
    ) {
        drive {
            fileStorageItems(
                page: $page
                count: $count
                order: [{ field: $orderField, direction: $orderDirection }]
                search: $search
            ) {
                paginatorInfo {
                    count
                    firstItem
                    lastItem
                    total
                    currentPage
                    lastPage
                    perPage
                    hasMorePages
                }
                data {
                    id
                    type
                    files {
                        id
                        name
                        size
                        mime_type
                        created_at
                        updated_at
                    }
                    targetObject {
                        ... on BasicStatement {
                            id
                            consultingEntity {
                                ... on Consultant {
                                    id
                                    contact_number
                                    first_name
                                    last_name
                                    consulting_company_name
                                    consultingCompany {
                                        id
                                    }
                                }
                                ... on ConsultingCompany {
                                    id
                                    contact_number
                                    company_name
                                }
                            }
                            date_from
                            date_until
                        }
                        ... on BasicAccountBalanceReport {
                            id
                            accountBalanceReportAccounts: accounts {
                                number
                                name
                            }
                            account_number_from
                            account_number_to
                            date_from
                            date_until
                        }
                        ... on BasicAccountStatementReport {
                            id
                            accountStatementReportAccounts: accounts {
                                number
                                name
                            }
                            date_from
                            date_until
                        }
                    }
                    acknowledged_at
                    created_at
                    updated_at
                }
                total_of_items_per_type
                total_of_not_yet_acknowledged_items_per_type
            }
            total_of_items_per_type
            total_of_not_yet_acknowledged_items_per_type
        }
    }
`
