<template>
    <page-wrapper>
        <page-header>
            <h1>{{ $tc('common.commission.excel-mapping', 2) }}</h1>

            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.commission.excel-mapping', 2)" :to="{ name: 'commission-list-import-excel-mappings' }" />
            </template>
        </page-header>
        <base-table
            ref="commissionListImportExcelMappingListTable"
            :columns="columns"
            :fetch-objects-fn="CommissionListImportExcelMapping.service.all"
            user-settings-base-path="CommissionListImportExcelMappingList"
            pagination-sort-by-default-key="name"
            data-test="table:commission-excel-mapping-list"
            @row-dblclick="onDblClick"
        >
            <template v-slot:body-cell-product_provider_name="slotProps">
                <q-td :props="slotProps">
                    <template v-if="slotProps.row.productProvider.display_name">
                        {{ slotProps.row.productProvider.name }} (<b>{{ slotProps.row.productProvider.display_name }}</b>)
                    </template>
                    <b v-else>{{ slotProps.row.productProvider.name }}</b>
                </q-td>
            </template>

            <template v-slot:body-cell-actions="slotProps">
                <q-td :props="slotProps" auto-width>
                    <q-btn
                        color="primary"
                        size="sm"
                        flat
                        round
                        icon="mib-pencil"
                        :to="{ name: 'commission-list-import-excel-mapping-update', params: { id: slotProps.row.id } }"
                        data-test="link:tooltip-actions"
                    >
                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.commission.excel-mapping.update-excel-mapping', 1) }}</q-tooltip>
                    </q-btn>
                </q-td>
            </template>
        </base-table>
        <in-page-footer>
            <base-button
                :label="$tc('views.commission.excel-mapping.create-excel-mapping', 1)"
                primary-button
                :to="{ name: 'commission-list-import-excel-mapping-create' }"
                data-test="link:add-commission-excel-mapping"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { CommissionListImportExcelMapping } from '@/models/commissionListImportExcelMapping'
import BaseTable from '@/components/BaseTable'
import { EventBus } from '@/event-bus'

export default {
    name: 'CommissionListImportExcelMappingList',
    meta () {
        return {
            title: this.$tc('common.commission.excel-mapping', 2),
        }
    },
    components: {
        BaseTable,
    },
    data () {
        return {
            CommissionListImportExcelMapping,
            columns: [
                {
                    name: 'name',
                    label: this.$tc('common.term.name', 1),
                    field: row => row.name,
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'product_provider_name',
                    label: this.$tc('common.product.product-provider', 1),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'updated_at',
                    label: this.$t('common.term.updated--last-updated'),
                    field: row => row.formattedUpdatedAt,
                    sortable: true,
                    align: 'left',
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left' },
            ],
        }
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        onNewEntity () {
            this.$router.push({ name: 'commission-list-import-excel-mapping-create' })
        },
        onDblClick (evt, row) {
            this.$router.push({ name: 'commission-list-import-excel-mapping-update', params: { id: row.id } })
        },
    },
}
</script>
