import gql from 'graphql-tag'

export const MAIL_PROCESSING_TEMPLATE_CREATE_MUTATION = gql`
    mutation mailProcessingTemplateCreate(
        $mailProcessingTemplate: MailProcessingTemplateInput!
    ) {
        mailProcessingTemplateCreate(
            mailProcessingTemplate: $mailProcessingTemplate
        ) {
            id
        }
    }
`
