<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el
                    :label="$tc('common.commission.excel-mapping', 2)"
                    :to="{ name: 'commission-list-import-excel-mappings' }"
                />
                <q-breadcrumbs-el
                    :label="$tc('views.commission.excel-mapping.create-excel-mapping', 1)"
                    :to="{ name: 'commission-list-import-excel-mapping-create' }"
                />
            </template>
            <h1>{{ $tc('views.commission.excel-mapping.create-excel-mapping', 1) }}</h1>
        </page-header>

        <commission-list-import-excel-mapping-form ref="commissionListImportExcelMappingForm" @submit="onSubmit" />
    </page-wrapper>
</template>

<script>
import CommissionListImportExcelMappingForm from '@/components/commission/CommissionListImportExcelMappingForm.vue'
import { CommissionListImportExcelMapping } from '@/models/commissionListImportExcelMapping'

export default {
    name: 'CommissionListImportExcelMappingCreate',
    meta () {
        return {
            title: this.$tc('views.commission.excel-mapping.create-excel-mapping', 1),
        }
    },
    components: {
        CommissionListImportExcelMappingForm,
    },
    methods: {
        onSubmit (formData) {
            const commissionListImportExcelMappingInput = {
                commission_entries_start_row: parseInt(formData.commissionEntriesStartRow),
                commission_amount_column: formData.commissionAmountColumn,
                commission_type_column: formData.commissionTypeColumn,
                contract_number_column: formData.contractNumberColumn,
                customer_full_name_column: formData.customerFullNameColumn,
                name: formData.name,
                premium_from_column: formData.premiumFromColumn,
                premium_from_date_format: formData.premiumFromDateFormat,
                premium_to_column: formData.premiumToColumn,
                premium_to_date_format: formData.premiumToDateFormat,
            }

            CommissionListImportExcelMapping.create(formData.productProvider, commissionListImportExcelMappingInput)
                .then(() => {
                    this.$router.replace({ name: 'commission-list-import-excel-mappings' })
                })
                .catch(error => {
                    this.$refs.commissionListImportExcelMappingForm.showFormErrorMessage(error)
                    this.$refs.commissionListImportExcelMappingForm.resetFormSubmitStatus()
                })
        },
    },
}
</script>
