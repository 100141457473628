<template>
    <page-wrapper>
        <page-header>
            <h1 class="q-mb-none">{{ $tc('common.task.task', 2) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <info-box v-if="currentTaskFilterMode === TaskFilterMode.QUERYSTRING" :inline-actions="$q.screen.width > 1300">
                    <p><b>{{ $tc('common.filter.temporary-filter-is-active', 1) }}</b> <info-icon :text="$tc('common.filter.temporary-filter--description', 1)" color="info" /></p>
                    <template v-slot:action>
                        <base-button
                            :label="$tc('common.filter.deactivate-temporary-filter', 1)"
                            outline
                            @click="clearQueryStringFilter"
                        />
                    </template>
                </info-box>

                <base-table
                    ref="tasksTable"
                    :columns="columns"
                    :visible-columns="visibleColumns"
                    :fetch-objects-fn="Task.service.all"
                    :additional-filters="additionalFilters"
                    user-settings-base-path="TaskList"
                    :pagination-sort-by-default-key="paginationSortByDefaultKey"
                    :pagination-initial-order-direction-descending="paginationInitialOrderDirectionDescending"
                    data-test="table:task-list"
                    @row-dblclick="onDblClick"
                >
                    <template v-slot:top>
                        <div class="col-xs-12 flex q-mb-sm">
                            <q-tabs
                                dense
                                no-caps
                                inline-label
                                align="left"
                                class="q-mr-sm bg-background-secondary"
                                indicator-color="accent"
                                data-test="tabs:links"
                            >
                                <q-route-tab :to="{ name: 'task-list'}" :label="$t('common.status.pending')" data-test="tab:task-pending" />
                                <q-route-tab :to="{ name: 'task-list', params: { tab: 'done' } }" :label="$t('common.status.done')" data-test="tab:task-done" />
                            </q-tabs>

                            <q-space />

                            <base-toggle
                                :value="currentTaskFilter.data.activeAdvancedFilters"
                                :label="$tc('common.term.advanced-filter', 1)"
                                left-label
                                :class="['q-mr-xs', { 'temporary-filter-active': currentTaskFilterMode === TaskFilterMode.QUERYSTRING }]"
                                @input="currentTaskFilter.data.activeAdvancedFilters = !currentTaskFilter.data.activeAdvancedFilters"
                            />
                            <filter-input v-model="filter" :label="$tc('common.task.search-task', 1)" @clear-filter="clearFilter" />
                        </div>

                        <template v-if="currentTaskFilter.data.activeAdvancedFilters">
                            <div :class="['col-xs-12 q-pt-md q-pb-md q-mb-sm advanced-filter-wrapper', { 'temporary-filter-active': currentTaskFilterMode === TaskFilterMode.QUERYSTRING }]">
                                <div class="row q-col-gutter-md">
                                    <div class="col-xs-12 col-md-6">
                                        <q-list bordered>
                                            <q-expansion-item
                                                group="date-filter"
                                                :class="['advanced-filter-expansion-item', { 'is-active': currentTaskFilter.data.advancedFilters.dueDateFrom || currentTaskFilter.data.advancedFilters.dueDateUntil }]"
                                                expand-icon="mib-arrow-left-1"
                                                expanded-icon="mib-arrow-down-1"
                                                default-opened
                                            >
                                                <template v-slot:header>
                                                    <q-item-section>
                                                        {{ $t('views.task.list.filter-by-due-date') }}
                                                    </q-item-section>
                                                    <q-item-section side>
                                                        <div class="row items-center">
                                                            <q-btn
                                                                v-if="currentTaskFilter.data.advancedFilters.dueDateFrom || currentTaskFilter.data.advancedFilters.dueDateUntil"
                                                                color="primary"
                                                                size="sm"
                                                                flat
                                                                round
                                                                icon="mib-filter-2"
                                                                @click="triggerClearDueDateFilter"
                                                            >
                                                                <q-tooltip
                                                                    anchor="center start"
                                                                    self="center right"
                                                                    :delay="1000"
                                                                    :offset="[10, 0]"
                                                                >
                                                                    {{ $tc('common.filter.filter-is-active--click-to-reset', 1) }}
                                                                </q-tooltip>
                                                            </q-btn>
                                                        </div>
                                                    </q-item-section>
                                                </template>
                                                <q-card>
                                                    <q-card-section class="q-pt-xs q-pl-xs q-col-gutter-x-sm">
                                                        <q-list>
                                                            <q-item class="q-pr-none q-pl-none">
                                                                <q-item-section avatar>
                                                                    <q-radio
                                                                        v-model="currentTaskFilter.data.advancedFilters.dueDateRangeFilterType"
                                                                        :val="TaskDueDateRangeFilterType.DEFAULT"
                                                                    />
                                                                </q-item-section>
                                                                <q-item-section>
                                                                    <q-item-label class="q-mb-xs">{{ $t('views.task.list.filter-by-due-date--date-range') }}</q-item-label>
                                                                    <div class="row q-col-gutter-sm">
                                                                        <div class="col-xs-6 col-sm-4 col-md-6 col-lg-4">
                                                                            <calendar-input
                                                                                v-model="currentTaskFilter.data.advancedFilters.dueDateFrom"
                                                                                :placeholder="$t('common.term.date-from')"
                                                                                :disable="currentTaskFilter.data.advancedFilters.dueDateRangeFilterType !== TaskDueDateRangeFilterType.DEFAULT"
                                                                            />
                                                                        </div>
                                                                        <div class="col-xs-6 col-sm-4 col-md-6 col-lg-4">
                                                                            <calendar-input
                                                                                v-model="currentTaskFilter.data.advancedFilters.dueDateUntil"
                                                                                :placeholder="$t('common.term.date-until')"
                                                                                :disable="currentTaskFilter.data.advancedFilters.dueDateRangeFilterType !== TaskDueDateRangeFilterType.DEFAULT"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </q-item-section>
                                                            </q-item>

                                                            <q-item class="q-pr-none q-pl-none">
                                                                <q-item-section avatar>
                                                                    <q-radio
                                                                        v-model="currentTaskFilter.data.advancedFilters.dueDateRangeFilterType"
                                                                        :val="TaskDueDateRangeFilterType.DYNAMIC"
                                                                    />
                                                                </q-item-section>
                                                                <q-item-section>
                                                                    <q-item-label class="q-mb-xs">{{ $t('views.task.list.filter-by-due-date--dynamic-date-range') }}</q-item-label>
                                                                    <div class="dynamic-date-range-wrapper row q-col-gutter-sm">
                                                                        <div class="input-wrapper col-xs-3 col-sm-3 col-md-4 col-lg-3">
                                                                            <q-btn
                                                                                class="due-date-range-nav-button q-mr-sm"
                                                                                flat
                                                                                color="secondary"
                                                                                size="sm"
                                                                                icon="mib-arrow-left-1"
                                                                                :disabled="!currentTaskFilter.data.advancedFilters.dueDateRangeUnit"
                                                                                @click="() => currentTaskFilter.shiftDueDateRangeEarlier()"
                                                                            >
                                                                                <q-tooltip v-if="currentTaskFilter.data.advancedFilters.dueDateRangeUnit" :delay="1000" :offset="[0, 10]">{{ $tc(`common.time.${currentTaskFilter.data.advancedFilters.dueDateRangeUnit.toLowerCase()}--previous-n-${currentTaskFilter.data.advancedFilters.dueDateRangeUnit.toLowerCase()}s`, currentTaskFilter.data.advancedFilters.dueDateRangeAmount) }}</q-tooltip>
                                                                            </q-btn>

                                                                            <!-- TODO improvement: Check if a `max` prop would make sense (maybe with different values depending on the selected `advancedFilters.dueDateRangeUnit)` -->
                                                                            <base-input
                                                                                v-model.number="currentTaskFilter.data.advancedFilters.dueDateRangeAmount"
                                                                                type="number"
                                                                                min="1"
                                                                                step="1"
                                                                                :disable="currentTaskFilter.data.advancedFilters.dueDateRangeFilterType !== TaskDueDateRangeFilterType.DYNAMIC"
                                                                            />
                                                                        </div>
                                                                        <div class="input-wrapper col-xs-9 col-sm-5 col-md-8 col-lg-5">
                                                                            <!-- TODO @TFU: Get label translations in :options with "dynamic" amount -->
                                                                            <q-select
                                                                                v-model="currentTaskFilter.data.advancedFilters.dueDateRangeUnit"
                                                                                :options="advancedFilters.dueDateRangeUnitOptions"
                                                                                emit-value
                                                                                map-options
                                                                                dense
                                                                                filled
                                                                                square
                                                                                :disable="currentTaskFilter.data.advancedFilters.dueDateRangeFilterType !== TaskDueDateRangeFilterType.DYNAMIC"
                                                                            />

                                                                            <q-btn
                                                                                class="due-date-range-nav-button q-ml-sm"
                                                                                flat
                                                                                color="secondary"
                                                                                size="sm"
                                                                                icon="mib-arrow-right-1"
                                                                                :disabled="!currentTaskFilter.data.advancedFilters.dueDateRangeUnit"
                                                                                @click="() => currentTaskFilter.shiftDueDateRangeLater()"
                                                                            >
                                                                                <q-tooltip v-if="currentTaskFilter.data.advancedFilters.dueDateRangeUnit" :delay="1000" :offset="[0, 10]">{{ $tc(`common.time.${currentTaskFilter.data.advancedFilters.dueDateRangeUnit.toLowerCase()}--next-n-${currentTaskFilter.data.advancedFilters.dueDateRangeUnit.toLowerCase()}s`, currentTaskFilter.data.advancedFilters.dueDateRangeAmount) }}</q-tooltip>
                                                                            </q-btn>
                                                                        </div>
                                                                    </div>
                                                                </q-item-section>
                                                            </q-item>
                                                        </q-list>
                                                    </q-card-section>
                                                </q-card>
                                            </q-expansion-item>
                                        </q-list>
                                    </div>

                                    <div class="col-xs-12 col-md-6">
                                        <q-list bordered>
                                            <q-expansion-item
                                                group="target-object-filter"
                                                expand-icon="mib-arrow-left-1"
                                                expanded-icon="mib-arrow-down-1"
                                                :class="['advanced-filter-expansion-item', { 'is-active': currentTaskFilter.data.advancedFilters.targetObjectType }]"
                                                :default-opened="!!currentTaskFilter.data.advancedFilters.targetObjectType"
                                            >
                                                <template v-slot:header>
                                                    <q-item-section>
                                                        {{ $t('views.task.list.filter-by-target-object-type') }}
                                                    </q-item-section>
                                                    <q-item-section side>
                                                        <div class="row items-center">
                                                            <q-btn
                                                                v-if="currentTaskFilter.data.advancedFilters.targetObjectType"
                                                                color="primary"
                                                                size="sm"
                                                                flat
                                                                round
                                                                icon="mib-filter-2"
                                                                @click="triggerClearTargetObjectTypeFilter"
                                                            >
                                                                <q-tooltip
                                                                    anchor="center start"
                                                                    self="center right"
                                                                    :delay="1000"
                                                                    :offset="[10, 0]"
                                                                >
                                                                    {{ $tc('common.filter.filter-is-active--click-to-reset', 1) }}
                                                                </q-tooltip>
                                                            </q-btn>
                                                        </div>
                                                    </q-item-section>
                                                </template>
                                                <q-card>
                                                    <q-card-section class="q-pt-xs q-pb-xs q-pl-xs">
                                                        <q-option-group
                                                            v-model="currentTaskFilter.data.advancedFilters.targetObjectType"
                                                            :options="advancedFilters.targetObjectTypeOptions"
                                                            type="radio"
                                                        />
                                                    </q-card-section>
                                                </q-card>
                                            </q-expansion-item>

                                            <q-separator />

                                            <q-expansion-item
                                                group="target-object-filter"
                                                expand-icon="mib-arrow-left-1"
                                                expanded-icon="mib-arrow-down-1"
                                                :class="['advanced-filter-expansion-item', { 'is-active': currentTaskFilter.data.advancedFilters.targetObject }]"
                                                :default-opened="!!currentTaskFilter.data.advancedFilters.targetObject"
                                            >
                                                <template v-slot:header>
                                                    <q-item-section>
                                                        <div>{{ $t('views.task.list.filter--target-object--related-to-specific-target-object') }} <info-icon>{{ $t('common.task.target-object--description') }}</info-icon></div>
                                                    </q-item-section>
                                                    <q-item-section side>
                                                        <div class="row items-center">
                                                            <q-btn
                                                                v-if="currentTaskFilter.data.advancedFilters.targetObject"
                                                                color="primary"
                                                                size="sm"
                                                                flat
                                                                round
                                                                icon="mib-filter-2"
                                                                @click="triggerClearTargetObjectFilter"
                                                            >
                                                                <q-tooltip
                                                                    anchor="center start"
                                                                    self="center right"
                                                                    :delay="1000"
                                                                    :offset="[10, 0]"
                                                                >
                                                                    {{ $tc('common.filter.filter-is-active--click-to-reset', 1) }}
                                                                </q-tooltip>
                                                            </q-btn>
                                                        </div>
                                                    </q-item-section>
                                                </template>
                                                <q-card>
                                                    <q-card-section v-if="currentTaskFilter.data.advancedFilters.targetObject && currentTaskFilter.data.advancedFilters.targetObject.targetObjectType">
                                                        <p class="q-pt-xs q-mb-none">
                                                            <template v-if="currentTaskFilter.data.advancedFilters.targetObject.__typename">
                                                                <i18n
                                                                    :path="`views.task.list.filter--target-object--${currentTaskFilter.data.advancedFilters.targetObject.targetObjectType.toLowerCase()}--link-text`"
                                                                    tag="span"
                                                                >
                                                                    <template v-slot:contactName>
                                                                        <template v-if="currentTaskFilter.data.advancedFilters.targetObject.targetObjectType === TaskTargetObjectType.CONTACT">
                                                                            <router-link :to="{ name: 'contact-detail', params: { id: currentTaskFilter.data.advancedFilters.targetObject.id } }">{{ currentTaskFilter.data.advancedFilters.targetObject.getContactName({ contactNumber: true }) }}</router-link>
                                                                        </template>
                                                                        <template v-else>{{ currentTaskFilter.data.advancedFilters.targetObject.customer.getContactName({ contactNumber: true }) }}</template>
                                                                    </template>
                                                                    <template v-if="currentTaskFilter.data.advancedFilters.targetObject.targetObjectType === TaskTargetObjectType.APPLICATION" v-slot:contractNumber><!-- TODO @MTR -->
                                                                        <router-link :to="{ name: 'application-detail', params: { contactId: currentTaskFilter.data.advancedFilters.targetObject.customer.id, id: currentTaskFilter.data.advancedFilters.targetObject.id } }">{{ currentTaskFilter.data.advancedFilters.targetObject.formattedNumber }}</router-link>
                                                                    </template>
                                                                    <template v-if="currentTaskFilter.data.advancedFilters.targetObject.targetObjectType === TaskTargetObjectType.CONTRACT" v-slot:contractNumber>
                                                                        <router-link :to="{ name: 'contract-detail', params: { contactId: currentTaskFilter.data.advancedFilters.targetObject.customer.id, id: currentTaskFilter.data.advancedFilters.targetObject.id } }">{{ currentTaskFilter.data.advancedFilters.targetObject.currentContractNumber }}</router-link>
                                                                    </template>
                                                                </i18n>
                                                                <q-btn
                                                                    icon="mib-delete-2-alternate"
                                                                    flat
                                                                    round
                                                                    color="negative"
                                                                    size="sm"
                                                                    @click="() => currentTaskFilter.clearTargetObject()"
                                                                >
                                                                    <q-tooltip
                                                                        :delay="1000"
                                                                        anchor="center end"
                                                                        self="center start"
                                                                        :offset="[10, 0]"
                                                                    >
                                                                        {{ $tc('common.filter.remove-filter', 1) }}
                                                                    </q-tooltip>
                                                                </q-btn>
                                                            </template>
                                                            <template v-else><q-skeleton type="text" animation="fade" width="60%" /></template>
                                                        </p>
                                                    </q-card-section>
                                                    <q-card-section v-else>
                                                        <target-object-search @open-search-result="targetObject => currentTaskFilter.setTargetObject(targetObject)" />
                                                    </q-card-section>
                                                </q-card>
                                            </q-expansion-item>
                                        </q-list>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <div :class="['col-xs-12', { 'temporary-filter-active': currentTaskFilterMode === TaskFilterMode.QUERYSTRING }]">
                            <div class="q-gutter-x-sm">
                                <p class="inline-block q-mb-none">{{ $tc('common.term.quick-filter', 2) }}:</p>
                                <base-button-toggle
                                    v-model="currentTaskFilter.data.quickFilters.creatorId"
                                    :options="[
                                        { label: $t('common.task.created-by-me'), value: userId }
                                    ]"
                                    clearable
                                />
                                <base-button-toggle
                                    v-model="currentTaskFilter.data.quickFilters.assigneeId"
                                    :options="[
                                        { label: $t('common.task.assigned-to-me'), value: userId }
                                    ]"
                                    clearable
                                />
                                <!-- TODO improvement: Add another option to search for "my private tasks" (when task.visibility can be searched for / filtered). -->
                                <!-- TODO improvement: Only show date related quick filters for "pending" tab. (Since they do not really make sense for "done" tasks. Please note: Filter handling must be considered – the buttons cannot just be hidden.) -->
                                <base-button-toggle
                                    v-model="currentTaskFilter.data.quickFilters.dueDateStatus"
                                    :options="quickFilters.dueDateStatusOptions"
                                    clearable
                                />
                            </div>
                        </div>
                    </template>

                    <template v-slot:header-cell-targetObject="slotProps">
                        <q-th :props="slotProps">
                            {{ $tc('common.task.target-object', 1) }}
                            <info-icon :text="$tc('common.task.target-object--description', 1)" />
                        </q-th>
                    </template>

                    <!-- Template for "generic" / not explicitly specified cells (needed in order to apply `class`) -->
                    <template v-slot:body-cell="slotProps">
                        <q-td :props="slotProps" :class="taskRowCellClass(slotProps.row)">
                            {{ slotProps.value }}
                        </q-td>
                    </template>

                    <template v-slot:body-cell-due_date="slotProps">
                        <q-td :props="slotProps" :class="['due-date', taskRowCellClass(slotProps.row), { 'no-icon': slotProps.row.status !== TaskStatus.DONE && !['UPCOMING', 'DUE-TODAY', 'OVERDUE'].includes(slotProps.row.dueDateStatus) }]">
                            <template v-if="slotProps.row.status !== TaskStatus.DONE && ['UPCOMING', 'DUE-TODAY', 'OVERDUE'].includes(slotProps.row.dueDateStatus)">
                                <info-icon
                                    v-if="slotProps.row.dueDateDiffDays >= 0"
                                    color="text-primary"
                                    :name="slotProps.row.dueDateDiffDays > 0 ? 'mib-calendar-clock' : 'mib-calendar-information'"
                                    size="1em"
                                    :text="$tc('common.task.task-due-in-n-days', slotProps.row.dueDateDiffDays)"
                                    :do-not-print-icon="false"
                                    class="q-mr-xs"
                                />
                                <info-icon
                                    v-if="slotProps.row.dueDateDiffDays < 0"
                                    color="negative-darker"
                                    name="mib-calendar-warning"
                                    size="1em"
                                    :text="slotProps.row.dueDateDiffDays > -15 ? $tc('common.task.task-overdue-since-n-days', -slotProps.row.dueDateDiffDays) : $tc('common.task.task-overdue-since-date', 1, { date: formatDate(slotProps.row.due_date, { noLeadingZero: true }) })"
                                    :do-not-print-icon="false"
                                    class="q-mr-xs"
                                />
                            </template>
                            <span class="td-value">{{ formatDate(slotProps.row.due_date) }}</span>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-done_at="slotProps">
                        <q-td :props="slotProps" :class="['done-at', taskRowCellClass(slotProps.row), { 'no-icon': !slotProps.row.displayStatus }]">
                            <template v-if="slotProps.row.displayStatus">
                                <!-- TODO improvement: Enhance difference/translation with check for weeks, months, (years?) -->
                                <info-icon
                                    v-if="slotProps.row.displayStatus === TaskDisplayStatus.DONE_AFTER_DUE_DATE"
                                    color="negative-darker"
                                    name="mib-calendar-disable-1"
                                    size="1em"
                                    :text="$tc('common.task.task-display-status--description', differenceInCalendarDays(parseISO(slotProps.row.done_at), parseISO(slotProps.row.due_date)))"
                                    :do-not-print-icon="false"
                                    class="q-mr-xs"
                                />
                                <info-icon
                                    v-if="slotProps.row.displayStatus === TaskDisplayStatus.DONE_IN_TIME"
                                    color="positive-darker"
                                    name="mib-calendar-check-1"
                                    size="1em"
                                    :text="$tc('common.task.task-display-status--description', 0)"
                                    :do-not-print-icon="false"
                                    class="q-mr-xs"
                                />
                            </template>
                            <span class="td-value">{{ formatDate(slotProps.row.done_at) }}</span>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-subject="slotProps">
                        <q-td :class="['task-subject', taskRowCellClass(slotProps.row)]">
                            <b>
                                {{ slotProps.row.subject }}
                                <q-tooltip
                                    anchor="center middle"
                                    self="center left"
                                    :delay="1000"
                                    :offset="[10, 0]"
                                >{{ slotProps.row.subject }}
                                </q-tooltip>
                            </b>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-targetObject="slotProps">
                        <q-td :class="taskRowCellClass(slotProps.row)">
                            <!-- User has access to `targetObject` -->
                            <template v-if="slotProps.row.target_object_type && slotProps.row.targetObject">
                                <template v-if="slotProps.row.target_object_type === TaskTargetObjectType.CONTACT">
                                    <info-icon
                                        :name="slotProps.row.target_object_type === TaskTargetObjectType.CONTRACT ? 'mib-common-file-text-alternate' : getContactTypeIcon(slotProps.row.targetObject)"
                                        :text="$tc('common.task.target-object--contact--link-text', 1, { contactName: slotProps.row.targetObject.getContactName() })"
                                        size="1em"
                                        color="text-secondary"
                                        class="q-mr-xs"
                                    />
                                    {{ slotProps.row.targetObject.getContactName() }}
                                </template>
                                <template v-if="slotProps.row.target_object_type === TaskTargetObjectType.CONTRACT">
                                    <info-icon
                                        name="mib-common-file-text-alternate"
                                        :text="$tc('common.task.target-object--contract--link-text', 1, { contractNumber: slotProps.row.targetObject.currentContractNumber, contactName: slotProps.row.targetObject.customer.getContactName() })"
                                        size="1em"
                                        color="text-secondary"
                                        class="q-mr-xs"
                                    />
                                    {{ slotProps.row.targetObject.currentContractNumber }} ({{ slotProps.row.targetObject.customer.getContactName() }})
                                </template>
                            </template>
                            <!-- User does not have access to `targetObject` -->
                            <info-icon
                                v-else-if="slotProps.row.target_object_type && !slotProps.row.targetObject"
                                :name="slotProps.row.target_object_type === TaskTargetObjectType.CONTRACT ? 'mib-common-file-text-lock' : 'mib-single-neutral-actions-lock'"
                                :title="$tc('common.term.access--no-access', 1)"
                                :text="$tc(`common.task.target-object--${toKebabCase(slotProps.row.target_object_type)}--no-access`, 1)"
                                size="1em"
                                color="text-secondary"
                            />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-attachments="slotProps">
                        <q-td :class="['text-center', taskRowCellClass(slotProps.row)]">
                            <template v-if="slotProps.row.attachments.length > 0">
                                {{ slotProps.row.attachments.length }}
                                <info-icon
                                    name="mib-attachment-1"
                                    color="text-secondary"
                                    :text="$tc('common.task.number-of-attachments', slotProps.row.attachments.length)"
                                    size="1em"
                                    :do-not-print-icon="false"
                                />
                            </template>
                            <span v-else class="additional-info">–</span>
                        </q-td>
                    </template>

                    <template v-slot:body-cell-visibility="slotProps">
                        <q-td :class="['text-center', taskRowCellClass(slotProps.row)]">
                            <info-icon
                                :name="slotProps.row.visibility === TaskVisibility.PRIVATE ? 'mib-lock-1' : 'mib-lock-unlock'"
                                :color="slotProps.row.visibility === TaskVisibility.PRIVATE ? 'text-secondary' : 'border-tertiary'"
                                :title="$tc(`common.task.visibility-${toKebabCase(slotProps.row.visibility.toLowerCase())}--${toKebabCase(slotProps.row.visibility.toLowerCase())}-task`, 1)"
                                :text="$tc(`common.task.visibility-${toKebabCase(slotProps.row.visibility.toLowerCase())}--description`, 1)"
                                size="1em"
                                :do-not-print-icon="false"
                            />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-status="slotProps">
                        <q-td :class="['status', taskRowCellClass(slotProps.row)]">
                            <status-badge :status="slotProps.row.status" />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-actions="slotProps">
                        <q-td :class="taskRowCellClass(slotProps.row)" auto-width>
                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-view-1"
                                :to="{ name: 'task-detail', params: { id: slotProps.row.id } }"
                                data-test="btn:tooltip-details"
                            >
                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.task.show-task', 1) }}</q-tooltip>
                            </q-btn>

                            <!-- TODO: Disable button if user does not have `manage` permission on this task. -->
                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-pencil"
                                data-test="btn:tooltip-actions"
                                @click="() => EventBus.$emit('taskFormDialog:open', { taskId: slotProps.row.id })"
                            >
                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.task.edit-task', 1) }}</q-tooltip>
                            </q-btn>
                        </q-td>
                    </template>
                </base-table>
            </div>
        </div>

        <in-page-footer>
            <base-button
                :label="$tc('common.task.add-task', 1)"
                icon="mib-checklist-alternate"
                primary-button
                data-test="btn:add-task"
                @click="triggerCreateTask"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import store from '@/store/store'
import kebabCase from 'lodash.kebabcase'
import { EventBus } from '@/event-bus'
import { Task } from '@/models/models'
import { formatDate } from '@/helpers/date'
import { UserSettings } from '@/helpers/user'
import { getContactTypeIcon } from '@/helpers/contact'
import { TaskStatus, TaskTargetObjectType, ContactType, TaskVisibility } from '@/enums/graphql'
import { TaskDueDateStatus, TaskDisplayStatus, TaskDueDateRangeUnitOptions, FileUploadTargetObjectType, TaskDueDateRangeFilterType, TaskFilterMode } from '@/enums'
import FilterInput from '@/components/FilterInput'
import BaseTable from '@/components/BaseTable'
import BaseToggle from '@/components/form/BaseToggle.vue'
import BaseButtonToggle from '@/components/form/BaseButtonToggle.vue'
import CalendarInput from '@/components/form/CalendarInput.vue'
import StatusBadge from '@/components/StatusBadge.vue'
import BaseInput from '@/components/form/BaseInput'
import TargetObjectSearch from '@/components/search/TargetObjectSearch'
import {
    parseISO,
    differenceInCalendarDays
} from 'date-fns'
import { TaskFilter } from '@/filters/TaskFilter'
import { ApplicationService, ContactService, ContractService } from '@/services'

export default {
    name: 'TaskList',
    components: {
        BaseTable,
        BaseToggle,
        BaseInput,
        BaseButtonToggle,
        CalendarInput,
        StatusBadge,
        FilterInput,
        TargetObjectSearch,
    },
    meta () {
        return {
            title: `${this.$tc('common.task.task', 2)}`,
        }
    },
    props: {
        tab: {
            type: String,
            default: 'pending',
        },
        filterDueDateRangeAmount: {
            type: Number,
            default: 1,
        },
    },
    setup () {
        const userSettings = new UserSettings(store.state.user)

        const taskFilter = new TaskFilter()
        taskFilter.data.activeAdvancedFilters = userSettings.getItem('Tasks.activeAdvancedFilters') === 'true'

        // Advanced filter presets
        if (userSettings.getItem('Tasks.advancedFilters.dueDateFrom')) taskFilter.data.advancedFilters.dueDateFrom = userSettings.getItem('Tasks.advancedFilters.dueDateFrom')
        if (userSettings.getItem('Tasks.advancedFilters.dueDateUntil')) taskFilter.data.advancedFilters.dueDateUntil = userSettings.getItem('Tasks.advancedFilters.dueDateUntil')
        if (userSettings.getItem('Tasks.advancedFilters.dueDateRangeFilterType')) taskFilter.data.advancedFilters.dueDateRangeFilterType = userSettings.getItem('Tasks.advancedFilters.dueDateRangeFilterType')
        if (userSettings.getItem('Tasks.advancedFilters.dueDateRangeUnit')) taskFilter.data.advancedFilters.dueDateRangeUnit = userSettings.getItem('Tasks.advancedFilters.dueDateRangeUnit')
        if (userSettings.getItem('Tasks.advancedFilters.dueDateRangeAmount')) taskFilter.data.advancedFilters.dueDateRangeAmount = userSettings.getItem('Tasks.advancedFilters.dueDateRangeAmount')
        if (userSettings.getItem('Tasks.advancedFilters.targetObjectType')) taskFilter.data.advancedFilters.targetObjectType = userSettings.getItem('Tasks.advancedFilters.targetObjectType')
        if (userSettings.getItem('Tasks.advancedFilters.targetObject')) taskFilter.setTargetObject(JSON.parse(userSettings.getItem('Tasks.advancedFilters.targetObject')))

        // Quick filter presets
        if (userSettings.getItem('Tasks.quickFilters.creatorId')) taskFilter.data.quickFilters.creatorId = userSettings.getItem('Tasks.quickFilters.creatorId')
        if (userSettings.getItem('Tasks.quickFilters.assigneeId')) taskFilter.data.quickFilters.assigneeId = userSettings.getItem('Tasks.quickFilters.assigneeId')
        if (userSettings.getItem('Tasks.quickFilters.dueDateStatus')) taskFilter.data.quickFilters.dueDateStatus = userSettings.getItem('Tasks.quickFilters.dueDateStatus')

        return {
            taskFilter: taskFilter,
        }
    },
    data () {
        const user = this.$store.state.user
        const userId = user.consultant.id
        const userSettings = new UserSettings(user)
        const filterStatus = {
            'pending': [TaskStatus.TODO, TaskStatus.IN_PROGRESS],
            'done': [TaskStatus.DONE],
        }

        const data = {
            EventBus,
            userId,
            userSettings,
            activeTab: this.tab,
            Task,
            TaskStatus,
            TaskDisplayStatus,
            TaskVisibility,
            TaskTargetObjectType,
            TaskDueDateRangeFilterType,
            TaskFilterMode,
            ContactType,
            parseISO,
            differenceInCalendarDays,
            columns: [
                {
                    name: 'due_date',
                    label: this.$tc('common.term.due-on', 1),
                    required: true,
                    sortable: true,
                    align: 'left',
                    sortOrder: 'ad',
                },
                {
                    name: 'done_at',
                    label: this.$tc('common.term.done-on', 1),
                    sortable: true,
                    align: 'left',
                    sortOrder: 'da',
                },
                {
                    name: 'subject',
                    label: this.$tc('common.term.subject', 1),
                    required: true,
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'creator_name',
                    label: this.$tc('common.term.created-by', 1),
                    required: true,
                    field: row => row.creator
                        ? row.creator.getContactName({ commaSeparated: true, consultingCompanyName: !row.creator.worksForUserConsultingCompany })
                        : null
                    ,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'assignee_name',
                    label: this.$tc('common.task.assignee', 1),
                    required: true,
                    field: row => row.assignee
                        ? row.assignee.getContactName({ commaSeparated: true, consultingCompanyName: !row.assignee.worksForUserConsultingCompany })
                        : null
                    ,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'targetObject',
                    label: this.$tc('common.term.target-object', 1),
                    required: true,
                    align: 'left',
                },
                {
                    name: 'attachments',
                    label: this.$tc('common.term.attachment', 2),
                    required: true,
                    align: 'left',
                },
                {
                    name: 'visibility',
                    label: this.$tc('common.task.visibility', 1),
                    required: true,
                    align: 'center',
                },
                {
                    name: 'status',
                    label: this.$tc('common.term.status', 1),
                    required: true,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'actions',
                    label: this.$tc('common.term.action', 2),
                    required: true,
                    align: 'left',
                },
            ],
            currentTaskFilterMode: TaskFilterMode.DEFAULT,
            currentTaskFilter: null,
            filterStatus,
            filter: '',
            advancedFilters: {
                targetObjectTypeOptions: [
                    { label: this.$t('views.task.list.filter--target-object--all-types'), value: null },
                    { label: this.$t('views.task.list.filter--target-object--related-to-contacts'), value: TaskTargetObjectType.CONTACT },
                    { label: this.$t('views.task.list.filter--target-object--related-to-applications'), value: TaskTargetObjectType.APPLICATION },
                    { label: this.$t('views.task.list.filter--target-object--related-to-contracts'), value: TaskTargetObjectType.CONTRACT },
                ],
                dueDateRangeUnitOptions: [
                    // TODO: Update `this.filterDueDateRangeAmount` in translations automatically
                    { label: this.$tc('common.time.day', this.filterDueDateRangeAmount), value: TaskDueDateRangeUnitOptions.DAY },
                    { label: this.$tc('common.time.week', this.filterDueDateRangeAmount), value: TaskDueDateRangeUnitOptions.WEEK },
                    { label: this.$tc('common.time.month', this.filterDueDateRangeAmount), value: TaskDueDateRangeUnitOptions.MONTH },
                    { label: this.$tc('common.time.year', this.filterDueDateRangeAmount), value: TaskDueDateRangeUnitOptions.YEAR },
                ],
            },
            quickFilters: {
                dueDateStatusOptions: [
                    { label: this.$t('common.status.due-date-status.overdue'), value: TaskDueDateStatus.OVERDUE },
                    { label: this.$t('common.status.due-date-status.due-today'), value: TaskDueDateStatus.DUE_TODAY },
                    { label: this.$t('common.status.due-date-status.upcoming'), value: TaskDueDateStatus.UPCOMING },
                ],
            },
        }

        data.currentTaskFilter = this.taskFilter

        return data
    },
    computed: {
        additionalFilters () {
            const filterVariables = {
                filterQuery: this.filter,
            }

            // Tabs
            if (this.activeTab) filterVariables.filterStatus = this.filterStatus[this.activeTab]

            // Current task filter
            Object.assign(filterVariables, this.currentTaskFilter.variables)

            return filterVariables
        },
        visibleColumns () {
            return this.activeTab === 'done' ? ['done_at'] : ['']
        },
        paginationSortByDefaultKey () {
            return this.activeTab === 'done' ? 'done_at' : 'due_date'
        },
        paginationInitialOrderDirectionDescending () {
            return this.activeTab === 'done'
        },
    },
    watch: {
        'activeTab' (newValue) {
            if (newValue === 'done') {
                this.$refs.tasksTable.setSortByKey('done_at')
                this.$refs.tasksTable.setOrderDirectionDescending(true)
            } else {
                this.$refs.tasksTable.setSortByKey('due_date')
                this.$refs.tasksTable.setOrderDirectionDescending(false)
            }
        },
        '$route' (to) {
            this.activeTab = to.params.tab || this.tab
            this.initializeQueryStringFilter()
        },
        'currentTaskFilter.data.activeAdvancedFilters' (newValue) {
            if (this.currentTaskFilterMode !== TaskFilterMode.DEFAULT) return
            this.userSettings.setItem('Tasks.activeAdvancedFilters', newValue)
        },
        'currentTaskFilter.data.advancedFilters.dueDateFrom' (newValue) {
            if (this.currentTaskFilterMode !== TaskFilterMode.DEFAULT) return
            this.userSettings.setItem('Tasks.advancedFilters.dueDateFrom', newValue)
        },
        'currentTaskFilter.data.advancedFilters.dueDateUntil' (newValue) {
            if (this.currentTaskFilterMode !== TaskFilterMode.DEFAULT) return
            this.userSettings.setItem('Tasks.advancedFilters.dueDateUntil', newValue)
        },
        'currentTaskFilter.data.advancedFilters.dueDateRangeFilterType' (newValue) {
            if (this.currentTaskFilterMode !== TaskFilterMode.DEFAULT) return
            this.userSettings.setItem('Tasks.advancedFilters.dueDateRangeFilterType', newValue)
        },
        'currentTaskFilter.data.advancedFilters.dueDateRangeUnit' (newValue) {
            if (this.currentTaskFilterMode !== TaskFilterMode.DEFAULT) return
            this.userSettings.setItem('Tasks.advancedFilters.dueDateRangeUnit', newValue)
        },
        'currentTaskFilter.data.advancedFilters.dueDateRangeAmount' (newValue) {
            if (this.currentTaskFilterMode !== TaskFilterMode.DEFAULT) return
            this.userSettings.setItem('Tasks.advancedFilters.dueDateRangeAmount', newValue)
        },
        'currentTaskFilter.data.advancedFilters.targetObjectType' (newValue) {
            if (this.currentTaskFilterMode !== TaskFilterMode.DEFAULT) return
            if (newValue) {
                this.userSettings.setItem('Tasks.advancedFilters.targetObjectType', newValue)
            } else {
                this.userSettings.removeItem('Tasks.advancedFilters.targetObjectType')
            }
        },
        'currentTaskFilter.data.advancedFilters.targetObject' (newValue) {
            if (this.currentTaskFilterMode !== TaskFilterMode.DEFAULT) return
            if (newValue) {
                this.userSettings.setItem('Tasks.advancedFilters.targetObject', JSON.stringify({
                    targetObjectType: newValue.targetObjectType,
                    id: newValue.id,
                }))
            } else {
                this.userSettings.removeItem('Tasks.advancedFilters.targetObject')
            }
        },
        'currentTaskFilter.data.quickFilters.creatorId' (newValue) {
            if (this.currentTaskFilterMode !== TaskFilterMode.DEFAULT) return
            if (newValue) {
                this.userSettings.setItem('Tasks.quickFilters.creatorId', newValue)
            } else {
                this.userSettings.removeItem('Tasks.quickFilters.creatorId')
            }
        },
        'currentTaskFilter.data.quickFilters.assigneeId' (newValue) {
            if (this.currentTaskFilterMode !== TaskFilterMode.DEFAULT) return
            if (newValue) {
                this.userSettings.setItem('Tasks.quickFilters.assigneeId', newValue)
            } else {
                this.userSettings.removeItem('Tasks.quickFilters.assigneeId')
            }
        },
        'currentTaskFilter.data.quickFilters.dueDateStatus' (newValue) {
            if (this.currentTaskFilterMode !== TaskFilterMode.DEFAULT) return
            if (newValue) {
                this.userSettings.setItem('Tasks.quickFilters.dueDateStatus', newValue)
            } else {
                this.userSettings.removeItem('Tasks.quickFilters.dueDateStatus')
            }
        },
    },
    created () {
        EventBus.$on('task:create', this.fetchObjects)
        EventBus.$on('task:update', this.fetchObjects)
        EventBus.$on('task:delete', this.fetchObjects)
        EventBus.$on('fileUploadManager:allUploadsDone', this.checkRefetchObjects)
        EventBus.$on('task:delete-attachment', this.handleRemoteAttachmentDelete)
        EventBus.$on('shortcut:newEntity', this.onNewEntity)

        this.initializeQueryStringFilter()
    },
    beforeDestroy () {
        EventBus.$off('task:create', this.fetchObjects)
        EventBus.$off('task:update', this.fetchObjects)
        EventBus.$off('task:delete', this.fetchObjects)
        EventBus.$off('fileUploadManager:allUploadsDone', this.checkRefetchObjects)
        EventBus.$off('task:delete-attachment', this.handleRemoteAttachmentDelete)
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        fetchObjects () {
            this.$refs.tasksTable.fetchObjects()
        },
        checkRefetchObjects (targetObjects) {
            if (targetObjects.some(targetObject => targetObject.type === FileUploadTargetObjectType.TASK && this.$refs.tasksTable.items.some(task => task.id === targetObject.id))) {
                this.fetchObjects()
            }
        },
        fetchTargetObjectData () {
            if (!this.currentTaskFilter.data.advancedFilters.targetObject || this.currentTaskFilter.data.advancedFilters.targetObject.__typename) return

            const handleSuccess = targetObject => this.currentTaskFilter.data.advancedFilters.targetObject = targetObject
            const handleError = () => {
                this.currentTaskFilter.data.advancedFilters.targetObject = null
                this.$q.notify({
                    type: 'negative',
                    timeout: 10000,
                    message: this.$tc('common.term.error', 1),
                    caption: this.$tc('common.notifications.target-object-could-not-be-loaded', 1),
                })
            }

            switch (this.currentTaskFilter.data.advancedFilters.targetObject.targetObjectType) {
                case TaskTargetObjectType.CONTACT:
                    ContactService.get(this.currentTaskFilter.data.advancedFilters.targetObject.id)
                        .then(handleSuccess)
                        .catch(handleError)
                    break
                case TaskTargetObjectType.APPLICATION:
                    ApplicationService.get(this.currentTaskFilter.data.advancedFilters.targetObject.id)
                        .then(handleSuccess)
                        .catch(handleError)
                    break
                case TaskTargetObjectType.CONTRACT:
                    ContractService.get(this.currentTaskFilter.data.advancedFilters.targetObject.id)
                        .then(handleSuccess)
                        .catch(handleError)
                    break
            }
        },
        handleRemoteAttachmentDelete (updatedTask) {
            this.$refs.tasksTable.items.some(task => {
                if (task.id === updatedTask.id) {
                    Object.assign(task, updatedTask)
                    return true
                }
                return false
            })
        },
        initializeQueryStringFilter () {
            let hasQueryStringFilterKeys = false
            let queryStringFilter = null

            if (Object.keys(this.$route.query).length) {
                queryStringFilter = new TaskFilter()

                // Advanced filters
                // Target object
                if (typeof this.$route.query.filterTargetObjectType !== 'undefined' && typeof this.$route.query.filterTargetObjectId !== 'undefined') {
                    hasQueryStringFilterKeys = true
                    queryStringFilter.activateAdvancedFilters()
                    queryStringFilter.setTargetObject({
                        targetObjectType: this.$route.query.filterTargetObjectType,
                        id: this.$route.query.filterTargetObjectId,
                    })
                } else if (typeof this.$route.query.filterTargetObjectType !== 'undefined') {
                    hasQueryStringFilterKeys = true
                    queryStringFilter.activateAdvancedFilters()
                    queryStringFilter.data.advancedFilters.targetObjectType = this.$route.query.filterTargetObjectType
                }

                // Quick filters
                // Creator
                if (typeof this.$route.query.filterCreatorId !== 'undefined') {
                    hasQueryStringFilterKeys = true
                    queryStringFilter.data.quickFilters.creatorId = this.$route.query.filterCreatorId
                }

                // Assignee
                if (typeof this.$route.query.filterAssigneeId !== 'undefined') {
                    hasQueryStringFilterKeys = true
                    queryStringFilter.data.quickFilters.assigneeId = this.$route.query.filterAssigneeId
                }

                // Due date status
                if (typeof this.$route.query.filterDueDateStatus !== 'undefined') {
                    hasQueryStringFilterKeys = true
                    queryStringFilter.data.quickFilters.dueDateStatus = this.$route.query.filterDueDateStatus
                }
            }

            if (hasQueryStringFilterKeys) {
                if (!queryStringFilter.data.activeAdvancedFilters) queryStringFilter.data.activeAdvancedFilters = this.taskFilter.data.activeAdvancedFilters
                this.currentTaskFilterMode = TaskFilterMode.QUERYSTRING
                this.currentTaskFilter = queryStringFilter
            } else {
                this.currentTaskFilterMode = TaskFilterMode.DEFAULT
                this.currentTaskFilter = this.taskFilter
            }

            this.fetchTargetObjectData()
        },
        clearQueryStringFilter () {
            // This wipes all query strings no matter if they are used for filtering or not.
            // It doesn't matter for now, because we currently don't use query strings for anything else.
            // If that should change in the future, we would need to remove the according keys handpicked.
            this.$router.push({
                name: this.$route.name,
                params: this.$route.params,
            })
        },
        clearFilter () {
            this.filter = ''
        },
        triggerClearDueDateFilter (event) {
            event.preventDefault()
            event.stopPropagation()
            this.currentTaskFilter.clearDueDateFilter()
        },
        triggerClearTargetObjectTypeFilter (event) {
            event.preventDefault()
            event.stopPropagation()
            this.currentTaskFilter.clearTargetObjectTypeFilter()
        },
        triggerClearTargetObjectFilter (event) {
            event.preventDefault()
            event.stopPropagation()
            this.currentTaskFilter.clearTargetObjectFilter()
        },
        onDblClick (evt, row) {
            this.$router.push({ name: 'task-detail', params: { id: row.id } })
        },
        onNewEntity () {
            this.triggerCreateTask()
        },
        triggerCreateTask () {
            EventBus.$emit('taskFormDialog:open')
        },
        taskRowCellClass (row) {
            if (row.status !== TaskStatus.DONE && ['UPCOMING', 'DUE-TODAY', 'OVERDUE'].includes(row.dueDateStatus)) {
                return `due-date-status-${kebabCase(row.dueDateStatus.toLowerCase())}`
            }
        },
        toKebabCase: function (text) {
            return kebabCase(text)
        },
        formatDate (...args) {
            return formatDate(...args)
        },
        getContactTypeIcon (...args) {
            return getContactTypeIcon(...args)
        },
    },
}
</script>

<style lang="scss" scoped>
// Filter
// ----------------------------------------------------------------------------------------------------
.advanced-filter-wrapper {
    border-top: 1px solid var(--color-border-primary);
    border-bottom: 1px solid var(--color-border-primary);
}

::v-deep .advanced-filter-expansion-item {
    background: var(--color-background-secondary);

    &.is-active {
        //background: var(--q-color-accent-lighter);
        background: var(--q-color-primary-lighter);

        & > .q-expansion-item__container > .q-item {
            &::after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 0;

                // TODO improvement: Add high-contrast version for better accessibility (also for the `toggle-color` above)
                border-bottom: 2px solid var(--q-color-primary-light);

                pointer-events: none;
            }
        }
    }
}

::v-deep .dynamic-date-range-wrapper {
    height: 48px; // This is needed because – for some incomprehensible reason – the height gets expanded when any option is selected in the q-select.

    .input-wrapper {
        display: flex;
        align-self: flex-start;

        label {
            flex: 1 1 auto;
        }
    }

    .due-date-range-nav-button {
        width: 32px;
    }
}


// List
// ----------------------------------------------------------------------------------------------------
::v-deep .due-date {
    // Add transparent border so that due dates without special due-date-status are properly aligned, too.
    border-left: $sizeSpacingXs solid transparent;
}

::v-deep .q-table .q-td.due-date-status {
    &-overdue {
        background-color: var(--q-color-negative-lighter);
        border-color: var(--q-color-negative-light);

        &.due-date {
            border-left: $sizeSpacingXs solid var(--q-color-negative);
            color: var(--q-color-negative-darker);
        }
    }

    &-due-today,
    &-upcoming {
        background-color: var(--q-color-warning-lighter);
        border-color: var(--color-border-secondary);

        &.due-date {
            border-left: $sizeSpacingXs solid var(--q-color-warning);
        }
    }

    &-due-today {

        &.due-date {
            font-weight: bold;
        }
    }
}

::v-deep .task-subject {
    max-width: 40ch;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Adjust spacing of dates that don’t have an icon in front of them
::v-deep .no-icon {

    .td-value {
        padding-left: 18px;
    }
}
</style>
