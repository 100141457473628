import gql from 'graphql-tag'

export const CONTRACT_FILE_DELETE_MUTATION = gql`
    mutation contractFileDelete(
        $id: ID!
    ) {
        contractFileDelete(
            id: $id
        ) {
            status
        }
    }
`
