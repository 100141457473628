<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.accounting.posting-type.posting-type', 2)" :to="{ name: 'accounting-posting-type-list' }" />
                <q-breadcrumbs-el :label="$tc('views.accounting.posting-type.add-posting-type', 1)" :to="{ name: 'accounting-posting-type-create' }" />
            </template>

            <h1>{{ $tc('views.accounting.posting-type.add-posting-type', 1) }}</h1>
        </page-header>

        <posting-type-form ref="postingTypeForm" @submit="onSubmit" />
    </page-wrapper>
</template>

<script>
import { PostingType } from '@/models/postingType'
import PostingTypeForm from '@/components/accounting/PostingTypeForm.vue'

export default {
    name: 'PostingTypeCreate',
    meta () {
        return {
            title: this.$tc('views.accounting.posting-type.add-posting-type', 1),
        }
    },
    components: {
        PostingTypeForm,
    },
    methods: {
        onSubmit (postingType) {
            PostingType.create(postingType)
                .then(() => {
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.accounting.posting-type-created-success', 1),
                    })
                    this.$router.replace({ name: 'accounting-posting-type-list' })
                })
                .catch(error => {
                    this.$q.notify({
                        type: 'negative',
                        title: this.$t('common.term.error'),
                        message: this.$tc('common.notifications.accounting.posting-type-created-error', 1),
                    })
                    this.$refs.postingTypeForm.showFormErrorMessage(error)
                    this.$refs.postingTypeForm.resetFormSubmitStatus()
                })
        },
    },
}
</script>
