import gql from 'graphql-tag'

export const COMMENT_UPDATE_MUTATION = gql`
    mutation commentUpdate(
        $id: ID!
        $comment: CommentInputUpdate!
    ) {
        commentUpdate(
            id: $id
            comment: $comment
        ) {
            id
            creator {
                id
                contact_number
                first_name
                last_name
                gender
                title
                consultingCompany {
                    id
                }
                consulting_company_name
            }

            text
            pinned

            created_at
            updated_at
        }
    }
`
