import gql from 'graphql-tag'

export const DOCUMENT_TEMPLATE_UPDATE_MUTATION = gql`
    mutation documentTemplateUpdate(
        $id: ID!
        $documentTemplate: DocumentTemplateInput!
    ) {
        documentTemplateUpdate(
            id: $id
            documentTemplate: $documentTemplate
        ) {
            id
                owner {
                    ... on Consultant {
                        id
                        first_name
                        last_name
                    }
                    ... on ConsultingCompany {
                        id
                        company_name
                    }
                }
                available_for_subordinate_companies
                name
                description
                type
                supported_target_object_types
                language {
                    id
                    name
                }
                confidentiality_notice
                differentiate_formality
                contents {
                    id
                    formality
                    content {
                        schemaType
                        schemaVersion
                        contentJSON
                        contentHTML
                        attrs
                    }
                }
                created_at
                updated_at
        }
    }
`
