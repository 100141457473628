import gql from 'graphql-tag'

export function buildConsultingFilesQuery (fields) {
    return gql`
        query consultingFiles(
            $page: Int = 1
            $count: Int = 1000
            $orderField: ConsultantFileOrderField = FILE_NAME
            $orderDirection: OrderDirection = DESC

            $consultingFileTargetObject: ConsultingFileTargetObjectInput!

            $searchTerm: String
            $typeId: ID
            $dateFrom: Date
            $dateUntil: Date
            $consultantId: ID
        ) {
            consultingFiles(
                page: $page
                count: $count
                consultingFileTargetObject: $consultingFileTargetObject
                order: [{ field: $orderField, direction: $orderDirection }]
                search: {
                    search_term: $searchTerm
                    type_id: $typeId
                    date_from: $dateFrom
                    date_until: $dateUntil
                    consultant_id: $consultantId
                }
            ) {
                paginatorInfo {
                    count
                    firstItem
                    lastItem
                    total
                    currentPage
                    lastPage
                    perPage
                    hasMorePages
                }
                data {
                    ${fields}
                }
            }
        }
    `
}

const consultingFilesFields = `
    id
    file {
        id
        name
        size
        created_at
    }
    consultant {
        id
        contact_number
        first_name
        last_name
        gender
        title
        consultingCompany {
            id
        }
        consulting_company_name
        consulting_settings_status
    }
`

const defaultQueryFields = `
    ... on ContactFile {
        type {
            id
            contactFileTypeStatus: status
            key
            name
        }
        ${consultingFilesFields}
    }
    ... on ApplicationFile {
        type {
            id
            applicationFileTypeStatus: status
            key
            name
        }
        ${consultingFilesFields}
    }
    ... on ContractFile {
        type {
            id
            contractFileTypeStatus: status
            key
            name
        }
        ${consultingFilesFields}
    }
`

export const CONSULTING_FILES_QUERY = buildConsultingFilesQuery(defaultQueryFields)
