export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'commissionRecipientOverrideId',
            label: 'views.contract.settings.commission.commission-recipient-override.commission-recipient',
            infoText: 'views.contract.settings.commission.commission-recipient-override.commission-recipient-override--info',
            inputType: 'consultant',
        },
    ],
}
