import { Model } from '@/models'
import { ContactRelationshipDefinitionsService } from '@/services'

export class ContactRelationshipDefinitions extends Model {
    /**
     * Create a ContactRelationshipDefinitions model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'type',
            'categories',
            'node1_type',
            'node1_valid_contact_types',
            'node2_type',
            'node2_valid_contact_types',

            // Timestamps
            'created_at',
            'updated_at',
        ], data)
    }

    static get service () {
        return ContactRelationshipDefinitionsService
    }
}
