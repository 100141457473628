import {
    getContactGender,
    getContactTypeIcon,
    getContactName,
    getContactAddress
} from '@/helpers/contact'

export const contactMixin = {
    methods: {
        getContactGender (...args) {
            console.warn('contactMixin.getContactGender() is deprecated, use contact.getGender() instead.')
            return getContactGender(...args)
        },
        getContactTypeIcon (...args) {
            console.warn('contactMixin.getContactTypeIcon() is deprecated, use contact.typeIcon instead.')
            return getContactTypeIcon(...args)
        },
        getContactName (...args) {
            console.warn('contactMixin.getContactName() is deprecated, use contact.getContactName() instead.')
            return getContactName(...args)
        },
        getContactAddress (...args) {
            console.warn('contactMixin.getContactAddress() is deprecated, use contact.getContactAddress() instead.')
            return getContactAddress(...args)
        },
    },
}
