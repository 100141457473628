import { kebabCase } from 'lodash'
import { CommissionType } from '@/enums/graphql'

const commissionTypeOptions = Object.values(CommissionType).map(value => ({ value: value, label: `common.commission.commission-type.${kebabCase(value)}--type-number-prefix` }))

export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'premiumFrom',
            label: 'forms.field.label.commission-list-entry-position.premium-from',
            inputType: 'calendar',
            validators: [
                { type: 'date' },
                {
                    type: 'isBeforeOrEqualToDate',
                    params: {
                        target: 'premiumTo',
                        targetLabel: 'forms.field.label.commission-list-entry-position.premium-to',
                    },
                },
                {
                    type: 'requiredIf',
                    params: {
                        'target': 'premiumTo',
                        'targetLabel': 'forms.field.label.commission-list-entry-position.premium-to',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'premiumTo',
            label: 'forms.field.label.commission-list-entry-position.premium-to',
            inputType: 'calendar',
            validators: [
                { type: 'date' },
                {
                    type: 'isAfterOrEqualToDate',
                    params: {
                        target: 'premiumFrom',
                        targetLabel: 'forms.field.label.commission-list-entry-position.premium-from',
                    },
                },
                {
                    type: 'requiredIf',
                    params: {
                        'target': 'premiumFrom',
                        'targetLabel': 'forms.field.label.commission-list-entry-position.premium-from',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'commissionType',
            label: 'forms.field.label.commission-list-entry-position.commission-type',
            inputType: 'select',
            extra_attributes: {
                list: {
                    options: commissionTypeOptions,
                },
            },
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'commissionAmount',
            label: 'forms.field.label.commission-list-entry-position.commission-amount',
            inputType: 'number',
            validators: [
                {
                    type: 'required',
                },
                {
                    type: 'notEqualTo',
                    params: { value: '0', type: 'Number' },
                },
            ],
            extra_attributes: {
                step: '0.01',
            },
        },
    ],
}
