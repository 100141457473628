<template>
    <div>
        <template v-if="currentMode === ContentStoreViewMode.LIST">
            <content-store-list
                :items="items"
                :columns="columns"
                :content-type="contentType"
                @update-selection="selectedItems => { selection = selectedItems }"
            />
            <q-card-actions class="q-gutter-sm">
                <base-button
                    :label="$t('common.term.import')"
                    :disable="selection.length === 0"
                    primary-button
                    @click="triggerImports"
                />
                <base-button
                    :label="$t('common.term.cancel')"
                    primary-button
                    flat
                    @click="$emit('hide')"
                />
            </q-card-actions>
        </template>
        <div v-else-if="currentMode === ContentStoreViewMode.IMPORT">
            <content-store-import
                :queue="importQueue"
                :log="importLog"
                display-property-name="companyName"
                @abort="abortImport"
                @acknowledge="acknowledgeImport"
            />
        </div>
    </div>
</template>

<script>
import PRODUCT_PROVIDERS from '#/content-store/product-providers.json'
import { contentStoreMixin } from '@/mixins/contentStoreMixin'
import ContentStoreList from '@/components/content-store/ContentStoreList'
import ContentStoreImport from '@/components/content-store/ContentStoreImport'
import { ContactService } from '@/services'
import { Company, ProductProviderSettings } from '@/models/models'
import { ContentStoreItemWrapperStatus } from '@/enums'

export default {
    name: 'ContentStoreProductProvider',
    components: {
        ContentStoreList,
        ContentStoreImport,
    },
    mixins: [contentStoreMixin],
    contentStoreSettings: {
        items: PRODUCT_PROVIDERS,
    },
    props: {
        contentType: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            columns: [
                // TODO improvement: Add more details/info to table (address, zip, city, as well as a preview of the items)
                { name: 'company_name', label: this.$tc('common.term.name'), field: row => row.item.companyName, sortable: true, align: 'left' },
                { name: 'status', label: this.$tc('common.term.status'), field: row => row.status, sortable: true, align: 'left' },
            ],

            // Address
            addressCategories: [],
            addressLabels: [],

            // EmailAddress
            emailAddressCategories: [],
            emailAddressLabels: [],

            // PhoneNumber
            phoneNumberCategories: [],
            phoneNumberLabels: [],
        }
    },
    created () {
        // Address
        ContactService.getAddressCategories().then(addressCategories => (this.addressCategories = addressCategories))
        ContactService.getAddressLabels().then(addressLabels => (this.addressLabels = addressLabels))

        // EmailAddress
        ContactService.getEmailAddressCategories().then(emailAddressCategories => (this.emailAddressCategories = emailAddressCategories))
        ContactService.getEmailAddressLabels().then(emailAddressLabels => (this.emailAddressLabels = emailAddressLabels))

        // PhoneNumber
        ContactService.getPhoneNumberCategories().then(phoneNumberCategories => (this.phoneNumberCategories = phoneNumberCategories))
        ContactService.getPhoneNumberLabels().then(phoneNumberLabels => (this.phoneNumberLabels = phoneNumberLabels))
    },
    methods: {
        importItem (contentStoreItemWrapper) {
            contentStoreItemWrapper.status = ContentStoreItemWrapperStatus.IMPORTING
            const productProvider = contentStoreItemWrapper.item
            const variables = Object.assign({}, productProvider, {
                consultants: [this.$store.state.user.consultant.id],
            })

            // Address
            const mainAddress = productProvider.addresses.find(address => address.isMain === true)
            const address = Object.assign({}, mainAddress, {
                category: this.addressCategories.find(addressCategory => addressCategory.key === mainAddress.category.key).id,
                label: null,
                country: mainAddress.country.id,
            })
            if (mainAddress.label) address.label = this.addressLabels.find(addressLabel => addressLabel.key === mainAddress.label.key).id
            variables.addresses = [address]

            // Email address
            const mainEmailAddress = (productProvider.emailAddresses) ? productProvider.emailAddresses.find(emailAddress => emailAddress.isMain === true) : null
            if (mainEmailAddress) {
                const emailAddress = Object.assign({}, mainEmailAddress, {
                    category: this.emailAddressCategories.find(emailAddressCategory => emailAddressCategory.key === mainEmailAddress.category.key).id,
                    label: null,
                    emailAddress: mainEmailAddress.email,
                })
                if (mainEmailAddress.label) emailAddress.label = this.emailAddressLabels.find(emailAddressLabel => emailAddressLabel.key === mainEmailAddress.label.key).id
                variables.emailAddresses = [emailAddress]
            }

            // Phone number
            const mainPhoneNumber = (productProvider.phoneNumbers) ? productProvider.phoneNumbers.find(phoneNumber => phoneNumber.isMain === true) : null
            if (mainPhoneNumber) {
                const phoneNumber = Object.assign({}, mainPhoneNumber, {
                    category: this.phoneNumberCategories.find(phoneNumberCategory => phoneNumberCategory.key === mainPhoneNumber.category.key).id,
                    label: null,
                    phoneNumber: mainPhoneNumber.phoneNumber,
                })
                if (mainPhoneNumber.label) phoneNumber.label = this.phoneNumberLabels.find(phoneNumberLabel => phoneNumberLabel.key === mainPhoneNumber.label.key).id
                variables.phoneNumbers = [phoneNumber]
            }

            // Import
            Company.create(variables)
                .then(company => {
                    const displayName = (productProvider.productProviderSettings && productProvider.productProviderSettings.displayName) ? productProvider.productProviderSettings.displayName : ''
                    ProductProviderSettings.create({ contactId: company.id, displayName })
                        .then(() => {
                            this.handleImportSuccess(contentStoreItemWrapper)
                        })
                        .catch(() => {
                            company.delete()
                            this.handleImportFailure(contentStoreItemWrapper)
                        })
                })
                .catch(() => {
                    this.handleImportFailure(contentStoreItemWrapper)
                })
        },
    },
}
</script>
