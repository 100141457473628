<template>
    <div>
        <base-input
            :value="entityNumber"
            v-bind="$attrs"
            :mask="entityNumberMask"

            fill-mask
            @input="updateValue"
        >
            <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
        </base-input>
    </div>
</template>

<script>
import BaseInput from '@/components/form/BaseInput.vue'

export default {
    name: 'EntityNumberInput',
    components: {
        BaseInput,
    },
    inheritAttrs: false,
    props: {
        value: {
            type: Array,
            required: true,
        },
    },
    computed: {
        entityNumber () {
            return this.value.map(segment => segment.value).join('')
        },
        entityNumberMask () {
            let mask = ''
            this.value.forEach(segment => {
                if (segment.mutable) {
                    switch (segment.type) {
                        case 'string':
                            mask += 'N'.repeat(segment.value.length)
                            break
                        case 'date':
                            mask += '#'.repeat(segment.value.length)
                            break
                        case 'counter':
                            mask += '#'.repeat(segment.paddingLeft || segment.maxLength)
                            break
                    }
                } else {
                    let sliceLength = (segment.type === 'counter') ? segment.paddingLeft : segment.value.length
                    mask += this.entityNumber.slice(mask.length, mask.length + sliceLength)
                }
            })
            return mask
        },
    },
    methods: {
        updateValue (inputValue) {
            const inputValueArray = inputValue.split('')
            const valueStructure = JSON.parse(JSON.stringify(this.value))
            valueStructure.forEach(segment => {
                segment.value = inputValueArray.splice(0, segment.value.length).join('')
            })
            this.$emit('input', valueStructure)
        },
    },
}
</script>
