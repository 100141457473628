import { apolloClient } from '@/apollo/client'

import { COMMISSION_REVENUE_REPORTS_CREATE_MUTATION } from '@/graphql/commissionRevenueReport/commissionRevenueReports'

export const CommissionRevenueReportService = {
    createBulk (variables) {
        return apolloClient.mutate({
            mutation: COMMISSION_REVENUE_REPORTS_CREATE_MUTATION,
            variables,
        }).then(response => response.data.commissionRevenueReportsCreate)
    },
}
