import gql from 'graphql-tag'

export const USER_VALIDATE_2FA_MUTATION = gql`
    mutation userValidate2FA($two_fa_code: String!) {
        userValidate2FA(
            two_fa_code: $two_fa_code
        ) {
            two_fa_backup_code
        }
    }
`
