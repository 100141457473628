<template>
    <page-loading-indicator v-if="!commissionTypeAccountingConfiguration" :number-of-breadcrumb-elements="2" />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('views.commission.accounting.commission-type-configuration.commission-type-configuration')" :to="{ name: 'commission-type-accounting-configuration-list' }" />
                <q-breadcrumbs-el
                    :label="`${commissionTypeAccountingConfiguration.igb2bCommissionTypeCode} – ${commissionTypeAccountingConfiguration.postingType.name}`"
                    :to="{ name: 'commission-type-accounting-configuration-update', params: { id: commissionTypeAccountingConfiguration.id } }"
                    data-test="breadcrumb:commission-type-accounting-configuration-detail"
                />
            </template>

            <h1>{{ `${commissionTypeAccountingConfiguration.igb2bCommissionTypeCode} – ${commissionTypeAccountingConfiguration.postingType.name}` }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12 col-lg-10 col-xl-8">
                <commission-type-accounting-configuration-form
                    v-if="commissionTypeAccountingConfiguration"
                    ref="commissionTypeAccountingConfigurationForm"
                    :commission-type-accounting-configuration="commissionTypeAccountingConfiguration"
                    @submit="onSubmit"
                />
            </div>
        </div>

        <div>
            <p>
                <small>
                    <base-button
                        show-as-link
                        @click="$refs.activityLogOverlay.open({ filterSubjects: [{ id: id, type: ActivityLogSubjectType.COMMISSION_TYPE_ACCOUNTING_CONFIGURATION }] })"
                    >
                        <template v-if="commissionTypeAccountingConfiguration.created_at === commissionTypeAccountingConfiguration.updated_at">{{ $tc('common.term.created-on--date-time', 1, { date: commissionTypeAccountingConfiguration.formattedCreatedAtDate, time: commissionTypeAccountingConfiguration.formattedCreatedAtTime }) }}</template>
                        <template v-else>{{ $tc('common.term.updated-on--date-time--last-updated', 1, { date: commissionTypeAccountingConfiguration.formattedUpdatedAtDate, time: commissionTypeAccountingConfiguration.formattedUpdatedAtTime }) }}</template>
                    </base-button>
                </small>
            </p>
        </div>

        <!-- Activity log -->
        <activity-log-overlay ref="activityLogOverlay" />
    </page-wrapper>
</template>

<script>
import { CommissionTypeAccountingConfiguration } from '@/models/commissionTypeAccountingConfiguration'
import CommissionTypeAccountingConfigurationForm from '@/components/commission/CommissionTypeAccountingConfigurationForm'
import ActivityLogOverlay from '@/components/ActivityLogOverlay'
import { ActivityLogSubjectType } from '@/enums/graphql'

export default {
    name: 'CommissionTypeAccountingConfigurationUpdate',
    components: {
        CommissionTypeAccountingConfigurationForm,
        ActivityLogOverlay,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    meta () {
        return {
            title: !this.commissionTypeAccountingConfiguration ? `${this.$t('common.term.loading')} …` : `${this.commissionTypeAccountingConfiguration.igb2bCommissionTypeCode} – ${this.commissionTypeAccountingConfiguration.postingType.name}`,
        }
    },
    data () {
        return {
            ActivityLogSubjectType,
            commissionTypeAccountingConfiguration: null,
        }
    },
    created () {
        CommissionTypeAccountingConfiguration.service.get(this.id).then(commissionTypeAccountingConfiguration => {
            this.commissionTypeAccountingConfiguration = commissionTypeAccountingConfiguration
        })
            .catch(error => {
                // Forward to `forbidden` error view.
                this.$router.replace({ name: '403' }) // TODO @TFU: Differentiate between 403 and 404.
            })
    },
    methods: {
        onSubmit (commissionTypeAccountingConfiguration) {
            this.commissionTypeAccountingConfiguration.update(commissionTypeAccountingConfiguration)
                .then(() => {
                    this.$router.replace({ name: 'commission-type-accounting-configuration-list' })
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.commission.commission-type-accounting-configuration-saved-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.commissionTypeAccountingConfigurationForm.showFormErrorMessage(error)
                    this.$refs.commissionTypeAccountingConfigurationForm.resetFormSubmitStatus()
                })
        },
    },
}
</script>
