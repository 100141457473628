import gql from 'graphql-tag'

export const PRINT_JOB_CREATE_MUTATION = gql`
    mutation printJobCreate(
        $fileId: ID!
        $printerId: ID!
    ) {
        printJobCreate(
            file_id: $fileId
            printer_id: $printerId
        ) {
            status
        }
    }
`
