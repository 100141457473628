<template>
    <component
        :is="componentName"
        :value="value"
        v-bind="$attrs"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </component>
</template>

<script>
import ContactFileTypeSelect from '@/components/consultingFile/ContactFileTypeSelect'
import ApplicationFileTypeSelect from '@/components/consultingFile/ApplicationFileTypeSelect'
import ContractFileTypeSelect from '@/components/consultingFile/ContractFileTypeSelect'
import { ConsultingFileTargetObjectType } from '@/enums/graphql'
import { computed } from 'vue'

export default {
    name: 'ConsultingFileTypeSelect',
    components: {
        ContactFileTypeSelect,
        ApplicationFileTypeSelect,
        ContractFileTypeSelect,
    },
    props: {
        value: {
            required: true,
        },
        targetObjectType: {
            type: String,
            required: true,
        },
    },
    setup (props) {
        const componentName = computed(() => {
            switch (props.targetObjectType) {
                case ConsultingFileTargetObjectType.CONTACT:
                    return 'ContactFileTypeSelect'
                case ConsultingFileTargetObjectType.APPLICATION:
                    return 'ApplicationFileTypeSelect'
                case ConsultingFileTargetObjectType.CONTRACT:
                    return 'ContractFileTypeSelect'
            }
        })

        return {
            componentName,
        }
    },
}
</script>
