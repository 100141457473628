import { Form, FormField } from '@/libs/form'
import { isAfterOrEqualToDate, isBeforeOrEqualToDate } from '@/libs/form/validators'

export class CommissionRevenueReportForm extends Form {
    compose () {
        this.addField(new FormField('dateFrom', {
            label: 'forms.field.label.statement.accounting-period--from',
            inputType: 'calendar',
            validators: [
                { type: 'required' },
                { type: 'date' },
                {
                    type: 'isBeforeOrEqualToDate',
                    validator: () => {
                        return isBeforeOrEqualToDate(this.getItem('dateFrom').value, this.getItem('dateUntil').value)
                    },
                    params: {
                        targetLabel: 'forms.field.label.statement.accounting-period--to',
                    },
                },
            ],
        }))

        this.addField(new FormField('dateUntil', {
            label: 'forms.field.label.statement.accounting-period--to',
            inputType: 'calendar',
            validators: [
                { type: 'required' },
                { type: 'date' },
                {
                    type: 'isAfterOrEqualToDate',
                    validator: () => {
                        return isAfterOrEqualToDate(this.getItem('dateUntil').value, this.getItem('dateFrom').value)
                    },
                    params: {
                        targetLabel: 'forms.field.label.statement.accounting-period--from',
                    },
                },
            ],
        }))
    }
}
