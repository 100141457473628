import { i18n } from '@/i18n'
import { ActivityLogParser } from '@/parsers/activityLog'

export class EmailAddressActivityLogParser extends ActivityLogParser {
    static attributeDataTypes = {
        'label.id': 'emailAddressLabel',
        'is_main_email_address': 'boolean',
    }

    static getLogDescriptionUpdated (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `${i18n.tc('common.change-log.someone-updated-something--field', activityLog.attributeKeys.length)} <b>${activityLog.attributeLabels}</b>`,
            // TODO: Re-add this when `activityLog.properties.attributes.email` is always logged
            // ofSomething: `${i18n.tc('common.change-log.subject.of-this.email-address', 1)} ${activityLog.properties.attributes.email}`,
            ofSomething: `${i18n.tc('common.change-log.subject.of-some.email-address', 1)}`,
        }
        return i18n.t('common.change-log.someone-updated-something-of-something', interpolationParams)
    }
}
