$<template>
    <q-list padding class="main-menu">
        <q-item :to="{ name: 'user-manual' }" exact>
            <q-item-section avatar>
                <q-icon name="mi-book-flip-page" />
            </q-item-section>
            <q-item-section>{{ $tc('common.user-manual.user-manual', 1) }}</q-item-section>
        </q-item>

        <q-separator />

        <!-- TODO improvement: Decide if it would make sense to only show those parts of the user manual that the user actually can use? (What about different roles? Features?) -->
        <template v-for="chapter in userManual.chapters">
            <!-- TODO: Check if `class="subitem-points-to-same-route"` is needed here, too – and if so, find a way to dynamically add it -->
            <q-expansion-item
                v-if="chapter.pages.length > 1"
                :key="`navigation-item-chapter-${chapter.key}`"
                v-model="navGroupState[chapter.key]"
                :to="{ name: 'user-manual', params: { chapter: chapter.key }}"
                :label="chapter.label[$i18n.locale]"
                :icon="(chapter.navIcon) || 'mi-book-next-page'"
                expand-icon="mib-arrow-left-1"
                expanded-icon="mib-arrow-down-1"
                expand-icon-toggle
                :content-inset-level="1"
            >
                <q-list dense>
                    <template v-for="page in chapter.pages">
                        <q-item
                            v-if="page.key !== 'index'"
                            :key="`navigation-item-chapter-${chapter.key}-page-${page.key}`"
                            :to="{ name: 'user-manual', params: { chapter: chapter.key, page: page.key }}"
                            exact-path
                        >
                            <q-item-section>{{ page[$i18n.locale].attributes.label }}</q-item-section>
                        </q-item>
                    </template>
                </q-list>
            </q-expansion-item>

            <q-item
                v-else
                :key="`navigation-item-chapter-${chapter.key}`"
                :to="{ name: 'user-manual', params: { chapter: chapter.key }}"
                exact-path
            >
                <q-item-section avatar>
                    <q-icon :name="(chapter.navIcon) || 'mi-book-next-page'" />
                </q-item-section>
                <q-item-section>{{ chapter.label[$i18n.locale] }}</q-item-section>
            </q-item>
        </template>

        <q-item :to="{ name: 'glossary' }" exact>
            <q-item-section avatar>
                <q-icon name="mi-book-next-page" />
            </q-item-section>
            <q-item-section>{{ $tc('common.term.glossary', 1) }}</q-item-section>
        </q-item>
    </q-list>
</template>

<script>
import userManual from '#/user-manual'

export default {
    name: 'MainMenuUserManual',
    data () {
        const navGroupState = {}
        userManual.chapters.forEach(chapter => {
            navGroupState[chapter.key] = this.$route.params.chapter === chapter.key
        })

        return {
            userManual,
            navGroupState,
        }
    },
    watch: {
        $route (to) {
            if (this.navGroupState[to.params.chapter] !== undefined) {
                this.navGroupState[to.params.chapter] = true
            }
        },
    },
}
</script>
