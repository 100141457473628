import { Model } from '@/models'
import { CorrespondenceEmailService } from '@/services'

export class ProcessedCorrespondenceEmail extends Model {
    /**
     * Create a ProcessedCorrespondenceEmail model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'correspondenceItem',
            'targetObject',
            'status',
            'status_message',
            'creator',
            'sender',
            'recipient_address',
            'subject',
            'language',
            'esp_email_id',
            'file_id',
        ], data)
    }

    static get service () { return CorrespondenceEmailService }

    /**
     * Delete this processedCorrespondenceEmail.
     * @returns {Promise<*>}
     */
    delete (deleteConsultingFile = false) {
        // TODO @TFU: Implement once available on API.
        // return CorrespondenceEmailService.delete(this.id)
    }
}
