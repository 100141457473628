<template>
    <page-loading-indicator
        v-if="!posting"
        :number-of-breadcrumb-elements="2"
        actions
        :number-of-next-actions="1"
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.accounting.posting.posting', 2)" :to="{ name: 'accounting-posting-list' }" />
                <q-breadcrumbs-el :label="$tc('views.accounting.posting.update-posting', 1)" :to="{ name: 'accounting-posting-update', params: { id: id } }">
                    <template v-if="posting.number.number"> (#{{ posting.number.number }})</template>
                </q-breadcrumbs-el>
            </template>

            <h1>{{ $tc('views.accounting.posting.update-posting', 1) }}</h1>

            <template v-slot:actions>
                <actions
                    :actions="posting.actions"
                    translation-base-key="common.actions.accounting"
                    @click="handleActions"
                />

                <!-- Delete Posting -->
                <form-dialog
                    ref="deleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleDelete"
                >
                    <template v-slot:title>{{ $tc('views.accounting.posting.delete-posting', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="testing.placeholder" tag="p">
                            <template v-slot:placeholder><b>{{ posting.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <posting-form
            ref="postingForm"
            :posting="posting"
            @submit="onSubmit"
        />

        <div>
            <p>
                <small>
                    <base-button
                        show-as-link
                        @click="$refs.activityLogOverlay.open({ filterSubjects: [{ id: id, type: ActivityLogSubjectType.POSTING }] })"
                    >{{ $tc('common.term.created-on--date-time', 1, { date: posting.formattedCreatedAtDate, time: posting.formattedCreatedAtTime }) }}</base-button>
                </small>
            </p>
        </div>

        <!-- Activity log -->
        <activity-log-overlay ref="activityLogOverlay" />
    </page-wrapper>
</template>

<script>
import { Posting } from '@/models/posting'
import PostingForm from '@/components/accounting/PostingForm'
import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'
import { EventBus } from '@/event-bus'
import { FileUploadTargetObjectType } from '@/enums'
import { ActivityLogSubjectType } from '@/enums/graphql'
import { prepareFileUploadWrappers } from '@/helpers/file'
import ActivityLogOverlay from '@/components/ActivityLogOverlay'

export default {
    name: 'PostingUpdate',
    meta () {
        return {
            title: this.$tc('views.accounting.posting.update-posting', 1),
        }
    },
    components: {
        PostingForm,
        Actions,
        FormDialog,
        ActivityLogOverlay,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            ActivityLogSubjectType,
            posting: null,
        }
    },
    created () {
        Posting.service.get(this.id)
            .then(posting => {
                this.posting = posting
            })
            .catch(error => {
                // Forward to `forbidden` error view.
                this.$router.replace({ name: '403' }) // TODO @TFU: Differentiate between 403 and 404.
            })
    },
    methods: {
        onSubmit (formData) {
            this.posting.update(formData)
                .then(async (posting) => {
                    if (formData.attachments?.length) {
                        // Add attachments to file upload manager queue.
                        const fileUploadWrappers = await prepareFileUploadWrappers(formData.attachments, {
                            targetObjectType: FileUploadTargetObjectType.POSTING,
                            targetObjectId: posting.id,
                        })
                        EventBus.$emit('fileUploadManager:addToUploadQueue', fileUploadWrappers)
                    }
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.accounting.posting-updated-success', 1),
                    })
                    this.$router.replace({ name: 'accounting-posting-list' })
                })
                .catch(error => {
                    this.$refs.postingForm.showFormErrorMessage(error)
                    this.$refs.postingForm.resetFormSubmitStatus()
                })
        },
        handleActions (action) {
            switch (action.key) {
                case Posting.action.DELETE:
                    this.$refs.deleteDialog.open()
                    break
            }
        },
        handleDelete () {
            this.posting.delete()
                .then(() => {
                    this.$refs.deleteDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.accounting.posting-deleted-success', 1),
                    })
                    this.$router.replace({ name: 'accounting-posting-list' })
                })
                .catch(error => {
                    this.$refs.deleteDialog.showFormErrorMessage(error)
                    this.$refs.deleteDialog.resetFormSubmitStatus()
                })
        },
    },
}
</script>
