<template>
    <div class="illustration-heading-wrapper">
        <h1>
            <template v-if="icon && iconBefore"><q-icon :name="icon" :color="iconColor" /></template>
            {{ title }}
            <template v-if="subtitle"> <span class="subtitle">({{ subtitle }})</span></template>
            <template v-if="icon"><q-icon :name="icon" :color="iconColor" /></template>
        </h1>
        <illustration
            :name="img"
            :width="imgWidth"
            :min-width="imgMinWidth"
            :max-width="imgMaxWidth"
            :error="error"
        />
    </div>
</template>

<script>
export default {
    name: 'IllustrationHeading',
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        iconBefore: {
            type: Boolean,
            default: false,
        },
        iconColor: {
            type: String,
            default: 'secondary-light',
        },
        img: {
            type: String,
            default: 'laptop-information',
        },
        imgWidth: {
            type: String,
            default: '22vh',
        },
        imgMinWidth: {
            type: String,
            default: '10rem',
        },
        imgMaxWidth: {
            type: String,
            default: '14rem',
        },
        error: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.illustration-heading-wrapper {
    text-align: center;
}

h1,
h2,
h3,
h4 {
    margin-bottom: 0;
}
</style>
