import { Model } from '@/models'
import { PostingTypeService } from '@/services'
import { PostingTypeActions } from '@/enums'
import { i18n } from '@/i18n'

export class PostingType extends Model {
    /**
     * Create a PostingType model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'key',
            'name',
            'name_language_versions',
        ], data)
    }

    static action = PostingTypeActions

    static get service () { return PostingTypeService }

    /**
     * Create a new postingType.
     * @param {Object} postingType - The values used to create the postingType.
     * @returns {Promise<*>}
     */
    static create (postingType) {
        const variables = { postingType }
        variables.postingType.name = JSON.stringify(variables.postingType.name)

        return PostingTypeService.create(variables)
    }

    /**
     * Update this postingType.
     * @param {Object} postingType - The values used to update the postingType.
     * @returns {Promise<*>}
     */
    update (postingType) {
        const variables = Object.assign({}, postingType)
        variables.name = JSON.stringify(variables.name)

        return PostingTypeService.update(this.id, variables).then(postingType => {
            Object.assign(this, postingType)
            return postingType
        })
    }

    /**
     * Delete this postingType.
     * @returns {Promise<*>}
     */
    delete () {
        return PostingTypeService.delete(this.id)
    }

    get actions () {
        const actions = super.actions

        const deleteAction = {
            key: PostingTypeActions.DELETE,
            item: this,
        }

        if (this.isSystemPostingType) {
            Object.assign(deleteAction, {
                disable: true,
                infoText: i18n.t('views.accounting.posting-type.system-posting-type--description'),
            })
        }

        actions.push(deleteAction)
        return actions
    }

    get isSystemPostingType () {
        return !!this.key
    }
}
