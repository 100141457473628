<template>
    <q-input
        ref="baseInput"
        :value="value"
        v-bind="$attrs"
        dense
        filled
        square
        @input="onInput"
        @change="onChange"
        @keyup="onKeyUp"
        @keydown="onKeyDown"
        @focus="onFocus"
        @blur="onBlur"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" /><!-- Named slots -->
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template><!-- Scoped slots -->

        <template v-if="infoText || infoTitle" v-slot:append>
            <info-icon
                :title="infoTitle"
                :text="infoText"
                class="all-pointer-events"
            />
        </template>
    </q-input>
</template>

<script>
export default {
    name: 'BaseInput',
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        infoTitle: {
            type: String,
            default: '',
        },
        infoText: {
            type: String,
            default: '',
        },
    },
    methods: {
        async focus () {
            this.$refs.baseInput.focus()
        },
        onInput (value) {
            this.$emit('input', value)
        },
        onChange (event) {
            this.$emit('change', event.target.value)
        },
        onKeyDown (event) {
            this.$emit('keydown', event)
        },
        onKeyUp (event) {
            this.$emit('keyup', event)
        },
        onFocus (event) {
            this.$emit('focus', event)
        },
        onBlur (event) {
            this.$emit('blur', event)
        },
    },
}
</script>

<style lang="scss" scoped>
.tooltip {
    opacity: 0.54; // Default Quasar opacity when element has hover effect
    transition: opacity $defaultTransitionStyles;

    cursor: help;

    &:hover,
    &:focus,
    &:active {
        opacity: 1;
    }
}
</style>
