<template>
    <form
        v-if="commissionRateAdjustmentRuleSets && !allProductProvidersConfigured"
        data-test="form:commission-rate-adjustment-rule-set"
        class="row q-col-gutter-md"
        @submit.prevent="onFormSubmit"
    >
        <div
            v-if="(currentMode === ViewMode.UPDATE && anyDirty) || formErrorMessage"
            class="col-xs-12"
        >
            <info-box
                v-if="currentMode === ViewMode.UPDATE && anyDirty"
                type="warning"
                data-test="warning:unsaved-changes"
            >
                <i18n path="common.rule.unsaved-changes">
                    <template v-slot:item>{{ $tc('common.rule.unsaved-changes--item-part--rule-set', 1) }}</template>
                </i18n>
            </info-box>

            <!-- eslint-disable vue/no-v-html -->
            <info-box
                v-if="formErrorMessage"
                type="negative"
                data-test="error:global-message"
                v-html="formErrorMessage"
            />
            <!-- eslint-enable -->
        </div>

        <template v-if="currentMode === ViewMode.CREATE">
            <div class="col-xs-12 col-md-8 col-lg-6 col-xl-4">
                <form-builder item-key="productProviderId" :filter-fn="filterProductProviderOptions" />
            </div>
            <div class="col-xs-12">
                <q-separator />
            </div>
        </template>

        <div class="col-xs-12">
            <commission-rate-adjustment-rule-manage
                ref="commissionRateAdjustmentRuleManage"
                :commission-rate-adjustment-rule-set-rules="commissionRateAdjustmentRuleSetRules"
            />
        </div>

        <in-page-footer>
            <base-button
                primary-button
                flat
                data-test="btn:cancel"
                :label="$t('common.term.cancel')"
                @click="cancel"
            />
            <!-- TODO @TFU: Add `:loading` state to this button? (During the time until the updated `markup-table` is visible again.) -->
            <base-button
                primary-button
                type="submit"
                data-test="btn:save"
                :label="currentMode === ViewMode.CREATE ? $t('common.term.create') : $tc('common.rule.save-rule-set', 1)"
                :disabled="!anyDirty"
            />
        </in-page-footer>
    </form>
</template>

<script>
import { ViewMode } from '@/enums'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import { CommissionRateAdjustmentRuleSet } from '@/models/models'

import COMMISSION_RATE_ADJUSTMENT_RULE_SET_FORM from '@/forms/commissionRateAdjustmentRuleSet.form'

import CommissionRateAdjustmentRuleManage from '@/components/commission/commissionRateAdjustmentRule/CommissionRateAdjustmentRuleManage.vue'
import { EventBus } from '@/event-bus'

export default {
    name: 'CommissionRateAdjustmentRuleSetForm',
    components: {
        CommissionRateAdjustmentRuleManage,
    },
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: COMMISSION_RATE_ADJUSTMENT_RULE_SET_FORM,
    },
    props: {
        commissionRateAdjustmentRuleSet: {
            type: Object,
            required: false,
            default () {
                return undefined
            },
        },
    },
    data () {
        const data = {
            ViewMode,
            currentMode: this.commissionRateAdjustmentRuleSet ? ViewMode.UPDATE : ViewMode.CREATE,
            formData: {},
            commissionRateAdjustmentRuleSets: null,
            allProductProvidersConfigured: false,
            anyDirty: false,
        }

        if (data.currentMode === ViewMode.UPDATE) {
            Object.assign(data.formData, {
                productProviderId: this.commissionRateAdjustmentRuleSet.productProvider.id,
            })
        }

        return data
    },
    computed: {
        commissionRateAdjustmentRuleSetRules () {
            return this.commissionRateAdjustmentRuleSet ? this.commissionRateAdjustmentRuleSet.rules : []
        },
    },
    created () {
        EventBus.$on('commissionRateAdjustmentRuleSet:submit-rule-forms-any-dirty-state', this.handleAnyDirtyState)
        CommissionRateAdjustmentRuleSet.objects.all().then(response => {
            this.commissionRateAdjustmentRuleSets = response.data
        })
    },
    beforeDestroy () {
        EventBus.$off('commissionRateAdjustmentRuleSet:submit-rule-forms-any-dirty-state', this.handleAnyDirtyState)
    },
    methods: {
        handleAnyDirtyState (anyDirtyState) {
            this.anyDirty = true
        },
        filterProductProviderOptions (options) {
            const productProvidersWithRuleSets = this.commissionRateAdjustmentRuleSets.map(ruleSet => ruleSet.productProvider.id)
            const filteredOptions = options.filter(productProvider => {
                return !productProvidersWithRuleSets.includes(productProvider.id)
            })
            if (!filteredOptions.length) {
                this.allProductProvidersConfigured = true
                this.$emit('allProductProvidersConfigured')
            }
            return filteredOptions
        },
        cancel () {
            this.$router.push({
                name: 'commission-rate-adjustment-rule-set-list',
            })
        },
    },
}
</script>
