import { render, staticRenderFns } from "./CorrespondenceItemTargetObjectConfigurator.vue?vue&type=template&id=2013e3de&scoped=true&"
import script from "./CorrespondenceItemTargetObjectConfigurator.vue?vue&type=script&lang=js&"
export * from "./CorrespondenceItemTargetObjectConfigurator.vue?vue&type=script&lang=js&"
import style0 from "./CorrespondenceItemTargetObjectConfigurator.vue?vue&type=style&index=0&id=2013e3de&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2013e3de",
  null
  
)

export default component.exports