<template>
    <div id="task-panel">
        <!-- TODO @TFU: The numbers and the shown tasks should update automatically (at least when changing the route). Currently it stays the same until the page is refreshed/reloaded. -->
        <!-- TODO @TFU (related to the one above): If someone else assigns an urgent task to a user, it’s not visible in the panel until the user reloads the whole app/client. -->
        <h1 class="text-h2">{{ $tc('common.task.task', 2) }} <info-icon
            size="0.6em"
            anchor="bottom middle"
            self="top middle"
            :offset-x="0"
            :offset-y="10"
            :text="$t('common.task.task--panel-description')"
        /></h1>

        <info-box v-if="targetObject">
            <template v-if="numberOfTargetObjectRelatedTasks === null">
                <p>
                    <skeleton type="text" />
                    <skeleton type="text" width="60%" />
                </p>
                <div class="task-panel-footer flex flex-center">
                    <skeleton type="QBtn" bordered />
                </div>
            </template>
            <template v-else>
                <i18n :path="`common.task.task--pending-task--n-pending-tasks--for-target-object--${targetObject.targetObjectType.toLowerCase()}`" tag="p">
                    <template v-slot:numberOfPendingTasks>
                        <router-link v-if="numberOfTargetObjectRelatedTasks > 0" :to="{ name: 'task-list', query: { filterTargetObjectType: targetObject.targetObjectType, filterTargetObjectId: targetObject.id } }">{{ $tc('common.task.task--pending-task--n-pending-tasks', numberOfTargetObjectRelatedTasks) }}</router-link>
                        <template v-else>{{ $tc('common.task.task--pending-task--n-pending-tasks', 0) }}</template>
                    </template>
                    <template v-slot:targetObjectName><b>{{ targetObjectName }}</b></template>
                </i18n>

                <!-- TODO improvement @TFU: Only show the footer if the user :can create tasks -->
                <div class="task-panel-footer flex flex-center">
                    <base-button
                        v-if="targetObject.targetObjectType"
                        :label="$tc('common.task.add-task', 1)"
                        primary-button
                        outline
                        @click="() => triggerCreateTask(targetObject)"
                    />
                </div>
            </template>
        </info-box>

        <div v-if="assignedTasks" class="task-panel-list-wrapper assigned-tasks q-mb-lg">
            <h3 class="text-h4">{{ $tc('common.task.assigned-to-me', numberOfUrgentAssignedTasks) }} <span v-if="numberOfUrgentAssignedTasks" class="subtitle">({{ $tc('common.task.task--n-tasks', numberOfUrgentAssignedTasks) }})</span></h3>
            <template v-if="numberOfUrgentAssignedTasks">
                <q-separator />
                <task-panel-list
                    :tasks="assignedTasks"
                    group="task-panel"
                    no-assignee
                />

                <q-item
                    class="task-info-list-item custom-link"
                    :to="{ name: 'task-list', query: { filterAssigneeId: userId } }"
                >
                    <q-item-section avatar class="task-icon-wrapper self-center">
                        <q-icon
                            name="mib-checklist-alternate"
                            color="secondary"
                            size="xs"
                        />
                    </q-item-section>

                    <q-item-section>
                        <q-item-label>{{ $t('common.task.all-my-assigned-tasks') }}</q-item-label>
                    </q-item-section>
                </q-item>
                <q-separator />
            </template>

            <p v-else class="additional-info q-mb-none">{{ $tc('common.task.task--n-tasks', 0) }}</p>
        </div>

        <!-- TODO improvement @MTR: Adjust q-mb-class (.q-mb-md / .q-mb-lg) if the user :can/:cannot create tasks and the "create" button is shown/hidden accordingly -->
        <div v-if="createdTasks" :class="['task-panel-list-wrapper created-tasks', 'q-mb-md']">
            <h3 class="text-h4">{{ $t('common.task.created-by-me') }} <span v-if="numberOfUrgentCreatedTasks" class="subtitle">({{ $tc('common.task.task--n-tasks', numberOfUrgentCreatedTasks) }})</span></h3>
            <template v-if="numberOfUrgentCreatedTasks">
                <q-separator />

                <task-panel-list :tasks="createdTasks" group="task-panel" no-creator />

                <q-item class="task-info-list-item custom-link" :to="{ name: 'task-list', query: { filterCreatorId: userId } }">
                    <q-item-section avatar class="task-icon-wrapper self-center">
                        <q-icon name="mib-checklist-alternate" color="secondary" size="xs" />
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>{{ $t('common.task.all-my-created-tasks') }}</q-item-label>
                    </q-item-section>
                </q-item>

                <q-separator />
            </template>

            <p v-else class="additional-info q-mb-none">{{ $tc('common.task.task--n-tasks', 0) }}</p>
        </div>

        <!-- TODO improvement @TFU: Only show the footer if the user :can create tasks -->
        <div class="task-panel-footer flex flex-center">
            <base-button
                :label="$tc('common.task.add-task', 1)"
                primary-button
                outline
                @click="() => triggerCreateTask()"
            />
        </div>
    </div>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { EventBus } from '@/event-bus'
import { TargetObjectType, TaskDueDateStatus } from '@/enums'
import { OrderDirection, TaskStatus } from '@/enums/graphql'
import { Task } from '@/models/models'
import { formatDate } from '@/helpers/date'
import TaskPanelList from '@/components/task/TaskInfoList.vue'

import {
    addDays,
    endOfDay,
    formatISO
} from 'date-fns'

export default {
    name: 'TaskPanel',
    components: {
        TaskPanelList,
    },
    data () {
        const store = this.$store.state
        const userId = store.user.consultant.id

        return {
            EventBus,
            TaskDueDateStatus,
            userId,
            assignedTasks: null,
            numberOfUrgentAssignedTasks: null,
            createdTasks: null,
            numberOfUrgentCreatedTasks: null,
            targetObject: undefined,
            numberOfTargetObjectRelatedTasks: null,
            totalTasks: 5,
        }
    },
    computed: {
        targetObjectName () {
            if (this.targetObject) {
                switch (this.targetObject.targetObjectType) {
                    case TargetObjectType.CONTACT:
                        return this.targetObject.getContactName()
                    case TargetObjectType.APPLICATION:
                        return this.targetObject.formattedNumber
                    case TargetObjectType.CONTRACT:
                        return this.targetObject.currentContractNumber
                }
            }
            return null
        },
    },
    watch: {
        async targetObject (targetObject) {
            this.numberOfTargetObjectRelatedTasks = null
            if (!targetObject) return

            const filterTargetObject = { filterTargetObjectType: targetObject.targetObjectType, filterTargetObjectId: targetObject.id }
            const tasksResponse = await Task.service.all({
                orderDirection: OrderDirection.DESC,
                ...filterTargetObject,
                filterStatus: [TaskStatus.TODO, TaskStatus.IN_PROGRESS],
            })
            this.numberOfTargetObjectRelatedTasks = tasksResponse.paginatorInfo.total
        },
    },
    // TODO refactoring @TFU: Use Pinia store instead of using events (in all places: Dashboard, TaskList, TaskDetail, TaskPanel, etc.).
    created () {
        EventBus.$on('taskPanel:setTargetObject', this.setTargetObject)
        EventBus.$on('task:create', this.fetchTasks)
        EventBus.$on('task:update', this.fetchTasks)
        EventBus.$on('task:delete', this.fetchTasks)
        this.fetchTasks()
    },
    beforeDestroy () {
        EventBus.$off('taskPanel:setTargetObject', this.setTargetObject)
        EventBus.$off('task:create', this.fetchTasks)
        EventBus.$off('task:update', this.fetchTasks)
        EventBus.$off('task:delete', this.fetchTasks)
    },
    methods: {
        async fetchTasks () {
            const dueDateUntil = endOfDay(addDays(new Date(), Task.UPCOMING_DUE_DATE_AMOUNT_OF_DAYS))
            const tasksResponse = await Task.service.panel({
                count: this.totalTasks,
                filterStatus: [TaskStatus.TODO, TaskStatus.IN_PROGRESS],
                filterCreatorId: this.userId,
                filterAssigneeId: this.userId,
                filterDueDateUntil: formatISO(dueDateUntil),
            })
            this.assignedTasks = tasksResponse.assignedTasks.data
            this.numberOfUrgentAssignedTasks = tasksResponse.assignedTasks.paginatorInfo.total
            this.createdTasks = tasksResponse.createdTasks.data
            this.numberOfUrgentCreatedTasks = tasksResponse.createdTasks.paginatorInfo.total
            this.$emit('updateNumberOfUrgentTasks', this.numberOfUrgentAssignedTasks + this.numberOfUrgentCreatedTasks)
        },
        triggerCreateTask (targetObject) {
            EventBus.$emit('taskFormDialog:open', { targetObject })
        },
        setTargetObject (targetObject) {
            this.targetObject = targetObject
        },
        toKebabCase: function (text) {
            return kebabCase(text)
        },
        formatDate (...args) {
            return formatDate(...args)
        },
    },
}
</script>

<style lang="scss" scoped>
.task-info-list-item {
    &.custom-link {
        // Adjust padding so that – ideally – the default texts don’t break on two lines.
        padding-right: $sizeSpacingSm;
    }
}
</style>
