import { i18n } from '@/i18n'
import { ActivityLogParser } from '@/parsers/activityLog'

export class ContactRelationshipActivityLogParser extends ActivityLogParser {
    // TODO: Enhance after contactRelationships have been done.
    // static getLogDescriptionCreated (activityLog, data) {
    //     const interpolationParams = {
    //         someone: data.causer,
    //         // something: i18n.tc(`common.change-log.subject.some.contact-relationship${activityLog.subject.node1 ? '--to-someone' : ''}`, 1, { someone: 'TEMP' }),
    //         something: `<b>${i18n.tc('common.change-log.subject.some.contact-relationship', 1)}</b>`,
    //     }
    //     return i18n.t('common.change-log.someone-added-something', interpolationParams)
    // }

    static getLogDescriptionUpdated (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `${i18n.tc('common.change-log.someone-updated-something--field', activityLog.attributeKeys.length)} <b>${activityLog.attributeLabels}</b>`,
            ofSomething: `${i18n.tc('common.change-log.subject.of-some.contact-relationship', 1)}`,
        }
        return i18n.t('common.change-log.someone-updated-something-of-something', interpolationParams)
    }
}
