<template>
    <!-- TODO improvement @MTR: Refactor/tweak layout (hHh LpR lFr) so that the in-page footer does not overlap the app container/width. (And adjust the in-page footer styles accordingly.) -->
    <q-layout
        view="hHh LpR lFf"
        :class="{
            'screen-size-xxl': $q.screen.width >= ($q.screen.sizes.xl + 350),
            'content-scrolled': scrollInfo.position >= layoutHeaderScrollThreshold,
            'drawer-right-shown': $store.state.drawerRight || $q.screen.gt.md && !$store.state.drawerRightMiniState,
            'drawer-right-mini-mode': $store.state.drawerRightMiniState,
            'edit-mode-active': editMode
        }"
    >
        <q-resize-observer debounce="500" @resize="onResize" />
        <q-scroll-observer debounce="250" @scroll="onScroll" />

        <main-header />

        <q-drawer
            :value="drawerLeft"
            side="left"
            no-swipe-open
            show-if-above
            :width="280"
            content-class="bg-background-secondary"
            @hide="hideDrawerLeft"
        >
            <q-scroll-area
                class="fit"
                :thumb-style="{ width: '5px', right: '3px', borderRadius: '5px', backgroundColor: 'var(--color-border-tertiary)', opacity: 1 }"
                :bar-style="{ right: '1px', borderLeft: '1px solid var(--color-background-primary)', backgroundColor: 'var(--color-background-secondary)', width: '10px', opacity: 1 }"
            >
                <main-menu />

                <div class="version-info">
                    <span>
                        <router-link :to="{ name: 'release-notes' }">{{ $tc('common.app.name', 1) }} – {{ $tc('common.term.version', 1) }} <span class="version-number">{{ $store.state.release }}</span></router-link>
                        <q-tooltip
                            :delay="1000"
                            anchor="center right"
                            self="center left"
                            :offset="[10, 0]"
                            transition-show="scale"
                            transition-hide="scale"
                        >{{ $t('common.app.show-release-notes') }}</q-tooltip>
                    </span>
                </div>
            </q-scroll-area>
        </q-drawer>

        <q-page-container>
            <router-view />
        </q-page-container>

        <!-- TODO: Tweak width for mobile screens (especially really small ones where the width of the drawer is too big for the screen) -->
        <!-- TODO: Prevent the drawer from being visible by default on mobile screens. -->
        <q-drawer
            :value="drawerRight"
            side="right"
            :mini="drawerRightMiniState"
            :mini-to-overlay="$q.screen.lt.lg"
            :width="350"
            :mini-width="48"
            no-swipe-open
            @hide="hideDrawerRight"
        >
            <!-- TODO: Create drawerRight component -->
            <div class="drawer-right-nav">
                <q-tabs
                    v-model="drawerRightTab"
                    vertical
                    dense
                    no-caps
                    indicator-color="white"
                    :active-color="!drawerRightMiniState ? 'primary-darker' : ''"
                    :active-bg-color="!drawerRightMiniState ? 'white' : ''"
                >
                    <q-tab
                        v-can="'Feature:tasks:core'"
                        name="tasks"
                        icon="mib-checklist-alternate"
                        :label="$tc('common.task.task', 2)"
                        :ripple="false"
                        @click="tabToggleDrawerRight('tasks')"
                    >
                        <q-tooltip
                            :delay="1000"
                            transition-show="fade"
                            transition-hide="fade"
                            anchor="center left"
                            self="center right"
                        >
                            {{ $tc('common.task.task', 2) }}
                        </q-tooltip>
                        <!-- TODO @TFU: This number should update automatically (at least when changing the route). Currently it stays the same until the page is refreshed/reloaded. -->
                        <!-- TODO @TFU: We need the number of overdue tasks here, too. (To be able to adjust the hints/messages and visual appearance.) -->
                        <q-badge
                            v-if="numberOfUrgentTasks"
                            color="accent"
                            text-color="text-primary"
                            floating
                        >{{ numberOfUrgentTasks }}</q-badge>
                    </q-tab>

                    <q-tab
                        name="upload-manager"
                        :icon="(numberOfFailedHistoryItems) ? 'mib-cloud-warning' : 'mib-cloud-upload'"
                        :label="(numberOfFailedHistoryItems) ? `${$t('common.file.upload-manager.title')} (${numberOfFailedHistoryItems} ${$tc('common.term.error', numberOfFailedHistoryItems)})` : $t('common.file.upload-manager.title')"
                        :ripple="false"
                        @click="tabToggleDrawerRight('upload-manager')"
                    >
                        <q-tooltip
                            :delay="1000"
                            transition-show="fade"
                            transition-hide="fade"
                            anchor="center left"
                            self="center right"
                        >
                            {{ (numberOfFailedHistoryItems) ? `${$t('common.file.upload-manager.title')} (${numberOfFailedHistoryItems} ${$tc('common.term.error', numberOfFailedHistoryItems)})` : $t('common.file.upload-manager.title') }}
                        </q-tooltip>
                        <q-badge
                            v-if="numberOfQueuedFiles"
                            color="accent"
                            text-color="text-primary"
                            floating
                        >{{ numberOfQueuedFiles }}</q-badge>
                        <q-badge
                            v-if="numberOfFailedHistoryItems && !numberOfQueuedFiles"
                            color="negative"
                            text-color="text-inverted"
                            floating
                        >
                            {{ numberOfFailedHistoryItems }}
                        </q-badge>
                    </q-tab>
                </q-tabs>
            </div>

            <q-scroll-area
                v-show="!drawerRightMiniState"
                class="fit drawer-right-content-wrapper q-pr-xs"
                :thumb-style="{ width: '5px', right: '3px', borderRadius: '5px', backgroundColor: 'var(--color-border-tertiary)', opacity: 1 }"
                :bar-style="{ right: '1px', borderLeft: '1px solid var(--color-background-secondary)', backgroundColor: 'var(--color-background-primary)', width: '10px', opacity: 1 }"
                visible
            >
                <q-tab-panels
                    v-model="drawerRightTab"
                    keep-alive
                >
                    <q-tab-panel v-can="'Feature:tasks:core'" name="tasks">
                        <task-panel @updateNumberOfUrgentTasks="amount => { numberOfUrgentTasks = amount }" />
                    </q-tab-panel>

                    <q-tab-panel name="upload-manager">
                        <file-upload-manager @updateNumberOfQueuedFiles="amount => { numberOfQueuedFiles = amount }" @updateNumberOfFailedHistoryItems="amount => { numberOfFailedHistoryItems = amount }" />
                    </q-tab-panel>
                </q-tab-panels>
            </q-scroll-area>

            <consulting-file-upload-staging-dialog />
        </q-drawer>
        <task-form-dialog v-can="'Feature:tasks:core'" />
    </q-layout>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue'
import MainMenu from '@/components/MainMenu.vue'
import FileUploadManager from '@/components/form/FileUploadManager'
import ConsultingFileUploadStagingDialog from '@/components/consultingFile/ConsultingFileUploadStagingDialog'
import { EventBus } from '@/event-bus'
import TaskFormDialog from '@/components/task/TaskFormDialog.vue'
import TaskPanel from '@/components/task/TaskPanel.vue'

export default {
    components: {
        MainHeader,
        MainMenu,
        FileUploadManager,
        ConsultingFileUploadStagingDialog,
        TaskFormDialog,
        TaskPanel,
    },
    data () {
        return {
            drawerRightTab: 'upload-manager', // Initial value needs to be 'upload-manager' in order to pre-load the component and register the necessary events.
            scrollInfo: {},
            numberOfUrgentTasks: 0,
            numberOfQueuedFiles: 0,
            numberOfFailedHistoryItems: 0,
        }
    },
    computed: {
        drawerLeft () {
            return this.$store.state.drawerLeft
        },
        drawerRight () {
            if (this.$q.screen.lt.md) {
                return this.$store.state.drawerRight
            } else {
                return true
            }
        },
        drawerRightMiniState () {
            return (this.$q.screen.width >= (this.$q.screen.sizes.xl + (350 - 48))) ? false : this.$store.state.drawerRightMiniState
        },
        editMode () {
            return this.$store.state.editMode
        },
        layoutHeaderScrollThreshold () {
            return parseInt(getComputedStyle(document.documentElement).getPropertyValue('--size-layout-header-scroll-threshold'), 10)
        },
    },
    mounted () {
        if (this.$can('Feature:tasks:core')) this.drawerRightTab = 'tasks' // Change drawer right to the tab the user is supposed to see initially.
    },
    methods: {
        hideDrawerLeft () {
            this.$store.dispatch('hideDrawerLeft')
        },
        toggleDrawerRight () {
            if (this.$q.screen.lt.md) {
                this.$store.dispatch('toggleDrawerRight')
            } else {
                this.$store.dispatch('toggleDrawerRightMiniState')
            }
        },
        // Advanced toggleDrawerRight() depending on the clicked and currently active tab
        tabToggleDrawerRight (tab) {
            // Only trigger further actions when the drawerRight is not always shown (xxl screens)
            if (this.$q.screen.width < (this.$q.screen.sizes.xl + 350)) {
                // Show drawerRight when any of its tabs is clicked and the drawerRight is in mini state and not visible
                if (this.$store.state.drawerRightMiniState && !this.$store.state.drawerRight) {
                    this.showDrawerRight()
                } else {
                    // Hide drawerRight when the currently active tab is clicked again and the drawerRight is visible and not in mini state
                    if (this.drawerRightTab === tab) {
                        this.hideDrawerRight()
                    }
                }
            }
        },
        showDrawerRight () {
            if (this.$q.screen.lt.md) {
                this.$store.dispatch('showDrawerRight')
            } else {
                this.$store.dispatch('disableDrawerRightMiniState')
            }
        },
        hideDrawerRight () {
            if (this.$q.screen.lt.md) {
                this.$store.dispatch('hideDrawerRight')
            } else {
                this.$store.dispatch('enableDrawerRightMiniState')
            }
        },
        onScroll (info) {
            this.scrollInfo = info
        },
        onResize (size) {
            // Correct values for existing elements will be set initially and onResize (<q-resize-observer>)
            let mainPageHeaderHeight = '0px'
            let mainPageHeaderStickyTopPosition = '0px'
            let inPageFooterHeight = '0px'

            // document.querySelector() only selects the FIRST element that matches the criteria. (Currently, this is fine.)
            const mainPageHeader = document.querySelector('.page-wrapper > .page-header')
            const inPageFooter = document.querySelector('.in-page-footer')

            if (mainPageHeader) mainPageHeaderHeight = getComputedStyle(mainPageHeader).height
            if (mainPageHeader) mainPageHeaderStickyTopPosition = getComputedStyle(mainPageHeader).top
            if (inPageFooter) inPageFooterHeight = getComputedStyle(inPageFooter).height

            document.documentElement.style.setProperty('--size-main-page-header-height', mainPageHeaderHeight)
            document.documentElement.style.setProperty('--size-main-page-header-sticky-top-position', mainPageHeaderStickyTopPosition)
            document.documentElement.style.setProperty('--size-in-page-footer-height', inPageFooterHeight)
        },
        triggerCreateTask () {
            EventBus.$emit('taskFormDialog:open')
        },
    },
}
</script>

<style lang="scss" scoped>
// Drawer Left
// ----------------------------------------------------------------------------------------------------
::v-deep .q-drawer--left .q-drawer__content {
    container-type: inline-size; // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries
    container-name: drawer-left;

    // The transition is only added to prevent weird effects when the viewport is being resized
    // TODO: Check if this is still needed
    transition: box-shadow $defaultTransitionStyles;

    box-shadow: -1px 0 0 var(--color-border-secondary) inset;
}

// Version info
.version-info {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: $sizeSpacingSm;

    font-size: smaller;

    // Tweak styling when drawerLeft is in miniState
    .q-drawer--mini & {
        word-break: break-all;
        text-align: center;
    }

    a {
        color: var(--color-text-secondary);
        text-decoration: none;

        &:hover,
        &:active {
            color: var(--color-link-action);
            background-color: var(--color-link-action-background);
            cursor: pointer;
        }

        &:focus {
            color: var(--color-link-action);
        }
    }
}

.version-number {
    word-break: break-word;
}

// Drawer right
// ----------------------------------------------------------------------------------------------------
$sizeDrawerRightTabBorderWidth: 1px;

// Drawer itself
// TODO: Check what styles are still needed with the new layout
::v-deep .q-drawer--right {
    max-width: calc(100vw - #{$sizeAppDrawerRightMiniWidth});

    background-color: transparent;
}

// Drawer right navigation (tabs)
::v-deep .drawer-right-nav {
    position: absolute;
    left: 0;
    top: $sizeSpacingSm;
    width: $sizeAppDrawerRightMiniWidth;
    z-index: 100;

    @include mq($to: $sizeBreakpointMd) {
        left: (-($sizeAppDrawerRightMiniWidth - $sizeDrawerRightTabBorderWidth));
    }

    // Tabs
    .q-tab {
        padding: 0;
        width: $sizeAppDrawerRightMiniWidth;
        height: $sizeAppDrawerRightMiniWidth;
        margin-bottom: $sizeSpacingSm;

        border: $sizeDrawerRightTabBorderWidth solid var(--color-border-secondary);
        border-right: none;
        border-radius: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        background-color: var(--color-background-primary);

        box-shadow: $defaultBoxShadow;

        &.q-tab--inactive {
            opacity: 1;

            // Fake border-right and drop shadow for visual effect
            box-shadow: -1px 0 0px var(--color-border-secondary) inset, -2px 0 2px var(--color-shadow) inset, $defaultBoxShadow;
        }

        // Hide shadow/effects when in mini mode
        .drawer-right-mini-mode & {
            box-shadow: $defaultBoxShadow;
        }
    }

    // Visually hide labels
    .q-tab__label {
        @include visuallyhidden;
    }

    // Indicator
    .q-tab__indicator {
        width: $sizeDrawerRightTabBorderWidth;
    }

    // Badges
    .q-badge--floating {
        margin-right: 2px; // Fix positioning behind drawerRight (as the drawer overlaps the whole drawerRightNav).
    }
}

// Drawer right content
::v-deep .drawer-right-content-wrapper {
    container-type: inline-size; // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries
    container-name: drawer-right;

    background-color: var(--color-white);
    box-shadow: 1px 0 0 var(--color-border-secondary) inset;
}

@include mq($from: $sizeBreakpointMd) {
    // Drawer itself
    ::v-deep .q-drawer--right {
        padding-left: $sizeAppDrawerRightMiniWidth - $sizeDrawerRightTabBorderWidth;
    }
}

@include mq($from: $sizeAppMaxWidth + $sizeBrowserScrollbarWidth) {
    // Drawer itself
    ::v-deep .q-drawer--right {
        right: calc(100vw - #{$sizeAppMaxWidth} - #{$sizeBrowserScrollbarWidth});
    }
}

// Screen size XXL
@include mq($from: $sizeAppMaxWidth + ($sizeAppDrawerRightWidth - $sizeAppDrawerRightMiniWidth)) {
    // Adjust padding when drawer is moved "out of the content"
    .q-page-container {
        padding-right: $sizeAppDrawerRightMiniWidth !important; // !important needed to override inline styles
    }

    // Drawer itself
    ::v-deep .q-drawer--right {
        right: calc(100vw - #{$sizeAppMaxWidth} - #{$sizeAppDrawerRightWidth} + #{$sizeAppDrawerRightMiniWidth} - #{$sizeBrowserScrollbarWidth});
    }

    // Drawer right content
    ::v-deep .drawer-right-content-wrapper {
        box-shadow: 1px 0 0 var(--color-border-secondary) inset, -1px 0 0 var(--color-border-secondary) inset;
    }
}
</style>
