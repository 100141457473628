<template>
    <form
        :data-test="`form:${form.constructor.name}`"
        class="form-component form-item-form"
        @submit.prevent="formBuilder.onFormSubmit"
    >
        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="formBuilder.formErrorMessage"
            type="negative"
            data-test="error:global-message"
            v-html="formBuilder.formErrorMessage"
        />
        <!-- eslint-enable -->

        <div class="form-component-content">
            <slot :form-builder="formBuilder">
                <form-builder-next :item="formBuilder.form" :auto-layout="autoLayout" :manual-layout="manualLayout" />
            </slot>
        </div>

        <component
            v-if="!hideDefaultButtons"
            :is="inPageFooter ? 'in-page-footer' : 'div'"
            :class="{ 'form--buttons': !inPageFooter }"
        >
            <slot name="buttons" :form-builder="formBuilder">
                <base-button
                    data-test="btn:cancel"
                    :label="$t('common.term.cancel')"
                    primary-button
                    flat
                    @click="$emit('cancel')"
                />
                <base-button
                    data-test="btn:submit"
                    type="submit"
                    :label="submitButtonLabel"
                    :disable="formBuilder.formSubmitStatus"
                    :loading="formBuilder.formSubmitStatus"
                    primary-button
                />
            </slot>
        </component>
    </form>
</template>

<script>
import { reactive } from 'vue'
import { useFormBuilder } from '@/libs/form/composables/formBuilder'

export default {
    name: 'FormComponent',
    props: {
        form: {
            type: [Function, Object],
            required: true,
        },
        initialData: {
            type: Object,
            default: null,
        },

        autoLayout: {
            type: Boolean,
            default: false,
        },
        manualLayout: {
            type: Boolean,
            default: false,
        },

        submitButtonLabel: {
            type: String,
            default () {
                return this.$t('common.term.save')
            },
        },
        hideDefaultButtons: {
            type: Boolean,
            default: false,
        },
        inPageFooter: {
            type: Boolean,
            default: false,
        },
    },
    setup (props, context) {
        const form = (typeof props.form === 'function') ? new props.form() : props.form
        const formBuilder = reactive(useFormBuilder(context, form))
        if (props.initialData) formBuilder.form.setInitialData(props.initialData)
        context.emit('setup', formBuilder)

        return {
            formBuilder,
        }
    },
}
</script>
