import gql from 'graphql-tag'

export const EMAIL_ADDRESS_CATEGORIES_QUERY = gql`
    query {
        emailAddressCategories {
            id
            key
            name
        }
    }
`
