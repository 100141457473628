<template>
    <q-badge
        v-bind="$attrs"
        :class="`q-ml-${marginLeft} q-mr-${marginRight}`"
    >
        <slot name="default" />
    </q-badge>
</template>

<script>
export default {
    name: 'BaseBadge',
    props: {
        status: {
            type: String,
        },
        marginLeft: {
            type: String,
            default: 'xs', // Any of the default sizes: 'none', 'xs', 'sm', 'md', 'lg', 'xl'
        },
        marginRight: {
            type: String,
            default: 'none', // Any of the default sizes: 'none', 'xs', 'sm', 'md', 'lg', 'xl'
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
