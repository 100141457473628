import { apolloClient } from '@/apollo/client'

import { COMMISSION_LIST_IMPORT_EXCEL_MAPPING_QUERY } from '@/graphql/commissionListImportExcelMapping/commissionListImportExcelMapping'
import { COMMISSION_LIST_IMPORT_EXCEL_MAPPINGS_QUERY } from '@/graphql/commissionListImportExcelMapping/commissionListImportExcelMappings'
import { COMMISSION_LIST_IMPORT_EXCEL_MAPPING_CREATE_MUTATION } from '@/graphql/commissionListImportExcelMapping/commissionListImportExcelMappingCreate'
import { COMMISSION_LIST_IMPORT_EXCEL_MAPPING_UPDATE_MUTATION } from '@/graphql/commissionListImportExcelMapping/commissionListImportExcelMappingUpdate'
import { COMMISSION_LIST_IMPORT_EXCEL_MAPPING_DELETE_MUTATION } from '@/graphql/commissionListImportExcelMapping/commissionListImportExcelMappingDelete'

export const CommissionListImportExcelMappingService = {
    get (id) {
        return apolloClient.query({
            query: COMMISSION_LIST_IMPORT_EXCEL_MAPPING_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.commissionListImportExcelMapping)
    },
    all (variables) {
        return apolloClient.query({
            query: COMMISSION_LIST_IMPORT_EXCEL_MAPPINGS_QUERY,
            variables,
        }).then(response => response.data.commissionListImportExcelMappings)
    },
    create (productProviderId, commissionListImportExcelMappingInput) {
        return apolloClient.mutate({
            mutation: COMMISSION_LIST_IMPORT_EXCEL_MAPPING_CREATE_MUTATION,
            variables: {
                productProviderId,
                commissionListImportExcelMapping: commissionListImportExcelMappingInput,
            },
        }).then(response => response.data.commissionListImportExcelMappingCreate)
    },
    update (id, commissionListImportExcelMappingVariables) {
        return apolloClient.mutate({
            mutation: COMMISSION_LIST_IMPORT_EXCEL_MAPPING_UPDATE_MUTATION,
            variables: {
                id: id,
                commissionListImportExcelMapping: commissionListImportExcelMappingVariables,
            },
        }).then(response => response.data.commissionListImportExcelMappingUpdate)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: COMMISSION_LIST_IMPORT_EXCEL_MAPPING_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.commissionListImportExcelMappingDelete)
    },
}
