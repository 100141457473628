import { apolloClient } from '@/apollo/client'

import { ACTIVITY_LOGS_QUERY } from '@/graphql/activityLogs'

export const ActivityLogService = {
    all (variables) {
        return apolloClient.query({
            query: ACTIVITY_LOGS_QUERY,
            variables,
        }).then(response => response.data.activityLogs)
    },
}
