import gql from 'graphql-tag'

export const FORGOT_PASSWORD_MUTATION = gql`
    mutation forgotPassword($email: String!, $two_fa_code: String!) {
        forgotPassword(
            email: $email,
            two_fa_code: $two_fa_code
        ) {
            status
        }
    }
`
