import gql from 'graphql-tag'

export const CONSULTANTS_QUERY = gql`
    query consultants(
        $page: Int = 1
        $count: Int = 1000
        $orderField: ConsultantOrderField = CONTACT_NAME
        $orderDirection: OrderDirection = ASC
        $consultingSettingsStatus: [ConsultingSettingsStatus]
    ) {
        consultants(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                consulting_settings_status: $consultingSettingsStatus
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                contact_number
                first_name
                last_name
                gender
                title
                consultingCompany {
                    id
                }
                consulting_company_name
                consulting_settings_status
                created_at
                updated_at
            }
        }
    }
`
