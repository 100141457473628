<template>
    <q-list padding class="main-menu">
        <q-item :to="{ name: 'account-dashboard' }" exact>
            <q-item-section avatar>
                <q-icon name="mi-settings-user" />
            </q-item-section>
            <q-item-section>{{ $tc('common.term.user-account--my-user-account', 1) }}</q-item-section>
        </q-item>

        <q-expansion-item
            v-model="navGroupState.security"
            :to="{ name: 'account-security-password' }"
            :label="$t('common.term.security')"
            expand-icon-toggle
            icon="mi-password-lock-1"
            expand-icon="mib-arrow-left-1"
            expanded-icon="mib-arrow-down-1"
            :content-inset-level="1"
            class="subitem-points-to-same-route"
        >
            <q-list dense>
                <q-item :to="{ name: 'account-security-password' }" exact>
                    <q-item-section>{{ $t('views.account.change-password.title') }}</q-item-section>
                </q-item>
                <q-item :to="{ name: 'account-security-reset-2fa' }" exact>
                    <q-item-section>{{ $t('views.auth.reset-2fa.reset-2fa') }}</q-item-section>
                </q-item>
            </q-list>
        </q-expansion-item>

        <q-separator />

        <q-item :to="{ name: 'release-notes' }">
            <q-item-section avatar>
                <q-icon name="mi-file-code-information-1" />
            </q-item-section>
            <q-item-section>{{ $tc('common.app.release-note', 2) }}</q-item-section>
        </q-item>

        <q-separator />
    </q-list>
</template>

<script>
export default {
    name: 'MainMenuAccount',
    data () {
        return {
            navGroupState: {
                security: this.$route.meta.navGroup === 'security',
            },
        }
    },
    watch: {
        $route (to) {
            if (this.navGroupState[to.meta.navGroup] !== undefined) {
                this.navGroupState[to.meta.navGroup] = true
            }
        },
    },
}
</script>
