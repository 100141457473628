<template>
    <base-select-filter
        v-if="state.options.length"

        :value="value"
        v-bind="$attrs"
        :options="state.options"

        option-value="id"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { ContactService } from '@/services'
import { onBeforeMount, reactive, watch } from 'vue'

export default {
    name: 'ContactPhoneNumberLabelSelect',
    components: {
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        filter: {
            type: Object,
            default () {
                return {}
            },
        },
    },
    setup (props, context) {
        // Data
        const state = reactive({
            options: [],
        })

        // Methods
        async function fetchOptions () {
            const phoneNumberLabels = await ContactService.getPhoneNumberLabels(props.filter)
            state.options.length = 0
            state.options.push(...phoneNumberLabels)
            context.emit('fetchedOptions', state.options)
        }
        async function fetchInitialOptions () {
            await fetchOptions()
        }

        // Watchers
        watch(() => props.filter, () => {
            fetchOptions()
        })

        // Lifecycle hooks
        onBeforeMount(() => {
            fetchInitialOptions()
        })

        return {
            state,
        }
    },
}
</script>
