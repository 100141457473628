import { required, helpers } from 'vuelidate/lib/validators'
import { i18n } from '@/i18n'
import kebabCase from 'lodash.kebabcase'
import { walkJSON, isPlainObject, getValueFromProperty } from '@/helpers'
import { parseISO, isDate, isValid, parse, isEqual, isBefore, isAfter } from 'date-fns'
import { EditorContent } from '@/models/models'
import { FormBuilderConfiguration } from '@/form-builder-configuration'
import { initializeFormValidations } from '@/libs/form/helpers'

export function getFormItemByKey (formConfiguration, key) {
    let formItem = null

    const getFormItem = function (item) {
        if (item.key === key) {
            formItem = item
            return true
        }

        if (item.children && item.children.length) {
            item.children.some(child => {
                return getFormItem(child)
            })
        }

        return false
    }

    getFormItem(formConfiguration)
    return formItem
}

export function setupFormLocale (formConfiguration) {
    const localeProperties = ['label', 'equalToLabel', 'hint', 'infoTitle', 'infoText', 'errorMessage']
    let formLocale = JSON.parse(JSON.stringify(formConfiguration)) // TODO: if we want to be able to pass model instances as extra_attributes as for example in ConsultingFileBrowser.computed.consultingFileUpdateformBuilderSettings this would need to be reworked…

    walkJSON(formLocale, (value, key, node) => {
        if (localeProperties.includes(key)) {
            const args = []
            if (isPlainObject(value)) {
                args.push(value.key)
                args.push((value.count) ? value.count : 1)
                if (value.values) args.push(value.values)
            } else {
                args.push(value)
            }
            node[key] = i18n.tc(...args)
        }
    })

    return formLocale
}

export function setupFormData (formConfiguration, keys) {
    let formData = {}

    const buildFormData = function (item) {
        if (item.type === 'field') {
            if (keys && !keys.includes(item.key)) return false

            // Aliases
            if (item.inputType === 'date') item.inputType = 'calendar' // TODO: check if aliases is a thing; maybe just re-factor calendar to date?
            if (item.inputType === 'currency') item.inputType = 'text' // TODO: check if aliases is a thing; maybe create currency component?

            if (item.default_value) {
                formData[item.key] = item.default_value
            } else {
                switch (item.inputType) {
                    case 'editor':
                        formData[item.key] = EditorContent.create({
                            schemaType: item.extra_attributes.schemaType,
                        })
                        break
                    default:
                        formData[item.key] = getValueFromProperty(FormBuilderConfiguration.supportedInputTypes[item.inputType].initialValue)
                        break
                }
            }
        }

        if (item.children && item.children.length) {
            item.children.forEach(child => {
                buildFormData(child)
            })
        }
    }

    buildFormData(formConfiguration)
    return formData
}

export function setupFormValidations (formConfiguration, keys) {
    let formValidations = {}

    const buildFormValidations = function (item) {
        if (item.type === 'field' && item.validators && item.validators.length) {
            if (keys && !keys.includes(item.key)) return false
            formValidations[item.key] = initializeFormValidations(item.validators)
        }

        if (item.children && item.children.length) {
            item.children.forEach(child => {
                buildFormValidations(child)
            })
        }
    }

    buildFormValidations(formConfiguration)
    return formValidations
}

export function createFormBuilders (items, h, props) {
    const formBuilders = []
    items.forEach(item => {
        formBuilders.push(h('FormBuilder', {
            props: {
                item: item,
                modelPath: props.modelPath,
                model: props.model,
                $v: props.$v,
            },
            key: item.key,
        }))
    })
    return formBuilders
}



// TODO: Check if the naming of this function should be changed
// TODO: REFACTOR this whole thing
export function getFormattedFieldValue (
    value,
    {
        defaultTranslationBasePath = 'common.status',
    } = {
        defaultTranslationBasePath: 'common.status', // i18n translation base path (folder)
    }
) {
    let fieldValue = {
        value: '',
        cssClass: '',
    }

    // TODO: Add handling for dates
    switch (value) {
        case true:
            fieldValue.value = i18n.t('common.term.yes')
            break

        case false:
            fieldValue.value = i18n.t('common.term.no')
            break

        case '':
        case null:
        case undefined:
            fieldValue = {
                value: i18n.t('common.term.not-provided--alt'),
                cssClass: 'additional-info',
            }
            break

        default:
            // Check if a translation for the according key exists (i18n.te(…)); if so, we assume the value represents a status
            if (typeof value === 'string' && i18n.te(`${defaultTranslationBasePath}.${value.toLowerCase()}`)) {
                fieldValue = {
                    value: i18n.t(`${defaultTranslationBasePath}.${value.toLowerCase()}`),
                    cssClass: `status-${value.toLowerCase()}`,
                }
            } else {
                fieldValue.value = value
            }
    }

    return fieldValue
}

/**
 * Transform boolean values to localized 'Yes' or 'No'.
 * @param value
 * @returns {VueI18n.TranslateResult|*}
 */
export function getTrueFalseAsYesNo (value) {
    if (typeof value === 'boolean') {
        switch (value) {
            case true:
                return i18n.t('common.term.yes')

            case false:
                return i18n.t('common.term.no')
        }
    } else {
        return value
    }
}

/**
 * Get the translation ID of a given item.
 * @param {string} [translationBasePath=forms.field.label]
 * @param {string} [type=label] - The type of value that should be returned ('label' || 'value').
 * @param {string} context - Context (entity) in which the translation IDs should be searched. (E.g. 'contact', 'address', 'email-address' etc.)
 * @param {string} key - The key of the given item. Used to determine the translation ID.
 * @param {*} [value=] - The value of the item (if any; only needed when type='value').
 * @param {Boolean} trueFalseAsYesNo - Option to turn a boolean value to the translation ID of localized 'Yes' or 'No'.
 * @param {Boolean} formatDates - Option to return date values as properly formatted dates.
 * @returns {string|VueI18n.TranslateResult|*}
 */
export function getFormFieldTranslationId (
    {
        translationBasePath = 'forms.field.label',
        type = 'label',
        context = '',
        key = '',
        value = '',
        trueFalseAsYesNo = true,
        formatDates = true,
    } = {
        translationBasePath: 'forms.field.label',
        type: 'label',
        context: '',
        key: '',
        value: '',
        trueFalseAsYesNo: true,
        formatDates: true,
    }
) {
    const enums = {
        // TODO: Add check for and extend with (potentially) missing enums.
        'account': ['type', 'status'],
        'address': ['status'],
        'contact': ['civil-status', 'customer-status', 'gender', 'status'],
    }

    switch (type) {
        case 'value':
            // Handling of labels and values of defined enums.
            if (enums[context] && enums[context].includes(key)) {
                return `${translationBasePath}.${context}.${key}.${kebabCase(value)}`
            } else {
                // If value is a boolean and the according option is set to true, return true or false as localized 'Yes' or 'No'.
                if (trueFalseAsYesNo && typeof value === 'boolean') {
                    return getTrueFalseAsYesNo(value)
                }

                // TODO @TFU: Find solution for dates. (The stored string misses the 'T' between the date and the time and thus is not a valid ISO date time string –which results in a "RangeError: Invalid time value".)
                // See https://itxpert.atlassian.net/browse/MAX-748
                // If value is a valid date and the according option is set to true, return value as formatted date.
                if (formatDates && isDate(new Date(value))) {
                    // return formatDate(new Date(value))
                }

                // Arrays can't be translated, therefore we return an empty string so the translation id will be invalid.
                if (Array.isArray(value)) {
                    return ''
                }

                // If none of the above matches, return the value instead.
                return value
            }

        case 'label':
            // Handling of labels and values of defined enums.
            if (enums[context] && enums[context].includes(key)) {
                return `${translationBasePath}.${context}.${key}.${key}`
            } else {
                return `${translationBasePath}.${context}.${key}`
            }

        default:
            console.warn(`Unknown type '${type}' passed to getFormFieldTranslationId().`)
    }
}

// The following Regex ranges do NOT cover all accented uppercase/lowercase characters.
// For simplicity, it only covers Basic Latin and Latin 1 Supplement (https://unicode-table.com/en/#latin-1-supplement).
// This means that all characters starting from Ā (https://unicode-table.com/en/#0100) that are not specifically added
// to the lowercase or uppercase range are not taken into account for the validation.
export const minRequirementsLowercase = helpers.regex('minRequirementsLowercase', /[a-zß-öø-ýÿ]/)
export const minRequirementsUppercase = helpers.regex('minRequirementsUppercase', /[A-ZÀ-ÖØ-Ý]/)
export const minRequirementsSpecialCharOrNumber = helpers.regex('minRequirementsSpecialCharOrNumber', /[\d\W_]/)

export function isValidIsoDate (isoDateString) {
    return !helpers.req(isoDateString) || isValid(parseISO(isoDateString))
}

export function isValidDate (dateString) {
    return isValid(parse(dateString, 'dd.MM.yyyy', new Date()))
}

export function isValidDateInstance (date) {
    return date instanceof Date && !isNaN(date)
}

export function isValidMonthDay (value) {
    return !helpers.req(value) || (() => {
        const match = value.toString().match(/^--(\d{2})-(\d{2})$/)
        if (match.length === 3) {
            const day = parseInt(match.pop())
            const month = parseInt(match.pop())

            // Validate month
            if (month < 1 || month > 12) return false

            // Validate day
            const dayLowerBound = 1
            let dayUpperBound = 31
            if ([4, 6, 9, 11].includes(month)) {
                dayUpperBound = 30
            } else if (month === 2) {
                dayUpperBound = 29
            }
            if (day < dayLowerBound || day > dayUpperBound) return false

            return true
        }

        return false
    })()
}

export function isTrue (value) {
    return value === true
}

export function isMediaType (mediaTypes) {
    return (value) => !helpers.req(value) || mediaTypes.includes(value.type)
}

export function requiredProperty (propertyName) {
    return (value) => required(value[propertyName])
}

export function extractErrorMessage (error) {
    let errorMessages = '<ul>'

    if (error.graphQLErrors.length) {
        error.graphQLErrors.forEach(graphQLError => {
            if (graphQLError.message) {
                errorMessages += `<li>${graphQLError.message}`

                if (graphQLError.extensions && graphQLError.extensions.validation) {
                    errorMessages += '<ul>'
                    Object.keys(graphQLError.extensions.validation).forEach(validationKey => {
                        errorMessages += `<li>${graphQLError.extensions.validation[validationKey]}</li>`
                    })
                    errorMessages += '</ul>'
                }

                errorMessages += '</li>'
            }
        })
    } else if (error.message) {
        errorMessages += `<li>${error.message}</li>`
    }

    errorMessages += '</ul>'
    return errorMessages
}

/**
 * Check is an input value is not the equal as a given value.
 * @export
 * @param  {string} inputValue
 * @param  {string} value
 * @return {Boolean}
 */
export function notEqualTo (inputValue, value, type) {
    if (type === 'Number') {
        return parseFloat(inputValue) !== parseFloat(value)
    } else {
        return inputValue !== value
    }
}

/**
 * Validate if an input value date is before or equal to target input value date.
 * @export
 * @param  {string} target - Name of the target input.
 * @return {Boolean} Returns result of validation.
 */
export function isBeforeOrEqualToDate(target) {
    return helpers.withParams({ type: 'isBeforeOrEqualToDate', target }, (value, parentVm)  => {
        const targetValue = helpers.ref(target, this, parentVm)

        if (!targetValue) return true

        const date = new Date(value)
        const targetDate = new Date(targetValue)

        return !helpers.req(value) || (isEqual(date, targetDate) || isBefore(date, targetDate)) && !isAfter(date, targetDate)
    })
}

/**
 * Validate if an input value date is after or equal to target input value date.
 * @export
 * @param  {string} target - Name of the target input.
 * @return {Boolean} Returns result of validation.
 */
export function isAfterOrEqualToDate(target) {
    return helpers.withParams({ type: 'isAfterOrEqualToDate', target }, (value, parentVm) => {
        const targetValue = helpers.ref(target, this, parentVm)

        if (!targetValue) return true

        const date = new Date(value)
        const targetDate = new Date(targetValue)

        return !helpers.req(value) || (isEqual(date, targetDate) || isAfter(date, targetDate)) && !isBefore(date, targetDate)
    })
}

/**
 * Validate if an input value is less than target input value.
 * @export
 * @param  {string} target - Name of the target input.
 * @return {Boolean} Returns result of validation.
 */
export function lessThan(target) {
    return helpers.withParams({ type: 'lessThan', target }, (value, parentVm) => {
        const targetValue = helpers.ref(target, this, parentVm)
        if (!targetValue) return true

        return !helpers.req(value) || parseFloat(value) < parseFloat(targetValue)
    })
}

/**
 * Validate if an input value is more than target input value.
 * @export
 * @param  {string} target - Name of the target input.
 * @return {Boolean} Returns result of validation.
 */
export function moreThan(target) {
    return helpers.withParams({ type: 'moreThan', target }, (value, parentVm) => {
        const targetValue = helpers.ref(target, this, parentVm)
        if (!targetValue) return true

        return !helpers.req(value) || parseFloat(value) > parseFloat(targetValue)
    })
}
