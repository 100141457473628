<template>
    <div class="row q-col-gutter-y-sm">
        <div class="col-xs-12">
            <base-button flat :disabled="!consultingTree.length" @click="expandTree()">{{ $t('common.term.toggle--expand-all') }}</base-button>
            <base-button flat :disabled="!consultingTree.length" @click="collapseTree()">{{ $t('common.term.toggle--collapse-all') }}</base-button>
            <base-checkbox v-model="showInactive" :label="$tc('views.admin.consulting-settings.include-inactive-consulting-entities', 2)" :disabled="!consultingTree.length" />
        </div>
        <loading-indicator v-if="!consultingTree.length" auto-height class="q-pa-xl" />
        <div v-show="consultingTree.length" class="col-12">
            <q-tree
                ref="qTree"
                :nodes="consultingTree"
                node-key="id"
            >
                <template v-slot:default-header="prop">
                    <div class="row items-center">
                        <contact-name
                            :contact="prop.node"
                            type-icon
                            highlighted
                            comma-separated
                            :class="`consulting-settings-status-${prop.node.consultingSettings.status.toLowerCase()}`"
                        />
                        <status-badge v-if="prop.node.is_tenant_company" status="system-owner" />
                        <status-badge v-if="prop.node.consultingSettings.status !== ConsultingSettingsStatus.ACTIVE" :status="prop.node.consultingSettings.status" />
                        <base-button
                            v-if="!prop.node.is_tenant_company"
                            class="edit-settings-btn do-not-print"
                            color="primary"
                            size="xs"
                            flat
                            round
                            icon="mib-pencil"
                            :to="{ name: 'contact-settings-consulting-settings-update', params: { id: prop.node.id } }"
                        >
                            <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.admin.consulting-settings.edit-consulting-settings', 1, { contactName: prop.node.getContactName() }) }}</q-tooltip>
                        </base-button>
                    </div>
                </template>
            </q-tree>
        </div>
    </div>
</template>

<script>
import { ContactType, ConsultingSettingsStatus } from '@/enums/graphql'
import { Contact } from '@/models/contact'

import ContactName from '@/components/contact/ContactName.vue'
import StatusBadge from '@/components/StatusBadge.vue'
import { fetchPaginatedObjects } from '@/helpers'
import BaseCheckbox from '@/components/form/BaseCheckbox.vue'

export default {
    name: 'ConsultingSettingsTreeView',
    components: {
        BaseCheckbox,
        ContactName,
        StatusBadge,
    },
    props: {
        loading: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            ConsultingSettingsStatus,
            consultingTree: [],
            showInactive: false,
        }
    },
    computed: {
        consultingSettingsStatusFilter () {
            const status = [ConsultingSettingsStatus.ACTIVE]
            if (this.showInactive) status.push(ConsultingSettingsStatus.INACTIVE)
            return status
        },
    },
    watch: {
        showInactive () {
            this.fetchObjects()
        },
    },
    created () {
        this.fetchObjects()
    },
    methods: {
        async fetchObjects () {
            this.consultingTree = []

            const contacts = await fetchPaginatedObjects({ fn: Contact.objects.all }, {
                hasConsultingSettings: true,
                consultingSettingsStatus: this.consultingSettingsStatusFilter,
            }, `
                id
                status
                consultingSettings {
                    status
                    company {
                        id
                    }
                }
                ... on Person {
                    first_name
                    last_name
                }
                ... on Company {
                    company_name
                    is_tenant_company
                }
            `)

            const consultingTreePointer = {}

            // List items alphabetically
            contacts.sort(function (a, b) {
                let nameA = a.getContactName({ commaSeparated: true }).toUpperCase() // Ignore upper and lowercase
                let nameB = b.getContactName({ commaSeparated: true }).toUpperCase() // Ignore upper and lowercase
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                // If names should be equal
                return 0
            })

            // List companies before persons inside parent node
            contacts.sort((a, b) => {
                if (a.type === ContactType.COMPANY && b.type !== ContactType.COMPANY) {
                    return -1
                } else {
                    return 0
                }
            })

            // Transform the flat list into a tree structure
            contacts.forEach(item => {
                // Add every item to the top level of the structure
                if (!consultingTreePointer[item.id]) consultingTreePointer[item.id] = item
            })

            // Push item to the children array of its company, if it has a consulting company assigned (all but the tenant company).
            contacts.forEach(item => {
                if (item.consultingSettings.company) {
                    const pointer = consultingTreePointer[item.consultingSettings.company.id]
                    if (!pointer.children) pointer.children = []
                    pointer.children.push(item)
                }
            })

            // Remove all items on the top level except the tenant company
            contacts.forEach(item => {
                if (item.consultingSettings.company) delete consultingTreePointer[item.id]
            })

            this.consultingTree.push(...Object.values(consultingTreePointer))

            this.$refs.qTree.setExpanded(Object.keys(consultingTreePointer).at(0), true)
        },
        expandTree () {
            this.$refs.qTree.expandAll()
        },
        collapseTree () {
            this.$refs.qTree.collapseAll()
            this.$refs.qTree.setExpanded(this.consultingTree.at(0).id, true)
        },
    },
}
</script>

<style lang="scss" scoped>
.consulting-settings-status-inactive {
    color: var(--color-text-secondary);
}

.edit-settings-btn {
    margin: (-($sizeSpacingSm)) 0 (-($sizeSpacingSm)) $sizeSpacingXs;
}
</style>
