<template>
    <page-wrapper>
        <page-header
            :progress-bar="isImportingOrProcessing"
            progress-bar-indeterminate
        >
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.commission.commission-list.commission-list', 2)" :to="{ name: 'commission-lists' }" />
            </template>

            <h1>{{ $tc('views.commission.commission-list.commission-list', 2) }}</h1>
        </page-header>

        <base-table
            ref="commissionListListTable"
            :columns="columns"
            :fetch-objects-fn="CommissionList.service.all"
            :additional-filters="additionalFilters"
            user-settings-base-path="CommissionListList"
            pagination-sort-by-default-key="created_at"
            pagination-initial-order-direction-descending
            enable-visible-columns
            @row-dblclick="onDblClick"
        >
            <template v-slot:tabs>
                <q-tabs
                    dense
                    no-caps
                    inline-label
                    align="left"
                    class="q-mr-sm bg-background-secondary"
                    indicator-color="accent"
                >
                    <q-route-tab :to="{ name: 'commission-list-list', params: { status: 'pending' } }" :label="$t('common.status.commission-list-status.pending')" />
                    <q-route-tab :to="{ name: 'commission-list-list', params: { status: 'posted' } }" :label="$t('common.status.commission-list-status.posted')" />
                </q-tabs>
            </template>

            <template v-slot:body-cell-status="slotProps">
                <q-td :props="slotProps">
                    <status-badge
                        :status="slotProps.row.status"
                        default-translation-base-path="common.status.commission-list-status"
                        :loading="[CommissionListStatus.IMPORTING, CommissionListStatus.PROCESSING].includes(slotProps.row.status)"
                    />
                </q-td>
            </template>

            <template v-slot:body-cell-actions="slotProps">
                <q-td :props="slotProps" auto-width>
                    <q-btn
                        color="primary"
                        size="sm"
                        flat
                        round
                        icon="mib-view-1"
                        :to="{ name: 'commission-list-detail', params: { id: slotProps.row.id } }"
                    >
                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $t('common.term.show-details') }}</q-tooltip>
                    </q-btn>
                </q-td>
            </template>
        </base-table>

        <polling
            v-if="status === 'pending'"
            ref="polling"
            :poll-fn="CommissionList.service.all"
            :stop-condition-fn="stopPollingConditionFn"
            :poll-filters="pollFilters"
            :timeout-tm="3000"
            auto-start
            @condition-check="handlePollingConditionCheck"
        />

        <in-page-footer>
            <base-button
                :label="$tc('views.commission.commission-list.upload-commission-list', 1)"
                primary-button
                :to="{ name: 'commission-list-create' }"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { CommissionList } from '@/models/commissionList'
import { CommissionListStatus } from '@/enums/graphql'
import BaseTable from '@/components/BaseTable'
import { EventBus } from '@/event-bus'
import StatusBadge from '@/components/StatusBadge.vue'
import Polling from '@/components/Polling.vue'

export default {
    name: 'CommissionListList',
    meta () {
        return {
            title: this.$tc('views.commission.commission-list.commission-list', 2),
        }
    },
    components: {
        BaseTable,
        StatusBadge,
        Polling,
    },
    props: {
        status: {
            type: String,
            default: CommissionListStatus.PENDING,
        },
    },
    data () {
        const pending = Object.values(CommissionListStatus).filter(status => status !== CommissionListStatus.POSTED);
        const data = {
            filterStatus: {
                'posted': [CommissionListStatus.POSTED],
                'pending': [...pending],
            },
        }

        return {
            ...data,
            activeStatus: this.status,
            CommissionList,
            CommissionListStatus,
            additionalFilters: this.status
                ? {
                    filterStatus: data.filterStatus[this.status],
                }
                : null,
            columns: [
                {
                    name: 'created_at',
                    label: this.$t('common.term.added-on'),
                    field: row => row.formattedCreatedAt,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'product_provider_name',
                    label: this.$tc('common.product-provider.product-provider', 1),
                    field: row => (row.productProvider.display_name || row.productProvider.name),
                    align: 'left',
                    sortable: true,
                    required: true,
                    classes: 'product-provider-name-column truncate-text',
                },
                {
                    name: 'source',
                    label: this.$tc('views.commission.commission-list.source', 1),
                    field: row => this.$tc(`views.commission.commission-list.source--${kebabCase(row.source)}`, 1),
                    align: 'left',
                    sortable: true,
                    hideInitially: true,
                },
                {
                    name: 'type',
                    label: this.$tc('common.term.type', 1),
                    field: row => row.type,
                    align: 'left',
                    sortable: true,
                    hideInitially: true,
                },
                {
                    name: 'filename',
                    label: this.$tc('common.file.file-name', 1),
                    field: row => row.filename,
                    align: 'left',
                    sortable: true,
                    required: true,
                    classes: 'filename-column truncate-text',
                },
                {
                    name: 'exchange_date_from',
                    label: this.$tc('common.commission.exchange-date-from', 1),
                    field: row => row.formattedExchangeDateFrom || this.$t('common.term.not-provided--alt'),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'exchange_date_to',
                    label: this.$tc('common.commission.exchange-date-to', 1),
                    field: row => row.formattedExchangeDateTo || this.$t('common.term.not-provided--alt'),
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'status',
                    label: this.$tc('common.term.status', 1),
                    align: 'left',
                    sortable: true,
                    required: true,
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left', required: true },
            ],
            isImportingOrProcessing: false,
            pollFilters: {
                count: 1,
                filterStatus: [CommissionListStatus.IMPORTING, CommissionListStatus.PROCESSING],
            },
        }
    },
    watch: {
        $route () {
            this.additionalFilters = {
                filterStatus: this.filterStatus[this.status],
            }
        },
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        onNewEntity () {
            this.$router.push({ name: 'commission-list-create' })
        },
        onDblClick (evt, row) {
            this.$router.push({ name: 'commission-list-detail', params: { id: row.id } })
        },
        toKebabCase: function (text) {
            return kebabCase(text)
        },
        stopPollingConditionFn (response) {
            return !response.data.length
        },
        handlePollingConditionCheck (stopCondition) {
            if (this.isImportingOrProcessing && stopCondition) this.$refs.commissionListListTable.fetchObjects()
            this.isImportingOrProcessing = !stopCondition
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep {
    .truncate-text {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .product-provider-name-column {
        max-width: 20ch;
    }

    .filename-column {
        max-width: 40ch;
    }
}
</style>
