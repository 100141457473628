import { apolloClient } from '@/apollo/client'

import { AREA_OF_RESPONSIBILITY_QUERY } from '@/graphql/areasOfResponsibility/areaOfResponsibility'
import { AREAS_OF_RESPONSIBILITY_QUERY } from '@/graphql/areasOfResponsibility/areasOfResponsibility'
import { AREA_OF_RESPONSIBILITY_CREATE_MUTATION } from '@/graphql/areasOfResponsibility/areaOfResponsibilityCreate'
import { AREA_OF_RESPONSIBILITY_UPDATE_MUTATION } from '@/graphql/areasOfResponsibility/areaOfResponsibilityUpdate'
import { AREA_OF_RESPONSIBILITY_DELETE_MUTATION } from '@/graphql/areasOfResponsibility/areaOfResponsibilityDelete'

export const AreaOfResponsibilityService = {
    get (id) {
        return apolloClient.query({
            query: AREA_OF_RESPONSIBILITY_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.areaOfResponsibility)
    },
    all (variables) {
        return apolloClient.query({
            query: AREAS_OF_RESPONSIBILITY_QUERY,
            variables,
        }).then(response => response.data.areasOfResponsibility)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: AREA_OF_RESPONSIBILITY_CREATE_MUTATION,
            variables,
        }).then(response => response.data.areaOfResponsibilityCreate)
    },
    update (variables) {
        return apolloClient.mutate({
            mutation: AREA_OF_RESPONSIBILITY_UPDATE_MUTATION,
            variables,
        }).then(response => response.data.areaOfResponsibilityUpdate)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: AREA_OF_RESPONSIBILITY_DELETE_MUTATION,
            variables: {
                id,
            },
        }).then(response => response.data.areaOfResponsibilityDelete)
    },
}
