<template>
    <fake-select
        v-if="!consultants.length"
        v-bind="$attrs"
    />
    <base-select-filter
        v-else

        :value="value"
        v-bind="$attrs"
        :options="consultants"

        option-value="id"
        :option-label="getOptionLabel"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" /><!-- Named slots -->
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template><!-- Scoped slots -->
    </base-select-filter>
</template>

<script>
import { ConsultingSettingsStatus } from '@/enums/graphql'
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { Consultant } from '@/models/models'
import { ContactService } from '@/services'
import FakeSelect from '@/components/FakeSelect.vue'

export default {
    name: 'ConsultantSelect',
    components: {
        FakeSelect,
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        injectConsultants: {
            type: Array,
        },
        includeInactive: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            consultants: [],
        }
    },
    created () {
        const statusFilter = [ConsultingSettingsStatus.ACTIVE]
        if (this.includeInactive) statusFilter.push(ConsultingSettingsStatus.INACTIVE)

        ContactService.consultants.all({
            consultingSettingsStatus: statusFilter,
        }).then(response => {
            let consultants = response.data

            if (this.injectConsultants) {
                this.injectConsultants.forEach(injectConsultant => {
                    const index = consultants.findIndex(consultant => consultant.id === injectConsultant.id)
                    if (index === -1) consultants.push(injectConsultant)
                })
            }

            consultants = consultants.map(consultant => {
                // TODO: Check if this workaround is still needed with form-builder-next.
                if (!consultant.getContactName) { // Workaround because we can't pass model instances through the FormBuilder right now… See @/helpers/form/setupFormLocale for more information.
                    consultant = new Consultant(consultant)
                }
                consultant.label = consultant.getContactName()

                return consultant
            })

            consultants.sort((a, b) => {
                return a.label.localeCompare(b.label)
            })

            this.consultants.push(...consultants)
            this.$emit('fetchedObjects', this.consultants)
        })
    },
    methods: {
        getOptionLabel (item) {
            return `${item.getContactName()} <span class="additional-info">${item.getContactName({ noFirstName: true, noLastName: true, contactNumber: true, consultingCompanyName: !item.worksForUserConsultingCompany })}</span>`
        },
    },
}
</script>
