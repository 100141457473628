<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('views.dev.design.design')" :to="{ name: 'dev-design' }" />
                <q-breadcrumbs-el :label="$t('views.dev.design.illustrations.title')" :to="{ name: 'dev-design-illustrations' }" />
            </template>

            <h1>{{ $t('views.dev.design.illustrations.title') }}</h1>

            <template v-slot:afterContent>
                <div class="row">
                    <div class="col-xs-12">
                        <base-checkbox v-model="faded" label="faded" />
                        <base-checkbox v-model="interactive" label="interactive" />
                        <base-checkbox
                            v-model="selected"
                            label="selected"
                            :disable="!faded && !interactive"
                            :disabled="!faded && !interactive"
                            info-text="`selected` resets the colors and interactive item to the default values – only has a visual effect in conjunction with `faded` and/or `interactive`."
                            :info-icon-color="faded || interactive ? 'accent' : 'secondary-light'"
                        />
                        <base-checkbox v-model="error" label="error" />
                    </div>
                </div>
            </template>
        </page-header>

        <!-- TODO improvement @MTR: Add table/list view with illustration info. -->
        <!-- TODO improvement @MTR: Add filter functionality to filter/search for illustration/file name -->
        <div class="row q-mb-xl">
            <div class="col-xs-12">
                <div class="illustration-grid">
                    <q-card
                        v-for="illustration in maxIllustrations"
                        :key="illustration.name"
                        class="illustration-grid-item q-pa-none"
                        square
                        flat
                    >
                        <q-card-section class="q-pa-none text-center">
                            <q-btn
                                unelevated
                                class="illustration-context-menu-trigger"
                            >
                                <illustration
                                    :name="illustration.name"
                                    :faded="faded"
                                    :interactive="interactive"
                                    :selected="selected"
                                    :error="error"
                                />
                                <dev-illustration-context-menu
                                    :illustration="illustration.name"
                                    :faded="faded"
                                    :interactive="interactive"
                                    :selected="selected"
                                    :error="error"
                                />
                            </q-btn>
                        </q-card-section>
                    </q-card>
                </div>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import BaseCheckbox from '@/components/form/BaseCheckbox.vue'
import DevIllustrationContextMenu from '@/components/dev/DevIllustrationContextMenu.vue'

export default {
    name: 'DevDesignIllustrations',
    meta () {
        return {
            title: this.$t('views.dev.design.illustrations.title'),
        }
    },
    components: {
        BaseCheckbox,
        DevIllustrationContextMenu,
    },
    data () {
        return {
            faded: false,
            interactive: false,
            selected: false,
            error: false,
            maxIllustrations: [
                { name: 'bike-fail' },
                { name: 'cloud-upload-in-progress' },
                { name: 'cloud-upload' },
                { name: 'code-magnifier' },
                { name: 'desk-panic' },
                { name: 'file-download-laptop' },
                { name: 'laptop-bug' },
                { name: 'laptop-information' },
                { name: 'lock-safe-circle' },
                { name: 'mail-letter-content' },
                { name: 'mail-reply' },
                { name: 'maintenance' },
                { name: 'max-cloud-add' },
                { name: 'max-cloud-approve' },
                { name: 'max-cloud-check' },
                { name: 'max-cloud-configure' },
                { name: 'max-cloud-deny' },
                { name: 'max-cloud-edit' },
                { name: 'max-cloud-repeat' },
                { name: 'max-cloud-search' },
                { name: 'max-cloud-subtract' },
                { name: 'max-cloud-sync' },
                { name: 'max-cloud-transfer' },
                { name: 'max-envelope-add' },
                { name: 'max-envelope-approve' },
                { name: 'max-envelope-check' },
                { name: 'max-envelope-configure' },
                { name: 'max-envelope-deny' },
                { name: 'max-envelope-edit' },
                { name: 'max-envelope-repeat' },
                { name: 'max-envelope-search' },
                { name: 'max-envelope-subtract' },
                { name: 'max-envelope-sync' },
                { name: 'max-envelope-transfer' },
                { name: 'max-file-add--csv' },
                { name: 'max-file-add--excel' },
                { name: 'max-file-add--json' },
                { name: 'max-file-add--pdf' },
                { name: 'max-file-add--xml' },
                { name: 'max-file-add--zip' },
                { name: 'max-file-add' },
                { name: 'max-file-approve--csv' },
                { name: 'max-file-approve--excel' },
                { name: 'max-file-approve--json' },
                { name: 'max-file-approve--pdf' },
                { name: 'max-file-approve--xml' },
                { name: 'max-file-approve--zip' },
                { name: 'max-file-approve' },
                { name: 'max-file-check--csv' },
                { name: 'max-file-check--excel' },
                { name: 'max-file-check--json' },
                { name: 'max-file-check--pdf' },
                { name: 'max-file-check--xml' },
                { name: 'max-file-check--zip' },
                { name: 'max-file-check' },
                { name: 'max-file-configure--csv' },
                { name: 'max-file-configure--excel' },
                { name: 'max-file-configure--json' },
                { name: 'max-file-configure--pdf' },
                { name: 'max-file-configure--xml' },
                { name: 'max-file-configure--zip' },
                { name: 'max-file-configure' },
                { name: 'max-file-content-add' },
                { name: 'max-file-content-approve' },
                { name: 'max-file-content-check' },
                { name: 'max-file-content-configure' },
                { name: 'max-file-content-deny' },
                { name: 'max-file-content-edit' },
                { name: 'max-file-content-image-add' },
                { name: 'max-file-content-image-approve' },
                { name: 'max-file-content-image-check' },
                { name: 'max-file-content-image-configure' },
                { name: 'max-file-content-image-deny' },
                { name: 'max-file-content-image-edit' },
                { name: 'max-file-content-image-repeat' },
                { name: 'max-file-content-image-search' },
                { name: 'max-file-content-image-subtract' },
                { name: 'max-file-content-image-sync' },
                { name: 'max-file-content-image-transfer' },
                { name: 'max-file-content-repeat' },
                { name: 'max-file-content-search' },
                { name: 'max-file-content-signature--deny' },
                { name: 'max-file-content-signature-add' },
                { name: 'max-file-content-signature-approve' },
                { name: 'max-file-content-signature-check' },
                { name: 'max-file-content-signature-configure' },
                { name: 'max-file-content-signature-edit' },
                { name: 'max-file-content-signature-repeat' },
                { name: 'max-file-content-signature-search' },
                { name: 'max-file-content-signature-subtract' },
                { name: 'max-file-content-signature-sync' },
                { name: 'max-file-content-signature-transfer' },
                { name: 'max-file-content-subtract' },
                { name: 'max-file-content-sync' },
                { name: 'max-file-content-transfer' },
                { name: 'max-file-deny--csv' },
                { name: 'max-file-deny--excel' },
                { name: 'max-file-deny--json' },
                { name: 'max-file-deny--pdf' },
                { name: 'max-file-deny--xml' },
                { name: 'max-file-deny--zip' },
                { name: 'max-file-deny' },
                { name: 'max-file-edit--csv' },
                { name: 'max-file-edit--excel' },
                { name: 'max-file-edit--json' },
                { name: 'max-file-edit--pdf' },
                { name: 'max-file-edit--xml' },
                { name: 'max-file-edit--zip' },
                { name: 'max-file-edit' },
                { name: 'max-file-repeat--csv' },
                { name: 'max-file-repeat--excel' },
                { name: 'max-file-repeat--json' },
                { name: 'max-file-repeat--pdf' },
                { name: 'max-file-repeat--xml' },
                { name: 'max-file-repeat--zip' },
                { name: 'max-file-repeat' },
                { name: 'max-file-search--csv' },
                { name: 'max-file-search--excel' },
                { name: 'max-file-search--json' },
                { name: 'max-file-search--pdf' },
                { name: 'max-file-search--xml' },
                { name: 'max-file-search--zip' },
                { name: 'max-file-search' },
                { name: 'max-file-subtract--csv' },
                { name: 'max-file-subtract--excel' },
                { name: 'max-file-subtract--json' },
                { name: 'max-file-subtract--pdf' },
                { name: 'max-file-subtract--xml' },
                { name: 'max-file-subtract--zip' },
                { name: 'max-file-subtract' },
                { name: 'max-file-sync--csv' },
                { name: 'max-file-sync--excel' },
                { name: 'max-file-sync--json' },
                { name: 'max-file-sync--pdf' },
                { name: 'max-file-sync--xml' },
                { name: 'max-file-sync--zip' },
                { name: 'max-file-sync' },
                { name: 'max-file-transfer--csv' },
                { name: 'max-file-transfer--excel' },
                { name: 'max-file-transfer--json' },
                { name: 'max-file-transfer--pdf' },
                { name: 'max-file-transfer--xml' },
                { name: 'max-file-transfer--zip' },
                { name: 'max-file-transfer' },
                { name: 'max-office-building-add' },
                { name: 'max-office-building-approve' },
                { name: 'max-office-building-check' },
                { name: 'max-office-building-configure' },
                { name: 'max-office-building-deny' },
                { name: 'max-office-building-edit' },
                { name: 'max-office-building-repeat' },
                { name: 'max-office-building-search' },
                { name: 'max-office-building-subtract' },
                { name: 'max-office-building-sync' },
                { name: 'max-office-building-transfer' },
                { name: 'max-person-add' },
                { name: 'max-person-approve' },
                { name: 'max-person-check' },
                { name: 'max-person-configure' },
                { name: 'max-person-deny' },
                { name: 'max-person-edit' },
                { name: 'max-person-repeat' },
                { name: 'max-person-search' },
                { name: 'max-person-subtract' },
                { name: 'max-person-sync' },
                { name: 'max-person-transfer' },
                { name: 'not-found-folder-file' },
                { name: 'monitor-access-denied' },
                { name: 'password-lock-circle' },
                { name: 'password-lock' },
                { name: 'password' },
                { name: 'support-rescue' },
            ],
        }
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
.illustration-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.illustration-grid-item {
    flex: 1 1;
    border: 1px solid var(--color-border-primary);
    margin: $sizeSpacingSm;
}

.illustration-context-menu-trigger {
    width: 100%;
    border-radius: 0;
}
</style>
