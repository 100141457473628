<template>
    <div class="illustration-wrapper" :class="{ 'faded': faded, 'interactive': interactive, 'is-selected': selected, 'has-error': error }">
        <inline-svg :src="illustration" :style="`width: ${width}; min-width: ${minWidth}; max-width: ${maxWidth}`" />
    </div>
</template>

<script>
export default {
    name: 'Illustration',
    props: {
        name: {
            type: String,
            default: 'laptop-information',
        },
        width: {
            type: String,
            default: '22vh',
        },
        minWidth: {
            type: String,
            default: '10rem',
        },
        maxWidth: {
            type: String,
            default: '14rem',
        },
        faded: {
            type: Boolean,
            default: false,
        },
        interactive: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {}
    },
    computed: {
        illustration () {
            return require('@/assets/img/illustrations/' + this.name + '.svg')
        },
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
.illustration-wrapper {
    width: 100%;
    text-align: center;

    ::v-deep {
        .icon,
        .type,
        .inner-icon {
            transition: all $defaultTransitionStyles;
        }
    }
}

// faded: Illustration colors are set to faded defaults and (probably) change later depending on state
.faded {
    --color-illustration-accent: var(--color-illustration-primary);
    --color-illustration-accent-light: var(--color-illustration-primary-light);
    --color-illustration-accent-lighter: var(--color-illustration-primary-lighter);
}

// interactive: Interactive elements are animated
.interactive {
    ::v-deep {
        // TODO improvement @MTR: Check if individual animations per icon (e.g. `person`, `cloud`, `file`, …) would make sense
        .icon,
        .type {
            transform: translateX(7%);
        }

        // TODO improvement @MTR: Add individual animations per inner icon (e.g. `add`, `approve`, `check`, …)
        .inner-icon {
            opacity: 0;
            transform: translateX(17%) scale(1.1);
        }
    }
}

// TODO improvement @MTR: Add `animated` styles where illustrations have an initial animation
.animated {
}

// selected: Resets the colors and interactive item to the default values – only has a visual effect in conjunction with `faded` and/or `interactive`
.is-selected {
    --color-illustration-accent: var(--color-illustration-accent);
    --color-illustration-accent-light: var(--color-illustration-accent-light);
    --color-illustration-accent-lighter: var(--color-illustration-accent-lighter);

    ::v-deep {
        .icon,
        .type {
            transform: translateX(0%);
        }

        .inner-icon {
            opacity: 1;
            transform: translateX(0%) scale(1);
        }
    }
}

// error: Change colors
.has-error {
    --color-illustration-accent: var(--q-color-negative);
    --color-illustration-accent-light: var(--q-color-negative-light);
    --color-illustration-accent-lighter: var(--q-color-negative-lighter);
}
</style>
