import { Form, FormField, FormRepeaterItem } from '@/libs/form'
import { computed, watch } from 'vue'

export class AddressForm extends Form {
    compose () {
        this.addField(new FormField('category', {
            label: 'common.term.category',
            inputType: 'addressCategory',
            validators: [
                { type: 'required' },
            ],
        }))

        this.addField(new FormField('label', {
            label: 'forms.field.label.address.label',
            inputType: 'addressLabel',
            props: {
                filter: computed(() => {
                    const filter = {}
                    if (this.getItem('category').value !== null) filter.filterCategoryIds = [this.getItem('category').value]
                    return filter
                }),
            },
            attributes: {
                infoText: 'common.address.label--description',
                disable: computed(() => {
                    return this.getItem('category').value === null
                }),
            },
        }))
        watch(() => this.getItem('category').value, (newValue, oldValue) => {
            if (oldValue !== null) this.getItem('label').resetValue()
        })

        this.addField(new FormField('poBox', {
            label: 'common.address.po-box',
            inputType: 'checkbox',
        }))

        this.addField(new FormField('address1', {
            label: 'common.address.address-1',
            inputType: 'text',
        }))

        this.addField(new FormField('address2', {
            label: 'common.address.address-2',
            inputType: 'text',
        }))

        this.addField(new FormField('address3', {
            label: 'common.address.address-3',
            inputType: 'text',
        }))

        this.addField(new FormField('zip', {
            label: 'common.address.zip',
            inputType: 'text',
            validators: [
                { type: 'required' },
            ],
        }))

        this.addField(new FormField('city', {
            label: 'common.address.city',
            inputType: 'text',
            validators: [
                { type: 'required' },
            ],
        }))

        this.addField(new FormField('country', {
            label: 'common.address.country',
            inputType: 'country',
            validators: [
                { type: 'required' },
            ],
        }))

        this.addField(new FormField('validFrom', {
            label: 'common.term.valid-from',
            inputType: 'calendar',
            validators: [
                { type: 'required' },
                { type: 'date' },
            ],
        }))

        this.addField(new FormField('validUntil', {
            label: 'common.term.valid-until',
            inputType: 'calendar',
            validators: [
                { type: 'date' },
            ],
        }))

        this.addField(new FormField('isMain', {
            label: 'common.contact.main-correspondence-address',
            inputType: 'checkbox',
            attributes: {
                disable: false,
            },
        }))
    }
}

export class AddressRepeaterItem extends FormRepeaterItem {
    compose () {
        const form = new AddressForm({ excludeItems: ['isMain'] })
        this.addItems(form.items)
    }
}
