import { Node as Node$1 } from 'prosemirror-model';
import { Node, mergeAttributes, getSchema } from '@tiptap/core';
import { get } from 'micromustache';
import { de, enUS } from 'date-fns/locale';
import { parseISO, format } from 'date-fns';
import Text from '@tiptap/extension-text';
import Paragraph from '@tiptap/extension-paragraph';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import HardBreak from '@tiptap/extension-hard-break';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Heading from '@tiptap/extension-heading';

const EditorContentSchemaType = Object.freeze({
    'LETTER': 'LETTER',
    'EMAIL': 'EMAIL',
    'SALUTATION': 'SALUTATION',
    'SIMPLE_RICH_TEXT': 'SIMPLE-RICH-TEXT',
});

const SmartObject = Node.create({
    name: 'smartObject',
    group: 'inline',
    inline: true,
    selectable: true,
    atom: true,
    draggable: true,

    addAttributes () {
        return {
            id: {
                default: null,
                parseHTML: element => ({ id: element.getAttribute('data-id') }),
                renderHTML: attributes => ((typeof attributes.id !== 'undefined') ? { 'data-id': attributes.id } : {}),
            },
        }
    },

    parseHTML () {
        return [{ tag: `[data-smart-object="${this.name.split('_').pop()}"]` }]
    },

    renderHTML ({ node, HTMLAttributes }) {
        return ['span', mergeAttributes({ 'data-smart-object': node.type.name.split('_').pop() }, HTMLAttributes), `[%= ${node.attrs.id} %]`]
    },

    renderText({ node }) {
        return `[%= ${node.attrs.id} %]`
    },
});

const SmartObjectSimplePlaceholder = SmartObject.extend({
    name: 'smartObject_simplePlaceholder',
});

const SmartObjectConditionalSpace = SmartObject.extend({
    name: 'smartObject_conditionalSpace',

    addAttributes() {
        return {
            ...this.parent?.(),
            relatedId: {
                default: "",
                parseHTML: element => ({ relatedId: element.getAttribute('data-related-id') }),
                renderHTML: attributes => ((attributes.relatedId) ? { 'data-related-id': attributes.relatedId } : {}),
            },
        }
    },

    renderHTML ({ node, HTMLAttributes }) {
        return ['span', mergeAttributes({ 'data-smart-object': node.type.name.split('_').pop() }, HTMLAttributes), `[%= ${node.attrs.relatedId}__conditional_space %]`]
    },

    renderText({ node }) {
        return `[%= ${node.attrs.relatedId}__conditional_space %]`
    },
});

const renderCurrencyString = function (node, contextData, locale) {
    const value = get(contextData, node.attrs.id);
    return (value) ? new Intl.NumberFormat(locale, { style: 'currency', currency: 'CHF' }).format(value) : ''
};

const SmartObjectCurrency = SmartObject.extend({
    name: 'smartObject_currency',

    renderHTML ({ node, HTMLAttributes }) {
        return ['span', mergeAttributes({ 'data-smart-object': node.type.name.split('_').pop() }, HTMLAttributes), `${renderCurrencyString(node, this.options.contextData, this.options.language)}`]
    },

    renderText({ node, language, contextData }) {
        return renderCurrencyString(node, contextData, language)
    },
});

function getDateFnsLocale (locale) {
    const locales = { de, enUS };
    switch (locale) {
        case 'de-CH':
            return locales['de']
        case 'en':
            return locales['enUS']
    }
}

const renderDateString = function (node, contextData, locale) {
    const formatArguments = [node.attrs.format];
    if (locale) formatArguments.push({ locale: getDateFnsLocale(locale) });

    let date;
    if (node.attrs.date) {
        date = parseISO(node.attrs.date);
    } else if (node.attrs.id === 'general.current_date') {
        date = Date.now();
    } else {
        const dateString = get(contextData, node.attrs.id);
        if (dateString) date = parseISO(dateString);
    }

    return (date) ? format(date, ...formatArguments) : ''
};

const SmartObjectDate = SmartObject.extend({
    name: 'smartObject_date',

    addAttributes() {
        return {
            ...this.parent?.(),
            date: {
                default: "",
                parseHTML: element => ({ date: element.getAttribute('datetime') }),
                renderHTML: attributes => ((attributes.date) ? { 'datetime': attributes.date } : {}),
            },
            format: {
                default: "d. MMMM yyyy",
                parseHTML: element => ({ format: element.getAttribute('data-format') }),
                renderHTML: attributes => ((attributes.format) ? { 'data-format': attributes.format } : {}),
            },
        }
    },

    renderHTML ({ node, HTMLAttributes }) {
        return ['time', mergeAttributes({ 'data-smart-object': node.type.name.split('_').pop() }, HTMLAttributes), `${renderDateString(node, this.options.contextData, this.options.language)}`]
    },

    renderText({ node, language, contextData }) {
        return renderDateString(node, contextData, language)
    },
});

function generateText (schema, json, options) {
    const contentNode = Node$1.fromJSON(schema, json);

    let textContent = '';
    contentNode.descendants(node => {
        if (node.isText) {
            textContent += node.text;
        } else {
            const renderTextArguments = { node };
            switch (node.type.name) {
                case SmartObjectSimplePlaceholder.name:
                    textContent += SmartObjectSimplePlaceholder.parent.config.renderText(renderTextArguments);
                    break
                case SmartObjectConditionalSpace.name:
                    textContent += SmartObjectConditionalSpace.config.renderText(renderTextArguments);
                    break
                case SmartObjectCurrency.name:
                    if (options?.contextData) renderTextArguments.contextData = options.contextData;
                    if (options?.language) renderTextArguments.language = options.language;
                    textContent += SmartObjectCurrency.config.renderText(renderTextArguments);
                    break
                case SmartObjectDate.name:
                    if (options?.contextData) renderTextArguments.contextData = options.contextData;
                    if (options?.language) renderTextArguments.language = options.language;
                    textContent += SmartObjectDate.config.renderText(renderTextArguments);
                    break
            }
        }
    });

    return textContent
}

const StructureBlockSubject = Node.create({
    name: 'structureBlock_subject',
    content: 'inline*',
    isolating: true,

    renderHTML ({ node, HTMLAttributes }) {
        return ['h1', mergeAttributes({ 'data-structure-block': node.type.name.split('_').pop() }, HTMLAttributes), 0]
    },
});

const StructureBlockContent = Node.create({
    name: 'structureBlock_content',
    content: 'block+',
    isolating: true,

    renderHTML ({ node, HTMLAttributes }) {
        return ['div', mergeAttributes({ 'data-structure-block': node.type.name.split('_').pop() }, HTMLAttributes), 0]
    },
});

const EmailExtensions = [
    StructureBlockSubject,
    StructureBlockContent,

    Text,
    Paragraph,
    BulletList,
    OrderedList,
    ListItem,
    HardBreak,

    Bold,
    Italic,
    Strike,

    SmartObjectSimplePlaceholder,
    SmartObjectCurrency,
    SmartObjectDate,
    SmartObjectConditionalSpace,
];

const Email = Node.create({
    name: 'email',
    topNode: true,
    content: 'structureBlock_subject structureBlock_content',

    addExtensions() {
        return [
            // Filter out extensions …
            ...EmailExtensions.filter(extension => {
                return ![
                    SmartObjectCurrency.name,
                    SmartObjectDate.name,
                ].includes(extension.name)
            }),

            // … and include their configured counterparts.
            SmartObjectCurrency.configure({
                contextData: this.options.contextData,
                language: this.options.language,
            }),

            SmartObjectDate.configure({
                contextData: this.options.contextData,
                language: this.options.language,
            }),
        ]
    },
});

const EmailSchema = getSchema([Email, ...EmailExtensions]);

const StructureBlockAddress = Node.create({
    name: 'structureBlock_address',
    selectable: false,
    atom: true,
    isolating: true,

    renderHTML ({ node, HTMLAttributes }) {
        const translations = {
            'de-CH': {
                poBox: 'Postfach',
                confidentialityNotice: {
                    PERSONAL: 'Persönlich',
                    CONFIDENTIAL: 'Vertraulich',
                },
            },
            'en': {
                poBox: 'P.O. Box',
                confidentialityNotice: {
                    PERSONAL: 'Personal',
                    CONFIDENTIAL: 'Confidential',
                },
            },
        };
        let contentString = '';

        if (this.options.contextData) {
            const recipient = this.options.contextData.recipient;

            if (recipient) {
                let fullAddress = [];

                if (this.options.contextData.confidentialityNotice) fullAddress.push(translations[this.options.language].confidentialityNotice[this.options.contextData.confidentialityNotice]);

                if (recipient.first_name && recipient.last_name) {
                    fullAddress.push(`${recipient.first_name} ${recipient.last_name}`);
                } else if (recipient.company_name) {
                    fullAddress.push(recipient.company_name);
                    if (this.options.contextData.attentionOf) fullAddress.push(this.options.contextData.attentionOf);
                }

                const address = recipient.address;
                if (address) {
                    if (address.address1) fullAddress.push(address.address1);
                    if (address.address2) fullAddress.push(address.address2);
                    if (address.address3) fullAddress.push(address.address3);
                    if (address.po_box) fullAddress.push(translations[this.options.language].poBox);
                    if (address.zip && address.city) fullAddress.push(`${address.zip} ${address.city}`);
                    if (address.country?.id !== 'CH') fullAddress.push(address.country.name.toUpperCase()); // Only show the country in an address if the address is in another country.
                }

                contentString = fullAddress.join('\n');
            }
        }

        return ['div', mergeAttributes({ 'data-structure-block': node.type.name.split('_').pop() }, HTMLAttributes), ['address', contentString]]
    },
});

const StructureBlockMetadata = Node.create({
    name: 'structureBlock_metadata',
    content: 'block+',
    isolating: true,

    renderHTML ({ node, HTMLAttributes }) {
        return ['div', mergeAttributes({ 'data-structure-block': node.type.name.split('_').pop() }, HTMLAttributes), 0]
    },
});

const SmartObjectImage = SmartObject.extend({
    name: 'smartObject_image',

    addAttributes() {
        return {
            ...this.parent?.(),
            maxWidth: {
                default: 0,
                parseHTML: element => ({ maxWidth: element.getAttribute('data-max-width') }),
                renderHTML: attributes => ((attributes.maxWidth) ? { 'data-max-width': attributes.maxWidth } : {}),
            },
            maxHeight: {
                default: 0,
                parseHTML: element => ({ maxHeight: element.getAttribute('data-max-height') }),
                renderHTML: attributes => ((attributes.maxHeight) ? { 'data-max-height': attributes.maxHeight } : {}),
            },
        }
    },

    renderHTML ({ node, HTMLAttributes }) {
        const imagePath = get(this.options.contextData, node.attrs.id);

        if (imagePath) {
            const attributes = {
                'data-smart-object': node.type.name.split('_').pop(),
                'data-max-width': node.attrs.maxWidth,
                'data-max-height': node.attrs.maxHeight,
                'src': `[%= ${node.attrs.id} %]`,
            };
            const styles = [];
            if (node.attrs.maxWidth > 0) styles.push(`--size-image-max-width: ${node.attrs.maxWidth}mm;`);
            if (node.attrs.maxHeight > 0) styles.push(`--size-image-max-height: ${node.attrs.maxHeight}mm;`);
            if (styles.length) attributes.style = styles.join(' ');

            return ['img', mergeAttributes(attributes, HTMLAttributes)]
        } else {
            return ['span', { 'class': 'empty-smart-object-image'}]
        }
    },
});

const LetterExtensions = [
    StructureBlockAddress,
    StructureBlockMetadata,
    StructureBlockSubject,
    StructureBlockContent,

    Text,
    Paragraph,
    Heading,
    BulletList,
    OrderedList,
    ListItem,
    HardBreak,

    Bold,
    Italic,
    Strike,

    SmartObjectSimplePlaceholder,
    SmartObjectCurrency,
    SmartObjectDate,
    SmartObjectImage,
    SmartObjectConditionalSpace,
];

const Letter = Node.create({
    name: 'letter',
    topNode: true,
    content: 'structureBlock_address? structureBlock_metadata? structureBlock_subject structureBlock_content',

    addExtensions() {
        return [
            // Filter out extensions …
            ...LetterExtensions.filter(extension => {
                return ![
                    StructureBlockAddress.name,
                    SmartObjectCurrency.name,
                    SmartObjectDate.name,
                    SmartObjectImage.name,
                ].includes(extension.name)
            }),

            // … and include their configured counterparts.
            StructureBlockAddress.configure({
                contextData: this.options.contextData,
                language: this.options.language,
            }),

            SmartObjectCurrency.configure({
                contextData: this.options.contextData,
                language: this.options.language,
            }),

            SmartObjectDate.configure({
                contextData: this.options.contextData,
                language: this.options.language,
            }),

            SmartObjectImage.configure({
                contextData: this.options.contextData,
                language: this.options.language,
            }),
        ]
    },
});

const LetterSchema = getSchema([Letter, ...LetterExtensions]);

const Salutation = Node.create({
    name: 'salutation',
    topNode: true,
    content: 'inline*',

    addExtensions() {
        return [
            Text,

            SmartObjectSimplePlaceholder,
            SmartObjectConditionalSpace,
        ]
    },
});

const SimpleRichText = Node.create({
    name: 'simpleRichText',
    topNode: true,
    content: 'block+',

    addExtensions() {
        return [
            Text,
            Paragraph,
            Heading,
            BulletList,
            OrderedList,
            ListItem,
            HardBreak,

            Bold,
            Italic,
            Strike,
        ]
    },
});

export { EditorContentSchemaType, Email, EmailExtensions, EmailSchema, Letter, LetterExtensions, LetterSchema, Salutation, SimpleRichText, SmartObject, SmartObjectConditionalSpace, SmartObjectCurrency, SmartObjectDate, SmartObjectImage, SmartObjectSimplePlaceholder, StructureBlockAddress, StructureBlockContent, StructureBlockMetadata, StructureBlockSubject, generateText };
