import { Model } from '@/models'

import { ConsultingSettingsService } from '@/services'
import { ConsultingSettingsActions } from '@/enums'
import { ConsultingSettingsStatus } from '@/enums/graphql'
import { formatStatementRecipients } from '@/helpers/accounting'

export class ConsultingSettings extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'contact_id',
            'status',
            'company',
            'signature',
            'defaultDocumentLayout',
            'currentAccount',
            'cancellationReserveAccount',
            'otherIncomeAccount',
            'commissionIncomeAccount',
            'mailProcessingRevenueAccount',
            'statementRecipients',
            'commissionDistributionRuleSet',
            'mailProcessingSettings',
            // Timestamps
            'created_at',
            'updated_at',
        ], data)

        this.statusTransitions[ConsultingSettingsStatus.ACTIVE] = [ConsultingSettingsStatus.INACTIVE]
        this.statusTransitions[ConsultingSettingsStatus.INACTIVE] = [ConsultingSettingsStatus.ACTIVE]

        this.statusActionMapping[ConsultingSettingsStatus.ACTIVE] = ConsultingSettingsActions.ACTIVATE
        this.statusActionMapping[ConsultingSettingsStatus.INACTIVE] = ConsultingSettingsActions.DEACTIVATE
    }

    static get service () { return ConsultingSettingsService }
    static status = ConsultingSettingsStatus
    static action = ConsultingSettingsActions

    static create (contactId, input) {
        return ConsultingSettingsService.create(contactId, input)
    }

    update (input) {
        return ConsultingSettingsService.update(this.contact_id, input).then(consultingSettings => {
            Object.assign(this, consultingSettings)
            return consultingSettings
        })
    }

    delete () {
        return ConsultingSettingsService.delete(this.contact_id)
    }

    get actions () {
        const actions = super.actions
        actions.push({
            key: ConsultingSettingsActions.DELETE,
            item: this,
        })
        return actions
    }

    activate () {
        return ConsultingSettingsService.changeStatus(this.contact_id, ConsultingSettingsStatus.ACTIVE).then(consultingSettings => {
            this.status = consultingSettings.status
            return consultingSettings
        })
    }

    deactivate () {
        return ConsultingSettingsService.changeStatus(this.contact_id, ConsultingSettingsStatus.INACTIVE).then(consultingSettings => {
            this.status = consultingSettings.status
            return consultingSettings
        })
    }

    get hasStatementRecipients () {
        return !!this.statementRecipients?.consultants?.length > 0
    }

    formattedStatementRecipients (showConsultingCompanyName = false, forceShowConsultingCompanyName = false, divider = ', ') {
        return formatStatementRecipients(this.statementRecipients, showConsultingCompanyName, forceShowConsultingCompanyName, divider)
    }
}
