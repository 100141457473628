<template>
    <form data-test="form:2fa-code" @submit.prevent="onFormSubmit">
        <p>{{ $t('views.auth.2fa.text') }}</p>

        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="formErrorMessage"
            type="negative"
            data-test="error:global-message"
            v-html="formErrorMessage"
        />
        <!-- eslint-enable -->

        <form-builder item-key="twoFACode" autofocus />

        <form-navigation>
            <template v-slot:default>
                <slot name="formNavigationDefault" />
            </template>
            <template v-slot:col2>
                <base-button
                    data-test="btn:back"
                    outline
                    :label="$t('common.term.back')"
                    class="q-mr-sm"
                    @click="$emit('back')"
                />
                <base-button
                    data-test="btn:submit"
                    type="submit"
                    :label="$t(submitButtonLabel)"
                    :disable="formSubmitStatus"
                    :loading="formSubmitStatus"
                />
            </template>
        </form-navigation>
    </form>
</template>

<script>
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import FormNavigation from '@/components/form/FormNavigation'

import AUTH_CREDENTIALS_FIELDS from '@/forms/auth.fields.json'

export default {
    name: 'Auth2faCode',
    components: {
        FormNavigation,
    },
    mixins: [formBuilderMixin],
    props: {
        submitButtonLabel: {
            type: String,
            default: 'common.term.continue',
        },
    },
    formBuilderSettings: {
        schema: AUTH_CREDENTIALS_FIELDS,
        fields: ['twoFACode'],
    },
}
</script>
