import { Model } from '@/models'

import store from '@/store/store'
import { ConsultingSettingsStatus, ContactType } from '@/enums/graphql'
import { getContactName, getContactGender } from '@/helpers/contact'

export class Consultant extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'contact_number',
            'first_name',
            'last_name',
            'gender',
            'title',
            'consultingCompany',
            'consulting_company_name',
            'consulting_settings_status',
            // Timestamps
            'created_at',
            'updated_at',
        ], data)
        this.type = ContactType.PERSON
    }

    get isActive () {
        return this.consulting_settings_status === ConsultingSettingsStatus.ACTIVE
    }

    get isCurrentUser () {
        return this.id === store.state.user.consultant?.id
    }

    get consultingCompanyName () {
        // TODO improvement: After refactoring all occurrences of `x.consulting_company_name` to `x.consultingCompany.company_name` in the app, remove according part here.
        return this.consulting_company_name || this.consultingCompany?.company_name || ''
    }

    get worksForUserConsultingCompany () {
        return this.consultingCompany?.id && this.consultingCompany?.id === store.state.user.consultant?.consultingCompany?.id
    }

    getContactName (args) {
        return getContactName(this, args)
    }

    getGender (args) {
        return getContactGender(this, args)
    }
}
