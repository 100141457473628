<template>
    <q-card v-show="items.length" class="popper-wrapper">
        <q-card-section>
            <q-list dense>
                <q-item
                    v-for="(item, index) in items"
                    :key="item.id"
                    v-ripple
                    clickable
                    class="item"
                    :class="{ 'is-selected': index === selectedIndex }"
                    @click="selectItem(index)"
                >
                    <q-item-section>
                        <span
                            :data-smart-object="item.type"
                            :data-id="item.id"
                            class="smart-object"
                        >
                            <q-icon v-if="item.icon" :name="item.icon" />
                            {{ $tc('common.correspondence.smart-object.' + normalizeTranslationId(item.id), 1) }}
                        </span>
                    </q-item-section>
                </q-item>
            </q-list>
        </q-card-section>
    </q-card>
</template>

<script>
import { normalizeTranslationId } from '@/helpers'

export default {
    name: 'SmartObjectSuggestionList',
    props: {
        items: {
            type: Array,
            required: true,
        },
        command: {
            type: Function,
            required: true,
        },
    },
    data () {
        return {
            selectedIndex: 0,
        }
    },
    watch: {
        items () {
            this.selectedIndex = 0
        },
    },
    methods: {
        onKeyDown ({ event }) {
            if (event.key === 'ArrowUp') {
                this.upHandler()
                return true
            }

            if (event.key === 'ArrowDown') {
                this.downHandler()
                return true
            }

            if (event.key === 'Enter') {
                this.enterHandler()
                return true
            }

            return false
        },

        upHandler () {
            this.selectedIndex = (this.selectedIndex + this.items.length - 1) % this.items.length
        },

        downHandler () {
            this.selectedIndex = (this.selectedIndex + 1) % this.items.length
        },

        enterHandler () {
            this.selectItem(this.selectedIndex)
        },

        selectItem (index) {
            const item = this.items[index]

            if (item) {
                this.command(item)
            }
        },
        normalizeTranslationId (...args) {
            return normalizeTranslationId(...args)
        },
    },
}
</script>

<style lang="scss" scoped>
// Duplicated selector for higher specificity (without the need for !important)
.item.item {
    padding: $sizeSpacingXs $sizeSpacingSm;

    &.is-selected,
    &:hover,
    &:focus, {
        background: var(--q-color-primary-lighter);

        .smart-object {
            background-color: var(--color-text-selected-background);
        }
    }
}
</style>
