import gql from 'graphql-tag'

export const COMMISSION_LIST_ENTRY_UPDATE_MUTATION = gql`
    mutation commissionListEntryUpdate(
        $id: ID!
        $contractId: ID!
        $premiumFrom: Date
        $premiumTo: Date
        $commissionType: CommissionType!
        $commissionAmount: Float!
    ) {
        commissionListEntryUpdate(
            id: $id
            contract_id: $contractId
            premium_from: $premiumFrom
            premium_to: $premiumTo
            commission_type: $commissionType
            commission_amount: $commissionAmount
        ) {
            id
            status
            ready_to_post
            commissionList {
                id
            }
            contractMatchShortlist {
                id
                customer_name_match
                contract {
                    id
                    contractNumbers {
                        id
                        number
                    }
                    customer {
                        id
                        ... on Person {
                            first_name
                            last_name
                            gender
                            personal_pronoun
                            title
                        }
                        ... on Company {
                            company_name
                        }
                        contactNumber {
                            number
                        }
                    }
                }
            }
            matchedContract {
                id
                contractNumbers {
                    id
                    number
                }
                customer {
                    id
                    ... on Person {
                        first_name
                        last_name
                        gender
                        personal_pronoun
                        title
                    }
                    ... on Company {
                        company_name
                    }
                    contactNumber {
                        number
                    }
                }
            }
            contract_number
            framework_agreement_number
            positions {
                id
                type
                premium_from
                premium_to
                commission_type
                commission_amount
            }
            customer_first_name
            customer_last_name
            customer_company_name
            customer_full_name
            created_manually
            created_at
            updated_at
        }
    }
`
