<template>
    <page-loading-indicator v-if="!correspondenceLetter" :number-of-breadcrumb-elements="3" subtitle />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.correspondence.letter.letter', 2)" :to="{ name: 'correspondence-letters' }" />
                <q-breadcrumbs-el :label="$tc('common.correspondence.letter.write-letter', 1)" :to="{ name: 'correspondence-letter-update', params: { id: correspondenceLetter.id } }" />
                <q-breadcrumbs-el :label="$tc('common.term.preview', 1)" :to="{ name: 'correspondence-letter-preview', params: { id: correspondenceLetter.id } }" />
            </template>

            <h1>{{ $tc('common.correspondence.letter.letter-preview', 1) }} <span class="subtitle">{{ correspondenceLetter.name }}</span></h1>
        </page-header>

        <div class="row q-col-gutter-md">
            <div class="col-xs-12 col-lg-10 col-xl-8">
                <info-box>
                    <i18n path="common.correspondence.letter.letter-preview--text" tag="p">
                        <template v-slot:numberOfRecipients>
                            <b>{{ $tc('common.correspondence.letter.letter-preview--text--part--number-of-recipients', correspondenceLetter.target_object_ids.length) }}</b>
                        </template>
                        <template v-slot:correspondenceLanguage>{{ correspondenceLetter.language.name }}</template>
                        <template v-slot:differentiateFormality>{{ $t(`common.correspondence.letter.letter-preview--text--part--differentiate-formality--${correspondenceLetter.differentiate_formality.toString()}`) }}</template>
                    </i18n>
                </info-box>

                <q-card flat bordered>
                    <q-item class="q-pt-md q-pl-lg">
                        <p class="q-mb-none"><span class="additional-info">{{ $tc(`common.term.sender--${correspondenceLetter.targetObjects[0].sender.gender.toLowerCase()}`, 1) }}:</span> {{ correspondenceLetter.targetObjects[0].sender.getContactName({ consultingCompanyName: true }) }}</p>
                    </q-item>

                    <q-separator />

                    <div class="address-preview">
                        <p v-if="correspondenceLetter.targetObjects[0].recipient.gender" class="additional-info">{{ $tc(`common.term.recipient--${correspondenceLetter.targetObjects[0].recipient.gender.toLowerCase()}`, 1) }}:</p>
                        <p v-else class="additional-info">{{ $tc('common.term.recipient', 1) }}:</p>
                        <address>
                            <template v-if="correspondenceLetter.confidentiality_notice">{{ $t(`common.correspondence.confidentiality-notice.${kebabCase(correspondenceLetter.confidentiality_notice)}`) }}<br></template>
                            {{ correspondenceLetter.targetObjects[0].recipient.getContactName({ salutation: true, title: true }) }}<br>
                            <template v-if="correspondenceLetter.attention_ofs">{{ correspondenceLetter.attention_ofs[correspondenceLetter.targetObjects.at(0)?.customer ? correspondenceLetter.targetObjects.at(0).customer.id : correspondenceLetter.targetObjects.at(0).id] }}<br></template>
                            {{ correspondenceLetter.targetObjects[0].recipient.getContactAddress(correspondenceLetter.targetObjects[0].recipient_address, { multiline: true }) }}
                        </address>
                    </div>
                </q-card>
            </div>

            <div class="col-xs-12 col-lg-10 col-xl-8">
                <base-button
                    data-test="btn:create-preview"
                    :label="$tc('common.correspondence.letter.create-preview', correspondenceLetter.target_object_ids.length)"
                    :loading="creatingPreview"
                    :disable="creatingPreview"
                    primary-button
                    outline
                    class="do-not-print"
                    @click="createPreview"
                >
                    <template v-slot:loading>
                        <q-spinner class="q-mr-sm" />
                        <span>{{ $tc('common.correspondence.letter.create-preview--creating-preview', correspondenceLetter.target_object_ids.length) }}</span>
                    </template>
                </base-button>
            </div>
        </div>

        <in-page-footer>
            <base-button
                data-test="btn:back"
                :label="$t('common.term.back')"
                flat
                primary-button
                :to="{ name: 'correspondence-letter-update', params: { id: correspondenceLetter.id } }"
            />

            <!-- Start processing -->
            <form-dialog
                ref="correspondenceLetterStartProcessingDialog"
                :double-confirm="correspondenceLetter.target_object_ids.length > 1"
                dont-close-on-confirm
                @confirm="handleCorrespondenceLetterStartProcessing"
            >
                <template v-slot:title>{{ $tc('common.correspondence.letter.create-letter', correspondenceLetter.target_object_ids.length) }}</template>
                <template v-slot:default>
                    <ul :class="{ 'q-mb-none': correspondenceLetter.target_object_ids.length <= 1 }">
                        <li>{{ $tc('common.correspondence.letter.send-letter--confirm--description--created-for-recipient', correspondenceLetter.target_object_ids.length) }}</li>
                        <li>{{ $tc(`common.correspondence.letter.send-letter--confirm--description--stored-as-correspondence--${correspondenceLetter.target_object_type.toLowerCase()}`, correspondenceLetter.target_object_ids.length) }}</li>
                    </ul>
                </template>
            </form-dialog>

            <base-button
                :label="$tc('common.correspondence.letter.create-letter', correspondenceLetter.target_object_ids.length)"
                primary-button
                @click="triggerStartProcessing"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { CorrespondenceLetter } from '@/models/correspondenceLetter'
import FormDialog from '@/components/form/FormDialog'
import { kebabCase } from 'lodash'

export default {
    name: 'CorrespondenceLetterPreview',
    meta () {
        return {
            title: this.$tc('common.correspondence.letter.letter-preview', 1),
        }
    },
    components: {
        FormDialog,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            correspondenceLetter: null,
            creatingPreview: false,
        }
    },
    created () {
        // TODO improvement @TFU: Redirect if item with given ID should not have this view. (Due to its status.)
        CorrespondenceLetter.objects.get(this.id).then(correspondenceLetter => {
            this.correspondenceLetter = correspondenceLetter
        })
    },
    methods: {
        kebabCase,
        createPreview () {
            this.creatingPreview = true

            this.correspondenceLetter.preview(this.correspondenceLetter.target_object_ids[0]) // As long as there is no bulk, just use the first and only targetObjectId.
                .then(() => {
                    // TODO: Check if this notification is necessary and/or useful. (Remove if not.)
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.correspondence.correspondence-letter-preview-created-success', 1),
                    })
                })
                .catch(error => {
                    // TODO @TFU: Check if we even know when there was an error?
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$tc('common.notifications.correspondence.correspondence-letter-preview-created-error', 1),
                    })
                })
                .finally(() => {
                    this.creatingPreview = false
                })
        },
        triggerStartProcessing () {
            this.$refs.correspondenceLetterStartProcessingDialog.open()
        },
        handleCorrespondenceLetterStartProcessing () {
            this.correspondenceLetter.startProcessing()
                .then(() => {
                    this.$refs.correspondenceLetterStartProcessingDialog.close()
                    this.$router.replace({ name: 'correspondence-letter-summary', params: { id: this.id } })
                })
                .catch(error => {
                    this.$refs.correspondenceLetterStartProcessingDialog.showFormErrorMessage(error)
                    this.$refs.correspondenceLetterStartProcessingDialog.resetFormSubmitStatus()
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.address-preview {
    position: relative;
    margin: $sizeSpacingMd $sizeSpacingLg;

    address {
        padding: $sizeSpacingMd;
        display: block;
        max-width: 100mm;
        min-height: 45mm;

        border: 1px solid var(--color-border-primary);
        border-radius: $sizeSpacingSm !important; // !important necessary
        background: var(--color-background-primary);
    }
}
</style>
