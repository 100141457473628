import { apolloClient } from '@/apollo/client'

import { CONTACT_RELATIONSHIP_QUERY } from '@/graphql/contactRelationship/contactRelationship'
import { CONTACT_RELATIONSHIPS_QUERY } from '@/graphql/contactRelationship/contactRelationships'
import { CONTACT_RELATIONSHIP_CREATE_MUTATION } from '@/graphql/contactRelationship/contactRelationshipCreate'
import { CONTACT_RELATIONSHIP_UPDATE_MUTATION } from '@/graphql/contactRelationship/contactRelationshipUpdate'
import { CONTACT_RELATIONSHIP_DELETE_MUTATION } from '@/graphql/contactRelationship/contactRelationshipDelete'

export const ContactRelationshipService = {
    get (id) {
        return apolloClient.query({
            query: CONTACT_RELATIONSHIP_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.contactRelationship)
    },
    all (variables) {
        return apolloClient.query({
            query: CONTACT_RELATIONSHIPS_QUERY,
            variables,
        }).then(response => response.data.contactRelationships)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: CONTACT_RELATIONSHIP_CREATE_MUTATION,
            variables,
        }).then(response => response.data.contactRelationshipCreate)
    },
    update (id, variables) {
        return apolloClient.mutate({
            mutation: CONTACT_RELATIONSHIP_UPDATE_MUTATION,
            variables: {
                id: id,
                ...variables,
            },
        }).then(response => response.data.contactRelationshipUpdate)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: CONTACT_RELATIONSHIP_DELETE_MUTATION,
            variables: {
                id,
            },
        }).then(response => response.data.contactRelationshipDelete)
    },
}
