var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-wrapper',[_c('page-header',{scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(){return [_c('q-breadcrumbs-el',{attrs:{"label":_vm.$tc('views.admin.eco-hub.tech-user.tech-user', 1),"to":{ name: 'eco-hub-tech-user-detail' }}}),_c('q-breadcrumbs-el',{attrs:{"label":_vm.$tc('views.admin.eco-hub.tech-user.enroll-tech-user', 1),"to":{ name: 'eco-hub-tech-user-create' }}})]},proxy:true}])},[_c('h1',[_vm._v(_vm._s(_vm.$tc('views.admin.eco-hub.tech-user.tech-user', 1)))])]),_c('div',{staticClass:"row print-row-lg"},[_c('grid-card',{attrs:{"columns":{
                'xs': 12,
                'sm': 12,
                'md': 8,
                'lg': 6,
                'xl': 6,
            },"heading":_vm.$tc('views.admin.eco-hub.tech-user.enroll-tech-user', 1),"heading-info":_vm.$t('views.admin.eco-hub.tech-user.tech-user--info--short'),"container-type-normal":""}},[_c('eco-hub-tech-user-form',{ref:"ecoHubTechUserForm",on:{"cancel":_vm.onCancel,"submit":_vm.onSubmit}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }