import gql from 'graphql-tag'

export const TASK_DELETE_ATTACHMENT_MUTATION = gql`
    mutation taskDeleteAttachment(
        $taskId: ID!
        $fileId: ID!
    ) {
        taskDeleteAttachment(
            task_id: $taskId
            file_id: $fileId
        ) {
            status
        }
    }
`
