import gql from 'graphql-tag'

export function buildContractsQuery (fields) {
    return gql`
        query contracts(
            $page: Int = 1
            $count: Int = 1000
            $orderField: ContractOrderField = PRODUCT_NAME
            $orderDirection: OrderDirection = DESC
            $filterQuery: String
            $filterStatus: [ContractStatus!]
            $filterManagedByStatus: [ContractManagedByStatus!]
            $filterCustomerId: ID
            $filterConsultantId: ID
            $filterCreatedAtDateFrom: Date
            $filterCreatedAtDateUntil: Date
        ) {
            contracts(
                page: $page
                count: $count
                order: [
                    {
                        field: $orderField
                        direction: $orderDirection
                    }
                ]
                search: {
                    search_term: $filterQuery,
                    contract_status: $filterStatus,
                    managed_by_status: $filterManagedByStatus,
                    customer_id: $filterCustomerId
                    consultant_id: $filterConsultantId
                    created_at_date_from: $filterCreatedAtDateFrom
                    created_at_date_until: $filterCreatedAtDateUntil
                }
            ) {
                paginatorInfo {
                    count
                    firstItem
                    lastItem
                    total
                    currentPage
                    lastPage
                    perPage
                    hasMorePages
                }
                data {
                    ${fields}
                }
            }
        }
    `
}

const defaultContractsQueryFields = `
    id
    contractNumbers {
        id
        number
    }
    status
    customer {
        id
        contactNumber {
            id
            number
        }
        ... on Person {
            first_name
            last_name
        }
        ... on Company {
            company_name
        }
    }
    consultant {
        id
        contact_number
        first_name
        last_name
        gender
        title
        consultingCompany {
            id
        }
        consulting_company_name
        consulting_settings_status
    }
    currentContractInformation {
        product {
            name
            basicProvider {
                name
                display_name
            }
        }
        productTemplate {
            name
            category {
                name
            }
            subCategory {
                name
            }
            version
        }
        start_date
        end_date
        premium
        generalAgency {
            id
            company_name
        }
        managed_by_status
        consulting_mandate_enquiry_send_date
    }
    cancellations {
        id
        status
        cancellation_date
    }
    termination_date
    replacedBy {
        id
    }
    commissionRecipientOverride {
        id
        first_name
        last_name
        contact_number
        gender
        title
        consultingCompany {
            id
        }
        consulting_company_name
    }
    commissionSplits {
        id
    }
`

export const CONTRACTS_QUERY = buildContractsQuery(defaultContractsQueryFields)
