import { apolloClient } from '@/apollo/client'

import { DOCUMENT_LAYOUT_QUERY } from '@/graphql/documentLayout/documentLayout'
import { DOCUMENT_LAYOUTS_QUERY } from '@/graphql/documentLayout/documentLayouts'
import { DOCUMENT_LAYOUT_CREATE_MUTATION } from '@/graphql/documentLayout/documentLayoutCreate'
import { DOCUMENT_LAYOUT_UPDATE_MUTATION } from '@/graphql/documentLayout/documentLayoutUpdate'
import { DOCUMENT_LAYOUT_DELETE_MUTATION } from '@/graphql/documentLayout/documentLayoutDelete'

export const DocumentLayoutService = {
    get (id) {
        return apolloClient.query({
            query: DOCUMENT_LAYOUT_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.documentLayout)
    },
    all (variables) {
        return apolloClient.query({
            query: DOCUMENT_LAYOUTS_QUERY,
            variables,
        }).then(response => response.data.documentLayouts)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: DOCUMENT_LAYOUT_CREATE_MUTATION,
            variables,
        }).then(response => response.data.documentLayoutCreate)
    },
    update (id, documentLayoutVariables) {
        return apolloClient.mutate({
            mutation: DOCUMENT_LAYOUT_UPDATE_MUTATION,
            variables: {
                id: id,
                documentLayout: documentLayoutVariables,
            },
        }).then(response => response.data.documentLayoutUpdate)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: DOCUMENT_LAYOUT_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.documentLayoutDelete)
    },
}
