<template>
    <page-wrapper>
        <page-header>
            <h1>{{ $tc('common.term.dashboard', 1) }} <span class="subtitle">{{ $store.state.user.getUserName() }}</span></h1>
        </page-header>

        <div v-can="'Feature:tasks:core'">
            <div class="row q-col-gutter-md">
                <h2>{{ $tc('common.task.task', 2) }}</h2>
            </div>

            <div class="row print-row-md q-col-gutter-lg">
                <!-- TODO improvement: Card settings (user settings) for how many tasks should be shown in this card. -->
                <grid-card
                    :columns="{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }"
                    :heading="$t('common.task.assigned-to-me')"
                    :heading-level="3"
                    :subheading="tasksAssignedToUser ? $tc('common.task.task--n-tasks', numberOfTasks.assignedToUser) : null"
                    min-height="30em"
                    max-height="40em"
                    content-scrollable
                    :no-content-padding="tasksAssignedToUser && numberOfTasks.assignedToUser > 0 ? true : null"
                    :loading="!tasksAssignedToUser"
                >
                    <template v-if="tasksAssignedToUser">
                        <template v-if="numberOfTasks.assignedToUser > 0">
                            <task-info-list
                                :tasks="tasksAssignedToUser"
                                group="tasks-assigned-to-me"
                                no-assignee
                            />

                            <p v-if="numberOfTasks.assignedToUser > numberOfTasks.assignedToUserShown" class="additional-info q-pa-md">
                                {{ $tc('common.task.only-first-n-tasks-shown', numberOfTasks.assignedToUserShown) }}
                            </p>
                        </template>
                    </template>

                    <template v-slot:actions>
                        <base-button
                            :label="$t('common.task.all-my-assigned-tasks')"
                            :to="{ name: 'task-list', query: { filterAssigneeId: userConsultantId } }"
                            primary-button
                            flat
                        />
                    </template>
                </grid-card>

                <grid-card
                    :columns="{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }"
                    :heading="$t('common.task.created-by-me')"
                    :heading-level="3"
                    :subheading="tasksCreatedByUser ? $tc('common.task.task--n-tasks', numberOfTasks.createdByUser) : null"
                    :min-height="tasksCreatedByUser ? null : '15em'"
                    :loading="!tasksCreatedByUser"
                    content-scrollable
                    :no-content-padding="tasksCreatedByUser && numberOfTasks.createdByUser > 0 ? true : null"
                >
                    <template v-slot:headerMenu>
                        <q-list separator>
                            <q-item clickable @click="() => triggerCreateTask()">
                                <q-item-section>{{ $tc('common.task.add-task', 1) }}</q-item-section>
                            </q-item>
                        </q-list>
                    </template>

                    <template v-if="tasksCreatedByUser">
                        <template v-if="numberOfTasks.createdByUser > 0">
                            <task-info-list
                                :tasks="tasksCreatedByUser"
                                group="tasks-created-by-me"
                                no-creator
                            />

                            <p v-if="numberOfTasks.createdByUser > numberOfTasks.createdByUserShown" class="additional-info q-pa-md">
                                {{ $tc('common.task.only-first-n-tasks-shown', numberOfTasks.createdByUserShown) }}
                            </p>
                        </template>
                    </template>

                    <template v-slot:actions>
                        <base-button
                            :label="$t('common.task.all-my-created-tasks')"
                            :to="{ name: 'task-list', query: { filterCreatorId: userConsultantId } }"
                            primary-button
                            flat
                        />
                    </template>
                </grid-card>

                <!-- TODO improvement @MTR: Use D3.js pie chart (or similar) -->
                <grid-card
                    :columns="{ xs: 12, sm: 12, md: 6, lg: 4, xl: 4 }"
                    :heading="$tc('views.dashboard.tasks.statistic', 1)"
                    :heading-level="3"
                    :heading-info="$t('views.dashboard.tasks.statistic--description')"
                    :loading="!tasksLoaded"
                >
                    <template v-if="tasksLoaded">
                        <template v-if="numberOfTasks.pending">
                            <p>{{ $tc('views.dashboard.tasks.there-are-n-pending-tasks', numberOfTasks.pending) }}:</p>

                            <q-linear-progress
                                size="24px"
                                :value="numberOfTasks.status[TaskStatus.TODO] / numberOfTasks.pending"
                                color="grey-5"
                                class="q-mb-sm"
                            >
                                <div class="absolute-full flex flex-center">
                                    <q-badge
                                        color="white"
                                        text-color="secondary-dark"
                                        :label="`${$t('common.status.todo')}: ${numberOfTasks.status[TaskStatus.TODO]} (${((100 / numberOfTasks.pending) * numberOfTasks.status[TaskStatus.TODO]).toFixed(0)}%)`"
                                    />
                                </div>
                            </q-linear-progress>

                            <q-linear-progress
                                size="24px"
                                :value="numberOfTasks.status[TaskStatus.IN_PROGRESS] / numberOfTasks.pending"
                                color="lime"
                                class="q-mb-sm"
                            >
                                <div class="absolute-full flex flex-center">
                                    <q-badge
                                        color="white"
                                        text-color="secondary-dark"
                                        :label="`${$t('common.status.in-progress')}: ${numberOfTasks.status[TaskStatus.IN_PROGRESS]} (${((100 / numberOfTasks.pending) * numberOfTasks.status[TaskStatus.IN_PROGRESS]).toFixed(0)}%)`"
                                    />
                                </div>
                            </q-linear-progress>
                        </template>
                        <template v-else>
                            <p>{{ $tc('views.dashboard.tasks.no-pending-tasks-text', (numberOfTasks.status[TaskStatus.DONE] > 20) ? 0 : numberOfTasks.status[TaskStatus.DONE]) }}</p>

                            <template v-if="numberOfTasks.status[TaskStatus.DONE]">
                                <q-linear-progress
                                    size="24px"
                                    :value="0"
                                    color="grey-5"
                                    class="q-mb-sm"
                                >
                                    <div class="absolute-full flex flex-center">
                                        <q-badge
                                            color="white"
                                            text-color="secondary-dark"
                                            :label="`${$tc('common.task.task--pending-task--n-pending-tasks', 0)} (0%)`"
                                        />
                                    </div>
                                </q-linear-progress>

                                <q-linear-progress
                                    size="24px"
                                    :value="1"
                                    color="positive"
                                    class="q-mb-sm"
                                >
                                    <div class="absolute-full flex flex-center">
                                        <q-badge
                                            color="white"
                                            text-color="secondary-dark"
                                            :label="`${$t('common.status.done')}: ${numberOfTasks.status[TaskStatus.DONE]} (100%)`"
                                        />
                                    </div>
                                </q-linear-progress>
                            </template>
                        </template>

                        <q-separator v-if="numberOfTasks.pending" class="q-mt-md q-mb-md" />

                        <template v-if="numberOfTasks.urgent">
                            <p>{{ $tc('views.dashboard.tasks.n-of-these-tasks-are-urgent', numberOfTasks.urgent) }}:</p>

                            <q-linear-progress
                                size="24px"
                                :value="numberOfTasks.dueDateStatus[TaskDueDateStatus.OVERDUE] / numberOfTasks.urgent"
                                color="negative"
                                class="q-mb-sm"
                            >
                                <div class="absolute-full flex flex-center">
                                    <q-badge
                                        color="white"
                                        text-color="secondary-dark"
                                        :label="`${$t('common.status.due-date-status.overdue')}: ${numberOfTasks.dueDateStatus[TaskDueDateStatus.OVERDUE]}`"
                                    />
                                </div>
                            </q-linear-progress>

                            <q-linear-progress
                                size="24px"
                                :value="numberOfTasks.dueDateStatus[TaskDueDateStatus.UPCOMING] / numberOfTasks.urgent"
                                color="warning"
                                class="q-mb-sm"
                            >
                                <div class="absolute-full flex flex-center">
                                    <q-badge
                                        color="white"
                                        text-color="secondary-dark"
                                    >
                                        <template v-if="numberOfTasks.dueDateStatus[TaskDueDateStatus.DUE_TODAY]">
                                            <template v-if="numberOfTasks.dueDateStatus[TaskDueDateStatus.UPCOMING] > numberOfTasks.dueDateStatus[TaskDueDateStatus.DUE_TODAY]">
                                                {{ $t('common.status.due-date-status.upcoming') }}: {{ numberOfTasks.dueDateStatus[TaskDueDateStatus.UPCOMING] }} ({{ $tc('views.dashboard.tasks.n-of-these-tasks-are-urgent--n-of-which-are-due-today', numberOfTasks.dueDateStatus[TaskDueDateStatus.DUE_TODAY]) }})
                                            </template>
                                            <template v-else>
                                                {{ $t('common.status.due-date-status.due-today') }}: {{ numberOfTasks.dueDateStatus[TaskDueDateStatus.DUE_TODAY] }}
                                            </template>
                                        </template>

                                        <template v-else>
                                            {{ $t('common.status.due-date-status.upcoming') }}: {{ numberOfTasks.dueDateStatus[TaskDueDateStatus.UPCOMING] }}
                                        </template>
                                    </q-badge>
                                </div>
                            </q-linear-progress>
                        </template>
                        <p v-else-if="numberOfTasks.pending" class="additional-info">{{ $tc('views.dashboard.tasks.n-of-these-tasks-are-urgent', 0) }}.</p>
                    </template>

                    <template v-slot:actions>
                        <base-button
                            :to="{ name: 'task-list' }"
                            :label="$t('common.task.all-tasks')"
                            primary-button
                            flat
                        />

                        <base-button
                            v-if="numberOfTasks.dueDateStatus[TaskDueDateStatus.OVERDUE]"
                            :to="{ name: 'task-list', query: { filterDueDateStatus: TaskDueDateStatus.OVERDUE } }"
                            :label="$tc('common.status.due-date-status.overdue', numberOfTasks.dueDateStatus[TaskDueDateStatus.OVERDUE])"
                            primary-button
                            flat
                        />

                        <base-button
                            v-if="numberOfTasks.dueDateStatus[TaskDueDateStatus.DUE_TODAY]"
                            :to="{ name: 'task-list', query: { filterDueDateStatus: TaskDueDateStatus.DUE_TODAY } }"
                            :label="$tc('common.status.due-date-status.due-today', numberOfTasks.dueDateStatus[TaskDueDateStatus.DUE_TODAY])"
                            primary-button
                            flat
                        />

                        <base-button
                            v-if="numberOfTasks.dueDateStatus[TaskDueDateStatus.UPCOMING] > numberOfTasks.dueDateStatus[TaskDueDateStatus.DUE_TODAY]"
                            :to="{ name: 'task-list', query: { filterDueDateStatus: TaskDueDateStatus.UPCOMING } }"
                            :label="$tc('common.status.due-date-status.upcoming', numberOfTasks.dueDateStatus[TaskDueDateStatus.UPCOMING])"
                            primary-button
                            flat
                        />
                    </template>
                </grid-card>
            </div>
        </div>

        <div v-can.not="'Feature:tasks:core'" class="col flex flex-center do-not-print" style="height: 75vh;">
            <inline-svg :src="require('@/assets/img/max-logo.svg')" style="width: 20rem;" />
        </div>
    </page-wrapper>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { EventBus } from '@/event-bus'
import { formatISO, startOfDay, endOfDay, addDays } from 'date-fns'
import { formatDate } from '@/helpers/date'
import { Task } from '@/models/task'
import { DashboardService } from '@/services/dashboard'
import { TaskStatus } from '@/enums/graphql'
import { TaskDueDateStatus, TaskStatistics } from '@/enums'
import TaskInfoList from '@/components/task/TaskInfoList.vue'

export default {
    name: 'Dashboard',
    components: {
        TaskInfoList,
    },
    meta () {
        return {
            title: this.$tc('common.term.dashboard', 1),
        }
    },
    data () {
        const store = this.$store.state
        const userConsultantId = store.user.consultant.id

        return {
            EventBus,
            userConsultantId,
            TaskStatus,
            TaskDueDateStatus,
            TaskStatistics,
            tasksLoaded: false,
            tasksAssignedToUser: null,
            tasksCreatedByUser: null,
            numberOfTasks: {
                assignedToUser: 0,
                assignedToUserShown: 10,
                createdByUser: 0,
                createdByUserShown: 10,
                pending: 0,
                status: {
                    [TaskStatus.TODO]: 0,
                    [TaskStatus.IN_PROGRESS]: 0,
                    [TaskStatus.DONE]: 0,
                },
                urgent: 0,
                dueDateStatus: {
                    [TaskDueDateStatus.OVERDUE]: 0,
                    [TaskDueDateStatus.DUE_TODAY]: 0,
                    [TaskDueDateStatus.UPCOMING]: 0,
                    [TaskDueDateStatus.FUTURE]: 0,
                },
            },
        }
    },
    async created () {
        if (this.$can('Feature:tasks:core')) {
            const date = new Date()
            const overdueDate = +startOfDay(new Date()) - 1
            const dueDateUntil = endOfDay(addDays(date, Task.UPCOMING_DUE_DATE_AMOUNT_OF_DAYS))

            await DashboardService.all({
                filterUserId: this.$store.state.user.consultant.id,
                filterTasksStatisticsDueDateStatusDueTodayFrom: formatISO(startOfDay(date)),
                filterTasksStatisticsDueDateStatusDueTodayUntil: formatISO(endOfDay(date)),
                filterTasksStatisticsDueDateStatusOverdueUntil: formatISO(new Date(overdueDate)),
                filterTasksStatisticsDueDateStatusUpcomingFrom: formatISO(startOfDay(date)),
                filterTasksStatisticsDueDateStatusUpcomingUntil: formatISO(dueDateUntil),
                filterTasksStatisticsDueDateStatusFutureFrom: formatISO(+dueDateUntil + 1),
            }).then(result => {
                this.tasksLoaded = true
                this.tasksAssignedToUser = result.tasksAssigned.data
                this.tasksCreatedByUser = result.tasksCreated.data
                this.numberOfTasks = {
                    assignedToUser: result.tasksAssigned.paginatorInfo.total,
                    assignedToUserShown: result.tasksAssigned.paginatorInfo.count,
                    createdByUser: result.tasksCreated.paginatorInfo.total,
                    createdByUserShown: result.tasksCreated.paginatorInfo.count,
                    pending: result.tasksStatisticsStatusTodo.paginatorInfo.total + result.tasksStatisticsStatusInProgress.paginatorInfo.total,
                    status: {
                        [TaskStatus.TODO]: result.tasksStatisticsStatusTodo.paginatorInfo.total,
                        [TaskStatus.IN_PROGRESS]: result.tasksStatisticsStatusInProgress.paginatorInfo.total,
                        [TaskStatus.DONE]: result.tasksStatisticsStatusDone.paginatorInfo.total,
                    },
                    urgent: result.tasksStatisticsDueDateStatusOverdue.paginatorInfo.total + result.tasksStatisticsDueDateStatusUpcoming.paginatorInfo.total,
                    dueDateStatus: {
                        [TaskDueDateStatus.OVERDUE]: result.tasksStatisticsDueDateStatusOverdue.paginatorInfo.total,
                        [TaskDueDateStatus.DUE_TODAY]: result.tasksStatisticsDueDateStatusDueToday.paginatorInfo.total,
                        [TaskDueDateStatus.UPCOMING]: result.tasksStatisticsDueDateStatusUpcoming.paginatorInfo.total,
                        [TaskDueDateStatus.FUTURE]: result.tasksStatisticsDueDateStatusFuture.paginatorInfo.total,
                    },
                }
            })
        }
    },
    methods: {
        triggerCreateTask (targetObject) {
            EventBus.$emit('taskFormDialog:open', { targetObject })
        },
        toKebabCase: function (text) {
            return kebabCase(text)
        },
        formatDate (...args) {
            return formatDate(...args)
        },
    },
}
</script>
