import { Letter as LetterBase } from '@max/tiptap-extensions'

import Dropcursor from '@tiptap/extension-dropcursor'
import History from '@tiptap/extension-history'

import { StructureBlockAddress } from '@/editor/structureBlocks/StructureBlockAddress'
import { StructureBlockMetadata } from '@/editor/structureBlocks/StructureBlockMetadata'
import { StructureBlockContent } from '@/editor/structureBlocks/StructureBlockContent'
import { StructureBlockSubject } from '@/editor/structureBlocks/StructureBlockSubject'

import { SmartObjectSuggestion } from '@/editor/smartObjects/SmartObjectSuggestion'
import { SmartObjectSimplePlaceholder } from '@/editor/smartObjects/SmartObjectSimplePlaceholder'
import { SmartObjectCurrency } from '@/editor/smartObjects/SmartObjectCurrency'
import { SmartObjectDate } from '@/editor/smartObjects/SmartObjectDate'
import { SmartObjectImage } from '@/editor/smartObjects/SmartObjectImage'
import { SmartObjectConditionalSpace } from '@/editor/smartObjects/SmartObjectConditionalSpace'

export const Letter = LetterBase.extend({
    addExtensions() {
        const letterBaseExtensions = this.parent?.() || []
        return [
            // Filter out StructureBlock… and SmartObject… extensions …
            ...letterBaseExtensions.filter(extension => {
                return ![
                    StructureBlockAddress.name,
                    StructureBlockMetadata.name,
                    StructureBlockContent.name,
                    StructureBlockSubject.name,
                    SmartObjectSimplePlaceholder.name,
                    SmartObjectCurrency.name,
                    SmartObjectDate.name,
                    SmartObjectImage.name,
                    SmartObjectConditionalSpace.name,
                ].includes(extension.name)
            }),

            Dropcursor,
            History,

            SmartObjectSuggestion.configure({
                blockEditorComponentInstance: this.options.blockEditorComponentInstance,
            }),

            // … and include the versions with a NodeView.
            StructureBlockAddress,
            StructureBlockMetadata,
            StructureBlockContent,
            StructureBlockSubject,
            SmartObjectSimplePlaceholder,
            SmartObjectCurrency,
            SmartObjectDate,
            SmartObjectImage,
            SmartObjectConditionalSpace,
        ]
    },
})
