<template>
    <base-select-filter
        v-if="options.length"

        :value="value"
        v-bind="$attrs"
        :options="options"

        option-value="id"
        :option-label="getOptionLabel"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import { stringWithPlaceholderTags } from '@/helpers'
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { CorrespondenceSalutationService } from '@/services/correspondenceSalutation'

export default {
    name: 'CorrespondenceSalutationSelect',
    components: {
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        filter: {
            type: Object,
        },
    },
    data () {
        return {
            options: [],
        }
    },
    created () {
        const variables = {}
        if (this.filter) Object.assign(variables, this.filter)

        CorrespondenceSalutationService.all(variables).then(response => {
            this.options = response.data
        })
    },
    methods: {
        getOptionLabel (item) {
            return stringWithPlaceholderTags(item.salutation.contentHTML)
        },
    },
}
</script>
