export default {
    name: 'max-icons-bold',
    type: {
        positive: 'mib-check-circle-1',
        negative: 'mib-alert-triangle',
        info: 'mib-information-circle',
        warning: 'mib-alert-diamond',
    },
    arrow: {
        up: 'mib-arrow-up',
        right: 'mib-arrow-right',
        down: 'mib-arrow-down',
        left: 'mib-arrow-left',
        dropdown: 'mib-arrow-button-down-2',
    },
    chevron: {
        left: 'mib-arrow-left-1',
        right: 'mib-arrow-right-1',
    },
    colorPicker: {
        spectrum: 'mib-color-brush',
        tune: 'mib-settings-horizontal',
        palette: 'mib-color-palette-sample',
    },
    pullToRefresh: {
        icon: 'mib-button-refresh-arrow',
    },
    carousel: {
        left: 'mib-arrow-left-1',
        right: 'mib-arrow-right-1',
        navigationIcon: 'mib-sign-badge-circle',
        thumbnails: 'mib-ui-browser-slider',
    },
    chip: {
        remove: 'mib-remove-circle',
        selected: 'mib-check',
    },
    datetime: {
        arrowLeft: 'mib-arrow-left-1',
        arrowRight: 'mib-arrow-right-1',
        now: 'mib-time-clock-circle-alternate',
        today: 'mib-calendar',
    },
    editor: {
        bold: 'mib-text-bold',
        italic: 'mib-text-italic',
        strikethrough: 'mib-text-strike-through',
        underline: 'mib-text-underline',
        unorderedList: 'mib-list-bullets',
        orderedList: 'mib-list-numbers',
        subscript: 'mib-subscript',
        superscript: 'mib-superscript',
        hyperlink: 'mib-hyperlink-2',
        toggleFullscreen: 'mib-expand-full',
        quote: 'mib-open-quote',
        left: 'mib-paragraph-left-align',
        center: 'mib-paragraph-center-align',
        right: 'mib-paragraph-right-align',
        justify: 'mib-paragraph-justified-align',
        print: 'mib-print-text',
        outdent: 'mib-indent-decrease-1',
        indent: 'mib-indent-increase-1',
        removeFormat: 'mib-italic-off',
        formatting: 'mib-text-options',
        fontSize: 'mib-font-size',
        align: 'mib-paragraph-left-align',
        hr: 'mib-subtract',
        undo: 'mib-undo',
        redo: 'mib-redo',
        header: 'mib-small-caps',
        code: 'mib-angle-brackets',
        size: 'mib-small-caps',
        font: 'mib-text-style',
        viewSource: 'mib-angle-brackets',
    },
    expansionItem: {
        icon: 'mib-arrow-down-1',
        denseIcon: 'mib-arrow-button-down-2',
    },
    fab: {
        icon: 'mib-add',
        activeIcon: 'mib-close',
    },
    field: {
        clear: 'mib-remove-circle',
        error: 'mib-alert-circle',
    },
    pagination: {
        first: 'mib-itx-arrow-first',
        prev: 'mib-arrow-left-1',
        next: 'mib-arrow-right-1',
        last: 'mib-itx-arrow-last',
    },
    rating: {
        icon: 'mib-rating-star',
    },
    stepper: {
        done: 'mib-check',
        active: 'mib-pencil',
        error: 'mib-alert-triangle',
    },
    tabs: {
        left: 'mib-arrow-left-1',
        right: 'mib-arrow-right-1',
        up: 'mib-arrow-up-1',
        down: 'mib-arrow-down-1',
    },
    table: {
        arrowUp: 'mib-arrow-up',
        warning: 'mib-alert-triangle',
        firstPage: 'mib-itx-arrow-first',
        prevPage: 'mib-arrow-left-1',
        nextPage: 'mib-arrow-right-1',
        lastPage: 'mib-itx-arrow-last',
    },
    tree: {
        icon: 'mib-arrow-button-right-2',
    },
    uploader: {
        done: 'mib-check-1',
        clear: 'mib-close',
        add: 'mib-add-square-alternate',
        upload: 'mib-cloud-upload',
        removeQueue: 'mib-close',
        removeUploaded: 'mib-check-double-1',
    },
}
