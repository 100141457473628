<template>
    <!-- TODO improvement @MTR: primary-button:hover color -->
    <!--
    <page-loading-indicator
        v-if="state.isLoading"
        :number-of-breadcrumb-elements="1"
    />
    <page-wrapper v-else :inner-loading="state.isPrinting">
    -->
    <page-wrapper :inner-loading="state.isPrinting">
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('common.mail-processing.mail-processing')" :to="{ name: 'correspondence-mail-processing-generate-cover-letter' }" />
            </template>

            <h1>{{ $t('common.mail-processing.mail-processing') }}</h1>
        </page-header>

        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="state.formErrorMessage"
            type="negative"
            data-test="error:global-message"
            class="q-mb-xs"
            v-html="state.formErrorMessage"
        />
        <!-- eslint-enable -->
        <info-box v-if="state.hasPrintNodeError" type="negative"><p>{{ $t('views.correspondence.mail-processing.print-service-provider-error') }}</p></info-box>
        <div v-else class="row q-col-gutter-md print-row-lg">
            <grid-card
                :loading="state.isLoading || state.isPrinting"
                :columns="{
                    'xs': 12,
                    'sm': 12,
                    'md': 12,
                    'lg': 12,
                    'xl': 12,
                }"
                :style="state.isLoading ? 'height: 13.6rem;' : false"
            >
                <div v-if="!state.isLoading" class="row q-col-gutter-md">
                    <div class="col-xs-12 col-md-12 col-lg-4 col-xl-3">
                        <h2 class="text-h4">{{ $tc('common.term.target-object', 1) }}</h2>

                        <template v-if="!state.targetObject">
                            <target-object-search
                                ref="targetObjectSearch"
                                :error="v$.targetObject.$error"
                                required
                                @open-search-result="setTargetObject"
                            >
                                <template v-slot:error>
                                    <div v-if="v$.targetObject.$error">{{ $tc('common.error-message.required', 1, { field: $tc('common.term.target-object', 1) }) }}</div>
                                </template>
                            </target-object-search>
                        </template>
                        <div v-else>
                            <!-- CONTRACT -->
                            <template v-if="state.targetObject.targetObjectType === TargetObjectType.CONTRACT">
                                <fake-input-field
                                    clearable
                                    class="q-mb-sm"
                                    @clear="clearTargetObject"
                                >
                                    {{ state.targetObject.currentContractNumber }}
                                </fake-input-field>

                                <p><router-link :to="{ name: 'contract-detail', params: { contactId: state.targetObject.customer.id, id: state.targetObject.id }}">{{ $tc('views.correspondence.mail-processing.go-to-contract', 1) }}</router-link></p>
                            </template>

                            <!-- APPLICATION -->
                            <template v-else-if="state.targetObject.targetObjectType === TargetObjectType.APPLICATION">
                                <fake-input-field
                                    clearable
                                    class="q-mb-sm"
                                    @clear="clearTargetObject"
                                >
                                    {{ state.targetObject.formattedNumber }}
                                </fake-input-field>

                                <p><router-link :to="{ name: 'application-detail', params: { contactId: state.targetObject.customer.id, id: state.targetObject.id }}">{{ $tc('views.correspondence.mail-processing.go-to-application', 1) }}</router-link></p>
                            </template>

                            <!-- CONTACT -->
                            <template v-else-if="state.targetObject.targetObjectType === TargetObjectType.CONTACT">
                                <fake-input-field
                                    clearable
                                    class="q-mb-sm"
                                    @clear="clearTargetObject"
                                >
                                    {{ state.targetObject.getContactName() }}
                                </fake-input-field>

                                <p><router-link :to="{ name: 'contact-detail', params: { id: state.targetObject.id }}">{{ $tc('views.correspondence.mail-processing.go-to-contact', 1) }}</router-link></p>

                                <template v-if="state.targetObject.consultants.length > 1">
                                    <info-box :type="state.consultant ? 'info' : 'warning'">
                                        <p>{{ $tc('views.correspondence.mail-processing.target-object-has-multiple-consultants', 1) }}</p>
                                    </info-box>
                                    <base-select-filter-next
                                        ref="consultantSelect"
                                        v-model="state.consultant"
                                        :options="state.targetObject.consultants.map(consultant => ({ id: consultant.id, label: consultant.getContactName() }))"
                                        :label="$tc('views.correspondence.mail-processing.please-select-consultant', 1)"
                                        required
                                        :error="v$.consultant.$error"
                                    >
                                        <template v-slot:error>
                                            <div v-if="v$.consultant.$error">{{ $tc('common.error-message.required', 1, { field: $tc('common.contact.consultant', 1) }) }}</div>
                                        </template>
                                    </base-select-filter-next>
                                </template>
                            </template>
                        </div>
                    </div>

                    <div class="col-xs-12 col-md-12 col-lg-4 col-xl-6">
                        <h2 class="text-h4 required">{{ $tc('views.correspondence.mail-processing.cover-letter', 1) }}</h2>

                        <div class="row">
                            <div class="col-xs-12">
                                <div class="mail-processing-template-selection-wrapper">
                                    <template v-if="filteredMailProcessingTemplates.length">
                                        <q-option-group
                                            v-model="state.mailProcessingTemplateId"
                                            :options="filteredMailProcessingTemplates"
                                            class="mail-processing-template-selection"
                                            color="primary"
                                            dense
                                        />
                                        <small v-if="v$.mailProcessingTemplateId.$error" class="error-hint">{{ $tc('common.error-message.required', 1, { field: $tc('views.correspondence.mail-processing.cover-letter', 1) }) }}</small>
                                    </template>
                                    <template v-else>
                                        <p v-if="!state.targetObject" class="additional-info q-mt-md">{{ $tc('views.correspondence.mail-processing.please-select-target-object', 1) }}</p>
                                        <info-box v-else type="warning">{{ $t('views.correspondence.mail-processing.no-cover-letters-available') }}</info-box><!-- TODO improvement @MTR: Differentiate based on `MailProcessingSettings:manage` permission (link to MailProcessingTemplate manage view). -->
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-12 col-md-12 col-lg-4 col-xl-3">
                        <div class="row q-col-gutter-md">
                            <div class="col-xs-12">
                                <h2 class="text-h4">{{ $tc('forms.field.label.mail-processing.printer', 1) }}</h2>
                                <base-select-filter-next
                                    ref="printerSelect"
                                    v-model="state.printerId"
                                    :label="$tc('forms.field.label.mail-processing.printer', 1)"
                                    :options="state.printers"
                                    option-value="id"
                                    :option-label="getPrinterSelectOptionLabel"
                                    :use-chips="false"
                                    clearable
                                    required
                                    :error="v$.printerId.$error"
                                >
                                    <template v-slot:error>
                                        <div v-if="v$.printerId.$error">{{ $tc('common.error-message.required', 1, { field: $tc('forms.field.label.mail-processing.printer', 1) }) }}</div>
                                    </template>
                                </base-select-filter-next>
                            </div>

                            <div v-if="state.selectedPrinter && state.selectedPrinter.status !== PrinterStatus.ONLINE" class="col-xs-12">
                                <info-box type="warning" no-margin>
                                    <i18n :path="`views.correspondence.mail-processing.printer-status.${kebabCase(state.selectedPrinter.status)}`">
                                        <template v-slot:printer><b>{{ state.selectedPrinter.name }}</b> <span class="additional-info">({{ state.selectedPrinter.computer_name }})</span></template>
                                    </i18n>
                                </info-box>
                            </div>

                            <div class="col-xs-12 q-gutter-x-md">
                                <base-button
                                    ref="printButton"
                                    :label="$t('common.print.print')"
                                    primary-button
                                    class="do-not-print"
                                    :disable="state.isPrinting"
                                    @click="print"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </grid-card>

            <!-- CONTRACT -->
            <!-- TODO improvement: Check if this part could/should be moved to a separate component as it is almost the same as on the contract detail view. -->
            <div v-if="state.targetObject?.targetObjectType === TargetObjectType.CONTRACT" class="col-xs-12 q-mt-sm">
                <h3>
                    {{ state.targetObject.currentContractNumber }}
                    <span class="subtitle">({{ state.targetObject.currentContractInformation.product.basicProvider.display_name || state.targetObject.currentContractInformation.product.basicProvider.name }})</span>
                    <contract-status-badge :contract="state.targetObject" />
                </h3>

                <info-box
                    v-if="state.targetObject.currentContractInformation.managed_by_status !== ContractManagedByStatus.INTERNAL"
                    :type="([ContractManagedByStatus.EXTERNAL, ContractManagedByStatus.CONSULTING_MANDATE_CANCELLATION_PENDING]).includes(state.targetObject.currentContractInformation.managed_by_status) ? 'warning' : 'info'"
                    :icon="state.targetObject.currentContractInformation.managed_by_status === ContractManagedByStatus.EXTERNAL ? 'mib-information-circle': null"
                    data-test="info:contract-managed-by-status"
                >
                    <p><component :is="state.targetObject.currentContractInformation.managed_by_status === ContractManagedByStatus.EXTERNAL ? 'strong' : 'span'">{{ $tc(`common.contract.managed-by-status.${kebabCase(state.targetObject.currentContractInformation.managed_by_status)}--info`, 1, { date: state.targetObject.currentContractInformation.formattedConsultingMandateEnquirySendDate }) }}</component></p>
                </info-box>

                <info-box
                    v-if="state.targetObject.currentCancellation"
                    type="warning"
                    :inline-actions="$q.screen.width > 1300"
                    data-test="info:contract-cancellation"
                >
                    <strong>{{ $tc('common.contract.cancellation-info--contract-cancelled-per', 1, { cancellationDate: state.targetObject.currentCancellation.formattedCancellationDate }) }}</strong> {{ $tc(`common.contract.cancellation-info--status-${state.targetObject.currentCancellation.status.toLowerCase()}`, 1) }}
                </info-box>

                <info-box
                    v-if="state.targetObject.currentChangeApplication"
                    inline-actions
                >
                    <i18n path="common.contract.change-application-info--number" tag="p">
                        <template v-slot:number><b>{{ state.targetObject.currentChangeApplication.formattedNumber }}</b></template>
                    </i18n>

                    <template v-slot:action>
                        <base-button
                            :to="{ name: 'application-detail', params: { contactId: state.targetObject.customer.id, id: state.targetObject.currentChangeApplication.id } }"
                            :label="$tc('common.application.go-to-application', 1)"
                            outline
                            primary-button
                        />
                    </template>
                </info-box>

                <div class="row print-row-lg q-col-gutter-md entity-info-wrapper">
                    <grid-card
                        :columns="{
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 4,
                            xl: 4,
                        }"
                        class="entity-info contact-info"
                        data-test="wrapper:contact-info"
                    >
                        <address data-test="wrapper:contact-main-address">
                            {{ state.targetObject.customer.getContactName({ salutation: true, title: true }) }}<br>
                            {{ state.targetObject.customer.getContactAddress(state.targetObject.customer.mainAddress, { multiline: true }) }}
                        </address>
                    </grid-card>

                    <grid-card
                        :columns="{
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 8,
                            xl: 8,
                        }"
                        class="entity-info contract-info"
                        data-test="wrapper:contract-info"
                    >
                        <dl class="q-mb-none">
                            <dt>{{ $tc('common.term.product', 1) }}: </dt>
                            <dd data-test="text:contract-product-name">{{ state.targetObject.currentContractInformation.product.name }}</dd>
                            <br>

                            <dt>{{ $tc('common.product.product-provider', 1) }}: </dt>
                            <dd data-test="text:contract-provider-name">{{ state.targetObject.currentContractInformation.product.providerName }}</dd>
                            <br>

                            <template v-if="state.targetObject.currentContractInformation.generalAgency">
                                <dt>{{ $tc('common.term.general-agency', 1) }}: </dt>
                                <dd data-test="text:contract-general-agency">{{ state.targetObject.currentContractInformation.generalAgency.company_name }}</dd>
                                <br>
                            </template>

                            <dt>{{ $tc('common.contact.consultant', 1) }}: </dt>
                            <dd data-test="text:contract-consultant">{{ state.targetObject.consultant.getContactName({ consultingCompanyName: !state.targetObject.consultant.worksForUserConsultingCompany }) }}</dd>
                            <br>

                            <dt>{{ $tc('views.contract.settings.commission.commission-recipient-override.commission-recipient', 1) }}: </dt>
                            <dd
                                data-test="text:contract-commission-recipient"
                                :class="[{ 'additional-info': !state.targetObject.commissionRecipientOverride }]"
                            >{{ state.targetObject.commissionRecipientOverride ? state.targetObject.commissionRecipientOverride.getContactName({ consultingCompanyName: !state.targetObject.commissionRecipientOverride.worksForUserConsultingCompany }) : state.targetObject.consultant.getContactName({ consultingCompanyName: !state.targetObject.consultant.worksForUserConsultingCompany }) }}
                                <info-icon
                                    v-if="state.targetObject.commissionRecipientOverride"
                                    :text="$t('views.contract.settings.commission.commission-recipient-override.contract-has-override-text')"
                                    class="q-ml-xs"
                                />
                            </dd>
                            <br>

                            <dt>{{ $tc('common.contract.managed-by-status.managed-by-status', 1) }}: </dt>
                            <dd data-test="text:contract-managed-by-status">{{ $tc(`common.contract.managed-by-status.${kebabCase(state.targetObject.currentContractInformation.managed_by_status)}`, 1) }}</dd>
                            <br>
                        </dl>

                        <dl class="q-mb-none">
                            <dt>{{ $t('common.contract.contract-start') }}: </dt>
                            <dd data-test="text:contract-start" :class="getFormattedFieldValue(state.targetObject.currentContractInformation.start_date).cssClass">{{ getFormattedFieldValue(state.targetObject.currentContractInformation.formattedStartDate).value }}</dd>
                            <br>

                            <dt>{{ $t('common.contract.contract-end') }}: </dt>
                            <dd data-test="text:contract-end" :class="getFormattedFieldValue(state.targetObject.currentContractInformation.end_date).cssClass">{{ getFormattedFieldValue(state.targetObject.currentContractInformation.formattedEndDate).value }}</dd>
                            <br>

                            <dt v-if="state.targetObject.termination_date">{{ $t('common.contract.termination-date') }}: </dt>
                            <dd v-if="state.targetObject.termination_date" data-test="text:contract-termination">{{ state.targetObject.formattedTerminationDate }}</dd>
                            <br v-if="state.targetObject.termination_date">

                            <dt>{{ $t('common.contract.premium') }}: </dt>
                            <dd data-test="text:contract-premium" :class="getFormattedFieldValue(state.targetObject.currentContractInformation.premium).cssClass">{{ getFormattedFieldValue(state.targetObject.currentContractInformation.formattedPremium).value }}</dd>
                        </dl>
                    </grid-card>
                </div>
            </div>

            <!-- APPLICATION -->
            <!-- TODO improvement: Check if this part could/should be moved to a separate component as it is almost the same as on the application detail view. -->
            <div v-else-if="state.targetObject?.targetObjectType === TargetObjectType.APPLICATION" class="col-xs-12 q-mt-sm">
                <h3>
                    {{ state.targetObject.formattedNumber }}
                    <span class="subtitle">({{ state.targetObject.currentContractInformation.product.basicProvider.display_name || state.targetObject.currentContractInformation.product.basicProvider.name }})</span>
                    <status-badge status="application" default-translation-base-path="common.status.application-status" />
                    <contract-status-badge :contract="state.targetObject" default-translation-base-path="common.status.application-status" />
                </h3>

                <template v-if="state.targetObject.statusInfo.text">
                    <div class="col-xs-12">
                        <info-box
                            :type="state.targetObject.statusInfo.type"
                            :inline-actions="$q.screen.width > 1300"
                            data-test="info:application-status-info-text"
                        >
                            <p>{{ state.targetObject.statusInfo.text }} <template v-if="state.targetObject.targetContract?.currentContractNumber"><br>({{ $tc('common.contract.contract-number', 1) }}: <b>{{ state.targetObject.targetContract.currentContractNumber }}</b>)</template></p>

                            <template v-slot:action>
                                <base-button
                                    v-if="state.targetObject.targetContract"
                                    :to="{ name: 'contract-detail', params: { contactId: state.targetObject.customer.id, id: state.targetObject.targetContract.id } }"
                                    :label="$tc('common.application.go-to-contract', 1)"
                                    outline
                                    primary-button
                                />
                            </template>
                        </info-box>
                    </div>
                </template>

                <div class="row print-row-lg q-col-gutter-md entity-info-wrapper">
                    <grid-card
                        :columns="{
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 4,
                            xl: 4,
                        }"
                        class="entity-info contact-info"
                        data-test="wrapper:contact-info"
                    >
                        <address data-test="wrapper:contact-main-address">
                            {{ state.targetObject.customer.getContactName({ salutation: true, title: true }) }}<br>
                            {{ state.targetObject.customer.getContactAddress(state.targetObject.customer.mainAddress, { multiline: true }) }}
                        </address>
                    </grid-card>

                    <grid-card
                        :columns="{
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 8,
                            xl: 8,
                        }"
                        class="entity-info application-info"
                        data-test="wrapper:application-info"
                    >
                        <dl class="q-mb-none">
                            <dt>{{ $tc('common.term.product', 1) }}: </dt>
                            <dd data-test="text:contract-product-name">{{ state.targetObject.currentContractInformation?.product.name }}</dd>
                            <br>

                            <dt>{{ $tc('common.product.product-provider', 1) }}: </dt>
                            <dd data-test="text:contract-provider-name">{{ state.targetObject.currentContractInformation?.product.providerName }}</dd>
                            <br>

                            <dt>{{ $tc('common.contact.consultant', 1) }}: </dt>
                            <dd data-test="text:contract-consultant">{{ state.targetObject.consultant.getContactName({ consultingCompanyName: !state.targetObject.consultant.worksForUserConsultingCompany }) }}</dd>
                            <br>

                            <dt>{{ $tc('views.contract.settings.commission.commission-recipient-override.commission-recipient', 1) }}: </dt>
                            <dd data-test="text:contract-commission-recipient" :class="[{ 'additional-info': !state.targetObject.commissionRecipientOverride }]">{{ state.targetObject.commissionRecipientOverride ? state.targetObject.commissionRecipientOverride.getContactName({ consultingCompanyName: !state.targetObject.commissionRecipientOverride.worksForUserConsultingCompany }) : state.targetObject.consultant.getContactName({ consultingCompanyName: !state.targetObject.consultant.worksForUserConsultingCompany }) }}
                                <info-icon
                                    v-if="state.targetObject.commissionRecipientOverride"
                                    :text="$t('views.contract.settings.commission.commission-recipient-override.contract-has-override-text')"
                                    class="q-ml-xs"
                                />
                            </dd>
                        </dl>

                        <dl class="q-mb-none">
                            <dt>{{ $t('common.contract.contract-start') }}: </dt>
                            <dd data-test="text:contract-start" :class="getFormattedFieldValue(state.targetObject.currentContractInformation?.start_date).cssClass">{{ getFormattedFieldValue(state.targetObject.currentContractInformation?.formattedStartDate).value }}</dd>
                            <br>

                            <dt>{{ $t('common.contract.contract-end') }}: </dt>
                            <dd data-test="text:contract-end" :class="getFormattedFieldValue(state.targetObject.currentContractInformation?.end_date).cssClass">{{ getFormattedFieldValue(state.targetObject.currentContractInformation?.formattedEndDate).value }}</dd>
                            <br>

                            <dt v-if="state.targetObject.termination_date">{{ $t('common.contract.termination-date') }}: </dt>
                            <dd v-if="state.targetObject.termination_date" data-test="text:contract-termination">{{ state.targetObject.formattedTerminationDate }}</dd>
                            <br v-if="state.targetObject.termination_date">

                            <dt>{{ $t('common.contract.premium') }}: </dt>
                            <dd data-test="text:contract-premium" :class="getFormattedFieldValue(state.targetObject.currentContractInformation?.premium).cssClass">{{ getFormattedFieldValue(state.targetObject.currentContractInformation?.formattedPremium).value }}</dd>
                        </dl>

                        <dl v-if="state.targetObject.sourceContract || state.targetObject.on_hold_until || state.targetObject.submission_send_date || state.targetObject.targetContract" class="q-mb-none">
                            <template v-if="state.targetObject.sourceContract">
                                <dt>{{ $tc('common.application.change-application-for', 1) }}: </dt>
                                <dd><router-link :to="{ name: 'contract-detail', params: { contactId: state.targetObject.customer.id, id: state.targetObject.sourceContract.id } }">{{ state.targetObject.sourceContract.currentContractNumber }}</router-link></dd>
                                <br>
                            </template>

                            <template v-if="state.targetObject.on_hold_until">
                                <dt>{{ $t('common.application.on-hold-until') }}: </dt>
                                <dd :class="{ 'is-overdue': state.targetObject.statusInfo.isOverdue }" data-test="text:on-hold-until">{{ state.targetObject.formattedOnHoldUntil }}</dd>
                                <br v-if="state.targetObject.submission_send_date">
                            </template>

                            <template v-if="state.targetObject.submission_send_date">
                                <dt>{{ $t('common.application.submission-send-date') }}: </dt>
                                <dd :class="{ 'additional-info': !state.targetObject.formattedSubmissionSendDate, 'is-overdue' : !state.targetObject.formattedSubmissionResponseDate && state.targetObject.statusInfo.isOverdue }" data-test="text:submission-send-date">{{ state.targetObject.formattedSubmissionSendDate }}</dd>
                                <br>

                                <dt>{{ state.targetObject.statusInfo.submissionResponseDateLabel }}: </dt>
                                <dd :class="{ 'additional-info': !state.targetObject.formattedSubmissionResponseDate, 'is-overdue' : state.targetObject.formattedSubmissionResponseDate && state.targetObject.statusInfo.isOverdue }" data-test="text:submission-response-date">{{ state.targetObject.formattedSubmissionResponseDate || '–' }}</dd>
                            </template>
                        </dl>
                    </grid-card>
                </div>
            </div>

            <!-- CONTACT -->
            <!-- TODO improvement: Check if this part could/should be moved to a separate component as it similar to the contact detail view. -->
            <div v-else-if="state.targetObject?.targetObjectType === TargetObjectType.CONTACT" class="col-xs-12 q-mt-sm">
                <h3>
                    {{ state.targetObject.getContactName() }}
                    <span class="subtitle">{{ state.targetObject.getContactName({ contactNumber: true, noFirstName: true, noLastName: true }) }}</span>
                    <status-badge :status="state.targetObject.status" />
                    <contact-type-badges :contact="state.targetObject" show-system-owner />
                    <status-badge v-if="state.targetObject.is_deceased" status="deceased" />
                </h3>

                <div class="row print-row-lg q-col-gutter-md entity-info-wrapper">
                    <grid-card
                        :columns="{
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 4,
                            xl: 4,
                        }"
                        class="entity-info contact-info"
                        data-test="wrapper:contact-info"
                    >
                        <div class="contact-info--main-address">
                            <address data-test="wrapper:contact-main-address">
                                {{ state.targetObject.getContactName({ salutation: true, title: true }) }}<br>
                                {{ state.targetObject.getContactAddress(state.targetObject.mainAddress, { multiline: true }) }}
                            </address>
                        </div>
                    </grid-card>

                    <grid-card
                        :columns="{
                            xs: 12,
                            sm: 6,
                            md: 6,
                            lg: 8,
                            xl: 8,
                        }"
                        class="entity-info contact-info--details"
                        data-test="wrapper:contact-info-details"
                    >
                        <dl class="q-mb-none">
                            <template v-if="state.targetObject.type === ContactType.PERSON">
                                <dt>{{ $tc('common.contact.birthday', 1) }}: </dt>
                                <!-- TODO: Add countdown to birthday when its less than 1 week away: (Turns {n} in {x} days.) -->
                                <!-- TODO: Add highlighting when birthday == today: (Turns {n} today.) -->
                                <dd v-if="state.targetObject.date_of_birth" data-test="text:contact-person-birthdate">{{ state.targetObject.formattedDateOfBirth }}
                                    <span v-if="!state.targetObject.is_deceased" class="additional-info" data-test="text:contact-person-age">({{ $tc('common.contact.age--n-years-old', 2, { age: state.targetObject.age }) }})</span>
                                </dd>
                                <dd v-else class="additional-info">{{ $t('common.term.not-provided--alt') }}</dd>

                                <template v-if="state.targetObject.is_deceased">
                                    <br>
                                    <dt>{{ $t('common.contact.deceased-on') }}: </dt>
                                    <dd v-if="state.targetObject.date_of_death">{{ state.targetObject.formattedDateOfDeath }}
                                        <span class="additional-info">
                                            († {{ $tc('common.contact.age--n-years-old', 2, { age: state.targetObject.getAge(state.targetObject.date_of_death) }) }})
                                        </span>
                                    </dd>
                                    <dd v-else class="additional-info">{{ $t('common.term.not-provided--alt') }}</dd>
                                </template>
                                <br>
                            </template>

                            <template v-else>
                                <dt>{{ $t('common.contact.uid') }}: </dt>
                                <dd :class="getFormattedFieldValue(state.targetObject.uid).cssClass" data-test="text:contact-company-uid">{{ getFormattedFieldValue(state.targetObject.uid).value }}</dd>
                                <br>
                            </template>

                            <dt>{{ $tc('common.contact.consultant', 2) }}: </dt>
                            <dd data-test="text:contact-consultants">
                                <div v-for="(consultant, index) in state.targetObject.consultants" :key="`consultant-${index}`">{{ consultant.getContactName() }} <span v-if="!consultant.worksForUserConsultingCompany" class="additional-info">({{ consultant.consultingCompanyName }})</span></div>
                            </dd>
                        </dl>

                        <dl class="q-mb-none">
                            <dt>{{ $t('common.contact.customer-status.title') }}: </dt>
                            <dd :class="getFormattedFieldValue(state.targetObject.customer_status).cssClass" data-test="text:contact-status">{{ getFormattedFieldValue(state.targetObject.customer_status, { defaultTranslationBasePath: 'common.contact.customer-status' }).value }}</dd>
                            <br>

                            <dt>{{ $t('common.contact.customer-since') }}: </dt>
                            <dd :class="getFormattedFieldValue(state.targetObject.formattedCustomerSince).cssClass" data-test="text:contact-customer-since">{{ getFormattedFieldValue(state.targetObject.formattedCustomerSince).value }}</dd>
                            <br>

                            <dt>{{ $t('common.contact.customer-until') }}: </dt>
                            <dd :class="getFormattedFieldValue(state.targetObject.formattedCustomerUntil).cssClass">{{ getFormattedFieldValue(state.targetObject.formattedCustomerUntil).value }}</dd>
                            <br>
                        </dl>
                    </grid-card>
                </div>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { fetchPaginatedObjects } from '@/helpers'
import { MailProcessingTemplate } from '@/models/mailProcessingTemplate'
import { MailProcessingService } from '@/services'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import { computed, nextTick, onBeforeMount, reactive, ref, watch } from 'vue'
import { kebabCase } from 'lodash'
import { useStore } from '@/composables/store'
import { useQuasar } from '@/composables/quasar'
import { useI18n } from '@/composables/i18n'
import { TargetObjectType, PrinterStatus } from '@/enums'
import { ContactType, ContractManagedByStatus } from '@/enums/graphql'
import { UserSettings } from '@/helpers/user'
import { extractErrorMessage, getFormattedFieldValue } from '@/helpers/form'
import TargetObjectSearch from '@/components/search/TargetObjectSearch'
import BaseSelectFilterNext from '@/components/form/BaseSelectFilterNext'
import FakeInputField from '@/components/FakeInputField'
import StatusBadge from '@/components/StatusBadge'
import ContractStatusBadge from '@/components/contract/ContractStatusBadge'
import ContactTypeBadges from '@/components/contact/ContactTypeBadges'
import { PrintService } from '@/services/print'
import BaseBadge from '@/components/BaseBadge.vue'

export default {
    name: 'MailProcessingGenerateCoverLetter',
    meta () {
        return {
            title: this.$t('common.mail-processing.mail-processing'),
        }
    },
    components: {
        BaseBadge,
        TargetObjectSearch,
        BaseSelectFilterNext,
        FakeInputField,
        StatusBadge,
        ContractStatusBadge,
        ContactTypeBadges,
    },
    setup () {
        // Composables
        const store = useStore()
        const $q = useQuasar()
        const { tc } = useI18n()

        // User settings
        const userSettings = new UserSettings(store.state.user)

        // Data
        const state = reactive({
            isLoading: true,
            formErrorMessage: '',
            hasPrintNodeError: false,
            targetObject: null,
            consultant: null,
            mailProcessingTemplates: [],
            mailProcessingTemplateId: null,
            selectedMailProcessingTemplate: null,
            printers: [],
            printerId: null,
            selectedPrinter: null,
            isPrinting: false,
        })

        // Template refs
        const targetObjectSearch = ref(null)
        const consultantSelect = ref(null)
        const printerSelect = ref(null)
        const printButton = ref(null)

        // Validation
        const v$ = useVuelidate({
            targetObject: { required, $autoDirty: true },
            consultant: { requiredIf: requiredIf(() => state.targetObject?.consultants?.length > 1), $autoDirty: true },
            mailProcessingTemplateId: { required, $autoDirty: true },
            printerId: { required, $autoDirty: true },
        }, state)

        // Computed
        const filteredMailProcessingTemplates = computed(() => {
            return state.mailProcessingTemplates.filter(mailProcessingTemplate => mailProcessingTemplate.target_object_type === state.targetObject?.targetObjectType)
        })
        const mailProcessingItemName = computed(() => {
            let mailProcessingItemName = []

            if (state.targetObject && state.selectedMailProcessingTemplate) {
                switch (state.targetObject.targetObjectType) {
                    case TargetObjectType.CONTACT:
                        mailProcessingItemName.push(state.targetObject.getContactName())
                        break
                    case TargetObjectType.APPLICATION:
                        mailProcessingItemName.push(state.targetObject.formattedNumber)
                        break
                    case TargetObjectType.CONTRACT:
                        mailProcessingItemName.push(`${state.targetObject.currentContractNumber} (${state.targetObject.currentContractInformation.product.basicProvider.display_name || state.targetObject.currentContractInformation.product.basicProvider.name})`)
                        break
                }
                mailProcessingItemName.push(state.selectedMailProcessingTemplate.name)
            }

            return state.mailProcessingItemName = mailProcessingItemName.join(' – ')
        })

        // Watchers
        /// TargetObject
        watch(() => state.targetObject, () => {
            setAutofocus()
        })
        /// Consultant
        watch(() => state.consultant, () => {
            setAutofocus()
        })
        /// MailProcessingTemplate
        watch(() => state.mailProcessingTemplateId, () => {
            if (state.mailProcessingTemplateId) {
                userSettings.setItem(`MailProcessingGenerateCoverLetter.${state.targetObject.targetObjectType}.selectedMailProcessingTemplate`, state.mailProcessingTemplateId)
                state.selectedMailProcessingTemplate = getMailProcessingTemplate(state.mailProcessingTemplateId)
                setAutofocus()
            }
        })
        watch(() => filteredMailProcessingTemplates.value, () => {
            const selectedMailProcessingTemplateId = userSettings.getItem(`MailProcessingGenerateCoverLetter.${state.targetObject?.targetObjectType}.selectedMailProcessingTemplate`)
            if (selectedMailProcessingTemplateId && getMailProcessingTemplate(selectedMailProcessingTemplateId)) state.mailProcessingTemplateId = selectedMailProcessingTemplateId
        })
        /// Printer
        watch(() => state.printerId, () => {
            if (state.printerId) {
                userSettings.setItem(`MailProcessingGenerateCoverLetter.selectedPrinter`, state.printerId)
                state.selectedPrinter = state.printers.find(printer => printer.id === state.printerId) || null
            } else {
                userSettings.removeItem(`MailProcessingGenerateCoverLetter.selectedPrinter`)
                state.selectedPrinter = null
            }
            setAutofocus()
        })

        // Functions
        function showFormErrorMessage (error) {
            state.formErrorMessage = extractErrorMessage(error)
        }
        function resetFormErrorMessage () {
            state.formErrorMessage = ''
        }
        async function fetchObjects () {
            // MailProcessingTemplates
            const mailProcessingTemplates = await fetchPaginatedObjects({ fn: MailProcessingTemplate.objects.all })
            state.mailProcessingTemplates = mailProcessingTemplates.map(mailProcessingTemplate => {
                return Object.assign(mailProcessingTemplate, {
                    label: mailProcessingTemplate.name,
                    value: mailProcessingTemplate.id,
                })
            })

            // Printers
            try {
                state.printers = await PrintService.getPrinters({ ids: null }) // TODO @TFU: Remove ids parameter after bug in backend has been fixed.
                const selectedPrinterId = userSettings.getItem(`MailProcessingGenerateCoverLetter.selectedPrinter`)
                if (selectedPrinterId && state.printers.find(printer => printer.id === selectedPrinterId)) state.printerId = selectedPrinterId
            } catch (e) {
                state.hasPrintNodeError = true
            }

            // Toggle loading state
            state.isLoading = false

            // Set focus
            if (!state.hasPrintNodeError) setAutofocus()
        }

        function setTargetObject (targetObject) {
            state.mailProcessingTemplateId = null
            state.targetObject = targetObject
            state.consultant = null // Clear previously selected Consultant.
        }

        async function setAutofocus () {
            await nextTick()
            if (v$.value.targetObject.$invalid) return targetObjectSearch.value.focus()
            if (v$.value.consultant.$invalid) return consultantSelect.value.focus()
            if (v$.value.printerId.$invalid) return printerSelect.value.focus()
            printButton.value.focus()
        }

        function clearTargetObject () {
            state.targetObject = null
            state.consultant = null
            state.mailProcessingTemplateId = null
            v$.value.$reset()
        }

        function getMailProcessingTemplate (id) {
            return state.mailProcessingTemplates.find(mailProcessingTemplate => mailProcessingTemplate.id === id) || null
        }

        function getPrinterSelectOptionLabel (option) {
            return `<span class="printer-status-indicator q-mr-xs ${option.status === PrinterStatus.ONLINE ? 'text-positive' : 'text-negative'}" role="presentation">●</span> ${option.name} <small class="additional-info">(${option.computer_name})</small>`
        }

        async function print () {
            const isValid = await v$.value.$validate()
            if (isValid) {
                resetFormErrorMessage()
                state.isPrinting = true

                const notification = $q.notify({
                    group: false, // required to be updatable
                    timeout: 0, // we want to be in control when it gets dismissed
                    spinner: true,
                    message: tc('views.correspondence.mail-processing.cover-letter-printed--printing', 1),
                    caption: mailProcessingItemName.value,
                    multiLine: true,
                    attrs: { 'data-test': 'notification:printing-in-progress' },
                })

                const generateCoverLetterVariables = {
                    mailProcessingTemplateId: state.mailProcessingTemplateId,
                    targetObjectId: state.targetObject.id,
                    printerId: state.printerId,
                }
                if (state.targetObject.targetObjectType === TargetObjectType.CONTACT) {
                    if (state.targetObject.consultants.length > 1) {
                        generateCoverLetterVariables.consultantId = state.consultant.id
                    } else {
                        generateCoverLetterVariables.consultantId = state.targetObject.consultants.at(0).id
                    }
                }
                MailProcessingService.generateCoverLetter(generateCoverLetterVariables).then(() => {
                    state.isPrinting = false
                    clearTargetObject()

                    notification({
                        type: 'positive',
                        spinner: false, // we reset the spinner setting so the icon can be displayed
                        message: tc('views.correspondence.mail-processing.cover-letter-printed--success', 1),
                        timeout: 3000, // we will time out it in x
                        attrs: { 'data-test': 'notification:printing-success' },
                    })
                }).catch(error => {
                    state.isPrinting = false
                    showFormErrorMessage(error)

                    notification({
                        type: 'negative',
                        spinner: false, // we reset the spinner setting so the icon can be displayed
                        message: tc('views.correspondence.mail-processing.cover-letter-printed--error', 1),
                        timeout: 6000, // we will time out it in x
                        attrs: { 'data-test': 'notification:printing-error' },
                    })
                })
            }
        }

        // Lifecycle hooks
        onBeforeMount(() => {
            fetchObjects()
        })

        return {
            // Data
            state,
            PrinterStatus,
            TargetObjectType,
            ContactType,
            ContractManagedByStatus,

            // Template refs
            targetObjectSearch,
            consultantSelect,
            printerSelect,
            printButton,

            // Validation
            v$,

            // Computed
            filteredMailProcessingTemplates,

            // Functions
            setTargetObject,
            clearTargetObject,
            print,
            kebabCase,
            getFormattedFieldValue,
            getPrinterSelectOptionLabel,
        }
    },
}
</script>

<style lang="scss" scoped>
.mail-processing-template-selection {
    column-count: 2;

    @each $breakpoint, $numberOfColumns in (
        $sizeBreakpointFormBuilderMd: 3,
        $sizeBreakpointMd: 2,
        '1200px': 3,
        '1400px': 4,
        $sizeBreakpointLg: 1,
        $sizeBreakpointXl: 2,
    ) {
        @media screen and (min-width: #{$breakpoint}) {
            column-count: $numberOfColumns;
        }
    }
}

dd {
    &.is-overdue {
        color: var(--q-color-negative-dark);
    }
}

.error-hint {
    color: var(--q-color-negative);
    font-weight: normal;
}
</style>
