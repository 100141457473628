import { apolloClient } from '@/apollo/client'
import { PRINTERS_QUERY } from '@/graphql/print/printers'
import { PRINT_JOB_CREATE_MUTATION } from '@/graphql/print/printJobCreate'

export const PrintService = {
    getPrinters (variables) {
        return apolloClient.query({
            query: PRINTERS_QUERY,
            variables,
        }).then(response => response.data.printers)
    },
    createPrintJob (variables) {
        return apolloClient.mutate({
            mutation: PRINT_JOB_CREATE_MUTATION,
            variables,
        }).then(response => response.data.printJobCreate)
    },
}
