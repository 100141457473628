import gql from 'graphql-tag'

export const CORRESPONDENCE_EMAIL_ATTACHMENTS_QUERY = gql`
    query correspondenceEmailAttachments(
        $id: ID!
    ) {
        correspondenceEmail(
            id: $id
        ) {
            id
            attachments {
                id
                name
                size
                mime_type

                created_at
                updated_at
            }
            created_at
            updated_at
        }
    }
`
