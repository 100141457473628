import gql from 'graphql-tag'

export const CONTACT_UPDATE_ENTITY_NUMBER_MUTATION = gql`
    mutation contactUpdateEntityNumber(
        $contactId: ID!
        $valueStructure: Mixed!
    ) {
        contactUpdateEntityNumber(
            contact_id: $contactId
            value_structure: $valueStructure
        ) {
            id
            contactNumber {
                id
                number
                value_structure
            }
        }
    }
`
