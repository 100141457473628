import gql from 'graphql-tag'

export const USER_QUERY = gql`
    query User(
        $id: ID!
    ) {
        user(
            id: $id
        ) {
            id
            email
            new_email
            status
            consultant {
                id
                contact_number
                first_name
                last_name
                gender
                title
                consultingCompany {
                    id
                    contact_number
                    company_name
                    consulting_settings_status
                }
                consulting_company_name
                consulting_settings_status
                created_at
                updated_at
            }
            roles {
                id
                name
                description
            }
        }
    }
`
