<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.accounting.statement.statement', 2)" :to="{ name: 'accounting-statements' }" />
                <q-breadcrumbs-el :label="$tc('common.accounting.statement.create-statement', 2)" :to="{ name: 'accounting-statement-create' }" />
            </template>

            <h1>{{ $tc('common.accounting.statement.create-statement', 2) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <statement-form
                    ref="statementForm"
                    @post-handle-form-data="handleSubmit"
                />
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { Statement } from '@/models/statement'
import { parseFormDataToInput }  from '@/helpers/statement'
import StatementForm from '@/components/accounting/statement/StatementForm.vue'

export default {
    name: 'StatementCreate',
    components: {
        StatementForm,
    },
    methods: {
        handleSubmit ({ formData, consultingEntityIds }) {
            const statementInput = parseFormDataToInput(formData)

            Statement.create({
                consultingEntityIds: consultingEntityIds,
                statementInput: statementInput,
            }).then(() => {
                this.$router.replace({ name: 'accounting-statement-list' })

                this.$q.notify({
                    type: 'positive',
                    message: this.$tc('common.notifications.accounting.statement-created-success', 1),
                })
            }).catch(error => {
                this.$refs.statementForm.showFormErrorMessage(error)
                this.$refs.statementForm.resetFormSubmitStatus()
            })
        },
    },
}
</script>
