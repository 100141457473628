<template>
    <div
        :class="['markup-table', { 'dense': dense }]"
        role="table"
        :style="`--markup-table-col-count: ${columns.length}; ${gridTemplateColumns}`"
    >
        <div class="markup-table-thead" role="rowgroup">
            <div class="markup-table-tr header" role="row">
                <div
                    v-for="(column) in columns"
                    :id="`markup-table-th-${column.name}`"
                    :key="`header-column-${column.name}`"
                    :class="['markup-table-th', column.align ? `text-${column.align}`: 'text-left', { 'auto-width': column.autoWidth }]"
                    role="columnheader"
                >{{ column.label }}</div>

                <q-linear-progress
                    v-if="loading"
                    class="markup-table-loading-indicator"
                    indeterminate
                    size="xs"
                    color="primary-light"
                />
            </div>
        </div>


        <div
            v-for="(row, index) in data"
            :key="`data-item-${typeof rowKey === 'function' ? rowKey(row) : row[rowKey]}`"
            class="markup-table-tbody"
            role="rowgroup"
        >
            <slot name="body-row" v-bind:row="row" v-bind:rowIndex="index">
                <markup-table-row :columns="columns" :row="row">
                    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotProps">
                        <slot :name="name" v-bind="slotProps" />
                    </template>
                </markup-table-row>
            </slot>
        </div>

        <slot name="afterContent"></slot>
    </div>
</template>

<script>
import MarkupTableRow from '@/components/MarkupTableRow.vue'

export default {
    name: 'MarkupTable',
    components: {
        MarkupTableRow,
    },
    props: {
        dense: {
            type: Boolean,
            default: false,
        },
        columns: {
            type: Array,
            default: () => {
                return []
            },
        },
        data: {
            type: Array,
            default: () => {
                return []
            },
        },
        rowKey: {
            type: [String, Function],
            default: 'id',
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        gridTemplateColumns () {
            const gridTemplateColumns = []

            this.columns.forEach(column => {
                let trackMinWidth = 'max-content'
                let trackMaxWidth = '1fr'

                if (column.autoWidth) {
                    gridTemplateColumns.push('max-content')
                } else {
                    trackMinWidth = column.minWidth || trackMinWidth
                    trackMaxWidth = column.maxWidth || trackMaxWidth
                    gridTemplateColumns.push(`minmax(${trackMinWidth}, ${trackMaxWidth})`)
                }
            })

            return `--markup-table-grid-template-columns: ${gridTemplateColumns.join(' ')};`
        },
    },
}
</script>

<style lang="scss">
.markup-table {
    // Default definition of custom props as fallback values
    --markup-table-col-count: 1;
    --markup-table-grid-template-columns: repeat( var(--markup-table-col-count), 1fr);

    display: grid;
    grid-template-columns: var(--markup-table-grid-template-columns);
    width: 100%;
    min-width: 100%;
    overflow-x: auto;

    // Loading indicator
    &-loading-indicator {
        grid-column-start: 1;
        grid-column-end: -1;
    }
}

.markup-table-thead {
    display: contents;
}

.markup-table-tbody {
    display: contents;

    // Zebra pattern
    &:nth-child(2n+2) { // 2n+1 if no .header should be used
        .markup-table-tr:not(:hover):not(.selected):not(.selected) {
            .markup-table-td:not(.has-error) {
                background: var(--color-background-secondary);
            }
        }

        // Adjust styling of nested form fields
        .q-field__control {
            background-color: var(--color-text-inverted);
        }
    }

    // Adjust styling of nested form fields
    .q-field__control.q-field__control {
        background-color: var(--color-text-inverted);
        outline: 1px solid var(--color-border-primary);
    }
}

.markup-table-tr {
    display: contents;

    // Hover styles
    &:not(.header) {
        &:hover {
            .markup-table-td:not(.has-error) {
                background: var(--q-color-primary-lighter);

                // Adjust styling of nested form fields
                .q-field__control {
                    outline: 1px solid var(--color-border-inverted);
                }
            }
        }
    }

    // Handle (directly) nested elements
    //& > div:not([class*='markup-table-']),
    & > form {
        display: contents;

        // Make child elements use the full width of the row
        & > .info-box {
            grid-column-start: 1;
            grid-column-end: -1;
        }
    }
}

.markup-table-th,
.markup-table-td {
    min-height: 48px;
    padding: ($sizeSpacingMd - 2px) $sizeSpacingSm ($sizeSpacingMd - 3px);

    border-bottom: 1px solid var(--color-border-primary);

    font-size: 1rem;

    &:first-child {
        padding-left: $sizeSpacingMd;
    }

    &:last-child {
        padding-right: $sizeSpacingMd;
    }

    // Dense styles
    .markup-table.dense & {
        min-height: 36px;
        padding: $sizeSpacingSm $sizeSpacingSm ($sizeSpacingSm - 1px);

        &:first-child {
            padding-left: $sizeSpacingMd;
        }

        &:last-child {
            padding-right: $sizeSpacingMd;
        }
    }
}

.markup-table-th {
    font-weight: 500;
    font-size: 12px;
}

// Styling if there’s an error
.markup-table-tr .info-box--negative {
    border-top: 1px solid var(--q-color-negative-light);
    border-right: 1px solid var(--q-color-negative-light);
}

.markup-table-th,
.markup-table-td {

    &.has-error {
        border-bottom: 1px solid var(--q-color-negative-light);
        background-color: var(--q-color-negative-lighter);

        // Adjust styling if it’s the first td after an info-box
        .info-box--negative + & {
            border-left: $sizeSpacingXs solid var(--q-color-negative);
        }

        // Last cell in row
        &:last-child {
            border-right: 1px solid var(--q-color-negative-light);
        }
    }
}


// Styling for specific cells
.markup-table-td {

    // Actions usually only contain flat round buttons
    &--actions {
        // Prevent action buttons from spreading the height and vertically center them
        display: flex;
        align-items: center;
        padding-top: 0;
        padding-bottom: 0;

        .markup-table.dense & {
            padding: ($sizeSpacingSm - 5px) $sizeSpacingSm ($sizeSpacingSm - 1px - 5px);
        }
    }
}
</style>
