<template>
    <form
        data-test="form:correspondence-salutation-create"
        class="row q-col-gutter-sm"
        @submit.prevent="onFormSubmit"
        @keyup.esc="cancel"
    >
        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="formErrorMessage"
            type="negative"
            data-test="error:global-message"
            v-html="formErrorMessage"
        />
        <!-- eslint-enable -->

        <div class="col-xs-6 col-md-3">
            <form-builder item-key="contactType" />
        </div>
        <div class="col-xs-6 col-md-3">
            <form-builder item-key="gender" />
        </div>
        <div class="col-xs-6 col-md-3">
            <form-builder item-key="formality" />
        </div>
        <div class="col-xs-6 col-md-3">
            <form-builder item-key="languageId" />
        </div>
        <div class="col-xs-12">
            <form-builder item-key="salutation" />
        </div>

        <div class="col-xs-12 q-mt-sm do-not-print">
            <base-button
                :label="$t('common.term.cancel')"
                flat
                primary-button
                @click="cancel"
            />
            <base-button
                data-test="btn:submit"
                type="submit"
                :label="$t('common.term.add')"
                :disable="formSubmitStatus"
                :loading="formSubmitStatus"
                primary-button
                class="q-ml-sm"
            />
        </div>
    </form>
</template>

<script>
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import CORRESPONDENCE_SALUTATION_FIELDS from '@/forms/correspondence-salutation.fields'

export default {
    name: 'CorrespondenceSalutationCreateForm',
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: CORRESPONDENCE_SALUTATION_FIELDS,
    },
    methods: {
        cancel () {
            this.$router.go(-1)
        },
    },
}
</script>
