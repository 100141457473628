<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.accounting.posting.posting', 2)" :to="{ name: 'accounting-posting-list' }" />
                <q-breadcrumbs-el :label="$tc('views.accounting.posting.add-posting', 1)" :to="{ name: 'accounting-posting-create' }" />
            </template>

            <h1>{{ $tc('views.accounting.posting.add-posting', 1) }}</h1>
        </page-header>

        <posting-form ref="postingForm" @submit="onSubmit" />
    </page-wrapper>
</template>

<script>
import { Posting } from '@/models/posting'
import PostingForm from '@/components/accounting/PostingForm'
import { EventBus } from '@/event-bus'
import { FileUploadTargetObjectType } from '@/enums'
import { prepareFileUploadWrappers } from '@/helpers/file'

export default {
    name: 'PostingCreate',
    meta () {
        return {
            title: this.$tc('views.accounting.posting.add-posting', 1),
        }
    },
    components: {
        PostingForm,
    },
    methods: {
        async onSubmit (formData) {
            Posting.create(formData)
                .then(async (posting) => {
                    if (formData.attachments?.length) {
                        // Add attachments to file upload manager queue.
                        const fileUploadWrappers = await prepareFileUploadWrappers(formData.attachments, {
                            targetObjectType: FileUploadTargetObjectType.POSTING,
                            targetObjectId: posting.id,
                        })
                        EventBus.$emit('fileUploadManager:addToUploadQueue', fileUploadWrappers)
                    }

                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.accounting.posting-created-success', 1),
                    })
                    this.$router.replace({ name: 'accounting-posting-list' })
                })
                .catch(error => {
                    this.$refs.postingForm.showFormErrorMessage(error)
                    this.$refs.postingForm.resetFormSubmitStatus()
                })
        },
    },
}
</script>
