import gql from 'graphql-tag'

export function buildApplicationQuery (fields) {
    return gql`
        query application(
            $id: ID!
        ) {
            application(
                id: $id
            ) {
                ${fields}
            }
        }
    `
}

const defaultApplicationQueryFields = `
    id
    number {
        number
    }
    external_reference_number
    status
    customer {
        id
        contactNumber {
            id
            number
        }
        status
        mainAddress {
            id
            category {
                id
                key
                name
            }
            label {
                id
                label
            }
            po_box
            address1
            address2
            address3
            zip
            city
        }
        emailAddresses {
            id
        }
        ... on Person {
            first_name
            last_name
            gender
            personal_pronoun
            title
        }
        ... on Company {
            company_name
        }
        consultants {
            id
        }
    }
    consultant {
        id
        contact_number
        first_name
        last_name
        gender
        title
        consultingCompany {
            id
        }
        consulting_company_name
        consulting_settings_status
    }
    sourceContract {
        id
        contractNumbers {
            id
            number
        }
    }
    targetContract {
        id
        contractNumbers {
            id
            number
        }
    }
    is_change_application
    currentContractInformation {
        product {
            name
            basicProvider {
                id
                name
                display_name
            }
            template {
                version
                form_configuration
                template_configuration
            }
        }
        productTemplate {
            name
            category {
                name
            }
            subCategory {
                name
            }
            version
            form_configuration
            template_configuration
        }
        product_field_values
        start_date
        end_date
        premium
        generalAgency {
            id
            company_name
        }
        managed_by_status
        consulting_mandate_enquiry_send_date
    }
    on_hold_until
    submission_send_date
    submission_response_date
    created_at
    updated_at
`

export const targetObjectApplicationQueryFields = `
    id
    number {
        number
    }
    external_reference_number
    status
    customer {
        id
        status
        consultingSettings {
            status
            company {
                id
                company_name
            }
        }
        productProviderSettings {
            status
            display_name
        }
        contactNumber {
            id
            number
        }
        addresses {
            id
            category {
                id
                key
                name
            }
            label {
                id
                key
                label
            }
            po_box
            address1
            address2
            address3
            zip
            city
            country {
                id
                name
            }
            valid_from
            valid_until
            is_main_correspondence_address
            status
        }
        mainAddress {
            id
            category {
                id
                key
                name
            }
            label {
                id
                key
                label
            }
            po_box
            address1
            address2
            address3
            zip
            city
            country {
                id
                name
            }
            valid_from
            valid_until
            is_main_correspondence_address
            status
        }
        emailAddresses {
            id
            category {
                id
                key
                name
            }
            label {
                id
                key
                label
            }
            email
            is_main_email_address
        }
        mainEmailAddress {
            id
            category {
                id
                key
                name
            }
            label {
                id
                key
                label
            }
            email
            is_main_email_address
        }
        consultants {
            id
            contact_number
            first_name
            last_name
            gender
            title
            consultingCompany {
                id
            }
            consulting_company_name
            consulting_settings_status
        }
        ... on Person {
            first_name
            last_name
            gender
            personal_pronoun
        }
        ... on Company {
            company_name
            is_tenant_company
        }
    }
    consultant {
        id
        contact_number
        first_name
        last_name
        gender
        title
        consultingCompany {
            id
        }
        consulting_company_name
        consulting_settings_status
    }
    currentContractInformation {
        product {
            name
            basicProvider {
                name
                display_name
            }
        }
        productTemplate {
            name
            category {
                name
            }
            subCategory {
                name
            }
            version
        }
        start_date
        end_date
        premium
    }
`

export const APPLICATION_QUERY = buildApplicationQuery(defaultApplicationQueryFields)
