import gql from 'graphql-tag'

export const ACCOUNT_BALANCE_REPORTS_QUERY = gql`
    query accountBalanceReports(
        $page: Int = 1
        $count: Int = 1000
        $orderField: AccountBalanceReportOrderField = DATE_FROM
        $orderDirection: OrderDirection = DESC
        $filterCurrentQueueItemStatus: [QueueItemStatus!]
    ) {
        accountBalanceReports(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                current_queue_item_status: $filterCurrentQueueItemStatus
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                owner {
                    id
                    contact_number
                    first_name
                    last_name
                    gender
                    title
                    consulting_company_name
                    created_at
                    updated_at
                }
                accounts {
                    id
                    type
                    status
                    number
                    name
                    description
                }
                account_number_from
                account_number_to
                date_from
                date_until
                file_types
                currentQueueItem {
                    id
                    status
                    status_message
                }
                queueItems {
                    id
                    status
                    status_message
                }
                fileStorageItem {
                    id
                    type
                    files {
                        id
                        name
                        size
                        mime_type
                    }
                }
                created_at
                updated_at
            }
        }
    }
`
