import { QueueItemStatus } from '@/enums/graphql'

/**
 * Parse form data values.
 * @export
 * @param  {Object} formData - formData - {dateFrom, dateUntil, fileTypes[], accountIds}
 * @return {Object} typeof AccountStatementReportInput
 */

export function parseFormDataToInput(formData) {
    return {
        date_from: formData.dateFrom,
        date_until: formData.dateUntil,
        file_types: formData.fileTypes,
        account_ids: formData.accountIds,
    }
}

/**
 * Filter account statement report with cancel creation condition.
 * @export
 * @param  {Array} accountStatementReports - typeof AccountStatementReport
 * @return {Array} typeof AccountStatementReport
 */
export function cancelCreationCondition (accountStatementReports) {
    return accountStatementReports.filter(accountStatementReport => accountStatementReport.currentQueueItem.status === QueueItemStatus.QUEUED)
}

/**
 * Filter account statement report with delete condition.
 * @export
 * @param  {Array} accountStatementReports - typeof AccountStatementReport
 * @return {Array} typeof AccountStatementReport
 */
export function deleteCondition (accountStatementReports) {
    return accountStatementReports.filter(accountStatementReport => [QueueItemStatus.FAILED, QueueItemStatus.CANCELLED, QueueItemStatus.DONE].includes(accountStatementReport.currentQueueItem.status))
}
