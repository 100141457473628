import DefaultLayout from '@/layouts/Default'
import AdminDashboard from '@/views/admin/AdminDashboard'

export default [
    {
        path: '/admin',
        component: DefaultLayout,
        children: [{
            path: '',
            name: 'admin-dashboard',
            component: AdminDashboard,
            meta: {
                appSubSection: 'admin',
            },
        }],
    },
]
