import { kebabCase } from 'lodash'
import { FileType } from '@/enums/graphql'

export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'dateFrom',
            label: 'forms.field.label.account-balance-report.account-statement.period-from',
            inputType: 'calendar',
            validators: [
                { type: 'required' },
                { type: 'date' },
                {
                    type: 'isBeforeOrEqualToDate',
                    params: {
                        target: 'dateUntil',
                        targetLabel: 'forms.field.label.account-balance-report.account-statement.period-to',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'dateUntil',
            label: 'forms.field.label.account-balance-report.account-statement.period-to',
            inputType: 'calendar',
            validators: [
                { type: 'required' },
                { type: 'date' },
                {
                    type: 'isAfterOrEqualToDate',
                    params: {
                        target: 'dateFrom',
                        targetLabel: 'forms.field.label.account-balance-report.account-statement.period-from',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'fileTypes',
            label: 'forms.field.label.account-balance-report.account-statement.file-type',
            inputType: 'select',
            extra_attributes: {
                list: {
                    options: [{
                        label: `common.file.type.${kebabCase(FileType.EXCEL)}`,
                        value: FileType.EXCEL,
                    }],
                },
                multiple: false,
            },
            validators: [
                { type: 'required' },
            ],
        },
    ],
}

