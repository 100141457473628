<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.correspondence.document-template.document-template', 2)" :to="{ name: 'correspondence-document-template-list' }" />
            </template>

            <h1 class="q-mb-none">{{ $tc('common.correspondence.document-template.document-template', 2) }}</h1>
        </page-header>

        <!-- TODO improvement @TFU: Add filter / tabs for "all templates" / "only my templates" -->
        <div class="row">
            <div class="col-12">
                <base-table
                    :columns="columns"
                    :fetch-objects-fn="DocumentTemplate.objects.all"
                    user-settings-base-path="DocumentTemplateList"
                    pagination-sort-by-default-key="name"
                    @row-dblclick="(evt, row) => $router.push({ name: 'correspondence-document-template-update', params: { id: row.id } })"
                >
                    <template v-slot:body-cell-actions="slotProps">
                        <q-td :props="slotProps" auto-width>
                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                :icon="$can('update', slotProps.row) ? 'mib-pencil' : 'mib-view-1'"
                                :to="{ name: 'correspondence-document-template-update', params: { id: slotProps.row.id } }"
                            >
                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc(`common.correspondence.${slotProps.row.type.toLowerCase()}.${slotProps.row.type.toLowerCase()}-template--edit-template`, 1) }}</q-tooltip>
                            </q-btn>

                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-common-file-double-1"
                                :to="{ name: 'correspondence-document-template-create', query: { prefill: slotProps.row.id } }"
                            >
                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.correspondence.document-template.duplicate-document-template', 1) }}</q-tooltip>
                            </q-btn>
                        </q-td>
                    </template>
                </base-table>
            </div>
        </div>

        <in-page-footer>
            <base-button
                :to="{ name: 'correspondence-document-template-create' }"
                :label="$tc('common.correspondence.document-template.add-document-template', 1)"
                icon="mib-shipment-sync"
                primary-button
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import BaseTable from '@/components/BaseTable'
import { DocumentTemplate } from '@/models/documentTemplate'

export default {
    name: 'DocumentTemplateList',
    components: {
        BaseTable,
    },
    meta () {
        return {
            title: this.$tc('common.correspondence.document-template.document-template', 2),
        }
    },
    data () {
        return {
            DocumentTemplate,
            columns: [
                {
                    name: 'name',
                    label: this.$tc('common.term.name'),
                    field: row => row.name,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'type',
                    label: this.$tc('common.term.type'),
                    field: row => this.$tc(`common.correspondence.${row.type.toLowerCase()}.${row.type.toLowerCase()}-template`, 1),
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'supported_target_object_types',
                    label: this.$tc('common.correspondence.document-template.supported-target-object-types', 2),
                    field: row => row.supported_target_object_types.map(supported_target_object_type => this.$tc(`common.correspondence.target-object.target-object-type--${supported_target_object_type.toLowerCase()}`, 1)).join(', '),
                    align: 'left',
                },
                {
                    name: 'language_name',
                    label: this.$tc('common.correspondence.correspondence-item.language', 1),
                    field: row => row.language.name,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'owner_name',
                    label: this.$tc('common.correspondence.document-template.template-owner', 1),
                    field: row => row.getOwnerName(),
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'updated_at',
                    label: this.$t('common.term.updated-on'),
                    field: row => row.formattedUpdatedAt,
                    sortable: true,
                    align: 'left',
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left' },
            ],
        }
    },
    created () {
        EventBus.$on('shortcut:newEntity', this.onNewEntity)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:newEntity', this.onNewEntity)
    },
    methods: {
        onNewEntity () {
            this.$router.push({ name: 'correspondence-document-template-create' })
        },
    },
}
</script>
