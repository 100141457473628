import { Model } from '@/models'
import { CommissionRateAdjustmentRuleSetService } from '@/services'
import { formatCreateVariables, formatUpdateVariables } from '@/helpers/commissionRateAdjustmentRuleSet'
import { CommissionRateAdjustmentRuleSetActions } from '@/enums';

export class CommissionRateAdjustmentRuleSet extends Model {
    /**
     * Create a CommissionRateAdjustmentRuleSet model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'productProvider',
            'rules',
        ], data)
    }

    static get service () { return CommissionRateAdjustmentRuleSetService }

    static action = CommissionRateAdjustmentRuleSetActions

    /**
     * Add custom actions.
     * @returns {Array}
     */
    get actions () {
        const actions = super.actions
        actions.push({
            key: CommissionRateAdjustmentRuleSetActions.DELETE,
            item: this,
        })
        return actions
    }

    /**
     * Create a new commissionRateAdjustmentRuleSet.
     * @param {string} productProviderId
     * @param {Object} input - CommissionRateAdjustmentRuleSetInput
     * @returns {Promise<*>}
     */
    static create (productProviderId, input) {
        const variables = formatCreateVariables(productProviderId, input)
        return CommissionRateAdjustmentRuleSetService.create(variables)
    }

    /**
     * Update this commissionRateAdjustmentRuleSet.
     * @param {Object} input - CommissionRateAdjustmentRuleSetInput
     * @returns {Promise<*>}
     */
    update (input) {
        const variables = formatUpdateVariables(this.id, input)

        return CommissionRateAdjustmentRuleSetService.update(variables)
            .then(commissionRateAdjustmentRuleSet => {
                Object.assign(this, commissionRateAdjustmentRuleSet)
                return commissionRateAdjustmentRuleSet
            })
    }

    /**
     * Delete this commissionRateAdjustmentRuleSet.
     * @returns {Promise<*>}
     */
    delete () {
        return CommissionRateAdjustmentRuleSetService.delete(this.id)
    }
}
