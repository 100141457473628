<template>
    <node-view-wrapper as="span" data-drag-handle>
        <span
            class="smart-object"
            :class="{ selected: selected }"
            data-smart-object="image"
            :data-id="node.attrs.id"
            :data-max-width="maxWidth"
            :data-max-height="maxHeight"
        >
            <q-icon name="mib-itx-file-type-image" />
            {{ $tc('common.correspondence.smart-object.' + normalizeTranslationId(node.attrs.id), 1) }}
            <q-btn
                ref="configButton"
                color="primary"
                size="sm"
                flat
                round
                icon="mib-cog-double-3"
            >
                <q-tooltip
                    :delay="2000"
                    anchor="center right"
                    self="center left"
                    :offset="[0, 20]"
                >{{ $t('common.term.configure') }}</q-tooltip>
            </q-btn>
        </span>
        <q-popup-proxy
            ref="configProxy"
            :target="$refs.configButton"
            anchor="center right"
            self="center left"
            @before-show="onBeforeConfigShow"
        >
            <q-card>
                <q-card-section class="q-pb-none">
                    <div class="row q-col-gutter-md">
                        <div class="col-xs-6">
                            <base-input
                                v-model="$v.maxWidth.$model"
                                :label="$tc('common.editor.structure-block.image.max-width', 1)"
                                type="number"
                                min="0"
                                step="1"
                                suffix="mm"
                                :error="$v.maxWidth.$error"
                            >
                                <template v-if="$v.maxWidth.$error" v-slot:error>
                                    <div v-show="!$v.maxWidth.required">{{ $t('common.error-message.required', { field: $tc('common.editor.structure-block.image.max-width', 1) }) }}</div>
                                </template>
                            </base-input>
                        </div>
                        <div class="col-xs-6">
                            <base-input
                                v-model="$v.maxHeight.$model"
                                :label="$tc('common.editor.structure-block.image.max-height', 1)"
                                type="number"
                                min="0"
                                step="1"
                                suffix="mm"
                                :error="$v.maxHeight.$error"
                            >
                                <template v-if="$v.maxHeight.$error" v-slot:error>
                                    <div v-show="!$v.maxHeight.required">{{ $t('common.error-message.required', { field: $tc('common.editor.structure-block.image.max-height', 1) }) }}</div>
                                </template>
                            </base-input>
                        </div>
                    </div>
                </q-card-section>

                <q-card-actions align="right" class="q-pa-md">
                    <base-button
                        :label="$t('common.term.cancel')"
                        primary-button
                        flat
                        @click="closeConfig"
                    />
                    <base-button
                        :label="$t('common.term.save-and-close')"
                        primary-button
                        :disable="$v.$invalid"
                        @click="saveAndCloseConfig"
                    />
                </q-card-actions>
            </q-card>
        </q-popup-proxy>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import { normalizeTranslationId } from '@/helpers'
import BaseInput from '@/components/form/BaseInput'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'SmartObjectImageNodeView',
    components: {
        NodeViewWrapper,
        BaseInput,
    },
    props: nodeViewProps,
    data () {
        return {
            maxWidth: 0,
            maxHeight: 0,
        }
    },
    validations () {
        const validations = {
            maxWidth: {
                required,
            },
            maxHeight: {
                required,
            },
        }
        return validations
    },
    methods: {
        onBeforeConfigShow () {
            this.maxWidth = this.node.attrs.maxWidth ?? null
            this.maxHeight = this.node.attrs.maxHeight ?? null
        },
        saveAndCloseConfig () {
            this.updateAttributes({
                maxWidth: this.maxWidth,
                maxHeight: this.maxHeight,
            })
            this.closeConfig()
        },
        closeConfig () {
            this.$refs.configProxy.hide()
        },
        normalizeTranslationId (...args) {
            return normalizeTranslationId(...args)
        },
    },
}
</script>
