import { kebabCase } from 'lodash'
import { AccountType } from '@/enums/graphql'
import { Form, FormField } from '@/libs/form'

export class AccountForm extends Form {
    compose () {
        const typeOptions = Object.values(AccountType).map(value => ({ value: value, label: `common.accounting.account.type.${kebabCase(value.toLowerCase())}` }))
        this.addField(new FormField('type', {
            label: 'forms.field.label.account.type.type',
            type: 'select',
            extra_attributes: {
                list: {
                    options: typeOptions,
                },
            },
            validators: [
                { type: 'required' },
            ],
        }))

        this.addField(new FormField('name', {
            label: 'forms.field.label.account.name',
            type: 'text',
            validators: [
                { type: 'required' },
            ],
        }))

        this.addField(new FormField('number', {
            label: 'forms.field.label.account.number',
            type: 'number',
            validators: [
                { type: 'required' },
                { type: 'maxLength', params: { max: 9 } },
            ],
        }))

        this.addField(new FormField('description', {
            label: 'forms.field.label.account.description',
            type: 'text',
        }))
    }
}
