<template>
    <form
        data-test="form:product-template"
        @submit.prevent="handleFormSubmit"
        @keyup.esc="cancel"
    >
        <info-box v-if="$v.formData.templateConfiguration.$error || $v.formData.templateConfigurationValidation.$error" type="negative">
            <ul>
                <li v-if="!$v.formData.templateConfiguration.required" data-test="error:template-required">{{ $t('views.admin.products.product-templates.error-empty-template') }}</li>
                <li v-if="!$v.formData.templateConfigurationValidation.mustBeTrue" data-test="error:template-not-valid">{{ $t('views.admin.products.product-templates.error-invalid-template') }}</li>
            </ul>
        </info-box>
        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="formErrorMessage"
            type="negative"
            data-test="error:global-message"
            v-html="formErrorMessage"
        />
        <!-- eslint-enable -->

        <div class="row q-col-gutter-sm">
            <div class="col-xs-12">
                <form-builder item-key="name" autofocus />
            </div>
            <div class="col-xs-12">
                <product-categories-select
                    ref="productCategoriesSelect"
                    v-model="formData.productCategory"
                    :label="$tc('common.product.product-category', 1)"
                    :error="$v.formData.productCategory.$error"
                />
            </div>
        </div>

        <h2>{{ $tc('common.term.configuration', 1) }}</h2>

        <product-template-builder ref="productTemplateBuilder" :configuration="formData.templateConfiguration" />

        <in-page-footer>
            <base-button
                :label="$t('common.term.cancel')"
                flat
                primary-button
                @click="cancel"
            />
            <base-button
                data-test="btn:submit"
                type="submit"
                :label="(currentMode === ViewMode.CREATE) ? $t('views.admin.products.product-templates.add-product-template') : $t('common.term.save')"
                :disable="formSubmitStatus"
                :loading="formSubmitStatus"
                primary-button
            />
        </in-page-footer>
    </form>
</template>

<script>
import { ViewMode } from '@/enums'
import { required } from 'vuelidate/lib/validators'
import { walkTree } from '@/helpers'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'

import ProductCategoriesSelect from '@/components/product/ProductCategoriesSelect.vue'
import ProductTemplateBuilder from '@/components/product/ProductTemplateBuilder.vue'

import PRODUCT_TEMPLATE_FORM from '@/forms/product.productTemplate.form.json'

export default {
    name: 'ProductTemplateForm',
    components: {
        ProductCategoriesSelect,
        ProductTemplateBuilder,
    },
    mixins: [formBuilderMixin],
    props: {
        productTemplate: {
            type: Object,
        },
    },
    formBuilderSettings: {
        schema: PRODUCT_TEMPLATE_FORM,
        fields: ['name'],
    },
    data () {
        const data = {
            ViewMode,
            currentMode: (this.productTemplate) ? ViewMode.UPDATE : ViewMode.CREATE,
            formData: {
                productCategory: {
                    category: null,
                    subCategory: null,
                },
                templateConfiguration: {
                    type: 'root',
                    children: [],
                },
                templateConfigurationValidation: false,
            },
        }
        if (data.currentMode === ViewMode.UPDATE) {
            const templateConfiguration = JSON.parse(JSON.stringify(this.productTemplate.template_configuration))
            walkTree(templateConfiguration, (item) => {
                switch (item.type) {
                    case 'section':
                        item.key = window.crypto.getRandomValues(new Uint32Array(1))[0]
                        if (item.children === undefined) item.children = []
                        item.removeFlag = false
                        break
                    case 'field':
                        item.removeFlag = false
                        break
                }
            })
            data.formData.name = this.productTemplate.name
            data.formData.productCategory.category = this.productTemplate.category.id
            data.formData.productCategory.subCategory = this.productTemplate.subCategory.id
            data.formData.templateConfiguration.children = templateConfiguration.children
        }
        return data
    },
    validations () {
        return {
            formData: {
                productCategory: {
                    category: {
                        required,
                    },
                    subCategory: {
                        required,
                    },
                },
                templateConfiguration: {
                    required: (templateConfiguration) => !!templateConfiguration.children.length,
                },
                templateConfigurationValidation: {
                    mustBeTrue: (value) => value === true,
                },
            },
        }
    },
    created () {
        this.$watch('formData.productCategory', () => {
            this.$v.formData.productCategory.$touch()
        }, { deep: true })
    },
    methods: {
        handleFormSubmit () {
            this.formData.templateConfigurationValidation = this.$refs.productTemplateBuilder.validate()
            this.onFormSubmit()
        },
        cancel () {
            this.$router.go(-1)
        },
    },
}
</script>
