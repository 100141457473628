import gql from 'graphql-tag'

export const COMMISSION_LIST_ENTRY_START_POSTING_MUTATION = gql`
    mutation commissionListEntriesStartPosting(
        $ids: [ID!]
        $startAll: Boolean!
    ) {
        commissionListEntriesStartPosting(
            ids: $ids
            start_all: $startAll

        ) {
            status
            number_of_affected_entries
        }
    }
`
