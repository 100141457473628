import gql from 'graphql-tag'

export const ECO_HUB_TECH_USER_CREATE_MUTATION = gql`
    mutation ecoHubTechUserCreate(
        $techUser: EcoHubTechUserInput!
    ) {
        ecoHubTechUserCreate(
            techUser: $techUser
        ) {
            idp_user_id
            created_at
            updated_at
        }
    }
`
