import { isPlainObject, walkJSON } from '@/helpers'

/**
 * @param  {Object} contract
 * @return { name: value }
 */
export function createProductFieldsNameValuePairs (contract) {
    function composeInitialData (data, items) {
        let sectionIndex = 0
        items.forEach((item, index) => {
            switch (item.type) {
                case 'field': {
                    const productFieldValue = contract.currentContractInformation.product_field_values.find(productField => productField.field_id === item.id)
                    if (productFieldValue) data[item.key] = productFieldValue.value
                    break
                }
                case 'section': {
                    const sectionKey = `section${sectionIndex++}`
                    data[sectionKey] = {}
                    if (item.children?.length) composeInitialData(data[sectionKey], item.children)
                    break
                }
            }
        })
    }

    const productFields = {}
    composeInitialData(productFields, contract.currentContractInformation.productTemplate.form_configuration.children)
    return productFields
}

/**
 * @param  {Object} productTemplate
 * @param  {Object} productFields - { name: value}
 * @return { id: value }
 */
export function createProductFieldIdValuePairs (productTemplate, productFields) {
    const flattenedProductFields = {}
    walkJSON(productFields, (value, key, node) => {
        if (!(key.startsWith('section') && isPlainObject(value))) {
            flattenedProductFields[key] = value
        }
    })

    const productFieldValues = []
    walkJSON(productTemplate.form_configuration, (value, key, node) => {
        if (key === 'type' && value === 'field') {
            productFieldValues.push({
                field_id: node.id,
                value: flattenedProductFields[node.key],
            })
        }
    })

    return productFieldValues
}
