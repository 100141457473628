import Vue from 'vue'
import Router from 'vue-router'
import { Cookies } from 'quasar'

import DefaultLayout from '@/layouts/Default'
import Dashboard from '@/views/Dashboard'
import UserManual from '@/views/UserManual'
import Glossary from '@/views/Glossary'
import authRoutes from '@/routes/auth'
import userRoutes from '@/routes/user'
import contactRoutes from '@/routes/contact'
import contractRoutes from '@/routes/contract'
import applicationRoutes from '@/routes/application'
import accountRoutes from '@/routes/account'
import adminRoutes from '@/routes/admin'
import productRoutes from '@/routes/product'
import correspondenceRoutes from '@/routes/correspondence'
import taskRoutes from '@/routes/task'
import commissionRoutes from '@/routes/commission'
import accountingRoutes from '@/routes/accounting'
import ecoHubRoutes from '@/routes/ecoHub'
import ErrorNotFound from '@/views/ErrorNotFound'
import ErrorForbidden from '@/views/ErrorForbidden'
import ErrorGeneral from '@/views/ErrorGeneral'
import Drive from '@/views/drive/Drive'
import Downloads from '@/views/Downloads'

// DEV only
import devRoutes from '@/routes/_dev'
import { checkRouteAccessPermissions } from '@/helpers/acl'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: DefaultLayout,
            children: [
                {
                    path: '',
                    name: 'dashboard',
                    component: Dashboard,
                },
            ],
        },
        {
            path: '/drive',
            component: DefaultLayout,
            children: [{
                path: '',
                name: 'drive',
                component: Drive,
                meta: {
                    accessPermissions: ['Feature:accounting:core'],
                    can: checkRouteAccessPermissions,
                    navGroup: 'drive',
                },
            }],
        },
        {
            path: '/downloads/:encodedTemporaryFileInfo',
            component: DefaultLayout,
            children: [{
                path: '',
                name: 'downloads',
                component: Downloads,
                props: true,
                meta: {
                    navGroup: 'downloads',
                },
            }],
        },
        {
            path: '/user-manual/:chapter?/:page?',
            component: DefaultLayout,
            children: [{
                path: '',
                name: 'user-manual',
                component: UserManual,
                props: true,
                meta: {
                    appSubSection: 'user-manual',
                },
            }],
        },
        {
            path: '/user-manual/glossary',
            component: DefaultLayout,
            children: [{
                path: '',
                name: 'glossary',
                component: Glossary,
                meta: {
                    appSubSection: 'user-manual',
                },
            }],
        },
        ...authRoutes,
        ...userRoutes,
        ...contactRoutes,
        ...contractRoutes,
        ...applicationRoutes,
        ...accountRoutes,
        ...adminRoutes,
        ...productRoutes,
        ...correspondenceRoutes,
        ...taskRoutes,
        ...commissionRoutes,
        ...accountingRoutes,
        ...ecoHubRoutes,

        // DEV only
        ...devRoutes,

        // General error page
        {
            path: '/error/:errorNumber?',
            component: DefaultLayout,
            children: [{
                path: '',
                name: 'error',
                component: ErrorGeneral,
                props: {
                    default: true,
                },
            }],
        },

        // Forbidden (403)
        {
            path: '/forbidden',
            component: DefaultLayout,
            children: [{
                path: '',
                name: '403',
                component: ErrorForbidden,
            }],
        },

        // Not found (404 / catch all)
        // version with separate route and redirect
        // https://stackoverflow.com/questions/45619407/how-to-create-a-404-component-in-vuejs-using-vue-router
        // "… you can also programmatically direct the user to the 404 route in such a case when some data you need does not resolve"
        {
            path: '/not-found',
            component: DefaultLayout,
            children: [{
                path: '',
                name: '404',
                component: ErrorNotFound,
            }],
        },
        {
            path: '*',
            redirect: { name: '404' },
        },
    ],
    scrollBehavior (to) {
        if (to.hash) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        selector: to.hash,
                        behavior: 'smooth',
                        offset: {
                            x: 0,
                            y: 120, // TODO improvement: get value depending on current view / context
                        },
                    })
                }, 200)
            })
        }
    },
})

export { router as default }

router.beforeEach((to, from, next) => {
    if (to.matched.every(record => record.meta.noAuth)) { // No auth required
        if (Cookies.has('jwt_header_payload') && !to.matched.every(record => record.meta.noAuthDoNotRedirect)) { // Is logged in
            next({ name: 'dashboard' })
        } else { // Not logged in OR logged in but with `meta.noAuthDoNotRedirect`
            next()
        }
    } else { // Route requires auth
        if (Cookies.has('jwt_header_payload')) { // Is logged in
            next()
        } else { // Not logged in
            next({ name: 'auth-login' })
        }
    }
})
