import gql from 'graphql-tag'

export const CONTACT_EMAIL_ADDRESS_DELETE_MUTATION = gql`
    mutation contactEmailAddressDelete(
        $id: ID!
    ) {
        contactEmailAddressDelete(
            id: $id
        ) {
            status
        }
    }
`
