import { MailProcessingSettingsStatus } from '@/enums'
import { formatDate } from '@/helpers/date'
import { Model } from '@/models'
import { computed } from 'vue'
import { parseISO, isPast, isFuture, isToday, endOfDay } from 'date-fns'

export class MailProcessingSettings extends Model {
    /**
     * Create a MailProcessingSettings model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'active_from',
            'active_until',
            'mail_processing_is_active',
            'price_per_processed_mail_item',
            'notification_email_override',
            'error_notification_email',
        ], data)

        this.formattedActiveFrom = computed(() => {
            return formatDate(this.active_from)
        })

        this.formattedActiveUntil = computed(() => {
            return formatDate(this.active_until)
        })

        this.status = computed(() => {
            return MailProcessingSettings.getStatus(this.active_from, this.active_until)
        })
    }

    static getStatus (activeFrom, activeUntil) {
        const parsedActiveFrom = parseISO(activeFrom)
        const parsedActiveUntil = endOfDay(parseISO(activeUntil))

        if (activeFrom && isFuture(parsedActiveFrom)) return MailProcessingSettingsStatus.FUTURE
        if (activeUntil && isPast(parsedActiveUntil)) return MailProcessingSettingsStatus.EXPIRED

        if (activeFrom && !activeUntil && (isPast(parsedActiveFrom) || isToday(parsedActiveFrom))) return MailProcessingSettingsStatus.ACTIVE
        if (!activeFrom && activeUntil && (isFuture(parsedActiveUntil) || isToday(parsedActiveUntil))) return MailProcessingSettingsStatus.ACTIVE
        if (activeFrom && activeUntil && (isPast(parsedActiveFrom) || isToday(parsedActiveFrom)) && (isFuture(parsedActiveUntil) || isToday(parsedActiveUntil))) return MailProcessingSettingsStatus.ACTIVE

        return MailProcessingSettingsStatus.INACTIVE
    }
}
