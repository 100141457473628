import { requiredUnless } from '@vuelidate/validators'
import { Form, FormField } from '@/libs/form'
import { watch } from 'vue'
import { i18n } from '@/i18n'

export class AreaOfResponsibilityForm extends Form {
    compose () {
        this.addField(new FormField('isAnonymous', {
            label: 'common.contact.relationship.area-of-responsibility.is-anonymous',
            type: 'checkbox',
        }))
        watch(() => this.getItem('isAnonymous').value, () => {
            this.getItem('contactRelationshipId').resetValue()
        })

        this.addField(new FormField('contactRelationshipId', {
            label: 'common.term.contact',
            type: 'contactRelationship',
            validators: [
                {
                    type: 'requiredUnlessIsAnonymous',
                    validator: requiredUnless(() => {
                        return this.getItem('isAnonymous').value
                    }),
                    errorMessage: i18n.t('common.error-message.required', { field: i18n.tc('common.term.contact', 1) }),
                },
            ],
        }))
        watch(() => this.getItem('contactRelationshipId').value, (newValue, oldValue) => {
            if (oldValue !== null) {
                this.getItem('emailAddressId').resetValue()
                this.getItem('phoneNumberId').resetValue()
            }
        })

        this.addField(new FormField('name', {
            label: 'forms.field.label.area-of-responsibility.area-of-responsibility',
            type: 'text',
            validators: [
                { type: 'required' },
            ],
        }))

        this.addField(new FormField('description', {
            label: 'forms.field.label.area-of-responsibility.description',
            type: 'textarea',
        }))

        this.addField(new FormField('emailAddressId', {
            label: 'forms.field.label.area-of-responsibility.email-address',
            type: 'select',
            validators: [
                {
                    type: 'ifAnonymousRequiredUnlessPhoneNumber',
                    validator: requiredUnless(() => {
                        return (this.getItem('isAnonymous').value) ? this.getItem('phoneNumberId').value : true
                    }),
                    errorMessage: 'views.area-of-responsibility.list.form-field-error--email-phone--required-when-anonymous',
                },
            ],
        }))

        this.addField(new FormField('phoneNumberId', {
            label: 'forms.field.label.area-of-responsibility.phone-number',
            type: 'select',
            validators: [
                {
                    type: 'ifAnonymousRequiredUnlessEmailAddress',
                    validator: requiredUnless(() => {
                        return (this.getItem('isAnonymous').value) ? this.getItem('emailAddressId').value : true
                    }),
                    errorMessage: 'views.area-of-responsibility.list.form-field-error--email-phone--required-when-anonymous',
                },
            ],
        }))
    }
}

