<template>
    <page-loading-indicator v-if="!correspondenceEmail" :number-of-breadcrumb-elements="3" subtitle />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.correspondence.email.email', 2)" :to="{ name: 'correspondence-emails' }" />
                <q-breadcrumbs-el :label="$tc('common.correspondence.email.status--completed', 2)" :to="{ name: 'correspondence-email-list', params: { statusGroup: 'completed' } }" />
                <q-breadcrumbs-el :to="{ name: 'correspondence-email-summary', params: { id: correspondenceEmail.id } }">
                    {{ correspondenceEmail.name }}
                    <status-badge :status="correspondenceEmail.status.toLowerCase()" />
                </q-breadcrumbs-el>
            </template>

            <h1>{{ $tc('common.correspondence.email.email-summary', 1) }} <span class="subtitle">{{ correspondenceEmail.name }}</span></h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12 col-lg-10 col-xl-8">
                <info-box
                    type="secondary"
                    :inline-actions="$q.screen.width > 1300"
                    no-margin
                >
                    <i18n path="common.correspondence.email.email-summary--text" tag="p">
                        <template v-slot:numberOfRecipients>
                            <b>{{ $tc('common.correspondence.email.email-summary--text--part--number-of-recipients', correspondenceEmail.target_object_ids.length) }}</b>
                        </template>
                        <template v-slot:numberOfAttachments>
                            <b>{{ $tc('common.correspondence.email.email-summary--text--part--number-of-attachments--total-file-size', correspondenceEmail.attachments.length, { 'totalFileSize': humanStorageSize(attachmentsTotalSize) }) }}</b>
                        </template>
                        <template v-slot:correspondenceLanguage>{{ correspondenceEmail.language.name }}</template>
                        <template v-slot:differentiateFormality>{{ $t(`common.correspondence.email.email-summary--text--part--differentiate-formality--${correspondenceEmail.differentiate_formality.toString()}`) }}</template>
                    </i18n>

                    <!-- TODO: Remove when bulk correspondence has been implemented. -->
                    <template v-slot:action>
                        <base-button
                            v-if="processedItems[0]?.targetObject"
                            data-test="btn:go-to-entity"
                            :label="$tc(`common.${correspondenceEmail.target_object_type.toLowerCase()}.go-to-${correspondenceEmail.target_object_type.toLowerCase()}`, 1)"
                            primary-button
                            :to="{ name: `${correspondenceEmail.target_object_type.toLowerCase()}-detail`, params: { id: processedItems[0]?.targetObject.id, contactId: processedItems[0]?.targetObject.customer?.id, tab: 'documents' } }"
                        />
                        <base-button
                            v-else
                            :label="$tc(`common.${correspondenceEmail.target_object_type.toLowerCase()}.go-to-${correspondenceEmail.target_object_type.toLowerCase()}`, 1)"
                            primary-button
                            disabled
                        />
                    </template>
                </info-box>
            </div>
        </div>

        <q-separator />

        <div class="row">
            <div class="col-12">
                <h2>{{ $tc('common.correspondence.email.sent-email', 2) }}</h2>
                <base-table
                    :columns="columns"
                    :fetch-objects-fn="CorrespondenceService.items.processed"
                    :additional-filters="additionalFilters"
                    enable-visible-columns
                    user-settings-base-path="SummaryProcessedCorrespondenceEmailList"
                    pagination-sort-by-default-key="updated_at"
                    :pagination-initial-order-direction-descending="true"
                    :show-filter="false"
                    @update:items="setProcessedItems"
                >
                    <template v-slot:body-cell-status="slotProps">
                        <q-td :props="slotProps">
                            <!-- TODO @TFU: Please verify that these work as expected. -->
                            <status-badge
                                v-if="slotProps.row.status_message"
                                :status="slotProps.row.status"
                                default-translation-base-path="common.status.correspondence-item-status"
                                :info-text="$t(`common.status.code.${slotProps.row.status_message.type}.${slotProps.row.status_message.code.replace('_', '.')}`)"
                            />
                            <status-badge
                                v-else
                                :status="slotProps.row.status"
                                default-translation-base-path="common.status.correspondence-item-status"
                            />
                        </q-td>
                    </template>

                    <template v-slot:body-cell-actions="slotProps">
                        <q-td :props="slotProps" auto-width>
                            <q-btn
                                color="primary"
                                size="sm"
                                flat
                                round
                                icon="mib-navigation-next-alternate"
                                :to="slotProps.row.targetObject ? { name: `${getTargetObjectType(slotProps.row.targetObject).toLowerCase()}-detail`, params: { id: slotProps.row.targetObject.id, contactId: slotProps.row.targetObject.customer?.id, tab: 'documents' } } : null"
                                :disable="!slotProps.row.targetObject"
                            >
                                <q-tooltip v-if="slotProps.row.targetObject" :delay="1000" :offset="[0, 10]">{{ $tc(`common.${getTargetObjectType(slotProps.row.targetObject).toLowerCase()}.go-to-${getTargetObjectType(slotProps.row.targetObject).toLowerCase()}`, 1) }}</q-tooltip>
                                <q-tooltip v-else :delay="1000" :offset="[0, 10]">{{ $t('common.target-object.target-object--no-target-object-available') }}</q-tooltip>
                            </q-btn>
                        </q-td>
                    </template>
                </base-table>
            </div>
        </div>

        <in-page-footer>
            <base-button
                data-test="btn:back"
                :label="$t('common.term.back')"
                primary-button
                flat
                :to="{ name: 'correspondence-email-list', params: { statusGroup: 'completed' } }"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { CorrespondenceService } from '@/services/correspondence'
import { CorrespondenceItemStatus } from '@/enums/graphql'
import { CorrespondenceEmail } from '@/models/correspondenceEmail'
import BaseTable from '@/components/BaseTable'
import StatusBadge from '@/components/StatusBadge'
import { humanStorageSize } from '@/helpers/file'

export default {
    name: 'CorrespondenceEmailDetail',
    meta () {
        return {
            title: `${this.$tc('common.correspondence.email.email-summary', 1)} (${this.$tc('common.correspondence.email.email', 1)})`,
        }
    },
    components: {
        BaseTable,
        StatusBadge,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            correspondenceEmail: null,
            CorrespondenceService,
            CorrespondenceItemStatus,
            processedItems: [],
            columns: [
                {
                    name: 'subject',
                    label: this.$tc('common.correspondence.email.subject', 1),
                    field: row => row.subject,
                    sortable: true,
                    align: 'left',
                    required: true,
                },
                {
                    name: 'recipient_address',
                    label: this.$tc('common.term.recipient', 1),
                    field: row => row.recipient_address,
                    align: 'left',
                    required: true,
                },
                {
                    name: 'language_name',
                    label: this.$tc('common.term.language'),
                    field: row => row.language.name,
                    align: 'left',
                    hideInitially: true,
                },
                {
                    name: 'creator_name',
                    label: this.$t('common.term.created-by'),
                    // TODO improvement @TFU: Extend available info with consulting company name
                    field: row => (row.creator) ? row.creator.getContactName({ consultingCompanyName: !row.creator.worksForUserConsultingCompany }) : '—', // TODO improvement @MTR: Maybe display a tooltip with possible reasons why?
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'sender_name',
                    label: this.$tc('common.term.sender', 1),
                    // TODO improvement @TFU: Extend available info with consulting company name
                    field: row => (row.sender) ? row.sender.getContactName({ consultingCompanyName: !row.sender.worksForUserConsultingCompany }) : '—', // TODO improvement @MTR: Maybe display a tooltip with possible reasons why?
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'updated_at',
                    label: this.$tc('common.term.updated--last-updated', 1),
                    field: row => row.formattedUpdatedAt,
                    sortable: true,
                    align: 'left',
                },
                {
                    name: 'status',
                    label: this.$tc('common.term.status'),
                    field: row => row.status,
                    align: 'left',
                    required: true,
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left', required: true },
            ],
        }
    },
    computed: {
        attachmentsTotalSize () {
            return (this.correspondenceEmail) ? this.correspondenceEmail.attachments.reduce((total, file) => (total += file.size), 0) : 0
        },
        additionalFilters () {
            const filters = {
                filterCorrespondenceItemId: this.id,
            }
            return filters
        },
    },
    created () {
        // TODO improvement @TFU: Redirect if item with given ID should not have this view. (Due to its status.)
        CorrespondenceEmail.objects.get(this.id).then(correspondenceEmail => {
            this.correspondenceEmail = correspondenceEmail
        })
    },
    methods: {
        setProcessedItems (items) {
            this.processedItems = items
        },
        humanStorageSize (...args) {
            return humanStorageSize(...args)
        },
        getTargetObjectType (targetObject) {
            return ['Person', 'Company'].includes(targetObject.__typename) ? 'Contact' : targetObject.__typename
        },
    },
}
</script>
