import gql from 'graphql-tag'

export const PHONE_NUMBER_LABELS_QUERY = gql`
    query phoneNumberLabels(
        $filterCategoryIds: [ID!]
    ) {
        phoneNumberLabels(
            search: {
                category_ids: $filterCategoryIds
            }
        ) {
            id
            key
            label
        }
    }
`
