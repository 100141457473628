import { ActivityLogParser } from '@/parsers/activityLog'
import { i18n } from '@/i18n'

export class CommissionListActivityLogParser extends ActivityLogParser {
    static attributeDataTypes = {
        'exchange_date_from': 'date',
        'exchange_date_to': 'date',
        'commission_total': 'currency',
    }

    static preprocess (activityLog) {
        const productProviderNameKey = 'productProvider.name'
        const productProviderDisplayNameKey = 'productProvider.product_provider_name'

        if (activityLog.properties.attributes && activityLog.properties.attributes[productProviderNameKey] && activityLog.properties.attributes[productProviderDisplayNameKey]) {
            activityLog.properties.attributes.productProviderName = activityLog.properties.attributes[productProviderDisplayNameKey] || activityLog.properties.attributes[productProviderNameKey]
            delete activityLog.properties.attributes[productProviderNameKey]
            delete activityLog.properties.attributes[productProviderDisplayNameKey]
        }

        if (activityLog.properties.old && activityLog.properties.old[productProviderNameKey] && activityLog.properties.old[productProviderDisplayNameKey]) {
            activityLog.properties.old.productProviderName = activityLog.properties.old[productProviderDisplayNameKey] || activityLog.properties.old[productProviderNameKey]
            delete activityLog.properties.old[productProviderDisplayNameKey]
            delete activityLog.properties.old[productProviderNameKey]
        }
        return activityLog
    }

    static getLogDescriptionStartProcessing (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `<b>${i18n.tc('common.change-log.action.commission-list-import', 1)}</b>`,
        }
        return i18n.t('common.change-log.someone-started-something', interpolationParams)
    }

    static getLogDescriptionCancelProcessing (activityLog, data) {
        const interpolationParams = {
            someone: data.causer,
            something: `<b>${i18n.tc('common.change-log.action.commission-list-import', 1)}</b>`,
        }
        return i18n.t('common.change-log.someone-cancelled-something', interpolationParams)
    }
}
