<template>
    <q-list
        :class="['task-info-list', { 'highlight-background': highlightBackground }]"
        separator
    >
        <!-- TODO improvement: Create custom BaseExpansionItem component -->
        <q-expansion-item
            v-for="task in tasks"
            :key="task.id"
            :group="group"
            :to="{ name: 'task-detail', params: { id: task.id } }"
            :class="['task-info-list-item', task.due_date ? `due-date-status-${kebabCase(task.dueDateStatus.toLowerCase())}` : null]"
            expand-icon-toggle
            expand-icon="mib-arrow-left-1"
            expanded-icon="mib-arrow-down-1"
            expand-icon-class="self-center expand-icon"
        >
            <template v-slot:header>
                <q-item-section avatar class="task-icon-wrapper self-center">
                    <q-icon
                        v-if="task.due_date"
                        :name="task.dueDateDiffDays < 0 ? 'mib-calendar-warning' : task.dueDateDiffDays > 0 ? 'mib-calendar-clock' : 'mib-calendar-information'"
                        :color="task.dueDateDiffDays < 0 ? 'negative-dark' : 'warning-dark'"
                        size="xs"
                        class="cursor-help"
                    >
                        <q-tooltip
                            :delay="250"
                            anchor="center start"
                            self="center end"
                            :offset="[10, 0]"
                            :content-class="[{ 'bg-warning text-text-primary': [TaskDueDateStatus.DUE_TODAY, TaskDueDateStatus.UPCOMING].includes(task.dueDateStatus), 'bg-negative-dark': task.dueDateStatus === TaskDueDateStatus.OVERDUE }]"
                        >
                            <template v-if="task.dueDateDiffDays >= 0">{{ $tc('common.task.task-due-in-n-days', task.dueDateDiffDays) }}</template>
                            <template v-else>{{ task.dueDateDiffDays >= -showOverdueSinceDateThreshold ? $tc('common.task.task-overdue-since-n-days', -task.dueDateDiffDays) : $tc('common.task.task-overdue-since-date', 1, { date: formatDate(task.due_date, { noLeadingZero: true }) }) }}</template>
                        </q-tooltip>
                    </q-icon>
                    <q-icon
                        v-else
                        name="mib-check-square-alternate"
                        color="secondary-light"
                        size="xs"
                    />
                    <info-icon
                        v-if="task.visibility === TaskVisibility.PRIVATE"
                        name="mib-lock-1"
                        :title="$tc('common.task.visibility-private--private-task', 1)"
                        :text="$tc('common.task.visibility-private--description', 1)"
                        color="secondary"
                        :delay="250"
                        anchor="center start"
                        self="center end"
                        :offset="[10, 0]"
                    />
                </q-item-section>

                <q-item-section>
                    <q-item-label>
                        {{ task.subject }}
                    </q-item-label>
                </q-item-section>
            </template>

            <q-card>
                <q-card-section :class="highlightBackground ? 'q-pt-sm q-pb-sm' : 'q-pt-xs'">
                    <dl class="q-mb-sm">
                        <div v-if="!noCreator">
                            <dt>{{ $tc('common.term.created-by', 1) }}: </dt>
                            <dd v-if="task.creator">{{ task.creator.getContactName() }} <span class="additional-info">{{ task.creator.getContactName({ noFirstName: true, noLastName: true, consultingCompanyName: !task.creator.worksForUserConsultingCompany }) }}</span></dd>
                            <dd v-else class="additional-info">–</dd>
                        </div>

                        <div v-if="!noAssignee">
                            <dt>{{ $tc('common.task.assignee', 1) }}: </dt>
                            <dd v-if="task.assignee">{{ task.assignee.getContactName() }} <span class="additional-info">{{ task.assignee.getContactName({ noFirstName: true, noLastName: true, consultingCompanyName: !task.assignee.worksForUserConsultingCompany }) }}</span></dd>
                            <dd v-else class="additional-info">–</dd>
                        </div>

                        <div v-if="!noStatus">
                            <dt>{{ $tc('common.term.status', 1) }}: </dt>
                            <dd><status-badge :status="task.status" /></dd>
                        </div>
                    </dl>

                    <info-box v-if="task.due_date" :type="task.dueDateDiffDays < 0 ? 'negative' : 'warning'" no-margin>
                        <p v-if="task.dueDateDiffDays < 0" class="text-negative-darker">{{ task.dueDateDiffDays >= -showOverdueSinceDateThreshold ? $tc('common.task.task-overdue-since-n-days', -task.dueDateDiffDays) : $tc('common.task.task-overdue-since-date', 1, { date: formatDate(task.due_date, { noLeadingZero: true }) }) }}</p>
                        <p v-else>{{ $tc('common.task.task-due-in-n-days', task.dueDateDiffDays) }}</p>

                        <p v-if="task.visibility === TaskVisibility.PRIVATE"><b>{{ $tc('common.task.visibility-private--private-task', 1) }}</b> – {{ $tc('common.task.visibility-private--description', 1) }}</p>
                    </info-box>
                    <info-box v-else-if="task.visibility === TaskVisibility.PRIVATE" type="warning" no-margin>
                        <p><b>{{ $tc('common.task.visibility-private--private-task', 1) }}</b> – {{ $tc('common.task.visibility-private--description', 1) }}</p>
                    </info-box>
                </q-card-section>
            </q-card>
        </q-expansion-item>
        <q-separator />
    </q-list>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { TaskDueDateStatus } from '@/enums'
import { TaskVisibility } from '@/enums/graphql'
import { formatDate } from '@/helpers/date'
import StatusBadge from '@/components/StatusBadge.vue'

export default {
    name: 'TaskInfoList',
    components: {
        StatusBadge,
    },
    props: {
        tasks: {
            type: Array,
            required: true,
        },
        group: {
            type: String,
            default: 'task-info-list-group',
        },
        noCreator: {
            type: Boolean,
            default: false,
        },
        noAssignee: {
            type: Boolean,
            default: false,
        },
        noStatus: {
            type: Boolean,
            default: false,
        },
        highlightBackground: {
            type: Boolean,
            default: false,
        },
        showOverdueSinceDateThreshold: {
            type: Number,
            default: 14,
        },
    },
    setup () {
        return {
            // Static
            TaskDueDateStatus,
            TaskVisibility,

            // Functions
            kebabCase,
            formatDate,
        }
    },
}
</script>

<style lang="scss">
.task-info-list-item {

    .highlight-background & {
        &.due-date-status {
            &-overdue {
                border-left: 4px solid var(--q-color-negative);
                background: var(--q-color-negative-lighter);
            }

            &-due-today,
            &-upcoming {
                border-left: 4px solid var(--q-color-warning);
                background: var(--q-color-warning-lighter);
            }
        }
    }

    .q-item__section--avatar {
        min-width: 36px;

        flex-direction: row;
        flex-wrap: nowrap;
        flex: 0 1 36px;
    }
}
</style>
