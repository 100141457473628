import { apolloClient } from '@/apollo/client'

import { ACCOUNT_QUERY } from '@/graphql/account/account'
import { ACCOUNTS_QUERY, buildAccountsQuery } from '@/graphql/account/accounts'
import { ACCOUNT_CREATE_MUTATION } from '@/graphql/account/accountCreate'
import { ACCOUNT_UPDATE_MUTATION } from '@/graphql/account/accountUpdate'
import { ACCOUNT_DELETE_MUTATION } from '@/graphql/account/accountDelete'
import { ACCOUNT_CHANGE_STATUS_MUTATION } from '@/graphql/account/accountChangeStatus'

export const AccountService = {
    get (id) {
        return apolloClient.query({
            query: ACCOUNT_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.account)
    },
    all (variables, fields, additionalParams) {
        const QUERY = (typeof fields !== 'undefined') ? buildAccountsQuery(fields, additionalParams) : ACCOUNTS_QUERY
        return apolloClient.query({
            query: QUERY,
            variables,
        }).then(response => response.data.accounts)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: ACCOUNT_CREATE_MUTATION,
            variables,
        }).then(response => response.data.accountCreate)
    },
    update (id, account) {
        return apolloClient.mutate({
            mutation: ACCOUNT_UPDATE_MUTATION,
            variables: {
                id: id,
                account: account,
            },
        }).then(response => response.data.accountUpdate)
    },
    changeStatus (id, status) {
        return apolloClient.mutate({
            mutation: ACCOUNT_CHANGE_STATUS_MUTATION,
            variables: {
                id: id,
                status: status,
            },
        }).then(response => response.data.accountChangeStatus)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: ACCOUNT_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.accountDelete)
    },
}
