<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.term.overview', 1)" :to="{ name: 'correspondence-dashboard' }" />
            </template>

            <h1 class="q-mb-none">{{ $tc('views.correspondence.dashboard.title', 1) }}</h1>
        </page-header>

        <!-- <div class="row">
            <div class="col-12">
                <h3>TODO: New query that retrieves:</h3>
                <h4>CorrespondenceItem</h4>
                <ul>
                    <li>Number of items per CorrespondenceItemStatus</li>
                </ul>
                <h4>ProcessedCorrespondenceItem</h4>
                <ul>
                    <li>Number of items per ProcessedCorrespondenceItemStatus</li>
                </ul>
            </div>
        </div> -->

        <in-page-footer>
            <base-button
                :to="{ name: 'correspondence-letter-create' }"
                :label="$tc('common.correspondence.letter.write-new-letter', 1)"
                icon="mib-common-file-text-add"
                primary-button
            />

            <base-button
                :to="{ name: 'correspondence-email-create' }"
                :label="$tc('common.correspondence.email.write-new-email', 1)"
                icon="mib-email-action-add"
                primary-button
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
export default {
    name: 'CorrespondenceDashboard',
    meta () {
        return {
            title: this.$tc('views.correspondence.dashboard.title', 1),
        }
    },
    data () {
        return {
        }
    },
}
</script>
