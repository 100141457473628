import { apolloClient } from '@/apollo/client'

import { COMMISSION_SPLIT_CREATE_MUTATION } from '@/graphql/commissionSplit/commissionSplitCreate'
import { COMMISSION_SPLIT_UPDATE_MUTATION } from '@/graphql/commissionSplit/commissionSplitUpdate'
import { COMMISSION_SPLIT_DELETE_MUTATION } from '@/graphql/commissionSplit/commissionSplitDelete'

export const CommissionSplitService = {
    create (contractId, variables) {
        return apolloClient.mutate({
            mutation: COMMISSION_SPLIT_CREATE_MUTATION,
            variables: {
                contractId: contractId,
                commissionSplit: variables,
            },
        }).then(response => response.data.commissionSplitCreate)
    },
    update (id, variables) {
        return apolloClient.mutate({
            mutation: COMMISSION_SPLIT_UPDATE_MUTATION,
            variables: {
                id: id,
                commissionSplit: variables,
            },
        }).then(response => response.data.commissionSplitUpdate)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: COMMISSION_SPLIT_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.commissionSplitDelete)
    },
}
