<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="downloadInfo.breadcrumb" />
            </template>
        </page-header>

        <div class="row">
            <div class="col-xs-12 text-center">
                <illustration-heading
                    :title="downloadInfo.title"
                    :img="downloadInfo.illustration"
                />

                <div class="col-xs-12">
                    <p>{{ downloadInfo.text }}</p>

                    <div v-if="downloadInfo.downloadIsPossible" class="file-name-wrapper">
                        <span class="file-name"><q-icon :name="downloadInfo.fileTypeIcon" size="xs" class="q-mr-sm" /> {{ state.temporaryFileInfo?.name }}</span>
                    </div>
                </div>

                <base-button
                    class="q-mt-md"
                    :label="$tc('views.download.download-file', 1)"
                    :disabled="downloadInfo.status !== TemporaryDownloadFileStatus.VALID"
                    primary-button
                    @click="triggerDownload"
                />
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { useI18n } from '@/composables/i18n'
import { useStore } from '@/composables/store'
import { getFileDownloadURL } from '@/helpers/file'
import { formatISO, isPast } from 'date-fns'
import { formatDate, formatTime } from '@/helpers/date'
import { computed, reactive } from 'vue'

const TemporaryDownloadFileStatus = Object.freeze({
    VALID: 'VALID',
    INVALID: 'INVALID',
    EXPIRED: 'EXPIRED',
    UNAUTHORIZED: 'UNAUTHORIZED',
})

export default {
    name: 'Downloads',
    meta () {
        return {
            title: this.downloadInfo.title,
        }
    },
    props: {
        encodedTemporaryFileInfo: {
            type: String,
            required: true,
        },
    },
    setup (props) {
        // Composables
        const store = useStore()
        const { t, tc } = useI18n()

        // State
        let status
        let expDate
        let temporaryFileInfo = {}
        let authorized = false
        try {
            temporaryFileInfo = JSON.parse(atob(props.encodedTemporaryFileInfo))
            expDate = new Date(temporaryFileInfo.exp * 1000) // Encoded value is in seconds since 1970, we need ms though.
            authorized = temporaryFileInfo.userId === store.state.user.id
            if (!authorized) {
                status = TemporaryDownloadFileStatus.UNAUTHORIZED
            } else if (isPast(expDate)) {
                status = TemporaryDownloadFileStatus.EXPIRED
            } else {
                status = TemporaryDownloadFileStatus.VALID
            }
        } catch (e) {
            status = TemporaryDownloadFileStatus.INVALID
        }
        const state = reactive({
            temporaryFileInfo,
        })

        // Computed
        const downloadInfo = computed(() => {
            const info = {
                authorized: authorized,
                downloadIsPossible: false,
                status,
            }
            const fileExtension = state.temporaryFileInfo?.name?.split('.').pop() || null
            const fileType = ['csv', 'json', 'pdf', 'png', 'tsv', 'txt', 'xls', 'xlsx', 'xml', 'zip'].includes(fileExtension) ? fileExtension : 'general'

            switch (status) {
                case TemporaryDownloadFileStatus.VALID:
                    Object.assign(info, {
                        title: tc('views.download.download-file', 1),
                        text: tc('views.download.download-file--info', 1, { date: formatDate(formatISO(expDate)), time: formatTime(formatISO(expDate)) }),
                        illustration: 'file-download-laptop',
                        fileTypeIcon: `mib-itx-file-type-${fileType}`,
                        breadcrumb: state.temporaryFileInfo?.name || tc('views.download.download-file', 1),
                        downloadIsPossible: true,
                    })
                    break
                case TemporaryDownloadFileStatus.EXPIRED:
                case TemporaryDownloadFileStatus.INVALID:
                    Object.assign(info, {
                        title: tc(`views.download.file-not-found--${status.toLowerCase()}`, 1),
                        text: tc(`views.download.file-not-found--${status.toLowerCase()}--info`, 1),
                        illustration: 'not-found-folder-file',
                        breadcrumb: tc(`views.download.file-not-found--${status.toLowerCase()}`, 1),
                    })
                    break
                case TemporaryDownloadFileStatus.UNAUTHORIZED:
                    Object.assign(info, {
                        title: t('views.download.unautorized'),
                        text: t('views.download.unautorized--info'),
                        illustration: 'monitor-access-denied',
                        breadcrumb: t('views.download.unautorized'),
                    })
                    break
            }

            return info
        })

        // Functions
        function triggerDownload () {
            window.location.assign(getFileDownloadURL(state.temporaryFileInfo.id))
        }

        return {
            // Static
            TemporaryDownloadFileStatus,

            // State
            state,

            // Computed
            downloadInfo,

            // Functions
            triggerDownload,
        }
    },
}
</script>

<style lang="scss" scoped>
.file-name-wrapper {
    display: inline-block;
    margin: 1em;
    cursor: default;
}
.file-name {
    padding: 1em;
    background-color: var(--color-white);

    font-weight: bold;
}
</style>
