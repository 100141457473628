<template>
    <global-search
        ref="globalSearch"
        v-bind="$attrs"
        link-search-result-components
        @open-search-result="handleOpenSearchResult"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" /><!-- Named slots -->
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template><!-- Scoped slots -->
    </global-search>
</template>

<script>
import { EventBus } from '@/event-bus'
import GlobalSearch from '@/components/search/GlobalSearch'

export default {
    name: 'MainHeaderSearch',
    components: {
        GlobalSearch,
    },
    created () {
        EventBus.$on('shortcut:globalSearch', this.setFocus)
    },
    beforeDestroy () {
        EventBus.$off('shortcut:globalSearch', this.setFocus)
    },
    methods: {
        setFocus () {
            this.$refs.globalSearch.setFocus()
        },
        handleOpenSearchResult (searchResultWrapper) {
            if (searchResultWrapper) {
                let location
                switch (searchResultWrapper.model.__typename) {
                    case 'Person':
                    case 'Company':
                        location = this.$router.resolve({ name: 'contact-detail', params: { id: searchResultWrapper.model.id } })
                        break
                    case 'Application':
                        location = this.$router.resolve({
                            name: 'application-detail',
                            params: { contactId: searchResultWrapper.model.customer.id, id: searchResultWrapper.model.id },
                        })
                        break
                    case 'Contract':
                        location = this.$router.resolve({
                            name: 'contract-detail',
                            params: { contactId: searchResultWrapper.model.customer.id, id: searchResultWrapper.model.id },
                        })
                        break
                }
                if (location.href !== this.$route.path) this.$router.push(location.href)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
// Slight visual effect on input field
::v-deep .q-field__control {
    box-shadow: $defaultBoxShadow inset;
}

// Mobile styles
// mq($to: …) instead of $from (mobile first) used as these styles are not the default ones
@include mq($to: $sizeBreakpointMd) {
    $sizeMobileSearchWrapperHeight: 60px;

    .search-wrapper {
        position: absolute;
        top: $sizeLayoutHeaderHeight;
        left: 0;
        width: 100%;
        height: $sizeMobileSearchWrapperHeight;
        padding: ($sizeSpacingSm + 2px) $sizeSpacingMd 0;
        z-index: 100;

        background: var(--q-color-primary-dark);

        box-shadow: $defaultBoxShadow;
    }

    .search-results-wrapper {
        max-height: calc(100vh - #{$sizeLayoutHeaderHeight} - #{$sizeMobileSearchWrapperHeight});
        top: $sizeMobileSearchWrapperHeight;

        border-left: none;
        border-right: none;
    }
}
</style>
