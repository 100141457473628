import gql from 'graphql-tag'

export const USER_ASSIGN_ROLES_MUTATION = gql`
    mutation userAssignRoles(
        $input: userAssignRolesInput!
    ) {
        userAssignRoles(
            input: $input
        ) {
            id
            roles {
                id
                name
                description
            }
        }
    }
`
