<template>
    <page-loading-indicator
        v-if="!correspondenceLetter"
        :number-of-breadcrumb-elements="2"
        actions
        :number-of-next-actions="0"
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.correspondence.letter.letter', 2)" :to="{ name: 'correspondence-letters' }" />
                <q-breadcrumbs-el :label="$tc('common.correspondence.letter.write-letter', 1)" :to="{ name: 'correspondence-letter-update', params: { id: correspondenceLetter.id } }" />
            </template>

            <h1>{{ $tc('common.correspondence.letter.write-letter', 1) }}</h1>

            <template v-slot:actions>
                <actions
                    v-if="correspondenceLetter.actions"
                    :actions="correspondenceLetter.actions"
                    :number-of-next-best-actions="0"
                    @click="handleActions"
                />

                <form-dialog
                    ref="correspondenceLetterDeleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleCorrespondenceLetterDelete"
                >
                    <template v-slot:title>{{ $tc('common.correspondence.letter.delete-letter', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="common.correspondence.letter.delete-letter--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:name><b>{{ correspondenceLetter.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <correspondence-item-compose ref="correspondenceItemCompose" :correspondence-item="correspondenceLetter" @submit="onSubmit" />

                <in-page-footer>
                    <base-button
                        :label="$t('common.term.cancel')"
                        flat
                        primary-button
                        @click="cancel"
                    />
                    <base-button
                        data-test="btn:submit"
                        :label="$t('common.term.save')"
                        primary-button
                        outline
                        :disable="isProcessing"
                        :loading="isProcessing && !continueToPreviewAfterProcessing"
                        @click="triggerProcessing(false)"
                    />
                    <base-button
                        data-test="btn:submit"
                        :label="$t('common.term.save-and-continue')"
                        primary-button
                        :disable="isProcessing"
                        :loading="isProcessing && continueToPreviewAfterProcessing"
                        @click="triggerProcessing(true)"
                    />
                </in-page-footer>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { CorrespondenceLetter } from '@/models/models'
import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'
import CorrespondenceItemCompose from '@/components/correspondence/CorrespondenceItemCompose'

export default {
    name: 'CorrespondenceLetterUpdate',
    meta () {
        return {
            title: this.$tc('common.correspondence.letter.write-letter', 1),
        }
    },
    components: {
        Actions,
        FormDialog,
        CorrespondenceItemCompose,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            correspondenceLetter: null,
            isProcessing: false,
            continueToPreviewAfterProcessing: false,
        }
    },
    created () {
        // TODO improvement @TFU: Redirect if item with given ID should not have this view. (Due to its status.)
        CorrespondenceLetter.objects.get(this.id).then(correspondenceLetter => {
            this.correspondenceLetter = correspondenceLetter
        })
    },
    methods: {
        triggerProcessing (continueToPreviewAfterProcessing) {
            if (continueToPreviewAfterProcessing === true) this.continueToPreviewAfterProcessing = true
            this.$refs.correspondenceItemCompose.triggerSubmit()
        },
        onSubmit (data) {
            this.isProcessing = true
            const { name, languageId, confidentialityNotice, differentiateFormality, targetObjects, contents, attentionOfs, addressOverrides, senderOverrides } = data

            const variables = {
                name,
                targetObjectIds: targetObjects.map(targetObject => targetObject.id),
                languageId,
                confidentialityNotice,
                attentionOfs: (attentionOfs === null) ? null : JSON.stringify(attentionOfs),
                addressOverrides: (addressOverrides === null) ? null : JSON.stringify(addressOverrides),
                senderOverrides: (senderOverrides === null) ? null : JSON.stringify(senderOverrides),
                differentiateFormality,
                contents: contents.map(documentContent => documentContent.getVariablesObject()),
            }

            this.correspondenceLetter.update(variables)
                .then(() => {
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.correspondence.correspondence-letter-saved-success', 1),
                    })
                    if (this.continueToPreviewAfterProcessing) this.$router.push({ name: 'correspondence-letter-preview', params: { id: this.id } })
                })
                .catch(error => {
                    this.continueToPreviewAfterProcessing = false
                    this.$refs.correspondenceItemCompose.$refs.correspondenceItemForm.showFormErrorMessage(error)
                    this.$refs.correspondenceItemCompose.$refs.correspondenceItemForm.resetFormSubmitStatus()
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$tc('common.notifications.correspondence.correspondence-letter-saved-error', 1),
                    })
                })
                .finally(() => {
                    this.isProcessing = false
                })
        },
        cancel () {
            this.$router.push({ name: 'correspondence-letter-list', params: { statusGroup: 'pending' } })
        },
        handleActions (action) {
            switch (action.key) {
                case CorrespondenceLetter.action.DELETE:
                    this.$refs.correspondenceLetterDeleteDialog.open()
                    break
            }
        },
        handleCorrespondenceLetterDelete () {
            this.correspondenceLetter.delete()
                .then(() => {
                    this.$refs.correspondenceLetterDeleteDialog.close()
                    this.$router.replace({ name: 'correspondence-letter-list' })
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.correspondence.correspondence-letter-deleted-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.correspondenceLetterDeleteDialog.showFormErrorMessage(error)
                    this.$refs.correspondenceLetterDeleteDialog.resetFormSubmitStatus()
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$tc('common.notifications.correspondence.correspondence-letter-deleted-error', 1),
                    })
                })
        },
    },
}
</script>
