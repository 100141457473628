import gql from 'graphql-tag'

export const COMMISSION_TYPE_ACCOUNTING_CONFIGURATION_QUERY = gql`
    query commissionTypeAccountingConfiguration(
        $id: ID!
    ) {
        commissionTypeAccountingConfiguration(
            id: $id
        ) {
            id
            commission_type
            postingType {
                id
                key
                name
                name_language_versions
                created_at
                updated_at
            }
            posting_text_part
            commissionExpenseAccount {
                id
                type
                status
                number
                name
            }
            commissionIncomeAccount {
                id
                type
                status
                number
                name
            }
            created_at
            updated_at
        }
    }
`
