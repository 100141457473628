<template>
    <q-table
        ref="qTable"
        v-bind="$attrs"
        :data="items"
        :columns="columns"
        :visible-columns="visibleColumns"
        row-key="id"
        :pagination.sync="pagination"
        :selected.sync="selected"
        :expanded.sync="expanded"
        :loading="loading"
        :rows-per-page-options="[10, 20, 50, 100]"
        binary-state-sort
        :class="['base-table', { 'sticky': sticky, 'no-top-padding': noTopPadding }]"
        flat
        square
        dense
        v-on="$listeners"
        @request="onRequest"
        @row-dblclick="onRowDoubleClick"
    >
        <template v-slot:top>
            <slot name="tabs" />
            <slot name="quickfilters" />
            <q-space />

            <q-input
                v-if="showFilter"
                v-model="filterQuery"
                dense
                filled
                square
                debounce="300"
                :label="$tc('common.term.filter')"
                :autofocus="filterAutofocus"
            >
                <template v-slot:append>
                    <q-icon v-if="filterQuery === ''" name="mib-search-alternate" size="1rem" />
                    <q-icon
                        v-else
                        name="mib-search-remove"
                        size="1rem"
                        class="cursor-pointer"
                        @click="clearFilterQuery"
                    />
                </template>
            </q-input>

            <q-select
                v-if="enableVisibleColumns"
                v-model="visibleColumns"
                multiple
                dense
                options-dense
                filled
                square
                :class="['column-select', 'truncate', 'do-not-print', { 'q-ml-sm': showFilter }]"
                :display-value="$q.lang.table.columns"
                emit-value
                map-options
                :options="columns"
                option-value="name"
                option-disable="required"
            />
        </template>

        <template v-if="$attrs.selection !== 'none'" v-slot:body-selection="slotProps">
            <q-checkbox v-if="selectionRowCondition(slotProps.row)" v-model="slotProps.selected" dense />
            <template v-else><slot name="selectionRowConditionInfo" v-bind="slotProps" /></template>
        </template>

        <template v-for="(index, name) in $slots" v-slot:[name]>
            <slot :name="name" />
        </template>

        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="slotProps">
            <slot :name="name" v-bind="slotProps" />
        </template>
    </q-table>
</template>

<script>
import { UserSettings } from '@/helpers/user'
import { OrderDirection } from '@/enums/graphql'

export default {
    name: 'BaseTable',
    inheritAttrs: false,
    props: {
        columns: {
            type: Array,
            required: true,
            validator: function (value) {
                return value.length >= 1
            },
        },
        enableVisibleColumns: {
            type: Boolean,
            default: false,
        },
        fetchObjectsFn: {
            type: Function,
            required: true,
        },
        fetchObjectsGetPaginatorFn: {
            type: Function,
            default: response => response.paginatorInfo,
        },
        fetchObjectsGetDataFn: {
            type: Function,
            default: response => response.data,
        },
        fetchObjectsPostProcessingFn: {
            type: Function,
            default: data => data,
        },
        additionalFilters: {
            type: Object,
        },
        userSettingsBasePath: {
            type: String,
        },
        paginationSortByDefaultKey: {
            type: String,
            required: true,
        },
        paginationInitialOrderDirectionDescending: {
            type: Boolean,
            default: false,
        },
        paginationRowsPerPageDefault: {
            type: Number,
            default: 50,
        },
        sticky: {
            type: Boolean,
            default: false,
        },
        selectionRowCondition: {
            type: Function,
            default: row => true,
        },
        disableSelectionDblclick: {
            type: Boolean,
            default: false,
        },
        filterAutofocus: {
            type: Boolean,
            default: false,
        },
        showFilter: {
            type: Boolean,
            default: true,
        },
        noTopPadding: {
            type: Boolean,
            default: false,
        },
        expanded: {
            type: Array,
            default: () => {
                return undefined
            },
        },
    },
    data () {
        const data = {
            filterQuery: '',
            loading: false,
            pagination: {
                sortBy: this.paginationSortByDefaultKey,
                descending: this.paginationInitialOrderDirectionDescending,
                page: 1,
                rowsPerPage: this.paginationRowsPerPageDefault,
                rowsNumber: 0,
            },
            selected: [],
            items: [],
            visibleColumns: [],
        }

        if (this.userSettingsBasePath) {
            data.userSettings = new UserSettings(this.$store.state.user)
            data.pagination.rowsPerPage = parseInt(data.userSettings.getItem(`${this.userSettingsBasePath}.pagination.rowsPerPage`)) || this.paginationRowsPerPageDefault
        }

        if (this.enableVisibleColumns) {
            if (data.userSettings) {
                const visibleColumnsUserSettings = JSON.parse(data.userSettings.getItem(`${this.userSettingsBasePath}.visibleColumns`))
                if (visibleColumnsUserSettings) {
                    data.visibleColumns = visibleColumnsUserSettings
                } else {
                    data.visibleColumns = this.getDefaultVisibleColumns()
                }
            } else {
                data.visibleColumns = this.getDefaultVisibleColumns()
            }
        } else {
            data.visibleColumns = this.getDefaultVisibleColumns()
        }

        return data
    },
    watch: {
        'filterQuery' () {
            this.fetchObjects()
        },
        'additionalFilters' () {
            this.fetchObjects()
        },
        'pagination.rowsPerPage' (rowsPerPage) {
            if (this.userSettings) this.userSettings.setItem(`${this.userSettingsBasePath}.pagination.rowsPerPage`, rowsPerPage)
        },
        'visibleColumns' (visibleColumns) {
            if (this.enableVisibleColumns && this.userSettings) this.userSettings.setItem(`${this.userSettingsBasePath}.visibleColumns`, JSON.stringify(visibleColumns))
        },
    },
    created () {
        this.fetchObjects()
    },
    methods: {
        clearFilterQuery () {
            this.filterQuery = ''
        },
        onRequest (props) {
            let { page, rowsPerPage, sortBy, descending } = props.pagination

            this.pagination.page = page
            this.pagination.rowsPerPage = rowsPerPage
            this.pagination.sortBy = sortBy
            this.pagination.descending = descending

            this.fetchObjects()
        },
        onRowDoubleClick (event, row) {
            if (this.$attrs.selection && !this.disableSelectionDblclick) {
                switch (this.$attrs.selection) {
                    case 'single':
                        if (this.selected.includes(row)) {
                            this.selected = []
                        } else {
                            if (this.selectionRowCondition(row)) this.selected = [row]
                        }
                        this.$emit('update:selected', this.selected)
                        break
                    case 'multiple':
                        if (this.selected.includes(row)) {
                            this.selected.splice(this.selected.indexOf(row), 1)
                        } else {
                            if (this.selectionRowCondition(row)) this.selected.push(row)
                        }
                        this.$emit('update:selected', this.selected)
                        break
                }
            }
        },
        fetchObjects () {
            this.loading = true
            const variables = {
                page: this.pagination.page,
                count: this.pagination.rowsPerPage,
                orderField: this.pagination.sortBy.toUpperCase(),
                orderDirection: (this.pagination.descending) ? OrderDirection.DESC : OrderDirection.ASC,
                filterQuery: this.filterQuery,
            }

            if (this.additionalFilters) Object.assign(variables, this.additionalFilters)

            this.fetchObjectsFn(variables).then(response => {
                const paginatorInfo = this.fetchObjectsGetPaginatorFn(response)
                const data = this.fetchObjectsGetDataFn(response)

                this.pagination.rowsNumber = paginatorInfo.total
                this.loading = false
                this.items = this.fetchObjectsPostProcessingFn(data)
                this.$emit('update:paginator-info', paginatorInfo)
                this.$emit('update:items', this.items)
                this.$emit('update:response', response)
            })
        },
        setSortByKey (key) {
            this.pagination.sortBy = key
        },
        setOrderDirectionDescending (isDescending) {
            this.pagination.descending = isDescending
        },
        getDefaultVisibleColumns () {
            const defaultVisibleColumns = []
            this.columns.forEach(column => {
                if (!column.hideInitially) defaultVisibleColumns.push(column.name)
            })
            return defaultVisibleColumns
        },
    },
}
</script>

<style lang="scss" scoped>
.base-table {
    .column-select {
        min-width: 150px;
    }

    &.q-table--dense {
        ::v-deep .q-table {
            thead tr,
            tbody tr,
            tbody td {
                height: 36px; // Matches the height of "dense" q-table rows that have a small round button in it (e.g. in the actions column)
            }
        }
    }

    &.no-top-padding {
        ::v-deep .q-table__top {
            padding-top: 0;
        }
    }
}

$sizes: (
    xs: $sizeSpacingXs,
    sm: $sizeSpacingSm,
    md: $sizeSpacingMd,
    lg: $sizeSpacingLg,
    xl: $sizeSpacingXl
);

@media screen and (min-height: 480px) {
    .sticky {
        height: 100%;

        @each $name, $size in $sizes {
            &.q-mb-#{$name} {
                height: calc(100% - #{map-get($sizes, $name)});
                margin-bottom: 0;
            }
        }
    }
}
</style>
