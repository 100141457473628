import { apolloClient } from '@/apollo/client'

import { GLOBAL_SEARCH_QUERY } from '@/graphql/globalSearch'

export const GlobalSearchService = {
    search (variables, abortController) {
        return apolloClient.query({
            query: GLOBAL_SEARCH_QUERY,
            variables,
            context: {
                fetchOptions: {
                    signal: abortController.signal,
                },
            },
        }).then(response => response.data.globalSearch)
    },
}
