<template>
    <form
        data-test="form:contract-select-product"
        class="row q-col-gutter-sm"
        @submit.prevent="onFormSubmit"
    >
        <div class="col-xs-12">
            <!-- eslint-disable vue/no-v-html -->
            <info-box
                v-if="formErrorMessage"
                type="negative"
                data-test="error:global-message"
                v-html="formErrorMessage"
            />
            <!-- eslint-enable -->

            <div class="product-selection row q-col-gutter-sm justify-center">
                <div class="col-xs-12 col-md-7 col-lg-5 col-xl-4">
                    <form-builder item-key="productId" autofocus />
                </div>
                <div class="column">
                    <div class="col">
                        <base-button
                            data-test="btn:submit"
                            type="submit"
                            :label="$t('common.term.continue')"
                            :disable="formSubmitStatus"
                            :loading="formSubmitStatus"
                            primary-button
                        />
                    </div>
                </div>
            </div>
        </div>

        <in-page-footer>
            <base-button
                :label="$t('common.term.cancel')"
                flat
                primary-button
                @click="$router.go(-1)"
            />
        </in-page-footer>
    </form>
</template>

<script>
import { formBuilderMixin } from '@/mixins/formBuilderMixin'

export default {
    name: 'ContractSelectProductForm',
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: {
            type: 'root',
            children: [{
                type: 'field',
                key: 'productId',
                label: 'common.term.product',
                inputType: 'product',
                validators: [
                    {
                        type: 'required',
                    },
                ],
            }],
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .product-selection {
    @include mq($to: $sizeBreakpointMd) {
        justify-content: start;
    }
}
</style>
