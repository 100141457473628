<template>
    <form
        data-test="form:posting-type"
        class="row q-col-gutter-sm q-mb-md"
        @submit.prevent="onFormSubmit"
    >
        <!-- PostingTypeForm -->
        <div class="col-xs-12">
            <!-- eslint-disable vue/no-v-html -->
            <info-box
                v-if="formErrorMessage"
                type="negative"
                data-test="error:global-message"
                class="q-mb-xs"
                no-margin
                v-html="formErrorMessage"
            />
            <!-- eslint-enable -->

            <div class="row q-col-gutter-md">
                <div class="col-xs-12">
                    <localized-input
                        v-model="formData.name"
                        :label="$tc('forms.field.label.posting-type.name', 1)"
                        :validation="$v.formData.name"
                        autofocus
                        :disable="isSystemPostingType"
                    />
                </div>
            </div>
        </div>

        <in-page-footer>
            <!-- TODO: Create BaseButtonCancel component -->
            <base-button
                :label="$t('common.term.cancel')"
                primary-button
                flat
                @click="cancel"
            />
            <!-- TODO: Create BaseButtonSubmit component -->
            <base-button
                type="submit"
                data-test="btn:submit"
                :label="currentMode === ViewMode.CREATE ? $tc('views.accounting.posting-type.add-posting-type', 1) : $t('common.term.save')"
                :disable="formSubmitStatus || isSystemPostingType"
                :loading="formSubmitStatus"
                primary-button
            />
        </in-page-footer>
    </form>
</template>

<script>
import { ViewMode } from '@/enums'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import { required } from 'vuelidate/lib/validators'
import LocalizedInput from '@/components/form/LocalizedInput.vue'

export default {
    name: 'PostingTypeForm',
    components: {
        LocalizedInput,
    },
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: {
            type: 'root',
            children: [],
        },
    },
    props: {
        postingType: {
            type: Object,
            required: false,
            default () {
                return undefined
            },
        },
    },
    data () {
        const data = {
            ViewMode,
            currentMode: this.postingType ? ViewMode.UPDATE : ViewMode.CREATE,
            formData: {
                name: {},
            },
        }
        this.$store.state.user.tenantSettings.systemLanguages.forEach(systemLanguage => {
            data.formData.name[systemLanguage.id] = ''
        })

        if (data.currentMode === ViewMode.UPDATE) {
            Object.assign(data.formData, {
                name: this.postingType.name_language_versions,
            })
        }

        return data
    },
    computed: {
        isSystemPostingType () {
            return this.postingType?.isSystemPostingType
        },
    },
    validations () {
        const validations = {
            formData: {
                name: {},
            },
        }
        validations.formData.name[this.$store.state.user.tenantSettings.defaultSystemLanguage.id] = {
            required,
        }
        return validations
    },
    methods: {
        cancel () {
            this.$router.push({ name: 'accounting-posting-type-list' })
        },
    },
}
</script>
