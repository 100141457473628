<template>
    <fake-select
        v-if="state.initialFetch"
        v-bind="$attrs"
    />
    <base-select-filter-next
        v-else
        v-bind="$attrs"

        :value="value"
        :loading="state.isLoading"
        :options="state.options"
        option-value="id"

        :filter-server-side="filterServerSide"

        v-on="$listeners"
        @filter="onFilter"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" /><!-- Named slots -->
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template><!-- Scoped slots -->
    </base-select-filter-next>
</template>

<script>
import { ContactType } from '@/enums/graphql'
import BaseSelectFilterNext from '@/components/form/BaseSelectFilterNext'
import { Contact } from '@/models/models'
import { onBeforeMount, reactive, watch } from 'vue'
import { isEqual } from 'lodash'
import FakeSelect from '@/components/FakeSelect.vue'

export default {
    name: 'ContactSelect',
    components: {
        FakeSelect,
        BaseSelectFilterNext,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        filters: {
            type: Object,
            default: () => {},
        },
        filterServerSide: {
            type: Boolean,
            default: true,
        },
        excludeIds: {
            type: Array,
            default: () => null,
        },
    },
    setup (props, context) {
        // Data
        const state = reactive({
            initialFetch: true,
            isLoading: false,
            abortController: null,
            options: [],
        })

        // Functions
        async function fetchOptions (filters) {
            if (state.abortController) state.abortController.abort()
            state.isLoading = true
            state.abortController = new AbortController()
            const response = await Contact.service.allWithOptions(Object.assign({}, props.filters, filters), {
                fields: `
                    id
                    contactNumber {
                        number
                    }
                    emailAddresses {
                        id
                        category {
                            id
                            key
                            name
                        }
                        label {
                            id
                            key
                            label
                        }
                        email
                        is_main_email_address
                    }
                    phoneNumbers {
                        id
                        category {
                            id
                            key
                            name
                        }
                        label {
                            id
                            key
                            label
                        }
                        phone_number
                        is_main_phone_number
                    }
                    ... on Person {
                        first_name
                        last_name
                    }
                    ... on Company {
                        company_name
                    }
                `,
                abortController: state.abortController,
            })

            let contacts = response.data.map(contact => {
                switch (contact.type) {
                    case ContactType.PERSON:
                        contact.label = `${contact.first_name} ${contact.last_name} (${contact.contactNumber.number})`
                        break
                    case ContactType.COMPANY:
                        contact.label = `${contact.company_name} (${contact.contactNumber.number})`
                        break
                }
                return contact
            })

            if (props.excludeIds && props.excludeIds.length) contacts = contacts.filter(contact => !props.excludeIds.includes(contact.id))

            state.abortController = null
            state.initialFetch = false
            state.isLoading = false
            return contacts
        }
        async function onFilter (filterQuery, doneFn) {
            if (props.filterServerSide) {
                let options = await fetchOptions({ filterQuery })

                const query = filterQuery.toLowerCase()
                options = options.filter(option => {
                    return option['label'].toLowerCase().indexOf(query) > -1
                })

                context.emit('fetchedObjects', options)
                doneFn(() => {
                    state.options = options
                })
            }
        }

        // Watch
        watch(() => props.filters, async (newValue, oldValue) => {
            if (isEqual(oldValue, newValue)) return
            const options = await fetchOptions()
            state.options = options
            context.emit('fetchedObjects', options)
        })

        // Lifecycle hooks
        onBeforeMount(async () => {
            const options = await fetchOptions()
            state.options = options
            context.emit('fetchedObjects', options)
        })

        return {
            // Data
            state,

            // Functions
            onFilter,
        }
    },
}
</script>
