<template>
    <div>
        <page-header no-breadcrumbs>
            <h2>{{ $tc('views.contact.settings.customer-relation.customer-relation--title', 1) }} <span v-if="contact" class="subtitle">{{ $tc('views.contact.settings.customer-relation.customer-relation--title--related-contacts', 1, { customerName: contact.getContactName(), consultantName: $store.state.user.consultant ? $store.state.user.consultant.getContactName() : $store.state.user.getUserName() }) }}</span></h2>
        </page-header>

        <info-box><p>{{ $t('views.contact.settings.customer-relation.customer-relation--description') }}</p></info-box><!-- TODO @MTR: Adapt info text, so it's possible to display it, unrelated to if the user has or has not access to the correspondence module. -->

        <loading-indicator v-if="!contact" auto-height margin-top="5em" />
        <contact-consultant-relation-settings-update-form
            v-else
            ref="contactConsultantRelationSettingsUpdateForm"
            :contact="contact"
            @submit="onSubmit"
        />
    </div>
</template>

<script>
import ContactConsultantRelationSettingsUpdateForm from '@/components/contact/ContactConsultantRelationSettingsUpdateForm'
import { Contact } from '@/models/contact'

export default {
    name: 'ContactConsultantRelationSettingsUpdate',
    components: {
        ContactConsultantRelationSettingsUpdateForm,
    },
    props: {
        contactId: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            contact: null,
        }
    },
    created () {
        Contact.objects.get(this.contactId).then(contact => {
            this.contact = contact
        })
    },
    methods: {
        onSubmit ({ formality, correspondenceSalutationOverride, correspondenceSalutationId }) {
            this.contact.updateConsultantRelationSettings({ formality, correspondenceSalutationOverride, correspondenceSalutationId })
                .then(() => {
                    this.$refs.contactConsultantRelationSettingsUpdateForm.resetFormSubmitStatus()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.contact.settings.contact-consultant-relation-settings-saved-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.contactConsultantRelationSettingsUpdateForm.showFormErrorMessage(error)
                    this.$refs.contactConsultantRelationSettingsUpdateForm.resetFormSubmitStatus()
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$tc('common.notifications.contact.settings.contact-consultant-relation-settings-saved-error', 1),
                    })
                })
        },
    },
}
</script>
