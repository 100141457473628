import { isPlainObject } from '@/helpers/index'
import { i18n } from '@/i18n'
import {
    decimal,
    email, maxLength,
    maxValue,
    minLength,
    minValue, not,
    required,
    requiredIf,
    requiredUnless, sameAs, url
} from 'vuelidate/lib/validators'
import {
    isAfterOrEqualToDate,
    isBeforeOrEqualToDate,
    isTrue,
    isValidIsoDate, isValidMonthDay, lessThan,
    minRequirementsLowercase,
    minRequirementsSpecialCharOrNumber,
    minRequirementsUppercase, moreThan, notEqualTo,
    requiredProperty
} from '@/helpers/form'

export function localizeProperties (object, localeProperties = ['label', 'equalToLabel', 'hint', 'infoTitle', 'infoText', 'errorMessage']) {
    return new Proxy(object, {
        get (target, prop, receiver) {
            const originalValue = Reflect.get(...arguments)

            if (localeProperties.includes(prop)) {
                const args = []
                if (isPlainObject(originalValue)) {
                    args.push(originalValue.key)
                    args.push((originalValue.count) ? originalValue.count : 1)
                    if (originalValue.values) args.push(originalValue.values)
                } else {
                    args.push(originalValue)
                }
                return i18n.tc(...args)
            }

            if (isPlainObject(originalValue) || Array.isArray(originalValue)) return new Proxy(originalValue, this)
            return originalValue
        },
    })
}

function dirtyChecker () {
    return true
}

export function initializeFormValidations(validators) {
    const validations = {}
    validators.forEach(validator => {
        if (validator.validator) {
            // Use custom validator function as override.
            validations[validator.type] = validator.validator
        } else {
            // Initialize 'default' validator function using params from schema.
            switch (validator.type) {
                case 'required':
                    validations.required = required
                    break
                case 'requiredIf':
                    validations.requiredIf = requiredIf(validator.params.target)
                    break
                case 'requiredUnless':
                    validations.requiredUnless = requiredUnless(validator.params.target)
                    break
                case 'requiredProperty':
                    validations.requiredProperty = requiredProperty(validator.params.propertyName)
                    break
                case 'email':
                    validations.email = email
                    break
                case 'decimal':
                    validations.decimal = decimal
                    break
                case 'minValue':
                    validations.minValue = minValue(validator.params.min)
                    break
                case 'minLength':
                    validations.minLength = minLength(validator.params.min)
                    break
                case 'maxValue':
                    validations.maxValue = maxValue(validator.params.max)
                    break
                case 'maxLength':
                    validations.maxLength = maxLength(validator.params.max)
                    break
                case 'minRequirementsLowercase':
                    validations.minRequirementsLowercase = minRequirementsLowercase
                    break
                case 'minRequirementsUppercase':
                    validations.minRequirementsUppercase = minRequirementsUppercase
                    break
                case 'minRequirementsSpecialCharOrNumber':
                    validations.minRequirementsSpecialCharOrNumber = minRequirementsSpecialCharOrNumber
                    break
                case 'sameAs':
                    validations.sameAs = sameAs(validator.params.equalTo)
                    break
                case 'notSameAs':
                    validations.notSameAs = not(sameAs(validator.params.target))
                    break
                case 'date':
                    validations.date = isValidIsoDate
                    break
                case 'monthDay':
                    validations.monthDay = isValidMonthDay
                    break
                case 'isTrue':
                    validations.isTrue = isTrue
                    break
                case 'url':
                    validations.url = url
                    break
                case 'notEqualTo':
                    validations.notEqualTo = (inputValue) => notEqualTo(inputValue, validator.params.value, validator.params.type)
                    break
                case 'isBeforeOrEqualToDate':
                    validations.isBeforeOrEqualToDate = isBeforeOrEqualToDate(validator.params.target)
                    break
                case 'isAfterOrEqualToDate':
                    validations.isAfterOrEqualToDate = isAfterOrEqualToDate(validator.params.target)
                    break
                case 'lessThan':
                    validations.lessThan = lessThan(validator.params.target)
                    break
                case 'moreThan':
                    validations.moreThan = moreThan(validator.params.target)
                    break
                case 'dirtyChecker':
                    validations.dirtyChecker = dirtyChecker
                    break
            }
        }
    })
    return validations
}

export function createFormItems (recursionDepth, items, h) {
    const formItems = []
    items.forEach(item => {
        if (!Array.isArray(item)) { // This makes sure, to not render FormItems for nested Forms.
            formItems.push(h('form-item', {
                props: {
                    item: item,
                    recursionDepth: recursionDepth + 1,
                },
                key: item.key,
            }))
        }
    })
    return formItems
}
