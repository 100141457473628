<template>
    <div>
        <q-toggle
            :value="value"
            v-bind="$attrs"
            @input="inputValue => $emit('input', inputValue)"
        >
            <template v-if="infoText || infoTitle">
                <info-icon
                    :title="infoTitle"
                    :text="infoText"
                    class="q-mr-xs q-ml-xs all-pointer-events"
                />
            </template>

            <slot name="default" />
        </q-toggle>
        <slot v-if="$attrs.error" name="error" /><!-- TODO: styling -->
    </div>
</template>

<script>
export default {
    name: 'BaseToggle',
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        infoTitle: {
            type: String,
            default: '',
        },
        infoText: {
            type: String,
            default: '',
        },
    },
}
</script>
