import DefaultLayout from '@/layouts/Default.vue'
import ProductList from '@/views/product/ProductList.vue'
import ProductCreate from '@/views/product/ProductCreate.vue'
import ProductCreateBulk from '@/views/product/ProductCreateBulk.vue'
import ProductDetail from '@/views/product/ProductDetail.vue'
import ProductProviderList from '@/views/product/ProductProviderList.vue'
import ProductProviderCreate from '@/views/product/ProductProviderCreate.vue'
import ProductProviderUpdate from '@/views/product/ProductProviderUpdate.vue'
import ProductTemplateList from '@/views/product/ProductTemplateList.vue'
import ProductTemplateCreate from '@/views/product/ProductTemplateCreate.vue'
import ProductTemplateUpdate from '@/views/product/ProductTemplateUpdate.vue'
import { checkRouteAccessPermissionsNested } from '@/helpers/acl'

export default [
    {
        path: '/admin/product-management/',
        name: 'admin-product-management',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Product:manage'],
            can: checkRouteAccessPermissionsNested,
            appSubSection: 'admin',
            navGroup: 'products',
        },
        redirect: {
            name: 'admin-product-list',
        },
    },
    {
        path: '/admin/product-management/products/',
        name: 'admin-product',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Product:manage'],
            can: checkRouteAccessPermissionsNested,
            appSubSection: 'admin',
            navGroup: 'products',
        },
        redirect: {
            name: 'admin-product-list',
        },
        children: [
            {
                path: ':tab(active|archived)?',
                name: 'admin-product-list',
                component: ProductList,
                props: true,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'products',
                },
            },
            {
                path: 'create',
                name: 'admin-product-create',
                component: ProductCreate,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'products',
                },
            },
            {
                path: 'create/bulk',
                name: 'admin-product-create-bulk',
                component: ProductCreateBulk,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'products',
                },
            },
            {
                path: ':id(\\d+)',
                name: 'admin-product-detail',
                component: ProductDetail,
                props: true,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'products',
                },
            },
        ],
    },
    {
        path: '/admin/product-management/product-providers/',
        name: 'admin-product-provider',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['ProductProviderSettings:create', 'ProductProviderSettings:read'],
            can: checkRouteAccessPermissionsNested,
            appSubSection: 'admin',
            navGroup: 'products',
        },
        redirect: {
            name: 'admin-product-provider-list',
        },
        children: [
            {
                path: ':tab(active|inactive)?',
                name: 'admin-product-provider-list',
                component: ProductProviderList,
                props: true,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'products',
                },
            },
            {
                path: 'create',
                name: 'admin-product-provider-create',
                component: ProductProviderCreate,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'products',
                },
            },
            {
                path: ':id(\\d+)',
                name: 'admin-product-provider-update',
                component: ProductProviderUpdate,
                props: true,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'products',
                },
            },
        ],
    },
    {
        path: '/admin/product-management/product-templates/',
        name: 'admin-product-template',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['ProductTemplate:manage'],
            can: checkRouteAccessPermissionsNested,
            appSubSection: 'admin',
            navGroup: 'products',
        },
        redirect: {
            name: 'admin-product-template-list',
        },
        children: [
            {
                path: ':tab(active|archived)?',
                name: 'admin-product-template-list',
                component: ProductTemplateList,
                props: true,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'products',
                },
            },
            {
                path: 'create',
                name: 'admin-product-template-create',
                component: ProductTemplateCreate,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'products',
                },
            },
            {
                path: ':id(\\d+)',
                name: 'admin-product-template-update',
                component: ProductTemplateUpdate,
                props: true,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'products',
                },
            },
        ],
    },
]
