<template>
    <page-wrapper>
        <page-header>
            <h1>Dev Dashboard</h1>
        </page-header>

        <div class="row q-mt-md q-col-gutter-md">
            <div class="col-12 flex q-gutter-md">
                <q-card flat bordered class="temp-dashboard-card">
                    <q-card-section>
                        <h3>Options</h3>
                        <p>Some useful <b>dev only</b> things.</p>
                    </q-card-section>
                    <q-separator />
                    <q-card-actions vertical>
                        <q-toggle
                            :value="devHeader"
                            color="accent"
                            label="Enable DevHeader"
                            @input="toggleDevHeader"
                        />
                    </q-card-actions>
                </q-card>

                <q-card flat bordered class="temp-dashboard-card">
                    <q-card-section>
                        <h3>Error: Not Found</h3>
                        <p>Wildcard route that catches all the wrong URLs.</p>
                    </q-card-section>
                    <q-separator />
                    <q-card-actions vertical>
                        <base-button
                            :to="{ name: '404' }"
                            icon="mib-app-window-error-404"
                            label="Default (no redirect)"
                        />
                        <base-button
                            to="/contacts/abc"
                            label="Test 1"
                            outline
                        />
                        <base-button
                            to="/path/to/some/file.pdf"
                            label="Test 2"
                            outline
                        />
                        <base-button
                            to="/something"
                            label="Test 3"
                            outline
                        />
                    </q-card-actions>
                </q-card>

                <q-card flat bordered class="temp-dashboard-card">
                    <q-card-section>
                        <h3>Error: General Error</h3>
                        <p>A general error page that can be shown when something goes wrong in any component.</p>
                    </q-card-section>
                    <q-separator />
                    <q-card-actions vertical>
                        <base-button
                            :to="{ name: 'error' }"
                            icon="mib-alert-triangle"
                            label="Default"
                        />
                        <base-button
                            :to="{ name: 'error', params: { errorNumber: 'x234' } }"
                            label="With error number"
                            outline
                        />
                        <base-button
                            :to="{ name: 'error', params: { title: 'Oops…', text: 'Whoa – a custom error message.' } }"
                            label="Custom title and text"
                            outline
                        />
                        <base-button
                            :to="{ name: 'error', params: { errorNumber: '123', title: 'Dang!', text: 'What can we say? We’re sorry? Ideally, this text would explain what happened.' } }"
                            label="Custom title, text and error number"
                            outline
                        />
                    </q-card-actions>
                </q-card>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
export default {
    name: 'DevDashboard',
    meta () {
        return {
            title: 'Dev Dashboard',
        }
    },
    computed: {
        devHeader () {
            return this.$store.state.devHeader
        },
    },
    methods: {
        toggleDevHeader () {
            this.$store.dispatch('toggleDevHeader')
        },
    },
}
</script>

<style lang="scss" scoped>
.temp-dashboard-card {
    width: calc(25% - 20px);
    min-width: 20em;
}
</style>
