import gql from 'graphql-tag'

export const CANCELLATION_CHANGE_STATUS_MUTATION = gql`
    mutation cancellationChangeStatus(
        $id: ID!
        $status: CancellationStatus!
        $cancellation_date: Date
    ) {
        cancellationChangeStatus(
            id: $id
            status: $status
            cancellation_date: $cancellation_date
        ) {
            id
            status
            cancellation_date
        }
    }
`
