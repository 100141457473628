export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'activeFrom',
            label: 'forms.field.label.mail-processing.active-from',
            inputType: 'calendar',
            validators: [
                { type: 'date' },
                {
                    type: 'isBeforeOrEqualToDate',
                    params: {
                        target: 'activeUntil',
                        targetLabel: 'forms.field.label.mail-processing.active-until',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'activeUntil',
            label: 'forms.field.label.mail-processing.active-until',
            inputType: 'calendar',
            validators: [
                { type: 'date' },
                {
                    type: 'isAfterOrEqualToDate',
                    params: {
                        target: 'activeFrom',
                        targetLabel: 'forms.field.label.mail-processing.active-from',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'notificationEmailOverride',
            label: 'forms.field.label.mail-processing.notification-email-address',
            infoText: 'forms.field.label.mail-processing.notification-email-address--info',
            inputType: 'text',
            validators: [
                { type: 'email' },
            ],
        },
        {
            type: 'field',
            key: 'pricePerProcessedMailItem',
            label: 'forms.field.label.mail-processing.price-per-processed-mail-item',
            infoText: 'forms.field.label.mail-processing.price-per-processed-mail-item--info',
            inputType: 'currency',
            prefix: 'CHF',
            validators: [
                { type: 'required' },
                { type: 'decimal' },
                {
                    type: 'minValue',
                    params: {
                        'min': 0,
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'errorNotificationEmail',
            label: 'forms.field.label.mail-processing.error-notification-email-address',
            infoText: 'forms.field.label.mail-processing.error-notification-email-address--info',
            inputType: 'text',
            validators: [
                { type: 'required' },
                { type: 'email' },
            ],
        },
    ],
}
