import { sha256 } from '@/helpers/index'

export async function wrapContentStoreItems (items) {
    const contentStoreItemWrappers = []

    for (let i = 0; i < items.length; i++) {
        const item = items[i]
        const hash = await sha256(JSON.stringify(item))
        const contentStoreItemWrapper = {
            id: hash,
            status: null,
            item,
        }
        contentStoreItemWrappers.push(contentStoreItemWrapper)
    }

    return contentStoreItemWrappers
}
