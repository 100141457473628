import gql from 'graphql-tag'

export const POSTINGS_QUERY = gql`
    query postings(
        $page: Int = 1
        $count: Int = 1000
        $orderField: PostingOrderField = NUMBER
        $orderDirection: OrderDirection = DESC
        $filterQuery: String
        $filterTypeIds: [ID!]
        $filterGroupIds: [ID!]
        $filterDebitAccountIds: [ID!]
        $filterCreditAccountIds: [ID!]
        $filterAccountIds: [ID!]
        $filterAmountFrom: Float
        $filterAmountTo: Float
        $filterDateFrom: DateTimeTz
        $filterDateUntil: DateTimeTz
        $filterHasAttachments: Boolean
        $filterIsConfidential: Boolean
    ) {
        postings(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                query: $filterQuery
                type_ids: $filterTypeIds
                group_ids: $filterGroupIds
                debit_account_ids: $filterDebitAccountIds
                credit_account_ids: $filterCreditAccountIds
                account_ids: $filterAccountIds
                amount_from: $filterAmountFrom
                amount_to: $filterAmountTo
                date_from: $filterDateFrom
                date_until: $filterDateUntil
                has_attachments: $filterHasAttachments
                is_confidential: $filterIsConfidential
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                type {
                    id
                    key
                    name
                    name_language_versions
                    created_at
                    updated_at
                }
                group {
                    id
                }
                date
                number {
                    id
                    number
                }
                text
                debitAccount {
                    id
                    name
                    number
                }
                creditAccount {
                    id
                    name
                    number
                }
                amount
                attachments {
                    id
                    name
                    size
                    mime_type
                }
                is_confidential
                created_at
                updated_at
            }
        }
    }
`
