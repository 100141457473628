import gql from 'graphql-tag'

export const APPLICATION_CHANGE_STATUS_MUTATION = gql`
    mutation applicationChangeStatus(
        $id: ID!
        $status: ApplicationStatus!
        $submissionSendDate: Date
        $submissionResponseDate: Date
        $createNewContract: Boolean
        $contractNumber: String
        $productFieldValues: JSON
        $startDate: Date
        $endDate: Date
        $premium: Float
        $generalAgencyId: ID
    ) {
        applicationChangeStatus(
            id: $id
            status: $status
            submission_send_date: $submissionSendDate
            submission_response_date: $submissionResponseDate
            create_new_contract: $createNewContract
            contract_number: $contractNumber
            product_field_values: $productFieldValues
            start_date: $startDate
            end_date: $endDate
            premium: $premium
            general_agency_id: $generalAgencyId
        ) {
            id
            number {
                number
            }
            external_reference_number
            status
            sourceContract {
                id
            }
            targetContract {
                id
            }
            on_hold_until
            submission_send_date
            submission_response_date

            # TODO @MTR: Add required info
        }
    }
`
