<template>
    <div class="col-xs-12">
        <div class="row q-col-gutter-md">
            <grid-card
                :columns="{
                    xs: 12,
                    sm: 12,
                    md: 6,
                    lg: 6,
                    xl: 4
                }"
                container-type-normal
                :heading="$tc('common.product-provider.product-provider-info', 2)"
            >
                <form-builder-next
                    v-slot="{ formBuilder }"
                    :form="productProviderSettingsForm"
                    :initial-data="state.initialData"
                    in-page-footer
                    :submit-button-label="productProviderSettings ? $t('common.term.save') : $t('views.admin.products.product-providers.add-product-provider-settings')"
                    @cancel="$emit('cancel')"
                    @submit="onSubmit"
                />
            </grid-card>

            <grid-card
                v-can="'Feature:ecoHub:core'"
                :columns="{
                    xs: 12,
                    sm: 12,
                    md: 6,
                    lg: 6,
                    xl: 4
                }"
                :heading="$tc('views.admin.products.product-providers.eco-hub.eco-hub-process-integration', 1)"
            >
                <template v-if="!store.state.ecoHubTechUser || !formComponent?.formBuilder.form.data.igb2bInsurerCode">
                    <info-box v-if="!store.state.ecoHubTechUser" type="warning">
                        <p>{{ $t('views.admin.products.product-providers.eco-hub.status-message.no-tech-user') }}</p>

                        <template v-slot:action>
                            <base-button
                                :to="{ name: 'eco-hub-tech-user-detail' }"
                                :label="$tc('views.admin.eco-hub.tech-user.enroll-tech-user', 1)"
                                primary-button
                                outline
                            />
                        </template>
                    </info-box>

                    <info-box v-if="!formComponent?.formBuilder.form.data.igb2bInsurerCode" no-margin>
                        <p>{{ $t('views.admin.products.product-providers.eco-hub.status-message.no-igb2b-insurer-code') }}</p>
                    </info-box>
                </template>

                <div v-else>
                    <info-box
                        v-if="agreementPermissions && agreementPermissions !== 'FULL_PERMISSIONS' && availableDxpProcessVersionMappings.length"
                        type="negative"
                        icon="mib-alert-triangle"
                        icon-color="negative"
                    >
                        <p>{{ $t(`views.admin.products.product-providers.eco-hub.status-message.permissions--${kebabCase(agreementPermissions)}`) }}</p>
                    </info-box>

                    <!-- Processes -->
                    <info-box v-if="!availableDxpProcessVersionMappings.length" type="warning" no-margin>
                        <i18n path="views.admin.products.product-providers.eco-hub.status-message.no-supported-processes-for-insurer-code" tag="p">
                            <template v-slot:igb2bInsurerCode><b>{{ formComponent?.formBuilder.form.data.igb2bInsurerCode }}</b></template>
                        </i18n>
                    </info-box>

                    <template v-else>
                        <q-markup-table
                            flat
                            square
                            dense
                        >
                            <thead>
                                <tr>
                                    <th class="text-left">{{ $tc('views.admin.products.product-providers.eco-hub.eco-hub-process', 1) }}</th>
                                    <th class="text-center">{{ $t('views.admin.products.product-providers.eco-hub.column-title--activated') }}</th>
                                    <th class="text-center">{{ $t('views.admin.products.product-providers.eco-hub.column-title--automatic-processing') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="dxpProcessVersionMapping in availableDxpProcessVersionMappings" :key="`enabled-process-${dxpProcessVersionMapping.dxpProcess.id}`">
                                    <td class="text-left">{{ $t(`views.admin.products.product-providers.eco-hub.document-type.${kebabCase(dxpProcessVersionMapping.dxpProcess.id)}`) }}</td>

                                    <td class="text-center">
                                        <q-toggle
                                            v-model="state.enabledProcesses"
                                            :val="dxpProcessVersionMapping.dxpProcess.id"
                                            color="positive"
                                            checked-icon="mib-check"
                                        />
                                    </td>

                                    <td class="text-center">
                                        <q-toggle
                                            v-model="state.enabledForAutomaticProcessing"
                                            :val="dxpProcessVersionMapping.dxpProcess.id"
                                            :disable="disableAutomaticProcessing(dxpProcessVersionMapping.dxpProcess.id)"
                                            color="positive"
                                            checked-icon="mib-check"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </q-markup-table>
                    </template>
                </div>
            </grid-card>
        </div>
    </div>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { computed, reactive, ref, watch } from 'vue'
import { ProductProviderSettingsForm } from '@/forms/productProviderSettings'
import { useStore } from '@/composables/store'
import { getAgreementPermissions, getAvailableDxpProcessVersionMappings } from '@/helpers/dxp'

export default {
    name: 'ProductProviderSettingsForm',
    props: {
        productProviderSettings: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
    setup (props, context) {
        // Composables
        const store = useStore()

        // Data
        const state = reactive({
            ecoHubTechUser: null,
            igb2bInsurerCodes: null,
            insurerList: null,
            initialData: null,
            enabledProcesses: [],
            enabledForAutomaticProcessing: [],
        })

        if (props.productProviderSettings) {
            state.initialData = {
                displayName: props.productProviderSettings.display_name,
            }
            if (props.productProviderSettings.igb2bInsurerCode) state.initialData.igb2bInsurerCode = props.productProviderSettings.igb2bInsurerCode.id
            if (props.productProviderSettings?.dxpServiceProviderSettings?.enabledProcesses.length) state.enabledProcesses = props.productProviderSettings.dxpServiceProviderSettings.enabledProcesses.map(process => process.id)
            if (props.productProviderSettings?.dxpServiceProviderSettings?.enabledForAutomaticProcessing.length) state.enabledForAutomaticProcessing = props.productProviderSettings.dxpServiceProviderSettings.enabledForAutomaticProcessing.map(process => process.id)
        }

        const formContext = {
            fields: props.productProviderSettings ? ['displayName', 'igb2bInsurerCode'] : ['contactId', 'displayName', 'igb2bInsurerCode'], // TODO refactor @TFU: This is experimental. A more generic solution without using the context would be nice.
        }
        if (props.productProviderSettings?.igb2bInsurerCode) formContext.igb2bInsurerCodeInjectOptions = [props.productProviderSettings.igb2bInsurerCode]
        const productProviderSettingsForm = new ProductProviderSettingsForm({ context: formContext })

        // Template refs
        const formComponent = ref(null)

        // Computed
        const agreementPermissions = computed(() => {
            return getAgreementPermissions(formComponent.value?.formBuilder.form.data.igb2bInsurerCode)
        })
        const availableDxpProcessVersionMappings = computed(() => {
            return getAvailableDxpProcessVersionMappings(formComponent.value?.formBuilder.form.data.igb2bInsurerCode)
        })

        // Functions
        function disableAutomaticProcessing (id) {
            return !state.enabledProcesses.includes(id)
        }

        function onSubmit (formData) {
            formData = Object.assign({
                dxpServiceProviderSettings: {
                    enabledProcesses: state.enabledProcesses,
                    enabledForAutomaticProcessing: state.enabledForAutomaticProcessing,
                },
            }, formData)
            context.emit('submit', formData)
        }

        // Watchers
        watch(() => formComponent.value?.formBuilder.form.data.igb2bInsurerCode, (newValue, oldValue) => {
            if (oldValue !== undefined) {
                state.enabledProcesses = []
                state.enabledForAutomaticProcessing = []
            }
        })
        watch(() => state.enabledProcesses, () => {
            state.enabledForAutomaticProcessing = state.enabledForAutomaticProcessing.filter(process => state.enabledProcesses.includes(process))
        })

        return {
            // Data
            store,
            state,
            productProviderSettingsForm,

            // Template refs
            formComponent,

            // Computed
            agreementPermissions,
            availableDxpProcessVersionMappings,

            // Functions
            kebabCase,
            disableAutomaticProcessing,
            onSubmit,

            columns: [
                {
                    name: 'process_name',
                    label: 'process_name',
                    align: 'left',
                },
                {
                    name: 'enable_process',
                    label: 'enable_process',
                    align: 'left',
                },
                {
                    name: 'auto_processing',
                    label: 'auto_processing',
                    align: 'left',
                },
            ],
        }
    },
}
</script>
