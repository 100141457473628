<template>
    <div>
        <div class="row print-row-lg q-col-gutter-md">
            <!-- TODO improvement @MTR: Refactor to use cards with proper structure -->
            <grid-card
                v-for="item in items"
                :key="item.id"
                data-test="item:address"
                :class="['address-wrapper', `address-status-${item.status.toLowerCase()}`, { 'address-not-valid-yet': item.isNotValidYet, 'address-no-longer-valid': item.isNoLongerValid }]"
                no-content-padding
            >
                <q-item
                    class="address-label"
                    data-test="item:address-label"
                >
                    <q-item-section>
                        <q-item-label>{{ item.formattedCategoryLabel }}</q-item-label>
                        <q-item-label v-if="item.is_main_correspondence_address" caption>
                            {{ $tc('common.contact.main-correspondence-address', 1) }}
                        </q-item-label>
                        <q-item-label v-if="item.status === AddressStatus.INACTIVE" caption>
                            <template v-if="item.isNotValidYet">
                                {{ $t('common.address.valid--not-yet-valid') }}
                            </template>
                            <template v-else-if="item.isNoLongerValid">
                                <q-icon v-if="item.is_main_correspondence_address" name="mib-alert-triangle" class="q-mr-xs" />
                                {{ $t('common.address.valid--no-longer-valid') }}
                            </template>
                            <template v-else>{{ $t('common.address.valid--not-valid') }}</template>
                        </q-item-label>
                    </q-item-section>
                </q-item>

                <q-separator />

                <q-card-section
                    class="address-content"
                    data-test="item:address-content"
                >
                    <address>{{ getContactAddress(item, { multiline: true }) }}</address>

                    <dl class="address-meta">
                        <dt
                            v-if="item.status === AddressStatus.ACTIVE"
                            class="address-valid-from-title"
                        >
                            {{ $t('common.term.valid-since') }}:
                        </dt>
                        <dt
                            v-else
                            class="address-valid-from-title"
                        >
                            {{ $t('common.term.valid-from') }}:
                        </dt>
                        <dd class="address-valid-from-data" data-test="item:address-valid-from-data">{{ item.formattedValidFrom }}</dd>
                        <template v-if="item.valid_until">
                            <br>
                            <dt class="address-valid-until-title">{{ $t('common.term.valid-until') }}: </dt>
                            <dd class="address-valid-until-data" data-test="item:address-valid-until-data">{{ item.formattedValidUntil }}</dd>
                        </template>
                    </dl>
                </q-card-section>

                <template v-slot:actions>
                    <!-- TODO improvement @MTR: Refactor to use cards with proper buttons -->
                    <ul class="address-actions-list" data-test="item:address-actions">
                        <!-- TODO: Check if the addresses should include the contact name when copied (same as when the main address is copied on the ContactDetail view) -->
                        <li>
                            <a
                                class="q-link"
                                tabindex="0"
                                @click="triggerCopyToClipboard(getContactAddress(item, { multiline: true }))"
                                @keyup.enter="triggerCopyToClipboard(getContactAddress(item, { multiline: true }))"
                            ><q-icon name="mib-copy-paste" class="q-mr-xs" />
                                {{ $tc('common.address.copy-address', 1) }}
                            </a>
                        </li>

                        <li>
                            <a
                                class="q-link"
                                :href="`https://www.google.ch/maps/dir//${ encodeURIComponent(getContactAddress(item, { noPOBox: true }))}`"
                                target="_blank"
                                rel="noopener"
                            ><q-icon name="mib-style-two-pin-marker" class="q-mr-xs" />
                                {{ $t('common.address.open-in-google-maps') }}</a>
                        </li>

                        <li v-if="store.state.editMode">
                            <a
                                class="q-link edit-link"
                                data-test="btn:update-address"
                                tabindex="0"
                                @click="triggerUpdateItemWrapper(item)"
                                @keyup.enter="triggerUpdateItemWrapper(item)"
                            ><q-icon name="mib-pencil" class="q-mr-xs" />{{ $tc('common.address.edit-address', 1) }}</a>
                        </li>

                        <li v-if="store.state.editMode && !item.is_main_correspondence_address">
                            <a
                                class="q-link edit-link"
                                data-test="btn:delete-address"
                                tabindex="0"
                                @click="triggerDeleteItem(item)"
                                @keyup.enter="triggerDeleteItem(item)"
                            ><q-icon name="mib-bin" class="q-mr-xs" />{{ $tc('common.address.delete-address', 1) }}</a>
                        </li>
                    </ul>
                </template>
            </grid-card>

            <!-- Create item -->
            <form-dialog
                v-if="state.currentMode === ViewMode.CREATE"
                ref="createItemDialog"
                :form="AddressForm"
                :initial-data="initialFormData"
                @setup="onCreateFormSetup"
                @submit="handleCreateItem"
                @cancel="cancelAction"
            >
                <template v-slot:title>{{ $tc('common.address.add-address', 1) }}</template>
            </form-dialog>

            <!-- Update item -->
            <form-dialog
                v-if="state.currentMode === ViewMode.UPDATE && state.currentItem"
                ref="updateItemDialog"
                :form="AddressForm"
                :initial-data="initialFormData"
                @submit="handleUpdateItem"
                @cancel="cancelAction"
            >
                <template v-slot:title>{{ $tc('common.address.edit-address', 1) }}</template>
            </form-dialog>

            <!-- Delete item -->
            <form-dialog
                v-if="state.currentMode === ViewMode.DELETE && state.currentItem"
                ref="deleteItemDialog"
                @submit="handleDeleteItem"
                @cancel="cancelAction"
            >
                <template v-slot:title>{{ $tc('common.address.delete-address', 1) }}</template>
                <template v-slot:default>
                    <i18n v-if="state.currentItem" path="common.term.delete-item--confirm" tag="span">
                        <template v-slot:item>
                            <address><b>{{ getContactAddress(state.currentItem) }}</b></address>
                            ({{ state.currentItem.formattedCategoryLabel }})
                        </template>
                    </i18n>
                </template>
            </form-dialog>
        </div>

        <in-page-footer>
            <base-button
                :label="$tc('common.address.add-address', 1)"
                icon="mib-pin-add"
                primary-button
                data-test="btn:create-address"
                @click="triggerCreateItem"
            />
        </in-page-footer>
    </div>
</template>

<script>
import { lightFormat } from 'date-fns'
import { AddressStatus, ContactType } from '@/enums/graphql'
import { AddressForm } from '@/forms/contact/address'

import FormDialog from '@/libs/form/components/FormDialog'

import { Address } from '@/models/address'
import { copyToClipboard } from 'quasar'
import { computed } from 'vue'
import { useViewModeManager } from '@/composables/viewModeManager'
import { useOnNewEntity } from '@/composables/onNewEntity'
import { useQuasar } from '@/composables/quasar'
import { useI18n } from '@/composables/i18n'
import { useStore } from '@/composables/store'
import { getContactAddress } from '@/helpers/contact'

export default {
    name: 'ContactAddressList',
    components: {
        FormDialog,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        targetObject: {
            type: Object,
            required: true,
        },
    },
    setup (props, context) {
        const store = useStore()
        const $q = useQuasar()
        const { t, tc } = useI18n()
        const {
            // Static
            ViewMode,

            // Data
            state,

            // Template refs
            createItemDialog,
            updateItemDialog,
            deleteItemDialog,

            // Methods
            triggerCreateItem,
            triggerUpdateItem,
            triggerDeleteItem,
            handleUpdateItem,
            handleDeleteItem,
            cancelAction,
            emitCreate,
        } = useViewModeManager(context)
        useOnNewEntity(triggerCreateItem)

        // Computed
        const initialFormData = computed(() => {
            if (state.currentItem) {
                return {
                    category: state.currentItem.category?.id || null,
                    label: state.currentItem.label?.id || null,
                    poBox: state.currentItem.po_box,
                    address1: state.currentItem.address1,
                    address2: state.currentItem.address2 || '',
                    address3: state.currentItem.address3 || '',
                    zip: state.currentItem.zip,
                    city: state.currentItem.city,
                    validFrom: state.currentItem.valid_from,
                    validUntil: state.currentItem.valid_until || '',
                    isMain: state.currentItem.is_main_correspondence_address,
                    country: state.currentItem.country.id,
                }
            } else {
                return {
                    country: 'CH',
                    validFrom: lightFormat(new Date(), 'yyyy-MM-dd'),
                }
            }
        })

        // Methods
        async function triggerUpdateItemWrapper (item) {
            await triggerUpdateItem(item)
            updateItemDialog.value.state.formInstance.getItem('isMain').configuration.attributes.disable = state.currentItem.is_main_correspondence_address
        }
        function handleCreateItem (formData) {
            Address.create(props.targetObject.id, formData)
                .then(address => {
                    emitCreate(address)
                    createItemDialog.value.close()
                    // TODO: Add notification?
                })
                .catch(error => {
                    createItemDialog.value.showFormErrorMessage(error)
                    createItemDialog.value.resetFormSubmitStatus()
                })
        }
        function triggerCopyToClipboard (content) {
            content = content.replace(/<br>/g, '\n')

            copyToClipboard(content)
                .then(() => {
                    $q.notify({
                        type: 'positive',
                        message: t('common.notifications.copied-to-clipboard-success'),
                    })
                })
                .catch(() => {
                    $q.notify({
                        type: 'negative',
                        message: tc('common.term.error', 1),
                        caption: t('common.notifications.copied-to-clipboard-error'),
                    })
                })
        }
        function onCreateFormSetup (formBuilder) {
            if (!formBuilder.form.getItem('category').configuration.attributes) formBuilder.form.getItem('category').configuration.attributes = {}
            formBuilder.form.getItem('category').configuration.attributes.preSelectKey = props.targetObject.type === ContactType.PERSON ? 'private' : 'business'
        }

        return {
            // Static
            ViewMode,
            AddressStatus,
            AddressForm,

            // Data
            store,
            state,

            // Computed
            initialFormData,

            // Template refs
            createItemDialog,
            updateItemDialog,
            deleteItemDialog,

            // Methods
            getContactAddress,
            triggerCreateItem,
            triggerUpdateItemWrapper,
            triggerDeleteItem,
            handleCreateItem,
            handleUpdateItem,
            handleDeleteItem,
            cancelAction,
            emitCreate,
            triggerCopyToClipboard,
            onCreateFormSetup,
        }
    },
}
</script>

<style lang="scss" scoped>
.address-wrapper {
    //background-color: var(--color-text-inverted);

    &.address-status-active {
    }

    &.address-status-inactive {
        //background-color: var(--color-background-faded);
        color: var(--color-text-secondary);
    }

    &.address-not-valid-yet {

        .address-label {
            background-color: var(--q-color-positive-lighter); /* TODO: check if this is the right color for an address that is currently not valid */

            .q-item__label {
                color: var(--q-color-positive-dark);
            }
        }

        [class*="address-valid-from-"] {
            color: var(--q-color-positive-dark);
        }
    }

    &.address-no-longer-valid {

        .address-label {
            background-color: var(--q-color-negative-lighter);

            .q-item__label {
                color: var(--q-color-negative-dark);
            }
        }

        [class*="address-valid-until-"] {
            color: var(--q-color-negative-dark);
        }
    }
}

.address-label {
    background-color: var(--color-background-faded);

    font-size: smaller;
    font-weight: bold;

    ::v-deep .q-item__label--caption {
        font-weight: normal;
    }
}

.address-content {
    padding-bottom: $sizeSpacingSm;
}

.address-meta {
    margin: $sizeSpacingMd 0 0;

    font-size: smaller;
    color: var(--color-text-secondary);
}

.address-actions {
    background-color: var(--color-text-inverted);
}

.address-actions-list {
    @include resetList;

    padding: $sizeSpacingXs $sizeSpacingSm;
}
</style>
