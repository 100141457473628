import { apolloClient } from '@/apollo/client'

import { CONTACT_RELATIONSHIP_DEFINITIONS_QUERY } from '@/graphql/contactRelationshipDefinition/contactRelationshipDefinitions'

export const ContactRelationshipDefinitionsService = {
    all (variables) {
        return apolloClient.query({
            query: CONTACT_RELATIONSHIP_DEFINITIONS_QUERY,
            variables,
        }).then(response => response.data.contactRelationshipDefinitions)
    },
    allWithOptions (variables, options) {
        const queryOptions = {
            query: CONTACT_RELATIONSHIP_DEFINITIONS_QUERY,
            variables,
        }
        if (options?.abortController) {
            queryOptions.context = {
                fetchOptions: {
                    signal: options.abortController.signal,
                },
            }
        }
        return apolloClient.query(queryOptions).then(response => response.data.contactRelationshipDefinitions)
    },
}
