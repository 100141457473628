<template>
    <div class="document-content-formality-editor-wrapper">
        <template v-if="contents.length > 1">
            <q-tabs
                v-model="activeFormality"
                dense
                no-caps
                inline-label
                align="left"
                class="bg-grey-2"
                indicator-color="accent"
            >
                <q-tab
                    v-for="documentContent in contents"
                    :key="documentContent.formality"
                    :name="documentContent.formality"
                >
                    {{ $t(`common.contact.formality.formality--${documentContent.formality.toLowerCase()}`) }}
                    <q-icon
                        v-show="documentContent.content.$v && documentContent.content.$v.$error"
                        name="mib-alert-triangle"
                        size="1em"
                        color="negative"
                        class="q-ml-xs"
                    >
                        <q-tooltip>{{ $tc(`common.editor.formality.error--${documentContent.formality.toLowerCase()}-content`, 1) }}</q-tooltip>
                    </q-icon>
                </q-tab>
            </q-tabs>

            <!-- We use this custom panel implementation instead of q-panels, because we need to render all components from the start (v-show instead of v-if) in order to support validation. -->
            <div class="panels">
                <div
                    v-for="documentContent in contents"
                    :key="documentContent.formality"
                    :class="['panel', { 'is-active': activeFormality === documentContent.formality }]"
                >
                    <editor
                        v-model="documentContent.content"
                        :validator-configuration="validatorConfiguration"
                        :smart-object-valid-target-object-types="smartObjectValidTargetObjectTypes"
                        :readonly="readonly"
                        sticky-menu-bar
                    >
                        <template v-slot:menuBarMoreMenu>
                            <q-menu anchor="bottom right" self="top right">
                                <q-list>
                                    <q-item clickable @click="triggerRemoveFormality(documentContent.formality)">
                                        <q-item-section>
                                            <q-item-label>{{ $tc(`common.editor.formality.remove-${documentContent.formality.toLowerCase()}-content`, 1) }}</q-item-label>
                                        </q-item-section>
                                    </q-item>
                                </q-list>
                            </q-menu>
                        </template>
                    </editor>
                </div>
            </div>

            <form-dialog
                ref="removeFormalityDialog"
                double-confirm
                @confirm="handleRemoveFormality"
                @hide="formalityToRemove = null"
            >
                <template v-slot:title>{{ $t('common.editor.formality.remove-content--confirm--title') }}</template>
                <template v-if="formalityToRemove" v-slot:default>
                    <i18n path="common.editor.formality.remove-content--confirm" tag="p">
                        <template v-slot:contentType>
                            <b>{{ $t(`common.editor.formality.remove-content--confirm--content-type--${formalityToRemove.toLowerCase()}`) }}</b>
                        </template>
                    </i18n>
                </template>
            </form-dialog>
        </template>
        <template v-else>
            <editor
                v-model="contents[0].content"
                :validator-configuration="validatorConfiguration"
                :smart-object-valid-target-object-types="smartObjectValidTargetObjectTypes"
                :readonly="readonly"
                sticky-menu-bar
            >
                <template v-slot:menuBarMoreMenu>
                    <q-menu anchor="bottom right" self="top right">
                        <q-list>
                            <q-item clickable>
                                <q-item-section>
                                    <q-item-label>{{ $tc('common.correspondence.correspondence-item.differentiate-formality', 1) }}
                                        <info-icon :text="$t('common.correspondence.correspondence-item.formality--description')" />
                                    </q-item-label>
                                </q-item-section>
                                <q-menu anchor="top start" self="top end">
                                    <q-list>
                                        <q-item v-close-popup clickable @click="addFormality(DocumentContentFormality.FORMAL)">
                                            <q-item-section>
                                                <q-item-label>{{ $t(`common.editor.formality.add-${DocumentContentFormality.FORMAL.toLowerCase()}-content--extended`) }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                        <q-item v-close-popup clickable @click="addFormality(DocumentContentFormality.INFORMAL)">
                                            <q-item-section>
                                                <q-item-label>{{ $t(`common.editor.formality.add-${DocumentContentFormality.INFORMAL.toLowerCase()}-content--extended`) }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </q-list>
                                </q-menu>
                            </q-item>
                        </q-list>
                    </q-menu>
                </template>
            </editor>
        </template>
    </div>
</template>

<script>
import { DocumentContentFormality } from '@/enums/graphql'
import { DocumentContent, EditorContent } from '@/models/models'
import Editor from '@/components/editor/Editor'
import FormDialog from '@/components/form/FormDialog'

export default {
    name: 'DocumentContentFormalityEditor',
    components: {
        Editor,
        FormDialog,
    },
    props: {
        contents: {
            type: Array,
            required: true,
        },
        validatorConfiguration: {
            type: Object,
            default: () => {
                return {}
            },
        },
        differentiateFormality: {
            type: Boolean,
            default: false,
        },
        smartObjectValidTargetObjectTypes: {
            type: Array,
            default: () => {
                return []
            },
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        const data = {
            DocumentContentFormality,
            activeFormality: null,
            formalityToRemove: null,
        }

        if (this.differentiateFormality) {
            data.activeFormality = this.contents[0].formality
        }

        return data
    },
    methods: {
        addFormality (formality) {
            // Enable differentiation.
            this.$emit('update:differentiateFormality', true)

            // Set correct formality to existing content.
            this.contents[0].formality = (formality === DocumentContentFormality.FORMAL) ? DocumentContentFormality.INFORMAL : DocumentContentFormality.FORMAL

            // Add new content.
            this.contents.push(new DocumentContent({
                formality: formality,
                content: EditorContent.create({
                    schemaType: this.contents[0].content.schemaType, // Set same schemaType as existing content.
                }),
            }))

            // Activate new content tab.
            this.activeFormality = formality
        },
        triggerRemoveFormality (formality) {
            this.formalityToRemove = formality
            this.$refs.removeFormalityDialog.open()
        },
        handleRemoveFormality () {
            this.removeFormality(this.formalityToRemove)
            this.formalityToRemove = null
        },
        removeFormality (formality) {
            const contentIndex = this.contents.findIndex(content => content.formality === formality)
            if (contentIndex > -1) {
                // Remove content.
                this.contents.splice(contentIndex, 1)

                // Remove formality from remaining content.
                delete this.contents[0].formality
                this.activeFormality = null

                // Disable differentiation.
                this.$emit('update:differentiateFormality', false)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.panel {
    height: 0px;
    overflow: hidden;

    &.is-active {
        height: auto;
        overflow: visible;
    }
}
</style>
