import gql from 'graphql-tag'

const processedCorrespondenceItemFields = `
    id
    correspondenceItem {
        id
    }
    targetObject {
        ... on Company {
            id
        }
        ... on Person {
            id
        }
        ... on Contract {
            id
            customer {
                id
            }
        }
        ... on Application {
            id
            customer {
                id
            }
        }
    }
    status
    status_message
    creator {
        id
        contact_number
        first_name
        last_name
        consultingCompany {
            id
            company_name
        }
    }
    sender {
        id
        contact_number
        first_name
        last_name
        consultingCompany {
            id
            company_name
        }
    }
    recipient_address
    subject
    language {
        id
        name
    }
    file_id
    created_at
    updated_at
`

export const PROCESSED_CORRESPONDENCE_ITEMS_QUERY = gql`
    query processedCorrespondenceItems(
        $page: Int = 1
        $count: Int = 1000
        $orderField: ProcessedCorrespondenceItemOrderField = UPDATED_AT
        $orderDirection: OrderDirection = DESC
        $filterQuery: String
        $filterStatus: [ProcessedCorrespondenceItemStatus!]
        $filterCorrespondenceItemId: ID
        $filterTargetObjectType: CorrespondenceItemTargetObjectType
        $filterTargetObjectId: ID
        $filterCreatorId: ID
        $filterSenderId: ID
        $filterProcessedCorrespondenceItemType: [ProcessedCorrespondenceItemType!]
    ) {
        processedCorrespondenceItems(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                query: $filterQuery
                status: $filterStatus
                correspondence_item_id: $filterCorrespondenceItemId
                target_object_type: $filterTargetObjectType
                target_object_id: $filterTargetObjectId
                creator_id: $filterCreatorId
                sender_id: $filterSenderId
                processed_correspondence_item_type: $filterProcessedCorrespondenceItemType
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                ... on ProcessedCorrespondenceLetter {
                    ${processedCorrespondenceItemFields}
                }
                ... on ProcessedCorrespondenceEmail {
                    ${processedCorrespondenceItemFields}
                }
            }
        }
    }
`
