<template>
    <q-layout view="hHh LpR lFf" class="minimal-layout">
        <q-header elevated class="app-header bg-primary text-white">
            <q-banner
                v-if="!$store.state.online"
                inline-actions
                class="online-status-wrapper bg-negative-dark text-inverted text-center"
            >
                <q-icon
                    name="mib-wifi-warning"
                    size="1.5rem"
                    color="white"
                    class="q-mr-sm"
                />
                {{ $t('common.notifications.banner-no-internet-connection') }}
            </q-banner>

            <div class="inner-wrapper">
                <q-toolbar>
                    <q-item :to="{ name: 'dashboard' }">
                        <img src="@/assets/img/max-logo-white.svg" style="height: 30px;">
                    </q-item>

                    <q-space />

                    <locale-switch />
                </q-toolbar>
            </div>
        </q-header>

        <q-page-container>
            <router-view />
        </q-page-container>
    </q-layout>
</template>

<script>
import LocaleSwitch from '@/components/LocaleSwitch'

export default {
    components: {
        LocaleSwitch,
    },
    meta: {
        // TODO: Edit default title (used whenever no specific title is set).
        title: 'Default title',
        // TODO: We should check if it makes sense to append the system owner’s name to the title.
        titleTemplate: title => `${title} | MAX`,
        meta: {
            // TODO: Edit default description (used whenever no specific description is set).
            // This will very likely end up being the _only_ description for all pages/views as the description does
            // not add any value except for when links to the app are shared in some other tool.
            description: { name: 'description', content: 'TODO: Default description' },
        },
    },
    mounted () {
        window.addEventListener('online', this.updateOnlineStatus)
        window.addEventListener('offline', this.updateOnlineStatus)
    },
    beforeDestroy () {
        window.removeEventListener('online', this.updateOnlineStatus)
        window.removeEventListener('offline', this.updateOnlineStatus)
    },
    methods: {
        updateOnlineStatus () {
            if (navigator.onLine) {
                this.$store.dispatch('activateOnlineStatus')

                this.$q.notify({
                    message: this.$t('common.notifications.back-online'),
                    color: 'positive',
                    icon: 'mib-wifi-check',
                })
            } else {
                this.$store.dispatch('deactivateOnlineStatus')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .q-page > .row:first-child {
    margin-top: 0;
}
</style>
