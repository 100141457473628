import { apolloClient } from '@/apollo/client'

import { REGISTRATION_VALIDATE_TOKEN_MUTATION } from '@/graphql/auth/registrationValidateToken'
import { REGISTRATION_SET_PASSWORD_MUTATION } from '@/graphql/auth/registrationSetPassword'
import { REGISTRATION_VALIDATE_2FA_MUTATION } from '@/graphql/auth/registrationValidate2FA'
import { REGISTRATION_VALIDATE_2FA_BACKUP_CODE_MUTATION } from '@/graphql/auth/registrationValidate2FABackupCode'

import { LOGIN_MUTATION } from '@/graphql/auth/login'
import { LOGIN_VALIDATE_2FA_MUTATION } from '@/graphql/auth/loginValidate2FA'
import { LOGIN_VALIDATE_2FA_BACKUP_CODE_MUTATION } from '@/graphql/auth/loginValidate2FABackupCode'
import { LOGOUT_MUTATION } from '@/graphql/auth/logout'

import { FORGOT_PASSWORD_MUTATION } from '@/graphql/auth/forgotPassword'
import { RESET_PASSWORD_MUTATION } from '@/graphql/auth/resetPassword'

export const AuthService = {
    // Registration
    registration: {
        validateToken (token) {
            return apolloClient.mutate({
                mutation: REGISTRATION_VALIDATE_TOKEN_MUTATION,
                variables: {
                    token,
                },
            }).then(response => response.data.registrationValidateToken)
        },
        setPassword (processToken, password, passwordConfirmation) {
            return apolloClient.mutate({
                mutation: REGISTRATION_SET_PASSWORD_MUTATION,
                variables: {
                    token: processToken,
                    password: password,
                    password_confirmation: passwordConfirmation,
                },
            }).then(response => response.data.registrationSetPassword)
        },
        validate2fa (processToken, twoFACode) {
            return apolloClient.mutate({
                mutation: REGISTRATION_VALIDATE_2FA_MUTATION,
                variables: {
                    token: processToken,
                    two_fa_code: twoFACode,
                },
            }).then(response => response.data.registrationValidate2FA)
        },
        validate2faBackupCode (processToken, twoFABackupCode) {
            return apolloClient.mutate({
                mutation: REGISTRATION_VALIDATE_2FA_BACKUP_CODE_MUTATION,
                variables: {
                    token: processToken,
                    two_fa_backup_code: twoFABackupCode,
                },
            }).then(response => response.data.registrationValidate2FABackupCode)
        },
    },

    // Authentication
    login (email, password) {
        return apolloClient.mutate({
            mutation: LOGIN_MUTATION,
            variables: {
                email: email,
                password: password,
            },
        }).then(response => response.data.login)
    },
    validate2fa (token, twoFACode) {
        return apolloClient.mutate({
            mutation: LOGIN_VALIDATE_2FA_MUTATION,
            variables: {
                token: token,
                two_fa_code: twoFACode,
            },
        }).then(response => response.data.loginValidate2FA)
    },
    validate2faBackupCode (token, twoFABackupCode) {
        return apolloClient.mutate({
            mutation: LOGIN_VALIDATE_2FA_BACKUP_CODE_MUTATION,
            variables: {
                token: token,
                two_fa_backup_code: twoFABackupCode,
            },
        }).then(response => response.data.loginValidate2FABackupCode)
    },
    logout () {
        return apolloClient.mutate({
            mutation: LOGOUT_MUTATION,
        }).then(response => response.data.logout)
    },

    // Recovery
    forgotPassword (email, twoFACode) {
        return apolloClient.mutate({
            mutation: FORGOT_PASSWORD_MUTATION,
            variables: {
                email: email,
                two_fa_code: twoFACode,
            },
        }).then(response => response.data.forgotPassword)
    },
    resetPassword (token, password, passwordConfirmation) {
        return apolloClient.mutate({
            mutation: RESET_PASSWORD_MUTATION,
            variables: {
                token: token,
                password: password,
                password_confirmation: passwordConfirmation,
            },
        }).then(response => response.data.resetPassword)
    },
}
