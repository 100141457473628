<template>
    <node-view-wrapper as="span" data-drag-handle>
        <span
            class="smart-object"
            :class="{ selected: selected, 'unrelated': !node.attrs.relatedId }"
            data-smart-object="conditionalSpace"
            :data-id="node.attrs.id"
            @dragover="onDragOver"
            @drop="onDrop"
        >
            <q-icon name="mib-itx-open-box-character" />
        </span>
        <q-tooltip
            :delay="1000"
            anchor="center right"
            self="center left"
            :offset="[0, 20]"
        >{{ relatedId ? $tc('common.correspondence.smart-object.general.conditional-space--description', 1, { fieldName: $tc('common.correspondence.smart-object.' + normalizeTranslationId(node.attrs.relatedId), 1) }) : $t('common.correspondence.smart-object.general.conditional-space--instructions') }}</q-tooltip>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import { normalizeTranslationId } from '@/helpers'

export default {
    name: 'SmartObjectDateNodeView',
    components: {
        NodeViewWrapper,
    },
    props: {
        nodeViewProps,
    },
    data () {
        return {
            popper: null,
            showConfig: false,
            relatedId: this.node.attrs.relatedId ?? '',
        }
    },
    watch: {
        relatedId (newValue) {
            this.updateAttributes({ relatedId: newValue })
        },
    },
    methods: {
        onDragOver (event) {
            event.preventDefault()
            event.dataTransfer.dropEffect = 'link'
        },
        onDrop (event) {
            event.preventDefault()
            this.relatedId = event.dataTransfer.getData('data-id')
        },
        normalizeTranslationId (...args) {
            return normalizeTranslationId(...args)
        },
    },
}
</script>
