import { apolloClient } from '@/apollo/client'
import { UserStatus } from '@/enums/graphql'

import { USER_ME_QUERY } from '@/graphql/user/me'
import { USER_QUERY } from '@/graphql/user/user'
import { USERS_QUERY } from '@/graphql/user/users'
import { USER_CREATE_MUTATION } from '@/graphql/user/userCreate'
import { USER_DELETE_MUTATION } from '@/graphql/user/userDelete'
import { USER_CHANGE_EMAIL_MUTATION } from '@/graphql/user/userChangeEmail'
import { USER_CONFIRM_EMAIL_MUTATION } from '@/graphql/user/userConfirmEmail'
import { USER_RESEND_REGISTRATION_INVITE_MUTATION } from '@/graphql/user/userResendRegistrationInvite'
import { USER_CHANGE_STATUS_MUTATION } from '@/graphql/user/userChangeStatus'
import { USER_ASSIGN_PERSON_MUTATION } from '@/graphql/user/userAssignPerson'
import { USER_ASSIGN_ROLES_MUTATION } from '@/graphql/user/userAssignRoles'
import { USER_CHANGE_PASSWORD_MUTATION } from '@/graphql/user/changePassword'
import { ROLES_QUERY } from '@/graphql/user/roles'
import { USER_RESET_2FA_MUTATION } from '@/graphql/user/userReset2FA'
import { USER_VALIDATE_2FA_MUTATION } from '@/graphql/user/userValidate2FA'
import { USER_VALIDATE_2FA_BACKUP_CODE_MUTATION } from '@/graphql/user/userValidate2FABackupCode'

export const UserService = {
    me () {
        return apolloClient.query({
            query: USER_ME_QUERY,
        }).then(response => response.data.me)
    },
    get (id) {
        return apolloClient.query({
            query: USER_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.user)
    },
    all (variables) {
        return apolloClient.query({
            query: USERS_QUERY,
            variables,
        }).then(response => response.data.users)
    },
    create (email, roleIds) {
        return apolloClient.mutate({
            mutation: USER_CREATE_MUTATION,
            variables: {
                email: email,
                role_ids: roleIds,
            },
        }).then(response => response.data.userCreate)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: USER_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.userDelete)
    },
    changeEmail (id, email) {
        return apolloClient.mutate({
            mutation: USER_CHANGE_EMAIL_MUTATION,
            variables: {
                id,
                email,
            },
        }).then(response => response.data.userChangeEmail)
    },
    confirmEmail (token) {
        return apolloClient.mutate({
            mutation: USER_CONFIRM_EMAIL_MUTATION,
            variables: {
                token,
            },
        }).then(response => response.data.userConfirmEmail)
    },
    resendInvitation (id) {
        return apolloClient.mutate({
            mutation: USER_RESEND_REGISTRATION_INVITE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.userResendRegistrationInvite)
    },
    deactivate (id) {
        return apolloClient.mutate({
            mutation: USER_CHANGE_STATUS_MUTATION,
            variables: {
                id: id,
                status: UserStatus.INACTIVE,
            },
        }).then(response => response.data.userChangeStatus)
    },
    invite (id) {
        return apolloClient.mutate({
            mutation: USER_CHANGE_STATUS_MUTATION,
            variables: {
                id: id,
                status: UserStatus.INVITED,
            },
        }).then(response => response.data.userChangeStatus)
    },
    assignPerson (id, personId) {
        return apolloClient.mutate({
            mutation: USER_ASSIGN_PERSON_MUTATION,
            variables: {
                input: {
                    user_id: id,
                    person_id: personId,
                },
            },
        }).then(response => response.data.userAssignPerson)
    },
    assignRoles (id, roleIds) {
        return apolloClient.mutate({
            mutation: USER_ASSIGN_ROLES_MUTATION,
            variables: {
                input: {
                    user_id: id,
                    role_ids: roleIds,
                },
            },
        }).then(response => response.data.userAssignRoles)
    },
    changePassword (password, newPassword, newPasswordConfirmation) {
        return apolloClient.mutate({
            mutation: USER_CHANGE_PASSWORD_MUTATION,
            variables: {
                password: password,
                new_password: newPassword,
                new_password_confirmation: newPasswordConfirmation,
            },
        }).then(response => response.data.changePassword)
    },
    getRoles () {
        return apolloClient.query({
            query: ROLES_QUERY,
        }).then(response => response.data.roles)
    },
    reset2FA (password) {
        return apolloClient.mutate({
            mutation: USER_RESET_2FA_MUTATION,
            variables: {
                password,
            },
        }).then(response => response.data.userReset2FA)
    },
    validate2FA (twoFACode) {
        return apolloClient.mutate({
            mutation: USER_VALIDATE_2FA_MUTATION,
            variables: {
                two_fa_code: twoFACode,
            },
        }).then(response => response.data.userValidate2FA)
    },
    validate2faBackupCode (twoFABackupCode) {
        return apolloClient.mutate({
            mutation: USER_VALIDATE_2FA_BACKUP_CODE_MUTATION,
            variables: {
                two_fa_backup_code: twoFABackupCode,
            },
        }).then(response => response.data.userValidate2FABackupCode)
    },
}
