import { ApolloLink } from 'apollo-link'
import { Cookies } from 'quasar'
import { walkJSON } from '@/helpers'
import { AutoLogout } from '@/auto-logout'
import store from '@/store/store'
import * as modelClasses from '@/models/models'

export const languageMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => {
        const context = {
            headers: {
                langCode: localStorage.getItem('locale') || process.env.VUE_APP_I18N_LOCALE,
                ...headers,
            },
        }
        return context
    })
    return forward(operation)
})

export const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => {
        const context = {
            headers: {
                'X-Requested-With': 'max',
                ...headers,
            },
        }
        return context
    })
    return forward(operation)
})

export const initializeModels = function (response) {
    walkJSON(response, (value, key, node, parent) => {
        if (key === '__typename') {
            const ModelClass = modelClasses[value] || modelClasses.Model
            parent.node[Array.isArray(parent.node) ? parent.index : parent.key] = new ModelClass(parent.value)
        }
    })
}

export const initializeModelsAfterware = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
        try {
            initializeModels(response)
        } catch (error) {
            console.error(error)
        } finally {
            // eslint-disable-next-line no-unsafe-finally
            return response
        }
    })
})

export const processJwtPayloadAfterware = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
        if (Cookies.has('jwt_header_payload')) {
            const payload = JSON.parse(atob(Cookies.get('jwt_header_payload').split('.')[1]))
            AutoLogout.startTimeout(payload.exp * 1000) // Exp claim is in number of seconds, javascript timestamps are number of milliseconds
            if (store.state.user) {
                // Use this to simulate the UI with different permission. Note: This does not influence the API in any way (sending data, accepting params).
                // payload.features.push('Feature:mailProcessing:core')
                // payload.permissions.push('CommissionRevenueReport:create')

                // Comment out when testing permissions, in order to be able to simulate a specific set of permissions.
                store.dispatch('setAclFeatures', payload.features)
                store.dispatch('setAclRoles', payload.roles)
                store.dispatch('setAclPermissions', payload.permissions)
            }
        }
        return response
    })
})

export async function onLogin (client) {
    try {
        await client.resetStore()
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('%cError on cache reset (login)', 'color: orange;', e.message)
    }
}

export async function onLogout (client) {
    if (Cookies.has('jwt_header_payload')) {
        Cookies.remove('jwt_header_payload', {
            domain: process.env.VUE_APP_COOKIE_DOMAIN,
            path: '/',
        })
    }

    try {
        await client.resetStore()
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
    }
}
