import { apolloClient } from '@/apollo/client'

import { CONTACT_QUERY } from '@/graphql/contact/contact'
import { CONTACTS_QUERY, buildContactsQuery } from '@/graphql/contact/contacts'
import { CONTACTS_EXPORT_QUERY } from '@/graphql/contact/contactsExport'
import { CONSULTANTS_QUERY } from '@/graphql/contact/consultants'
import { buildContactCreateMutation } from '@/graphql/contact/contactCreate'
import { CONTACT_UPDATE_MUTATION } from '@/graphql/contact/contactUpdate'
import { CONTACT_UPDATE_ENTITY_NUMBER_MUTATION } from '@/graphql/contact/contactUpdateEntityNumber'
import { CONTACT_CHANGE_STATUS_MUTATION } from '@/graphql/contact/contactChangeStatus'
import { CONTACT_DELETE_MUTATION } from '@/graphql/contact/contactDelete'
import { CONTACT_CONSULTANT_RELATION_SETTINGS_UPDATE_MUTATION } from '@/graphql/contact/contactConsultantRelationSettingsUpdate'

import { ADDRESS_CATEGORIES_QUERY } from '@/graphql/contact/addressCategories'
import { ADDRESS_LABELS_QUERY } from '@/graphql/contact/addressLabels'
import { CONTACT_ADDRESS_CREATE_MUTATION } from '@/graphql/contact/contactAddressCreate'
import { CONTACT_ADDRESS_UPDATE_MUTATION } from '@/graphql/contact/contactAddressUpdate'
import { CONTACT_ADDRESS_DELETE_MUTATION } from '@/graphql/contact/contactAddressDelete'

import { EMAIL_ADDRESS_CATEGORIES_QUERY } from '@/graphql/contact/emailAddressCategories'
import { EMAIL_ADDRESS_LABELS_QUERY } from '@/graphql/contact/emailAddressLabels'
import { CONTACT_EMAIL_ADDRESS_CREATE_MUTATION } from '@/graphql/contact/contactEmailAddressCreate'
import { CONTACT_EMAIL_ADDRESS_UPDATE_MUTATION } from '@/graphql/contact/contactEmailAddressUpdate'
import { CONTACT_EMAIL_ADDRESS_DELETE_MUTATION } from '@/graphql/contact/contactEmailAddressDelete'

import { PHONE_NUMBER_CATEGORIES_QUERY } from '@/graphql/contact/phoneNumberCategories'
import { PHONE_NUMBER_LABELS_QUERY } from '@/graphql/contact/phoneNumberLabels'
import { CONTACT_PHONE_NUMBER_CREATE_MUTATION } from '@/graphql/contact/contactPhoneNumberCreate'
import { CONTACT_PHONE_NUMBER_UPDATE_MUTATION } from '@/graphql/contact/contactPhoneNumberUpdate'
import { CONTACT_PHONE_NUMBER_DELETE_MUTATION } from '@/graphql/contact/contactPhoneNumberDelete'

import { COUNTRIES_QUERY } from '@/graphql/countries'
import { GENERAL_AGENCIES_QUERY } from '@/graphql/contact/generalAgencies'

export const ContactService = {
    // Contact
    get (id) {
        return apolloClient.query({
            query: CONTACT_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.contact)
    },
    all (variables, fields) {
        const QUERY = (typeof fields !== 'undefined') ? buildContactsQuery(fields) : CONTACTS_QUERY
        return apolloClient.query({
            query: QUERY,
            variables,
        }).then(response => response.data.contacts)
    },
    // TODO @TFU: Refactor all service methods to this kind of function signature when upgrading to Vue 3. Ideally create an abstraction for apolloClient.query that is able to handle request cancellations generically.
    allWithOptions (variables, options) {
        const QUERY = (typeof options?.fields !== 'undefined') ? buildContactsQuery(options.fields) : CONTACTS_QUERY
        const queryOptions = {
            query: QUERY,
            variables,
        }
        if (options?.abortController) {
            queryOptions.context = {
                fetchOptions: {
                    signal: options.abortController.signal,
                },
            }
        }
        return apolloClient.query(queryOptions).then(response => response.data.contacts)
    },
    exportAll () {
        return apolloClient.query({
            query: CONTACTS_EXPORT_QUERY,
        }).then(response => response.data.contactsExport)
    },
    consultants: {
        all (variables) {
            return apolloClient.query({
                query: CONSULTANTS_QUERY,
                variables,
            }).then(response => response.data.consultants)
        },
    },
    create (variables) {
        const options = {}
        if (variables.emailAddressInputs) options.includeEmailAddressInputs = true
        if (variables.phoneNumberInputs) options.includePhoneNumberInputs = true
        return apolloClient.mutate({
            mutation: buildContactCreateMutation(options),
            variables,
        }).then(response => response.data.contactCreate)
    },
    update (variables) {
        return apolloClient.mutate({
            mutation: CONTACT_UPDATE_MUTATION,
            variables,
        }).then(response => response.data.contactUpdate)
    },
    updateContactNumber (id, valueStructure) {
        return apolloClient.mutate({
            mutation: CONTACT_UPDATE_ENTITY_NUMBER_MUTATION,
            variables: {
                contactId: id,
                valueStructure: valueStructure,
            },
        }).then(response => response.data.contactUpdateEntityNumber)
    },
    changeStatus (variables) {
        return apolloClient.mutate({
            mutation: CONTACT_CHANGE_STATUS_MUTATION,
            variables,
        }).then(response => response.data.contactChangeStatus)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: CONTACT_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.contactDelete)
    },
    updateConsultantRelationSettings (variables) {
        return apolloClient.mutate({
            mutation: CONTACT_CONSULTANT_RELATION_SETTINGS_UPDATE_MUTATION,
            variables: {
                contactConsultantRelationSettings: variables,
            },
        }).then(response => response.data.contactConsultantRelationSettingsUpdate)
    },

    // Address
    getAddressCategories () {
        return apolloClient.query({
            query: ADDRESS_CATEGORIES_QUERY,
        }).then(response => response.data.addressCategories)
    },
    getAddressLabels (variables) {
        return apolloClient.query({
            query: ADDRESS_LABELS_QUERY,
            variables,
        }).then(response => response.data.addressLabels)
    },
    createAddress (variables) {
        return apolloClient.mutate({
            mutation: CONTACT_ADDRESS_CREATE_MUTATION,
            variables,
        }).then(response => response.data.contactAddressCreate)
    },
    updateAddress (addressId, addressVariables) {
        return apolloClient.mutate({
            mutation: CONTACT_ADDRESS_UPDATE_MUTATION,
            variables: {
                id: addressId,
                address: addressVariables,
            },
        }).then(response => response.data.contactAddressUpdate)
    },
    deleteAddress (addressId) {
        return apolloClient.mutate({
            mutation: CONTACT_ADDRESS_DELETE_MUTATION,
            variables: {
                id: addressId,
            },
        }).then(response => response.data.contactAddressDelete)
    },

    // EmailAddress
    getEmailAddressCategories () {
        return apolloClient.query({
            query: EMAIL_ADDRESS_CATEGORIES_QUERY,
        }).then(response => response.data.emailAddressCategories)
    },
    getEmailAddressLabels (variables) {
        return apolloClient.query({
            query: EMAIL_ADDRESS_LABELS_QUERY,
            variables,
        }).then(response => response.data.emailAddressLabels)
    },
    createEmailAddress (variables) {
        return apolloClient.mutate({
            mutation: CONTACT_EMAIL_ADDRESS_CREATE_MUTATION,
            variables,
        }).then(response => response.data.contactEmailAddressCreate)
    },
    updateEmailAddress (emailAddressId, emailAddressVariables) {
        return apolloClient.mutate({
            mutation: CONTACT_EMAIL_ADDRESS_UPDATE_MUTATION,
            variables: {
                id: emailAddressId,
                emailAddress: emailAddressVariables,
            },
        }).then(response => response.data.contactEmailAddressUpdate)
    },
    deleteEmailAddress (emailAddressId) {
        return apolloClient.mutate({
            mutation: CONTACT_EMAIL_ADDRESS_DELETE_MUTATION,
            variables: {
                id: emailAddressId,
            },
        }).then(response => response.data.contactEmailAddressDelete)
    },

    // PhoneNumber
    getPhoneNumberCategories () {
        return apolloClient.query({
            query: PHONE_NUMBER_CATEGORIES_QUERY,
        }).then(response => response.data.phoneNumberCategories)
    },
    getPhoneNumberLabels (variables) {
        return apolloClient.query({
            query: PHONE_NUMBER_LABELS_QUERY,
            variables,
        }).then(response => response.data.phoneNumberLabels)
    },
    createPhoneNumber (variables) {
        return apolloClient.mutate({
            mutation: CONTACT_PHONE_NUMBER_CREATE_MUTATION,
            variables,
        }).then(response => response.data.contactPhoneNumberCreate)
    },
    updatePhoneNumber (phoneNumberId, phoneNumberVariables) {
        return apolloClient.mutate({
            mutation: CONTACT_PHONE_NUMBER_UPDATE_MUTATION,
            variables: {
                id: phoneNumberId,
                phoneNumber: phoneNumberVariables,
            },
        }).then(response => response.data.contactPhoneNumberUpdate)
    },
    deletePhoneNumber (phoneNumberId) {
        return apolloClient.mutate({
            mutation: CONTACT_PHONE_NUMBER_DELETE_MUTATION,
            variables: {
                id: phoneNumberId,
            },
        }).then(response => response.data.contactPhoneNumberDelete)
    },

    // Misc
    getCountries () {
        return apolloClient.query({
            query: COUNTRIES_QUERY,
        }).then(response => response.data.countries)
    },

    // GeneralAgencies
    generalAgencies: {
        all (variables) {
            return apolloClient.query({
                query: GENERAL_AGENCIES_QUERY,
                variables,
            }).then(response => response.data.generalAgencies)
        },
    },
}
