import gql from 'graphql-tag'

export const CONTACT_FILE_DELETE_MUTATION = gql`
    mutation contactFileDelete(
        $id: ID!
    ) {
        contactFileDelete(
            id: $id
        ) {
            status
        }
    }
`
