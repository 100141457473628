<template>
    <fake-select
        v-if="!products.length"
        v-bind="$attrs"
    />
    <base-select-filter
        v-else
        :value="value"
        v-bind="$attrs"
        :options="products"

        option-value="id"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { Product } from '@/models/product'
import FakeSelect from '@/components/FakeSelect.vue'

export default {
    name: 'ProductSelect',
    components: {
        FakeSelect,
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
    },
    data () {
        return {
            products: [],
        }
    },
    created () {
        Product.objects.all().then(response => {
            this.products = response.data.map(product => {
                product.label = `${product.name} (${product.providerName})`
                return product
            })
        })
    },
}
</script>
