<template>
    <base-select-filter
        v-if="state.options.length"

        :value="value"
        v-bind="$attrs"
        :options="state.options"

        option-value="id"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { ContactService } from '@/services'
import { onBeforeMount, reactive, watch } from 'vue'

export default {
    name: 'ContactAddressLabelSelect',
    components: {
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        preSelectKey: {
            type: String,
        },
        filter: {
            type: Object,
            default () {
                return {}
            },
        },
    },
    setup (props) {
        // Data
        const state = reactive({
            options: [],
        })

        // Methods
        async function fetchOptions () {
            const addressLabels = await ContactService.getAddressLabels(props.filter)
            state.options.length = 0
            state.options.push(...addressLabels)
        }
        async function fetchInitialOptions () {
            await fetchOptions()

            // Set initial value
            if (props.preSelectKey && props.value === null) {
                const label = state.options.find(option => option.key === this.preSelectKey)
                if (label) this.$emit('input', label.id)
            }
        }

        // Watchers
        watch(() => props.filter, () => {
            fetchOptions()
        })

        // Lifecycle hooks
        onBeforeMount(() => {
            fetchInitialOptions()
        })

        return {
            state,
        }
    },
}
</script>
