<template>
    <base-select-filter
        v-if="options.length"

        :value="value"
        v-bind="$attrs"
        :options="options"

        option-value="id"
        :option-label="getOptionLabel"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { IGB2BInsurerCodeService } from '@/services'

export default {
    name: 'IGB2BInsurerCodeSelect',
    components: {
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        filter: {
            type: Object,
        },
        injectOptions: {
            type: Array,
            default () {
                return []
            },
        },
    },
    data () {
        return {
            options: [],
        }
    },
    created () {
        const variables = {}
        if (this.filter) Object.assign(variables, this.filter)

        IGB2BInsurerCodeService.all(variables).then(response => {
            const options = response.data

            if (this.injectOptions.length) {
                this.injectOptions.forEach(injectOption => {
                    const index = options.findIndex(option => option.id === injectOption.id)
                    if (index === -1) options.push(injectOption)
                })
                options.sort((a, b) => a.name.localeCompare(b.name))
            }

            this.options = options
        })
    },
    methods: {
        getOptionLabel (item) {
            return `${item.name} – <b class="monospace">${item.id}</b>`
        },
    },
}
</script>
