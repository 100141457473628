<template>
    <span class="contact-name" data-test="text:contact-name">
        <q-icon
            v-if="typeIcon"
            :name="contact.typeIcon"
            color="border-tertiary"
            class="q-mr-xs"
            data-test="contact-name-type-icon"
        />

        <!-- TODO improvement: Enhance avatar to use image when `contact.picture` has been implemented. -->
        <q-avatar
            v-if="avatar"
            :class="['contact-name-avatar q-mr-xs', { 'initials': !contact.picture }]"
            :size="avatarSize"
            :style="`${(coloredAvatarBackground) ? 'background-color' : 'color'}: ${stringToColor(contact.getContactName({ noFirstName: false, noLastName: false, displayName: displayName, consultingCompanyName: consultingCompanyName }))};`"
            data-test="contact-name-avatar"
        >
            <span class="contact-name-avatar-initials">{{ getContactInitials(contact, { maxLength: 2 }) }}</span>
        </q-avatar>

        <component
            :is="linked ? 'router-link' : 'span'"
            :to="linked ? { name: 'contact-detail', params: { id: contact.id } } : null"
            :target="linked ? `${linkTarget}` : null"
        >
            <!-- TODO improvement: Add `highlightFullName` prop that highlights the full name but not the additional info (if there is any) -->
            <template v-if="highlighted">
                <template v-if="contact.company_name"><b>{{ contact.getContactName({ noFirstName: true, noLastName: true, displayName: displayName }) }}</b></template>
                <template v-else>
                    <template v-if="commaSeparated"><b>{{ contact.last_name }}</b>, {{ contact.first_name }}</template>
                    <template v-else>{{ contact.getContactName({ salutation: salutation, title: title, noFirstName: noFirstName, noLastName: true }) }} <b>{{ contact.last_name }}</b></template>
                </template>
                <!-- TODO improvement: Properly add all additional info using `getContactName()` -->
                <template v-if="contactNumber"> ({{ contact.contactNumber.number }})</template>
                <!--<span v-if="displayName || consultingCompanyName && !contact.worksForUserConsultingCompany" class="additional-info"> ({{ contact.getContactName({ noFirstName: true, noLastName: true, displayName: displayName, consultingCompanyName: consultingCompanyName }) }})</span>-->
                <template v-if="currentUserHint"> ({{ $t('common.term.me') }})</template>
            </template>

            <template v-else>{{ contact.getContactName({ salutation: salutation, title: title, contactNumber: contactNumber, commaSeparated: commaSeparated, noFirstName: noFirstName, noLastName: noLastName, displayName: displayName, consultingCompanyName: consultingCompanyName, currentUserHint: currentUserHint, nestedParentheses: nestedParentheses }) }}</template>
        </component>
    </span>
</template>

<script>
import { getContactInitials } from '@/helpers/contact'
import { stringToColor } from '@/helpers'

export default {
    name: 'ContactName',
    props: {
        contact: {
            type: Object,
            required: true,
        },
        salutation: {
            type: Boolean,
            default: false,
        },
        title: {
            type: Boolean,
            default: false,
        },
        contactNumber: {
            type: Boolean,
            default: false,
        },
        commaSeparated: {
            type: Boolean,
            default: false,
        },
        noFirstName: {
            type: Boolean,
            default: false,
        },
        noLastName: {
            type: Boolean,
            default: false,
        },
        displayName: {
            type: Boolean,
            default: false,
        },
        consultingCompanyName: {
            type: Boolean,
            default: false,
        },
        currentUserHint: {
            type: Boolean,
            default: false,
        },
        nestedParentheses: {
            type: Boolean,
            default: false,
        },
        highlighted: {
            type: Boolean,
            default: false,
        },
        avatar: {
            type: Boolean,
            default: false,
        },
        avatarSize: {
            type: String,
            default: '2em',
        },
        coloredAvatarBackground: {
            type: Boolean,
            default: false,
        },
        typeIcon: {
            type: Boolean,
            default: false,
        },
        linked: {
            type: Boolean,
            default: false,
        },
        linkTarget: {
            type: String,
            default: '_self',
        },
    },
    methods: {
        getContactInitials (...args) {
            return getContactInitials(...args)
        },
        stringToColor (...args) {
            return stringToColor(...args)
        },
    },
}
</script>

<style lang="scss" scoped>
.contact-name {

    .q-icon {
        margin-top: -2px;
    }
}

.contact-name-avatar {
    background-color: var(--color-background-secondary);
    color: var(--color-text-inverted);
}
.contact-name-avatar-initials {
    font-size: 0.5em;
}
</style>
