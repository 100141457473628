import { ContactRelationshipType, ContactRelationshipNodeType, ContactType } from '@/enums/graphql'

export function generateContactRelationshipDefinitionsOptions(contactRelationshipDefinitions, sourceNodeContact, targetNodeContact) {
    const options = []

    contactRelationshipDefinitions.forEach((contactRelationShipDefinition) => {
        if ((contactRelationShipDefinition.node1_valid_contact_types.includes(sourceNodeContact.type) && contactRelationShipDefinition.node2_valid_contact_types.includes(targetNodeContact.type)) || (contactRelationShipDefinition.node1_valid_contact_types.includes(targetNodeContact.type) && contactRelationShipDefinition.node2_valid_contact_types.includes(sourceNodeContact.type))) {
            if (contactRelationShipDefinition.node1_type !== contactRelationShipDefinition.node2_type) {
                // Special case for relationship with product provider.
                if (contactRelationShipDefinition.type === ContactRelationshipType.PRODUCT_PROVIDER__GENERAL_AGENCY) {
                    // Not possible to have a product provider on both ends of the relationship.
                    if ((sourceNodeContact.type === ContactType.COMPANY && sourceNodeContact.isProductProvider) && (targetNodeContact.type === ContactType.COMPANY && targetNodeContact.isProductProvider)) return
                }

                if (contactRelationShipDefinition.node1_valid_contact_types.includes(sourceNodeContact.type) && contactRelationShipDefinition.node2_valid_contact_types.includes(targetNodeContact.type)) {
                    let valid = true
                    if (contactRelationShipDefinition.node1_type === ContactRelationshipNodeType.PRODUCT_PROVIDER) {
                        valid = (sourceNodeContact.type === ContactType.COMPANY && sourceNodeContact.isProductProvider)
                        valid = valid && (targetNodeContact.type === ContactType.COMPANY && !targetNodeContact.isProductProvider && !targetNodeContact.isConsultingCompany)
                    }
                    if (valid) {
                        options.push({
                            key: `${contactRelationShipDefinition.type}:${contactRelationShipDefinition.node2_type}`,
                            ...contactRelationShipDefinition,
                        })
                    }
                }

                if (contactRelationShipDefinition.node2_valid_contact_types.includes(sourceNodeContact.type) && contactRelationShipDefinition.node1_valid_contact_types.includes(targetNodeContact.type)) {
                    let valid = true
                    if (contactRelationShipDefinition.node1_type === ContactRelationshipNodeType.PRODUCT_PROVIDER) {
                        valid = (targetNodeContact.type === ContactType.COMPANY && targetNodeContact.isProductProvider)
                        valid = valid && (sourceNodeContact.type === ContactType.COMPANY && !sourceNodeContact.isProductProvider && !sourceNodeContact.isConsultingCompany)
                    }
                    if (valid) {
                        options.push({
                            key: `${contactRelationShipDefinition.type}:${contactRelationShipDefinition.node1_type}`,
                            ...contactRelationShipDefinition,
                        })
                    }
                }
            } else {
                options.push({
                    key: `${contactRelationShipDefinition.type}:${contactRelationShipDefinition.node1_type}`,
                    ...contactRelationShipDefinition,
                })
            }
        }
    })

    return options
}
