import gql from 'graphql-tag'

export const POSTING_TYPE_CREATE_MUTATION = gql`
    mutation postingTypeCreate(
        $postingType: PostingTypeInput!
    ) {
        postingTypeCreate(
            postingType: $postingType
        ) {
            id
            key
            name
            name_language_versions
            created_at
            updated_at
        }
    }
`
