<template>
    <div class="editor-menu-bar-inner-wrapper row">
        <div :class="['flex', { 'col-xs-10 col-lg-11': enableMoreMenu, 'col-xs-12': !enableMoreMenu }]">
            <template v-for="(item, index) in itemsForCurrentSchemaType">
                <span v-if="item.type === 'divider'" :key="index" class="divider" />
                <base-button
                    v-else
                    :key="index"
                    :class="[{ 'is-active': item.isActive ? item.isActive() : null }]"
                    :title="item.title"
                    color="transparent"
                    size="sm"
                    text-color="text-primary"
                    @click="item.action"
                >
                    <q-icon :name="item.icon" />
                </base-button>
            </template>
            <template v-if="schemaType === EditorContentSchemaType.LETTER">
                <span class="divider" />
                <base-button
                    :label="$tc('common.editor.structure-block.address.insert-address-block', 1)"
                    color="transparent"
                    text-color="text-primary"
                    :disable="hasAddressBlock"
                    :title="hasAddressBlock ? $tc('common.editor.structure-block.address.insert-address-block--disabled-description', 1) : $tc('common.editor.structure-block.address.insert-address-block--description', 1)"
                    @click="insertAddressBlock"
                />
                <span class="divider" />
                <base-button
                    :label="$tc('common.editor.structure-block.metadata.insert-metadata-block', 1)"
                    color="transparent"
                    text-color="text-primary"
                    :disable="hasMetadataBlock"
                    :title="hasMetadataBlock ? $tc('common.editor.structure-block.metadata.insert-metadata-block--disabled-description', 1) : $tc('common.editor.structure-block.metadata.insert-metadata-block--description', 1)"
                    @click="insertMetadataBlock"
                />
            </template>
        </div>
        <div v-if="enableMoreMenu" class="col-xs-2 col-lg-1 flex items-start justify-end more-menu-wrapper">
            <base-button
                v-if="!readonly"
                icon="mib-navigation-menu-horizontal"
                class="action-button further-actions-menu q-mt-xs q-ml-sm"
                flat
                round
                text-color="primary"
                size="sm"
                data-test="btn:editor-menu-bar-more-actions"
            >
                <q-tooltip
                    :delay="1000"
                    anchor="center right"
                    self="center left"
                    :offset="[10, 0]"
                >
                    {{ $t('common.term.more') }}
                </q-tooltip>
                <slot name="menuBarMoreMenu" />
            </base-button>
        </div>
    </div>
</template>

<script>
import { EditorContentSchemaType } from '@max/tiptap-extensions'
import { EditorDisplayMode } from '@/enums'
import { StructureBlockAddress } from '@/editor/structureBlocks/StructureBlockAddress'
import { StructureBlockMetadata } from '@/editor/structureBlocks/StructureBlockMetadata'

export default {
    name: 'MenuBar',
    props: {
        editor: {
            type: Object,
            required: true,
        },
        schemaType: {
            type: String,
            required: true,
        },
        displayMode: {
            type: String,
            default: EditorDisplayMode.FULL,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        const items = [
            {
                icon: 'mib-itx-text-bold',
                title: this.$t('common.editor.button.bold'),
                action: () => this.editor.chain().focus().toggleBold().run(),
                isActive: () => this.editor.isActive('bold'),
            },
            {
                icon: 'mib-itx-text-italic',
                title: this.$t('common.editor.button.italic'),
                action: () => this.editor.chain().focus().toggleItalic().run(),
                isActive: () => this.editor.isActive('italic'),
            },
            {
                icon: 'mib-itx-text-strike-through',
                title: this.$t('common.editor.button.strike-through'),
                action: () => this.editor.chain().focus().toggleStrike().run(),
                isActive: () => this.editor.isActive('strike'),
            },
            {
                type: 'divider',
            },
            {
                icon: 'mib-itx-text-h1',
                title: this.$t('common.editor.button.heading-1'),
                action: () => this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
                isActive: () => this.editor.isActive('heading', { level: 1 }),
                schemaTypes: [EditorContentSchemaType.LETTER, EditorContentSchemaType.SIMPLE_RICH_TEXT],
            },
            {
                icon: 'mib-itx-text-h2',
                title: this.$t('common.editor.button.heading-2'),
                action: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
                isActive: () => this.editor.isActive('heading', { level: 2 }),
                schemaTypes: [EditorContentSchemaType.LETTER, EditorContentSchemaType.SIMPLE_RICH_TEXT],
            },
            {
                icon: 'mib-itx-text-h3',
                title: this.$t('common.editor.button.heading-3'),
                action: () => this.editor.chain().focus().toggleHeading({ level: 3 }).run(),
                isActive: () => this.editor.isActive('heading', { level: 3 }),
                schemaTypes: [EditorContentSchemaType.SIMPLE_RICH_TEXT],
            },
            {
                icon: 'mib-itx-text-paragraph',
                title: this.$t('common.editor.button.paragraph'),
                action: () => this.editor.chain().focus().setParagraph().run(),
                isActive: () => this.editor.isActive('paragraph'),
            },
            {
                icon: 'mib-list-bullets',
                title: this.$t('common.editor.button.unordered-list'),
                action: () => this.editor.chain().focus().toggleBulletList().run(),
                isActive: () => this.editor.isActive('bulletList'),
            },
            {
                icon: 'mib-list-numbers',
                title: this.$t('common.editor.button.ordered-list'),
                action: () => this.editor.chain().focus().toggleOrderedList().run(),
                isActive: () => this.editor.isActive('orderedList'),
            },
            {
                type: 'divider',
            },
            {
                icon: 'mib-eraser-1',
                title: this.$t('common.editor.button.remove-formatting'),
                action: () => this.editor.chain().focus().clearNodes().unsetAllMarks().run(),
            },
            {
                type: 'divider',
            },
            {
                icon: 'mib-undo',
                title: this.$t('common.editor.button.undo'),
                action: () => this.editor.chain().focus().undo().run(),
            },
            {
                icon: 'mib-redo',
                title: this.$t('common.editor.button.redo'),
                action: () => this.editor.chain().focus().redo().run(),
            },
        ].map(item => {
            if (typeof item.schemaTypes === 'undefined') item.schemaTypes = [EditorContentSchemaType.LETTER, EditorContentSchemaType.EMAIL, EditorContentSchemaType.SIMPLE_RICH_TEXT]
            return item
        })

        return {
            EditorContentSchemaType,
            EditorDisplayMode,
            items,
        }
    },
    computed: {
        itemsForCurrentSchemaType () {
            return this.items.filter(item => item.schemaTypes.includes(this.schemaType))
        },
        enableMoreMenu () {
            return typeof this.$slots.menuBarMoreMenu !== 'undefined'
        },
        hasAddressBlock () {
            return this.editor.state.doc.firstChild.type.name === StructureBlockAddress.name
        },
        hasMetadataBlock () {
            return [this.editor.state.doc.child(0).type.name, this.editor.state.doc.child(1).type.name].includes(StructureBlockMetadata.name)
        },
    },
    methods: {
        insertAddressBlock () {
            this.editor.commands.insertContentAt(0, { type: StructureBlockAddress.name })
        },
        insertMetadataBlock () {
            this.editor.commands.insertContentAt(this.hasAddressBlock ? 1 : 0, {
                type: StructureBlockMetadata.name,
                content: [
                    { type: 'paragraph' },
                ],
            })
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep .q-btn__wrapper {
    padding: $sizeSpacingXs ($sizeSpacingSm + $sizeSpacingXs);
}
</style>
