<template>
    <!-- TODO improvement @MTR: Refactor/tweak layout (hHh LpR lFr) so that the in-page footer does not overlap the app container/width. (And adjust the in-page footer styles accordingly.) -->
    <!-- TODO improvement @MTR: Add option to have offset so that footer content is aligned with page content (f.e. when in-page-nav is used). -->
    <q-footer bordered class="in-page-footer" :class="`text-${textColor} bg-${backgroundColor}`">
        <div class="inner-wrapper">
            <q-toolbar class="q-pt-sm q-pr-lg q-pb-sm q-pl-lg q-gutter-sm items-right">
                <slot name="default" />
            </q-toolbar>
        </div>
    </q-footer>
</template>

<script>
export default {
    name: 'InPageFooter',
    props: {
        backgroundColor: { type: String, default: 'white' },
        textColor: { type: String, default: 'text-primary' },
    },
}
</script>

<style lang="scss" scoped>
.in-page-footer {
    padding: 0;
    border-top: 1px solid #eee;
    /*
    TODO: Add vertical media query to position the footer relative (and add negative left/right margins when the viewport is less than xy px high.
    position: relative;
    left: 0; (!important?)
    margin-left: -_px;
    margin-right: -_px;
    */

    ::v-deep .q-toolbar {
        margin-left: 0;
        margin-right: 0;
        padding-left: $sizeSpacingSm;
        padding-right: $sizeSpacingSm;

        // TODO: Check variants with team: flex-start / center / flex-end
        justify-content: flex-start;
    }
}

.inner-wrapper {
    max-width: $sizeAppMaxWidth - $sizeAppDrawerLeftWidth;
    transition: max-width 60ms ease-in-out;
}

@media print {
    .in-page-footer {
        display: none;
    }
}
</style>
