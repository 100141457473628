import { checkRouteAccessPermissions, checkRouteAccessPermissionsNested } from '@/helpers/acl'

import DefaultLayout from '@/layouts/Default.vue'
import CommissionListImportExcelMappingList from '@/views/commission/CommissionListImportExcelMappingList.vue'
import CommissionListImportExcelMappingCreate from '@/views/commission/CommissionListImportExcelMappingCreate.vue'
import CommissionListImportExcelMappingUpdate from '@/views/commission/CommissionListImportExcelMappingUpdate.vue'
import CommissionListList from '@/views/commission/CommissionListList.vue'
import CommissionListCreate from '@/views/commission/CommissionListCreate.vue'
import CommissionListDetail from '@/views/commission/CommissionListDetail.vue'
import CommissionListUpdate from '@/views/commission/CommissionListUpdate.vue'
import CommissionRevenueReportCreate from '@/views/commission/CommissionRevenueReportCreate'
import CommissionTurnoverReportCreate from '@/views/commission/CommissionTurnoverReportCreate'
import CommissionTypeAccountingConfigurationList from '@/views/commission/CommissionTypeAccountingConfigurationList.vue'
import CommissionTypeAccountingConfigurationUpdate from '@/views/commission/CommissionTypeAccountingConfigurationUpdate.vue'

import CommissionDistributionRuleSetList from '@/views/commission/commissionDistributionRuleSet/CommissionDistributionRuleSetList.vue'
import CommissionDistributionRuleSetCreate from '@/views/commission/commissionDistributionRuleSet/CommissionDistributionRuleSetCreate.vue'
import CommissionDistributionRuleSetUpdate from '@/views/commission/commissionDistributionRuleSet/CommissionDistributionRuleSetUpdate.vue'
import CommissionRateAdjustmentRuleSetList from '@/views/commission/commissionRateAdjustmentRuleSet/CommissionRateAdjustmentRuleSetList'
import CommissionRateAdjustmentRuleSetCreate from '@/views/commission/commissionRateAdjustmentRuleSet/CommissionRateAdjustmentRuleSetCreate'
import CommissionRateAdjustmentRuleSetUpdate from '@/views/commission/commissionRateAdjustmentRuleSet/CommissionRateAdjustmentRuleSetUpdate'

import CommissionListManualEntryList from '@/views/commission/commissionListManualEntry/CommissionListManualEntryList.vue'
import CommissionListManualEntryCreate from '@/views/commission/commissionListManualEntry/CommissionListManualEntryCreate.vue'
import CommissionListManualEntryUpdate from '@/views/commission/commissionListManualEntry/CommissionListManualEntryUpdate.vue'

export default [
    {
        path: '/commissions/',
        name: 'commissions',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:commissions:core', 'Commissions:manage'],
            can: checkRouteAccessPermissions,
            navGroup: 'commissions',
        },
        redirect: { name: 'commission-lists', params: { status: 'pending' } },
    },
    {
        path: '/commissions/lists/',
        name: 'commission-lists',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:commissions:core', 'Commissions:manage'],
            can: checkRouteAccessPermissionsNested,
            navGroup: 'commissions',
        },
        redirect: { name: 'commission-list-list', params: { status: 'pending' } },
        children: [
            {
                path: ':status(pending|posted)',
                name: 'commission-list-list',
                component: CommissionListList,
                props: true,
                meta: {
                    navGroup: 'commissions',
                    subNavGroup: 'lists',
                },
            },
            {
                path: 'create',
                name: 'commission-list-create',
                component: CommissionListCreate,
                meta: {
                    navGroup: 'commissions',
                    subNavGroup: 'lists',
                },
            },
            {
                path: ':id(\\d+)/:status(pending|posted)',
                name: 'commission-list-detail',
                component: CommissionListDetail,
                props: true,
                meta: {
                    navGroup: 'commissions',
                    subNavGroup: 'lists',
                },
            },
            {
                path: ':id(\\d+)/update',
                name: 'commission-list-update',
                component: CommissionListUpdate,
                props: true,
                meta: {
                    navGroup: 'commissions',
                    subNavGroup: 'lists',
                },
            },
        ],
    },
    {
        path: '/commissions/manual-entries',
        name: 'commission-list-manual-entries',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:commissions:core', 'Commissions:manage'],
            can: checkRouteAccessPermissionsNested,
            navGroup: 'commissions',
        },
        redirect: {
            name: 'commission-list-manual-entry-list',
            params: { status: 'pending' },
        },
        children: [
            {
                path: ':status(pending|posted)',
                name: 'commission-list-manual-entry-list',
                component: CommissionListManualEntryList,
                props: true,
                meta: {
                    navGroup: 'commissions',
                    subNavGroup: 'manual-entry',
                },
            },
            {
                path: 'create',
                name: 'commission-list-manual-entry-create',
                component: CommissionListManualEntryCreate,
                meta: {
                    navGroup: 'commissions',
                    subNavGroup: 'manual-entry',
                },
            },
            {
                path: 'update/:id(\\d+)',
                name: 'commission-list-manual-entry-update',
                component: CommissionListManualEntryUpdate,
                props: true,
                meta: {
                    navGroup: 'commissions',
                    subNavGroup: 'manual-entry',
                },
            },
        ],
    },
    {
        path: '/commissions/commission-income-report',
        name: 'commission-revenue-report',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:commissions:core', 'CommissionRevenueReport:create'],
            can: checkRouteAccessPermissionsNested,
            navGroup: 'commissions',
        },
        redirect: {
            name: 'commission-revenue-report-create',
        },
        children: [
            {
                path: 'create',
                name: 'commission-revenue-report-create',
                component: CommissionRevenueReportCreate,
                meta: {
                    navGroup: 'commissions',
                },
            },
        ],
    },
    {
        path: '/commissions/commission-turnover-report',
        name: 'commission-turnover-report',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:commissions:core', 'CommissionTurnoverReport:create'],
            can: checkRouteAccessPermissionsNested,
            navGroup: 'commissions',
        },
        redirect: {
            name: 'commission-turnover-report-create',
        },
        children: [
            {
                path: 'create',
                name: 'commission-turnover-report-create',
                component: CommissionTurnoverReportCreate,
                meta: {
                    navGroup: 'commissions',
                },
            },
        ],
    },

    // System preferences
    {
        path: '/admin/commissions/',
        name: 'admin-commissions',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:commissions:core', 'Commissions:manage'],
            can: checkRouteAccessPermissions,
            appSubSection: 'admin',
            navGroup: 'commissions',
        },
        redirect: {
            name: 'commission-distribution-rule-sets',
        },
    },

    {
        path: '/admin/commissions/distribution-rule-sets/',
        name: 'commission-distribution-rule-sets',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:commissions:core', 'Commissions:manage'],
            can: checkRouteAccessPermissionsNested,
            appSubSection: 'admin',
            navGroup: 'commissions',
        },
        redirect: {
            name: 'commission-distribution-rule-set-list',
        },
        children: [
            {
                path: '/',
                name: 'commission-distribution-rule-set-list',
                component: CommissionDistributionRuleSetList,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'commissions',
                    subNavGroup: 'distribution',
                },
            },
            {
                path: 'create',
                name: 'commission-distribution-rule-set-create',
                component: CommissionDistributionRuleSetCreate,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'commissions',
                    subNavGroup: 'distribution',
                },
            },
            {
                path: ':id(\\d+)',
                name: 'commission-distribution-rule-set-update',
                component: CommissionDistributionRuleSetUpdate,
                props: true,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'commissions',
                    subNavGroup: 'distribution',
                },
            },
        ],
    },

    {
        path: '/admin/commissions/excel-mappings/',
        name: 'commission-list-import-excel-mappings',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:commissions:core', 'Commissions:manage'],
            can: checkRouteAccessPermissionsNested,
            appSubSection: 'admin',
            navGroup: 'commissions',
        },
        redirect: {
            name: 'commission-list-import-excel-mapping-list',
        },
        children: [
            {
                path: '/',
                name: 'commission-list-import-excel-mapping-list',
                component: CommissionListImportExcelMappingList,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'commissions',
                    subNavGroup: 'mapping',
                },
            },
            {
                path: 'create',
                name: 'commission-list-import-excel-mapping-create',
                component: CommissionListImportExcelMappingCreate,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'commissions',
                    subNavGroup: 'mapping',
                },
            },
            {
                path: ':id(\\d+)',
                name: 'commission-list-import-excel-mapping-update',
                component: CommissionListImportExcelMappingUpdate,
                props: true,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'commissions',
                    subNavGroup: 'mapping',
                },
            },
        ],
    },

    {
        path: '/admin/commissions/commission-rate-adjustments/',
        name: 'commission-rate-adjustment',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:commissions:core', 'Feature:commissions:commission-rate-adjustment', 'Commissions:manage'],
            can: checkRouteAccessPermissionsNested,
            appSubSection: 'admin',
            navGroup: 'commissions',
        },
        redirect: {
            name: 'commission-rate-adjustment-rule-set-list',
        },
        children: [
            {
                path: '/',
                name: 'commission-rate-adjustment-rule-set-list',
                component: CommissionRateAdjustmentRuleSetList,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'commissions',
                    subNavGroup: 'rateAdjustment',
                },
            },
            {
                path: 'create',
                name: 'commission-rate-adjustment-rule-set-create',
                component: CommissionRateAdjustmentRuleSetCreate,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'commissions',
                    subNavGroup: 'rateAdjustment',
                },
            },
            {
                path: ':id(\\d+)',
                name: 'commission-rate-adjustment-rule-set-update',
                component: CommissionRateAdjustmentRuleSetUpdate,
                props: true,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'commissions',
                    subNavGroup: 'rateAdjustment',
                },
            },
        ],
    },

    // Accounting related
    {
        path: '/admin/accounting/commission-type-configuration',
        name: 'commission-type-accounting-configuration',
        component: DefaultLayout,
        meta: {
            accessPermissions: ['Feature:commissions:core', 'Commissions:manage'],
            can: checkRouteAccessPermissionsNested,
            appSubSection: 'admin',
            navGroup: 'accounting',
        },
        redirect: {
            name: 'commission-type-accounting-configuration-list',
        },
        children: [
            {
                path: '/',
                name: 'commission-type-accounting-configuration-list',
                component: CommissionTypeAccountingConfigurationList,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'accounting',
                },
            },
            {
                path: ':id(\\d+)',
                name: 'commission-type-accounting-configuration-update',
                component: CommissionTypeAccountingConfigurationUpdate,
                props: true,
                meta: {
                    appSubSection: 'admin',
                    navGroup: 'accounting',
                },
            },
        ],
    },
]
