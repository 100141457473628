<template>
    <!--
        TODO:
        [ ] Badges
            [ ] Mandate
            [ ] ContractManagedByStatus
            [ ] Cancellations
    -->
    <div ref="searchWrapper" class="search-wrapper" data-test="wrapper:global-search">
        <div ref="searchInputWrapper">
            <label for="global-search-input" class="hidden">{{ $tc('common.search.search', 1) }}</label>
            <base-input
                id="global-search-input"
                ref="globalSearchInput"
                v-model="searchTerm"
                debounce="500"
                :loading="loading"
                clearable
                :class="['global-search-input', { 'required': required }]"
                data-test="input:global-search"
                :placeholder="required ? `${placeholder}*` : placeholder"
                v-bind="$attrs"
                @input="search"
                @keyup.esc="onKeyEsc"
                @keyup.up="selectPreviousSearchResult"
                @keyup.down="selectNextSearchResult"
                @keydown.enter="preventAndStopKeydownEnter"
                @keyup.enter="triggerOpenSearchResult"
                @focus="onFocus"
            >
                <slot v-for="(_, name) in $slots" :slot="name" :name="name" /><!-- Named slots -->
                <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template><!-- Scoped slots -->

                <template v-slot:append>
                    <q-icon v-if="searchTerm === null" name="mib-search-alternate" size="1rem" />
                </template>
            </base-input>
        </div>

        <q-menu
            ref="searchResultMenu"
            v-model="showSearchResults"
            no-parent-event
            persistent
            no-refocus
            no-focus
            fit
        >
            <div
                ref="searchResultWrapper"
                class="search-results-wrapper"
                data-test="wrapper:global-search-results"
            >
                <q-list v-show="flattenedSearchResults">
                    <div
                        v-for="searchResultWrapper in groupedSearchResults"
                        :key="searchResultWrapper.model.id"
                    >
                        <global-search-result-person
                            v-if="searchResultWrapper.model.__typename === 'Person'"
                            :item="searchResultWrapper"
                            :link-search-result-components="linkSearchResultComponents"
                            :enable-fetch-contracts="enableFetchContracts"
                            :enabled-result-types="enabledResultTypes"
                            :disabled="!enabledResultTypes.includes('Person')"
                            @fetch-contracts="fetchContracts"
                            @open-search-result="searchResultWrapper => $emit('open-search-result', searchResultWrapper)"
                        />
                        <global-search-result-company
                            v-else-if="searchResultWrapper.model.__typename === 'Company'"
                            :item="searchResultWrapper"
                            :link-search-result-components="linkSearchResultComponents"
                            :enable-fetch-contracts="enableFetchContracts"
                            :enabled-result-types="enabledResultTypes"
                            :disabled="!enabledResultTypes.includes('Company')"
                            @fetch-contracts="fetchContracts"
                            @open-search-result="searchResultWrapper => $emit('open-search-result', searchResultWrapper)"
                        />
                    </div>
                </q-list>
            </div>
            <div v-show="!flattenedSearchResults" class="no-search-results-wrapper additional-info no-highlighting" data-test="wrapper:no-search-results">{{ $tc('common.search.no-search-result', 0) }}</div>
        </q-menu>
    </div>
</template>

<script>
import Mark from 'mark.js'
import { GlobalSearchService, ContractService } from '@/services'
import BaseInput from '@/components/form/BaseInput'
import GlobalSearchResultPerson from '@/components/search/GlobalSearchResultPerson'
import GlobalSearchResultCompany from '@/components/search/GlobalSearchResultCompany'
import { GLOBAL_SEARCH_CONTRACT_FIELDS } from '@/graphql/globalSearch'
import { FetchContractsSearchStatus } from '@/enums'

export default {
    name: 'GlobalSearch',
    components: {
        BaseInput,
        GlobalSearchResultPerson,
        GlobalSearchResultCompany,
    },
    props: {
        placeholder: {
            type: String,
            default () {
                return this.$t('common.search.search-action')
            },
        },
        required: {
            type: Boolean,
            default: false,
        },
        linkSearchResultComponents: {
            type: Boolean,
            default: false,
        },
        enableFetchContracts: {
            type: Boolean,
            default: false,
        },
        enabledResultTypes: {
            type: Array,
            default () {
                return ['Person', 'Company', 'Application', 'Contract']
            },
        },
    },
    data () {
        return {
            loading: false,
            searchTerm: null,
            groupedSearchResults: null,
            flattenedSearchResults: null,
            currentSelectedSearchResultIndex: null,
            showSearchResults: false,
            marker: undefined,
            abortController: null,
        }
    },
    watch: {
        $route () {
            this.triggerHideSearchResults()
            this.clearSearch()
            this.$refs.globalSearchInput.$refs.baseInput.blur()
        },
    },
    beforeDestroy () {
        this.removeCheckFocusListener()
    },
    methods: {
        focus () {
            this.$refs.globalSearchInput.focus()
        },
        search () {
            if (this.searchTerm && this.searchTerm.trim().length) {
                if (this.abortController) this.abortController.abort()

                this.loading = true
                this.abortController = new AbortController()
                GlobalSearchService.search({
                    count: 50, // Number of results
                    searchTerm: this.searchTerm,
                }, this.abortController).then(async (response) => {
                    if (response.length) {
                        this.clearSearchResults()
                        this.groupedSearchResults = this.groupSearchResults(response)
                        this.flattenedSearchResults = this.flattenSearchResults(this.groupedSearchResults)
                        if (response.length === 1) this.selectSearchResult(this.flattenedSearchResults.length - 1)
                        await this.$nextTick()
                    } else {
                        this.clearSearchResults()
                    }
                    this.triggerShowSearchResults()
                    this.abortController = null
                    this.loading = false
                })
            } else {
                this.triggerHideSearchResults()
                this.clearSearch()
            }
        },
        groupSearchResults (data) {
            const groupedSearchResults = new Map()
            data.forEach(searchResult => {
                const searchResultWrapper = {
                    children: [],
                    selected: false,
                    fetchContractsSearchStatus: null,
                }
                switch (searchResult.__typename) {
                    case 'Person':
                    case 'Company':
                        if (!groupedSearchResults.has(searchResult.id)) {
                            searchResultWrapper.model = searchResult
                            groupedSearchResults.set(searchResult.id, searchResultWrapper)
                        }
                        break
                    case 'Application':
                    case 'Contract': {
                        if (!groupedSearchResults.has(searchResult.customer.id)) {
                            searchResultWrapper.model = searchResult.customer
                            groupedSearchResults.set(searchResult.customer.id, searchResultWrapper)
                        }

                        const customerSearchResult = groupedSearchResults.get(searchResult.customer.id)
                        customerSearchResult.children.push({
                            model: searchResult,
                            selected: false,
                        })
                        break
                    }
                }
            })
            return [...groupedSearchResults.values()]
        },
        flattenSearchResults (groupedSearchResults) {
            const flattenedSearchResults = []
            groupedSearchResults.forEach(searchResult => {
                flattenedSearchResults.push(searchResult)
                if (searchResult.children.length) flattenedSearchResults.push(...searchResult.children)
            })
            return flattenedSearchResults
        },
        clearSearch () {
            this.searchTerm = null
            this.clearSearchResults()
        },
        clearSearchResults () {
            this.groupedSearchResults = null
            this.flattenedSearchResults = null
            this.currentSelectedSearchResultIndex = null
        },
        selectSearchResult (index) {
            if (index >= 0 && index <= this.flattenedSearchResults.length - 1) {
                if (this.currentSelectedSearchResultIndex !== null) {
                    this.flattenedSearchResults[this.currentSelectedSearchResultIndex].selected = false
                }
                this.currentSelectedSearchResultIndex = index
                this.flattenedSearchResults[this.currentSelectedSearchResultIndex].selected = true
            }
        },
        unselectSearchResult (index) {
            if (index >= 0 && index <= this.flattenedSearchResults.length - 1) {
                this.flattenedSearchResults[index].selected = false
            }
        },
        selectPreviousSearchResult () {
            if (!this.flattenedSearchResults) return false
            let prevIndex
            if (this.currentSelectedSearchResultIndex === null) {
                prevIndex = this.flattenedSearchResults.length - 1
            } else {
                prevIndex = this.currentSelectedSearchResultIndex - 1
                if (prevIndex < 0) prevIndex = this.flattenedSearchResults.length - 1
            }
            this.selectSearchResult(prevIndex)
        },
        selectNextSearchResult () {
            if (!this.flattenedSearchResults) return false
            let nextIndex
            if (this.currentSelectedSearchResultIndex === null) {
                nextIndex = 0
            } else {
                nextIndex = this.currentSelectedSearchResultIndex + 1
                if (nextIndex > this.flattenedSearchResults.length - 1) nextIndex = 0
            }
            this.selectSearchResult(nextIndex)
        },
        preventAndStopKeydownEnter (event) {
            event.preventDefault()
            event.stopPropagation()
        },
        triggerOpenSearchResult () {
            if (this.currentSelectedSearchResultIndex !== null) {
                const currentSelectedSearchResultWrapper = this.flattenedSearchResults[this.currentSelectedSearchResultIndex]
                if (this.enabledResultTypes.includes(currentSelectedSearchResultWrapper.model.__typename)) this.$emit('open-search-result', currentSelectedSearchResultWrapper)
            }
        },
        async triggerShowSearchResults () {
            window.addEventListener('click', this.checkFocus)
            this.showSearchResults = true
            await this.$nextTick()
            this.markSearchResults()
            this.$refs.searchResultMenu.updatePosition()
        },
        triggerHideSearchResults () {
            this.removeCheckFocusListener()
            this.showSearchResults = false
            if (this.currentSelectedSearchResultIndex !== null) this.unselectSearchResult(this.currentSelectedSearchResultIndex)
            this.currentSelectedSearchResultIndex = null
        },
        markSearchResults () {
            const marker = new Mark(this.$refs.searchResultWrapper)
            marker.unmark()
            const searchTokens = this.searchTerm.match(/\\?.|^$/g).reduce((p, c) => { // TODO: Simplify this function, it's currently not easily readable…
                if (c === '"') {
                    p.quote ^= 1
                } else if (!p.quote && c === ' ') {
                    p.a.push('')
                } else {
                    p.a[p.a.length - 1] += c.replace(/\\(.)/, '$1')
                }
                return p
            }, { a: [''] }).a
            marker.markRegExp(new RegExp(`(^|\\s|\\()(${searchTokens.join('|')})`, 'i'), { exclude: ['.no-highlighting', '.no-highlighting *'], ignoreGroups: 1 })
        },
        removeCheckFocusListener () {
            window.removeEventListener('click', this.checkFocus)
        },
        checkFocus (event) {
            if (!this.$refs.searchInputWrapper.contains(event.target) && !this.$refs.searchResultWrapper.contains(event.target)) {
                this.triggerHideSearchResults()
            }
        },
        onFocus () {
            if (this.searchTerm) this.triggerShowSearchResults()
        },
        setFocus () {
            this.$refs.globalSearchInput.$refs.baseInput.focus()
        },
        onKeyEsc () {
            if (this.searchTerm) {
                this.triggerHideSearchResults()
                this.clearSearch()
            } else {
                this.$refs.globalSearchInput.$refs.baseInput.blur()
            }
        },
        fetchContracts (item) {
            ContractService.all({ filterCustomerId: item.model.id }, GLOBAL_SEARCH_CONTRACT_FIELDS).then(async (response) => {
                if (response.data.length > item.children.length) {
                    item.fetchContractsSearchStatus = FetchContractsSearchStatus.ADDITIONAL_CONTRACTS_LOADED
                    const contractSearchResults = response.data.map(contract => {
                        return {
                            model: contract,
                            selected: false,
                        }
                    })
                    item.children = contractSearchResults
                    this.flattenedSearchResults = this.flattenSearchResults(this.groupedSearchResults)
                    await this.$nextTick()
                    this.$refs.searchResultMenu.updatePosition()
                } else if (response.data.length === 0) {
                    item.fetchContractsSearchStatus = FetchContractsSearchStatus.NO_CONTRACTS_LOADED
                } else if (response.data.length === item.children.length) {
                    item.fetchContractsSearchStatus = FetchContractsSearchStatus.NO_ADDITIONAL_CONTRACTS_LOADED
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.search-wrapper {
    position: relative;
}

.required {
    ::v-deep .q-placeholder {
        @include placeholder {
            font-weight: bold;
        }
    }
}

::v-deep {
    .selected {
        background-color: var(--q-color-primary-lighter);
    }

    .search-results-wrapper {
        box-shadow: $defaultBoxShadow;

        color: var(--color-text-primary);
    }

    .search-result-contact {
        border-top: 1px solid var(--color-border-secondary);

        & > .q-item {
            padding-top: $sizeSpacingSm;
            padding-right: $sizeSpacingXs;
            padding-bottom: $sizeSpacingSm - 2px;
            padding-left: $sizeSpacingSm;
        }
    }

    .fetch-contracts-content-wrapper {
        display: flex;
    }

    .fetch-contracts-content {
        flex: 1 1 100%;
        min-height: 36px;
        margin-left: $sizeSpacingMd;

        border-top: 1px dotted var(--color-border-primary);
        text-align: left;

        &.text {
            padding: $sizeSpacingSm $sizeSpacingMd $sizeSpacingSm 10px;
        }

        .q-btn__wrapper {
            flex-direction: column;
            align-content: start;
            padding-left: $sizeSpacingSm - 2px;
        }

        .q-icon {
            color: var(--color-text-secondary);
        }
    }

    .search-results-contracts-wrapper {
        padding: 0 0 0 $sizeSpacingMd;

        .search-result-contract {
            padding-top: $sizeSpacingSm;
            padding-right: $sizeSpacingXs;
            padding-bottom: $sizeSpacingSm - 2px;
            padding-left: $sizeSpacingSm;

            border-top: 1px dotted var(--color-border-primary);

            .contract-info-wrapper {
                line-height: 1.4 !important;
            }

            .contract-icon {
                padding-top: $sizeSpacingXs / 2;
                padding-right: $sizeSpacingSm;
            }
        }

        .contract-base-info {
            display: inline-block;
        }

        .contract-additional-info {
            display: inline-block;
        }

        .license-plate {
            margin-left: $sizeSpacingSm;
            margin-right: $sizeSpacingSm;
        }
    }

    // Statuses & info
    .entry-status-section {
        flex-direction: row;
        align-content: center;
    }
}

.no-search-results-wrapper {
    padding: $sizeSpacingSm $sizeSpacingMd;
}
</style>
