<template>
    <form
        data-test="form:user-assign-person"
        class="row q-col-gutter-y-sm"
        @submit.prevent="onFormSubmit"
        @keyup.esc="cancel"
    >
        <div class="col-xs-12">
            <!-- eslint-disable vue/no-v-html -->
            <info-box
                v-if="formErrorMessage"
                type="negative"
                data-test="error:global-message"
                v-html="formErrorMessage"
            />
            <!-- eslint-enable -->
        </div>

        <div class="col-xs-12">
            <form-builder item-key="personId" :include-inactive="true" autofocus />
        </div>

        <div class="col-xs-12 q-gutter-x-sm">
            <base-button
                :label="$t('common.term.cancel')"
                outline
                flat
                primary-button
                @click="cancel"
            />
            <base-button
                data-test="btn:submit"
                type="submit"
                :label="(currentMode === ViewMode.CREATE) ? $t('common.term.link-action') : $t('common.term.save')"
                :disable="formSubmitStatus"
                :loading="formSubmitStatus"
                primary-button
            />
        </div>
    </form>
</template>

<script>
import { ViewMode } from '@/enums'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'

export default {
    name: 'UserAssignPersonForm',
    mixins: [formBuilderMixin],
    props: {
        personId: {
            type: String,
        },
    },
    formBuilderSettings: {
        schema: {
            'type': 'root',
            'children': [
                {
                    'type': 'field',
                    'key': 'personId',
                    'label': 'common.contact.consultant',
                    'inputType': 'consultant',
                },
            ],
        },
    },
    data () {
        const data = {
            ViewMode,
            currentMode: (this.personId) ? ViewMode.UPDATE : ViewMode.CREATE,
        }
        if (data.currentMode === ViewMode.UPDATE) {
            data.formData = {
                personId: this.personId,
            }
        }
        return data
    },
    methods: {
        cancel () {
            this.$emit('cancel')
        },
    },
}
</script>
