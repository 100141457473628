import { apolloClient } from '@/apollo/client'

import { STATEMENT_QUERY } from '@/graphql/statement/statement'
import { STATEMENTS_QUERY } from '@/graphql/statement/statements'
import { STATEMENTS_CREATE_MUTATION } from '@/graphql/statement/statementsCreate'
import { STATEMENTS_DELETE_MUTATION } from '@/graphql/statement/statementsDelete'
import { STATEMENTS_CANCEL_CREATION_MUTATION } from '@/graphql/statement/statementsCancelCreation'
import { STATEMENTS_SHARE_MUTATION } from '@/graphql/statement/statementsShare'

export const StatementService = {
    get (id) {
        return apolloClient.query({
            query: STATEMENT_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.statement)
    },
    all (variables) {
        return apolloClient.query({
            query: STATEMENTS_QUERY,
            variables,
        }).then(response => response.data.statements)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: STATEMENTS_CREATE_MUTATION,
            variables,
        }).then(response => response.data.statementsCreate)
    },
    cancelCreation(statementIds) {
        return apolloClient.mutate({
            mutation: STATEMENTS_CANCEL_CREATION_MUTATION,
            variables: {
                statement_ids: statementIds,
            },
        }).then(response => response.data.statementsCancelCreation)
    },
    share(statementIds) {
        return apolloClient.mutate({
            mutation: STATEMENTS_SHARE_MUTATION,
            variables: {
                statement_ids: statementIds,
            },
        }).then(response => response.data.statementsShare)
    },
    delete (statementIds) {
        return apolloClient.mutate({
            mutation: STATEMENTS_DELETE_MUTATION,
            variables: {
                statement_ids: statementIds,
            },
        }).then(response => response.data.statementsDelete)
    },
}
