// import indexPageEn from '#/user-manual/correspondence/index.en.md'
import indexPageDeCH from '#/user-manual/correspondence/index.de-CH.md'
// import documentLayoutsPageEn from '#/user-manual/correspondence/document-layouts.en.md'
import documentLayoutsPageDeCH from '#/user-manual/correspondence/document-layouts.de-CH.md'
// import documentTemplatesPageEn from '#/user-manual/correspondence/document-templates.en.md'
import documentTemplatesPageDeCH from '#/user-manual/correspondence/document-templates.de-CH.md'
// import editorPageEn from '#/user-manual/correspondence/editor.en.md'
import editorPageDeCH from '#/user-manual/correspondence/editor.de-CH.md'

export default {
    key: 'correspondence',
    label: { 'en': 'Correspondence', 'de-CH': 'Korrespondenz' },
    navIcon: 'mi-read-email-letter',
    pages: [
        { 'key': 'index', 'en': indexPageDeCH, 'de-CH': indexPageDeCH },
        { 'key': 'document-layouts', 'en': documentLayoutsPageDeCH, 'de-CH': documentLayoutsPageDeCH },
        { 'key': 'document-templates', 'en': documentTemplatesPageDeCH, 'de-CH': documentTemplatesPageDeCH },
        { 'key': 'editor', 'en': editorPageDeCH, 'de-CH': editorPageDeCH },
    ],
}
