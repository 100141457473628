import { reactive, computed, watch } from 'vue'
import { TaskDueDateRangeFilterType, TaskDueDateRangeUnitOptions, TaskDueDateStatus } from '@/enums'
import {
    addDays,
    addMonths,
    addWeeks,
    addYears, endOfDay, endOfWeek, endOfYear, formatISO, lastDayOfMonth, lightFormat,
    startOfDay, startOfMonth,
    startOfWeek, startOfYear,
    subDays,
    subMonths,
    subWeeks,
    subYears
} from 'date-fns'
import { isValidIsoDate } from '@/helpers/form'
import { Task } from '@/models/task'

export class TaskFilter {
    constructor () {
        // Data
        this.data = reactive({
            activeAdvancedFilters: false,
            advancedFilters: {
                targetObject: null,
                targetObjectType: null,
                dueDateRangeFilterType: TaskDueDateRangeFilterType.DEFAULT,
                dueDateRangeAmount: 1,
                dueDateRangeUnit: TaskDueDateRangeUnitOptions.WEEK,
                dueDateFrom: '',
                dueDateUntil: '',
            },
            quickFilters: {
                creatorId: null,
                assigneeId: null,
                dueDateStatus: null,
            },
        })

        // Computed
        this.variables = computed(() => {
            const filterVariables = {}

            // Advanced filters
            if (this.data.activeAdvancedFilters) {
                // Target object
                if (this.data.advancedFilters.targetObject) {
                    filterVariables.filterTargetObjectType = this.data.advancedFilters.targetObject.targetObjectType
                    filterVariables.filterTargetObjectId = this.data.advancedFilters.targetObject.id

                // Target object type
                } else if (this.data.advancedFilters.targetObjectType) {
                    filterVariables.filterTargetObjectType = this.data.advancedFilters.targetObjectType
                }

                // Due date
                if (this.data.advancedFilters.dueDateFrom || this.data.advancedFilters.dueDateUntil) {
                    if (this.data.advancedFilters.dueDateFrom && isValidIsoDate(this.data.advancedFilters.dueDateFrom)) filterVariables.filterDueDateFrom = formatISO(startOfDay(new Date(this.data.advancedFilters.dueDateFrom)))
                    if (this.data.advancedFilters.dueDateUntil && isValidIsoDate(this.data.advancedFilters.dueDateUntil)) filterVariables.filterDueDateUntil = formatISO(endOfDay(new Date(this.data.advancedFilters.dueDateUntil)))
                }
            }

            // Quick filters
            if (this.data.quickFilters.creatorId) filterVariables.filterCreatorId = this.data.quickFilters.creatorId
            if (this.data.quickFilters.assigneeId) filterVariables.filterAssigneeId = this.data.quickFilters.assigneeId
            if (this.data.quickFilters.dueDateStatus) {
                switch (this.data.quickFilters.dueDateStatus) {
                    case TaskDueDateStatus.OVERDUE: {
                        const date = +startOfDay(new Date())

                        filterVariables.filterDueDateUntil = formatISO(new Date(date - 1))
                        break
                    }
                    case TaskDueDateStatus.DUE_TODAY: {
                        const date = new Date()

                        filterVariables.filterDueDateFrom = formatISO(startOfDay(date))
                        filterVariables.filterDueDateUntil = formatISO(endOfDay(date))
                        break
                    }
                    case TaskDueDateStatus.UPCOMING: {
                        const date = new Date()
                        const dueDateUntil = endOfDay(addDays(date, Task.UPCOMING_DUE_DATE_AMOUNT_OF_DAYS))

                        filterVariables.filterDueDateFrom = formatISO(date)
                        filterVariables.filterDueDateUntil = formatISO(dueDateUntil)
                        break
                    }
                }
            }

            return filterVariables
        })

        // Watch
        watch(() => this.data.advancedFilters.targetObject, (newValue, oldValue) => {
            if (this.data.advancedFilters.targetObject) this.data.advancedFilters.targetObjectType = null
        })

        watch(() => this.data.advancedFilters.targetObjectType, (newValue, oldValue) => {
            if (this.data.advancedFilters.targetObjectType) this.data.advancedFilters.targetObject = null
        })

        watch(() => this.data.advancedFilters.dueDateFrom, (newValue, oldValue) => {
            if (this.data.advancedFilters.dueDateFrom) this.data.quickFilters.dueDateStatus = null
        })

        watch(() => this.data.advancedFilters.dueDateUntil, (newValue, oldValue) => {
            if (this.data.advancedFilters.dueDateUntil) this.data.advancedFilters.dueDateStatus = null
        })

        watch(() => this.data.advancedFilters.dueDateRangeFilterType, (newValue, oldValue) => {
            switch (this.data.advancedFilters.dueDateRangeFilterType) {
                case TaskDueDateRangeFilterType.DYNAMIC:
                    this.updateDueDateRange(new Date())
                    break
            }
        })

        watch(() => this.data.advancedFilters.dueDateRangeAmount, (newValue, oldValue) => {
            if (this.data.advancedFilters.dueDateRangeFilterType === TaskDueDateRangeFilterType.DYNAMIC && this.data.advancedFilters.dueDateRangeUnit) this.updateDueDateRange(new Date(this.data.advancedFilters.dueDateFrom))
        })

        watch(() => this.data.advancedFilters.dueDateRangeUnit, (newValue, oldValue) => {
            if (this.data.advancedFilters.dueDateRangeFilterType === TaskDueDateRangeFilterType.DYNAMIC) {
                if (newValue) {
                    this.updateDueDateRange(new Date())
                } else {
                    this.data.advancedFilters.dueDateFrom = ''
                    this.data.advancedFilters.dueDateUntil = ''
                }
            }
        })

        watch(() => this.data.quickFilters.dueDateStatus, (newValue, oldValue) => {
            if (this.data.quickFilters.dueDateStatus) this.clearDueDateFilter()
        })
    }

    activateAdvancedFilters () {
        this.data.activeAdvancedFilters = true
    }

    deactivateAdvancedFilters () {
        this.data.activeAdvancedFilters = false
    }

    setTargetObject (targetObject) {
        this.data.advancedFilters.targetObject = targetObject
    }

    clearTargetObject () {
        this.data.advancedFilters.targetObject = null
    }

    clearDueDateFilter () {
        this.data.advancedFilters.dueDateRangeFilterType = TaskDueDateRangeFilterType.DEFAULT
        this.data.advancedFilters.dueDateFrom = ''
        this.data.advancedFilters.dueDateUntil = ''
        this.data.advancedFilters.dueDateRangeAmount = 1
        this.data.advancedFilters.dueDateRangeUnit = TaskDueDateRangeUnitOptions.WEEK
    }

    clearTargetObjectTypeFilter () {
        this.data.advancedFilters.targetObjectType = null
    }

    clearTargetObjectFilter () {
        this.clearTargetObject()
    }

    getAddDate (date, amount) {
        const fn = {
            [TaskDueDateRangeUnitOptions.DAY]: (date, numOfDays) => addDays(date, numOfDays),
            [TaskDueDateRangeUnitOptions.WEEK]: (date, numOfWeeks) => addWeeks(date, numOfWeeks),
            [TaskDueDateRangeUnitOptions.MONTH]: (date, numOfMonths) => addMonths(date, numOfMonths),
            [TaskDueDateRangeUnitOptions.YEAR]: (date, numOfYears) => addYears(date, numOfYears),
        }
        return fn[this.data.advancedFilters.dueDateRangeUnit](date, amount)
    }

    getSubDate (date, amount) {
        const fn = {
            [TaskDueDateRangeUnitOptions.DAY]: (date, numOfDays) => subDays(date, numOfDays),
            [TaskDueDateRangeUnitOptions.WEEK]: (date, numOfWeeks) => subWeeks(date, numOfWeeks),
            [TaskDueDateRangeUnitOptions.MONTH]: (date, numOfMonths) => subMonths(date, numOfMonths),
            [TaskDueDateRangeUnitOptions.YEAR]: (date, numOfYears) => subYears(date, numOfYears),
        }
        return fn[this.data.advancedFilters.dueDateRangeUnit](date, amount)
    }

    getDateStartBy (date) {
        const fn = {
            [TaskDueDateRangeUnitOptions.DAY]: date => startOfDay(date),
            [TaskDueDateRangeUnitOptions.WEEK]: date => startOfWeek(date, { weekStartsOn: 1 }),
            [TaskDueDateRangeUnitOptions.MONTH]: date => startOfMonth(date),
            [TaskDueDateRangeUnitOptions.YEAR]: date => startOfYear(date),
        }
        return fn[this.data.advancedFilters.dueDateRangeUnit](date)
    }

    getDateEndBy (date) {
        const fn = {
            [TaskDueDateRangeUnitOptions.DAY]: date => endOfDay(date),
            [TaskDueDateRangeUnitOptions.WEEK]: date => endOfWeek(date, { weekStartsOn: 1 }),
            [TaskDueDateRangeUnitOptions.MONTH]: date => lastDayOfMonth(date),
            [TaskDueDateRangeUnitOptions.YEAR]: date => endOfYear(date),
        }
        return fn[this.data.advancedFilters.dueDateRangeUnit](date)
    }

    shiftDueDateRangeEarlier () {
        const dueDateFrom = this.getDateStartBy(this.getSubDate(new Date(this.data.advancedFilters.dueDateFrom), this.data.advancedFilters.dueDateRangeAmount))
        this.updateDueDateRange(dueDateFrom)
    }

    shiftDueDateRangeLater () {
        const dueDateFrom = this.getDateStartBy(this.getAddDate(new Date(this.data.advancedFilters.dueDateFrom), this.data.advancedFilters.dueDateRangeAmount))
        this.updateDueDateRange(dueDateFrom)
    }

    updateDueDateRange (referenceDate) {
        const dueDateFrom = this.getDateStartBy(referenceDate)
        const dueDateUntil = this.getDateEndBy(this.getAddDate(dueDateFrom, this.data.advancedFilters.dueDateRangeAmount - 1))
        this.data.advancedFilters.dueDateFrom = lightFormat(dueDateFrom, 'yyyy-MM-dd')
        this.data.advancedFilters.dueDateUntil = lightFormat(dueDateUntil, 'yyyy-MM-dd')
    }
}
