export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'productProviderId',
            label: 'forms.field.label.commission-list.product-provider-name',
            infoText: 'common.product-provider.product-provider-selection--igb2b-insurer-code-only--info',
            inputType: 'productProvider',
            validators: [
                { type: 'required' },
            ],
        },
    ],
}
