import gql from 'graphql-tag'

export const PRINTERS_QUERY = gql`
    query printers(
        $ids: [ID!]
    ) {
        printers(
            ids: $ids
        ) {
            id
            status
            name
            computer_id
            computer_name
        }
    }
`
