<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('views.commission.accounting.commission-type-configuration.commission-type-configuration')" :to="{ name: 'commission-type-accounting-configuration-list' }" />
            </template>

            <h1>{{ $t('views.commission.accounting.commission-type-configuration.commission-type-configuration') }}</h1>
        </page-header>

        <base-table
            ref="CommissionTypeAccountingConfigurationListTable"
            :columns="columns"
            :fetch-objects-fn="CommissionTypeAccountingConfiguration.service.all"
            user-settings-base-path="CommissionTypeAccountingConfigurationList"
            pagination-sort-by-default-key="posting_type_name"
            data-test="table:commission-type-accounting-configuration-list"
            @row-dblclick="onDblClick"
        >
            <template v-slot:body-cell-actions="slotProps">
                <q-td :props="slotProps" auto-width>
                    <q-btn
                        color="primary"
                        size="sm"
                        flat
                        round
                        icon="mib-pencil"
                        :to="{ name: 'commission-type-accounting-configuration-update', params: { id: slotProps.row.id } }"
                        data-test="link:tooltip-actions"
                    >
                        <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('views.commission.accounting.commission-type-configuration.configure-commission-type', 1) }}</q-tooltip>
                    </q-btn>
                </q-td>
            </template>
        </base-table>
    </page-wrapper>
</template>

<script>
import { CommissionTypeAccountingConfiguration } from '@/models/commissionTypeAccountingConfiguration'
import BaseTable from '@/components/BaseTable'

export default {
    name: 'CommissionTypeAccountingConfigurationList',
    components: {
        BaseTable,
    },
    meta () {
        return {
            title: this.$t('views.commission.accounting.commission-type-configuration.commission-type-configuration'),
        }
    },
    data () {
        return {
            CommissionTypeAccountingConfiguration,
            columns: [
                {
                    name: 'commission_type',
                    label: this.$tc('views.commission.accounting.commission-type-configuration.commission-type-igb2b', 1),
                    field: row => row.igb2bCommissionTypeCode,
                    align: 'left',
                },
                {
                    name: 'posting_type_name',
                    label: this.$tc('views.commission.accounting.commission-type-configuration.linked-posting-type', 1),
                    field: row => row.postingType.name,
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'posting_text_part',
                    label: this.$tc('views.commission.accounting.commission-type-configuration.posting-text', 1),
                    field: row => row.posting_text_part,
                    align: 'left',
                    sortable: true,
                },
                {
                    name: 'commission_expense_account',
                    label: this.$tc('views.commission.accounting.commission-type-configuration.expense-account', 1),
                    field: row => row.commissionExpenseAccount?.name,
                    align: 'left',
                },
                {
                    name: 'commission_income_account',
                    label: this.$tc('views.commission.accounting.commission-type-configuration.income-account', 1),
                    field: row => row.commissionIncomeAccount?.name,
                    align: 'left',
                },
                { name: 'actions', label: this.$tc('common.term.action', 2), align: 'left' },
            ],
        }
    },
    methods: {
        onDblClick (evt, row) {
            this.$router.push({ name: 'commission-type-accounting-configuration-update', params: { id: row.id } })
        },
    },
}
</script>
