<template>
    <page-loading-indicator
        v-if="!accountStatementReport"
        :number-of-breadcrumb-elements="2"
        actions
        :number-of-next-actions="0"
    />
    <page-wrapper v-else>
        <page-header :progress-bar="accountStatementReport.currentQueueItem.isQueuedOrProcessing" progress-bar-indeterminate>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.accounting.account-statement.account-statement', 2)" :to="{ name: 'accounting-account-statements' }" />
                <q-breadcrumbs-el :label="`${accountStatementReport.formattedPeriod} – ${accountStatementReport.accounts.length + ' ' + $tc('common.accounting.account.account', accountStatementReport.accounts.length)}`" :to="{ name: 'accounting-statement-detail', params: { id: accountStatementReport.id } }" >
                    <status-badge
                        :status="accountStatementReport.currentQueueItem.status"
                        default-translation-base-path="common.status.queue-item-status"
                        :loading="accountStatementReport.currentQueueItem.isQueuedOrProcessing"
                    />
                </q-breadcrumbs-el>
            </template>

            <h1>{{ $tc('common.accounting.account-statement.account-statement', 1) }}</h1>

            <template v-slot:actions>
                <actions
                    v-if="accountStatementReport.actions"
                    :actions="accountStatementReport.actions"
                    :number-of-next-best-actions="0"
                    translation-base-key="common.actions.accounting"
                    @click="handleActions"
                />

                <!-- Cancel Creation Account Statement Report -->
                <form-dialog
                    ref="cancelCreationDialog"
                    dont-close-on-confirm
                    @confirm="handleCancelCreation"
                >
                    <template v-slot:title>{{ $tc('common.actions.accounting.account-statement-report.cancel-creation', 1) }}</template>
                    <template v-slot:default>
                        <p>{{ $tc('views.accounting.account-statement.cancel-creation--confirm--n-of-x', 1) }}</p>
                    </template>
                </form-dialog>

                <!-- Delete Account Statement Report -->
                <form-dialog
                    ref="deleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleDelete"
                >
                    <template v-slot:title>{{ $tc('common.accounting.account-statement.delete-statement', 1) }}</template>
                    <template v-slot:default>
                        <p>{{ $tc('views.accounting.account-statement.delete-statement--confirm--n-of-x', 1) }}</p>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <div class="row q-col-gutter-md">
            <div v-if="hasStatusMessage(accountStatementReport)" class="col-xs-12">
                <info-box :type="accountStatementReport.currentQueueItem.status === QueueItemStatus.FAILED ? 'negative' : 'info'" no-margin>
                    <p><b>{{ $tc(`common.status.${kebabCase(accountStatementReport.currentQueueItem.status)}`, 1) }}</b>: {{ accountStatementReport.currentQueueItem.status_message.message }}</p>
                </info-box>
            </div>

            <grid-card
                :columns="{ xs: 12, md: 6 }"
                :heading="$tc('common.term.configuration', 1)"
                content-class="q-pb-sm"
            >
                <dl>
                    <div>
                        <dt>{{ $tc('common.accounting.account.account', accountStatementReport.accounts.length || 2) }}: </dt>
                        <dd>{{ accountStatementAccounts }} <info-icon v-if="accountStatementReport.formattedAccountList.teaser" :text="accountStatementReport.formattedAccountList.accounts" /></dd>
                    </div>
                    <div>
                        <dt>{{ $tc('common.term.period', 1) }}: </dt>
                        <dd>{{ accountStatementReport.formattedPeriod }}</dd>
                    </div>
                    <div>
                        <dt>{{ $tc('common.file.file-type', 1) }}: </dt>
                        <dd><template v-for="(name, index) in accountStatementReport.file_types">
                            <span :key="index">{{ $tc(`common.file.type.${kebabCase(name)}`, 1) }}<template v-if="index + 1 < accountStatementReport.file_types.length">, </template></span>
                        </template></dd>
                    </div>
                </dl>
            </grid-card>

            <grid-card
                v-if="accountStatementReport.fileStorageItem"
                :columns="{ xs: 12, md: 6 }"
                :heading="$tc('common.file.file', 2)"
            >
                <template v-for="(entry, index) in accountStatementReport.fileStorageItem.files">
                    <div :key="index">
                        {{ entry.name }} / {{ entry.formattedFileSize }}
                        <q-btn
                            color="primary"
                            size="sm"
                            flat
                            round
                            icon="mib-cloud-download"
                            @click="entry.download()"
                        />
                    </div>
                </template>
            </grid-card>

            <div class="col-xs-12">
                <p>
                    <small>
                        <base-button
                            show-as-link
                            @click="$refs.activityLogOverlay.open({ filterSubjects: [{ id: id, type: ActivityLogSubjectType.ACCOUNT_STATEMENT_REPORT }] })"
                        >{{ $tc('common.term.created-by--on--date-time', 1, { name: accountStatementReport.owner.getContactName(), date: accountStatementReport.formattedCreatedAtDate, time: accountStatementReport.formattedCreatedAtTime }) }}</base-button>
                    </small>
                </p>
            </div>
        </div>

        <!-- Polling -->
        <polling
            v-if="accountStatementReport.currentQueueItem.isQueuedOrProcessing"
            ref="polling"
            :poll-fn="AccountStatementReport.objects.get"
            :poll-filters="id"
            :stop-condition-fn="stopPollingConditionFn"
            auto-start
        />

        <!-- Activity log -->
        <activity-log-overlay ref="activityLogOverlay" />

        <in-page-footer>
            <base-button
                :label="$t('common.term.back')"
                flat
                primary-button
                @click="$router.go(-1)"
            />
        </in-page-footer>
    </page-wrapper>
</template>

<script>
import { kebabCase } from 'lodash'
import { AccountStatementReport } from '@/models/models'
import { QueueItemStatus, ActivityLogSubjectType } from '@/enums/graphql'
import Actions from '@/components/Actions'

import StatusBadge from '@/components/StatusBadge'
import FormDialog from '@/components/form/FormDialog'
import Polling from '@/components/Polling.vue'
import ActivityLogOverlay from '@/components/ActivityLogOverlay'

export default {
    name: 'AccountStatementReportDetail',
    components: {
        Actions,
        FormDialog,
        Polling,
        StatusBadge,
        ActivityLogOverlay,
    },
    meta () {
        return {
            title: !this.accountStatementReport ? `${this.$t('common.term.loading')} …` : `${this.$tc('common.accounting.account-statement.account-statement', 1)}: ${this.accountStatementReport.formattedPeriod} – ${this.accountStatementReport.accounts.length + ' ' + this.$tc('common.accounting.account-statement.account-statement', this.accountStatementReport.accounts.length)}`,
        }
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            ActivityLogSubjectType,
            QueueItemStatus,
            AccountStatementReport,
            accountStatementReport: null,
        }
    },
    computed: {
        accountStatementAccounts () {
            if (this.accountStatementReport.formattedAccountList.teaser) {
                return this.accountStatementReport.formattedAccountList.teaser.short
            }
            return this.accountStatementReport.formattedAccountList.accounts
        },
    },
    created () {
        this.fetchObject()
    },
    methods: {
        fetchObject() {
            AccountStatementReport.objects.get(this.id).then(accountStatementReport => {
                this.accountStatementReport = accountStatementReport
            })
        },
        handleActions (action) {
            switch (action.key) {
                case AccountStatementReport.action.CANCEL_CREATION:
                    this.$refs.cancelCreationDialog.open()
                    break
                case AccountStatementReport.action.DELETE:
                    this.$refs.deleteDialog.open()
                    break
            }
        },
        handleCancelCreation () {
            AccountStatementReport.cancelCreation([this.id])
                .then(accountStatementReports => {
                    Object.assign(this.accountStatementReport, accountStatementReports.pop())
                    this.$refs.cancelCreationDialog.close()

                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.accounting.account-statement-creation-cancelled-success', 1),
                    })
                }).catch(error => {
                    this.$refs.cancelCreationDialog.showFormErrorMessage(error)
                    this.$refs.cancelCreationDialog.resetFormSubmitStatus()
                })
        },
        handleDelete () {
            AccountStatementReport.delete([this.id])
                .then(() => {
                    this.$refs.deleteDialog.close()
                    this.$router.replace({ name: 'accounting-account-statements' })

                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.accounting.account-statement-deleted-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.deleteDialog.showFormErrorMessage(error)
                    this.$refs.deleteDialog.resetFormSubmitStatus()
                })
        },
        stopPollingConditionFn (accountStatementReport) {
            Object.assign(this.accountStatementReport, accountStatementReport)
            return !this.accountStatementReport.currentQueueItem.isQueuedOrProcessing
        },
        hasStatusMessage (accountStatementReport) {
            return !!accountStatementReport.currentQueueItem?.status_message
        },
        kebabCase(...args) {
            return kebabCase(...args)
        },
    },
}

</script>
