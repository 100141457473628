import gql from 'graphql-tag'

export const REGISTRATION_VALIDATE_2FA_MUTATION = gql`
    mutation registrationValidate2FA($token: String!, $two_fa_code: String!) {
        registrationValidate2FA(
            token: $token,
            two_fa_code: $two_fa_code
        ) {
            two_fa_backup_code
        }
    }
`
