<template>
    <q-header elevated class="app-header text-text-inverted" :class="headerBackground">
        <q-banner
            v-if="!$store.state.online"
            inline-actions
            class="online-status-wrapper bg-negative-dark text-inverted text-center"
        >
            <q-icon
                name="mib-wifi-warning"
                size="1.5rem"
                color="white"
                class="q-mr-sm"
            />
            {{ $t('common.notifications.banner-no-internet-connection') }}
        </q-banner>

        <!-- TODO: Global info messages can be placed using an <info-box> element here. -->
        <!--
                <info-box inline-actions class="text-text-primary bg-warning">
                    Some important information
                    <template v-slot:action>
                        <q-btn unelevated outline no-caps color="text-primary" label="Do something" />
                    </template>
                </info-box>
        -->
        <div class="inner-wrapper">
            <q-toolbar>
                <q-btn
                    v-if="$q.screen.lt.md"
                    icon="mib-navigation-menu"
                    size="1rem"
                    flat
                    round
                    :aria-label="$t('common.a11y.toggle-main-menu')"
                    @click="toggleDrawerLeft"
                />

                <q-item
                    :to="{ name: 'dashboard' }"
                    exact
                    tabindex="0"
                    class="logo-wrapper"
                >
                    <img src="@/assets/img/max-logo-white.svg" alt="MAX Logo">
                </q-item>

                <q-space />

                <main-header-search v-show="appSubSection === 'main' && ($q.screen.lt.md && showMobileSearch || $q.screen.gt.sm)" />

                <div v-if="appSubSection !== 'main'">
                    <span class="text-h6">{{ $tc(`common.app.section.${appSubSection}`, 1) }}</span> –
                    <router-link :to="{ name: 'dashboard' }" class="text-text-inverted">{{ $t('common.app.back-to-app') }}</router-link>
                </div>

                <q-space />

                <!-- TODO improvement: Set focus to search input when triggered on smaller devices. -->
                <q-btn
                    v-if="$q.screen.lt.md && appSubSection === 'main'"
                    :icon="showMobileSearch ? 'mib-search-remove' : 'mib-search-alternate'"
                    size="1rem"
                    flat
                    round
                    @click="showMobileSearch = !showMobileSearch"
                />

                <user-menu />

                <!-- Show only on small screens -->
                <q-btn
                    v-if="$q.screen.lt.md"
                    icon="mib-itx-drawer-right-toggle"
                    class="q-ml-xs"
                    size="1rem"
                    flat
                    round
                    @click="toggleDrawerRight"
                >
                    <!-- TODO: Add (accumulated) notification when any of the content has notifications -->
                    <!--<q-badge color="red" floating>2</q-badge>-->
                </q-btn>
            </q-toolbar>
        </div>
    </q-header>
</template>

<script>
import UserMenu from '@/components/UserMenu'
import MainHeaderSearch from '@/components/search/MainHeaderSearch'

export default {
    name: 'MainHeader',
    components: {
        UserMenu,
        MainHeaderSearch,
    },
    data () {
        return {
            showMobileSearch: false,
        }
    },
    computed: {
        // TODO improvement: Check if it would make sense to replace appSubSection and headerBackground with a theming approach for the different app sections (see https://css-tricks.com/creating-color-themes-with-custom-properties-hsl-and-a-little-calc/)
        // --> appSubsection is being applied as `data-app-sub-section` attribute on `div#app` (in App.vue)
        appSubSection () {
            return this.$route.meta.appSubSection || 'main'
        },
        headerBackground () {
            return `bg-section-${this.appSubSection}`
        },
    },
    beforeMount () {
        // Initially hide drawerLeft on small screens
        if (this.$q.screen.lt.md) {
            this.$store.dispatch('hideDrawerLeft')
        }
    },
    mounted () {
        window.addEventListener('online', this.updateOnlineStatus)
        window.addEventListener('offline', this.updateOnlineStatus)
    },
    beforeDestroy () {
        window.removeEventListener('online', this.updateOnlineStatus)
        window.removeEventListener('offline', this.updateOnlineStatus)
    },
    methods: {
        toggleDrawerLeft () {
            this.$store.dispatch('toggleDrawerLeft')
        },
        toggleDrawerRight () {
            if (this.$q.screen.lt.md) {
                this.$store.dispatch('disableDrawerRightMiniState')
                this.$store.dispatch('toggleDrawerRight')
            } else {
                this.$store.dispatch('toggleDrawerRightMiniState')
            }
        },
        updateOnlineStatus () {
            if (navigator.onLine) {
                this.$store.dispatch('activateOnlineStatus')

                this.$q.notify({
                    message: this.$t('common.notifications.back-online'),
                    color: 'positive',
                    icon: 'mib-wifi-check',
                })
            } else {
                this.$store.dispatch('deactivateOnlineStatus')
            }
        },
    },
}
</script>

<style lang="scss">
.app-header {
    // High z-index needed so that the footer (or other content) does not overlap the header content (e.g. search results).
    z-index: 10000;

    .inner-wrapper {
        & > .q-toolbar {
            padding: 0 $sizeSpacingXs 0 $sizeSpacingSm;
        }
    }

    .logo-wrapper {
        position: relative;

        img {
            height: 30px;
        }
    }

    .search-wrapper {
        width: 55%;
        min-width: 200px;
    }
}

// Mobile styles
// mq($to: …) instead of $from (mobile first) used as these styles are not the default ones
@include mq($to: $sizeBreakpointMd) {
    $sizeMobileSearchWrapperHeight: 60px;

    .app-header .search-wrapper {
        width: 100%;
    }
}

// Unfortunately, due to the high z-index of the .app-header, we need to adjust the z-index of other elements, too.
// (This is handled here to have the context of why it’s done and to have all overrides in one place.)
.q-menu {
    z-index: 11000; // Was: 6000
}
</style>
