import gql from 'graphql-tag'

export const CONTACT_CONSULTANT_RELATION_SETTINGS_UPDATE_MUTATION = gql`
    mutation contactConsultantRelationSettingsUpdate(
        $contactConsultantRelationSettings: ContactConsultantRelationSettingsInput!
    ) {
        contactConsultantRelationSettingsUpdate(
            contactConsultantRelationSettings: $contactConsultantRelationSettings
        ) {
            formality
            correspondence_salutation_override {
                schemaType
                schemaVersion
                contentJSON
                contentHTML
                attrs
            }
            correspondenceSalutation {
                id
                contact_type
                formality
                gender
                language {
                    id
                    name
                }
                is_default
                salutation {
                    schemaType
                    schemaVersion
                    contentJSON
                    contentHTML
                    attrs
                }
            }
            created_at
            updated_at
        }
    }
`
