import { Model } from '@/models'
import { AreaOfResponsibilityService } from '@/services'

export class AreaOfResponsibility extends Model {
    /**
     * Create a AreaOfResponsibility model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'is_anonymous',
            'name',
            'description',
            'contactRelationship',
            'contactRelationshipNode2Contact',
            'emailAddress',
            'phoneNumber',

            // Timestamps
            'created_at',
            'updated_at',
        ], data)
    }

    static get service () { return AreaOfResponsibilityService }

    /**
     * Create a new areaOfResponsibility.
     * @param {String} companyId
     * @param {Boolean} isAnonymous
     * @param {String} contactRelationshipId
     * @param {Object} areaOfResponsibilityInput - typeof AreaOfResponsibilityInput
     * @returns {Promise<*>}
     */
    static create ({ companyId, isAnonymous, contactRelationshipId, areaOfResponsibilityInput }) {
        const variables = {
            companyId,
            isAnonymous,
            contactRelationshipId,
            areaOfResponsibility: areaOfResponsibilityInput,
        }

        return AreaOfResponsibilityService.create(variables)
    }

    /**
     * Update this areaOfResponsibility.
     * @param {Object} areaOfResponsibilityInput - typeof AreaOfResponsibilityInput
     * @returns {Promise<*>}
     */
    update (areaOfResponsibilityInput) {
        areaOfResponsibilityInput.id = this.id

        const variables = {
            areaOfResponsibility: areaOfResponsibilityInput,
        }

        return AreaOfResponsibilityService.update(variables).then(areaOfResponsibility => {
            Object.assign(this, areaOfResponsibility)
            return areaOfResponsibility
        })
    }

    /**
     * Delete this areaOfResponsibility.
     * @returns {Promise<*>}
     */
    delete () {
        return AreaOfResponsibilityService.delete(this.id)
    }
}
