import { i18n } from '@/i18n'
import kebabCase from 'lodash.kebabcase'
import { Model } from '@/models'
import { textList } from '@/helpers'
import { getFormFieldTranslationId } from '@/helpers/form'
import {
    ActivityLogParser,
    AccountActivityLogParser,
    AccountBalanceReportActivityLogParser,
    AccountStatementReportActivityLogParser,
    AddressActivityLogParser,
    ContactActivityLogParser,
    ContactRelationshipActivityLogParser,
    CommissionDistributionRuleSetActivityLogParser,
    CommissionDistributionRuleRangeActivityLogParser,
    CommissionListActivityLogParser,
    CommissionListEntryActivityLogParser,
    CommissionListEntryPositionActivityLogParser,
    CommissionRateAdjustmentRuleActivityLogParser,
    CommissionRateAdjustmentRuleSetActivityLogParser,
    CommissionSplitActivityLogParser,
    CommissionTypeAccountingConfigurationActivityLogParser,
    EmailAddressActivityLogParser,
    PhoneNumberActivityLogParser,
    PostingActivityLogParser,
    PostingTypeActivityLogParser,
    StatementActivityLogParser
} from '@/parsers/activityLog'

export class ActivityLog extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'log_name',
            'description',
            'subject',
            'causer',
            'properties',

            // Timestamps
            'created_at',
            'updated_at',
        ], data)

        this.isSubjectRelation = false
        this._data = null
    }

    static parsers = {}
    static registerParser (key, parserClass) {
        ActivityLog.parsers[key] = parserClass
    }

    get data () {
        if (!this._data) this._data = Object.keys(ActivityLog.parsers).includes(this.log_name) ? ActivityLog.parsers[this.log_name].parse(this) : ActivityLogParser.parse(this)
        return this._data
    }

    get attributeKeys () {
        return Object.keys(this.properties.attributes)
    }

    get attributeLabels () {
        const list = this.attributeKeys.map(item => {
            return i18n.tc(getFormFieldTranslationId({ type: 'label', context: kebabCase(this.log_name), key: kebabCase(item) }), 1)
        })

        return textList(list)
    }
}

ActivityLog.registerParser('account', AccountActivityLogParser)
ActivityLog.registerParser('accountBalanceReport', AccountBalanceReportActivityLogParser)
ActivityLog.registerParser('accountStatementReport', AccountStatementReportActivityLogParser)
ActivityLog.registerParser('address', AddressActivityLogParser)
ActivityLog.registerParser('contact', ContactActivityLogParser)
ActivityLog.registerParser('contactRelationship', ContactRelationshipActivityLogParser)
ActivityLog.registerParser('commissionDistributionRuleSet', CommissionDistributionRuleSetActivityLogParser)
ActivityLog.registerParser('commissionDistributionRuleRange', CommissionDistributionRuleRangeActivityLogParser)
ActivityLog.registerParser('commissionList', CommissionListActivityLogParser)
ActivityLog.registerParser('commissionListEntry', CommissionListEntryActivityLogParser)
ActivityLog.registerParser('commissionListEntryPosition', CommissionListEntryPositionActivityLogParser)
ActivityLog.registerParser('commissionRateAdjustmentRule', CommissionRateAdjustmentRuleActivityLogParser)
ActivityLog.registerParser('commissionRateAdjustmentRuleSet', CommissionRateAdjustmentRuleSetActivityLogParser)
ActivityLog.registerParser('commissionSplit', CommissionSplitActivityLogParser)
ActivityLog.registerParser('commissionTypeAccountingConfiguration', CommissionTypeAccountingConfigurationActivityLogParser)
ActivityLog.registerParser('emailAddress', EmailAddressActivityLogParser)
ActivityLog.registerParser('phoneNumber', PhoneNumberActivityLogParser)
ActivityLog.registerParser('posting', PostingActivityLogParser)
ActivityLog.registerParser('postingType', PostingTypeActivityLogParser)
ActivityLog.registerParser('statement', StatementActivityLogParser)
