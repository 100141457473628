<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.commission.manual-entry.manual-commission-entry', 2)" :to="{ name: 'commission-list-manual-entries' }" />
                <q-breadcrumbs-el :label="$tc('views.commission.manual-entry.add-entry', 1)" :to="{ name: 'commission-list-manual-entry-create' }" />
            </template>

            <h1>{{ $tc('views.commission.manual-entry.add-entry', 1) }}</h1>
        </page-header>

        <div class="row q-col-gutter-sm">
            <div class="col-xs-12 col-lg-10 col-xl-8">
                <commission-list-manual-entry-form
                    ref="commissionListManualEntryForm"
                    @submit="handleCreate"
                />
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { CommissionListEntry } from '@/models/commissionListEntry'
import CommissionListManualEntryForm from '@/components/commission/commissionListManualEntry/CommissionListManualEntryForm'

export default {
    name: 'CommissionListManualEntryCreate',
    meta () {
        return {
            title: this.$tc('views.commission.manual-entry.add-entry', 1),
        }
    },
    components: {
        CommissionListManualEntryForm,
    },
    methods: {
        handleCreate (formData) {
            CommissionListEntry.create(this.parseFormData(formData))
                .then(() => {
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.commission.manual-commission-entry-added-success', 1),
                    })

                    this.$router.replace({
                        name: 'commission-list-manual-entries',
                    })
                }).catch(error => {
                    this.$refs.commissionListManualEntryForm.showFormErrorMessage(error)
                    this.$refs.commissionListManualEntryForm.resetFormSubmitStatus()
                })
        },
        parseFormData(formData) {
            return {
                ...formData,
                premiumFrom: formData.premiumFrom || undefined,
                premiumTo: formData.premiumTo || undefined,
                commissionAmount: parseFloat(formData.commissionAmount),
            }
        },
    },
}
</script>
