import gql from 'graphql-tag'

export const ACCOUNT_STATEMENT_REPORTS_DELETE_MUTATION = gql`
    mutation accountStatementReportsDelete(
        $account_statement_report_ids: [ID!]!
    ) {
        accountStatementReportsDelete(
            account_statement_report_ids: $account_statement_report_ids
        ) {
            status
        }
    }
`
