import gql from 'graphql-tag'

export const COMMISSION_LIST_IMPORT_EXCEL_MAPPINGS_QUERY = gql`
    query commissionListImportExcelMappings(
        $page: Int = 1
        $count: Int = 1000
        $orderField: CommissionListImportExcelMappingOrderField = CREATED_AT
        $orderDirection: OrderDirection = DESC
        $filterQuery: String
    ) {
        commissionListImportExcelMappings(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                query: $filterQuery
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                productProvider {
                    id
                    name
                    display_name
                }
                name
                commission_entries_start_row
                contract_number_column
                commission_type_column
                commission_amount_column
                premium_from_column
                premium_from_date_format
                premium_to_column
                premium_to_date_format
                customer_full_name_column
                created_at
                updated_at
            }
        }
    }
`
