import { Model } from '@/models'

import { DocumentLayoutService, ConsultingSettingsService } from '@/services'
import { DocumentLayoutActions } from '@/enums'

export class DocumentLayout extends Model {
    /**
     * Create a documentLayout model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'name',
            'name_language_versions',
            'owner',
            'pageBackground',
            'page_margins',
            'is_default',
        ], data)
    }

    static get service () { return DocumentLayoutService }
    static action = DocumentLayoutActions

    get actions () {
        const actions = []

        if (!this.is_default) {
            actions.push({
                key: DocumentLayoutActions.SET_DEFAULT,
                item: this,
            })
        }

        actions.push({
            key: DocumentLayoutActions.DELETE,
            item: this,
        })

        return actions
    }

    /**
     * Create a new documentLayout.
     * @param {number} ownerCompanyId
     * @param {Object} variables - The values used to create the documentLayout.
     * @param {Object} variables.name - For example: { "en": "My layout", "de-CH": "Mein Layout" }
     * @param {File} variables.pageBackground - PDF file that is used as background on evey page of the document.
     * @param {Object} variables.pageMargins - For example: { "margin-top": "3cm", "margin-right": "2cm", "margin-bottom": "3cm", "margin-left": "2cm" }
     * @returns {Promise<*>}
     */
    static create (ownerCompanyId, { name, pageBackground, pageMargins }) {
        const variables = {
            ownerCompanyId,
            documentLayout: {
                name,
                page_margins: pageMargins,
            },
        }

        if (typeof pageBackground !== 'undefined') variables.documentLayout.page_background = pageBackground

        return DocumentLayoutService.create(variables)
    }

    /**
     * Update this documentLayout.
     * @param {Object} variables - The values used to update the documentLayout.
     * @param {Object} variables.name - For example: { "en": "My layout", "de-CH": "Mein Layout" }
     * @param {File} variables.pageBackground - PDF file that is used as background on evey page of the document.
     * @param {Object} variables.pageMargins - For example: { "margin-top": "3cm", "margin-right": "2cm", "margin-bottom": "3cm", "margin-left": "2cm" }
     * @returns {Promise<*>}
     */
    update ({ name, pageBackground, pageMargins }) {
        const documentLayoutVariables = {
            name,
            page_margins: pageMargins,
        }

        if (typeof pageBackground !== 'undefined') documentLayoutVariables.page_background = pageBackground

        return DocumentLayoutService.update(this.id, documentLayoutVariables).then(documentLayout => {
            Object.assign(this, documentLayout)
            return documentLayout
        })
    }

    /**
     * Delete this documentLayout.
     * @returns {Promise<*>}
     */
    delete () {
        return DocumentLayoutService.delete(this.id)
    }

    /**
     * Sets this documentLayout as default for the owner `ConsultingCompany`.
     * @returns {Promise<*>}
     */
    setDefault () {
        return ConsultingSettingsService.update(this.owner.id, { default_document_layout_id: this.id }).then(consultingSettings => {
            this.is_default = consultingSettings.defaultDocumentLayout.is_default
            return this
        })
    }
}
