import { EventBus } from '@/event-bus'
import { Cookies } from 'quasar'

export const AutoLogout = {
    timeoutId: undefined,
    expirationTimestamp: null,
    warningPeriod: (1000 * 60) * 3, // n minutes

    startTimeout (expirationTimestamp) {
        if (this.timeoutId) this.timeoutId = clearTimeout(this.timeoutId)
        this.expirationTimestamp = expirationTimestamp
        // this.expirationTimestamp = Date.now() + (this.warningPeriod + (1000 * 3)) // TODO: Uncomment for testing. Once finished remove this line.
        const timeout = this.expirationTimestamp - Date.now() - this.warningPeriod
        this.timeoutId = setTimeout(this.onTimeout.bind(this), timeout)
        EventBus.$emit('autoLogout:startTimeout')
    },

    onTimeout () {
        if (Cookies.has('jwt_header_payload')) {
            const payload = JSON.parse(atob(Cookies.get('jwt_header_payload').split('.')[1]))
            if (payload.exp * 1000 - Date.now() <= this.warningPeriod) {
                EventBus.$emit('autoLogout:onTimeout', this.expirationTimestamp)
            } else {
                this.startTimeout(payload.exp * 1000) // Exp claim is in number of seconds, javascript timestamps are number of milliseconds
            }
        }
    },
}
