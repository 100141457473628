import { formatDateTime, formatDate, formatTime } from '@/helpers/date'

export class Model {
    statusTransitions = {}
    statusActionMapping = {}

    constructor (data) {
        Object.assign(this, data) // TODO @TFU: Check if this should be: if (data) Object.assign(this, data)
    }

    static get objects () {
        const modelClassName = this.name
        const objects = {}
        if (this.service) {
            objects.get = this.service.get || function () { throw Error(`${modelClassName}.service does not implement .get()!`) }
            objects.all = this.service.all || function () { throw Error(`${modelClassName}.service does not implement .all()!`) }
        }
        return objects
    }

    static initializeFields (modelInstance, fields, data) {
        // Add meta fields
        if (!fields.includes('created_at')) fields.push('created_at')
        if (!fields.includes('updated_at')) fields.push('updated_at')
        if (!fields.includes('__typename')) fields.push('__typename')

        fields.forEach(field => {
            let key = field
            let value
            if (Array.isArray(field)) {
                key = field[0]
                field.some(nestedField => {
                    const nestedFieldValue = data[nestedField]
                    if (nestedFieldValue !== undefined) value = nestedFieldValue
                    return nestedFieldValue
                })
            } else {
                value = data[key]
            }
            if (value !== undefined) modelInstance[key] = value
        })
    }

    get actions () {
        const actions = []
        if (this.statusTransitions[this.status]) {
            this.statusTransitions[this.status].forEach(targetStatus => {
                actions.push({
                    key: this.statusActionMapping[targetStatus],
                    item: this,
                })
            })
        }
        return actions
    }

    get formattedCreatedAt () {
        return (this.created_at) ? formatDateTime(this.created_at) : undefined
    }

    get formattedCreatedAtDate () {
        return (this.created_at) ? formatDate(this.created_at) : undefined
    }

    get formattedCreatedAtTime () {
        return (this.created_at) ? formatTime(this.created_at) : undefined
    }

    get formattedUpdatedAt () {
        return (this.updated_at) ? formatDateTime(this.updated_at) : undefined
    }

    get formattedUpdatedAtDate () {
        return (this.updated_at) ? formatDate(this.updated_at) : undefined
    }

    get formattedUpdatedAtTime () {
        return (this.updated_at) ? formatTime(this.updated_at) : undefined
    }
}
