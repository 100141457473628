<template>
    <base-select-filter
        v-if="countries.length"

        :value="value"
        v-bind="$attrs"
        :options="countries"

        option-label="name"
        option-value="id"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { ContactService } from '@/services'

export default {
    name: 'CountrySelect',
    components: {
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
    },
    data () {
        return {
            countries: [],
        }
    },
    created () {
        ContactService.getCountries().then(response => {
            this.countries = response.data
        })
    },
}
</script>
