import gql from 'graphql-tag'

export const COMMISSION_RATE_ADJUSTMENT_RULE_SETS_QUERY = gql`
    query commissionRateAdjustmentRuleSets(
        $page: Int = 1
        $count: Int = 1000
        $orderField: CommissionRateAdjustmentRuleSetOrderField = PRODUCT_PROVIDER_NAME
        $orderDirection: OrderDirection = DESC
        $filterQuery: String
    ) {
        commissionRateAdjustmentRuleSets(
            page: $page
            count: $count
            order: [{ field: $orderField,  direction: $orderDirection }]
            search: {
                query: $filterQuery
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                productProvider {
                    id
                    contact_number
                    name
                    display_name
                }
                rules {
                    id
                    igb2bSectorCode {
                        id
                        code
                        name
                    }
                    custom_sector_code
                    commission_rate
                    adjusted_commission_rate
                    created_at
                    updated_at
                }
                created_at
                updated_at
            }
        }
    }
`
