<template>
    <q-select
        ref="qSelect"
        :value="value"
        v-bind="$attrs"
        :options="displayOptions"

        map-options
        emit-value
        use-input

        :use-chips="useChips"
        filled

        square
        dense
        options-dense
        :class="['truncate', { 'required': required }]"

        v-on="$listeners"
        @filter="onFilter"
    >
        <!--@input="inputValue => $emit('input', inputValue)"-->
        <!--@filter="filterFn"-->
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" /><!-- Named slots -->
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template><!-- Scoped slots -->

        <template v-slot:no-option>
            <q-item>
                <q-item-section>
                    {{ $tc('common.term.result', 0) }}
                </q-item-section>
            </q-item>
        </template>

        <template v-if="infoText || infoTitle" v-slot:append>
            <info-icon
                :title="infoTitle"
                :text="infoText"
                class="all-pointer-events"
            />
        </template>
    </q-select>
</template>

<script>
import { computed, reactive, ref, useAttrs } from 'vue'

export default {
    name: 'BaseSelectFilterNext',
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        filterServerSide: {
            type: Boolean,
            default: false,
        },

        infoTitle: {
            type: String,
            default: '',
        },
        infoText: {
            type: String,
            default: '',
        },

        useChips: {
            type: Boolean,
            default: true,
        },

        required: {
            type: Boolean,
            default: false,
        },
    },
    setup (props) {
        // Composables
        const attrs = useAttrs()

        // Data
        const state = reactive({
            filteredOptions: [],
        })

        // Template refs
        const qSelect = ref(null)

        // Computed
        const displayOptions = computed(() => {
            return (state.filteredOptions.length) ? state.filteredOptions : props.options
        })

        // Functions
        function onFilter (value, doneFn) {
            if (!props.filterServerSide) {
                doneFn(() => {
                    const query = value.toLowerCase()
                    const optionLabelProp = attrs['option-label'] || 'label'
                    const isFunction = (typeof optionLabelProp === 'function')
                    state.filteredOptions = props.options.filter(option => {
                        const optionLabel = (isFunction) ? optionLabelProp(option) : option[optionLabelProp]
                        return optionLabel.toLowerCase().indexOf(query) > -1
                    })
                })
            }
        }
        function focus () {
            qSelect.value.focus()
        }

        return {
            // Data
            state,

            // Template refs
            qSelect,

            // Computed
            displayOptions,

            // Functions
            onFilter,
            focus,
        }
    },
}
</script>
