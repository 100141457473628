<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('views.dev.automation.automation', 1)" :to="{ name: 'dev-automation' }" />
                <q-breadcrumbs-el :label="$t('views.dev.automation.contract-file-management')" :to="{ name: 'dev-automation-contract-file-management' }" />
            </template>

            <h1 :data-is-ready="(!state.isProcessing).toString()">
                {{ $tc('views.dev.automation.contract-file-management', 1) }}
                <status-badge
                    v-if="state.isProcessing"
                    status="Processing"
                    :loading="state.isProcessing"
                />
            </h1>
        </page-header>

        <form-builder-next
            :form="form"
            in-page-footer
            :submit-button-label="$tc('common.contract.search-contract', 2)"
            @submit="fetchObjects"
        />
    </page-wrapper>
</template>

<script>
import { fetchPaginatedObjects } from '@/helpers'
import { ConsultingFileTargetObjectType, ContractManagedByStatus, ContractStatus } from '@/enums/graphql'
import { ConsultingFileService, ContractService } from '@/services'
import { reactive, ref } from 'vue'
import { Form, FormField } from '@/libs/form'
import { useRouter } from '@/composables/router'
import StatusBadge from '@/components/StatusBadge.vue'
import { isMonday, lightFormat, subDays } from 'date-fns'

export default {
    name: 'DevAutomationContractFileManagement',
    meta () {
        return {
            title: `${this.$tc('views.dev.automation.automation', 1)}: ${this.$t('views.dev.automation.contract-file-management')}`,
        }
    },
    components: { StatusBadge },
    setup () {
        // Composables
        const router = useRouter()

        // Data
        const state = reactive({
            isProcessing: false,
        })

        // Template refs
        const formComponent = ref(null)

        // Form
        const form = new Form()
        const today = new Date()

        const createdAtDateFrom = lightFormat(subDays(today, isMonday(today) ? 3 : 1), 'yyyy-MM-dd')
        form.addField(new FormField('createdAtDateFrom', {
            label: 'Created at date from',
            type: 'calendar',
            validators: [
                { type: 'required' },
            ],
        }))
        form.getItem('createdAtDateFrom').setData(createdAtDateFrom)

        const createdAtDateUntil = lightFormat(today, 'yyyy-MM-dd')
        form.addField(new FormField('createdAtDateUntil', {
            label: 'Created at date until',
            type: 'calendar',
        }))
        form.getItem('createdAtDateUntil').setData(createdAtDateUntil)

        form.addField(new FormField('jsonOutput', {
            label: 'JSON Output',
            type: 'textarea',
            attributes: {
                readonly: true,
                class: 'monospace',
            },
        }))

        // Functions
        async function fetchObjects () {
            state.isProcessing = true

            const variables = {
                filterStatus: [ContractStatus.ACTIVE],
                filterManagedByStatus: [ContractManagedByStatus.INTERNAL],
                filterCreatedAtDateFrom: form.getItem('createdAtDateFrom').value,
            }
            if (form.getItem('createdAtDateUntil').value) variables.filterCreatedAtDateUntil = form.getItem('createdAtDateUntil').value
            const contracts = await fetchPaginatedObjects({ fn: ContractService.all }, variables, `
                id
                contractNumbers {
                    number
                }
                currentContractInformation {
                    product {
                        name
                        basicProvider {
                            name
                        }
                    }
                    managed_by_status
                }
                customer {
                    id
                }
                consultant {
                    first_name
                    last_name
                }
                created_at
            `)

            const contractsWithoutContractFile = []
            for (const contract of contracts) {
                const contractFilesResponse = await ConsultingFileService.all({
                    consultingFileTargetObject: {
                        object_type: ConsultingFileTargetObjectType.CONTRACT,
                        object_id: contract.id,
                    },
                    typeId: '3',
                }, `
                    ... on ContractFile {
                        id
                    }
                `)
                if (!contractFilesResponse.data.length) contractsWithoutContractFile.push(contract)
            }

            form.getItem('jsonOutput').setData(JSON.stringify(contractsWithoutContractFile.map(contract => {
                return {
                    id: contract.id,
                    currentContractNumber: contract.currentContractNumber,
                    productProviderName: contract.currentContractInformation.product.basicProvider.name,
                    productName: contract.currentContractInformation.product.name,
                    managedByStatus: contract.currentContractInformation.managed_by_status,
                    consultantName: `${contract.consultant.first_name} ${contract.consultant.last_name}`,
                    createdAt: contract.created_at,
                    views: {
                        contractDetailDocuments: `${window.location.origin}${router.resolve({ name: 'contract-detail', params: { contactId: contract.customer.id, id: contract.id, tab: 'documents' } }).href}`,
                        contractUpdate: `${window.location.origin}${router.resolve({ name: 'contract-update', params: { contactId: contract.customer.id, id: contract.id } }).href}`,
                    },
                }
            }), null, 4))

            formComponent.value.formBuilder.resetFormSubmitStatus()
            state.isProcessing = false
        }

        return {
            // Data
            state,

            // Template ref
            formComponent,

            // Form
            form,

            // Functions
            fetchObjects,
        }
    },
}
</script>
