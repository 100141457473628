<template>
    <dl :class="{ 'form-builder-data-wrapper': $parent.name }">
        <template v-for="item in productFields">
            <!-- TODO: Add solution for collapsible sections -->
            <div
                v-if="item.type === 'section'"
                :key="item.key"
                class="form-builder-data-section"
                :class="formBuilderDataItemClass(item.key)"
            >
                <dt class="section-heading">{{ item.label }}</dt>
                <dd>
                    <product-fields v-if="item.children && item.children.length" :product-fields="item.children" />
                </dd>
            </div>
            <div
                v-else
                :key="item.key"
                class="form-builder-data-field"
                :class="formBuilderDataItemClass(item.key)"
            >
                <dt>{{ item.label }}: </dt>
                <dd :class="getFormattedFieldValue(item.value).cssClass">
                    <license-plate v-if="item.key === 'licencePlate'" :license-plate="getFormattedFieldValue(item.value).value" />
                    <template v-else><span v-if="item.formattedValue">{{ getFormattedFieldValue(item.formattedValue).value }}</span><span v-else>{{ getFormattedFieldValue(item.value).value }}</span></template>
                </dd>
            </div>
        </template>
    </dl>
</template>

<script>
import kebabCase from 'lodash.kebabcase'
import { getFormattedFieldValue } from '@/helpers/form'
import LicensePlate from '@/components/contract/LicensePlate'

export default {
    name: 'ProductFields',
    components: {
        LicensePlate,
    },
    props: {
        productFields: {
            type: Array,
            required: true,
        },
    },
    setup () {
        function formBuilderDataItemClass (key) {
            // Add the items here that should be highlighted in the contract data
            switch (key) {
                case 'automaticRenewal':
                case 'annualCancellationRight':
                case 'paymentMode':
                case 'licencePlate':
                    return `${kebabCase(key)} most-relevant`

                default:
                    return kebabCase(key)
            }
        }

        return {
            formBuilderDataItemClass,
            getFormattedFieldValue,
        }
    },
}
</script>

<style scoped>

</style>
