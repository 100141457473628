<template>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="release-notes-wrapper md-content-wrapper" @click="handleAnchorNavigation" v-html="localizedReleaseNotes" />
</template>

<script>
import releaseNotesEn from '#/RELEASENOTES.en.md'
import releaseNotesDeCH from '#/RELEASENOTES.de-CH.md'
import { markdownContentMixin } from '@/mixins/markdownContentMixin'

export default {
    name: 'ReleaseNotesContent',
    mixins: [markdownContentMixin],
    data () {
        return {
            releaseNotes: {
                'en': releaseNotesEn.html,
                'de-CH': releaseNotesDeCH.html,
            },
        }
    },
    computed: {
        localizedReleaseNotes () {
            return this.releaseNotes[this.$i18n.locale]
        },
    },
}
</script>

<style lang="scss" scoped>
.release-notes-wrapper {
}

::v-deep h2 {
    // Styling = h4
    font-size: 1.25rem;
    line-height: 1.5rem;
    letter-spacing: 0;

    &:first-of-type {
        margin-top: 0;
    }

    time {
        font-size: 1rem;
        font-weight: normal;
        color: var(--color-text-secondary);

        &::before {
            content: " – ";
        }
    }
}

::v-deep h3 {
    // Styling = bold standard text
    font-size: 1rem;
    line-height: 1.4em;
    letter-spacing: 0;
    color: var(--color-text-primary);
}
</style>
