import { apolloClient } from '@/apollo/client'

import { POSTING_QUERY } from '@/graphql/posting/posting'
import { POSTINGS_QUERY } from '@/graphql/posting/postings'
import { POSTING_CREATE_MUTATION } from '@/graphql/posting/postingCreate'
import { POSTINGS_CREATE_MUTATION } from '@/graphql/posting/postingsCreate'
import { POSTING_UPDATE_MUTATION } from '@/graphql/posting/postingUpdate'
import { POSTING_DELETE_MUTATION } from '@/graphql/posting/postingDelete'
import { POSTING_DELETE_ATTACHMENT_MUTATION } from '@/graphql/posting/postingDeleteAttachment'
import { POSTING_ADD_ATTACHMENT_MUTATION } from '@/graphql/posting/postingAddAttachment'

export const PostingService = {
    get (id) {
        return apolloClient.query({
            query: POSTING_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.posting)
    },
    all (variables) {
        return apolloClient.query({
            query: POSTINGS_QUERY,
            variables,
        }).then(response => response.data.postings)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: POSTING_CREATE_MUTATION,
            variables,
        }).then(response => response.data.postingCreate)
    },
    createBulk (variables) {
        return apolloClient.mutate({
            mutation: POSTINGS_CREATE_MUTATION,
            variables,
        }).then(response => response.data.postingCreate)
    },
    update (id, posting) {
        return apolloClient.mutate({
            mutation: POSTING_UPDATE_MUTATION,
            variables: {
                id: id,
                posting,
            },
        }).then(response => response.data.postingUpdate)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: POSTING_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.postingDelete)
    },
    addAttachment (id, file, abortController) {
        return apolloClient.mutate({
            mutation: POSTING_ADD_ATTACHMENT_MUTATION,
            variables: {
                id,
                file,
            },
            context: {
                fetchOptions: {
                    signal: abortController.signal,
                },
            },
        }).then(response => response.data.postingAddAttachment)
    },
    deleteAttachment (id, fileId) {
        return apolloClient.mutate({
            mutation: POSTING_DELETE_ATTACHMENT_MUTATION,
            variables: {
                id,
                fileId,
            },
        }).then(response => response.data.postingDeleteAttachment)
    },
}
