import gql from 'graphql-tag'

export const TASK_UPDATE_MUTATION = gql`
    mutation taskUpdate(
        $id: ID!
        $taskInput: TaskInput!
    ) {
        taskUpdate(
            id: $id
            task: $taskInput
        ) {
            id
            status
            creator {
                id
            }
            assignee {
                id
            }
            visibility
            subject
            description {
                schemaType
                schemaVersion
                contentJSON
                contentHTML
                attrs
            }
            attachments {
                id
            }
            target_object_type
            target_object_id
            targetObject {
                ... on Person {
                    id
                }
                ... on Company {
                    id
                }
                ... on Application {
                    id
                }
                ... on Contract {
                    id
                }
            }
            due_date
            created_at
            updated_at
            done_at
        }
    }
`
