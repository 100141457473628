import { MailProcessingTemplateActions } from '@/enums'
import { Model } from '@/models'
import { MailProcessingTemplateService } from '@/services'

export class MailProcessingTemplate extends Model {
    /**
     * Create a MailProcessingTemplate model wrapper.
     * @param {Object} data - Object holding the field values.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'name',
            'description',
            'file_name',
            'mailProcessingActiveDocumentTemplate',
            'mailProcessingInactiveDocumentTemplate',
            'target_object_type',
            'consultingFileType',
            'billable',
        ], data)
    }

    static get service () { return MailProcessingTemplateService }
    static action = MailProcessingTemplateActions

    get actions () {
        const actions = super.actions
        actions.push({
            key: MailProcessingTemplateActions.DELETE,
            item: this,
        })
        return actions
    }

    /**
     * Create a new mailProcessingTemplate.
     * @param {Object} variables - The values used to create the mailProcessingTemplate.
     * @returns {Promise<*>}
     */
    static create ({
        name,
        description,
        fileName,
        mailProcessingActiveDocumentTemplate,
        mailProcessingInactiveDocumentTemplate,
        targetObjectType,
        consultingFileType,
        billable,
    }) {
        const variables = {
            mailProcessingTemplate: {
                name,
                description,
                file_name: fileName,
                mail_processing_active_document_template_id: mailProcessingActiveDocumentTemplate,
                mail_processing_inactive_document_template_id: mailProcessingInactiveDocumentTemplate,
                target_object_type: targetObjectType,
                consulting_file_type_id: consultingFileType,
                billable,
            },
        }
        return MailProcessingTemplateService.create(variables)
    }

    /**
     * Update this mailProcessingTemplate.
     * @param {Object} variables - The values used to update the mailProcessingTemplate.
     * @returns {Promise<*>}
     */
    update ({
        name,
        description,
        fileName,
        mailProcessingActiveDocumentTemplate,
        mailProcessingInactiveDocumentTemplate,
        targetObjectType,
        consultingFileType,
        billable,
    }) {
        const variables = {
            name,
            description,
            file_name: fileName,
            mail_processing_active_document_template_id: mailProcessingActiveDocumentTemplate,
            mail_processing_inactive_document_template_id: mailProcessingInactiveDocumentTemplate,
            target_object_type: targetObjectType,
            consulting_file_type_id: consultingFileType,
            billable,
        }

        return MailProcessingTemplateService.update(this.id, variables).then(mailProcessingTemplate => {
            Object.assign(this, mailProcessingTemplate)
            return mailProcessingTemplate
        })
    }

    /**
     * Delete this mailProcessingTemplate.
     * @returns {Promise<*>}
     */
    delete () {
        return MailProcessingTemplateService.delete(this.id)
    }
}
