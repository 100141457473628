import gql from 'graphql-tag'

export const CONTACT_PHONE_NUMBER_CREATE_MUTATION = gql`
    mutation contactPhoneNumberCreate(
        $contact_id: ID!
        $phoneNumber: PhoneNumberInput!
    ) {
        contactPhoneNumberCreate(
            contact_id: $contact_id
            phoneNumber: $phoneNumber
        ) {
            id
            category {
                id
                name
            }
            label {
                id
                label
            }
            phone_number
            is_main_phone_number
        }
    }
`
