export function mapSubject (verbObject) {
    if (typeof verbObject === 'string') {
        return verbObject
    }
    if (typeof verbObject.__typename !== 'undefined') {
        switch (verbObject.__typename) {
            case 'Company':
                return 'Contact'
            case 'Person':
                return 'Contact'
            default:
                return verbObject.__typename
        }
    }
    if (typeof verbObject === 'function') {
        return verbObject.name
    }
    return verbObject.constructor.name
}

export function checkRouteAccessPermissions (to, from, can) {
    const hasAccessPermissions = Array.isArray(to.meta?.accessPermissions) && to.meta.accessPermissions.length
    return Promise.resolve(hasAccessPermissions ? to.meta.accessPermissions.every(permission => can(permission, 'GLOBAL_RULE')) : true)
}

export function checkRouteAccessPermissionsNested (to, from, can) {
    const accessPermissions = []
    to.matched.forEach(matchedRoute => {
        if (matchedRoute.meta && Array.isArray(matchedRoute.meta.accessPermissions) && matchedRoute.meta.accessPermissions.length) {
            matchedRoute.meta.accessPermissions.forEach(permission => {
                if (!accessPermissions.includes(permission)) accessPermissions.push(permission)
            })
        }
    })
    return Promise.resolve(accessPermissions.length ? accessPermissions.every(permission => can(permission, 'GLOBAL_RULE')) : true)
}
