import { PostingAccountSide, PostingDateType } from '@/enums'
import { getAccountBalanceChange } from '@/helpers/account'
import { isValidIsoDate } from '@/helpers/form'
import { formatCurrency } from '@/helpers/number'
import useVuelidate from '@vuelidate/core'
import { computed, reactive, unref } from 'vue'
import { decimal, minValue, required } from 'vuelidate/lib/validators'

export class AccountingTransferPostingItem {
    constructor({ id, itemAccount, superOrdinateAccount, postingDate, postingText, postingAmount, postingConfigurationForm }) {
        this.isSwapped = false

        this.id = id
        this.itemAccount = itemAccount
        this.superOrdinateAccount = superOrdinateAccount ?? null
        this.formData = reactive({
            postingDate,
            postingAmount,
            postingText,
        })
        this.postingConfigurationForm = postingConfigurationForm

        // Computed
        this.debitAccount = computed(() => {
            return this.isSwapped ? this.superOrdinateAccount : this.itemAccount
        })
        this.creditAccount = computed(() => {
            return this.isSwapped ? this.itemAccount : this.superOrdinateAccount
        })
        this.postingDate = computed({
            get: function () {
                if (this.postingConfigurationForm.postingDateType === PostingDateType.UNIVERSAL) {
                    return this.postingConfigurationForm.formData.postingDate
                } else {
                    return this.formData.postingDate || this.postingConfigurationForm.formData.postingDate
                }
            }.bind(this),
            set: function (value) {
                if (value) {
                    this.formData.postingDate = value
                } else {
                    this.formData.postingDate = ''
                }
                unref(this.validations).postingDate.$touch()
            }.bind(this),
        })
        this.postingAmount = computed({
            get: function () {
                return this.formData.postingAmount || ''
            }.bind(this),
            set: function (value) {
                if (value) {
                    this.formData.postingAmount = this.getRoundedAmount(value)
                } else {
                    this.formData.postingAmount = ''
                }
                unref(this.validations).postingAmount.$touch()
            }.bind(this),
        })
        this.postingText = computed({
            get: function () {
                return this.formData.postingText || this.postingConfigurationForm.postingTypeName
            }.bind(this),
            set: function (value) {
                if (value) {
                    this.formData.postingText = value
                } else {
                    this.formData.postingText = ''
                }
                unref(this.validations).postingText.$touch()
            }.bind(this),
        })

        // Validations
        this.validations = useVuelidate({
            postingDate: {
                required,
                isValidIsoDate,
            },
            postingAmount: {
                required,
                decimal,
                minValue: minValue(0.01),
            },
            postingText: {
                required,
            },
        }, {
            postingDate: this.postingDate,
            postingAmount: this.postingAmount,
            postingText: this.postingText,
        })
    }

    swap () {
        this.isSwapped = !this.isSwapped
        if (this.isSwapped && this.itemAccount.balance < 0 && !this.postingAmount) this.postingAmount = Math.abs(this.itemAccount.balance)
    }

    getAccountBalanceChange(account) {
        return getAccountBalanceChange({
            accountType: account.type,
            postingAccountSide: account.id === this.debitAccount.id ? PostingAccountSide.DEBIT : PostingAccountSide.CREDIT,
            postingAmount: parseFloat(this.formData.postingAmount),
        })
    }

    getFormattedAccountBalanceChange(account, displaySign = false) {
        return formatCurrency(this.getAccountBalanceChange(account), { signDisplay: displaySign ? 'exceptZero' : 'never' })
    }

    getAccountBalanceChangeDetails(account) {
        const balanceChange = this.getAccountBalanceChange(account)
        const isDecreasing = balanceChange < 0
        return {
            change: isDecreasing ? 'decrease' : 'increase',
            icon: isDecreasing ? 'mib-graph-stats-descend' : 'mib-graph-stats-ascend',
            color: isDecreasing ? 'negative' : 'positive',
            formattedProjectedBalance: formatCurrency(parseFloat(account.balance) + balanceChange),
        }
    }

    getRoundedAmount (amount) {
        return (Math.round(amount / this.postingConfigurationForm.formData.postingTypeRounding) * this.postingConfigurationForm.formData.postingTypeRounding).toFixed(2)
    }
}

export class AccountingTransferPostingAccount {
    constructor({ id, type, number, name, balance, assignedTo, assigned_as_type }) {
        this.id = id
        this.type = type
        this.number = number
        this.name = name
        this.balance = balance
        this.assignedTo = assignedTo
        this.assigned_as_type = assigned_as_type
    }

    get formattedBalance () {
        return formatCurrency(this.balance)
    }
}
