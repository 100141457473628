<template>
    <page-loading-indicator
        v-if="!prefillCheckDone"
        :number-of-breadcrumb-elements="2"
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.correspondence.email.email', 2)" :to="{ name: 'correspondence-emails' }" />
                <q-breadcrumbs-el :label="$tc('common.correspondence.email.write-email', 1)" :to="{ name: 'correspondence-email-create' }" />
            </template>

            <h1>{{ $tc('common.correspondence.email.write-email', 1) }}</h1>
        </page-header>

        <div class="row">
            <div class="col-xs-12">
                <q-stepper
                    v-model="currentStep"
                    alternative-labels
                    :vertical="$q.screen.lt.md"
                    flat
                    class="correspondence-email-create-stepper-wrapper"
                >
                    <q-step
                        name="configuration"
                        :title="$tc('common.term.configuration', 1)"
                        :caption="(currentStep !== 'configuration' && correspondenceEmail.target_object_type) ? `${$tc('common.term.' + correspondenceEmail.target_object_type.toLowerCase(), 1)}` : ''"
                        icon="mib-cog-double-3"
                        active-icon="mib-cog-double-3"
                        done-icon="mib-check"
                        done-color="secondary"
                        :done="currentStep !== 'configuration'"
                    >
                        <loading-indicator v-if="isLoadingTargetObject" auto-height />
                        <correspondence-item-create-configurator
                            v-else
                            :correspondence-item="correspondenceEmail"
                            :correspondence-item-target-object-type="correspondenceItemTargetObjectType"
                            :correspondence-item-initial-data-source="correspondenceItemInitialDataSource"
                            @submit="onCreateConfiguratorSubmit"
                        />
                    </q-step>

                    <q-step
                        name="compose"
                        :title="$t('common.correspondence.compose')"
                        caption=""
                        icon="mib-pencil-write-3-alternate"
                        active-icon="mib-pencil-write-3-alternate"
                        done-icon="mib-check"
                        done-color="secondary"
                    >
                        <correspondence-item-compose ref="correspondenceItemCompose" :correspondence-item="correspondenceEmail" @submit="onComposeSubmit">
                            <template v-slot:afterEditor>
                                <h2>{{ $tc('common.term.attachment', 2) }}</h2>
                                <info-box v-if="$route.query?.prefill && correspondenceEmail.attachments.length">
                                    <p>{{ $tc('views.correspondence.email.create.prefill-email--attachments-not-added--info', correspondenceEmail.attachments.length) }}</p>
                                    <ul>
                                        <li v-for="attachment in correspondenceEmail.attachments" :key="`attachment-${attachment.id}`">{{ attachment.name }}
                                            <base-button
                                                color="primary"
                                                size="sm"
                                                flat
                                                round
                                                icon="mib-common-file-download"
                                                data-test="btn:download-attachment"
                                                @click="attachment.download()"
                                            >
                                                <q-tooltip :delay="1000" :offset="[0, 10]">{{ $tc('common.attachment.download-attachment', 1) }}</q-tooltip>
                                            </base-button>
                                        </li>
                                    </ul>
                                </info-box>
                                <p :class="{ 'text-negative': ($v.attachmentsTotalSize.$error && !$v.attachmentsTotalSize.maxValue) }">{{ $tc('common.term.of--x-of-n', 1, { x: humanStorageSize(attachmentsTotalSize), n: humanStorageSize(attachmentsTotalSizeMax) }) }}</p>
                                <div class="row q-col-gutter-md">
                                    <div class="col-xs-12 col-lg-4">
                                        <file-upload v-model="formData.attachments" multiple />
                                        <info-box v-if="$v.attachmentsTotalSize.$error" type="negative">
                                            <p v-if="!$v.attachmentsTotalSize.maxValue" class="error-message">{{ $tc('common.error-message.max-upload-size--total', 1, { maxUploadSize: humanStorageSize(attachmentsTotalSizeMax) }) }}</p>
                                        </info-box>
                                    </div>
                                </div>
                            </template>
                        </correspondence-item-compose>

                        <in-page-footer>
                            <base-button
                                :label="$t('common.term.cancel')"
                                primary-button
                                flat
                                @click="cancel"
                            />
                            <base-button
                                data-test="btn:submit"
                                :label="$t('common.term.save')"
                                primary-button
                                outline
                                :disable="isProcessing"
                                :loading="isProcessing && !continueToPreviewAfterProcessing"
                                @click="triggerProcessing(false)"
                            />
                            <base-button
                                data-test="btn:submit"
                                :label="$t('common.term.save-and-continue')"
                                primary-button
                                :disable="isProcessing"
                                :loading="isProcessing && continueToPreviewAfterProcessing"
                                @click="triggerProcessing(true)"
                            />
                        </in-page-footer>
                    </q-step>
                </q-stepper>
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import { EventBus } from '@/event-bus'
import { maxValue } from 'vuelidate/lib/validators'
import { prepareFileUploadWrappers, humanStorageSize } from '@/helpers/file'
import { FileUploadTargetObjectType } from '@/enums'
import { CorrespondenceItemTargetObjectType } from '@/enums/graphql'
import { EditorContentSchemaType } from '@max/tiptap-extensions'
import { CorrespondenceEmail, DocumentContent, EditorContent, Contact, Contract } from '@/models/models'
import { targetObjectContractQueryFields } from '@/graphql/contract/contract'
import CorrespondenceItemCreateConfigurator from '@/components/correspondence/CorrespondenceItemCreateConfigurator'
import CorrespondenceItemCompose from '@/components/correspondence/CorrespondenceItemCompose'
import FileUpload from '@/components/form/FileUpload'

export default {
    name: 'CorrespondenceEmailCreate',
    components: {
        CorrespondenceItemCreateConfigurator,
        CorrespondenceItemCompose,
        FileUpload,
    },
    meta () {
        return {
            title: this.$tc('common.correspondence.email.write-email', 1),
        }
    },
    props: {
        correspondenceItemTargetObjectType: {
            type: String,
            default: null,
        },
        correspondenceItemTargetObjectId: {
            type: String,
            default: null,
        },
        correspondenceItemInitialDataSource: {
            type: String,
            default: null,
        },
    },
    data () {
        return {
            prefillCheckDone: false,
            currentStep: 'configuration',
            isLoadingTargetObject: false,
            correspondenceEmail: new CorrespondenceEmail({
                creator: this.$store.state.user.consultant,
                email_address_overrides: null,
                sender_overrides: null,
                contents: [new DocumentContent({
                    content: EditorContent.create({
                        schemaType: EditorContentSchemaType.EMAIL,
                    }),
                })],
            }),
            formData: {
                attachments: [],
            },
            attachmentsTotalSizeMax: 15 * 1024 * 1024, // 15 MB in Bytes
            isProcessing: false,
            continueToPreviewAfterProcessing: false,
        }
    },
    computed: {
        attachmentsTotalSize () {
            return this.formData.attachments.reduce((total, file) => (total += file.size), 0)
        },
    },
    watch: {
        'formData.attachments' () {
            this.$v.attachmentsTotalSize.$touch()
        },
    },
    validations () {
        return {
            attachmentsTotalSize: {
                maxValue: maxValue(this.attachmentsTotalSizeMax),
            },
        }
    },
    created () {
        if (this.$route.query?.prefill) {
            CorrespondenceEmail.objects.get(this.$route.query.prefill)
                .then(correspondenceEmail => {
                    delete correspondenceEmail.id
                    correspondenceEmail.contents.forEach(content => delete content.id)

                    this.correspondenceEmail = correspondenceEmail
                    this.currentStep = 'compose'
                })
                .catch(() => {
                    this.prefillCheckDone = true
                })
                .finally(() => {
                    this.prefillCheckDone = true
                })
        } else {
            this.prefillCheckDone = true
        }
    },
    methods: {
        async onCreateConfiguratorSubmit (configuration) {
            if (this.correspondenceItemTargetObjectId) {  // Preload and set targetObject.
                this.isLoadingTargetObject = true
                switch (configuration.target_object_type) {
                    case CorrespondenceItemTargetObjectType.CONTACT:
                        await Contact.objects.get(this.correspondenceItemTargetObjectId).then(contact => {
                            this.correspondenceEmail.targetContacts = [contact]
                        })
                        break
                    case CorrespondenceItemTargetObjectType.CONTRACT:
                        await Contract.objects.get(this.correspondenceItemTargetObjectId, targetObjectContractQueryFields).then(contract => {
                            this.correspondenceEmail.targetContracts = [contract]
                        })
                        break
                }
            }

            Object.assign(this.correspondenceEmail, configuration)
            this.currentStep = 'compose'
            this.isLoadingTargetObject = false
        },
        triggerProcessing (continueToPreviewAfterProcessing) {
            if (continueToPreviewAfterProcessing === true) this.continueToPreviewAfterProcessing = true
            this.$refs.correspondenceItemCompose.triggerSubmit()
        },
        onComposeSubmit (data) {
            if (!this.$v.$invalid) {
                this.isProcessing = true
                const { name, languageId, differentiateFormality, targetObjects, contents, emailAddressOverrides, senderOverrides } = data

                CorrespondenceEmail.create({
                    targetObjectType: this.correspondenceEmail.target_object_type,
                    name,
                    targetObjectIds: targetObjects.map(targetObject => targetObject.id),
                    languageId,
                    emailAddressOverrides: (emailAddressOverrides === null) ? null : JSON.stringify(emailAddressOverrides),
                    senderOverrides: (senderOverrides === null) ? null : JSON.stringify(senderOverrides),
                    differentiateFormality,
                    contents: contents.map(documentContent => documentContent.getVariablesObject()),
                })
                    .then(async (correspondenceEmail) => {
                        if (this.formData.attachments.length) {
                            // Add attachments to file upload manager queue.
                            const fileUploadWrappers = await prepareFileUploadWrappers(this.formData.attachments, {
                                targetObjectType: FileUploadTargetObjectType.CORRESPONDENCE_EMAIL_ATTACHMENT,
                                targetObjectId: correspondenceEmail.id,
                                meta: { name: name },
                            })
                            EventBus.$emit('fileUploadManager:addToUploadQueue', fileUploadWrappers)
                        }
                        this.$q.notify({
                            type: 'positive',
                            message: this.$tc('common.notifications.correspondence.correspondence-email-saved-success', 1),
                        })

                        if (this.continueToPreviewAfterProcessing) {
                            this.$router.replace({ name: 'correspondence-email-preview', params: { id: correspondenceEmail.id } })
                        } else {
                            this.$router.replace({ name: 'correspondence-email-update', params: { id: correspondenceEmail.id } })
                        }
                    })
                    .catch(error => {
                        this.continueToPreviewAfterProcessing = false
                        this.$refs.correspondenceItemCompose.$refs.correspondenceItemForm.showFormErrorMessage(error)
                        this.$refs.correspondenceItemCompose.$refs.correspondenceItemForm.resetFormSubmitStatus()
                        this.$q.notify({
                            type: 'negative',
                            message: this.$tc('common.term.error', 1),
                            caption: this.$tc('common.notifications.correspondence.correspondence-email-saved-error', 1),
                        })
                    })
                    .finally(() => {
                        this.isProcessing = false
                    })
            }
        },
        cancel () {
            this.$router.go(-1)
        },
        humanStorageSize (...args) {
            return humanStorageSize(...args)
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep {
    // Needed for editor elements with `position: sticky;` to work
    .correspondence-email-create-stepper-wrapper {
        .q-panel-parent,
        .q-panel.scroll {
            overflow: visible;
        }
    }

    .q-stepper__step-inner.q-stepper__step-inner {
        @include mq($from: $sizeBreakpointMd) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
</style>
