import { ContentStoreViewMode, ContentStoreItemWrapperStatus } from '@/enums'
import { wrapContentStoreItems } from '@/helpers/content-store'

export const contentStoreMixin = {
    data () {
        return {
            ContentStoreViewMode,
            currentMode: ContentStoreViewMode.LIST,
            items: [],
            selection: [],
            importQueue: [],
            importLog: [],
        }
    },
    methods: {
        triggerImports () {
            if (this.selection.length) {
                this.selection.every(contentStoreItemWrapper => (contentStoreItemWrapper.status = ContentStoreItemWrapperStatus.QUEUED))

                this.setMode(ContentStoreViewMode.IMPORT)
                this.importQueue.push(...this.selection)
                this.selection = []
            }
        },
        abortImport () {
            this.importQueue.filter(importQueueItem => importQueueItem.status === ContentStoreItemWrapperStatus.QUEUED).forEach(importQueueItem => {
                this.handleImportAbort(importQueueItem)
            })
        },
        acknowledgeImport () {
            this.setMode(ContentStoreViewMode.LIST)
            this.importLog = []
        },
        addToImportLog (contentStoreItemWrapper, status) {
            const contentStoreItemWrapperIndex = this.importQueue.findIndex(queueItem => queueItem.id === contentStoreItemWrapper.id)
            this.importQueue.splice(contentStoreItemWrapperIndex, 1)

            contentStoreItemWrapper.status = status
            this.importLog.unshift(contentStoreItemWrapper)
        },
        handleImportSuccess (contentStoreItemWrapper) {
            this.addToImportLog(contentStoreItemWrapper, ContentStoreItemWrapperStatus.DONE)
        },
        handleImportFailure (contentStoreItemWrapper) {
            this.addToImportLog(contentStoreItemWrapper, ContentStoreItemWrapperStatus.FAILED)
        },
        handleImportAbort (contentStoreItemWrapper) {
            this.addToImportLog(contentStoreItemWrapper, ContentStoreItemWrapperStatus.ABORTED)
        },
        async wrapContentStoreItems () {
            this.items = await wrapContentStoreItems(this.$options.contentStoreSettings.items)
        },
        setMode (mode) {
            this.currentMode = mode
            this.$emit('mode-changed', this.currentMode)
        },
    },
    watch: {
        importQueue () {
            if (this.importQueue.length) {
                const nextItem = this.importQueue[0]
                if (nextItem.status === ContentStoreItemWrapperStatus.QUEUED) this.importItem(nextItem)
            } else {
                this.$emit('import-finished')
            }
        },
    },
    created () {
        this.wrapContentStoreItems()
    },
}
