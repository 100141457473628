<template>
    <q-menu
        anchor="bottom start"
        self="top start"
    >
        <q-list dense>
            <q-item v-close-popup clickable @click="copyToClipboard(`${illustration}`)">
                <q-item-section class="q-pt-sm q-pb-sm">
                    <q-item-label>{{ $t('views.dev.design.illustrations.copy-illustration-name') }}</q-item-label>
                </q-item-section>
            </q-item>
            <q-separator />
            <q-item v-close-popup clickable>
                <q-item-section @click='copyToClipboard(`<illustration name="${illustration}" />`)'>{{ $t('views.dev.design.illustrations.copy-illustration-component') }}</q-item-section>
            </q-item>
            <q-item v-close-popup clickable :disable="!(faded || interactive || selected || error)">
                <q-item-section @click='copyToClipboard(`<illustration name="${illustration}"${illustrationAttrs()} />`)'>{{ $t('views.dev.design.illustrations.copy-illustration-component-including-attrs') }}</q-item-section>
            </q-item>
            <q-separator />
            <q-item class="bg-background-primary">
                <q-item-section>
                    <q-item-label caption>{{ $tc('common.term.illustration', 1) }}: <code>{{ `${illustration}` }}</code></q-item-label>
                </q-item-section>
            </q-item>
        </q-list>
    </q-menu>
</template>

<script>
import { copyToClipboard } from 'quasar'

export default {
    name: 'DevIllustrationContextMenu',
    props: {
        illustration: {
            type: String,
            required: true,
        },
        faded: {
            type: Boolean,
            default: false,
        },
        interactive: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        copyToClipboard (content) {
            content = content.replace(/<br>/g, '\n')

            copyToClipboard(content)
                .then(() => {
                    this.$q.notify({
                        type: 'positive',
                        message: this.$t('common.notifications.copied-to-clipboard-success'),
                        caption: content,
                    })
                })
                .catch(() => {
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$t('common.notifications.copied-to-clipboard-error'),
                    })
                })
        },
        illustrationAttrs () {
            const attrs = []

            if (this.faded) attrs.push('faded')
            if (this.interactive) attrs.push('interactive')
            if (this.selected) attrs.push('selected')
            if (this.error) attrs.push('error')

            return attrs.length ? ' ' + attrs.join(' ') : '' // `attrs` prepended with whitespace for proper markup
        },
    },
}
</script>
