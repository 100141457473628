import gql from 'graphql-tag'

export const CONTACT_CONSULTING_SETTINGS_DELETE_MUTATION = gql`
    mutation contactConsultingSettingsDelete(
        $contact_id: ID!
    ) {
        contactConsultingSettingsDelete(
            contact_id: $contact_id
        ) {
            status
        }
    }
`
