import { walkJSON } from '@/helpers/index'
import { FormField, FormSection } from '@/libs/form'

// TODO refactoring @TFU: Separate property name renaming from form composition.
export function composeProductFieldsSection (form, templateFormConfiguration, setDefaultValues = false) {
    const PRODUCT_TEMPLATE_FORM_CONFIGURATION = JSON.parse(JSON.stringify(templateFormConfiguration))
    walkJSON(PRODUCT_TEMPLATE_FORM_CONFIGURATION, (value, key, node) => {
        if (key === 'input_type') {
            node.inputType = value
            if (node.inputType === 'date') node.inputType = 'calendar' // TODO: check if aliases is a thing; maybe just re-factor calendar to date?
            if (node.inputType === 'currency') node.inputType = 'text' // TODO: check if aliases is a thing; maybe create currency component?
            delete node.input_type
        }
        if (key === 'default_value') {
            if (value !== null) node.defaultValue = value // This is necessary due to a shortcoming in the product template implementation (default_value is set to null even if no default_value was manually set) and still works because usually the default value of a list is `null` – so no damage is done here.
            delete node.default_value
        }
        if (key === 'validators' && value.length) {
            node.validators = value.filter(validator => validator.type !== 'inList') // The `inList` validator is only relevant for API validation.
        }
    })
    const productFieldsSection = form.addSection(new FormSection('productFields', {
        label: 'common.contract.product-specific-info',
    }))

    function composeFormItems (formItem, items) {
        items.forEach((item, index) => {
            switch (item.type) {
                case 'field':
                    formItem.addField(new FormField(item.key, item))
                    break
                case 'section': {
                    const section = formItem.addSection(new FormSection(item.key ?? `section${index}`, item))
                    if (item.children?.length) composeFormItems(section, item.children)
                    break
                }
            }
        })
    }
    composeFormItems(productFieldsSection, PRODUCT_TEMPLATE_FORM_CONFIGURATION.children)
}
