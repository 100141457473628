import { Model } from '@/models'
import { formatDate } from '@/helpers/date'

export class BasicStatement extends Model {
    /**
     * Create a BasicStatement model wrapper.
     * @param {Object} data - Object holding the field values.
     */

    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'consultingEntity',
            'date_from',
            'date_until',
            'include_attachments',
            'file_storage_item_shared_at',
        ], data)
    }

    get formattedDateFrom () {
        return formatDate(this.date_from)
    }
    get formattedDateUntil () {
        return formatDate(this.date_until)
    }

    get formattedPeriod () {
        return `${this.formattedDateFrom}–${this.formattedDateUntil}`
    }

    get formattedFileStorageItemSharedAt () {
        return formatDate(this.file_storage_item_shared_at)
    }
}
