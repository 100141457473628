import { QueueItemStatus } from '@/enums/graphql'

/**
 * Parse form data values.
 * @export
 * @param  {Object} formData - {dateFrom, dateUntil, fileTypes[], includeAttachments, triggerFileStorageItemSharing}
 * @return {Object} typeof StatementInput
 */
export function parseFormDataToInput(formData) {
    return {
        date_from: formData.dateFrom,
        date_until: formData.dateUntil,
        file_types: formData.fileTypes,
        include_attachments: formData.includeAttachments,
        trigger_file_storage_item_sharing: formData.triggerFileStorageItemSharing,
    }
}

/**
 * Filter statement with cancel creation condition.
 * @export
 * @param  {Array} statements - typeof Statement
 * @return {Array} typeof Statement
 */
export function cancelCreationCondition (statements) {
    return statements.filter(statement => statement.currentQueueItem.status === QueueItemStatus.QUEUED)
}

/**
 * Filter statement with share condition.
 * @export
 * @param  {Array} statements - typeof Statement
 * @return {Array} typeof Statement
 */
export function shareCondition (statements) {
    return statements.filter(statement => {
        return statement.currentQueueItem.status === QueueItemStatus.DONE && statement.file_storage_item_shared_at === null && statement.recipients !== null && statement.recipients.consultants.length > 0
    })
}

/**
 * Filter statement with delete condition.
 * @export
 * @param  {Array} statements - typeof Statement
 * @return {Array} typeof Statement
 */
export function deleteCondition (statements) {
    return statements.filter(statement => [QueueItemStatus.FAILED, QueueItemStatus.CANCELLED, QueueItemStatus.DONE].includes(statement.currentQueueItem.status))
}
