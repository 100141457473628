import gql from 'graphql-tag'

export const AREA_OF_RESPONSIBILITY_CREATE_MUTATION = gql`
    mutation areaOfResponsibilityCreate(
        $companyId: ID!
        $isAnonymous: Boolean!
        $contactRelationshipId: ID
        $areaOfResponsibility: AreaOfResponsibilityInput!
    ) {
        areaOfResponsibilityCreate(
            company_id: $companyId
            is_anonymous: $isAnonymous
            contact_relationship_id: $contactRelationshipId
            areaOfResponsibility: $areaOfResponsibility
        ) {
            id
            is_anonymous
            name
            description
            contactRelationship {
                id
                definition {
                    id
                    type
                    categories
                    node1_type
                    node1_valid_contact_types
                    node2_type
                    node2_valid_contact_types
                }
                node1 {
                    id
                    contactNumber {
                        id
                        number
                    }
                    ... on Person {
                        first_name
                        last_name
                    }
                    ... on Company {
                        company_name
                        is_tenant_company
                    }
                }
                node2 {
                    id
                    contactNumber {
                        id
                        number
                    }
                    ... on Person {
                        first_name
                        last_name
                    }
                    ... on Company {
                        company_name
                        is_tenant_company
                    }
                }
                notes
            }
            contactRelationshipNode2Contact {
                ... on BasicPerson {
                    id
                    first_name
                    last_name
                }
                ... on BasicCompany {
                    id
                    company_name
                }
            }
            emailAddress {
                id
                category {
                    id
                    key
                    name
                }
                label {
                    key
                    label
                }
                email
                is_main_email_address
            }
            phoneNumber {
                id
                category {
                    id
                    key
                    name
                }
                label {
                    key
                    label
                }
                phone_number
                is_main_phone_number
            }
            created_at
            updated_at
        }
    }
`
