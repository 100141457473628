import gql from 'graphql-tag'

export const APPLICATION_FILE_UPDATE_MUTATION = gql`
    mutation applicationFileUpdate(
        $id: ID!
        $typeId: ID
        $consultantId: ID
        $fileName: String
    ) {
        applicationFileUpdate(
            id: $id
            type_id: $typeId
            consultant_id: $consultantId
            file_name: $fileName
        ) {
            id
            file {
                id
                name
            }
            type {
                id
                status
                key
                name
            }
            consultant {
                id
                contact_number
                first_name
                last_name
                gender
                title
                consulting_company_name
                consulting_settings_status
            }
        }
    }
`
