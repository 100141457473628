import { Form, FormField } from '@/libs/form'
import { ContactType } from '@/enums/graphql'

export class ProductProviderSettingsForm extends Form {
    compose () {
        if (this.context?.fields.includes('contactId')) {
            this.addField(new FormField('contactId', {
                label: 'common.term.contact',
                type: 'contact',
                validators: [
                    { type: 'required' },
                ],
                attributes: {
                    filters: { contact_type: ContactType.COMPANY, hasProductProviderSettings: false },
                    autofocus: true,
                },
            }))
        }

        if (this.context?.fields.includes('displayName')) {
            this.addField(new FormField('displayName', {
                label: 'common.term.display-name',
                type: 'text',
                attributes: {
                    infoText: 'common.product-provider.display-name--description',
                },
            }))
        }

        if (this.context?.fields.includes('igb2bInsurerCode')) {
            const igb2bInsurerCodeAttributes = {
                filter: { filterIsAssignedToProductProvider: false },
                infoText: 'common.product-provider.igb2b-insurer-code--description',
            }
            if (this.context?.igb2bInsurerCodeInjectOptions) igb2bInsurerCodeAttributes.injectOptions = this.context?.igb2bInsurerCodeInjectOptions
            this.addField(new FormField('igb2bInsurerCode', {
                label: 'common.product-provider.igb2b-insurer-code',
                type: 'igb2bInsurerCode',
                attributes: igb2bInsurerCodeAttributes,
            }))
        }
    }
}

