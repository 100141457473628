import { Model } from '@/models'
import { isPlainObject } from '@/helpers'

export class DocumentContent extends Model {
    /**
     * Create a DocumentContent model wrapper.
     * @param {Object} data - Object holding the field values.
     * @param {string} data.id
     * @param {string} data.formality
     * @param {Object} data.content
     * @param {number} data.content.schemaType – The schema type that is used for this content (relevant for loading the correct editor schema).
     * @param {number} data.content.schemaVersion – The schema version number, currently all new contents are generated with structure version 1.
     * @param {Object} data.content.contentJSON – Holds the editors content serialized as JSON. Used to re-initialize the editor for editing.
     * @param {string} data.content.contentHTML – Holds the editors content serialized as HTML. Used in the process to generate the final medium.
     * @param {Object} data.content.attrs – Object holding additional attributes related to the content.
     */
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'formality',
            'content',
        ], data)
    }

    static contentSchemaFactory (initialValues) {
        console.warn('DocumentContent.contentSchemaFactory() is deprecated, use EditorContent.create() instead.')
        const contentSchema = {
            schemaType: '',
            schemaVersion: 1,
            contentJSON: {}, // TODO @TFU: Check if this should be initialized with `null`.
            contentHTML: '',
            attrs: {}, // TODO @TFU: Check if this should be initialized with `null`.
        }

        if (isPlainObject(initialValues)) {
            if (typeof initialValues.schemaType === 'undefined') throw Error('The param `initialValues` must be provided with at least the property `schemaType`.')

            if (typeof initialValues.schemaType === 'string') contentSchema.schemaType = initialValues.schemaType
            if (isPlainObject(initialValues.contentJSON)) contentSchema.contentJSON = initialValues.contentJSON // TODO @TFU: Check if we should create a copy with JSON.parse(JSON.stringify()) or pass the reference.
            if (typeof initialValues.contentHTML === 'string') contentSchema.contentHTML = initialValues.contentHTML
            if (isPlainObject(initialValues.attrs)) contentSchema.attrs = JSON.parse(JSON.stringify(initialValues.attrs)) // TODO @TFU: Check if we should create a copy with JSON.parse(JSON.stringify()) or pass the reference.
        } else {
            throw Error('The param `initialValues` must be provided with at least the property `schemaType`.')
        }

        return contentSchema
    }

    getVariablesObject () {
        const variables = {
            content: this.content.getVariablesObject(),
        }

        if (this.id) variables.id = this.id
        if (this.formality) variables.formality = this.formality

        return variables
    }
}
