<template>
    <div v-if="categories.length" class="row q-col-gutter-sm">
        <div class="col-xs-12 col-lg-6">
            <base-select-filter
                ref="category"
                v-model="value.category"
                :label="$tc('common.term.category', 1)"
                :options="categories"
                option-label="name"
                option-value="id"
                :error="categoryError"
            >
                <template v-slot:error>
                    <div v-show="categoryError" data-test="error:category-required">{{ $t('views.admin.products.product-templates.category-must-be-selected') }}</div>
                </template>
            </base-select-filter>
        </div>
        <div class="col-xs-12 col-lg-6">
            <base-select-filter
                ref="subcategory"
                v-model="value.subCategory"
                :label="$tc('common.term.subcategory', 1)"
                :options="getSubcategories(value.category)"
                option-label="name"
                option-value="id"
                :error="subCategoryError"
                :disable="!value.category"
            >
                <template v-slot:error>
                    <div v-show="subCategoryError" data-test="error:subcategory-required">{{ $t('views.admin.products.product-templates.subcategory-must-be-selected') }}</div>
                </template>
            </base-select-filter>
        </div>
    </div>
</template>

<script>
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { ProductService } from '@/services'

export default {
    name: 'ProductCategoriesSelect',
    components: {
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            dirty: false,
            categories: [],
        }
    },
    computed: {
        categoryError () {
            return (this.$attrs.error && !this.value.category)
        },
        subCategoryError () {
            return (this.$attrs.error && !!this.value.category)
        },
    },
    watch: {
        'value.category' () {
            if (this.dirty) {
                this.value.subCategory = null
            }
            this.dirty = true
        },
    },
    created () {
        ProductService.getCategories().then(response => {
            this.categories = response.data
        })
    },
    methods: {
        getSubcategories (categoryId) {
            if (this.value.category && this.categories && this.categories.length) {
                return this.categories.find(category => category.id === categoryId).subCategories
            } else {
                return []
            }
        },
    },
}
</script>
