<template>
    <q-select
        :value="value"
        v-bind="$attrs"
        :options="filteredOptions"

        map-options
        emit-value
        use-input

        use-chips
        filled

        square
        dense
        options-dense
        class="truncate"

        @input="inputValue => $emit('input', inputValue)"
        @filter="filterFn"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" /><!-- Named slots -->
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template><!-- Scoped slots -->

        <template v-slot:no-option>
            <q-item>
                <q-item-section>
                    {{ $tc('common.term.result', 0) }}
                </q-item-section>
            </q-item>
        </template>

        <template v-if="infoText || infoTitle" v-slot:append>
            <info-icon
                :title="infoTitle"
                :text="infoText"
                class="all-pointer-events"
            />
        </template>
    </q-select>
</template>

<script>
export default {
    name: 'BaseSelectFilter',
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        infoTitle: {
            type: String,
            default: '',
        },
        infoText: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            filteredOptions: this.options,
        }
    },
    methods: {
        filterFn (value, doneFn) {
            doneFn(() => {
                const query = value.toLowerCase()
                const optionLabelProp = this.$attrs['option-label'] || 'label'
                const isFunction = (typeof optionLabelProp === 'function')
                this.filteredOptions = this.options.filter(option => {
                    const optionLabel = (isFunction) ? optionLabelProp(option) : option[optionLabelProp]
                    return optionLabel.toLowerCase().indexOf(query) > -1
                })
            })
        },
    },
}
</script>
