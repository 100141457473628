import { EditorContentSchemaType } from '@max/tiptap-extensions'
import { EditorDisplayMode, SmartObjectTreeDisplayMode } from '@/enums'
import { DocumentContentFormality } from '@/enums/graphql'

const formalityOptions = Object.values(DocumentContentFormality).map(value => ({ value: value, label: `common.contact.formality.formality--${value.toLowerCase()}` }))

export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'formality',
            label: 'common.contact.formality.formality',
            inputType: 'select',
            extra_attributes: {
                list: {
                    options: formalityOptions,
                },
            },
        },
        {
            type: 'field',
            key: 'correspondenceSalutationOverride',
            label: 'forms.field.label.correspondence.custom-salutation',
            inputType: 'editor',
            extra_attributes: {
                schemaType: EditorContentSchemaType.SALUTATION,
                displayMode: EditorDisplayMode.MINIMAL,
                smartObjectTreeDisplayMode: SmartObjectTreeDisplayMode.NONE,
            },
        },
        {
            type: 'field',
            key: 'correspondenceSalutationId',
            label: 'forms.field.label.correspondence.predefined-salutation',
            inputType: 'correspondenceSalutation',
        },
    ],
}
