import gql from 'graphql-tag'

export const REGISTRATION_VALIDATE_TOKEN_MUTATION = gql`
    mutation registrationValidateToken($token: String!) {
        registrationValidateToken(
            token: $token
        ) {
            token,
            user {
                id,
                email,
                status
            }
        }
    }
`
