import { Wizard, WizardStep } from '@/libs/wizard'
import { reactive } from 'vue'

export class FormWizard extends Wizard {
    constructor (steps, options) {
        super(steps, options)
    }

    get formData () {
        const data = {}
        this.steps.forEach(step => {
            data[step.key] = step.data
        })
        return data
    }

    handleFormSubmit (data) {
        this.getCurrentStep().setData(data)
        this.nextStep()
    }
}

export class FormWizardStep extends WizardStep {
    constructor (key, configuration) {
        super(key, configuration)
        this.data = reactive({})
    }

    setData (data) {
        Object.assign(this.data, data)
    }
}
