<template>
    <page-loading-indicator
        v-if="!commissionListImportExcelMapping"
        :number-of-breadcrumb-elements="2"
        actions
        :number-of-next-actions="1"
        subtitle
    />
    <page-wrapper v-else>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$tc('common.commission.excel-mapping', 2)" :to="{ name: 'commission-list-import-excel-mappings' }" />
                <q-breadcrumbs-el :label="commissionListImportExcelMapping.name" :to="{ name: 'commission-list-import-excel-mapping-update', params: { id: id } }" data-test="breadcrumb:commission-excel-mapping-detail" />
            </template>

            <h1>
                {{ commissionListImportExcelMapping.name }}
                <span v-if="commissionListImportExcelMapping.productProvider.display_name" class="subtitle">({{ commissionListImportExcelMapping.productProvider.display_name }})</span>
                <span v-else class="subtitle">({{ commissionListImportExcelMapping.productProvider.name }})</span>
            </h1>

            <template v-slot:actions>
                <actions
                    :actions="commissionListImportExcelMapping.actions"
                    @click="handleActions"
                />

                <!-- Delete commissionListImportExcelMapping -->
                <form-dialog
                    ref="deleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleDelete"
                >
                    <template v-slot:title>{{ $tc('views.commission.excel-mapping.delete-excel-mapping', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.commission.excel-mapping.delete-excel-mapping--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:name><b>{{ commissionListImportExcelMapping.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <template v-if="commissionListImportExcelMapping">
            <commission-list-import-excel-mapping-form
                ref="commissionListImportExcelMappingForm"
                :commission-list-import-excel-mapping="commissionListImportExcelMapping"
                @submit="onSubmit"
            />
        </template>

        <div>
            <p>
                <small>
                    <base-button
                        show-as-link
                        @click="$refs.activityLogOverlay.open({ filterSubjects: [{ id: id, type: ActivityLogSubjectType.COMMISSION_LIST_IMPORT_EXCEL_MAPPING }] })"
                    >{{ $tc('common.term.created-on--date-time', 1, { date: commissionListImportExcelMapping.formattedCreatedAtDate, time: commissionListImportExcelMapping.formattedCreatedAtTime }) }}</base-button>
                </small>
            </p>
        </div>

        <!-- Activity log -->
        <activity-log-overlay ref="activityLogOverlay" />
    </page-wrapper>
</template>

<script>
import { CommissionListImportExcelMapping } from '@/models/commissionListImportExcelMapping'
import CommissionListImportExcelMappingForm from '@/components/commission/CommissionListImportExcelMappingForm.vue'
import { ActivityLogSubjectType } from '@/enums/graphql'
import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'
import ActivityLogOverlay from '@/components/ActivityLogOverlay'

export default {
    name: 'CommissionListImportExcelMappingUpdate',
    meta () {
        return {
            title: this.$tc('views.commission.excel-mapping.update-excel-mapping', 1),
        }
    },
    components: {
        Actions,
        FormDialog,
        CommissionListImportExcelMappingForm,
        ActivityLogOverlay,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            ActivityLogSubjectType,
            commissionListImportExcelMapping: null,
        }
    },
    created () {
        CommissionListImportExcelMapping.objects.get(this.id).then(commissionListImportExcelMapping => {
            this.commissionListImportExcelMapping = commissionListImportExcelMapping
        })
            .catch(error => {
                // Forward to `forbidden` error view.
                this.$router.replace({ name: '403' }) // TODO @TFU: Differentiate between 403 and 404.
            })
    },
    methods: {
        onSubmit (formData) {
            const commissionListImportExcelMappingInput = {
                commission_amount_column: formData.commissionAmountColumn,
                commission_entries_start_row: parseInt(formData.commissionEntriesStartRow),
                commission_type_column: formData.commissionTypeColumn,
                contract_number_column: formData.contractNumberColumn,
                customer_full_name_column: formData.customerFullNameColumn,
                name: formData.name,
                premium_from_column: formData.premiumFromColumn,
                premium_from_date_format: formData.premiumFromDateFormat,
                premium_to_column: formData.premiumToColumn,
                premium_to_date_format: formData.premiumToDateFormat,
            }
            this.commissionListImportExcelMapping.update(commissionListImportExcelMappingInput)
                .then(() => {
                    this.$router.replace({ name: 'commission-list-import-excel-mappings' })
                })
                .catch(error => {
                    this.$refs.commissionListImportExcelMappingForm.showFormErrorMessage(error)
                    this.$refs.commissionListImportExcelMappingForm.resetFormSubmitStatus()
                })
        },
        handleActions (action) {
            switch (action.key) {
                case CommissionListImportExcelMapping.action.DELETE:
                    this.$refs.deleteDialog.open()
                    break
            }
        },
        handleDelete () {
            this.commissionListImportExcelMapping.delete()
                .then(() => {
                    this.$refs.deleteDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.commission.excel-mapping-deleted-success', 1),
                    })
                    this.$router.replace({ name: 'commission-list-import-excel-mappings' })
                })
                .catch(error => {
                    this.$refs.deleteDialog.showFormErrorMessage(error)
                    this.$refs.deleteDialog.resetFormSubmitStatus()
                })
        },
    },
}
</script>
