import { ViewMode } from '@/enums'
import { nextTick, reactive, ref, watch } from 'vue'
import { useStore } from '@/composables/store'

export function useViewModeManager (context) {
    // Composables
    const store = useStore()

    // Data
    const state = reactive({
        currentMode: ViewMode.LIST,
        currentItem: null,
        currentDialog: null,
    })

    // Template refs
    const createItemDialog = ref(null)
    const updateItemDialog = ref(null)
    const deleteItemDialog = ref(null)

    // Methods
    async function triggerCreateItem () {
        state.currentMode = ViewMode.CREATE
        await nextTick()
        state.currentDialog = createItemDialog.value
        state.currentDialog.open()
    }
    async function triggerUpdateItem (item) {
        state.currentItem = item
        state.currentMode = ViewMode.UPDATE
        await nextTick()
        state.currentDialog = updateItemDialog.value
        state.currentDialog.open()
    }
    async function triggerDeleteItem (item) {
        state.currentItem = item
        state.currentMode = ViewMode.DELETE
        await nextTick()
        state.currentDialog = deleteItemDialog.value
        state.currentDialog.open()
    }
    function handleUpdateItem (formData) {
        state.currentItem.update(formData)
            .then(item => {
                emitUpdate(item)
                updateItemDialog.value.close()
                // TODO: Add notification?
            })
            .catch(error => {
                updateItemDialog.value.showFormErrorMessage(error)
                updateItemDialog.value.resetFormSubmitStatus()
            })
    }
    function handleDeleteItem () {
        state.currentItem.delete()
            .then(() => {
                emitDelete(state.currentItem)
                deleteItemDialog.value.close()
                // TODO: Add notification?
            })
            .catch(error => {
                deleteItemDialog.value.showFormErrorMessage(error)
                deleteItemDialog.value.resetFormSubmitStatus()
            })
    }
    function cancelAction () {
        state.currentMode = ViewMode.LIST
        state.currentItem = null
        if (state.currentDialog) state.currentDialog.close()
        state.currentDialog = null
    }
    function emitCreate (item) {
        context.emit(ViewMode.CREATE.toLowerCase(), item)
    }
    function emitUpdate (item) {
        context.emit(ViewMode.UPDATE.toLowerCase(), item)
    }
    function emitDelete (item) {
        context.emit(ViewMode.DELETE.toLowerCase(), item)
    }

    // Watchers
    watch(() => store.state.editMode, (editMode) => {
        if (editMode === false && state.currentMode !== ViewMode.CREATE) cancelAction()
    })

    return {
        // Static
        ViewMode,

        // Data
        state,

        // Template refs
        createItemDialog,
        updateItemDialog,
        deleteItemDialog,

        // Methods
        triggerCreateItem,
        triggerUpdateItem,
        triggerDeleteItem,
        handleUpdateItem,
        handleDeleteItem,
        cancelAction,
        emitCreate,
    }
}
