<template>
    <q-skeleton
        v-bind="$attrs"
        :width="width ? width : `${Math.floor(Math.random() * maxRandomWidth) + minRandomWidth}${randomWidthUnit}`"
        :height="height ? height : null"
        :square="square"
        animation="fade"
        :style="inline ? 'display: inline-block;' : null"
    />
</template>

<script>
export default {
    name: 'Skeleton',
    props: {
        width: {
            type: String,
            default: '',
        },
        minRandomWidth: {
            type: Number,
            default: 5,
        },
        maxRandomWidth: {
            type: Number,
            default: 20,
        },
        randomWidthUnit: {
            type: String,
            default: 'ch',
        },
        height: {
            type: String,
            // default: '40px', // Default input field height
            default: '',
        },
        inline: {
            type: Boolean,
            default: false,
        },
        square: {
            type: Boolean,
            default: true,
        },
    },
}
</script>
