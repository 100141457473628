import { i18n } from '@/i18n'
import { ActivityLogParser } from '@/parsers/activityLog'

export class PostingActivityLogParser extends ActivityLogParser {
    static attributeDataTypes = {
        'type.name': 'localized:userLanguage',
        'date': 'date',
        'amount': 'currency',
    }

    static preprocess (activityLog) {
        ['debitAccount', 'creditAccount'].forEach(accountType => {
            const accountNumberKey = `${accountType}.number`
            const accountNameKey = `${accountType}.name`
            if (activityLog.properties.attributes && activityLog.properties.attributes[accountNumberKey] && activityLog.properties.attributes[accountNameKey]) {
                activityLog.properties.attributes[accountType] = `${activityLog.properties.attributes[accountNumberKey]} – ${activityLog.properties.attributes[accountNameKey]}`
                delete activityLog.properties.attributes[accountNumberKey]
                delete activityLog.properties.attributes[accountNameKey]
            }

            if (activityLog.properties.old && activityLog.properties.old[accountNumberKey] && activityLog.properties.old[accountNameKey]) {
                activityLog.properties.old[accountType] = `${activityLog.properties.old[accountNumberKey]} – ${activityLog.properties.old[accountNameKey]}`
                delete activityLog.properties.old[accountNumberKey]
                delete activityLog.properties.old[accountNameKey]
            }
        })
        return activityLog
    }

    static getLogDescriptionAddAttachment (activityLog, data) {
        return i18n.tc('common.change-log.someone-added-something', 1, { someone: data.causer, something: `<b>${ i18n.tc('common.change-log.subject.some.attachment', 1) }</b>` })
    }

    static getLogDescriptionDeleteAttachment (activityLog, data) {
        return i18n.tc('common.change-log.someone-deleted-something', 1, { someone: data.causer, something: `<b>${ i18n.tc('common.change-log.subject.some.attachment', 1) }</b>` })
    }

    static getLogDetailsAddAttachment (activityLog, data) {
        return [
            {
                key: 'new-attachment',
                newValue: activityLog.properties.attributes['attachment.name'],
            },
        ]
    }

    static getLogDetailsDeleteAttachment (activityLog, data) {
        return [
            {
                key: 'new-attachment',
                oldValue: activityLog.properties.attributes['attachment.name'],
            },
        ]
    }
}
