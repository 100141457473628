import gql from 'graphql-tag'

export const PROCESSED_CORRESPONDENCE_LETTER_DELETE_MUTATION = gql`
    mutation processedCorrespondenceLetterDelete(
        $id: ID!
        $deleteConsultingFile: Boolean!
    ) {
        processedCorrespondenceLetterDelete(
            id: $id
            delete_consulting_file: $deleteConsultingFile
        ) {
            status
        }
    }
`
