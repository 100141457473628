import { kebabCase } from 'lodash'
import { FileType } from '@/enums/graphql'

const typeOptions = Object.values(FileType).map(value => ({ value: value, label: `common.file.type.${kebabCase(value)}` }))

export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'dateFrom',
            label: 'forms.field.label.statement.accounting-period--from',
            inputType: 'calendar',
            validators: [
                { type: 'required' },
                { type: 'date' },
                {
                    type: 'isBeforeOrEqualToDate',
                    params: {
                        target: 'dateUntil',
                        targetLabel: 'forms.field.label.statement.accounting-period--to',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'dateUntil',
            label: 'forms.field.label.statement.accounting-period--to',
            inputType: 'calendar',
            validators: [
                { type: 'required' },
                { type: 'date' },
                {
                    type: 'isAfterOrEqualToDate',
                    params: {
                        target: 'dateFrom',
                        targetLabel: 'forms.field.label.statement.accounting-period--from',
                    },
                },
            ],
        },
        {
            type: 'field',
            key: 'fileTypes',
            label: 'forms.field.label.statement.file-type',
            inputType: 'select',
            extra_attributes: {
                list: {
                    options: typeOptions,
                },
                multiple: true,
            },
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'includeAttachments',
            label: { key: 'forms.field.label.statement.include-attachment', count: 2 },
            inputType: 'checkbox',
            validators: [
                { type: 'required' },
            ],
        },
    ],
}
