<template>
    <q-expansion-item
        ref="builderField"
        v-model="expanded"
        class="product-template-builder--field"
        :class="{ remove: item.removeFlag, error: $v.$anyError }"
        expand-icon-toggle
        expand-icon="mib-pencil"
        expanded-icon="mib-check"
        header-class="item-header draggable-handle"
        dense
    >
        <template v-slot:header>
            <q-item-section avatar class="drag-icon-wrapper do-not-print">
                <q-icon
                    class="drag-icon"
                    name="mib-cursor-select"
                    size="xs"
                    color="secondary"
                />
            </q-item-section>

            <q-item-section @dblclick="expanded = !expanded">
                <q-item-label>{{ item.label }}</q-item-label>
            </q-item-section>

            <q-item-section side class="item-header--actions do-not-print">
                <div class="row items-center">
                    <base-button
                        data-test="btn:product-template-builder-item-remove"
                        :icon="(!expanded) ? 'mib-pencil' : 'mib-check'"
                        size="xs"
                        color="secondary"
                        round
                        flat
                        @click="$refs.builderField.toggle()"
                    />

                    <slot name="header" />
                </div>
            </q-item-section>
        </template>

        <div class="inner-wrapper row q-col-gutter-sm">
            <div class="col-xs-12 col-sm-6">
                <form-builder item-key="required" />
            </div>
            <div class="col-xs-12 col-sm-6">
                <form-builder item-key="defaultValue" />
            </div>
            <div v-show="formData.defaultValue" class="col-xs-12">
                <form-builder :item-key="item.key" :disable="!formData.defaultValue" />
            </div>
        </div>
    </q-expansion-item>
</template>

<script>
import { getFormItemByKey, setupFormLocale, setupFormData, setupFormValidations } from '@/helpers/form'
import FormBuilder from '@/components/form/FormBuilder.vue'

export default {
    name: 'ProductTemplateField',
    components: {
        FormBuilder,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data () {
        const itemSchema = {
            type: 'field',
            key: this.item.key,
            label: {
                key: 'common.term.default-value-for-field',
                values: { field: this.item.label },
            },
            inputType: this.item.input_type,
            validators: [],
        }
        if (this.item.extra_attributes) itemSchema.extra_attributes = this.item.extra_attributes
        if (this.item.validators) {
            itemSchema.validators = Array.from(this.item.validators)
            if (this.item.default_value === null) {
                this.removeValidator(itemSchema, 'required')
            }
        }

        const schema = {
            type: 'root',
            children: [
                itemSchema,
                {
                    type: 'field',
                    key: 'defaultValue',
                    label: 'common.term.default-value--use-default-value',
                    inputType: 'checkbox',
                },
                {
                    type: 'field',
                    key: 'required',
                    label: 'common.term.mandatory-field--this-is-a',
                    inputType: 'checkbox',
                },
            ],
        }

        const data = {
            schema: schema,
            formConfiguration: setupFormLocale(schema),
            formData: setupFormData(schema),
            expanded: !!this.item.newlyInserted,
        }
        if (this.item.default_value !== undefined && this.item.default_value !== null) {
            data.formData.defaultValue = true
            data.formData[this.item.key] = this.item.default_value
        }
        data.formData.required = this.item.required
        return data
    },
    validations () {
        return {
            formData: setupFormValidations(this.formConfiguration),
        }
    },
    watch: {
        'formData.defaultValue' (defaultValue) {
            const itemSchema = getFormItemByKey(this.formConfiguration, this.item.key)
            if (defaultValue) {
                itemSchema.validators.push({ type: 'required' }) // Add required validator
            } else {
                this.removeValidator(itemSchema, 'required') // Remove required validator
                this.formData[this.item.key] = setupFormData(this.formConfiguration, [this.item.key])[this.item.key] // Reset initial value
            }
        },
        'formData.required' (required) {
            this.item.required = required
        },
    },
    created () {
        this.$watch(`formData.${this.item.key}`, (itemValue) => {
            if (this.formData.defaultValue) {
                this.item.default_value = itemValue
            } else {
                this.item.default_value = null
            }
        })
        this.$watch('$i18n.locale', () => {
            this.formConfiguration = setupFormLocale(this.schema)
        })
    },
    methods: {
        removeValidator (itemSchema, validatorType) {
            const indexOfValidator = itemSchema.validators.findIndex(validator => validator.type === validatorType)
            if (indexOfValidator >= 0) itemSchema.validators.splice(indexOfValidator, 1)
        },
        validate () {
            this.$v.$touch()
            const invalid = this.$v.$invalid
            if (invalid) this.expanded = true
            return !invalid
        },
    },
}
</script>
