<template>
    <form data-test="form:auth-set-2fa-code" @submit.prevent="onFormSubmit">
        <i18n path="views.auth.setup.2fa-text" tag="p">
            <template v-slot:recommended-app>
                <a href="https://authy.com/download/" target="_blank" rel="noopener">Authy</a>
            </template>
        </i18n>

        <!-- eslint-disable vue/no-v-html -->
        <info-box
            v-if="formErrorMessage"
            type="negative"
            data-test="error:global-message"
            v-html="formErrorMessage"
        />
        <!-- eslint-enable -->

        <div class="row q-col-gutter-y-sm auth-2fa-qr-code-wrapper">
            <div class="col-xs-12 text-center">
                <img
                    :src="qrCode"
                    :alt="$tc('common.auth.2fa-qr-code', 1)"
                    class="auth-2fa-qr-code"
                    data-test="img:qr-code"
                >
            </div>
            <div class="col-xs-12">
                <form-builder item-key="twoFACode" autofocus />
            </div>
            <q-stepper-navigation class="col-xs-12" :class="{ 'text-right': $q.screen.gt.md }">
                <base-button
                    data-test="btn:submit"
                    type="submit"
                    :label="$t('common.term.continue')"
                    :disable="formSubmitStatus"
                    :loading="formSubmitStatus"
                />
            </q-stepper-navigation>
        </div>
    </form>
</template>

<script>
import { formBuilderMixin } from '@/mixins/formBuilderMixin'

import AUTH_CREDENTIALS_FIELDS from '@/forms/auth.fields.json'

export default {
    name: 'AuthSet2faCode',
    mixins: [formBuilderMixin],
    props: {
        qrCode: {
            type: String,
            required: true,
        },
    },
    formBuilderSettings: {
        schema: AUTH_CREDENTIALS_FIELDS,
        fields: ['twoFACode'],
    },
}
</script>

<style lang="scss" scoped>
.auth-2fa-qr-code-wrapper {
    margin: 0 !important;
}

.auth-2fa-qr-code {
    width: 16rem;
    max-width: 100%;

    //@include mq($to: $sizeBreakpointLg) {
    /*max-width: 40vw;*/
    /*}*/

}
</style>
