import gql from 'graphql-tag'

export const ECO_HUB_TECH_USER_QUERY = gql`
    query ecoHubTechUser {
        ecoHubTechUser {
            idp_user_id
            created_at
            updated_at
        }
    }
`
