// import indexPageEn from '#/user-manual/getting-started/index.en.md'
import indexPageDeCH from '#/user-manual/getting-started/index.de-CH.md'

export default {
    key: 'getting-started',
    label: { 'en': 'Getting started', 'de-CH': 'Einleitung' },
    navIcon: 'mi-newspaper-read-man',
    pages: [
        { 'key': 'index', 'en': indexPageDeCH, 'de-CH': indexPageDeCH }, // TODO: Re-add english version
    ],
}
