<script>
import draggable from 'vuedraggable'
import { walkTree } from '@/helpers'
import ProductTemplateBuilderOverlay from '@/components/product/ProductTemplateBuilderOverlay.vue'
import ProductTemplateSection from '@/components/product/ProductTemplateSection.vue'
import ProductTemplateField from '@/components/product/ProductTemplateField.vue'
import ProductTemplateBuilderItemAdd from '@/components/product/ProductTemplateBuilderItemAdd.vue'
import ProductTemplateBuilderItemRemove from '@/components/product/ProductTemplateBuilderItemRemove'

import { ProductService } from '@/services'

export default {
    name: 'ProductTemplateBuilder',
    inheritAttrs: false,
    props: {
        configuration: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            overlayIsShown: false,
            currentItemList: null,
            productFieldGroups: [],
        }
    },
    created () {
        ProductService.getProductFields().then(productFieldGroups => {
            this.productFieldGroups = productFieldGroups.map(group => {
                group.selectable = false
                return group
            })
            this.syncSelectableProductFields()
        })
    },
    methods: {
        showOverlay (items) {
            this.currentItemList = items
            this.overlayIsShown = true
        },
        hideOverlay () {
            this.currentItemList = null
            this.overlayIsShown = false
        },
        addStructureItem (structureItem) {
            if (this.currentItemList) this.currentItemList.push(structureItem)
        },
        addProductFieldItem (productField) {
            if (this.currentItemList) this.currentItemList.push(productField)
            this.syncSelectableProductFields()
        },
        removeItem (item) {
            let key
            switch (item.type) {
                case 'section':
                    key = item.key
                    break
                case 'field':
                    key = item.key
                    break
            }

            const removeItemfromConfiguration = (items) => {
                const index = items.findIndex(item => {
                    let keyProp
                    switch (item.type) {
                        case 'section':
                            keyProp = 'key'
                            break
                        case 'field':
                            keyProp = 'key'
                            break
                    }
                    return item[keyProp] === key
                })
                if (index >= 0) {
                    items.splice(index, 1)
                } else {
                    items.forEach(item => {
                        if (item.children && item.children.length) {
                            removeItemfromConfiguration(item.children)
                        }
                    })
                }
            }

            removeItemfromConfiguration(this.configuration.children)
            this.syncSelectableProductFields()
        },
        syncSelectableProductFields () {
            const selectedProductFields = []
            walkTree(this.configuration, (item) => {
                if (item.type === 'field') selectedProductFields.push(item.key)
            })

            this.productFieldGroups.forEach(group => {
                group.children.forEach(productField => {
                    productField.disabled = selectedProductFields.includes(productField.key)
                    productField.selectable = !productField.disabled
                })
            })
        },
        validate () {
            let isValid = true
            walkTree(this.configuration, (item) => {
                if (['section', 'field'].includes(item.type)) {
                    const itemIsValid = this.$refs[item.key].validate()
                    if (!itemIsValid) isValid = false
                }
            })
            return isValid
        },
    },
    render (h) {
        const build = (item) => {
            const output = []
            const renderChildren = []

            if (item.children) {
                const draggableItems = []

                item.children.forEach(child => {
                    draggableItems.push(build(child))
                })

                renderChildren.push(h(draggable, {
                    class: 'product-template-builder--item',
                    attrs: {
                        list: item.children,
                        group: 'product-template-builder',
                        handle: '.draggable-handle',
                        emptyInsertThreshold: 10,
                        swapThreshold: 0.5,
                        animation: 100,
                        easing: 'cubic-bezier(0.25, 0.8, 0.5, 1)',
                        delay: 200, // time in milliseconds to define when the sorting should start
                        delayOnTouchOnly: true, // only delay if user is using touch
                    },
                }, draggableItems))

                renderChildren.push(h(ProductTemplateBuilderItemAdd, {
                    props: { items: item.children },
                    on: { showOverlay: this.showOverlay },
                }))
            }

            switch (item.type) {
                case 'root':
                    output.push(renderChildren)
                    break
                case 'section':
                    output.push(h(ProductTemplateSection, {
                        key: item.key,
                        ref: item.key,
                        props: { item: item },
                    }, [
                        h(ProductTemplateBuilderItemRemove, {
                            props: { item: item },
                            on: { removeItem: this.removeItem },
                            slot: 'header',
                        }),
                        ...renderChildren,
                    ]))
                    break
                case 'field': {
                    const options = {
                        key: item.key,
                        ref: item.key,
                        props: { item: item },
                    }
                    output.push(h(ProductTemplateField, options, [
                        h(ProductTemplateBuilderItemRemove, {
                            props: { item: item },
                            on: { removeItem: this.removeItem },
                            slot: 'header',
                        }),
                    ]))
                    break
                }
            }

            return output
        }

        const output = []
        if (this.overlayIsShown) {
            output.push(h(ProductTemplateBuilderOverlay, {
                props: { productFieldGroups: this.productFieldGroups },
                on: {
                    addStructureItem: this.addStructureItem,
                    addProductFieldItem: this.addProductFieldItem,
                    hide: this.hideOverlay,
                },
            }))
        }
        output.push(build(this.configuration))
        return h('div', output)
    },
}
</script>

<style lang="scss" scoped>
$sizeBorderWidth: 2px;
$sizeBorderWidthThick: 8px;
$sizeDragIconMarginLeft: -6px;
$sizeQItemDensePaddingSides: 16px;
$sizeInnerPadding: 1rem;

// General builder item styling
::v-deep .product-template-builder--item {
    margin: 0 0 1rem;

    .draggable-handle {
        cursor: move;
    }

    .item-header {
        // Used for highlighting when item should be removed
        transition: background-color $defaultTransitionStyles;

        .drag-icon-wrapper {
            min-width: auto;
            margin: 2px 0 0 $sizeDragIconMarginLeft;
            padding: 0 8px 0 0;
        }

        .q-item__section--main {
            flex-direction: row;
            justify-content: flex-start;
            align-content: center;
        }

        .q-item__label {
            font-weight: bold;
            align-self: baseline;

            &--caption {
                margin: 0 0 0 0.5em;

                font-size: .75em;
                font-weight: normal;
                color: var(--color-heading-tertiary);
                hyphens: auto;
            }
        }
    }

    .item-header--actions {
        flex-direction: row;
        align-self: center;
    }

    .inner-wrapper {
        width: 100%;
        max-width: 100%;
        padding: $sizeInnerPadding;
    }

    // Preview of the item when dragged to another position
    .sortable-ghost {
        border-style: dashed;
    }

    // Visually highlight when item(s) are about to be removed
    .remove {
        border-color: var(--q-color-negative-light);
        transition: all 1s $defaultTransitionTimingFunction;

        .product-template-builder--section,
        .product-template-builder--field {
            border-color: var(--q-color-negative-light);
            transition: all 1s $defaultTransitionTimingFunction;
        }

        .product-template-builder--field {
            border-style: dashed;
        }

        & .q-expansion-item__container > .item-header,
        & .item-header {
            background-color: var(--q-color-negative-light);
            transition: all 1s $defaultTransitionTimingFunction;
        }
    }
}

// Builder section
::v-deep .product-template-builder--section {
    margin: 0 0 1rem;

    border: $sizeBorderWidth solid var(--q-color-accent-light);
    border-left: $sizeBorderWidthThick solid var(--q-color-accent-light);
    background-color: var(--color-white);

    // Used for highlighting when item should be removed
    transition: border-color $defaultTransitionStyles;

    // Preview of the item when dragged to another position
    &.sortable-ghost {
        background-color: var(--q-color-accent-lighter);

        border-left-width: $sizeBorderWidth;

        & > .item-header {
            padding-left: calc(#{$sizeQItemDensePaddingSides} + (#{$sizeBorderWidthThick} - #{$sizeBorderWidth}));
        }

        .inner-wrapper {
            padding-left: calc(#{$sizeInnerPadding} + (#{$sizeBorderWidthThick} - #{$sizeBorderWidth}));
        }
    }

    & > .item-header {
        background-color: var(--q-color-accent-light);

        .missing-label {
            font-style: italic;
        }

        .drag-icon-wrapper {
            margin-left: calc(#{$sizeDragIconMarginLeft} - (#{$sizeBorderWidthThick} - #{$sizeBorderWidth}));
        }
    }

    .section-details {
        transition: padding-top $defaultTransitionDuration linear;

        &.q-expansion-item--collapsed {
            padding-top: 0;
        }

        &-header {
            display: none;
        }
    }
}

// Builder field
::v-deep .product-template-builder--field {
    margin: 0 0 1rem;

    border: $sizeBorderWidth solid var(--q-color-primary-light);
    background-color: var(--color-white);

    // Used for highlighting when item should be removed
    transition: border-color $defaultTransitionStyles;

    // Preview of the item when dragged to another position
    &.sortable-ghost {
        background-color: var(--q-color-primary-lighter);
    }

    .item-header {
        background-color: var(--q-color-primary-light);
    }

    .item-header--actions {

        // Hide default expansion toggle section
        & + .q-item__section--side {
            display: none;
        }
    }
}
</style>
