import gql from 'graphql-tag'

export const POSTING_TYPES_QUERY = gql`
    query postingTypes(
        $page: Int = 1
        $count: Int = 1000
        $orderField: PostingTypeOrderField = NAME
        $orderDirection: OrderDirection = ASC
        $filterQuery: String
        $filterHasKey: Boolean
    ) {
        postingTypes(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                query: $filterQuery
                has_key: $filterHasKey
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                key
                name
                name_language_versions
                created_at
                updated_at
            }
        }
    }
`
