<template>
    <fake-select
        v-if="!contacts.length"
        v-bind="$attrs"
    />
    <base-select-filter
        v-else

        :value="value"
        v-bind="$attrs"
        :options="contacts"

        option-value="id"
        :option-label="getOptionLabel"
        @input="inputValue => $emit('input', inputValue)"
    >
        <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
    </base-select-filter>
</template>

<script>
import { ContactType, ContactOrderField, OrderDirection } from '@/enums/graphql'
import BaseSelectFilter from '@/components/form/BaseSelectFilter'
import { Contact } from '@/models/contact'
import FakeSelect from '@/components/FakeSelect.vue'

export default {
    name: 'CompanySelect',
    components: {
        FakeSelect,
        BaseSelectFilter,
    },
    inheritAttrs: false,
    props: {
        value: {
            required: true,
        },
        filter: {
            type: Object,
        },
    },
    data () {
        return {
            contacts: [],
        }
    },
    created () {
        const variables = {
            contact_type: ContactType.COMPANY,
            orderField: ContactOrderField.CONTACT_NAME,
            orderDirection: OrderDirection.ASC,
        }
        if (this.filter) Object.assign(variables, this.filter)

        Contact.objects.all(variables, `
            id
            contactNumber {
                number
            }
            ... on Company {
                company_name
            }
        `).then(response => {
            this.contacts = response.data
        })
    },
    methods: {
        getOptionLabel (item) {
            return `${item.company_name} <span class="additional-info">(${item.contactNumber.number})</span>`
        },
    },
}
</script>
