import gql from 'graphql-tag'

export const USER_CHANGE_STATUS_MUTATION = gql`
    mutation userChangeStatus(
        $id: ID!
        $status: UserStatus!
    ) {
        userChangeStatus(
            id: $id
            status: $status
        ) {
            id
            status
        }
    }
`
