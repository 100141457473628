import gql from 'graphql-tag'

export const ROLES_QUERY = gql`
    query roles(
        $page: Int = 1
        $count: Int = 300
        $orderField: RoleOrderField = ROLE_NAME
        $orderDirection: OrderDirection = ASC
        $filterQuery: String
    ) {
        roles(
            page: $page
            count: $count
            order: [{ field: $orderField, direction: $orderDirection }]
            search: { role_name: $filterQuery }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                name
                description
            }
        }
    }
`
