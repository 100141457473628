import { kebabCase } from 'lodash'
import { AccountAssignedAsType, AccountAssignedToType } from '@/enums/graphql'

const assignedAsTypeOptions = Object.values(AccountAssignedAsType)
    .filter(value => (value === AccountAssignedAsType.CONSULTING_SETTINGS_CURRENT_ACCOUNT || value === AccountAssignedAsType.CONSULTING_SETTINGS_CANCELLATION_RESERVE_ACCOUNT))
    .map(value => ({ value: value, label: `common.accounting.account.type.${kebabCase(value.replace('CONSULTING_SETTINGS_', '').toLowerCase())}` }))

const assignedToTypeOptions = Object.values(AccountAssignedToType)
    .filter(value => (value === AccountAssignedToType.PERSON || value === AccountAssignedToType.COMPANY))
    .map(value => ({ value: value, label: `common.contact.consulting-${kebabCase(value.toLowerCase())}` }))

export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'balancePerDate',
            label: 'common.accounting.account.balance-per',
            inputType: 'calendar',
            validators: [
                {
                    type: 'date',
                },
                {
                    type: 'required',
                },
            ],
        },
        {
            type: 'field',
            key: 'accountAssignedAsType',
            label: 'common.accounting.account.assigned-as',
            inputType: 'select',
            extra_attributes: {
                list: {
                    options: assignedAsTypeOptions,
                },
                multiple: true,
            },
            validators: [
                {
                    type: 'required',
                },
            ],
        },
        {
            type: 'field',
            key: 'accountAssignedToType',
            label: 'common.accounting.account.assigned-to',
            inputType: 'select',
            extra_attributes: {
                list: {
                    options: assignedToTypeOptions,
                },
                multiple: true,
            },
            validators: [
                {
                    type: 'required',
                },
            ],
        },
        {
            type: 'field',
            key: 'assignedToConsultingSettingsStatus',
            label: 'views.accounting.transfer-posting.include-inactive-consulting-entities',
            inputType: 'checkbox',
        },
    ],
}
