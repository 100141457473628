<template>
    <form
        data-test="form:posting-configuration"
        @submit.prevent="onFormSubmit"
    >
        <div class="row q-col-gutter-x-md q-mb-sm">
            <div class="col-xs-12 col-sm-6">
                <q-radio
                    v-model="postingDateType"
                    :label="$tc('common.accounting.posting.date--same-date', 1)"
                    :val="PostingDateType.UNIVERSAL"
                />
            </div>
            <div class="col-xs-12 col-sm-6">
                <form-builder
                    item-key="postingDate"
                    :disable="postingDateType === PostingDateType.INDIVIDUAL"
                />
            </div>
            <div class="col-xs-12">
                <q-radio
                    v-model="postingDateType"
                    :label="$tc('common.accounting.posting.date--individual-date', 1)"
                    :val="PostingDateType.INDIVIDUAL"
                />
            </div>
        </div>

        <div class="row q-col-gutter-md">
            <div class="col-xs-12 col-sm-6 col-md-12 col-lg-6">
                <form-builder
                    item-key="postingType"
                    :filter-fn="filterPostingType"
                />
            </div>
            <div class="col-xs-12 col-sm-6 col-md-12 col-lg-6">
                <form-builder
                    item-key="postingTypeRounding"
                />
            </div>
        </div>
    </form>
</template>

<script>
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import POSTING_CONFIGURATION_FORM from '@/forms/postingConfiguration.form'
import { PostingTypeRounding, PostingDateType } from '@/enums'
import { lightFormat } from 'date-fns'

export default {
    name: 'PostingConfigurationForm',
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: POSTING_CONFIGURATION_FORM,
    },
    data () {
        return {
            PostingDateType,
            postingDateType: PostingDateType.UNIVERSAL,
            formData: {
                postingDate: lightFormat(new Date(), 'yyyy-MM-dd'),
                postingTypeRounding: PostingTypeRounding['0.05'],
            },
            postingTypes: [],
        }
    },
    computed: {
        postingTypeName () {
            return this.formData.postingType ? this.postingTypes.find(entry => entry.id === this.formData.postingType).name : ''
        },
    },
    methods: {
        filterPostingType (postingTypes) {
            this.postingTypes = postingTypes
            const filteredPostingTypes = postingTypes.filter(postingType => postingType.key === 'accountTransfer' || postingType.key === 'payout')
            const accountTransferPostingType = filteredPostingTypes.find(postingType => postingType.key === 'accountTransfer')
            if (accountTransferPostingType) this.formData.postingType = accountTransferPostingType.id
            return filteredPostingTypes
        },
    },
}
</script>
