$<template>
    <div>
        <page-header no-breadcrumbs>
            <h2>
                {{ $tc('views.admin.consulting-settings.update-title', 2) }}
                <span class="subtitle">{{ $t('common.term.of') }} {{ contact.getContactName() }}</span>
                <status-badge v-if="contact.consultingSettings && contact.consultingSettings.status" :status="contact.consultingSettings.status" />
                <status-badge v-if="contact.is_tenant_company" status="system-owner" />
            </h2>

            <template v-slot:actions>
                <actions
                    v-if="contact.consultingSettings && contact.consultingSettings.actions"
                    :actions="contact.consultingSettings.actions"
                    @click="handleActions"
                />

                <!-- Activate ConsultingSettings -->
                <form-dialog
                    ref="activateDialog"
                    dont-close-on-confirm
                    @confirm="handleActivate"
                >
                    <template v-slot:title>{{ $tc('common.actions.consulting-settings.activate', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.consulting-settings.activate-consulting-settings--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:contact><b>{{ contact.getContactName() }}</b></template>
                            <template v-if="contact.type === ContactType.COMPANY" v-slot:type>{{ $tc('common.contact.consulting-company', 1) }}</template>
                            <template v-else v-slot:type>
                                <template v-if="contact.gender && !contact.personal_pronoun">{{ $tc('common.contact.consultant--' + contact.getGender(), 1) }}</template>
                                <template v-else>{{ $tc('common.contact.consultant', 1) }}</template>
                            </template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Deactivate ConsultingSettings -->
                <form-dialog
                    ref="deactivateDialog"
                    dont-close-on-confirm
                    @confirm="handleDeactivate"
                >
                    <template v-slot:title>{{ $tc('common.actions.consulting-settings.deactivate', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.consulting-settings.deactivate-consulting-settings--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:contact><b>{{ contact.getContactName() }}</b></template>
                            <template v-if="contact.type === ContactType.COMPANY" v-slot:type>{{ $tc('common.contact.consulting-company', 1) }}</template>
                            <template v-else v-slot:type>
                                <template v-if="contact.gender && !contact.personal_pronoun">{{ $tc('common.contact.consultant--' + contact.getGender(), 1) }}</template>
                                <template v-else>{{ $tc('common.contact.consultant', 1) }}</template>
                            </template>
                        </i18n>
                    </template>
                </form-dialog>

                <!-- Delete ConsultingSettings -->
                <!-- TODO @TFU: Enhance v-if with defined conditions which must be met to be able to delete consulting settings -->
                <!-- TODO improvement @MTR: Enhance text with info about consequences -->
                <form-dialog
                    ref="deleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleDelete"
                >
                    <template v-slot:title>{{ $tc('common.actions.consulting-settings.delete', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.admin.consulting-settings.delete-consulting-settings--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:contact><b>{{ contact.getContactName() }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <div class="row q-col-gutter-md">
            <div class="col-xs-12">
                <consulting-settings-form
                    ref="consultingSettingsForm"
                    :consulting-settings="contact.consultingSettings"
                    :contact="contact"
                    @post-handle-form-data="onSubmit"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { ContactType, ConsultingSettingsStatus } from '@/enums/graphql'
import { validateConsultingSettingsFormData, parseFormDataToConsultingSettingsInput } from '@/helpers/contact'
import ConsultingSettingsForm from '@/components/consulting-settings/ConsultingSettingsForm'
import StatusBadge from '@/components/StatusBadge.vue'
import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'

import { ConsultingSettings } from '@/models/consultingSettings'
import { i18n } from '@/i18n'

export default {
    name: 'ContactConsultingSettingsUpdate',
    components: {
        StatusBadge,
        ConsultingSettingsForm,
        Actions,
        FormDialog,
    },
    props: {
        contact: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            ContactType,
            ConsultingSettingsStatus,
            confirmDeletion: false,
        }
    },
    computed: {
        companyFieldLabel () {
            if (this.contact.type === ContactType.PERSON) {
                return i18n.tc('common.term.employer', 1)
            } else {
                return i18n.t('views.admin.consulting-settings.superordinate-consulting-company')
            }
        },
    },
    methods: {
        onSubmit (formData) {
            const errorMessages = validateConsultingSettingsFormData(formData)

            this.$refs.consultingSettingsForm.resetFormSubmitStatus()

            if (errorMessages.graphQLErrors.length) {
                this.$refs.consultingSettingsForm.showFormErrorMessage(errorMessages)
                return
            } else {
                this.$refs.consultingSettingsForm.resetFormErrorMessage()
            }

            const variables = parseFormDataToConsultingSettingsInput(formData, this.$store.state.user)
            if (typeof formData.signature !== 'undefined') variables.signature = formData.signature

            this.contact.consultingSettings.update(variables)
                .then(() => {
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.consulting-settings.consulting-settings-saved-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.consultingSettingsForm.showFormErrorMessage(error)
                    this.$refs.consultingSettingsForm.resetFormSubmitStatus()
                })
        },
        handleActions (action) {
            switch (action.key) {
                case ConsultingSettings.action.ACTIVATE:
                    this.$refs.activateDialog.open()
                    break
                case ConsultingSettings.action.DEACTIVATE:
                    this.$refs.deactivateDialog.open()
                    break
                case ConsultingSettings.action.DELETE:
                    this.$refs.deleteDialog.open()
                    break
            }
        },
        handleActivate () {
            this.contact.consultingSettings.activate()
                .then(() => {
                    this.$refs.activateDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.consulting-settings.consulting-settings-activated-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.activateDialog.showFormErrorMessage(error)
                    this.$refs.activateDialog.resetFormSubmitStatus()
                })
        },
        handleDeactivate () {
            this.contact.consultingSettings.deactivate()
                .then(() => {
                    this.$refs.deactivateDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.consulting-settings.consulting-settings-deactivated-success', 1),
                    })
                })
                .catch(error => {
                    this.$refs.deactivateDialog.showFormErrorMessage(error)
                    this.$refs.deactivateDialog.resetFormSubmitStatus()
                })
        },
        handleDelete () {
            this.contact.consultingSettings.delete()
                .then(() => {
                    this.contact.consultingSettings = null
                    this.$refs.deleteDialog.close()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.consulting-settings.consulting-settings-deleted-success', 1),
                    })
                    this.$router.replace({ name: 'contact-settings-consulting-settings-create' })
                })
                .catch(error => {
                    this.$refs.deleteDialog.showFormErrorMessage(error)
                    this.$refs.deleteDialog.resetFormSubmitStatus()
                })
        },
    },
}
</script>
