export default {
    type: 'root',
    children: [
        {
            type: 'field',
            key: 'name',
            label: 'forms.field.label.commission-distribution-rule-set.name',
            inputType: 'text',
            validators: [
                { type: 'required' },
            ],
        },
        {
            type: 'field',
            key: 'description',
            label: 'forms.field.label.commission-distribution-rule-set.description',
            inputType: 'text',
            validators: [
                { type: 'dirtyChecker' },
            ],
        },
    ],
}
