<template>
    <page-wrapper>
        <page-header>
            <h1>{{ $tc('common.term.user-account', 1) }}</h1>
        </page-header>

        <div class="row q-col-gutter-md">
            <grid-card :columns="{ xs: 12, sm: 12, lg: 6, xl: 6 }">
                <div class="row">
                    <div class="col-xs-12">
                        <illustration name="max-person-approve" />
                    </div>

                    <div class="col-xs-12">
                        <h2>{{ $store.state.user.getUserName() || $store.state.user.email }}</h2>

                        <dl>
                            <dt>{{ $tc('common.contact.email-address', 1) }}: </dt>
                            <dd>{{ $store.state.user.email }}</dd>
                            <br>

                            <template v-if="$store.state.user.consultant">
                                <dt>{{ $tc('views.admin.users.detail.linked-contact', 1) }}: </dt>
                                <dd>{{ `${$store.state.user.consultant.first_name} ${$store.state.user.consultant.last_name}` }} <span class="additional-info">({{ $store.state.user.consultant.contact_number }})</span></dd>
                                <br>
                                <dt>{{ $tc('common.contact.consulting-company', 1) }}: </dt>
                                <dd>{{ $store.state.user.consultant.consultingCompany?.company_name }}</dd>
                                <br>
                            </template>
                            <template v-else>
                                <dt>{{ $tc('views.admin.users.detail.linked-contact', 1) }}: </dt>
                                <dd>{{ $tc('views.admin.users.detail.linked-contact', 0) }}</dd>
                                <br>
                            </template>

                            <dt>{{ $tc('views.admin.users.role', $store.state.user.aclRoles.length || 2) }}: </dt>
                            <dd>
                                <template v-if="$store.state.user.aclRoles">
                                    <q-badge v-for="role in $store.state.user.aclRoles" :key="role" class="q-mr-xs">{{ $t(`common.user.role.${role}`) }}</q-badge>
                                </template>
                                <span v-else class="additional-info">–</span>
                            </dd>
                        </dl>
                    </div>
                </div>

            </grid-card>
        </div>
    </page-wrapper>
</template>

<script>
import { stringToColor } from '@/helpers'
import { getContactInitials } from '@/helpers/contact'

export default {
    name: 'AccountDashboard',
    meta () {
        return {
            title: this.$tc('common.term.user-account', 1),
        }
    },
    methods: {
        getContactInitials (...args) {
            return getContactInitials(...args)
        },
        stringToColor (...args) {
            return stringToColor(...args)
        },
    },
}
</script>
