import gql from 'graphql-tag'

export const COMMISSION_LIST_IMPORT_ERROR_QUERY = gql`
    query commissionListImportError(
        $id: ID!
    ) {
        commissionListImportError(
            id: $id
        ) {
            id
            commissionList {
                id
            }
            scope
            messages {
                subject
                code
                type
                params
            }
            source_entry_number
            source_data
            parsed_data
            created_at
            updated_at
        }
    }
`
