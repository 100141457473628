import gql from 'graphql-tag'

export const AREA_OF_RESPONSIBILITY_DELETE_MUTATION = gql`
    mutation areaOfResponsibilityDelete(
        $id: ID!
    ) {
        areaOfResponsibilityDelete(
            id: $id
        ) {
            status
        }
    }
`
