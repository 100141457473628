<template>
    <loading-indicator v-if="!documentLayout" auto-height />
    <div v-else>
        <page-header no-breadcrumbs>
            <h2>{{ $tc('views.contact.settings.document-layout.update-document-layout--with-name', 1, { documentLayoutName: documentLayout.name }) }}</h2>

            <template v-slot:actions>
                <actions
                    v-if="documentLayout.actions"
                    :actions="documentLayout.actions"
                    :number-of-next-best-actions="(documentLayout.actions.length === 1) ? 0 : 1"
                    @click="handleActions"
                />

                <!-- TODO improvement @TFU: It should also be possible to clear the defaultDocumentLayout without setting a new one. -->
                <!-- Set default DocumentLayout -->
                <form-dialog
                    ref="documentLayoutSetDefaultDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleDocumentLayoutSetDefault"
                >
                    <template v-slot:title>{{ $tc('common.actions.document-layout.set-default', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.contact.settings.document-layout.set-default-document-layout--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:documentLayoutName><b>{{ documentLayout.name }}</b></template>
                        </i18n>

                        <info-box type="warning">{{ $tc('views.contact.settings.document-layout.set-default-document-layout--description', 1) }}</info-box>
                    </template>
                </form-dialog>

                <!-- Delete DocumentLayout -->
                <form-dialog
                    ref="documentLayoutDeleteDialog"
                    double-confirm
                    dont-close-on-confirm
                    @confirm="handleDocumentLayoutDelete"
                >
                    <template v-slot:title>{{ $tc('common.actions.document-layout.delete', 1) }}</template>
                    <template v-slot:default>
                        <i18n path="views.contact.settings.document-layout.delete-document-layout--confirm--temp-component-interpolation-count" tag="p">
                            <template v-slot:documentLayoutName><b>{{ documentLayout.name }}</b></template>
                        </i18n>
                    </template>
                </form-dialog>
            </template>
        </page-header>

        <document-layout-form
            ref="documentLayoutForm"
            :owner-id="ownerId"
            :document-layout="documentLayout"
            @post-handle-form-data="onSubmit"
        />
    </div>
</template>

<script>
import DocumentLayoutForm from '@/components/documentLayout/DocumentLayoutForm'
import { DocumentLayout } from '@/models/documentLayout'
import Actions from '@/components/Actions'
import FormDialog from '@/components/form/FormDialog'

export default {
    name: 'DocumentLayoutUpdate',
    components: {
        DocumentLayoutForm,
        Actions,
        FormDialog,
    },
    props: {
        ownerId: {
            type: String,
            required: true,
        },
        documentLayoutId: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            documentLayout: null,
        }
    },
    created () {
        DocumentLayout.objects.get(this.documentLayoutId).then(documentLayout => {
            this.documentLayout = documentLayout
        })
    },
    methods: {
        onSubmit ({ name, pageBackground, marginTop, marginRight, marginBottom, marginLeft }) {
            const variables = {
                name: JSON.stringify(name),
                pageMargins: JSON.stringify({
                    'margin-top': `${marginTop}mm`,
                    'margin-right': `${marginRight}mm`,
                    'margin-bottom': `${marginBottom}mm`,
                    'margin-left': `${marginLeft}mm`,
                }),
            }
            if (typeof pageBackground !== 'undefined') variables.pageBackground = pageBackground

            this.documentLayout.update(variables)
                .then(() => {
                    this.$router.replace({ name: 'contact-settings-document-layout-list', params: { id: this.ownerId } })
                })
                .catch(error => {
                    this.$refs.documentLayoutForm.showFormErrorMessage(error)
                    this.$refs.documentLayoutForm.resetFormSubmitStatus()
                })
        },
        handleActions (action) {
            switch (action.key) {
                case DocumentLayout.action.SET_DEFAULT:
                    this.$refs.documentLayoutSetDefaultDialog.open()
                    break
                case DocumentLayout.action.DELETE:
                    this.$refs.documentLayoutDeleteDialog.open()
                    break
            }
        },
        handleDocumentLayoutSetDefault () {
            this.documentLayout.setDefault()
                .then(() => {
                    this.$refs.documentLayoutSetDefaultDialog.close()
                    this.$router.replace({ name: 'contact-settings-document-layout-list', params: { id: this.ownerId } })
                })
                .catch(error => {
                    this.$refs.documentLayoutSetDefaultDialog.showFormErrorMessage(error)
                    this.$refs.documentLayoutSetDefaultDialog.resetFormSubmitStatus()
                })
        },
        handleDocumentLayoutDelete () {
            this.documentLayout.delete()
                .then(() => {
                    this.$refs.documentLayoutDeleteDialog.close()
                    this.$router.replace({ name: 'contact-settings-document-layout-list', params: { id: this.ownerId } })
                })
                .catch(error => {
                    this.$refs.documentLayoutDeleteDialog.showFormErrorMessage(error)
                    this.$refs.documentLayoutDeleteDialog.resetFormSubmitStatus()
                })
        },
    },
}
</script>
