import gql from 'graphql-tag'

export const CORRESPONDENCE_LETTER_START_PROCESSING_MUTATION = gql`
    mutation correspondenceLetterStartProcessing(
        $id: ID!
    ) {
        correspondenceLetterStartProcessing(
            id: $id
        ) {
            id
        }
    }
`
