<template>
    <form
        data-test="form:document-template"
        class="row q-col-gutter-sm q-mb-md"
        @submit.prevent="onFormSubmit"
        @keyup.esc="cancel"
    >
        <div class="col-xs-12">
            <!-- eslint-disable vue/no-v-html -->
            <info-box
                v-if="formErrorMessage"
                type="negative"
                data-test="error:global-message"
                class="q-mb-xs"
                no-margin
                v-html="formErrorMessage"
            />
            <!-- eslint-enable -->

            <!-- TODO improvement: Adjust text depending on whether the user is not the owner of a superordinate company’s template or if their company is the owner but the user does not have the company permission. -->
            <info-box v-if="currentMode === ViewMode.UPDATE && $can.not('update', documentTemplate)" type="warning">
                {{ $tc('views.correspondence.document-template-update.read-only', 1) }} <span class="additional-info">{{ $tc('views.correspondence.document-template-update.read-only--description', 1) }}</span>
            </info-box>
            <!-- TODO improvement @TFU: Check if the consulting company has subordinate companies – and if that’s the case: adjust translation ID to `common.correspondence.document-template.template-owner-info--consulting-company--with-subordinate-companies` -->
            <info-box type="info" class="q-mb-xs" no-margin>{{ $tc(`common.correspondence.document-template.template-owner-info--${documentTemplateOwnerType.toLowerCase()}`, 1) }}</info-box>
        </div>

        <div class="col-xs-12 col-md-8 col-lg-4 col-xl-3">
            <form-builder item-key="name" :disable="currentMode === ViewMode.UPDATE && $can.not('update', documentTemplate)" />
        </div>
        <div class="col-xs-12 col-md-4 col-lg-2 col-xl-2">
            <form-builder item-key="languageId" :disable="currentMode === ViewMode.UPDATE && $can.not('update', documentTemplate)" />
        </div>
        <div class="col-xs-12 col-lg-6 col-xl-4">
            <form-builder item-key="description" :disable="currentMode === ViewMode.UPDATE && $can.not('update', documentTemplate)" />
        </div>
        <div class="col-xs-12 col-md-6 col-lg-4 col-xl-3">
            <form-builder item-key="supportedTargetObjectTypes" :disable="currentMode === ViewMode.UPDATE && $can.not('update', documentTemplate)" />
        </div>
        <div v-if="documentType === DocumentType.LETTER" class="col-xs-12 col-md-6 col-lg-2 col-xl-3">
            <form-builder item-key="confidentialityNotice" />
        </div>
        <!-- TODO improvement @TFU: Check if the consulting company even has subordinate companies first. (Don’t show this checkbox otherwise.) -->
        <div v-if="documentTemplateOwnerType === DocumentTemplateOwnerType.CONSULTING_COMPANY" class="col-xs-12 col-md-6 col-lg-6 col-xl-5">
            <form-builder item-key="availableForSubordinateCompanies" :disable="currentMode === ViewMode.UPDATE && $can.not('update', documentTemplate)" />
        </div>

        <div v-if="formData.supportedTargetObjectTypes?.length > 1" class="col-xs-12">
            <info-box type="warning">{{ $t('common.correspondence.document-template.smart-object-info--multiple-target-object-types-selected') }}</info-box>
        </div>
    </form>
</template>

<script>
import { ViewMode, DocumentTemplateOwnerType } from '@/enums'
import { CorrespondenceItemTargetObjectType, DocumentType } from '@/enums/graphql'
import { formBuilderMixin } from '@/mixins/formBuilderMixin'
import DOCUMENT_TEMPLATE_FIELDS from '@/forms/document-template.fields.json'

export default {
    name: 'DocumentTemplateForm',
    mixins: [formBuilderMixin],
    formBuilderSettings: {
        schema: DOCUMENT_TEMPLATE_FIELDS,
        fields () {
            const fields = [
                'name',
                'description',
                'availableForSubordinateCompanies',
                'supportedTargetObjectTypes',
                'languageId',
            ]
            if (this.documentType === DocumentType.LETTER) fields.push('confidentialityNotice')

            return fields
        },
    },
    props: {
        documentType: {
            type: String,
            required: true,
        },
        documentTemplateOwnerType: {
            type: String,
            required: true,
        },
        documentTemplate: {
            type: Object,
        },
    },
    data () {
        const data = {
            ViewMode,
            currentMode: (this.documentTemplate) ? ViewMode.UPDATE : ViewMode.CREATE,
            DocumentTemplateOwnerType,
            DocumentType,
            CorrespondenceItemTargetObjectType,
            formData: {},
        }

        // Prefill with the first defined correspondence language in the tenantSettings.
        if (this.$store.state.user.tenantSettings.correspondenceLanguages.length) {
            data.formData.languageId = this.$store.state.user.tenantSettings.correspondenceLanguages[0].id
        }

        if (data.currentMode === ViewMode.UPDATE) {
            const initialData = {
                name: this.documentTemplate.name,
                description: this.documentTemplate.description,
                availableForSubordinateCompanies: this.documentTemplate.available_for_subordinate_companies,
                supportedTargetObjectTypes: this.documentTemplate.supported_target_object_types,
                languageId: this.documentTemplate.language.id,
            }
            if (this.documentType === DocumentType.LETTER) initialData.confidentialityNotice = this.documentTemplate.confidentiality_notice ??  null

            Object.assign(data.formData, initialData)
            if (initialData.supportedTargetObjectTypes?.length) this.$emit('update:supportedTargetObjectTypes', initialData.supportedTargetObjectTypes)
        }
        return data
    },
    watch: {
        'formData.supportedTargetObjectTypes' (newValue) {
            this.$emit('update:supportedTargetObjectTypes', newValue)
        },
    },
}
</script>
