import gql from 'graphql-tag'

export const COMMISSION_LIST_IMPORT_EXCEL_MAPPING_DELETE_MUTATION = gql`
    mutation commissionListImportExcelMappingDelete(
        $id: ID!
    ) {
        commissionListImportExcelMappingDelete(
            id: $id
        ) {
            status
        }
    }
`
