import { sha256 } from '@/helpers'

export const UPLOAD_IGNORE_FILENAMES = []

export function filterFiles (files) {
    return files.filter(file => {
        return !(file.name.startsWith('.') || UPLOAD_IGNORE_FILENAMES.includes(file.name))
    })
}

export async function prepareFileUploadWrappers (files, { targetObjectType, targetObjectId, targetObjectConsultants, fileTypeId, meta }) {
    const fileUploadWrappers = []
    for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const fileUploadWrapper = {
            targetObjectType: targetObjectType,
            targetObjectId: targetObjectId,
            file: file,
        }
        if (typeof targetObjectConsultants !== 'undefined') fileUploadWrapper.targetObjectConsultants = targetObjectConsultants
        fileUploadWrapper.fileTypeId = (typeof fileTypeId !== 'undefined') ? fileTypeId : null

        const hashObject = {}
        Object.assign(hashObject, fileUploadWrapper)
        delete hashObject.file
        Object.assign(hashObject, {
            file: {
                lastModified: file.lastModified,
                name: file.name,
                size: file.size,
                type: file.type,
            },
        })

        fileUploadWrapper.id = await sha256(JSON.stringify(hashObject))
        if (typeof meta !== 'undefined') fileUploadWrapper.meta = meta
        fileUploadWrappers.push(fileUploadWrapper)
    }
    return fileUploadWrappers
}

export function getFileDownloadURL (fileId) {
    const tenantDomain = process.env.VUE_APP_TENANT_DOMAIN || window.location.hostname
    return `${process.env.VUE_APP_API_URL}/${tenantDomain}/downloads/${fileId}`
}

// https://stackoverflow.com/a/53058574
// Drop handler function to get all files
export async function getAllFiles (dataTransferItemList) {
    let files = []
    // Use BFS to traverse entire directory/file structure
    let queue = []
    // Unfortunately dataTransferItemList is not iterable i.e. no forEach
    for (let i = 0; i < dataTransferItemList.length; i++) {
        queue.push(dataTransferItemList[i].webkitGetAsEntry()) // TODO: Check for .getAsEntry() --> https://developer.mozilla.org/en-US/docs/Web/API/DataTransferItem/webkitGetAsEntry
    }
    while (queue.length > 0) {
        let entry = queue.shift()
        if (entry.isFile) {
            files.push(await getFile(entry))
        } else if (entry.isDirectory) {
            queue.push(...await readAllDirectoryEntries(entry.createReader()))
        }
    }
    return files
}

// Get all the entries (files or sub-directories) in a directory
// by calling readEntries until it returns empty array
async function readAllDirectoryEntries (directoryReader) {
    let entries = []
    let readEntries = await readEntriesPromise(directoryReader)
    while (readEntries.length > 0) {
        entries.push(...readEntries)
        readEntries = await readEntriesPromise(directoryReader)
    }
    return entries
}

// Wrap readEntries in a promise to make working with readEntries easier
// readEntries will return only some of the entries in a directory
// e.g. Chrome returns at most 100 entries at a time
async function readEntriesPromise (directoryReader) {
    try {
        return await new Promise((resolve, reject) => directoryReader.readEntries(resolve, reject))
    } catch (err) {
        console.log(err)
    }
}

async function getFile (fileEntry) {
    try {
        return await new Promise((resolve, reject) => fileEntry.file(resolve, reject))
    } catch (err) {
        console.log(err)
    }
}

export function getFileTypeIcon (fileType) {
    // fileType should be a String of a Media Type template from the official list:
    // https://www.iana.org/assignments/media-types/media-types.xhtml
    const fileTypeParts = fileType ? fileType.split('/') : []
    const mediaType = fileTypeParts[0] || ''
    const mediaSubType = fileTypeParts[1] || ''

    // List of allowed media (sub) types, grouped by type
    const mediaTypeList = {
        'application': {
            'json': 'json',
            'msonenote': 'one',
            'msword': 'doc',
            'pdf': 'pdf',
            'rtf': 'rtf',
            'vnd.ms-excel': 'xls',
            'vnd.ms-outlook': 'msg',
            'vnd.ms-powerpoint': 'ppt',
            'vnd.oasis.opendocument.chart': 'odc',
            'vnd.oasis.opendocument.formula': 'odf',
            'vnd.oasis.opendocument.graphics': 'odg',
            'vnd.oasis.opendocument.image': 'odi',
            'vnd.oasis.opendocument.presentation': 'odp',
            'vnd.oasis.opendocument.spreadsheet': 'ods',
            'vnd.oasis.opendocument.text': 'odt',
            'vnd.oasis.opendocument.text-master': 'odm',
            'vnd.oasis.opendocument.text-web': 'oth',
            'vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
            'vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
            'vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
            'vnd.rar': 'rar',
            'vnd.visio': 'vsd',
            'x-7z-compressed': '7z',
            'xml': 'xml',
            'zip': 'zip',
        },
        'audio': {
            'aac': 'aac',
            'mp4': 'mp4',
            'mpeg': 'mp3',
            // 'ogg': 'oga',
            'ogg': 'ogg',
            'wav': 'wav',
            'webm': 'weba',
            'x-matroska': 'mka',
            'x-ms-wma': 'wma',
        },
        'image': {
            'bmp': 'bmp',
            'gif': 'gif',
            'jpeg': 'jpeg',
            // 'jpeg': 'jpg',
            'png': 'png',
            // 'tiff': 'tif',
            'tiff': 'tiff',
            'webp': 'webp',
        },
        'message': {
            'rfc822': 'eml',
        },
        'text': {
            'calendar': 'ics',
            'csv': 'csv',
            'markdown': 'txt',
            'plain': 'txt',
            'tab-separated-values': 'tsv',
            'xml': 'xml',
        },
        'video': {
            'mp4': 'mp4',
            'mpeg': 'mpeg',
            'ogg': 'ogv',
            'quicktime': 'mov',
            'webm': 'webm',
            'x-matroska': 'mkv',
            'x-ms-wmv': 'wmv',
            'x-msvideo': 'avi',
        },
    }

    if (Object.keys(mediaTypeList).indexOf(mediaType) >= 0) { // File media type exists
        if (Object.keys(mediaTypeList[mediaType]).indexOf(mediaSubType) >= 0) { // There is a specific icon for it (as defined in the `mediaTypeList` above)
            return `mib-itx-file-type-${mediaTypeList[mediaType][mediaSubType]}`
        } else { // There is no specific icon defined
            return `mib-itx-file-type-${mediaType}`
        }
    } else { // The file media type is unknown
        return fileType === null ? 'mib-itx-file-type-general' : 'mib-itx-file-type-unknown'
    }
}

const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

/**
 * This is a copy of quasars built in humanStorageSize function (https://quasar.dev/quasar-utils/formatter-utils#Format-to-Human-Readable-Size)
 * Unfortunately it does not include a space between the number and the unit, which is wrong…
 * Therefor this copy here with the only difference being the additional space in the output.
 */

export function humanStorageSize (bytes) {
    let u = 0

    while (parseInt(bytes, 10) >= 1024 && u < units.length - 1) {
        bytes /= 1024
        ++u
    }

    return `${bytes.toFixed(1)} ${units[u]}`
}
