import gql from 'graphql-tag'

export const COMMISSION_RATE_ADJUSTMENT_RULE_SET_CREATE_MUTATION = gql`
    mutation commissionRateAdjustmentRuleSetCreate(
        $product_provider_id: ID!
        $commissionRateAdjustmentRuleSet: CommissionRateAdjustmentRuleSetInput!
    ) {
        commissionRateAdjustmentRuleSetCreate(
            product_provider_id: $product_provider_id,
            commissionRateAdjustmentRuleSet: $commissionRateAdjustmentRuleSet
        ) {
            id
            productProvider {
                id
                name
                display_name
            }
            rules {
                id
                igb2bSectorCode {
                    id
                    code
                    name
                }
                custom_sector_code
                commission_rate
                adjusted_commission_rate
                created_at
                updated_at
            }
            created_at
            updated_at
        }
    }
`
