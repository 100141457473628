import { Model } from '@/models'

import { ProductTemplateService } from '@/services'
import { ProductTemplateStatus } from '@/enums/graphql'
import { ProductTemplateActions } from '@/enums'
import { walkTree } from '@/helpers'
import { prepareProductTemplateFormData } from '@/helpers/product'
import { UserSettings } from '@/helpers/user'
import { saveAs } from 'file-saver'
import store from '@/store/store'

export class ProductTemplate extends Model {
    constructor (data) {
        super()
        Model.initializeFields(this, [
            'id',
            'name',
            'status',
            'category',
            'subCategory',
            'version',
            'template_configuration',
            'form_configuration',

            // Timestamps
            'created_at',
            'updated_at',
        ], data)

        this.statusTransitions[ProductTemplateStatus.ACTIVE] = [ProductTemplateStatus.ARCHIVED]
        this.statusTransitions[ProductTemplateStatus.ARCHIVED] = [ProductTemplateStatus.ACTIVE]

        this.statusActionMapping[ProductTemplateStatus.ACTIVE] = ProductTemplateActions.UNARCHIVE
        this.statusActionMapping[ProductTemplateStatus.ARCHIVED] = ProductTemplateActions.ARCHIVE
    }

    static get service () { return ProductTemplateService }
    static status = ProductTemplateStatus
    static action = ProductTemplateActions

    static compareVersions (productTemplate1, productTemplate2) {
        const diff = []

        const productTemplate1Fields = []
        walkTree(productTemplate1.template_configuration, node => {
            if (node.type === 'field') productTemplate1Fields.push(node)
        })

        const productTemplate2Fields = []
        walkTree(productTemplate2.template_configuration, node => {
            if (node.type === 'field') productTemplate2Fields.push(node)
        })

        productTemplate2Fields.forEach(productField => {
            const productTemplate1Field = productTemplate1Fields.find(productTemplate1Field => productTemplate1Field.key === productField.key)
            if (productTemplate1Field === undefined) {
                diff.push({
                    key: productField.key,
                    label: productField.label,
                    required: productField.required,
                    modification: 'addField',
                })
            } else {
                if (productTemplate1Field.required === false && productField.required === true) {
                    diff.push({
                        key: productField.key,
                        label: productField.label,
                        required: productField.required,
                        modification: 'addMandatory',
                    })
                } else if (productTemplate1Field.required === true && productField.required === false) {
                    diff.push({
                        key: productField.key,
                        label: productField.label,
                        required: productField.required,
                        modification: 'removeMandatory',
                    })
                }

                productTemplate1Fields.splice(productTemplate1Fields.indexOf(productTemplate1Field), 1)
            }
        })

        // Deleted fields
        if (productTemplate1Fields.length) {
            productTemplate1Fields.forEach(productField => {
                diff.push({
                    key: productField.key,
                    label: productField.label,
                    required: productField.required,
                    modification: 'removeField',
                })
            })
        }

        return diff
    }

    static create (name, productCategoryId, productSubCategoryId, templateConfiguration) {
        const preparedTemplateConfiguration = JSON.parse(JSON.stringify(templateConfiguration))
        walkTree(preparedTemplateConfiguration, prepareProductTemplateFormData)
        delete preparedTemplateConfiguration.type // TODO: Remove after "type": "root" has been added in Backend

        const variables = {
            productTemplate: {
                name: name,
                product_category_id: productCategoryId,
                product_sub_category_id: productSubCategoryId,
                template_configuration: JSON.stringify(preparedTemplateConfiguration),
            },
        }

        return ProductTemplateService.create(variables)
    }

    update (name, productCategoryId, productSubCategoryId, templateConfiguration) {
        const preparedTemplateConfiguration = JSON.parse(JSON.stringify(templateConfiguration))
        walkTree(preparedTemplateConfiguration, prepareProductTemplateFormData)
        delete preparedTemplateConfiguration.type // TODO: Remove after "type": "root" has been added in Backend

        const productTemplateVariables = {
            name: name,
            product_category_id: productCategoryId,
            product_sub_category_id: productSubCategoryId,
        }

        // Only send template_configuration if it's dirty
        if (JSON.stringify(preparedTemplateConfiguration) !== JSON.stringify(this.template_configuration)) {
            productTemplateVariables.template_configuration = JSON.stringify(preparedTemplateConfiguration)
        }

        return ProductTemplateService.update(this.id, productTemplateVariables)
    }

    delete () {
        return ProductTemplateService.delete(this.id)
    }

    get actions () {
        const actions = super.actions
        actions.push({
            key: ProductTemplateActions.DELETE,
            item: this,
        })

        if (this.export) {
            const userSettings = new UserSettings(store.state.user)
            if (['console', 'file'].includes(userSettings.getItem('ProductTemplate.export'))) {
                actions.push({
                    key: ProductTemplateActions.EXPORT,
                    item: this,
                })
            }
        }

        return actions
    }

    archive () {
        return ProductTemplateService.changeStatus(this.id, ProductTemplateStatus.ARCHIVED).then(productTemplate => {
            this.status = productTemplate.status
            return productTemplate
        })
    }

    unarchive () {
        return ProductTemplateService.changeStatus(this.id, ProductTemplateStatus.ACTIVE).then(productTemplate => {
            this.status = productTemplate.status
            return productTemplate
        })
    }

    export () {
        const exportObject = {
            id: this.id,
            name: this.name,
            status: this.status,
            category: {
                id: this.category.id,
                name: this.category.name,
                key: this.category.key,
            },
            subCategory: {
                id: this.subCategory.id,
                name: this.subCategory.name,
                key: this.subCategory.key,
            },
            version: this.version,
            templateConfiguration: this.template_configuration,

            // Timestamps
            createdAt: this.created_at,
            updatedAt: this.updated_at,
        }

        const userSettings = new UserSettings(store.state.user)
        switch (userSettings.getItem('ProductTemplate.export')) {
            case 'console':
                console.log(JSON.stringify(exportObject, null, 4))
                break
            case 'file':
                saveAs(new Blob([JSON.stringify(exportObject, null, 4)], { type: 'application/json;charset=utf-8' }), `MAX Product Template – ${this.name}.json`)
                break
        }
    }
}
