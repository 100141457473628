<template>
    <page-wrapper>
        <page-header>
            <template v-slot:breadcrumbs>
                <q-breadcrumbs-el :label="$t('views.account.change-password.title')" :to="{ name: 'account-security-password' }" />
            </template>

            <h1>{{ $t('views.account.change-password.title') }}</h1>
        </page-header>

        <div class="row q-mt-md q-col-gutter-md">
            <div class="col-xs-12">
                <user-change-password-form ref="userChangePasswordForm" @submit="changePassword" />
            </div>
        </div>
    </page-wrapper>
</template>

<script>
import UserChangePasswordForm from '@/components/user/UserChangePasswordForm.vue'

export default {
    name: 'AccountChangePassword',
    components: {
        UserChangePasswordForm,
    },
    meta () {
        return {
            title: this.$tc('views.account.change-password.title', 1),
        }
    },
    data () {
        return {
            showChangePasswordForm: false,
        }
    },
    methods: {
        changePassword ({ password, setPassword, confirmPassword }) {
            this.$store.state.user.changePassword(password, setPassword, confirmPassword)
                .then(() => {
                    this.$refs.userChangePasswordForm.resetFormData()
                    this.$refs.userChangePasswordForm.resetFormSubmitStatus()
                    this.$q.notify({
                        type: 'positive',
                        message: this.$tc('common.notifications.security.password-changed-success', 1),
                    })
                })
                .catch((error) => {
                    this.$q.notify({
                        type: 'negative',
                        message: this.$tc('common.term.error', 1),
                        caption: this.$tc('common.notifications.security.password-changed-error', 1),
                    })
                    this.$refs.userChangePasswordForm.showFormErrorMessage(error)
                    this.$refs.userChangePasswordForm.resetFormSubmitStatus()
                })
        },
    },
}
</script>

<style lang="scss" scoped>
</style>
