import { i18n } from '@/i18n'
import { ActivityLogParser } from '@/parsers/activityLog'
import { getContactAddress } from '@/helpers/contact'

export class AddressActivityLogParser extends ActivityLogParser {
    static attributeDataTypes = {
        'label.id': 'addressLabel',
        'po_box': 'boolean',
        'country.id': 'country',
        'valid_from': 'date',
        'valid_until': 'date',
        'is_main_correspondence_address': 'boolean',
    }

    static getLogDetailsCreated (activityLog, data) {
        const logDetails = []

        logDetails.push (
            {
                key: 'address',
                newValue: `<address><span class="additional-info">${this.getFormattedLogDetailValue(activityLog, 'addressLabel', this.attributeDataTypes['label.id'] ?? 'undefined', activityLog.properties.attributes['label.id'])}:</span>
                ${getContactAddress(activityLog.properties.attributes, { multiline: true }) }</address>`,
            }
        )

        if (activityLog.properties.attributes.valid_from) {
            logDetails.push (
                {
                    key: 'valid_from',
                    label: i18n.tc('forms.field.label.address.valid-from', 1),
                    newValue: this.getFormattedLogDetailValue(activityLog, 'valid_from', this.attributeDataTypes['valid_from'] ?? 'undefined', activityLog.properties.attributes.valid_from),
                }
            )
        }

        if (activityLog.properties.attributes.valid_until) {
            logDetails.push (
                {
                    key: 'valid_until',
                    label: i18n.tc('forms.field.label.address.valid-until', 1),
                    newValue: this.getFormattedLogDetailValue(activityLog, 'valid_until', this.attributeDataTypes['valid_until'] ?? 'undefined', activityLog.properties.attributes.valid_until),
                }
            )
        }

        if (activityLog.properties.attributes.is_main_correspondence_address) {
            logDetails.push (
                {
                    key: 'is_main_correspondence_address',
                    label: i18n.tc('forms.field.label.address.is-main-correspondence-address', 1),
                    newValue: this.getFormattedLogDetailValue(activityLog, 'is_main_correspondence_address', this.attributeDataTypes['is_main_correspondence_address'] ?? 'undefined', activityLog.properties.attributes.is_main_correspondence_address),
                }
            )
        }

        return logDetails
    }

    static getLogDetailsDeleted (activityLog, data) {
        const logDetails = []

        logDetails.push (
            {
                key: 'address',
                oldValue: `<address><span class="additional-info">${this.getFormattedLogDetailValue(activityLog, 'addressLabel', this.attributeDataTypes['label.id'] ?? 'undefined', activityLog.properties.attributes['label.id'])}:</span>
                ${getContactAddress(activityLog.properties.attributes, { multiline: true }) }</address>`,
            }
        )

        if (activityLog.properties.attributes.valid_from) {
            logDetails.push (
                {
                    key: 'valid_from',
                    label: i18n.tc('forms.field.label.address.valid-from', 1),
                    oldValue: this.getFormattedLogDetailValue(activityLog, 'valid_from', this.attributeDataTypes['valid_from'] ?? 'undefined', activityLog.properties.attributes.valid_from),
                }
            )
        }

        if (activityLog.properties.attributes.valid_until) {
            logDetails.push (
                {
                    key: 'valid_until',
                    label: i18n.tc('forms.field.label.address.valid-until', 1),
                    oldValue: this.getFormattedLogDetailValue(activityLog, 'valid_until', this.attributeDataTypes['valid_until'] ?? 'undefined', activityLog.properties.attributes.valid_until),
                }
            )
        }

        if (activityLog.properties.attributes.is_main_correspondence_address) {
            logDetails.push (
                {
                    key: 'is_main_correspondence_address',
                    label: i18n.tc('forms.field.label.address.is-main-correspondence-address', 1),
                    oldValue: this.getFormattedLogDetailValue(activityLog, 'is_main_correspondence_address', this.attributeDataTypes['is_main_correspondence_address'] ?? 'undefined', activityLog.properties.attributes.is_main_correspondence_address),
                }
            )
        }

        return logDetails
    }
}
