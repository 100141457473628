import gql from 'graphql-tag'

export const COMMENTS_QUERY = gql`
    query comments(
        $page: Int = 1
        $count: Int = 1000
        $targetObject: CommentTargetObjectInput!
        $orderField: CommentOrderField = CREATED_AT
        $orderDirection: OrderDirection = DESC
        $filterCreatorId: ID
        $filterPinned: Boolean
    ) {
        comments(
            page: $page
            count: $count
            targetObject: $targetObject
            order: [{ field: $orderField, direction: $orderDirection }]
            search: {
                creator_id: $filterCreatorId
                pinned: $filterPinned
            }
        ) {
            paginatorInfo {
                count
                firstItem
                lastItem
                total
                currentPage
                lastPage
                perPage
                hasMorePages
            }
            data {
                id
                creator {
                    id
                    contact_number
                    first_name
                    last_name
                    gender
                    title
                    consultingCompany {
                        id
                    }
                    consulting_company_name
                }

                text
                pinned

                created_at
                updated_at
            }
        }
    }
`
