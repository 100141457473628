export class CommentPolicy {
    constructor () {
        this.create = true
    }

    update (user, comment) {
        return comment.creator.id === user.consultant.id
    }

    delete (user, comment) {
        return user.aclPermissions.includes('Comment:delete:all') || comment.creator.id === user.consultant.id
    }
}
