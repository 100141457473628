import { apolloClient } from '@/apollo/client'

import { COMMISSION_LIST_QUERY } from '@/graphql/commissionList/commissionList'
import { COMMISSION_LISTS_QUERY } from '@/graphql/commissionList/commissionLists'
import { COMMISSION_LIST_CREATE_MUTATION } from '@/graphql/commissionList/commissionListCreate'
import { COMMISSION_LIST_UPDATE_MUTATION } from '@/graphql/commissionList/commissionListUpdate'
import { COMMISSION_LIST_DELETE_MUTATION } from '@/graphql/commissionList/commissionListDelete'
import { COMMISSION_LIST_START_PROCESSING_MUTATION } from '@/graphql/commissionList/commissionListStartProcessing'
import { COMMISSION_LIST_CANCEL_PROCESSING_MUTATION } from '@/graphql/commissionList/commissionListCancelProcessing'
import { COMMISSION_LIST_START_POSTING_MUTATION } from '@/graphql/commissionList/commissionListStartPosting'
import { COMMISSION_LIST_CANCEL_POSTING_MUTATION } from '@/graphql/commissionList/commissionListCancelPosting'

export const CommissionListService = {
    get (id) {
        return apolloClient.query({
            query: COMMISSION_LIST_QUERY,
            variables: {
                id: id,
            },
        }).then(response => response.data.commissionList)
    },
    all (variables) {
        return apolloClient.query({
            query: COMMISSION_LISTS_QUERY,
            variables,
        }).then(response => response.data.commissionLists)
    },
    create (variables) {
        return apolloClient.mutate({
            mutation: COMMISSION_LIST_CREATE_MUTATION,
            variables,
        }).then(response => response.data.commissionListCreate)
    },
    update (id, commissionListVariables) {
        return apolloClient.mutate({
            mutation: COMMISSION_LIST_UPDATE_MUTATION,
            variables: {
                id: id,
                commissionList: commissionListVariables,
            },
        }).then(response => response.data.commissionListUpdate)
    },
    delete (id) {
        return apolloClient.mutate({
            mutation: COMMISSION_LIST_DELETE_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.commissionListDelete)
    },
    startProcessing (id) {
        return apolloClient.mutate({
            mutation: COMMISSION_LIST_START_PROCESSING_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.commissionListStartProcessing)
    },
    cancelProcessing (id) {
        return apolloClient.mutate({
            mutation: COMMISSION_LIST_CANCEL_PROCESSING_MUTATION,
            variables: {
                id: id,
            },
        }).then(response => response.data.commissionListCancelProcessing)
    },
    startPosting (variables) {
        return apolloClient.mutate({
            mutation: COMMISSION_LIST_START_POSTING_MUTATION,
            variables,
        }).then(response => response.data.commissionListStartPosting)
    },
    cancelPosting (variables) {
        return apolloClient.mutate({
            mutation: COMMISSION_LIST_CANCEL_POSTING_MUTATION,
            variables,
        }).then(response => response.data.commissionListCancelPosting)
    },
}
