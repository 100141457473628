import { Form, FormField } from '@/libs/form'
import { ConsultingFileTargetObjectType, DocumentType } from '@/enums/graphql'
import { kebabCase } from 'lodash'
import { watch } from 'vue'
import store from '@/store/store'

export class MailProcessingTemplateForm extends Form {
    compose () {
        this.addField(new FormField('name', {
            label: 'forms.field.label.mail-processing.name',
            inputType: 'text',
            validators: [
                { type: 'required' },
            ],
        }))

        this.addField(new FormField('description', {
            label: 'forms.field.label.mail-processing.description',
            inputType: 'text',
        }))

        this.addField(new FormField('fileName', {
            label: 'forms.field.label.mail-processing.file-name',
            inputType: 'text',
            attributes: {
                infoText: 'forms.field.label.mail-processing.file-name--info',
            },
        }))

        this.addField(new FormField('mailProcessingActiveDocumentTemplate', {
            label: 'forms.field.label.mail-processing.mail-processing-template--active',
            inputType: 'documentTemplate',
            validators: [
                { type: 'required' },
            ],
            attributes: {
                filters: {
                    filterType: DocumentType.LETTER,
                    filterOwnerId: store.state.user.tenantCompany.id,
                },
                infoText: 'forms.field.label.mail-processing.mail-processing-template--active--info',
            },
        }))

        this.addField(new FormField('mailProcessingInactiveDocumentTemplate', {
            label: 'forms.field.label.mail-processing.mail-processing-template--inactive',
            inputType: 'documentTemplate',
            validators: [
                { type: 'required' },
            ],
            attributes: {
                filters: {
                    filterType: DocumentType.LETTER,
                    filterOwnerId: store.state.user.tenantCompany.id,
                },
                infoText: 'forms.field.label.mail-processing.mail-processing-template--inactive--info',
            },
        }))

        const targetObjectTypeOptions = [
            { label: 'common.term.please-choose', value: null, disable: true },
            ...Object.values(ConsultingFileTargetObjectType).map(value => ({ label: `common.correspondence.target-object.target-object-type--${kebabCase(value)}`, value: value })),
        ]
        this.addField(new FormField('targetObjectType', {
            label: 'forms.field.label.mail-processing.target-object-type',
            inputType: 'select',
            validators: [
                { type: 'required' },
            ],
            attributes: {
                options: targetObjectTypeOptions,
                infoText: 'forms.field.label.mail-processing.target-object-type--info',
            },
        }))
        watch(() => this.getItem('targetObjectType').value, (value, oldValue) => {
            if (oldValue !== null) this.getItem('consultingFileType').resetValue()
        })

        this.addField(new FormField('consultingFileType', {
            label: 'forms.field.label.mail-processing.consulting-file-type',
            inputType: 'consultingFileType',
            validators: [
                { type: 'required' },
            ],
        }))

        this.addField(new FormField('billable', {
            label: 'forms.field.label.mail-processing.billable',
            inputType: 'checkbox',
        }))
    }
}
