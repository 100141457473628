import gql from 'graphql-tag'

export const COMMISSION_LIST_ENTRY_POSTING_PREVIEW_MUTATION = gql`
    mutation commissionListEntryPostingPreview(
        $id: ID!
    ) {
        commissionListEntryPostingPreview(
            id: $id
        ) {
            postings {
                type {
                    key
                    name
                }
                date
                debitAccount {
                    number
                    name
                }
                amount
                creditAccount {
                    number
                    name
                }
                text
            }
        }
    }
`
