<template>
    <q-toolbar v-if="devHeader" class="dev-header q-pa-sm">
        <!-- Visible dev header content -->
        <q-toggle
            v-model="showBreakpointInfo"
            color="accent"
            icon="mib-resize-expand-sides"
            label="Breakpoint helper"
        />

        <q-space />

        <q-btn
            :to="{ name: 'dev-dashboard' }"
            icon="mib-programming-browser-1"
            size="0.7rem"
            text-color="white"
            unelevated
            round
        >
            <q-tooltip>
                Go to Dev Dashboard
            </q-tooltip>
        </q-btn>
        <q-btn
            icon="mib-close"
            size="0.7rem"
            text-color="white"
            unelevated
            round
            @click="hideDevHeader"
        >
            <q-tooltip>
                Close DevHeader
            </q-tooltip>
        </q-btn>

        <!-- Dev only components -->
        <breakpoint-info v-if="showBreakpointInfo" />
    </q-toolbar>
</template>

<script>
import BreakpointInfo from '@/components/dev/DevBreakpointInfo.vue'

export default {
    name: 'DevHeader',
    components: {
        BreakpointInfo,
    },
    data () {
        return {
            showBreakpointInfo: false,
        }
    },
    computed: {
        devHeader () {
            return this.$store.state.devHeader
        },
    },
    created () {
        if (this.$q.localStorage.has('show-dev-header')) {
            this.$store.dispatch('showDevHeader')
        }
    },
    methods: {
        hideDevHeader () {
            this.$store.dispatch('hideDevHeader')
        },
    },
}
</script>

<style lang="scss" scoped>
$sizeDevHeaderHeight: 50px;

.dev-header {
    position: fixed;
    width: 100%;
    height: $sizeDevHeaderHeight;
    z-index: 10000; // q-menu has a z-index of 6000

    background: var(--q-color-secondary-darker);
    color: var(--color-text-tertiary);

    // Adjust QLayout when DevHeader is visible
    & ::v-deep + .q-layout {
        min-height: calc( 100vh - #{$sizeDevHeaderHeight} ) !important;
        padding-top: $sizeDevHeaderHeight;

        .q-header {
            top: $sizeDevHeaderHeight;
        }

        // TODO: Enhance this for mobile screens (drawers should be at the very top when they use the "mobile" behaviour).
        .q-drawer {
            top: $sizeDevHeaderHeight + $sizeLayoutHeaderHeight !important;
        }

        // TODO: Enhance this / fix scrolling issue when QLayoutFooter (<in-page-footer> component) is used.
        .q-page {
            min-height: calc( 100vh - ( #{$sizeLayoutHeaderHeight} + #{$sizeDevHeaderHeight} ) ) !important;
        }
    }
}
</style>
