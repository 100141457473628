<template>
    <q-dialog
        ref="dialog"
        v-bind="$attrs"
        @show="onShow"
        @hide="onHide"
    >
        <q-card class="overlay-size-md">
            <q-card-section>
                <slot name="header"><h1>{{ $tc(`views.contact-relationship.list.${currentMode.toLowerCase()}-relationship`, 1) }}</h1></slot>

                <info-box v-if="currentMode === ViewMode.UPDATE" type="secondary">
                    <p>{{ contactRelationship.getRelationshipFullText(contact.id, { inverted: true }) }}</p>
                </info-box>

                <contact-relationship-form
                    ref="contactRelationshipForm"
                    :contact="contact"
                    :contact-relationship="contactRelationship"
                    :contact-relationships="contactRelationships"
                    @submit="handleSubmit"
                    @cancel="close"
                />
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script>
import { ContactRelationship } from '@/models/contactRelationship'
import { parseFormDataToInput } from '@/helpers/contactRelationship'
import { ViewMode } from '@/enums'

import ContactRelationshipForm from '@/components/contactRelationship/ContactRelationshipForm.vue'

export default {
    name: 'ContactRelationshipOverlay',
    components: {
        ContactRelationshipForm,
    },
    props: {
        contact: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            ViewMode,
            contactRelationship: null,
            contactRelationships: [],
        }
    },
    computed: {
        currentMode () {
            return this.contactRelationship ? ViewMode.UPDATE : ViewMode.CREATE
        },
    },
    methods: {
        open ({ contactRelationship, contactRelationships }) {
            Object.assign(this, { contactRelationship })
            this.contactRelationships = contactRelationships
            this.$refs.dialog.show()
        },
        close () {
            this.$refs.dialog.hide()
        },
        onShow () {
            this.$emit('show')
        },
        onHide () {
            this.$emit('hide')
        },
        handleSubmit (data) {
            const contactRelationshipInput = parseFormDataToInput(data.formData)

            switch (this.currentMode) {
                case ViewMode.CREATE:
                    this.handleCreate({ data, contactRelationshipInput})
                    break
                case ViewMode.UPDATE:
                    this.handleUpdate({ data, contactRelationshipInput})
                    break
            }
        },
        handleCreate ({ data, contactRelationshipInput }) {
            const ids = this.getIds({
                contactId: data.formData.contactId,
                contactRelationshipDefinitionKey: data.contactRelationshipDefinitionKey,
                contactRelationshipDefinition: data.contactRelationshipDefinition,
            })

            const variables = {
                definitionId: ids.definitionId,
                node1Id: ids.node1Id,
                node2Id: ids.node2Id,
                contactRelationshipInput,
            }

            if (data.formData.areasOfResponsibility) variables.areasOfResponsibility = data.formData.areasOfResponsibility.map(areaOfResponsibility => {
                const data = { name: areaOfResponsibility.name }
                if (areaOfResponsibility.description) data.description = areaOfResponsibility.description
                if (areaOfResponsibility.emailAddressId) data.email_address_id = areaOfResponsibility.emailAddressId
                if (areaOfResponsibility.phoneNumberId) data.phone_number_id = areaOfResponsibility.phoneNumberId
                return data
            })

            ContactRelationship.create(variables).then(() => {
                this.close()

                this.$q.notify({
                    type: 'positive',
                    message: this.$tc('common.notifications.contact-relationship.contact-relationship-added-success', 1),
                })

                this.$emit('refetch:objects')
            }).catch(error => {
                this.$refs.contactRelationshipForm.formComponent.formBuilder.showFormErrorMessage(error)
                this.$refs.contactRelationshipForm.formComponent.formBuilder.resetFormSubmitStatus()
            })
        },
        handleUpdate ({ data, contactRelationshipInput }) {
            const variables = {
                contactRelationshipInput,
            }
            if (data.formData.areasOfResponsibility) variables.areasOfResponsibility = data.formData.areasOfResponsibility.map(areaOfResponsibility => {
                const data = { name: areaOfResponsibility.name }
                if (areaOfResponsibility.id) data.id = areaOfResponsibility.id
                if (areaOfResponsibility.description) data.description = areaOfResponsibility.description
                if (areaOfResponsibility.emailAddressId) data.email_address_id = areaOfResponsibility.emailAddressId
                if (areaOfResponsibility.phoneNumberId) data.phone_number_id = areaOfResponsibility.phoneNumberId
                return data
            })

            this.contactRelationship.update(variables).then(() => {
                this.close()

                this.$q.notify({
                    type: 'positive',
                    message: this.$tc('common.notifications.contact-relationship.contact-relationship-updated-success', 1),
                })
            }).catch(error => {
                this.$refs.contactRelationshipForm.formComponent.formBuilder.showFormErrorMessage(error)
                this.$refs.contactRelationshipForm.formComponent.formBuilder.resetFormSubmitStatus()
            })
        },
        getIds ({ contactId, contactRelationshipDefinitionKey, contactRelationshipDefinition }) {
            const ids = {
                definitionId: contactRelationshipDefinition.id,
            }

            if (contactRelationshipDefinition.node1_type === contactRelationshipDefinition.node2_type) {
                return {
                    ...ids,
                    node1Id: this.contact.id,
                    node2Id: contactId,
                }
            }

            return {
                ...ids,
                node1Id: contactRelationshipDefinitionKey === contactRelationshipDefinition.node1_type ? contactId : this.contact.id,
                node2Id: contactRelationshipDefinitionKey === contactRelationshipDefinition.node2_type ? contactId : this.contact.id,
            }
        },
    },
}
</script>
